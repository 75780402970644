import * as React from 'react';
import { Path, Svg } from 'react-native-svg';
const IconCategoryBills = (props) => (<Svg viewBox="0 0 24 24" fill="none" {...props}>
    <Path d="M12 4.88083C11.655 4.88083 11.375 4.60083 11.375 4.25583V2.625C11.375 2.28 11.655 2 12 2C12.345 2 12.625 2.28 12.625 2.625V4.25583C12.625 4.60083 12.345 4.88083 12 4.88083Z" fill={props.fill}/>
    <Path d="M17.4783 7.1481C17.3183 7.1481 17.1583 7.08727 17.0366 6.96477C16.7925 6.7206 16.7925 6.32477 17.0366 6.0806L18.19 4.92727C18.4341 4.6831 18.83 4.6831 19.0741 4.92727C19.3183 5.17143 19.3183 5.56727 19.0741 5.81143L17.9208 6.96477C17.7983 7.08643 17.6383 7.1481 17.4783 7.1481Z" fill={props.fill}/>
    <Path d="M21.375 12.625H19.7441C19.3991 12.625 19.1191 12.345 19.1191 12C19.1191 11.655 19.3991 11.375 19.7441 11.375H21.375C21.72 11.375 22 11.655 22 12C22 12.345 21.72 12.625 21.375 12.625Z" fill={props.fill}/>
    <Path d="M18.6316 19.2575C18.4716 19.2575 18.3116 19.1966 18.19 19.0741L17.0366 17.9208C16.7925 17.6766 16.7925 17.2808 17.0366 17.0366C17.2808 16.7925 17.6766 16.7925 17.9208 17.0366L19.0741 18.19C19.3183 18.4341 19.3183 18.83 19.0741 19.0741C18.9516 19.1966 18.7916 19.2575 18.6316 19.2575Z" fill={props.fill}/>
    <Path d="M5.37137 19.2575C5.21137 19.2575 5.05137 19.1966 4.92971 19.0741C4.68554 18.83 4.68554 18.4341 4.92971 18.19L6.08304 17.0366C6.32721 16.7925 6.72304 16.7925 6.96721 17.0366C7.21137 17.2808 7.21137 17.6766 6.96721 17.9208L5.81387 19.0741C5.69137 19.1966 5.53137 19.2575 5.37137 19.2575Z" fill={props.fill}/>
    <Path d="M4.25583 12.625H2.625C2.28 12.625 2 12.345 2 12C2 11.655 2.28 11.375 2.625 11.375H4.25583C4.60083 11.375 4.88083 11.655 4.88083 12C4.88083 12.345 4.60083 12.625 4.25583 12.625Z" fill={props.fill}/>
    <Path d="M6.52471 7.1481C6.36471 7.1481 6.20471 7.08727 6.08304 6.96477L4.92971 5.81143C4.68554 5.56727 4.68554 5.17143 4.92971 4.92727C5.17387 4.6831 5.56971 4.6831 5.81387 4.92727L6.96721 6.0806C7.21137 6.32477 7.21137 6.7206 6.96721 6.96477C6.84387 7.08643 6.68471 7.1481 6.52471 7.1481Z" fill={props.fill}/>
    <Path d="M14.5 19.5V20.5417C14.5 21.3417 13.8417 22 13.0417 22H10.9583C10.2583 22 9.5 21.4667 9.5 20.3V19.5H14.5Z" fill={props.fill}/>
    <Path d="M15.6744 7.46878C14.3078 6.36045 12.5078 5.92711 10.7494 6.30211C8.54111 6.76045 6.74944 8.56045 6.29111 10.7688C5.82444 13.0354 6.67444 15.2854 8.49111 16.6604C8.98278 17.0271 9.32444 17.5938 9.44111 18.2521V18.2604C9.45778 18.2521 9.48278 18.2521 9.49944 18.2521H14.4994C14.5161 18.2521 14.5244 18.2521 14.5411 18.2604V18.2521C14.6578 17.6188 15.0328 17.0354 15.6078 16.5854C17.0161 15.4688 17.8328 13.8021 17.8328 12.0021C17.8328 10.2354 17.0494 8.58545 15.6744 7.46878ZM15.1244 12.4188C14.7828 12.4188 14.4994 12.1354 14.4994 11.7938C14.4994 10.5271 13.4744 9.50211 12.2078 9.50211C11.8661 9.50211 11.5828 9.21878 11.5828 8.87711C11.5828 8.53545 11.8661 8.25211 12.2078 8.25211C14.1578 8.25211 15.7494 9.84378 15.7494 11.7938C15.7494 12.1354 15.4661 12.4188 15.1244 12.4188Z" fill={props.fill}/>
    <Path d="M9.44385 18.25H9.50334C9.48634 18.25 9.46084 18.25 9.44385 18.2585V18.25Z" fill={props.fill}/>
    <Path d="M14.5427 18.25V18.2585C14.5256 18.25 14.5171 18.25 14.5 18.25H14.5427Z" fill={props.fill}/>
  </Svg>);
export default IconCategoryBills;
