import AsyncStorage from '@react-native-async-storage/async-storage';
import appsFlyer from 'utils/packages/rnAppsflyer';
import Sentry from 'utils/sentry';
export const ATTRIBUTION_DATA = 'ATTRIBUTION_DATA';
export const getAttributionDataFromAsyncStorage = async () => {
    try {
        const data = await AsyncStorage.getItem(ATTRIBUTION_DATA);
        if (data) {
            try {
                return JSON.parse(data);
            }
            catch (err) {
                Sentry.logError({
                    filename: 'appsflyer.ts',
                    fingerprint: ['appsflyer.ts', 'JSON.parse'],
                    message: 'attributionData is not a valid JSON',
                    data,
                    err,
                });
            }
        }
    }
    catch {
        // noop
    }
    return null;
};
export const onInstallConversionData = () => appsFlyer.onInstallConversionData(async (res) => {
    try {
        const isFirstLaunch = res?.data?.is_first_launch;
        if (isFirstLaunch && JSON.parse(isFirstLaunch) === true) {
            // if (res.data.af_status === 'Non-organic') {
            //   var media_source = res.data.media_source;
            //   var campaign = res.data.campaign;
            // } else if (res.data.af_status === 'Organic') {
            // }
            try {
                const resdata = JSON.stringify(res.data);
                try {
                    if (resdata)
                        await AsyncStorage.setItem(ATTRIBUTION_DATA, resdata);
                }
                catch (err) {
                    Sentry.logError({
                        filename: 'appsflyer.ts',
                        fingerprint: ['onInstallConversionData', 'AsyncStorage.setItem'],
                        message: 'Failed to save AppsFlyer conversion data to AsyncStorage',
                        err,
                    });
                }
            }
            catch (err) {
                Sentry.logError({
                    filename: 'appsflyer.ts',
                    fingerprint: [
                        'onInstallConversionData',
                        'JSON.stringify(res.data)',
                    ],
                    message: 'Failed to stringify res.data',
                    err,
                });
            }
        }
    }
    catch (err) {
        Sentry.logError({
            filename: 'appsflyer.ts',
            fingerprint: ['onInstallConversionData', 'is_first_launch'],
            message: 'res.data.is_first_launch is not a valid JSON',
            data: res.data.is_first_launch,
            err,
        });
    }
});
export const logAppsFlyerEvent = async (eventName, eventValues = {}) => {
    try {
        await appsFlyer.logEvent(eventName, eventValues);
    }
    catch (e) {
        // No op
    }
};
