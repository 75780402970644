import React, { useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import { View, FlatList, } from 'react-native';
import Text from 'design-system/Text';
import Button from 'design-system/Button';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import { CardView } from 'design-system/CardView';
import { TextButton } from 'design-system/TextButton';
import { SelectableAccountCard } from 'design-system/SelectableAccountCard';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import useIsTablet from 'hooks/useIsTablet';
import createStyleSheets from 'utils/createStyleSheets';
import { sortAccountsByType } from 'utils/api/sortAccounts';
import { Switch } from '../Switch';
import { isWeb } from '../../constants';
export const getAccountsTitle = (currentAccountIds) => {
    if (currentAccountIds) {
        return `Accounts (${currentAccountIds.length})`;
    }
    return 'All Accounts';
};
const keyExtractor = (account) => account.id.toString();
const getItemLayout = (_, index) => ({
    length: rem(80),
    offset: rem(80) * index,
    index,
});
const Content = ({ onScroll, setState, ...state }) => {
    const styles = useStyles(styleSet);
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [showExcludedTransactions, setShowExcludedTransactions] = useState(true);
    useEffect(() => {
        if (state.alreadySelected) {
            setSelectedAccounts(state.alreadySelected);
        }
    }, [state.alreadySelected]);
    useEffect(() => {
        if (typeof state.showExcludedTxns === 'boolean') {
            setShowExcludedTransactions(state.showExcludedTxns);
        }
    }, [state.showExcludedTxns]);
    useEffect(() => {
        if (state.showSelectAll && !state.isOpen) {
            const timeout = setTimeout(() => {
                setSelectedAccounts(state.alreadySelected);
            }, 200);
            return () => {
                clearTimeout(timeout);
            };
        }
        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.isOpen]);
    const onPress = useCallback((data) => {
        if (!data) {
            return;
        }
        let array = [...selectedAccounts];
        const index = array.indexOf(data.id);
        if (index === -1) {
            array.push(data.id);
        }
        else {
            array = array.filter((item) => item !== data.id);
        }
        setSelectedAccounts(array);
    }, [selectedAccounts]);
    const onPressSave = useCallback(() => {
        setState((state) => ({ ...state, isOpen: false }));
        state.onSelected(selectedAccounts, showExcludedTransactions);
    }, [setState, state, selectedAccounts, showExcludedTransactions]);
    const colors = useColors();
    const handleToggle = React.useCallback((value) => {
        setShowExcludedTransactions(value);
    }, []);
    const scrollRef = useRef(null);
    const renderHeader = useMemo(() => {
        const isAllSelected = selectedAccounts.length >=
            state.accounts.filter((acc) => !acc?.isHidden).length;
        return state.showSelectAll ? (<>
        {typeof state.showExcludedTxns === 'boolean' && (<View style={styles.row}>
            <Text Text-16 flex>
              Show excluded transactions
            </Text>
            <Switch onValueChange={handleToggle} value={showExcludedTransactions}/>
          </View>)}

        <View style={styles.selectAllRow}>
          <Text Text-16 color={colors.text.tertiary}>
            {getAccountsTitle(selectedAccounts)}
          </Text>
          <TextButton text={`${isAllSelected ? 'Deselect' : 'Select'} all`} onPress={() => {
                if (isAllSelected) {
                    setSelectedAccounts([]);
                }
                else {
                    const allAccounts = state.accounts.flatMap((acc) => !acc.isHidden ? [acc.id] : []);
                    setSelectedAccounts(allAccounts);
                }
            }}/>
        </View>
      </>) : null;
    }, [
        selectedAccounts,
        state.accounts,
        state.showSelectAll,
        state.showExcludedTxns,
        styles.row,
        styles.selectAllRow,
        handleToggle,
        showExcludedTransactions,
        colors.text.tertiary,
    ]);
    const sorted = useMemo(() => {
        const copy = sortAccountsByType(state.accounts);
        copy.sort((a, b) => Number(a.isHidden) - Number(b.isHidden));
        return copy;
    }, [state.accounts]);
    const renderItem = useCallback(({ item, index }) => {
        const selected = selectedAccounts.indexOf(item.id) > -1;
        return (<>
          <SelectableAccountCard data={item} isSelected={selected} onPress={onPress} showHiddenLogo/>
          {!isWeb && index !== state.accounts.length - 1 && <Spacer h={16}/>}
        </>);
    }, [onPress, selectedAccounts, state.accounts.length]);
    const isTablet = useIsTablet();
    const content = useMemo(() => state.accounts && !state.accounts.length ? (<View style={styles.emptyContainer}>
          <Text Text-16 centered>
            No accounts to choose from.
          </Text>
        </View>) : (<FlatList data={sorted} windowSize={8} ref={scrollRef} onScroll={onScroll} initialNumToRender={5} renderItem={renderItem} maxToRenderPerBatch={5} scrollEventThrottle={16} keyExtractor={keyExtractor} extraData={selectedAccounts} getItemLayout={getItemLayout} {...(!isWeb && {
        ListHeaderComponent: renderHeader,
    })} contentContainerStyle={styles.contentContainerStyle}/>), [
        onScroll,
        renderHeader,
        renderItem,
        selectedAccounts,
        sorted,
        state.accounts,
        styles.contentContainerStyle,
        styles.emptyContainer,
    ]);
    return (<>
      {isWeb ? (<>
          {renderHeader}
          <CardView noMargin style={styles.card}>
            {content}
          </CardView>
        </>) : (content)}
      {isWeb && <Spacer h={16}/>}
      <Button brand title="Save" onPress={onPressSave} floating={!isTablet && !isWeb} style={isTablet && styles.button} disabled={!selectedAccounts.length}/>
    </>);
};
const styleSet = createStyleSheets((colors) => ({
    contentContainerStyle: {
        ...(!isWeb
            ? {
                paddingBottom: rem(160),
            }
            : {
                paddingHorizontal: rem(8),
            }),
    },
    row: {
        flexDirection: 'row',
        paddingVertical: rem(16),
        ...(!isWeb && {
            paddingHorizontal: rem(16),
        }),
    },
    selectAllRow: {
        flexDirection: 'row',
        paddingVertical: rem(16),
        justifyContent: 'space-between',
        ...(!isWeb && {
            paddingHorizontal: rem(16),
        }),
    },
    button: {
        position: 'absolute',
        bottom: rem(16),
        left: rem(16),
        right: rem(16),
    },
    card: {
        overflow: 'hidden',
        maxHeight: rem(350),
        paddingVertical: rem(8),
        backgroundColor: colors.background.light,
    },
    emptyContainer: {
        ...(isWeb
            ? {
                paddingVertical: rem(80),
            }
            : {
                paddingVertical: rem(40),
            }),
    },
}));
export default Content;
