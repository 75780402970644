const Settings = {
    getAdvertiserTrackingEnabled: () => { },
    setAdvertiserTrackingEnabled: () => { },
    setDataProcessingOptions: () => { },
    initializeSDK: () => { },
    setAppID: () => { },
    setClientToken: () => { },
    setAppName: () => { },
    setGraphAPIVersion: () => { },
    setAutoLogAppEventsEnabled: () => { },
    setAdvertiserIDCollectionEnabled: () => { },
};
const ShareDialog = {
    canShow: () => { },
    show: () => { },
    setMode: () => { },
    setShouldFailOnDataError: () => { },
};
const LoginManager = {
    logInWithPermissions: () => { },
    getLoginBehavior: () => { },
    setLoginBehavior: () => { },
    getDefaultAudience: () => { },
    setDefaultAudience: () => { },
    reauthorizeDataAccess: () => { },
    logOut: () => { },
};
const AppEventsLogger = {
    logEvent: () => { },
    setUserID: () => { },
    setUserData: () => { },
};
const AEMReporterIOS = {
    logAEMEvent: () => { },
};
const AccessToken = {};
const FBAuthenticationToken = {};
export { Settings, ShareDialog, AccessToken, LoginManager, AEMReporterIOS, AppEventsLogger, FBAuthenticationToken, };
