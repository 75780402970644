import { useAppSelector } from 'store/hooks';
import { updateTradingAccount } from '../api/account';
import { fakeAccount, useFakeData } from '../debug';
import { generateFakeAccount } from '../utils';
const useAccount = (options) => {
    const account = useAppSelector((state) => useFakeData ? generateFakeAccount() : state.invest.account);
    // code below is not wrapped into an effect because it needs to be run on every render
    if (!account || options?.refresh) {
        updateTradingAccount();
    }
    return fakeAccount || account;
};
export default useAccount;
