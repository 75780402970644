export const EMOJI_CATEGORIES = [
    'Smileys & Emotion',
    'Animals & Nature',
    'Food & Drink',
    'Travel & Places',
    'Activities',
    'Objects',
    'Symbols',
    'Flags',
];
