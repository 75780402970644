import React from 'react';
import { Spacer } from 'design-system';
import InfoCard from 'design-system/InfoCard';
import ScreenInfoHeader from 'design-system/ScreenInfoHeader';
import Text from 'design-system/Text';
import useColors from 'hooks/useColors';
const EditCategoryVerticalListHeader = () => {
    const colors = useColors();
    return (<>
      <ScreenInfoHeader title="Manage custom categories"/>
      <InfoCard color={colors.text.textOnBrand} backgroundColor={colors.cards.onDark}>
        <Text TextThin-14 Secondary>
          Default categories can’t be edited, but you can create unlimited
          custom.
        </Text>
      </InfoCard>
      <Spacer h={16}/>
    </>);
};
export default EditCategoryVerticalListHeader;
