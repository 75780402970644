import React from 'react';
import Svg, { Path } from 'react-native-svg';
import useColors from 'hooks/useColors';
import { rem } from 'design-system/values';
const IconCheck = ({ width = rem(24), height = rem(24), color, ...props }) => {
    const colors = useColors();
    const fill = color || colors.elements.positive;
    return (<Svg width={width} height={height} viewBox="0 0 17 17" fill="none" {...props}>
      <Path fillRule="evenodd" clipRule="evenodd" d="M6.09479 2.65728C7.41344 2.34796 8.78578 2.34796 10.1044 2.65728C12.0087 3.10397 13.4956 4.59087 13.9423 6.49518C14.2516 7.81383 14.2516 9.18617 13.9423 10.5048C13.4956 12.4091 12.0087 13.896 10.1044 14.3427C8.78578 14.652 7.41344 14.652 6.09479 14.3427C4.19048 13.896 2.70358 12.4091 2.25689 10.5048C1.94757 9.18617 1.94757 7.81383 2.25689 6.49517C2.70358 4.59087 4.19048 3.10397 6.09479 2.65728ZM10.1599 7.47223C10.306 7.3157 10.2975 7.07038 10.141 6.92429C9.98447 6.7782 9.73914 6.78666 9.59305 6.94319L7.60537 9.07284L6.59685 8.06432C6.44545 7.91292 6.19998 7.91292 6.04858 8.06432C5.89718 8.21572 5.89718 8.46118 6.04858 8.61258L7.34086 9.90485C7.4152 9.9792 7.51655 10.0202 7.62167 10.0183C7.72679 10.0165 7.82667 9.97211 7.89841 9.89525L10.1599 7.47223Z" fill={fill}/>
    </Svg>);
};
export default IconCheck;
