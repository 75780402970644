import React from 'react';
import { View } from 'react-native';
import { rem } from 'design-system/values';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import { isWeb } from '../../constants';
export const AvatarWrapper = ({ children, color, size, style, borderWidth = 0, }) => {
    const styles = useStyles(styleSet);
    const containerStyle = [
        styles.container,
        {
            width: rem(size + 2 * borderWidth),
            height: rem(size + 2 * borderWidth),
            borderRadius: (size + 2 * borderWidth) / 2,
            backgroundColor: color,
            borderWidth,
        },
        style,
    ];
    return <View style={containerStyle}>{children}</View>;
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        ...(isWeb && {
            borderColor: colors.text.primary,
        }),
    },
}));
