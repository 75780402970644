import React, { useId } from 'react';
import { StyleSheet, View } from 'react-native';
import Svg, { Defs, LinearGradient, Path, Stop, } from 'react-native-svg';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
export const IconSquareCircle = ({ children, color, scale = 1, width = 24, height = 24, style, ...props }) => {
    const remWidth = rem(width * scale);
    const remHeight = rem(height * scale);
    const colors = useColors();
    const id = useId();
    return (<View>
      <Svg viewBox="0 0 24 24" width={remWidth} height={remHeight} style={style}>
        <View style={[
            styles.container,
            {
                width: remWidth,
                height: remHeight,
            },
        ]}>
          {children}
        </View>
        <Path fillRule="nonzero" fill={Array.isArray(color)
            ? `url(#custom-gradient-${id})`
            : color ?? colors.background.transparent} d="M14 0C3.934 0 .105 3.7.105 11.5S3.934 23 12 23c8.066 0 11.895-3.7 11.895-11.5S20.066 0 12 0Zm0 0" {...props}/>
        <Defs>
          {Array.isArray(color) && (<LinearGradient id={`custom-gradient-${id}`} x1="21.7017" y1="21.0074" x2="-23.0243" y2="23.3636" gradientUnits="userSpaceOnUse">
              <Stop stopColor={color[0]}/>
              <Stop offset="1" stopColor={color[1]}/>
            </LinearGradient>)}
        </Defs>
      </Svg>
    </View>);
};
const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'transparent',
    },
});
