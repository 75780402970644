import React, { useRef, useState, useMemo } from 'react';
import { View, TouchableBounce, StyleSheet, Platform } from 'react-native';
import Animated, { useSharedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
const MAX_SUPPORTED_PERIODS_COUNT = 6;
const PERIOD_WIDTH = rem(40);
const PERIOD_HEIGHT = rem(24);
const hitSlop = { top: 10, bottom: 10, left: 10, right: 10 };
const INACTIVE_OPACITY = 0;
export var Period;
(function (Period) {
    Period["Last"] = "LAST";
    Period["D1"] = "1D";
    Period["W1"] = "1W";
    Period["M1"] = "1M";
    Period["M3"] = "3M";
    Period["M6"] = "6M";
    Period["Y1"] = "1Y";
})(Period || (Period = {}));
/**
 * Supports a set of either 5 or 6 periods.
 * 1D 1W 1M 3M 6M 1Y  or  1W 1M 3M 6M 1Y
 */
const PeriodSwitch = ({ periods, onSelect, defaultPeriod }) => {
    const [selected, setSelected] = useState(defaultPeriod);
    const { width } = useAppFrameDimensions();
    const horizontalPadding = periods.length === MAX_SUPPORTED_PERIODS_COUNT ? rem(-16) : rem(-32);
    const positions = useMemo(() => periods.map((p, i) => ({
        top: 0,
        left: horizontalPadding -
            rem(4) +
            ((width - horizontalPadding * 2) * (i + 1)) / (periods.length + 1) -
            PERIOD_WIDTH / 2,
    })), [horizontalPadding, periods, width]);
    const touchLock = useRef(new Date().getTime());
    const animatedOpacities = {
        [Period.Last]: useSharedValue(selected === Period.Last ? 1 : INACTIVE_OPACITY),
        [Period.D1]: useSharedValue(selected === Period.Last ? 1 : INACTIVE_OPACITY),
        [Period.W1]: useSharedValue(selected === Period.Last ? 1 : INACTIVE_OPACITY),
        [Period.M1]: useSharedValue(selected === Period.Last ? 1 : INACTIVE_OPACITY),
        [Period.M3]: useSharedValue(selected === Period.Last ? 1 : INACTIVE_OPACITY),
        [Period.M6]: useSharedValue(selected === Period.Last ? 1 : INACTIVE_OPACITY),
        [Period.Y1]: useSharedValue(selected === Period.Last ? 1 : INACTIVE_OPACITY),
    };
    const animatedLeft = useSharedValue(positions[periods.findIndex((p) => p === selected)].left);
    const animatedScaleX = useSharedValue(1);
    const selectedPeriodStyle = useAnimatedStyle(() => ({
        transform: [{ translateX: animatedLeft.value }, { scaleX: animatedScaleX.value }],
    }));
    const periodBackgroundStyles = {
        [Period.Last]: useAnimatedStyle(() => ({
            opacity: 1 - animatedOpacities[Period.Last].value,
        })),
        [Period.D1]: useAnimatedStyle(() => ({
            opacity: 1 - animatedOpacities[Period.D1].value,
        })),
        [Period.W1]: useAnimatedStyle(() => ({
            opacity: 1 - animatedOpacities[Period.W1].value,
        })),
        [Period.M1]: useAnimatedStyle(() => ({
            opacity: 1 - animatedOpacities[Period.M1].value,
        })),
        [Period.M3]: useAnimatedStyle(() => ({
            opacity: 1 - animatedOpacities[Period.M3].value,
        })),
        [Period.M6]: useAnimatedStyle(() => ({
            opacity: 1 - animatedOpacities[Period.M6].value,
        })),
        [Period.Y1]: useAnimatedStyle(() => ({
            opacity: 1 - animatedOpacities[Period.Y1].value,
        })),
    };
    const styles = useStyles(styleSet);
    return (<View style={styles.container}>
      <Animated.View style={[styles.selectedPeriodBackground, selectedPeriodStyle]}/>
      {periods.map((period, index) => (<TouchableBounce key={period} style={[styles.period, positions[index]]} onPress={() => {
                const now = new Date().getTime();
                if (now - touchLock.current > 0) {
                    touchLock.current = now;
                    ReactNativeHapticFeedback.trigger(Platform.OS === 'ios' ? 'selection' : 'clockTick');
                    onSelect(period);
                    setSelected(period);
                    animatedScaleX.value = withTiming(index === periods.length - 1 ? 1.2 : 1);
                    animatedLeft.value = withTiming(positions[index].left);
                    for (let i = 0; i < periods.length; i += 1) {
                        animatedOpacities[period].value = withTiming(period === periods[i] ? 1 : INACTIVE_OPACITY);
                    }
                }
            }} hitSlop={hitSlop}>
          <View style={styles.periodContainer}>
            <Text Text-12 White>
              {period}
            </Text>
            <Animated.View style={[styles.overlay, periodBackgroundStyles[period]]}>
              <View>
                <Text Text-12 White>
                  {period}
                </Text>
              </View>
            </Animated.View>
          </View>
        </TouchableBounce>))}
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        width: '100%',
        height: PERIOD_HEIGHT,
    },
    periodContainer: {
        width: PERIOD_WIDTH,
        height: PERIOD_HEIGHT,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: rem(8),
    },
    period: {
        position: 'absolute',
        borderRadius: rem(8),
    },
    selectedPeriodBackground: {
        position: 'absolute',
        width: PERIOD_WIDTH,
        height: PERIOD_HEIGHT,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        borderRadius: rem(8),
        backgroundColor: colors.background.transparent,
    },
    overlay: {
        ...StyleSheet.absoluteFillObject,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: rem(8),
    },
}));
export default PeriodSwitch;
