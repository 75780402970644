import React, { memo } from 'react';
import { Defs, LinearGradient, Stop, Svg } from 'react-native-svg';
import { useGradientColors } from 'hooks/useColors';
import { rem } from '../values';
export const Icon = memo(({ color, width = 24, height = 24, scale = 1, children, ...props }) => {
    const remWidth = rem(width * scale);
    const remHeight = rem(height * scale);
    return (<Svg viewBox="0 0 24 24" width={remWidth} height={remHeight} fill={color || 'none'} {...props}>
      {children}
    </Svg>);
});
/**
 * @param gradientKey Mandatory. Should always be set, but takes least priority
 * @param gradientColors Takes precedence
 * @param color Used only when `gradientColors` is omitted
 * @param colorKey Used when both `gradientColors` and `color` are not set. You can use it for setting up default color
 */
export const IconWithGradient = memo(({ id, color, colorKey, defaultGradientKey, gradientKey, gradientColors, width = 24, height = 24, scale = 1, children, ignoreRem, ...props }) => {
    const colors = useGradientColors(gradientKey ?? defaultGradientKey, gradientColors, color, colorKey);
    const remWidth = ignoreRem ? width * scale : rem(width * scale);
    const remHeight = ignoreRem ? height * scale : rem(height * scale);
    return (<Svg viewBox="0 0 24 24" width={remWidth} height={remHeight} fill="none" {...props}>
        {children}
        <Defs>
          <LinearGradient id={id ? `gradient_${id}` : 'a'} x1="12" y1="3" x2="12" y2="21" gradientUnits="userSpaceOnUse">
            <Stop stopColor={colors[0]}/>
            <Stop offset="1" stopColor={colors[1]}/>
          </LinearGradient>
        </Defs>
      </Svg>);
});
