import { useCallback } from 'react';
import { hideAccount, refreshApp } from 'actions';
import { muteAll, useFetchOld } from 'hooks';
import useRefreshAccounts from 'hooks/useRefreshAccounts';
import { useAppDispatch } from 'store/hooks';
export const useHideAccount = () => {
    const dispatch = useAppDispatch();
    const [, , , fetch] = useFetchOld(muteAll);
    const refreshAccounts = useRefreshAccounts();
    const close = useCallback((account) => {
        if (!account)
            return;
        const body = [
            {
                id: account.id,
                isHidden: !account.isHidden,
            },
        ];
        fetch(hideAccount(body)).then((response) => {
            if (response) {
                refreshAccounts(account.type);
                dispatch(refreshApp());
            }
        });
    }, [refreshAccounts]);
    return close;
};
