import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconInstagram = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="lightPurple">
      <Path d="M20.947 8.305a6.526 6.526 0 0 0-.42-2.216 4.609 4.609 0 0 0-2.632-2.633 6.605 6.605 0 0 0-2.186-.42c-.962-.043-1.267-.055-3.71-.055-2.441 0-2.754 0-3.71.055a6.606 6.606 0 0 0-2.184.42 4.607 4.607 0 0 0-2.633 2.633 6.554 6.554 0 0 0-.419 2.185c-.043.963-.056 1.268-.056 3.71s0 2.754.056 3.71c.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.583 6.583 0 0 0 2.185.45c.963.043 1.268.056 3.71.056s2.755 0 3.71-.056a6.59 6.59 0 0 0 2.186-.419 4.614 4.614 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.187.043-.962.056-1.267.056-3.71-.002-2.442-.002-2.752-.058-3.709Zm-8.953 8.297a4.622 4.622 0 0 1-4.623-4.623 4.622 4.622 0 1 1 4.623 4.623ZM16.8 8.263a1.076 1.076 0 0 1-.413-2.073 1.078 1.078 0 1 1 .413 2.073Z" fill={`url(#gradient_${id})`}/>
      <Path d="M11.994 14.982a3.003 3.003 0 1 0 0-6.006 3.003 3.003 0 0 0 0 6.006Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
