import React, { memo } from 'react';
import { Text, View } from 'react-native';
import { useAnimatedProps } from 'react-native-reanimated';
import { rem } from 'design-system';
import Legend from 'design-system/Legend';
import { styles as textStyles } from 'design-system/Text';
import { AnimatedTextInput } from 'design-system/AnimatedComponents';
import { selectTotalAssetBalance, selectTotalDebtBalance, } from 'reducers/selectors';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { useAppSelector } from 'store/hooks';
import createStyleSheets from 'utils/createStyleSheets';
import { abbreviateNumber, abbreviateNumberWorklet, } from 'features/invest/utils/format';
import { ChartType } from 'features/accounts/types';
import BlurAmountView from 'design-system/BlurAmountView';
import { isWeb } from '../../../../constants';
const AssetsVsDebtInfo = ({ title, type, selectedInfoObj, selectedInfoObjState, selectedInfoObj2, selectedInfoObj2State, blurValues, }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const totalDebtBalance = useAppSelector(selectTotalDebtBalance);
    const totalAssetsBalance = useAppSelector(selectTotalAssetBalance);
    const assetsProps = useAnimatedProps(() => ({
        text: selectedInfoObj.value
            ? abbreviateNumberWorklet(selectedInfoObj.value.actualY)
            : abbreviateNumberWorklet(totalAssetsBalance.amount),
    }), [totalAssetsBalance]);
    const debtProps = useAnimatedProps(() => ({
        text: selectedInfoObj2.value
            ? abbreviateNumberWorklet(selectedInfoObj2.value.actualY)
            : abbreviateNumberWorklet(totalDebtBalance.amount),
    }), [totalDebtBalance]);
    if (title === 'Net Worth - Main' && type === ChartType.assetVsDebt) {
        return (<View style={styles.container}>
        <View style={styles.infoContainer}>
          <Legend title="Assets" color={colors.elements.brand} containerStyles={styles.legendContainer} metrics={<BlurAmountView enabled={blurValues}>
                {isWeb ? (<Text numberOfLines={1} style={styles.metrics} allowFontScaling={false}>
                    {selectedInfoObjState
                        ? abbreviateNumber(selectedInfoObjState.actualY)
                        : abbreviateNumber(totalAssetsBalance.amount)}
                  </Text>) : (<AnimatedTextInput editable={false} numberOfLines={1} style={styles.metrics} allowFontScaling={false} animatedProps={assetsProps} defaultValue={abbreviateNumber(totalAssetsBalance.amount)}/>)}
              </BlurAmountView>}/>
          <Legend title="Debt" color={colors.elements.brandMedium} containerStyles={styles.legendContainer} metrics={<BlurAmountView enabled={blurValues}>
                {isWeb ? (<Text numberOfLines={1} style={styles.metrics} allowFontScaling={false}>
                    {selectedInfoObj2State
                        ? abbreviateNumber(selectedInfoObj2State.actualY)
                        : abbreviateNumber(totalDebtBalance.amount)}
                  </Text>) : (<AnimatedTextInput editable={false} numberOfLines={1} style={styles.metrics} allowFontScaling={false} animatedProps={debtProps} defaultValue={abbreviateNumber(totalDebtBalance.amount)}/>)}
              </BlurAmountView>}/>
        </View>
      </View>);
    }
    return <View style={styles.container}/>;
};
export default memo(AssetsVsDebtInfo);
const styleSet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
    },
    infoContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    metrics: {
        color: colors.text.primary,
        fontSize: textStyles.Numbers_18.fontSize,
        fontFamily: textStyles.Numbers_18.fontFamily,
    },
    legendContainer: {
        width: rem(90),
    },
}));
