import { GET_PROMOTIONS_SUCCESS, LOAD_PROMO_IAP_SUCCESS, PATCH_PROMOTION_SUCCESS, } from './actions';
const initialState = {
    promotions: [],
    iapSubscriptions: {},
};
export const promotionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROMOTIONS_SUCCESS:
            return {
                ...state,
                promotions: action.payload.promotions,
            };
        case PATCH_PROMOTION_SUCCESS:
            return {
                ...state,
                promotions: (() => {
                    const updatedPromotion = action.payload.promotion;
                    const currentPromotions = state.promotions;
                    const matchingIndex = currentPromotions.findIndex((p) => p.id === updatedPromotion.id);
                    if (matchingIndex !== -1) {
                        const newPromotions = [...currentPromotions];
                        newPromotions[matchingIndex] = {
                            ...currentPromotions[matchingIndex],
                            ...updatedPromotion,
                        };
                        return newPromotions;
                    }
                    return state.promotions;
                })(),
            };
        case LOAD_PROMO_IAP_SUCCESS:
            return {
                ...state,
                iapSubscriptions: (() => {
                    const newMap = {
                        ...state.iapSubscriptions,
                    };
                    action.payload.subscriptions.forEach((sub) => {
                        newMap[sub.id] = sub;
                    });
                    return newMap;
                })(),
            };
        default:
            return state;
    }
};
