import React, { memo, useMemo } from 'react';
import { TouchableOpacity, View } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import { useAppSelector } from 'store/hooks';
import Card from 'features/feed/components/Card';
import createStyleSheets from 'utils/createStyleSheets';
import { selectDefaultCurrency } from 'reducers/selectors';
import { sortSavingsAccounts } from 'utils/api/sortAccounts';
import { keyExtractor } from './util';
import RowAccount from '../RowAccount';
import RowEmmaWallet from '../RowEmmaWallet';
import RowPotAccount from '../RowPotAccount';
import AccountBalance from './AccountBalance';
import { isWeb } from '../../../../constants';
import RowEmmaInvestAccount from '../RowEmmaInvestAccount';
const AccountSection = ({ data, title, allowEmpty, balanceColor, onRowPress, onRowLongPress, onOpenLogins, onOpenUpgrade, reverse, firstCTA, secondCTA, onFirstCTAPress, onSecondCTAPress, forceShowBottomContent, }) => {
    const defaultCurrency = useAppSelector(selectDefaultCurrency);
    const sorted = useMemo(() => sortSavingsAccounts(data.array, reverse), [data.array, reverse]);
    const currency = useMemo(() => {
        const firstItem = data.array?.[0];
        if (firstItem) {
            if ('currency' in firstItem) {
                return firstItem.currency;
            }
            if (firstItem.balance?.currency) {
                return firstItem.balance.currency;
            }
        }
        return defaultCurrency;
    }, [data.array, defaultCurrency]);
    const styles = useStyles(styleSet);
    const titleBottomContent = useMemo(() => {
        if (isWeb) {
            return null;
        }
        if (!forceShowBottomContent) {
            if (sorted?.length) {
                return null;
            }
        }
        if (firstCTA && onFirstCTAPress) {
            const elements = [
                <TouchableOpacity key="firstCTA" onPress={onFirstCTAPress}>
          <Text TextThin-14 Primary>
            {firstCTA}
          </Text>
        </TouchableOpacity>,
            ];
            if (secondCTA && onSecondCTAPress) {
                elements.push(<Text key="or" TextThin-14 Gray>
            {' or '}
          </Text>);
                elements.push(<TouchableOpacity key="secondCTA" onPress={onSecondCTAPress}>
            <Text TextThin-14 Primary>
              {secondCTA}
            </Text>
          </TouchableOpacity>);
            }
            return <View style={styles.callToActionRow}>{elements}</View>;
        }
        return null;
    }, [
        sorted?.length,
        forceShowBottomContent,
        firstCTA,
        onFirstCTAPress,
        secondCTA,
        onSecondCTAPress,
        styles.callToActionRow,
    ]);
    if (!data.array || (data.array.length <= 0 && !allowEmpty)) {
        return null;
    }
    return (<Card style={styles.container}>
      <View style={[styles.headerContainer, !sorted.length && styles.hideBorder]}>
        <View style={styles.titleRow}>
          <Text Text-16 OffBlack>
            {title}
          </Text>
          <AccountBalance color={balanceColor} currency={currency} amount={data.total}/>
        </View>
        {titleBottomContent}
      </View>
      <View style={sorted?.length ? styles.webPadding : undefined}>
        {sorted.map((item) => {
            if ('currency' in item) {
                return (<RowAccount onCard data={item} style={styles.row} onPress={onRowPress} key={keyExtractor(item)} onOpenLogins={onOpenLogins} onOpenUpgrade={onOpenUpgrade} onRowLongPressed={onRowLongPress} showHidden/>);
            }
            if ('isEmmaWallet' in item) {
                return (<RowEmmaWallet onCard data={item} key={item.id} style={styles.row}/>);
            }
            if ('isEmmaInvestmentAccount' in item) {
                return (<RowEmmaInvestAccount onCard data={item} key={item.id} style={styles.row}/>);
            }
            return (<RowPotAccount key={item.id} data={item} style={styles.row} onCard/>);
        })}
      </View>
    </Card>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        marginHorizontal: rem(16),
        marginBottom: rem(16),
    },
    headerContainer: {
        padding: rem(16),
        borderBottomWidth: rem(1),
        borderBottomColor: colors.background.dark,
    },
    hideBorder: {
        borderBottomWidth: 0,
    },
    row: {
        paddingHorizontal: rem(16),
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
    titleRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    callToActionRow: {
        flexDirection: 'row',
    },
    webPadding: {
        ...(isWeb && {
            padding: rem(8),
        }),
    },
}));
export default memo(AccountSection);
