import { useEffect, useCallback } from 'react';
import Modal from 'utils/packages/Modal';
import { useAppDispatch, useAppSelector, useAppStore } from 'store/hooks';
import { selectErrorToDisplay } from 'reducers/selectors';
import { resetError, logout } from '../actions';
const showErrorModal = (errorToDisplay, onPress) => {
    const titleText = errorToDisplay.error || 'Error';
    const messageText = errorToDisplay.errorMessage || 'This is awkward, please try again.';
    Modal.showError(messageText, onPress, titleText, 'Okay', onPress);
};
const ErrorModal = () => {
    const store = useAppStore();
    const dispatch = useAppDispatch();
    const errorToDisplay = useAppSelector(selectErrorToDisplay);
    const onPress = useCallback((needLogout) => async () => {
        if (needLogout) {
            dispatch(logout());
        }
        dispatch(resetError());
    }, []);
    useEffect(() => {
        if (errorToDisplay) {
            if (errorToDisplay?.errorType === 'SessionExpired') {
                const { requestTimestamp } = errorToDisplay;
                if (requestTimestamp) {
                    /**
                     * This is a case for Session expired
                     * The logic below is there to avoid multiple 403 modals
                     */
                    const { lastLoggedInTimestamp, lastLoggedOutTimestamp } = store.getState().unpersistedUtils;
                    /**
                     * If we have lastLoggedOutTimestamp, it means that the user has already seen the 403 modal before
                     */
                    if (lastLoggedOutTimestamp) {
                        /**
                         * if lastLoggedInTimestamp is present, it means that the user has logged in and so we need to check
                         * if the requestTimestamp is actually greater than the lastLoggedInTimestamp
                         * If greater, it means that the 403 request is new so we set the SET_LAST_LOGGED_OUT_TIMESTAMP_ACTION and
                         * show 403 error modal again
                         */
                        if (lastLoggedInTimestamp &&
                            lastLoggedInTimestamp < Number(requestTimestamp)) {
                            dispatch({
                                type: 'SET_LAST_LOGGED_OUT_TIMESTAMP_ACTION',
                                timestamp: new Date().getTime(),
                            });
                            showErrorModal(errorToDisplay, onPress(true));
                        }
                        else {
                            dispatch(resetError());
                        }
                    }
                    else {
                        /**
                         * This is the case where the user is shown the 403 modal for the first time
                         */
                        dispatch({
                            type: 'SET_LAST_LOGGED_OUT_TIMESTAMP_ACTION',
                            timestamp: Number(requestTimestamp),
                        });
                        showErrorModal(errorToDisplay, onPress(true));
                    }
                }
                else {
                    dispatch(resetError());
                }
            }
            else {
                showErrorModal(errorToDisplay, onPress(false));
            }
        }
    }, [errorToDisplay, onPress]);
    return null;
};
export default ErrorModal;
