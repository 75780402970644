import React, { useMemo } from 'react';
import Section from 'design-system/Section';
import { CardView } from 'design-system/CardView';
import { useAppSelector } from 'store/hooks';
import { makeSelectTransactionById } from 'features/transactions/selectors';
import { styles } from './styles';
import RowTransaction from '../RowTransaction';
const TransactionOriginalTransactionSection = ({ transaction, }) => {
    const memoSelectTransactionByIdForSplit = useMemo(makeSelectTransactionById, []);
    const originalSplit = useAppSelector((store) => memoSelectTransactionByIdForSplit(store, 'splitFromId' in transaction ? transaction?.splitFromId : undefined));
    if (!originalSplit) {
        return null;
    }
    return (<Section title="Original Transaction" style={styles.section}>
      <CardView noMarginTop noMarginBottom webPadding>
        <RowTransaction disabled backgroundDark item={originalSplit}/>
      </CardView>
    </Section>);
};
export default TransactionOriginalTransactionSection;
