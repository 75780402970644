import React, { memo } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import IconCoinStack from 'features/subscriptions/icons/IconCoinStack';
import useEnergyCompare, { mapMerchantTypeToCompareType, } from 'features/feed/components/save/useEnergyCompare';
import { useAppSelector } from 'store/hooks';
import { selectFeatureFlag } from 'reducers/selectors';
import { isWeb } from '../../../../constants';
const SwitchingButton = ({ onOpenCompare, guessedHomeCountry, data, }) => {
    const compare = useEnergyCompare(onOpenCompare);
    const showEnergySwitch = useAppSelector((store) => selectFeatureFlag(store, 'energy_switch_two').value);
    if (isWeb) {
        return null;
    }
    const openCompare = async () => {
        const { merchantInfo } = data || {};
        const { type } = merchantInfo || {};
        if (type === 'GAS_AND_ELECTRICITY' || type === 'BROADBAND') {
            compare(mapMerchantTypeToCompareType(type));
        }
    };
    let label = null;
    if (!(data && data.merchantInfo))
        return null;
    const { merchantInfo } = data;
    const { type } = merchantInfo;
    if (guessedHomeCountry === 'GB') {
        if (showEnergySwitch && type === 'GAS_AND_ELECTRICITY')
            label = 'Save on Energy';
        if (type === 'BROADBAND')
            label = 'Save on Broadband';
    }
    if (!label)
        return null;
    return (<TouchableOpacity style={styles.button} onPress={openCompare}>
      <View style={styles.container}>
        <IconCoinStack style={styles.icon}/>
        <Text TextThin-14 Primary>
          {label}
        </Text>
      </View>
    </TouchableOpacity>);
};
const styles = StyleSheet.create({
    button: {
        marginTop: rem(2),
        alignSelf: 'flex-start',
    },
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        marginEnd: rem(8),
    },
});
export default memo(SwitchingButton);
