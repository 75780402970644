import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState, } from 'react';
import { View, } from 'react-native';
import getSymbolFromCurrency from 'currency-symbol-map';
import Button from 'design-system/Button';
import { IconCalendar, IconCoinStack, IconNote } from 'design-system/icons';
import StatusBar from 'design-system/StatusBar';
import Text from 'design-system/Text';
import ToolTip from 'design-system/ToolTip';
import { AppView } from 'design-system/AppView';
import { SettingsSection } from 'design-system/SettingsSection';
import { SettingsItemDescription, SettingsItemInputMask, SettingsItemNavigate, SettingsItemSubDescription, SettingsItemTitle, } from 'design-system/SettingsItem';
import { HelpCallToAction } from 'design-system/HelpCallToAction';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import { CardView } from 'design-system/CardView';
import { AnimatedKeyboardAwareScrollView } from 'design-system/AnimatedComponents';
import { incomeToPeriod, toFrequencyText, } from 'features/budgeting/utils';
import Alert from 'utils/packages/Alert';
import { selectIsScrambled, useTextInput } from 'hooks';
import Amplitude from 'utils/amplitude';
import { formatAmount } from 'utils/formatting';
import Links from 'utils/links';
import createStyleSheets from 'utils/createStyleSheets';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import { useBudgetRollover } from 'features/budgeting/hooks/useBudgetRollover';
import useAppRating from 'hooks/useAppRating';
import { useAppSelector, useAppStore } from 'store/hooks';
import { selectIsLoggedIn, selectDefaultCurrency } from 'reducers/selectors';
import HelpButton from 'login/components/HelpButton';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useAutoFocusInput } from 'hooks/useAutoFocusInput';
import useStyles from 'hooks/useStyles';
import usePrivateRoute from 'hooks/usePrivateRoute';
import { useRequiredBenefitCallbackWithSpaces } from 'features/premium/hooks/useRequiredBenefitCallback';
import { CommonActions } from '@react-navigation/native';
import TextInput from 'utils/packages/TextInput';
import { RollingBudgetsSwitchCard } from '../components/RollingBudgetsSwitchCard';
import { useLoadBudgetsAndIncomes } from '../hooks/useLoadBudgetsAndIncomes';
import { useSaveBudget } from '../hooks/useSaveBudget';
import { selectBudgets, selectIncomes } from '../selectors';
import { isWeb } from '../../../constants';
const strings = {
    ok: 'OK',
    headerTitle: 'Edit Budgeting',
    category: 'Category',
    categoryBudgets: 'Category budgets',
    setUpBudgetTitle: 'Welcome to budgeting!',
    setUpBudget: 'How much do you want to spend each month?',
    setUpBudgetDescription: 'This should include both your day to day spending and recurring payments.',
    categoryBudgetsDescription: 'Category budgets are useful to track your spending in each category. You can set these to control how much you spend on eating out, groceries, and any other category you have set in Emma.',
    choose: 'Choose',
    budgetingPeriod: 'Period',
    budgetingPeriodDescription: 'The period is the cycle you will use to sync your budgets and analytics. For example, if you get paid on the last working day of each month you can set your period to this so you can track your spending throughout your pay cycle.',
    rollingBudgets: 'Rolling Budgets',
    rollingBudgetsDescription: "Rolling budgets are a premium feature that makes every budget roll to the next budgeting period if they haven't been filled. This means if you haven't spent an amount in a cycle, it will be included in the next.",
    totalBudgets: 'Total budget',
    totalBudgetsDescription: 'Total Budget is how much you want to spend in a given budgeting period. This includes both your day-to-day spending and recurring payments. The total budget is usually greater or equal to the sum of all your category budgets.',
    totalBudgetErrorTitle: 'Total Budget Error!',
    totalBudgetErrorDescription: 'Your total budget can not be null, set it to start saving money. 🤑',
    confirmTitle: 'Confirm your budget',
    confirmDescription: 'You are ready to start saving in Emma, just make sure the details you have inputted are correct. You can change this at anytime.',
};
const EditBudgetScreen = withAnimatedNavigationHeader(({ navigation, route, onScrollY }) => {
    usePrivateRoute();
    const { keyBack, flow, editedMonthlyBudget, shouldRollover, budgetState: budgetStateFromRoute, shouldResetRollingAmount, period: periodFromRoute, } = route.params;
    const store = useAppStore();
    const styles = useStyles(styleSet);
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    useLayoutEffect(() => {
        if (!isLoggedIn) {
            navigation.setOptions({
                headerRight: () => <HelpButton black/>,
            });
        }
    }, [isLoggedIn]);
    const income = useAppSelector(selectIncomes);
    const budgets = useAppSelector(selectBudgets);
    const scrambled = useAppSelector(selectIsScrambled);
    const [period, setPeriod] = useState(periodFromRoute || {});
    const [budgetState, setBudgetState] = useState([]);
    const [IsFocused, setIsFocused] = React.useState(false);
    const handleEvent = useCallback((event) => {
        if (isWeb) {
            setIsFocused(event === 'focus');
        }
    }, []);
    const handleFocus = useCallback(() => {
        handleEvent('focus');
    }, [handleEvent]);
    const handleBlur = useCallback(() => {
        handleEvent('blur');
    }, [handleEvent]);
    useEffect(() => {
        if (periodFromRoute) {
            periodChanged.current = true;
            setPeriod(periodFromRoute);
        }
    }, [periodFromRoute]);
    useEffect(() => {
        // This is when the API returns
        if (budgets && flow !== 'Confirm') {
            setBudgetState(budgets);
        }
    }, [budgets]);
    useEffect(() => {
        if (budgetStateFromRoute !== undefined) {
            setBudgetState(budgetStateFromRoute);
        }
    }, [budgetStateFromRoute]);
    useLoadBudgetsAndIncomes(flow !== 'Confirm');
    useEffect(() => {
        if (flow !== 'Confirm') {
            setPeriod(incomeToPeriod(income));
        }
    }, [flow, income]);
    useEffect(() => {
        if (flow !== 'Confirm') {
            Amplitude.logEvent('Budgets.Start', {
                type: store.getState().user.isLoggedIn ? 'in_app' : 'onboarding',
            });
        }
    }, []);
    const monthly = useMemo(() => budgetState.find((budget) => budget.type === 'overall'), [budgetState]);
    const categoryBudgets = useMemo(() => budgetState.filter((budget) => budget.type === 'category' && budget.totalLimit), [budgetState]);
    let initialBudgetValue;
    if (flow === 'Set') {
        initialBudgetValue = '';
    }
    else {
        initialBudgetValue =
            editedMonthlyBudget
                ?.toString()
                .replace(/(\.\d*[1-9])0+$|\.0+$/, '$1') ??
                monthly?.totalLimit
                    ?.toString()
                    .replace(/(\.\d*[1-9])0+$|\.0+$/, '$1') ??
                '';
    }
    const [monthlyBudget, setMonthlyBudget, , monthlyBudgetRef] = useTextInput(initialBudgetValue);
    useEffect(() => {
        if (editedMonthlyBudget !== undefined) {
            setMonthlyBudget(editedMonthlyBudget.toString());
        }
    }, [editedMonthlyBudget, setMonthlyBudget]);
    useAutoFocusInput(flow === 'Set' ? monthlyBudgetRef : null);
    useBudgetRollover();
    const { isRollover, setRollover } = useBudgetRollover();
    useEffect(() => {
        if (shouldRollover !== undefined) {
            setRollover(shouldRollover);
        }
    }, [shouldRollover]);
    const onSetBudget = (value, rawValue) => {
        if (rawValue !== undefined) {
            setMonthlyBudget(rawValue);
        }
    };
    const onInput = useCallback((value) => {
        const sanitized = value.replace(/[^0-9.]/g, '');
        const parts = sanitized.split('.');
        let cleanValue = parts.length > 2
            ? `${parts[0]}.${parts.slice(1).join('')}`
            : sanitized;
        if (parts.length > 1 && parts[1].length > 2) {
            cleanValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
        }
        const cleanValueFinal = cleanValue
            .replace(/^0+(\d)/, '$1')
            .replace(/^0+$/, '');
        setMonthlyBudget(cleanValueFinal);
    }, [setMonthlyBudget]);
    const clearMonthlyBudget = () => {
        onSetBudget('0', '0');
    };
    const onScroll = useCallback(({ nativeEvent: { contentOffset }, }) => {
        onScrollY(contentOffset.y);
    }, [onScrollY]);
    const [saving, save] = useSaveBudget();
    const periodChanged = useRef(false);
    const wasKeyboardVisible = useRef(false);
    const onTooltipModalShown = useCallback(() => {
        if (monthlyBudgetRef.current?.isFocused()) {
            wasKeyboardVisible.current = true;
            monthlyBudgetRef.current?.blur();
        }
    }, [monthlyBudgetRef]);
    const onTooltipModalHidden = useCallback(() => {
        if (wasKeyboardVisible.current) {
            wasKeyboardVisible.current = false;
            monthlyBudgetRef.current?.focus();
        }
    }, [monthlyBudgetRef]);
    const onHelpPress = useCallback(() => {
        Links.openKnownLink('budgetingHelp');
    }, []);
    const validateTotalBudget = () => {
        if (monthlyBudget === '' || monthlyBudget == null) {
            Alert.alert(strings.totalBudgetErrorTitle, strings.totalBudgetErrorDescription, [
                {
                    onPress: () => {
                        monthlyBudgetRef.current?.getElement()?.focus();
                    },
                    text: strings.ok,
                },
            ]);
            return false;
        }
        return true;
    };
    const onPressSave = useRequiredBenefitCallbackWithSpaces(async () => {
        const result = await saveBudget({
            monthlyBudget: Number(monthlyBudget),
            budgetState,
            shouldRollover: isRollover,
            resetRollingAccumulated: shouldResetRollingAmount || false,
        });
        if (result) {
            if (flow === 'Confirm') {
                Amplitude.logEvent('BudgetsSetup.Finish');
                if (store.getState().user.isLoggedIn || isWeb) {
                    navigation.navigate('Success', {
                        title: 'Success!',
                        subTitle: 'Your budget has been set!',
                        onContinue: () => {
                            navigation.dispatch((state) => {
                                const routes = state.routes.filter((r) => ![
                                    'SelectPeriod',
                                    'EditBudgetFrequency',
                                    'SelectDate',
                                    'HolidaySelection',
                                    'Rollover',
                                    'EditBudget',
                                    'Budgets',
                                    'Success',
                                ].includes(r.name));
                                return CommonActions.reset({
                                    ...state,
                                    routes,
                                    index: routes.length - 1,
                                });
                            });
                            return true;
                        },
                    });
                }
                else {
                    const route = 'SetBudgetsSuccess';
                    navigation.navigate(route);
                }
            }
            else {
                navigation.goBack();
            }
        }
    }, 'budgeting');
    const rateApp = useAppRating();
    const saveBudget = async ({ monthlyBudget, budgetState, shouldRollover, resetRollingAccumulated, }) => {
        if (!validateTotalBudget()) {
            return false;
        }
        const res = await save(monthlyBudget, shouldRollover, budgetState, period && periodChanged.current ? period : undefined, resetRollingAccumulated);
        if (res === null)
            return false;
        const [budgetResult, incomeResult] = res;
        if (budgetResult !== null &&
            (period === undefined || incomeResult !== null)) {
            rateApp();
        }
        return true;
    };
    const onPressCategories = () => {
        navigation.navigate('Budgets', {
            monthlyBudget: Number(monthlyBudget),
            budgetState,
            shouldRollover: isRollover,
            period: route.params.period,
            flow,
        });
    };
    const onPressPeriod = () => {
        if (!validateTotalBudget()) {
            return;
        }
        const state = store.getState();
        if (state.deviceSettings.didSetPeriod && flow === 'Set') {
            navigation.navigate('Budgets', {
                period,
                monthlyBudget: Number(monthlyBudget),
                budgetState,
                flow,
            });
        }
        else {
            navigation.navigate('SelectPeriod', {
                keyBack,
                period,
                monthlyBudget: Number(monthlyBudget),
                budgetState,
                flow,
            });
        }
    };
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const defaultCurrency = useAppSelector(selectDefaultCurrency);
    const [totalFocused, setTotalFocused] = useState(false);
    const handleTotalFocus = useCallback(() => {
        setTotalFocused(true);
    }, []);
    const handleTotalBlur = useCallback(() => {
        setTotalFocused(false);
    }, []);
    const totalBudgetContent = (<SettingsItemInputMask noMask icon={<IconCoinStack gradientKey="plus"/>} value={monthlyBudget} ref={monthlyBudgetRef} onChangeText={onInput} maxLength={12} onFocus={handleTotalFocus} onBlur={handleTotalBlur} style={totalBudgetContainer} description={<View style={styles.textWithIconContainer}>
            <SettingsItemDescription>
              {strings.totalBudgets}
            </SettingsItemDescription>
            <View style={styles.infoIconContainer}>
              <ToolTip small offset={1} title={strings.totalBudgets} subtitle={strings.totalBudgetsDescription} onModalShown={onTooltipModalShown} onModalHidden={onTooltipModalHidden}/>
            </View>
          </View>} subDescription={monthly?.rollingAccumulatedLimit && monthly?.shouldRollover ? (<SettingsItemSubDescription>
              Rolling amount{' '}
              <Text Text-14 Gray>
                {formatAmount(monthly?.rollingAccumulatedLimit, monthly?.currency, 2, scrambled, true)}
              </Text>
            </SettingsItemSubDescription>) : (<SettingsItemSubDescription>
              3-months avg{' '}
              <Text Text-14 Gray>
                {formatAmount(monthly?.previousAverage, monthly?.currency, 2, scrambled, true)}
              </Text>
            </SettingsItemSubDescription>)} type="money" returnKeyLabel="Done" returnKeyType="done" includeRawValueInChangeText keyboardType="decimal-pad" onClearTextInput={clearMonthlyBudget} placeholder="0" prefix={getSymbolFromCurrency(monthly?.currency || defaultCurrency)}/>);
    return (<AppView withFrame={false}>
        <AnimatedKeyboardAwareScrollView scrollEventThrottle={8} onScroll={onScroll} keyboardShouldPersistTaps="always" contentContainerStyle={paddingHorizontalStyle}>
          <StatusBar barStyle="dark-content"/>
          {flow === 'Confirm' && (<View style={styles.setUpBudgetContainer}>
              <Text Text-24 style={styles.setUpBudgetText}>
                {strings.confirmTitle}
              </Text>
              <Text TextThin-14 Secondary>
                {strings.confirmDescription}
              </Text>
            </View>)}

          {flow === 'Set' && (<View style={styles.contentContainer}>
              <View style={styles.setUpBudgetContainer}>
                <Text Text-14 Secondary>
                  {strings.setUpBudgetTitle}
                </Text>
                <Text Text-24 style={styles.setUpBudgetText}>
                  {strings.setUpBudget}
                </Text>
                <Text TextThin-14 Secondary>
                  {strings.setUpBudgetDescription}
                </Text>
              </View>

              <View style={styles.inputWrapper}>
                <View style={[styles.inputContainer, IsFocused && styles.focused]}>
                  <View style={styles.inputLeftContainer}>
                    <Text Text-24 style={styles.inputTextLeft}>
                      {getSymbolFromCurrency(monthly?.currency || defaultCurrency)}
                    </Text>
                    <View style={styles.verticalSeparator}/>
                  </View>
                  <Text Numbers-24 style={styles.inputText} {...(monthlyBudget === '' || monthlyBudget == null
            ? { Secondary: true }
            : { OffBlack: true })} right>
                    {monthlyBudget === '' || monthlyBudget == null
                ? '0'
                : monthlyBudget}
                  </Text>
                  <TextInput ref={monthlyBudgetRef} style={styles.transparentTextInput} value={monthlyBudget} allowFontScaling={false} caretHidden onFocus={handleFocus} onBlur={handleBlur} contextMenuHidden enablesReturnKeyAutomatically maxLength={12} keyboardType="decimal-pad" returnKeyType="done" onChangeText={onInput}/>
                </View>

                <Text TextThin-14 Secondary centered>
                  {monthly?.rollingAccumulatedLimit && monthly?.shouldRollover
                ? `Rolling amount: ${formatAmount(monthly?.rollingAccumulatedLimit, monthly?.currency, 2, scrambled, true)}`
                : `3-months avg: ${formatAmount(monthly?.previousAverage, monthly?.currency, 2, scrambled, true)}`}
                </Text>
              </View>
            </View>)}
          {flow !== 'Set' && (<>
              {isWeb && (<CardView webPadding noMarginBottom selectable isSelected={totalFocused}>
                  {totalBudgetContent}
                </CardView>)}
              <SettingsSection webPadding>
                {!isWeb && totalBudgetContent}
                <SettingsItemNavigate icon={<IconNote gradientKey="lightPink"/>} onPress={onPressCategories}>
                  <View style={styles.textWithIconContainer}>
                    <SettingsItemDescription>
                      {strings.categoryBudgets}
                    </SettingsItemDescription>
                    <View style={styles.infoIconContainer}>
                      <ToolTip small offset={1} title={strings.categoryBudgets} subtitle={strings.categoryBudgetsDescription} onModalShown={onTooltipModalShown} onModalHidden={onTooltipModalHidden}/>
                    </View>
                  </View>
                  <SettingsItemTitle>
                    {categoryBudgets.length
                ? `${categoryBudgets.length} ${categoryBudgets.length === 1
                    ? 'category'
                    : 'categories'}`
                : strings.choose}
                  </SettingsItemTitle>
                </SettingsItemNavigate>

                <SettingsItemNavigate icon={<IconCalendar gradientKey="darkPurple"/>} onPress={onPressPeriod}>
                  <View style={styles.textWithIconContainer}>
                    <SettingsItemDescription>
                      {strings.budgetingPeriod}
                    </SettingsItemDescription>
                    <View style={styles.infoIconContainer}>
                      <ToolTip small offset={1} title={strings.budgetingPeriod} subtitle={strings.budgetingPeriodDescription} onModalShown={onTooltipModalShown} onModalHidden={onTooltipModalHidden}/>
                    </View>
                  </View>
                  <SettingsItemTitle>
                    {toFrequencyText(period, strings.choose)}
                  </SettingsItemTitle>
                </SettingsItemNavigate>
                <RollingBudgetsSwitchCard value={isRollover} onValueChange={setRollover} onModalShown={onTooltipModalShown} onModalHidden={onTooltipModalHidden}/>
              </SettingsSection>

              {isLoggedIn && (<>
                  <Spacer.H16 />
                  <HelpCallToAction onDark onPress={onHelpPress} subTitle="Learn how to do it" title="Need to amend your budgets?"/>
                </>)}
            </>)}
        </AnimatedKeyboardAwareScrollView>
        {flow === 'Set' && (<Button brand floating style={styles.button} preventDoubleTap title="Continue" disabled={monthlyBudget === '' || monthlyBudget == null} onPress={onPressPeriod}/>)}

        {flow === 'Edit' && (<Button brand floating title="Save" isFetching={saving} onPress={onPressSave}/>)}
        {flow === 'Confirm' && (<Button brand floating title="Continue" isFetching={saving} onPress={onPressSave}/>)}
      </AppView>);
}, ({ route }) => ({
    pageTitle: getPageTitle(route.params.flow),
    title: route.params.flow === 'Edit' ? strings.headerTitle : '',
}));
const getPageTitle = (flow) => {
    if (flow === 'Set') {
        return 'Set Budget';
    }
    if (flow === 'Confirm') {
        return 'Confirm Budget';
    }
    return 'Edit Budget';
};
const totalBudgetContainer = {
    ...(isWeb && {
        cursor: 'text',
    }),
};
const styleSet = createStyleSheets((colors) => ({
    setUpBudgetContainer: {
        paddingHorizontal: rem(16),
        paddingTop: rem(10),
        marginBottom: rem(16),
    },
    contentContainer: {
        flex: 1,
        justifyContent: 'space-between',
    },
    button: {
        bottom: 0,
    },
    setUpBudgetText: { marginBottom: rem(9) },
    textWithIconContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    infoIconContainer: {
        marginLeft: rem(10),
    },
    inputWrapper: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: rem(169),
        marginHorizontal: rem(16),
        marginTop: rem(16),
    },
    inputContainer: {
        backgroundColor: colors.cards.onDark,
        width: rem(270),
        borderRadius: rem(12),
        marginBottom: rem(13),
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        ...(isWeb && {
            borderWidth: 1,
            borderColor: colors.cards.onDark,
        }),
    },
    focused: {
        ...(isWeb && {
            borderWidth: 1,
            borderColor: colors.elements.brand,
        }),
    },
    inputLeftContainer: {
        flexDirection: 'row',
    },
    inputText: {
        paddingVertical: rem(11.5),
        paddingHorizontal: rem(16),
    },
    inputTextLeft: {
        paddingVertical: rem(11.5),
        paddingHorizontal: rem(20),
    },
    verticalSeparator: {
        backgroundColor: colors.background.dark,
        width: 1,
    },
    pinPad: {
        marginBottom: rem(16),
    },
    transparentTextInput: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        color: 'transparent',
        opacity: 0,
    },
}));
export default EditBudgetScreen;
