import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from './Icon';
const IconRepeat = ({ width = 24, height = 24, ...props }) => {
    const id = useId();
    return (<IconWithGradient id={id} width={width} height={height} defaultGradientKey="green" {...props}>
      {props.alt ? (<>
          <Path d="M5.19444 16.8181C5.0377 16.8181 4.88095 16.7603 4.7572 16.6366C3.62698 15.4981 3 13.9967 3 12.4044C3 9.09628 5.68118 6.40685 8.98109 6.40685L13.9887 6.42335L13.0895 5.56537C12.842 5.32613 12.8337 4.93839 13.073 4.69089C13.3122 4.4434 13.7 4.43515 13.9475 4.67439L15.9604 6.60484C16.1419 6.77809 16.1997 7.05033 16.1089 7.28133C16.0182 7.51232 15.7872 7.66907 15.5314 7.66907L8.98109 7.65257C6.36591 7.65257 4.23747 9.78926 4.23747 12.4127C4.23747 13.6749 4.73245 14.8711 5.63168 15.7704C5.87092 16.0096 5.87092 16.4056 5.63168 16.6448C5.50793 16.7603 5.35119 16.8181 5.19444 16.8181Z" fill={`url(#gradient_${id})`}/>
          <Path d="M10.2186 20.5868C10.0618 20.5868 9.91333 20.529 9.78959 20.4135L7.77664 18.4831C7.59514 18.3098 7.53739 18.0376 7.62814 17.8066C7.71889 17.5756 7.94988 17.4189 8.20563 17.4189L14.7642 17.4354C17.3794 17.4354 19.5078 15.2987 19.5078 12.6752C19.5078 11.413 19.0128 10.2168 18.1136 9.31758C17.8744 9.07834 17.8744 8.68235 18.1136 8.4431C18.3529 8.20386 18.7489 8.20386 18.9881 8.4431C20.1183 9.58157 20.7453 11.083 20.7453 12.6752C20.7453 15.9834 18.0641 18.6728 14.7642 18.6728L9.75659 18.6563L10.6558 19.5143C10.9033 19.7536 10.9116 20.1413 10.6723 20.3888C10.5403 20.5208 10.3836 20.5868 10.2186 20.5868Z" fill={`url(#gradient_${id})`}/>
          <Path d="M9.39378 15.4311H14.3437C15.9276 15.4311 17.2311 14.1359 17.2311 12.5437C17.2311 10.9597 15.9359 9.65625 14.3437 9.65625H9.39378C7.80982 9.65625 6.50636 10.9515 6.50636 12.5437C6.50636 14.1359 7.80157 15.4311 9.39378 15.4311Z" fill={`url(#gradient_${id})`}/>
        </>) : (<Path d="M16.4319 8.29387C15.3605 7.2906 13.97 6.74063 12.4948 6.74194C9.83998 6.74427 7.54806 8.56457 6.91526 11.0896C6.8692 11.2734 6.70543 11.4032 6.51593 11.4032H4.55192C4.29493 11.4032 4.0997 11.1699 4.14724 10.9174C4.88883 6.97925 8.34641 4 12.5 4C14.7775 4 16.8457 4.89579 18.3717 6.35412L19.5958 5.13002C20.114 4.61183 21 4.97884 21 5.71169V10.3065C21 10.7608 20.6317 11.129 20.1774 11.129H15.5827C14.8498 11.129 14.4828 10.243 15.001 9.72478L16.4319 8.29387ZM4.82258 13.871H9.41735C10.1502 13.871 10.5172 14.757 9.99901 15.2752L8.56806 16.7062C9.63954 17.7095 11.0302 18.2594 12.5053 18.2581C15.1588 18.2557 17.4516 16.4366 18.0847 13.9105C18.1308 13.7266 18.2946 13.5968 18.4841 13.5968H20.4481C20.7051 13.5968 20.9003 13.8301 20.8528 14.0827C20.1112 18.0208 16.6536 21 12.5 21C10.2225 21 8.15434 20.1042 6.62832 18.6459L5.40421 19.87C4.88602 20.3882 4 20.0212 4 19.2883V14.6935C4 14.2392 4.36828 13.871 4.82258 13.871Z" fill={`url(#gradient_${id})`}/>)}
    </IconWithGradient>);
};
export default IconRepeat;
