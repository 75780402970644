import React, { useState } from 'react';
import { StyleSheet, } from 'react-native';
import useColors from 'hooks/useColors';
import { CardView } from './CardView';
import { rem } from './values';
import { SettingsItemInput, SettingsItemInputMask } from './SettingsItem';
import Text from './Text';
const SettingsItemSelectable = ({ children, errorText, }) => {
    const [isSelected, setIsSelected] = useState(false);
    const isTextInput = React.isValidElement(children)
        ? children?.type.name === SettingsItemInput.name ||
            children?.type.name === SettingsItemInputMask.name
        : false;
    const clonedChildren = React.isValidElement(children) && children
        ? React.cloneElement(children, {
            onBlur: (e) => {
                setIsSelected(false);
                children.props.onBlur?.(e);
            },
            onFocus: (e) => {
                setIsSelected(true);
                children.props.onFocus?.(e);
            },
            style: {
                cursor: isTextInput ? 'text' : undefined,
                margin: 0,
                paddingVertical: rem(16),
                paddingHorizontal: rem(8),
            },
        })
        : null;
    const colors = useColors();
    return (<>
      <CardView webPadding isSelected={isSelected} selectable noMarginTop noMarginBottom={!!errorText} hasError={!!errorText}>
        {clonedChildren}
      </CardView>
      {!!errorText && (<Text TextThin-16 color={colors.text.negative} style={styles.errorText}>
          {errorText}
        </Text>)}
    </>);
};
const styles = StyleSheet.create({
    errorText: {
        marginTop: rem(16),
        marginBottom: rem(8),
        marginRight: rem(16),
        textAlign: 'right',
    },
});
export default SettingsItemSelectable;
