import Config from 'utils/packages/configs';
const CRISP_URL = `https://go.crisp.chat/chat/embed/?website_id=${Config.CRISP_ID}`;
const getSupportUrl = (user) => {
    const tier = user?.emmaProStatus?.tier;
    const id = user ? user.id : null;
    const email = user ? user.email : null;
    const firstname = user ? user.firstName : null;
    const lastname = user ? user.lastName : null;
    const proStatus = tier ? `-(${tier})` : '';
    const crisp_token = user && Object.prototype.hasOwnProperty.call(user, 'crispTokenId')
        ? `&token_id=${encodeURIComponent(user.crispTokenId)}`
        : '';
    const nickName = `${id}${proStatus}-${firstname}-${lastname}`;
    const components = `&user_nickname=${encodeURIComponent(nickName)}&user_email=${encodeURIComponent(email || '')}${crisp_token}`;
    return {
        url: CRISP_URL + components,
        nickName,
    };
};
export default getSupportUrl;
