import { useState, useEffect } from 'react';
import { Dimensions } from 'react-native';
// A hook to return the current screen dimensions
function useScreenDimensions() {
    // Get initial dimensions and initialize state
    const initialDimensions = Dimensions.get('screen');
    const [state, setState] = useState(initialDimensions);
    useEffect(() => {
        const handleChange = ({ screen }) => {
            setState(screen);
        };
        // Listen for dimension changes, which typically indicates a rotation
        const listener = Dimensions.addEventListener('change', handleChange);
        // Cleanup
        return () => {
            listener.remove();
        };
    });
    return state;
}
export default useScreenDimensions;
