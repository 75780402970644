import React from 'react';
import { Path } from 'react-native-svg';
import { Icon } from 'design-system/icons/Icon';
import useColors from 'hooks/useColors';
export const IconCreditCard = ({ ...props }) => {
    const colors = useColors();
    return (<Icon {...props}>
      <Path d="M20.8652 5.00037H3.86234C2.83553 5.00037 2.00012 5.83577 2.00012 6.86258V17.3882C2.00012 18.415 2.83553 19.2504 3.86234 19.2504H20.8652C21.892 19.2504 22.7274 18.415 22.7274 17.3882V6.86258C22.7274 5.83577 21.892 5.00037 20.8652 5.00037ZM8.4774 10.668C8.4774 11.4716 7.82364 12.1254 7.02001 12.1254H6.04842C5.24479 12.1254 4.59103 11.4716 4.59103 10.668V9.69639C4.59103 8.89276 5.24479 8.239 6.04842 8.239H7.02001C7.82364 8.239 8.4774 8.89276 8.4774 9.69639V10.668ZM16.8047 13.9758C16.6643 13.9758 16.5229 13.9304 16.4041 13.8368C16.1232 13.6153 16.075 13.208 16.2964 12.9271C16.478 12.6968 16.574 12.4196 16.574 12.1254C16.574 11.8312 16.478 11.5539 16.2965 11.3236C16.075 11.0427 16.1232 10.6354 16.4042 10.4139C16.6851 10.1925 17.0924 10.2406 17.3139 10.5216C17.6721 10.9761 17.8694 11.5457 17.8694 12.1254C17.8694 12.705 17.6721 13.2746 17.3138 13.7291C17.186 13.8912 16.9963 13.9758 16.8047 13.9758ZM18.8952 15.3687C18.7673 15.5112 18.5905 15.5838 18.4129 15.5838C18.2588 15.5838 18.1042 15.5292 17.9805 15.4182C17.7143 15.1793 17.6921 14.7697 17.931 14.5035C18.5179 13.8495 18.841 13.005 18.841 12.1254C18.841 11.2458 18.5179 10.4012 17.931 9.74724C17.6921 9.48098 17.7143 9.07145 17.9805 8.83256C18.2467 8.59367 18.6562 8.61578 18.8952 8.88203C19.6956 9.77404 20.1365 10.9259 20.1365 12.1254C20.1365 13.3248 19.6956 14.4767 18.8952 15.3687Z" fill={props.color || colors.elements.brand}/>
    </Icon>);
};
export default IconCreditCard;
