import { useEffect, useRef, useState } from 'react';
import { AppState } from 'react-native';
export const useAppState = (callbacks) => {
    const appStateRef = useRef('active');
    const [appState, setAppState] = useState('active');
    useEffect(() => {
        const listener = (nextState) => {
            const appState = appStateRef.current;
            const didBecomeActive = appState === 'background' && nextState === 'active';
            const didBecomeInactive = nextState === 'background' || nextState === 'inactive';
            const didBecomeActiveAfterInactive = appState === 'inactive' && nextState === 'active';
            if (didBecomeActive && callbacks && callbacks.didBecomeActive) {
                callbacks.didBecomeActive();
            }
            else if (didBecomeInactive &&
                callbacks &&
                callbacks.didBecomeInactive) {
                callbacks.didBecomeInactive();
            }
            else if (didBecomeActiveAfterInactive &&
                callbacks &&
                callbacks.didBecomeActiveAfterInactive) {
                callbacks.didBecomeActiveAfterInactive();
            }
            if (callbacks && callbacks.appStateDidChange) {
                callbacks.appStateDidChange(nextState);
            }
            if (appState !== nextState) {
                appStateRef.current = nextState;
                setAppState(nextState);
            }
        };
        const appStateSubsription = AppState.addEventListener('change', listener);
        return () => {
            appStateSubsription.remove();
        };
    }, [callbacks]);
    return appState;
};
export default useAppState;
