import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import Logo from 'design-system/Logo';
import Spacer from 'design-system/Spacer';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import { isWeb } from '../../../constants';
const ROW_ITEM_VERTICAL_PADDING = 16;
const ROW_ITEM_HORIZONTAL_PADDING = 16;
export const MERCHANT_ITEM_HEIGHT = rem(ROW_ITEM_VERTICAL_PADDING * 2 + 44);
const MerchantItem = ({ data }) => (<View style={styles.container}>
    <Logo R44 uri={data.iconUrl} resizeMode="contain"/>
    <Spacer w={16}/>
    <View style={styles.flex}>
      <View style={styles.row}>
        <Text Text-16 flex style={styles.merchantName} numberOfLines={1}>
          {data.displayName}
        </Text>
      </View>
    </View>
  </View>);
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        ...(isWeb
            ? {
                paddingVertical: rem(16),
                paddingHorizontal: rem(8),
            }
            : {
                paddingHorizontal: rem(ROW_ITEM_HORIZONTAL_PADDING),
                paddingVertical: rem(ROW_ITEM_VERTICAL_PADDING),
            }),
    },
    merchantName: {
        marginRight: rem(16),
    },
    flex: {
        flex: 1,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
});
export default memo(MerchantItem);
