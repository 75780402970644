import React from 'react';
import { View } from 'react-native';
import { CARD_VIEW_BORDER_RADIUS } from 'design-system/CardView';
import IconPieChart from 'design-system/icons/IconPieChart';
import IconPlus from 'design-system/icons/IconPlus';
import { rem } from 'design-system/values';
import { BudgetActionRow } from 'features/budgeting/components/BudgetActionRow';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableBounce from 'utils/packages/TouchableBounce';
import { isWeb } from '../../../constants';
const strings = {
    title: 'Budget by merchant',
};
const MerchantBudgetEmptyRow = ({ onPress }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    return (<TouchableBounce onPress={onPress} style={styles.container} hoverStyle={styles.hoverOnDark}>
      <BudgetActionRow title={strings.title} icon={<IconPieChart gradientKey="ultimate" alt/>} rightContent={<View style={styles.iconPlusContainer}>
            <IconPlus color={colors.text.textOnBrand}/>
          </View>}/>
    </TouchableBounce>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: colors.cards.onDark,
        borderRadius: CARD_VIEW_BORDER_RADIUS,
        marginHorizontal: rem(16),
        marginBottom: rem(16),
        ...(isWeb && {
            padding: rem(8),
        }),
        flex: 1,
    },
    iconPlusContainer: {
        width: rem(32),
        height: rem(32),
        borderRadius: rem(8),
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.buttons.brandLight,
    },
    hoverOnDark: {
        backgroundColor: colors.control.hoverOnDark,
    },
}));
export default MerchantBudgetEmptyRow;
