import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { rem } from 'design-system/values';
import RadioButton from 'design-system/RadioButton';
const EditTransactionSelect = ({ style, selected, isMultipleSelect = true, }) => (<View style={[styles.container, style]}>
    <RadioButton isChecked={selected} type={isMultipleSelect ? 'multiple' : 'single'}/>
  </View>);
const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: rem(16),
    },
});
export default memo(EditTransactionSelect);
