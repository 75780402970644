import React, { useId } from 'react';
import { G, Path, Defs, Rect, ClipPath } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconMine = ({ ...props }) => {
    const id = useId();
    return (<IconWithGradient id={id} defaultGradientKey="apricot" {...props}>
      <G clipPath="url(#clip0_415_3661)">
        <Path d="M4.4696 6.31022H4.82506V6.66568C4.82506 6.98927 5.0874 7.25161 5.41099 7.25161C5.73458 7.25161 5.99692 6.98927 5.99692 6.66568V6.31022H6.35239C6.67598 6.31022 6.93832 6.04788 6.93832 5.72429C6.93832 5.4007 6.67598 5.13836 6.35239 5.13836H5.99692V4.78289C5.99692 4.4593 5.73458 4.19696 5.41099 4.19696C5.0874 4.19696 4.82506 4.4593 4.82506 4.78289V5.13836H4.4696C4.14601 5.13836 3.88367 5.4007 3.88367 5.72429C3.88367 6.04788 4.14601 6.31022 4.4696 6.31022Z" fill={`url(#gradient_${id})`}/>
        <Path d="M17.6481 6.31022H18.0036V6.66568C18.0036 6.98927 18.2659 7.25161 18.5895 7.25161C18.9131 7.25161 19.1755 6.98927 19.1755 6.66568V6.31022H19.5309C19.8545 6.31022 20.1168 6.04788 20.1168 5.72429C20.1168 5.4007 19.8545 5.13836 19.5309 5.13836H19.1755V4.78289C19.1755 4.4593 18.9131 4.19696 18.5895 4.19696C18.2659 4.19696 18.0036 4.4593 18.0036 4.78289V5.13836H17.6481C17.3245 5.13836 17.0622 5.4007 17.0622 5.72429C17.0622 6.04788 17.3245 6.31022 17.6481 6.31022Z" fill={`url(#gradient_${id})`}/>
        <Path d="M10.7451 4.42765H11.4144V5.0969C11.4144 5.42049 11.6767 5.68283 12.0003 5.68283C12.3239 5.68283 12.5862 5.42049 12.5862 5.0969V4.42765H13.2555C13.5791 4.42765 13.8414 4.16531 13.8414 3.84172C13.8414 3.51813 13.5791 3.25579 13.2555 3.25579H12.5862V2.58654C12.5862 2.26295 12.3239 2.00061 12.0003 2.00061C11.6767 2.00061 11.4144 2.26295 11.4144 2.58654V3.25579H10.7451C10.4215 3.25579 10.1592 3.51813 10.1592 3.84172C10.1592 4.16531 10.4215 4.42765 10.7451 4.42765Z" fill={`url(#gradient_${id})`}/>
        <Path d="M15.1378 22.0003C16.1546 22.0003 16.9789 21.176 16.9789 20.1592C16.9789 19.1424 16.1546 18.3181 15.1378 18.3181C14.121 18.3181 13.2967 19.1424 13.2967 20.1592C13.2967 21.176 14.121 22.0003 15.1378 22.0003Z" fill={`url(#gradient_${id})`}/>
        <Path d="M18.6025 10.2432L16.808 8.44867C16.6981 8.33879 16.5491 8.27703 16.3937 8.27703H15.1385C14.9831 8.27703 14.8341 8.33879 14.7242 8.44867L14.5109 8.66195L13.0425 7.19349C12.9326 7.08361 12.7836 7.02185 12.6282 7.02185H11.373C11.2175 7.02185 11.0685 7.08361 10.9587 7.19349L9.49021 8.66195L9.27693 8.44867C9.16705 8.33879 9.01802 8.27703 8.86264 8.27703H7.60746C7.45203 8.27703 7.30305 8.33879 7.19316 8.44867L5.3985 10.2432H18.6025Z" fill={`url(#gradient_${id})`}/>
        <Path d="M20.639 11.6643C20.5294 11.5077 20.3502 11.4145 20.1591 11.4145H3.84155C3.65042 11.4145 3.47128 11.5077 3.36163 11.6643C3.25198 11.8209 3.22558 12.021 3.29089 12.2007L5.80129 19.1042C5.83531 19.1978 5.89179 19.2784 5.96297 19.3416C6.32027 18.0764 7.48431 17.1462 8.86231 17.1462C10.2936 17.1462 11.4941 18.1497 11.7994 19.4899H12.2011C12.5064 18.1497 13.707 17.1462 15.1382 17.1462C16.5162 17.1462 17.6803 18.0764 18.0376 19.3416C18.1088 19.2784 18.1652 19.1978 18.1993 19.1042L20.7097 12.2007C20.7751 12.021 20.7486 11.8208 20.639 11.6643Z" fill={`url(#gradient_${id})`}/>
        <Path d="M8.86211 22.0003C9.87892 22.0003 10.7032 21.176 10.7032 20.1592C10.7032 19.1424 9.87892 18.3181 8.86211 18.3181C7.84529 18.3181 7.021 19.1424 7.021 20.1592C7.021 21.176 7.84529 22.0003 8.86211 22.0003Z" fill={`url(#gradient_${id})`}/>
      </G>
      <Defs>
        <ClipPath id="clip0_415_3661">
          <Rect fill="white" width="19.9998" height="19.9998" transform="translate(2 2)"/>
        </ClipPath>
      </Defs>
    </IconWithGradient>);
};
