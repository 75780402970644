import React from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import Amount from 'design-system/Amount';
import { rem } from 'design-system/values';
import IconCircle from 'design-system/icons/IconCircle';
import useColors from 'hooks/useColors';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import IconTransactionCategory from 'features/transactions/icons/IconTransactionCategory';
import { isWeb } from '../../../constants';
const UnbudgetedSpendRow = ({ onPress, category }) => {
    const colors = useColors();
    return (<TouchableHighlight onCard hoverOnLight onPress={onPress} style={styles.container} underlayColor={colors.background.underlay}>
      <View style={styles.content}>
        <IconCircle width={44} height={44}>
          <IconTransactionCategory category={category}/>
        </IconCircle>
        <View style={styles.innerContent}>
          <View>
            <Text Text-16 style={styles.displayName}>
              {category.displayName}
            </Text>
            <Text TextThin-14 Gray>
              {category.transactionsCount}
              {category.transactionsCount === 1
            ? ' transaction'
            : ' transactions'}
            </Text>
          </View>
          <Amount Numbers-16 value={{
            value: category.total,
            currency: category.currency,
        }}/>
        </View>
      </View>
    </TouchableHighlight>);
};
const styles = StyleSheet.create({
    container: {
        padding: rem(16),
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
    content: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    innerContent: {
        flex: 1,
        marginStart: rem(16),
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    displayName: {
        marginBottom: rem(4),
        flexShrink: 1,
        paddingEnd: rem(12),
    },
});
export default UnbudgetedSpendRow;
