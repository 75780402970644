import React, { memo, useCallback, useEffect, useLayoutEffect, useMemo, } from 'react';
import { View, StyleSheet } from 'react-native';
import Button from 'design-system/Button';
import { useDisableAndroidBackButton } from 'hooks';
import SuccessView from 'design-system/SuccessView';
import EmptyComponent from 'design-system/EmptyComponent';
import { goBackWithKey } from 'utils/navigationv6';
import useColors from 'hooks/useColors';
import StatusBar from 'design-system/StatusBar';
import useRateAppAfterTransactionEdit from '../hooks/useRateAppAfterTransactionEdit';
const EditCompleteScreen = memo(({ navigation, route }) => {
    const colors = useColors();
    useLayoutEffect(() => {
        navigation.setOptions({
            gestureEnabled: false,
            headerLeft: EmptyComponent,
            headerTintColor: colors.elements.primary,
        });
    }, [colors.elements.primary]);
    const goBack = useCallback(() => {
        goBackWithKey(navigation, route.params.keyBack);
    }, [route.params.keyBack]);
    useDisableAndroidBackButton(() => {
        goBack();
        return true;
    });
    const rateApp = useRateAppAfterTransactionEdit();
    useEffect(() => {
        rateApp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { transactionsCount } = route.params;
    const text = useMemo(() => {
        const { editData } = route.params;
        switch (editData.editType) {
            case 'rename':
                const { customName } = editData;
                return `${transactionsCount > 1 ? 'have' : 'has'} been renamed as ${customName}.`;
            case 'categorise':
                const { category } = editData;
                return `${transactionsCount > 1 ? 'are' : 'is'} now categorised as ${category?.displayName}.`;
            case 'tags':
                return `${transactionsCount > 1 ? 'have' : 'has'} been tagged.`;
            default:
                return '';
        }
    }, [route.params, transactionsCount]);
    return (<View style={styles.container}>
        <StatusBar barStyle="dark-content" animated/>
        <SuccessView title={`${transactionsCount} ${transactionsCount > 1 ? 'Transactions' : 'Transaction'}`} subTitle={text}>
          <Button brand floating title="Perfect, let's go back!" onPress={goBack} testID="backButton"/>
        </SuccessView>
      </View>);
});
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
});
export default EditCompleteScreen;
