import React, { memo, useCallback, useMemo } from 'react';
import { View, TouchableOpacity, StyleSheet, } from 'react-native';
import Separator from 'design-system/Separator';
import { CardView } from 'design-system/CardView';
import { rem } from 'design-system/values';
import Text from 'design-system/Text';
import useEnergyCompare, { mapMerchantTypeToCompareType, } from 'features/feed/components/save/useEnergyCompare';
import { useAppSelector } from 'store/hooks';
import { selectFeatureFlag, selectIsGBUser } from 'reducers/selectors';
import TouchableBounce from 'utils/packages/TouchableBounce';
import { isWeb } from '../../../../constants';
const SubscriptionAd = ({ onCard, merchant, onPress, style, }) => {
    const isGBUser = useAppSelector(selectIsGBUser);
    const openCompare = useEnergyCompare(onPress);
    const showEnergySwitch = useAppSelector((store) => selectFeatureFlag(store, 'energy_switch_two').value);
    const pressed = useCallback(() => {
        if (!merchant)
            return;
        const { type } = merchant;
        openCompare(mapMerchantTypeToCompareType(type));
    }, [merchant, openCompare]);
    const content = useMemo(() => {
        if (!merchant)
            return null;
        let title = null;
        let subtitle = null;
        const { type } = merchant;
        if (isGBUser) {
            if ((showEnergySwitch && type === 'GAS_AND_ELECTRICITY') ||
                type === 'BROADBAND') {
                title = 'Switch this provider and save £100s';
                subtitle = 'Get a quote with MoneySuperMarket!';
            }
        }
        if (!title || isWeb)
            return null;
        const info = (<View>
        <Text Text-16>{title}</Text>
        <Text TextThin-14 Primary>
          {subtitle}
        </Text>
      </View>);
        if (onCard) {
            return (<TouchableBounce onPress={pressed}>
          <CardView noMarginBottom withPadding>
            {info}
          </CardView>
        </TouchableBounce>);
        }
        return (<View style={style}>
        <TouchableOpacity onPress={pressed} style={styles.container}>
          {info}
        </TouchableOpacity>
        <Separator style={styles.separator}/>
      </View>);
    }, [merchant, onCard, pressed, showEnergySwitch, style, isGBUser]);
    if (!merchant)
        return null;
    return content;
};
const styles = StyleSheet.create({
    container: {
        marginBottom: rem(16),
        paddingHorizontal: rem(16),
    },
    separator: {
        marginHorizontal: rem(16),
    },
});
export default memo(SubscriptionAd);
