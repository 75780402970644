import React from 'react';
import { StyleSheet, View } from 'react-native';
import { ButtonGroup, rem } from 'design-system';
import Button from 'design-system/Button';
import Confetti from 'design-system/Confetti';
import { IconSquareCircle, IconTrash } from 'design-system/icons';
import Modal from 'design-system/Modal';
import Spacer from 'design-system/Spacer';
import Text from 'design-system/Text';
import useColors from 'hooks/useColors';
const strings = {
    areYouSure: 'Are you sure you want to delete?',
    yes: 'Yes, let me delete',
    no: 'Not really, I am joking',
};
export const DeleteModal = {
    show: (props) => {
        Modal.show(<DeleteModalContent {...props}/>, {
            containerStyle: styles.modal,
        });
    },
    hide: () => {
        Modal.hide();
    },
};
const DeleteModalContent = ({ onPressDelete, onPressNotDelete, }) => {
    const colors = useColors();
    const onNotDelete = () => {
        onPressNotDelete?.();
        Confetti.startConfetti();
        Modal.hide();
    };
    const onDelete = () => {
        onPressDelete();
        Modal.hide();
    };
    return (<>
      <View style={styles.textContainer}>
        <IconSquareCircle scale={2.9} color={colors.background.dark}>
          <IconTrash scale={2} gradientColors={colors.gradients.ultimate.colors}/>
        </IconSquareCircle>
        <Spacer h={rem(24)}/>
        <Text Text-20 centered>
          {strings.areYouSure}
        </Text>
      </View>
      <ButtonGroup>
        <Button brandReversed title="Yes, let me delete" onPress={onDelete}/>
        <Button brand title="Not really, I’m joking" onPress={onNotDelete}/>
      </ButtonGroup>
    </>);
};
const styles = StyleSheet.create({
    textContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: rem(8),
        maxWidth: rem(240),
        alignSelf: 'center',
    },
    modal: {
        paddingHorizontal: 0,
        paddingTop: rem(48),
    },
});
export default DeleteModal;
