import React, { memo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import Animated, { runOnJS, useDerivedValue, } from 'react-native-reanimated';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import ToolTip from 'design-system/ToolTip';
import { CardView } from 'design-system/CardView';
import UpgradeButton from 'design-system/UpgradeButton';
import BlurAmountView from 'design-system/BlurAmountView';
import Amount from 'design-system/Amount';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import useSelectedCategory from '../hooks/useSelectedCategory';
import { ChartType, chartTypeToMinHeight } from '../types';
import BarChart from './chart/BarChart';
import CategoryPieChart from './chart/CategoryPieChart';
import LinearChart from './chart/LinearChart';
import ChartViewSwitcher from './ChartViewSwitcher';
const SavingsChip = ({ name, toolTip, amount, blurValue, }) => {
    const chipStyles = useStyles(styleSet);
    return (<Animated.View style={chipStyles.savingsChip}>
      <Text Secondary TextThin-14 numberOfLines={1} style={[!!toolTip && styles.savingsName, chipStyles.chipTitleStyles]}>
        {name}
      </Text>
      {toolTip}
      <BlurAmountView enabled={blurValue} containerStyle={styles.savingsAmount}>
        <Amount showFraction={false} Numbers-16 showSign value={amount}/>
      </BlurAmountView>
    </Animated.View>);
};
const summaryValueToolTip = (<ToolTip title="Summary value" subtitle="This is your total income minus your spending for the period you have selected. The default is calendar months. This value can also help you understand if you are saving or spending more than you make." small offset={2}/>);
const summaryValueLineGraphToolTip = (<ToolTip title="Summary value" subtitle="This is your total spending for the current period that you have selected minus your spending from the previous." small offset={2}/>);
const AnalyticsCarouselItem = ({ index, positionAnimatedValue, currency, income, spending, previousSpending, value, chartTypeToShow = ChartType.linear, monthlyCategories, spendingBreakdown, spendingBreakdownPrevious, currentFrom, currentTo, previousFrom, previousTo, blurValue, onPressUpgradeForSearch, }) => {
    const isInFocus = useDerivedValue(() => positionAnimatedValue.value === index, [positionAnimatedValue, index]);
    const { selectedCategoryId } = useSelectedCategory();
    const [selectedCategory, setSelectedCategory] = useState();
    useDerivedValue(() => {
        const categories = monthlyCategories?.categories;
        const result = categories?.find((cat) => cat.id === selectedCategoryId.value);
        runOnJS(setSelectedCategory)(result);
    }, [selectedCategoryId, monthlyCategories]);
    const renderChart = () => {
        switch (chartTypeToShow) {
            case ChartType.bar:
                return (<BarChart income={income} spending={spending} currency={currency} blurValue={blurValue}/>);
            case ChartType.pie:
                return (<View style={styles.pieChartContainer}>
            <CategoryPieChart isInFocus={isInFocus} spending={spending} currency={currency} monthlyCategories={monthlyCategories} blurValue={blurValue}/>
            <Spacer h={24}/>
          </View>);
            case ChartType.linear: {
                return (<View style={styles.lineChartContainer}>
            <LinearChart current={spendingBreakdown} currentSpending={spending} previousSpending={previousSpending} previous={spendingBreakdownPrevious} currency={currency} currentFrom={currentFrom} currentTo={currentTo} previousFrom={previousFrom} previousTo={previousTo} blurValue={blurValue}/>
          </View>);
            }
            default:
                return null;
        }
    };
    if (blurValue) {
        return (<CardView withPadding style={[
                styles.blurCard,
                {
                    minHeight: rem(chartTypeToMinHeight[chartTypeToShow]),
                },
            ]}>
        <UpgradeButton onCard onPress={onPressUpgradeForSearch}/>
      </CardView>);
    }
    return (<CardView>
      <View style={styles.row}>
        <View>
          {chartTypeToShow === ChartType.bar ||
            chartTypeToShow === ChartType.linear ? (<SavingsChip name="Summary" amount={{
                value: chartTypeToShow === ChartType.linear
                    ? (previousSpending ?? 0) - spending
                    : value,
                currency,
            }} toolTip={chartTypeToShow === ChartType.bar
                ? summaryValueToolTip
                : summaryValueLineGraphToolTip} blurValue={blurValue}/>) : (selectedCategory && (<SavingsChip name={selectedCategory?.displayName} amount={{
                value: selectedCategory?.total > 0 ? 0 : selectedCategory?.total,
                currency: selectedCategory?.currency,
            }} blurValue={blurValue}/>))}
        </View>
        <ChartViewSwitcher currentChart={chartTypeToShow}/>
      </View>
      <Spacer h={24}/>
      {renderChart()}
    </CardView>);
};
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    blurCard: {
        justifyContent: 'center',
    },
    lineChartContainer: {
        alignItems: 'center',
    },
    pieChartContainer: {
        marginTop: -rem(16),
    },
    savingsAmount: {
        marginStart: rem(10),
    },
    savingsName: {
        marginEnd: rem(4),
    },
});
const styleSet = createStyleSheets((colors) => ({
    savingsChip: {
        margin: rem(16),
        paddingVertical: rem(8),
        paddingHorizontal: rem(16),
        backgroundColor: colors.background.dark,
        alignSelf: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: rem(8),
    },
    chipTitleStyles: {
        maxWidth: rem(150),
    },
}));
export default memo(AnalyticsCarouselItem);
