import React, { useMemo, useState } from 'react';
import { useDerivedValue, runOnJS } from 'react-native-reanimated';
import useColors from 'hooks/useColors';
import IconTransactionCategory from 'features/transactions/icons/IconTransactionCategory';
import IconSector from './IconSector';
export const CategoryLabel = ({ category, selected, sliceKey, }) => {
    const colors = useColors();
    const [isSelectedState, setIsSelectedState] = useState(selected?.value === sliceKey);
    useDerivedValue(() => {
        runOnJS(setIsSelectedState)(!!(selected && selected.value === sliceKey));
    }, [selected, sliceKey]);
    const renderIcon = useMemo(() => {
        if ('sector' in category) {
            return (<IconSector category={category} emojiBackgroundColor={colors.background.transparent} color={isSelectedState ? colors.background.dark : colors.elements.secondary}/>);
        }
        return (<IconTransactionCategory category={category} color={isSelectedState ? colors.background.dark : colors.elements.secondary} emojiBackgroundColor={colors.background.transparent}/>);
    }, [
        category,
        isSelectedState,
        colors.background.dark,
        colors.elements.secondary,
        colors.background.transparent,
    ]);
    return renderIcon;
};
