import React, { useCallback } from 'react';
import { View } from 'react-native';
import { useAppIcon } from 'features/app-icons/hooks';
import Modal from 'design-system/Modal';
import AppIconItem from 'features/app-icons/components/AppIconItem';
import { isUnlocked } from 'features/premium/iap';
import APP_ICONS from 'features/app-icons/appIcons';
import { rem } from 'design-system';
import { useAppSelector } from 'store/hooks';
import { selectUserTier } from 'features/premium/selectors/tier';
const strings = {
    title: 'Choose an icon',
};
const SelectAppIconModal = () => {
    const userTier = useAppSelector(selectUserTier);
    const isCrowdfundingInvestor = useAppSelector((store) => store.user.user.isCrowdfundingInvestor);
    const [icon, switchIcon] = useAppIcon();
    const onIconPress = useCallback((newIcon) => {
        switchIcon(newIcon);
        SelectAppIconModal.hide();
    }, [switchIcon]);
    const iconItems = Object.values(APP_ICONS).map((iconItem) => {
        const selected = icon?.name === iconItem.name;
        const locked = 
        // eslint-disable-next-line no-nested-ternary
        iconItem.name === 'investor'
            ? !isCrowdfundingInvestor
            : iconItem?.tier
                ? !isUnlocked(userTier, iconItem.tier)
                : false;
        return (<AppIconItem locked={locked} appIcon={iconItem} key={iconItem.name} selected={selected} onPress={onIconPress} disabled={iconItem.name === 'investor' && !isCrowdfundingInvestor}/>);
    });
    return <View>{iconItems}</View>;
};
const SelectAppIconModalMemo = React.memo(SelectAppIconModal);
SelectAppIconModal.show = () => {
    Modal.show(<SelectAppIconModalMemo />, {
        title: strings.title,
        containerStyle: {
            paddingHorizontal: 0,
        },
        scrollable: {
            maxHeight: rem(500),
        },
        propagateSwipe: true,
    });
};
SelectAppIconModal.hide = () => {
    Modal.hide();
};
export default SelectAppIconModal;
