import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconUpDownArrows = ({ direction = 'top', ...props }) => {
    const id = useId();
    if (direction === 'left') {
        return (<IconWithGradient id={id} {...props} defaultGradientKey="darkPurple">
        <Path fillRule="evenodd" clipRule="evenodd" d="m9.126 7.432-.001-.277c-.009-1.102-.074-2.085-.188-2.708 0-.011-.124-.63-.203-.835a1.479 1.479 0 0 0-.633-.71 1.569 1.569 0 0 0-.714-.173c-.196.01-.518.107-.749.19l-.192.073c-1.268.502-3.694 2.145-4.624 3.15l-.068.07-.306.33c-.193.252-.295.56-.296.892 0 .298.09.595.272.835.054.078.141.178.219.263l.297.311c1.022 1.038 3.235 2.495 4.383 2.978 0 .01.713.31 1.053.32h.046c.52.001 1.008-.295 1.257-.775.069-.132.134-.389.184-.615l.09-.426c.102-.686.171-1.738.173-2.893Zm4.964 1.203c.65 0 1.18-.532 1.18-1.19 0-.657-.526-1.19-1.178-1.191l-2.901.253a.929.929 0 0 0-.927.933.93.93 0 0 0 .924.935l2.902.26ZM15.287 16.45v.278c.01 1.101.074 2.084.188 2.707 0 .012.124.63.204.835.124.297.35.55.633.71.227.115.464.173.713.173.196-.009.52-.107.75-.19l.191-.072c1.269-.503 3.695-2.146 4.624-3.151l.069-.07.306-.33c.193-.252.295-.56.295-.892 0-.297-.09-.595-.27-.835a2.795 2.795 0 0 0-.22-.262l-.297-.312c-1.022-1.038-3.236-2.494-4.384-2.977 0-.01-.713-.31-1.053-.321h-.045c-.521-.001-1.008.296-1.258.776-.068.13-.133.388-.183.614l-.09.426c-.103.686-.172 1.739-.173 2.893Zm-4.964-1.202c-.651-.001-1.18.531-1.18 1.189-.001.657.526 1.19 1.177 1.192l2.902-.254c.51.001.926-.416.926-.932a.93.93 0 0 0-.924-.936l-2.901-.26Z" fill={`url(#gradient_${id})`}/>
      </IconWithGradient>);
    }
    return (<IconWithGradient id={id} {...props} defaultGradientKey="darkPurple">
      <Path fillRule="evenodd" clipRule="evenodd" d="m5.789 11.086-.278.001c-1.102.01-2.085.076-2.708.19-.01 0-.629.125-.834.205-.297.125-.55.35-.71.634a1.569 1.569 0 0 0-.171.714c.009.195.107.518.19.749l.073.191c.504 1.268 2.15 3.692 3.156 4.62l.07.07.33.305c.253.192.561.294.893.294.297 0 .594-.09.834-.272.078-.054.178-.142.262-.22l.312-.297c1.036-1.023 2.49-3.239 2.972-4.387.01 0 .309-.714.32-1.053v-.046c0-.52-.297-1.008-.778-1.257-.13-.068-.388-.133-.614-.183l-.427-.089c-.686-.102-1.738-.17-2.892-.17ZM6.985 6.12c0-.65-.533-1.179-1.19-1.179-.658 0-1.191.528-1.191 1.18l.257 2.9c0 .512.417.926.934.926a.93.93 0 0 0 .934-.925l.256-2.902Z" fill={`url(#gradient_${id})`}/>
      <Path fillRule="evenodd" clipRule="evenodd" d="m18.211 12.914.278-.001c1.102-.01 2.085-.076 2.708-.19.01 0 .629-.125.834-.205.297-.125.55-.35.71-.634.114-.227.172-.465.172-.714-.01-.195-.108-.518-.19-.749l-.074-.191c-.504-1.268-2.15-3.692-3.156-4.62l-.07-.07-.33-.305a1.456 1.456 0 0 0-.893-.294c-.297 0-.594.09-.834.272a2.793 2.793 0 0 0-.262.22l-.312.297c-1.036 1.023-2.49 3.239-2.972 4.387-.01 0-.309.714-.32 1.053v.046c0 .52.297 1.008.778 1.257.13.068.388.133.614.183l.427.089c.686.102 1.738.17 2.892.17Zm-1.196 4.966c0 .65.533 1.179 1.19 1.179.658 0 1.191-.528 1.191-1.18l-.256-2.9a.929.929 0 0 0-.934-.926.93.93 0 0 0-.934.925l-.257 2.902Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
