import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
const IconAmountUpper = (props) => {
    const id = useId();
    return (<IconWithGradient viewBox="0 0 24 24" id={id} width={24} height={24} defaultGradientKey="green" {...props}>
      <Path fill={`url(#gradient_${id})`} fillRule="evenodd" d="M12.896 11.686 9.304 14.88a1.5 1.5 0 1 0 1.993 2.242l4.062-3.61a2.5 2.5 0 0 0-.212-3.906L11.17 6.778a1.5 1.5 0 0 0-1.738 2.444l3.464 2.464Z" clipRule="evenodd"/>
    </IconWithGradient>);
};
export default IconAmountUpper;
