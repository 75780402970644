import { popToTop } from 'utils/navigationv6';
import * as types from './types';
import { CALL_API } from '../middleware/api';
export const getFilters = () => ({
    [CALL_API]: {
        endpoint: '/transaction-filters/',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_FILTERS_REQUEST,
            types.GET_FILTERS_SUCCESS,
            types.GET_FILTERS_FAILURE,
        ],
    },
});
export const setFilters = (body) => ({
    [CALL_API]: {
        endpoint: '/transaction-filters/',
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.SET_FILTERS_REQUEST,
            types.SET_FILTERS_SUCCESS,
            types.SET_FILTERS_FAILURE,
        ],
    },
});
export const getFilterPreview = (parameters, page) => {
    const queryString = Object.keys(parameters)
        .filter((key) => {
        if (parameters[key])
            return true;
        return false;
    })
        .map((key) => {
        if (parameters[key])
            return `${key}=${parameters[key]}`;
        return null;
    })
        .concat(page ? [`page=${page}`, `perPage=40`] : [`page=1`, `perPage=1`])
        .join('&');
    return {
        [CALL_API]: {
            endpoint: `/transaction-filters/transactions?${queryString}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            types: [
                types.SET_FILTER_PREVIEW_REQUEST,
                types.SET_FILTER_PREVIEW_SUCCESS,
                types.SET_FILTER_PREVIEW_FAILURE,
            ],
        },
    };
};
export const editFilters = (id, body) => ({
    [CALL_API]: {
        endpoint: `/transaction-filters/${id}`,
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.EDIT_FILTERS_REQUEST,
            types.EDIT_FILTERS_SUCCESS,
            types.EDIT_FILTERS_FAILURE,
        ],
    },
});
export const getAutomationRules = () => ({
    [CALL_API]: {
        endpoint: '/automation-rules/',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_AUTOMATION_RULES_REQUEST,
            types.GET_AUTOMATION_RULES_SUCCESS,
            types.GET_AUTOMATION_RULES_FAILURE,
        ],
    },
});
export const setAutomationRules = (body) => ({
    [CALL_API]: {
        endpoint: '/automation-rules/',
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.SET_AUTOMATION_RULES_REQUEST,
            types.SET_AUTOMATION_RULES_SUCCESS,
            types.SET_AUTOMATION_RULES_FAILURE,
        ],
    },
});
export const deleteAutomationRules = (id) => ({
    [CALL_API]: {
        endpoint: `/automation-rules/${id}`,
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.DELETE_AUTOMATION_RULES_REQUEST,
            types.DELETE_AUTOMATION_RULES_SUCCESS,
            types.DELETE_AUTOMATION_RULES_FAILURE,
        ],
    },
});
export const deleteAccount = (id) => ({
    [CALL_API]: {
        endpoint: `/accounts/${id}`,
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.DELETE_ACCOUNT_REQUEST,
            types.DELETE_ACCOUNT_SUCCESS,
            types.DELETE_ACCOUNT_FAILURE,
        ],
    },
});
export const createAccount = (body) => ({
    [CALL_API]: {
        endpoint: '/accounts/',
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.CREATE_ACCOUNT_REQUEST,
            types.CREATE_ACCOUNT_SUCCESS,
            types.CREATE_ACCOUNT_FAILURE,
        ],
        addSpaceIdHeader: true,
    },
});
export const getInvestmentHistory = (from, to, accountIds, stepSize = '1day') => ({
    [CALL_API]: {
        endpoint: `/balance-history?from=${from}&to=${to}&step=${stepSize}&accountIds[]=${accountIds}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        extra: { accountIds, stepSize },
        types: [
            types.GET_INVESTMENT_REQUEST,
            types.GET_INVESTMENT_SUCCESS,
            types.GET_INVESTMENT_FAILURE,
        ],
        addSpaceIdHeader: true,
    },
});
export const resetInvestmentBalances = () => ({
    type: types.RESET_INVESTMENT_BALANCES,
});
export const getBalanceHistory = (from, to, accountType, step = '1day') => {
    let accountsQuery = `&accountTypes[]=${accountType}`;
    if (accountType === 'CHECKING') {
        accountsQuery += '&accountTypes[]=CREDITCARD';
    }
    if (accountType === 'INVESTMENT') {
        accountsQuery += '&accountTypes[]=CRYPTO';
    }
    if (accountType === 'SAVINGS') {
        accountsQuery += '&accountTypes[]=SAVINGS';
    }
    if (accountType === 'NETWORTH') {
        accountsQuery =
            '&accountTypes[]=CHECKING&accountTypes[]=CREDITCARD&accountTypes[]=SAVINGS&accountTypes[]=INVESTMENT&accountTypes[]=CRYPTO&accountTypes[]=PENSION&accountTypes[]=LOAN&accountTypes[]=OTHER&accountTypes[]=VEHICLE&accountTypes[]=LOAN&accountTypes[]=REAL_ESTATE';
    }
    if (accountType === 'DEBT') {
        accountsQuery = '&graphType=DEBT';
    }
    if (accountType === 'ASSETS') {
        accountsQuery = '&graphType=ASSET';
    }
    return {
        [CALL_API]: {
            endpoint: `/balance-history?from=${from}&to=${to}&step=${step}${accountsQuery}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            extra: { accountType, stepSize: step },
            types: [
                types.GET_BALANCE_REQUEST,
                types.GET_BALANCE_SUCCESS,
                types.GET_BALANCE_FAILURE,
            ],
            addSpaceIdHeader: true,
        },
    };
};
export const setBalanceStorageReset = () => ({
    type: types.SET_BALANCE_STORAGE_RESET,
});
export const hideAccount = (body) => ({
    [CALL_API]: {
        endpoint: '/accounts',
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        extra: body,
        types: [types.HIDE_REQUEST, types.HIDE_SUCCESS, types.HIDE_FAILURE],
    },
});
export const closeAccount = (id, body) => ({
    [CALL_API]: {
        endpoint: `/accounts/${id}/edit`,
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.CLOSE_ACCOUNT_REQUEST,
            types.CLOSE_ACCOUNT_SUCCESS,
            types.CLOSE_ACCOUNT_FAILURE,
        ],
    },
});
export const editAccount = (id, body) => ({
    [CALL_API]: {
        endpoint: `/accounts/${id}/edit`,
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.EDIT_ACCOUNT_REQUEST,
            types.EDIT_ACCOUNT_SUCCESS,
            types.EDIT_ACCOUNT_FAILURE,
        ],
        extra: { id, body },
    },
});
export const setAccountName = (id, data) => {
    const body = {
        customName: data,
    };
    return {
        [CALL_API]: {
            endpoint: `/accounts/${id}/edit`,
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            types: [
                types.SET_ACCOUNT_NAME_REQUEST,
                types.SET_ACCOUNT_NAME_SUCCESS,
                types.SET_ACCOUNT_NAME_FAILURE,
            ],
        },
    };
};
export const setAccountType = (type, id) => ({
    [CALL_API]: {
        endpoint: `/accounts/${id}/edit`,
        method: 'POST',
        body: JSON.stringify({ type }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.SET_ACCOUNT_TYPE_REQUEST,
            types.SET_ACCOUNT_TYPE_SUCCESS,
            types.SET_ACCOUNT_TYPE_FAILURE,
        ],
    },
});
export const setOverdraft = (id, data) => ({
    [CALL_API]: {
        endpoint: `/accounts/${id}/edit`,
        method: 'POST',
        body: JSON.stringify({
            overdraftLimit: data ? parseInt(data, 10) : null,
        }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.SET_OVERDRAFT_REQUEST,
            types.SET_OVERDRAFT_SUCCESS,
            types.SET_OVERDRAFT_FAILURE,
        ],
    },
});
export const getBankConnection = (connectionId) => {
    const id = connectionId ? `/${connectionId}` : '';
    return {
        [CALL_API]: {
            endpoint: `/bank-connections${id}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            types: [
                types.CONNECTION_REQUEST,
                types.CONNECTION_SUCCESS,
                types.CONNECTION_FAILURE,
            ],
        },
    };
};
export const getBankConnections = () => ({
    [CALL_API]: {
        endpoint: '/bank-connections',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        types: [
            types.CONNECTIONS_REQUEST,
            types.CONNECTIONS_SUCCESS,
            types.CONNECTIONS_FAILURE,
        ],
    },
});
export const setReconsentConnections = (bankConnectionIds) => ({
    [CALL_API]: {
        endpoint: '/bank-connections/extend-consent',
        method: 'POST',
        body: JSON.stringify({ bankConnectionIds }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.RECONSENT_CONNECTIONS_REQUEST,
            types.RECONSENT_CONNECTIONS_SUCCESS,
            types.RECONSENT_CONNECTIONS_FAILURE,
        ],
        extra: bankConnectionIds,
    },
});
export const setRetainedConnections = (bankConnectionIds) => ({
    [CALL_API]: {
        endpoint: '/bank-connections/choose-retained-over-quota',
        method: 'POST',
        body: JSON.stringify({ bankConnectionIds }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.CHOOSE_RETAINED_CONNECTIONS_REQUEST,
            types.CHOOSE_RETAINED_CONNECTIONS_SUCCESS,
            types.CHOOSE_RETAINED_CONNECTIONS_FAILURE,
        ],
        extra: bankConnectionIds,
    },
});
export const closedConnection = (data) => ({
    [CALL_API]: {
        endpoint: `/bank-connections/${data.id}`,
        method: 'PATCH',
        body: JSON.stringify({ isClosed: !(data && data.isClosed) }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.CLOSED_CONNECTION_REQUEST,
            types.CLOSED_CONNECTION_SUCCESS,
            types.CLOSED_CONNECTION_FAILURE,
        ],
    },
});
export const deleteConnection = (id) => ({
    [CALL_API]: {
        endpoint: `/bank-connections/${id}/delete`,
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        types: [
            types.DELETE_CONNECTION_REQUEST,
            types.DELETE_CONNECTION_SUCCESS,
            types.DELETE_CONNECTION_FAILURE,
        ],
    },
});
export const syncConnection = (id, muteAlert) => ({
    [CALL_API]: {
        endpoint: `/bank-connections/${id}/sync`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        types: [types.SYNC_REQUEST, types.SYNC_SUCCESS, types.SYNC_FAILURE],
        muteAlert,
    },
});
export const syncAllConnections = () => ({
    [CALL_API]: {
        endpoint: '/sync-all-connections/',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.SYNC_ALL_REQUEST,
            types.SYNC_ALL_SUCCESS,
            types.SYNC_ALL_FAILURE,
        ],
    },
});
export const setConnectionsStatus = (data) => ({
    type: types.SET_CONNECTIONS_STATUS,
    payload: data,
});
export const setWaitingStatus = (data) => ({
    type: types.SET_WAITING_STATUS,
    payload: data,
});
export const getSyncingStatus = (isConnectingAtOnboarding, runOnlyOnce) => async (dispatch, getState) => {
    await dispatch(getBankConnections());
    const { user } = getState();
    if (((user.isSyncingManual && !isConnectingAtOnboarding) ||
        user.lastChange == null) &&
        user.connections.filter((item) => item.bankInfo.provider !== 'MANUAL')
            .length) {
        if (!runOnlyOnce) {
            setTimeout(() => {
                dispatch(getSyncingStatus());
            }, 5000);
        }
    }
    else if (user.isLoggedIn && user.isWaiting) {
        popToTop(() => {
            setTimeout(() => {
                dispatch(setConnectionsStatus(false));
                dispatch(setWaitingStatus(false));
            }, 200);
        });
    }
    else {
        dispatch(setConnectionsStatus(false));
        dispatch(setWaitingStatus(false));
    }
};
export const getAccountGoals = () => ({
    [CALL_API]: {
        endpoint: '/account-goals?showAll=true',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_ACCOUNT_GOALS_REQUEST,
            types.GET_ACCOUNT_GOALS_SUCCESS,
            types.GET_ACCOUNT_GOALS_FAILURE,
        ],
    },
});
export const addGoal = (goal) => ({
    [CALL_API]: {
        endpoint: '/account-goals',
        method: 'POST',
        body: JSON.stringify(goal),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.ADD_GOAL_REQUEST,
            types.ADD_GOAL_SUCCESS,
            types.ADD_GOAL_FAILURE,
        ],
    },
});
export const editGoal = (goalID, goal) => ({
    [CALL_API]: {
        endpoint: `/account-goals/${goalID}`,
        method: 'PUT',
        body: JSON.stringify(goal),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.EDIT_GOAL_REQUEST,
            types.EDIT_GOAL_SUCCESS,
            types.EDIT_GOAL_FAILURE,
        ],
    },
});
export const deleteGoal = (goalID, accountId) => ({
    [CALL_API]: {
        endpoint: `/account-goals/${goalID}`,
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        extra: { accountId },
        types: [
            types.DELETE_GOAL_REQUEST,
            types.DELETE_GOAL_SUCCESS,
            types.DELETE_GOAL_FAILURE,
        ],
    },
});
