import React, { memo } from 'react';
import { View } from 'react-native';
import { rem } from 'design-system/values';
import { CARD_VIEW_BORDER_RADIUS } from 'design-system/CardView';
import { HelpCallToAction } from 'design-system/HelpCallToAction';
import Links from 'utils/links';
import Amplitude from 'utils/amplitude';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
const AnalyticsSectionFooter = () => {
    const openToolTip = () => {
        Links.openKnownLink('amendAnalytics');
        Amplitude.logEvent('Categories.ClickInfo');
    };
    const styles = useStyles(styleSet);
    return (<View style={styles.container}>
      <HelpCallToAction title="Need to amend your data?" subTitle="Learn how to do it" onPress={openToolTip} style={styles.help}/>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    help: {
        marginVertical: rem(16),
    },
    container: {
        backgroundColor: colors.cards.onDark,
        marginHorizontal: rem(16),
        borderBottomLeftRadius: CARD_VIEW_BORDER_RADIUS,
        borderBottomRightRadius: CARD_VIEW_BORDER_RADIUS,
    },
}));
export default memo(AnalyticsSectionFooter);
