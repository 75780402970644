import React from 'react';
import { useAppSelector } from 'store/hooks';
import { selectIsGBUser } from 'reducers/selectors';
import { selectIsDefaultSpace } from 'features/spaces/reducers/selectors';
import { selectHasInterestPotProduct } from 'features/pots/reducers/selectors';
import EmptyComponent from './EmptyComponent';
import { isWeb } from '../../../../constants';
import StartSavingCard from './StartSavingCard';
import StartInvestingCard from './StartInvestingCard';
const AccountOverviewEmpty = ({ title, }) => {
    const potsAccountStatus = useAppSelector((state) => state.pots.account?.status);
    const investAccountStatus = useAppSelector((state) => state.invest.account?.status);
    const isGBUser = useAppSelector(selectIsGBUser);
    const hasInterestPotProduct = useAppSelector(selectHasInterestPotProduct);
    const isDefaultSpace = useAppSelector(selectIsDefaultSpace);
    switch (title) {
        case 'Investments': {
            if (!isWeb &&
                isGBUser &&
                isDefaultSpace &&
                investAccountStatus !== 'ACTIVE') {
                return <StartInvestingCard />;
            }
            return <EmptyComponent />;
        }
        case 'Savings': {
            if (!isWeb &&
                isGBUser &&
                isDefaultSpace &&
                potsAccountStatus !== 'ACTIVE' &&
                hasInterestPotProduct) {
                return <StartSavingCard />;
            }
            return <EmptyComponent />;
        }
        default:
            return <EmptyComponent />;
    }
};
export default AccountOverviewEmpty;
