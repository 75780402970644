import { Animated } from 'react-native';
export const DEFAULT_DELAY_TIME = 100;
/**
 * Avoid using this in anything that can unmount, as it is not necessarily cleaned up
 */
export const delay = (callBack = () => null, time = DEFAULT_DELAY_TIME) => {
    Animated.timing(new Animated.Value(0), {
        toValue: 1,
        duration: time,
        useNativeDriver: true,
    }).start(callBack);
};
export const delayAsync = (time = DEFAULT_DELAY_TIME) => new Promise((resolve) => {
    delay(() => {
        resolve();
    }, time);
});
