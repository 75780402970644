import * as React from 'react';
import Svg, { Defs, LinearGradient, Path, Stop, } from 'react-native-svg';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
export const IconGlobe = ({ ...props }) => {
    const colors = useColors();
    const width = rem(44);
    const height = rem(44);
    // todo use IconSquareCircle here
    return (<Svg width={width} height={height} fill="none" viewBox="0 0 44 44" {...props}>
      <Path d="M22 0C7.07763 0 0 7.07763 0 22C0 36.9224 7.07763 44 22 44C36.9224 44 44 36.9224 44 22C44 7.07763 36.9224 0 22 0Z" fill={colors.background.dark}/>
      <Path d="M22.5 11.875C20.4975 11.875 18.5399 12.4688 16.8749 13.5814C15.2098 14.6939 13.9121 16.2752 13.1457 18.1253C12.3794 19.9754 12.1789 22.0112 12.5696 23.9753C12.9602 25.9393 13.9245 27.7435 15.3405 29.1595C16.7566 30.5755 18.5607 31.5398 20.5247 31.9305C22.4888 32.3211 24.5246 32.1206 26.3747 31.3543C28.2248 30.5879 29.8061 29.2902 30.9186 27.6251C32.0312 25.9601 32.625 24.0025 32.625 22C32.6225 19.3154 31.555 16.7416 29.6567 14.8433C27.7584 12.945 25.1846 11.8775 22.5 11.875ZM30.2906 20.875H27.3281C27.1697 18.6304 26.4298 16.4657 25.1813 14.5938C26.5207 15.0818 27.703 15.9234 28.6026 17.0294C29.5022 18.1353 30.0855 19.4642 30.2906 20.875V20.875ZM19.9313 23.125H25.0688C24.8438 25.4406 23.925 27.5969 22.5 29.1813C21.075 27.5969 20.1563 25.4406 19.9313 23.125V23.125ZM19.9313 20.875C20.1563 18.5594 21.075 16.4031 22.5 14.8188C23.925 16.4031 24.8438 18.5594 25.0688 20.875H19.9313ZM19.8188 14.5938C18.5702 16.4657 17.8303 18.6304 17.6719 20.875H14.7094C14.9145 19.4642 15.4978 18.1353 16.3974 17.0294C17.297 15.9234 18.4793 15.0818 19.8188 14.5938V14.5938ZM14.7094 23.125H17.6719C17.8303 25.3696 18.5702 27.5343 19.8188 29.4062C18.4782 28.92 17.2948 28.0788 16.395 26.9726C15.4951 25.8663 14.9125 24.5365 14.7094 23.125V23.125ZM25.1813 29.4062C26.4298 27.5343 27.1697 25.3696 27.3281 23.125H30.2906C30.0875 24.5365 29.5049 25.8663 28.605 26.9726C27.7052 28.0788 26.5218 28.92 25.1813 29.4062V29.4062Z" fill="url(#paint0_linear_1_4)"/>
      <Defs>
        <LinearGradient id="paint0_linear_1_4" x1="24.6157" y1="23.7241" x2="-0.611706" y2="25.053" gradientUnits="userSpaceOnUse">
          <Stop stopColor="#9C39FF"/>
          <Stop offset="1" stopColor="#8103FF"/>
        </LinearGradient>
      </Defs>
    </Svg>);
};
