import React from 'react';
import { StyleSheet } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import TouchableOpacity from 'utils/packages/TouchableOpacity';
import { isWeb } from '../../../../constants';
const EditMultipleSelectAllButton = ({ isSelectedAll, onPress, }) => {
    const text = isSelectedAll ? 'Deselect All' : 'Select All';
    return (<TouchableOpacity onCard hoverOnDark onPress={onPress} style={styles.headerRightBtn}>
      <Text Text-16>{text}</Text>
    </TouchableOpacity>);
};
const styles = StyleSheet.create({
    headerRightBtn: {
        ...(isWeb && {
            marginRight: rem(16),
            paddingVertical: rem(8),
            paddingHorizontal: rem(16),
        }),
    },
});
export default EditMultipleSelectAllButton;
