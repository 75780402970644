import * as Sentry from '@sentry/react-native';
import Config from 'utils/packages/configs';
import isEmpty from 'lodash/isEmpty';
export const routingInstrumentation = new Sentry.ReactNavigationInstrumentation();
const addBreadcrumb = (breadcrumb) => {
    Sentry.addBreadcrumb(breadcrumb);
};
const captureMessage = (message) => {
    Sentry.captureMessage(message);
};
const captureException = (error) => {
    Sentry.captureException(error);
};
const init = () => {
    Sentry.init({
        dsn: Config.SENTRY_DSN,
        enableAutoSessionTracking: true,
        enableAutoPerformanceTracing: false,
        maxBreadcrumbs: 150,
        enabled: !__DEV__,
        integrations: [
            new Sentry.ReactNativeTracing({
                // Disable stall tracking while testing
                // https://github.com/wix/Detox/blob/master/docs/Troubleshooting.Synchronization.md#settimeout-and-setinterval
                // (Sentry will call setTimeout(checkIfStalled, 0) causing Detox to wait forever
                enableStallTracking: Config.DETOX_ENV !== 'true',
                routingInstrumentation,
                beforeNavigate: (context) => {
                    const rnContext = context;
                    if (!rnContext.data) {
                        return rnContext;
                    }
                    const { route, previousRoute } = rnContext.data;
                    const data = {
                        to: route.name,
                        toParams: route.params,
                        hasBeenSeen: route.hasBeenSeen,
                        from: previousRoute?.name ?? null,
                        fromParams: previousRoute?.params ?? null,
                    };
                    try {
                        addBreadcrumb({
                            data,
                            type: 'navigation',
                        });
                    }
                    catch (error) {
                        captureException(error);
                    }
                    return rnContext;
                },
            }),
        ],
    });
};
const setUser = (user) => {
    if (!user || !user.id) {
        return;
    }
    Sentry.setUser({ id: user.id.toString() });
};
const logDebug = (props) => {
    Sentry.captureMessage(props.filename ? `[${props.filename}] ${props.message}` : props.message, 'debug');
};
/**
 * Send an error event to Sentry
 * eg: Sentry.logError({filename: 'App.js', message: 'Failed loading'})
 * eg: Sentry.logError({filename: 'App.js', data: {flow: 'logout'}})
 */
const logError = (props) => {
    if (!props.message && !props.data && !props.err) {
        return;
    }
    if ('message' in props &&
        props.message &&
        !('data' in props) &&
        !('err' in props)) {
        captureMessage(props.filename ? `[${props.filename}] ${props.message}` : props.message);
    }
    else {
        Sentry.withScope((scope) => {
            if (props.filename) {
                scope.setExtra('filename', props.filename);
            }
            if (props.message) {
                scope.setExtra('message', props.message);
            }
            if (props.data) {
                scope.setExtra('data', props.data);
            }
            if (props.fingerprint) {
                scope.setFingerprint(props.fingerprint);
            }
            captureException(props.err || new Error('Generic Error'));
        });
    }
};
const matchersMap = {
    digits: /^\d+$/,
};
export function stripIds(url) {
    if (isEmpty(url)) {
        return '';
    }
    const placeholder = 'id';
    const matchers = [matchersMap.digits];
    const isMatched = (s) => matchers.some((matchers) => matchers.test(s));
    return url
        .split('/')
        .map((chunk) => (isMatched(chunk) ? placeholder : chunk))
        .join('/');
}
/**
 * Send network error to Sentry
 * eg:   Sentry.logNetworkError({
 *       url: 'https://api.staging.nonprod-emma.com/transactions-compact',
 *       method: 'GET',
 *       status: 500,
 *       error: 'Error',
 *       errorMessage: 'Error Message',
 *       requestId: 'REQUEST_TYPE'
 *     })
 */
const logNetworkError = ({ url, method, status, error, errorMessage, requestId, }) => {
    const resultError = error || 'UNKNOWN';
    const resultErrorMessage = errorMessage || 'UNKNOWN';
    const resultCode = status ? String(status) : 'UNKNOWN';
    const resultRequestId = requestId || 'UNKNOWN';
    const resultMethod = method ?? '';
    Sentry.withScope((scope) => {
        scope.setFingerprint([requestId, resultCode, resultError]);
        scope.setExtra('url', url);
        scope.setTag('statusCode', resultCode);
        scope.setTag('requestId', resultRequestId);
        scope.setExtra('method', resultMethod);
        scope.setExtra('errorMessage', resultErrorMessage);
        scope.setExtra('error', resultError);
        captureMessage(`${method} ${stripIds(url)} | ${resultCode}`);
    });
};
export default {
    init,
    setUser,
    logError,
    logDebug,
    addBreadcrumb,
    captureMessage,
    captureException,
    logNetworkError,
};
