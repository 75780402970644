import React from 'react';
import * as SVG from 'react-native-svg';
import { rem } from 'design-system/values';
export const IconGraterThan = ({ color = '#31D8BD' }) => {
    const width = rem(8);
    const height = rem(16);
    return (<SVG.Svg width={width} height={height} viewBox="0 0 8 16" fill="none">
      <SVG.Path fillRule="evenodd" clipRule="evenodd" d="M4.39693 7.68626L0.805087 10.879C0.185913 11.4294 0.130141 12.3775 0.680518 12.9967C1.2309 13.6158 2.179 13.6716 2.79818 13.1212L6.85941 9.51125C8.06042 8.44368 7.95686 6.53658 6.64731 5.60534L2.67092 2.77769C1.99579 2.29759 1.05929 2.4557 0.5792 3.13083C0.0991068 3.80596 0.257216 4.74246 0.932347 5.22255L4.39693 7.68626Z" fill={color}/>
    </SVG.Svg>);
};
export default IconGraterThan;
