import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { CommonActions } from '@react-navigation/native';
import PopUpMenuModal, { defaultPopUpWidth, } from 'design-system/PopUpMenuModal.web';
import { rem } from 'design-system/values';
import Modal from 'utils/packages/Modal';
import Alert from 'utils/packages/Alert';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { isWeb } from '../../../constants';
import { useSaveBudget } from './useSaveBudget';
import ManageBudgetingModalContent from '../components/ManageBudgetingModalContent';
const useManageBudgetingModal = (btnViewRef) => {
    const navigation = useMainStackNavigation();
    const onEditBudget = useCallback(() => {
        navigation.navigate('EditBudget', { flow: 'Edit' });
    }, []);
    const [isBudgetFetching, saveBudget, getBudgets] = useSaveBudget();
    const onResetBudget = useCallback(() => {
        Alert.alert('Reset budgeting?', 'Are you sure you want to reset? You can start fresh after this', [
            { style: 'cancel', text: 'Cancel' },
            {
                style: 'destructive',
                text: 'Reset',
                onPress: async () => {
                    const budgetResult = await getBudgets();
                    if (!budgetResult) {
                        navigation.goBack();
                        return;
                    }
                    const { budgets } = budgetResult;
                    const rollover = budgets.some((budget) => !!budget.shouldRollover);
                    await saveBudget(null, rollover, budgets.map((budget) => ({ ...budget, totalLimit: null })));
                    if (isWeb) {
                        navigation.dispatch(CommonActions.reset({
                            index: 0,
                            routes: [{ name: 'Feed' }],
                        }));
                    }
                    else {
                        navigation.popToTop();
                    }
                },
            },
        ]);
    }, [getBudgets, saveBudget]);
    const show = useCallback(() => {
        if (isWeb) {
            if (btnViewRef?.current) {
                btnViewRef.current?.measure((_, __, ___, ____, fx, fy) => {
                    PopUpMenuModal.show(<ManageBudgetingModalContent onEditBudget={onEditBudget} onResetBudget={onResetBudget}/>, {
                        fx: fx - defaultPopUpWidth,
                        fy: fy + rem(40),
                    });
                });
            }
            return;
        }
        Modal.show(<ManageBudgetingModalContent onEditBudget={onEditBudget} onResetBudget={onResetBudget}/>, {
            containerStyle: styles.modal,
            title: 'Manage',
        });
    }, [onResetBudget, onEditBudget]);
    return [show, isBudgetFetching];
};
const styles = StyleSheet.create({
    modal: {
        paddingHorizontal: 0,
        overflow: 'hidden',
    },
});
export default useManageBudgetingModal;
