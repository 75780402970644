import { useRef } from 'react';
/**
 * When this is true, there will be no passcode lock shown on app start.
 */
export const SKIP_PASSCODE = false; // do not commit any changes to this line
/**
 * When set, the Navigator's main stack will be initialized with this route.
 */
export const INITIAL_ROUTE = undefined; // do not commit any changes to this line
/**
 * Use this to quickly show quiz card in feed to spped up development
 */
export const FORCE_SHOW_QUIZ_CARD_IN_FEED_ID = undefined; // value of 9 works on staging
/**
 * Use this to quickly show a demo weekly report in the feed
 */
export const FORCE_SHOW_DEMO_REPORT_IN_FEED = false;
export const DEMO_MODAL_NOTIFICATIONS = [
    {
        id: 1,
        buttonRoute: 'Invest',
        // buttonRoute: 'Bad Link', // This should show a link to the app stores
        buttonText: 'Invest now',
        message: 'hello',
        from: {
            name: 'Jack',
            iconUrl: 'https://global.discourse-cdn.com/standard14/uploads/emma_app/original/1X/d484c23375bbdcdc6df7dea9275f603cc28dbe99.png',
        },
        subject: 'Invest now available!',
        buttonUrl: null,
        data: {
            resourceId: 1,
            type: '',
        },
    },
];
/**
 * Show a demo modal notification on app start, you can customise the data above
 */
export const FORCE_SHOW_MODAL_NOTIFICATIONS = undefined;
export const SHOW_LOGOUT = true;
/**
 * Add this to a component to log when it renders
 */
export const useRenderCount = (tag) => {
    const renderCount = useRef(0);
    renderCount.current += 1;
    log(`[${tag}] Render count: ${renderCount.current}`, false, 'yellow');
};
/**
 * Force show the walkthrough in the feed, usually it is only shown to newly registered users
 */
export const SHOW_WALKTHROUGH = undefined;
export const FORCE_SHOW_TRIAL = undefined;
