import { uniqBy } from 'lodash';
import { isValidAppLink } from 'hooks/useAppLink';
import { sortAccordingToDatetime } from 'utils/sort';
import * as types from '../../../actions/types';
const initialState = {
    notificationsArray: null,
    page: 1,
    pageCount: 1,
    error: null,
    isFetching: undefined,
};
const filterValidAppLinks = (items) => items.map((item) => ({
    ...item,
    // Remove any appLinks that aren't valid
    data: item.data
        ? {
            ...item.data,
            appLink: 'appLink' in item.data &&
                item.data.appLink &&
                isValidAppLink(item.data.appLink)
                ? item.data.appLink
                : undefined,
        }
        : undefined,
}));
const notifications = (state = initialState, action) => {
    switch (action.type) {
        case types.LOGGED_OUT: {
            return initialState;
        }
        case types.GET_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                error: null,
                isFetching: true,
            };
        case types.GET_NOTIFICATIONS_SUCCESS:
            const isNewArray = !state.notificationsArray ||
                !action.payload.items[0] ||
                !state.notificationsArray[0] ||
                action.payload.items[0].id !== state.notificationsArray[0].id;
            if (action.payload.paging.page === 1) {
                if (!isNewArray) {
                    // Nothing has changed, use the cache
                    return {
                        ...state,
                        error: null,
                        isFetching: false,
                    };
                }
                // The notifications are different wipe the cache
                return {
                    ...state,
                    notificationsArray: filterValidAppLinks(action.payload.items),
                    page: action.payload.paging.page,
                    pageCount: action.payload.paging.pageCount,
                    error: null,
                    isFetching: false,
                };
            }
            // New page has loaded, append to the end of the cache
            return {
                ...state,
                notificationsArray: uniqBy([
                    ...(state.notificationsArray || []),
                    ...filterValidAppLinks(action.payload.items),
                ], (item) => item.id).sort(sortAccordingToDatetime),
                page: action.payload.paging.page,
                pageCount: action.payload.paging.pageCount,
                error: null,
                isFetching: false,
            };
        case types.GET_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                error: true,
            };
        default:
            return state;
    }
};
export default notifications;
