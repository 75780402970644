/**
 * Minimum year configuration for the filter calendar for yearly filter
 */
export const filterStartYear = 2010;
/**
 * Segment options
 */
export const options = ['Weekly', 'Monthly', 'Quarterly', 'Yearly', 'Custom'];
/**
 * Monthly is selected by default
 */
export const defaultSelectedSegmentIndex = 1;
/**
 * Minimum date configuration for the filter calendar
 */
export const minDate = '2010-01-01';
export const monthsArr = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];
export const quartersArr = ['Q1', 'Q2', 'Q3', 'Q4'];
