export const getDailyAllowance = (currentMonthlyTotal) => {
    if (!currentMonthlyTotal.hasCircle) {
        return null;
    }
    if (!currentMonthlyTotal.totalBudget || !currentMonthlyTotal.daysLeft) {
        return 0;
    }
    const amountLeftUntilMonthEnd = currentMonthlyTotal.totalBudget -
        (currentMonthlyTotal.spending + currentMonthlyTotal.committed);
    const dailyAllowance = amountLeftUntilMonthEnd / currentMonthlyTotal.daysLeft;
    if (Number.isNaN(dailyAllowance) || dailyAllowance < 0) {
        return 0;
    }
    return dailyAllowance;
};
