import React from 'react';
import { StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Animated, { interpolate, useAnimatedStyle } from 'react-native-reanimated';
import { rem } from 'design-system/values';
const BORDER_RADIUS = rem(12);
const BackgroundView = ({ color, scrollY, navHeaderHeight, headerContainerHeight }) => {
    const animatedHeight = useAnimatedStyle(() => {
        const expandedHeight = headerContainerHeight.value + BORDER_RADIUS + navHeaderHeight;
        return {
            height: interpolate(scrollY.value, [-1, 0, headerContainerHeight.value + BORDER_RADIUS, headerContainerHeight.value + 1 + BORDER_RADIUS], [expandedHeight + 1, expandedHeight, navHeaderHeight, navHeaderHeight]),
        };
    });
    return (<Animated.View style={[styles.CategoryBackgroundView, animatedHeight]}>
      <LinearGradient style={styles.container} {...color}/>
    </Animated.View>);
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    CategoryBackgroundView: {
        left: 0,
        right: 0,
        position: 'absolute',
    },
});
export default BackgroundView;
