import React, { memo } from 'react';
import { View, InteractionManager } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import { CheckMarkSquareBadge } from 'design-system/badges/badges';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
import createStyleSheets from 'utils/createStyleSheets';
import { charFromEmojiObject } from 'utils/emojiHelpers';
import TouchableBounce from 'utils/packages/TouchableBounce';
import { isWeb } from '../../constants';
const EmojiItem = ({ item, onSelected, columnWidth, isSelected, }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const selected = () => {
        InteractionManager.runAfterInteractions(() => {
            onSelected(charFromEmojiObject(item));
        });
    };
    return (<TouchableBounce style={{ width: columnWidth }} onPress={selected} testID="emoji-item">
      <View style={styles.innerContainer}>
        <View style={[styles.emojiItem, isSelected && styles.emojiItemSelected]}>
          <Text Text-24>{charFromEmojiObject(item)}</Text>
        </View>
        {isSelected && (<CheckMarkSquareBadge gradientKey="ultimate" style={styles.badge} {...(isWeb && {
            fill: colors.elements.brand,
        })}/>)}
      </View>
    </TouchableBounce>);
};
export default memo(EmojiItem);
const styleSet = createStyleSheets((colors) => ({
    innerContainer: {
        width: rem(44),
        alignSelf: 'center',
    },
    emojiItem: {
        width: rem(44),
        height: rem(44),
        overflow: 'hidden',
        alignSelf: 'center',
        alignItems: 'center',
        borderRadius: rem(22),
        marginVertical: rem(8),
        justifyContent: 'center',
        backgroundColor: colors.cards.onLight,
    },
    emojiItemSelected: {
        borderWidth: rem(1),
        borderColor: colors.text.brand,
        backgroundColor: colors.background.brandLight,
    },
    badge: {
        marginBottom: rem(6),
    },
}));
