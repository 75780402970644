import { createSelector } from 'reselect';
import { Tier } from '../entities';
export const selectUserTier = createSelector([(store) => store.user.user], (user) => {
    if (user.emmaProStatus?.active && user.emmaProStatus?.tier) {
        return user.emmaProStatus.tier;
    }
    return Tier.free;
});
export const selectIsUserUltimate = createSelector([(store) => store.user.user], (user) => {
    if (user.emmaProStatus.active && user.emmaProStatus.tier) {
        return user.emmaProStatus.tier === Tier.ultimate;
    }
    return false;
});
export const selectIsUserFreeTier = createSelector(selectUserTier, (tier) => tier === Tier.free);
