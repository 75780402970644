import React from 'react';
import { StyleSheet, View } from 'react-native';
import useNavigateToAnalytics from 'features/analytics/hooks/useNavigateToAnalytics';
import { rem } from 'design-system/values';
import { TextButtonLinkSeeAll } from 'design-system/TextButton';
import Section from '../Section';
import BudgetingCardContainer from './BudgetingCardContainer';
import IncomeSpendingCard from './IncomeSpendingCard';
const Analytics = ({ onLayout }) => {
    const onPressAnalytics = useNavigateToAnalytics();
    return (<Section title="Analytics & budgeting" button={<TextButtonLinkSeeAll onPress={onPressAnalytics}/>} onLayout={onLayout}>
      <View style={styles.container}>
        <IncomeSpendingCard />
        <BudgetingCardContainer />
      </View>
    </Section>);
};
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        marginHorizontal: rem(16),
    },
});
export default Analytics;
