import { GET_ACTIVE_REFERRAL_PROGRAM_SUCCESS, GET_CREDIT_REFERRALS_SUCCESS, GET_REFERRALS_SUCCESS, LOGGED_OUT, SET_REFERRAL_SEEN_SUCCESS, } from 'actions/types';
import { REHYDRATE } from 'redux-persist';
const initialState = {
    referrals: undefined,
    creditReferrals: undefined,
    activeProgram: undefined,
};
const referrals = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE:
            const { referrals } = action.payload || {};
            if (referrals)
                return { ...initialState, ...referrals };
            return state;
        case GET_REFERRALS_SUCCESS:
            return { ...state, referrals: action.payload };
        case GET_CREDIT_REFERRALS_SUCCESS:
            return { ...state, creditReferrals: action.payload };
        case GET_ACTIVE_REFERRAL_PROGRAM_SUCCESS:
            return { ...state, activeProgram: action.payload.program };
        case SET_REFERRAL_SEEN_SUCCESS:
            if (!state.creditReferrals?.referrals) {
                return state;
            }
            const date = new Date().toISOString();
            return {
                ...state,
                creditReferrals: {
                    ...state.creditReferrals,
                    referrals: state.creditReferrals.referrals.map((r) => ({
                        ...r,
                        seenAt: action.extra.ids.includes(r.id) ? date : r.seenAt,
                    })),
                },
            };
        case LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
};
export { referrals };
