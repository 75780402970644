import React from 'react';
import { StyleSheet } from 'react-native';
import useColors from 'hooks/useColors';
import { rem } from '../values';
import IconArrowTriangle from './IconArrowTriangle';
export const IconArrowTriangleRight = ({ width = rem(7), height = rem(6), color, style, }) => {
    const colors = useColors();
    return (<IconArrowTriangle width={width} height={height} color={color ?? colors.text.brand} style={[style, styles.icon]}/>);
};
const styles = StyleSheet.create({
    icon: {
        transform: [{ rotate: '-90deg' }],
    },
});
export default IconArrowTriangleRight;
