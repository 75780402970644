import useColors from 'hooks/useColors';
import React from 'react';
import { StyleSheet } from 'react-native';
import { correct } from 'design-system/values';
import { Number } from './Number';
const MoneyInputField = ({ color, currency = 'GBP', style, children, }) => {
    const colors = useColors();
    return (<Number style={[
            styles.number,
            style,
            { color: color ?? colors.text.whiteOnColor },
        ]} currency={currency}>
      {children}
    </Number>);
};
const styles = StyleSheet.create({
    number: {
        fontWeight: '800',
        fontSize: correct(72),
    },
});
export default MoneyInputField;
