import React, { useId } from 'react';
import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconChat = ({ alt, ...props }) => {
    const width = rem(20);
    const height = rem(20);
    const colors = useColors();
    const id = useId();
    if (alt) {
        return (<Svg width={width} height={height} viewBox="0 0 28 28" fill="none" {...props}>
        <Path d="M14.0002 2.33337C20.4437 2.33337 25.6668 7.55654 25.6668 14C25.6668 20.4435 20.4437 25.6667 14.0002 25.6667C12.1128 25.6692 10.2532 25.2119 8.58217 24.3344L3.57717 25.6375C3.40994 25.6811 3.23422 25.6802 3.06745 25.6349C2.90067 25.5896 2.74861 25.5016 2.62636 25.3794C2.5041 25.2573 2.41588 25.1053 2.37045 24.9386C2.32502 24.7719 2.32395 24.5961 2.36734 24.4289L3.66934 19.425C2.78944 17.7523 2.33091 15.8901 2.33351 14C2.33351 7.55654 7.55667 2.33337 14.0002 2.33337ZM15.4608 15.1667H10.2085L10.0895 15.1749C9.88008 15.2037 9.68816 15.3074 9.54924 15.4667C9.41031 15.626 9.33378 15.8303 9.33378 16.0417C9.33378 16.2531 9.41031 16.4574 9.54924 16.6167C9.68816 16.7761 9.88008 16.8797 10.0895 16.9085L10.2085 16.9167H15.4608L15.5787 16.9085C15.7881 16.8797 15.98 16.7761 16.1189 16.6167C16.2579 16.4574 16.3344 16.2531 16.3344 16.0417C16.3344 15.8303 16.2579 15.626 16.1189 15.4667C15.98 15.3074 15.7881 15.2037 15.5787 15.1749L15.4608 15.1667ZM17.7918 11.0834H10.2085L10.0895 11.0915C9.88008 11.1204 9.68816 11.224 9.54924 11.3834C9.41031 11.5427 9.33378 11.747 9.33378 11.9584C9.33378 12.1698 9.41031 12.374 9.54924 12.5334C9.68816 12.6927 9.88008 12.7964 10.0895 12.8252L10.2085 12.8334H17.7918L17.9108 12.8252C18.1203 12.7964 18.3122 12.6927 18.4511 12.5334C18.59 12.374 18.6666 12.1698 18.6666 11.9584C18.6666 11.747 18.59 11.5427 18.4511 11.3834C18.3122 11.224 18.1203 11.1204 17.9108 11.0915L17.7918 11.0834Z" fill={`url(#gradient_${id})`}/>
        <Defs>
          <LinearGradient id={`gradient_${id}`} x1="16.438" y1="15.9883" x2="-12.6306" y2="17.5194" gradientUnits="userSpaceOnUse">
            <Stop stopColor={colors.gradients.brandGradient.colors[0]}/>
            <Stop offset="1" stopColor={colors.gradients.brandGradient.colors[1]}/>
          </LinearGradient>
        </Defs>
      </Svg>);
    }
    return (<IconWithGradient id={id} {...props} defaultGradientKey="green">
      <Path fillRule="evenodd" clipRule="evenodd" d="M2 12.015C2 6.74712 6.21 2 12.02 2C17.7 2 22 6.65699 22 11.985C22 18.1642 16.96 22 12 22C10.36 22 8.54 21.5593 7.08 20.698C6.57 20.3876 6.14 20.1572 5.59 20.3375L3.57 20.9384C3.06 21.0986 2.6 20.698 2.75 20.1572L3.42 17.9139C3.53 17.6034 3.51 17.2729 3.35 17.0125C2.49 15.4301 2 13.6975 2 12.015ZM10.7 12.015C10.7 12.7261 11.27 13.2969 11.98 13.307C12.69 13.307 13.26 12.7261 13.26 12.025C13.26 11.314 12.69 10.7431 11.98 10.7431C11.28 10.7331 10.7 11.314 10.7 12.015ZM15.31 12.025C15.31 12.7261 15.88 13.307 16.59 13.307C17.3 13.307 17.87 12.7261 17.87 12.025C17.87 11.314 17.3 10.7431 16.59 10.7431C15.88 10.7431 15.31 11.314 15.31 12.025ZM7.37 13.307C6.67 13.307 6.09 12.7261 6.09 12.025C6.09 11.314 6.66 10.7431 7.37 10.7431C8.08 10.7431 8.65 11.314 8.65 12.025C8.65 12.7261 8.08 13.2969 7.37 13.307Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
