import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconMenuBold = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="peach">
      <Path d="M8.191 3.156a5.901 5.901 0 0 0-2.695 0 3.14 3.14 0 0 0-2.34 2.34 5.901 5.901 0 0 0 0 2.695 3.14 3.14 0 0 0 2.34 2.34 5.9 5.9 0 0 0 2.695 0 3.14 3.14 0 0 0 2.34-2.34 5.9 5.9 0 0 0 0-2.695 3.14 3.14 0 0 0-2.34-2.34Z" fill={`url(#gradient_${id})`}/>
      <Path d="M8.191 13.469a5.9 5.9 0 0 0-2.695 0 3.14 3.14 0 0 0-2.34 2.34 5.901 5.901 0 0 0 0 2.695 3.14 3.14 0 0 0 2.34 2.34 5.902 5.902 0 0 0 2.695 0 3.14 3.14 0 0 0 2.34-2.34 5.9 5.9 0 0 0 0-2.695 3.14 3.14 0 0 0-2.34-2.34Z" fill={`url(#gradient_${id})`}/>
      <Path d="M18.504 3.156a5.901 5.901 0 0 0-2.695 0 3.14 3.14 0 0 0-2.34 2.34 5.9 5.9 0 0 0 0 2.695 3.14 3.14 0 0 0 2.34 2.34 5.9 5.9 0 0 0 2.695 0 3.14 3.14 0 0 0 2.34-2.34 5.902 5.902 0 0 0 0-2.695 3.14 3.14 0 0 0-2.34-2.34Z" fill={`url(#gradient_${id})`}/>
      <Path d="M18.504 13.469a5.9 5.9 0 0 0-2.695 0 3.14 3.14 0 0 0-2.34 2.34 5.9 5.9 0 0 0 0 2.695 3.14 3.14 0 0 0 2.34 2.34 5.902 5.902 0 0 0 2.695 0 3.14 3.14 0 0 0 2.34-2.34 5.902 5.902 0 0 0 0-2.695 3.14 3.14 0 0 0-2.34-2.34Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
