import React, { memo } from 'react';
import { View } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { isWeb } from '../../constants';
const Header = () => {
    const styles = useStyles(styleSet);
    return (<>
      {!isWeb && (<View style={[styles.container, styles.firstContainer]}>
          <Text flex Text-24>
            Filter period
          </Text>
        </View>)}
      <View style={styles.container}>
        <Text TextThin-14 Secondary>
          Filtering the period won’t change your cycle set up or save it as your
          preference.
        </Text>
      </View>
    </>);
};
export default memo(Header);
const styleSet = createStyleSheets((colors) => ({
    container: {
        alignItems: 'center',
        flexDirection: 'row',
        paddingVertical: rem(8),
        paddingHorizontal: rem(16),
        ...(isWeb && {
            paddingBottom: rem(16),
            borderBottomWidth: rem(1),
            borderBottomColor: colors.borders.divider,
        }),
    },
    firstContainer: {
        marginTop: rem(16),
    },
}));
