import queryString from 'query-string';
import { prepareParams } from 'features/invest/utils/prepareParams';
import { CALL_API } from 'middleware/api';
import { UPDATE_SAVING_RULES } from './types';
export const setAccountStatus = (status) => ({
    type: 'POTS_SET_ACCOUNT_STATUS',
    status,
});
export const createPotsAccount = () => ({
    [CALL_API]: {
        endpoint: '/wealth/pots/account',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: ['CREATE_POTS_ACCOUNT_REQUEST', 'CREATE_POTS_ACCOUNT_SUCCESS', 'CREATE_POTS_ACCOUNT_FAILURE'],
    },
});
export const getPotsAccount = () => ({
    [CALL_API]: {
        endpoint: '/wealth/pots/account',
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        types: ['GET_POTS_ACCOUNT_REQUEST', 'GET_POTS_ACCOUNT_SUCCESS', 'GET_POTS_ACCOUNT_FAILURE'],
    },
});
export const cachePotsAccount = (account, hasFunded, hasCreatedPot, hasFundedPot, savingsPlan, savingsPlanMode, savingsPlanFrequency, savingsPlanTierFees) => ({
    type: 'GET_POTS_ACCOUNT_SUCCESS',
    payload: {
        account,
        hasFunded,
        savingsPlan,
        hasCreatedPot,
        hasFundedPot,
        savingsPlanMode,
        savingsPlanFrequency,
        savingsPlanTierFees,
    },
});
/**
 * `GET /wealth/pots`
 * @returns All user pots
 */
export function getPots() {
    return {
        [CALL_API]: {
            endpoint: '/wealth/pots?withDeleted=true',
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            types: ['GET_POTS_REQUEST', 'GET_POTS_SUCCESS', 'GET_POTS_ERROR'],
        },
    };
}
/**
 * Create a new pot.
 * `POST /wealth/pots`
 * @returns The newly created pot
 */
export function createPot(name, emoji, type, productId, goalAmount, goalDate) {
    return {
        [CALL_API]: {
            endpoint: '/wealth/pots',
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                pot: {
                    name,
                    emoji,
                    type,
                    productId,
                    ...(goalAmount && goalDate && { goal: { amount: Number(goalAmount), date: goalDate } }),
                },
            }),
            types: ['CREATE_POT_REQUEST', 'CREATE_POT_SUCCESS', 'CREATE_POT_ERROR'],
        },
    };
}
export function updatePot(pot) {
    return {
        [CALL_API]: {
            endpoint: `/wealth/pots`,
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ pot }),
            types: ['UPDATE_POT_REQUEST', 'UPDATE_POT_SUCCESS', 'UPDATE_POT_FAILURE'],
        },
    };
}
/**
 * Delete a pot.
 * `DELETE /wealth/pots/{potId}`
 * @param potId The ID of the pot to delete. You can even delete it multiple times - no error will be raised on backend.
 * @returns availableToInvest
 */
export function deletePot(potId) {
    return {
        [CALL_API]: {
            endpoint: `/wealth/pots/${potId}`,
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
            },
            types: ['DELETE_POT_REQUEST', 'DELETE_POT_SUCCESS', 'DELETE_POT_ERROR'],
            extra: { potId },
        },
    };
}
/**
 * Execute internal transfer between pots or to/from available balance.
 * `POST /wealth/pots/transfer`
 */
export function internalTransfer(fromPotId, toPotId, amount) {
    return {
        [CALL_API]: {
            endpoint: `/wealth/pots/${fromPotId}/transfer`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ amount, toPotId }),
            types: ['INTERNAL_TRANSFER_REQUEST', 'INTERNAL_TRANSFER_SUCCESS', 'INTERNAL_TRANSFER_ERROR'],
        },
    };
}
/**
 * Execute deposit to pot
 * `POST /wealth/pots/${potId}/deposit
 */
export function depositPot(potId, amount) {
    return {
        [CALL_API]: {
            endpoint: `/wealth/pots/${potId}/deposit`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ amount }),
            types: ['DEPOSIT_POT_REQUEST', 'DEPOSIT_POT_SUCCESS', 'DEPOSIT_POT_ERROR'],
        },
    };
}
/**
 * Execute withdraw from pot
 * `POST /wealth/pots/wtihdraw`
 */
export function withdrawPot(potId, amount) {
    return {
        [CALL_API]: {
            endpoint: `/wealth/pots/${potId}/withdraw`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ amount }),
            types: ['WITHDRAW_POT_REQUEST', 'WITHDRAW_POT_SUCCESS', 'WITHDRAW_POT_ERROR'],
        },
    };
}
export function getPotActivities(potId, params) {
    return {
        [CALL_API]: {
            muteAlert: true,
            endpoint: `/wealth/pots/${potId}/activity?${queryString.stringify(prepareParams(params))}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            extra: { potId },
            types: ['GET_POT_ACTIVITIES_REQUEST', 'GET_POT_ACTIVITIES_SUCCESS', 'GET_POT_ACTIVITIES_FAILURE'],
        },
    };
}
export const getActivities = (params) => ({
    [CALL_API]: {
        endpoint: `/wealth/pots/activity?${queryString.stringify(prepareParams(params))}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        types: ['GET_ALL_POT_ACTIVITIES_REQUEST', 'GET_ALL_POT_ACTIVITIES_SUCCESS', 'GET_ALL_POT_ACTIVITIES_FAILURE'],
    },
});
export const getPotsInformation = () => ({
    [CALL_API]: {
        endpoint: '/wealth/pots/products?types[]=BASIC&types[]=INTEREST&types[]=INTEREST_NOTICE',
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        types: ['GET_POTS_INFORMATION_REQUEST', 'GET_POTS_INFORMATION_SUCCESS', 'GET_POTS_INFORMATION_FAILURE'],
    },
});
export function createSavingsPlan(bankAccount, allowOverdraft, distribution, rules) {
    const destinations = [];
    Object.keys(distribution).forEach((id) => {
        if (distribution[id]) {
            destinations.push({
                potId: id,
                percentage: distribution[id],
            });
        }
    });
    return {
        [CALL_API]: {
            endpoint: '/wealth/pots/savings-plan',
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                rules,
                destinations,
                allowOverdraft,
                accountId: bankAccount.id,
            }),
            types: ['CREATE_SAVINGS_PLAN_REQUEST', 'CREATE_SAVINGS_PLAN_SUCCESS', 'CREATE_SAVINGS_PLAN_ERROR'],
        },
    };
}
const getPatchData = (data) => {
    if (data.distribution) {
        const { distribution, ...otherData } = data;
        const destinations = [];
        Object.keys(distribution).forEach((id) => {
            if (distribution[id]) {
                destinations.push({
                    potId: id,
                    percentage: distribution[id],
                });
            }
        });
        return {
            destinations,
            ...otherData,
        };
    }
    return data;
};
export const editSavingsPlan = (id, data, skipSet) => {
    const patchData = getPatchData(data);
    return {
        [CALL_API]: {
            endpoint: `/wealth/pots/savings-plan/${id}`,
            method: 'PATCH',
            body: JSON.stringify(patchData),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            types: ['EDIT_SAVINGS_PLAN_REQUEST', 'EDIT_SAVINGS_PLAN_SUCCESS', 'EDIT_SAVINGS_PLAN_FAILURE'],
            extra: { savingsPlanId: id, skipSet },
        },
    };
};
export const getAutosaveActivities = (paging) => ({
    [CALL_API]: {
        endpoint: `/wealth/pots/savings-plan/plan-activity?${queryString.stringify(prepareParams(paging))}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        extra: paging,
        types: ['GET_AUTOSAVE_ACTIVITIES_REQUEST', 'GET_AUTOSAVE_ACTIVITIES_SUCCESS', 'GET_AUTOSAVE_ACTIVITIES_FAILURE'],
    },
});
export const editAutosaveUpcomingPayment = (id, skip) => ({
    [CALL_API]: {
        endpoint: `/wealth/pots/savings-plan/upcoming-payment/${id}`,
        method: 'PATCH',
        body: JSON.stringify({ skip }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            'EDIT_AUTOSAVE_UPCOMING_PAYMENT_REQUEST',
            'EDIT_AUTOSAVE_UPCOMING_PAYMENT_SUCCESS',
            'EDIT_AUTOSAVE_UPCOMING_PAYMENT_FAILURE',
        ],
    },
});
export const updateSavingRules = (payload) => ({
    payload,
    type: UPDATE_SAVING_RULES,
});
export const updateSavingRulesFailure = () => ({
    payload: 'EventBus.Pots.UPDATE_SAVING_RULES_FAILURE',
    type: 'EVENT_BUS.EVENT',
});
