import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { rem } from 'design-system/values';
import { Amplitude } from 'utils';
import { useAppSelector } from 'store/hooks';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import TouchableBounceCard from 'utils/packages/TouchableBounceCard';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import BudgetingCard from './BudgetingCard';
const BudgetingCardContainer = ({ style, disabled }) => {
    const navigation = useMainStackNavigation();
    const thisMonth = useAppSelector((store) => store.feed.feed.cards.thisMonth);
    const { isUnlockedForSpace } = useShouldShowUpgrade({
        benefitId: 'budgeting',
    });
    const onPressBudgeting = useCallback(() => {
        if (isUnlockedForSpace && thisMonth?.budget) {
            navigation.navigate('Budgeting');
        }
        else {
            navigation.navigate('EditBudget', {
                flow: 'Set',
            });
            Amplitude.logEvent('Feed.openBudgetingSetup');
        }
    }, [isUnlockedForSpace, thisMonth?.budget]);
    return (<TouchableBounceCard onDark noMargin withPadding disabled={disabled} onPress={onPressBudgeting} style={[styles.cardWrapper, style]}>
      <BudgetingCard thisMonth={isUnlockedForSpace ? thisMonth : undefined}/>
    </TouchableBounceCard>);
};
const styles = StyleSheet.create({
    cardWrapper: {
        flex: 1,
        flexGrow: 1,
        flexShrink: 1,
        marginStart: rem(4.5),
    },
});
export default BudgetingCardContainer;
