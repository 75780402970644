import React from 'react';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import { IconBell } from 'design-system/icons';
import { AppView } from 'design-system/AppView';
import EmptyStateCard from 'design-system/EmptyStateCard';
const iconSize = rem(32);
export const EnableNotificationsView = ({ onPressEnable }) => (<AppView>
    <Spacer h={8}/>
    <EmptyStateCard onPress={onPressEnable} title="Notifications are disabled" btnTitle="Enable push notifications" subTitle="Enable notifications to receive updates" Icon={<IconBell width={iconSize} height={iconSize} colorKey="brand"/>}/>
  </AppView>);
