import React from 'react';
import { Path, Svg } from 'react-native-svg';
import { rem } from 'design-system/values';
export const IconMagicBall = () => {
    const width = rem(44);
    const height = rem(44);
    return (<Svg width={width} height={height} viewBox="0 0 44 44" fill="none">
      <Path d="M22.0002 37.6899C32.408 37.6899 40.8452 29.2528 40.8452 18.845C40.8452 8.43718 32.408 0 22.0002 0C11.5925 0 3.15527 8.43718 3.15527 18.845C3.15527 29.2528 11.5925 37.6899 22.0002 37.6899Z" fill="#DAD2FF"/>
      <Path d="M28.9857 11.858L27.8555 11.5075C26.2234 11.0015 24.9455 9.72367 24.4395 8.09161L24.0891 6.96139C24.0549 6.85106 23.9529 6.77588 23.8373 6.77588C23.7218 6.77588 23.6197 6.85106 23.5855 6.96139L23.2351 8.09161C22.729 9.72367 21.4512 11.0016 19.8191 11.5075L18.6889 11.858C18.5786 11.8922 18.5034 11.9942 18.5034 12.1098C18.5034 12.2252 18.5786 12.3273 18.6889 12.3616L19.8191 12.712C21.4512 13.218 22.7291 14.4959 23.2351 16.1279L23.5855 17.2582C23.6197 17.3685 23.7218 17.4437 23.8373 17.4437C23.9528 17.4437 24.0549 17.3685 24.0891 17.2582L24.4395 16.1279C24.9456 14.4959 26.2234 13.218 27.8555 12.712L28.9857 12.3616C29.096 12.3273 29.1712 12.2253 29.1712 12.1098C29.1712 11.9942 29.0959 11.8921 28.9857 11.858Z" fill="#C3B9F9"/>
      <Path d="M30.2221 7.54688C30.3808 7.4423 30.3808 7.21015 30.2221 7.10556C29.8124 6.8357 29.4236 6.52177 29.0625 6.16343C28.7014 5.8051 28.385 5.41924 28.1131 5.01264C28.0077 4.85516 27.7738 4.85516 27.6684 5.01264C27.3965 5.41916 27.0801 5.8051 26.719 6.16343C26.3579 6.52177 25.969 6.83578 25.5594 7.10556C25.4008 7.21015 25.4008 7.4423 25.5594 7.54688C25.9691 7.81675 26.3579 8.13068 26.7191 8.48902C27.0801 8.84735 27.3966 9.23321 27.6685 9.63981C27.7738 9.79729 28.0078 9.79729 28.1131 9.63981C28.3851 9.23329 28.7014 8.84735 29.0626 8.48902C29.4236 8.13068 29.8124 7.81675 30.2221 7.54688Z" fill="#C3B9F9"/>
      <Path d="M18.3443 20.92L17.3731 20.619C15.9707 20.1842 14.8727 19.0862 14.4379 17.6838L14.1368 16.7126C14.1074 16.6178 14.0197 16.5532 13.9205 16.5532C13.8212 16.5532 13.7336 16.6179 13.7042 16.7126L13.4031 17.6838C12.9683 19.0862 11.8704 20.1842 10.468 20.6191L9.49677 20.9201C9.40195 20.9495 9.3374 21.0372 9.3374 21.1364C9.3374 21.2357 9.40204 21.3233 9.49685 21.3527L10.468 21.6538C11.8704 22.0886 12.9685 23.1866 13.4033 24.589L13.7044 25.5602C13.7338 25.655 13.8215 25.7195 13.9207 25.7195C14.0199 25.7195 14.1076 25.6549 14.137 25.5601L14.438 24.5889C14.8728 23.1865 15.9708 22.0884 17.3732 21.6536L18.3444 21.3525C18.4392 21.3231 18.5038 21.2354 18.5038 21.1362C18.5037 21.0371 18.4391 20.9494 18.3443 20.92Z" fill="#C3B9F9"/>
      <Path d="M33.9058 21.8961L33.0702 21.6371C31.8637 21.263 30.919 20.3183 30.545 19.1118L30.2859 18.2763C30.2606 18.1948 30.1852 18.1392 30.0998 18.1392C30.0144 18.1392 29.939 18.1947 29.9137 18.2763L29.6546 19.1119C29.2806 20.3184 28.3359 21.2631 27.1294 21.6371L26.2939 21.8962C26.2124 21.9215 26.1567 21.9969 26.1567 22.0823C26.1567 22.1677 26.2123 22.2431 26.2939 22.2684L27.1295 22.5275C28.336 22.9015 29.2807 23.8462 29.6547 25.0527L29.9138 25.8882C29.9391 25.9697 30.0145 26.0254 30.0999 26.0254C30.1853 26.0254 30.2607 25.9698 30.286 25.8882L30.5451 25.0527C30.9191 23.8462 31.8638 22.9015 33.0703 22.5275L33.9058 22.2684C33.9873 22.2431 34.043 22.1677 34.043 22.0823C34.0429 21.9968 33.9873 21.9214 33.9058 21.8961Z" fill="#C3B9F9"/>
      <Path d="M20.5628 26.7888C20.7027 26.6966 20.7027 26.4919 20.5628 26.3998C20.2017 26.1619 19.8589 25.8851 19.5406 25.5692C19.2223 25.2533 18.9434 24.9132 18.7037 24.5548C18.6108 24.416 18.4046 24.416 18.3116 24.5548C18.072 24.9132 17.7931 25.2533 17.4748 25.5692C17.1565 25.8851 16.8138 26.1619 16.4526 26.3998C16.3127 26.4919 16.3127 26.6966 16.4526 26.7888C16.8137 27.0267 17.1565 27.3034 17.4748 27.6193C17.7931 27.9352 18.072 28.2754 18.3117 28.6337C18.4046 28.7726 18.6108 28.7726 18.7038 28.6337C18.9434 28.2754 19.2223 27.9352 19.5406 27.6193C19.8589 27.3034 20.2017 27.0267 20.5628 26.7888Z" fill="#C3B9F9"/>
      <Path d="M13.057 28.4001C13.1969 28.308 13.1969 28.1032 13.057 28.0111C12.6959 27.7732 12.3531 27.4965 12.0348 27.1806C11.7165 26.8647 11.4376 26.5245 11.1978 26.1662C11.105 26.0273 10.8987 26.0273 10.8058 26.1662C10.5661 26.5245 10.2872 26.8647 9.96893 27.1806C9.65063 27.4965 9.30789 27.7732 8.94673 28.0111C8.80682 28.1032 8.80682 28.308 8.94673 28.4001C9.30781 28.638 9.65063 28.9147 9.96893 29.2306C10.2872 29.5465 10.5661 29.8867 10.8059 30.245C10.8987 30.3839 11.105 30.3839 11.1979 30.245C11.4376 29.8867 11.7165 29.5465 12.0348 29.2306C12.3531 28.9147 12.6958 28.638 13.057 28.4001Z" fill="#C3B9F9"/>
      <Path d="M40.8447 18.8451C40.8447 29.2531 32.4082 37.6898 22.0001 37.6898C12.3135 37.6899 4.33482 30.3829 3.2749 20.9796C5.72779 26.9688 11.6147 31.1884 18.4873 31.1884C27.562 31.1884 34.9188 23.8316 34.9188 14.7568C34.9188 8.76132 31.7083 3.51615 26.9121 0.646973C34.9378 2.80822 40.8447 10.136 40.8447 18.8451Z" fill="#C3B9F9"/>
      <Path d="M40.8449 18.8448C40.845 29.2527 32.4084 37.6894 22.0004 37.6895C14.7857 37.6895 8.51769 33.6355 5.35156 27.6808C9.10603 31.246 14.1801 33.4337 19.7675 33.4337C30.371 33.4336 39.1334 25.5509 40.5163 15.3267C40.7327 16.4661 40.8449 17.6419 40.8449 18.8448Z" fill="#AB97F2"/>
      <Path d="M35.7621 41.3732L8.23908 41.3734C7.73921 41.3734 7.33398 40.9682 7.33398 40.4683V36.8636C7.33398 35.8638 8.14444 35.0533 9.14418 35.0533L34.857 35.0532C35.8567 35.0532 36.6672 35.8637 36.6672 36.8634V40.4681C36.6671 40.968 36.2619 41.3732 35.7621 41.3732Z" fill="#B55434"/>
      <Path d="M36.6671 36.8625V40.4676C36.6671 40.9672 36.2616 41.3727 35.762 41.3727L26.4238 41.3728C29.6162 40.8804 32.1152 38.289 32.4673 35.0522H34.8568C35.857 35.0523 36.6671 35.8633 36.6671 36.8625Z" fill="#A1412B"/>
      <Path d="M36.6671 36.8628V40.4679C36.6671 40.9675 36.2616 41.373 35.762 41.373L26.4238 41.3731C27.4059 41.2219 28.3219 40.8717 29.1301 40.3648C29.6098 40.5295 30.1249 40.6182 30.6597 40.6182C33.2692 40.6182 35.3835 38.5029 35.3835 35.8943C35.3835 35.6282 35.3618 35.3667 35.3192 35.1123C36.095 35.3169 36.6671 36.0229 36.6671 36.8628Z" fill="#7F392C"/>
      <Path d="M28.4603 36.9305C28.4603 35.8937 27.6198 35.0532 26.583 35.0532L16.4109 35.0533C15.3742 35.0533 14.5337 35.8938 14.5337 36.9305C14.5337 37.9673 15.3742 38.8078 16.4109 38.8078L26.583 38.8077C27.6198 38.8077 28.4603 37.9672 28.4603 36.9305Z" fill="#DB765A"/>
      <Path d="M24.0022 35.0532H20.5076C19.4708 35.0532 18.6304 35.8937 18.6304 36.9305C18.6304 37.9672 19.4708 38.8077 20.5076 38.8077H24.0022C25.039 38.8077 25.8795 37.9672 25.8795 36.9305C25.8795 35.8937 25.039 35.0531 24.0022 35.0532Z" fill="#EF927D"/>
      <Path d="M36.7967 43.9995L7.20362 43.9997C6.09989 43.9997 5.20508 43.105 5.20508 42.0012C5.20508 40.8974 6.0998 40.0026 7.20362 40.0026L36.7968 40.0024C37.9005 40.0024 38.7953 40.8972 38.7953 42.001C38.7952 43.1048 37.9005 43.9995 36.7967 43.9995Z" fill="#FFC751"/>
      <Path d="M25.1259 40.0024L11.8486 40.0025C10.7449 40.0025 9.8501 40.8973 9.8501 42.0011C9.8501 43.1048 10.7449 43.9996 11.8486 43.9996L25.1259 43.9995C26.2297 43.9995 27.1245 43.1047 27.1245 42.001C27.1245 40.8973 26.2297 40.0024 25.1259 40.0024Z" fill="#FFE059"/>
      <Path d="M18.72 40.0024H14.9756C13.8719 40.0024 12.9771 40.8973 12.9771 42.001C12.9771 43.1047 13.8719 43.9995 14.9756 43.9995H18.72C19.8238 43.9995 20.7186 43.1047 20.7186 42.001C20.7186 40.8973 19.8238 40.0024 18.72 40.0024Z" fill="#FFF370"/>
      <Path d="M38.7957 42.0014C38.7957 42.5536 38.5722 43.0532 38.2102 43.4143C37.849 43.7763 37.3494 43.9999 36.7972 43.9999H30.7085C32.6201 43.3464 34.1516 41.8746 34.8856 40.002H36.7972C37.9006 40.002 38.7957 40.8972 38.7957 42.0014Z" fill="#FFAF40"/>
      <Path d="M12.4233 38.3968C12.9224 38.3968 13.327 37.9922 13.327 37.4931C13.327 36.994 12.9224 36.5894 12.4233 36.5894C11.9241 36.5894 11.5195 36.994 11.5195 37.4931C11.5195 37.9922 11.9241 38.3968 12.4233 38.3968Z" fill="#FFC751"/>
      <Path d="M12.2396 37.9993C12.6052 37.9993 12.9016 37.7029 12.9016 37.3373C12.9016 36.9717 12.6052 36.6753 12.2396 36.6753C11.874 36.6753 11.5776 36.9717 11.5776 37.3373C11.5776 37.7029 11.874 37.9993 12.2396 37.9993Z" fill="#FFE059"/>
      <Path d="M31.5771 38.3968C32.0762 38.3968 32.4808 37.9922 32.4808 37.4931C32.4808 36.994 32.0762 36.5894 31.5771 36.5894C31.078 36.5894 30.6733 36.994 30.6733 37.4931C30.6733 37.9922 31.078 38.3968 31.5771 38.3968Z" fill="#FFC751"/>
      <Path d="M31.3934 37.9993C31.759 37.9993 32.0554 37.7029 32.0554 37.3373C32.0554 36.9717 31.759 36.6753 31.3934 36.6753C31.0278 36.6753 30.7314 36.9717 30.7314 37.3373C30.7314 37.7029 31.0278 37.9993 31.3934 37.9993Z" fill="#FFE059"/>
      <Path d="M7.61409 13.8629C9.50247 8.42487 14.3924 4.37893 20.2918 3.7173C21.2075 3.6146 21.8994 2.84006 21.8994 1.91867C21.8994 0.834233 20.9533 -0.000827558 19.8756 0.120047C12.5714 0.939505 6.53085 5.93375 4.19498 12.6613C3.84273 13.6758 4.45225 14.7718 5.49819 15.0153C6.39378 15.2237 7.31251 14.7315 7.61409 13.8629Z" fill="#EAE9FF"/>
      <Path d="M11.2693 8.05736C13.092 6.24433 15.37 4.88865 17.9148 4.17892C18.7667 3.94129 19.3249 3.12715 19.2266 2.24811C19.2253 2.23611 19.224 2.22411 19.2226 2.2121C19.0993 1.10966 18.0155 0.390419 16.9466 0.687289C13.8004 1.5611 10.9857 3.23079 8.73243 5.46568C8.05322 6.13931 8.01139 7.22332 8.64568 7.9394L8.67002 7.96691C9.35086 8.73554 10.5413 8.78149 11.2693 8.05736Z" fill="#FAFAFF"/>
      <Path d="M27.9667 10.8141L26.8364 10.4636C25.2044 9.95756 23.9265 8.67972 23.4205 7.04766L23.07 5.91745C23.0358 5.80712 22.9338 5.73193 22.8183 5.73193C22.7028 5.73193 22.6007 5.80712 22.5665 5.91745L22.216 7.04766C21.71 8.67972 20.4322 9.95764 18.8001 10.4636L17.6699 10.8141C17.5596 10.8483 17.4844 10.9503 17.4844 11.0658C17.4844 11.1813 17.5596 11.2834 17.6699 11.3176L18.8001 11.6681C20.4322 12.1741 21.7101 13.4519 22.216 15.084L22.5665 16.2142C22.6007 16.3245 22.7027 16.3997 22.8183 16.3997C22.9337 16.3997 23.0358 16.3245 23.07 16.2142L23.4205 15.084C23.9265 13.4519 25.2044 12.174 26.8364 11.6681L27.9667 11.3176C28.077 11.2834 28.1522 11.1814 28.1522 11.0658C28.1522 10.9503 28.077 10.8483 27.9667 10.8141Z" fill="#FFE059"/>
      <Path d="M28.1517 11.0651C28.1517 11.1808 28.0766 11.2829 27.9659 11.3173L26.8367 11.6678C25.2045 12.1733 23.9264 13.4513 23.4208 15.0836L23.0703 16.214C23.0358 16.3247 22.9337 16.3998 22.8182 16.3998C22.7025 16.3998 22.6004 16.3248 22.5661 16.214L22.2155 15.0836C22.0445 14.5301 21.7838 14.0172 21.4492 13.5609C23.2267 13.2447 24.5748 11.6912 24.5747 9.82399C24.5747 9.51281 24.5379 9.21019 24.4664 8.91992C25.0888 9.63334 25.9043 10.1745 26.8368 10.4636L27.966 10.8141C28.0766 10.8487 28.1517 10.9495 28.1517 11.0651Z" fill="#FFC751"/>
      <Path d="M29.9765 6.51319C30.1352 6.40861 30.1352 6.17646 29.9765 6.07187C29.5668 5.80201 29.178 5.48808 28.8169 5.12974C28.4558 4.7714 28.1394 4.38555 27.8675 3.97895C27.7621 3.82147 27.5281 3.82147 27.4228 3.97895C27.1509 4.38546 26.8345 4.7714 26.4734 5.12974C26.1122 5.48808 25.7234 5.80209 25.3138 6.07187C25.1552 6.17646 25.1552 6.40861 25.3138 6.51319C25.7235 6.78306 26.1123 7.09699 26.4735 7.45533C26.8345 7.81366 27.1509 8.19952 27.4229 8.60612C27.5282 8.76359 27.7622 8.76359 27.8675 8.60612C28.1395 8.1996 28.4558 7.81366 28.817 7.45533C29.1781 7.09699 29.5668 6.78306 29.9765 6.51319Z" fill="#FAFAFF"/>
      <Path d="M29.9765 6.51316C29.5669 6.78302 29.1781 7.09687 28.8169 7.45537C28.4556 7.81388 28.1394 8.19974 27.8675 8.60617C27.762 8.76339 27.5284 8.76339 27.423 8.60617C27.1511 8.19974 26.8348 7.81388 26.4736 7.45537C26.4266 7.40874 26.3789 7.36279 26.3306 7.31744C26.3943 7.32276 26.4588 7.32541 26.524 7.32541C27.7635 7.32541 28.774 6.34856 28.8157 5.12842C28.8164 5.1291 28.8164 5.1291 28.8171 5.12979C29.1783 5.4883 29.5671 5.80214 29.9767 6.07201C30.1349 6.17659 30.1349 6.40848 29.9765 6.51316Z" fill="#EAE9FF"/>
      <Path d="M17.3255 19.8766L16.3543 19.5755C14.9519 19.1407 13.8538 18.0427 13.419 16.6403L13.1179 15.6691C13.0885 15.5743 13.0008 15.5098 12.9016 15.5098C12.8024 15.5098 12.7148 15.5744 12.6853 15.6691L12.3843 16.6403C11.9495 18.0427 10.8515 19.1408 9.44909 19.5756L8.47772 19.8766C8.38291 19.906 8.31836 19.9937 8.31836 20.0929C8.31836 20.1921 8.383 20.2798 8.47781 20.3092L9.449 20.6102C10.8514 21.045 11.9495 22.143 12.3843 23.5454L12.6853 24.5166C12.7148 24.6114 12.8024 24.676 12.9016 24.676C13.0009 24.676 13.0885 24.6113 13.1179 24.5165L13.419 23.5453C13.8538 22.1429 14.9518 21.0449 16.3542 20.6101L17.3254 20.309C17.4202 20.2796 17.4847 20.1919 17.4847 20.0927C17.4848 19.9936 17.4203 19.906 17.3255 19.8766Z" fill="#FAFAFF"/>
      <Path d="M17.4844 20.0924C17.4844 20.1918 17.4199 20.2795 17.3247 20.309L16.3545 20.6103C14.9519 21.0446 13.8538 22.1429 13.4194 23.5455L13.1182 24.5168C13.0886 24.612 13.0008 24.6764 12.9014 24.6764C12.8021 24.6764 12.7144 24.612 12.6848 24.5168L12.3836 23.5455C12.2366 23.0698 12.0125 22.6291 11.7251 22.2369C13.2524 21.9653 14.4108 20.6303 14.4108 19.0259C14.4108 18.7585 14.3791 18.4985 14.3178 18.249C14.8527 18.8621 15.5534 19.3271 16.3546 19.5755L17.3248 19.8767C17.4199 19.9064 17.4844 19.993 17.4844 20.0924Z" fill="#EAE9FF"/>
      <Path d="M32.8867 20.8532L32.0512 20.5941C30.8447 20.2201 29.9 19.2754 29.5259 18.0688L29.2669 17.2334C29.2416 17.1518 29.1661 17.0962 29.0808 17.0962C28.9954 17.0962 28.9199 17.1517 28.8947 17.2334L28.6356 18.0689C28.2616 19.2754 27.3169 20.2201 26.1103 20.5942L25.2749 20.8532C25.1933 20.8785 25.1377 20.954 25.1377 21.0393C25.1377 21.1247 25.1932 21.2002 25.2749 21.2255L26.1104 21.4845C27.3169 21.8585 28.2617 22.8033 28.6357 24.0098L28.8947 24.8453C28.92 24.9268 28.9955 24.9824 29.0809 24.9824C29.1662 24.9824 29.2417 24.9269 29.267 24.8453L29.526 24.0098C29.9001 22.8033 30.8448 21.8585 32.0513 21.4845L32.8868 21.2255C32.9683 21.2002 33.0239 21.1247 33.0239 21.0393C33.0238 20.9539 32.9682 20.8784 32.8867 20.8532Z" fill="#FAFAFF"/>
      <Path d="M33.0236 21.0391C33.0236 21.1245 32.9681 21.2 32.8862 21.2254L32.0515 21.4846C30.8448 21.8584 29.9001 22.8031 29.5264 24.0097L29.2672 24.8454C29.2418 24.9273 29.1663 24.9827 29.0808 24.9827C28.9954 24.9827 28.9199 24.9273 28.8945 24.8454L28.6353 24.0097C28.509 23.6006 28.3162 23.2214 28.0688 22.884C29.3828 22.6503 30.3794 21.5018 30.3794 20.1214C30.3794 19.8914 30.3522 19.6677 30.2994 19.4531C30.7595 19.9805 31.3625 20.3806 32.0517 20.5943L32.8864 20.8535C32.968 20.879 33.0236 20.9536 33.0236 21.0391Z" fill="#EAE9FF"/>
      <Path d="M20.3172 25.7546C20.4571 25.6624 20.4571 25.4577 20.3172 25.3656C19.9561 25.1277 19.6133 24.851 19.295 24.5351C18.9767 24.2192 18.6978 23.879 18.4581 23.5207C18.3652 23.3818 18.159 23.3818 18.066 23.5207C17.8264 23.879 17.5475 24.2192 17.2292 24.5351C16.9109 24.851 16.5681 25.1277 16.207 25.3656C16.0671 25.4577 16.0671 25.6624 16.207 25.7546C16.5681 25.9925 16.9109 26.2692 17.2292 26.5851C17.5475 26.901 17.8264 27.2412 18.0661 27.5995C18.159 27.7384 18.3652 27.7384 18.4582 27.5995C18.6978 27.2412 18.9767 26.901 19.295 26.5851C19.6133 26.2692 19.9561 25.9925 20.3172 25.7546Z" fill="#FFE059"/>
      <Path d="M20.3171 25.7547C19.956 25.9926 19.6133 26.2692 19.2949 26.5853C18.9764 26.9013 18.6976 27.2414 18.4579 27.5998C18.365 27.7384 18.159 27.7384 18.0661 27.5998C17.8264 27.2414 17.5476 26.9013 17.2291 26.5854C17.1877 26.5442 17.1456 26.5037 17.103 26.4638C17.1593 26.4685 17.2161 26.4708 17.2735 26.4708C18.3662 26.4708 19.257 25.6097 19.2937 24.5342L19.2949 24.5354C19.6134 24.8514 19.956 25.1281 20.3171 25.366C20.4568 25.4581 20.4568 25.6624 20.3171 25.7547Z" fill="#FFC751"/>
      <Path d="M12.8114 27.3659C12.9513 27.2738 12.9513 27.0691 12.8114 26.9769C12.4503 26.739 12.1075 26.4623 11.7892 26.1464C11.4709 25.8305 11.192 25.4903 10.9522 25.132C10.8594 24.9931 10.6531 24.9931 10.5602 25.132C10.3205 25.4903 10.0416 25.8305 9.72332 26.1464C9.40502 26.4623 9.06229 26.739 8.70112 26.9769C8.56122 27.0691 8.56122 27.2738 8.70112 27.3659C9.0622 27.6038 9.40502 27.8805 9.72332 28.1965C10.0416 28.5124 10.3205 28.8525 10.5603 29.2109C10.6531 29.3497 10.8594 29.3497 10.9523 29.2109C11.192 28.8525 11.4709 28.5124 11.7892 28.1965C12.1075 27.8805 12.4502 27.6038 12.8114 27.3659Z" fill="#FFE059"/>
      <Path d="M12.8112 27.3655C12.4501 27.6034 12.1074 27.88 11.789 28.1961C11.4705 28.5121 11.1918 28.8523 10.9521 29.2106C10.8591 29.3492 10.6531 29.3492 10.5602 29.2106C10.3205 28.8523 10.0417 28.5122 9.72327 28.1962C9.68187 28.155 9.63977 28.1146 9.59717 28.0746C9.6534 28.0793 9.71024 28.0817 9.76768 28.0817C10.8603 28.0817 11.7511 27.2205 11.7878 26.145L11.789 26.1462C12.1075 26.4622 12.4501 26.7389 12.8112 26.9768C12.9509 27.0689 12.9509 27.2733 12.8112 27.3655Z" fill="#FFC751"/>
    </Svg>);
};
export default IconMagicBall;
