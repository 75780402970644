import React from 'react';
import { Path, Svg } from 'react-native-svg';
export const CurrencySign = ({ currency, size, color, style, }) => {
    switch (currency) {
        case 'GBP':
            return (<Svg width={(size * 47) / 51} height={size} viewBox="0 0 47 51" style={style}>
          <Path d="m 22.916664,21.534937 c 0,0.554128 0.453404,1.003306 1.012733,1.003306 h 10.078704 c 0.559332,0 1.012732,0.449178 1.012732,1.003306 v 6.734181 c 0,0.554128 -0.4534,1.003306 -1.012732,1.003306 H 23.900838 c -0.547986,0 -0.99592,0.431921 -1.019822,0.974412 -0.0618,1.402118 -0.171439,2.883296 -0.328932,4.443436 -0.111383,1.47105 -0.251074,2.843672 -0.419169,4.118167 -0.0802,0.60881 0.39385,1.155209 1.013541,1.155209 H 39.987272 C 40.5466,41.97026 41,42.419438 41,42.973565 v 7.023131 c 0,0.554124 -0.4534,1.003309 -1.012728,1.003309 H 11.276331 c -0.648991,0 -1.130177,-0.59647 -0.995354,-1.22544 0.725794,-3.38575 1.218943,-6.540945 1.479439,-9.465782 0.255345,-2.866746 0.436154,-5.530622 0.542468,-7.991427 0.02457,-0.567368 -0.434785,-1.03832 -1.008022,-1.03832 H 7.0127284 c -0.5593135,0 -1.0127283,-0.449178 -1.0127283,-1.003306 v -6.734181 c 0,-0.554128 0.4534148,-1.003306 1.0127321,-1.003306 h 4.3182848 c 0.559321,0 1.012732,-0.449178 1.012732,-1.003306 V 18.70963 c 0,-3.611898 0.437499,-6.597732 1.312501,-8.9575071 0.874999,-2.4079297 2.065973,-4.3342757 3.572917,-5.7790381 1.555555,-1.4447584 3.402777,-2.4560883 5.541668,-3.03398946 2.138888,-0.62606368 4.472221,-0.93909552085107 7,-0.93909552085107 1.944446,0 3.767361,0.19264254085107 5.46875,0.57790494085107 1.401313,0.31732916 2.769716,0.73267284 4.105103,1.24602344 0.486826,0.1871244 0.733017,0.7198602 0.575535,1.213047 L 37.851321,9.5183137 C 37.665784,10.099669 37.004877,10.381097 36.434505,10.15194 35.697845,9.8559654 34.983563,9.6263893 34.291668,9.4631704 33.319445,9.2223767 32.12847,9.0779042 30.718751,9.0297455 c -1.166668,0 -2.236112,0.1204157 -3.208335,0.3611717 -0.923611,0.2407937 -1.725695,0.6982978 -2.406251,1.3725208 -0.680554,0.674218 -1.215277,1.589234 -1.604164,2.74504 -0.388891,1.15581 -0.583337,2.600569 -0.583337,4.33428 z" fill={color}/>
        </Svg>);
        default:
            return null;
    }
};
export default CurrencySign;
