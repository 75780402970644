export const GET_MONITORED_ACC_REQUEST = 'GET_MONITORED_ACC_REQUEST';
export const GET_MONITORED_ACC_SUCCESS = 'GET_MONITORED_ACC_SUCCESS';
export const GET_MONITORED_ACC_FAILURE = 'GET_MONITORED_ACC_FAILURE';
export const DELETE_MONITORED_ACC_REQUEST = 'DELETE_MONITORED_ACC_REQUEST';
export const DELETE_MONITORED_ACC_SUCCESS = 'DELETE_MONITORED_ACC_SUCCESS';
export const DELETE_MONITORED_ACC_FAILURE = 'DELETE_MONITORED_ACC_FAILURE';
export const SAVE_MONITORED_PHONE_REQUEST = 'SAVE_MONITORED_PHONE_REQUEST';
export const SAVE_MONITORED_PHONE_SUCCESS = 'SAVE_MONITORED_PHONE_SUCCESS';
export const SAVE_MONITORED_PHONE_FAILURE = 'SAVE_MONITORED_PHONE_FAILURE';
export const SAVE_MONITORED_EMAIL_REQUEST = 'SAVE_MONITORED_EMAIL_REQUEST';
export const SAVE_MONITORED_EMAIL_SUCCESS = 'SAVE_MONITORED_EMAIL_SUCCESS';
export const SAVE_MONITORED_EMAIL_FAILURE = 'SAVE_MONITORED_EMAIL_FAILURE';
export const GET_BREACHES_REQUEST = 'GET_BREACHES_REQUEST';
export const GET_BREACHES_SUCCESS = 'GET_BREACHES_SUCCESS';
export const GET_BREACHES_FAILURE = 'GET_BREACHES_FAILURE';
export const RESET_BREACHES = 'RESET_BREACHES';
