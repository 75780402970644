import React, { useMemo } from 'react';
import { View } from 'react-native';
import TouchableBounce from 'utils/packages/TouchableBounce';
import Spacer from 'design-system/Spacer';
import Text from 'design-system/Text';
import { useCategoryColors } from 'hooks/useCategory';
import useColors from 'hooks/useColors';
import { useStatusBarHeight } from 'hooks/useHeaderHeight';
import useIsTablet from 'hooks/useIsTablet';
import useStyles from 'hooks/useStyles';
import { getFallbackColor } from './colors';
import IconGraterThan from './icons/IconGraterThan';
import styleSet, { SEARCH_BAR_TOTAL_HEIGHT } from './styles';
import { isWeb } from '../../../../constants';
const strings = {
    title: 'Search by name,\ncategory, tag or note',
    subtitle: 'Or try out with these filters',
};
const HIT_SLOP = { top: 10, bottom: 10 };
const NoTransactions = ({ style, defaultCategoryName, defaultAmount, onApplyDefaultCategoryFilter, onApplyDefaultAmountFilter, }) => {
    const statusBarHeight = useStatusBarHeight();
    const isTablet = useIsTablet();
    const styles = useStyles(styleSet);
    const colors = useColors();
    const categoryColors = useCategoryColors();
    let marginBottom;
    if (isWeb) {
        marginBottom = 0;
    }
    else if (isTablet) {
        marginBottom = '40%';
    }
    else {
        marginBottom = '90%';
    }
    const containerStyles = useMemo(() => ({
        marginTop: isWeb ? 0 : statusBarHeight + SEARCH_BAR_TOTAL_HEIGHT,
        marginBottom: marginBottom,
    }), [statusBarHeight, marginBottom]);
    return (<View style={[style, styles.noTransactionsMainContainer, containerStyles]}>
      <View style={styles.noTransactionsContainer}>
        <View style={styles.noTransactionsTitleContainer}>
          <Text Text-16 centered>
            {strings.title}
          </Text>
        </View>
        <Text TextThin-14 Gray centered>
          {strings.subtitle}
        </Text>
        <Spacer h={16}/>
        <View style={styles.row}>
          <TouchableBounce hoverStyle={styles.hoverOnDark} style={styles.noTransactionsTagContainer} onPress={onApplyDefaultCategoryFilter} hitSlop={HIT_SLOP}>
            <View style={styles.noTransactionsTag}>
              <Text Text-16 color={categoryColors[0]}>
                {defaultCategoryName}
              </Text>
            </View>
          </TouchableBounce>
          <Spacer w={8}/>
          <TouchableBounce hoverStyle={styles.hoverOnDark} style={styles.noTransactionsTagContainer} onPress={onApplyDefaultAmountFilter} hitSlop={HIT_SLOP}>
            <View style={styles.noTransactionsTag}>
              <IconGraterThan />
              <Spacer w={4}/>
              <Text Numbers-16 color={colors.gradients[getFallbackColor('AMOUNT_UPPER')].colors[0]}>
                {defaultAmount}
              </Text>
            </View>
          </TouchableBounce>
        </View>
      </View>
    </View>);
};
export default NoTransactions;
