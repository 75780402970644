import React, { useCallback, useLayoutEffect } from 'react';
import { View } from 'react-native';
import { emojify } from 'node-emoji';
import { CardView } from 'design-system';
import { AppView } from 'design-system/AppView';
import Button from 'design-system/Button';
import EmojiText from 'design-system/EmojiText';
import EmptyComponent from 'design-system/EmptyComponent';
import ScreenInfoHeader from 'design-system/ScreenInfoHeader';
import { SettingsItemNavigate } from 'design-system/SettingsItem';
import Spacer from 'design-system/Spacer';
import StatusBar from 'design-system/StatusBar';
import { IconTwitter } from 'design-system/icons';
import useColors from 'hooks/useColors';
import usePrivateRoute from 'hooks/usePrivateRoute';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { isWeb } from '../../../constants';
const strings = {
    title: 'Would you like to add a logo?',
    useXHandle: 'Use X handle',
    addEmoji: 'Add emoji',
    noThanks: 'No, thanks',
};
const DummyAccountLogoTypeScreen = ({ navigation, route }) => {
    usePrivateRoute();
    const colors = useColors();
    const styles = useStyles(styleSet);
    useLayoutEffect(() => {
        navigation.setOptions({
            headerTintColor: colors.text.primary,
            ...(isWeb && {
                title: 'Logo Type',
                headerTitle: EmptyComponent,
            }),
        });
    }, [colors.text.primary]);
    const onX = useCallback(() => {
        navigation.navigate('DummyAccountLogo', {
            ...route.params,
            keyBack: route.params.keyBack || route.key,
        });
    }, [route]);
    const onEmoji = useCallback(() => {
        navigation.navigate('AddAnEmoji', {
            ...route.params,
            keyBack: route.params.keyBack || route.key,
        });
    }, [route]);
    const onNothing = useCallback(() => {
        const { params = {} } = route;
        navigation.navigate('DummyAccountCreated', {
            ...params,
            keyBack: route.params.keyBack || route.key,
        });
    }, [route]);
    return (<AppView type="secondary" paddingBottom isInTab={isWeb} avoidHeader>
      <View style={styles.container}>
        <StatusBar barStyle="dark-content" animated/>
        <ScreenInfoHeader title="Would you like to add a logo?"/>
        <CardView webPadding={isWeb} noMarginTop>
          <SettingsItemNavigate icon={<IconTwitter />} title={strings.useXHandle} onPress={onX}/>
        </CardView>
        <CardView webPadding={isWeb} noMarginTop>
          <SettingsItemNavigate icon={<EmojiText>{emojify(':grinning:')}</EmojiText>} title={strings.addEmoji} onPress={onEmoji}/>
        </CardView>
        <Spacer flex/>
      </View>
      <Button brand title={strings.noThanks} onPress={onNothing} floating/>
    </AppView>);
};
const styleSet = createStyleSheets(() => ({
    container: {
        flex: 1,
    },
    centered: {
        alignSelf: 'center',
    },
}));
export default DummyAccountLogoTypeScreen;
