import { useCallback } from 'react';
import { Platform } from 'react-native';
import { AccessToken, LoginManager, AuthenticationToken, } from 'utils/packages/fbsdk';
import { PERMISSIONS, check } from 'react-native-permissions';
const useFacebookSignIn = () => {
    const signIn = useCallback(async () => {
        const loginTracking = Platform.OS === 'ios' &&
            (await check(PERMISSIONS.IOS.APP_TRACKING_TRANSPARENCY)) !== 'granted'
            ? 'limited'
            : 'enabled';
        const facebookResult = await LoginManager.logInWithPermissions(['public_profile', 'email'], loginTracking);
        if (facebookResult.isCancelled) {
            return null;
        }
        if (loginTracking === 'limited') {
            return AuthenticationToken.getAuthenticationTokenIOS();
        }
        const token = await AccessToken.getCurrentAccessToken();
        if (!token) {
            return null;
        }
        return {
            userId: token.userID,
            accessToken: token.accessToken,
        };
    }, []);
    return [signIn];
};
export default useFacebookSignIn;
