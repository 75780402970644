import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { rem } from 'design-system/values';
export const IconCrown = (props) => {
    const width = rem(16);
    const height = rem(14);
    return (<Svg width={width} height={height} fill="none" viewBox="0 0 16 14" {...props}>
      <Path d="m1.381 1.51 3.165 2.827L7.366.812A.834.834 0 0 1 8.02.5a.821.821 0 0 1 .647.326l2.69 3.51 3.268-2.868a.825.825 0 0 1 1.287.262.856.856 0 0 1 .08.498l-1.248 8.738H1.276L.009 2.268a.856.856 0 0 1 .078-.502.839.839 0 0 1 .347-.365.821.821 0 0 1 .947.108Zm-.04 10.3h13.307v.845a.85.85 0 0 1-.243.598.825.825 0 0 1-.588.247H2.171a.825.825 0 0 1-.588-.247.851.851 0 0 1-.243-.598v-.844Z" fill="#fff"/>
    </Svg>);
};
