import React, { memo } from 'react';
import { View } from 'react-native';
import { rem } from 'design-system/values';
import Indicator from 'design-system/Indicator';
import ListEmptyView from 'design-system/ListEmptyView';
import { CARD_VIEW_BORDER_RADIUS } from 'design-system/CardView';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
const AnalyticsSectionListEmpty = ({ isFetching }) => {
    const styles = useStyles(styleSet);
    return (<View style={styles.container}>
      {isFetching ? (<Indicator style={styles.indicator} dark/>) : (<ListEmptyView compact title="No Transactions" subTitle="All of your transactions will appear here."/>)}
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: rem(8),
        marginHorizontal: rem(16),
        paddingBottom: rem(22),
        borderBottomLeftRadius: CARD_VIEW_BORDER_RADIUS,
        borderBottomRightRadius: CARD_VIEW_BORDER_RADIUS,
        backgroundColor: colors.cards.onDark,
    },
    indicator: {
        marginBottom: rem(16),
        minHeight: rem(40),
    },
}));
export default memo(AnalyticsSectionListEmpty);
