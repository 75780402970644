import React, { memo, useContext, useCallback, useState } from 'react';
import moment from 'moment';
import { padStart } from 'lodash';
import { View, FlatList } from 'react-native';
import { rem } from 'design-system/values';
import { Button } from 'design-system/Button';
import { CardView } from 'design-system/CardView';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import ContentHeader from '../../calendar/ContentHeader';
import { AnalyticsFilterContext } from '../context';
import SelectorWithButtons from '../SelectorWithButtons';
import { monthsArr, filterStartYear } from '../constants';
import { isWeb } from '../../../constants';
const MonthlyContent = () => {
    const styles = useStyles(styleSet);
    const { currentYear, selectedYear, selectedMonth, setSelectedYear, setSelectedMonth, todayDateStr, } = useContext(AnalyticsFilterContext);
    const [calendarYear, setCalendarYear] = useState(selectedYear);
    const changeYear = useCallback((decrease) => () => {
        if (decrease) {
            if (calendarYear > filterStartYear) {
                setCalendarYear(calendarYear - 1);
            }
            return;
        }
        if (calendarYear < currentYear) {
            setCalendarYear(calendarYear + 1);
        }
    }, [currentYear, calendarYear, setCalendarYear]);
    const onPress = (index) => {
        setSelectedMonth(index);
        setSelectedYear(calendarYear);
    };
    return (<CardView style={styles.card}>
      <ContentHeader style={styles.header} title={selectedMonth !== undefined
            ? `${moment().month(selectedMonth).format('MMMM')} ${calendarYear}`
            : ''}/>
      <SelectorWithButtons title={calendarYear} onForward={changeYear(false)} onBackward={changeYear(true)} disableForward={calendarYear === currentYear} disableBackward={calendarYear === filterStartYear}/>
      <FlatList numColumns={isWeb ? 4 : 3} data={monthsArr} scrollEnabled={false} contentContainerStyle={styles.contentContainerStyles} columnWrapperStyle={styles.columnWrapperStyle} renderItem={({ item, index }) => (<View style={styles.eachButtonContainer}>
            <Button flex square title={item} disabled={calendarYear === currentYear &&
                moment(`${calendarYear}-${padStart((index + 1).toString(), 2, '0')}-01`).isAfter(todayDateStr)} containerStyle={styles.buttonStyles} onPress={() => onPress(index)} {...(selectedMonth === index && calendarYear === selectedYear
            ? { brandTextBorder: true }
            : { gray: true })}/>
          </View>)}/>
    </CardView>);
};
export default memo(MonthlyContent);
const styleSet = createStyleSheets((colors) => ({
    card: {
        ...(isWeb && {
            backgroundColor: colors.background.light,
        }),
    },
    header: {
        marginVertical: rem(16),
        marginHorizontal: rem(16),
    },
    columnWrapperStyle: {
        flex: 1,
    },
    eachButtonContainer: {
        flex: 1,
        margin: rem(8),
        height: rem(45),
    },
    contentContainerStyles: {
        padding: rem(8),
    },
    buttonStyles: {
        height: rem(45),
    },
}));
