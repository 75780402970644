import React, { useId } from 'react';
import { IconWithGradient } from 'design-system/icons/Icon';
import { Path } from 'react-native-svg';
const IconStatusUp = ({ ...props }) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="skyBlue">
      <Path d="M15.6667 3.5H8.18631C4.93705 3.5 3 5.43705 3 8.68631V16.1578C3 19.416 4.93705 21.353 8.18631 21.353H15.6578C18.9071 21.353 20.8441 19.416 20.8441 16.1667V8.68631C20.853 5.43705 18.916 3.5 15.6667 3.5ZM8.02563 17.9163C8.02563 18.2823 7.72213 18.5858 7.35614 18.5858C6.99015 18.5858 6.68665 18.2823 6.68665 17.9163V16.0685C6.68665 15.7026 6.99015 15.3991 7.35614 15.3991C7.72213 15.3991 8.02563 15.7026 8.02563 16.0685V17.9163ZM12.596 17.9163C12.596 18.2823 12.2925 18.5858 11.9265 18.5858C11.5605 18.5858 11.257 18.2823 11.257 17.9163V14.2118C11.257 13.8458 11.5605 13.5423 11.9265 13.5423C12.2925 13.5423 12.596 13.8458 12.596 14.2118V17.9163ZM17.1664 17.9163C17.1664 18.2823 16.8629 18.5858 16.4969 18.5858C16.1309 18.5858 15.8274 18.2823 15.8274 17.9163V12.364C15.8274 11.998 16.1309 11.6945 16.4969 11.6945C16.8629 11.6945 17.1664 11.998 17.1664 12.364V17.9163ZM17.1664 9.54326C17.1664 9.90924 16.8629 10.2127 16.4969 10.2127C16.1309 10.2127 15.8274 9.90924 15.8274 9.54326V8.67738C13.5511 11.0161 10.7036 12.6675 7.51682 13.462C7.46326 13.4799 7.4097 13.4799 7.35614 13.4799C7.05264 13.4799 6.78484 13.2745 6.70451 12.971C6.61524 12.614 6.82948 12.248 7.19546 12.1587C10.2037 11.4089 12.8817 9.8289 15.0062 7.59727H13.8904C13.5244 7.59727 13.2209 7.29377 13.2209 6.92778C13.2209 6.5618 13.5244 6.2583 13.8904 6.2583H16.5058C16.5415 6.2583 16.5683 6.27615 16.604 6.27615C16.6486 6.28507 16.6933 6.28507 16.7379 6.30293C16.7825 6.32078 16.8183 6.34756 16.8629 6.37434C16.8897 6.39219 16.9164 6.40112 16.9432 6.41897C16.9522 6.4279 16.9522 6.43683 16.9611 6.43683C16.9968 6.47253 17.0236 6.50824 17.0503 6.54394C17.0771 6.57965 17.1039 6.60643 17.1128 6.64214C17.1307 6.67784 17.1307 6.71355 17.1396 6.75818C17.1485 6.80281 17.1664 6.84745 17.1664 6.901C17.1664 6.90993 17.1753 6.91886 17.1753 6.92778V9.54326H17.1664Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default IconStatusUp;
