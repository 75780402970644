import React, { useCallback } from 'react';
import { getSyncingStatus, syncAllConnections, } from 'actions/connections';
import Modal from 'design-system/Modal';
import { benefits } from 'features/premium/benefits';
import { Tier } from 'features/premium/entities';
import { useIsTierUnlockedForSpace } from 'features/premium/hooks/useIsTierUnlockedForSpace';
import { selectUserTier } from 'features/premium/selectors/tier';
import { muteErrorAndResult, useFetchOld } from 'hooks/useFetch';
import { Keyboard } from 'react-native';
import { useAppDispatch, useAppStore } from 'store/hooks';
import { Amplitude } from 'utils';
import ForceSyncUpgradeModal from './ForceSyncUpgradeModal';
const useForceSync = () => {
    const store = useAppStore();
    const dispatch = useAppDispatch();
    const [, , isFetching, fetch] = useFetchOld(muteErrorAndResult);
    const isUnlockedForSpace = useIsTierUnlockedForSpace(benefits.forceSync.minTier);
    const forceSync = useCallback(async () => {
        const state = store.getState();
        const userTier = selectUserTier(state);
        if (isUnlockedForSpace) {
            const result = await fetch(syncAllConnections());
            if (result?.isSyncingManual) {
                dispatch(getSyncingStatus());
            }
        }
        else {
            Keyboard.dismiss();
            Amplitude.logEvent('Force Sync Modal');
            Modal.show(<ForceSyncUpgradeModal />, {
                title: userTier === Tier.plus
                    ? 'Emma updates 4 times a day'
                    : 'Emma updates once a day',
            });
        }
    }, [dispatch, isUnlockedForSpace]);
    return [isFetching, forceSync];
};
export default useForceSync;
