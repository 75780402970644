import useStyles from 'hooks/useStyles';
import React, { memo } from 'react';
import { View } from 'react-native';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableOpacity from 'utils/packages/TouchableOpacity';
import Text from './Text';
import { rem } from './values';
const getTextColorStyle = (tab, activeTab, Secondary) => {
    if (activeTab === tab) {
        if (Secondary) {
            return { OnBrand: true };
        }
        return { Primary: true };
    }
    return { Secondary: true };
};
const WebTabs = ({ tabs, displayTabs, activeTab, handleTabChange, Secondary, }) => {
    const styles = useStyles(styleSet);
    return (<View style={styles.tabsContainer}>
      {tabs.map((tab, index) => (<TouchableOpacity key={tab} hoverStyle={styles.selectorHoverStyles} onPress={() => handleTabChange(tab)} style={[
                styles.tab,
                Secondary && styles.tabSecondary,
                activeTab === tab && styles.selectorHoverStyles,
            ]}>
          <Text Text-14 {...getTextColorStyle(tab, activeTab, Secondary)}>
            {displayTabs?.[index] || tab}
          </Text>
        </TouchableOpacity>))}
    </View>);
};
export default memo(WebTabs);
const styleSet = createStyleSheets((colors) => ({
    tabsContainer: {
        flexDirection: 'row',
        marginVertical: rem(8),
        marginHorizontal: rem(16),
        justifyContent: 'flex-start',
    },
    tab: {
        marginRight: rem(8),
        borderRadius: rem(8),
        paddingVertical: rem(8),
        paddingHorizontal: rem(16),
        backgroundColor: 'transparent',
    },
    tabSecondary: {
        paddingVertical: rem(6),
    },
    selectorHoverStyles: {
        backgroundColor: colors.background.brandLight,
    },
}));
