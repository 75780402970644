import { LOGGED_OUT } from 'actions/types';
import { DELETE_VRP_CONSENT_BY_ID_SUCCESS, GET_VRP_CONSENTS_FAILURE, GET_VRP_CONSENTS_REQUEST, GET_VRP_CONSENTS_SUCCESS, GET_VRP_CONSENT_BY_ID_SUCCESS, } from '../actions/types';
const initialState = {
    isFetchingConsentsList: false,
    consents: {},
};
const preApprovedTransfers = (state = initialState, action) => {
    switch (action.type) {
        case GET_VRP_CONSENTS_REQUEST:
            return {
                ...state,
                isFetchingConsentsList: true,
            };
        case GET_VRP_CONSENTS_FAILURE:
            return {
                ...state,
                isFetchingConsentsList: false,
            };
        case GET_VRP_CONSENTS_SUCCESS:
            return {
                ...state,
                isFetchingConsentsList: false,
                consents: action.payload.reduce((prev, curr) => ({
                    ...prev,
                    [curr.id]: curr,
                }), {}),
            };
        case GET_VRP_CONSENT_BY_ID_SUCCESS:
            return {
                ...state,
                consents: {
                    ...state.consents,
                    [action.payload.id]: action.payload,
                },
            };
        case DELETE_VRP_CONSENT_BY_ID_SUCCESS: {
            const newConsents = { ...state.consents };
            delete newConsents[action.extra.consentId];
            return {
                ...state,
                consents: newConsents,
            };
        }
        case LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
};
export default preApprovedTransfers;
