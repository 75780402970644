import React, { useEffect } from 'react';
import { StatusBar as RNStatusBar } from 'react-native';
import useColorScheme from 'hooks/useColorScheme';
import { inverseStatusBarStyle } from 'hooks/useStatusBar';
const StatusBar = ({ barStyle, animated, }) => {
    const colorScheme = useColorScheme();
    useEffect(() => {
        StatusBar.colorScheme = colorScheme;
    }, [colorScheme]);
    const effectiveBarStyle = colorScheme === 'dark' ? inverseStatusBarStyle(barStyle) : barStyle;
    return <RNStatusBar barStyle={effectiveBarStyle} animated={animated}/>;
};
StatusBar.pushStackEntry = (props) => {
    const barStyle = StatusBar.colorScheme === 'dark' ? inverseStatusBarStyle(props.barStyle) : props.barStyle;
    return RNStatusBar.pushStackEntry({ ...props, barStyle });
};
StatusBar.pushStackEntryWithoutChecking = (props) => RNStatusBar.pushStackEntry(props);
StatusBar.popStackEntry = (entry) => {
    RNStatusBar.popStackEntry(entry);
};
StatusBar.replaceStackEntry = (entry, props) => {
    const barStyle = StatusBar.colorScheme === 'dark' ? inverseStatusBarStyle(props.barStyle) : props.barStyle;
    return RNStatusBar.replaceStackEntry(entry, { ...props, barStyle });
};
export default StatusBar;
