import React, { memo } from 'react';
import { View } from 'react-native';
import Animated, { withTiming, useAnimatedStyle, useDerivedValue, withSpring, } from 'react-native-reanimated';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { springDefaultConfig } from 'features/invest/components/Chart/constants';
import Svg, { Path } from 'react-native-svg';
import useColors from 'hooks/useColors';
const WIDTH = rem(8);
const HEIGHT = rem(250);
const Pointer = ({ useLine, selectedInfoObj }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const opacity = useDerivedValue(() => {
        if (!selectedInfoObj.value)
            return withTiming(0);
        return withTiming(1);
    }, [selectedInfoObj]);
    const animatedStyles = useAnimatedStyle(() => ({
        opacity: withTiming(!selectedInfoObj.value ? 0 : 1),
        transform: [
            {
                translateX: selectedInfoObj.value?.x !== undefined
                    ? selectedInfoObj.value?.x
                    : -100,
            },
            {
                translateY: useLine ? 0 : selectedInfoObj.value?.y || -100,
            },
            {
                scale: useLine
                    ? 1
                    : withSpring(!selectedInfoObj.value ? 0 : 1, springDefaultConfig),
            },
        ],
    }), [opacity, useLine, selectedInfoObj]);
    if (!useLine) {
        return (<Animated.View style={[styles.outerDotAbs, animatedStyles]}>
        <View style={styles.innerDot}/>
      </Animated.View>);
    }
    return (<Animated.View pointerEvents="none" style={[styles.container, animatedStyles]}>
      <Animated.View style={styles.outerDot}>
        <View style={styles.innerDot}/>
      </Animated.View>
      {useLine && (<Svg width={WIDTH} height={HEIGHT} viewBox={`0 0 ${WIDTH} ${HEIGHT}`}>
          <Path strokeWidth={1} strokeDasharray="5,5" d={`M4 0 l0 ${HEIGHT}`} stroke={colors.elements.brand}/>
        </Svg>)}
    </Animated.View>);
};
export default memo(Pointer);
const styleSet = createStyleSheets((colors) => ({
    container: {
        bottom: -rem(16),
        top: -rem(4),
        left: -rem(4),
        width: rem(8),
        position: 'absolute',
    },
    outerDotAbs: {
        top: -rem(4),
        left: -rem(4),
        width: rem(8),
        height: rem(8),
        position: 'absolute',
        alignItems: 'center',
        borderRadius: rem(8),
        justifyContent: 'center',
        backgroundColor: colors.elements.brand,
    },
    outerDot: {
        width: rem(8),
        height: rem(8),
        alignItems: 'center',
        borderRadius: rem(8),
        justifyContent: 'center',
        backgroundColor: colors.elements.brand,
    },
    innerDot: {
        width: rem(4),
        height: rem(4),
        borderRadius: rem(4),
        backgroundColor: colors.background.light,
    },
}));
