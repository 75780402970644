import React, { memo, useCallback } from 'react';
import { StyleSheet } from 'react-native';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import Button from 'design-system/Button';
import { rem } from 'design-system/values';
import Squircle from 'design-system/Squircle';
import { CardView } from 'design-system/CardView';
import { IconSaveMoney } from 'design-system/icons/IconSaveMoney';
import useColors from 'hooks/useColors';
import { useAppSelector } from 'store/hooks';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { selectMaximumInterest } from 'features/pots/reducers/selectors';
const StartInvestingCard = memo(() => {
    const colors = useColors();
    const navigation = useMainStackNavigation();
    const maximumInterest = useAppSelector(selectMaximumInterest);
    const handleNavigation = useCallback(() => {
        navigation.navigate('Save', { tab: 'pots' });
    }, []);
    return (<CardView noMarginTop withPadding style={styles.container}>
      <Squircle width={rem(64)} height={rem(64)} color={colors.background.dark}>
        <IconSaveMoney width={rem(32)} height={rem(32)}/>
      </Squircle>
      <Spacer.H16 />
      <Text Text-16>Grow your savings</Text>
      <Spacer h={4}/>
      <Text TextThin-14 centered Secondary>
        {'Open a pot and\nearn up to '}
        <Text NumbersThin-14 Secondary>
          {maximumInterest}
        </Text>
        % AER. FSCS protected.
      </Text>
      <Spacer.H16 />
      <Button hug small grey title="Get started" onPress={handleNavigation}/>
    </CardView>);
});
export default StartInvestingCard;
const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
    },
    empty: {
        padding: rem(24),
    },
});
