import * as Keychain from 'utils/packages/keychain';
import { sha256 } from 'utils/packages/sha256';
import { Platform } from 'react-native';
import Sentry from './sentry';
export const KEYCHAIN_SERVICE = 'emma-passcode';
export const KEYCHAIN_SERVICE_HASHED = 'emma-passcode-h';
export const KEYCHAIN_SERVICE_RAW = 'emma-passcode-raw';
/**
 * Stores pin in keychain to allow pin validation when using scoped API requests and when user unlocks the app.
 * Uses 3 services in keychain:
 *   KEYCHAIN_SERVICE: used to keep pin encrypted with user-presense access level
 *   KEYCHAIN_SERVICE_HASHED: used to keep hashed pin with no access level
 *   KEYCHAIN_SERVICE_RAW: used to keep raw unhashed pin with no access level (android only)
 *
 * A note on KEYCHAIN_SERVICE_RAW: whenever you use it to get pin,
 * please call FingerprintScanner.authenticate to check for user presense.
 */
export const storePasscode = async (passcodeOrUndefined, // undefined will be passed from useSignIn hook on token stage
userIdStringOrNumber) => {
    try {
        const userId = `${userIdStringOrNumber}`;
        if (Number(userId) === -1) {
            pinTempStorage.next(passcodeOrUndefined);
            return false;
        }
        let passcode = passcodeOrUndefined;
        if (passcode === undefined) {
            const pin = pinTempStorage.next().value;
            if (pin) {
                passcode = pin;
            }
        }
        if (passcode === undefined) {
            return false; // nothing to store
        }
        const secureBiometryTypes = await Keychain.getSupportedBiometryType();
        const result = await Keychain.setGenericPassword(
        // without specifying an access control this can be retrieved at any point by the app
        // see https://github.com/oblador/react-native-keychain/pull/326
        userId, await sha256(`${passcode}_${userId}`), {
            service: KEYCHAIN_SERVICE_HASHED,
        });
        if (secureBiometryTypes !== null) {
            try {
                await Keychain.resetGenericPassword({ service: KEYCHAIN_SERVICE });
                await Keychain.setGenericPassword(userId, passcode, {
                    // In the 'plain text' version we force the user to be present (the device decides on the biometrics to use)
                    // when we come to verify this we can check if the user has biometrics enabled and use this,
                    // otherwise get them to enter their passcode and compare with the hashed version
                    accessControl: Platform.select({
                        android: Keychain.ACCESS_CONTROL.BIOMETRY_ANY,
                        default: Keychain.ACCESS_CONTROL.USER_PRESENCE,
                    }),
                    authenticationType: Keychain.AUTHENTICATION_TYPE.BIOMETRICS,
                    service: KEYCHAIN_SERVICE,
                });
            }
            catch (err) {
                // We couldn't setup biometrics. We can still allow the user to use the app without.
                Sentry.logError({
                    filename: 'passcode.ts',
                    message: 'Couldnt setup biometrics',
                    err,
                });
            }
        }
        // User doesn't have any secure biometry method that can be used to unlock encrypted data by checking for user presense
        // We still want to provide some nice UX by showing him face/touch prompt whenever a scoped API request needs to be made
        // That's why we store raw unhashed pin in keystore (android only)
        else if (Platform.OS === 'android') {
            await Keychain.setGenericPassword(userId, passcode, {
                service: KEYCHAIN_SERVICE_RAW,
            });
        }
        else {
            await Keychain.resetGenericPassword({ service: KEYCHAIN_SERVICE });
        }
        return typeof result !== 'boolean';
    }
    catch (e) {
        Sentry.logError({
            filename: 'passcode.ts',
            message: 'storePasscode failed',
            err: e,
        });
        return false;
    }
};
export const migratePassCode = async (passCodeInRedux, userId, pinLastChangedAt) => {
    if (typeof passCodeInRedux !== 'string' ||
        passCodeInRedux === '' ||
        pinLastChangedAt) {
        // Passcode has already been migrated or not yet set
        return undefined;
    }
    const stored = await storePasscode(passCodeInRedux, userId);
    return stored ? passCodeInRedux : undefined;
};
/**
 * A place where we can store raw pin until we have userId to store it in keychain
 * It yields stored pin, never returns, and takes pin or nothing in
 * When you pass it pin, it will yield it next time.
 * When you take pin from it, it will yield undefined next time.
 */
function* pinTempStorageGenerator() {
    let pin = yield; // dry run. Generator runs up to this line and waits for next call, which will be a "supply" call, so we save pin
    while (true) {
        yield; // when pin is saved, we yield undefined. After pin is saved, it will be retrieved, and nothing will be supplied
        pin = yield pin; // when pin is retrieved, we yield pin. Next time generator is called - we save pin
    }
}
const pinTempStorage = pinTempStorageGenerator();
pinTempStorage.next(); // dry run
export default { storePasscode };
