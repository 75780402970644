import React from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
export const AnalyticsCardHeader = ({ title, subTitle, icon, }) => (<>
    <Text Text-16 style={styles.title}>
      {title}
    </Text>
    <View style={[styles.subtitleContainer, styles.budgetingSubtitleContainer]}>
      {icon}
      <Text TextThin-14 Gray style={styles.subTitleText}>
        {subTitle}
      </Text>
    </View>
  </>);
const styles = StyleSheet.create({
    title: {
        marginBottom: rem(4),
    },
    subtitleContainer: {
        flexDirection: 'row',
        marginBottom: rem(20),
        alignItems: 'center',
    },
    budgetingSubtitleContainer: {
        marginBottom: rem(12),
    },
    subTitleText: {
        flexShrink: 1,
        textAlign: 'left',
        marginStart: rem(4),
    },
});
