import React, { useCallback, useEffect } from 'react';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { SettingsSection } from 'design-system/SettingsSection';
import { SettingsItemContent, SettingsItemDescription, SettingsItemNavigate, SettingsItemTitle, } from 'design-system/SettingsItem';
import { IconCalendar, IconFlag, IconProfile, IconHashTag, } from 'design-system/icons';
import useColors from 'hooks/useColors';
import Text from 'design-system/Text';
import { registerUser } from 'actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import useDateTimePickerDOB, { DOB_DISPLAY_FORMAT_DATE_FNS, } from 'hooks/useDateTimePickerDOB';
import { selectUser, selectUserNationalities } from 'reducers/selectors';
import { isWeb } from '../../../../constants';
const strings = {
    sectionName: 'Personal Details',
    legalFirstName: 'Legal First Name',
    legalMiddleName: 'Legal Middle Name',
    legalLastName: 'Legal Last Name',
    dob: 'DOB',
    countryOfCitizenship: 'Citizenships',
    emmaTag: 'Emma Tag',
    defaultTitle: 'Choose..',
};
export const PersonalDetailsSection = () => {
    const { firstName, middleName, lastName, dateOfBirth, kycStartedAt, urlHandle, } = useAppSelector(selectUser);
    const nationalities = useAppSelector(selectUserNationalities);
    const colors = useColors();
    const areFieldsDisabled = Boolean(kycStartedAt);
    const { navigate } = useMainStackNavigation();
    const dispatch = useAppDispatch();
    const changeName = useCallback(() => {
        navigate('ChangeDetail', { key: 'firstName' });
    }, []);
    const changeMiddleName = useCallback(() => {
        navigate('ChangeDetail', { key: 'middleName' });
    }, []);
    const changeLastName = useCallback(() => {
        navigate('ChangeDetail', { key: 'lastName' });
    }, []);
    const changeTag = useCallback(() => {
        navigate('ChangeDetail', { key: 'urlHandle' });
    }, []);
    const showCountrySelectorModal = useCallback(() => {
        navigate('MultipleCitizenshipPickerScreen');
    }, []);
    const { showDateTimePicker, dob } = useDateTimePickerDOB({
        dateOfBirth,
    }, 16);
    useEffect(() => {
        if (dob &&
            dateOfBirth &&
            dob !== format(toZonedTime(dateOfBirth, 'UTC'), 'yyyy-MM-dd')) {
            dispatch(registerUser({
                dateOfBirth: dob,
            }));
        }
    }, [dob, dateOfBirth]);
    const defaultTitle = areFieldsDisabled ? '' : strings.defaultTitle;
    return (<SettingsSection name={strings.sectionName}>
      {!!(!isWeb || firstName) && (<SettingsItemNavigate icon={<IconProfile gradientKey="ultimate"/>} disabled={isWeb || areFieldsDisabled} description={strings.legalFirstName} title={firstName || defaultTitle} onPress={changeName}/>)}

      {!!(!isWeb || middleName) && (<SettingsItemNavigate icon={<IconProfile gradientKey="deepPink"/>} disabled={isWeb || areFieldsDisabled} description={strings.legalMiddleName} title={middleName || defaultTitle} onPress={changeMiddleName}/>)}

      {!!(!isWeb || lastName) && (<SettingsItemNavigate icon={<IconProfile gradientKey="green"/>} disabled={isWeb || areFieldsDisabled} description={strings.legalLastName} title={lastName || defaultTitle} onPress={changeLastName}/>)}

      {!!(!isWeb || urlHandle) && (<SettingsItemNavigate icon={<IconHashTag gradientColors={colors.gradients.peach.colors}/>} disabled={isWeb} description={strings.emmaTag} title={urlHandle ? `@${urlHandle}` : defaultTitle} onPress={changeTag}/>)}

      {!!(!isWeb || dateOfBirth) && (<SettingsItemNavigate icon={<IconCalendar gradientColors={colors.gradients.darkPurple.colors}/>} disabled={isWeb || areFieldsDisabled} onPress={showDateTimePicker}>
          <SettingsItemContent>
            <SettingsItemDescription>{strings.dob}</SettingsItemDescription>
            <SettingsItemTitle>
              <Text Numbers-16>
                {dateOfBirth
                ? format(toZonedTime(dateOfBirth, 'UTC'), DOB_DISPLAY_FORMAT_DATE_FNS)
                : defaultTitle}
              </Text>
            </SettingsItemTitle>
          </SettingsItemContent>
        </SettingsItemNavigate>)}

      {!!(!isWeb || nationalities) && (<SettingsItemNavigate icon={<IconFlag />} disabled={isWeb || areFieldsDisabled} onPress={showCountrySelectorModal} title={nationalities || defaultTitle} description={strings.countryOfCitizenship}/>)}
    </SettingsSection>);
};
