import React, { memo } from 'react';
import { View } from 'react-native';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { Button, CardView, rem } from '.';
import Text from './Text';
import Squircle from './Squircle';
const Banner = ({ title, description, icon, onLight, buttonTitle, noMarginBottom, onPress, hideButton, }) => {
    const styles = useStyles(styleSet);
    const colors = useColors();
    return (<CardView withPadding noMarginTop noMarginBottom={noMarginBottom} style={onLight && styles.cardOnLight}>
      <Squircle color={onLight ? colors.background.light : colors.background.dark} style={styles.icon}>
        {icon}
      </Squircle>
      <View style={styles.row}>
        <Text Text-16 flex style={styles.title}>
          {title}
        </Text>
      </View>
      <Text TextThin-14 Secondary style={!hideButton && styles.description}>
        {description}
      </Text>
      {!hideButton && (<View style={styles.row}>
          <Button square brandLight onPress={onPress} title={buttonTitle} containerStyle={styles.btn}/>
        </View>)}
    </CardView>);
};
export default memo(Banner);
const styleSet = createStyleSheets((colors) => ({
    row: {
        flexDirection: 'row',
    },
    btn: {
        paddingHorizontal: rem(24),
    },
    icon: {
        marginBottom: rem(16),
    },
    title: {
        marginBottom: rem(4),
    },
    description: {
        marginBottom: rem(16),
    },
    cardOnLight: {
        backgroundColor: colors.cards.onLight,
    },
}));
