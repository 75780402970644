import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from './Icon';
const IconPrediction = ({ ...props }) => {
    const id = useId();
    return (<IconWithGradient id={id} defaultGradientKey="ultimate" {...props}>
      <Path d="M16.683 14.926L17.967 18.78C18.0961 19.1672 18.0857 19.5873 17.9378 19.9676C17.7899 20.348 17.5137 20.6647 17.157 20.863C15.786 21.626 14.064 22 12 22C9.936 22 8.214 21.626 6.843 20.863C6.48628 20.6647 6.21008 20.348 6.06217 19.9676C5.91426 19.5873 5.90394 19.1672 6.033 18.78L7.318 14.926C8.66403 15.9495 10.309 16.5025 12 16.5C13.76 16.5 15.382 15.914 16.683 14.926ZM12 2C13.337 2 14.582 2.388 15.63 3.059C15.548 3.372 15.422 3.603 15.263 3.763C15.01 4.015 14.58 4.183 13.938 4.233L13.716 4.246L13.337 4.256C11.168 4.415 11.222 7.75 13.5 7.75C14.39 7.75 14.957 7.931 15.263 8.237C15.515 8.49 15.683 8.92 15.733 9.562L15.746 9.784L15.756 10.163C15.852 11.477 17.114 11.975 18.094 11.657C17.4345 13.0392 16.3247 14.1561 14.9467 14.8243C13.5687 15.4926 12.0044 15.6724 10.5107 15.3343C9.01704 14.9963 7.68268 14.1604 6.72672 12.9639C5.77077 11.7674 5.25003 10.2815 5.25 8.75C5.25 6.95979 5.96116 5.2429 7.22703 3.97703C8.4929 2.71116 10.2098 2 12 2ZM18.25 2C18.25 3.15 18.52 3.96 19.03 4.47C19.501 4.94 20.228 5.207 21.24 5.245L21.5 5.25C22.458 5.25 22.498 6.628 21.62 6.742L21.5 6.75C20.35 6.75 19.54 7.02 19.03 7.53C18.56 8.002 18.293 8.728 18.255 9.74L18.25 10C18.25 11 16.75 11 16.75 10C16.75 8.85 16.48 8.04 15.97 7.53C15.498 7.06 14.772 6.793 13.76 6.755L13.5 6.75C12.542 6.75 12.502 5.372 13.38 5.258L13.5 5.25C14.65 5.25 15.46 4.98 15.97 4.47C16.48 3.96 16.75 3.15 16.75 2C16.75 1 18.25 1 18.25 2Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default IconPrediction;
