import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updatePreferences } from 'actions/user';
const normalize = (preferences) => {
    const pushPreferences = [];
    const emailPreferences = [];
    Object.entries(preferences).forEach((entry) => {
        const [key] = entry;
        if (key.includes('alerts') && key.includes('.push')) {
            pushPreferences.push(entry);
        }
        else if (key.includes('alerts') && key.includes('.email')) {
            emailPreferences.push(entry);
        }
    });
    return {
        pushPreferences: pushPreferences.reduce((value, item) => ({ ...value, [item[0]]: item[1].value }), {}),
        emailPreferences: emailPreferences.reduce((value, item) => ({ ...value, [item[0]]: item[1].value }), {}),
    };
};
export const useNotificationsPreferences = () => {
    const dispatch = useAppDispatch();
    const notificationPreferences = useAppSelector((store) => store.user.preferences);
    const { pushPreferences, emailPreferences } = useMemo(() => normalize(notificationPreferences), [notificationPreferences]);
    return {
        updatePreferences: (preferences) => dispatch(updatePreferences(preferences)),
        pushNormalizedNotificationPreferences: pushPreferences,
        emailNormalizedNotificationPreferences: emailPreferences,
    };
};
