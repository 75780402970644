import React, { memo, useEffect, useState } from 'react';
import { View } from 'react-native';
import getSymbolFromCurrency from 'currency-symbol-map';
import Animated, { withTiming, interpolate, useAnimatedStyle, useSharedValue, } from 'react-native-reanimated';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
import RNMaskedText from 'utils/packages/rnMaskedText';
import createStyleSheets from 'utils/createStyleSheets';
import { isWeb } from '../../../../constants';
import CategoryMenuButton from './CategoryMenuButton';
const SplitItem = ({ amount, currency, category, id, onPressShowMenu, onChangeText, animate, }) => {
    const colors = useColors();
    const [value, setValue] = useState(amount);
    const opacity = useSharedValue(animate ? 0 : 1);
    const translateY = useSharedValue(animate ? 0 : 1);
    const animStyle = useAnimatedStyle(() => ({
        opacity: opacity.value,
        transform: [
            {
                translateY: interpolate(translateY.value, [0, 1], [-16, 0]),
            },
        ],
    }));
    useEffect(() => {
        opacity.value = withTiming(1, { duration: 250 });
        translateY.value = withTiming(1, { duration: 500 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (amount !== value) {
            setValue(amount);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount]);
    const changeText = (_, rawValue) => {
        onChangeText(rawValue, id);
    };
    const isEditable = id !== 0;
    const styles = useStyles(styleSet);
    return (<Animated.View style={[styles.container, animStyle]}>
      <Text Text-14 Gray style={styles.header}>
        Transaction {id + 1}
      </Text>
      <View style={styles.content}>
        <CategoryMenuButton idx={id} selected={category} onPressShowMenu={onPressShowMenu}/>
        <View style={styles.flex}/>
        <View>
          <RNMaskedText pointerEvents={isEditable ? 'auto' : 'none'} style={[styles.textinput, !isEditable && styles.unEditable]} type="money" editable={isEditable} allowFontScaling={false} selectionColor={colors.text.selection} includeRawValueInChangeText underlineColorAndroid="transparent" options={{
            unit: getSymbolFromCurrency(currency),
            separator: '.',
            delimiter: ',',
            precision: 2,
        }} onChangeText={changeText} value={value} testID="splitInput"/>
        </View>
      </View>
    </Animated.View>);
};
const styleSet = createStyleSheets((colors) => ({
    flex: {
        flex: 1,
    },
    container: {
        overflow: 'hidden',
        padding: rem(16),
        transform: [{ translateY: -16 }],
        opacity: 0,
    },
    content: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    header: {
        paddingBottom: rem(16),
    },
    textinput: {
        fontSize: rem(16),
        fontFamily: 'Nunito-Bold',
        textAlign: 'right',
        margin: 0,
        ...(isWeb
            ? {
                width: rem(120),
                color: colors.text.primary,
                backgroundColor: colors.background.dark,
            }
            : {
                padding: 0,
                borderBottomWidth: 1,
                color: colors.text.primary,
                borderBottomColor: colors.elements.secondary,
            }),
    },
    unEditable: {
        ...(isWeb
            ? {
                color: colors.text.secondary,
            }
            : {
                borderBottomWidth: 0,
            }),
    },
}));
export default memo(SplitItem);
