import React, { memo, useId } from 'react';
import { StyleSheet, View } from 'react-native';
import Svg, { Path, Defs, LinearGradient, Stop } from 'react-native-svg';
import { rem } from 'design-system/values';
import { interpolate } from 'utils/interpolations';
import { useGradientColors } from 'hooks/useColors';
const AnalyticsCard = ({ large, leftBarColor, rightBarColor, leftBarPercent = 100, rightBarPercent = 80, leftBarGradientKey = 'deepPink', rightBarGradientKey = 'turquoise', useRem, }) => {
    const leftBarGradient = useGradientColors(leftBarGradientKey, (leftBarColor ? [leftBarColor, leftBarColor] : undefined));
    const rightBarGradient = useGradientColors(rightBarGradientKey, (rightBarColor ? [rightBarColor, rightBarColor] : undefined));
    const largeHeight = useRem ? rem(116) : 116;
    const MAX_HEIGHT = large ? largeHeight : 70;
    const leftBarHeight = interpolate(leftBarPercent, {
        inputRange: [0, 100],
        outputRange: [0, MAX_HEIGHT],
    });
    const rightBarHeight = interpolate(rightBarPercent, {
        inputRange: [0, 100],
        outputRange: [0, MAX_HEIGHT],
    });
    const id = useId();
    return (<View style={[
            styles.container,
            {
                height: MAX_HEIGHT,
                width: large ? 214 : 130,
            },
        ]}>
      <Svg width={large ? 120 : 78} height={rightBarHeight} fill="none" style={[styles.spending, { left: large ? 94 : 57 }]}>
        <Path d={large
            ? 'M0 38C0 20.0866 0 11.1299 5.56497 5.56497C11.1299 0 20.0866 0 38 0H81C98.9134 0 107.87 0 113.435 5.56497C119 11.1299 119 20.0866 119 38V116H0V38Z'
            : 'M0 23.489c0-11.073 0-16.61 3.44-20.05C6.88 0 12.416 0 23.489 0H47.51c11.073 0 16.61 0 20.05 3.44C71 6.88 71 12.416 71 23.489V71H0V23.489Z'} fill={`url(#a_${id})`}/>
        <Defs>
          <LinearGradient id={`a_${id}`} x1={-10.035} y1={3.585} x2={27.097} y2={100.468} gradientUnits="userSpaceOnUse">
            <Stop offset={0.003} stopColor={rightBarGradient[0]}/>
            <Stop offset={1} stopColor={rightBarGradient[1]}/>
          </LinearGradient>
        </Defs>
      </Svg>
      <Svg width={large ? 128 : 71} height={leftBarHeight} fill="none" style={[
            styles.shadow,
            {
                shadowOffset: { width: 10, height: MAX_HEIGHT - rightBarHeight },
            },
        ]}>
        <Path d={large
            ? 'M0 38C0 20.0866 0 11.1299 5.56497 5.56497C11.1299 0 20.0866 0 38 0H90C107.913 0 116.87 0 122.435 5.56497C128 11.1299 128 20.0866 128 38V116H0V38Z'
            : 'M0 23.489c0-11.073 0-16.61 3.44-20.05C6.88 0 12.416 0 23.489 0H47.51c11.073 0 16.61 0 20.05 3.44C71 6.88 71 12.416 71 23.489V71H0V23.489Z'} fill={`url(#b_${id})`}/>
        <Defs>
          <LinearGradient id={`b_${id}`} x1={-10.035} y1={3.585} x2={27.097} y2={100.468} gradientUnits="userSpaceOnUse">
            <Stop stopColor={leftBarGradient[0]}/>
            <Stop offset={0.994} stopColor={leftBarGradient[1]}/>
          </LinearGradient>
        </Defs>
      </Svg>
    </View>);
};
const styles = StyleSheet.create({
    container: {
        justifyContent: 'flex-end',
        overflow: 'hidden',
        alignSelf: 'center',
    },
    shadow: {
        shadowColor: '#17102A',
        shadowOpacity: 0.2,
        shadowRadius: 5,
    },
    spending: {
        position: 'absolute',
        right: 0,
        bottom: 0,
    },
});
export default memo(AnalyticsCard);
