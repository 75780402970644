import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import useColors from 'hooks/useColors';
import { IconWithGradient } from '../Icon';
const IconStatusReceived = ({ ...props }) => {
    const colors = useColors();
    const id = useId();
    return (<IconWithGradient id={id} {...props} width={18} height={18} viewBox="0 0 18 18" defaultGradientKey="ultimate">
      <Path d="M0.286624 6.70701C0.706221 3.35023 3.35023 0.706221 6.70701 0.286624C8.22974 0.096282 9.77026 0.096282 11.293 0.286624C14.6498 0.706221 17.2938 3.35023 17.7134 6.70701C17.9037 8.22974 17.9037 9.77026 17.7134 11.293C17.2938 14.6498 14.6498 17.2938 11.293 17.7134C9.77026 17.9037 8.22974 17.9037 6.70701 17.7134C3.35023 17.2938 0.706221 14.6498 0.286624 11.293C0.096282 9.77026 0.096282 8.22974 0.286624 6.70701Z" fill={`url(#gradient_${id})`}/>
      <Path d="M5.28412 8.21462C5.32336 8.17435 5.47152 8.00212 5.60953 7.86046C6.41864 6.96949 8.52937 5.51115 9.63413 5.06601C9.8019 4.99449 10.2261 4.8431 10.4527 4.83337C10.6699 4.83337 10.8769 4.88337 11.0744 4.98476C11.3207 5.12643 11.5182 5.34935 11.6265 5.61254C11.6961 5.79518 11.8044 6.34171 11.8044 6.35143C11.9126 6.94935 11.9722 7.92087 11.9722 8.99449C11.9722 10.0174 11.9126 10.9493 11.824 11.5563C11.8139 11.566 11.7056 12.2452 11.5872 12.4778C11.3701 12.9028 10.9459 13.1667 10.4919 13.1667L10.4527 13.1667C10.1571 13.1563 9.53535 12.8924 9.53535 12.8827C8.49014 12.4368 6.42811 11.05 5.59938 10.1285C5.59938 10.1285 5.36598 9.89171 5.2645 9.74379C5.1062 9.53129 5.02772 9.2681 5.02772 9.0049C5.02772 8.71115 5.11635 8.43754 5.28412 8.21462Z" fill={colors.background.light}/>
    </IconWithGradient>);
};
export default IconStatusReceived;
