import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconOpenSquircle = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="green">
      <Path d="M21.6599 14.5421C21.1947 18.2635 18.2635 21.1947 14.5421 21.6599C12.8539 21.8709 11.1461 21.8709 9.45791 21.6599C5.73649 21.1947 2.80525 18.2635 2.34008 14.5421C2.12906 12.8539 2.12906 11.1461 2.34008 9.45791C2.80526 5.73649 5.73649 2.80526 9.45791 2.34008C11.1461 2.12906 12.8539 2.12906 14.5421 2.34008C18.2635 2.80526 21.1947 5.73649 21.6599 9.45791C21.8709 11.1461 21.8709 12.8539 21.6599 14.5421Z" fill={`url(#gradient_${id})`}/>
      <Path fillRule="evenodd" clipRule="evenodd" d="M17.0608 9.10651C17.5752 9.62271 17.5871 10.4719 17.0874 11.0033L12.6985 15.6698C11.8285 16.5949 10.3905 16.5949 9.52038 15.6698L6.91263 12.8971C6.41285 12.3657 6.42477 11.5165 6.93924 11.0002C7.45371 10.484 8.27592 10.4964 8.77569 11.0277L11.1095 13.5091L15.2243 9.134C15.7241 8.60261 16.5463 8.5903 17.0608 9.10651Z" fill="white"/>
    </IconWithGradient>);
};
export default IconOpenSquircle;
