import React, { useCallback, useState } from 'react';
import { Alert, View } from 'react-native';
import Spacer from 'design-system/Spacer';
import { CardView } from 'design-system/CardView';
import { IconEdit } from 'design-system/icons/IconEdit';
import { SettingsItem } from 'design-system/SettingsItem';
import Links from 'utils/links';
import useColors from 'hooks/useColors';
import { getCategories } from 'actions';
import Modal from 'utils/packages/Modal';
import { selectOrderedCategories } from 'reducers/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useMainStackRoute, } from 'utils/types/navigationV6';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import IconTags from '../icons/IconTags';
import { isWeb } from '../../../constants';
const useAdvancedEditTransactions = (navigation, isEditInSearch, goBackKey) => {
    const route = useMainStackRoute();
    const categories = useAppSelector(selectOrderedCategories);
    const colors = useColors();
    const styles = useStyles(styleSet);
    const [isEditing, setIsEditing] = useState(!!route.params?.isEditing);
    const [selected, setSelected] = useState([]);
    const [selectedObj, setSelectedObj] = useState({});
    const dispatch = useAppDispatch();
    const { isUnlockedForSpace, onPressUpgrade } = useShouldShowUpgrade({
        benefitId: 'advancedTransactionsEditing',
    });
    const isEditDisable = !isUnlockedForSpace;
    const toSuccess = useCallback((changeInfo) => {
        navigation.navigate('LearningScreen', {
            keyBack: goBackKey || route.key,
            editData: changeInfo.type === 'tags'
                ? { editType: 'tags', tags: changeInfo.value }
                : {
                    editType: 'categorise',
                    category: changeInfo.value,
                },
            transactionsCount: selected.length,
        });
    }, [goBackKey, route.key, selected.length]);
    const onEdit = useCallback((isEditing) => {
        navigation.setParams({
            isEditing,
        });
        setIsEditing(isEditing);
        setSelected([]);
        setSelectedObj({});
    }, []);
    const onEditFinished = useCallback((changeInfo) => {
        navigation.setParams({ isEditing: false });
        if (isEditInSearch && !isEditDisable) {
            toSuccess(changeInfo);
        }
        setIsEditing(false);
        setSelected([]);
        setSelectedObj({});
    }, [isEditDisable, isEditInSearch, toSuccess]);
    const onSelected = useCallback((data) => {
        setSelected((state) => {
            let array = [...state];
            const index = array.findIndex((item) => item.id === data.id);
            if (index === -1) {
                array.push({
                    id: data.id,
                    currentCategoryId: 'category' in data
                        ? data.category.id
                        : data.categoryId,
                });
            }
            else {
                array = array.filter((item) => item.id !== data.id);
            }
            return array;
        });
        setSelectedObj((prevSelectedObj) => {
            if (prevSelectedObj[data.id]) {
                return {
                    ...prevSelectedObj,
                    [data.id]: undefined,
                };
            }
            return {
                ...prevSelectedObj,
                [data.id]: true,
            };
        });
    }, []);
    const selectAllTransactions = useCallback((transactions) => {
        const allSelected = transactions.map((transaction) => ({
            id: transaction.id,
            currentCategoryId: 'category' in transaction
                ? transaction.category.id
                : transaction.categoryId,
        }));
        const allSelectedObj = transactions.reduce((acc, transaction) => {
            acc[transaction.id] = true;
            return acc;
        }, {});
        setSelected(allSelected);
        setSelectedObj(allSelectedObj);
    }, []);
    const deselectAllTransactions = useCallback(() => {
        setSelected([]);
        setSelectedObj({});
    }, []);
    const onEditCategories = useCallback(() => {
        Modal.hide(() => {
            if (isEditDisable) {
                onEditFinished({ type: null, value: null });
                Alert.alert('Slow Down 🤪', "Advanced transaction editing is an Emma Pro feature. Don't worry! You can still recategorise a transaction by selecting the transaction then the category under the transaction name.", [
                    {
                        text: 'Check Emma Pro',
                        onPress: onPressUpgrade,
                    },
                    {
                        text: 'Learn how to recategorise',
                        onPress: () => Links.openTransactionCategories(),
                    },
                    {
                        text: 'Ok, thanks',
                        style: 'cancel',
                    },
                ]);
                return;
            }
            navigation.navigate('EditCategory', {
                multipleEditing: true,
                selected,
                onEditCallBack: (newCategory) => {
                    onEditFinished({ type: 'categorise', value: newCategory || null });
                },
            });
        });
        if (categories && !categories.length)
            dispatch(getCategories());
    }, [categories, isEditDisable, selected, onEditFinished, onPressUpgrade]);
    const onEditTags = useCallback(() => {
        if (isEditDisable) {
            onEditFinished({ type: null, value: null });
            Alert.alert('Slow Down 🤪', "Advanced transaction editing is an Emma Pro feature. Don't worry! You can still edit tags by going into the transaction screen.", [
                {
                    text: 'Check Emma Pro',
                    onPress: onPressUpgrade,
                },
                {
                    text: 'Ok, thanks',
                    style: 'cancel',
                },
            ]);
            return;
        }
        Modal.hide(() => {
            navigation.navigate('AddTags', {
                type: 'multi',
                ids: selected.map((item) => item.id),
                onEditFinished: () => {
                    onEditFinished({ type: 'tags', value: [] });
                },
            });
        });
    }, [isEditDisable, onEditFinished, onPressUpgrade, selected]);
    const openEditModal = useCallback(() => {
        Modal.show(<View>
        {isWeb && <Spacer h={16}/>}
        <CardView noMargin webPadding style={styles.card}>
          <SettingsItem light={isWeb} title="Categories" onPress={onEditCategories} icon={<IconEdit gradientColors={colors.gradients.skyBlue.colors}/>}/>
        </CardView>
        {isWeb && <Spacer h={16}/>}
        <CardView noMargin webPadding style={styles.card}>
          <SettingsItem title="Tags" light={isWeb} icon={<IconTags />} onPress={onEditTags}/>
        </CardView>
      </View>, {
            title: 'Edit',
            lightBg: true,
            titleBottomBorder: true,
            containerStyle: styles.modalContent,
        });
    }, [
        colors.gradients.skyBlue.colors,
        onEditCategories,
        onEditTags,
        styles.card,
        styles.modalContent,
    ]);
    return {
        isEditing,
        selected,
        openEditModal,
        selectedObj,
        onEdit,
        onSelected,
        selectAllTransactions,
        deselectAllTransactions,
    };
};
const styleSet = createStyleSheets((colors) => ({
    modalContent: {
        ...(!isWeb && {
            paddingHorizontal: 0,
        }),
    },
    card: {
        backgroundColor: isWeb ? colors.background.light : 'transparent',
    },
}));
export default useAdvancedEditTransactions;
