import React from 'react';
import { View } from 'react-native';
import { setAccountType } from 'actions';
import { rem } from 'design-system/values';
import { SettingsItem } from 'design-system/SettingsItem';
import Modal from 'utils/packages/Modal';
import { DUMMY_ACCOUNT_ICON_MAP } from 'features/accounts/hooks/useDummyAccountLogo';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import { muteAll, useFetchOld } from 'hooks';
import useRefreshAccounts from 'hooks/useRefreshAccounts';
import { Separator } from 'design-system/Separator';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableBounceCard from 'utils/packages/TouchableBounceCard';
import useStyles from 'hooks/useStyles';
import i18n from '../../../../i18n';
import { isWeb } from '../../../../constants';
const accountTypeMenuOptions = [
    {
        type: 'CHECKING',
        title: i18n.t('current'),
    },
    {
        type: 'SAVINGS',
        title: 'Savings',
    },
    {
        type: 'INVESTMENT',
        title: 'Investment',
    },
    {
        type: 'PENSION',
        title: 'Pension',
    },
    {
        type: 'REAL_ESTATE',
        title: 'Real Estate',
    },
    {
        type: 'VEHICLE',
        title: 'Vehicle',
    },
    {
        type: 'OTHER',
        title: 'Other Assets',
    },
];
const accountTypeMenuOptionsDebt = [
    {
        type: 'LOAN',
        title: 'Loan',
    },
    {
        type: 'CREDITCARD',
        title: 'Credit Card',
    },
];
const AccountTypeMenu = ({ accountId, currentAccountType, }) => {
    const [, , , fetch] = useFetchOld(muteAll);
    const isDebt = currentAccountType === 'LOAN' || currentAccountType === 'CREDITCARD';
    const { isUnlockedForSpace, onPressUpgrade } = useShouldShowUpgrade({
        benefitId: 'offlineAccounts',
    });
    const refreshAccounts = useRefreshAccounts();
    const onPressType = (type) => () => {
        Modal.hide();
        if (type === currentAccountType)
            return;
        if ((type === 'REAL_ESTATE' || type === 'PENSION' || type === 'VEHICLE') &&
            !isUnlockedForSpace) {
            onPressUpgrade();
            return;
        }
        fetch(setAccountType(type, accountId)).then((response) => {
            if (response) {
                refreshAccounts(type, currentAccountType);
            }
        });
    };
    const options = isDebt ? accountTypeMenuOptionsDebt : accountTypeMenuOptions;
    const styles = useStyles(styleSet);
    return (<>
      <Separator style={styles.separator}/>
      <View style={styles.container}>
        {options.map(({ type, title }) => {
            if (isWeb) {
                return (<TouchableBounceCard onDark noMargin style={styles.card} onPress={onPressType(type)}>
                <SettingsItem light key={type} disabled title={title} icon={DUMMY_ACCOUNT_ICON_MAP[type]} style={isWeb && styles.item}/>
              </TouchableBounceCard>);
            }
            return (<SettingsItem key={type} title={title} light={isWeb} icon={DUMMY_ACCOUNT_ICON_MAP[type]} onPress={onPressType(type)} style={isWeb && styles.item}/>);
        })}
      </View>
    </>);
};
const styleSet = createStyleSheets((colors) => ({
    separator: {
        marginTop: rem(12),
        backgroundColor: colors.borders.divider,
    },
    container: {
        ...(!isWeb && {
            marginTop: rem(20),
        }),
    },
    card: {
        marginTop: rem(16),
        backgroundColor: colors.background.light,
    },
    item: {
        paddingHorizontal: rem(8),
    },
}));
export default AccountTypeMenu;
