import { useMemo } from 'react';
import useColors from 'hooks/useColors';
import { useAppSelector } from 'store/hooks';
import { selectPotsTotalBalance } from 'features/pots/reducers/selectors';
import { selectPortfolioBalance } from 'features/invest/hooks/selectors';
import { selectDefaultCurrency, selectEmmaBalanceWithSpaceFilter } from 'reducers/selectors';
import { selectTotalNetworthBalance } from '../selectors';
export const overviewColorMap = {
    Everyday: 'income',
    Savings: 'feed',
    Investments: 'positive',
    'Net worth': 'brand',
    Debt: 'debt',
    Cash: 'income',
    Checking: 'income',
    Current: 'income',
    Pensions: 'positive',
};
export const useBalances = (data) => {
    const colors = useColors();
    const potsBalance = useAppSelector(selectPotsTotalBalance);
    const defaultCurrency = useAppSelector(selectDefaultCurrency);
    const investPortfolioValue = useAppSelector(selectPortfolioBalance);
    const totalNetWorthBalance = useAppSelector(selectTotalNetworthBalance);
    const emmaWalletBalance = useAppSelector(selectEmmaBalanceWithSpaceFilter);
    const array = useMemo(() => {
        const saved = data && data.saved ? data.saved.amount : 0;
        const available = data && data.available ? data.available.amount : 0;
        const investments = data && data.investments ? data.investments.amount : 0;
        return [
            {
                title: 'Everyday',
                color: colors.text[overviewColorMap.Everyday],
                currency: data?.available?.currency || defaultCurrency,
                amount: available + -(data?.creditCardDebt?.amount || 0) + (emmaWalletBalance?.value || 0),
            },
            {
                title: 'Savings',
                amount: saved + potsBalance.value,
                color: colors.text[overviewColorMap.Savings],
                currency: data?.saved?.currency || defaultCurrency,
            },
            {
                title: 'Investments',
                color: colors.text[overviewColorMap.Investments],
                amount: investments + investPortfolioValue.value,
                currency: data?.investments?.currency || defaultCurrency,
            },
            {
                title: 'Net worth',
                color: colors.text[overviewColorMap['Net worth']],
                currency: totalNetWorthBalance.currency,
                amount: totalNetWorthBalance.amount,
            },
        ];
    }, [
        data,
        colors.text,
        defaultCurrency,
        emmaWalletBalance?.value,
        potsBalance.value,
        investPortfolioValue.value,
        totalNetWorthBalance.currency,
        totalNetWorthBalance.amount,
    ]);
    return array;
};
