import React from 'react';
import { StyleSheet, View } from 'react-native';
import Modal from 'design-system/Modal';
import { rem } from 'design-system/values';
import { IconEdit } from 'design-system/icons';
import { SettingsItem } from 'design-system/SettingsItem';
import PopUpMenuModal from 'design-system/PopUpMenuModal.web';
import { IconPresentation } from 'design-system/icons/IconPresentation';
import useColors from 'hooks/useColors';
import { isWeb } from '../../../constants';
const strings = {
    editBudget: 'Edit budget',
    editBudgetSubtitle: 'Adjust your budgets and period',
    resetBudgeting: 'Reset budgeting',
    resetBudgetingSubTitle: 'Reset your current budgets and period',
};
const ModalToUse = isWeb ? PopUpMenuModal : Modal;
const ManageBudgetingModalContent = ({ onEditBudget, onResetBudget, }) => {
    const colors = useColors();
    return (<View style={styles.modalContent}>
      <SettingsItem light={isWeb} icon={<IconEdit gradientColors={colors.gradients.darkPurple.colors}/>} title={strings.editBudget} description={strings.editBudgetSubtitle} onPress={() => {
            onEditBudget?.();
            ModalToUse.hide();
        }}/>
      <SettingsItem light={isWeb} icon={<IconPresentation gradientColors={colors.gradients.orange.colors}/>} title={strings.resetBudgeting} description={strings.resetBudgetingSubTitle} onPress={() => {
            onResetBudget?.();
            ModalToUse.hide();
        }}/>
    </View>);
};
export default ManageBudgetingModalContent;
const styles = StyleSheet.create({
    modalContent: {
        paddingVertical: rem(isWeb ? 0 : 16),
    },
});
