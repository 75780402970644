import { createSelector } from 'reselect';
import { formatDateForItem } from 'utils/formatting';
export const selectNotificationsListIsFetching = (store) => store.notifications.isFetching === true;
export const selectNotificationsListPage = (store) => store.notifications.page;
export const selectNotificationsListPageCount = (store) => store.notifications.pageCount;
export const selectNotificationsListSections = createSelector([(store) => store.notifications.notificationsArray], (notificationsArray) => {
    const notificationItems = [];
    let activeDate = '';
    notificationsArray?.forEach((notification) => {
        if (notification.datetime.substring(0, 10) !== activeDate.substring(0, 10)) {
            activeDate = notification.datetime;
            notificationItems.push({
                type: 'SECTION_HEADER',
                id: notification.datetime,
                sectionTitle: formatDateForItem(notification.datetime),
            });
        }
        notificationItems.push(notification);
    });
    return notificationItems;
});
