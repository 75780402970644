import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import Modal from 'utils/packages/rnModal';
import ModalHandle from 'design-system/ModalHandle';
import Text from 'design-system/Text';
import { SeparatedList } from 'design-system/SeparatedList';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import Phone from 'utils/dimensions';
import createStyleSheets from 'utils/createStyleSheets';
import { IconBankCard } from 'design-system/icons/IconBankCard';
import Squircle from 'design-system/Squircle';
import useColors from 'hooks/useColors';
import { IconWork } from 'design-system/icons';
import { IconWifi } from 'design-system/icons/IconWifi';
import IconProperty from 'design-system/icons/IconProperty';
import useConnectionsLimitModal from 'features/link-bank/hooks/useConnectionsLimitModal';
import useIsTablet from 'hooks/useIsTablet';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useAppSelector } from 'store/hooks';
import { selectIsGBUser } from 'reducers/selectors';
import IconInvest from 'design-system/icons/IconInvest';
import TapableCard from './TapableCard';
const AddAccountModal = () => {
    const [state, setState] = useState({
        isOpen: false,
    });
    const isGBUser = useAppSelector(selectIsGBUser);
    useEffect(() => {
        AddAccountModal.setStateRef = setState;
    }, []);
    const connectionsLimitModal = useConnectionsLimitModal();
    const onAddPersonal = useCallback(() => {
        setState((o) => ({ ...o, isOpen: false }));
        if (!isGBUser) {
            connectionsLimitModal(() => {
                state.options?.onOpenPlaid();
            });
        }
        else {
            state.options?.navigate({
                name: 'ChooseBank',
                params: {
                    back: true,
                    personal: true,
                },
            });
        }
    }, [isGBUser, state.options, connectionsLimitModal]);
    const onAddInvestments = useCallback(() => {
        setState((o) => ({ ...o, isOpen: false }));
        state.options?.navigate({
            name: 'ChooseBank',
            params: {
                back: true,
                personal: true,
                investments: true,
            },
        });
    }, [state.options]);
    const onAddBusiness = useCallback(() => {
        setState((o) => ({ ...o, isOpen: false }));
        state.options?.navigate({
            name: 'ChooseBank',
            params: {
                back: true,
                business: true,
            },
        });
    }, [state]);
    const colors = useColors();
    const styles = useStyles(styleSet);
    const isTablet = useIsTablet();
    const { width } = useAppFrameDimensions();
    const close = useCallback(() => {
        setState({ isOpen: false });
    }, []);
    return (<Modal isVisible={state.isOpen} hasBackdrop coverScreen={false} style={isTablet ? [styles.modalOuterTablet, { width }] : styles.modalOuter} swipeDirection="down" onBackButtonPress={close} onBackdropPress={close} onSwipeComplete={close} useNativeDriverForBackdrop>
      <View style={isTablet
            ? [styles.modalPad, { width }]
            : styles.modal}>
        <ModalHandle />
        <Text Text-20 centered style={styles.title}>
          Add Account
        </Text>
        <SeparatedList>
          <TapableCard title="Personal" subtitle={isGBUser
            ? 'Current, Savings, Credit Cards and Investments'
            : 'Current, Savings, and Credit Cards'} IconComponent={() => (<Squircle color={colors.background.dark}>
                <IconBankCard color={colors.gradients.green.colors[0]}/>
              </Squircle>)} onPress={onAddPersonal}/>
          {isGBUser && (<TapableCard title="Business" subtitle={'Current, Savings and Credit\xa0Cards'} IconComponent={() => (<Squircle color={colors.background.dark}>
                  <IconWork gradientKey="deepPink"/>
                </Squircle>)} onPress={onAddBusiness}/>)}
          <TapableCard title="Offline Account" subtitle={'Track your cash and accounts\xa0not\xa0in\xa0Emma'} IconComponent={() => (<Squircle color={colors.background.dark}>
                <IconWifi off gradientKey="ultimate"/>
              </Squircle>)} onPress={() => {
            setState((o) => ({ ...o, isOpen: false }));
            state.options?.onAddOffline?.();
        }}/>
          {!isGBUser && (<TapableCard title="Investments" subtitle="Stocks & Crypto" IconComponent={() => (<Squircle color={colors.background.dark}>
                  <IconInvest gradientKey="apricot"/>
                </Squircle>)} onPress={onAddInvestments}/>)}
          {state.options?.onAddProperty && (<TapableCard title="Property" subtitle="Make your net worth accurate" IconComponent={IconProperty} onPress={() => {
                setState((o) => ({ ...o, isOpen: false }));
                state.options?.onAddProperty?.();
            }}/>)}
        </SeparatedList>
        <View style={[
            styles.extraBottomSpacer,
            isTablet && styles.extraBottomSpacerTablet,
        ]}/>
      </View>
    </Modal>);
};
AddAccountModal.show = (options) => {
    if (AddAccountModal.setStateRef) {
        AddAccountModal.setStateRef({ isOpen: true, options });
    }
};
const styleSet = createStyleSheets((colors) => ({
    modalOuter: {
        margin: 0,
        justifyContent: 'flex-end',
    },
    modalOuterTablet: {
        alignSelf: 'center',
        justifyContent: 'center',
    },
    modal: {
        width: '100%',
        height: 'auto',
        borderTopLeftRadius: rem(16),
        borderTopRightRadius: rem(16),
        paddingBottom: Phone.isIphoneX() ? rem(34) : rem(16),
        backgroundColor: colors.cards.onDark,
        zIndex: 9,
    },
    modalPad: {
        height: 'auto',
        borderRadius: rem(16),
        paddingBottom: rem(32),
        backgroundColor: colors.cards.onDark,
    },
    extraBottomSpacer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 100,
        marginBottom: -100,
        backgroundColor: colors.cards.onDark,
    },
    extraBottomSpacerTablet: {
        display: 'none',
    },
    title: {
        marginTop: rem(48),
        marginBottom: rem(16),
    },
}));
export default AddAccountModal;
