import React, { useState } from 'react';
import { FlatList, View, } from 'react-native';
import { drop } from 'lodash';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableBounce from 'utils/packages/TouchableBounce';
const DAYS = Array.from({ length: 31 }, (_, i) => i + 1);
const DaysOfTheMonthSelector = ({ onChange, maxNumberOfDays = 1, style, days = [], }) => {
    const styles = useStyles(styleSet);
    const onPressDay = (day) => {
        const newDays = [
            ...drop(days, days.length === maxNumberOfDays ? 1 : 0),
            day,
        ];
        onChange(newDays);
    };
    const [layout, setLayout] = useState({});
    const renderItem = ({ item }) => {
        const selected = days.includes(item);
        const press = () => onPressDay(item);
        const dayDiameter = layout.width ? (layout.width - 7 * rem(8)) / 7 : 0;
        return (<View>
        <TouchableBounce onPress={press} style={[
                styles.day,
                {
                    width: dayDiameter,
                    height: dayDiameter,
                    borderRadius: dayDiameter,
                },
                selected && styles.daySelected,
                item % 7 === 0 && styles.lastDayInRow,
            ]}>
          {selected ? (<Text Numbers-16 White>
              {item}
            </Text>) : (<Text Numbers-16 Gray>
              {item}
            </Text>)}
        </TouchableBounce>
      </View>);
    };
    const keyExtractor = (item) => item.toString();
    return (<View style={[styles.container, style]} onLayout={({ nativeEvent: { layout } }) => {
            setLayout(layout);
        }}>
      <FlatList scrollEnabled={false} contentContainerStyle={styles.container} numColumns={7} data={DAYS} renderItem={renderItem} columnWrapperStyle={styles.columnWrapper} keyExtractor={keyExtractor}/>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        paddingLeft: rem(2),
    },
    columnWrapper: {
        marginBottom: rem(16),
        flex: 1,
    },
    day: {
        backgroundColor: colors.cards.onLight,
        borderRadius: rem(50),
        justifyContent: 'center',
        alignItems: 'center',
        marginEnd: rem(8),
    },
    daySelected: {
        backgroundColor: colors.elements.brand,
    },
    lastDayInRow: {
        marginEnd: rem(0),
    },
}));
export default DaysOfTheMonthSelector;
