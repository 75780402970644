import { all, call, put, putResolve, select, takeLatest, } from 'typed-redux-saga/macro';
import { getBankConnections, getFeatureFlags, getGroups, getMonitoredAccounts, getUser, } from 'actions';
import { getFxFees, getPendingTopups, getRequiredNationalIdentifiers, } from 'actions/invest';
import { REFRESH_ON_START } from 'actions/types';
import { updateTradingAccount } from 'features/invest/api/account';
import { selectAccountStatus, selectHasCountryOfTaxResidence, selectHasFxFeesFeature, } from 'features/invest/reducers/selectors';
import { getActiveReferralProgram } from 'features/inviteFriend/actions';
import { getPotsInformation } from 'features/pots/actions';
import { selectIsPotsAccountActive } from 'features/pots/reducers/selectors';
import { selectFeedPriority } from 'reducers/selectors';
import { setupDashboardStackWorker } from './RefreshSaga';
function* fetchAppData() {
    log('[onAppActiveSaga] refreshing data...', false, 'cyan');
    yield* all([
        putResolve(getUser()),
        putResolve(getBankConnections()),
        putResolve(getActiveReferralProgram()),
        putResolve(getFeatureFlags()),
    ]);
    yield* call(setupDashboardStackWorker);
    yield* put(getGroups());
    const feedPriority = yield* select(selectFeedPriority);
    if (feedPriority.priority.includes('fraudDetection')) {
        // @ts-ignore
        yield* put(getMonitoredAccounts());
    }
    const isGBUser = yield* select(selectHasFxFeesFeature);
    if (isGBUser) {
        yield* put(getFxFees());
        const countryOfTaxResidence = yield* select(selectHasCountryOfTaxResidence);
        if (countryOfTaxResidence) {
            yield* put(getRequiredNationalIdentifiers());
        }
        const status = yield* select(selectAccountStatus);
        const isPotsAccActive = yield* select(selectIsPotsAccountActive);
        if (status === 'ACTIVE' || isPotsAccActive) {
            yield* put(getPendingTopups());
            updateTradingAccount(true);
        }
        yield* put(getPotsInformation());
    }
}
function* onAppActiveSaga() {
    yield* takeLatest(REFRESH_ON_START, fetchAppData);
}
export default onAppActiveSaga;
