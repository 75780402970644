import { LOGGED_OUT, SET_CONNECTIONS_STATUS } from 'actions/types';
import { SWITCHED_SPACE } from 'features/spaces/constants';
const initialState = {
    pots: [],
    debt: [],
    assets: [],
    savings: [],
    networth: [],
    portfolio: [],
    investment: [],
    emmaWallet: [],
    potsLastFetchedTimeStamp: undefined,
    debtLastFetchedTimeStamp: undefined,
    assetsLastFetchedTimeStamp: undefined,
    savingsLastFetchedTimeStamp: undefined,
    networthLastFetchedTimeStamp: undefined,
    checkingLastFetchedTimeStamp: undefined,
    portfolioLastFetchedTimeStamp: undefined,
    investmentLastFetchedTimeStamp: undefined,
    emmaWalletLastFetchedTimeStamp: undefined,
    potsBalanceHistoryReq: false,
    debtBalanceHistoryReq: false,
    assetsBalanceHistoryReq: false,
    savingsBalanceHistoryReq: false,
    networthBalanceHistoryReq: false,
    checkingBalanceHistoryReq: false,
    portfolioBalanceHistoryReq: false,
    investmentBalanceHistoryReq: false,
    emmaWalletBalanceHistoryReq: false,
};
const balanceHistoryreducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGGED_OUT:
        case SWITCHED_SPACE: {
            return initialState;
        }
        case 'GET_POT_BALANCE_HISTORY_REQUEST': {
            return {
                ...state,
                potsBalanceHistoryReq: true,
            };
        }
        case 'GET_POT_BALANCE_HISTORY_SUCCESS': {
            return {
                ...state,
                pots: action.payload.balances,
                potsBalanceHistoryReq: false,
                potsLastFetchedTimeStamp: new Date().getTime(),
            };
        }
        case 'GET_POT_BALANCE_HISTORY_FAILURE': {
            return {
                ...state,
                potsBalanceHistoryReq: false,
            };
        }
        case 'GET_EMMA_WALLET_BALANCE_HISTORY_REQUEST': {
            return {
                ...state,
                emmaWalletBalanceHistoryReq: true,
            };
        }
        case 'GET_EMMA_WALLET_BALANCE_HISTORY_SUCCESS': {
            const { currency } = action.payload;
            const finalBalances = action.payload.balances.map((balance) => ({
                ...balance,
                currency,
            }));
            return {
                ...state,
                emmaWallet: finalBalances,
                emmaWalletBalanceHistoryReq: false,
                emmaWalletLastFetchedTimeStamp: new Date().getTime(),
            };
        }
        case 'GET_EMMA_WALLET_BALANCE_HISTORY_FAILURE': {
            return {
                ...state,
                emmaWalletBalanceHistoryReq: false,
            };
        }
        case 'GET_PORTFOLIO_BALANCE_HISTORY_REQUEST': {
            return {
                ...state,
                portfolioBalanceHistoryReq: true,
            };
        }
        case 'GET_PORTFOLIO_BALANCE_HISTORY_SUCCESS': {
            return {
                ...state,
                portfolio: action.payload.balances,
                portfolioBalanceHistoryReq: false,
                portfolioLastFetchedTimeStamp: new Date().getTime(),
            };
        }
        case 'GET_PORTFOLIO_BALANCE_HISTORY_FAILURE': {
            return {
                ...state,
                portfolioBalanceHistoryReq: false,
            };
        }
        case 'GET_BALANCE_REQUEST': {
            switch (action.extra.accountType) {
                case 'SAVINGS':
                    return {
                        ...state,
                        savingsBalanceHistoryReq: true,
                    };
                case 'NETWORTH':
                    return {
                        ...state,
                        networthBalanceHistoryReq: true,
                    };
                case 'INVESTMENT':
                    return {
                        ...state,
                        investmentBalanceHistoryReq: true,
                    };
                case 'CHECKING':
                    return {
                        ...state,
                        checkingBalanceHistoryReq: true,
                    };
                case 'DEBT':
                    return {
                        ...state,
                        debtBalanceHistoryReq: true,
                    };
                case 'ASSETS':
                    return {
                        ...state,
                        assetsBalanceHistoryReq: true,
                    };
                default:
                    return state;
            }
        }
        case 'GET_BALANCE_SUCCESS': {
            switch (action.extra.accountType) {
                case 'SAVINGS':
                    return {
                        ...state,
                        savings: action.payload.history,
                        savingsBalanceHistoryReq: false,
                        savingsLastFetchedTimeStamp: new Date().getTime(),
                    };
                case 'NETWORTH':
                    return {
                        ...state,
                        networth: action.payload.history,
                        networthBalanceHistoryReq: false,
                        networthLastFetchedTimeStamp: new Date().getTime(),
                    };
                case 'INVESTMENT':
                    return {
                        ...state,
                        investment: action.payload.history,
                        investmentBalanceHistoryReq: false,
                        investmentLastFetchedTimeStamp: new Date().getTime(),
                    };
                case 'CHECKING':
                    return {
                        ...state,
                        checking: action.payload.history,
                        checkingBalanceHistoryReq: false,
                        checkingLastFetchedTimeStamp: new Date().getTime(),
                    };
                case 'DEBT':
                    return {
                        ...state,
                        debt: action.payload.history,
                        debtBalanceHistoryReq: false,
                        debtLastFetchedTimeStamp: new Date().getTime(),
                    };
                case 'ASSETS':
                    return {
                        ...state,
                        assets: action.payload.history,
                        assetsBalanceHistoryReq: false,
                        assetsLastFetchedTimeStamp: new Date().getTime(),
                    };
                default:
                    return state;
            }
        }
        case 'GET_BALANCE_FAILURE': {
            /**
             * Adding ?. just for extra security
             */
            switch (action.extra?.accountType) {
                case 'SAVINGS':
                    return {
                        ...state,
                        savingsBalanceHistoryReq: false,
                    };
                case 'NETWORTH':
                    return {
                        ...state,
                        networthBalanceHistoryReq: false,
                    };
                case 'INVESTMENT':
                    return {
                        ...state,
                        investmentBalanceHistoryReq: false,
                    };
                case 'CHECKING':
                    return {
                        ...state,
                        checkingBalanceHistoryReq: false,
                    };
                case 'DEBT':
                    return {
                        ...state,
                        debtBalanceHistoryReq: false,
                    };
                case 'ASSETS':
                    return {
                        ...state,
                        assetsBalanceHistoryReq: false,
                    };
                default:
                    return state;
            }
        }
        case SET_CONNECTIONS_STATUS: {
            if (action.payload) {
                return state;
            }
            return {
                ...state,
                debtLastFetchedTimeStamp: undefined,
                assetsLastFetchedTimeStamp: undefined,
                savingsLastFetchedTimeStamp: undefined,
                networthLastFetchedTimeStamp: undefined,
                checkingLastFetchedTimeStamp: undefined,
                portfolioLastFetchedTimeStamp: undefined,
                investmentLastFetchedTimeStamp: undefined,
            };
        }
        default:
            return state;
    }
};
export default balanceHistoryreducer;
