import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
const IconDates = (props) => {
    const id = useId();
    return (<IconWithGradient viewBox="0 0 24 24" id={id} width={24} height={24} defaultGradientKey="plus" {...props}>
      <Path fill={`url(#gradient_${id})`} fillRule="evenodd" d="M8.598 3.002c.375 0 .679.312.679.696v.226a11.979 11.979 0 0 1 5.877 0v-.226c0-.384.303-.696.677-.696.375 0 .678.312.678.696v.733a7.324 7.324 0 0 1 3.542 3.876c.14.352.253.718.34 1.094a12.927 12.927 0 0 1 0 5.758c-.626 2.734-2.706 4.869-5.37 5.51a11.977 11.977 0 0 1-5.611 0c-2.665-.641-4.746-2.776-5.37-5.51a12.926 12.926 0 0 1 0-5.758c.086-.376.2-.742.339-1.094A7.324 7.324 0 0 1 7.92 4.431v-.733c0-.384.304-.696.678-.696Zm-.678 3.02a5.935 5.935 0 0 0-1.804 1.851h12.198a5.935 5.935 0 0 0-1.805-1.852.687.687 0 0 1-.678.692.687.687 0 0 1-.678-.695V5.37a5.757 5.757 0 0 0-.443-.125 10.656 10.656 0 0 0-4.99 0 5.75 5.75 0 0 0-.443.125v.647a.687.687 0 0 1-.679.695.687.687 0 0 1-.678-.692Zm6.193 6.832c0 .543-.228.944-.595 1.211.505.309.79.818.79 1.411 0 1.077-.781 1.745-2.043 1.745-1.269 0-2.042-.668-2.042-1.745 0-.593.285-1.102.781-1.411a1.45 1.45 0 0 1-.594-1.21c0-1.128.855-1.712 1.855-1.712 1.001 0 1.848.584 1.848 1.711Zm-1.115.059c0-.434-.31-.685-.733-.685s-.732.25-.732.685c0 .426.31.693.732.693.424 0 .733-.267.733-.693Zm-1.66 2.413c0 .526.39.81.927.81s.928-.284.928-.81c0-.518-.39-.802-.928-.802-.537 0-.927.284-.927.802Z" clipRule="evenodd"/>
    </IconWithGradient>);
};
export default IconDates;
