import React, { memo, useContext, useCallback } from 'react';
import { View, FlatList } from 'react-native';
import { rem } from 'design-system/values';
import { Button } from 'design-system/Button';
import { CardView } from 'design-system/CardView';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import ContentHeader from '../calendar/ContentHeader';
import { DateFilterContext } from './context';
import SelectorWithButtons from '../analyticsFilter/SelectorWithButtons';
import { isWeb } from '../../constants';
const YearlyContent = () => {
    const styles = useStyles(styleSet);
    const { yearData, selectedYearFilter, setSelectedYearFilter, selectedYearFilterIndex, setSelectedYearFilterIndex, } = useContext(DateFilterContext);
    const change = useCallback((decrease) => () => {
        if (decrease) {
            if (selectedYearFilterIndex >= 0) {
                setSelectedYearFilterIndex((selectedYearFilterIndex) => selectedYearFilterIndex - 1);
            }
            return;
        }
        if (selectedYearFilterIndex < yearData.length - 1) {
            setSelectedYearFilterIndex((selectedYearFilterIndex) => selectedYearFilterIndex + 1);
        }
    }, [selectedYearFilterIndex, yearData.length, setSelectedYearFilterIndex]);
    return (<CardView style={styles.container}>
      <ContentHeader title={selectedYearFilter} style={styles.header}/>
      <SelectorWithButtons title={`${yearData[selectedYearFilterIndex][0]}-${yearData[selectedYearFilterIndex][yearData[selectedYearFilterIndex].length - 1]}`} onForward={change(false)} onBackward={change(true)} disableBackward={selectedYearFilterIndex === 0} disableForward={yearData.length - 1 === selectedYearFilterIndex}/>
      <FlatList numColumns={isWeb ? 4 : 3} data={yearData[selectedYearFilterIndex]} scrollEnabled={false} contentContainerStyle={styles.contentContainerStyles} columnWrapperStyle={styles.columnWrapperStyle} renderItem={({ item }) => (<View style={styles.eachButtonContainer}>
            <Button flex square title={String(item)} containerStyle={styles.buttonStyles} onPress={() => setSelectedYearFilter(item)} {...(selectedYearFilter === item
            ? { brandTextBorder: true }
            : { gray: true })}/>
          </View>)}/>
    </CardView>);
};
export default memo(YearlyContent);
const styleSet = createStyleSheets((colors) => ({
    container: {
        ...(!isWeb
            ? {
                backgroundColor: colors.cards.onDark,
            }
            : {
                backgroundColor: colors.background.light,
            }),
    },
    header: {
        marginVertical: rem(16),
        marginHorizontal: rem(16),
    },
    columnWrapperStyle: {
        flex: 1,
    },
    eachButtonContainer: {
        flex: 1,
        margin: rem(8),
        height: rem(45),
    },
    contentContainerStyles: {
        padding: rem(8),
    },
    buttonStyles: {
        height: rem(42),
    },
}));
