import { LOGGED_OUT } from 'actions/types';
import { SET_CHART_TYPE } from '../actions/types';
import { ChartType } from '../types';
const initialState = {
    chartType: ChartType.bar,
};
const analyticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGGED_OUT: {
            return initialState;
        }
        case SET_CHART_TYPE: {
            return { ...state, chartType: action.payload.chartType };
        }
        default:
            return state;
    }
};
export default analyticsReducer;
