import { REHYDRATE } from 'redux-persist';
import { SELECT_ADDRESS, CLEAR_ADDRESS_LIST, GET_ADDRESSES_FAILURE, GET_ADDRESSES_SUCCESS, GET_USER_ADDRESSES_SUCCESS, POST_USER_ADDRESSES_SUCCESS, UPDATE_USER_ADDRESSES_SUCCESS, } from 'actions/address';
import { LOGGED_OUT } from 'actions/types';
export const initialState = {
    addressesList: [],
    userAddresses: [],
    selectedAddress: undefined,
};
const sortAddresses = (addressA, addressB) => {
    const dateA = new Date(addressA.kycSubmittedAt).valueOf();
    const dateB = new Date(addressB.kycSubmittedAt).valueOf();
    return dateB - dateA;
};
const getNewAddressList = (addresses) => {
    const addressWithKYCStatus = [];
    const addressWithoutKYCStatus = [];
    addresses.forEach((address) => {
        if (address.kycStatus) {
            addressWithKYCStatus.push(address);
        }
        else {
            addressWithoutKYCStatus.push(address);
        }
    });
    addressWithKYCStatus.sort(sortAddresses);
    return [...addressWithKYCStatus, ...addressWithoutKYCStatus];
};
const reducer = (state = initialState, action) => {
    switch (action?.type) {
        case REHYDRATE: {
            if (action.payload && action.payload.addressReducer) {
                return {
                    ...state,
                    ...action.payload.addressReducer,
                    addressesList: [],
                    selectedAddress: undefined,
                };
            }
            return state;
        }
        case CLEAR_ADDRESS_LIST:
        case GET_ADDRESSES_FAILURE: {
            return { ...state, addressesList: [] };
        }
        case GET_ADDRESSES_SUCCESS:
            return { ...state, addressesList: action.payload.addresses };
        case SELECT_ADDRESS: {
            return {
                ...state,
                selectedAddress: action.payload,
            };
        }
        case GET_USER_ADDRESSES_SUCCESS: {
            const userAddresses = action.payload.addresses;
            const newAddressList = getNewAddressList(userAddresses);
            return {
                ...state,
                userAddresses: newAddressList,
            };
        }
        case POST_USER_ADDRESSES_SUCCESS: {
            const addresses = action.payload.address.isPrimary
                ? /**
                   * removing isPrimary from other address
                   */
                    [
                        action.payload.address,
                        ...(state.userAddresses || []).map((userAddress) => ({
                            ...userAddress,
                            isPrimary: false,
                        })),
                    ]
                : [action.payload.address, ...(state.userAddresses || [])];
            const newAddressList = getNewAddressList(addresses);
            return {
                ...state,
                userAddresses: newAddressList,
                selectedAddress: action.payload.address,
            };
        }
        case UPDATE_USER_ADDRESSES_SUCCESS: {
            const addresses = state.userAddresses.map((address) => address.id === action.payload.address.id
                ? action.payload.address
                : address);
            return {
                ...state,
                userAddresses: getNewAddressList(addresses),
            };
        }
        case 'SET_NEW_ADDRESS_IN_USER_ADDRESSES': {
            let isAddressInArray = false;
            const newAddressesArr = state.userAddresses.map((address) => {
                if (address.id === action.address.id) {
                    isAddressInArray = true;
                    return action.address;
                }
                return address;
            });
            if (!isAddressInArray) {
                newAddressesArr.unshift(action.address);
            }
            return {
                ...state,
                userAddresses: getNewAddressList(newAddressesArr),
            };
        }
        case LOGGED_OUT: {
            return initialState;
        }
        default:
            return state;
    }
};
export default reducer;
