import { useCallback } from 'react';
import { UpgradeWebModal } from 'design-system/UpgradeWebModal';
import { selectFeatureFlags } from 'reducers/selectors';
import { useAppSelector } from 'store/hooks';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { isWeb } from '../../../constants';
import { benefits } from '../benefits';
import { checkIsBenefitIdAvailableOnFree } from '../checkIsBenefitIdAvailableOnFree';
import { Tier } from '../entities';
import { isUnlocked } from '../iap';
import { selectUserTier } from '../selectors/tier';
import { useIsTierUnlockedForSpace } from './useIsTierUnlockedForSpace';
const useShouldShowUpgrade = ({ benefitId }) => {
    const { minTier } = benefits[benefitId];
    const isUnlockedForSpace = useIsTierUnlockedForSpace(minTier);
    const userTier = useAppSelector(selectUserTier);
    const guessedHomeCountry = useAppSelector((store) => store.user.user.guessedHomeCountry);
    const navigation = useMainStackNavigation();
    const onPressUpgrade = useCallback(() => {
        if (minTier === Tier.free)
            return;
        if (isWeb) {
            UpgradeWebModal.show(minTier);
            return;
        }
        navigation.navigate('EmmaProModal', {
            tier: minTier,
            isTierRequired: true,
            benefitId,
        });
    }, [benefitId, minTier]);
    const featureFlags = useAppSelector(selectFeatureFlags);
    const isAvailableOnFree = checkIsBenefitIdAvailableOnFree(benefitId, guessedHomeCountry, featureFlags);
    return {
        isUnlockedForSpace: isUnlockedForSpace || isAvailableOnFree,
        isUnlocked: isUnlocked(userTier, minTier) || isAvailableOnFree,
        isAvailableOnFree,
        onPressUpgrade,
    };
};
export default useShouldShowUpgrade;
