import { useRoute } from '@react-navigation/native';
import { useRequiredBenefitCallbackWithSpaces } from 'features/premium/hooks/useRequiredBenefitCallback';
import { useCallback } from 'react';
import { useAppStore } from 'store/hooks';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { selectOrderedActiveSubscriptions } from '../selectors';
import { SUBSCRIPTIONS_ON_FREE_TIER } from '../constants';
const useOpenSubscription = () => {
    const route = useRoute();
    const navigation = useMainStackNavigation();
    const openSubscriptionRequiresPremium = useRequiredBenefitCallbackWithSpaces(useCallback((id) => {
        navigation.navigate('Subscription', {
            keyBack: route.key,
            id,
        });
    }, [route.key]), 'recurringPayments');
    const store = useAppStore();
    const openSubscription = useCallback((data) => {
        const id = typeof data === 'object' ? data.id : data;
        const activeSubscriptions = selectOrderedActiveSubscriptions(store.getState());
        const idx = activeSubscriptions.findIndex((sub) => id === sub.id);
        if (idx > -1 && idx < SUBSCRIPTIONS_ON_FREE_TIER) {
            navigation.navigate('Subscription', {
                keyBack: route.key,
                id,
            });
        }
        else {
            openSubscriptionRequiresPremium(id);
        }
    }, [openSubscriptionRequiresPremium, route.key]);
    return openSubscription;
};
export default useOpenSubscription;
