import React, { forwardRef, useMemo, useImperativeHandle, useRef, memo } from 'react';
import { View, Platform } from 'react-native';
import Animated, { useSharedValue, useAnimatedScrollHandler, useAnimatedStyle, interpolateColor, interpolate, } from 'react-native-reanimated';
import useHeaderHeight from 'hooks/useHeaderHeight';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import useColors from 'hooks/useColors';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { rem } from './values';
import BackgroundView, { DEFAULT_NEGATIVE_MARGIN } from './BackgroundView';
import { AnimatedKeyboardAwareScrollView, LinearGradientAnimated } from './AnimatedComponents';
const SCROLL_EVENT_THROTTLE = 8;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function assertUnreachable(_x) {
    // we should catch this on typecheck stage
}
/**
 * If you need the purple area to get lower than default, specify a lower `negativeMargin` value.
 *
 * Example:
 *
 *   `const [negativeMargin] = useState(rem(-54));`
 *
 * @param headerShouldUseFlex `boolean` - Use only with `scrollEnabled={false}`. Only supported for `primary` with no gradient. When `headerShouldUseFlex={true}`, the layout for the purple area will be calculated using flexbox, and all animations will be disabled.
 */
const ScrollView = forwardRef(({ finalHeaderHeight, expandedHeaderHeight, negativeMargin, scrollY, containerStyle, contentContainerStyle, children, useKeyboardAware, contentOffset, header, bgColorOutputRange, animationInputRange = [0, 40], shadowOpacityOutputRange = [0, 1], headerShouldUseFlex, isInTab, ...props }, ref) => {
    const colors = useColors();
    const fallbackScrollY = useSharedValue(0);
    const headerHeight = useHeaderHeight(finalHeaderHeight);
    const bgColors = useMemo(() => bgColorOutputRange ?? [colors.background.dark, colors.cards.onDark], [bgColorOutputRange, colors.background.dark, colors.cards.onDark]);
    const scrollYToUse = useMemo(() => {
        if (scrollY) {
            return scrollY;
        }
        return fallbackScrollY;
    }, [fallbackScrollY, scrollY]);
    const scrollRef = useRef(null);
    const keyboardAwareScroll = useRef(null);
    useImperativeHandle(ref, () => ({
        scrollTo: (args) => {
            if (useKeyboardAware) {
                if (typeof args === 'object' && args.x !== undefined && args.y !== undefined) {
                    keyboardAwareScroll?.current?.scrollToPosition(args.x, args.y, args.animated);
                }
            }
            else {
                scrollRef?.current?.scrollTo(args);
            }
        },
        scrollToEnd: (args) => {
            if (useKeyboardAware) {
                keyboardAwareScroll?.current?.scrollToEnd(args?.animated);
            }
            else {
                scrollRef?.current?.scrollToEnd(args);
            }
        },
    }), [scrollRef, useKeyboardAware]);
    const scrollHander = useAnimatedScrollHandler(({ contentOffset }) => {
        if (scrollY) {
            // eslint-disable-next-line no-param-reassign
            scrollY.value = contentOffset.y;
        }
        else {
            fallbackScrollY.value = contentOffset.y;
        }
    }, []);
    const headerStyle = useAnimatedStyle(() => ({
        height: headerHeight,
        backgroundColor: interpolateColor(scrollYToUse.value, animationInputRange, bgColors),
        shadowOpacity: interpolate(scrollYToUse.value, animationInputRange, shadowOpacityOutputRange),
    }), [animationInputRange, headerHeight, bgColors, shadowOpacityOutputRange]);
    const androidShadowStyle = useAnimatedStyle(() => ({
        opacity: interpolate(scrollYToUse.value, animationInputRange, shadowOpacityOutputRange),
    }), [animationInputRange, shadowOpacityOutputRange]);
    const colorVariation = props;
    const styles = useStyles(styleSet);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isInTab);
    const scrollView = useMemo(() => useKeyboardAware ? (<AnimatedKeyboardAwareScrollView ref={keyboardAwareScroll} style={[styles.scrollView, props.refreshControl && { marginTop: expandedHeaderHeight ?? headerHeight }]} contentContainerStyle={[
            paddingHorizontalStyle,
            contentContainerStyle,
            { paddingTop: props.refreshControl ? rem(16) : expandedHeaderHeight ?? headerHeight + rem(16) },
        ]} onScroll={scrollHander} scrollEventThrottle={SCROLL_EVENT_THROTTLE} overScrollMode="never" {...props}>
            {children}
          </AnimatedKeyboardAwareScrollView>) : (<Animated.ScrollView ref={scrollRef} style={[styles.scrollView, props.refreshControl && { marginTop: expandedHeaderHeight ?? headerHeight }]} contentContainerStyle={[
            paddingHorizontalStyle,
            contentContainerStyle,
            { paddingTop: props.refreshControl ? rem(16) : expandedHeaderHeight ?? headerHeight + rem(16) },
        ]} onScroll={scrollHander} contentOffset={contentOffset} scrollEventThrottle={SCROLL_EVENT_THROTTLE} overScrollMode="never" {...props}>
            {children}
          </Animated.ScrollView>), [
        useKeyboardAware,
        styles.scrollView,
        props,
        expandedHeaderHeight,
        headerHeight,
        contentContainerStyle,
        paddingHorizontalStyle,
        scrollHander,
        children,
        contentOffset,
    ]);
    if ('color' in colorVariation) {
        return (<View style={[styles.container, containerStyle]}>
          <BackgroundView color={colorVariation.color} scrollY={scrollY ?? fallbackScrollY} finalHeaderHeight={finalHeaderHeight} expandedHeaderHeight={expandedHeaderHeight} negativeMargin={negativeMargin}/>
          {scrollView}
          <BackgroundView color={colorVariation.color} bottom scrollY={scrollY ?? fallbackScrollY} finalHeaderHeight={finalHeaderHeight} expandedHeaderHeight={expandedHeaderHeight} negativeMargin={negativeMargin}>
            {header}
          </BackgroundView>
        </View>);
    }
    /**
     * To have animating header title, please use alongside with ReanimatedStackHeader
     */
    if ('secondary' in colorVariation) {
        return (<View style={[styles.container, containerStyle]}>
          {scrollView}
          <Animated.View style={[styles.secondaryHeader, headerStyle]}/>
          {Platform.OS === 'android' && (<LinearGradientAnimated style={[styles.androidShadow, { top: headerHeight }, androidShadowStyle]} colors={[
                    colors.shadows.androidShadowGradientStart,
                    colors.shadows.androidShadowGradientEnd,
                ]}/>)}
        </View>);
    }
    if ('primary' in colorVariation) {
        if (colorVariation.gradient) {
            return (<View style={[styles.container, containerStyle]}>
            <BackgroundView primary gradient scrollY={scrollY ?? fallbackScrollY} finalHeaderHeight={finalHeaderHeight} expandedHeaderHeight={expandedHeaderHeight} negativeMargin={negativeMargin}/>
            {scrollView}
            <BackgroundView primary gradient bottom scrollY={scrollY ?? fallbackScrollY} finalHeaderHeight={finalHeaderHeight} expandedHeaderHeight={expandedHeaderHeight} negativeMargin={negativeMargin}>
              {header}
            </BackgroundView>
          </View>);
        }
        if (props.scrollEnabled === false && headerShouldUseFlex) {
            return (<View style={[styles.container, containerStyle]}>
            <BackgroundView primary scrollY={scrollY ?? fallbackScrollY} negativeMargin={negativeMargin} flex>
              {header}
            </BackgroundView>
            <View style={[contentContainerStyle, { marginTop: negativeMargin ?? -DEFAULT_NEGATIVE_MARGIN }]}>
              {children}
            </View>
          </View>);
        }
        return (<View style={[styles.container, containerStyle]}>
          <BackgroundView primary scrollY={scrollY ?? fallbackScrollY} finalHeaderHeight={finalHeaderHeight} expandedHeaderHeight={expandedHeaderHeight} negativeMargin={negativeMargin}/>
          {scrollView}
          <BackgroundView primary bottom scrollY={scrollY ?? fallbackScrollY} finalHeaderHeight={finalHeaderHeight} expandedHeaderHeight={expandedHeaderHeight} negativeMargin={negativeMargin}>
            {header}
          </BackgroundView>
        </View>);
    }
    if ('gradient' in colorVariation) {
        return (<View style={[styles.container, containerStyle]}>
          <BackgroundView gradient={colorVariation.gradient} scrollY={scrollY ?? fallbackScrollY} finalHeaderHeight={finalHeaderHeight} expandedHeaderHeight={expandedHeaderHeight} negativeMargin={negativeMargin}/>
          {scrollView}
          <BackgroundView gradient={colorVariation.gradient} bottom scrollY={scrollY ?? fallbackScrollY} finalHeaderHeight={finalHeaderHeight} expandedHeaderHeight={expandedHeaderHeight} negativeMargin={negativeMargin}/>
        </View>);
    }
    assertUnreachable(colorVariation);
    // fallback
    return (<View style={[styles.container, containerStyle]}>
        <BackgroundView primary scrollY={scrollY ?? fallbackScrollY} finalHeaderHeight={finalHeaderHeight} expandedHeaderHeight={expandedHeaderHeight} negativeMargin={negativeMargin}/>
        {scrollView}
        <BackgroundView primary bottom scrollY={scrollY ?? fallbackScrollY} finalHeaderHeight={finalHeaderHeight} expandedHeaderHeight={expandedHeaderHeight} negativeMargin={negativeMargin}/>
      </View>);
});
const styleSet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
        backgroundColor: colors.background.dark,
    },
    scrollView: {
        flex: 1,
    },
    secondaryHeader: {
        top: 0,
        left: 0,
        right: 0,
        position: 'absolute',
        ...colors.shadows.card,
    },
    androidShadow: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 10,
    },
}));
export default memo(ScrollView);
