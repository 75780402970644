import React from 'react';
import { StyleSheet, View } from 'react-native';
import ToolTip from 'design-system/ToolTip';
import Text from 'design-system/Text';
import Spacer from './Spacer';
import { rem } from './values';
const SectionHeader = ({ children, style, button, horizontalMargins, verticalMargins, largeVerticalMargins, onLight, toolTipTitle, toolTipSubTitle, }) => {
    const colors = onLight
        ? { 'Gray-50': true }
        : { Secondary: true };
    const title = (<Text Text-16 {...colors}>
      {children}
    </Text>);
    return (<View style={[
            styles.container,
            horizontalMargins && styles.horizontalMargins,
            verticalMargins && styles.verticalMargins,
            largeVerticalMargins && styles.largeVerticalMargins,
            style,
        ]} testID="section-header">
      {!!toolTipTitle && !!toolTipSubTitle ? (<View style={styles.titleRow}>
          {title}
          <Spacer w={6}/>
          <ToolTip small title={toolTipTitle} subtitle={toolTipSubTitle}/>
        </View>) : (<View style={styles.flex}>{title}</View>)}
      {button}
    </View>);
};
const styles = StyleSheet.create({
    flex: {
        flex: 1,
    },
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    horizontalMargins: {
        marginHorizontal: rem(16),
    },
    verticalMargins: {
        marginTop: rem(16),
        marginBottom: rem(8),
    },
    largeVerticalMargins: {
        paddingTop: rem(24),
        paddingBottom: rem(16),
    },
    titleRow: {
        flex: 1,
        alignItems: 'center',
        flexDirection: 'row',
    },
});
export default SectionHeader;
