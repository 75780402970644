import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import NotificationBanner from 'design-system/NotificationBanner';
import Text from 'design-system/Text';
import { setDidClosePotsCTAfromAnalytics, setDidClosePotsCTAfromBudgeting, setDidClosePotsCTAfromPots, setDidClosePotsCTAfromTransactions, } from 'actions/deviceSettings';
import { assertUnreachable } from 'utils';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import useHandleOpenInterestPot from '../hooks/useHandleOpenInterestPot';
import { selectMaximumInterest, selectPotsStatuses, selectShouldShowOpenPotsNotificationBanner, } from '../reducers/selectors';
const OpenPotsAccountNotificationBanner = ({ from, noMarginTop, noMarginBottom, buttonText = 'Open pot', }) => {
    const dispatch = useAppDispatch();
    const navigation = useMainStackNavigation();
    const { status } = useAppSelector(selectPotsStatuses);
    const maximumInterest = useAppSelector(selectMaximumInterest);
    const didCloseInPast = useAppSelector((state) => selectShouldShowOpenPotsNotificationBanner(state, from));
    const navigateToOpenInterestPot = useHandleOpenInterestPot();
    const handleCancel = useCallback(() => {
        switch (from) {
            case 'Analytics':
                dispatch(setDidClosePotsCTAfromAnalytics());
                break;
            case 'Budgeting':
                dispatch(setDidClosePotsCTAfromBudgeting());
                break;
            case 'Transactions':
                dispatch(setDidClosePotsCTAfromTransactions());
                break;
            case 'Pots':
                dispatch(setDidClosePotsCTAfromPots());
                break;
            default:
                assertUnreachable(from);
                break;
        }
    }, [from]);
    const handleOpenPot = useCallback(() => {
        if (from === 'Pots') {
            navigateToOpenInterestPot();
        }
        else {
            navigation.navigate('Save', { tab: 'pots' });
        }
    }, [from]);
    if (from === 'Pots' && (!maximumInterest || !!didCloseInPast)) {
        return null;
    }
    if (from !== 'Pots' && (!maximumInterest || status === 'ACTIVE' || didCloseInPast)) {
        return null;
    }
    return (<NotificationBanner lightButton noMarginTop={noMarginTop} noMarginBottom={noMarginBottom} onCancel={handleCancel} title="Grow your savings" cancelButtonTitle="Dismiss" positiveButtonTitle={buttonText} onPositiveBtnPress={handleOpenPot} description={<Text TextThin-14 Secondary>
          Earn up to{' '}
          <Text NumbersThin-14 Secondary>
            {maximumInterest}
          </Text>
          % AER when opening an Interest Pot.{' '}
          <Text Text-14 Secondary>
            FSCS applies.
          </Text>
        </Text>}/>);
};
export default OpenPotsAccountNotificationBanner;
