import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import getSymbolFromCurrency from 'currency-symbol-map';
import { getBankConnections, setOverdraft } from 'actions';
import { AppView } from 'design-system/AppView';
import Button from 'design-system/Button';
import KeyboardAvoidingContainer from 'design-system/KeyboardAvoidingContainer';
import Text, { styles as textStyles } from 'design-system/Text';
import { muteAll, useFetchOld } from 'hooks';
import useHeaderHeight from 'hooks/useHeaderHeight';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import useColors from 'hooks/useColors';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useAutoFocusInput } from 'hooks/useAutoFocusInput';
import { useMarginBottom } from 'hooks/useMarginBottom';
import StatusBar from 'design-system/StatusBar';
import usePrivateRoute from 'hooks/usePrivateRoute';
import { selectAccountById, selectDefaultCurrency } from 'reducers/selectors';
import RNMaskedText from 'utils/packages/rnMaskedText';
import ScreenInfoHeader from 'design-system/ScreenInfoHeader';
import EmptyComponent from 'design-system/EmptyComponent';
import { rem } from 'design-system/values';
import { isWeb } from '../../../constants';
const EditOverdraftScreen = ({ navigation, route, }) => {
    usePrivateRoute();
    const colors = useColors();
    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Edit Overdraft',
            ...(isWeb && {
                headerTitle: EmptyComponent,
            }),
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary]);
    const account = useAppSelector((store) => selectAccountById(store, route.params.id));
    const defaultCurrency = useAppSelector(selectDefaultCurrency);
    const currency = account?.nativeBalance
        ? account?.nativeBalance.currency
        : account?.currency || defaultCurrency;
    const defaultValue = account?.nativeBalance?.overdraftLimit?.toString() ||
        account?.overdraftLimit?.toString();
    const [value, setValue] = useState(defaultValue || '0');
    const [rawValue, setRawValue] = useState(defaultValue);
    useEffect(() => {
        if (value === '0' && defaultValue) {
            setValue(defaultValue);
            setRawValue(defaultValue);
        }
    }, [defaultValue]);
    const onChangeText = (val, raw) => {
        setValue(val);
        if (typeof raw !== 'undefined') {
            setRawValue(raw);
        }
    };
    const dispatch = useAppDispatch();
    const [, , , fetch] = useFetchOld(muteAll);
    const onPressSubmit = () => {
        fetch(setOverdraft(route.params.id, rawValue)).then((response) => {
            if (response)
                dispatch(getBankConnections());
        });
        if (navigation.canGoBack()) {
            navigation.goBack();
        }
        else {
            navigation.navigate('Transactions', { id: route.params.id });
        }
    };
    const { width } = useAppFrameDimensions();
    const headerHeight = useHeaderHeight();
    const styles = useStyles(styleSheet);
    const marginBottom = useMarginBottom('marginBottom', undefined, styles.container);
    const textInputRef = useRef(null);
    useAutoFocusInput(textInputRef);
    const subTitle = `What's your pre-arranged overdraft limit for ${account?.name}?`;
    return (<AppView type={isWeb ? 'secondary' : 'primary'} isInTab={isWeb} avoidHeader={isWeb}>
      <StatusBar barStyle="dark-content" animated/>
      <KeyboardAvoidingContainer>
        {isWeb ? (<ScreenInfoHeader title={'Edit Overdraft'} subTitle={subTitle} smallSubTitle style={styles.infoTitle}/>) : (<Text TextThin-20 OffBlack style={{ marginTop: headerHeight }}>
            {subTitle}
          </Text>)}
        <View style={marginBottom}>
          <RNMaskedText ref={textInputRef} style={[styles.textinput, !isWeb && { width: width - 40 }]} type="money" allowFontScaling={false} underlineColorAndroid="transparent" options={{
            unit: getSymbolFromCurrency(currency),
            separator: '.',
            delimiter: ',',
            precision: 0,
        }} keyboardType="number-pad" onChangeText={onChangeText} includeRawValueInChangeText value={value} selectionColor={colors.text.selection} placeholderTextColor={colors.text.placeholder} onSubmitEditing={onPressSubmit}/>
        </View>
        <Button brand onPress={onPressSubmit} title="Save"/>
      </KeyboardAvoidingContainer>
    </AppView>);
};
const styleSheet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
        alignItems: 'flex-start',
        ...(!isWeb && {
            justifyContent: 'center',
            alignItems: 'center',
        }),
    },
    infoTitle: {
        paddingHorizontal: 0,
    },
    textinput: {
        ...(isWeb
            ? {
                ...textStyles.Numbers_24,
                color: colors.text.primary,
                alignSelf: 'flex-start',
                width: rem(122),
            }
            : {
                ...textStyles.Numbers_64,
            }),
        color: colors.text.primary,
        textAlign: 'center',
    },
}));
export default EditOverdraftScreen;
