import queryString from 'query-string';
import { CALL_API } from '../../../middleware/api';
import * as types from './types';
export const fetchCommittedForCurrentPaydayRange = () => ({
    type: types.FETCH_COMMITTED_FOR_CURRENT_PAYDAY_RANGE,
});
export const getCommitted = (from, until) => ({
    [CALL_API]: {
        endpoint: `/analytics/committed/?from=${from}&until=${until}&includeInternal=true`,
        method: 'GET',
        types: [
            types.COMMITTED_REQUEST,
            types.COMMITTED_SUCCESS,
            types.COMMITTED_FAILURE,
        ],
        addSpaceIdHeader: true,
    },
});
export const isRefreshingSubscriptions = () => ({
    type: types.IS_REFRESHING_SUBSCRIPTIONS,
});
export const cancelSubscriptionAction = (id) => ({
    [CALL_API]: {
        endpoint: `/subscriptions/${id}/flag-incorrect`,
        method: 'POST',
        types: [
            types.CANCEL_SUBSCRIPTIONS_REQUEST,
            types.CANCEL_SUBSCRIPTIONS_SUCCESS,
            types.CANCEL_SUBSCRIPTIONS_FAILURE,
        ],
        addSpaceIdHeader: true,
    },
});
export const addSubscription = (data) => ({
    [CALL_API]: {
        endpoint: '/subscriptions',
        method: 'POST',
        body: JSON.stringify(data),
        extra: data,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.ADD_SUBSCRIPTIONS_REQUEST,
            types.ADD_SUBSCRIPTIONS_SUCCESS,
            types.ADD_SUBSCRIPTIONS_FAILURE,
        ],
        addSpaceIdHeader: true,
    },
});
export const editSubscription = (id, data) => ({
    [CALL_API]: {
        endpoint: `/subscriptions/${id}`,
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.EDIT_SUBSCRIPTIONS_REQUEST,
            types.EDIT_SUBSCRIPTIONS_SUCCESS,
            types.EDIT_SUBSCRIPTIONS_FAILURE,
        ],
        addSpaceIdHeader: true,
        extra: { subscriptionId: id },
    },
});
export const getSubscriptions = () => ({
    [CALL_API]: {
        endpoint: '/subscriptions?includeInactive=true',
        method: 'GET',
        types: [
            types.SUBSCRIPTIONS_REQUEST,
            types.SUBSCRIPTIONS_SUCCESS,
            types.SUBSCRIPTIONS_FAILURE,
        ],
        addSpaceIdHeader: true,
    },
});
export const getSubscriptionsById = (id) => ({
    [CALL_API]: {
        endpoint: `/subscriptions/${id}`,
        method: 'GET',
        types: [
            types.SUBSCRIPTIONS_ID_REQUEST,
            types.SUBSCRIPTIONS_ID_SUCCESS,
            types.SUBSCRIPTIONS_ID_FAILURE,
        ],
        extra: { subscriptionId: id },
        addSpaceIdHeader: true,
    },
});
export const addTxnsToSubscription = (id, ids) => ({
    [CALL_API]: {
        endpoint: `/subscriptions/${id}/transactions/add`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ transactionIds: ids }),
        types: [
            types.ADD_TXNS_TO_SUBSCRIPTION_REQUEST,
            types.ADD_TXNS_TO_SUBSCRIPTION_SUCCESS,
            types.ADD_TXNS_TO_SUBSCRIPTION_FAILURE,
        ],
        extra: { ids },
        addSpaceIdHeader: true,
    },
});
export const removeTxnsFromSubscription = (id, ids) => ({
    [CALL_API]: {
        endpoint: `/subscriptions/${id}/transactions/remove`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ transactionIds: ids }),
        types: [
            types.DELETE_TXN_FROM_SUBSCRIPTION_REQUEST,
            types.DELETE_TXN_FROM_SUBSCRIPTION_SUCCESS,
            types.DELETE_TXN_FROM_SUBSCRIPTION_FAILURE,
        ],
        extra: { ids },
        addSpaceIdHeader: true,
    },
});
export const resetSubscriptionTransactions = () => ({
    type: types.RESET_SUBSCRIPTION_TRANSACTION,
});
export const cleanSubscriptionSearch = () => ({
    type: types.CLEAN_SUBSCRIPTIONS,
});
export const searchSubscriptions = (data) => ({
    [CALL_API]: {
        endpoint: `/subscriptions-suggest-trxs?${queryString.stringify({
            includeRecurring: true,
            query: data,
        })}`,
        method: 'GET',
        types: [
            types.SEARCH_SUBSCRIPTION_REQUEST,
            types.SEARCH_SUBSCRIPTION_SUCCESS,
            types.SEARCH_SUBSCRIPTION_FAILURE,
        ],
        addSpaceIdHeader: true,
    },
});
