import React, { useLayoutEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { formatISO, format } from 'date-fns';
import Button from 'design-system/Button';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import StatusBar from 'design-system/StatusBar';
import { AppView } from 'design-system/AppView';
import useColors from 'hooks/useColors';
import { formatAmount } from 'utils/formatting';
import { goBackWithKey } from 'utils/navigationv6';
import useFetchOld, { muteErrorAndResult } from 'hooks/useFetch';
import AboutInfoRow from 'features/invest/components/AboutInfoRow';
import { FREQUENCY } from 'features/subscriptions/utils/frequency';
import { isWeb } from '../../../constants';
import SubscriptionLogo from '../components/SubscriptionLogo';
import { editSubscription, getSubscriptionsById } from '../actions';
const CorrectedPredictionScreen = ({ navigation, route, }) => {
    const colors = useColors();
    useLayoutEffect(() => {
        navigation.setOptions({
            headerTitle: '',
            title: 'Confirm Prediction',
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary]);
    const [, , isFetching, fetch] = useFetchOld(muteErrorAndResult);
    const { subscription, correctedAmount, correctedDate } = route.params;
    const onPressConfirm = () => {
        fetch(editSubscription(subscription.id, {
            userPredictedAmount: correctedAmount,
            userPredictedDate: formatISO(correctedDate),
            isActive: true,
        })).then(() => {
            fetch(getSubscriptionsById(subscription.id));
        });
        goBackWithKey(navigation, route.params.keyBack);
    };
    const frequency = subscription.paymentFrequency
        ? FREQUENCY[subscription.paymentFrequency]
        : undefined;
    return (<AppView paddingBottom isInTab={isWeb}>
      <StatusBar animated barStyle="dark-content"/>
      <View style={styles.content}>
        <View style={styles.logoContainer}>
          <SubscriptionLogo R46 url={subscription.merchantInfo?.iconUrl}/>
        </View>
        <Spacer h={48}/>
        <AboutInfoRow title="Vendor" info={subscription.merchantInfo?.displayName}/>
        <AboutInfoRow title="Amount" info={formatAmount(Number(correctedAmount), subscription.currency, 2)}/>
        <AboutInfoRow title="Next payment" info={format(correctedDate, 'd MMM, yyyy')}/>
        <AboutInfoRow title="Frequency" info={frequency}/>
      </View>
      <View style={styles.buttonContainer}>
        <Button brand isFetching={isFetching} onPress={onPressConfirm} title="Confirm prediction"/>
      </View>
    </AppView>);
};
const styles = StyleSheet.create({
    content: {
        flex: 1,
        justifyContent: 'center',
    },
    logoContainer: {
        alignItems: 'center',
    },
    buttonContainer: {
        paddingHorizontal: rem(16),
    },
});
export default CorrectedPredictionScreen;
