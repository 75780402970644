import React from 'react';
import Suggestions from 'design-system/suggestions/Suggestions';
const SUGGESTIONS_DATA = [
    {
        emoji: '💸',
        name: 'Savings',
    },
    {
        emoji: '🔒',
        name: 'Insurance',
    },
    {
        emoji: '🎁',
        name: 'Gifts',
    },
    {
        emoji: '➿',
        name: 'Subscriptions',
    },
    {
        emoji: '🐶',
        name: 'Pets',
    },
    {
        emoji: '🚗',
        name: 'Car',
    },
    {
        emoji: '☕️',
        name: 'Coffee',
    },
    {
        emoji: '👗',
        name: 'Clothes',
    },
    {
        emoji: '🔑',
        name: 'Rent',
    },
];
const CustomCategorySuggestions = ({ name, handleSuggestionPress, suggestedData, }) => (<Suggestions title="Suggestions" data={SUGGESTIONS_DATA} name={name} handleSuggestionPress={handleSuggestionPress} suggestedData={suggestedData}/>);
export default CustomCategorySuggestions;
