import React, { useCallback } from 'react';
import { IconTrash } from 'design-system/icons';
import { SettingsItemNavigate } from 'design-system/SettingsItem';
import { useAppDispatch } from 'store/hooks';
import { muteAll, useFetchOld } from 'hooks/useFetch';
import { deleteTransaction } from 'actions/transactions';
import { getBankConnections } from 'actions/connections';
import { useMainStackNavigation } from 'utils/types/navigationV6';
const TransactionDeleteItem = ({ transaction }) => {
    const [, , , fetch] = useFetchOld(muteAll);
    const dispatch = useAppDispatch();
    const navigation = useMainStackNavigation();
    const onDeletePressed = useCallback(async () => {
        await fetch(deleteTransaction(transaction.id));
        dispatch(getBankConnections());
        navigation.goBack();
    }, [transaction.id]);
    if (!('provider' in transaction && transaction?.provider === 'MANUAL')) {
        return null;
    }
    return (<SettingsItemNavigate icon={<IconTrash />} title="Delete this transaction" onPress={onDeletePressed}/>);
};
export default TransactionDeleteItem;
