import { rem } from 'design-system';
export const PAGE_WIDTH = rem(32);
export const PAGE_HEIGHT = rem(32);
export const CAROUSEL_WIDTH = rem(32);
export const AVATAR_SIZE = 32;
//  animation
export const GROW_DURATION = 100;
export const REPEATING_GROW_DURATION = 900;
export const DEFAULT_SCALE = 0.85;
export const START_SCALE = 1;
export const REPEATED_SCALE = 1.1;
export const AUTOPLAY_DELAY = 1000;
export const AUTOPLAY_INTERVAL = 2000;
export const MAX_CYCLES_COUNT = 3;
