import { LOGGED_OUT } from 'actions/types';
import { sortAscByStringWithKey } from 'utils/arrayHelpers';
import { AssetStateCache } from '../types';
const initialState = {
    watchListAssetCache: {},
    stockWatchListsInfoMap: {},
};
const watchListReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_WATCHLIST_SUCCESS': {
            const { id, assets } = action.payload.watchlist;
            sortAscByStringWithKey(assets, 'name');
            const stockWatchListsInfoMap = {};
            const assetsWithAssetState = assets.map((asset) => {
                stockWatchListsInfoMap[asset.symbol] = {
                    ...state.stockWatchListsInfoMap?.[asset.symbol],
                    [id]: true,
                };
                return {
                    ...asset,
                    state: AssetStateCache.SYNCED,
                };
            });
            return {
                ...state,
                watchListAssetCache: {
                    ...state.watchListAssetCache,
                    [id]: assetsWithAssetState,
                },
                stockWatchListsInfoMap: {
                    ...state.stockWatchListsInfoMap,
                    ...stockWatchListsInfoMap,
                },
            };
        }
        case 'ADD_ASSET_TO_WATCHLIST_REQUEST': {
            const { watchlistId, asset } = action.extra;
            const watchListAssetCache = state.watchListAssetCache?.[watchlistId] || [];
            const hasAsset = watchListAssetCache.find((cachedAsset) => asset.symbol === cachedAsset.symbol);
            if (!hasAsset) {
                const newWatchListAssetCache = 'sharePrice' in asset
                    ? [...watchListAssetCache, { ...asset, state: AssetStateCache.ADDING }]
                    : watchListAssetCache;
                sortAscByStringWithKey(newWatchListAssetCache, 'name');
                return {
                    ...state,
                    watchListAssetCache: {
                        ...state.watchListAssetCache,
                        [watchlistId]: newWatchListAssetCache,
                    },
                    stockWatchListsInfoMap: {
                        ...state.stockWatchListsInfoMap,
                        [asset.symbol]: {
                            ...state.stockWatchListsInfoMap?.[asset.symbol],
                            [watchlistId]: true,
                        },
                    },
                };
            }
            return state;
        }
        case 'REMOVE_ASSET_FROM_WATCHLIST_REQUEST': {
            const { watchlistId, symbol } = action.extra;
            const watchListAssetCache = state.watchListAssetCache?.[watchlistId] || [];
            const newWatchListAssetCache = watchListAssetCache.map((cachedAsset) => {
                if (cachedAsset.symbol === symbol) {
                    return {
                        ...cachedAsset,
                        state: AssetStateCache.REMOVING,
                    };
                }
                return cachedAsset;
            });
            const stockWatchListsInfoMap = state.stockWatchListsInfoMap?.[symbol] || {};
            delete stockWatchListsInfoMap[watchlistId];
            return {
                ...state,
                watchListAssetCache: {
                    ...state.watchListAssetCache,
                    [watchlistId]: newWatchListAssetCache,
                },
                stockWatchListsInfoMap: {
                    ...state.stockWatchListsInfoMap,
                    [symbol]: {
                        ...stockWatchListsInfoMap,
                    },
                },
            };
        }
        case 'ADD_ASSET_TO_WATCHLIST_ERROR': {
            const { watchlistId, symbol } = action.extra;
            const watchListAssetCache = state.watchListAssetCache?.[watchlistId] || [];
            const newWatchListAssetCache = watchListAssetCache.filter((cachedAsset) => cachedAsset.symbol !== symbol);
            const stockWatchListsInfoMap = state.stockWatchListsInfoMap?.[symbol] || {};
            delete stockWatchListsInfoMap[watchlistId];
            return {
                ...state,
                watchListAssetCache: {
                    ...state.watchListAssetCache,
                    [watchlistId]: newWatchListAssetCache,
                },
                stockWatchListsInfoMap: {
                    ...state.stockWatchListsInfoMap,
                    [symbol]: {
                        ...stockWatchListsInfoMap,
                    },
                },
            };
        }
        case 'REMOVE_ASSET_FROM_WATCHLIST_ERROR': {
            const { watchlistId, symbol } = action.extra;
            const watchListAssetCache = state.watchListAssetCache?.[watchlistId] || [];
            const newWatchListAssetCache = watchListAssetCache.map((cachedAsset) => {
                if (cachedAsset.symbol === symbol) {
                    return {
                        ...cachedAsset,
                        state: AssetStateCache.SYNCED,
                    };
                }
                return cachedAsset;
            });
            return {
                ...state,
                watchListAssetCache: {
                    ...state.watchListAssetCache,
                    [watchlistId]: newWatchListAssetCache,
                },
                stockWatchListsInfoMap: {
                    ...state.stockWatchListsInfoMap,
                    [symbol]: {
                        ...state.stockWatchListsInfoMap?.[symbol],
                        [watchlistId]: true,
                    },
                },
            };
        }
        case 'ADD_ASSET_TO_WATCHLIST_SUCCESS': {
            const { extra: { symbol }, payload: { watchlist: { id, assets }, }, } = action;
            const watchListAssetCache = state.watchListAssetCache?.[id] || [];
            const newAddedAsset = assets.find((asset) => asset.symbol === symbol);
            let newWatchListAssetCache;
            if (newAddedAsset) {
                let hasAsset = false;
                newWatchListAssetCache = watchListAssetCache.map((cachedAsset) => {
                    if (cachedAsset.symbol !== symbol) {
                        return cachedAsset;
                    }
                    hasAsset = true;
                    return {
                        ...newAddedAsset,
                        state: AssetStateCache.SYNCED,
                    };
                });
                if (!hasAsset) {
                    newWatchListAssetCache.push({
                        ...newAddedAsset,
                        state: AssetStateCache.SYNCED,
                    });
                }
                sortAscByStringWithKey(newWatchListAssetCache, 'name');
            }
            else {
                newWatchListAssetCache = watchListAssetCache;
            }
            return {
                ...state,
                watchListAssetCache: {
                    ...state.watchListAssetCache,
                    [id]: newWatchListAssetCache,
                },
            };
        }
        case 'REMOVE_ASSET_FROM_WATCHLIST_SUCCESS': {
            const { extra: { symbol }, payload: { watchlist: { id }, }, } = action;
            const watchListAssetCache = state.watchListAssetCache?.[id] || [];
            const newWatchListAssetCache = watchListAssetCache.filter((asset) => asset.symbol !== symbol);
            return {
                ...state,
                watchListAssetCache: {
                    ...state.watchListAssetCache,
                    [id]: newWatchListAssetCache,
                },
            };
        }
        case 'GET_WATCHLIST_OF_STOCK_SUCCESS': {
            const { symbol, payload: { watchlistIds }, } = action;
            const symbolWatchListInfo = {};
            watchlistIds.forEach((watchListId) => {
                symbolWatchListInfo[watchListId] = true;
            });
            return {
                ...state,
                stockWatchListsInfoMap: {
                    ...state.stockWatchListsInfoMap,
                    [symbol]: symbolWatchListInfo,
                },
            };
        }
        case LOGGED_OUT: {
            return initialState;
        }
        default:
            return state;
    }
};
export default watchListReducer;
