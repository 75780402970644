export var DaysOfTheWeek;
(function (DaysOfTheWeek) {
    DaysOfTheWeek[DaysOfTheWeek["Monday"] = 1] = "Monday";
    DaysOfTheWeek[DaysOfTheWeek["Tuesday"] = 2] = "Tuesday";
    DaysOfTheWeek[DaysOfTheWeek["Wednesday"] = 3] = "Wednesday";
    DaysOfTheWeek[DaysOfTheWeek["Thursday"] = 4] = "Thursday";
    DaysOfTheWeek[DaysOfTheWeek["Friday"] = 5] = "Friday";
    DaysOfTheWeek[DaysOfTheWeek["Saturday"] = 6] = "Saturday";
    DaysOfTheWeek[DaysOfTheWeek["Sunday"] = 7] = "Sunday";
})(DaysOfTheWeek || (DaysOfTheWeek = {}));
export const DaysOfTheWeekNames = {
    [DaysOfTheWeek.Monday]: 'Monday',
    [DaysOfTheWeek.Tuesday]: 'Tuesday',
    [DaysOfTheWeek.Wednesday]: 'Wednesday',
    [DaysOfTheWeek.Thursday]: 'Thursday',
    [DaysOfTheWeek.Friday]: 'Friday',
    [DaysOfTheWeek.Saturday]: 'Saturday',
    [DaysOfTheWeek.Sunday]: 'Sunday',
};
