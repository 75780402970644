import React, { memo, useContext, useCallback } from 'react';
import moment from 'moment';
import { View, FlatList } from 'react-native';
import { rem } from 'design-system/values';
import { Button } from 'design-system/Button';
import { CardView } from 'design-system/CardView';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import ContentHeader from '../calendar/ContentHeader';
import { DateFilterContext } from './context';
import SelectorWithButtons from '../analyticsFilter/SelectorWithButtons';
import { quartersArr, filterStartYear } from './constants';
import { isWeb } from '../../constants';
const QuarterlyContent = () => {
    const styles = useStyles(styleSet);
    const { currentYear, selectedYear, selectedQuarter, setSelectedYear, setSelectedQuarter, todayDateStr, } = useContext(DateFilterContext);
    const changeYear = useCallback((decrease) => () => {
        if (decrease) {
            if (selectedYear > filterStartYear) {
                setSelectedYear(selectedYear - 1);
            }
            return;
        }
        if (selectedYear < currentYear) {
            setSelectedYear(selectedYear + 1);
        }
    }, [currentYear, selectedYear, setSelectedYear]);
    return (<CardView style={styles.container}>
      <ContentHeader style={styles.header} title={selectedQuarter !== undefined
            ? `Q${selectedQuarter} ${selectedYear}`
            : ''}/>
      <SelectorWithButtons title={selectedYear} onForward={changeYear(false)} onBackward={changeYear(true)} disableForward={selectedYear === currentYear} disableBackward={selectedYear === filterStartYear}/>
      <FlatList numColumns={4} data={quartersArr} scrollEnabled={false} contentContainerStyle={styles.contentContainerStyles} columnWrapperStyle={styles.columnWrapperStyle} renderItem={({ item, index }) => (<View style={styles.eachButtonContainer}>
            <Button flex square title={item} disabled={selectedYear === currentYear &&
                moment()
                    .year(selectedYear)
                    .quarter(index + 1)
                    .startOf('quarter')
                    .isAfter(moment(todayDateStr))} containerStyle={styles.buttonStyles} onPress={() => setSelectedQuarter(index + 1)} {...(selectedQuarter === index + 1
            ? { brandTextBorder: true }
            : { gray: true })}/>
          </View>)}/>
    </CardView>);
};
export default memo(QuarterlyContent);
const styleSet = createStyleSheets((colors) => ({
    container: {
        ...(!isWeb
            ? {
                backgroundColor: colors.cards.onDark,
            }
            : {
                backgroundColor: colors.background.light,
            }),
    },
    header: {
        marginVertical: rem(16),
        marginHorizontal: rem(16),
    },
    columnWrapperStyle: {
        flex: 1,
    },
    eachButtonContainer: {
        flex: 1,
        margin: rem(8),
        height: rem(45),
    },
    contentContainerStyles: {
        padding: rem(8),
    },
    buttonStyles: {
        height: rem(42),
    },
}));
