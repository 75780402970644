import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { rem } from 'design-system/values';
import { IconWithGradient } from './Icon';
export const IconUnion = (props) => {
    const width = rem(23);
    const height = rem(13);
    const id = useId();
    return (<IconWithGradient id={id} width={width} height={height} fill="none" viewBox="0 0 23 13" defaultGradientKey="darkPurple" gradientColors={props.gradientColors} {...props}>
      <Path fillRule="evenodd" clipRule="evenodd" d="M9.73 0c.399 0 .718.133.96.398.253.253.38.585.38.995V11.54c0 .421-.127.765-.38 1.03-.242.254-.561.38-.96.38-.433 0-.783-.156-1.048-.47l-6.06-7.76v6.82c0 .421-.12.765-.361 1.03-.23.254-.543.38-.94.38-.399 0-.718-.126-.96-.38-.24-.265-.361-.609-.361-1.03V1.393C0 .983.12.65.362.398.615.133.94 0 1.338 0c.447 0 .79.157 1.031.47l6.06 7.705V1.393c0-.41.12-.742.361-.995C9.031.133 9.345 0 9.73 0Zm7.933 8.61c-.876 0-1.646-.175-2.309-.523a3.79 3.79 0 0 1-1.533-1.5c-.36-.65-.539-1.415-.539-2.29 0-.877.18-1.635.54-2.275.359-.652.87-1.152 1.533-1.5C16.017.174 16.787 0 17.663 0c.876 0 1.645.174 2.308.522a3.655 3.655 0 0 1 1.533 1.5c.36.64.54 1.398.54 2.274 0 .876-.18 1.64-.54 2.292-.36.64-.87 1.14-1.533 1.499-.663.348-1.432.522-2.308.522Zm0-1.938c1.235 0 1.853-.792 1.853-2.376 0-.797-.163-1.393-.489-1.786-.314-.393-.769-.59-1.364-.59-1.236 0-1.854.792-1.854 2.376 0 1.584.618 2.376 1.854 2.376Zm-3.392 4.35a.99.99 0 1 0 0 1.978h6.783a.99.99 0 0 0 0-1.979h-6.783Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
