import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { Icon, IconWithGradient } from 'design-system/icons/Icon';
import useColors from 'hooks/useColors';
export const IconSearch = ({ alt, color, ...props }) => {
    const colors = useColors();
    const id = useId();
    if (alt) {
        return (<IconWithGradient id={id} {...props} defaultGradientKey="white">
        <Path fill={`url(#gradient_${id})`} d="M21.568 19.7164L19.0133 17.6543C18.6268 17.2704 18.0072 17.2704 17.6207 17.6543C17.4203 17.8561 17.3076 18.1304 17.3076 18.4164C17.3076 18.7023 17.4203 18.9766 17.6207 19.1784L19.7407 21.6082C20.2576 22.1306 21.0955 22.1306 21.6124 21.6082C22.1292 21.0858 22.1292 20.2388 21.6124 19.7164H21.568Z"/>
        <Path fill={`url(#gradient_${id})`} d="M2.81573 7.824C3.39864 5.33897 5.33897 3.39864 7.824 2.81573C9.61866 2.39476 11.4864 2.39476 13.281 2.81573C15.7661 3.39864 17.7064 5.33897 18.2893 7.824C18.7103 9.61866 18.7103 11.4864 18.2893 13.281C17.7064 15.7661 15.7661 17.7064 13.281 18.2893C11.4864 18.7103 9.61866 18.7103 7.824 18.2893C5.33897 17.7064 3.39864 15.7661 2.81573 13.2811C2.39476 11.4864 2.39476 9.61866 2.81573 7.824Z"/>
      </IconWithGradient>);
    }
    return (<Icon {...props}>
      <Path d="M3.31573 13.7811L4.04591 13.6098L3.31573 13.7811ZM3.31573 8.324L4.04591 8.49528L4.04591 8.49528L3.31573 8.324ZM18.7893 8.324L19.5195 8.15273L19.5195 8.15273L18.7893 8.324ZM18.7893 13.781L19.5195 13.9523L18.7893 13.781ZM13.781 18.7893L13.6098 18.0591L13.781 18.7893ZM8.324 18.7893L8.15273 19.5195L8.324 18.7893ZM8.324 3.31573L8.15272 2.58555L8.324 3.31573ZM13.781 3.31573L13.9523 2.58555L13.781 3.31573ZM20.4697 21.5303C20.7626 21.8232 21.2374 21.8232 21.5303 21.5303C21.8232 21.2374 21.8232 20.7626 21.5303 20.4697L20.4697 21.5303ZM4.04591 13.6098C3.65136 11.9278 3.65136 10.1773 4.04591 8.49528L2.58555 8.15272C2.13815 10.06 2.13815 12.045 2.58555 13.9523L4.04591 13.6098ZM18.0591 8.49528C18.4537 10.1773 18.4537 11.9278 18.0591 13.6098L19.5195 13.9523C19.9669 12.045 19.9669 10.06 19.5195 8.15273L18.0591 8.49528ZM13.6098 18.0591C11.9278 18.4537 10.1773 18.4537 8.49528 18.0591L8.15273 19.5195C10.06 19.9669 12.045 19.9669 13.9523 19.5195L13.6098 18.0591ZM8.49528 4.04591C10.1773 3.65136 11.9278 3.65136 13.6098 4.04591L13.9523 2.58555C12.045 2.13815 10.06 2.13815 8.15272 2.58555L8.49528 4.04591ZM8.49528 18.0591C6.28757 17.5413 4.56377 15.8175 4.04591 13.6098L2.58555 13.9523C3.23351 16.7147 5.39038 18.8715 8.15273 19.5195L8.49528 18.0591ZM13.9523 19.5195C16.7147 18.8715 18.8715 16.7147 19.5195 13.9523L18.0591 13.6098C17.5413 15.8175 15.8175 17.5413 13.6098 18.0591L13.9523 19.5195ZM13.6098 4.04591C15.8175 4.56377 17.5413 6.28757 18.0591 8.49528L19.5195 8.15273C18.8715 5.39037 16.7147 3.23351 13.9523 2.58555L13.6098 4.04591ZM8.15272 2.58555C5.39037 3.23351 3.23351 5.39037 2.58555 8.15272L4.04591 8.49528C4.56377 6.28757 6.28757 4.56377 8.49528 4.04591L8.15272 2.58555ZM16.8048 17.8655L20.4697 21.5303L21.5303 20.4697L17.8655 16.8048L16.8048 17.8655Z" fill={color ?? colors.elements.secondary}/>
    </Icon>);
};
export default IconSearch;
