import React, { useCallback, useEffect, useMemo } from 'react';
import { noop } from 'lodash';
import moment from 'moment';
import Animated from 'react-native-reanimated';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import ToolTip from 'design-system/ToolTip';
import { AppView } from 'design-system/AppView';
import Indicator from 'design-system/Indicator';
import StatusBar from 'design-system/StatusBar';
import { CardView } from 'design-system/CardView';
import { rem, SCROLL_INDICATOR_INSETS } from 'design-system/values';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import { selectScrambled, selectAccountById, selectCurrentPaydayRangeWithFallback, } from 'reducers/selectors';
import useStyles from 'hooks/useStyles';
import Amplitude from 'utils/amplitude';
import { getAccountGoals } from 'actions';
import { formatAmount } from 'utils/formatting';
import usePrivateRoute from 'hooks/usePrivateRoute';
import SectionHeader from 'design-system/SectionHeader';
import createStyleSheets from 'utils/createStyleSheets';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useAppDispatch, useAppSelector, useAppStore } from 'store/hooks';
import TransactionRow from 'features/transactions/components/TransactionRow';
import useOpenSubscription from 'features/subscriptions/hooks/useOpenSubscription';
import { isWeb } from '../../../constants';
import { selectTrueBalance, selectUpcomingPayments } from '../selectors';
const TrueBalanceToolTip = () => (<ToolTip title="True balance" subtitle="This is your available balance minus all your committed spending for this account. You can adjust this by reviewing your upcoming transactions or editing your budgeting period." small/>);
const TrueBalanceScreen = withAnimatedNavigationHeader(
// @ts-ignore
({ navigation, route, scrollHandler, data }) => {
    usePrivateRoute();
    const dispatch = useAppDispatch();
    const store = useAppStore();
    const styles = useStyles(styleSet);
    const scrambled = useAppSelector(selectScrambled);
    const guessedHomeCountry = useAppSelector((store) => store.user.user.guessedHomeCountry);
    const currentPaydayRange = useAppSelector(selectCurrentPaydayRangeWithFallback);
    useEffect(() => {
        if (!store.getState().accounts.loadedGoals) {
            dispatch(getAccountGoals());
        }
    }, []);
    const openSubscription = useOpenSubscription();
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const balances = useMemo(() => {
        const { nativeBalance } = data;
        const mainCurrency = nativeBalance
            ? nativeBalance.currency
            : data.currency;
        let mainBalance = data.availableBalance !== null
            ? data.availableBalance
            : data.postedBalance;
        if (nativeBalance)
            mainBalance =
                nativeBalance.availableBalance !== null
                    ? nativeBalance.availableBalance
                    : nativeBalance.postedBalance;
        return {
            mainCurrency,
            mainBalance,
        };
    }, [data]);
    const toFromObj = useMemo(() => ({
        to: currentPaydayRange.to,
        from: currentPaydayRange.from,
        toFormatted: moment(currentPaydayRange.to).format('Do of MMMM'),
    }), [currentPaydayRange.from, currentPaydayRange.to]);
    const accountId = route.params.id || data?.id;
    const upcomingBalance = useAppSelector((store) => selectTrueBalance(store, accountId));
    const onCompare = useCallback((routeWithParams) => {
        navigation.navigate(routeWithParams);
        Amplitude.logEvent('Transactions.openCompare', {
            type: routeWithParams.name,
        });
    }, []);
    const upcoming = useAppSelector((store) => selectUpcomingPayments(store, accountId));
    const renderUpcoming = useMemo(() => {
        const upcomingLength = upcoming?.length;
        if (upcomingLength) {
            return (<>
            <SectionHeader horizontalMargins largeVerticalMargins>
              Upcoming
            </SectionHeader>
            {upcoming.map((item, index) => (<TransactionRow item={{
                        ...item,
                        isFirstInSection: index === 0,
                        sectionKey: 'Upcoming transactions',
                        isLastInSection: index === upcomingLength - 1,
                    }} openItem={noop} key={String(item.id)} onCompare={onCompare} openSubscription={openSubscription} guessedHomeCountry={guessedHomeCountry} isEditing={false} isSelected={false} onPressUpgradeForSearch={noop} blurSubscriptionValues={false}/>))}
          </>);
        }
        return null;
    }, [guessedHomeCountry, onCompare, openSubscription, upcoming]);
    return (<AppView withFrame={false}>
        <StatusBar barStyle="dark-content" animated/>
        <Animated.ScrollView onScroll={scrollHandler} scrollEventThrottle={16} contentContainerStyle={paddingHorizontalStyle} scrollIndicatorInsets={SCROLL_INDICATOR_INSETS}>
          <CardView noMarginBottom>
            <View style={styles.headerContainer}>
              <Text Numbers-36 numberOfLines={1}>
                {formatAmount((balances.mainBalance || 0) - upcomingBalance, balances.mainCurrency, 2, scrambled)}
              </Text>
              <View style={styles.descContainer}>
                <Text TextThin-14 Secondary>
                  Balance on {toFromObj.toFormatted}
                </Text>
                <Spacer w={4}/>
                <TrueBalanceToolTip />
              </View>
            </View>
            <View style={styles.infoRow}>
              <Text Text-16>Available balance</Text>
              <Text Numbers-16>
                {formatAmount(balances.mainBalance, balances.mainCurrency, 2, scrambled)}
              </Text>
            </View>
            <View style={styles.infoRow}>
              <Text Text-16>Upcoming</Text>
              <Text Numbers-16>
                {formatAmount(upcomingBalance, balances.mainCurrency, 2, scrambled)}
              </Text>
            </View>
          </CardView>

          {renderUpcoming}
        </Animated.ScrollView>
      </AppView>);
}, ({ colors }) => ({
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
    title: 'True balance',
}));
const TrueBalanceScreenWrapper = (props) => {
    usePrivateRoute();
    const data = useAppSelector((store) => selectAccountById(store, props.route.params.id));
    if (!data) {
        return (<AppView>
        <View style={staticStyles.indicatorContainer}>
          <Indicator dark/>
        </View>
      </AppView>);
    }
    return <TrueBalanceScreen {...props} data={data}/>;
};
const staticStyles = StyleSheet.create({
    indicatorContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
const styleSet = createStyleSheets((colors) => ({
    headerContainer: {
        padding: rem(16),
        alignItems: 'center',
        borderBottomWidth: rem(1),
        borderBottomColor: colors.borders.divider,
    },
    infoRow: {
        padding: rem(16),
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    descContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
    },
}));
export default TrueBalanceScreenWrapper;
