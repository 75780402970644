import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, ButtonGroup, rem } from 'design-system';
import Modal from 'design-system/Modal';
import Text from 'design-system/Text';
import { isPremiumTier, Tier } from 'features/premium/entities';
import { isUnlocked } from 'features/premium/iap';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { benefits } from 'features/premium/benefits';
import { useAppSelector } from 'store/hooks';
import { selectUserTier } from 'features/premium/selectors/tier';
const ForceSyncUpgradeModal = () => {
    const navigation = useMainStackNavigation();
    const userTier = useAppSelector(selectUserTier);
    const onUpgrade = () => {
        const tier = benefits.forceSync.minTier;
        Modal.hide(() => {
            navigation.navigate('EmmaProModal', {
                tier: isPremiumTier(tier) ? tier : undefined,
                benefitId: 'forceSync',
            });
        });
    };
    const onPressMaybeLater = () => {
        Modal.hide();
    };
    return (<View>
      <Text TextThin-14 Secondary style={styles.description} centered>
        Emma updates {isUnlocked(userTier, Tier.plus) ? '4x ' : ''}daily with
        the information from your bank. With Pro or Ultimate, you can sync
        anytime.
        {!isUnlocked(userTier, Tier.plus) &&
            ' For more automatic daily syncs, upgrade to any plan.'}
      </Text>
      <ButtonGroup margins={false}>
        <Button brandReversed title="Maybe later" onPress={onPressMaybeLater} testID="maybeLaterButton"/>
        <Button brand title="Continue" onPress={onUpgrade} testID="viewUpgradeOptionsButton"/>
      </ButtonGroup>
    </View>);
};
const styles = StyleSheet.create({
    description: {
        marginTop: rem(8),
        marginBottom: rem(16),
    },
});
export default ForceSyncUpgradeModal;
