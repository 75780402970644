import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { BlurView } from 'utils/packages/rnBlurView';
import useColorScheme from 'hooks/useColorScheme';
import useColors from 'hooks/useColors';
import { rem } from './values';
const BlurAmountView = ({ enabled, blurAmount = 3, children, style, containerStyle, }) => {
    const colorScheme = useColorScheme();
    const colors = useColors();
    if (Platform.OS !== 'ios') {
        return (<View style={containerStyle}>
        <View style={enabled && styles.hide}>{children}</View>
        {enabled && (<View style={[
                    StyleSheet.absoluteFill,
                    {
                        backgroundColor: colors.cards.transparentOnDark,
                        borderRadius: rem(8),
                    },
                ]}/>)}
      </View>);
    }
    return (<View style={containerStyle}>
      {children}
      {enabled && (<BlurView blurType={colorScheme === 'dark' ? 'dark' : 'light'} blurAmount={blurAmount} style={[styles.blur, style]}/>)}
    </View>);
};
const styles = StyleSheet.create({
    blur: {
        top: -4,
        bottom: -5,
        left: -6,
        right: -5,
        position: 'absolute',
    },
    hide: {
        opacity: 0,
    },
});
export default BlurAmountView;
