import { getCategoryExpenses, getCategoryTotals, getMerchantExpenses, } from 'actions';
import { useEffect, useMemo, useRef } from 'react';
import { selectHasSetPaydayRange } from 'reducers/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectCategoryTotals } from '../selectors';
const selectCategoryExpenses = (store) => store.expenses.categoryExpenses;
const useSyncMonthlyExpenses = (params) => {
    const categoryTotals = useAppSelector(selectCategoryTotals);
    const hasPaydayRange = useAppSelector(selectHasSetPaydayRange);
    const previousCategoryTotals = useRef(categoryTotals);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (params &&
            categoryTotals.isValid === false &&
            (previousCategoryTotals.current.isValid === true ||
                previousCategoryTotals.current.isValid === undefined)) {
            const { from, to, monthStartDate, monthEndDate, categoryId, merchantId, step, accountIds, } = params;
            dispatch(getCategoryTotals({
                dateFrom: from,
                dateTo: to,
                categoryId,
                merchantId,
                isPayDay: hasPaydayRange,
                step,
                accountIds,
            }));
            if (categoryId) {
                dispatch(getCategoryExpenses(monthStartDate, monthEndDate, categoryId, accountIds));
            }
            else if (merchantId) {
                dispatch(getMerchantExpenses(monthStartDate, monthEndDate, merchantId, accountIds));
            }
        }
        previousCategoryTotals.current = categoryTotals;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryTotals]);
    const categoryExpenses = useAppSelector(selectCategoryExpenses);
    const filtered = useMemo(() => {
        // These should be filtered anyway, but it handles the case where the endpoint has not returned yet and we have a patched cache
        if (params?.categoryId) {
            return categoryExpenses.filter((t) => t.category.id === params.categoryId);
        }
        return categoryExpenses;
    }, [categoryExpenses, params?.categoryId]);
    return [categoryTotals, filtered];
};
export default useSyncMonthlyExpenses;
