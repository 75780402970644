import React, { memo, useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import { View } from 'react-native';
import DropShadow from 'react-native-drop-shadow';
import { useSharedValue } from 'react-native-reanimated';
import { rem } from 'design-system';
import Text from 'design-system/Text';
import InteractivePieChart from 'design-system/InteractivePieChart';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { formatAmount } from 'utils';
import { selectIsScrambled } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectDefaultCurrency } from 'reducers/selectors';
const OverviewPieChart = ({ data, title }) => {
    const styles = useStyles(styleSet);
    const scrambled = useAppSelector(selectIsScrambled);
    const defaultCurrency = useAppSelector(selectDefaultCurrency);
    const selectedPieSliceKey = useSharedValue('none');
    const [selectedPie, setSelectedPie] = useState(undefined);
    const titleRef = useRef(title);
    const hasSelectedData = useRef(false);
    useEffect(() => {
        if (!hasSelectedData.current || titleRef.current !== title) {
            const firstKey = data[0]?.key;
            if (firstKey) {
                hasSelectedData.current = true;
                selectedPieSliceKey.value = firstKey;
                setSelectedPie(data[0]);
            }
        }
        titleRef.current = title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, title]);
    const onSelectPieSlice = useCallback((pieSlice) => {
        selectedPieSliceKey.value = pieSlice.key;
        setSelectedPie(pieSlice);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    const info = useMemo(() => {
        const pieSlice = data.find((item) => item.key === selectedPie?.key);
        if (pieSlice) {
            return {
                title: formatAmount(pieSlice.value, ('currency' in pieSlice.category && pieSlice.category.currency) ||
                    'GBP', 0, scrambled),
                subTitle: 'name' in pieSlice.category ? pieSlice.category.name : '',
            };
        }
        return {
            title: formatAmount(0, defaultCurrency, 0, scrambled),
            subTitle: title,
        };
    }, [data, defaultCurrency, scrambled, selectedPie?.key, title]);
    return (<InteractivePieChart pieSlices={data} onSelectPieSlice={onSelectPieSlice} selectedPieSliceKey={selectedPieSliceKey}>
      <DropShadow style={styles.circleShadow}>
        <View style={styles.circle}>
          <Text Numbers-24 numberOfLines={1}>
            {info.title}
          </Text>
          <Text TextThin-14 Gray numberOfLines={1}>
            {info.subTitle}
          </Text>
        </View>
      </DropShadow>
    </InteractivePieChart>);
};
const styleSet = createStyleSheets((colors) => ({
    circleShadow: {
        ...colors.shadows.floatingButton,
    },
    circle: {
        width: rem(160),
        height: rem(160),
        padding: rem(16),
        alignItems: 'center',
        borderRadius: rem(160),
        justifyContent: 'center',
        backgroundColor: colors.cards.onDark,
    },
}));
export default memo(OverviewPieChart);
