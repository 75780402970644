import React, { memo } from 'react';
import Animated, { useAnimatedStyle, } from 'react-native-reanimated';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
const ChartOpacityOverlay = ({ opacitySharedValue, }) => {
    const animatedStyles = useAnimatedStyle(() => ({
        opacity: opacitySharedValue.value,
    }), [opacitySharedValue]);
    const styles = useStyles(styleSet);
    return (<Animated.View style={[styles.loaderWrapper, animatedStyles]} pointerEvents="none"/>);
};
export default memo(ChartOpacityOverlay);
const styleSet = createStyleSheets((colors) => ({
    loaderWrapper: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
        alignItems: 'center',
        borderRadius: rem(16),
        justifyContent: 'center',
        backgroundColor: colors.cards.onDark,
    },
}));
