import moment from 'moment';
import { useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
const compareByDays = (dd1, dd2) => {
    const m1 = moment(dd1);
    const m2 = moment(dd2);
    return m1.diff(m2, 'days');
};
const isBefore = (dd1, dd2) => {
    const m1 = moment(dd1);
    const m2 = moment(dd2);
    return m1.isBefore(m2);
};
export const useDateRange = (initialDates = [], 
// 0 = Sunday, 1 = Monday
firstDay = 1) => {
    const [range, setRange] = useState(initialDates);
    const onAddDayToRange = useCallback((date) => {
        let newRange;
        if (isEmpty(range) || range.length === 2 || isBefore(date, range[0])) {
            newRange = [date];
        }
        else {
            newRange = [...range, date].sort(compareByDays);
        }
        setRange(newRange);
        return newRange;
    }, [range]);
    const selectEntireWeek = useCallback((date) => {
        const dateObj = moment(date);
        const startDay = dateObj.clone().weekday(firstDay).format('YYYY-MM-DD');
        const endDay = dateObj
            .clone()
            .weekday(firstDay + 6)
            .format('YYYY-MM-DD');
        setRange([startDay, endDay]);
        return [startDay, endDay];
    }, [firstDay]);
    const resetRange = useCallback(() => {
        setRange([]);
    }, []);
    const checkInRange = useCallback((date) => {
        if (isEmpty(range)) {
            return false;
        }
        if (range.length === 1) {
            return range[0] === date;
        }
        const momentToCompare = moment(date);
        return (momentToCompare.isSameOrAfter(range[0], 'days') &&
            momentToCompare.isSameOrBefore(range[1], 'days'));
    }, [range]);
    const checkIsDayLastOrFirst = useCallback((date) => date === range[0] || date === range[1], [range]);
    const checkIsStartDay = useCallback((date) => date === range[0], [range]);
    const checkIsEndDay = useCallback((date) => date === range[1] || (range.length === 1 && date === range[0]), [range]);
    return {
        onAddDayToRange,
        range,
        checkInRange,
        checkIsDayLastOrFirst,
        checkIsStartDay,
        checkIsEndDay,
        resetRange,
        selectEntireWeek,
    };
};
