import { useCallback } from 'react';
import { useMainStackNavigation } from 'utils/types/navigationV6';
const useHandleOpenInterestPot = () => {
    const navigation = useMainStackNavigation();
    const navigateToOpenInterestPot = useCallback(() => {
        navigation.navigate('SelectPotType');
    }, []);
    return navigateToOpenInterestPot;
};
export default useHandleOpenInterestPot;
