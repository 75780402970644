export const QUESTS_GROUPS = [
    'GETTING_STARTED',
    'TRANSACTION_NINJA',
    'MASTER_ACCOUNTS',
    'LIKE_A_PRO',
    'SAVE_MONEY',
    'START_INVESTING',
];
export const QUESTS = [
    'first_time.set_payday',
    'first_time.set_budgets',
    'first_time.set_rolling_budgets',
    'first_time.total_budget',
    'first_time.check_subscriptions',
    'social.invite_1_friend',
    'first_time.add_profile_pic',
    'first_time.edit_category',
    'first_time.add_note',
    'first_time.add_tag',
    'first_time.upload_receipt',
    'first_time.rename_account',
    'first_time.search_trxs',
    'first_time.check_bank_fees',
    'enable.daily_balances',
    'first_time.use_scramble',
    'social.invite_2_friends',
    'social.invite_3_friends',
    'pro.complete_month_pro',
    'streak.10_days',
    'streak.20_days',
    'streak.50_days',
    'rewards.earn_reward',
    'social.rate_emma',
    'social.facebook_share',
    'first_time.switch_energy',
    'first_time.get_broadband_quotes',
    'first_time.open_net_worth',
    'first_time.submit_logo',
    'first_time.split_transaction',
    'first_time.rename_transaction',
    'first_time.change_transaction_date',
    'first_time.play_quiz',
    'first_time.check_report',
    'first_time.setup_fraud_detect',
    'first_time.discover_loans',
    'first_time.discover_cards',
    'first_time.make_a_payment',
    'first_time.request_a_payment',
    'first_time.transfer_between_accounts',
    'first_time.create_a_group',
    'enable.set_rent_reporting',
    'first_time.create_a_space',
    'first_time.create_an_offline_account',
    'first_time.set_a_rule',
    'streak.link_2_bank_logins',
    'streak.link_3_bank_logins',
    'first_time.create_a_pot',
    'enable.set_autosaves',
    'first_time.earn_interest',
    'first_time.deposit_in_emma',
    'first_time.make_a_trade',
    'first_time.create_a_list',
    'first_time.set_a_reacurring_order',
];
