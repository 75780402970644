import AddAccountModal from 'features/accounts/components/AddAccountModal';
import usePlaidLink from 'hooks/usePlaidLink';
import { useCallback } from 'react';
import Amplitude from 'utils/amplitude';
import { useMainStackNavigation } from 'utils/types/navigationV6';
export const useAddAccountModal = (title) => {
    const navigation = useMainStackNavigation();
    const openPlaid = usePlaidLink();
    const addAccount = useCallback(() => {
        Amplitude.logEvent('Accounts.OpenAddAnAccount');
        AddAccountModal.show({
            navigate: navigation.navigate,
            onAddProperty: title === 'Net worth'
                ? () => {
                    navigation.navigate('Property', {});
                }
                : undefined,
            onOpenPlaid: openPlaid,
            onAddOffline: () => {
                const cash = title === 'Cash' ||
                    title === 'Checking' ||
                    title === 'Current' ||
                    title === 'Everyday';
                const debt = title === 'Debt';
                const netWorth = title === 'Net worth';
                let params = {}; // TODO: refactor this. Use a separate key instead of mixing AccountType with some random strings.
                if (cash || debt || netWorth) {
                    const pickAccountTypeParams = {
                        type: (() => {
                            if (cash) {
                                return 'CHECKINGCARDS';
                            }
                            if (debt) {
                                return 'DEBT';
                            }
                            return undefined;
                        })(),
                    };
                    navigation.navigate('PickAccountType', pickAccountTypeParams);
                }
                else {
                    const addBalanceParams = {
                        type: (() => {
                            if (title === 'Savings') {
                                return 'SAVINGS';
                            }
                            if (title === 'Investments') {
                                return 'INVESTMENT';
                            }
                            return undefined;
                        })(),
                    };
                    params = addBalanceParams;
                    navigation.navigate('AddBalance', addBalanceParams);
                }
                Amplitude.logEvent('Accounts.DummyAccountSetup', params);
            },
        });
    }, [openPlaid, title]);
    return addAccount;
};
