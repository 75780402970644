import React from 'react';
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';
import useColors from 'hooks/useColors';
const BorrowIcon = ({ isActive }) => {
    const colors = useColors();
    return (<Svg width={32} height={32} viewBox="0 0 32 32">
      <G clipPath="url(#clip0_214_5116)">
        <Path fill={isActive ? colors.elements.brand : colors.elements.secondary} d="M19.6762 5.51918C17.2967 4.82678 14.7033 4.82678 12.3238 5.51918C11.9246 5.63472 11.734 6.09831 11.9487 6.46466L12.9236 8.21005C14.6189 7.53549 17.1335 7.43477 19.0762 8.21035L20.0513 6.46466C20.266 6.09831 20.0754 5.63476 19.6762 5.51918Z"/>
        <Path fill={isActive ? colors.elements.brand : colors.elements.secondary} d="M18.9929 9.61446C17.7868 8.76479 14.2489 8.72934 12.9997 9.61841C12.4641 9.99649 12.1789 10.5905 12.1845 11.211C13.4222 10.5553 14.8735 10.2401 16 10.2401C17.372 10.2401 18.6573 10.5974 19.8156 11.211C19.821 10.6132 19.5553 10.0111 18.9929 9.61446Z"/>
        <Path fill={isActive ? colors.elements.brand : colors.elements.secondary} fillRule="evenodd" clipRule="evenodd" d="M7.6203 21.1971C7.6203 16.7259 11.2801 11.5283 15.9999 11.5283C20.7198 11.5283 24.3796 16.7259 24.3796 21.1971C24.3796 26.3973 19.5968 26.9984 15.9999 26.9984C12.4031 26.9984 7.6203 26.3972 7.6203 21.1971Z"/>
      </G>
      <Defs>
        <ClipPath id="clip0_214_5116">
          <Rect width="22" height="22" fill="white" transform="translate(5 5)"/>
        </ClipPath>
      </Defs>
    </Svg>);
};
export default BorrowIcon;
