import React, { useCallback, useMemo, useRef } from 'react';
import DateTimePicker from 'utils/packages/dateTimePicker';
import Modal from 'design-system/Modal';
import Button from 'design-system/Button';
import { subYears } from 'date-fns';
import { Keyboard, Platform } from 'react-native';
import useColorScheme from './useColorScheme';
export const useDateTimePicker = ({ initialValue = subYears(new Date(), 20), onSubmitDate, ...props }) => {
    const dateValue = useRef(new Date(initialValue));
    const onSaveButtonPress = useCallback(() => {
        onSubmitDate?.(dateValue.current);
        Modal.hide();
    }, [onSubmitDate]);
    const colorScheme = useColorScheme();
    const hideDateTimePicker = useCallback(() => {
        Modal.hide();
    }, []);
    const picker = useMemo(() => {
        const androidProps = Platform.OS === 'android'
            ? {
                onChange: (_, date) => {
                    hideDateTimePicker();
                    if (date) {
                        onSubmitDate?.(date);
                    }
                },
                onTouchCancel: () => {
                    hideDateTimePicker();
                },
            }
            : {};
        return (<DateTimePicker themeVariant={colorScheme} value={new Date(initialValue.toString())} display="spinner" mode="date" onChange={(_event, date) => {
                if (date !== undefined) {
                    dateValue.current = date;
                }
            }} {...props} {...androidProps}/>);
    }, [colorScheme, hideDateTimePicker, initialValue, onSubmitDate, props]);
    const showDateTimePicker = useCallback(() => {
        Keyboard.dismiss();
        Modal.show(<>
        {picker}
        {Platform.OS !== 'android' && (<Button brand title="Save" onPress={onSaveButtonPress}/>)}
      </>, {
            hideHandle: true,
            fullscreen: Platform.OS === 'android',
            noBackdrop: Platform.OS === 'android',
        });
    }, [onSaveButtonPress, picker]);
    return {
        showDateTimePicker,
        hideDateTimePicker,
    };
};
