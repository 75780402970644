import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from './Icon';
export const IconHeartBeat = ({ ...props }) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="peach">
      <Path d="M15.75 5.031c-1.896 0-3.112 1.313-3.75 2.496-.638-1.183-1.854-2.496-3.75-2.496C5.845 5.031 4 6.983 4 9.571c0 .675.135 1.299.384 1.898H7.96l.808-1.616c.16-.318.68-.318.839 0l1.456 2.911.518-1.036c.159-.318.679-.318.838 0l.518 1.036 1.456-2.911c.16-.318.68-.318.839 0l.808 1.616h3.576A4.87 4.87 0 0 0 20 9.57c0-2.588-1.845-4.54-4.25-4.54Z" fill={`url(#gradient_${id})`}/>
      <Path d="M15.75 12.406c-.178 0-.34-.1-.42-.26l-.518-1.036-1.455 2.912a.469.469 0 0 1-.839 0L12 12.985l-.518 1.037c-.16.317-.68.317-.839 0L9.187 11.11l-.518 1.037a.469.469 0 0 1-.419.259H4.876c.965 1.518 2.702 2.96 4.876 4.794.61.515 1.26 1.063 1.94 1.654a.468.468 0 0 0 .615 0c.682-.591 1.33-1.139 1.941-1.654 2.174-1.834 3.911-3.276 4.876-4.794H15.75Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
