import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import Button from 'design-system/Button';
import EmptyComponent from 'design-system/EmptyComponent';
import StatusBar from 'design-system/StatusBar';
import SuccessView from 'design-system/SuccessView';
import useDisableAndroidBackButton from 'hooks/useDisableAndroidBackButton';
import { isWeb } from '../../../constants';
import useRateAppAfterTransactionEdit from '../hooks/useRateAppAfterTransactionEdit';
const EditCompleteScreen = ({ navigation, route, }) => {
    const rateApp = useRateAppAfterTransactionEdit();
    useLayoutEffect(() => {
        navigation.setOptions({
            gestureEnabled: false,
            headerLeft: EmptyComponent,
        });
    }, []);
    useEffect(() => {
        rateApp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const goBack = useCallback(() => {
        if (!isWeb || navigation.getState().routes.length > 3) {
            navigation.pop(3);
        }
        else {
            navigation.navigate('TransactionsList');
        }
    }, []);
    useDisableAndroidBackButton(() => {
        goBack();
        return true;
    });
    const { transactionsCount } = route.params;
    return (<View style={styles.container}>
      <StatusBar barStyle="dark-content" animated/>
      <SuccessView title={`${transactionsCount} ${transactionsCount > 1 ? 'Transactions' : 'Transaction'}`} subTitle={`${transactionsCount > 1 ? 'have' : 'has'} been created!`}>
        <Button brand floating title="Perfect, let's go back!" onPress={goBack}/>
      </SuccessView>
    </View>);
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
});
export default EditCompleteScreen;
