import { useCallback } from 'react';
import { getSearchTransactions, getTags } from 'actions/transactions';
import { getCategories } from 'actions/user';
import { selectOrderedCategories } from 'reducers/selectors';
import { useAppDispatch, useAppStore } from 'store/hooks';
export const useFetchSearchTransactionData = () => {
    const dispatch = useAppDispatch();
    const store = useAppStore();
    return useCallback(() => {
        const state = store.getState();
        const { searchTransactions, tags } = state.transactions;
        const categories = selectOrderedCategories(state);
        if (!searchTransactions?.length) {
            dispatch(getSearchTransactions());
        }
        if (!categories?.length) {
            dispatch(getCategories());
        }
        if (!tags?.length) {
            dispatch(getTags());
        }
    }, [store]);
};
