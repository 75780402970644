import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import useColors from 'hooks/useColors';
import { IconWithGradient } from '../Icon';
const IconStatusPending = ({ bgColor, ...props }) => {
    const colors = useColors();
    const id = useId();
    return (<IconWithGradient id={id} {...props} width={18} height={18} viewBox="0 0 18 18" defaultGradientKey="white">
      <Path d="M0.286624 6.70701C0.706221 3.35023 3.35023 0.706221 6.70701 0.286624C8.22974 0.096282 9.77026 0.096282 11.293 0.286624C14.6498 0.706221 17.2938 3.35023 17.7134 6.70701C17.9037 8.22974 17.9037 9.77026 17.7134 11.293C17.2938 14.6498 14.6498 17.2938 11.293 17.7134C9.77026 17.9037 8.22974 17.9037 6.70701 17.7134C3.35023 17.2938 0.706221 14.6498 0.286624 11.293C0.096282 9.77026 0.096282 8.22974 0.286624 6.70701Z" fill={bgColor ?? colors.cards.onLight}/>
      <Path fillRule="evenodd" clipRule="evenodd" d="M13.152 12.3153C12.8052 13.1243 11.8683 13.4991 11.0593 13.1524L7.98449 11.8346C7.00783 11.4161 6.37459 10.4557 6.37458 9.39314L6.37459 4.78125C6.37458 3.90105 7.08813 3.1875 7.96833 3.1875C8.84854 3.1875 9.56208 3.90105 9.56208 4.78125L9.56209 9.04284L12.3149 10.2226C13.1239 10.5693 13.4987 11.5063 13.152 12.3153Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default IconStatusPending;
