import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
const IconPieChart = ({ alt, ...props }) => {
    const id = useId();
    if (alt) {
        return (<IconWithGradient id={id} {...props} defaultGradientKey="ultimate">
        <Path d="M10.63 6.38c.05.104.084.215.099.33l.278 4.14.139 2.08c.001.214.035.427.1.631.166.397.568.649 1.005.631l6.657-.435c.288-.005.567.103.774.3.173.163.284.378.32.608l.01.14c-.275 3.815-3.076 6.997-6.883 7.818-3.807.822-7.71-.914-9.592-4.263a8.214 8.214 0 0 1-.996-3.146 6.019 6.019 0 0 1-.063-.986c-.006-4.09 2.906-7.626 6.983-8.478.49-.077.972.183 1.168.63Z" fill={`url(#gradient_${id})`}/>
        <Path opacity={0.4} d="M13.347 2.826c4.56.116 8.392 3.395 9.13 7.811l-.007.033-.02.047.003.13a.8.8 0 0 1-.192.473.827.827 0 0 1-.462.271l-.11.015-7.68.498a.917.917 0 0 1-.7-.227.883.883 0 0 1-.289-.537l-.515-7.67a.122.122 0 0 1 0-.08.784.784 0 0 1 .258-.555.815.815 0 0 1 .584-.209Z" fill={`url(#gradient_${id})`}/>
      </IconWithGradient>);
    }
    return (<IconWithGradient id={id} {...props} defaultGradientKey="ultimate">
      <Path d="M8.956 5.46c.49-.092.982.152 1.196.595.053.08.095.167.126.257.136 2.097.281 4.159.417 6.22-.003.214.03.427.1.63.162.4.566.654 1.005.632l6.656-.427.045.018.122.009c.24.03.464.14.635.313.199.202.308.473.303.753-.263 3.822-3.065 7.015-6.878 7.838-3.814.823-7.723-.923-9.595-4.284a8.215 8.215 0 0 1-1.024-3.136 5.956 5.956 0 0 1-.063-.987C2.014 9.827 4.907 6.32 8.956 5.46Zm3.958-2.96c4.57.138 8.39 3.456 9.083 7.89a.244.244 0 0 1 0 .08l-.002.126a.783.783 0 0 1-.195.45.811.811 0 0 1-.555.269l-7.68.506-.126.003a.914.914 0 0 1-.567-.233.88.88 0 0 1-.293-.658l-.517-7.544v-.124a.793.793 0 0 1 .264-.555.824.824 0 0 1 .588-.21Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default IconPieChart;
