import { useCallback } from 'react';
import { NotificationsPermissionState } from './useNotificationPermissionTypes';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useNotificationsPermissions = (_) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const activatePush = useCallback((_) => { }, []);
    const goToSettings = useCallback(async () => { }, []);
    return {
        activatePush,
        goToSettings,
        permissionState: NotificationsPermissionState.Enabled,
    };
};
