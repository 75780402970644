import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { useSharedValue } from 'react-native-reanimated';
import StatusBar from 'design-system/StatusBar';
import { AppView, NavigationHeader } from 'design-system';
import useColors from 'hooks/useColors';
import EmptyComponent from 'design-system/EmptyComponent';
import usePrivateRoute from 'hooks/usePrivateRoute';
import TransactionsList from '../components/TransactionsList';
import TransactionsHeader from '../components/TransactionsHeader';
import TxnScreenNavHeaderExtension from '../components/TxnScreenNavHeaderExtension';
const TransactionsScreen = ({ navigation, route, }) => {
    usePrivateRoute();
    const { from, to } = route.params || {};
    const transactionsListRef = useRef(null);
    const scrollY = useSharedValue(0);
    const [filters, setFilters] = React.useState({
        selectedAccountsObj: {},
        showExcludedTxns: true,
    });
    const colors = useColors();
    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Transactions',
            headerTitle: EmptyComponent,
            headerTintColor: colors.elements.primary,
            headerRight: () => (<TransactionsHeader onPressEdit={transactionsListRef.current?.onEdit} isEditing={route.params?.isEditing}/>),
        });
    }, [
        colors.background.light,
        colors.elements.primary,
        route.params?.isEditing,
    ]);
    const onScrollY = useCallback((scrollYValue = 0) => {
        scrollY.value = scrollYValue;
    }, [scrollY]);
    const setTxnFilters = useCallback((selectedAccountsObj, showExcludedTxns) => {
        setFilters({
            showExcludedTxns,
            selectedAccountsObj,
        });
    }, []);
    const accountIdsStr = useMemo(() => {
        const accountIds = Object.keys(filters.selectedAccountsObj);
        if (!accountIds.length) {
            return undefined;
        }
        const numberAccIds = accountIds.map((accountIdStr) => Number(accountIdStr));
        numberAccIds.sort();
        let accountIdsStr = '';
        numberAccIds.forEach((numberAccId) => {
            accountIdsStr += `accountIds[]=${numberAccId}&`;
        }, []);
        return accountIdsStr;
    }, [filters.selectedAccountsObj]);
    return (<AppView withFrame={false}>
      <StatusBar barStyle="dark-content" animated/>
      <NavigationHeader scrollY={scrollY} title="Transactions">
        <TxnScreenNavHeaderExtension scrollY={scrollY} filters={filters} setFilters={setTxnFilters}/>
      </NavigationHeader>
      <TransactionsList to={to} from={from} onScrollY={onScrollY} navigation={navigation} ref={transactionsListRef} accountIdsStr={accountIdsStr} showExcludedTransactions={filters.showExcludedTxns} reducedTopPadding/>
    </AppView>);
};
export default TransactionsScreen;
