import React from 'react';
import * as SVG from 'react-native-svg';
import { rem } from 'design-system/values';
import { Icon } from 'design-system/icons/Icon';
import useColors from 'hooks/useColors';
export const IconCheckMark = (props) => {
    const width = rem(12);
    const height = rem(9);
    const colors = useColors();
    return (<Icon viewBox="0 0 12 9" fill="none" width={width} height={height} {...props}>
      <SVG.Path fillRule="evenodd" clipRule="evenodd" d="M11.0608 1.10651C11.5752 1.62272 11.5871 2.47196 11.0874 3.00334L6.69855 7.6698C5.82847 8.59491 4.39046 8.59492 3.52038 7.6698L0.912626 4.89708C0.412855 4.3657 0.424771 3.51646 0.939242 3.00026C1.45371 2.48405 2.27592 2.49636 2.77569 3.02775L5.10946 5.50915L9.22431 1.134C9.72408 0.602619 10.5463 0.590311 11.0608 1.10651Z" fill={props.color ?? colors.elements.onLight}/>
    </Icon>);
};
export default IconCheckMark;
