import { addLastInSectionInfo } from '../getTransactions';
import { formatDateForSectionHeader } from '../formatting';
export const getActivitiesSectionedFlashlist = (activities, key) => {
    if (!activities?.length || !key) {
        return [];
    }
    let activeDate;
    const flashListData = [];
    activities.forEach((activity) => {
        let didAddNewSection = false;
        const dateToUseRaw = activity[key];
        const dateToUse = formatDateForSectionHeader(dateToUseRaw);
        if (!activeDate || dateToUse !== activeDate) {
            activeDate = dateToUse;
            addLastInSectionInfo(flashListData);
            flashListData.push({
                id: dateToUse,
                sectionTitle: dateToUse,
            });
            didAddNewSection = true;
        }
        if (!didAddNewSection) {
            flashListData.push(activity);
        }
        else {
            flashListData.push({ ...activity, isFirstInSection: true });
        }
    });
    addLastInSectionInfo(flashListData);
    return flashListData;
};
