import { useEffect, useCallback } from 'react';
import moment from 'moment';
import { InteractionManager } from 'react-native';
import { useFetchOld } from 'hooks';
import { getBalanceHistory } from 'actions';
import { getPotBalanceHistory, getPortfolioBalanceHistory, getEmmaWalletBalanceHistory, } from 'actions/balanceHistory';
import { assertUnreachable } from 'utils/types';
import { useAppSelector, useAppStore } from 'store/hooks';
import { selectIsDefaultSpace } from 'features/spaces/reducers/selectors';
import { selectIsPotsAccountActive } from 'features/pots/reducers/selectors';
import { selectIsInvestAccountActive } from 'features/invest/reducers/selectors';
import { ChartType } from '../types';
const FETCH_PARAMS = {
    muteError: true,
    muteResult: true,
    muteFetching: true,
};
const shouldMakeRequest = (lastApiCallTimeStamp, momentState) => {
    if (!lastApiCallTimeStamp) {
        return true;
    }
    if (moment
        .duration(momentState.diff(moment(lastApiCallTimeStamp)))
        .asMinutes() > 10 ||
        moment(lastApiCallTimeStamp).isBefore(momentState, 'day')) {
        return true;
    }
    return false;
};
export const useBalanceHistory = (title, chartType) => {
    const store = useAppStore();
    const isDefaultSpace = useAppSelector(selectIsDefaultSpace);
    const isPotsAccountActive = useAppSelector(selectIsPotsAccountActive);
    const isInvestAccountActive = useAppSelector(selectIsInvestAccountActive);
    const [, , , fetch] = useFetchOld(FETCH_PARAMS);
    const getCheckingBalance = useCallback(async (lastYear, toNow) => {
        const state = store.getState();
        const momentState = moment();
        const promisesArray = [];
        if (!state.balanceHistory.checkingBalanceHistoryReq &&
            shouldMakeRequest(state.balanceHistory.checkingLastFetchedTimeStamp, momentState)) {
            promisesArray.push(fetch(getBalanceHistory(lastYear, toNow, 'CHECKING', '1day')));
        }
        if (isPotsAccountActive &&
            isDefaultSpace &&
            !state.balanceHistory.emmaWalletBalanceHistoryReq &&
            shouldMakeRequest(state.balanceHistory.emmaWalletLastFetchedTimeStamp, momentState)) {
            promisesArray.push(fetch(getEmmaWalletBalanceHistory(lastYear, toNow)));
        }
        if (promisesArray.length) {
            await Promise.all(promisesArray);
        }
    }, [isDefaultSpace, isPotsAccountActive]);
    const getSavingsBalance = useCallback(async (lastYear, toNow) => {
        const state = store.getState();
        const momentState = moment();
        const promisesArray = [];
        if (!state.balanceHistory.savingsBalanceHistoryReq &&
            shouldMakeRequest(state.balanceHistory.savingsLastFetchedTimeStamp, momentState)) {
            promisesArray.push(fetch(getBalanceHistory(lastYear, toNow, 'SAVINGS', '1day')));
        }
        if (isPotsAccountActive &&
            isDefaultSpace &&
            !state.balanceHistory.potsBalanceHistoryReq &&
            shouldMakeRequest(state.balanceHistory.potsLastFetchedTimeStamp, momentState)) {
            promisesArray.push(fetch(getPotBalanceHistory(lastYear, toNow)));
        }
        if (promisesArray.length) {
            await Promise.all(promisesArray);
        }
    }, [isDefaultSpace, isPotsAccountActive]);
    const getInvestmentBalance = useCallback(async (lastYear, toNow) => {
        const state = store.getState();
        const momentState = moment();
        const promisesArray = [];
        if (!state.balanceHistory.investmentBalanceHistoryReq &&
            shouldMakeRequest(state.balanceHistory.investmentLastFetchedTimeStamp, momentState)) {
            promisesArray.push(fetch(getBalanceHistory(lastYear, toNow, 'INVESTMENT', '1day')));
        }
        if (isInvestAccountActive &&
            isDefaultSpace &&
            !state.balanceHistory.portfolioBalanceHistoryReq &&
            shouldMakeRequest(state.balanceHistory.portfolioLastFetchedTimeStamp, momentState)) {
            promisesArray.push(fetch(getPortfolioBalanceHistory(lastYear, toNow)));
        }
        if (promisesArray.length) {
            await Promise.all(promisesArray);
        }
    }, [isDefaultSpace, isInvestAccountActive]);
    const getNetworthDebtBalance = useCallback(async (lastYear, toNow) => {
        const state = store.getState();
        const momentState = moment();
        const promisesArray = [];
        if (!state.balanceHistory.debtBalanceHistoryReq &&
            shouldMakeRequest(state.balanceHistory.debtLastFetchedTimeStamp, momentState)) {
            promisesArray.push(fetch(getBalanceHistory(lastYear, toNow, 'DEBT', '1day')));
        }
        if (promisesArray.length) {
            await Promise.all(promisesArray);
        }
    }, []);
    const getNetworthAssetsBalance = useCallback(async (lastYear, toNow) => {
        const state = store.getState();
        const momentState = moment();
        const promisesArray = [];
        if (!state.balanceHistory.assetsBalanceHistoryReq &&
            shouldMakeRequest(state.balanceHistory.assetsLastFetchedTimeStamp, momentState)) {
            promisesArray.push(fetch(getBalanceHistory(lastYear, toNow, 'ASSETS', '1day')));
        }
        if (isDefaultSpace) {
            if (isPotsAccountActive) {
                if (!state.balanceHistory.emmaWalletBalanceHistoryReq &&
                    shouldMakeRequest(state.balanceHistory.emmaWalletLastFetchedTimeStamp, momentState)) {
                    promisesArray.push(fetch(getEmmaWalletBalanceHistory(lastYear, toNow)));
                }
                if (!state.balanceHistory.potsBalanceHistoryReq &&
                    shouldMakeRequest(state.balanceHistory.potsLastFetchedTimeStamp, momentState)) {
                    promisesArray.push(fetch(getPotBalanceHistory(lastYear, toNow)));
                }
            }
            if (isInvestAccountActive &&
                !state.balanceHistory.portfolioBalanceHistoryReq &&
                shouldMakeRequest(state.balanceHistory.portfolioLastFetchedTimeStamp, momentState)) {
                promisesArray.push(fetch(getPortfolioBalanceHistory(lastYear, toNow)));
            }
        }
        if (promisesArray.length) {
            await Promise.all(promisesArray);
        }
    }, [isDefaultSpace, isInvestAccountActive, isPotsAccountActive]);
    const getNetworthBalance = useCallback(async (lastYear, toNow) => {
        const state = store.getState();
        const momentState = moment();
        const promisesArray = [];
        if (!state.balanceHistory.networthBalanceHistoryReq &&
            shouldMakeRequest(state.balanceHistory.networthLastFetchedTimeStamp, momentState)) {
            promisesArray.push(fetch(getBalanceHistory(lastYear, toNow, 'NETWORTH', '1day')));
        }
        if (isDefaultSpace) {
            if (isPotsAccountActive) {
                if (!state.balanceHistory.emmaWalletBalanceHistoryReq &&
                    shouldMakeRequest(state.balanceHistory.emmaWalletLastFetchedTimeStamp, momentState)) {
                    promisesArray.push(fetch(getEmmaWalletBalanceHistory(lastYear, toNow)));
                }
                if (!state.balanceHistory.potsBalanceHistoryReq &&
                    shouldMakeRequest(state.balanceHistory.potsLastFetchedTimeStamp, momentState)) {
                    promisesArray.push(fetch(getPotBalanceHistory(lastYear, toNow)));
                }
            }
            if (isInvestAccountActive &&
                !state.balanceHistory.portfolioBalanceHistoryReq &&
                shouldMakeRequest(state.balanceHistory.portfolioLastFetchedTimeStamp, momentState)) {
                promisesArray.push(fetch(getPortfolioBalanceHistory(lastYear, toNow)));
            }
        }
        if (promisesArray.length) {
            await Promise.all(promisesArray);
        }
    }, [isDefaultSpace, isInvestAccountActive, isPotsAccountActive]);
    useEffect(() => {
        InteractionManager.runAfterInteractions(() => {
            const toNow = moment().format('YYYY-MM-DD');
            const lastYear = moment().subtract(1, 'years').format('YYYY-MM-DD');
            switch (title) {
                case 'Everyday': {
                    getCheckingBalance(lastYear, toNow);
                    break;
                }
                case 'Investments': {
                    getInvestmentBalance(lastYear, toNow);
                    break;
                }
                case 'Savings': {
                    getSavingsBalance(lastYear, toNow);
                    break;
                }
                case 'Net Worth - Main': {
                    if (chartType === ChartType.assetVsDebt) {
                        getNetworthDebtBalance(lastYear, toNow);
                        getNetworthAssetsBalance(lastYear, toNow);
                    }
                    else {
                        getNetworthBalance(lastYear, toNow);
                    }
                    break;
                }
                case 'Net Worth - Debt': {
                    getNetworthDebtBalance(lastYear, toNow);
                    break;
                }
                case 'Net Worth - Asset': {
                    getNetworthAssetsBalance(lastYear, toNow);
                    break;
                }
                default: {
                    assertUnreachable(title);
                    break;
                }
            }
        });
    }, [
        title,
        chartType,
        isDefaultSpace,
        isInvestAccountActive,
        isPotsAccountActive,
        getCheckingBalance,
        getInvestmentBalance,
        getSavingsBalance,
        getNetworthDebtBalance,
        getNetworthAssetsBalance,
        getNetworthBalance,
    ]);
};
