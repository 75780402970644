import React, { memo, useCallback, useEffect, useRef } from 'react';
import { View, StyleSheet, InteractionManager } from 'react-native';
import Animated, { withTiming, useSharedValue, useAnimatedStyle, } from 'react-native-reanimated';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import Button from 'design-system/Button';
import { rem } from 'design-system/values';
import { CardView } from 'design-system/CardView';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setDidClosePushNotificationCard } from 'actions/deviceSettings';
import { useNotificationsPermissions } from 'hooks/useNotificationsPermissions';
import { NotificationsPermissionState } from 'hooks/useNotificationPermissionTypes';
const CARD_HEIGHT = rem(168);
const NotificationBadge = () => {
    const hideCard = useSharedValue(false);
    const dispatch = useAppDispatch();
    const useClosedBefore = useAppSelector((state) => state.deviceSettings.didClosePushNotificationCard);
    const { permissionState, activatePush } = useNotificationsPermissions('settings');
    const animatedStyles = useAnimatedStyle(() => {
        if (hideCard.value) {
            return { height: withTiming(0) };
        }
        return { height: CARD_HEIGHT };
    }, []);
    const hideView = useCallback(() => {
        hideCard.value = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const timeoutRef = useRef();
    const handleCancel = useCallback(() => {
        hideView();
        timeoutRef.current = setTimeout(() => {
            dispatch(setDidClosePushNotificationCard());
        }, 1000);
    }, [dispatch, hideView]);
    useEffect(() => () => {
        clearTimeout(timeoutRef.current);
    }, []);
    const handleTurnOn = useCallback(() => {
        InteractionManager.runAfterInteractions(activatePush);
    }, [activatePush]);
    if (useClosedBefore)
        return null;
    if (permissionState === NotificationsPermissionState.Enabled ||
        permissionState === NotificationsPermissionState.Unknown)
        return null;
    return (<Animated.View style={[animatedStyles, styles.container]}>
      <CardView withPadding noMarginTop>
        <View style={styles.row}>
          <Text Text-16 flex>
            Turn on notifications
          </Text>
        </View>
        <Spacer h={4}/>
        <Text TextThin-14 Secondary>
          {'Get real time updates on your finances to\xa0stay\xa0on\xa0track and never miss anything.'}
        </Text>
        <Spacer h={16}/>
        <View style={styles.row}>
          <Button brandReversed square title="Turn On" onPress={handleTurnOn} containerStyle={styles.btn}/>
          <Spacer w={8}/>
          <Button brandBorder square title="Not Now" onPress={handleCancel} containerStyle={styles.btn}/>
        </View>
      </CardView>
    </Animated.View>);
};
export default memo(NotificationBadge);
const styles = StyleSheet.create({
    container: {
        overflow: 'hidden',
    },
    row: {
        flexDirection: 'row',
    },
    btn: {
        paddingHorizontal: rem(24),
    },
});
