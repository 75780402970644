import { useCallback } from 'react';
import moment from 'moment';
import { getBankConnections, getBalanceHistory } from 'actions';
import { useAppDispatch } from 'store/hooks';
const addBalanceHistoryAccountType = (type, balanceHistoryTypes) => {
    switch (type) {
        case 'CRYPTO':
        case 'INVESTMENT':
            balanceHistoryTypes.add('INVESTMENT');
            break;
        case 'SAVINGS':
            balanceHistoryTypes.add('SAVINGS');
            break;
        case 'CHECKING':
        case 'CREDITCARD':
            balanceHistoryTypes.add('CHECKING');
            break;
        default:
            break;
    }
};
const useRefreshAccounts = () => {
    const dispatch = useAppDispatch();
    const refresh = useCallback((currentType, previousType) => {
        const toNow = moment().format('YYYY-MM-DD');
        const fromLastYear = moment().subtract(1, 'years').format('YYYY-MM-DD');
        dispatch(getBankConnections());
        const balanceHistoryTypes = new Set();
        addBalanceHistoryAccountType(currentType, balanceHistoryTypes);
        if (previousType) {
            addBalanceHistoryAccountType(previousType, balanceHistoryTypes);
        }
        balanceHistoryTypes.forEach((balanceHistoryType) => {
            dispatch(getBalanceHistory(fromLastYear, toNow, balanceHistoryType, '1day'));
        });
        // These calls are always needed as they can include any of the account types
        dispatch(getBalanceHistory(fromLastYear, toNow, 'NETWORTH', '1day'));
        dispatch(getBalanceHistory(fromLastYear, toNow, 'ASSETS', '1day'));
        dispatch(getBalanceHistory(fromLastYear, toNow, 'DEBT', '1day'));
    }, []);
    return refresh;
};
export default useRefreshAccounts;
