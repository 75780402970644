export var ChartType;
(function (ChartType) {
    ChartType["pie"] = "pie";
    ChartType["bar"] = "bar";
    ChartType["linear"] = "linear";
})(ChartType || (ChartType = {}));
export const chartTypeToMinHeight = {
    [ChartType.bar]: 291,
    [ChartType.linear]: 340,
    [ChartType.pie]: 404,
};
