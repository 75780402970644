import { SET_AD_ACTION, CLEAR_ADS_ACTION, SET_SHOULD_SEE_DEVICE_ROOTED_WARNING_MODAL, } from 'actions/sessionSettingsTypes';
import { LOGGED_OUT } from 'actions/types';
export const initialState = {
    ad: undefined,
    shouldSeeDeviceRootedWarningModal: false,
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SHOULD_SEE_DEVICE_ROOTED_WARNING_MODAL: {
            return {
                ...state,
                shouldSeeDeviceRootedWarningModal: action.value,
            };
        }
        case SET_AD_ACTION: {
            if (action.ad?.id) {
                return {
                    ...state,
                    ad: action.ad,
                };
            }
            return {
                ...state,
                ad: undefined,
            };
        }
        case CLEAR_ADS_ACTION: {
            return {
                ...state,
                ad: undefined,
            };
        }
        case LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
};
export default reducer;
