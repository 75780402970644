import React, { useCallback, useEffect, useMemo } from 'react';
import { Platform, View } from 'react-native';
import Animated from 'react-native-reanimated';
import { AppView } from 'design-system/AppView';
import { HelpCallToAction } from 'design-system/HelpCallToAction';
import { rem, SCROLL_INDICATOR_INSETS } from 'design-system/values';
import Spacer from 'design-system/Spacer';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import StatusBar from 'design-system/StatusBar';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useStyles from 'hooks/useStyles';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import createStyleSheets from 'utils/createStyleSheets';
import { useRequiredBenefitCallbackWithSpaces } from 'features/premium/hooks/useRequiredBenefitCallback';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import Amplitude from '../../../utils/amplitude';
import Links from '../../../utils/links';
import RowSubscription from '../components/row/RowSubscription';
import SwitchingButton from '../components/row/SwitchingButton';
import { selectInactiveSubscriptions } from '../selectors';
import { isWeb } from '../../../constants';
import { getSubscriptions } from '../actions';
import UpgradeToSeeMoreCard from '../components/UpgradeToSeeMoreCard';
const openInfo = () => {
    const url = 'https://help.emma-app.com/en/category/subscriptions-1xrbcm4/';
    Links.openLink(url);
};
const keyExtractor = (item) => item.id.toString();
const InactiveSubscriptionsScreen = withAnimatedNavigationHeader(({ navigation, route, onScroll }) => {
    const guessedHomeCountry = useAppSelector((store) => store.user.user.guessedHomeCountry);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (isWeb) {
            dispatch(getSubscriptions());
        }
    }, []);
    const styles = useStyles(styleSet);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const paddingBottom = useMarginBottom('paddingBottom', undefined, styles.contentContainer, paddingHorizontalStyle);
    const inactiveSubscriptions = useAppSelector(selectInactiveSubscriptions);
    const onOpenCompare = useCallback((routeWithParams) => {
        navigation.navigate(routeWithParams);
        Amplitude.logEvent('Subscriptions.openCompare', {
            type: routeWithParams.name,
        });
    }, []);
    const openSubscription = useRequiredBenefitCallbackWithSpaces(useCallback((data) => {
        navigation.navigate('Subscription', {
            keyBack: route.key,
            id: data.id,
        });
    }, [route.key]), 'recurringPayments');
    const { isUnlockedForSpace, onPressUpgrade } = useShouldShowUpgrade({
        benefitId: 'recurringPayments',
    });
    const renderHeader = useMemo(() => isUnlockedForSpace ? null : (<UpgradeToSeeMoreCard onPressUpgrade={onPressUpgrade}/>), [isUnlockedForSpace, onPressUpgrade]);
    const renderFooter = useMemo(() => (<>
            <Spacer h={16}/>
            <HelpCallToAction onDark onPress={openInfo} subTitle="Learn how to do it" title="Need to amend your recurring payments?"/>
          </>), []);
    const renderItem = useCallback(({ item, index }) => (<View style={[
            styles.rowSubscription,
            index === 0 && styles.itemContainerFirst,
            index === inactiveSubscriptions.length - 1 &&
                styles.itemContainerLast,
        ]}>
            <RowSubscription data={item} onPress={openSubscription} switchingButton={<SwitchingButton data={item} onOpenCompare={onOpenCompare} guessedHomeCountry={guessedHomeCountry}/>} blurValues={!isUnlockedForSpace}/>
          </View>), [
        styles.rowSubscription,
        styles.itemContainerFirst,
        styles.itemContainerLast,
        inactiveSubscriptions.length,
        openSubscription,
        onOpenCompare,
        guessedHomeCountry,
        isUnlockedForSpace,
    ]);
    return (<AppView withFrame={false}>
          <StatusBar barStyle="dark-content" animated/>
          <Animated.FlatList onScroll={onScroll} renderItem={renderItem} scrollEventThrottle={16} keyExtractor={keyExtractor} data={inactiveSubscriptions} ListHeaderComponent={renderHeader} ListFooterComponent={renderFooter} scrollIndicatorInsets={SCROLL_INDICATOR_INSETS} contentContainerStyle={paddingBottom}/>
        </AppView>);
}, ({ colors }) => ({
    title: 'Inactive Payments',
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styleSet = createStyleSheets((colors) => ({
    rowSubscription: {
        marginHorizontal: rem(16),
        backgroundColor: colors.cards.onDark,
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
    itemContainerFirst: {
        borderTopLeftRadius: rem(16),
        borderTopRightRadius: rem(16),
        ...(Platform.OS === 'ios' && {
            overflow: 'hidden',
        }),
        ...(isWeb && {
            paddingTop: rem(8),
        }),
    },
    itemContainerLast: {
        borderBottomLeftRadius: rem(16),
        borderBottomRightRadius: rem(16),
        ...(Platform.OS === 'ios' && {
            overflow: 'hidden',
        }),
        ...(isWeb && {
            paddingBottom: rem(8),
        }),
    },
    row: {
        flexDirection: 'row',
    },
    addNewBtn: {
        paddingHorizontal: rem(22),
    },
    contentContainer: {
        paddingTop: rem(16),
    },
}));
export default InactiveSubscriptionsScreen;
