import Modal from 'design-system/Modal';
import { FlatList, StyleSheet, View, } from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import { PickerItem } from 'design-system/picker/PickerItem';
import PopUpMenuModal, { defaultPopUpWidth, } from 'design-system/PopUpMenuModal.web';
import { rem } from 'design-system/values';
import { isWeb } from '../../constants';
const PickerModal = ({ onSelected, pickerOptions, selectedIconFill, hideAfterCallback, selectedValue: initialSelectedValue, }) => {
    const [selectedValue, setSelectedValue] = useState(initialSelectedValue);
    const timeoutRef = useRef();
    useEffect(() => () => {
        clearTimeout(timeoutRef.current);
    }, []);
    return (<View style={styles.modalContent}>
      <FlatList data={pickerOptions} renderItem={(info) => (<PickerItem selectedIconFill={selectedIconFill} label={info.item.label} value={info.item.value} icon={info.item.icon} selected={info.item.itemKey
                ? info.item.itemKey === selectedValue?.itemKey
                : info.item.value === selectedValue?.value} onPress={() => {
                setSelectedValue(info.item);
                if (hideAfterCallback) {
                    onSelected?.(info.item, info.index);
                    timeoutRef.current = setTimeout(() => {
                        PickerModal.hide();
                    }, 50);
                }
                else {
                    PickerModal.hide(() => {
                        onSelected?.(info.item, info.index);
                    });
                }
            }}/>)}/>
    </View>);
};
const PickerModalMemo = React.memo(PickerModal);
PickerModal.show = (props, options, viewRef) => {
    if (isWeb && viewRef) {
        viewRef.current?.measure((_, __, ___, ____, fx, fy) => {
            PopUpMenuModal.show(<PickerModalMemo {...props} hideAfterCallback={props?.hideAfterCallback}/>, {
                fx: fx - defaultPopUpWidth + rem(56),
                fy: fy + rem(56),
                containerStyle: options?.containerStyle,
            });
        });
    }
    else {
        Modal.show(<PickerModalMemo {...props} hideAfterCallback={props?.hideAfterCallback}/>, {
            containerStyle: {
                paddingHorizontal: 0,
            },
            ...options,
        });
    }
};
PickerModal.hide = (onDidHideCallback) => {
    if (isWeb) {
        PopUpMenuModal.hide(onDidHideCallback);
    }
    else {
        Modal.hide(onDidHideCallback);
    }
};
export { PickerModal };
const styles = StyleSheet.create({
    modalContent: {},
});
