import React, { useLayoutEffect, useRef, useState } from 'react';
import { CommonActions } from '@react-navigation/native';
import { View } from 'react-native';
import emoji from 'node-emoji';
import Button from 'design-system/Button';
import { rem } from 'design-system/values';
import { AppView } from 'design-system/AppView';
import StatusBar from 'design-system/StatusBar';
import KeyboardAvoidingContainer from 'design-system/KeyboardAvoidingContainer';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import Alert from 'utils/packages/Alert';
import TextInput from 'utils/packages/TextInput';
import createStyleSheets from 'utils/createStyleSheets';
import { muteErrorAndResult, useFetchOld } from 'hooks';
import { useAutoFocusInput } from 'hooks/useAutoFocusInput';
import { isWeb } from '../../../constants';
import { editSubscription } from '../actions';
const RenameSubscriptionScreen = ({ navigation, route, }) => {
    const colors = useColors();
    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Rename',
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary]);
    const styles = useStyles(styleSet);
    const subscription = route.params.data;
    const [name, setName] = useState(subscription.customName || subscription.merchantInfo?.displayName || '');
    const [, , isFetching, fetch] = useFetchOld(muteErrorAndResult);
    const onPressSubmit = async () => {
        if (!name || !name.length) {
            Alert.alert(emoji.emojify('Slow down :face_with_rolling_eyes:'), 'You must enter a merchant.');
            return;
        }
        if (isWeb) {
            await fetch(editSubscription(subscription.id, { customName: name }));
        }
        else {
            fetch(editSubscription(subscription.id, { customName: name }));
        }
        if (navigation.canGoBack()) {
            navigation.goBack();
        }
        else {
            navigation.dispatch(CommonActions.reset({
                index: 0,
                routes: [{ name: 'Feed' }],
            }));
        }
    };
    const textInputRef = useRef(null);
    useAutoFocusInput(textInputRef);
    return (<AppView type="primary" isInTab={isWeb}>
      <View style={styles.container}>
        <StatusBar barStyle="dark-content" animated/>
        <KeyboardAvoidingContainer>
          <View style={styles.inputContainer}>
            <TextInput ref={textInputRef} autoCorrect={false} allowFontScaling={false} value={name} onChangeText={setName} placeholderTextColor={colors.text.placeholder} underlineColorAndroid="transparent" selectionColor={colors.text.selection} style={styles.textinput} placeholder="Choose Name" returnKeyType="done" onSubmitEditing={onPressSubmit}/>
          </View>
          <Button brand title="Save" onPress={onPressSubmit} isFetching={isFetching}/>
        </KeyboardAvoidingContainer>
      </View>
    </AppView>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
    },
    inputContainer: {
        flex: 1,
        margin: rem(16),
        justifyContent: 'center',
    },
    textinput: {
        fontSize: rem(32),
        fontFamily: 'Montserrat-Light',
        textAlign: 'center',
        color: colors.text.primary,
    },
}));
export default RenameSubscriptionScreen;
