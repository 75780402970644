import React, { useCallback, useMemo } from 'react';
import { TouchableHighlight, View } from 'react-native';
import { Button, rem } from 'design-system';
import Amount from 'design-system/Amount';
import { CIRCLE_SIZE } from 'design-system/LogoBankAccount';
import Text from 'design-system/Text';
import useOnboarding from 'features/emmaWallet/hooks/useOnboarding';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { useAppSelector } from 'store/hooks';
import createStyleSheets from 'utils/createStyleSheets';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import IconEmmaWallet from './IconEmmaWallet';
import { isWeb } from '../../../constants';
/**
 * If you need to show this within a card, use `onCard`, which makes logo icons use darker background in dark mode.
 */
const RowEmmaWallet = ({ data, style, onCard, onPress }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const didViewEmmaWalletIntro = useAppSelector((store) => store.deviceSettings.didViewEmmaWalletIntro);
    const { navigate } = useMainStackNavigation();
    const onboard = useOnboarding();
    const handleActivate = useCallback(() => {
        if (didViewEmmaWalletIntro) {
            onboard();
        }
        else {
            navigate('EmmaWalletIntro');
        }
    }, [didViewEmmaWalletIntro, onboard]);
    const handlePress = useCallback(() => {
        if (onPress) {
            onPress();
            return;
        }
        navigate('EmmaWallet');
    }, [onPress]);
    const handleActionRequired = useCallback(() => {
        navigate('EmmaWalletUnderReview');
    }, []);
    const rightComponent = useMemo(() => {
        const { accountStatus } = data;
        switch (accountStatus) {
            case 'ACTIVE': {
                return <Amount Numbers-16 value={data.balance}/>;
            }
            case 'NOT_ELIGIBLE':
            case 'OPENING_IN_PROGRESS':
            case 'CLOSING_IN_PROGRESS':
                return (<Button small square brandReversed disabled={isWeb} title="Under Review" onPress={handleActionRequired}/>);
            case 'KYC_PENDING':
                return (<Button small square brandReversed disabled={isWeb} title="Action Required" onPress={handleActionRequired}/>);
            case 'NOT_STARTED': {
                if (isWeb) {
                    return null;
                }
                return (<Button small square brandReversed title="Learn more" onPress={handleActivate}/>);
            }
            default:
                return null;
        }
    }, [data, handleActionRequired, handleActivate]);
    return (<TouchableHighlight underlayColor={colors.background.underlay} activeOpacity={1} disabled={data.accountStatus !== 'ACTIVE' || isWeb} onPress={handlePress}>
      <View style={[styles.rowAccount, style]}>
        <IconEmmaWallet onCard={onCard}/>
        <View style={styles.textContainer}>
          <Text Text-16 numberOfLines={1} ellipsizeMode="tail">
            {data.name}
          </Text>
        </View>
        <View>{rightComponent}</View>
      </View>
    </TouchableHighlight>);
};
const styleSet = createStyleSheets((colors) => ({
    rowAccount: {
        paddingTop: rem(16),
        alignItems: 'center',
        flexDirection: 'row',
        paddingBottom: rem(16),
    },
    textContainer: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: rem(16),
    },
    container: {
        alignItems: 'center',
        borderRadius: rem(22),
        width: rem(CIRCLE_SIZE),
        justifyContent: 'center',
        height: rem(CIRCLE_SIZE),
        backgroundColor: colors.cards.onLight,
    },
    onCard: {
        backgroundColor: colors.background.dark,
    },
}));
export default RowEmmaWallet;
