import React, { useCallback, useEffect, useState, } from 'react';
import { Keyboard, KeyboardAvoidingView as OriginalKeyboardAvoidingView, useWindowDimensions, } from 'react-native';
const useIsFloatingKeyboard = () => {
    const [isFloating, setFloating] = useState(false);
    const { width } = useWindowDimensions();
    const onKeyboardWillChangeFrame = useCallback((event) => {
        setFloating(event.endCoordinates.width !== width);
    }, [width]);
    useEffect(() => {
        const listener = Keyboard.addListener('keyboardWillChangeFrame', onKeyboardWillChangeFrame);
        return () => {
            listener.remove();
        };
    }, [onKeyboardWillChangeFrame]);
    return isFloating;
};
const KeyboardAvoidingView = ({ children, ...props }) => {
    const isFloatingKeyboard = useIsFloatingKeyboard();
    return (<OriginalKeyboardAvoidingView {...props} enabled={isFloatingKeyboard ? false : undefined}>
      {children}
    </OriginalKeyboardAvoidingView>);
};
export default KeyboardAvoidingView;
