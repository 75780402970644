/**
 * Use this hook when you need to ensure that something runs only when user can see the app (instead of passcode lock
 * screen).
 *
 * For example, when you need to run some animation as soon as user can see your view. `useFocusEffect` won't do the
 * job because a screen could be focused in navigator, but the passcode overlay can be shown to user in that moment.
 *
 * It provides a callback that you use to wrap your executable code. The callback either executes it right away, or
 * will wait for the passcode overlay to be dismissed.
 *
 * It additionally provides a ref that holds a boolean flag saying if passcode is currently visible.
 *
 * The callback doesn't depend on anything, it has no dependencies and won't lead to any extra renders.
 */
import { useCallback, useRef } from 'react';
import { selectPasscodeVisible } from 'reducers/selectors';
import { useAppSelector } from 'store/hooks';
const useOnPasscodeHide = () => {
    const isPasscodeVisibleStateVariable = useAppSelector(selectPasscodeVisible);
    const isVisibleRef = useRef(isPasscodeVisibleStateVariable);
    const callbackRef = useRef();
    if (callbackRef.current) {
        if (isPasscodeVisibleStateVariable) {
            if (!isVisibleRef.current) {
                isVisibleRef.current = true;
                callbackRef.current();
                callbackRef.current = undefined;
            }
        }
        else {
            isVisibleRef.current = false;
        }
    }
    else {
        isVisibleRef.current = isPasscodeVisibleStateVariable;
    }
    const onHide = useCallback((callback) => {
        if (isVisibleRef.current === false) {
            callback();
        }
        else {
            callbackRef.current = callback;
        }
    }, []);
    return [onHide, isVisibleRef];
};
export default useOnPasscodeHide;
