import { getBalance } from './getBalance';
const getBalanceForSort = (a) => {
    const [balance, balanceSecondary, useSecondForSort] = getBalance(a);
    if (balanceSecondary && useSecondForSort) {
        return balanceSecondary;
    }
    return balance;
};
export const sortByBalance = (a, b) => {
    if ('isHidden' in a && a.isHidden)
        return 1;
    if ('isHidden' in b && b.isHidden)
        return -1;
    const balanceA = getBalanceForSort(a);
    const balanceB = getBalanceForSort(b);
    if (balanceA && balanceB) {
        if (balanceA.amount > balanceB.amount)
            return -1;
        if (balanceA.amount < balanceB.amount)
            return 1;
    }
    return 0;
};
export const sortAccounts = (data, reverse) => {
    const array = data.sort(sortByBalance);
    if (reverse) {
        return array.slice().reverse();
    }
    return array;
};
export const sortSavingsAccounts = (data, reverse) => {
    const array = data.sort(sortByBalance);
    if (reverse) {
        return array.slice().reverse();
    }
    return array;
};
const sortPriority = {
    CHECKING: 0,
    CREDITCARD: 1,
    LOAN: 1,
    SAVINGS: 2,
    INVESTMENT: 3,
    CRYPTO: 3,
    OTHER: 3,
    PENSION: 3,
    REAL_ESTATE: 3,
    VEHICLE: 3,
    DEBT: 3,
    ASSETS: 3,
    NETWORTH: 3,
};
export const sortAccountsByType = (data) => data.sort((a, b) => {
    const aPriority = sortPriority[a.type];
    const bPriority = sortPriority[b.type];
    const result = (aPriority === -1 ? 3 : aPriority) - (bPriority === -1 ? 3 : bPriority);
    if (result === 0) {
        return sortByBalance(a, b);
    }
    return result;
});
export default sortAccounts;
