import { REHYDRATE } from 'redux-persist';
import { getTransactionsIdObj } from 'utils/getTransactions';
import { SAVE_BUDGETS_SUCCESS } from 'features/budgeting/actions/types';
import { ADD_TXNS_TO_SUBSCRIPTION_SUCCESS } from 'features/subscriptions/actions/types';
import * as types from '../actions/types';
const initialState = {
    feed: { cards: {} },
    isFetching: false,
    isRefreshing: false,
};
const feed = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE:
            const { feed } = action.payload || {};
            if (feed && feed.feed && feed.feed.cards && feed.feed.cards) {
                if (feed.feed.cards.notifications &&
                    feed.feed.cards.notifications.items)
                    feed.feed.cards.notifications.items = undefined;
                if (feed.feed.cards.initialReportCoverInfo)
                    feed.feed.cards.initialReportCoverInfo = undefined;
                if (feed.feed.cards.paydayReportCoverInfo)
                    feed.feed.cards.paydayReportCoverInfo = undefined;
                if (feed.feed.cards.weeklyReportCoverInfo)
                    feed.feed.cards.weeklyReportCoverInfo = undefined;
                if (feed.feed.cards.monthlyReportCoverInfo)
                    feed.feed.cards.monthlyReportCoverInfo = undefined;
                if (feed.feed.cards.energySwitchingEnquiry)
                    feed.feed.cards.energySwitchingEnquiry = undefined;
                if (feed.feed.cards.quiz)
                    feed.feed.cards.quiz = undefined;
                feed.feed.fetchedInOnboarding = false;
            }
            if (feed?.walkthrough) {
                feed.walkthrough = {
                    status: feed.walkthrough.status,
                    step: 0,
                    count: 0,
                    highlightTabIndex: 0,
                };
            }
            const returnState = JSON.parse(JSON.stringify(initialState));
            if (feed)
                return Object.assign(returnState, feed);
            return state;
        case types.GET_FEED_REQUEST:
            return { ...state, isRefreshing: true };
        case types.GET_FEED_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isRefreshing: false,
                feed: {
                    cards: { ...action.payload },
                    fetchedInOnboarding: action.extra,
                },
            };
        case types.GET_FEED_FAILURE:
            return { ...state, isFetching: false, isRefreshing: false };
        case types.EDIT_CATEGORY_SUCCESS: {
            if (!state.feed?.cards?.latestTransactions) {
                return state;
            }
            const { data } = action.extra;
            return {
                ...state,
                feed: {
                    ...state.feed,
                    cards: {
                        ...state.feed.cards,
                        latestTransactions: state.feed.cards.latestTransactions.map((transaction) => {
                            if (data[transaction.id]) {
                                return {
                                    ...transaction,
                                    category: action.extra.category,
                                };
                            }
                            return transaction;
                        }),
                    },
                },
            };
        }
        case types.RENAME_TRANSACTION_SUCCESS: {
            if (!state.feed?.cards?.latestTransactions) {
                return state;
            }
            const { data } = action.extra;
            const idObj = getTransactionsIdObj(data);
            return {
                ...state,
                feed: {
                    ...state.feed,
                    cards: {
                        ...state.feed.cards,
                        latestTransactions: state.feed.cards.latestTransactions.map((transaction) => {
                            if (idObj[transaction.id]) {
                                return {
                                    ...transaction,
                                    customName: action.extra.counterPartName,
                                };
                            }
                            return transaction;
                        }),
                    },
                },
            };
        }
        case types.SAVE_NOTE_SUCCESS:
        case types.SET_TAGS_SUCCESS:
            if (!state.feed?.cards?.latestTransactions) {
                return state;
            }
            return {
                ...state,
                feed: {
                    ...state.feed,
                    cards: {
                        ...state.feed.cards,
                        latestTransactions: state.feed.cards.latestTransactions.map((transaction) => {
                            if (action.payload.id === transaction.id) {
                                return {
                                    ...transaction,
                                    ...action.payload,
                                };
                            }
                            return transaction;
                        }),
                    },
                },
            };
        case types.ADD_SUBSCRIPTIONS_SUCCESS:
        case ADD_TXNS_TO_SUBSCRIPTION_SUCCESS: {
            if (!state.feed.cards.latestTransactions?.length) {
                return state;
            }
            const txnIds = action.type === types.ADD_SUBSCRIPTIONS_SUCCESS
                ? [action.extra.baseTransactionId]
                : action.extra.ids;
            const subscription = action.type === types.ADD_SUBSCRIPTIONS_SUCCESS
                ? action.payload.subscription
                : action.payload;
            return {
                ...state,
                feed: {
                    ...state.feed,
                    cards: {
                        ...state.feed.cards,
                        latestTransactions: state.feed.cards.latestTransactions.map((item) => {
                            if (txnIds.includes(item.id)) {
                                return {
                                    ...item,
                                    subscriptionId: subscription.id,
                                };
                            }
                            return item;
                        }),
                    },
                },
            };
        }
        case types.RESET_UNREAD_SUCCESS:
            if (!state.feed?.cards)
                return state;
            return {
                ...state,
                feed: {
                    ...state.feed,
                    cards: {
                        ...state.feed.cards,
                        numberOfUnreadNotifications: 0,
                    },
                },
            };
        case SAVE_BUDGETS_SUCCESS:
            const total = action.extra.data['overall.monthly'];
            if (total === undefined || !state.feed.cards.thisMonth)
                return state;
            return {
                ...state,
                feed: {
                    ...state.feed,
                    cards: {
                        ...state.feed.cards,
                        thisMonth: {
                            ...state.feed.cards.thisMonth,
                            budget: total.baseLimit || 0,
                        },
                    },
                },
            };
        case types.SET_TAB_BAR_HEIGHT_FOR_WALKTHROUGH:
            return {
                ...state,
                walkthrough: {
                    ...state.walkthrough,
                    tabBarHeight: action.payload,
                    count: state.walkthrough?.count || 0,
                },
            };
        case types.SET_WALKTHROUGH_STATUS:
            const isStarting = action.payload === 'start' || action.payload === 'in-progress';
            return {
                ...state,
                walkthrough: {
                    ...state.walkthrough,
                    status: action.payload,
                    step: isStarting ? 0 : state.walkthrough?.step,
                    highlightTabIndex: isStarting
                        ? 0
                        : state.walkthrough?.highlightTabIndex,
                    count: state.walkthrough?.count || 0,
                },
            };
        case types.SET_WALKTHROUGH_STEP:
            return {
                ...state,
                walkthrough: {
                    ...state.walkthrough,
                    step: action.payload.index,
                    highlightTabIndex: action.payload.highlightTabIndex,
                    count: action.payload.count,
                },
            };
        case types.CANCEL_SUBSCRIPTIONS_SUCCESS:
            if (!state.feed.cards.subscriptions)
                return state;
            return {
                ...state,
                feed: {
                    cards: {
                        subscriptions: state.feed.cards.subscriptions.filter((sub) => sub.id !== action.payload.subscriptionId),
                    },
                },
            };
        case types.LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
};
export default feed;
