import { StyleSheet } from 'react-native';
import { rem } from 'design-system/values';
export const TAB_SCREEN_HEADER_HEIGHT = rem(60);
export const TAB_SCREEN_HEADER_HEIGHT_WEB = 64;
export const navigatorStyle = StyleSheet.create({
    title: {
        fontFamily: 'Montserrat-Regular',
        fontSize: rem(18),
        lineHeight: rem(18 * 1.1),
        fontWeight: null,
        textAlign: 'center',
        marginHorizontal: 8,
    },
});
