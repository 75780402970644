export const REVERSE_TRIAL_ACTIVATE_REQUEST = 'REVERSE_TRIAL_ACTIVATE_REQUEST';
export const REVERSE_TRIAL_ACTIVATE_SUCCESS = 'REVERSE_TRIAL_ACTIVATE_SUCCESS';
export const REVERSE_TRIAL_ACTIVATE_FAILURE = 'REVERSE_TRIAL_ACTIVATE_FAILURE';
export const REVERSE_TRIAL_CANCEL_REQUEST = 'REVERSE_TRIAL_CANCEL_REQUEST';
export const REVERSE_TRIAL_CANCEL_SUCCESS = 'REVERSE_TRIAL_CANCEL_SUCCESS';
export const REVERSE_TRIAL_CANCEL_FAILURE = 'REVERSE_TRIAL_CANCEL_FAILURE';
export const REVERSE_TRIAL_DELETE_FUNNEL_ELIGBILITY_REQUEST = 'REVERSE_TRIAL_DELETE_FUNNEL_ELIGBILITY_REQUEST';
export const REVERSE_TRIAL_DELETE_FUNNEL_ELIGBILITY_SUCCESS = 'REVERSE_TRIAL_DELETE_FUNNEL_ELIGBILITY_SUCCESS';
export const REVERSE_TRIAL_DELETE_FUNNEL_ELIGBILITY_FAILURE = 'REVERSE_TRIAL_DELETE_FUNNEL_ELIGBILITY_FAILURE';
export const REVERSE_TRIAL_DELETE_FUNNEL_START_REQUEST = 'REVERSE_TRIAL_DELETE_FUNNEL_START_REQUEST';
export const REVERSE_TRIAL_DELETE_FUNNEL_START_SUCCESS = 'REVERSE_TRIAL_DELETE_FUNNEL_START_SUCCESS';
export const REVERSE_TRIAL_DELETE_FUNNEL_START_FAILURE = 'REVERSE_TRIAL_DELETE_FUNNEL_START_FAILURE';
export const REVERSE_TRIAL_ACKNOWLEDGE_START_REQUEST = 'REVERSE_TRIAL_ACKNOWLEDGE_START_REQUEST';
export const REVERSE_TRIAL_ACKNOWLEDGE_START_SUCCESS = 'REVERSE_TRIAL_ACKNOWLEDGE_START_SUCCESS';
export const REVERSE_TRIAL_ACKNOWLEDGE_START_FAILURE = 'REVERSE_TRIAL_ACKNOWLEDGE_START_FAILURE';
export const REVERSE_TRIAL_ACKNOWLEDGE_END_REQUEST = 'REVERSE_TRIAL_ACKNOWLEDGE_END_REQUEST';
export const REVERSE_TRIAL_ACKNOWLEDGE_END_SUCCESS = 'REVERSE_TRIAL_ACKNOWLEDGE_END_SUCCESS';
export const REVERSE_TRIAL_ACKNOWLEDGE_END_FAILURE = 'REVERSE_TRIAL_ACKNOWLEDGE_END_FAILURE';
export const REVERSE_TRIAL_CONVERSION_EVENT_REQUEST = 'REVERSE_TRIAL_CONVERSION_EVENT_REQUEST';
export const REVERSE_TRIAL_CONVERSION_EVENT_SUCCESS = 'REVERSE_TRIAL_CONVERSION_EVENT_SUCCESS';
export const REVERSE_TRIAL_CONVERSION_EVENT_FAILURE = 'REVERSE_TRIAL_CONVERSION_EVENT_FAILURE';
export const SET_UP_STRIPE_INTENT_CONFIRM_SUCCESS = 'SET_UP_STRIPE_INTENT_CONFIRM_SUCCESS';
export const GET_STRIPE_SUBSCRIPTION_PURCHASE_PREVIEW_REQUEST = 'GET_STRIPE_SUBSCRIPTION_PURCHASE_PREVIEW_REQUEST';
export const GET_STRIPE_SUBSCRIPTION_PURCHASE_PREVIEW_SUCCESS = 'GET_STRIPE_SUBSCRIPTION_PURCHASE_PREVIEW_SUCCESS';
export const GET_STRIPE_SUBSCRIPTION_PURCHASE_PREVIEW_FAILURE = 'GET_STRIPE_SUBSCRIPTION_PURCHASE_PREVIEW_FAILURE';
export const GET_STRIPE_CREDITS_REQUEST = 'GET_STRIPE_CREDITS_REQUEST';
export const GET_STRIPE_CREDITS_SUCCESS = 'GET_STRIPE_CREDITS_SUCCESS';
export const GET_STRIPE_CREDITS_FAILURE = 'GET_STRIPE_CREDITS_FAILURE';
