import React from 'react';
import { Svg, Rect } from 'react-native-svg';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
const IDEAL_DASH_SIZE = rem(3.3); // dash pattern is 3.3 / 3.3
// eslint-disable no-nested-ternary
const DashedLine = () => {
    const colors = useColors();
    const { width } = useAppFrameDimensions();
    const WIDTH = width - rem(32);
    const APPROX_N = ((WIDTH - IDEAL_DASH_SIZE) / IDEAL_DASH_SIZE - 1) / 2;
    const N = Math.floor(APPROX_N);
    const DASH_SIZE = WIDTH / (2 * N + 2);
    return (<Svg width={WIDTH} height={1} viewBox={`0 0 ${WIDTH} 1`} fill="none">
      {Array.from({ length: N + 2 }).map((value, index) => (<Rect key={index.toString()} x={`${(index === 0 ? 0 : -DASH_SIZE / 2) + index * DASH_SIZE * 2}`} y="0" width={`${index === 0 || index === N + 1 ? DASH_SIZE / 2 : DASH_SIZE}`} height="0.5" rx="0.25" fill={colors.borders.strongDivider}/>))}
    </Svg>);
};
// eslint-enable no-nested-ternary
export default DashedLine;
