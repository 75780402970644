import * as amplitude from '@amplitude/analytics-browser';
import Config from 'utils/packages/configs';
amplitude.init(Config.AMPLITUDE_KEY, {
    minIdLength: 2,
    defaultTracking: false,
});
const WebAmplitude = {
    setUserId(userId) {
        amplitude.setUserId(userId);
    },
    setUserProperties(properties) {
        const identifyEvent = new amplitude.Identify();
        Object.keys(properties).forEach((key) => {
            identifyEvent.set(key, properties[key]);
            amplitude.identify(identifyEvent);
        });
    },
    logEvent(event, eventProperties) {
        amplitude.track(event, eventProperties);
    },
};
export default WebAmplitude;
