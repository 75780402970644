import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { rem } from 'design-system/values';
const IconExcluded = ({ width = rem(16), height = rem(16), ...props }) => (<Svg width={width} height={height} fill="none" viewBox="0 0 16 16" {...props}>
    <Path d="M15.745 10.038a6.573 6.573 0 0 1-5.707 5.707c-1.353.17-2.723.17-4.076 0a6.573 6.573 0 0 1-5.707-5.707 16.433 16.433 0 0 1 0-4.076A6.573 6.573 0 0 1 5.962.255c1.353-.17 2.723-.17 4.076 0a6.573 6.573 0 0 1 5.707 5.707c.17 1.353.17 2.723 0 4.076Z" fill="#6E7191"/>
    <Path fillRule="evenodd" clipRule="evenodd" d="m11.227 5.217-5.785 5.807a.32.32 0 0 1-.455 0 .323.323 0 0 1 0-.456l.31-.311a3.928 3.928 0 0 1-.656-.832 2.794 2.794 0 0 1 0-2.85C5.843 4.546 8.435 4.139 10.2 5.336l.573-.575a.32.32 0 0 1 .454 0 .323.323 0 0 1 0 .456ZM8.452 7.09A1.035 1.035 0 0 0 7.07 8.477L8.452 7.09Z" fill="#fff"/>
    <Path d="M10.919 6.463a.32.32 0 0 1 .44.112c.521.88.521 1.97 0 2.85-1.006 1.698-2.986 2.26-4.635 1.698a.323.323 0 0 1 .206-.61c1.389.472 3.04-.005 3.877-1.417.4-.677.4-1.515 0-2.191a.323.323 0 0 1 .112-.442Z" fill="#fff"/>
    <Path d="M9.355 7.7c.177 0 .321.145.321.323 0 .93-.75 1.682-1.676 1.682a.322.322 0 0 1 0-.645c.57 0 1.034-.464 1.034-1.037 0-.178.143-.323.32-.323Z" fill="#fff"/>
  </Svg>);
export default IconExcluded;
