import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from './Icon';
const IconText = ({ ...props }) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} width={props.width || 20} height={props.height || 24} defaultGradientKey="ultimate" viewBox="0 0 20 24" fill="none">
      <Path d="M6.28906 13.2266L4.75781 9.52344H4.625L3.17969 13.2266H6.28906ZM0.289062 16.5938C0.674479 16.5677 0.984375 16.3932 1.21875 16.0703C1.36979 15.8672 1.58594 15.4193 1.86719 14.7266L5.42188 5.96875H5.86719L9.4375 14.375C9.83854 15.3177 10.138 15.9271 10.3359 16.2031C10.5339 16.474 10.8151 16.6042 11.1797 16.5938V17H6V16.5938C6.52083 16.5729 6.86198 16.5286 7.02344 16.4609C7.1901 16.3932 7.27344 16.2214 7.27344 15.9453C7.27344 15.8203 7.23177 15.6302 7.14844 15.375C7.09635 15.224 7.02604 15.0417 6.9375 14.8281L6.53125 13.8594H2.9375C2.70312 14.4948 2.55208 14.9089 2.48438 15.1016C2.34375 15.513 2.27344 15.8125 2.27344 16C2.27344 16.2292 2.42708 16.3958 2.73438 16.5C2.91667 16.5573 3.1901 16.5885 3.55469 16.5938V17H0.289062V16.5938Z" fill={`url(#gradient_${id})`}/>
      <Path d="M15.5078 16.0859C15.7682 16.0859 16.0104 16.0078 16.2344 15.8516C16.3594 15.7578 16.5078 15.612 16.6797 15.4141V13.0781C16.2526 13.1823 15.8776 13.3464 15.5547 13.5703C15.0078 13.9505 14.7344 14.4349 14.7344 15.0234C14.7344 15.3724 14.8125 15.638 14.9688 15.8203C15.1302 15.9974 15.3099 16.0859 15.5078 16.0859ZM12.4062 15.5078C12.4062 14.7891 12.7526 14.2188 13.4453 13.7969C14.138 13.375 15.224 12.9505 16.7031 12.5234V11.3594C16.7031 10.8854 16.6016 10.5365 16.3984 10.3125C16.2005 10.0833 15.8464 9.96875 15.3359 9.96875C15.0495 9.96875 14.8125 10.0234 14.625 10.1328C14.4427 10.237 14.3516 10.3698 14.3516 10.5312C14.3516 10.5938 14.362 10.6458 14.3828 10.6875C14.4036 10.7292 14.4349 10.7734 14.4766 10.8203L14.5703 10.9297C14.6432 11.0182 14.7005 11.1172 14.7422 11.2266C14.7891 11.3307 14.8125 11.4427 14.8125 11.5625C14.8125 11.8646 14.7109 12.099 14.5078 12.2656C14.3099 12.4271 14.0781 12.5078 13.8125 12.5078C13.5052 12.5078 13.2422 12.4167 13.0234 12.2344C12.8099 12.0521 12.7031 11.7891 12.7031 11.4453C12.7031 10.8307 12.9922 10.3438 13.5703 9.98438C14.1484 9.625 14.8594 9.44531 15.7031 9.44531C16.6094 9.44531 17.3672 9.61979 17.9766 9.96875C18.5807 10.3229 18.8828 10.974 18.8828 11.9219V15.7969C18.8828 15.9115 18.9115 16.0104 18.9688 16.0938C19.0312 16.1719 19.099 16.2109 19.1719 16.2109C19.2344 16.2109 19.2891 16.2005 19.3359 16.1797C19.388 16.1536 19.4661 16.0885 19.5703 15.9844L19.8125 16.3203C19.5 16.6849 19.1536 16.9401 18.7734 17.0859C18.5339 17.1797 18.2812 17.2266 18.0156 17.2266C17.5417 17.2266 17.1953 17.0885 16.9766 16.8125C16.8516 16.6562 16.7604 16.4245 16.7031 16.1172C16.2604 16.5599 15.7604 16.8724 15.2031 17.0547C14.8646 17.1693 14.5312 17.2266 14.2031 17.2266C13.7656 17.2266 13.3568 17.0885 12.9766 16.8125C12.5964 16.5312 12.4062 16.0964 12.4062 15.5078Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default IconText;
