import React, { memo, useCallback } from 'react';
import { Animated } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableBounce from 'utils/packages/TouchableBounce';
const TagItem = ({ title, index, selected, compact, onPress, onDark, onCard, }) => {
    const press = useCallback(() => {
        if (onPress) {
            onPress(index);
        }
    }, [index, onPress]);
    const styles = useStyles(styleSet);
    const formattedTitle = title.charAt(0) !== '#' ? `#${title}` : title;
    return (<TouchableBounce onPress={press} disabled={!onPress}>
      <Animated.View style={[
            styles.tag,
            onCard && styles.onCard,
            onDark && styles.onDark,
            compact && styles.compact,
            selected && styles.tagSelected,
        ]}>
        {selected ? (<Text Text-14 White>
            {formattedTitle}
          </Text>) : (<Text Text-14 Gray>
            {formattedTitle}
          </Text>)}
      </Animated.View>
    </TouchableBounce>);
};
const styleSet = createStyleSheets((colors) => ({
    tag: {
        paddingVertical: rem(8),
        paddingHorizontal: rem(20),
        marginLeft: rem(2),
        marginRight: rem(2),
        borderRadius: rem(8),
        marginVertical: rem(5),
        backgroundColor: colors.cards.onLight,
    },
    onDark: {
        backgroundColor: colors.cards.onDark,
    },
    onCard: {
        backgroundColor: colors.background.dark,
    },
    compact: {
        paddingVertical: rem(3),
        paddingHorizontal: rem(16),
        borderRadius: rem(25),
    },
    tagSelected: {
        backgroundColor: colors.elements.brand,
    },
}));
export default memo(TagItem);
