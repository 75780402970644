import React, { useId } from 'react';
import { Path, Rect } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
import useColors from 'hooks/useColors';
const IconChart2 = ({ ...props }) => {
    const colors = useColors();
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="skyBlue">
      <Path fillRule="evenodd" clipRule="evenodd" fill={`url(#gradient_${id})`} d="M8.23161 3H15.7774C19.055 3 21.009 4.953 21 8.229V15.771C21 19.047 19.046 21 15.7684 21H8.23161C4.95397 21 3 19.047 3 15.762V8.229C3 4.953 4.95397 3 8.23161 3Z"/>
      <Rect y="7" rx="1" width="2" x="13.7354" height="11.3267" fill={colors.background.dark} transform="rotate(30.4194 13.7354 7)"/>
    </IconWithGradient>);
};
export default IconChart2;
