/**
 * Reducer for holding transfer details.
 *
 * This reducer should not be persisted.
 *
 * If we decide to make it persist between app sessions, please move it to invest.
 */
import { LOGGED_OUT } from 'actions/types';
import { assertUnreachable } from 'utils/types';
import { getPeriodKey } from '../utils/format';
const initialState = {
    financials: undefined,
};
const stockInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_STOCK_FINANCIALS_SUCCESS': {
            const { symbol, period } = action.extra;
            const periodKey = getPeriodKey(period);
            return {
                ...state,
                financials: {
                    ...state.financials,
                    [symbol]: {
                        ...state.financials?.[symbol],
                        [periodKey]: {
                            income: action.payload.income.slice(0, 4),
                            cashflow: action.payload.cashflow.slice(0, 4),
                            balanceSheet: action.payload.balancesheet.slice(0, 4),
                        },
                    },
                },
            };
        }
        case LOGGED_OUT: {
            return initialState;
        }
        default:
            assertUnreachable(action);
            return state;
    }
};
export default stockInfoReducer;
