import React, { memo } from 'react';
import { View, StyleSheet } from 'react-native';
import Svg, { Circle, Path } from 'react-native-svg';
import Animated, { interpolate, useAnimatedStyle, } from 'react-native-reanimated';
import useColors from 'hooks/useColors';
import { rem } from './values';
const size = rem(255);
const BgDots = () => {
    const colors = useColors();
    return (<Svg viewBox="0 0 255 255" width={size} height={size} fill={'none'}>
      <Circle cx="127.5" cy="22.5" r="4.5" fill={colors.elements.brand}/>
      <Circle cx="59.5" cy="60.5" r="4.5" fill={colors.elements.brand}/>
      <Circle cx="24.5" cy="129.5" r="4.5" fill={colors.elements.brand}/>
      <Circle cx="59.5" cy="198.5" r="4.5" fill={colors.elements.brand}/>
      <Circle cx="127.5" cy="232.5" r="4.5" fill={colors.elements.brand}/>
      <Circle cx="195.5" cy="198.5" r="4.5" fill={colors.elements.brand}/>
      <Circle cx="230.5" cy="129.5" r="4.5" fill={colors.elements.brand}/>
      <Circle cx="198.5" cy="58.5" r="4.5" fill={colors.elements.brand}/>
      <Circle cx="153.5" cy="66.5" r="3.5" fill={colors.elements.brand}/>
      <Circle cx="107.5" cy="81.5" r="3.5" fill={colors.background.brandMedium}/>
      <Circle cx="79.5" cy="149.5" r="3.5" fill={colors.background.brandMedium}/>
      <Circle cx="176.5" cy="109.5" r="3.5" fill={colors.background.brandMedium}/>
      <Circle cx="147.5" cy="177.5" r="3.5" fill={colors.background.brandMedium}/>
      <Circle cx="66.5" cy="104.5" r="3.5" fill={colors.elements.brand}/>
      <Circle cx="102.5" cy="190.5" r="3.5" fill={colors.elements.brand}/>
      <Circle cx="188.5" cy="155.5" r="3.5" fill={colors.elements.brand}/>
    </Svg>);
};
const CenterBg = () => {
    const colors = useColors();
    return (<Svg viewBox="0 0 255 255" width={size} height={size} fill={'none'}>
      <Path d="M134.138 165.169C130.465 169.714 123.535 169.714 119.862 165.169C117.579 162.345 113.846 161.133 110.34 162.075C104.696 163.593 99.0904 159.52 98.7896 153.684C98.6027 150.058 96.2957 146.882 92.9048 145.584C87.4471 143.494 85.3059 136.904 88.493 132.006C90.4732 128.962 90.4732 125.038 88.493 121.994C85.3059 117.096 87.4471 110.506 92.9048 108.416C96.2957 107.118 98.6027 103.943 98.7896 100.316C99.0904 94.48 104.696 90.4072 110.34 91.9246C113.846 92.8674 117.579 91.6546 119.862 88.8308C123.535 84.2859 130.465 84.2859 134.138 88.8308C136.421 91.6546 140.154 92.8674 143.66 91.9246C149.304 90.4072 154.91 94.48 155.21 100.316C155.397 103.942 157.704 107.118 161.095 108.416C166.553 110.506 168.694 117.096 165.507 121.994C163.527 125.038 163.527 128.962 165.507 132.006C168.694 136.904 166.553 143.494 161.095 145.584C157.704 146.882 155.397 150.058 155.21 153.684C154.91 159.52 149.304 163.593 143.66 162.075C140.154 161.133 136.421 162.345 134.138 165.169Z" fill={colors.elements.brand}/>
    </Svg>);
};
const CheckSymbol = () => {
    const colors = useColors();
    return (<Svg viewBox="0 0 255 255" width={size} height={size} fill={'none'}>
      <Path fillRule="evenodd" clipRule="evenodd" d="M141.478 115.911C142.263 116.613 142.331 117.818 141.63 118.603L123.475 138.936C123.114 139.341 122.597 139.573 122.054 139.573C121.511 139.573 120.993 139.341 120.632 138.936L113.37 130.803C112.669 130.018 112.737 128.813 113.522 128.111C114.307 127.41 115.512 127.478 116.214 128.264L122.054 134.805L138.786 116.064C139.488 115.278 140.693 115.21 141.478 115.911Z" fill={colors.elements.white}/>
    </Svg>);
};
const SuccessAnimation = ({ sharedValue }) => {
    const bgAnimatedStyle = useAnimatedStyle(() => ({
        transform: [
            {
                scale: sharedValue.value,
            },
        ],
    }), []);
    const centerAnimatedStyle = useAnimatedStyle(() => ({
        transform: [
            {
                scale: sharedValue.value,
            },
            {
                rotate: `${interpolate(sharedValue.value, [0, 1], [0, 270])}deg`,
            },
        ],
    }), []);
    const checkStyles = useAnimatedStyle(() => ({
        opacity: sharedValue.value,
        transform: [
            {
                scale: sharedValue.value,
            },
        ],
    }), []);
    return (<View>
      <Animated.View style={bgAnimatedStyle}>
        <BgDots />
      </Animated.View>
      <Animated.View style={[styles.centerContainer, centerAnimatedStyle]}>
        <CenterBg />
      </Animated.View>
      <Animated.View style={[styles.centerContainer, checkStyles]}>
        <CheckSymbol />
      </Animated.View>
    </View>);
};
export default memo(SuccessAnimation);
const styles = StyleSheet.create({
    centerContainer: {
        ...StyleSheet.absoluteFillObject,
    },
});
