import React, { memo } from 'react';
import { View } from 'react-native';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import Text from './Text';
import Button from './Button';
import { rem } from './values';
import Squircle from './Squircle';
import { CardView } from './CardView';
const UpgradeCard = ({ title, description, icon, onLight, noMarginBottom, onPressUpgrade, btnText = 'Upgrade now', }) => {
    const styles = useStyles(styleSet);
    const colors = useColors();
    return (<CardView withPadding noMarginTop noMarginBottom={noMarginBottom} style={onLight && styles.cardOnLight}>
      <Squircle color={onLight ? colors.background.light : colors.background.dark} style={styles.icon}>
        {icon}
      </Squircle>
      <View style={styles.row}>
        <Text Text-16 flex style={styles.title}>
          {title}
        </Text>
      </View>
      <Text TextThin-14 Secondary style={styles.description}>
        {description}
      </Text>
      <View style={styles.row}>
        <Button brandLight square title={btnText} onPress={onPressUpgrade} containerStyle={styles.btn}/>
      </View>
    </CardView>);
};
export default memo(UpgradeCard);
const styleSet = createStyleSheets((colors) => ({
    row: {
        flexDirection: 'row',
    },
    btn: {
        paddingHorizontal: rem(24),
    },
    icon: {
        marginBottom: rem(16),
    },
    title: {
        marginBottom: rem(4),
    },
    description: {
        marginBottom: rem(16),
    },
    cardOnLight: {
        backgroundColor: colors.cards.onLight,
    },
}));
