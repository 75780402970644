import { getYear, getMonth, startOfMonth, endOfMonth, format, parseISO, } from 'date-fns';
import { isNotLoadedState, } from 'reducers/types';
import { createSelector } from 'reselect';
import getMonthlyTotals from 'utils/api/getMonthlytotals';
import { createNonMemoizedSelector } from 'store/selectors';
import { getAnalyticsCategories } from './utils/getAnalyticsCategories';
import { emptyArray } from '../../constants';
export const selectIsFetchingTotals = (store) => store.expenses.isFetchingTotals;
export const selectAnalyticsPosition = (store) => store.expenses.analyticsPosition ?? 0;
export const selectAnalyticsFirstItem = (store) => store.expenses.analyticsFirstItem ?? 0;
export const selectBudgetingPosition = (store) => store.expenses.budgetingPosition;
export const selectExpensesScreen = (store) => store.utils.currentScreen;
// In analytics 'monthly' does not really necessarily mean monthly, it could mean, monthly, yearly, their pay period, weekly, or a custom period
// It simply means each card in the analytics carousel
export const selectMonthlyCategories = (store) => store.expenses.monthlyCategories;
export const selectMonthlyMerchants = (store) => store.expenses.monthlyMerchants;
export const selectMonthlyCategoriesForPeriod = (store, from) => from != null ? store.expenses.monthlyCategories[from] : undefined;
export const selectMonthlyMerchantsForPeriod = (store, from) => from != null ? store.expenses.monthlyMerchants[from] : undefined;
export const selectIsSwitchingTotalsStep = (store) => store.expenses.isSwitchingTotalsStep;
export const flattenMonthlyTotals = (monthlyTotals) => {
    const initialValue = {
        value: 0,
        income: 0,
        spending: 0,
        to: monthlyTotals[0].from,
        from: monthlyTotals[monthlyTotals.length - 1].from,
        currency: monthlyTotals[0].currency,
        hasCircle: false,
        isPayday: false,
        totalBudget: null,
        rollingAccumulatedLimit: null,
        spendBreakdown: flattenSpendingBreakdowns(monthlyTotals),
    };
    return [
        monthlyTotals.reduce((prev, curr) => ({
            ...prev,
            value: curr.value + prev.value,
            income: curr.income + prev.income,
            spending: curr.spending + prev.spending,
        }), initialValue),
    ];
};
export const selectMonthlyTotalsFiltered = createSelector([(store) => store.expenses.monthlyTotalsFiltered], (filtered) => {
    if (!filtered) {
        return undefined;
    }
    const results = getMonthlyTotals(filtered.results);
    return {
        ...filtered,
        results,
    };
});
const generateFakeMonths = (oldestMonthlyTotal) => {
    const currentYear = getYear(new Date());
    const lastRealResult = parseISO(oldestMonthlyTotal.from);
    const lastRealYear = getYear(lastRealResult);
    const lastRealMonth = getMonth(lastRealResult);
    const fakeResults = [];
    for (let year = lastRealYear; year >= 2010; year--) {
        for (let month = year === currentYear ? getMonth(lastRealResult) : 11; month >= 0; month--) {
            const from = startOfMonth(new Date(year, month - 1));
            const endOfMonthDate = endOfMonth(from);
            if (year !== lastRealYear || month <= lastRealMonth) {
                fakeResults.push({
                    from: format(from, 'yyyy-MM-dd'),
                    to: format(endOfMonthDate, 'yyyy-MM-dd'),
                    loaded: false,
                    income: 0,
                    currency: oldestMonthlyTotal.currency,
                    hasCircle: false,
                    isPayday: false,
                    spending: 0,
                    totalBudget: null,
                    value: 0,
                    rollingAccumulatedLimit: null,
                });
            }
        }
    }
    return fakeResults;
};
export const selectMonthlyTotalsFilteredWithFallbackMonths = createSelector([selectMonthlyTotalsFiltered], (filtered) => {
    if (!filtered)
        return undefined;
    if (filtered.step === 'month') {
        const { results } = filtered;
        // There is an issue with the carousel that means it cant scroll back when changing data and setting another first index
        // I don't 100% understand why, but populating the list with fake months is a workaround
        // we can then call fetchMoreAnalyticsBudgetingData when an item with loaded: false is reached
        const fakeResults = generateFakeMonths(results[results.length - 1]);
        return {
            ...filtered,
            results: [...results, ...fakeResults],
        };
    }
    return filtered;
}, {
    devModeChecks: {
        identityFunctionCheck: 'never',
    },
});
export const selectMonthlyTotals = createSelector([(store) => store.expenses.monthlyTotals], (monthlyTotals) => getMonthlyTotals(monthlyTotals));
export const selectCurrentMonthlyTotals = createNonMemoizedSelector([selectMonthlyTotalsFilteredWithFallbackMonths, selectMonthlyTotals], (filteredMonthlyTotals, unfilteredMonthlyTotals) => filteredMonthlyTotals?.results || unfilteredMonthlyTotals);
export const selectIsCustomFilterEnabled = createSelector([selectMonthlyTotalsFiltered], (monthlyTotalsFiltered) => monthlyTotalsFiltered !== undefined);
const frmtPeriodKey = (total) => total ? `${total.from}|${total.to}` : undefined;
export const selectAnalyticsMerchantCurrentLoadingState = createSelector([
    selectAnalyticsPosition,
    selectCurrentMonthlyTotals,
    selectMonthlyMerchants,
    selectIsSwitchingTotalsStep,
], (position, monthlyTotals, monthlyMerchants, isSwitchingTotalsStep) => {
    const periodKey = frmtPeriodKey(monthlyTotals[position]);
    const data = periodKey ? monthlyMerchants[periodKey] : undefined;
    return (isNotLoadedState(data?.loadingState) ||
        monthlyTotals.length === 0 ||
        isSwitchingTotalsStep);
});
export const selectAnalyticsCategoryCurrentLoadingState = createSelector([
    selectAnalyticsPosition,
    selectCurrentMonthlyTotals,
    selectMonthlyCategories,
    selectIsSwitchingTotalsStep,
], (position, monthlyTotals, monthlyCategories, isSwitchingTotalsStep) => {
    const periodKey = frmtPeriodKey(monthlyTotals[position]);
    const data = periodKey ? monthlyCategories[periodKey] : undefined;
    return (isNotLoadedState(data?.loadingState) ||
        monthlyTotals.length === 0 ||
        isSwitchingTotalsStep);
});
export const selectMonthlyCategoryFromCategoryId = createSelector([
    selectAnalyticsPosition,
    selectCurrentMonthlyTotals,
    selectMonthlyCategories,
    (_, categoryId) => categoryId,
], (position, monthlyTotals, monthlyCategories, categoryId) => {
    const periodKey = frmtPeriodKey(monthlyTotals[position]);
    const data = periodKey ? monthlyCategories[periodKey] : undefined;
    return data?.categories?.find((category) => category.id === categoryId);
});
export const selectMonthlyMerchantFromMerchantId = createSelector([
    selectAnalyticsPosition,
    selectCurrentMonthlyTotals,
    selectMonthlyMerchants,
    (_, merchantId) => merchantId,
], (position, monthlyTotals, monthlyMerchants, merchantId) => {
    const periodKey = frmtPeriodKey(monthlyTotals[position]);
    const data = periodKey ? monthlyMerchants[periodKey] : undefined;
    return data?.merchants.find((merchant) => merchant.id === merchantId);
});
export const selectAnalyticsCategorySections = createSelector([
    selectAnalyticsPosition,
    selectCurrentMonthlyTotals,
    selectMonthlyCategories,
], (position, monthlyTotals, monthlyCategories) => {
    const periodKey = frmtPeriodKey(monthlyTotals[position]);
    const data = periodKey ? monthlyCategories[periodKey] : undefined;
    if ((position === 0 && !data?.transactionsCount) ||
        (data &&
            monthlyTotals[position] &&
            !data.transactionsCount &&
            position !== 0))
        return emptyArray;
    const currency = monthlyTotals && monthlyTotals[position]
        ? monthlyTotals[position].currency
        : '';
    const totalSpending = monthlyTotals && monthlyTotals[position]
        ? monthlyTotals[position].spending
        : 0;
    if (data?.categories) {
        const sorted = [...data.categories].sort((a, b) => a.total > b.total ? 1 : -1);
        const array = getAnalyticsCategories(sorted);
        const expenses = array[0].data;
        const income = array.length > 1 ? array[1].data : [];
        const incomeTotal = array.length > 1 && array[1] ? array[1].total : 0;
        let sections = [];
        const expensesWithTransactions = expenses.filter((e) => e.transactionsCount > 0);
        if (expensesWithTransactions.length > 0 || income.length > 0) {
            sections.push({
                type: 'add-category',
            });
        }
        if (expensesWithTransactions.length > 0) {
            sections = sections.concat(expensesWithTransactions.map((data) => ({
                type: 'category',
                data,
                totalSpending,
            })));
        }
        if (income.length > 0) {
            if (income.length > 1) {
                sections.push({
                    type: 'header',
                    data: { title: 'Income', amount: incomeTotal, currency },
                });
            }
            sections = sections.concat(income.map((data) => ({ type: 'category', data })));
        }
        if (sections.length > 0) {
            return sections.concat({ type: 'footer' });
        }
        return sections;
    }
    return emptyArray;
});
export const selecAnalyticstMerchantSections = createSelector([selectAnalyticsPosition, selectCurrentMonthlyTotals, selectMonthlyMerchants], (position, monthlyTotals, monthlyMerchants) => {
    const periodKey = frmtPeriodKey(monthlyTotals[position]);
    const data = periodKey ? monthlyMerchants[periodKey] : undefined;
    const filtered = data?.merchants.filter((merchant) => merchant.transactionsCount > 0) ||
        [];
    const sections = filtered.map((data) => ({
        type: 'merchant',
        data,
    }));
    if (sections.length > 0) {
        return sections.concat({ type: 'footer' });
    }
    return sections;
});
export const selectCategoryTotals = createNonMemoizedSelector([
    selectMonthlyTotalsFiltered,
    (store) => store.expenses.categoryTotals,
], (filtered, categoryTotals) => {
    if (!filtered) {
        return categoryTotals;
    }
    return categoryTotals;
});
export const selectChartType = (store) => store.analytics.chartType;
const flattenSpendingBreakdowns = (breakdowns) => {
    const flattened = breakdowns
        .slice()
        .reverse()
        .reduce((prev, curr, index) => ({
        ...prev,
        spendBreakdown: [
            ...(curr.spendBreakdown
                ? curr.spendBreakdown.map((value) => ({
                    ...value,
                    cumulative: index > 0 && prev.spendBreakdown[0]
                        ? prev.spendBreakdown[0].cumulative + value.cumulative
                        : value.cumulative,
                }))
                : []),
            ...prev.spendBreakdown,
        ],
    }), { spendBreakdown: [] });
    return flattened.spendBreakdown.reverse();
};
