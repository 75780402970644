import React from 'react';
import Svg, { Path } from 'react-native-svg';
import useColors from 'hooks/useColors';
const ComingSoonIcon = () => {
    const colors = useColors();
    return (<Svg width={32} height={32} viewBox="0 0 32 32">
      <Path fill={colors.elements.secondary} d="M16.0001 8.64844C11.2201 8.64844 7.33008 12.5384 7.33008 17.3184C7.33008 22.0984 11.2201 25.9984 16.0001 25.9984C20.7801 25.9984 24.6701 22.1084 24.6701 17.3284C24.6701 12.5484 20.7801 8.64844 16.0001 8.64844ZM16.7501 16.9984C16.7501 17.4084 16.4101 17.7484 16.0001 17.7484C15.5901 17.7484 15.2501 17.4084 15.2501 16.9984V11.9984C15.2501 11.5884 15.5901 11.2484 16.0001 11.2484C16.4101 11.2484 16.7501 11.5884 16.7501 11.9984V16.9984Z"/>
      <Path fill={colors.elements.secondary} d="M18.8906 7.45H13.1106C12.7106 7.45 12.3906 7.13 12.3906 6.73C12.3906 6.33 12.7106 6 13.1106 6H18.8906C19.2906 6 19.6106 6.32 19.6106 6.72C19.6106 7.12 19.2906 7.45 18.8906 7.45Z"/>
    </Svg>);
};
export default ComingSoonIcon;
