import React from 'react';
import { View, StyleSheet } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import { Avatar } from 'design-system/avatar';
import useColors from 'hooks/useColors';
import { useAppSelector } from 'store/hooks';
import { selectUser } from 'reducers/selectors';
const strings = {
    headerTitle: 'Edit Profile',
};
export const Header = () => {
    const colors = useColors();
    const { firstName, lastName, userImage } = useAppSelector(selectUser);
    return (<View style={styles.headerContainer}>
      <Text Text-32>{strings.headerTitle}</Text>
      <Avatar primary name={firstName} size={56} lastName={lastName} fontColor={colors.text.secondary} imageUrl={userImage?.url} color={colors.background.transparent} borderWidth={2} style={{
            borderColor: userImage
                ? colors.borders.iconStroke
                : colors.text.secondary,
        }}/>
    </View>);
};
const styles = StyleSheet.create({
    headerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: rem(16),
        paddingVertical: rem(20),
    },
});
