import { useCallback } from 'react';
import { OnboardingFlow } from 'utils/types/navigation';
import { useMainStackNavigation } from 'utils/types/navigationV6';
const useOnboarding = () => {
    const navigation = useMainStackNavigation();
    const onboard = useCallback(() => {
        navigation.navigate('InvestSetupInvestmentAccount', {
            flow: OnboardingFlow.Wallet,
        });
    }, []);
    return onboard;
};
export default useOnboarding;
