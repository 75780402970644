import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { IconArrowBack } from 'design-system/icons';
import { rem, SettingsItemSwitch, SettingsItemTitle } from 'design-system';
import { RollingBudgetsTooltip, } from './RollingBudgetsTooltip';
import { isWeb } from '../../../constants';
export const RollingBudgetsSwitchCard = memo(({ value, onValueChange, onModalShown, onModalHidden }) => (<SettingsItemSwitch value={value} onValueChange={onValueChange} icon={<IconArrowBack />} style={styles.container} title={<View style={styles.textWithIconContainer}>
          <SettingsItemTitle>Rolling Budgets</SettingsItemTitle>
          <View style={styles.infoIconContainer}>
            <RollingBudgetsTooltip onModalShown={onModalShown} onModalHidden={onModalHidden}/>
          </View>
        </View>}/>));
const styles = StyleSheet.create({
    container: {
        ...(isWeb && {
            paddingHorizontal: rem(0),
        }),
    },
    textWithIconContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    infoIconContainer: {
        marginLeft: rem(10),
    },
});
