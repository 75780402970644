import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconSun = ({ ...props }) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="plus">
      <Path d="M12 6.484a5.516 5.516 0 1 0 0 11.032 5.516 5.516 0 0 0 0-11.032Z" fill={`url(#gradient_${id})`}/>
      <Path fillRule="evenodd" clipRule="evenodd" d="M12 3c.481 0 .871.39.871.871v.067a.871.871 0 0 1-1.742 0V3.87c0-.481.39-.871.871-.871ZM5.578 5.578a.871.871 0 0 1 1.231 0l.106.105a.871.871 0 0 1-1.232 1.232l-.105-.106a.871.871 0 0 1 0-1.231Zm12.844 0c.34.34.34.891 0 1.231l-.105.106a.871.871 0 0 1-1.232-1.232l.106-.105a.871.871 0 0 1 1.231 0ZM3 12c0-.481.39-.871.871-.871h.067a.871.871 0 0 1 0 1.742H3.87A.871.871 0 0 1 3 12Zm16.191 0c0-.481.39-.871.871-.871h.067a.871.871 0 1 1 0 1.742h-.067a.871.871 0 0 1-.87-.871Zm-2.106 5.085a.87.87 0 0 1 1.232 0l.105.105a.871.871 0 0 1-1.231 1.232l-.106-.105a.87.87 0 0 1 0-1.232Zm-10.17 0a.87.87 0 0 1 0 1.232l-.106.105a.87.87 0 1 1-1.231-1.231l.105-.106a.87.87 0 0 1 1.232 0ZM12 19.191c.481 0 .871.39.871.871v.067a.871.871 0 1 1-1.742 0v-.067c0-.48.39-.87.871-.87Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
