import { useCallback, useEffect, useRef, useState } from 'react';
import { fetchAnalyticsBudgetingData, fetchMoreAnalyticsBudgetingData, fetchCategoriesMerchants, } from 'actions';
import { useAppDispatch, useAppSelector, useAppStore } from 'store/hooks';
import { selectIsFetchingTotals, selectIsSwitchingTotalsStep, } from '../selectors';
let MOUNTED = false;
export const useSyncMonthlyTotals = (position) => {
    const store = useAppStore();
    const dispatch = useAppDispatch();
    const [lastPos, setLastPos] = useState(position);
    const fetchingTotals = useAppSelector(selectIsFetchingTotals);
    const switchingTotalsStep = useAppSelector(selectIsSwitchingTotalsStep);
    // We only want one of these hooks to run in one mounted component, it may be mounted on multiple screens
    const isMounted = useRef(MOUNTED).current;
    useEffect(() => {
        if (!isMounted) {
            MOUNTED = true;
            dispatch(fetchAnalyticsBudgetingData());
        }
        if (MOUNTED) {
            return () => {
                MOUNTED = false;
            };
        }
        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (position !== lastPos) {
            setLastPos(position);
            dispatch(fetchCategoriesMerchants());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [position]);
    const onEndReached = useCallback(() => {
        const fetchingTotals = selectIsFetchingTotals(store.getState());
        if (fetchingTotals)
            return;
        dispatch(fetchMoreAnalyticsBudgetingData());
    }, []);
    return [onEndReached, { totals: fetchingTotals, switchingTotalsStep }];
};
