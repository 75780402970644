import * as React from 'react';
import { StyleSheet } from 'react-native';
import useColors from 'hooks/useColors';
import Text from './Text';
import Button from './Button';
import Spacer from './Spacer';
import { rem } from './values';
import Squircle from './Squircle';
import { CardView } from './CardView';
const squircleSize = rem(64);
const EmptyStateCard = ({ Icon, title, onPress, btnTitle, subTitle, }) => {
    const colors = useColors();
    return (<CardView style={styles.container} noMarginTop noMarginBottom>
      <Squircle width={squircleSize} height={squircleSize} color={colors.background.dark}>
        {Icon}
      </Squircle>
      <Spacer h={16}/>
      <Text Text-16 centered>
        {title}
      </Text>
      <Spacer h={4}/>
      <Text TextThin-14 Secondary centered>
        {subTitle}
      </Text>
      <Spacer h={16}/>
      <Button hug small brandReversed title={btnTitle} onPress={onPress} containerStyle={styles.button}/>
    </CardView>);
};
export default React.memo(EmptyStateCard);
const styles = StyleSheet.create({
    container: {
        padding: rem(24),
        alignItems: 'center',
    },
    button: {
        paddingHorizontal: rem(24),
    },
});
