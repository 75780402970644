import { CALL_API } from 'middleware/api';
import * as types from './types/twitter';
export const getTwitterData = (handle) => ({
    [CALL_API]: {
        endpoint: `/twitter-data/${handle}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_TWITTER_HANDLE_DATA_REQUEST,
            types.GET_TWITTER_HANDLE_DATA_SUCCESS,
            types.GET_TWITTER_HANDLE_DATA_FAILURE,
        ],
    },
});
