import React, { forwardRef, useCallback } from 'react';
import { TextInputMask } from 'react-native-masked-text';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
const RNMaskedText = forwardRef(({ style, onBlur, onFocus, focusStyle, ...props }, ref) => {
    const styles = useStyles(styleSet);
    const [IsFocused, setIsFocused] = React.useState(false);
    const handleEvent = useCallback((event) => {
        if (props.editable !== false) {
            setIsFocused(event === 'focus');
        }
    }, [props.editable]);
    const handleFocus = useCallback((e) => {
        handleEvent('focus');
        onFocus?.(e);
    }, [handleEvent, onFocus]);
    const handleBlur = useCallback((e) => {
        handleEvent('blur');
        onBlur?.(e);
    }, [handleEvent, onBlur]);
    return (<TextInputMask ref={ref} onBlur={handleBlur} onFocus={handleFocus} style={[
            styles.textInput,
            IsFocused && styles.focusedStyles,
            style,
            IsFocused && focusStyle,
        ]} {...props}/>);
});
const styleSet = createStyleSheets((colors) => ({
    textInput: {
        fontSize: rem(16),
        textAlign: 'left',
        borderWidth: rem(1),
        borderRadius: rem(8),
        paddingVertical: rem(8),
        paddingHorizontal: rem(16),
        fontFamily: 'Montserrat-Regular',
        caretColor: colors.text.selection,
        borderColor: colors.borders.divider,
        backgroundColor: colors.cards.onDark,
    },
    focusedStyles: {
        borderColor: colors.elements.brand,
    },
}));
export default React.memo(RNMaskedText);
