import React, { memo } from 'react';
import { View, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import useHeaderHeight from 'hooks/useHeaderHeight';
const SafeAreaTop = ({ children, style, excludeNavHeader, }) => {
    const { top } = useSafeAreaInsets();
    const headerHeight = useHeaderHeight();
    return (<View style={[
            styles.container,
            {
                marginTop: excludeNavHeader ? headerHeight : top,
            },
            style,
        ]}>
      {children}
    </View>);
};
export default memo(SafeAreaTop);
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
});
