import { useEffect } from 'react';
import { getIncomes } from 'actions';
import { useAppDispatch } from 'store/hooks';
import { getBudgets } from '../actions';
export const useLoadBudgetsAndIncomes = (load = true) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (load) {
            dispatch(getBudgets());
            dispatch(getIncomes());
        }
    }, [dispatch]);
};
