import { select, takeEvery } from 'typed-redux-saga/macro';
import { CHANGE_DATE_TRANSACTION_SUCCESS, CREATE_TRANSACTION_SUCCESS, DELETE_CATEGORY_SUCCESS, DELETE_TRANSACTION_SUCCESS, EDIT_CATEGORY_SUCCESS, RENAME_TRANSACTION_SUCCESS, SAVE_NOTE_SUCCESS, SET_TAGS_SUCCESS, SPLIT_TRANSACTION_SUCCESS, UNSPLIT_TRANSACTION_SUCCESS, UPDATE_CATEGORY_SUCCESS, } from 'actions/types';
import { putEventBusEvent } from 'hooks/eventBus';
export const REFRESH_FILTER = 'REFRESH_FILTER';
function* refreshFilter() {
    const isActive = yield* select((store) => store.smartRules.isFiltersActive);
    if (isActive) {
        yield putEventBusEvent(REFRESH_FILTER, undefined);
    }
}
function* smartRulesSaga() {
    yield* takeEvery((action) => [
        DELETE_TRANSACTION_SUCCESS,
        UNSPLIT_TRANSACTION_SUCCESS,
        SPLIT_TRANSACTION_SUCCESS,
        CHANGE_DATE_TRANSACTION_SUCCESS,
        EDIT_CATEGORY_SUCCESS,
        RENAME_TRANSACTION_SUCCESS,
        SAVE_NOTE_SUCCESS,
        CREATE_TRANSACTION_SUCCESS,
        DELETE_CATEGORY_SUCCESS,
        UPDATE_CATEGORY_SUCCESS,
        SET_TAGS_SUCCESS,
    ].includes(action.type), refreshFilter);
}
export default smartRulesSaga;
