import { setPasscode, setPasscodeInKeychainSuccess, } from 'actions';
import { selectPasscode } from 'reducers/selectors';
import { call, put, putResolve, select } from 'typed-redux-saga/macro';
import { migratePassCode } from 'utils/passcode';
import Sentry from 'utils/sentry';
export function* migratePassCodeSaga(user) {
    try {
        const passCode = yield* select(selectPasscode);
        const needsMigration = yield* call(migratePassCode, passCode, user.id.toString(), user.pinLastChangedAt);
        if (needsMigration) {
            // This will send the passcode to the API and remove it from redux
            const result = (yield* putResolve(setPasscode(needsMigration)));
            if (result?.payload) {
                // Update last changed at local IF it was stored successfully
                yield* put(setPasscodeInKeychainSuccess(result.payload.pinLastChangedAt));
            }
        }
    }
    catch (err) {
        Sentry.logError({
            filename: 'Navigator.js',
            err,
            message: 'Could not migrate passcode',
        });
    }
}
