import { createEventBusModule } from 'hooks/eventBus';
import { createApiModule } from 'hooks/fetch';
const MODULE_NAME = 'Payment';
const createApiModuleActions = createApiModule(MODULE_NAME);
const createEventBusModuleEvent = createEventBusModule(MODULE_NAME);
// Reducers
const PENDING_PAYMENTS = 'pendingPayments';
const THANKED_PAYMENT = 'thankedPayment';
const REMINDED_REQUEST = 'remindedRequest';
const reducers = {
    PENDING_PAYMENTS,
    THANKED_PAYMENT,
    REMINDED_REQUEST,
};
// Api actions
const completedPayments = createApiModuleActions('COMPLETED_PAYMENTS');
const pendingPayments = createApiModuleActions('PENDING_PAYMENTS');
const paymentLink = createApiModuleActions('PAYMENT_LINK');
const paymentLinksByNamesAndHandles = createApiModuleActions('PAYMENT_LINKS_BY_NAMES_AND_HANDLES');
const deleteBillSharingForTransaction = createApiModuleActions('DELETE_BILL_SHARING_FOR_TRANSACTION');
const paymentSend = createApiModuleActions('PAYMENT_SEND');
const paymentInfo = createApiModuleActions('PAYMENT_INFO');
const paymentRequestInfo = createApiModuleActions('PAYMENT_REQUEST_INFO');
const paymentDefaultAccount = createApiModuleActions('PAYMENT_DEFAULT_ACCOUNT');
const paymentRequestFulfill = createApiModuleActions('PAYMENT_REQUEST_FULFILL');
const paymentRequestCancel = createApiModuleActions('PAYMENT_REQUEST_CANCEL');
const paymentSendThanks = createApiModuleActions('PAYMENT_SEND_THANKS');
const paymentRequestRemind = createApiModuleActions('PAYMENT_REQUEST_REMIND');
const handleInfo = createApiModuleActions('HANDLE_INFO');
const apiEvents = {
    completedPayments,
    pendingPayments,
    paymentLink,
    paymentLinksByNamesAndHandles,
    deleteBillSharingForTransaction,
    paymentSend,
    paymentInfo,
    paymentRequestInfo,
    paymentDefaultAccount,
    paymentRequestFulfill,
    paymentRequestCancel,
    paymentSendThanks,
    paymentRequestRemind,
    handleInfo,
};
// Bus events
const PAYMENT_SENT = createEventBusModuleEvent('PAYMENT_SENT');
const REQUEST_CREATED = createEventBusModuleEvent('REQUEST_CREATED');
const REQUEST_FULFILLED = createEventBusModuleEvent('REQUEST_FULFILLED');
const REQUEST_CANCELED = createEventBusModuleEvent('REQUEST_CANCELED');
const REQUEST_REJECTED = createEventBusModuleEvent('REQUEST_REJECTED');
const busEvents = {
    PAYMENT_SENT,
    REQUEST_CREATED,
    REQUEST_FULFILLED,
    REQUEST_CANCELED,
    REQUEST_REJECTED,
};
export { apiEvents, busEvents, reducers };
export default { apiEvents, busEvents, reducers };
