import React, { useEffect, useRef, useState, memo } from 'react';
import { Animated, TouchableOpacity, View, } from 'react-native';
import RNAnimated from 'react-native-reanimated';
import createStyleSheets from 'utils/createStyleSheets';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import Text from './Text';
import { rem } from './values';
export const Selector = ({ sections, onSelected, style, selectorStyle, selectedTextColor, unselectedTextColor, ...fontStyle }) => {
    const [selection, setSelection] = useState(0);
    const [width, setWidth] = useState(320);
    const [itemWidth, setItemWidth] = useState(320 / sections.length);
    const left = useRef(new Animated.Value(0)).current;
    const onLayout = (event) => {
        if (event.nativeEvent.layout.width) {
            setWidth(event.nativeEvent.layout.width);
        }
    };
    useEffect(() => {
        if (onSelected)
            onSelected(selection);
    }, [onSelected, selection]);
    useEffect(() => {
        setItemWidth((width - rem(4)) / sections.length);
    }, [sections, width]);
    useEffect(() => {
        const animation = Animated.timing(left, {
            toValue: itemWidth * selection,
            delay: 0,
            duration: 100,
            useNativeDriver: true,
        });
        animation.start();
    }, [sections, selection, itemWidth, left]);
    const styles = useStyles(styleSet);
    const colors = useColors();
    return (<RNAnimated.View style={[styles.container, style]} onLayout={onLayout}>
      <Animated.View style={[
            styles.selector,
            selectorStyle,
            {
                width: itemWidth,
                transform: [
                    {
                        translateX: left,
                    },
                ],
            },
        ]}/>
      {sections.map((value, index) => index === selection ? (<View key={`${value}#${index * 2}`} style={[styles.item, { width: itemWidth }]}>
            <Text {...fontStyle} color={selectedTextColor || colors.text.white}>
              {value}
            </Text>
          </View>) : (<TouchableOpacity key={`${value}#${index * 2}`} style={[styles.item, { width: itemWidth }]} onPress={() => setSelection(index)}>
            <Text {...fontStyle} color={unselectedTextColor || colors.text.secondary}>
              {value}
            </Text>
          </TouchableOpacity>))}
    </RNAnimated.View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        width: '100%',
        height: rem(40),
        borderRadius: rem(20),
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: colors.background.transparent,
    },
    selector: {
        position: 'absolute',
        left: rem(2),
        height: rem(36),
        borderRadius: rem(28),
        backgroundColor: colors.background.light,
    },
    item: {
        height: rem(36),
        borderRadius: rem(28),
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
export default memo(Selector);
