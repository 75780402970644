import { startOfMonth, startOfQuarter, startOfYear, parseISO, formatISO, } from 'date-fns';
import React, { memo, useCallback, useMemo } from 'react';
import { InteractionManager, View } from 'react-native';
import { rem } from 'design-system/values';
import ToolTip from 'design-system/ToolTip';
import useAnalyticsFilter from 'design-system/analyticsFilter';
import MultiSelectAccountModal from 'design-system/MultiSelectAccountModal';
import { getAccountsTitle } from 'design-system/MultiSelectAccountModal/Content';
import useStyles from 'hooks/useStyles';
import useAppRating from 'hooks/useAppRating';
import { selectListAccounts } from 'reducers/selectors';
import createStyleSheets from 'utils/createStyleSheets';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { filterChanged, resetAnalyticsFilter } from 'actions';
import { formatMonth, formatPeriod } from 'utils/formatting';
import AnalyticsControlsChip from './AnalyticsControlsChip';
const mapFilterTypeToStep = {
    week: 'isoWeek',
    month: 'month',
    quarter: 'quarter',
    year: 'year',
    custom: 'custom',
};
const getFromFormatted = (args) => {
    switch (args.type) {
        case 'week':
        case 'custom':
            return args.from;
        case 'month':
            return startOfMonth(new Date(args.year, args.month - 1));
        case 'quarter':
            return startOfQuarter(new Date(args.year, (args.quarter - 1) * 3, 1));
        case 'year':
            return startOfYear(new Date(args.year, 0, 1));
        default:
            return new Date();
    }
};
const budgetingPeriodToolTip = (<ToolTip title="Budgeting period" subtitle="This is the period you have selected when setting up budgeting. All your budgets will sync to this. If you want to change it, just tap on the edit icon on the top right of the screen." small/>);
const AnalyticsControls = ({ month, onLight, enableFilter, payPeriod, step: currentStep, accountIds: currentAccountIds, }) => {
    const title = useMemo(() => {
        if (month) {
            return formatPeriod(month);
        }
        // month may have not yet loaded
        if (payPeriod) {
            return formatPeriod(payPeriod);
        }
        return formatMonth(startOfMonth(new Date()));
    }, [month, payPeriod]);
    const styles = useStyles(styleSet);
    const dispatch = useAppDispatch();
    const [show, hide] = useAnalyticsFilter();
    const rateApp = useAppRating();
    const onSave = useCallback((response) => {
        hide();
        rateApp();
        InteractionManager.runAfterInteractions(() => {
            const step = mapFilterTypeToStep[response.type];
            dispatch(filterChanged(step, formatISO(getFromFormatted(response)), (response.type === 'custom'
                ? response.to
                : new Date()).toISOString(), currentAccountIds));
        });
    }, [currentAccountIds, hide, rateApp]);
    const onReset = useCallback(() => {
        hide();
        InteractionManager.runAfterInteractions(() => {
            dispatch(resetAnalyticsFilter());
        });
    }, [hide]);
    const onPressFilter = () => {
        if (!enableFilter)
            return;
        const from = parseISO(month?.from ?? '');
        show(onSave, currentStep !== undefined &&
            ((currentStep === 'month' && payPeriod !== undefined) ||
                currentStep !== 'month')
            ? onReset
            : undefined, month
            ? {
                weeklyRange: currentStep === 'isoWeek'
                    ? [from, parseISO(month.to)]
                    : undefined,
                monthly: currentStep === 'month' || (!currentStep && !payPeriod)
                    ? {
                        currentMonth: from.getMonth(),
                        currentYear: from.getFullYear(),
                    }
                    : undefined,
                quarterly: currentStep === 'quarter'
                    ? {
                        currentQuarter: Math.floor(from.getMonth() / 3) + 1,
                        currentYear: from.getFullYear(),
                    }
                    : undefined,
                yearly: currentStep === 'year' ? from.getFullYear() : undefined,
                custom: currentStep === 'custom' ? [from, parseISO(month.to)] : undefined,
            }
            : undefined);
    };
    const accounts = useAppSelector(selectListAccounts);
    const accountsWithoutHidden = useMemo(() => {
        const filtered = accounts.filter((acc) => !acc.isHidden);
        return filtered;
    }, [accounts]);
    const onSelectedAccounts = useCallback(async (selectedAccounts) => {
        const selectedAll = accountsWithoutHidden.length === selectedAccounts.length;
        const hasFilter = !selectedAll || currentStep;
        try {
            if (hasFilter) {
                dispatch(filterChanged(currentStep, formatISO(month?.from ?? new Date()), currentStep === 'custom' && month?.to
                    ? formatISO(month.to)
                    : formatISO(new Date()), selectedAll ? undefined : selectedAccounts));
            }
            else {
                dispatch(resetAnalyticsFilter());
            }
        }
        catch (e) {
            // noop
        }
    }, [accountsWithoutHidden.length, currentStep, month?.from, month?.to]);
    const alreadySelectedAccounts = useMemo(() => {
        /* No accounts means they have everything selected */
        if (currentAccountIds) {
            return currentAccountIds;
        }
        return accountsWithoutHidden.map((acc) => acc.id);
    }, [currentAccountIds, accountsWithoutHidden]);
    const onPressAccountsFilter = useCallback(() => {
        if (accounts.length) {
            MultiSelectAccountModal.show({
                accounts,
                alreadySelected: alreadySelectedAccounts,
                onSelected: onSelectedAccounts,
                showSelectAll: true,
            });
        }
    }, [accounts, alreadySelectedAccounts, onSelectedAccounts]);
    return (<View style={styles.container}>
      <AnalyticsControlsChip title={title ?? ''} onLight={onLight} disabled={!enableFilter} onPress={onPressFilter} toolTip={!enableFilter && budgetingPeriodToolTip}/>
      {enableFilter && (<AnalyticsControlsChip title={getAccountsTitle(currentAccountIds)} onLight={onLight} onPress={onPressAccountsFilter}/>)}
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        marginHorizontal: rem(16),
        marginTop: rem(16),
        alignItems: 'center',
        flexDirection: 'row',
    },
    chip: {
        backgroundColor: colors.cards.onDark,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: rem(8),
        paddingHorizontal: rem(16),
        paddingVertical: rem(6),
        minHeight: rem(31),
    },
    chipDisabled: {
        backgroundColor: colors.cards.onDark,
    },
    arrowContainer: {
        flexDirection: 'row',
    },
    arrowChip: {
        minWidth: rem(31),
        paddingVertical: 0,
        paddingHorizontal: 0,
    },
    rightArrow: {
        marginStart: rem(8),
    },
    dateArrowIcon: {
        marginLeft: rem(8),
    },
}));
export default memo(AnalyticsControls);
