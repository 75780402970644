import React, { useCallback } from 'react';
import { SettingsItemNavigate } from 'design-system/SettingsItem';
import SectionHeader from 'design-system/SectionHeader';
import Text from 'design-system/Text';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import IconNote from '../../icons/IconNote';
import { styles } from './styles';
const TransactionNotesItem = ({ transaction }) => {
    const navigation = useMainStackNavigation();
    const onAddNote = useCallback(() => {
        navigation.navigate('AddNote', {
            id: transaction.id,
        });
    }, [transaction.id]);
    return transaction.notes ? (<SettingsItemNavigate onPress={onAddNote}>
      <SectionHeader onLight style={styles.itemWithContentTitle}>
        Note
      </SectionHeader>
      <Text TextThin-16>{transaction.notes}</Text>
    </SettingsItemNavigate>) : (<SettingsItemNavigate onPress={onAddNote} icon={<IconNote />} title="Add note"/>);
};
export default TransactionNotesItem;
