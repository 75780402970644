import React, { memo, useCallback, useMemo } from 'react';
import OverviewSection from 'design-system/OverviewSection';
import { sections } from 'features/accounts/constants';
import { selectTotalNetworthBalance } from 'features/accounts/selectors';
import useColors from 'hooks/useColors';
import * as selectors from 'reducers/selectors';
import { useAppSelector } from 'store/hooks';
import OneMillion from '../OneMillion';
import BankLoginsHelp from './BankLoginsHelp';
import NetworthSpacingView from './NetworthSpacingView';
const NetWorthMainSection = ({ onPressAssets, onPressDebt, blurValues, }) => {
    const colors = useColors();
    const feed = useAppSelector(selectors.selectFeed);
    const zero = useAppSelector(selectors.selectDefaultZeroAmount);
    const totalNetWorth = useAppSelector(selectTotalNetworthBalance);
    const overviewSectionItems = useMemo(() => {
        const netWorth = feed.cards.overview?.netWorth ?? zero;
        const debt = feed.cards.overview?.totalDebt ?? zero;
        const assets = {
            amount: totalNetWorth.amount + debt.amount,
            currency: netWorth.currency,
        }; // TODO: make sure they are of same currency
        const items = [
            {
                title: sections.assets,
                color: colors.text.income,
                amount: assets,
                blurValue: blurValues,
            },
            {
                title: sections.debt,
                color: colors.text.debt,
                amount: debt,
                blurValue: blurValues,
            },
            {
                title: sections.netWorth,
                color: colors.text.brand,
                amount: totalNetWorth,
                blurValue: blurValues,
            },
        ];
        return items;
    }, [
        blurValues,
        colors.text.brand,
        colors.text.debt,
        colors.text.income,
        feed.cards.overview?.netWorth,
        feed.cards.overview?.totalDebt,
        totalNetWorth,
        zero,
    ]);
    const handlePress = useCallback((section) => {
        if (section === 'Assets') {
            onPressAssets();
        }
        else if (section === 'Debt') {
            onPressDebt();
        }
    }, [onPressAssets, onPressDebt]);
    return (<>
      <NetworthSpacingView />
      <OverviewSection items={overviewSectionItems} onPress={handlePress}/>
      <OneMillion />
      <BankLoginsHelp />
    </>);
};
export default memo(NetWorthMainSection);
