import { FORCE_SHOW_DEMO_REPORT_IN_FEED } from 'config/debug';
import { CALL_API } from '../middleware/api';
import * as types from './types';
export const getReport = (id) => ({
    [CALL_API]: {
        endpoint: FORCE_SHOW_DEMO_REPORT_IN_FEED && __DEV__
            ? '/reports/demo/WEEKLY/v2'
            : `/reports/${id}`,
        method: 'GET',
        types: [
            types.GET_REPORT_REQUEST,
            types.GET_REPORT_SUCCESS,
            types.GET_REPORT_FAILURE,
        ],
    },
});
export const dismissReport = (id) => ({
    [CALL_API]: {
        endpoint: `/reports/${id}/dismissed`,
        method: 'POST',
        types: [
            types.DISMISS_REPORT_REQUEST,
            types.DISMISS_REPORT_SUCCESS,
            types.DISMISS_REPORT_FAILURE,
        ],
    },
});
export const onReportFinished = (id) => ({
    [CALL_API]: {
        endpoint: `/reports/${id}/finished`,
        method: 'POST',
        types: [
            types.ON_REPORT_FINISHED_REQUEST,
            types.ON_REPORT_FINISHED_SUCCESS,
            types.ON_REPORT_FINISHED_FAILURE,
        ],
    },
});
export const onReportOpened = (id) => ({
    [CALL_API]: {
        endpoint: `/reports/${id}/opened`,
        method: 'POST',
        types: [
            types.ON_REPORT_OPENED_REQUEST,
            types.ON_REPORT_OPENED_SUCCESS,
            types.ON_REPORT_OPENED_FAILURE,
        ],
    },
});
export const onReportSwiped = (id, cardId) => ({
    [CALL_API]: {
        endpoint: `/reports/${id}/cards/${cardId}/swiped`,
        method: 'POST',
        types: [
            types.ON_REPORT_SWIPED_REQUEST,
            types.ON_REPORT_SWIPED_SUCCESS,
            types.ON_REPORT_SWIPED_FAILURE,
        ],
    },
});
