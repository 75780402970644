import React, { useId } from 'react';
import { ClipPath, Defs, G, Path, Rect, Svg } from 'react-native-svg';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import { Icon, IconWithGradient } from './Icon';
export const IconCloseSquircle = ({ color, crossColor, alt, alt2, width = rem(24), height = rem(24), ...props }) => {
    const colors = useColors();
    const fill = color || colors.gradients.red.colors[0];
    const crossColorFill = crossColor || colors.background.dark;
    const id = useId();
    if (alt2) {
        return (<IconWithGradient id={id} width={16} height={16} viewBox="0 0 16 16" defaultGradientKey="ultimate">
        <Path d="M14.4399 9.6948C14.1298 12.1757 12.1756 14.1299 9.69468 14.44V14.44C8.56924 14.5807 7.43067 14.5807 6.30523 14.44V14.44C3.82428 14.1299 1.87013 12.1757 1.56001 9.6948V9.6948C1.41933 8.56936 1.41933 7.43079 1.56001 6.30535V6.30535C1.87013 3.82441 3.82428 1.87025 6.30523 1.56013V1.56013C7.43067 1.41945 8.56924 1.41945 9.69468 1.56013V1.56013C12.1756 1.87025 14.1298 3.82441 14.4399 6.30536V6.30536C14.5806 7.43079 14.5806 8.56936 14.4399 9.6948V9.6948Z" fill={`url(#gradient_${id})`}/>
        <Path fillRule="evenodd" clipRule="evenodd" d="M5.77759 5.77771L10.222 10.2222Z" fill={colors.elements.onLight}/>
        <Path d="M5.77759 5.77771L10.222 10.2222" stroke={colors.elements.onLight} strokeWidth="2.22222" strokeLinecap="round"/>
        <Path fillRule="evenodd" clipRule="evenodd" d="M10.222 5.77771L5.77759 10.2222Z" fill={colors.elements.onLight}/>
        <Path d="M10.222 5.77771L5.77759 10.2222" stroke={colors.elements.onLight} strokeWidth="2.22222" strokeLinecap="round"/>
      </IconWithGradient>);
    }
    return alt ? (<Svg width={width} height={height} viewBox="0 0 16 17" fill="none">
      <Path fillRule="evenodd" clipRule="evenodd" d="M6.01983 2.72913C7.32227 2.42362 8.67773 2.42362 9.98017 2.72913C11.8611 3.17033 13.3297 4.63894 13.7709 6.51983C14.0764 7.82227 14.0764 9.17773 13.7709 10.4802C13.3297 12.3611 11.8611 13.8297 9.98017 14.2709C8.67773 14.5764 7.32227 14.5764 6.01983 14.2709C4.13895 13.8297 2.67033 12.3611 2.22913 10.4802C1.92362 9.17773 1.92362 7.82227 2.22913 6.51983C2.67033 4.63894 4.13894 3.17033 6.01983 2.72913ZM7.14258 7.10105C6.99304 6.95151 6.75059 6.95151 6.60105 7.10105C6.45152 7.25059 6.45152 7.49304 6.60105 7.64257L7.45846 8.49999L6.60105 9.3574C6.45152 9.50693 6.45152 9.74938 6.60105 9.89892C6.75059 10.0485 6.99304 10.0485 7.14258 9.89892L7.99999 9.04151L8.8574 9.89892C9.00694 10.0485 9.24938 10.0485 9.39892 9.89892C9.54846 9.74938 9.54846 9.50693 9.39892 9.3574L8.54151 8.49999L9.39892 7.64257C9.54846 7.49304 9.54846 7.25059 9.39892 7.10105C9.24938 6.95151 9.00694 6.95151 8.8574 7.10105L7.99999 7.95846L7.14258 7.10105Z" fill={fill}/>
    </Svg>) : (<Icon {...props} scale={1}>
      <G clipPath="url(#clip0_9765_17332)">
        <Path fillRule="evenodd" clipRule="evenodd" d="M14.9703 3.3437C13.0166 2.88543 10.9834 2.88543 9.02975 3.3437C6.20842 4.00549 4.0055 6.20841 3.3437 9.02975C2.88543 10.9834 2.88543 13.0166 3.3437 14.9703C4.0055 17.7916 6.20842 19.9945 9.02975 20.6563C10.9834 21.1146 13.0166 21.1146 14.9703 20.6563C17.7916 19.9945 19.9945 17.7916 20.6563 14.9703C21.1146 13.0166 21.1146 10.9834 20.6563 9.02975C19.9945 6.20842 17.7916 4.00549 14.9703 3.3437Z" fill={fill}/>
        <Rect x="15.9844" y="9.41504" width="10" height="2" rx="1" transform="rotate(135 15.9844 9.41504)" fill={crossColorFill}/>
        <Rect x="14.5703" y="16.4854" width="10" height="2" rx="1" transform="rotate(-135 14.5703 16.4854)" fill={crossColorFill}/>
      </G>
      <Defs>
        <ClipPath id="clip0_9765_17332">
          <Rect width="24" height="24" fill={crossColorFill}/>
        </ClipPath>
      </Defs>
    </Icon>);
};
export default IconCloseSquircle;
