import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from './Icon';
const IconBlock = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="red">
      <Path fillRule="evenodd" clipRule="evenodd" d="M7.4675 15.1172L15.1172 7.4675C12.9745 5.99022 10.0171 6.20469 8.11091 8.11091C6.20469 10.0171 5.99022 12.9745 7.4675 15.1172ZM8.88152 16.5316C11.0244 18.0099 13.9825 17.7957 15.8891 15.8891C17.7957 13.9825 18.0099 11.0244 16.5316 8.88152L8.88152 16.5316ZM17.6569 17.6569C14.5327 20.781 9.46734 20.781 6.34315 17.6569C3.21895 14.5327 3.21895 9.46734 6.34315 6.34315C9.46734 3.21895 14.5327 3.21895 17.6569 6.34315C20.781 9.46734 20.781 14.5327 17.6569 17.6569Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default IconBlock;
