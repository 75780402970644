import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import { InteractionManager } from 'react-native';
import { AppView } from 'design-system/AppView';
import { HelpNavigationButton } from 'design-system/TextButton';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import Modal from 'utils/packages/Modal';
import { useAppDispatch } from 'store/hooks';
import { getCategories } from 'actions/user';
import useHelpActionSheet from 'hooks/useHelpActionSheet';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import { isWeb } from '../../../constants';
import EditCategoryVerticalList from '../components/categories/EditCategoryVerticalList';
const ManageCategoriesScreen = withAnimatedNavigationHeader(({ scrollHandler, navigation, route }) => {
    const showHelpActionSheet = useHelpActionSheet({
        showVisitHelpCentre: true,
    });
    useLayoutEffect(() => {
        if (!isWeb) {
            navigation.setOptions({
                headerRight: () => (<HelpNavigationButton black onPress={showHelpActionSheet}/>),
            });
        }
    }, [showHelpActionSheet]);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!route.params?.skipRefresh) {
            InteractionManager.runAfterInteractions(() => {
                dispatch(getCategories());
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { isUnlockedForSpace, onPressUpgrade } = useShouldShowUpgrade({
        benefitId: 'customCategories',
    });
    const onPressCategory = useCallback((category, isDefaultCategory) => {
        if (!isUnlockedForSpace) {
            onPressUpgrade();
            return;
        }
        if (isDefaultCategory) {
            Modal.showConfirmation('Default categories can’t be modified', 'Default categories come pre-defined and are not editable or removable.', 'Got it');
        }
        else {
            navigation.navigate('CustomCategoryEdit', {
                category: category,
            });
        }
    }, [isUnlockedForSpace, onPressUpgrade]);
    return (<AppView type="secondary" withFrame={false}>
        <EditCategoryVerticalList scrollHandler={scrollHandler} onPressCategory={onPressCategory}/>
      </AppView>);
}, ({ colors }) => ({
    title: 'Manage custom categories',
    hideTitleOnScroll: true,
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
export default ManageCategoriesScreen;
