import { useCallback, useEffect, useRef } from 'react';
import { isUnlocked } from 'features/premium/iap';
import Modal from 'design-system/Modal';
import { UpgradeWebModal } from 'design-system/UpgradeWebModal';
import { useFocusEffect, useGetIsKeyboardOpen } from 'hooks';
import { InteractionManager, Keyboard } from 'react-native';
import { selectFeatureFlags } from 'reducers/selectors';
import { useAppSelector, useAppStore } from 'store/hooks';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { checkIsBenefitIdAvailableOnFree } from '../checkIsBenefitIdAvailableOnFree';
import { Tier } from '../entities';
import { isWeb } from '../../../constants';
import { selectUserTier } from '../selectors/tier';
const KEYBOARD_CLOSE_DELAY = 250;
export const useRequiredTierCallback = (cb, tier, validIf, benefitId, hideModal, skipTrialScreen, upsellTrial) => {
    const userTier = useAppSelector(selectUserTier);
    const pendingCallback = useRef();
    // Do this on focus to allow the premium flow to exit with pop before this callback (in case we navigate in the cb here)
    useFocusEffect(useCallback(() => {
        if (pendingCallback.current && tier && isUnlocked(userTier, tier)) {
            pendingCallback.current();
            pendingCallback.current = undefined;
        }
    }, [tier, userTier]));
    const navigation = useMainStackNavigation();
    const navigationRef = useRef(navigation);
    navigationRef.current = navigation;
    const getIsKeyboardOpen = useGetIsKeyboardOpen();
    const cancelFn = useRef(undefined);
    const timeoutRef = useRef();
    useEffect(() => () => {
        cancelFn.current?.();
        clearTimeout(timeoutRef.current);
    }, []);
    const store = useAppStore();
    return useCallback((...args) => {
        const t = tier;
        const isAvailableByFeatureFlag = benefitId
            ? checkIsBenefitIdAvailableOnFree(benefitId, store.getState().user.user.guessedHomeCountry, selectFeatureFlags(store.getState()))
            : false;
        if (t === undefined ||
            t === Tier.free ||
            isAvailableByFeatureFlag ||
            isUnlocked(userTier, t) ||
            validIf?.()) {
            cb(...args);
        }
        else {
            pendingCallback.current = () => cb(...args);
            if (hideModal) {
                Modal.hide();
            }
            const openPremiumModal = () => {
                const { cancel } = InteractionManager.runAfterInteractions(() => {
                    // Using ref to avoid returning a new callback when navigation state changes
                    if (isWeb) {
                        UpgradeWebModal.show(t);
                    }
                    else {
                        navigationRef.current?.navigate('EmmaProModal', {
                            tier: t,
                            isTierRequired: true,
                            skipTrialScreen,
                            upsellTrial,
                            benefitId,
                        });
                    }
                });
                cancelFn.current = cancel;
            };
            if (!getIsKeyboardOpen()) {
                openPremiumModal();
            }
            else {
                Keyboard.dismiss();
                timeoutRef.current = setTimeout(openPremiumModal, KEYBOARD_CLOSE_DELAY);
            }
        }
    }, [
        tier,
        benefitId,
        userTier,
        validIf,
        cb,
        hideModal,
        getIsKeyboardOpen,
        skipTrialScreen,
        upsellTrial,
    ]);
};
export default useRequiredTierCallback;
