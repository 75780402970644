import invest from './invest';
import investStockNews from './news';
import watchList from './watchList';
import collections from './collections';
import stockInfoReducer from './stockInfo';
import transferDetails from './transferDetails';
import unpersistedInvestReducer from './unpersistedInvestReducer';
export default {
    invest,
    watchList,
    collections,
    investStockNews,
    transferDetails,
    stockInfoReducer,
    unpersistedInvestReducer,
};
