import React, { useCallback, useLayoutEffect } from 'react';
import Animated from 'react-native-reanimated';
import { Button } from 'design-system/Button';
import { AppView } from 'design-system/AppView';
import { CardView } from 'design-system/CardView';
import { SettingsItem } from 'design-system/SettingsItem';
import ScreenInfoHeader from 'design-system/ScreenInfoHeader';
import { SCROLL_INDICATOR_INSETS } from 'design-system/values';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import EmptyComponent from 'design-system/EmptyComponent';
import { isWeb } from '../../../constants';
import { CHART_TYPE_ICONS } from '../components/ChartViewSwitcher';
const AnalyticsIntroScreen = withAnimatedNavigationHeader(({ navigation, scrollHandler }) => {
    const onPressSetup = useCallback(() => {
        navigation.navigate('SelectPeriod', {
            flow: 'SetAnalytics',
        });
    }, []);
    useLayoutEffect(() => {
        navigation.setOptions({
            headerTitle: EmptyComponent,
        });
    }, []);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    return (<AppView withFrame={false}>
        <Animated.ScrollView scrollEventThrottle={16} onScroll={scrollHandler} contentContainerStyle={paddingHorizontalStyle} scrollIndicatorInsets={SCROLL_INDICATOR_INSETS}>
          <ScreenInfoHeader preTitle="Welcome to analytics!" title="Visualise your spending" subTitle="Have a complete overview of your income and spending" smallSubTitle/>
          <CardView noMarginTop>
            <SettingsItem icon={CHART_TYPE_ICONS.bar} title="Income vs spending" description="Track how much you make and spend each month to maximise savings"/>
            <SettingsItem icon={CHART_TYPE_ICONS.linear} title="Spend over time" description="See how this month compares to previous and adjust your spending"/>
            <SettingsItem icon={CHART_TYPE_ICONS.pie} title="Category pie chart" description="Gain insight on how you spend with a pie chart"/>
          </CardView>
        </Animated.ScrollView>
        <Button brand floating title="Set up analytics" onPress={onPressSetup}/>
      </AppView>);
}, ({ colors }) => ({
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
    title: 'Welcome to analytics!',
    hasCustomTitle: true,
}));
export default AnalyticsIntroScreen;
