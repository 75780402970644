import { useCallback } from 'react';
import { donateShortcut } from 'utils/packages/siriShortcut';
import { analytics } from 'utils/packages/rnFirebase';
import Amplitude from 'utils/amplitude';
import Facebook from 'utils/facebook';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { subscriptionsActivityType } from '../../../../constants';
const useSeeAllSubscriptions = () => {
    const nav = useMainStackNavigation();
    const onPressSeeAll = useCallback(() => {
        Amplitude.logEvent('Feed.Subscriptions');
        Facebook.logEvent('Open All Subscriptions');
        analytics().logEvent('Open_All_Subscriptions');
        const opts = {
            activityType: subscriptionsActivityType,
            title: 'View Recurring Payments',
            keywords: ['Subscriptions', 'Recurring Payments', 'Bills'],
            persistentIdentifier: subscriptionsActivityType,
            isEligibleForSearch: true,
            isEligibleForPrediction: true,
            suggestedInvocationPhrase: 'View Recurring Payments',
        };
        donateShortcut(opts);
        nav.navigate('Subscriptions');
    }, [nav]);
    return onPressSeeAll;
};
export default useSeeAllSubscriptions;
