import _ from 'lodash';
import { AnalyticsLoadingState, } from 'reducers/types';
import { createSelector } from 'reselect';
import { isDefaultCategory } from 'hooks/useCategory';
import { sortByName } from 'utils/sort';
import { createNonMemoizedSelector } from 'store/selectors';
import { emptyArray } from '../../constants';
import { selectMonthlyTotals } from '../analytics/selectors';
export const selectUserIncomes = (state) => state.user.incomes;
export const selectBudgets = (state) => state.budgeting.budgets;
export const selectOverallBudget = (state) => {
    if (state.budgeting.budgets && state.budgeting.budgets.length) {
        return state.budgeting.budgets.find((budget) => budget.key === 'overall.monthly');
    }
    return undefined;
};
export const selectOverallBudgetBaseLimit = (state) => selectOverallBudget(state)?.baseLimit;
export const selectMonthlyCategories = (state) => state.expenses.monthlyCategories;
export const selectMonthlyMerchants = (state) => state.expenses.monthlyMerchants;
export const selectPosition = (state) => state.expenses.budgetingPosition;
export const selectIncomes = createSelector([selectUserIncomes], (incomes) => {
    const income = incomes?.find((i) => i.isPrimary);
    return income;
});
export const selectMerchantBudgets = createSelector([selectBudgets], (budgets) => budgets.filter((budget) => budget.type === 'merchant'));
const sortMonthlyTotals = (data) => [...data].sort((a, b) => {
    // Top spend is first
    if (a.total > b.total) {
        return 1;
    }
    if (a.total === b.total) {
        // If spend is equal sort by display name
        if (a.displayName < b.displayName)
            return -1;
        if (a.displayName > b.displayName)
            return 1;
        return 0;
    }
    return -1;
});
export const selectMonthlyTotalsWithBudgets = createSelector([selectMonthlyTotals], (monthlyTotals) => monthlyTotals.filter((total) => total.totalBudget));
export const selectCategoriesState = createSelector([selectMonthlyTotalsWithBudgets, selectPosition, selectMonthlyCategories], (monthlyTotalsWithBudgets, position, monthlyCategories) => monthlyTotalsWithBudgets[position]
    ? monthlyCategories[`${monthlyTotalsWithBudgets[position].from}|${monthlyTotalsWithBudgets[position].to}`]
    : undefined);
export const selectCategories = createSelector([selectCategoriesState], (categoriesState) => categoriesState?.categories
    ? sortMonthlyTotals(categoriesState.categories)
    : emptyArray);
export const selectCategoriesLoadingState = createNonMemoizedSelector([selectCategoriesState], (categoriesState) => categoriesState?.loadingState ?? AnalyticsLoadingState.INITIAL);
export const selectMerchantsState = createSelector([selectMonthlyTotalsWithBudgets, selectPosition, selectMonthlyMerchants], (monthlyTotalsWithBudgets, position, monthlyMerchants) => monthlyTotalsWithBudgets[position]
    ? monthlyMerchants[`${monthlyTotalsWithBudgets[position].from}|${monthlyTotalsWithBudgets[position].to}`]
    : undefined);
export const selectMerchants = createSelector([selectMerchantsState], (merchantsState) => merchantsState?.merchants
    ? sortMonthlyTotals(merchantsState.merchants)
    : emptyArray);
export const selectMerchantsLoadingState = createNonMemoizedSelector([selectMerchantsState], (merchantsState) => merchantsState?.loadingState ?? AnalyticsLoadingState.INITIAL);
export const selectBudgetedMerchants = createSelector([selectMerchants, selectBudgets], (merchants) => merchants.filter((item) => item.budgetLimit));
export const makeSelectCategoryTotalById = () => createSelector([selectCategories, (_, id) => id], (categories, id) => categories.find((category) => category.id === id));
export const makeSelectMerchantTotalById = () => createSelector([selectBudgetedMerchants, (_, id) => id], (merchants, id) => merchants.find((merchant) => merchant.id === id));
export const selectBudgetedCategories = createSelector([selectCategories], (categories) => categories.filter((item) => item.budgetLimit));
export const selectUnbudgetCategories = createSelector([selectCategories], (categories) => categories.filter((item) => !item.budgetLimit && item.total < 0));
const EMPTY_CATEGORIES = [];
export const selectUnbudgetedCategoriesForCurrentPeriod = createSelector([selectCategories, selectPosition], (categories, position) => position === 0
    ? categories.filter((item) => !item.budgetLimit && item.total < 0)
    : EMPTY_CATEGORIES);
export const selectCategoryBudgets = createSelector([selectBudgets], (budgets) => {
    const array = sortByName(budgets.filter((budget) => budget.type === 'category'));
    const filtered = array.filter((item) => item.key.includes('category'));
    const grouped = _.groupBy(filtered, (d) => {
        if (!isDefaultCategory(d.key.replace('category.', ''))) {
            return 'My Categories';
        }
        return 'Categories';
    });
    return (grouped['My Categories'] || []).concat(grouped.Categories || []);
});
