import { groupBy } from 'lodash';
import { getBalance } from './getBalance';
import sortAccounts from './sortAccounts';
const calcTotal = (array) => array.reduce((prev, curr) => {
    if (curr.proPreviewAccount ||
        curr.ultimatePreviewAccount ||
        curr.deactivatedOverQuota) {
        return prev;
    }
    // We dont want to use nativeBalance in this calc, it must be the balance in the users default currency
    const [primaryBalance] = getBalance(curr, false);
    return (primaryBalance?.amount ?? 0) + prev;
}, 0);
const getAccounts = (accounts) => {
    const groupedAccounts = groupBy(accounts, (account) => {
        if (account.isClosed) {
            return `${account.type}_CLOSED`;
        }
        if (account.isHidden) {
            return `${account.type}_HIDDEN`;
        }
        return account.type;
    });
    const groupAccountsByBalance = (groupedAccounts) => {
        const result = {};
        Object.entries(groupedAccounts).forEach(([key, accounts]) => {
            const groupedByBalance = groupBy(accounts, (account) => {
                const [primaryBalance] = getBalance(account, false);
                return primaryBalance &&
                    primaryBalance.amount >= 0 &&
                    // For CREDITCARD and LOAN we consider a balance of 0 to be negative so it shows in Debt
                    !(primaryBalance.amount === 0 &&
                        (account.type === 'CREDITCARD' || account.type === 'LOAN'))
                    ? 'positiveAccounts'
                    : 'negativeAccounts';
            });
            result[key] = {
                positiveAccounts: groupedByBalance.positiveAccounts || [],
                negativeAccounts: groupedByBalance.negativeAccounts || [],
            };
        });
        return result;
    };
    const groupedAccountsByBalance = groupAccountsByBalance(groupedAccounts);
    const groupedAllByHiddenAndClosed = Object.keys(groupedAccountsByBalance).reduce((prev, curr) => {
        let key;
        if (curr.endsWith('_HIDDEN')) {
            key = 'HIDDEN';
        }
        else if (curr.endsWith('_CLOSED')) {
            key = 'CLOSED';
        }
        if (key) {
            return {
                ...prev,
                [key]: {
                    ...prev[key],
                    positiveAccounts: prev[key].positiveAccounts.concat(groupedAccountsByBalance[curr]
                        .positiveAccounts),
                    negativeAccounts: prev[key].negativeAccounts.concat(groupedAccountsByBalance[curr]
                        .negativeAccounts),
                },
            };
        }
        return prev;
    }, {
        HIDDEN: {
            array: [],
            positiveAccounts: [],
            negativeAccounts: [],
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
        CLOSED: {
            array: [],
            positiveAccounts: [],
            negativeAccounts: [],
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
    });
    const result = {
        CHECKING: {
            array: groupedAccounts['CHECKING'] || [],
            positiveAccounts: groupedAccountsByBalance['CHECKING']?.positiveAccounts || [],
            negativeAccounts: groupedAccountsByBalance['CHECKING']?.negativeAccounts || [],
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
        CHECKING_HIDDEN: {
            array: [
                ...(groupedAccounts['CHECKING_HIDDEN'] || []),
                ...(groupedAccounts['CREDITCARD_HIDDEN'] || []),
            ],
            positiveAccounts: [
                ...(groupedAccountsByBalance['CHECKING_HIDDEN']?.positiveAccounts ||
                    []),
                ...(groupedAccountsByBalance['CREDITCARD_HIDDEN']?.positiveAccounts ||
                    []),
            ],
            negativeAccounts: [
                ...(groupedAccountsByBalance['CHECKING_HIDDEN']?.negativeAccounts ||
                    []),
                ...(groupedAccountsByBalance['CREDITCARD_HIDDEN']?.negativeAccounts ||
                    []),
            ],
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
        CHECKING_CLOSED: {
            array: [
                ...(groupedAccounts['CHECKING_CLOSED'] || []),
                ...(groupedAccounts['CREDITCARD_CLOSED'] || []),
            ],
            positiveAccounts: [
                ...(groupedAccountsByBalance['CHECKING_CLOSED']?.positiveAccounts ||
                    []),
                ...(groupedAccountsByBalance['CREDITCARD_CLOSED']?.positiveAccounts ||
                    []),
            ],
            negativeAccounts: [
                ...(groupedAccountsByBalance['CHECKING_CLOSED']?.negativeAccounts ||
                    []),
                ...(groupedAccountsByBalance['CREDITCARD_CLOSED']?.negativeAccounts ||
                    []),
            ],
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
        CREDITCARD: {
            array: groupedAccounts['CREDITCARD'] || [],
            positiveAccounts: groupedAccountsByBalance['CREDITCARD']?.positiveAccounts || [],
            negativeAccounts: groupedAccountsByBalance['CREDITCARD']?.negativeAccounts || [],
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
        CREDITCARD_HIDDEN: {
            array: groupedAccounts['CREDITCARD_HIDDEN'] || [],
            positiveAccounts: groupedAccountsByBalance['CREDITCARD_HIDDEN']?.positiveAccounts || [],
            negativeAccounts: groupedAccountsByBalance['CREDITCARD_HIDDEN']?.negativeAccounts || [],
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
        CREDITCARD_CLOSED: {
            array: groupedAccounts['CREDITCARD_CLOSED'] || [],
            positiveAccounts: groupedAccountsByBalance['CREDITCARD_CLOSED']?.positiveAccounts || [],
            negativeAccounts: groupedAccountsByBalance['CREDITCARD_CLOSED']?.negativeAccounts || [],
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
        SAVINGS: {
            array: groupedAccounts['SAVINGS'] || [],
            positiveAccounts: groupedAccountsByBalance['SAVINGS']?.positiveAccounts || [],
            negativeAccounts: groupedAccountsByBalance['SAVINGS']?.negativeAccounts || [],
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
        SAVINGS_HIDDEN: {
            array: groupedAccounts['SAVINGS_HIDDEN'] || [],
            positiveAccounts: groupedAccountsByBalance['SAVINGS_HIDDEN']?.positiveAccounts || [],
            negativeAccounts: groupedAccountsByBalance['SAVINGS_HIDDEN']?.negativeAccounts || [],
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
        SAVINGS_CLOSED: {
            array: groupedAccounts['SAVINGS_CLOSED'] || [],
            positiveAccounts: groupedAccountsByBalance['SAVINGS_CLOSED']?.positiveAccounts || [],
            negativeAccounts: groupedAccountsByBalance['SAVINGS_CLOSED']?.negativeAccounts || [],
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
        INVESTMENT: {
            array: groupedAccounts['INVESTMENT'] || [],
            positiveAccounts: groupedAccountsByBalance['INVESTMENT']?.positiveAccounts || [],
            negativeAccounts: groupedAccountsByBalance['INVESTMENT']?.negativeAccounts || [],
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
        ALL_INVESTMENT: {
            array: sortAccounts([
                ...(groupedAccounts['INVESTMENT'] || []),
                ...(groupedAccounts['CRYPTO'] || []),
            ]),
            positiveAccounts: sortAccounts([
                ...(groupedAccountsByBalance['INVESTMENT']?.positiveAccounts || []),
                ...(groupedAccountsByBalance['CRYPTO']?.positiveAccounts || []),
            ]),
            negativeAccounts: sortAccounts([
                ...(groupedAccountsByBalance['INVESTMENT']?.negativeAccounts || []),
                ...(groupedAccountsByBalance['CRYPTO']?.negativeAccounts || []),
            ]),
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
        PENSION: {
            array: groupedAccounts['PENSION'] || [],
            positiveAccounts: groupedAccountsByBalance['PENSION']?.positiveAccounts || [],
            negativeAccounts: groupedAccountsByBalance['PENSION']?.negativeAccounts || [],
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
        CRYPTO: {
            array: groupedAccounts['CRYPTO'] || [],
            positiveAccounts: groupedAccountsByBalance['CRYPTO']?.positiveAccounts || [],
            negativeAccounts: groupedAccountsByBalance['CRYPTO']?.negativeAccounts || [],
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
        LOAN: {
            array: groupedAccounts['LOAN'] || [],
            positiveAccounts: groupedAccountsByBalance['LOAN']?.positiveAccounts || [],
            negativeAccounts: groupedAccountsByBalance['LOAN']?.negativeAccounts || [],
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
        LOAN_HIDDEN: {
            array: groupedAccounts['LOAN_HIDDEN'] || [],
            positiveAccounts: groupedAccountsByBalance['LOAN_HIDDEN']?.positiveAccounts || [],
            negativeAccounts: groupedAccountsByBalance['LOAN_HIDDEN']?.negativeAccounts || [],
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
        LOAN_CLOSED: {
            array: groupedAccounts['LOAN_CLOSED'] || [],
            positiveAccounts: groupedAccountsByBalance['LOAN_CLOSED']?.positiveAccounts || [],
            negativeAccounts: groupedAccountsByBalance['LOAN_CLOSED']?.negativeAccounts || [],
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
        REAL_ESTATE: {
            array: groupedAccounts['REAL_ESTATE'] || [],
            positiveAccounts: groupedAccountsByBalance['REAL_ESTATE']?.positiveAccounts || [],
            negativeAccounts: groupedAccountsByBalance['REAL_ESTATE']?.negativeAccounts || [],
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
        VEHICLE: {
            array: groupedAccounts['VEHICLE'] || [],
            positiveAccounts: groupedAccountsByBalance['VEHICLE']?.positiveAccounts || [],
            negativeAccounts: groupedAccountsByBalance['VEHICLE']?.negativeAccounts || [],
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
        OTHER: {
            array: groupedAccounts['OTHER'] || [],
            positiveAccounts: groupedAccountsByBalance['OTHER']?.positiveAccounts || [],
            negativeAccounts: groupedAccountsByBalance['OTHER']?.negativeAccounts || [],
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
        INVESTMENT_HIDDEN: {
            array: [
                ...(groupedAccounts['INVESTMENT_HIDDEN'] || []),
                ...(groupedAccounts['CRYPTO_HIDDEN'] || []),
            ],
            positiveAccounts: [
                ...(groupedAccountsByBalance['INVESTMENT_HIDDEN']?.positiveAccounts ||
                    []),
                ...(groupedAccountsByBalance['CRYPTO_HIDDEN']?.positiveAccounts || []),
            ],
            negativeAccounts: [
                ...(groupedAccountsByBalance['INVESTMENT_HIDDEN']?.negativeAccounts ||
                    []),
                ...(groupedAccountsByBalance['CRYPTO_HIDDEN']?.negativeAccounts || []),
            ],
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
        INVESTMENT_CLOSED: {
            array: [
                ...(groupedAccounts['INVESTMENT_CLOSED'] || []),
                ...(groupedAccounts['CRYPTO_CLOSED'] || []),
            ],
            positiveAccounts: [
                ...(groupedAccountsByBalance['INVESTMENT_CLOSED']?.positiveAccounts ||
                    []),
                ...(groupedAccountsByBalance['CRYPTO_CLOSED']?.positiveAccounts || []),
            ],
            negativeAccounts: [
                ...(groupedAccountsByBalance['INVESTMENT_CLOSED']?.negativeAccounts ||
                    []),
                ...(groupedAccountsByBalance['CRYPTO_CLOSED']?.negativeAccounts || []),
            ],
            positiveTotal: 0,
            negativeTotal: 0,
            total: 0,
        },
        HIDDEN: groupedAllByHiddenAndClosed.HIDDEN,
        CLOSED: groupedAllByHiddenAndClosed.CLOSED,
    };
    result.CHECKING.positiveTotal = calcTotal(result.CHECKING.positiveAccounts);
    result.CHECKING.negativeTotal = calcTotal(result.CHECKING.negativeAccounts);
    result.CHECKING.total =
        result.CHECKING.positiveTotal + result.CHECKING.negativeTotal;
    result.SAVINGS.positiveTotal = calcTotal(result.SAVINGS.positiveAccounts);
    result.SAVINGS.negativeTotal = calcTotal(result.SAVINGS.negativeAccounts);
    result.SAVINGS.total =
        result.SAVINGS.positiveTotal + result.SAVINGS.negativeTotal;
    result.LOAN.positiveTotal = calcTotal(result.LOAN.positiveAccounts);
    result.LOAN.negativeTotal = calcTotal(result.LOAN.negativeAccounts);
    result.LOAN.total = result.LOAN.positiveTotal + result.LOAN.negativeTotal;
    result.CREDITCARD.positiveTotal = calcTotal(result.CREDITCARD.positiveAccounts);
    result.CREDITCARD.negativeTotal = calcTotal(result.CREDITCARD.negativeAccounts);
    result.CREDITCARD.total =
        result.CREDITCARD.positiveTotal + result.CREDITCARD.negativeTotal;
    result.INVESTMENT.positiveTotal = calcTotal(result.INVESTMENT.positiveAccounts);
    result.INVESTMENT.negativeTotal = calcTotal(result.INVESTMENT.negativeAccounts);
    result.INVESTMENT.total =
        result.INVESTMENT.positiveTotal + result.INVESTMENT.negativeTotal;
    result.ALL_INVESTMENT.positiveTotal = calcTotal(result.ALL_INVESTMENT.positiveAccounts);
    result.ALL_INVESTMENT.negativeTotal = calcTotal(result.ALL_INVESTMENT.negativeAccounts);
    result.ALL_INVESTMENT.total =
        result.ALL_INVESTMENT.positiveTotal + result.ALL_INVESTMENT.negativeTotal;
    result.CRYPTO.positiveTotal = calcTotal(result.CRYPTO.positiveAccounts);
    result.CRYPTO.negativeTotal = calcTotal(result.CRYPTO.negativeAccounts);
    result.CRYPTO.total =
        result.CRYPTO.positiveTotal + result.CRYPTO.negativeTotal;
    result.PENSION.positiveTotal = calcTotal(result.PENSION.positiveAccounts);
    result.PENSION.negativeTotal = calcTotal(result.PENSION.negativeAccounts);
    result.PENSION.total =
        result.PENSION.positiveTotal + result.PENSION.negativeTotal;
    result.OTHER.positiveTotal = calcTotal(result.OTHER.positiveAccounts);
    result.OTHER.negativeTotal = calcTotal(result.OTHER.negativeAccounts);
    result.OTHER.total = result.OTHER.positiveTotal + result.OTHER.negativeTotal;
    result.VEHICLE.positiveTotal = calcTotal(result.VEHICLE.positiveAccounts);
    result.VEHICLE.negativeTotal = calcTotal(result.VEHICLE.negativeAccounts);
    result.VEHICLE.total =
        result.VEHICLE.positiveTotal + result.VEHICLE.negativeTotal;
    result.REAL_ESTATE.positiveTotal = calcTotal(result.REAL_ESTATE.positiveAccounts);
    result.REAL_ESTATE.negativeTotal = calcTotal(result.REAL_ESTATE.negativeAccounts);
    result.REAL_ESTATE.total =
        result.REAL_ESTATE.positiveTotal + result.REAL_ESTATE.negativeTotal;
    return result;
};
export default getAccounts;
