import { useCallback, useState } from 'react';
export const useSearchField = (initialSearchTerm = '') => {
    const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
    const [isSearching, setIsSearching] = useState(false);
    const changeSearchTerm = useCallback((searchTerm) => {
        setSearchTerm(searchTerm);
        if (!isSearching && searchTerm !== '') {
            setIsSearching(true);
        }
    }, [isSearching]);
    const cancelSearch = useCallback(() => {
        setSearchTerm('');
        setIsSearching(false);
    }, []);
    return {
        searchTerm,
        changeSearchTerm,
        cancelSearch,
        isSearching,
    };
};
