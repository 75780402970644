import AsyncStorage from '@react-native-async-storage/async-storage';
import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { createTransform, persistCombineReducers, persistStore, } from 'redux-persist';
import getStoredStateMigrateV4 from 'redux-persist/lib/integration/getStoredStateMigrateV4';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import startupSaga from 'sagas';
import { initialState } from 'features/spaces/reducers/spaces';
import { isWeb } from '../constants';
import { analyticsMiddleware } from '../middleware/analytics';
import { apiMiddleware } from '../middleware/api';
import { errorMiddleware } from '../middleware/error';
import reducers from '../reducers';
const isDebuggingInChrome = __DEV__ && !!window.navigator?.userAgent;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const logger = createLogger({
    predicate: () => isDebuggingInChrome,
    collapsed: true,
    duration: true,
});
const saga = createSagaMiddleware();
const middlewares = [
    thunk,
    apiMiddleware,
    analyticsMiddleware,
    errorMiddleware,
    saga,
];
// flipper redux debugger
if (__DEV__) {
    const createDebugger = require('redux-flipper').default;
    middlewares.push(createDebugger());
}
const config = {
    whitelist: isWeb
        ? ['spaces']
        : [
            'addressReducer',
            'accounts',
            'user',
            'feed',
            'utils',
            'payments',
            'spaces',
            'stripe',
            'beneficiaries',
            'invest',
            'pots',
            'contacts',
            'deviceSettings',
            'analytics',
            'signInNavigation',
            'rentReporting',
            'quests',
            'budgeting',
            'paymentLimits',
            'iapSubscriptions',
            'referrals',
            'preApprovedTransfers',
        ],
    storage: AsyncStorage,
    // We need to only persist the selected space id on web so we have it before any requests
    ...(isWeb && {
        transforms: [
            createTransform((inboundState) => inboundState, (state, key) => {
                if (key === 'spaces') {
                    return {
                        ...initialState,
                        selectedSpace: state.selectedSpace,
                    };
                }
                return state;
            }, { whitelist: ['spaces'] }),
        ],
    }),
};
const createEMMAStore = applyMiddleware(...middlewares)(createStore);
const rootReducer = persistCombineReducers({
    // @ts-expect-error
    getStoredState: getStoredStateMigrateV4(config),
    key: 'primary',
    ...config,
}, reducers);
function configureStore() {
    const store = createEMMAStore(rootReducer, undefined, 
    // @ts-expect-error TODO fix
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
    const persistor = persistStore(store, null);
    if (isDebuggingInChrome) {
        window.store = store;
    }
    saga.run(startupSaga);
    return { store, persistor };
}
export default configureStore;
