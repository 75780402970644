import React, { useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import { View } from 'react-native';
import Section from 'design-system/Section';
import { CardView } from 'design-system/CardView';
import { IconArrowBack } from 'design-system/icons';
import { SettingsItemSwitch } from 'design-system/SettingsItem';
import { Amplitude } from 'utils';
import Modal from 'utils/packages/Modal';
import { useAppSelector } from 'store/hooks';
import useFetchOld, { muteAll } from 'hooks/useFetch';
import { editSubscription } from 'features/subscriptions/actions';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { makeSelectSubscriptionById } from 'features/subscriptions/selectors';
import useOpenSubscription from 'features/subscriptions/hooks/useOpenSubscription';
import { useRequiredBenefitCallbackWithSpaces } from 'features/premium/hooks/useRequiredBenefitCallback';
import SubscriptionPrediction from 'features/subscriptions/components/transactions/SubscriptionPrediction';
import { styles } from './styles';
const TransactionSubscriptionSection = ({ transaction }) => {
    const [isRepeating, setIsRepeating] = useState(false);
    const memoSelectSubscriptionById = useMemo(makeSelectSubscriptionById, []);
    const subscription = useAppSelector((store) => memoSelectSubscriptionById(store, 'subscriptionId' in transaction ? transaction?.subscriptionId : undefined));
    const hasSubscriptionSwitch = !(transaction.amount > 0 ||
        (transaction.category?.id === 'internal' &&
            (!('subscriptionId' in transaction) || !transaction.subscriptionId)));
    const hasLinkedSubscription = Boolean(((subscription?.predictions && subscription.predictions[0]?.date) ||
        subscription?.transactions?.[0]?.bookingDate) &&
        subscription.isActive);
    const prevHasLinkedSubscription = useRef(hasLinkedSubscription);
    useEffect(() => {
        if (!hasLinkedSubscription && prevHasLinkedSubscription.current) {
            setIsRepeating(false);
        }
        prevHasLinkedSubscription.current = hasLinkedSubscription;
    }, [hasLinkedSubscription]);
    const navigation = useMainStackNavigation();
    const openSubscription = useOpenSubscription();
    const onOpenSubscription = useCallback(() => {
        if (!subscription?.merchantInfo ||
            !('subscriptionId' in transaction) ||
            transaction.subscriptionId === null) {
            return;
        }
        const { subscriptionId } = transaction;
        openSubscription(subscriptionId);
    }, [openSubscription, subscription?.merchantInfo, transaction]);
    const [, , , fetch] = useFetchOld(muteAll);
    const onCreateSubscription = useRequiredBenefitCallbackWithSpaces(useCallback(() => {
        Modal.hide();
        Amplitude.logEvent('Transaction.SubscriptionAdded');
        if ('subscriptionId' in transaction &&
            transaction.subscriptionId != null) {
            fetch(editSubscription(transaction.subscriptionId, {
                isActive: true,
            }));
        }
        else {
            navigation.navigate('AdjustSubscription', {
                data: transaction,
                onSuccess: () => {
                    setIsRepeating(true);
                },
            });
        }
    }, [transaction]), 'recurringPayments');
    const onCloseSubscriptions = useCallback(() => {
        Modal.hide();
        setIsRepeating(false);
    }, []);
    const onSetSubscription = useCallback(() => {
        Modal.showConfirmation('Recurring Payment', 'Would you like to mark this as a recurring payment?', "Yes, let's do it", 'No, thanks', onCreateSubscription, onCloseSubscriptions);
    }, [onCloseSubscriptions, onCreateSubscription]);
    const content = useMemo(() => {
        if (hasLinkedSubscription && subscription) {
            return (<SubscriptionPrediction onPress={onOpenSubscription} isPredicted={!!subscription.predictions?.[0]?.date} date={subscription.predictions?.[0]?.date ||
                    subscription.transactions?.[0]?.bookingDate} currency={subscription.nativePrice.currency} amount={-subscription.nativePrice.amount}/>);
        }
        // This transaction either has no subscription or it is inactvie
        return hasSubscriptionSwitch ? (<View style={styles.webPadding}>
        <SettingsItemSwitch icon={<IconArrowBack gradientKey="darkPink"/>} title="Is it recurring?" onValueChange={onSetSubscription} value={subscription?.isActive || isRepeating} disabled={subscription?.isActive || isRepeating}/>
      </View>) : null;
    }, [
        hasLinkedSubscription,
        hasSubscriptionSwitch,
        isRepeating,
        onOpenSubscription,
        onSetSubscription,
        subscription,
    ]);
    if (hasSubscriptionSwitch || hasLinkedSubscription) {
        return (<Section title="Recurring Payment" style={styles.section}>
        <CardView noMarginTop noMarginBottom>
          {content}
        </CardView>
      </Section>);
    }
    return null;
};
export default TransactionSubscriptionSection;
