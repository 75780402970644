import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import Button from 'design-system/Button';
import { Amplitude } from 'utils';
import { useMainStackNavigation } from 'utils/types/navigationV6';
const BudgetingSetupButton = () => {
    const navigation = useMainStackNavigation();
    const onPressSetup = useCallback(() => {
        navigation.navigate('EditBudget', {
            flow: 'Set',
        });
        Amplitude.logEvent('Feed.openBudgetingSetup');
    }, []);
    return (<Button brandLight cards title="Set up budgets" style={styles.setupButton} onPress={onPressSetup}/>);
};
const styles = StyleSheet.create({
    setupButton: {
        alignSelf: 'flex-start',
    },
});
export default BudgetingSetupButton;
