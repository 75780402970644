import React, { memo, useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { rem } from 'design-system/values';
import IconChevron from 'design-system/icons/IconChevron';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAnalyticsPosition } from 'reducers/selectors';
import TouchableOpacity from 'utils/packages/TouchableOpacity';
import { setAnalyticsPosition, setBudgetingPosition } from 'actions/expenses';
const PeriodControl = ({ type, maxDataIndex }) => {
    const dispatch = useAppDispatch();
    const position = useAppSelector((store) => selectAnalyticsPosition(store, type));
    const styles = useStyles(styleSet);
    const handlePositionChange = useCallback((action) => () => {
        if (action === 'prev' && position === maxDataIndex) {
            return;
        }
        if (action === 'next' && position === 0) {
            return;
        }
        const nextIndex = position + (action === 'prev' ? 1 : -1);
        if (type === 'budgeting') {
            dispatch(setBudgetingPosition(nextIndex));
        }
        else if (type === 'analytics') {
            dispatch(setAnalyticsPosition(nextIndex));
        }
    }, [position, maxDataIndex, type]);
    const isLeftButtonDisabled = useMemo(() => maxDataIndex === -1 || position === maxDataIndex, [maxDataIndex, position]);
    const isRightButtonDisabled = useMemo(() => position === 0, [position]);
    return (<View style={styles.container} pointerEvents="box-none">
      {maxDataIndex !== undefined && (<>
          <TouchableOpacity hoverOnDark onPress={handlePositionChange('prev')} disabled={isLeftButtonDisabled} style={[styles.arrowBtn, isLeftButtonDisabled && styles.disabled]}>
            <IconChevron direction="left" colorKey="brand"/>
          </TouchableOpacity>
          <TouchableOpacity hoverOnDark style={[styles.arrowBtn, isRightButtonDisabled && styles.disabled]} disabled={isRightButtonDisabled} onPress={handlePositionChange('next')}>
            <IconChevron direction="right" colorKey="brand"/>
          </TouchableOpacity>
        </>)}
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        ...StyleSheet.absoluteFillObject,
        zIndex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: rem(32),
        justifyContent: 'space-between',
    },
    arrowBtn: {
        width: rem(40),
        height: rem(40),
        alignItems: 'center',
        borderRadius: rem(8),
        justifyContent: 'center',
        backgroundColor: colors.buttons.brandGroup,
    },
    disabled: {
        opacity: 0.5,
    },
}));
export default memo(PeriodControl);
