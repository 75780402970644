import React from 'react';
import { StyleSheet, View, } from 'react-native';
import { rem } from 'design-system/values';
import SectionHeader from './SectionHeader';
const Section = ({ title, button, children, style, titleContainerStyle, onLight, onLayout, }) => (<View style={[styles.container, style]} onLayout={onLayout}>
    {(!!title || !!button) && (<SectionHeader style={[styles.titleContainer, titleContainerStyle]} button={button} onLight={onLight}>
        {title}
      </SectionHeader>)}
    {children}
  </View>);
const styles = StyleSheet.create({
    container: {
        marginBottom: rem(16),
    },
    titleContainer: {
        marginHorizontal: rem(16),
        marginBottom: rem(16),
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});
export default Section;
