import { View } from 'react-native';
import React from 'react';
import Text from 'design-system/Text';
import Amount from 'design-system/Amount';
import { rem } from 'design-system/values';
import { Button } from 'design-system/Button';
import Separator from 'design-system/Separator';
import { IconArrowBack } from 'design-system/icons';
import BlurAmountView from 'design-system/BlurAmountView';
import { SettingsItemIcon } from 'design-system/SettingsItemIcon';
import { selectDefaultCurrency, selectCommittedTransactions, } from 'reducers/selectors';
import useStyles from 'hooks/useStyles';
import { useAppSelector } from 'store/hooks';
import createStyleSheets from 'utils/createStyleSheets';
import FastImage from 'utils/packages/rnFastImage';
import { selectActiveSubscriptions } from 'features/subscriptions/selectors';
const SubscriptionsSummaryCard = ({ subscriptions, blur, onPressSeeAll, }) => {
    const styles = useStyles(styleSet);
    const currency = useAppSelector(selectDefaultCurrency);
    const usePayday = useAppSelector((store) => store.feed.feed.cards.thisMonth?.usePayday);
    // todo we also need to ensure this is fetched at this point
    const { totalPaid, totalToBePaid } = useAppSelector(selectCommittedTransactions);
    const total = totalPaid + totalToBePaid;
    const subscriptionsFull = useAppSelector(selectActiveSubscriptions);
    const subscriptionsArray = subscriptionsFull?.length
        ? subscriptionsFull
        : subscriptions;
    const avatars = subscriptionsArray
        .filter((s) => s.merchantInfo?.iconUrl)
        .slice(0, 2);
    const count = subscriptionsArray.length - avatars.length;
    return (<>
      <View style={styles.header}>
        <SettingsItemIcon iconSize={44}>
          <IconArrowBack colorKey="brand"/>
        </SettingsItemIcon>
        <View style={styles.headerText}>
          <Text Text-16>Bills & subscriptions</Text>
          {!blur && (<View style={styles.countContainer}>
              <View style={styles.avatarContainer}>
                {avatars.map((s, index) => (<FastImage key={s.id} style={[
                    styles.avatar,
                    index === 1 && styles.avatarRight,
                ]} source={{ uri: s.merchantInfo?.iconUrl || undefined }}/>))}
              </View>
              {!!count && (<Text Text-12 Secondary style={styles.count}>
                  + {count} more
                </Text>)}
            </View>)}
        </View>
      </View>
      <Separator />
      <View style={styles.footer}>
        <View style={styles.footerText}>
          <Text Text-14>This {usePayday ? 'period' : 'month'}</Text>
          <BlurAmountView enabled={blur}>
            <Amount Numbers-14 value={{ value: -total, currency }}/>
          </BlurAmountView>
        </View>
        <Button brandReversed square title="See details" onPress={onPressSeeAll}/>
      </View>
    </>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        marginHorizontal: rem(16),
    },
    header: {
        padding: rem(16),
        flexDirection: 'row',
    },
    headerText: {
        justifyContent: 'center',
        marginLeft: rem(16),
    },
    footer: {
        padding: rem(16),
    },
    footerText: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: rem(24),
    },
    avatar: {
        width: rem(18),
        height: rem(18),
        borderRadius: rem(18),
        borderColor: colors.borders.logoStroke,
        borderWidth: 0.5,
    },
    avatarRight: {
        position: 'absolute',
        right: 0,
    },
    countContainer: {
        flexDirection: 'row',
        marginTop: rem(4),
    },
    avatarContainer: {
        width: rem(32),
    },
    count: {
        marginLeft: rem(4),
    },
}));
export default SubscriptionsSummaryCard;
