import { CALL_API } from '../middleware/api';
import * as types from './types';
export const dismissEnergyCard = (id) => ({
    [CALL_API]: {
        endpoint: `/bills-switching/enquiries/${id}/dismiss`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.POST_DISMISS_ENERGY_CARD_REQUEST,
            types.POST_DISMISS_ENERGY_CARD_SUCCESS,
            types.POST_DISMISS_ENERGY_CARD_FAILURE,
        ],
    },
});
export const getSwitchingQuotes = (body) => ({
    [CALL_API]: {
        endpoint: '/bills-switching/enquiries',
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_SWITCHING_QUOTES_REQUEST,
            types.GET_SWITCHING_QUOTES_SUCCESS,
            types.GET_SWITCHING_QUOTES_FAILURE,
        ],
    },
});
export const getEnergySwitchingResults = (id, body) => ({
    [CALL_API]: {
        endpoint: `/bills-switching/enquiries/${id}`,
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_ENERGY_SWITCHING_RESULTS_REQUEST,
            types.GET_ENERGY_SWITCHING_RESULTS_SUCCESS,
            types.GET_ENERGY_SWITCHING_RESULTS_FAILURE,
        ],
    },
});
export const getSwitchingResults = (id) => ({
    [CALL_API]: {
        endpoint: `/bills-switching/enquiries/${id}/results`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        types: [
            types.GET_SWITCHING_RESULTS_REQUEST,
            types.GET_SWITCHING_RESULTS_SUCCESS,
            types.GET_SWITCHING_RESULTS_FAILURE,
        ],
    },
});
export const getAdvancedSwitchingResults = (body) => ({
    [CALL_API]: {
        endpoint: '/bills-switching/msm-enquiry-submitted',
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_ADVANCED_SWITCHING_RESULTS_REQUEST,
            types.GET_ADVANCED_SWITCHING_RESULTS_SUCCESS,
            types.GET_ADVANCED_SWITCHING_RESULTS_FAILURE,
        ],
    },
});
export const onSwitchCompleted = (body) => ({
    [CALL_API]: {
        endpoint: '/bills-switching/msm-application-submitted',
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.ON_SWITCH_COMPLETED_REQUEST,
            types.ON_SWITCH_COMPLETED_SUCCESS,
            types.ON_SWITCH_COMPLETED_FAILURE,
        ],
    },
});
export const applyToSwitching = (id, resultsId) => ({
    [CALL_API]: {
        endpoint: `/bills-switching/enquiries/${id}/results/${resultsId}/apply`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        types: [
            types.APPLY_SWITCHING_REQUEST,
            types.APPLY_SWITCHING_SUCCESS,
            types.APPLY_SWITCHING_FAILURE,
        ],
    },
});
export const getEnquiries = (partner) => ({
    [CALL_API]: {
        endpoint: `/bills-switching/enquiries?partner=${partner}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        types: [
            types.GET_ENQUIRIES_REQUEST,
            types.GET_ENQUIRIES_SUCCESS,
            types.GET_ENQUIRIES_FAILURE,
        ],
    },
});
export const getAutoFill = () => ({
    [CALL_API]: {
        endpoint: '/bills-switching/autofill',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        types: [
            types.GET_AUTOFILL_REQUEST,
            types.GET_AUTOFILL_SUCCESS,
            types.GET_AUTOFILL_FAILURE,
        ],
    },
});
