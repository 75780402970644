import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconWalletCoin = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} width={20} height={20} viewBox="0 0 20 20" {...props} defaultGradientKey="lightPurple">
      <Path d="M17.3555 10.6327V11.5461C17.3555 11.7927 17.1637 11.9937 16.9079 12.0028H15.5743C15.0902 12.0028 14.6518 11.6466 14.6153 11.1716C14.5879 10.8884 14.6975 10.6236 14.8802 10.4409C15.0446 10.2673 15.2729 10.176 15.5195 10.176H16.8988C17.1637 10.1851 17.3555 10.3861 17.3555 10.6327Z M14.1944 9.76495C13.7377 10.2125 13.5185 10.8793 13.7012 11.5735C13.9387 12.423 14.7699 12.9619 15.6468 12.9619H16.4414C16.9438 12.9619 17.3549 13.3729 17.3549 13.8753V14.0489C17.3549 15.9396 15.8112 17.4833 13.9204 17.4833H3.43444C1.54367 17.4833 0 15.9396 0 14.0489V7.90158C0 6.7781 0.538915 5.78249 1.37012 5.16137C1.94557 4.72293 2.65804 4.46716 3.43444 4.46716H13.9204C15.8112 4.46716 17.3549 6.01083 17.3549 7.90158V8.30349C17.3549 8.80586 16.9438 9.2169 16.4414 9.2169H15.5098C14.9982 9.2169 14.5324 9.41785 14.1944 9.76495Z M12.5593 2.57583C12.806 2.82245 12.5959 3.20609 12.2488 3.20609L5.23374 3.19695C4.83184 3.19695 4.62175 2.70371 4.91405 2.42055L6.39378 0.931683C7.64518 -0.310561 9.67296 -0.310561 10.9243 0.931683L12.5228 2.54843C12.5319 2.55756 12.5502 2.56669 12.5593 2.57583Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
/*
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.3555 10.6327V11.5461C17.3555 11.7927 17.1637 11.9937 16.9079 12.0028H15.5743C15.0902 12.0028 14.6518 11.6466 14.6153 11.1716C14.5879 10.8884 14.6975 10.6236 14.8802 10.4409C15.0446 10.2673 15.2729 10.176 15.5195 10.176H16.8988C17.1637 10.1851 17.3555 10.3861 17.3555 10.6327Z" fill="#962DFF"/>
<path d="M14.1944 9.76495C13.7377 10.2125 13.5185 10.8793 13.7012 11.5735C13.9387 12.423 14.7699 12.9619 15.6468 12.9619H16.4414C16.9438 12.9619 17.3549 13.3729 17.3549 13.8753V14.0489C17.3549 15.9396 15.8112 17.4833 13.9204 17.4833H3.43444C1.54367 17.4833 0 15.9396 0 14.0489V7.90158C0 6.7781 0.538915 5.78249 1.37012 5.16137C1.94557 4.72293 2.65804 4.46716 3.43444 4.46716H13.9204C15.8112 4.46716 17.3549 6.01083 17.3549 7.90158V8.30349C17.3549 8.80586 16.9438 9.2169 16.4414 9.2169H15.5098C14.9982 9.2169 14.5324 9.41785 14.1944 9.76495Z" fill="#962DFF"/>
<path d="M12.5593 2.57583C12.806 2.82245 12.5959 3.20609 12.2488 3.20609L5.23374 3.19695C4.83184 3.19695 4.62175 2.70371 4.91405 2.42055L6.39378 0.931683C7.64518 -0.310561 9.67296 -0.310561 10.9243 0.931683L12.5228 2.54843C12.5319 2.55756 12.5502 2.56669 12.5593 2.57583Z" fill="#962DFF"/>
</svg>
*/
