import * as types from 'actions/types';
export const cacheSharedBillInfo = (transactionId, usersInfo) => ({
    type: types.CACHE_SHARED_BILL_INFO,
    payload: {
        id: transactionId,
        users: usersInfo,
    },
});
export const clearSharedBillInfoCache = (transactionId) => ({
    type: types.CLEAR_SHARED_BILL_INFO_CACHE,
    payload: {
        id: transactionId,
    },
});
