import React from 'react';
import { Path, Svg } from 'react-native-svg';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import Animated from 'react-native-reanimated';
const AnimatedSvg = Animated.createAnimatedComponent(Svg);
const IconCheck = ({ color, width = rem(24), height = rem(24), alt, thick, style, }) => {
    const colors = useColors();
    if (alt) {
        return (<AnimatedSvg width={width} height={height} viewBox="0 0 71 54" style={style}>
        <Path fillRule="evenodd" clipRule="evenodd" d="M63.656 1.75198C61.3201 -0.583994 57.3155 -0.583994 54.9795 1.75198L27.2545 29.351L15.2673 17.2694C12.9313 14.9334 8.92677 14.9334 6.59079 17.2694L1.75198 22.1082C-0.583994 24.4442 -0.583994 28.4487 1.75198 30.7847L22.9426 52.1422C25.2786 54.4782 29.2831 54.4782 31.6191 52.1422L36.4579 47.3034C36.7156 47.0457 36.9449 46.7677 37.1457 46.4739L68.4949 15.2673C70.8308 12.9313 70.8308 8.92677 68.4949 6.59079L63.656 1.75198Z" fill={color ?? colors.text.textOnBrand}/>
      </AnimatedSvg>);
    }
    if (thick) {
        return (<Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
        <Path d="M11.1479 17.0978C10.3318 17.9139 9.00761 17.9139 8.19192 17.0978L3.61207 12.518C2.79598 11.7023 2.79598 10.3781 3.61207 9.56239C4.42776 8.74631 5.75193 8.74631 6.56802 9.56239L9.29671 12.2907C9.5027 12.4963 9.83709 12.4963 10.0435 12.2907L17.432 4.90219C18.2477 4.0861 19.5718 4.0861 20.3879 4.90219C20.7798 5.29408 21 5.8258 21 6.37997C21 6.93414 20.7798 7.46585 20.3879 7.85775L11.1479 17.0978Z" fill={color ?? colors.text.textOnBrand}/>
      </Svg>);
    }
    return (<Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <Path d="M7 11.4706L10.262 15.2315C10.6138 15.6371 11.2433 15.6371 11.5951 15.2315L17 9" stroke={color ?? colors.text.textOnBrand} strokeWidth="2.5" strokeLinecap="round"/>
    </Svg>);
};
export default IconCheck;
