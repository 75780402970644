import * as React from 'react';
import { View } from 'react-native';
import { rem } from 'design-system/values';
import EmojiPickerModal from 'design-system/emojiPicker/EmojiPickerModal';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { webPickerWidth } from 'features/emoji-picker/components/EmojiSelector';
const WebEmojiPicker = ({ fx, fy, defaultSelectedEmoji, onEmojiSelected, }) => {
    const styles = useStyles(styleSet);
    if (fx && fy) {
        return (<View style={[styles.container, { left: fx, top: fy }]}>
        <EmojiPickerModal onEmojiSelected={onEmojiSelected} defaultSelectedEmoji={defaultSelectedEmoji}/>
      </View>);
    }
    return undefined;
};
export default React.memo(WebEmojiPicker);
const styleSet = createStyleSheets((colors) => ({
    container: {
        zIndex: 1,
        height: rem(468),
        width: webPickerWidth,
        paddingTop: rem(8),
        borderWidth: rem(1),
        position: 'absolute',
        borderRadius: rem(14),
        paddingBottom: rem(16),
        borderColor: colors.borders.divider,
        backgroundColor: colors.background.light,
        ...colors.shadows.webFloatingCard,
    },
}));
