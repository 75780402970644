import { REHYDRATE } from 'redux-persist';
import { LOGGED_OUT } from 'actions/types';
import * as types from '../actions/types';
const initialState = {
    quests: [],
};
// We can infer some quest completion from posts to events/RATE
// Just need to map the type
const questLogMap = {
    RATE: 'social.rate_emma',
    FACEBOOK_SHARE: 'social.facebook_share',
    OPEN_NET_WORTH: 'first_time.open_net_worth',
    SCRAMBLE: 'first_time.use_scramble',
    SUBSCRIPTIONS_OPENED: 'first_time.check_subscriptions',
    SETUP_FRAUD_DETECT: 'first_time.setup_fraud_detect',
};
const quests = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE:
            const quests = action.payload?.quests;
            if (quests) {
                return {
                    ...initialState,
                    ...quests,
                };
            }
            return state;
        case types.POST_LOG_SUCCESS:
            if (!state.quests)
                return state;
            return {
                ...state,
                quests: state.quests.map((category) => ({
                    ...category,
                    quests: category.quests.map((quest) => ({
                        ...quest,
                        status: quest.type === questLogMap[action.extra]
                            ? 'UNLOCKED'
                            : quest.status,
                    })),
                })),
            };
        case types.GET_QUESTS_SUCCESS:
            return { ...state, quests: action.payload };
        case LOGGED_OUT: {
            return initialState;
        }
        default:
            return state;
    }
};
export default quests;
