import { useCallback } from 'react';
import { Alert } from 'react-native';
import { useActionSheet } from 'hooks/useActionSheet';
import Links from 'utils/links';
import { useMainStackNavigation } from 'utils/types/navigationV6';
var ActionSheetOptionId;
(function (ActionSheetOptionId) {
    ActionSheetOptionId[ActionSheetOptionId["BANK_IS_NOT_HERE"] = 0] = "BANK_IS_NOT_HERE";
    ActionSheetOptionId[ActionSheetOptionId["SAVINGS_ACCOUNTS_MISSING"] = 1] = "SAVINGS_ACCOUNTS_MISSING";
    ActionSheetOptionId[ActionSheetOptionId["IS_THIS_APP_SAFE"] = 2] = "IS_THIS_APP_SAFE";
    ActionSheetOptionId[ActionSheetOptionId["SUPPORT"] = 3] = "SUPPORT";
    ActionSheetOptionId[ActionSheetOptionId["CRYPTO_LOGIN_HELP"] = 4] = "CRYPTO_LOGIN_HELP";
    ActionSheetOptionId[ActionSheetOptionId["VISIT_HELP_CENTRE"] = 5] = "VISIT_HELP_CENTRE";
    ActionSheetOptionId[ActionSheetOptionId["CANCEL"] = 6] = "CANCEL";
})(ActionSheetOptionId || (ActionSheetOptionId = {}));
const baseOptions = [
    {
        id: ActionSheetOptionId.BANK_IS_NOT_HERE,
        title: 'My bank is not here',
    },
    {
        id: ActionSheetOptionId.SAVINGS_ACCOUNTS_MISSING,
        title: 'My savings accounts are missing',
    },
    {
        id: ActionSheetOptionId.IS_THIS_APP_SAFE,
        title: 'Is this app safe?',
    },
    {
        id: ActionSheetOptionId.VISIT_HELP_CENTRE,
        title: 'Visit the help center',
    },
    {
        id: ActionSheetOptionId.CRYPTO_LOGIN_HELP,
        title: 'See our crypto guides',
    },
    {
        id: ActionSheetOptionId.SUPPORT,
        title: 'Chat to a human',
    },
    {
        id: ActionSheetOptionId.CANCEL,
        title: 'Cancel',
    },
];
const useHelpActionSheet = (opts) => {
    const { showActionSheetWithOptions } = useActionSheet();
    const navigation = useMainStackNavigation();
    const show = useCallback(() => {
        const options = (opts?.additionalOptions || []).concat(baseOptions.filter((option) => {
            if (!('id' in option))
                return true;
            switch (option.id) {
                case ActionSheetOptionId.SAVINGS_ACCOUNTS_MISSING:
                    return !!opts?.showMySavingsOption;
                case ActionSheetOptionId.BANK_IS_NOT_HERE:
                    return !!opts?.showMyBankIsNotHereOption;
                case ActionSheetOptionId.IS_THIS_APP_SAFE:
                    return !!opts?.showIsThisBankSafe;
                case ActionSheetOptionId.VISIT_HELP_CENTRE:
                    return !!opts?.showVisitHelpCentre;
                case ActionSheetOptionId.CRYPTO_LOGIN_HELP:
                    return !!opts?.showCryptoLoginInfo;
                default:
                    return true;
            }
        }));
        showActionSheetWithOptions({
            title: 'How can we help you?',
            options: options.map((option) => option.title),
            cancelButtonIndex: options.findIndex((option) => 'id' in option && option.id === ActionSheetOptionId.CANCEL),
        }, (data) => {
            if (data === undefined)
                return;
            const selectedOption = options[data];
            if ('onPress' in selectedOption) {
                selectedOption.onPress();
                return;
            }
            switch (selectedOption.id) {
                case ActionSheetOptionId.BANK_IS_NOT_HERE: {
                    Alert.alert('Sorry to hear that 😭', "If you don't see your bank, we don't currently support it. You can check any ETA or request it on our Community forum.", [
                        {
                            text: 'Visit Community',
                            onPress: Links.openEmmaCommunityBankIntegrations,
                        },
                        {
                            text: 'Ok, thanks',
                            style: 'cancel',
                        },
                    ]);
                    opts?.onBankNotHerePress?.();
                    break;
                }
                case ActionSheetOptionId.SAVINGS_ACCOUNTS_MISSING: {
                    Alert.alert('Sorry to hear that 😭', "If you don't see your savings account, it means your bank doesn't currently support it.", [
                        {
                            text: 'Ok, thanks',
                        },
                    ]);
                    opts?.onSavingAccountMissingPress?.();
                    break;
                }
                case ActionSheetOptionId.IS_THIS_APP_SAFE: {
                    Links.openHowSafeIsEmmaApp();
                    opts?.onIsThisAppSafePress?.();
                    break;
                }
                case ActionSheetOptionId.VISIT_HELP_CENTRE: {
                    Links.openEmmaHelp();
                    opts?.onVisitHelpCentrePress?.();
                    break;
                }
                case ActionSheetOptionId.CRYPTO_LOGIN_HELP: {
                    Links.openCryptoLoginInfo();
                    break;
                }
                case ActionSheetOptionId.SUPPORT: {
                    navigation.navigate('Support', {});
                    opts?.onSupportPress?.();
                    break;
                }
                case ActionSheetOptionId.CANCEL: {
                    break;
                }
                default: {
                    break;
                }
            }
        });
    }, [opts, showActionSheetWithOptions]);
    return show;
};
export default useHelpActionSheet;
