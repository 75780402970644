import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import Amount from 'design-system/Amount';
import Button from 'design-system/Button';
import { rem } from 'design-system/values';
import ProgressBar from 'design-system/ProgressBar';
import IconCircle from 'design-system/icons/IconCircle';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import IconTransactionCategory from 'features/transactions/icons/IconTransactionCategory';
import { selectIsScrambled } from 'hooks';
import { isDefaultCategory, useCategories, useCustomCategoryColors, } from 'hooks/useCategory';
import useColors from 'hooks/useColors';
import { useAppSelector } from 'store/hooks';
import { formatAmount } from 'utils/formatting';
import { interpolate } from 'utils/interpolations';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import { isWeb } from '../../../constants';
const BudgetingCategoryRow = ({ onPress, category }) => {
    const scrambled = useAppSelector(selectIsScrambled);
    const colors = useColors();
    const categories = useCategories();
    const customCategoryColors = useCustomCategoryColors();
    const positive = category.total > 0;
    const leftOfBudget = (category.budgetLimit || 0) - Math.abs(category.total);
    const percentage = interpolate(positive ? 0 : Math.abs(category.total), {
        inputRange: [0, category.budgetLimit || 0],
        outputRange: [0, 100],
    });
    const barColor = useMemo(() => {
        if (isDefaultCategory(category.id)) {
            return categories[category.id].gradientColors;
        }
        if (!category.color) {
            return colors.gradients.darkPink.colors;
        }
        const gradient = customCategoryColors.find((c) => c[0] === category.color);
        return [category.color, gradient ? gradient[1] : category.color];
    }, [
        category.color,
        category.id,
        colors.gradients.darkPink.colors,
        categories,
        customCategoryColors,
    ]);
    const { isUnlockedForSpace, onPressUpgrade } = useShouldShowUpgrade({
        benefitId: 'customCategories',
    });
    return (<TouchableHighlight onCard hoverOnLight onPress={onPress} style={styles.container} underlayColor={colors.background.underlay}>
      <View style={styles.content}>
        <IconCircle width={44} height={44}>
          <IconTransactionCategory category={category}/>
        </IconCircle>
        <View style={styles.innerContent}>
          {isUnlockedForSpace || isDefaultCategory(category.id) ? (<>
              <View style={styles.titleContainer}>
                <Text Text-16 style={styles.displayName}>
                  {category.displayName}
                </Text>
                <Amount Numbers-16 value={{
                value: category.total,
                currency: category.currency,
            }} showFraction/>
              </View>
              {Boolean(category.budgetLimit) && (<Text TextThin-14 Gray style={styles.subTitle}>
                  {formatAmount(positive ? category.budgetLimit : Math.abs(leftOfBudget), category.currency, 2, scrambled, true)}
                  {leftOfBudget >= 0 || positive
                    ? ' left of'
                    : ' over your budget of'}{' '}
                  {formatAmount(category.budgetLimit, category.currency, 2, scrambled, true)}
                </Text>)}
              <ProgressBar position={percentage} colors={barColor}/>
            </>) : (<View style={styles.innerContentUpgrade}>
              <Text Text-16 style={styles.displayNameUpgrade}>
                {category.displayName}
              </Text>
              <Button title="Upgrade" small square hug brandReversed onPress={onPressUpgrade}/>
            </View>)}
        </View>
      </View>
    </TouchableHighlight>);
};
const styles = StyleSheet.create({
    container: {
        padding: rem(16),
        justifyContent: 'center',
        ...(isWeb && {
            paddingVertical: rem(16),
            paddingHorizontal: rem(8),
        }),
    },
    content: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    innerContent: {
        flex: 1,
        marginStart: rem(16),
        flexDirection: 'column',
    },
    innerContentUpgrade: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    displayNameUpgrade: {
        flexShrink: 1,
        paddingEnd: rem(12),
    },
    titleContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flex: 1,
    },
    displayName: {
        marginBottom: rem(4),
        flexShrink: 1,
        paddingEnd: rem(12),
    },
    subTitle: {
        marginBottom: rem(8),
    },
});
export default BudgetingCategoryRow;
