import React, { memo } from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import Text from 'design-system/Text';
import { rem, hitSlop } from 'design-system/values';
import IconArrow from 'design-system/icons/IconArrow';
const SelectorWithButtons = ({ title, onForward, onBackward, disableForward = false, disableBackward = false, }) => {
    const change = (decrease) => () => {
        if (decrease) {
            onBackward();
            return;
        }
        onForward();
    };
    return (<View style={styles.selectorContainer}>
      <View style={styles.btn}>
        <TouchableOpacity hitSlop={hitSlop} onPress={change(true)} style={disableBackward && styles.hide}>
          <IconArrow direction="left"/>
        </TouchableOpacity>
      </View>
      <Text Text-16 flex centered style={styles.yearText}>
        {title}
      </Text>
      <View style={styles.btn}>
        <TouchableOpacity hitSlop={hitSlop} onPress={change(false)} style={[styles.btn, disableForward && styles.hide]}>
          <IconArrow />
        </TouchableOpacity>
      </View>
    </View>);
};
export default memo(SelectorWithButtons);
const styles = StyleSheet.create({
    selectorContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        paddingHorizontal: rem(16),
    },
    yearText: {
        paddingVertical: rem(5),
    },
    btn: {
        width: 20,
    },
    hide: {
        display: 'none',
    },
});
