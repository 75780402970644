import { REHYDRATE } from 'redux-persist';
import { CREATE_CATEGORY_SUCCESS, LOGGED_OUT } from 'actions/types';
import * as localTypes from '../actions/types';
const initialState = {
    isFetchingBudgets: false,
    budgets: [],
};
const budgeting = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE:
            const { budgeting } = action.payload || {};
            if (budgeting) {
                return {
                    ...budgeting,
                    budgets: budgeting.budgets || [],
                    isFetchingBudgets: false,
                };
            }
            return initialState;
        case localTypes.GET_BUDGETS_REQUEST:
            return { ...state, isFetchingBudgets: true };
        case localTypes.GET_BUDGETS_FAILURE:
            return {
                ...state,
                isFetchingBudgets: false,
            };
        case localTypes.SAVE_BUDGETS_SUCCESS:
            const newMonthlyBudget = action.extra.data['overall.monthly'];
            if (newMonthlyBudget?.baseLimit === null) {
                return {
                    ...state,
                };
            }
            return {
                ...state,
                budgets: state.budgets.map((budget) => action.extra.data[budget.key]
                    ? {
                        ...budget,
                        ...action.extra.data[budget.key],
                        // in case of new or updated budget, the baseLimit will be equal to the total limit
                        totalLimit: action.extra.data[budget.key]?.baseLimit || budget.totalLimit,
                    }
                    : budget),
            };
        case CREATE_CATEGORY_SUCCESS:
            const overallLimit = state.budgets.find((budget) => budget.key === 'overall.monthly');
            // todo this illustrates well how we could normalise categories, we have data duplicated all over
            return {
                ...state,
                budgets: [
                    ...state.budgets,
                    {
                        categoryId: action.payload.category.id,
                        displayName: action.payload.category.displayName,
                        color: action.payload.category.color,
                        emoji: action.payload.category.emoji,
                        currency: overallLimit?.currency || action.extra.currency || 'GBP',
                        currentValue: 0,
                        key: `category.${action.payload.category.id}`,
                        baseLimit: null,
                        totalLimit: null,
                        previousAverage: 0,
                        shouldRollover: null,
                        rollingAccumulatedLimit: null,
                        type: 'category',
                    },
                ],
            };
        case localTypes.GET_BUDGETS_SUCCESS:
            const { budgets } = action.payload;
            for (let x = 0; x < budgets.length; x++) {
                if (budgets[x].key === 'overall.monthly') {
                    budgets[x].previousAverage =
                        Math.ceil(Math.abs(budgets[x].previousAverage) / 10) * 10;
                }
                else {
                    budgets[x].previousAverage =
                        Math.ceil(Math.abs(budgets[x].previousAverage) / 5) * 5;
                }
                // Budgets.shouldRollover is a 0/1 number, so it needs to be converted to a boolean first
                budgets[x].shouldRollover =
                    budgets[x].shouldRollover != null
                        ? Boolean(budgets[x].shouldRollover)
                        : budgets[x].shouldRollover;
            }
            return {
                ...state,
                budgets,
                isFetchingBudgets: false,
            };
        case LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
};
export default budgeting;
