const analytics = () => ({
    logEvent: async () => { },
    setUserId: async () => { },
    setUserProperties: async () => { },
});
const messaging = {
    getToken: async () => 'mock-token',
    onMessage: () => { },
};
const dynamicLinks = {
    buildLink: async (link) => link,
};
const utils = {};
export { utils, analytics, messaging, dynamicLinks };
