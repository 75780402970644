import React from 'react';
import { StyleSheet } from 'react-native';
import Text from 'design-system/Text';
import { getInitials } from './utils';
import { isWeb } from '../../constants';
export const AvatarText = ({ name, primary, lastName, fontColor, }) => {
    const initials = getInitials(name, lastName);
    if (fontColor === undefined) {
        if (lastName) {
            return (<Text {...(primary ? {} : { White: true })} Text-14 style={styles.initials}>
          {initials}
        </Text>);
        }
        return (<Text White Text-20 style={styles.initials}>
        {initials}
      </Text>);
    }
    if (isWeb) {
        return (<Text Text-14 style={styles.initials} color={fontColor}>
        {initials}
      </Text>);
    }
    return (<Text Text-20 style={styles.initials} color={fontColor}>
      {initials}
    </Text>);
};
const styles = StyleSheet.create({
    initials: {
        textTransform: 'uppercase',
    },
});
