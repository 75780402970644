import { Platform } from 'react-native';
import DefaultPreference from 'react-native-default-preference';
import * as Keychain from 'utils/packages/keychain';
import { getItems } from './jwtHelpers';
const KEYCHAIN_USER = 'emma-keychain-user';
export const storeKeychainUser = async (userId, user) => {
    try {
        const tokens = await getItems(['refresh_token', 'client_id']);
        const refreshToken = tokens?.[0]?.[1];
        const clientId = tokens?.[1]?.[1];
        const data = {
            ...user,
            refreshToken,
            clientId,
        };
        if (Platform.OS === 'android') {
            await DefaultPreference.setName(KEYCHAIN_USER);
            await DefaultPreference.set(KEYCHAIN_USER, JSON.stringify(data));
        }
        else if (Platform.OS === 'ios') {
            await Keychain.setGenericPassword(userId.toString(), JSON.stringify(data), {
                service: KEYCHAIN_USER,
            });
        }
    }
    catch (e) {
        //noop
    }
};
export const removeKeychainUser = async () => {
    try {
        if (Platform.OS === 'android') {
            await DefaultPreference.setName(KEYCHAIN_USER);
            await DefaultPreference.clear(KEYCHAIN_USER);
        }
        else if (Platform.OS === 'ios') {
            await Keychain.resetGenericPassword({
                service: KEYCHAIN_USER,
            });
        }
    }
    catch (e) {
        //noop
    }
};
export const getKeychainUser = async () => {
    try {
        if (Platform.OS === 'android') {
            await DefaultPreference.setName(KEYCHAIN_USER);
            const result = await DefaultPreference.get(KEYCHAIN_USER);
            if (result) {
                return JSON.parse(result);
            }
        }
        else if (Platform.OS === 'ios') {
            const result = await Keychain.getGenericPassword({
                service: KEYCHAIN_USER,
            });
            if (result) {
                return JSON.parse(result.password);
            }
        }
    }
    catch {
        return undefined;
    }
    return undefined;
};
