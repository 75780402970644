const getListActiveAccounts = (data, spaceId) => {
    const accounts = [];
    for (let index = 0; index < data.length; index += 1) {
        const account = { ...data[index] };
        if ('isHidden' in account &&
            !account.isHidden &&
            !account.isClosed &&
            (!spaceId || account.spaceId === spaceId))
            accounts.push(account);
    }
    return accounts;
};
export default getListActiveAccounts;
