import React from 'react';
import { LinearGradient, Path, Stop, Svg } from 'react-native-svg';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
const IconPlus = ({ color, width = rem(16), height = rem(16), ...props }) => {
    const colors = useColors();
    return (<Svg width={width} height={height} viewBox="0 0 16 16" fill="none" {...props} testID="icon-plus">
      <Path fillRule="evenodd" clipRule="evenodd" d="M8 0C6.89543 0 6 0.895431 6 2V6H2C0.895431 6 0 6.89543 0 8C0 9.10457 0.89543 10 2 10H6V14C6 15.1046 6.89543 16 8 16C9.10457 16 10 15.1046 10 14V10H14C15.1046 10 16 9.10457 16 8C16 6.89543 15.1046 6 14 6H10V2C10 0.895431 9.10457 0 8 0Z" fill={color ?? colors.text.white}/>
      <LinearGradient id="gradient" x1="12" y1="4" x2="12" y2="20" gradientUnits="userSpaceOnUse">
        <Stop stopColor={colors.gradients.peach.colors[0]}/>
        <Stop offset="1" stopColor={colors.gradients.peach.colors[1]}/>
      </LinearGradient>
    </Svg>);
};
export default IconPlus;
