export const SIGN_IN_RESET = 'SIGN_IN_RESET';
export const SIGN_IN_REQUEST = 'SIGN_IN_REUQEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const GET_APPLE_FACEBOOK_TOKEN_REQUEST = 'GET_APPLE_FACEBOOK_TOKEN_REQUEST';
export const GET_APPLE_FACEBOOK_TOKEN_SUCCESS = 'GET_APPLE_FACEBOOK_TOKEN_SUCCESS';
export const GET_APPLE_FACEBOOK_TOKEN_FAILURE = 'GET_APPLE_FACEBOOK_TOKEN_FAILURE';
export const VERIFY_CODE_REQUEST = 'VERIFY_CODE_REQUEST';
export const VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS';
export const VERIFY_CODE_FAILURE = 'VERIFY_CODE_FAILURE';
