import * as types from 'actions/types';
const initialState = {};
const signin = (state = initialState, action) => {
    switch (action.type) {
        case types.SIGN_IN_RESET: {
            return { ...initialState };
        }
        case types.SIGN_IN_SUCCESS: {
            // We need to 'collect' the paramters with each request, and send them each time
            return {
                ...state,
                signInParamters: {
                    ...action.extra,
                    sendSmsOtp: false,
                },
                currentState: action.payload,
            };
        }
        default:
            return state;
    }
};
export default signin;
