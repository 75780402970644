const setAdvertiserTrackingEnabled = () => { };
const setAdvertiserTrackingIDEnabled = () => { };
const logEvent = () => { };
const getAnalyticsInfo = () => { };
const Facebook = {
    logEvent,
    setAdvertiserTrackingEnabled,
    setAdvertiserTrackingIDEnabled,
    getAnalyticsInfo,
};
export default Facebook;
