import { Alert, PermissionsAndroid, Platform } from 'react-native';
import { launchCamera, launchImageLibrary, } from 'utils/packages/imagePicker';
import { openSettings } from 'react-native-permissions';
import { useActionSheet } from 'hooks/useActionSheet';
import { isDesktop } from '../constants';
const strings = {
    takePhoto: 'Take a photo',
    chooseFromLibrary: 'Choose from Library',
    remove: 'Remove',
    cancel: 'Cancel',
};
export const useImagePicker = ({ title, onRemove, } = {}) => {
    const { showActionSheetWithOptions } = useActionSheet();
    const requestAndroidCameraPermission = async () => {
        try {
            const granted = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.CAMERA);
            if (granted === PermissionsAndroid.RESULTS.GRANTED) {
                return true;
            }
            return false;
        }
        catch (err) {
            return false;
        }
    };
    const onDestinationSelected = async (index, onSuccess, onCancel) => {
        switch (index) {
            case 0:
                if (isDesktop) {
                    launchImageLibrary({ mediaType: 'photo' }, (response) => {
                        if (response.assets && response.assets.length) {
                            onSuccess(response.assets[0]);
                            return;
                        }
                        onCancel();
                    });
                    break;
                }
                if (Platform.OS === 'android') {
                    const hasPermission = await requestAndroidCameraPermission();
                    if (!hasPermission) {
                        Alert.alert('Emma requires Camera permission', 'Upload your profile picture or send us a screenshot. 💪', [
                            { text: 'Cancel', style: 'cancel' },
                            {
                                text: 'Open Settings',
                                onPress: openSettings,
                            },
                        ]);
                        onCancel();
                        return;
                    }
                }
                launchCamera({ saveToPhotos: false, mediaType: 'photo' }, (response) => {
                    if (response.assets && response.assets.length) {
                        onSuccess(response.assets[0]);
                        return;
                    }
                    onCancel();
                });
                break;
            case 1:
                if (isDesktop) {
                    onRemove?.();
                    break;
                }
                launchImageLibrary({ mediaType: 'photo' }, (response) => {
                    if (response.assets && response.assets.length) {
                        onSuccess(response.assets[0]);
                        return;
                    }
                    onCancel();
                });
                break;
            case 2:
                if (isDesktop) {
                    onCancel();
                    break;
                }
                onRemove?.();
                break;
            default:
                onCancel();
        }
    };
    const showImagePicker = ({ hasRemove } = {}) => new Promise((resolve) => {
        const options = isDesktop
            ? [
                strings.chooseFromLibrary,
                hasRemove && strings.remove,
                strings.cancel,
            ].filter(Boolean)
            : [
                strings.takePhoto,
                strings.chooseFromLibrary,
                hasRemove && strings.remove,
                strings.cancel,
            ].filter(Boolean);
        showActionSheetWithOptions({
            options,
            title,
            destructiveButtonIndex: hasRemove ? options.length - 2 : undefined,
            cancelButtonIndex: options.length - 1,
        }, (index) => onDestinationSelected(index, (asset) => resolve(asset), () => resolve(undefined)));
    });
    return {
        showImagePicker,
    };
};
