import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
const IconCheckBox = ({ ...props }) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="darkPink">
      <Path d="M21.66 14.542a8.198 8.198 0 0 1-7.118 7.118 20.497 20.497 0 0 1-5.084 0 8.198 8.198 0 0 1-7.118-7.118 20.495 20.495 0 0 1 0-5.084A8.198 8.198 0 0 1 9.458 2.34a20.495 20.495 0 0 1 5.084 0 8.198 8.198 0 0 1 7.118 7.118 20.497 20.497 0 0 1 0 5.084Z" fill={`url(#gradient_${id})`}/>
      <Path fillRule="evenodd" clipRule="evenodd" d="M17.06 9.107c.515.516.527 1.365.027 1.896l-4.389 4.667a2.167 2.167 0 0 1-3.178 0l-2.607-2.773c-.5-.531-.488-1.38.026-1.897a1.27 1.27 0 0 1 1.837.028l2.333 2.481 4.115-4.375a1.27 1.27 0 0 1 1.837-.027Z" fill="#fff"/>
    </IconWithGradient>);
};
export default IconCheckBox;
