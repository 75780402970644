import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
/**
 * Also has `alt`, which looks like an arrow down, and accepts `color`, `gradientColors`, and `gradientKey` overrides.
 * Default is `skyBlue` for `alt` variant.
 *
 * Standard variant looks like a cloud, and has `ultimate` gradient which is not customizable.
 *
 * Figma: https://www.figma.com/file/SMqeFyVH6qLbMzeoCHBp4A/Emma-Design-System?node-id=723%3A2620
 */
export const IconDownload = ({ ...props }) => {
    const id = useId();
    return (<IconWithGradient id={id} defaultGradientKey="ultimate" {...props}>
      <Path d="M7.738 4.002C8.698 2.83 10.185 2 12.231 2c1.242 0 2.69.41 3.844 1.353.932.763 1.648 1.86 1.901 3.306a8.039 8.039 0 0 0-9.997 9.226h-.864c-1.979 0-3.3-.6-4.112-1.53C2.208 13.448 2 12.338 2 11.5c0-1.722 1.41-4.041 4.426-4.35.125-1.064.54-2.205 1.312-3.146v-.002Zm14.724 10.421a6.578 6.578 0 1 1-13.156 0 6.578 6.578 0 0 1 13.156 0Zm-6.06 3.44 2.923-2.924a.732.732 0 0 0-1.035-1.033l-1.675 1.676V11.5a.73.73 0 1 0-1.461 0v4.082l-1.675-1.676a.732.732 0 1 0-1.035 1.034l2.923 2.923a.73.73 0 0 0 .513.214h.009a.731.731 0 0 0 .509-.21l.004-.005Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
