import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
const IconScissors = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="skyBlue">
      <Path fillRule="evenodd" clipRule="evenodd" d="M16.063 13.473a4.3 4.3 0 0 1 4.04 1.153c.83.83 1.28 1.934 1.265 3.108a4.292 4.292 0 0 1-1.34 3.078A4.292 4.292 0 0 1 17.048 22H16.995a4.297 4.297 0 0 1-3.005-1.261 4.3 4.3 0 0 1-1.152-4.039L3.154 7.018a3.952 3.952 0 0 1 0-5.582L3.59 1l12.473 12.473Zm-5.169-1.28c.015.334.27.616.616.616.322 0 .631-.283.616-.616-.015-.334-.27-.616-.616-.616-.322 0-.63.283-.616.616Zm6.152 7.434c.498 0 .996-.19 1.376-.569a1.948 1.948 0 0 0-1.376-3.32 1.947 1.947 0 0 0-1.376 3.32c.38.38.878.57 1.376.57Zm3.168-12.61a3.952 3.952 0 0 0 0-5.581L19.778 1 12.7 8.08l3.227 3.226 4.288-4.288ZM7.305 13.475a4.3 4.3 0 0 0-4.039 1.153A4.29 4.29 0 0 0 2 17.734a4.291 4.291 0 0 0 1.34 3.079A4.291 4.291 0 0 0 6.376 22a4.297 4.297 0 0 0 3.005-1.261 4.3 4.3 0 0 0 1.152-4.038l.486-.486-3.227-3.227-.486.486Zm.393 5.585a1.94 1.94 0 0 1-1.375.569 1.94 1.94 0 0 1-1.376-.57 1.947 1.947 0 0 1 1.376-3.32 1.947 1.947 0 0 1 1.376 3.32Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default IconScissors;
