import React from 'react';
import { View, StyleSheet } from 'react-native';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import MerchantLogo from './MerchantLogo';
import { isWeb } from '../../../../constants';
const KeyboardMerchantsRow = ({ item, onPress }) => {
    const colors = useColors();
    if (!item) {
        return null;
    }
    return (<TouchableHighlight hoverOnLight onCard onPress={onPress} underlayColor={colors.background.underlay}>
      <View style={styles.container}>
        <MerchantLogo data={item}/>
        <Spacer w={16}/>
        <Text Text-14 flex>
          {item.displayName}
        </Text>
      </View>
    </TouchableHighlight>);
};
const styles = StyleSheet.create({
    container: {
        paddingVertical: rem(16),
        paddingHorizontal: rem(16),
        flexDirection: 'row',
        alignItems: 'center',
        ...(isWeb && {
            paddingHorizontal: 8,
        }),
    },
});
export default KeyboardMerchantsRow;
