import { REHYDRATE } from 'redux-persist';
import * as types from '../actions/types';
const initialState = {
    monitoredAccPhones: [],
    monitoredAccEmails: [],
    breaches: [],
    breachPage: 1,
    breachPageCount: 1,
};
const dataProtection = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE:
            const dataProtection = action.payload?.linkBank;
            if (dataProtection)
                return { ...initialState };
            return state;
        case types.GET_MONITORED_ACC_SUCCESS:
            return {
                ...state,
                monitoredAccPhones: action.payload?.monitoredAccounts?.filter((acc) => acc.accountType === 'phone'),
                monitoredAccEmails: action.payload?.monitoredAccounts?.filter((acc) => acc.accountType === 'email'),
            };
        case types.GET_BREACHES_SUCCESS:
            return {
                ...state,
                breaches: action.payload?.breaches,
                breachPage: action.payload?.paging?.page,
                breachPageCount: action.payload?.paging?.pageCount,
            };
        case types.RESET_BREACHES:
            return {
                ...state,
                breaches: [],
                breachPage: 1,
                breachPageCount: 1,
            };
        case types.LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
};
export default dataProtection;
