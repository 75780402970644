import React from 'react';
import * as SVG from 'react-native-svg';
import { rem } from '../values';
import useColors from '../../hooks/useColors';
const IconChatFloating = ({ color }) => {
    const size = rem(56);
    const colors = useColors();
    return (<SVG.Svg width={size} height={size} viewBox="0 0 56 56" fill="none">
      <SVG.Path d="M0 28C0 9.00789 9.00789 0 28 0C46.9921 0 56 9.00789 56 28C56 46.9921 46.9921 56 28 56C9.00789 56 0 46.9921 0 28Z" fillRule="evenodd" clipRule="evenodd" fill="url(#paint0_linear)"/>
      <SVG.Path d="M28.0002 16.3333C34.4437 16.3333 39.6668 21.5564 39.6668 27.9999C39.6668 34.4434 34.4437 39.6666 28.0002 39.6666C26.1128 39.6691 24.2532 39.2118 22.5822 38.3343L17.5772 39.6374C17.4099 39.681 17.2342 39.6801 17.0674 39.6348C16.9007 39.5895 16.7486 39.5015 16.6264 39.3793C16.5041 39.2572 16.4159 39.1052 16.3705 38.9385C16.325 38.7717 16.3239 38.596 16.3673 38.4288L17.6693 33.4249C16.7894 31.7522 16.3309 29.89 16.3335 27.9999C16.3335 21.5564 21.5567 16.3333 28.0002 16.3333ZM29.4608 29.1666H24.2085L24.0895 29.1748C23.8801 29.2036 23.6882 29.3072 23.5492 29.4666C23.4103 29.6259 23.3338 29.8302 23.3338 30.0416C23.3338 30.253 23.4103 30.4572 23.5492 30.6166C23.6882 30.7759 23.8801 30.8796 24.0895 30.9084L24.2085 30.9166H29.4608L29.5787 30.9084C29.7881 30.8796 29.98 30.7759 30.1189 30.6166C30.2579 30.4572 30.3344 30.253 30.3344 30.0416C30.3344 29.8302 30.2579 29.6259 30.1189 29.4666C29.98 29.3072 29.7881 29.2036 29.5787 29.1748L29.4608 29.1666ZM31.7918 25.0833H24.2085L24.0895 25.0914C23.8801 25.1202 23.6882 25.2239 23.5492 25.3832C23.4103 25.5426 23.3338 25.7468 23.3338 25.9583C23.3338 26.1697 23.4103 26.3739 23.5492 26.5333C23.6882 26.6926 23.8801 26.7963 24.0895 26.8251L24.2085 26.8333H31.7918L31.9108 26.8251C32.1203 26.7963 32.3122 26.6926 32.4511 26.5333C32.59 26.3739 32.6666 26.1697 32.6666 25.9583C32.6666 25.7468 32.59 25.5426 32.4511 25.3832C32.3122 25.2239 32.1203 25.1202 31.9108 25.0914L31.7918 25.0833Z" fill={color || colors.elements.white}/>
      <SVG.Defs>
        <SVG.LinearGradient id="paint0_linear" x1="39.8837" y1="43.8118" x2="-7.59902" y2="26.1574" gradientUnits="userSpaceOnUse">
          <SVG.Stop stopColor={colors.gradients.ultimate.colors[0]}/>
          <SVG.Stop offset="1" stopColor={colors.gradients.ultimate.colors[1]}/>
        </SVG.LinearGradient>
      </SVG.Defs>
    </SVG.Svg>);
};
export default IconChatFloating;
