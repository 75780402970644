import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { View, TouchableWithoutFeedback } from 'react-native';
import { interpolateColor, useAnimatedStyle, } from 'react-native-reanimated';
import { useHeaderHeight } from '@react-navigation/elements';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import FakeSearchBar from 'design-system/FakeSearchBar';
import { SEARCH_BAR_HEIGHT } from 'design-system/SearchBar';
import IconFilterBold from 'design-system/icons/IconFilterBold';
import { AnimatedTouchableBounce } from 'design-system/AnimatedComponents';
import MultiSelectAccountModal from 'design-system/MultiSelectAccountModal';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { useAppSelector } from 'store/hooks';
import { selectListAccounts } from 'reducers/selectors';
import createStyleSheets from 'utils/createStyleSheets';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { isWeb } from '../../../constants';
import { useOpenSearch } from '../hooks/useOpenSearch';
const checkEquality = (obj1, obj2) => {
    const obj1Keys = Object.keys(obj1);
    const obj2Keys = Object.keys(obj2);
    if (obj1Keys.length === obj2Keys.length) {
        let areTheSame = true;
        obj1Keys.every((key) => {
            const hasKeyInObj2 = !!obj2[Number(key)];
            if (!hasKeyInObj2) {
                areTheSame = false;
                return false;
            }
            return true;
        });
        return areTheSame;
    }
    return false;
};
const TxnScreenNavHeaderExtension = ({ scrollY, filters, setFilters }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const accounts = useAppSelector(selectListAccounts);
    const accountsWithoutHidden = useMemo(() => {
        const filtered = accounts.filter((acc) => !acc.isHidden);
        return filtered;
    }, [accounts]);
    const openSearch = useOpenSearch();
    const { top } = useSafeAreaInsets();
    const headerHeight = useHeaderHeight();
    const selectedAccountsRef = useRef([]);
    useEffect(() => {
        selectedAccountsRef.current = accountsWithoutHidden.map((acc) => acc.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleSave = useCallback((selectedAccounts, showExcludedTxns) => {
        const accs = selectedAccounts.length === accountsWithoutHidden.length
            ? []
            : selectedAccounts;
        const newSelectedAccObj = accs.reduce((a, b) => ({
            ...a,
            [b]: true,
        }), {});
        if (filters.showExcludedTxns !== showExcludedTxns ||
            !checkEquality(filters.selectedAccountsObj, newSelectedAccObj)) {
            selectedAccountsRef.current = selectedAccounts;
            setFilters(newSelectedAccObj, !!showExcludedTxns);
        }
    }, [
        accountsWithoutHidden.length,
        filters.selectedAccountsObj,
        filters.showExcludedTxns,
        setFilters,
    ]);
    const handleFilterPress = useCallback(() => {
        MultiSelectAccountModal.show({
            accounts,
            showSelectAll: true,
            onSelected: handleSave,
            alreadySelected: selectedAccountsRef.current,
            showExcludedTxns: filters.showExcludedTxns,
        });
    }, [accounts, filters.showExcludedTxns, handleSave]);
    const handleSearchPress = useCallback(() => {
        openSearch(isWeb
            ? undefined
            : {
                searchBarY: headerHeight - top,
            });
    }, [headerHeight, openSearch, top]);
    const bgColorStyle = useAnimatedStyle(() => ({
        backgroundColor: interpolateColor(scrollY.value, [0, 40], [
            colors.cards.onDark,
            colors.background.dark,
        ]),
    }), [colors.background.dark, colors.cards.onDark, scrollY.value]);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    return (<View style={[styles.container, paddingHorizontalStyle]}>
        <TouchableWithoutFeedback onPress={handleSearchPress} style={styles.searchBarBtn}>
          <View pointerEvents="box-only" style={styles.flex}>
            <FakeSearchBar scrollY={scrollY} style={styles.fakeSearchBar}/>
          </View>
        </TouchableWithoutFeedback>
        {(!isWeb || !!accounts?.length) && (<>
            <Spacer w={8}/>
            <AnimatedTouchableBounce onPress={handleFilterPress} hoverStyle={styles.hoverOnDark} style={[styles.filter, bgColorStyle]}>
              <IconFilterBold color={colors.text.secondary}/>
            </AnimatedTouchableBounce>
          </>)}
      </View>);
};
export default memo(TxnScreenNavHeaderExtension);
const styleSet = createStyleSheets((colors) => ({
    searchBarBtn: {
        flex: 1,
        ...(isWeb && {
            cursor: 'pointer',
        }),
    },
    container: {
        flexDirection: 'row',
        paddingBottom: rem(8),
    },
    flex: {
        flex: 1,
    },
    fakeSearchBar: {
        paddingRight: 0,
        marginBottom: 0,
    },
    filter: {
        aspectRatio: 1,
        marginTop: rem(8),
        marginRight: rem(16),
        alignItems: 'center',
        borderRadius: rem(12),
        justifyContent: 'center',
        height: SEARCH_BAR_HEIGHT,
    },
    hoverOnDark: {
        backgroundColor: colors.control.hoverOnDark,
    },
}));
