import { put, takeLatest } from 'typed-redux-saga/macro';
import { getEmmaWalletActivitiesHistory } from 'actions/emmaWallet';
import { CANCEL_ORDER_SUCCESS, CREATE_ORDER_SUCCESS, } from 'features/invest/actions/types';
function* fetchWalletActivitiesWorker() {
    yield* put(getEmmaWalletActivitiesHistory({ page: 1, perPage: 30 }));
}
function* refreshWalletActivitiesSaga() {
    yield* takeLatest((action) => [
        'WITHDRAW_SUCCESS',
        CREATE_ORDER_SUCCESS,
        CANCEL_ORDER_SUCCESS,
        'DEPOSIT_POT_SUCCESS',
        'WITHDRAW_POT_SUCCESS',
    ].includes(action.type), fetchWalletActivitiesWorker);
}
export default refreshWalletActivitiesSaga;
