import { put, select, takeEvery } from 'typed-redux-saga/macro';
import { getFeed } from 'actions';
import { CANCEL_SUBSCRIPTIONS_SUCCESS, CHANGE_DATE_TRANSACTION_SUCCESS, CHOOSE_RETAINED_CONNECTIONS_SUCCESS, CLOSED_CONNECTION_SUCCESS, CLOSE_ACCOUNT_SUCCESS, CREATE_ACCOUNT_SUCCESS, CREATE_TRANSACTION_SUCCESS, DELETE_ACCOUNT_SUCCESS, DELETE_CATEGORY_SUCCESS, DELETE_CONNECTION_SUCCESS, DELETE_INCOME_SUCCESS, DELETE_TRANSACTION_SUCCESS, DISMISS_QUIZ_SUCCESS, EDIT_ACCOUNT_SUCCESS, EDIT_CATEGORY_SUCCESS, EDIT_SUBSCRIPTIONS_SUCCESS, HIDE_SUCCESS, ON_SUBMIT_FEEDBACK_SUCCESS, REFRESH_ON_START, SAVE_TOTAL_BUDGET_SUCCESS, SET_ACCOUNT_TYPE_SUCCESS, SET_CONNECTIONS_STATUS, SET_INCOME_SUCCESS, SET_PRIMARY_INCOME_SUCCESS, SPLIT_TRANSACTION_SUCCESS, UNSPLIT_TRANSACTION_SUCCESS, UPDATE_CATEGORY_SUCCESS, } from 'actions/types';
import { checkIfNeedToRefreshMonthlyTotals } from 'utils/api/refresh';
import { selectIsFeedRefreshing } from '../selectors';
function* refreshFeedSaga(action) {
    if (action.payload === true) {
        return;
    }
    const isRefreshing = yield* select(selectIsFeedRefreshing);
    if (isRefreshing) {
        return;
    }
    if (checkIfNeedToRefreshMonthlyTotals(action)) {
        yield* put(getFeed());
    }
}
function* feedSaga() {
    yield* takeEvery((action) => [
        CANCEL_SUBSCRIPTIONS_SUCCESS,
        CHANGE_DATE_TRANSACTION_SUCCESS,
        CLOSED_CONNECTION_SUCCESS,
        CLOSE_ACCOUNT_SUCCESS,
        CREATE_TRANSACTION_SUCCESS,
        DELETE_ACCOUNT_SUCCESS,
        DELETE_CATEGORY_SUCCESS,
        DELETE_CONNECTION_SUCCESS,
        DELETE_INCOME_SUCCESS,
        DELETE_TRANSACTION_SUCCESS,
        DISMISS_QUIZ_SUCCESS,
        EDIT_SUBSCRIPTIONS_SUCCESS,
        EDIT_CATEGORY_SUCCESS,
        HIDE_SUCCESS,
        ON_SUBMIT_FEEDBACK_SUCCESS,
        REFRESH_ON_START,
        EDIT_ACCOUNT_SUCCESS,
        CREATE_ACCOUNT_SUCCESS,
        SAVE_TOTAL_BUDGET_SUCCESS,
        SET_ACCOUNT_TYPE_SUCCESS,
        SET_CONNECTIONS_STATUS,
        SET_INCOME_SUCCESS,
        SET_PRIMARY_INCOME_SUCCESS,
        SPLIT_TRANSACTION_SUCCESS,
        UNSPLIT_TRANSACTION_SUCCESS,
        UPDATE_CATEGORY_SUCCESS,
        CHOOSE_RETAINED_CONNECTIONS_SUCCESS,
    ].includes(action.type), refreshFeedSaga);
}
export default feedSaga;
