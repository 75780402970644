import React from 'react';
import { rem } from 'design-system';
import * as SVG from 'react-native-svg';
import useColors from 'hooks/useColors';
export const IconClose = ({ size = 24, color }) => {
    const width = rem(size);
    const height = rem(size);
    const colors = useColors();
    return (<SVG.Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <SVG.Path fillRule="evenodd" clipRule="evenodd" d="M18.4246 5.49989C17.8388 4.9141 16.889 4.9141 16.3032 5.49989L11.8639 9.93923L7.69665 5.77196C7.11086 5.18618 6.16111 5.18618 5.57533 5.77196C4.98954 6.35775 4.98954 7.3075 5.57533 7.89328L9.74259 12.0605L5.69665 16.1065C5.11086 16.6923 5.11086 17.642 5.69665 18.2278C6.28243 18.8136 7.23218 18.8136 7.81797 18.2278L11.8639 14.1819L16.1819 18.4999C16.7677 19.0857 17.7175 19.0857 18.3032 18.4999C18.889 17.9141 18.889 16.9644 18.3032 16.3786L13.9852 12.0605L18.4246 7.62121C19.0104 7.03542 19.0104 6.08567 18.4246 5.49989Z" fill={color || colors.text.secondary}/>
    </SVG.Svg>);
};
export default IconClose;
