import { Tier } from 'features/premium/entities';
export const getRequiredTier = (connectionsLimitMap, requiredConnections) => {
    if (connectionsLimitMap) {
        if (connectionsLimitMap.FREE !== null && requiredConnections > connectionsLimitMap.FREE) {
            if (connectionsLimitMap.PLUS !== null && requiredConnections > connectionsLimitMap.PLUS) {
                if (connectionsLimitMap.PRO !== null && requiredConnections > connectionsLimitMap.PRO) {
                    return Tier.ultimate;
                }
                return Tier.pro;
            }
            return Tier.plus;
        }
    }
    return Tier.free;
};
