export const LINK_PHONE_GET_CODE_REQUEST = 'LINK_PHONE_GET_CODE_REQUEST';
export const LINK_PHONE_GET_CODE_SUCCESS = 'LINK_PHONE_GET_CODE_SUCCESS';
export const LINK_PHONE_GET_CODE_FAILURE = 'LINK_PHONE_GET_CODE_FAILURE';
export const LINK_PHONE_VERIFY_CODE_REQUEST = 'LINK_PHONE_VERIFY_CODE_REQUEST';
export const LINK_PHONE_VERIFY_CODE_SUCCESS = 'LINK_PHONE_VERIFY_CODE_SUCCESS';
export const LINK_PHONE_VERIFY_CODE_FAILURE = 'LINK_PHONE_VERIFY_CODE_FAILURE';
export const GET_TRADING_ACCOUNT_REQUEST = 'GET_TRADING_ACCOUNT_REQUEST';
export const GET_TRADING_ACCOUNT_SUCCESS = 'GET_TRADING_ACCOUNT_SUCCESS';
export const GET_TRADING_ACCOUNT_FAILURE = 'GET_TRADING_ACCOUNT_FAILURE';
export const CACHE_TRADING_ACCOUNT = 'CACHE_TRADING_ACCOUNT';
export const GET_CONNECTED_ACCOUNT_REQUEST = 'GET_CONNECTED_ACCOUNT_REQUEST';
export const GET_CONNECTED_ACCOUNT_SUCCESS = 'GET_CONNECTED_ACCOUNT_SUCCESS';
export const GET_CONNECTED_ACCOUNT_FAILURE = 'GET_CONNECTED_ACCOUNT_FAILURE';
export const GET_STOCK_REQUEST = 'GET_STOCK_REQUEST';
export const GET_STOCK_SUCCESS = 'GET_STOCK_SUCCESS';
export const GET_STOCK_FAILURE = 'GET_STOCK_FAILURE';
export const GET_STOCK_META_REQUEST = 'GET_STOCK_META_REQUEST';
export const GET_STOCK_META_SUCCESS = 'GET_STOCK_META_SUCCESS';
export const GET_STOCK_META_FAILURE = 'GET_STOCK_META_FAILURE';
export const GET_ACTIVITIES_REQUEST = 'GET_ACTIVITIES_REQUEST';
export const GET_ACTIVITIES_SUCCESS = 'GET_ACTIVITIES_SUCCESS';
export const GET_ACTIVITIES_FAILURE = 'GET_ACTIVITIES_FAILURE';
export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE';
export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE';
export const GET_POSITIONS_REQUEST = 'GET_POSITIONS_REQUEST';
export const GET_POSITIONS_SUCCESS = 'GET_POSITIONS_SUCCESS';
export const GET_POSITIONS_FAILURE = 'GET_POSITIONS_FAILURE';
export const GET_TRUSTED_CONTACTS_REQUEST = 'GET_TRUSTED_CONTACTS_REQUEST';
export const GET_TRUSTED_CONTACTS_SUCCESS = 'GET_TRUSTED_CONTACTS_SUCCESS';
export const GET_TRUSTED_CONTACTS_FAILURE = 'GET_TRUSTED_CONTACTS_FAILURE';
export const ADD_TRUSTED_CONTACT_REQUEST = 'ADD_TRUSTED_CONTACT_REQUEST';
export const ADD_TRUSTED_CONTACT_SUCCESS = 'ADD_TRUSTED_CONTACT_SUCCESS';
export const ADD_TRUSTED_CONTACT_FAILURE = 'ADD_TRUSTED_CONTACT_FAILURE';
export const EDIT_TRUSTED_CONTACT_REQUEST = 'EDIT_TRUSTED_CONTACT_REQUEST';
export const EDIT_TRUSTED_CONTACT_SUCCESS = 'EDIT_TRUSTED_CONTACT_SUCCESS';
export const EDIT_TRUSTED_CONTACT_FAILURE = 'EDIT_TRUSTED_CONTACT_FAILURE';
export const CREATE_TRADING_ACCOUNT_REQUEST = 'CREATE_TRADING_ACCOUNT_REQUEST';
export const CREATE_TRADING_ACCOUNT_SUCCESS = 'CREATE_TRADING_ACCOUNT_SUCCESS';
export const CREATE_TRADING_ACCOUNT_FAILURE = 'CREATE_TRADING_ACCOUNT_FAILURE';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const GET_SEARCH_RESULTS_SUCCESS = 'GET_SEARCH_RESULTS_SUCCESS';
export const CACHE_CURRENT_STOCK_PRICE = 'CACHE_CURRENT_STOCK_PRICE';
export const SET_ACCOUNT_STATUS = 'SET_ACCOUNT_STATUS';
