import React, { useEffect } from 'react';
import { DaysOfTheMonthSelector } from 'design-system/date';
const FixedDateView = ({ days, onChange, ...props }) => {
    useEffect(() => {
        onChange([days?.[0] || 1]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<DaysOfTheMonthSelector days={days} onChange={onChange} maxNumberOfDays={1} {...props}/>);
};
export default FixedDateView;
