import React, { useCallback } from 'react';
import { SettingsItem } from 'design-system';
import * as Icons from 'design-system/icons';
import { tierThemes } from 'features/premium/benefits';
import { Tier } from 'features/premium/entities';
import { selectUserTier } from 'features/premium/selectors/tier';
import useColors from 'hooks/useColors';
import { useAppSelector } from 'store/hooks';
import { useMainStackNavigation } from 'utils/types/navigationV6';
const strings = {
    manageSubscriptions: {
        title: 'Manage your subscription',
        tierTitles: {
            [Tier.free]: 'Free Plan',
            [Tier.plus]: tierThemes.PLUS.name,
            [Tier.pro]: tierThemes.PRO.name,
            [Tier.ultimate]: tierThemes.ULTIMATE.name,
        },
    },
};
export const ManageSubscriptionsItem = () => {
    const colors = useColors();
    const tier = useAppSelector(selectUserTier);
    const navigation = useMainStackNavigation();
    const onPressManage = useCallback(() => {
        navigation.navigate('ManagePremiumSubscription');
    }, []);
    return (<SettingsItem title={strings.manageSubscriptions.title} description={tier
            ? strings.manageSubscriptions.tierTitles[tier] ?? undefined
            : undefined} onPress={onPressManage} icon={<Icons.IconStar color={colors.elements.brand}/>}/>);
};
