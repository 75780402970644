import { call, select, spawn, takeEvery } from 'typed-redux-saga/macro';
import { REGISTER_USER_SUCCESS } from 'actions/types';
import { selectUser } from 'reducers/selectors';
import { navigationRef } from 'utils/navigationv6';
import { updateTradingAccount } from '../api/account';
import { selectAccountStatus } from '../reducers/selectors';
import topupSaga from './topupSaga';
import withdrawSagas from './withdrawSagas';
function* fetchTradingAccountStatus() {
    const user = yield* select(selectUser);
    if (user.guessedHomeCountry === 'GB') {
        log('[investSaga] fetching trading account status...', false, 'cyan');
        yield* call(updateTradingAccount, true);
    }
    else {
        log('[investSaga] skipping, user is not GB', false, 'cyan');
    }
}
function* handleInvestInvite() {
    const user = yield* select(selectUser);
    const isGB = user.guessedHomeCountry === 'GB';
    const status = yield* select(selectAccountStatus);
    if (isGB && status !== 'ACTIVE') {
        if (navigationRef.isReady()) {
            navigationRef.navigate('Invest');
        }
    }
}
function* investSaga() {
    log('[investSaga] starting...', false, 'cyan');
    yield* takeEvery(REGISTER_USER_SUCCESS, fetchTradingAccountStatus);
    yield* takeEvery((action) => action.type === 'SET_REFERRER_ID', handleInvestInvite);
    yield* spawn(topupSaga);
    yield* spawn(withdrawSagas);
}
export default investSaga;
