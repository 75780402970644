export const useFakeData = __DEV__ && false; // please always use __DEV__ && something
export const useFakeOrderPreview = __DEV__ && false;
// export const fakeStatus: invest.TradingAccountStatus | false = __DEV__ && 'ACTIVE';
export const fakeStatus = __DEV__ && false;
// export const fakeRequiredUserAction: invest.IdentityVerificationStep | false = __DEV__ && 'UPLOAD_PROOF_OF_ADDRESS';
export const fakeRequiredUserAction = __DEV__ && false;
export const emulateNoConnectedAccount = __DEV__ && false;
/**
 * This will show "Your top-up is pending" in InvestAccount,
 * and will also trigger periodic fetch for `/pending-top-ups` endpoint for 90 seconds.
 */
export const emulatePendingTopups = __DEV__ && false;
export const fakeAccount = __DEV__ &&
    false && {
    currency: 'GBP',
    availableToInvest: { value: 10000, currency: 'GBP' },
    cashWithdrawable: { value: 3000, currency: 'GBP' },
    cashUnsettled: { value: 2000, currency: 'GBP' },
    cashReserved: { value: 4000, currency: 'GBP' },
    cashTotal: { value: 10000, currency: 'GBP' },
    longPositionsMarketValue: { value: 7500, currency: 'GBP' },
    status: 'ACTIVE',
    requiredUserAction: undefined,
    timestamp: new Date(2021, 1, 1).toISOString(), // date/time when account was created (in ISO format)
};
export const showPortfolioModeSwitch = __DEV__ && false;
export const showUploadDocumentButton = __DEV__ && false;
export const fakeNullsInPortfolioData = __DEV__ && false;
export const disableAnimatedChartRendering = false;
/* Stock Search customizations */
export const LOAD_NEXT_PAGE_RIGHT_AWAY = false;
