import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import IconArrowTriangle from 'design-system/icons/IconArrowTriangle';
import useColors from 'hooks/useColors';
import TouchableOpacity from 'utils/packages/TouchableOpacity';
const arrowSize = rem(8);
const AnalyticsControlsChip = ({ title, onLight, onPress, disabled, toolTip, }) => {
    const colors = useColors();
    return (<TouchableOpacity style={[
            styles.chip,
            {
                backgroundColor: onLight ? colors.cards.onLight : colors.cards.onDark,
            },
        ]} onPress={onPress} disabled={disabled} {...(onLight ? { hoverOnLight: true } : { hoverOnDark: true })}>
      <Text Text-14 {...(!onLight && { Secondary: true })} style={!!toolTip && styles.titleWithToolTip}>
        {title}
      </Text>
      {toolTip}
      {!disabled && (<IconArrowTriangle width={arrowSize} height={arrowSize} style={styles.arrowIcon} color={colors.elements.secondary}/>)}
    </TouchableOpacity>);
};
const styles = StyleSheet.create({
    chip: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: rem(8),
        paddingHorizontal: rem(16),
        paddingVertical: rem(6),
        minHeight: rem(31),
        marginRight: rem(12),
    },
    arrowIcon: {
        marginLeft: rem(8),
    },
    titleWithToolTip: {
        marginEnd: rem(8),
    },
});
export default memo(AnalyticsControlsChip);
