import { useCallback } from 'react';
import Config from 'utils/packages/configs';
import { GoogleSignin, statusCodes } from 'utils/packages/rnGoogleSignin';
const useGoogleSignIn = () => {
    const signIn = useCallback(async () => {
        GoogleSignin.configure({
            iosClientId: Config.GOOGLE_IOS_ID,
            webClientId: Config.GOOGLE_WEB_ID,
        });
        try {
            await GoogleSignin.hasPlayServices();
            try {
                /**
                 * This is so that the user can select from many google accounts every time
                 */
                await GoogleSignin.signOut();
            }
            catch (_) {
                // noop
            }
            const userInfo = await GoogleSignin.signIn();
            if (userInfo.idToken) {
                return { token: userInfo.idToken };
            }
        }
        catch (error) {
            if ('code' in error) {
                if (error.code === statusCodes.SIGN_IN_CANCELLED) {
                    // user cancelled the login flow
                    return undefined;
                }
                if (error.code === statusCodes.IN_PROGRESS) {
                    // operation (e.g. sign in) is in progress already
                    return undefined;
                }
                if (error.code === statusCodes.PLAY_SERVICES_NOT_AVAILABLE) {
                    // todo handle
                    throw error;
                    // play services not available or outdated
                }
            }
            else {
                throw error;
                // some other error happened
            }
        }
        return undefined;
    }, []);
    return [signIn];
};
export default useGoogleSignIn;
