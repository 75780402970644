import React, { useEffect, useRef } from 'react';
import { Animated } from 'react-native';
export default (props) => {
    const coef = props.flipHorizontal ? -1 : 1;
    const rotate = props.rotate ?? '0deg';
    const delay = props.delay ?? 0;
    const animatedValue = useRef(new Animated.Value(0.6)).current;
    useEffect(() => {
        const animation = Animated.sequence([
            Animated.delay(delay),
            Animated.loop(Animated.sequence([
                Animated.timing(animatedValue, {
                    toValue: 1,
                    duration: 100,
                    useNativeDriver: true,
                }),
                Animated.timing(animatedValue, {
                    toValue: 4,
                    duration: 1000,
                    useNativeDriver: true,
                }),
                Animated.timing(animatedValue, {
                    toValue: 0,
                    duration: 0,
                    useNativeDriver: true,
                }),
            ])),
        ]);
        animation.start();
        return () => {
            animation.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<Animated.Image source={require('../img/zaps.png')} style={[
            props.style,
            {
                opacity: animatedValue.interpolate({
                    inputRange: [0.6, 1, 4],
                    outputRange: [1, 1, 0],
                }),
                transform: [
                    {
                        scaleX: animatedValue.interpolate({
                            inputRange: [0.6, 4],
                            outputRange: [coef * 0.6, coef * 4],
                        }),
                    },
                    { scaleY: animatedValue },
                    { rotate },
                ],
            },
        ]}/>);
};
