import React from 'react';
import { View } from 'react-native';
import Squircle from 'design-system/Squircle';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { isWeb } from '../../../constants';
export const BudgetActionRow = ({ style, title, icon, rightContent, }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    return (<View style={[styles.container, style]}>
      <Squircle color={colors.background.dark}>{icon}</Squircle>
      <View style={styles.innerContent}>
        <Text Text-16 style={styles.displayName}>
          {title}
        </Text>
        {rightContent}
      </View>
    </View>);
};
const styleSet = createStyleSheets(() => ({
    container: {
        flex: 1,
        padding: rem(16),
        flexDirection: 'row',
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
    innerContent: {
        flex: 1,
        marginStart: rem(16),
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    displayName: {
        paddingEnd: rem(12),
    },
}));
