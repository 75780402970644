import React, { memo, useCallback, useImperativeHandle, useRef, useState, } from 'react';
import { View, TextInput } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { useAutoFocusInput } from 'hooks/useAutoFocusInput';
import { isWeb } from '../../constants';
const DateInputField = React.forwardRef(({ title, placeholder, maxLength, autoFocus, value, error, onDark, onChangeText, onSubmitEditing, }, ref) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const [focused, setFocused] = useState(false);
    const textRef = useRef(null);
    useImperativeHandle(ref, () => ({
        focus: () => textRef.current?.focus(),
    }));
    const setFocusedState = useCallback((value) => () => {
        setFocused(value);
    }, []);
    useAutoFocusInput(autoFocus ? textRef : null);
    return (<View style={styles.container}>
        <Text Text-16>{title}</Text>
        <TextInput value={value} ref={textRef} maxLength={maxLength} keyboardType="numeric" allowFontScaling={false} placeholder={placeholder} onChangeText={onChangeText} onBlur={setFocusedState(false)} onFocus={setFocusedState(true)} selectionColor={colors.text.selection} onSubmitEditing={onSubmitEditing} style={[
            styles.textInput,
            onDark && styles.textInputOnDark,
            focused && styles.focused,
            focused && error && styles.error,
            title === 'Year' && styles.year,
        ]}/>
      </View>);
});
const styleSet = createStyleSheets((colors) => ({
    container: {
        ...(isWeb
            ? { marginRight: rem(24) }
            : {
                flex: 1,
                alignItems: 'center',
            }),
    },
    textInput: {
        marginTop: 5,
        width: rem(56),
        borderWidth: 1,
        height: rem(56),
        fontSize: rem(24),
        textAlign: 'center',
        borderRadius: rem(12),
        fontFamily: 'Nunito-Bold',
        fontWeight: '800',
        color: colors.text.secondary,
        borderColor: colors.cards.onLight,
        backgroundColor: colors.cards.onLight,
    },
    textInputOnDark: {
        borderColor: colors.cards.onDark,
        backgroundColor: colors.cards.onDark,
    },
    year: {
        minWidth: rem(74),
    },
    focused: {
        borderColor: colors.text.brand,
        color: colors.text.brand,
    },
    error: {
        borderColor: colors.text.negative,
        color: colors.text.negative,
    },
}));
export default memo(DateInputField);
