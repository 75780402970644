import { PhoneNumberUtil } from 'google-libphonenumber';
export const extractCountryCode = (phoneNumber) => {
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
        return phoneUtil.getRegionCodeForNumber(phoneUtil.parse(phoneNumber));
    }
    catch {
        return undefined;
    }
};
export const validateUKPostcode = (postcode) => {
    const postcode_regex = /^(gir\s?0aa|[a-z]{1,2}\d[\da-z]?\s?(\d[a-z]{2})?)$/i;
    return !!postcode.toUpperCase().match(postcode_regex);
};
export const validateUSPostcode = (postcode) => {
    const postcode_regex = /\d{5}([ -]\d{4})?/g;
    return !!postcode.toUpperCase().match(postcode_regex);
};
export const validateEmail = (email) => {
    const email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !!email.trim().match(email_regex);
};
export const isPrivateEmail = (email) => email.toLocaleLowerCase().includes('@privaterelay.appleid.com');
export const validateName = (name) => {
    if (name.length < 2) {
        return false;
    }
    const nameRegex = /[^\p{L}\p{Z}\-'`’]/gu;
    return !nameRegex.test(name);
};
/**
 * Validates a string against UK National Insurance Number regex found on https://www.gov.uk/hmrc-internal-manuals/national-insurance-manual/nim39110#:~:text=NIM39100-,NIM39110%20%2D%20National%20Insurance%20Numbers%20(NINOs)%3A%20Format%20and%20Security%3A,B%2C%20C%2C%20or%20D.&text=This%20is%20an%20example%20only,used%20as%20an%20actual%20number
 * UK National Insurance Number (NINo) validation.
 * Rules:
 * - The first letter may not be D, F, I, Q, U or V;
 * - The second letter may not be D, F, I, Q, U, O or V;
 * - Prefixes BG, GB, KN, NK, NT, TN and ZZ are not to be used.
 * - Prefixes OO, CR, FY, MW, NC, PP, PZ and TN are not allowed too.
 * - The suffix can be A, B, C or D.
 *
 * Matches      `JG103759A` | `AP019283D` | `ZX047829C`
 *
 * Non-Matches  `DC135798A` | `FQ987654C` | `KL192845T`
 *
 * @param nationalInsuranceNumber UK National Insurance Number, as a string
 */
export const validateNIN = (nationalInsuranceNumber) => {
    const ninRegex = /^(?!BG|GB|NK|KN|TN|NT|ZZ|OO|CR|FY|MW|NC|PP|PZ|TN)(?![DFIQUV])[A-Z](?![DFIOQUV])[A-Z](?:\s?\d){6}\s?[A-D]$/;
    return Boolean(nationalInsuranceNumber.replace(/\s/g, '').match(ninRegex));
};
export const isUnderAge = (date, requiredAge) => {
    const now = new Date();
    const age = now.getFullYear() - date.getFullYear();
    const month = now.getMonth() - date.getMonth();
    const day = now.getDate() - date.getDate();
    return (age < requiredAge ||
        (age === requiredAge && month < 0) ||
        (age === requiredAge && month === 0 && day < 0));
};
// code taken from server repo
export const isUnder18 = (date) => isUnderAge(date, 18);
export const undefIfEmpty = (str) => str && str.length > 0 ? str : undefined;
// Taken from backend API nodejs/packages/api/src/utils.js
const stringContainsHTMLtags = (str) => /<[^>]*>/g.test(str);
export const isValidUserInputMultilineString = (str, maxLength = 1000) => {
    if (str === undefined || str === null)
        return false;
    if (str.length > maxLength)
        return false;
    if (stringContainsHTMLtags(str))
        return false;
    return true;
};
export const validAccountNumber = (accountNumber) => /^\d{8}$/.test(accountNumber);
export const validSortCode = (sortCode) => /^\d{6}$/.test(sortCode.replace(/-/g, ''));
