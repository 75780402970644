import React, { forwardRef, useCallback } from 'react';
import { Pressable, StyleSheet, View, } from 'react-native';
import { rem } from 'design-system/values';
import { CARD_VIEW_BORDER_RADIUS } from 'design-system/CardView';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
const TouchableBounceCard = forwardRef(({ noMarginTop, noMarginBottom, noMargin, withPadding, style, children, ...props }, ref) => {
    const styles = useStyles(styleSet);
    const [isHovering, setIsHovering] = React.useState(false);
    const handleEvent = useCallback((event) => () => {
        setIsHovering(event === 'onHoverIn');
    }, []);
    return (<Pressable ref={ref} onHoverIn={handleEvent('onHoverIn')} onHoverOut={handleEvent('onHoverOut')} style={[
            styles.container,
            noMarginTop && styles.noMarginTop,
            noMarginBottom && styles.noMarginBottom,
            noMargin && styles.noMargin,
            withPadding && styles.withPadding,
            'onLight' in props && styles.onLight,
            'onDark' in props && styles.onDark,
            style,
        ]} {...props}>
        <>
          <View pointerEvents="none" style={[
            styles.hoverBg,
            isHovering && 'onDark' in props && styles.hoverOnDark,
            isHovering && 'onLight' in props && styles.hoverOnLight,
        ]}/>
          {children}
        </>
      </Pressable>);
});
const styleSet = createStyleSheets((colors) => ({
    container: {
        borderRadius: CARD_VIEW_BORDER_RADIUS,
        margin: rem(16),
        overflow: 'hidden',
    },
    noMarginTop: {
        marginTop: 0,
    },
    noMarginBottom: {
        marginBottom: 0,
    },
    noMargin: {
        margin: 0,
    },
    withPadding: {
        padding: rem(16),
    },
    onLight: {
        backgroundColor: colors.cards.onLight,
    },
    onDark: {
        backgroundColor: colors.cards.onDark,
    },
    hoverBg: StyleSheet.absoluteFillObject,
    hoverOnDark: {
        backgroundColor: colors.control.hoverOnDark,
    },
    hoverOnLight: {
        backgroundColor: colors.control.hoverOnLight,
    },
}));
export default React.memo(TouchableBounceCard);
