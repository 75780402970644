import React from 'react';
import { StyleSheet, View, } from 'react-native';
import { Path, Svg } from 'react-native-svg';
import useColors from 'hooks/useColors';
import { rem } from './values';
const Squircle = ({ style, children, width = rem(44), height = rem(44), color, svgStyle, }) => {
    const colors = useColors();
    return (<View style={[{ width, height }, styles.container, style]}>
      <Svg style={svgStyle} width={width} height={height} viewBox="0 0 44 44" fill="none">
        <Path fill={color ?? colors.cards.onLight} d="M22 0C7.07763 0 0 7.07763 0 22C0 36.9224 7.07763 44 22 44C36.9224 44 44 36.9224 44 22C44 7.07763 36.9224 0 22 0Z"/>
      </Svg>
      <View style={styles.svg} pointerEvents="none">
        {children}
      </View>
    </View>);
};
const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    svg: {
        position: 'absolute',
    },
});
export default Squircle;
