import React from 'react';
import IconChart2 from 'design-system/icons/IconChart2';
import IconPieChart from 'design-system/icons/IconPieChart';
import IconActivity from 'design-system/icons/IconActivity';
export const accountOverviewScreenTitleToSelectedAccountTitleMap = {
    Everyday: 'Everyday',
    Savings: 'Savings',
    Investments: 'Investments',
    'Net Worth - Main': 'Net worth',
    'Net Worth - Asset': 'Net worth',
    'Net Worth - Debt': 'Net worth',
};
export var ChartType;
(function (ChartType) {
    ChartType["pie"] = "pie";
    ChartType["line"] = "line";
    ChartType["assetVsDebt"] = "assetVsDebt";
})(ChartType || (ChartType = {}));
export const CHART_TYPE_ICONS = {
    [ChartType.pie]: <IconPieChart gradientKey="deepPink"/>,
    [ChartType.line]: <IconActivity alt colorKey="brand"/>,
    [ChartType.assetVsDebt]: <IconChart2 />,
};
export const CHART_TYPE_TITLE = {
    [ChartType.pie]: 'Pie chart',
    [ChartType.line]: 'Line chart',
    [ChartType.assetVsDebt]: 'Assets vs debt',
};
