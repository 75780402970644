import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
const IconAccount = (props) => {
    const id = useId();
    return (<IconWithGradient viewBox="0 0 24 24" id={id} width={24} height={24} defaultGradientKey="lime" {...props}>
      <Path fill={`url(#gradient_${id})`} d="M11.437 2.57a1.749 1.749 0 0 1 2.126 0l7.443 5.69c.948.725.44 2.237-.752 2.243H4.744c-1.19-.006-1.7-1.518-.75-2.243l7.443-5.69ZM12.5 7.879a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"/>
      <Path fill={`url(#gradient_${id})`} d="M5.75 12.003v4.5H8v-4.5H5.75Z"/>
      <Path fill={`url(#gradient_${id})`} d="M9.5 12.003v4.5h2.25v-4.5H9.5Z"/>
      <Path fill={`url(#gradient_${id})`} d="M13.25 12.003v4.5h2.25v-4.5h-2.25Z"/>
      <Path fill={`url(#gradient_${id})`} d="M17 12.003v4.5h2.25v-4.5H17Z"/>
      <Path fill={`url(#gradient_${id})`} d="M3.5 19.878c0-1.035.84-1.875 1.875-1.875h14.25c1.035 0 1.875.84 1.875 1.875v.375a.75.75 0 0 1-.75.75H4.25a.75.75 0 0 1-.75-.75v-.375Z"/>
    </IconWithGradient>);
};
export default IconAccount;
