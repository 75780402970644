import React, { useId } from 'react';
import { IconWithGradient } from 'design-system/icons/Icon';
import { Path } from 'react-native-svg';
const IconChartFavourite = ({ ...props }) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="ultimate">
      <Path d="M18.1241 15.1768L18.3881 15.713C18.5201 15.977 18.8583 16.2245 19.1388 16.274L19.4936 16.3318C20.5743 16.5133 20.8218 17.3053 20.0546 18.0808L19.7246 18.4108C19.5018 18.6335 19.3863 19.0625 19.4523 19.376L19.4936 19.574C19.7906 20.8775 19.0976 21.3808 17.9673 20.696L17.7281 20.5475C17.4393 20.3743 16.9608 20.3743 16.6721 20.5475L16.4328 20.696C15.2943 21.3808 14.6096 20.8775 14.9066 19.574L14.9478 19.376C15.0138 19.0708 14.8983 18.6335 14.6756 18.4108L14.3456 18.0808C13.5783 17.297 13.8258 16.5133 14.9066 16.3318L15.2613 16.274C15.5501 16.2245 15.8801 15.977 16.0121 15.713L16.2761 15.1768C16.7876 14.1455 17.6126 14.1455 18.1241 15.1768Z" fill={`url(#gradient_${id})`}/>
      <Path d="M15.7067 4.5H8.79325C5.79025 4.5 4 6.29025 4 9.29325V16.2067C4 19.2098 5.79025 21 8.79325 21H13.1493C13.438 21 13.6442 20.703 13.6112 20.4143C13.5782 20.1008 13.5947 19.7295 13.6937 19.3005C13.7102 19.2428 13.6937 19.1767 13.6442 19.1272L13.4628 18.9458C12.7615 18.2362 12.5057 17.3783 12.7532 16.5945C13.009 15.819 13.7185 15.2745 14.7002 15.1095L14.9478 15.0683L15.1705 14.6228C15.6242 13.6988 16.3667 13.1625 17.2 13.1625C18.0332 13.1625 18.7757 13.6988 19.2295 14.6228L19.3533 14.8785C19.411 15.0022 19.5265 15.0847 19.6585 15.1095C19.7327 15.126 19.807 15.1425 19.8812 15.159C20.17 15.2332 20.5 15.0022 20.5 14.697V9.29325C20.5 6.29025 18.7098 4.5 15.7067 4.5ZM15.7645 10.242L13.8587 12.7005C13.6195 13.0057 13.2812 13.2038 12.8935 13.245C12.514 13.2945 12.1262 13.1873 11.8292 12.948L10.3195 11.7765C10.2617 11.727 10.1958 11.727 10.1628 11.7353C10.1298 11.7353 10.072 11.7517 10.0225 11.8177L8.059 14.367C7.93525 14.5238 7.75375 14.6062 7.564 14.6062C7.432 14.6062 7.3 14.565 7.1845 14.4742C6.91225 14.268 6.86275 13.8802 7.069 13.608L9.0325 11.0587C9.27175 10.7535 9.61 10.5555 9.99775 10.506C10.3855 10.4565 10.765 10.5638 11.0702 10.803L12.58 11.991C12.6377 12.0405 12.6955 12.0322 12.7367 12.0322C12.7697 12.0322 12.8275 12.0157 12.877 11.9497L14.7827 9.49125C14.989 9.219 15.3768 9.1695 15.649 9.384C15.9295 9.59025 15.9707 9.978 15.7645 10.242Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default IconChartFavourite;
