import { useEffect, useRef } from 'react';
export const usePreviousLastSync = (value) => {
    const ref = useRef('');
    useEffect(() => {
        if (value) {
            ref.current = value;
        }
    }, [value]);
    return ref.current;
};
