import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import useColors from 'hooks/useColors';
import TouchableScale from './TouchableScale';
import { rem } from './values';
import IconMoneyInputDelete from './icons/IconMoneyInputDelete';
import Text from './Text';
const MoneyInputPinPad = ({ captions, onInput, color: colorProp, style, }) => {
    const colors = useColors();
    const color = colorProp || colors.text.white;
    return (<View style={[styles.container, style]}>
      {captions.map((value, index) => {
            if (value.length === 0) {
                return (<View key={`${value}#${index * 2}`} style={styles.elementContainer}/>);
            }
            if (value === '<') {
                return (<View key={`${value}#${index * 2}`} style={styles.elementContainer}>
              <TouchableScale testID="deleteBtn" style={styles.button} onPress={() => onInput(value)}>
                <IconMoneyInputDelete color={color}/>
              </TouchableScale>
            </View>);
            }
            return (<View key={`${value}#${index * 2}`} style={styles.elementContainer}>
            <TouchableScale style={styles.button} onPress={() => onInput(value)}>
              <Text Numbers-26 color={color} style={styles.number}>
                {value}
              </Text>
            </TouchableScale>
          </View>);
        })}
    </View>);
};
const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    elementContainer: {
        alignItems: 'center',
        width: '33%',
    },
    button: {
        height: rem(80),
        width: rem(80),
        alignItems: 'center',
        justifyContent: 'center',
    },
    number: {
        backgroundColor: 'transparent',
    },
});
export default memo(MoneyInputPinPad);
