import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
const IconMoreSquircle = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="green">
      <Path fillRule="evenodd" clipRule="evenodd" d="M9.03 3.344a13.006 13.006 0 0 1 5.94 0 7.63 7.63 0 0 1 5.686 5.686 13.006 13.006 0 0 1 0 5.94 7.63 7.63 0 0 1-5.686 5.686 13.006 13.006 0 0 1-5.94 0 7.63 7.63 0 0 1-5.686-5.686 13.006 13.006 0 0 1 0-5.94A7.63 7.63 0 0 1 9.03 3.344ZM9.18 12a.97.97 0 0 1-.983.957.97.97 0 0 1-.983-.957.97.97 0 0 1 .983-.957.97.97 0 0 1 .984.957Zm3.803 0a.97.97 0 0 1-.983.957.97.97 0 0 1-.984-.957.97.97 0 0 1 .984-.957.97.97 0 0 1 .983.957Zm2.82.957a.97.97 0 0 0 .983-.957.97.97 0 0 0-.983-.957.97.97 0 0 0-.984.957c0 .529.44.957.984.957Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default IconMoreSquircle;
