import { useEffect, useMemo, useRef, useState } from 'react';
import useLineGraphData, { OVERVIEW_DEFAULT_PERIOD, OVERVIEW_DEFAULT_PERIODS, } from 'design-system/LineChart/useLineGraphData';
import monotoneCubicInterpolation, { getYExtremes, parse, } from 'design-system/LineChart/helpers';
import { selectSavingsBalance, selectCheckingBalance, selectNetWorthBalance, selectInvestmentBalance, selectSectionedAccounts, selectDebtBalanceHistory, selectAssetsBalance, } from 'reducers/selectors';
import { useAppSelector } from 'store/hooks';
import { ChartType, } from '../types';
import { useBalanceHistory } from './useBalanceHistory';
export const getPointsFromData = (dataArr, yExtremes, useAbsY) => {
    if (dataArr?.length) {
        let isAllSame = true;
        let checkerNum;
        const data = dataArr.map((data, index) => {
            if (typeof checkerNum === 'number' && data.balance !== checkerNum) {
                isAllSame = false;
            }
            checkerNum = data.balance;
            return {
                ...data,
                x: index,
                y: data.balance,
                actualY: data.balance,
            };
        });
        const factor = Math.floor(100 / data.length) || 1;
        const superData = isAllSame
            ? data
            : monotoneCubicInterpolation({
                data,
                range: data.length * factor - 1,
                includeExtremes: true,
                removePointsSurroundingExtremes: false,
            });
        const [parsedData] = parse(superData, isAllSame, yExtremes, useAbsY);
        return parsedData;
    }
    return [];
};
const useOverviewChart = (overviewScreenTitle) => {
    const checkingBalance = useAppSelector((state) => selectCheckingBalance(state, true));
    const networthBalance = useAppSelector(selectNetWorthBalance);
    const savingsBalance = useAppSelector((state) => selectSavingsBalance(state, true));
    const investmentsBalance = useAppSelector((state) => selectInvestmentBalance(state, true));
    const debtBalance = useAppSelector(selectDebtBalanceHistory);
    const assetsBalance = useAppSelector((state) => selectAssetsBalance(state));
    const [chartType, setChartType] = useState(ChartType.line);
    const pieChartData = useAppSelector((state) => selectSectionedAccounts(state, overviewScreenTitle, chartType === ChartType.pie));
    useBalanceHistory(overviewScreenTitle, chartType);
    const cache = useRef({
        Everyday: {
            '1W': null,
            '1M': null,
            '3M': null,
            '6M': null,
            '1Y': null,
        },
        Savings: {
            '1W': null,
            '1M': null,
            '3M': null,
            '6M': null,
            '1Y': null,
        },
        Investments: {
            '1W': null,
            '1M': null,
            '3M': null,
            '6M': null,
            '1Y': null,
        },
        'Net Worth - Main': {
            '1W': null,
            '1M': null,
            '3M': null,
            '6M': null,
            '1Y': null,
        },
        'Net Worth - Main Asset': {
            '1W': null,
            '1M': null,
            '3M': null,
            '6M': null,
            '1Y': null,
        },
        'Net Worth - Main Debt': {
            '1W': null,
            '1M': null,
            '3M': null,
            '6M': null,
            '1Y': null,
        },
        'Net Worth - Asset': {
            '1W': null,
            '1M': null,
            '3M': null,
            '6M': null,
            '1Y': null,
        },
        'Net Worth - Debt': {
            '1W': null,
            '1M': null,
            '3M': null,
            '6M': null,
            '1Y': null,
        },
    });
    const { portfolioDataSet, portfolioDataSet2, setPortfolioDataSet, setPortfolioDataSet2, selectedPeriod, setSelectedPeriod, requesting, setRequesting, selectedInfoObj, selectedInfoObj2, } = useLineGraphData(OVERVIEW_DEFAULT_PERIODS, OVERVIEW_DEFAULT_PERIOD);
    const selectedTitle = useRef(overviewScreenTitle);
    useEffect(() => {
        if (((overviewScreenTitle === 'Net Worth - Main' ||
            overviewScreenTitle === 'Everyday') &&
            chartType === ChartType.pie) ||
            (overviewScreenTitle !== 'Net Worth - Main' &&
                chartType === ChartType.assetVsDebt)) {
            setChartType(ChartType.line);
        }
    }, [chartType, overviewScreenTitle]);
    const pieData = useMemo(() => {
        if (chartType === ChartType.pie) {
            return pieChartData;
        }
        return [];
    }, [chartType, pieChartData]);
    useEffect(() => {
        if (chartType === ChartType.line || chartType === ChartType.assetVsDebt) {
            const isAssetsVsDebt = chartType === ChartType.assetVsDebt;
            if (!isAssetsVsDebt &&
                cache.current[overviewScreenTitle][selectedPeriod]
                    ?.length) {
                setRequesting(false);
                setPortfolioDataSet((oldDataSet) => ({
                    ...oldDataSet,
                    ...cache.current[overviewScreenTitle],
                }));
                setPortfolioDataSet2((oldDataSet) => ({
                    ...oldDataSet,
                    '1W': null,
                    '1M': null,
                    '3M': null,
                    '6M': null,
                    '1Y': null,
                }));
            }
            else if (isAssetsVsDebt &&
                cache.current['Net Worth - Main Asset'][selectedPeriod]?.length &&
                cache.current['Net Worth - Main Debt'][selectedPeriod]?.length) {
                setRequesting(false);
                setPortfolioDataSet((oldDataSet) => ({
                    ...oldDataSet,
                    ...cache.current['Net Worth - Main Asset'],
                }));
                setPortfolioDataSet2((oldDataSet) => ({
                    ...oldDataSet,
                    ...cache.current['Net Worth - Main Debt'],
                }));
            }
            else {
                const rawData = (() => {
                    switch (overviewScreenTitle) {
                        case 'Everyday': {
                            return [checkingBalance];
                        }
                        case 'Savings': {
                            return [savingsBalance];
                        }
                        case 'Investments': {
                            return [investmentsBalance];
                        }
                        case 'Net Worth - Main': {
                            if (isAssetsVsDebt) {
                                if (assetsBalance && debtBalance) {
                                    return [assetsBalance, debtBalance];
                                }
                                return [];
                            }
                            return [networthBalance];
                        }
                        case 'Net Worth - Debt': {
                            return [debtBalance];
                        }
                        case 'Net Worth - Asset': {
                            return [assetsBalance];
                        }
                        default: {
                            return null;
                        }
                    }
                })();
                if (rawData?.[0]) {
                    const data = (() => {
                        const useAbsY = overviewScreenTitle === 'Net Worth - Debt';
                        switch (selectedPeriod) {
                            case '1W': {
                                if (rawData?.[0].week?.length) {
                                    let YExtremes;
                                    if (isAssetsVsDebt &&
                                        rawData?.[0].week?.length &&
                                        rawData?.[1]?.week?.length) {
                                        YExtremes = getYExtremes(rawData[0].week, rawData[1].week, false, true);
                                    }
                                    return {
                                        one: getPointsFromData(rawData?.[0].week, YExtremes, useAbsY),
                                        two: rawData?.[1]?.week
                                            ? getPointsFromData(rawData?.[1].week, YExtremes, true)
                                            : null,
                                    };
                                }
                                return null;
                            }
                            case '1M': {
                                if (rawData?.[0].month?.length) {
                                    let YExtremes;
                                    if (isAssetsVsDebt &&
                                        rawData?.[0].month?.length &&
                                        rawData?.[1]?.month?.length) {
                                        YExtremes = getYExtremes(rawData[0].month, rawData[1].month, false, true);
                                    }
                                    return {
                                        one: getPointsFromData(rawData?.[0].month, YExtremes, useAbsY),
                                        two: rawData?.[1]?.month
                                            ? getPointsFromData(rawData?.[1]
                                                .month, YExtremes, true)
                                            : null,
                                    };
                                }
                                return null;
                            }
                            case '6M': {
                                if (rawData?.[0].sixmonths?.length) {
                                    let YExtremes;
                                    if (isAssetsVsDebt &&
                                        rawData?.[0].sixmonths?.length &&
                                        rawData?.[1]?.sixmonths?.length) {
                                        YExtremes = getYExtremes(rawData[0].sixmonths, rawData[1].sixmonths, false, true);
                                    }
                                    return {
                                        one: getPointsFromData(rawData?.[0]
                                            .sixmonths, YExtremes, useAbsY),
                                        two: rawData?.[1]?.sixmonths
                                            ? getPointsFromData(rawData?.[1]
                                                .sixmonths, YExtremes, true)
                                            : null,
                                    };
                                }
                                return null;
                            }
                            case '1Y': {
                                let YExtremes;
                                if (isAssetsVsDebt &&
                                    rawData?.[0].year?.length &&
                                    rawData?.[1]?.year?.length) {
                                    YExtremes = getYExtremes(rawData[0].year, rawData[1].year, false, true);
                                }
                                if (rawData?.[0].year?.length) {
                                    return {
                                        one: getPointsFromData(rawData?.[0].year, YExtremes, useAbsY),
                                        two: rawData?.[1]?.year
                                            ? getPointsFromData(rawData?.[1].year, YExtremes, true)
                                            : null,
                                    };
                                }
                                return null;
                            }
                            default:
                                if (rawData?.[0].threemonths?.length) {
                                    let YExtremes;
                                    if (isAssetsVsDebt &&
                                        rawData?.[0].threemonths?.length &&
                                        rawData?.[1]?.threemonths?.length) {
                                        YExtremes = getYExtremes(rawData[0].threemonths, rawData[1].threemonths, false, true);
                                    }
                                    return {
                                        one: getPointsFromData(rawData?.[0]
                                            .threemonths, YExtremes, useAbsY),
                                        two: rawData?.[1]?.threemonths
                                            ? getPointsFromData(rawData?.[1]
                                                .threemonths, YExtremes, true)
                                            : null,
                                    };
                                }
                                return null;
                        }
                    })();
                    if (data?.one?.length && data?.two?.length && isAssetsVsDebt) {
                        cache.current['Net Worth - Main Asset'][selectedPeriod] = data.one;
                        cache.current['Net Worth - Main Debt'][selectedPeriod] = data.two;
                        setPortfolioDataSet((oldDataSet) => ({
                            ...(selectedTitle.current !== overviewScreenTitle
                                ? {
                                    '1W': null,
                                    '1M': null,
                                    '3M': null,
                                    '6M': null,
                                    '1Y': null,
                                }
                                : oldDataSet),
                            [selectedPeriod]: data.one,
                        }));
                        setPortfolioDataSet2((oldDataSet) => ({
                            ...(selectedTitle.current !== overviewScreenTitle
                                ? {
                                    '1W': null,
                                    '1M': null,
                                    '3M': null,
                                    '6M': null,
                                    '1Y': null,
                                }
                                : oldDataSet),
                            [selectedPeriod]: data.two,
                        }));
                        setRequesting(false);
                    }
                    else if (data?.one && !isAssetsVsDebt) {
                        cache.current[overviewScreenTitle][selectedPeriod] = data.one;
                        setPortfolioDataSet((oldDataSet) => ({
                            ...(selectedTitle.current !== overviewScreenTitle
                                ? {
                                    '1W': null,
                                    '1M': null,
                                    '3M': null,
                                    '6M': null,
                                    '1Y': null,
                                }
                                : oldDataSet),
                            [selectedPeriod]: data.one,
                        }));
                        setRequesting(false);
                    }
                    else if (selectedTitle.current !== overviewScreenTitle ||
                        isAssetsVsDebt) {
                        setRequesting(true);
                        setPortfolioDataSet((oldDataSet) => ({
                            ...oldDataSet,
                            '1W': null,
                            '1M': null,
                            '3M': null,
                            '6M': null,
                            '1Y': null,
                        }));
                        setPortfolioDataSet2((oldDataSet) => ({
                            ...oldDataSet,
                            '1W': null,
                            '1M': null,
                            '3M': null,
                            '6M': null,
                            '1Y': null,
                        }));
                    }
                }
            }
            selectedTitle.current = overviewScreenTitle;
        }
    }, [
        checkingBalance,
        setPortfolioDataSet,
        selectedPeriod,
        savingsBalance,
        investmentsBalance,
        networthBalance,
        setRequesting,
        chartType,
        overviewScreenTitle,
        debtBalance,
        assetsBalance,
        setPortfolioDataSet2,
    ]);
    return {
        pieData,
        chartType,
        requesting,
        setChartType,
        selectedPeriod,
        selectedInfoObj,
        selectedInfoObj2,
        portfolioDataSet,
        portfolioDataSet2,
        setSelectedPeriod,
    };
};
export default useOverviewChart;
