import { useCallback } from 'react';
import { selectAllCategories, selectMerchants, selectTags } from 'reducers/selectors';
import { useAppStore } from 'store/hooks';
const SPECIAL_CHARACTERS_REGEX = /[`~!@#$%^&*()_|+\-=?;:'"’“”,.<>{}[\]\\/]/gi;
const useCheckFilter = (onSelectFilter) => {
    const store = useAppStore();
    const checkFilter = useCallback((textValue) => {
        const state = store.getState();
        const categories = selectAllCategories(state);
        const merchants = selectMerchants(state);
        const tags = selectTags(state);
        const textWord = textValue.replace(SPECIAL_CHARACTERS_REGEX, '');
        const wordsToMatch = textWord.toLowerCase().split(' ');
        // Search only if we have at least a world with a space at the end
        if (!wordsToMatch || !wordsToMatch.length || wordsToMatch[wordsToMatch.length - 1] !== '') {
            return textValue;
        }
        let textWithoutFilters = textValue;
        let previousWords = '';
        wordsToMatch.forEach((word, index) => {
            if (!word) {
                return;
            }
            // To check filter with several words
            previousWords += index ? ` ${word}` : word;
            // Search for categories
            if (categories && categories.length) {
                const isCategoryFound = categories.some((categ) => {
                    const categoryDisplayName = categ.displayName.toLowerCase();
                    // Check single word (ie: 'business') or multiple (ie: 'eating out')
                    if (categoryDisplayName === word || categoryDisplayName === previousWords) {
                        onSelectFilter({
                            ...categ,
                            badgeText: categ.displayName,
                            filterType: 'CATEGORY',
                        });
                        const regexCateg = new RegExp(categ.displayName, 'gi');
                        textWithoutFilters = textWithoutFilters.replace(regexCateg, '');
                        return true;
                    }
                    return false;
                });
                // Stop searching this word
                if (isCategoryFound) {
                    return;
                }
            }
            // Seach for merchants
            if (merchants && merchants.length) {
                const isMerchantFound = merchants.some((merchant) => {
                    let merchantDisplayName = merchant.displayName.toLowerCase();
                    merchantDisplayName = merchantDisplayName.replace(SPECIAL_CHARACTERS_REGEX, '');
                    // Check single word (ie: 'uber') or multiple (ie: 'best bake')
                    if (merchantDisplayName === word || merchantDisplayName === previousWords) {
                        onSelectFilter({
                            ...merchant,
                            badgeText: merchant.displayName,
                            filterType: 'MERCHANT',
                        });
                        const regexMerchant = new RegExp(merchant.displayName, 'gi');
                        textWithoutFilters = textWithoutFilters.replace(regexMerchant, '');
                        return true;
                    }
                    return false;
                });
                // Stop searching this word
                if (isMerchantFound) {
                    return;
                }
            }
            // Seach for tags
            if (tags && tags.length) {
                tags.some((tag) => {
                    if (tag && tag.label && tag.label.toLowerCase() === word) {
                        onSelectFilter({
                            ...tag,
                            badgeText: `#${tag.label}`,
                            filterType: 'TAG',
                        });
                        const regexTag = new RegExp(tag.label, 'gi');
                        textWithoutFilters = textWithoutFilters.replace(regexTag, '');
                        return true;
                    }
                    return false;
                });
            }
        });
        if (textWithoutFilters === ' ') {
            textWithoutFilters = '';
        }
        return textWithoutFilters;
    }, [onSelectFilter]);
    return checkFilter;
};
export default useCheckFilter;
