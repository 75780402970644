import React from 'react';
import { StyleSheet } from 'react-native';
import { rem } from '../values';
import IconArrowTriangle from './IconArrowTriangle';
export const IconArrowTriangleUp = ({ width = rem(7), height = rem(6), color, style, }) => (<IconArrowTriangle width={width} height={height} color={color} style={[style, styles.icon]}/>);
const styles = StyleSheet.create({
    icon: {
        transform: [{ rotate: '-180deg' }],
    },
});
export default IconArrowTriangleUp;
