import React from 'react';
import { Path } from 'react-native-svg';
import { Icon } from 'design-system/icons/Icon';
import useColors from 'hooks/useColors';
export const IconDoubleRotateBold = ({ color, ...props }) => {
    const colors = useColors();
    return (<Icon {...props}>
      <Path d="M16.033 7.686a5.864 5.864 0 0 0-4.038-1.592 5.913 5.913 0 0 0-5.723 4.46.423.423 0 0 1-.41.321H3.847a.422.422 0 0 1-.415-.498 8.721 8.721 0 0 1 14.59-4.681l1.256-1.256c.532-.531 1.44-.155 1.44.597V9.75a.844.844 0 0 1-.843.844h-4.713c-.752 0-1.128-.91-.597-1.44l1.468-1.468Zm-11.908 5.72h4.713c.752 0 1.128.91.597 1.44l-1.468 1.468a5.864 5.864 0 0 0 4.038 1.592 5.914 5.914 0 0 0 5.723-4.46.423.423 0 0 1 .41-.32h2.015c.263 0 .463.238.415.497a8.722 8.722 0 0 1-14.59 4.681L4.721 19.56c-.532.531-1.44.155-1.44-.597V14.25c0-.466.377-.844.843-.844Z" fill={color ?? colors.elements.white}/>
    </Icon>);
};
