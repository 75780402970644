import useColors from 'hooks/useColors';
import { useMemo } from 'react';
import { defaultCategories } from '../constants';
// If a category colour is null in the database the API returns this as the colour
export const API_DEFAULT_CATEGORY_COLOR = '#4481eb';
export const useCustomCategoryColors = () => {
    const colors = useColors();
    return useMemo(() => [
        ['rgb(124, 78, 255)', colors.gradients.darkPurple.colors[0], colors.gradients.darkPurple.colors],
        ['rgb(166, 74, 254)', colors.gradients.lightPurple.colors[0], colors.gradients.lightPurple.colors],
        ['rgb(245, 108, 217)', colors.gradients.lightPink.colors[0], colors.gradients.lightPink.colors],
        ['rgb(255, 145, 166)', colors.gradients.peach.colors[0], colors.gradients.peach.colors],
        ['rgb(255, 39, 117)', colors.gradients.red.colors[0], colors.gradients.red.colors],
        ['rgb(255, 167, 120)', colors.gradients.orange.colors[0], colors.gradients.orange.colors],
        ['rgb(255, 204, 105)', colors.gradients.yellow.colors[0], colors.gradients.yellow.colors],
        ['rgb(188, 249, 119)', colors.gradients.lime.colors[0], colors.gradients.lime.colors],
        ['rgb(34, 238, 238)', colors.gradients.turquoise.colors[0], colors.gradients.turquoise.colors],
        ['rgb(4, 222, 197)', colors.gradients.green.colors[0], colors.gradients.green.colors],
    ], [colors]);
};
export const useCustomCategoryColorsMap = () => {
    const colors = useColors();
    return useMemo(() => ({
        'rgb(124, 78, 255)': [colors.gradients.darkPurple.colors[0], colors.gradients.darkPurple.colors],
        'rgb(166, 74, 254)': [colors.gradients.lightPurple.colors[0], colors.gradients.lightPurple.colors],
        'rgb(245, 108, 217)': [colors.gradients.lightPink.colors[0], colors.gradients.lightPink.colors],
        'rgb(255, 145, 166)': [colors.gradients.peach.colors[0], colors.gradients.peach.colors],
        'rgb(255, 39, 117)': [colors.gradients.red.colors[0], colors.gradients.red.colors],
        'rgb(255, 167, 120)': [colors.gradients.orange.colors[0], colors.gradients.orange.colors],
        'rgb(255, 204, 105)': [colors.gradients.yellow.colors[0], colors.gradients.yellow.colors],
        'rgb(188, 249, 119)': [colors.gradients.lime.colors[0], colors.gradients.lime.colors],
        'rgb(34, 238, 238)': [colors.gradients.turquoise.colors[0], colors.gradients.turquoise.colors],
        'rgb(4, 222, 197)': [colors.gradients.green.colors[0], colors.gradients.green.colors],
    }), [colors]);
};
export const isDefaultCategory = (id) => defaultCategories.includes(id);
export const useCategories = () => {
    const colors = useColors();
    return useMemo(() => ({
        shopping: {
            id: 'shopping',
            displayName: 'Shopping',
            color: 'rgb(0,204,204)',
            gradientColors: colors.gradients.darkPurple.colors,
        },
        transport: {
            id: 'transport',
            displayName: 'Transport',
            color: 'rgb(112,48,255)',
            gradientColors: colors.gradients.peach.colors,
        },
        groceries: {
            id: 'groceries',
            displayName: 'Groceries',
            color: 'rgb(254,96,133)',
            gradientColors: colors.gradients.turquoise.colors,
        },
        entertainment: {
            id: 'entertainment',
            displayName: 'Entertainment',
            color: 'rgb(48,53,57)',
            gradientColors: colors.gradients.darkPurple.colors,
        },
        cash: {
            id: 'cash',
            displayName: 'Cash',
            color: 'rgb(82,186,253)',
            gradientColors: colors.gradients.yellow.colors,
        },
        eatingout: {
            id: 'eatingout',
            displayName: 'Eating Out',
            color: 'rgb(0,204,204)',
            gradientColors: colors.gradients.green.colors,
        },
        holidays: {
            id: 'holidays',
            displayName: 'Holidays',
            color: 'rgb(255,154,61)',
            gradientColors: colors.gradients.ultimate.colors,
        },
        health: {
            id: 'health',
            displayName: 'Personal Care',
            color: 'rgb(254,96,133)',
            gradientColors: colors.gradients.red.colors,
        },
        housing: {
            id: 'housing',
            displayName: 'Housing',
            color: 'rgb(48,65,255)',
            gradientColors: colors.gradients.lime.colors,
        },
        bills: {
            id: 'bills',
            displayName: 'Bills',
            color: 'rgb(48,53,57)',
            gradientColors: colors.gradients.apricot.colors,
        },
        charity: {
            id: 'charity',
            displayName: 'Charity',
            color: 'rgb(112,48,255)',
            gradientColors: colors.gradients.skyBlue.colors,
        },
        investments: {
            id: 'investments',
            displayName: 'Investments',
            color: 'rgb(0,204,204)',
            gradientColors: colors.gradients.orange.colors,
        },
        education: {
            id: 'education',
            displayName: 'Education',
            color: 'rgb(254,96,133)',
            gradientColors: colors.gradients.peach.colors,
        },
        family: {
            id: 'family',
            displayName: 'Family',
            color: 'rgb(255,154,61)',
            gradientColors: colors.gradients.lime.colors,
        },
        business: {
            id: 'business',
            displayName: 'Business',
            color: 'rgb(48,65,255)',
            gradientColors: colors.gradients.lightPink.colors,
        },
        income: {
            id: 'income',
            displayName: 'Income',
            color: 'rgb(112,48,255)',
            gradientColors: colors.gradients.green.colors,
        },
        general: {
            id: 'general',
            displayName: 'General',
            color: 'rgb(41,157,237)',
            gradientColors: colors.gradients.deepPink.colors,
        },
        internal: {
            id: 'internal',
            displayName: 'Excluded',
            color: 'rgb(48,53,57)',
            gradientColors: [colors.elements.primary, colors.elements.primary],
        },
        custom1: {
            id: 'custom1',
            displayName: 'Add New',
            color: 'rgb(48,53,57)',
            gradientColors: [colors.elements.primary, colors.elements.primary],
        },
    }), [colors]);
};
export const useCategoryColors = (category) => {
    const colors = useColors();
    const categories = useCategories();
    const customCategoryColorMap = useCustomCategoryColorsMap();
    return useMemo(() => {
        const defaultColors = colors.gradients.deepPink.colors;
        if (!category) {
            return defaultColors;
        }
        if (category.id && typeof category.id !== 'number' && categories[category.id]) {
            return categories[category.id].gradientColors;
        }
        if (!category.color) {
            return defaultColors;
        }
        const gradient = customCategoryColorMap[category.color];
        return gradient ? gradient[1] : [category.color, category.color];
    }, [categories, category, colors, customCategoryColorMap]);
};
