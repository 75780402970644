import React, { memo } from 'react';
import { View, StyleSheet } from 'react-native';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import SmallCircleButton from './SmallCircleButton';
const SmallCircleProgress = ({ length, itemStyle }) => {
    const { width } = useAppFrameDimensions();
    return (<View style={[styles.ballsContainer, { width: width / 3 }]}>
      <SmallCircleButton active={length > 0} style={itemStyle}/>
      <SmallCircleButton active={length > 1} style={itemStyle}/>
      <SmallCircleButton active={length > 2} style={itemStyle}/>
      <SmallCircleButton active={length > 3} style={itemStyle}/>
    </View>);
};
const styles = StyleSheet.create({
    ballsContainer: {
        flexDirection: 'row',
        height: 20,
        justifyContent: 'center',
        alignSelf: 'center',
    },
});
export default memo(SmallCircleProgress);
