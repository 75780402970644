import { Platform } from 'react-native';
import Config from 'utils/packages/configs';
import FastImage from 'utils/packages/rnFastImage';
export const extractImage = (bankAccount, showHidden = true) => {
    if (bankAccount &&
        showHidden &&
        'isHidden' in bankAccount &&
        bankAccount.isHidden)
        return require('../design-system/img/excluded.png');
    const iconBase64 = bankAccount && bankAccount.iconBase64;
    const iconUrl = bankAccount &&
        ('bankIconUrl' in bankAccount
            ? bankAccount.bankIconUrl
            : bankAccount.iconUrl);
    if (!iconBase64 && !iconUrl)
        return null;
    const cache = Platform.select({
        ios: FastImage.cacheControl?.web,
        default: FastImage.cacheControl?.immutable,
    }) || 'web';
    if (iconBase64)
        return { uri: `data:image/png;base64,${iconBase64}`, cache };
    if (bankAccount &&
        'type' in bankAccount &&
        bankAccount.type === 'CRYPTO' &&
        'nativeBalance' in bankAccount &&
        bankAccount.nativeBalance &&
        (!bankAccount.isHidden || !showHidden)) {
        return {
            uri: `${Config.API_URL}/images/currency-logo/${bankAccount.nativeBalance.currency}`,
            cache,
        };
    }
    return {
        uri: iconUrl,
        cache,
    };
};
