import { CALL_API } from '../middleware/api';
import * as types from './types';
export const getRecommendations = () => ({
    [CALL_API]: {
        endpoint: '/recommendations',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_RECOMMENDATIONS_REQUEST,
            types.GET_RECOMMENDATIONS_SUCCESS,
            types.GET_RECOMMENDATIONS_FAILURE,
        ],
    },
});
export const dismissRecommendation = (id, body) => ({
    [CALL_API]: {
        endpoint: `/recommendations/${id}/dismiss`,
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        types: [
            types.DISMISS_RECOMMENDATIONS_REQUEST,
            types.DISMISS_RECOMMENDATIONS_SUCCESS,
            types.DISMISS_RECOMMENDATIONS_FAILURE,
        ],
    },
});
export const openRecommendation = (id, body) => ({
    [CALL_API]: {
        endpoint: `/recommendations/${id}/open`,
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        types: [
            types.OPEN_RECOMMENDATIONS_REQUEST,
            types.OPEN_RECOMMENDATIONS_SUCCESS,
            types.OPEN_RECOMMENDATIONS_FAILURE,
        ],
    },
});
