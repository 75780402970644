import React from 'react';
import { View, StyleSheet } from 'react-native';
import Text from './Text';
import { IconIndicator } from './icons';
import IconCheckMarkSquircle from './icons/IconCheckMarkSquircle';
import { rem } from './values';
const VERIFY_ICON_SIZE = 14;
const VerificationStatusView = ({ verified, verifyText, verifiedText, ...props }) => (<View {...props}>
    {verified ? (<View style={styles.verifiedContainer}>
        <IconCheckMarkSquircle />
        <Text Text-12 Primary style={styles.verifyText}>
          {verifiedText}
        </Text>
      </View>) : (<View style={styles.verifiedContainer}>
        <IconIndicator colorKey="secondary" width={VERIFY_ICON_SIZE} height={VERIFY_ICON_SIZE}/>
        <Text Text-12 Gray-50 style={styles.verifyText}>
          {verifyText}
        </Text>
      </View>)}
  </View>);
export default VerificationStatusView;
const styles = StyleSheet.create({
    verifiedContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: rem(4),
    },
    verifyText: {
        paddingLeft: rem(5.45),
    },
});
