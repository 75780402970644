import { useCallback } from 'react';
import Modal from 'design-system/Modal';
import { getCategories } from 'actions';
import Alert from 'utils/packages/Alert';
import { useAppDispatch } from 'store/hooks';
import { deleteCategory } from 'actions/expenses';
import { getBudgets } from 'features/budgeting/actions';
import { muteErrorAndResult, useFetchOld } from 'hooks';
const useDeleteCategory = () => {
    const dispatch = useAppDispatch();
    const [, , isFetching, fetch] = useFetchOld(muteErrorAndResult);
    const onDelete = useCallback((id, name, onDeleted) => {
        const onConfirm = () => {
            fetch(deleteCategory(id)).then((response) => {
                if (response) {
                    dispatch(getCategories());
                    dispatch(getBudgets());
                    onDeleted?.();
                }
                return response;
            });
        };
        Modal.hide();
        Alert.alert(`Delete ${name}?`, 'Would you like to delete this category?', [
            { text: 'Cancel', style: 'cancel' },
            { text: 'Delete', onPress: onConfirm, style: 'destructive' },
        ]);
    }, []);
    return [isFetching, onDelete];
};
export default useDeleteCategory;
