import React, { memo } from 'react';
import { View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Color from 'color';
import useColors from 'hooks/useColors';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import Button from './Button';
import Text from './Text';
import { rem } from './values';
import { Chip } from './Chip';
import IconLockUnlocked from './icons/IconLockUnlocked';
const UpgradeButton = ({ onPress, onCard, text, additionalText, children, }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const marginBottom = useMarginBottom();
    return (<View style={!onCard && marginBottom}>
      <View>
        {children}
        {!onCard && (<LinearGradient style={styles.gradient} colors={[
                Color(colors.background.dark).fade(1).toString(),
                colors.background.dark,
            ]}/>)}
      </View>
      <View />
      <View style={!onCard && styles.container}>
        <Chip text="Enhanced tracking" TransparentOnDark type="squared-small" icon={IconLockUnlocked} noMarginEnd style={styles.chip}/>
        <Text Text-20 centered OffBlack style={onCard ? styles.titleOnCard : styles.title}>
          Upgrade to see more
        </Text>
        <Text TextThin-14 centered Secondary style={onCard ? styles.subTitleOnCard : styles.subTitle}>
          {text || (<>
              Enhanced tracking is available across all our premium plans.{' '}
              {additionalText || ''}Get started today by learning more.
            </>)}
        </Text>
        <Button title="Learn more" onPress={onPress} {...(onCard
        ? {
            small: true,
            hug: true,
            brandLight: true,
            style: styles.buttonOnCard,
        }
        : {
            brand: true,
        })}/>
      </View>
      {!onCard && <View style={styles.overscroll}/>}
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        paddingHorizontal: rem(16),
        backgroundColor: colors.background.dark,
    },
    button: {
        top: 0,
        left: 0,
        right: 0,
        position: 'absolute',
    },
    gradient: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
    },
    overscroll: {
        backgroundColor: colors.background.dark,
        height: 200,
        position: 'absolute',
        width: '100%',
        bottom: -200,
        left: 0,
    },
    title: {
        marginBottom: rem(16),
    },
    titleOnCard: {
        marginBottom: rem(4),
    },
    subTitle: {
        marginBottom: rem(24),
    },
    subTitleOnCard: {
        marginBottom: rem(16),
    },
    chip: {
        alignSelf: 'center',
        marginTop: rem(8),
        marginBottom: rem(16),
    },
    buttonOnCard: {
        paddingHorizontal: rem(16),
    },
}));
export default memo(UpgradeButton);
