import { call, delay, put, select, spawn, take, takeEvery } from 'typed-redux-saga/macro';
import { updateTradingAccount } from '../api/account';
import { getPendingTopups } from '../actions/topup';
import { selectPendingTopupsFlag } from '../reducers/selectors';
function* checkForPendingTopups() {
    yield* put(getPendingTopups());
}
/**
 * Just check for pending topups for 2 minutes, with 10 seconds delay.
 */
function* pollForPendingTopups() {
    for (let index = 0; index < 12; index += 1) {
        yield* call(checkForPendingTopups);
        yield* delay(10000);
    }
}
function* updateBalancesWhenPendingTopupsGoAway() {
    let pendingTopupPaymentsExist = yield* select(selectPendingTopupsFlag);
    while (true) {
        const successAction = yield* take((action) => action.type === 'GET_PENDING_TOPUPS_SUCCESS');
        const pendingTopupPaymentsExistOnServer = successAction.payload.pendingTopUps.length > 0;
        if (pendingTopupPaymentsExist && !pendingTopupPaymentsExistOnServer) {
            yield* spawn(updateTradingAccount, true);
        }
        pendingTopupPaymentsExist = pendingTopupPaymentsExistOnServer;
    }
}
function* topupSaga() {
    yield* takeEvery((action) => action.type === 'TOPUP_CREATED', pollForPendingTopups); // after user tops up, we start polling for pending topups
    yield* spawn(updateBalancesWhenPendingTopupsGoAway);
}
export default topupSaga;
