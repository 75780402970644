import React from 'react';
import { Path, Svg } from 'react-native-svg';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
export const IconFilterBold = ({ color, width = rem(24), height = rem(24), }) => {
    const colors = useColors();
    return (<Svg fill="none" width={width} height={height} viewBox="0 0 24 24">
      <Path fill={color ?? colors.text.white} fillRule="evenodd" clipRule="evenodd" d="M6.53231 3.25C4.12818 3.25 2.86001 6.06997 4.46829 7.83966L8.47608 12.2497C9.01844 12.8464 9.31853 13.6209 9.31853 14.4238V18.25C9.31853 19.6307 10.4488 20.75 11.843 20.75C13.2372 20.75 14.3674 19.6307 14.3674 18.25V14.4936C14.3674 13.6568 14.6933 12.8522 15.2773 12.2474L19.4746 7.90058C21.1637 6.15123 19.9117 3.25 17.4676 3.25H6.53231Z"/>
    </Svg>);
};
export default IconFilterBold;
