import React, { useId } from 'react';
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconHat = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} defaultGradientKey="apricot" {...props}>
      <G clipPath="url(#clip0_415_1295)">
        <Path d="M7.65526 15.0247C9.08673 15.3343 10.5485 15.4811 12.013 15.4625C13.4774 15.4811 14.9393 15.3343 16.3707 15.0247C17.6305 14.7197 18.409 14.2324 18.409 13.654C18.409 7.77903 16.5609 6.36079 14.7108 6.36079C13.4788 6.36277 12.2883 7.01247 11.6406 7.36505C11.4782 7.45418 11.3554 7.52153 11.2979 7.54134C10.9176 7.68395 10.3769 7.60076 9.81036 7.5136C9.40692 7.44041 8.99819 7.40001 8.58821 7.39278C6.98972 7.40664 5.57148 8.33761 5.61704 13.654C5.61704 14.2483 6.39548 14.7217 7.65526 15.0247Z" fill={`url(#gradient_${id})`}/>
        <Path d="M20.865 13.6462C20.2438 13.3535 19.5921 13.1303 18.9219 12.9807V13.6541C18.9219 14.807 17.3828 15.322 16.4795 15.5398C15.0109 15.8617 13.5103 16.0138 12.0069 15.9934C10.501 16.0145 8.99757 15.8623 7.52639 15.5398C6.62316 15.322 5.07418 14.807 5.07418 13.6541C5.07418 13.6422 5.07418 13.6304 5.07418 13.6205C5.07418 13.4224 5.07418 13.2005 5.07418 12.9826C4.40527 13.1324 3.75493 13.3556 3.13499 13.6482C2.40804 14.0146 2 14.4583 2 14.9654C2 15.7914 3.18847 16.4906 5.09201 16.9462C6.87471 17.3741 9.30514 17.6395 11.9931 17.6395C14.681 17.6395 17.1352 17.376 18.908 16.9541C20.8195 16.4926 22 15.7934 22 14.9733C22 14.4583 21.5939 14.0127 20.865 13.6462Z" fill={`url(#gradient_${id})`}/>
      </G>
      <Defs>
        <ClipPath id="clip0_415_1295">
          <Rect width="20" height="20" fill="white" transform="translate(2 2)"/>
        </ClipPath>
      </Defs>
    </IconWithGradient>);
};
