import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { Icon, IconWithGradient } from 'design-system/icons/Icon';
export const IconCart = (props) => {
    const id = useId();
    if ('alt' in props) {
        return (<IconWithGradient id={id} {...props} defaultGradientKey="darkPink">
        <Path d="M21.5 4.25a.75.75 0 0 0-.75-.75h-.558c-.95 0-1.52.639-1.845 1.233-.217.396-.374.855-.497 1.271a1.289 1.289 0 0 0-.1-.004H5.252c-.83 0-1.43.794-1.202 1.593l1.828 6.409a2.75 2.75 0 0 0 2.644 1.996h5.948a2.75 2.75 0 0 0 2.652-2.022l.76-2.772 1.26-4.248.001-.008c.156-.567.302-1.098.52-1.494.209-.385.377-.454.528-.454h.559a.75.75 0 0 0 .75-.75Z" fill={`url(#gradient_${id})`}/>
        <Path d="M15 21a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z" fill={`url(#gradient_${id})`}/>
        <Path d="M8 21a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z" fill={`url(#gradient_${id})`}/>
      </IconWithGradient>);
    }
    return (<Icon>
      <Path fill={props.color} d="M21.5 4.25C21.5 4.05109 21.421 3.86032 21.2803 3.71967C21.1397 3.57902 20.9489 3.5 20.75 3.5H20.192C19.242 3.5 18.672 4.139 18.347 4.733C18.13 5.129 17.973 5.588 17.85 6.004C17.8167 6.00137 17.7834 6.00004 17.75 6H5.252C4.422 6 3.822 6.794 4.05 7.593L5.878 14.002C6.0419 14.5769 6.38863 15.0827 6.86572 15.4428C7.3428 15.803 7.92424 15.9979 8.522 15.998H14.47C15.0725 15.998 15.6583 15.8002 16.1375 15.4349C16.6166 15.0696 16.9625 14.557 17.122 13.976L17.882 11.204L19.142 6.956L19.143 6.948C19.299 6.381 19.445 5.85 19.663 5.454C19.872 5.069 20.04 5 20.191 5H20.75C20.9489 5 21.1397 4.92098 21.2803 4.78033C21.421 4.63968 21.5 4.44891 21.5 4.25Z"/>
      <Path fill={props.color} d="M15 21C14.4696 21 13.9609 20.7893 13.5858 20.4142C13.2107 20.0391 13 19.5304 13 19C13 18.4696 13.2107 17.9609 13.5858 17.5858C13.9609 17.2107 14.4696 17 15 17C15.5304 17 16.0391 17.2107 16.4142 17.5858C16.7893 17.9609 17 18.4696 17 19C17 19.5304 16.7893 20.0391 16.4142 20.4142C16.0391 20.7893 15.5304 21 15 21Z"/>
      <Path fill={props.color} d="M8 21C7.46957 21 6.96086 20.7893 6.58579 20.4142C6.21071 20.0391 6 19.5304 6 19C6 18.4696 6.21071 17.9609 6.58579 17.5858C6.96086 17.2107 7.46957 17 8 17C8.53043 17 9.03914 17.2107 9.41421 17.5858C9.78929 17.9609 10 18.4696 10 19C10 19.5304 9.78929 20.0391 9.41421 20.4142C9.03914 20.7893 8.53043 21 8 21Z"/>
    </Icon>);
};
