import { REHYDRATE } from 'redux-persist';
import * as types from '../actions/types';
const initialState = {
    isFetching: false,
    isLoggingInBank: false,
    plaid_details: null,
    lastLinked: undefined,
    banks: [],
};
const parseBanks = (data) => {
    const array = [...data];
    const banksMap = new Map();
    array.forEach((a) => {
        banksMap.set(a.id, { ...a, child: [] });
    });
    array.forEach((a) => {
        if (!a.parentId)
            return;
        const parentBank = banksMap.get(a.parentId);
        if (parentBank)
            parentBank.child.push(a);
    });
    return data;
};
const linkBank = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE:
            if (action.payload?.linkBank)
                return {
                    ...initialState,
                    lastLinked: action.payload.linkBank.lastLinked,
                };
            return state;
        case types.LOGIN_BANK_REQUEST:
            return { ...state, isLoggingInBank: true };
        case types.PLAID_LINK_SUCCESS:
            return { ...state, plaid_details: action.payload?.params };
        case types.LINK_SUCCESS:
            // Not sure if this is even needed
            if (action.payload &&
                'params' in action.payload &&
                action.payload.params) {
                return { ...state, plaid_details: action.payload.params };
            }
            return state;
        case types.LOGIN_BANK_SUCCESS:
            return { ...state, isLoggingInBank: false };
        case types.LOGIN_BANK_FAILURE:
            return { ...state, isLoggingInBank: false };
        case types.BANKS_REQUEST:
            return { ...state, isFetching: true };
        case types.BANKS_SUCCESS:
            return {
                ...state,
                banks: parseBanks(action.payload.banks),
                isFetching: false,
            };
        case types.BANKS_FAILURE:
            return {
                ...state,
                isFetching: false,
            };
        case types.CONNECTION_CHANGE_HANDLED: {
            if (action.payload.status) {
                return {
                    ...state,
                    lastLinked: action.payload.bankInfo,
                };
            }
            return state;
        }
        case types.LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
};
export default linkBank;
