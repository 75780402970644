import * as types from 'actions/types';
import { Platform } from 'react-native';
const initialState = {};
const signinWeb = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_WEB_SIGN_IN_QR_CODE_STATUS_SUCCESS: {
            if (action.payload.qrStatus === 'AUTHORIZED' ||
                action.payload.qrStatus === 'DENIED' ||
                action.payload.qrStatus === 'LOGGED_IN') {
                return {
                    ...state,
                    webSignInConfirmation: undefined,
                };
            }
            return {
                ...state,
                webSignInConfirmation: {
                    ...(state.webSignInConfirmation?.clientId === action.payload.clientId
                        ? state.webSignInConfirmation
                        : {}),
                    ...action.payload,
                    popOnComplete: action.extra.popOnComplete,
                },
            };
        }
        case types.GET_WEB_SIGN_IN_QR_CODE_STATUS_FAILURE: {
            if (Platform.OS !== 'web') {
                return {
                    ...state,
                    webSignInConfirmation: undefined,
                };
            }
            return state;
        }
        case types.RESET_WEB_SIGN_IN_CONFIRMATION:
        case types.UPDATE_WEB_SIGN_IN_QR_CODE_STATUS_FAILURE: {
            return {
                ...state,
                webSignInConfirmation: undefined,
            };
        }
        case types.UPDATE_WEB_SIGN_IN_QR_CODE_STATUS_SUCCESS: {
            if (action.extra.qrOperation === 'AUTHORIZED' ||
                action.extra.qrOperation === 'DENIED')
                return {
                    ...state,
                    webSignInConfirmation: undefined,
                };
            return {
                ...state,
            };
        }
        case types.LOGGED_OUT: {
            return initialState;
        }
        default:
            return state;
    }
};
export default signinWeb;
