import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from './Icon';
export const IconCocoSettingsBold = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} defaultGradientKey="orange" fill="none" {...props}>
      <Path fill={`url(#gradient_${id})`} d="M15.164 3c-1.423 0-2.614.99-2.923 2.32H3.818a.818.818 0 1 0 0 1.636h8.501a3.001 3.001 0 0 0 5.69 0h2.173a.818.818 0 1 0 0-1.637h-2.096A3.001 3.001 0 0 0 15.164 3Z"/>
      <Path fill={`url(#gradient_${id})`} d="M7.527 9c-1.38 0-2.542.932-2.892 2.2l-.838.022a.818.818 0 0 0 .043 1.636l.806-.021a3.001 3.001 0 0 0 5.757.021h9.779a.818.818 0 1 0 0-1.636h-9.757A3.001 3.001 0 0 0 7.527 9Z"/>
      <Path fill={`url(#gradient_${id})`} d="M15.164 15a3.001 3.001 0 0 0-2.87 2.124H3.817a.818.818 0 1 0 0 1.637h8.443a3.001 3.001 0 0 0 5.805 0h2.116a.818.818 0 0 0 0-1.637h-2.148A3.001 3.001 0 0 0 15.164 15Z"/>
    </IconWithGradient>);
};
export default IconCocoSettingsBold;
