import React from 'react';
import Svg, { Path } from 'react-native-svg';
import useColors from 'hooks/useColors';
const InvestIcon = ({ isActive }) => {
    const colors = useColors();
    return (<Svg width={32} height={32} viewBox="0 0 32 32">
      <Path fillRule="evenodd" clipRule="evenodd" fill={isActive ? colors.elements.brand : colors.elements.secondary} d="M5 16C5 8.53882 8.53882 5 16 5C23.4612 5 27 8.53882 27 16C27 23.4612 23.4612 27 16 27C8.53882 27 5 23.4612 5 16ZM18.0798 15.8757L17.9538 15.7787C17.4321 15.3732 16.9802 14.973 16.7059 14.686L16.5444 14.4958C16.4598 14.394 16.3664 14.2742 16.3309 14.1973C16.2013 13.916 16.238 13.5916 16.421 13.356L16.4369 13.3355C16.5613 13.1859 16.9467 12.9681 16.9514 12.9718C17.5725 12.622 19.008 12.1315 19.8359 12.0332L20.081 12.0081C20.1465 12.0026 20.2224 11.9981 20.2766 12.0009C20.4489 12.0033 20.6151 12.0667 20.7494 12.1711C20.8994 12.2876 21.003 12.442 21.0494 12.6176L21.0911 12.8715L21.0989 12.9271C21.2277 13.7003 21.1201 15.3741 20.9024 16.1242L20.8683 16.2365C20.8247 16.3696 20.7558 16.5501 20.6911 16.6418C20.6037 16.7543 20.494 16.8415 20.3628 16.9039C20.191 16.9758 19.9976 16.9893 19.8194 16.9413C19.6985 16.9051 19.3754 16.7445 19.3704 16.7406C19.0486 16.5736 18.581 16.2583 18.0798 15.8757ZM11.1684 17.3043C11.4163 17.0015 11.8565 16.9424 12.1755 17.1692L14.4028 18.7531L15.9625 16.8478C16.2228 16.5298 16.6916 16.483 17.0096 16.7434C17.3277 17.0037 17.3744 17.4725 17.1141 17.7906L15.1141 20.2339C14.8661 20.5367 14.426 20.5958 14.107 20.3689L11.8796 18.7851L10.32 20.6904C10.0597 21.0084 9.59085 21.0551 9.27283 20.7948C8.95481 20.5345 8.90804 20.0656 9.16837 19.7476L11.1684 17.3043Z"/>
    </Svg>);
};
export default InvestIcon;
