export const changeableAccountTypes = [
    'INVESTMENT',
    'LOAN',
    'CHECKING',
    'CRYPTO',
    'SAVINGS',
    'CREDITCARD',
    'OTHER',
    'VEHICLE',
    'REAL_ESTATE',
    'PENSION',
];
