import React from 'react';
import { StyleSheet } from 'react-native';
import { SettingsItem } from 'design-system/SettingsItem';
import { SettingsSection } from 'design-system/SettingsSection';
import { IconEmma } from 'design-system/icons/IconEmma';
import { IconDocument } from 'design-system/icons/IconDocument';
import { rem } from 'design-system/values';
import { useMainStackNavigation } from 'utils/types/navigationV6';
const strings = {
    about: 'About us',
    importantStuff: 'Important stuff',
};
export const AboutAndImportantStuff = () => {
    const { navigate } = useMainStackNavigation();
    return (<SettingsSection noMarginTop style={styles.container}>
      <SettingsItem title={strings.about} onPress={() => navigate('AboutUs')} icon={<IconEmma gradientKey="ultimate"/>}/>
      <SettingsItem title={strings.importantStuff} onPress={() => navigate('ImportantStuff')} icon={<IconDocument gradientKey="apricot"/>}/>
    </SettingsSection>);
};
const styles = StyleSheet.create({
    container: {
        marginTop: rem(16),
    },
});
