import { StyleSheet } from 'react-native';
import day from 'design-system/colors/day';
import night from 'design-system/colors/night';
function createStyleSheets(getStyle) {
    return {
        day: StyleSheet.create(getStyle(day)),
        night: StyleSheet.create(getStyle(night)),
    };
}
export default createStyleSheets;
