import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import Amount from 'design-system/Amount';
import Text from 'design-system/Text';
import useColors from 'hooks/useColors';
import { getBalance } from 'utils/api/getBalance';
const RowAccontBalance = ({ data, primaryBalanceOnly, subtitle, tintColor, small, }) => {
    const [primaryBalance, secondBalance] = getBalance(data);
    const colors = useColors();
    const primaryColor = tintColor === colors.text.white ? { White: true } : { OffBlack: true };
    const secondaryColor = tintColor === colors.text.white ? { White: true } : { Gray: true };
    return (<View style={styles.balanceView}>
      {primaryBalance !== null && (<Amount {...(small ? { 'Numbers-14': true } : { 'Numbers-16': true })} {...primaryColor} value={primaryBalance}/>)}
      {!primaryBalanceOnly && (<View style={styles.secondBalanceContainer}>
          {secondBalance !== null && (<Amount NumbersThin-14 {...secondaryColor} value={secondBalance}/>)}
          {subtitle && (<Text TextThin-14 {...secondaryColor}>
              {subtitle}
            </Text>)}
        </View>)}
    </View>);
};
const styles = StyleSheet.create({
    balanceView: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    secondBalanceContainer: {
        alignSelf: 'flex-end',
        alignItems: 'flex-end',
    },
});
export default memo(RowAccontBalance);
