import React from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
const EmptyComponent = ({ description }) => (<View style={styles.emptyContainer}>
    <Text Text-16 centered>
      {description}
    </Text>
  </View>);
export default EmptyComponent;
const styles = StyleSheet.create({
    emptyContainer: {
        paddingTop: 40,
        paddingHorizontal: 40,
    },
});
