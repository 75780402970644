import React from 'react';
import Animated, { interpolate, useAnimatedStyle } from 'react-native-reanimated';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
const BORDER_RADIUS = rem(12);
const AnimatedWhiteBg = ({ scrollY, headerContainerHeight }) => {
    const reanimatedStyles = useAnimatedStyle(() => ({
        top: Math.max(headerContainerHeight.value - scrollY.value, 0),
        borderTopLeftRadius: interpolate(scrollY.value, [0, headerContainerHeight.value], [BORDER_RADIUS, 1], 'clamp'),
        borderTopRightRadius: interpolate(scrollY.value, [0, headerContainerHeight.value], [BORDER_RADIUS, 1], 'clamp'),
        opacity: !headerContainerHeight.value ? 0 : 1,
    }));
    const styles = useStyles(styleSet);
    return (<Animated.View style={[
            reanimatedStyles,
            // {
            //   height: WINDOW_HEIGHT * 2, // with some margin. It just needs to always go to the very bottom of the screen
            // },
            styles.container,
        ]}/>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        // top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: 'absolute',
        backgroundColor: colors.background.light,
    },
}));
export default AnimatedWhiteBg;
