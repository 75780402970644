import React, { useEffect } from 'react';
import TitledView from 'design-system/TitledView';
import { DaysOfTheMonthSelector } from 'design-system/date';
export const TwiceAMonthView = ({ days, onChange, ...props }) => {
    useEffect(() => {
        onChange([days?.[0] || 1, days?.[1] || 31]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<TitledView title="Pick two dates">
      <DaysOfTheMonthSelector onChange={onChange} days={days} maxNumberOfDays={2} {...props}/>
    </TitledView>);
};
