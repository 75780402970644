import React, { memo, useMemo } from 'react';
import LinearGradient from 'react-native-linear-gradient';
import { StyleSheet, View } from 'react-native';
import Squircle from 'design-system/Squircle';
import useColors from 'hooks/useColors';
import Button from './Button';
import { CardView } from './CardView';
import Text from './Text';
import IconInfo from './icons/IconInfo';
import { rem } from './values';
export const AlertButton = ({ onPress, children, type, testID, isFetching, }) => {
    if (typeof children !== 'string') {
        log('Alert button can only accept string as a child prop', undefined, 'red');
        return null;
    }
    if (type === 'warning') {
        return (<Button small extra frosted square title={children} containerStyle={styles.buttonWarning} onPress={onPress} testID={testID} isFetching={isFetching}/>);
    }
    return (<Button small extra gray title={children} containerStyle={styles.button} onPress={onPress} testID={testID}/>);
};
export const AlertView = ({ title, subTitle, icon, iconSize = 40, children, style, type = 'warning', testID, containerStyles, }) => {
    const newChildren = React.Children.toArray(children).map((child) => {
        // Pass the type to the button
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { type });
        }
        return child;
    });
    const colors = useColors();
    const content = useMemo(() => (<View style={[styles.container, style]}>
        {!!(type === 'warning' || icon) &&
            (type === 'warning' ? (<IconInfo width={44} height={44} color={colors.elements.white} style={styles.iconWarning}/>) : (<Squircle width={rem(iconSize)} height={rem(iconSize)} color={colors.background.dark} style={styles.icon}>
              {icon}
            </Squircle>))}
        {type === 'warning' ? (<>
            <Text Text-16 White centered style={subTitle ? styles.titleWarning : styles.subTitleWarning}>
              {title}
            </Text>
            {Boolean(subTitle) && (<Text TextThin-14 White centered style={Boolean(children) && styles.subTitleWarning}>
                {subTitle}
              </Text>)}
          </>) : (<>
            <Text Text-16 OffBlack centered style={subTitle ? styles.title : styles.subTitle}>
              {title}
            </Text>
            {Boolean(subTitle) && (<Text TextThin-14 Gray centered style={Boolean(children) && styles.subTitle}>
                {subTitle}
              </Text>)}
          </>)}
        {newChildren}
      </View>), [
        children,
        colors.background.dark,
        colors.elements.white,
        icon,
        iconSize,
        newChildren,
        style,
        subTitle,
        title,
        type,
    ]);
    return (<CardView testID={testID} style={containerStyles}>
      {type === 'warning' ? (<LinearGradient {...colors.gradients.red} style={styles.gradient}>
          {content}
        </LinearGradient>) : (content)}
    </CardView>);
};
const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginHorizontal: rem(16),
        paddingVertical: rem(24),
        paddingHorizontal: rem(48),
        borderRadius: rem(16),
        marginTop: 0,
        marginBottom: 0,
        alignItems: 'center',
    },
    icon: {
        marginBottom: rem(16),
    },
    iconWarning: {
        marginBottom: rem(8),
    },
    button: {
        paddingHorizontal: rem(26),
    },
    buttonWarning: {
        paddingHorizontal: rem(24),
    },
    title: {
        marginBottom: rem(4),
    },
    titleWarning: {
        marginBottom: rem(8),
    },
    subTitle: {
        marginBottom: rem(16),
    },
    subTitleWarning: {
        marginBottom: rem(20),
    },
    gradient: {
        flex: 1,
        borderRadius: rem(16),
    },
});
export default memo(AlertView);
