import React, { memo, useMemo } from 'react';
import { Platform, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Animated, { Extrapolate, interpolate, useAnimatedStyle, useSharedValue } from 'react-native-reanimated';
import { rem } from 'design-system/values';
import useHeaderHeight from 'hooks/useHeaderHeight';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
const headerShadowGradient = {
    colors: ['#24104512', '#24104502', '#24104500'],
    locations: [0, 0.8, 1],
    useAngle: true,
    angle: 180,
};
const AnimatedLinearGradient = Animated.createAnimatedComponent(LinearGradient);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function assertUnreachable(_x) {
    // we should catch this on typecheck stage
}
const COLLAPSE_DISTANCE = 12;
export const DEFAULT_NEGATIVE_MARGIN = 32;
const BORDER_RADIUS = 20; // no rem should be used on top of this value
/**
 * @param flex `boolean` - Only supported for `primary` with no gradient. When `true`, the layout will be calculated using flexbox, and all animations will be disabled. Use it only within scroll-disabled `ScrollView`, or separately.
 */
const BackgroundView = ({ finalHeaderHeight, expandedHeaderHeight, negativeMargin, bottom, flex, scrollY, children, ...colorVariation }) => {
    const headerHeight = useHeaderHeight(finalHeaderHeight);
    /**
     * The "h" is the distance from the very bottom edge of BackgroundView to the content inside of it.
     * It controls how far does BackgroundView go behind the screen content.
     */
    const initialH = negativeMargin === undefined ? rem(DEFAULT_NEGATIVE_MARGIN) : -negativeMargin;
    const hShared = useSharedValue(initialH);
    const collapsedFactor = useSharedValue(1); // 0 means fully collapsed
    const finalHeaderHeightShared = useSharedValue(headerHeight);
    finalHeaderHeightShared.value = headerHeight;
    const expandedHeaderHeightShared = useSharedValue(expandedHeaderHeight ?? headerHeight + COLLAPSE_DISTANCE);
    expandedHeaderHeightShared.value = expandedHeaderHeight ?? headerHeight + COLLAPSE_DISTANCE;
    const hasChildren = useSharedValue(children !== undefined);
    const topAnimatedStyle = useAnimatedStyle(() => {
        const collapsibleHeight = hShared.value;
        const totalHeight = expandedHeaderHeightShared.value + collapsibleHeight;
        return {
            height: interpolate(scrollY.value, [
                -1,
                0,
                expandedHeaderHeightShared.value - finalHeaderHeightShared.value - COLLAPSE_DISTANCE,
                expandedHeaderHeightShared.value - finalHeaderHeightShared.value,
                expandedHeaderHeightShared.value - finalHeaderHeightShared.value + 1,
            ], [
                totalHeight + 1,
                totalHeight,
                finalHeaderHeightShared.value + COLLAPSE_DISTANCE + collapsibleHeight,
                finalHeaderHeightShared.value,
                finalHeaderHeightShared.value,
            ]),
            borderBottomLeftRadius: interpolate(scrollY.value, [
                0,
                expandedHeaderHeightShared.value - finalHeaderHeightShared.value - COLLAPSE_DISTANCE,
                expandedHeaderHeightShared.value - finalHeaderHeightShared.value,
                expandedHeaderHeightShared.value - finalHeaderHeightShared.value + 1,
            ], [BORDER_RADIUS, BORDER_RADIUS, 1, 1], Extrapolate.CLAMP),
            borderBottomRightRadius: interpolate(scrollY.value, [
                0,
                expandedHeaderHeightShared.value - finalHeaderHeightShared.value - COLLAPSE_DISTANCE,
                expandedHeaderHeightShared.value - finalHeaderHeightShared.value,
                expandedHeaderHeightShared.value - finalHeaderHeightShared.value + 1,
            ], [BORDER_RADIUS, BORDER_RADIUS, 1, 1], Extrapolate.CLAMP),
        };
    }, []);
    const bottomIOSAnimatedStyle = useAnimatedStyle(() => ({
        height: finalHeaderHeightShared.value,
        shadowColor: '#24104512',
        shadowOffset: { height: 15, width: 10 },
        shadowOpacity: 1 - collapsedFactor.value,
        shadowRadius: 44,
    }), []);
    const bottomAndroidAnimatedStyle = useAnimatedStyle(() => {
        if (!hasChildren.value || finalHeaderHeightShared.value > expandedHeaderHeightShared.value - scrollY.value) {
            return {
                height: finalHeaderHeightShared.value,
            };
        }
        return {
            height: expandedHeaderHeightShared.value - scrollY.value,
        };
    }, []);
    const bottomAndroidShadowAnimatedStyle = useAnimatedStyle(() => {
        if (Platform.OS === 'android') {
            return {
                position: 'absolute',
                top: finalHeaderHeightShared.value,
                left: 0,
                right: 0,
                height: 40,
                opacity: 1 - collapsedFactor.value,
            };
        }
        return {};
    }, []);
    const androidShadow = useMemo(() => {
        if (Platform.OS === 'android' && bottom) {
            return (<AnimatedLinearGradient pointerEvents="none" style={bottomAndroidShadowAnimatedStyle} {...headerShadowGradient}/>);
        }
        return null;
    }, [bottom, bottomAndroidShadowAnimatedStyle]);
    const styles = useStyles(styleSet);
    const bottomStyle = useMemo(() => (Platform.OS === 'ios' ? bottomIOSAnimatedStyle : bottomAndroidAnimatedStyle), [bottomAndroidAnimatedStyle, bottomIOSAnimatedStyle]);
    const memoStyles = useMemo(() => [styles.primary, styles.absolute, bottom ? bottomStyle : topAnimatedStyle], [bottom, bottomStyle, styles.absolute, styles.primary, topAnimatedStyle]);
    if ('color' in colorVariation) {
        return (<>
        <Animated.View style={[styles.absolute, { backgroundColor: colorVariation.color }, bottom ? bottomStyle : topAnimatedStyle]}>
          {children}
        </Animated.View>
        {androidShadow}
      </>);
    }
    if ('primary' in colorVariation) {
        if (colorVariation.gradient) {
            return (<>
          <AnimatedLinearGradient style={[styles.absolute, bottom && styles.overflowVisible, bottom ? bottomStyle : topAnimatedStyle]} colors={purpleGradientColors} useAngle angle={270}>
            {children}
          </AnimatedLinearGradient>
          {androidShadow}
        </>);
        }
        if (flex) {
            return <View style={[styles.flexContainerPrimary, { paddingBottom: initialH }]}>{children}</View>;
        }
        return (<>
        <Animated.View style={memoStyles}>{children}</Animated.View>
        {androidShadow}
      </>);
    }
    if ('gradient' in colorVariation) {
        return (<>
        <AnimatedLinearGradient style={[styles.absolute, bottom && styles.overflowVisible, bottom ? bottomStyle : topAnimatedStyle]} {...colorVariation.gradient}>
          {children}
        </AnimatedLinearGradient>
        {androidShadow}
      </>);
    }
    assertUnreachable(colorVariation);
    // fallback
    return (<>
      <AnimatedLinearGradient style={[styles.absolute, bottom ? bottomStyle : topAnimatedStyle]} colors={purpleGradientColors} useAngle angle={270}/>
      {androidShadow}
    </>);
};
const purpleGradientColors = ['#9C39FF', '#8103FF'];
const styleSet = createStyleSheets((colors) => ({
    absolute: {
        position: 'absolute',
        left: 0,
        right: 0,
    },
    overflowVisible: {
        overflow: 'visible',
    },
    flexContainerPrimary: {
        alignSelf: 'stretch',
        borderBottomLeftRadius: BORDER_RADIUS,
        borderBottomRightRadius: BORDER_RADIUS,
        backgroundColor: colors.cards.brand,
    },
    primary: {
        backgroundColor: colors.cards.brand,
    },
}));
export default memo(BackgroundView);
