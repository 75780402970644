import React from 'react';
import { Circle, Svg } from 'react-native-svg';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
const IconMore = ({ color, style }) => {
    const width = rem(20);
    const height = rem(4);
    const colors = useColors();
    const defaultColor = colors.elements.white;
    return (<Svg width={width} height={height} viewBox="0 0 20 4" fill="none" style={style}>
      <Circle cx="2" cy="2" r="2" fill={color ?? defaultColor}/>
      <Circle cx="10" cy="2" r="2" fill={color ?? defaultColor}/>
      <Circle cx="18" cy="2" r="2" fill={color ?? defaultColor}/>
    </Svg>);
};
export default IconMore;
