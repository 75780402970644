import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
const IconTags = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} defaultGradientKey="lightPink" {...props}>
      <Path d="M6.35345 4.7659H11.298C11.8603 4.7659 12.3961 4.98882 12.7929 5.38604L18.614 11.2054C19.4408 12.0322 19.4408 13.375 18.614 14.2019L14.2019 18.614C13.375 19.4408 12.0322 19.4408 11.2054 18.614L5.38604 12.7929C4.98892 12.3961 4.7659 11.8603 4.7659 11.298V6.35345C4.7659 5.47666 5.47666 4.7659 6.35345 4.7659ZM8.47018 9.52855C9.05559 9.52855 9.52855 9.05559 9.52855 8.47018C9.52855 7.88478 9.05559 7.41182 8.47018 7.41182C7.88577 7.41182 7.41182 7.88478 7.41182 8.47018C7.41182 9.05559 7.88577 9.52855 8.47018 9.52855Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default IconTags;
