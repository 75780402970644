import React from 'react';
import { StyleSheet } from 'react-native';
import { SettingsItem } from 'design-system/SettingsItem';
import { SettingsSection } from 'design-system/SettingsSection';
import { IconProfile } from 'design-system/icons/IconProfile';
import { IconSettings } from 'design-system/icons/IconSettings';
import { rem } from 'design-system/values';
import { useMainStackNavigation } from 'utils/types/navigationV6';
const strings = {
    account: 'Account',
    preferences: 'Preferences',
};
export const AccountAndPreference = () => {
    const { navigate } = useMainStackNavigation();
    return (<SettingsSection noMarginBottom noMarginTop style={styles.container}>
      <SettingsItem title={strings.account} onPress={() => navigate('AccountSettingsList')} icon={<IconProfile gradientKey="orange"/>}/>
      <SettingsItem title={strings.preferences} onPress={() => navigate('PreferenceSettingsList')} icon={<IconSettings gradientKey="pro"/>}/>
    </SettingsSection>);
};
const styles = StyleSheet.create({
    container: {
        marginTop: rem(16),
    },
});
