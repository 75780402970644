export const SET_DID_CLOSE_PUSH_NOTIFICATION_CARD = 'SET_DID_CLOSE_PUSH_NOTIFICATION_CARD';
export const SET_DID_ONBOARD_NETWORTH = 'SET_DID_ONBOARD_NETWORTH';
export const SET_DID_CLOSE_UPGRADE_NETWORTH = 'SET_DID_CLOSE_UPGRADE_NETWORTH ';
export const SET_LAST_REVIEW_SESSION_COUNT = 'SET_LAST_REVIEW_SESSION_COUNT';
export const SET_DID_CLOSE_POTS_CTA_FROM_ANALYTICS = 'SET_DID_CLOSE_POTS_CTA_FROM_ANALYTICS';
export const SET_DID_CLOSE_POTS_CTA_FROM_BUDGETING = 'SET_DID_CLOSE_POTS_CTA_FROM_BUDGETING';
export const SET_DID_CLOSE_POTS_CTA_FROM_TRANSACTIONS = 'SET_DID_CLOSE_POTS_CTA_FROM_TRANSACTIONS';
export const SET_DID_CLOSE_POTS_CTA_FROM_POTS = 'SET_DID_CLOSE_POTS_CTA_FROM_POTS';
export const SET_FEED_PERSONALIZATION = 'SET_FEED_PERSONALIZATION';
export const SET_DEVICE_SETTINGS_KEY = 'SET_DEVICE_SETTINGS_KEY';
