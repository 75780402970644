import React from 'react';
import { StyleSheet, View, } from 'react-native';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
const IconCircle = ({ width = 24, height = 24, scale = 1, color, children, style, }) => {
    const colors = useColors();
    const remWidth = rem(width * scale);
    const remHeight = rem(height * scale);
    const bgColor = color ?? colors.background.dark;
    return (<View style={[
            styles.container,
            {
                width: remWidth,
                height: remHeight,
                backgroundColor: bgColor,
            },
            style,
        ]}>
      {children}
    </View>);
};
export default IconCircle;
const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 50,
    },
});
