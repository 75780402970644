import React from 'react';
import { Path, Defs, Stop, LinearGradient } from 'react-native-svg';
import { Icon } from 'design-system/icons/Icon';
import useColors from 'hooks/useColors';
const IconTransaction = ({ color, ...props }) => {
    const colors = useColors();
    // eslint-disable-next-line no-nested-ternary
    const gradientColors = props.gradientColors
        ? props.gradientColors
        : color
            ? [color, color]
            : colors.gradients.yellow.colors;
    return (<Icon {...props}>
      <Path d="M11.2036 9.21409C11.2036 10.5204 13.8065 11.2041 16.3776 11.2041C18.9487 11.2041 21.5515 10.5204 21.5515 9.21409C21.5515 6.60149 11.2036 6.60149 11.2036 9.21409Z" fill="url(#paint0_linear_3145_15313)"/>
      <Path d="M2.44818 14.7861C2.44818 16.0924 5.05108 16.7761 7.62216 16.7761C10.1932 16.7761 12.7961 16.0924 12.7961 14.7861C12.7961 12.1735 2.44818 12.1735 2.44818 14.7861Z" fill="url(#paint1_linear_3145_15313)"/>
      <Path d="M12.6041 16.7135C12.4848 16.642 12.3367 16.6369 12.2135 16.7014C11.1416 17.2627 9.51109 17.572 7.62216 17.572C5.73323 17.572 4.10276 17.2627 3.03081 16.7014C2.90761 16.6369 2.75873 16.642 2.64019 16.7135C2.52087 16.7854 2.44818 16.9148 2.44818 17.054V17.1741C2.44818 18.4804 5.05108 19.164 7.62216 19.164C10.1932 19.164 12.7961 18.4804 12.7961 17.1741V17.054C12.7961 16.9148 12.7234 16.7854 12.6041 16.7135Z" fill="url(#paint2_linear_3145_15313)"/>
      <Path d="M10.0108 8.81617L10.1577 8.81382C10.3217 8.81266 10.4683 8.71083 10.5265 8.55729C10.9098 7.55102 12.2857 6.84056 14.4008 6.55721C14.5621 6.53546 14.6938 6.41807 14.7339 6.25987C14.7735 6.10207 14.7133 5.93611 14.5815 5.84048C13.7272 5.22098 11.9759 4.83618 10.0108 4.83618C7.43969 4.83618 4.83679 5.51985 4.83679 6.82616C4.83679 8.13249 7.43969 8.81617 10.0108 8.81617Z" fill="url(#paint3_linear_3145_15313)"/>
      <Path d="M10.0108 11.2041L10.1795 11.2014C10.3303 11.2002 10.4675 11.1143 10.5339 10.9791C10.5864 10.8722 10.6404 10.787 10.6983 10.7182C10.8138 10.5818 10.8243 10.3855 10.7244 10.2378C10.6521 10.1306 10.5891 10.0082 10.5331 9.86316C10.4721 9.70574 10.2385 9.61052 10.0908 9.61052L10.0108 9.61209C8.12184 9.61209 6.49137 9.30269 5.41942 8.74148C5.29581 8.67697 5.14775 8.682 5.0288 8.75353C4.90948 8.82543 4.83679 8.95487 4.83679 9.09401V9.21411C4.83679 10.5204 7.43969 11.2041 10.0108 11.2041Z" fill="url(#paint4_linear_3145_15313)"/>
      <Path d="M21.3597 13.5294C21.2408 13.4579 21.0919 13.4528 20.9691 13.5174C19.8972 14.0786 18.2667 14.388 16.3778 14.388C15.5612 14.388 14.7741 14.3262 14.038 14.2049C13.9164 14.1863 13.7935 14.2216 13.7037 14.3044C13.6136 14.3876 13.5665 14.5073 13.5763 14.6293C13.5766 14.6344 13.591 14.7809 13.5918 14.786C13.5918 14.9341 13.5708 15.086 13.5277 15.2508C13.5001 15.3565 13.5172 15.4692 13.5751 15.5621C13.633 15.6551 13.7267 15.7199 13.834 15.7417C14.6066 15.8976 15.4862 15.98 16.3778 15.98C18.9488 15.98 21.5518 15.2963 21.5518 13.99V13.8699C21.5517 13.7307 21.4791 13.6013 21.3597 13.5294Z" fill="url(#paint5_linear_3145_15313)"/>
      <Path d="M21.3596 11.1415C21.2406 11.0699 21.0918 11.0649 20.9689 11.1294C19.897 11.6906 18.2665 12 16.3776 12C14.4887 12 12.8582 11.6906 11.7862 11.1294C11.6626 11.0649 11.5138 11.0699 11.3956 11.1415C11.2763 11.2134 11.2036 11.3428 11.2036 11.4819V11.6021C11.2036 12.9084 13.8065 13.592 16.3776 13.592C18.9487 13.592 21.5516 12.9084 21.5516 11.602V11.4819C21.5516 11.3428 21.4789 11.2134 21.3596 11.1415Z" fill="url(#paint6_linear_3145_15313)"/>
      <Path d="M5.26633 12.1784C5.28734 12.1784 5.30868 12.1768 5.33006 12.1734C5.48203 12.1489 5.63673 12.1287 5.79217 12.1092C5.9725 12.0871 6.11477 11.946 6.13809 11.766C6.16179 11.5861 6.06075 11.4127 5.89206 11.3451C5.72725 11.2787 5.5683 11.2071 5.41942 11.1294C5.29581 11.0649 5.14775 11.0699 5.0288 11.1415C4.90948 11.2138 4.83679 11.3428 4.83679 11.4819V11.6021C4.83679 11.7004 4.85388 11.7999 4.88888 11.9056C4.94336 12.0704 5.09727 12.1784 5.26633 12.1784Z" fill="url(#paint7_linear_3145_15313)"/>
      <Defs>
        <LinearGradient id="paint0_linear_3145_15313" x1="16.3776" y1="7.25464" x2="16.3776" y2="11.2041" gradientUnits="userSpaceOnUse">
          <Stop stopColor={gradientColors[0]}/>
          <Stop offset="1" stopColor={gradientColors[1]}/>
        </LinearGradient>
        <LinearGradient id="paint1_linear_3145_15313" x1="7.62216" y1="12.8267" x2="7.62216" y2="16.7761" gradientUnits="userSpaceOnUse">
          <Stop stopColor={gradientColors[0]}/>
          <Stop offset="1" stopColor={gradientColors[1]}/>
        </LinearGradient>
        <LinearGradient id="paint2_linear_3145_15313" x1="7.62216" y1="16.6562" x2="7.62216" y2="19.164" gradientUnits="userSpaceOnUse">
          <Stop stopColor={gradientColors[0]}/>
          <Stop offset="1" stopColor={gradientColors[1]}/>
        </LinearGradient>
        <LinearGradient id="paint3_linear_3145_15313" x1="9.79135" y1="4.83618" x2="9.79135" y2="8.81617" gradientUnits="userSpaceOnUse">
          <Stop stopColor={gradientColors[0]}/>
          <Stop offset="1" stopColor={gradientColors[1]}/>
        </LinearGradient>
        <LinearGradient id="paint4_linear_3145_15313" x1="7.81473" y1="8.69629" x2="7.81473" y2="11.2041" gradientUnits="userSpaceOnUse">
          <Stop stopColor={gradientColors[0]}/>
          <Stop offset="1" stopColor={gradientColors[1]}/>
        </LinearGradient>
        <LinearGradient id="paint5_linear_3145_15313" x1="17.5333" y1="13.4722" x2="17.5333" y2="15.98" gradientUnits="userSpaceOnUse">
          <Stop stopColor={gradientColors[0]}/>
          <Stop offset="1" stopColor={gradientColors[1]}/>
        </LinearGradient>
        <LinearGradient id="paint6_linear_3145_15313" x1="16.3776" y1="11.0842" x2="16.3776" y2="13.592" gradientUnits="userSpaceOnUse">
          <Stop stopColor={gradientColors[0]}/>
          <Stop offset="1" stopColor={gradientColors[1]}/>
        </LinearGradient>
        <LinearGradient id="paint7_linear_3145_15313" x1="5.48916" y1="11.0842" x2="5.48916" y2="12.1784" gradientUnits="userSpaceOnUse">
          <Stop stopColor={gradientColors[0]}/>
          <Stop offset="1" stopColor={gradientColors[1]}/>
        </LinearGradient>
      </Defs>
    </Icon>);
};
export default IconTransaction;
