import { SET_AD_ACTION, CLEAR_ADS_ACTION, SET_SHOULD_SEE_DEVICE_ROOTED_WARNING_MODAL, } from './sessionSettingsTypes';
export const setShouldSeeDeviceRootedWarningModal = (value) => ({
    value,
    type: SET_SHOULD_SEE_DEVICE_ROOTED_WARNING_MODAL,
});
export const clearAdsAction = () => ({
    type: CLEAR_ADS_ACTION,
});
export const setAdAction = (ad) => ({
    ad,
    type: SET_AD_ACTION,
});
