export const springDefaultConfig = {
    damping: 15,
    mass: 1,
    stiffness: 600,
};
export const timingFeedbackDefaultConfig = {
    duration: 80,
};
export const timingAnimationDefaultConfig = {
    duration: 300,
};
export const CURRENT_PRICE_CIRCLE_RADIUS = 3.5;
