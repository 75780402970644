import React, { useId } from 'react';
import { StyleSheet } from 'react-native';
import { Path, Svg } from 'react-native-svg';
import useColors from 'hooks/useColors';
import { rem } from '../values';
import { IconWithGradient } from './Icon';
/**
 * Figma: https://www.figma.com/file/SMqeFyVH6qLbMzeoCHBp4A/Emma-Design-System?node-id=3369%3A95374
 */
export const IconClose = ({ size, style, color: overrideColor, alt, fat, colorKey, gradientKey, }) => {
    const colors = useColors();
    const id = useId();
    if (fat) {
        return (<IconWithGradient id={id} defaultGradientKey="negativeCards" color={overrideColor} colorKey={colorKey} gradientKey={gradientKey} width={size} height={size}>
        <Path fillRule="evenodd" clipRule="evenodd" d="M9.41426 6.58579C8.63321 5.80474 7.36688 5.80474 6.58582 6.58579C5.80477 7.36684 5.80477 8.63317 6.58582 9.41423L9.17161 12L6.58579 14.5858C5.80474 15.3669 5.80474 16.6332 6.58579 17.4143C7.36684 18.1953 8.63317 18.1953 9.41423 17.4143L12 14.8284L14.5859 17.4143C15.3669 18.1953 16.6332 18.1953 17.4143 17.4143C18.1953 16.6332 18.1953 15.3669 17.4143 14.5858L14.8285 12L17.4143 9.41424C18.1953 8.63319 18.1953 7.36686 17.4143 6.5858C16.6332 5.80475 15.3669 5.80475 14.5858 6.5858L12 9.17157L9.41426 6.58579Z" fill={`url(#gradient_${id})`}/>
      </IconWithGradient>);
    }
    if (alt) {
        return (<Svg width={size ? rem(size) : rem(24)} height={size ? rem(size) : rem(24)} viewBox="0 0 24 24">
        <Path d="M7 7L16.9999 17" stroke={overrideColor ?? colors.text.white} strokeWidth="3.33333" strokeLinecap="round"/>
        <Path d="M16.9999 7L7 17" stroke={overrideColor ?? colors.text.white} strokeWidth="3.33333" strokeLinecap="round"/>
      </Svg>);
    }
    const { width = rem(12), height = rem(12), color = overrideColor ?? colors.elements.white, } = StyleSheet.flatten(style) ?? {};
    return (<Svg width={size ? rem(size) : Number(width)} height={size ? rem(size) : Number(height)} style={style} viewBox="0 0 12 12">
      <Path d="M11.358 0.357736C11.8252 0.824952 11.8252 1.58246 11.358 2.04967L7.69215 5.71554L11.358 9.38141C11.8252 9.84862 11.8252 10.6061 11.358 11.0733C10.8908 11.5406 10.1333 11.5406 9.66607 11.0733L6.00021 7.40748L2.33434 11.0733C1.86713 11.5406 1.10962 11.5406 0.642404 11.0733C0.175188 10.6061 0.175188 9.84862 0.642404 9.38141L4.30827 5.71554L0.642404 2.04967C0.175188 1.58246 0.175188 0.824952 0.642404 0.357736C1.10962 -0.10948 1.86713 -0.10948 2.33434 0.357736L6.00021 4.0236L9.66608 0.357736C10.1333 -0.10948 10.8908 -0.10948 11.358 0.357736Z" fill={color}/>
    </Svg>);
};
export default IconClose;
