import { useCallback } from 'react';
import { useAppStore } from 'store/hooks';
import { useLoginStackNavigation } from 'utils/types/navigationV6';
import { selectOverallBudgetBaseLimit } from 'features/budgeting/selectors';
import { selectHasCurrentRentReporting } from 'features/rent-reporting/selectors';
import { navigateToLoggedIn } from '../LoginNavConditionalRoutes';
export const useNavigateToNextScreen = () => {
    const store = useAppStore();
    const navigation = useLoginStackNavigation();
    return useCallback(() => {
        const { user: { user, userAdditionalInfo, connections, resetPasscodeNextStep }, utils: { featureFlags }, addressReducer: { userAddresses }, signInNavigation, spaces: { spaceInfo: spaces }, } = store.getState();
        const budgetBaseLimit = selectOverallBudgetBaseLimit(store.getState());
        const hasCurrentRentReporting = selectHasCurrentRentReporting(store.getState());
        navigateToLoggedIn(navigation, {
            user,
            userAddresses,
            userAdditionalInfo,
            connections,
            featureFlags,
            signInNavigation,
            budgetBaseLimit,
            resetPasscodeNextStep,
            hasCurrentRentReporting,
            spaces,
        });
    }, [store]);
};
