import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
import useColors from 'hooks/useColors';
const IconCoinStack = ({ ...props }) => {
    const id = useId();
    const colors = useColors();
    return (<IconWithGradient id={id} width={16} height={16} viewBox="0 0 16 16" fill="none" defaultGradientKey="darkPurple" {...props}>
      <Path d="M15.7452 10.0382C15.3722 13.022 13.022 15.3722 10.0382 15.7452C8.68467 15.9144 7.31533 15.9144 5.96178 15.7452C2.97799 15.3722 0.627754 13.022 0.254779 10.0382C0.0855872 8.68467 0.0855873 7.31533 0.25478 5.96178C0.627754 2.97799 2.97799 0.627752 5.96179 0.254778C7.31533 0.0855858 8.68467 0.0855859 10.0382 0.254778C13.022 0.627753 15.3722 2.97799 15.7452 5.96178C15.9144 7.31533 15.9144 8.68467 15.7452 10.0382Z" fill={colors.text.brand}/>
      <Path d="M8 7.79996C5.79063 7.79996 4 7.05308 4 6.13277C4 5.21246 5.79063 4.46558 8 4.46558C10.2094 4.46558 12 5.21246 12 6.13277C12 7.05308 10.2094 7.79996 8 7.79996ZM8 10.4656C9.52656 10.4656 10.8766 10.0984 11.7281 9.53121C11.9016 9.71871 12 9.92027 12 10.1328C12 11.0531 10.2094 11.8 8 11.8C5.79063 11.8 4 11.0531 4 10.1328C4 9.92027 4.09844 9.71871 4.27187 9.53277C5.12344 10.0984 6.47344 10.4656 8 10.4656ZM8 8.46558C9.52656 8.46558 10.8766 8.0984 11.7281 7.53121C11.9016 7.71871 12 7.92027 12 8.13277C12 9.05308 10.2094 9.79996 8 9.79996C5.79063 9.79996 4 9.05308 4 8.13277C4 7.92027 4.09844 7.71871 4.27187 7.53277C5.12344 8.0984 6.47344 8.46558 8 8.46558Z" fill={colors.elements.white}/>
    </IconWithGradient>);
};
export default IconCoinStack;
