import { CALL_API } from 'middleware/api';
import * as types from './types';
export const getWebSignInQRCodeStatus = (request) => ({
    [CALL_API]: {
        muteAlert: true,
        endpoint: `/sign-in?clientId=${request.clientId}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        extra: request,
        types: [
            types.GET_WEB_SIGN_IN_QR_CODE_STATUS_REQUEST,
            types.GET_WEB_SIGN_IN_QR_CODE_STATUS_SUCCESS,
            types.GET_WEB_SIGN_IN_QR_CODE_STATUS_FAILURE,
        ],
        noAccessToken: true,
    },
});
export const updateWebSignInQRCodeStatus = (request) => ({
    [CALL_API]: {
        muteAlert: true,
        endpoint: '/sign-in/qr',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
        extra: request,
        types: [
            types.UPDATE_WEB_SIGN_IN_QR_CODE_STATUS_REQUEST,
            types.UPDATE_WEB_SIGN_IN_QR_CODE_STATUS_SUCCESS,
            types.UPDATE_WEB_SIGN_IN_QR_CODE_STATUS_FAILURE,
        ],
    },
});
export const resetWebSignInConfirmation = () => ({
    type: types.RESET_WEB_SIGN_IN_CONFIRMATION,
});
