import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from './Icon';
export const IconCoinStack = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="darkPurple">
      <Path d="M11.177 9.12c0 1.35 2.69 2.057 5.349 2.057 2.658 0 5.35-.707 5.35-2.057 0-2.701-10.7-2.701-10.7 0Z" fill={`url(#gradient_${id})`}/>
      <Path d="M2.124 14.88c0 1.351 2.691 2.058 5.35 2.058 2.658 0 5.349-.706 5.349-2.057 0-2.701-10.7-2.701-10.7 0Z" fill={`url(#gradient_${id})`}/>
      <Path d="M12.624 16.873a.413.413 0 0 0-.404-.012c-1.108.58-2.794.9-4.747.9-1.953 0-3.638-.32-4.747-.9a.413.413 0 0 0-.602.364v.124c0 1.35 2.691 2.058 5.35 2.058 2.658 0 5.349-.707 5.349-2.058v-.124a.411.411 0 0 0-.199-.352Z" fill={`url(#gradient_${id})`}/>
      <Path d="m9.942 8.708.151-.002a.411.411 0 0 0 .382-.265c.396-1.04 1.819-1.775 4.005-2.068a.412.412 0 0 0 .187-.741c-.883-.64-2.694-1.039-4.725-1.039-2.659 0-5.35.707-5.35 2.058 0 1.35 2.691 2.057 5.35 2.057Z" fill={`url(#gradient_${id})`}/>
      <Path d="m9.942 11.177.174-.003a.412.412 0 0 0 .366-.23c.055-.11.11-.198.17-.27a.412.412 0 0 0 .027-.496 1.925 1.925 0 0 1-.197-.387c-.063-.163-.305-.262-.458-.262l-.082.002c-1.953 0-3.64-.32-4.747-.9a.413.413 0 0 0-.603.364v.124c0 1.351 2.691 2.058 5.35 2.058Z" fill={`url(#gradient_${id})`}/>
      <Path d="M21.678 13.581a.413.413 0 0 0-.404-.012c-1.109.58-2.794.9-4.747.9-.845 0-1.658-.064-2.42-.19a.412.412 0 0 0-.477.439l.016.162c0 .153-.021.31-.066.481a.412.412 0 0 0 .317.508c.799.16 1.708.246 2.63.246 2.658 0 5.35-.707 5.35-2.057v-.125a.411.411 0 0 0-.2-.352Z" fill={`url(#gradient_${id})`}/>
      <Path d="M21.677 11.113a.413.413 0 0 0-.404-.013c-1.108.58-2.794.9-4.747.9-1.953 0-3.639-.32-4.747-.9a.413.413 0 0 0-.602.365v.124c0 1.35 2.69 2.057 5.349 2.057 2.658 0 5.35-.707 5.35-2.057v-.124a.411.411 0 0 0-.2-.352Z" fill={`url(#gradient_${id})`}/>
      <Path d="M5.036 12.185a.41.41 0 0 0 .066-.005c.157-.026.317-.047.478-.067a.41.41 0 0 0 .103-.79 5.53 5.53 0 0 1-.488-.223.413.413 0 0 0-.603.365v.124c0 .101.018.204.054.314.056.17.215.282.39.282Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
