import { format, sub } from 'date-fns';
import { useCallback } from 'react';
import { getInvestmentHistory } from 'actions';
import { useAppDispatch } from 'store/hooks';
const useInvestmentGraphBalanceHistory = () => {
    const dispatch = useAppDispatch();
    const getInvestmentGraphBalanceHistory = useCallback((id) => {
        const now = new Date();
        const toEnd = format(now, 'yyyy-MM-dd');
        const fromStart = format(sub(now, { years: 1 }), 'yyyy-MM-dd');
        dispatch(getInvestmentHistory(fromStart, toEnd, id, '1day'));
    }, []);
    return getInvestmentGraphBalanceHistory;
};
export default useInvestmentGraphBalanceHistory;
