import React from 'react';
import { View } from 'react-native';
import { rem } from 'design-system/values';
import IconClose from 'design-system/icons/IconClose';
import { IconEdit } from 'design-system/icons/IconEdit';
import NavigationHeaderRightIconButton from 'design-system/NavigationHeaderRightIconButton';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
const TransactionsHeader = ({ isEditing, onPressEdit }) => {
    const pressEdit = () => {
        onPressEdit?.(!isEditing);
    };
    const colors = useColors();
    const styles = useStyles(styleSet);
    return (<View style={styles.headerRightContainer}>
      <NavigationHeaderRightIconButton onPress={pressEdit}>
        {isEditing ? (<IconClose alt color={colors.elements.primary}/>) : (<IconEdit color={colors.elements.primary}/>)}
      </NavigationHeaderRightIconButton>
    </View>);
};
const styleSet = createStyleSheets(() => ({
    headerRightContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    closeIcon: {
        width: rem(16),
        height: rem(16),
    },
}));
export default TransactionsHeader;
