import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import { CardView } from 'design-system/CardView';
import { rem } from 'design-system/values';
import Section from 'design-system/Section';
import SubscriptionAd from 'features/subscriptions/components/transactions/SubscriptionAd';
import Amplitude from 'utils/amplitude';
import Links from 'utils/links';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import useOpenSupport from 'features/support/hooks/useOpenSupport';
import TransactionHelpItem from './TransactionHelpItem';
import { styles as sectionStyles } from './styles';
import { isWeb } from '../../../../constants';
const TransactionHelpSection = ({ transaction }) => {
    const nav = useMainStackNavigation();
    const openAd = (routeWithParams) => {
        nav.navigate(routeWithParams);
        Amplitude.logEvent('Item.openCompare', { type: routeWithParams.name });
    };
    const openFeedback = () => {
        nav.navigate('MerchantFeedback', {
            transaction,
        });
    };
    const openContactSupport = useOpenSupport();
    const openSupport = () => {
        Links.openLink('https://help.emma-app.com/en/category/transactions-and-categories-1bw1563/');
    };
    return (<Section title="Help" style={sectionStyles.section}>
      <CardView noMarginTop noMarginBottom>
        {isWeb ? null : (<>
            {!transaction?.isPending &&
                'canSuggestMerchant' in transaction &&
                transaction.canSuggestMerchant &&
                transaction.merchant && (<SubscriptionAd merchant={transaction.merchant} onPress={openAd} style={styles.adContainer}/>)}
            {!transaction?.isPending && (<TransactionHelpItem title="Something wrong?" subTitle="Improve the logo and name" onPress={openFeedback}/>)}
          </>)}
        <TransactionHelpItem title="Need to amend your data?" subTitle="Learn how to" onPress={openSupport}/>
        <TransactionHelpItem title="Have an issue?" subTitle="Contact support" onPress={openContactSupport}/>
      </CardView>
    </Section>);
};
const styles = StyleSheet.create({
    adContainer: {
        marginTop: rem(16),
    },
});
export default memo(TransactionHelpSection);
