import { useCallback, useState } from 'react';
import { Alert, Platform } from 'react-native';
import { getUser } from 'actions/user';
import { getIapSubscriptionPrices, getItemSKUForProductId, getLastPurchase, } from 'features/premium/iap';
import { selectUserTier } from 'features/premium/selectors/tier';
import { muteAllButThrows, useFetchOld } from 'hooks/useFetch';
import { useAppStore } from 'store/hooks';
import Amplitude from 'utils/amplitude';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { validatePurchase } from '../actions/validatePurchase';
import { tierThemes } from '../benefits';
import { Tier, tierFromProductId } from '../entities';
const strings = {
    alert: {
        success: {
            title: 'Restore Successful',
        },
        error: {
            title: 'Restore unsuccessful',
        },
        errorNoProducts: {
            message: 'There are no products to restore.',
        },
        errorNoSubscription: {
            message: 'The subscription was not found for product',
        },
        errorTryAgainLater: {
            message: 'Please try again, if the error persists, contact support.',
        },
    },
};
export const useRestoreInAppPurchases = () => {
    const [, , , fetch] = useFetchOld(muteAllButThrows);
    const [isFetching, setIsFetching] = useState(false);
    const store = useAppStore();
    const navigation = useMainStackNavigation();
    const openRestorePurchases = useCallback(async () => {
        if (isFetching)
            return;
        setIsFetching(true);
        try {
            const { purchase, subscription } = await getLastPurchase();
            if (!purchase) {
                Alert.alert(strings.alert.error.title, strings.alert.errorNoProducts.message);
                return;
            }
            if (!subscription) {
                Alert.alert(strings.alert.error.title, `${strings.alert.errorNoSubscription.message} ${purchase.productId}.`);
                return;
            }
            const prices = getIapSubscriptionPrices(subscription);
            const { transactionId, transactionReceipt, productId, purchaseToken } = purchase;
            await fetch(validatePurchase({
                productId,
                price: prices?.price,
                currency: prices?.currency,
                ...(Platform.OS === 'ios'
                    ? { transactionId, transactionReceipt }
                    : { packageName: 'com.emmaprod', purchaseToken }),
            }));
            await fetch(getUser());
            const userTier = selectUserTier(store.getState());
            if (userTier === Tier.free) {
                const itemSku = getItemSKUForProductId(productId);
                const tier = itemSku?.tier || tierFromProductId(productId);
                Alert.alert(strings.alert.error.title, `We found a previous subscription to ${tierThemes[tier].name} but it appears to have expired. Upgrade to ${tierThemes[Tier.plus].shortName}, ${tierThemes[Tier.pro].shortName} or ${tierThemes[Tier.ultimate].shortName} to access our premium features.`, [
                    { text: 'Cancel', style: 'cancel' },
                    {
                        text: 'Upgrade',
                        onPress: () => {
                            navigation.navigate('EmmaProModal', {});
                        },
                    },
                ]);
            }
            else {
                Alert.alert(strings.alert.success.title, `You have successfully restored ${tierThemes[userTier].name}. 💎`);
                Amplitude.logEvent('AccountSettings.RestorePurchases', {
                    restored: true,
                });
            }
        }
        catch (err) {
            Alert.alert(strings.alert.error.title, strings.alert.errorTryAgainLater.message);
            Amplitude.logEvent('AccountSettings.RestorePurchases', {
                restored: false,
            });
        }
        finally {
            setIsFetching(false);
        }
    }, [isFetching]);
    return {
        isFetching,
        openRestorePurchases,
    };
};
