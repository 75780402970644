import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconWheel = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} defaultGradientKey="apricot" {...props}>
      <Path fill={`url(#gradient_${id})`} d="M13.469 11.0225C12.9296 11.0225 12.4907 11.4613 12.4907 12.0008C12.4907 12.5402 12.9296 12.9791 13.469 12.9791C14.0085 12.9791 14.4474 12.5402 14.4474 12.0008C14.4474 11.4613 14.0085 11.0225 13.469 11.0225Z"/>
      <Path fill={`url(#gradient_${id})`} d="M14.1167 8.05676V9.82095C14.6191 9.97056 15.0492 10.2893 15.3406 10.7111L17.0186 10.1659C16.448 9.06618 15.3812 8.26376 14.1167 8.05676Z"/>
      <Path fill={`url(#gradient_${id})`} d="M17.4201 11.3979L15.7417 11.9433C15.7421 11.9623 15.7432 11.9813 15.7432 12.0004C15.7432 12.5198 15.5678 12.9988 15.2736 13.3821L16.31 14.8084C17.024 14.0861 17.4655 13.0939 17.4655 12.0004C17.4655 11.7957 17.45 11.5945 17.4201 11.3979Z"/>
      <Path fill={`url(#gradient_${id})`} d="M13.4688 3.46948H5.58552C5.22766 3.46948 4.93754 3.7596 4.93754 4.11745C4.93754 4.4753 5.22766 4.76542 5.58552 4.76542H8.95372C8.2431 5.21046 7.60236 5.75703 7.05216 6.38496H2.64797C2.29012 6.385 2 6.67515 2 7.03301C2 7.39086 2.29012 7.68098 2.64797 7.68098H6.11477C5.36719 8.94879 4.93751 10.4254 4.93751 12.0007C4.93751 13.8337 5.51872 15.5335 6.5063 16.9256H4.66778C4.30993 16.9256 4.01981 17.2157 4.01981 17.5736C4.01981 17.9314 4.30993 18.2215 4.66778 18.2215H7.63728C8.0413 18.6005 8.48212 18.9406 8.95372 19.236H2.71481C2.35695 19.236 2.06684 19.5261 2.06684 19.8839C2.06684 20.2418 2.35695 20.5319 2.71481 20.5319H13.4688C18.173 20.5319 22 16.7048 22 12.0007C22 7.29656 18.1729 3.46948 13.4688 3.46948ZM16.6164 16.2531C16.6044 16.2631 16.5926 16.2733 16.5798 16.2826C16.5653 16.2931 16.5505 16.3029 16.5355 16.312C15.6698 16.9296 14.611 17.2934 13.4688 17.2934C12.3266 17.2934 11.2678 16.9296 10.402 16.312C10.387 16.3029 10.3722 16.2931 10.3578 16.2826C10.345 16.2732 10.3331 16.2631 10.3212 16.2531C9.0206 15.2878 8.17607 13.7411 8.17607 12.0007C8.17607 9.08231 10.5504 6.708 13.4688 6.708C16.3872 6.708 18.7615 9.08231 18.7615 12.0007C18.7615 13.7411 17.917 15.2878 16.6164 16.2531Z"/>
      <Path fill={`url(#gradient_${id})`} d="M9.91901 10.1659L11.5969 10.7111C11.8885 10.2893 12.3185 9.9706 12.8209 9.82095V8.05676C11.5565 8.26379 10.4897 9.06618 9.91901 10.1659Z"/>
      <Path fill={`url(#gradient_${id})`} d="M14.2262 14.1456C13.9892 14.2295 13.7345 14.2757 13.4692 14.2757C13.2038 14.2757 12.9491 14.2295 12.7122 14.1456L11.6756 15.5723C12.2153 15.8444 12.8246 15.998 13.4692 15.998C14.1137 15.998 14.723 15.8445 15.2627 15.5723L14.2262 14.1456Z"/>
      <Path fill={`url(#gradient_${id})`} d="M11.1944 12.0004C11.1944 11.9813 11.1953 11.9623 11.1958 11.9433L9.51731 11.3979C9.48747 11.5945 9.47192 11.7957 9.47192 12.0004C9.47192 13.0939 9.91353 14.0861 10.6276 14.8085L11.6639 13.3821C11.3698 12.9988 11.1944 12.5198 11.1944 12.0004Z"/>
    </IconWithGradient>);
};
