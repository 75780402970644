import i18n from '../../i18n';
// networth sections
export const sections = {
    creditCards: 'Credit Cards',
    savings: 'Savings',
    investments: 'Investments',
    loans: 'Loans',
    realEstate: 'Real Estate',
    vehicles: 'Vehicles',
    otherAssets: 'Other assets',
    assets: 'Assets',
    debt: 'Debt',
    netWorth: 'Net worth',
    pensions: 'Pensions',
    hidden: 'Hidden',
    closed: 'Closed',
};
export const DUMMY_ACCOUNT_TEXT_MAP = {
    CHECKING: `${i18n.t('current')} Account`,
    CREDITCARD: 'Credit Card',
    SAVINGS: 'Savings Account',
    LOAN: 'Loan',
    INVESTMENT: 'Investment Account',
    PENSION: 'Pension',
    REAL_ESTATE: 'Real Estate',
    VEHICLE: 'Vehicle',
    OTHER: 'Other Assets',
};
