import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { rem } from 'design-system/values';
export const useMarginBottom = (key = 'marginBottom', offset = 0, ...extraStyles) => {
    const { bottom } = useSafeAreaInsets();
    const style = useMemo(() => ({
        [key]: (bottom || rem(16)) + offset,
        ...StyleSheet.flatten(extraStyles),
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bottom, extraStyles[0], extraStyles[1], extraStyles[2], key, offset]);
    return style;
};
