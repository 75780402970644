import { forceRefreshAnalytics, getCategories, getFeed } from 'actions';
import { CONNECTIONS_REQUEST } from 'actions/types';
import { put, select, takeEvery, takeLatest } from 'typed-redux-saga/macro';
import { switchedSpace, getSpaceInfo, } from '../actions';
import { SELECTED_SPACE } from '../constants';
import { selectPreviousSelectedSpace, selectSelectedSpaceData, } from '../reducers/selectors';
function* refreshSpaceAccounts() {
    const space = yield* select(selectSelectedSpaceData);
    if (space && !space.isDefault) {
        log('[spaceSaga] CONNECTIONS rquest dispatch while viewing none default space, will refresh space accounts... ', false, 'cyan');
        yield* put(getSpaceInfo(space.id));
    }
}
function* changeSpace(action) {
    const id = action.payload;
    const previousSpace = yield* select(selectPreviousSelectedSpace);
    if (id !== previousSpace && id !== undefined) {
        log('[spaceSaga] Switching space...', false, 'cyan');
        yield* put(getSpaceInfo(id));
        yield* put(getFeed());
        yield* put(forceRefreshAnalytics());
        yield* put(getCategories());
        yield* put(switchedSpace());
    }
}
function* spaceSaga() {
    log('[spaceSaga] starting...', false, 'cyan');
    yield* takeEvery(CONNECTIONS_REQUEST, refreshSpaceAccounts);
    yield* takeLatest(SELECTED_SPACE, changeSpace);
}
export default spaceSaga;
