import React, { memo, useEffect, useState } from 'react';
import { View, Animated, StyleSheet } from 'react-native';
const SmallCircleButton = ({ active, style }) => {
    const [state] = useState({
        opacity: new Animated.Value(0.2),
        scale: new Animated.Value(1),
    });
    useEffect(() => {
        animationOpacity();
        animationScale();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active]);
    const animationOpacity = () => {
        if (active) {
            Animated.timing(state.opacity, {
                toValue: 1,
                duration: 100,
                useNativeDriver: true,
            }).start();
        }
        else {
            Animated.timing(state.opacity, {
                toValue: 0.2,
                duration: 100,
                useNativeDriver: true,
            }).start();
        }
    };
    const animationScale = () => {
        Animated.sequence([
            Animated.timing(state.scale, {
                toValue: 1.3,
                duration: 50,
                useNativeDriver: true,
            }),
            Animated.timing(state.scale, {
                toValue: 1,
                duration: 100,
                useNativeDriver: true,
            }),
        ]).start();
    };
    const stylesBall = [
        styles.ball,
        { transform: [{ scale: state.scale }] },
        { opacity: state.opacity },
    ];
    return (<View style={styles.elementContainer}>
      <Animated.View style={[stylesBall, style]}/>
    </View>);
};
const styles = StyleSheet.create({
    elementContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    ball: {
        height: 14,
        width: 14,
        borderRadius: 7,
        borderWidth: StyleSheet.hairlineWidth,
        borderColor: 'white',
        backgroundColor: 'white',
    },
});
export default memo(SmallCircleButton);
