import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconShieldDone = ({ size = 24, ...props }) => {
    const id = useId();
    return (<IconWithGradient id={id} width={size} height={size} viewBox="0 0 24 24" {...props} defaultGradientKey="green">
      <Path d="M11.7281 21.9137C11.8389 21.9715 11.9627 22.0009 12.0865 22C12.2103 21.999 12.3332 21.9686 12.4449 21.9097L16.0128 20.0025C17.0246 19.4631 17.8169 18.8601 18.435 18.1579C19.779 16.6282 20.5129 14.6758 20.4998 12.6626L20.4575 6.02198C20.4535 5.25711 19.9512 4.57461 19.2082 4.32652L12.5708 2.09956C12.1711 1.96424 11.7332 1.96718 11.3405 2.10643L4.72827 4.41281C3.98932 4.67071 3.49602 5.35811 3.50005 6.12397L3.54233 12.7597C3.55542 14.7758 4.3145 16.7194 5.68064 18.2335C6.30482 18.9258 7.10417 19.52 8.12702 20.0505L11.7281 21.9137ZM10.7836 14.1089C10.9326 14.2521 11.1259 14.3227 11.3192 14.3207C11.5125 14.3198 11.7048 14.2472 11.8517 14.1021L15.7508 10.2581C16.0438 9.96882 16.0408 9.50401 15.7448 9.21866C15.4478 8.9333 14.9696 8.93526 14.6767 9.22454L11.3081 12.5449L9.92888 11.2191C9.63189 10.9337 9.1547 10.9367 8.86073 11.226C8.56777 11.5152 8.57079 11.98 8.86777 12.2654L10.7836 14.1089Z" fill={`url(#gradient_${id})`} fillRule="evenodd" clipRule="evenodd"/>
    </IconWithGradient>);
};
export default IconShieldDone;
