import { StatusBar, Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { HeaderHeightContext, getDefaultHeaderHeight, useHeaderHeight as useRNHeaderHeight, } from '@react-navigation/elements';
import { useContext } from 'react';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
const useHeaderHeight = (pureHeaderHeight) => {
    const { top } = useSafeAreaInsets();
    const headerHeight = useContext(HeaderHeightContext) ?? 0;
    if (pureHeaderHeight === undefined) {
        return headerHeight;
    }
    if (Platform.OS === 'ios') {
        return pureHeaderHeight + top;
    }
    if (Platform.OS === 'android') {
        return pureHeaderHeight + (StatusBar.currentHeight ?? 30);
    }
    return pureHeaderHeight;
};
export const usePureHeaderHeight = () => {
    const { top } = useSafeAreaInsets();
    const headerHeight = useRNHeaderHeight();
    return headerHeight - top;
};
export const useDefaultHeaderHeight = () => {
    const statusBarHeight = useStatusBarHeight();
    const { width, height } = useAppFrameDimensions();
    return getDefaultHeaderHeight({
        width,
        height,
    }, false, statusBarHeight);
};
export const useStatusBarHeight = () => {
    const { top } = useSafeAreaInsets();
    if (Platform.OS === 'ios') {
        return top;
    }
    if (Platform.OS === 'android') {
        return StatusBar.currentHeight ?? 30;
    }
    return 0;
};
export default useHeaderHeight;
