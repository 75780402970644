import React, { useMemo, useState, memo, useCallback } from 'react';
import { FlatList, View, } from 'react-native';
import { rem } from 'design-system';
import Text from 'design-system/Text';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableBounce from 'utils/packages/TouchableBounce';
const DAYS = Array.from({ length: 35 }, (_, i) => i + 1);
const keyExtractor = (item) => item.toString();
const DateRangeSelector = ({ start, end, style, small, ...props }) => {
    const styles = useStyles(styleSet);
    const [startDate, setStartDate] = useState(start);
    const [endDate, setEndDate] = useState(end);
    const textSizeProps = useMemo(() => small
        ? {
            'Numbers-14': true,
        }
        : {
            'Numbers-16': true,
        }, [small]);
    const onPressDay = useCallback((day) => {
        if ('type' in props && props.type === 'single') {
            setStartDate(day);
            props.onChange(day);
        }
        if ('type' in props && props.type === 'range') {
            if (startDate && endDate) {
                setStartDate(day);
                setEndDate(undefined);
                props.onChange({ start: day, end: undefined });
                return;
            }
            if (!props?.allowReverseDates && startDate && day < startDate) {
                setEndDate(startDate);
                setStartDate(day);
                props.onChange({ start: day, end: startDate });
                return;
            }
            if (!startDate) {
                setStartDate(day);
                props.onChange({ start: day, end: endDate });
                return;
            }
            if (!endDate && day !== startDate) {
                setEndDate(day);
                props.onChange({ end: day, start: startDate });
            }
        }
    }, [endDate, props, startDate]);
    const getLeftAndRightFills = useCallback((item) => {
        const hasBothDates = !!(startDate && endDate);
        if (hasBothDates) {
            return {
                rightFill: endDate > startDate
                    ? startDate <= item && endDate > item
                    : item < endDate || item >= startDate,
                leftFill: endDate > startDate
                    ? startDate < item && endDate >= item
                    : item <= endDate || item > startDate,
            };
        }
        return {
            leftFill: false,
            rightFill: false,
        };
    }, [startDate, endDate]);
    const renderItem = useCallback(({ item }) => {
        if (item > 31)
            return <View style={styles.eachContainer}/>;
        const selected = item === startDate || item === endDate;
        const { rightFill, leftFill } = getLeftAndRightFills(item);
        const press = () => onPressDay(item);
        return (<View style={styles.eachContainer}>
          {leftFill && <View style={styles.dayLeftFill}/>}
          {rightFill && <View style={styles.dayRightFill}/>}
          <TouchableBounce onPress={press} style={[
                styles.day,
                selected ? styles.daySelected : null,
                item % 7 === 0 ? styles.lastDayInRow : null,
            ]} hoverStyle={styles.daySelected}>
            {selected ? (<Text {...textSizeProps} White>
                {item}
              </Text>) : (<Text {...textSizeProps} Gray>
                {item}
              </Text>)}
          </TouchableBounce>
        </View>);
    }, [
        endDate,
        getLeftAndRightFills,
        onPressDay,
        startDate,
        styles.day,
        styles.dayLeftFill,
        styles.dayRightFill,
        styles.daySelected,
        styles.eachContainer,
        styles.lastDayInRow,
        textSizeProps,
    ]);
    return (<View style={style}>
      <FlatList scrollEnabled={false} numColumns={7} data={DAYS} renderItem={renderItem} columnWrapperStyle={styles.columnWrapper} keyExtractor={keyExtractor} extraData={{ startDate, endDate }} scrollEventThrottle={50}/>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    eachContainer: {
        flex: 1,
        paddingHorizontal: rem(8),
    },
    columnWrapper: {
        marginBottom: rem(16),
    },
    day: {
        flex: 1,
        aspectRatio: 1,
        alignItems: 'center',
        borderRadius: rem(50),
        justifyContent: 'center',
        backgroundColor: colors.background.dark,
    },
    small: {
        width: rem(38),
        height: rem(38),
    },
    daySelected: {
        backgroundColor: colors.gradients.ultimate.colors[0],
    },
    lastDayInRow: {
        marginEnd: rem(0),
    },
    dayRightFill: {
        backgroundColor: colors.background.dark,
        position: 'absolute',
        width: rem(29),
        bottom: 0,
        right: -1,
        top: 0,
    },
    dayLeftFill: {
        backgroundColor: colors.background.dark,
        position: 'absolute',
        width: rem(29),
        bottom: 0,
        left: -1,
        top: 0,
    },
}));
export default memo(DateRangeSelector);
