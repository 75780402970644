import { FETCH_APP_DATA, FETCH_APP_DATA_COMPLETE, FETCH_DASHBOARD_MODAL_STACK, } from 'actions/refresh';
import { CONNECTION_CHANGE_HANDLED, LOGGED_OUT } from 'actions/types';
export const initialState = {
    isRefreshingApp: true,
    /**
     * This flag prevents the duplicate calls for onBankAdd listeners
     * This flag is reset on ChooseBank mount
     * Sets after one of the listeners handles the add bank connection
     */
    connectionChangeHandled: false,
    lastLoggedInTimestamp: undefined,
    lastLoggedOutTimestamp: undefined,
};
const unpersistedUtils = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_APP_DATA:
        case FETCH_DASHBOARD_MODAL_STACK: {
            return {
                ...state,
                isRefreshingApp: true,
            };
        }
        case 'SET_LAST_LOGGED_OUT_TIMESTAMP_ACTION': {
            return {
                ...state,
                lastLoggedOutTimestamp: action.timestamp,
            };
        }
        case 'SET_LAST_LOGGED_IN_TIMESTAMP_ACTION': {
            return {
                ...state,
                lastLoggedInTimestamp: new Date().getTime(),
            };
        }
        case FETCH_APP_DATA_COMPLETE: {
            return {
                ...state,
                isRefreshingApp: false,
            };
        }
        case CONNECTION_CHANGE_HANDLED: {
            return {
                ...state,
                connectionChangeHandled: action.payload.status,
            };
        }
        case LOGGED_OUT: {
            return {
                ...state,
                isRefreshingApp: true,
                connectionChangeHandled: false,
                lastLoggedInTimestamp: undefined,
            };
        }
        default: {
            return state;
        }
    }
};
export default unpersistedUtils;
