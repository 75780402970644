import React from 'react';
import { StyleSheet } from 'react-native';
import { IconGlobe } from 'design-system/icons/IconGlobe';
import { CardView } from 'design-system/CardView';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import Button from 'design-system/Button';
import { rem } from 'design-system/values';
const strings = {
    title: 'No accounts yet',
    subtitle: 'Add unlimited offline accounts',
    buttonText: 'Add new',
};
const EmptyAccounts = ({ title, subtitle, buttonText, onPress }) => (<CardView style={styles.container}>
    <IconGlobe />
    <Spacer h={16}/>
    <Text Text-16>{title ?? strings.title}</Text>
    <Spacer h={4}/>
    <Text TextThin-14 Gray>
      {subtitle ?? strings.subtitle}
    </Text>
    <Spacer h={16}/>
    <Button gray extra small title={buttonText ?? strings.buttonText} onPress={onPress}/>
  </CardView>);
const styles = StyleSheet.create({
    container: {
        padding: rem(16),
        marginTop: 0,
        alignItems: 'center',
    },
});
export default EmptyAccounts;
