import React from 'react';
import { InteractionManager, Keyboard, View } from 'react-native';
import { rem, SettingsItem } from 'design-system';
import { IconArrowBack } from 'design-system/icons';
import IconPieChart from 'design-system/icons/IconPieChart';
import Modal from 'design-system/Modal';
import { useRefCallback } from 'hooks';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
export const useClearCategoryBudgetsModal = ({ onClearBudgetPress, onClearRollingPress, }) => {
    const styles = useStyles(styleSet);
    return useRefCallback(() => {
        Keyboard.dismiss();
        Modal.show(<View style={styles.content}>
        <SettingsItem title="Clear budget" icon={<IconPieChart />} onPress={() => {
                onClearBudgetPress();
                InteractionManager.runAfterInteractions(() => Modal.hide());
            }}/>
        <SettingsItem title="Clear rolling amount" icon={<IconArrowBack />} onPress={() => {
                onClearRollingPress();
                InteractionManager.runAfterInteractions(() => Modal.hide());
            }}/>
      </View>, {
            containerStyle: styles.container,
            title: 'Clear',
        });
    });
};
const styleSet = createStyleSheets(() => ({
    content: {
        paddingVertical: rem(20),
    },
    container: {
        paddingHorizontal: 0,
        overflow: 'hidden',
    },
}));
