import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, } from 'react';
import { InteractionManager, StyleSheet } from 'react-native';
import { createTransaction, editTransactions, getAccountGoals, getCategories, refreshApp, } from 'actions';
import Amplitude from 'utils/amplitude';
import Modal from 'design-system/Modal';
import { rem } from 'design-system/values';
import Indicator from 'design-system/Indicator';
import StatusBar from 'design-system/StatusBar';
import { AppView } from 'design-system/AppView';
import { HelpNavigationButton } from 'design-system/TextButton';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import useColors from 'hooks/useColors';
import { Tier } from 'features/premium/entities';
import { selectUserId } from 'reducers/selectors';
import { goBackWithKey } from 'utils/navigationv6';
import { benefits } from 'features/premium/benefits';
import useHelpActionSheet from 'hooks/useHelpActionSheet';
import useRefreshAccounts from 'hooks/useRefreshAccounts';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import useFetchOld, { muteErrorAndResult } from 'hooks/useFetch';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import { isWeb } from '../../../constants';
import useDeleteCategory from '../hooks/useDeleteCategory';
import EditCategoryList from '../components/categories/EditCategoryList';
import useCheckTransactionsModal from '../hooks/useCheckTransactionsModal';
import useRateAppAfterTransactionEdit from '../hooks/useRateAppAfterTransactionEdit';
import EditCategoryModalContent from '../components/categories/EditCategoryModalContent';
const strings = {
    perfectLetsGoBack: 'Perfect, lets go back',
};
const EditCategoryScreen = withAnimatedNavigationHeader(({ scrollHandler, navigation, route }) => {
    const colors = useColors();
    const { isPickingCategory } = route.params;
    // The user is still picking a category if transaction is defined, they just use a different flow
    const { transaction } = route.params;
    const showHelpActionSheet = useHelpActionSheet({
        showVisitHelpCentre: true,
    });
    useLayoutEffect(() => {
        navigation.setOptions({
            ...(!isWeb && {
                headerRight: () => (<HelpNavigationButton black onPress={showHelpActionSheet}/>),
            }),
            headerTintColor: colors.text.primary,
        });
    }, [
        colors.text.primary,
        isPickingCategory,
        route.params.subtitle,
        route.params.title,
        showHelpActionSheet,
        transaction,
    ]);
    const isFetching = useAppSelector((store) => store.user.isFetching && !store.user.categories?.length);
    const refreshAccounts = useRefreshAccounts();
    const [, , fetching, fetch] = useFetchOld(muteErrorAndResult);
    const fetchingRef = useRef(fetching);
    fetchingRef.current = fetching;
    const dispatch = useAppDispatch();
    const userId = useAppSelector(selectUserId);
    const { isUnlockedForSpace: isCustomCategoriesUnlockedForSpace, onPressUpgrade: onPressUpgradeForCustomCategories, } = useShouldShowUpgrade({ benefitId: 'customCategories' });
    const { isUnlockedForSpace: isAdvancedEditingUnlockedForSpace, onPressUpgrade: onPressUpgradeForAdvancedEditing, } = useShouldShowUpgrade({ benefitId: 'advancedTransactionsEditing' });
    const rateApp = useRateAppAfterTransactionEdit();
    const justThisTransactionCb = useCallback((transaction, data) => {
        Modal.hide(() => {
            rateApp();
        });
        navigation.goBack();
        fetch(editTransactions({
            [transaction.id]: transaction.category.id,
        }, data));
    }, [rateApp]);
    const checkSimilarTransactionsCb = useCallback((transaction, data) => {
        if (isWeb) {
            navigation.navigate('EditMultipleTransactions', {
                category: data,
                isSelectedAll: true,
                keyBack: route.key,
                transaction,
                filterOut: data,
            });
            return;
        }
        Modal.hide(() => {
            navigation.navigate('EditMultipleTransactions', {
                category: data,
                isSelectedAll: true,
                keyBack: route.key,
                transaction,
                filterOut: data,
            });
        });
    }, [route.key]);
    const handleTransactionCategoryEdit = useCheckTransactionsModal(justThisTransactionCb, checkSimilarTransactionsCb);
    const onPressAdd = useCallback(() => {
        navigation.navigate('CustomCategoryEdit');
        Amplitude.logEvent('EditCategory.CustomCategorySetup');
    }, []);
    const upgradeRequired = useCallback((tier) => {
        if (tier === Tier.free)
            return;
        navigation.navigate('EmmaProModal', {
            tier,
            isTierRequired: true,
        });
    }, []);
    const [, deleteCategory] = useDeleteCategory();
    const onLongPress = useCallback((data, isDefaultCategory) => {
        if (data && data.id === 'custom1')
            navigation.navigate('CustomCategoryEdit');
        else if (!isDefaultCategory) {
            const onEdit = () => {
                Modal.hide(() => {
                    if (!isCustomCategoriesUnlockedForSpace) {
                        upgradeRequired(benefits.customCategories.minTier);
                    }
                    else {
                        navigation.navigate('CustomCategoryEdit', {
                            category: data,
                        });
                    }
                });
            };
            Modal.show(<EditCategoryModalContent onPressDelete={() => {
                    deleteCategory(data.id, data.displayName);
                }} onPressEdit={onEdit}/>, {
                title: 'Edit',
                textStyle: styles.modalText,
                containerStyle: styles.modalContent,
            });
        }
    }, [deleteCategory, isCustomCategoriesUnlockedForSpace, upgradeRequired]);
    const pickCategoryForOfflineTransaction = useCallback((data) => {
        const bankAccount = route.params.data; // when isPickingCategory, data should always be defined
        const { amount, customName, keyBack } = route.params;
        if (bankAccount && amount !== undefined) {
            navigation.navigate('SelectDate', {
                buttonText: strings.perfectLetsGoBack,
                onSubmitDate: (bookingDate) => {
                    if (fetchingRef.current) {
                        return;
                    }
                    const body = {
                        amount,
                        categoryId: data.id,
                        accountId: bankAccount.id,
                        customName,
                        userId,
                        currency: bankAccount.nativeBalance
                            ? bankAccount.nativeBalance.currency
                            : bankAccount.currency,
                        bookingDate,
                        updateAccountBalance: true,
                    };
                    fetch(createTransaction(body)).then(() => {
                        fetch(getAccountGoals());
                        refreshAccounts(bankAccount.type);
                    });
                    navigation.navigate('Success', {
                        title: 'Done!',
                        subTitle: 'Your transaction has been created!',
                        onContinue: () => {
                            if (keyBack) {
                                goBackWithKey(navigation, keyBack);
                            }
                            else {
                                navigation.goBack();
                            }
                            return true;
                        },
                    });
                },
            });
        }
        else {
            log('When navigating to EditCategoryScreen with isPickingCategory, data, amount should be defined', false, 'red');
        }
    }, [refreshAccounts, route.params, userId]);
    const setCategoryOnMultipleTransactions = useCallback((data, isDefaultCategory) => {
        if (!isAdvancedEditingUnlockedForSpace) {
            onPressUpgradeForAdvancedEditing();
            return;
        }
        if (!isDefaultCategory && !isCustomCategoriesUnlockedForSpace) {
            onPressUpgradeForCustomCategories();
            return;
        }
        const { selected, onEditCallBack } = route.params;
        const editTxnObj = selected?.reduce((prev, curr) => ({
            ...prev,
            [curr.id]: curr.currentCategoryId,
        }), {});
        if (editTxnObj) {
            fetch(editTransactions(editTxnObj, data)).then((response) => {
                if (response) {
                    dispatch(refreshApp());
                    if (onEditCallBack) {
                        onEditCallBack(data);
                    }
                }
            });
        }
        navigation.goBack();
        rateApp();
    }, [
        isAdvancedEditingUnlockedForSpace,
        isCustomCategoriesUnlockedForSpace,
        onPressUpgradeForAdvancedEditing,
        onPressUpgradeForCustomCategories,
        route.params,
        rateApp,
    ]);
    const setCategoryOnSingleTransaction = useCallback((transaction, data, isDefaultCategory) => {
        if (data.id === transaction.category?.id)
            return;
        if (!isDefaultCategory && !isCustomCategoriesUnlockedForSpace) {
            onPressUpgradeForCustomCategories();
            return;
        }
        if (isWeb) {
            checkSimilarTransactionsCb(transaction, data);
            return;
        }
        handleTransactionCategoryEdit(transaction, data);
    }, [
        isCustomCategoriesUnlockedForSpace,
        handleTransactionCategoryEdit,
        onPressUpgradeForCustomCategories,
        checkSimilarTransactionsCb,
    ]);
    const onPressCategory = useCallback((data, isDefaultCategory) => {
        if (data && data.id === 'custom1') {
            onPressAdd();
            return;
        }
        if (isPickingCategory) {
            pickCategoryForOfflineTransaction(data);
            return;
        }
        const { multipleEditing } = route.params;
        if (multipleEditing) {
            setCategoryOnMultipleTransactions(data, isDefaultCategory);
            return;
        }
        if (transaction) {
            setCategoryOnSingleTransaction(transaction, data, isDefaultCategory);
            return;
        }
        // Not picking a category
        onLongPress(data, isDefaultCategory);
    }, [
        isPickingCategory,
        onLongPress,
        onPressAdd,
        pickCategoryForOfflineTransaction,
        route.params,
        setCategoryOnMultipleTransactions,
        setCategoryOnSingleTransaction,
        transaction,
    ]);
    useEffect(() => {
        InteractionManager.runAfterInteractions(() => {
            dispatch(getCategories());
        });
    }, []);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const contentContainerStyle = useMemo(() => [
        {
            paddingTop: rem(16),
            paddingBottom: 40,
        },
        paddingHorizontalStyle,
    ], [paddingHorizontalStyle]);
    const onPressManage = useCallback(() => {
        navigation.navigate('ManageCategories', { skipRefresh: true });
    }, []);
    return (<AppView style={styles.content} withFrame={false}>
          <StatusBar barStyle="dark-content" animated/>
          {isFetching ? (<Indicator dark/>) : (<EditCategoryList scrollHandler={scrollHandler} selectedCategory={route.params.category} contentContainerStyle={contentContainerStyle} onPressCategory={onPressCategory} onLongPressCategory={onLongPress} onPressAdd={onPressAdd} onPressManage={onPressManage}/>)}
        </AppView>);
}, ({ colors }) => ({
    title: 'Pick a category',
    headerHeight: rem(52),
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styles = StyleSheet.create({
    content: {
        justifyContent: 'center',
    },
    modalText: {
        paddingHorizontal: rem(48),
        marginBottom: rem(30),
    },
    modalContent: {
        paddingHorizontal: 0,
    },
});
export default EditCategoryScreen;
