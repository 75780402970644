import React, { useCallback } from 'react';
import { Keyboard, StyleSheet, View } from 'react-native';
import Modal from 'design-system/Modal';
import { rem, Separator, SettingsItem } from 'design-system';
import IconCheckBox from '../icons/IconCheckBox';
import IconCompare from '../icons/IconCompare';
const useCheckTransactionsModal = (justThisTransactionCb, checkSimilarTransactionsCb) => {
    const open = useCallback((...args) => {
        Modal.show(<View>
          <SettingsItem icon={<IconCheckBox />} title="Just this one" onPress={() => justThisTransactionCb(...args)}/>
          <Separator style={styles.separator}/>
          <SettingsItem icon={<IconCompare />} title="Check similar transactions" onPress={() => checkSimilarTransactionsCb(...args)}/>
        </View>, {
            title: 'Which transactions should we update?',
            containerStyle: styles.modalContent,
            textStyle: styles.modalText,
        });
        Keyboard.dismiss();
    }, [justThisTransactionCb, checkSimilarTransactionsCb]);
    return open;
};
const styles = StyleSheet.create({
    modalText: {
        paddingHorizontal: rem(48),
        marginBottom: rem(30),
    },
    modalContent: {
        paddingHorizontal: 0,
    },
    separator: {
        marginHorizontal: rem(16),
    },
});
export default useCheckTransactionsModal;
