export const POST_CONTACTS_REQUEST = 'POST_CONTACTS_REQUEST';
export const POST_CONTACTS_SUCCESS = 'POST_CONTACTS_SUCCESS';
export const POST_CONTACTS_FAILURE = 'POST_CONTACTS_FAILURE';
export const GET_CONTACTS_REQUEST = 'GET_CONTACTS_REQUEST';
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
export const GET_CONTACTS_FAILURE = 'GET_CONTACTS_FAILURE';
export const POST_INVITE_CONTACTS_REQUEST = 'POST_INVITE_CONTACTS_REQUEST';
export const POST_INVITE_CONTACTS_SUCCESS = 'POST_INVITE_CONTACTS_SUCCESS';
export const POST_INVITE_CONTACTS_FAILURE = 'POST_INVITE_CONTACTS_FAILURE';
export const SYNC_CONTACTS = 'SYNC_CONTACTS';
