import { useCallback, useRef } from 'react';
import { parseISO, format, add, sub, isFuture, startOfMonth, startOfWeek, startOfQuarter, startOfYear, } from 'date-fns';
import { getCategoryExpenses, getCategoryTotals, getMerchantExpenses, isFetchingCategoryExpenses, } from 'actions';
import { selectHasSetPaydayRange } from 'reducers/selectors';
import { useAppDispatch, useAppStore } from 'store/hooks';
import { useMainStackNavigation } from 'utils/types/navigationV6';
export const mapStepToDateFnsUnit = (step) => {
    if (!step || step === 'payperiod') {
        return 'months';
    }
    switch (step) {
        case 'isoWeek':
            return 'weeks';
        case 'custom':
            return 'days';
        case 'quarter':
            return 'quarters';
        case 'year':
            return 'years';
        case 'month':
        default:
            return 'months';
    }
};
export const mapStepToStartOf = (step) => {
    if (step === 'isoWeek')
        return startOfWeek;
    if (step === 'quarter')
        return startOfQuarter;
    if (step === 'year')
        return startOfYear;
    return startOfMonth;
};
export const useOpenCategory = (monthlyTotals, position, categoryRoute, step, accountIds) => {
    const navigation = useMainStackNavigation();
    const dispatch = useAppDispatch();
    const store = useAppStore();
    // TODO come up with a better way to optimize rerenders
    const monthlyTotalsRef = useRef(monthlyTotals);
    monthlyTotalsRef.current = monthlyTotals;
    const positionRef = useRef(position);
    positionRef.current = position;
    const stepRef = useRef(step);
    stepRef.current = step;
    const accountIdsRef = useRef(accountIds);
    accountIdsRef.current = accountIds;
    return useCallback((data) => {
        const { from, to } = monthlyTotalsRef.current[positionRef.current];
        let toDate = parseISO(to);
        let fromDate = parseISO(from);
        const unit = mapStepToDateFnsUnit(stepRef.current);
        const startOfUnit = mapStepToStartOf(stepRef.current);
        if (stepRef.current !== 'custom') {
            if (positionRef.current === 0) {
                fromDate = sub(startOfUnit(fromDate), { [unit]: 6 });
            }
            else {
                toDate = add(startOfUnit(toDate), { [unit]: 3 });
                fromDate = sub(startOfUnit(fromDate), { [unit]: 3 });
                if (isFuture(add(toDate, { [unit]: 3 }))) {
                    toDate = parseISO(monthlyTotalsRef.current[0].to);
                }
            }
        }
        const categoryId = data.type === 'category' ? data.data.id : undefined;
        const merchantId = data.type === 'merchant' ? data.data.id : undefined;
        dispatch(isFetchingCategoryExpenses());
        if (categoryRoute === 'Category') {
            dispatch(getCategoryTotals({
                dateFrom: format(fromDate, 'yyyy-MM-dd'),
                dateTo: format(toDate, 'yyyy-MM-dd'),
                categoryId,
                merchantId,
                isPayDay: selectHasSetPaydayRange(store.getState()),
                step: stepRef.current,
                reset: true,
                accountIds: accountIdsRef.current,
            }));
        }
        const fromFormatted = format(parseISO(from), 'yyyy-MM-dd');
        const toFormatted = format(parseISO(to), 'yyyy-MM-dd');
        if (merchantId) {
            dispatch(getMerchantExpenses(fromFormatted, toFormatted, merchantId, accountIdsRef.current));
        }
        else if (categoryId) {
            dispatch(getCategoryExpenses(fromFormatted, toFormatted, categoryId, accountIdsRef.current));
        }
        if (categoryRoute === 'Category') {
            const params = {
                ...(merchantId && {
                    merchantId,
                }),
                ...(categoryId && {
                    categoryId,
                }),
                from: format(parseISO(from), 'yyyy-MM-dd'),
                to: format(parseISO(to), 'yyyy-MM-dd'),
                step: stepRef.current,
                accountIds: accountIdsRef.current,
            };
            navigation.navigate({ name: categoryRoute, params });
        }
        else {
            const monthlyCategoryParams = {
                ...(merchantId && {
                    merchantId,
                }),
                ...(categoryId && {
                    categoryId,
                }),
                from: format(parseISO(from), 'yyyy-MM-dd'),
                to: format(parseISO(to), 'yyyy-MM-dd'),
                step: stepRef.current,
                isEditable: data.type === 'merchant'
                    ? positionRef.current === 0
                    : Boolean(data.data.budgetLimit),
            };
            const params = monthlyCategoryParams;
            navigation.navigate({ name: categoryRoute, params });
        }
    }, [categoryRoute, dispatch, store]);
};
