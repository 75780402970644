import React from 'react';
import { View } from 'react-native';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableBounceCard from 'utils/packages/TouchableBounceCard';
import Text from './Text';
import TextBadge from './TextBadge';
import IconArrow from './icons/IconArrow';
const CardWithBadge = ({ title, description, badgeText, preText, onPress, ...props }) => {
    const styles = useStyles(styleSet);
    return (<TouchableBounceCard onDark onPress={onPress} withPadding noMargin style={[styles.card, Boolean(badgeText) && styles.cardWithBorder]} {...props}>
      {badgeText && <TextBadge style={styles.badge}>{badgeText}</TextBadge>}
      <View style={styles.cardText}>
        {!!preText && (<Text Text-12 Primary style={styles.preText}>
            {preText}
          </Text>)}
        <Text Text-16>{title}</Text>
        <Text TextThin-14 Secondary>
          {description}
        </Text>
      </View>
      <IconArrow direction="right"/>
    </TouchableBounceCard>);
};
export default CardWithBadge;
const styleSet = createStyleSheets((colors) => ({
    card: {
        paddingVertical: rem(24),
        marginVertical: rem(8),
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardWithBorder: {
        borderWidth: 1,
        borderColor: colors.text.brand,
    },
    badge: {
        position: 'absolute',
        top: rem(-15),
        right: rem(16),
    },
    cardText: {
        maxWidth: rem(277),
    },
    preText: {
        marginBottom: rem(4),
    },
}));
