import React, { memo } from 'react';
import useColors from 'hooks/useColors';
import Squircle from './Squircle';
import { rem } from './values';
export const SettingsItemIcon = memo(({ light, iconSize = 40, style, children }) => {
    const colors = useColors();
    const remSize = rem(iconSize);
    return (<Squircle width={remSize} height={remSize} color={light ? colors.cards.onLight : colors.background.dark} style={style}>
      {children}
    </Squircle>);
});
