import React, { useId } from 'react';
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconTools = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} defaultGradientKey="apricot" {...props}>
      <G clipPath="url(#clip0_324_7031)">
        <Path fill={`url(#gradient_${id})`} d="M21.4087 15.0032C20.3799 13.9736 18.9524 13.5375 17.1191 14.0288L10.5817 7.48474L10.6681 7.15802C11.0446 5.74529 10.6378 4.22673 9.60666 3.19564C8.55682 2.14498 7.06432 1.75947 5.6528 2.1472C5.45151 2.20248 5.29483 2.36064 5.24151 2.56248C5.18819 2.76431 5.24666 2.97884 5.3942 3.12646L6.9683 4.70056C7.4285 5.16189 7.42401 5.90072 6.96944 6.3565C6.51846 6.80869 5.76041 6.80798 5.31123 6.35763L3.73682 4.7831C3.5892 4.63541 3.37405 4.57748 3.17221 4.63111C2.97033 4.6847 2.81276 4.84177 2.75768 5.04326C2.37561 6.43927 2.75147 7.94103 3.80635 8.99591C4.83471 10.025 6.26045 10.4628 8.09487 9.97037L14.6328 16.5144C14.1449 18.3472 14.5658 19.7609 15.6084 20.8035C16.6596 21.8555 18.1528 22.2381 19.5612 21.8517C19.7625 21.7965 19.9192 21.6383 19.9726 21.4364C20.026 21.2346 19.9675 21.02 19.8199 20.8724L18.2462 19.2986C17.786 18.8373 17.7905 18.0984 18.245 17.6427C18.7016 17.1884 19.458 17.1956 19.9032 17.6421L21.4778 19.2162C21.6254 19.3638 21.8405 19.4217 22.0423 19.3681C22.2442 19.3145 22.4017 19.1575 22.4568 18.956C22.8389 17.5603 22.4631 16.0581 21.4087 15.0032Z"/>
        <Path fill={`url(#gradient_${id})`} d="M8.87398 12.4131L3.28836 17.9995C2.37426 18.9136 2.37426 20.4001 3.28836 21.3141C4.20246 22.2282 5.68895 22.2282 6.60301 21.3141L12.1885 15.728L8.87398 12.4131ZM6.05254 19.4957C5.82363 19.7246 5.45293 19.7246 5.22402 19.4957C4.99516 19.2669 4.99516 18.8961 5.22402 18.6672L8.77793 15.1126C9.0068 14.8838 9.37762 14.8838 9.60645 15.1126C9.83531 15.3415 9.83531 15.7123 9.60645 15.9412L6.05254 19.4957Z"/>
        <Path fill={`url(#gradient_${id})`} d="M22.4305 3.18134L21.4204 2.17122C21.2302 1.9811 20.9352 1.94477 20.7047 2.08302L17.5973 3.9472C17.2704 4.14333 17.2149 4.59438 17.4844 4.86395L17.7318 5.11122L13.7872 9.03962L15.4448 10.6972L19.3894 6.76884L19.7378 7.11731C20.0073 7.38681 20.4584 7.33134 20.6545 7.00438L22.5187 3.89708C22.6569 3.66649 22.6206 3.37145 22.4305 3.18134Z"/>
      </G>
      <Defs>
        <ClipPath id="clip0_324_7031">
          <Rect width="20" height="20" fill={`url(#gradient_${id})`} transform="translate(2.60217 2)"/>
        </ClipPath>
      </Defs>
    </IconWithGradient>);
};
