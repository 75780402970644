import React from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
export const AppVersion = () => (<View style={styles.container}>
    <Text TextThin-14 Gray>
      Emma Beta
    </Text>
  </View>);
const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        padding: 16,
        marginVertical: 24,
    },
});
