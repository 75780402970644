import { useCallback } from 'react';
import Amplitude from 'utils/amplitude';
import useAppRating from 'hooks/useAppRating';
import { isWeb } from '../../../constants';
const useRateAppAfterTransactionEdit = () => {
    const rateApp = useAppRating();
    const open = useCallback(async () => {
        if (isWeb) {
            return;
        }
        const result = await rateApp();
        if (result) {
            Amplitude.logEvent('Transaction.askForReview');
        }
    }, [rateApp]);
    return open;
};
export default useRateAppAfterTransactionEdit;
