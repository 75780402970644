export const strcmp = (a, b) => {
    if (a < b)
        return -1;
    if (a > b)
        return 1;
    return 0;
};
export const stringSort = (key, a, b) => {
    if (a[key] > b[key]) {
        return -1;
    }
    if (b[key] > a[key]) {
        return 1;
    }
    return 0;
};
export const sortAccordingToTimestamp = (a, b) => stringSort('timestamp', a, b);
export const sortAccordingToDate = (a, b) => stringSort('date', a, b);
export const sortAccordingToDatetime = (a, b) => stringSort('datetime', a, b);
export const sortByName = (data) => {
    if (data && data.length) {
        return data.sort((a, b) => strcmp(a.displayName, b.displayName));
    }
    return [];
};
