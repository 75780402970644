import React from 'react';
import { rem } from 'design-system/values';
import Selector from 'design-system/Selector';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
const SECTIONS = ['Category', 'Merchant'];
export var ListMode;
(function (ListMode) {
    ListMode[ListMode["Categories"] = 0] = "Categories";
    ListMode[ListMode["Merchants"] = 1] = "Merchants";
})(ListMode || (ListMode = {}));
const AnalyticsSelector = ({ setSelection }) => {
    const styles = useStyles(styleSet);
    return (<Selector Text-14 style={styles.selector} selectorStyle={styles.selectorSelected} sections={SECTIONS} onSelected={setSelection}/>);
};
const styleSet = createStyleSheets((colors) => ({
    selector: {
        backgroundColor: colors.background.dark,
        marginHorizontal: rem(16),
        marginBottom: rem(16),
        width: null,
    },
    selectorSelected: {
        backgroundColor: colors.gradients.ultimate.colors[0],
    },
}));
export default AnalyticsSelector;
