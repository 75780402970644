import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Animated, Platform, TextInput as RNTextInput, StyleSheet, TouchableOpacity, View } from 'react-native';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import { AppView } from 'design-system';
import Button from 'design-system/Button';
import MoneyInputField from 'design-system/MoneyInputField';
import ShakeContainer from 'design-system/ShakeContainer';
import Spacer from 'design-system/Spacer';
import Text from 'design-system/Text';
import IconTransaction from 'design-system/icons/IconTransaction';
import StatusBar from 'design-system/StatusBar';
import { rem } from 'design-system/values';
import { IconSquareCircle } from 'design-system/icons';
import { DEFAULT_MAX_AMOUNT, formatAmountText, getInitialScale, getScale, } from 'utils/formatting';
import useColors from 'hooks/useColors';
import { useAutoFocusInput } from 'hooks/useAutoFocusInput';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import KeyboardAvoidingView from 'design-system/KeyboardAvoidingView';
const strings = {
    next: 'Next',
    title: 'What’s the amount for\xa0this\xa0transaction?',
    value: 'value',
};
export var Sign;
(function (Sign) {
    Sign["Positive"] = "";
    Sign["Negative"] = "-";
})(Sign || (Sign = {}));
const flipIncomeExpenseStrings = {
    [Sign.Positive]: 'Swap to expense',
    [Sign.Negative]: 'Swap to income',
};
const hitSlop = { top: 10, bottom: 10, left: 20, right: 20 };
const AddAmountScreen = ({ navigation, route }) => {
    const bankAccount = route.params.data;
    const colors = useColors();
    useLayoutEffect(() => {
        navigation.setOptions({
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary]);
    const [state, setState] = useState({
        amount: 0,
        fixedText: '',
        displayAmount: '0',
        maxLength: DEFAULT_MAX_AMOUNT.toString().length + 3, // 3 is for cents
    });
    const [sign, setSign] = useState(bankAccount.type !== 'LOAN' ? Sign.Negative : Sign.Positive);
    const [currency] = useState(bankAccount.nativeBalance ? bankAccount.nativeBalance.currency : bankAccount.currency);
    const shake = useRef();
    const textInputRef = useRef(null);
    const amountScale = useRef(new Animated.Value(getInitialScale(DEFAULT_MAX_AMOUNT, '0'))).current;
    useAutoFocusInput(textInputRef);
    const paddingBottomStyle = useMarginBottom('paddingBottom');
    const onFlipIncomeExpense = useCallback(() => {
        setSign((currentSign) => {
            if (currentSign === Sign.Negative) {
                return Sign.Positive;
            }
            return Sign.Negative;
        });
    }, []);
    const warnAboutWrongAmount = useCallback(() => {
        if (shake.current) {
            shake.current();
        }
        ReactNativeHapticFeedback.trigger('notificationError');
        textInputRef.current?.focus();
    }, []);
    const onChangeText = useCallback((text) => {
        const values = formatAmountText(text);
        if (values) {
            setState(values);
            Animated.timing(amountScale, {
                toValue: getScale(values.displayAmount),
                duration: 100,
                delay: 200,
                useNativeDriver: true,
            }).start();
        }
        else {
            warnAboutWrongAmount();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onSubmit = useCallback(() => {
        textInputRef.current?.blur();
        navigation.navigate('ChooseCounterpartName', {
            data: route.params.data,
            amount: state.amount * (sign === Sign.Negative ? -1 : 1),
            keyBack: route.key,
        });
    }, [route.params.data, route.key, state.amount, sign]);
    const controls = useMemo(() => (<View style={paddingBottomStyle}>
        <TouchableOpacity onPress={onFlipIncomeExpense} hitSlop={hitSlop} style={styles.centered}>
          <Text Text-16 OnBrand>
            {flipIncomeExpenseStrings[sign]}
          </Text>
        </TouchableOpacity>
        <Spacer h={16}/>
        <Button brand title={strings.next} onPress={onSubmit}/>
      </View>), [paddingBottomStyle, onFlipIncomeExpense, sign, onSubmit]);
    useAutoFocusInput(textInputRef);
    const { paddingHorizontal } = useAppFrameDimensions();
    return (<AppView type="primary" style={styles.container}>
      <StatusBar barStyle="dark-content" animated/>
      <IconSquareCircle color={colors.cards.onLight} width={56} height={56}>
        <IconTransaction scale={1.5} color={colors.text.brand}/>
      </IconSquareCircle>
      <Spacer h={16}/>
      <View style={styles.titleContainer}>
        <Text TextThin-20 Secondary centered>
          {strings.title}
        </Text>
      </View>
      <ShakeContainer style={styles.amountContainer} registerCallback={(callback) => {
            shake.current = callback;
        }} shakeOnMount={false}>
        <Animated.View style={[styles.amountScalableContainer, { transform: [{ scale: amountScale }] }]}>
          <MoneyInputField currency={currency} color={colors.text.primary}>{`${sign}${state.displayAmount}`}</MoneyInputField>
        </Animated.View>
        <RNTextInput ref={textInputRef} style={styles.transparentTextInput} value={state.fixedText} allowFontScaling={false} blurOnSubmit={false} // on iOS there is no way to submit, and on android if user submits 0 then we would have an issue
     caretHidden contextMenuHidden enablesReturnKeyAutomatically keyboardType="decimal-pad" maxLength={state.maxLength} onChangeText={onChangeText} onSubmitEditing={onSubmit}/>
      </ShakeContainer>
      {Platform.OS === 'android' ? (<View style={styles.buttonContainer}>{controls}</View>) : (<KeyboardAvoidingView style={[styles.buttonContainer, { marginHorizontal: paddingHorizontal }]} behavior="padding" pointerEvents="box-none">
          {controls}
        </KeyboardAvoidingView>)}
    </AppView>);
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        paddingTop: rem(100),
    },
    titleContainer: {
        marginHorizontal: rem(16),
    },
    amountContainer: {
        marginTop: rem(40),
        width: '100%',
        height: rem(120),
        alignItems: 'center',
        justifyContent: 'center',
    },
    amountScalableContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: -1000,
    },
    transparentTextInput: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        color: 'transparent',
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        justifyContent: 'flex-end',
        paddingHorizontal: rem(16),
    },
    centered: {
        alignSelf: 'center',
    },
});
export default AddAmountScreen;
