import React from 'react';
import { View } from 'react-native';
import { rem } from 'design-system/values';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
export const AccentContainer = ({ style, ...props }) => {
    const styles = useStyles(styleSet);
    return <View style={[styles.container, style]} {...props}/>;
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        borderRadius: rem(16),
        backgroundColor: colors.background.dark,
    },
}));
