import React, { useLayoutEffect, useMemo, useState } from 'react';
import { isFuture, parseISO } from 'date-fns';
import emoji from 'node-emoji';
import { View, StyleSheet } from 'react-native';
import Text from 'design-system/Text';
import Button from 'design-system/Button';
import StatusBar from 'design-system/StatusBar';
import { AppView } from 'design-system/AppView';
import DateInput, { toDate } from 'design-system/date/DateInput';
import KeyboardAvoidingContainer from 'design-system/KeyboardAvoidingContainer';
import useColors from 'hooks/useColors';
import Alert from 'utils/packages/Alert';
import { isWeb } from '../../../constants';
const CorrectedDateScreen = ({ navigation, route, }) => {
    const colors = useColors();
    const { subscription } = route.params;
    useLayoutEffect(() => {
        navigation.setOptions({
            headerTitle: '',
            title: 'Adjust Date',
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary]);
    const prediction = useMemo(() => subscription.predictions && subscription.predictions[0]
        ? parseISO(subscription.predictions[0].date)
        : null, [subscription.predictions]);
    const [dateState, setDateState] = useState(toDate(prediction));
    const openFixedPrediction = () => {
        const { day, month, year } = dateState || {};
        if (!day ||
            !month ||
            !year ||
            Number(month) > 12 ||
            Number(day) > 31 ||
            (year && year.length < 4)) {
            Alert.alert(emoji.emojify('Come on :face_with_rolling_eyes:'), 'This is clearly not a valid date.');
            return;
        }
        const date = new Date(Number(year), Number(month) - 1, Number(day));
        if (!isFuture(date)) {
            Alert.alert(emoji.emojify('Past date :crystal_ball:'), 'We are predicting the future here.');
            return;
        }
        navigation.navigate('PredictionFixed', {
            subscription,
            correctedAmount: route.params.correctedAmount,
            correctedDate: date,
            keyBack: route.params.keyBack,
        });
    };
    return (<AppView isInTab={isWeb} avoidHeader>
      <StatusBar barStyle="dark-content" animated/>
      <KeyboardAvoidingContainer>
        <Text Text-24>
          What&apos;s the expected date for{' '}
          {subscription.merchantInfo?.displayName}?
        </Text>
        <View style={styles.innerContent}>
          <DateInput state={dateState} onChange={setDateState} onDark/>
        </View>
        <Button brand onPress={openFixedPrediction} title="Next"/>
      </KeyboardAvoidingContainer>
    </AppView>);
};
const styles = StyleSheet.create({
    innerContent: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
export default CorrectedDateScreen;
