import React, { useId } from 'react';
import { G, Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconPlane = ({ ...props }) => {
    const id = useId();
    return (<IconWithGradient id={id} defaultGradientKey="peach" {...props}>
      <G clipPath={`url(#gradient_${id})`}>
        <Path d="M23.89 8.646a1.81 1.81 0 0 0-.746-.917 5.666 5.666 0 0 0-4.941-.506l-2.71.986-5.51-2.9a.704.704 0 0 0-.57-.039l-2.143.78a.704.704 0 0 0-.203 1.209l3.42 2.772-5.734 2.087L2.9 10.483a.704.704 0 0 0-.706-.134l-1.731.63a.704.704 0 0 0-.42.902l1.081 2.974a3.758 3.758 0 0 0 4.811 2.243l5.356-1.949-.71 3.79a.704.704 0 0 0 .932.79l2.144-.78a.704.704 0 0 0 .412-.397l2.102-5.18 6.643-2.417a1.804 1.804 0 0 0 1.077-2.31ZM9.145 13.474l-.66.24a.703.703 0 1 1-.481-1.32l.66-.241a.703.703 0 0 1 .481 1.321Zm3.304-1.202-.66.24a.703.703 0 0 1-.482-1.321l.661-.24a.703.703 0 1 1 .481 1.321Zm3.304-1.202-.661.24a.703.703 0 1 1-.481-1.321l.66-.24a.703.703 0 0 1 .482 1.32Zm3.303-1.203-.66.24a.703.703 0 1 1-.481-1.32l.66-.241a.703.703 0 0 1 .481 1.321Z" fill={`url(#gradient_${id})`}/>
      </G>
    </IconWithGradient>);
};
