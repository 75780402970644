let lastLogTime = null;
global.log = (value, stringify = true, color = 'purple') => {
    if (__DEV__) {
        const code = {
            red: 31,
            green: 32,
            yellow: 33,
            blue: 34,
            purple: 35,
            cyan: 36,
        };
        const time = new Date().getTime();
        const timeString = lastLogTime === null ? '\t' : `+${time - lastLogTime}ms\t`;
        lastLogTime = time;
        if (stringify && __DEV__) {
            // eslint-disable-next-line no-console
            console.log(`${timeString}\x1b[${code[color]}m${JSON.stringify(value, null, '  ')}\x1b[0m`);
        }
        else if (__DEV__) {
            // eslint-disable-next-line no-console
            console.log(`${timeString}\x1b[${code[color]}m${value}\x1b[0m`);
        }
    }
};
export default global.log;
