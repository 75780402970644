import React from 'react';
const IconSector = ({ style, color, category, width = 24, height = 24, }) => {
    const props = {
        width,
        height,
        style,
        color,
    };
    const { Icon } = category;
    return <Icon {...props}/>;
};
export default IconSector;
