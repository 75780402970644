import React, { memo, useMemo } from 'react';
import { View } from 'react-native';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { isWeb } from '../../../../constants';
import RowTransaction from '../RowTransaction';
const EditMultipleListRow = ({ item, selected, onSelected, isLastItem, isFirstItem, }) => {
    const styles = useStyles(styleSet);
    const borderStyles = useMemo(() => [isLastItem && styles.lastItem, isFirstItem && styles.firstItem], [styles.firstItem, styles.lastItem, isLastItem, isFirstItem]);
    return (<View style={[styles.wrapper, borderStyles]}>
      <RowTransaction showDate item={item} backgroundDark selected={selected} onPress={onSelected} isEditingTransactions btnStyles={borderStyles}/>
    </View>);
};
export default memo(EditMultipleListRow);
const styleSet = createStyleSheets((colors) => ({
    wrapper: {
        marginHorizontal: rem(16),
        backgroundColor: colors.cards.onDark,
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
    firstItem: {
        borderTopLeftRadius: rem(16),
        borderTopRightRadius: rem(16),
        ...(isWeb && {
            paddingTop: rem(8),
        }),
    },
    lastItem: {
        borderBottomLeftRadius: rem(16),
        borderBottomRightRadius: rem(16),
        ...(isWeb && {
            paddingBottom: rem(8),
        }),
    },
}));
