export const GET_FEED_REQUEST = 'GET_FEED_REQUEST';
export const GET_FEED_SUCCESS = 'GET_FEED_SUCCESS';
export const GET_FEED_FAILURE = 'GET_FEED_FAILURE';
export const SET_TAB_BAR_HEIGHT_FOR_WALKTHROUGH = 'SET_TAB_BAR_HEIGHT_FOR_WALKTHROUGH';
export const SET_WALKTHROUGH_STATUS = 'SET_WALKTHROUGH_STATUS';
export const SET_WALKTHROUGH_STEP = 'SET_WALKTHROUGH_STEP';
export const SET_ADD_BANK = 'SET_ADD_BANK';
export const GET_ADS_REQUEST = 'GET_ADS_REQUEST';
export const GET_ADS_SUCCESS = 'GET_ADS_SUCCESS';
export const GET_ADS_FAILURE = 'GET_ADS_FAILURE';
export const MARK_AD_AS_SEEN_REQUEST = 'MARK_AD_AS_SEEN_REQUEST';
export const MARK_AD_AS_SEEN_SUCCESS = 'MARK_AD_AS_SEEN_SUCCESS';
export const MARK_AD_AS_SEEN_FAILURE = 'MARK_AD_AS_SEEN_FAILURE';
