import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import useColors from 'hooks/useColors';
import { IconWithGradient } from './Icon';
export const IconCheckMarkSquircle = ({ width = 16, height = 16, ...props }) => {
    const colors = useColors();
    const id = useId();
    return (<IconWithGradient id={id} width={width} height={height} {...props} viewBox="0 0 16 16" defaultGradientKey="ultimate">
      <Path d="M14.4399 9.69472C14.1298 12.1757 12.1756 14.1298 9.69468 14.4399V14.4399C8.56924 14.5806 7.43067 14.5806 6.30523 14.4399V14.4399C3.82428 14.1298 1.87013 12.1757 1.56001 9.69472V9.69472C1.41933 8.56929 1.41933 7.43071 1.56001 6.30528V6.30528C1.87013 3.82433 3.82428 1.87018 6.30523 1.56006V1.56006C7.43067 1.41938 8.56924 1.41938 9.69468 1.56006V1.56006C12.1756 1.87018 14.1298 3.82433 14.4399 6.30528V6.30528C14.5806 7.43072 14.5806 8.56929 14.4399 9.69472V9.69472Z" fill={`url(#gradient_${id})`}/>
      <Path fillRule="evenodd" clipRule="evenodd" d="M11.3737 6.07101C11.7167 6.41515 11.7247 6.98131 11.3915 7.33556L8.46559 10.4465C7.88554 11.0633 6.92686 11.0633 6.34681 10.4465L4.60831 8.59806C4.27512 8.2438 4.28307 7.67764 4.62605 7.33351C4.96903 6.98937 5.51717 6.99758 5.85035 7.35183L7.4062 9.0061L10.1494 6.08934C10.4826 5.73508 11.0307 5.72688 11.3737 6.07101Z" fill={colors.elements.onLight}/>
    </IconWithGradient>);
};
export default IconCheckMarkSquircle;
