import React, { memo, useMemo } from 'react';
import { View } from 'react-native';
import { rem } from 'design-system/values';
import SectionHeader from 'design-system/SectionHeader';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import RowTransaction from 'features/transactions/components/RowTransaction';
import { isWeb } from '../../../constants';
export const ROW_ITEM_HEIGHT = rem(98);
const SearchTransactionRow = ({ item, openItem, selected, isEditingTransactions, }) => {
    const styles = useStyles(styleSet);
    const borderStyles = useMemo(() => [
        item.isFirstInSection && styles.firstItem,
        item.isLastInSection && styles.lastItem,
    ], [
        item.isFirstInSection,
        styles.firstItem,
        styles.lastItem,
        item.isLastInSection,
    ]);
    if ('sectionTitle' in item) {
        return (<SectionHeader horizontalMargins largeVerticalMargins>
        {item.sectionTitle}
      </SectionHeader>);
    }
    return (<View style={[styles.item, borderStyles]}>
      <RowTransaction item={item} backgroundDark showUpgradeButton onPress={openItem} btnStyles={borderStyles} blurValue={item.blurValue} isEditingTransactions={isEditingTransactions} selected={selected}/>
    </View>);
};
export default memo(SearchTransactionRow);
const styleSet = createStyleSheets((colors) => ({
    firstItem: {
        borderTopLeftRadius: rem(16),
        borderTopRightRadius: rem(16),
    },
    lastItem: {
        borderBottomLeftRadius: rem(16),
        borderBottomRightRadius: rem(16),
    },
    item: {
        marginHorizontal: rem(16),
        backgroundColor: colors.cards.onDark,
        ...(isWeb && {
            padding: 8,
        }),
    },
}));
