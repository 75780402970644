import _ from 'lodash';
import moment from 'moment';
import { orderCommitted } from './getToBePaid';
const getCommittedTransactions = (data) => {
    let arrayToBePaid = [];
    let arrayPaid = [];
    let totalToBePaid = 0;
    let totalPaid = 0;
    let object = {};
    if (data) {
        const modifiedArrayToBePaid = [];
        const modifiedArrayPaid = [];
        for (let index = 0; index < data.length; index++) {
            for (let x = 0; x < data[index].predictions.length; x++) {
                object = { ...data[index] };
                object.prediction = data[index].predictions[x];
                if (!object.isInternal)
                    totalToBePaid += object.nativePrice.amount;
                modifiedArrayToBePaid.push(object);
            }
            for (let x = 0; x < data[index].transactions.length; x++) {
                object = { ...data[index] };
                object.prediction = {};
                // Really we should use the actual amount here (since it happened in the past)
                // But this requires a change in UI
                // object.currency = data[index].transactions[x].currency;
                // object.nativePrice = {
                //   ...object.nativePrice,
                //   amount: Math.abs(data[index].transactions[x].amount),
                // };
                object.prediction.date = data[index].transactions[x].bookingDate;
                if (!object.isInternal)
                    totalPaid += object.nativePrice.amount;
                modifiedArrayPaid.push(object);
            }
        }
        const orderedToBePaid = orderCommitted(modifiedArrayToBePaid);
        const groupedToBePaid = _.groupBy(orderedToBePaid, (d) => moment(d.prediction?.date).startOf('day').format());
        arrayToBePaid = _.reduce(groupedToBePaid, (acc, next, index) => {
            acc.push({
                key: index,
                data: next,
            });
            return acc;
        }, []);
        const oderedPaid = orderCommitted(modifiedArrayPaid);
        const groupedPaid = _.groupBy(oderedPaid, (d) => moment(d.prediction?.date).startOf('day').format());
        arrayPaid = _.reduce(groupedPaid, (acc, next, index) => {
            acc.push({
                key: index,
                data: next,
            });
            return acc;
        }, []);
    }
    return { arrayToBePaid, arrayPaid, totalPaid, totalToBePaid };
};
export default getCommittedTransactions;
