import React, { useState } from 'react';
import { Pressable } from 'react-native';
import Squircle from 'design-system/Squircle';
import { rem } from 'design-system/values';
const ToolbarButton = ({ size, active, bgColor, inactiveBgColor, icon, onPress, hitSlop, }) => {
    const [isHovered, setIsHovered] = useState(false);
    return (<Pressable onHoverIn={() => setIsHovered(true)} onHoverOut={() => setIsHovered(false)} hitSlop={hitSlop} onPress={onPress}>
      <Squircle width={rem(size)} height={rem(size)} color={isHovered || active ? bgColor : inactiveBgColor}>
        {React.cloneElement(icon, {
            gradientKey: isHovered || active ? 'white' : undefined,
        })}
      </Squircle>
    </Pressable>);
};
export default ToolbarButton;
