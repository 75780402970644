import React from 'react';
import { Path, Svg, Rect, Circle } from 'react-native-svg';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
const IconEmptyPlaceholder = ({ scale = 1, alt, happy = false, ...props }) => {
    const colors = useColors();
    const { onLight } = colors.cards;
    const { secondaryLight } = colors.elements;
    if (alt) {
        const altWidth = rem(159) * scale;
        const altHeight = rem(172) * scale;
        return (<Svg width={altWidth} height={altHeight} viewBox="0 0 120 120" fill="none">
        <Path d="M83.6778 50.034C83.6778 70.6277 66.9834 87.3221 46.3897 87.3221C25.796 87.3221 9.10156 70.6277 9.10156 50.034C9.10156 29.4403 25.796 12.7458 46.3897 12.7458C66.9834 12.7458 83.6778 29.4403 83.6778 50.034Z" fill={onLight}/>
        <Path d="M59.7848 64.0721C62.0449 55.6376 70.6417 50.6127 78.9864 52.8486C82.9937 53.9224 86.4057 56.5611 88.4719 60.1845C90.538 63.8078 91.089 68.1188 90.0037 72.1692C87.7437 80.6037 79.1469 85.6286 70.8022 83.3926C62.4574 81.1567 57.5248 72.5066 59.7848 64.0721Z" fill={onLight}/>
        <Path d="M86.4577 84.4046L89.9844 89.2428L90.0625 89.2637C90.7264 90.4279 90.3266 91.9198 89.1696 92.5961C88.0126 93.2723 86.5366 92.8768 85.8727 91.7127L83.2851 86.4321C83.0274 85.9819 82.9584 85.4457 83.0934 84.9419C83.2283 84.4381 83.5562 84.0082 84.0045 83.7472C84.8666 83.2534 85.958 83.5459 86.4577 84.4046Z" fill={onLight}/>
        <Path fillRule="evenodd" clipRule="evenodd" d="M89.3073 90.0116L85.6192 84.952L85.5934 84.9075C85.3707 84.5249 84.8893 84.395 84.5047 84.6132C84.2873 84.7408 84.1261 84.9515 84.0593 85.2007C83.9922 85.4511 84.0272 85.7155 84.153 85.9352L84.169 85.9632L86.7551 91.2408C87.1495 91.9033 87.9963 92.1236 88.665 91.7327C89.2675 91.3806 89.5234 90.6508 89.3073 90.0116ZM90.0625 89.2637C90.7264 90.4279 90.3266 91.9198 89.1696 92.5961C88.0126 93.2723 86.5366 92.8768 85.8727 91.7127L83.2851 86.4321C83.0274 85.9819 82.9584 85.4457 83.0934 84.9419C83.2283 84.4381 83.5562 84.0082 84.0045 83.7472C84.8666 83.2534 85.958 83.5459 86.4577 84.4046L89.9844 89.2428L90.0625 89.2637ZM78.7276 53.8145C70.9261 51.7241 62.8705 56.4201 60.7508 64.3309C58.6311 72.2417 63.2594 80.3363 71.061 82.4267C78.8625 84.5171 86.9181 79.8212 89.0378 71.9104C90.0554 68.1128 89.5381 64.073 87.6032 60.6798C85.6685 57.2871 82.4753 54.8187 78.7276 53.8145ZM78.9864 52.8486C70.6417 50.6127 62.0449 55.6376 59.7848 64.0721C57.5248 72.5066 62.4574 81.1567 70.8022 83.3926C79.1469 85.6286 87.7437 80.6037 90.0037 72.1692C91.089 68.1188 90.538 63.8078 88.4719 60.1845C86.4057 56.5611 82.9937 53.9224 78.9864 52.8486Z" fill={secondaryLight}/>
        <Path d="M80.6342 51.1192C77.2631 38.5381 64.4398 31.0428 51.9927 34.378C46.0153 35.9797 40.9259 39.9157 37.844 45.3203C34.7621 50.725 33.9401 57.1554 35.559 63.1971C38.9301 75.7781 51.7533 83.2734 64.2005 79.9382C76.6477 76.603 84.0053 63.7003 80.6342 51.1192Z" fill={onLight}/>
        <Path d="M40.8499 81.4489L35.5893 88.6656L35.4728 88.6969C34.4826 90.4333 35.0789 92.6588 36.8047 93.6675C38.5305 94.6762 40.7322 94.0863 41.7224 92.3498L45.5821 84.4732C45.9666 83.8016 46.0695 83.0018 45.8682 82.2504C45.6668 81.4989 45.1777 80.8577 44.509 80.4684C43.2232 79.7318 41.5952 80.168 40.8499 81.4489Z" fill={onLight}/>
        <Path fillRule="evenodd" clipRule="evenodd" d="M41.7224 92.3498C40.7322 94.0863 38.5305 94.6762 36.8047 93.6675C35.0789 92.6588 34.4826 90.4333 35.4728 88.6969L35.5893 88.6656L40.8499 81.4489C41.5952 80.168 43.2232 79.7318 44.509 80.4684C45.1777 80.8577 45.6668 81.4989 45.8682 82.2504C46.0695 83.0018 45.9666 83.8016 45.5821 84.4732L41.7224 92.3498ZM46.467 84.94L42.6204 92.7898C42.6112 92.8086 42.6014 92.827 42.5911 92.8452C41.3252 95.065 38.5062 95.8204 36.3 94.5308C34.1011 93.2455 33.3424 90.4141 34.6041 88.2015C34.6884 88.0536 34.8083 87.9311 34.9509 87.8439L40.0138 80.8982C41.048 79.1793 43.2593 78.6001 45.0061 79.6007L45.0122 79.6042C45.9104 80.1271 46.5648 80.9867 46.8341 81.9915C47.1004 82.9853 46.9693 84.0443 46.467 84.94ZM51.7339 33.4121C64.7242 29.9314 78.0887 37.7556 81.6001 50.8604C85.1115 63.9652 77.4497 77.4234 64.4593 80.9041C51.469 84.3849 38.1045 76.5607 34.5931 63.4559C32.9064 57.1614 33.7621 50.4597 36.9753 44.825C40.1887 39.1898 45.497 35.0833 51.7339 33.4121ZM51.9927 34.378C64.4398 31.0428 77.2631 38.5381 80.6342 51.1192C84.0053 63.7003 76.6477 76.603 64.2005 79.9382C51.7533 83.2734 38.9301 75.7781 35.559 63.1971C33.9401 57.1554 34.7621 50.725 37.844 45.3203C40.9259 39.9157 46.0153 35.9797 51.9927 34.378Z" fill={secondaryLight}/>
        <Path d="M54.5767 54.7467C54.6674 55.3279 54.6427 55.9213 54.504 56.493C54.3654 57.0647 54.1154 57.6035 53.7686 58.0786C53.4217 58.5537 52.9846 58.9558 52.4823 59.262C51.98 59.5682 51.4223 59.7725 50.8411 59.8631C50.2598 59.9538 49.6664 59.9291 49.0947 59.7904C48.523 59.6518 47.9843 59.4019 47.5091 59.055C47.034 58.7081 46.6319 58.271 46.3257 57.7687C46.0195 57.2664 45.8153 56.7087 45.7246 56.1275L46.7004 55.9753C46.771 56.4284 46.9303 56.8631 47.169 57.2547C47.4077 57.6462 47.7211 57.987 48.0915 58.2574C48.4619 58.5278 48.8819 58.7226 49.3275 58.8307C49.7732 58.9388 50.2358 58.958 50.6889 58.8874C51.142 58.8167 51.5767 58.6575 51.9683 58.4188C52.3598 58.1801 52.7005 57.8666 52.971 57.4962C53.2414 57.1259 53.4362 56.7059 53.5443 56.2602C53.6524 55.8146 53.6716 55.352 53.601 54.8989L54.5767 54.7467Z" fill={secondaryLight}/>
        {happy ? (<Path d="M54.9428 64.2644C55.0665 64.8395 55.3024 65.3846 55.6368 65.8686C55.9713 66.3525 56.3977 66.7659 56.8919 67.085C57.3861 67.4041 57.9383 67.6228 58.517 67.7285C59.0957 67.8343 59.6895 67.825 60.2646 67.7012C60.8397 67.5774 61.3848 67.3416 61.8688 67.0071C62.3527 66.6727 62.7661 66.2462 63.0852 65.752C63.4043 65.2578 63.623 64.7056 63.7287 64.1269C63.8344 63.5482 63.8251 62.9544 63.7014 62.3793L62.7359 62.5871C62.8324 63.0354 62.8396 63.4983 62.7572 63.9495C62.6748 64.4006 62.5043 64.831 62.2556 65.2163C62.0068 65.6015 61.6846 65.934 61.3073 66.1947C60.9301 66.4554 60.5051 66.6392 60.0568 66.7357C59.6085 66.8322 59.1456 66.8395 58.6945 66.757C58.2434 66.6746 57.8129 66.5042 57.4277 66.2554C57.0424 66.0066 56.71 65.6844 56.4493 65.3071C56.1886 64.9299 56.0047 64.505 55.9082 64.0566L54.9428 64.2644Z" fill={secondaryLight}/>) : (<Path d="M63.8443 65.3066C63.7537 64.7254 63.5494 64.1677 63.2432 63.6654C62.937 63.1631 62.5349 62.726 62.0598 62.3792C61.5847 62.0323 61.0459 61.7824 60.4742 61.6437C59.9025 61.505 59.3091 61.4803 58.7279 61.571C58.1466 61.6616 57.5889 61.8659 57.0866 62.1721C56.5843 62.4783 56.1473 62.8804 55.8004 63.3555C55.4535 63.8306 55.2036 64.3694 55.0649 64.9411C54.9262 65.5128 54.9015 66.1062 54.9922 66.6875L55.968 66.5352C55.8973 66.0821 55.9166 65.6196 56.0247 65.1739C56.1328 64.7283 56.3276 64.3083 56.598 63.9379C56.8684 63.5675 57.2091 63.254 57.6007 63.0154C57.9922 62.7767 58.427 62.6174 58.8801 62.5468C59.3332 62.4761 59.7958 62.4953 60.2414 62.6034C60.6871 62.7115 61.1071 62.9063 61.4774 63.1768C61.8478 63.4472 62.1613 63.7879 62.4 64.1794C62.6387 64.571 62.7979 65.0057 62.8686 65.4588L63.8443 65.3066Z" fill={secondaryLight}/>)}
        <Path d="M69.4556 52.4261C69.5463 53.0073 69.5216 53.6007 69.3829 54.1724C69.2443 54.7441 68.9943 55.2829 68.6475 55.758C68.3006 56.2331 67.8635 56.6353 67.3612 56.9415C66.8589 57.2477 66.3012 57.4519 65.72 57.5426C65.1387 57.6333 64.5453 57.6085 63.9736 57.4699C63.4019 57.3312 62.8632 57.0813 62.3881 56.7344C61.9129 56.3875 61.5108 55.9505 61.2046 55.4482C60.8984 54.9459 60.6942 54.3882 60.6035 53.8069L61.5793 53.6547C61.6499 54.1078 61.8092 54.5426 62.0479 54.9341C62.2866 55.3257 62.6 55.6664 62.9704 55.9368C63.3408 56.2072 63.7608 56.402 64.2064 56.5101C64.6521 56.6182 65.1147 56.6375 65.5678 56.5668C66.0209 56.4961 66.4556 56.3369 66.8472 56.0982C67.2387 55.8595 67.5794 55.546 67.8499 55.1757C68.1203 54.8053 68.3151 54.3853 68.4232 53.9397C68.5313 53.494 68.5505 53.0314 68.4799 52.5783L69.4556 52.4261Z" fill={secondaryLight}/>
        <Path d="M38.4418 31.4836C38.4418 32.097 37.9446 32.5942 37.3313 32.5942C36.7179 32.5942 36.2207 32.097 36.2207 31.4836C36.2207 30.8703 36.7179 30.373 37.3313 30.373C37.9446 30.373 38.4418 30.8703 38.4418 31.4836Z" fill={secondaryLight}/>
        <Path d="M22.9956 34.6355L24.0653 33.5657L24.8388 34.3392L23.769 35.409L24.978 36.618L24.1631 37.4329L22.9541 36.2239L21.8813 37.2966L21.1079 36.5232L22.1806 35.4504L21.022 34.2918L21.8369 33.4768L22.9956 34.6355Z" fill={secondaryLight}/>
        <Path d="M84.2945 31.155C84.8513 31.0816 85.3622 31.4736 85.4355 32.0305C85.5088 32.5873 85.1168 33.0981 84.56 33.1715L79.8548 33.7909C79.298 33.8642 78.7871 33.4722 78.7138 32.9154C78.6405 32.3585 79.0325 31.8477 79.5893 31.7744L84.2945 31.155Z" fill={secondaryLight}/>
        <Path d="M66.8613 20.7993C66.7879 20.2424 67.1799 19.7316 67.7368 19.6583C68.2936 19.585 68.8044 19.9769 68.8778 20.5338L69.4972 25.239C69.5705 25.7958 69.1785 26.3066 68.6217 26.3799C68.0648 26.4532 67.554 26.0613 67.4807 25.5044L66.8613 20.7993Z" fill={secondaryLight}/>
        <Path d="M78.0274 22.4479C78.3693 22.0023 79.0077 21.9183 79.4533 22.2602C79.8989 22.6021 79.9829 23.2405 79.641 23.6861L76.752 27.4511C76.4101 27.8967 75.7717 27.9808 75.3261 27.6389C74.8805 27.297 74.7965 26.6586 75.1384 26.213L78.0274 22.4479Z" fill={secondaryLight}/>
      </Svg>);
    }
    const width = rem(144) * scale;
    const height = rem(185) * scale;
    return (<Svg width={width} height={height} viewBox="0 0 144 185" fill="none" {...props}>
      <Circle cx="72" cy="75.4585" r="71.5" fill={onLight}/>
      <Rect x="43.9505" y="74.0012" width="70" height="98" rx="7" transform="rotate(2.5 43.9505 74.0012)" fill={onLight} stroke={secondaryLight} strokeWidth="2"/>
      <Rect x="4.57986" y="69.861" width="70" height="98" rx="7" transform="rotate(-7.5 4.57986 69.861)" fill={onLight} stroke={secondaryLight} strokeWidth="2"/>
      <Rect x="90.3662" y="62.6015" width="3" height="10" rx="1.5" transform="rotate(82.5 90.3662 62.6015)" fill={secondaryLight}/>
      <Rect x="62.9696" y="46.0358" width="3" height="10" rx="1.5" transform="rotate(-7.5 62.9696 46.0358)" fill={secondaryLight}/>
      <Rect x="80.5472" y="48.7648" width="3" height="10" rx="1.5" transform="rotate(37.5 80.5472 48.7648)" fill={secondaryLight}/>
      <Circle cx="63.5127" cy="8.35171" r="3.64859" fill={secondaryLight}/>
      <Circle cx="110.15" cy="9.98965" r="1.63809" fill={secondaryLight}/>
      <Circle cx="25.5076" cy="2.57385" r="2.12951" fill={secondaryLight}/>
      <Path d="M96.5816 23.0833L98.5416 21.1232L99.9588 22.5403L97.9987 24.5004L100.214 26.7156L98.7208 28.2088L96.5056 25.9935L94.5401 27.959L93.123 26.5419L95.0884 24.5764L92.9655 22.4534L94.4586 20.9603L96.5816 23.0833Z" fill={secondaryLight}/>
      <Path d="M38.9774 12.6953L40.5553 11.1173L41.6961 12.2582L40.1182 13.8361L41.9015 15.6194L40.6995 16.8214L38.9162 15.0381L37.3339 16.6204L36.1931 15.4796L37.7753 13.8973L36.0663 12.1882L37.2683 10.9862L38.9774 12.6953Z" fill={secondaryLight}/>
      <Path d="M17.8635 39.4954L18.8691 38.4899L19.5961 39.2169L18.5905 40.2224L19.727 41.3589L18.961 42.1249L17.8245 40.9885L16.8161 41.9968L16.0891 41.2698L17.0975 40.2614L16.0084 39.1723L16.7744 38.4063L17.8635 39.4954Z" fill={secondaryLight}/>
      <Path d="M125.319 29.6029L126.325 28.5973L127.052 29.3243L126.046 30.3299L127.183 31.4664L126.417 32.2324L125.28 31.0959L124.272 32.1042L123.545 31.3772L124.553 30.3689L123.464 29.2797L124.23 28.5137L125.319 29.6029Z" fill={secondaryLight}/>
      <Path d="M40.1054 100.603C40.2391 101.46 40.2027 102.336 39.9982 103.179C39.7936 104.022 39.425 104.817 38.9134 105.518C38.4017 106.218 37.757 106.812 37.0161 107.263C36.2752 107.715 35.4526 108.016 34.5953 108.15C33.738 108.284 32.8627 108.247 32.0195 108.043C31.1762 107.838 30.3815 107.469 29.6807 106.958C28.9799 106.446 28.3868 105.801 27.9352 105.061C27.4835 104.32 27.1822 103.497 27.0485 102.64L28.4878 102.415C28.592 103.084 28.8269 103.725 29.179 104.302C29.531 104.88 29.9934 105.382 30.5397 105.781C31.086 106.18 31.7055 106.468 32.3628 106.627C33.0202 106.786 33.7025 106.815 34.3708 106.711C35.0391 106.606 35.6804 106.371 36.2579 106.019C36.8355 105.667 37.338 105.205 37.7369 104.659C38.1358 104.112 38.4231 103.493 38.5825 102.836C38.742 102.178 38.7704 101.496 38.6661 100.828L40.1054 100.603Z" fill={secondaryLight}/>
      <Path d="M62.0525 97.1802C62.1863 98.0375 62.1498 98.9128 61.9453 99.7561C61.7408 100.599 61.3722 101.394 60.8605 102.095C60.3488 102.796 59.7042 103.389 58.9633 103.84C58.2224 104.292 57.3998 104.593 56.5425 104.727C55.6851 104.861 54.8099 104.824 53.9666 104.62C53.1234 104.415 52.3287 104.047 51.6279 103.535C50.9271 103.023 50.3339 102.379 49.8823 101.638C49.4307 100.897 49.1294 100.074 48.9957 99.2169L50.4349 98.9924C50.5392 99.6607 50.774 100.302 51.1261 100.88C51.4782 101.457 51.9405 101.96 52.4868 102.359C53.0331 102.757 53.6526 103.045 54.31 103.204C54.9673 103.364 55.6496 103.392 56.3179 103.288C56.9863 103.184 57.6275 102.949 58.2051 102.597C58.7826 102.245 59.2852 101.782 59.684 101.236C60.0829 100.69 60.3702 100.07 60.5297 99.4127C60.6891 98.7554 60.7175 98.073 60.6133 97.4047L62.0525 97.1802Z" fill={secondaryLight}/>
      {happy ? (<Path d="M40.7132 118.179C40.8421 119.037 41.1387 119.861 41.5862 120.605C42.0336 121.348 42.6231 121.996 43.321 122.512C44.0189 123.027 44.8115 123.4 45.6536 123.61C46.4957 123.819 47.3707 123.86 48.2288 123.731C49.0869 123.603 49.9111 123.306 50.6546 122.858C51.398 122.411 52.046 121.822 52.5616 121.124C53.0772 120.426 53.4503 119.633 53.6596 118.791C53.8689 117.949 53.9103 117.074 53.7814 116.216L52.3409 116.432C52.4414 117.101 52.4091 117.783 52.246 118.44C52.0828 119.096 51.792 119.714 51.39 120.258C50.9881 120.802 50.4829 121.262 49.9034 121.61C49.3239 121.959 48.6813 122.19 48.0124 122.291C47.3435 122.391 46.6614 122.359 46.0049 122.196C45.3485 122.033 44.7306 121.742 44.1866 121.34C43.6426 120.938 43.183 120.433 42.8342 119.853C42.4854 119.274 42.2542 118.631 42.1537 117.962L40.7132 118.179Z" fill={secondaryLight}/>) : (<Path d="M53.7757 116.179C53.642 115.322 53.3407 114.499 52.8891 113.758C52.4374 113.018 51.8443 112.373 51.1435 111.861C50.4427 111.35 49.648 110.981 48.8048 110.776C47.9615 110.572 47.0862 110.535 46.2289 110.669C45.3716 110.803 44.549 111.104 43.8081 111.556C43.0672 112.007 42.4225 112.601 41.9109 113.301C41.3992 114.002 41.0306 114.797 40.8261 115.64C40.6215 116.483 40.5851 117.359 40.7188 118.216L42.1581 117.991C42.0538 117.323 42.0822 116.641 42.2417 115.983C42.4011 115.326 42.6885 114.707 43.0873 114.16C43.4862 113.614 43.9888 113.152 44.5663 112.8C45.1439 112.448 45.7851 112.213 46.4534 112.108C47.1217 112.004 47.8041 112.033 48.4614 112.192C49.1187 112.351 49.7382 112.639 50.2845 113.038C50.8308 113.437 51.2932 113.939 51.6453 114.517C51.9974 115.094 52.2322 115.735 52.3365 116.404L53.7757 116.179Z" fill={secondaryLight}/>)}
    </Svg>);
};
export default IconEmptyPlaceholder;
