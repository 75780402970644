import { createSelector } from 'reselect';
import { emptyArray } from '../../../constants';
export const selectSelectedSpace = (store) => store.spaces.selectedSpace;
export const selectPreviousSelectedSpace = (store) => store.spaces.previousSpace;
export const selectSpaceInfo = (store) => store.spaces.spaceInfo;
export const selectSpaces = createSelector([selectSpaceInfo], (spaceInfo) => spaceInfo
    ? Object.values(spaceInfo).map((spaceInfo) => spaceInfo.space)
    : emptyArray);
export const selectHasSpaces = createSelector([selectSpaces], 
// A user should always have 1 space (their default space)
(spaces) => spaces && spaces?.length > 1);
export const selectSelectedSpaceData = createSelector([selectSelectedSpace, selectSpaces], (selectedId, spaces) => spaces?.find((space) => space?.id === selectedId));
export const selectIsDefaultSpace = createSelector(selectSelectedSpaceData, (selectedSpaceData) => {
    if (!selectedSpaceData || selectedSpaceData.isDefault) {
        return true;
    }
    return false;
});
const spaceById = (selectedId, spaces) => selectedId !== undefined && spaces !== undefined
    ? spaces[selectedId]
    : undefined;
export const selectSelectedSpaceInfo = createSelector([selectSelectedSpace, selectSpaceInfo], spaceById);
export const selectPreviousSelectedSpaceInfo = createSelector([selectPreviousSelectedSpace, selectSpaceInfo], spaceById);
