import React, { useId } from 'react';
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';
import { IconWithGradient } from './Icon';
export const IconCuttery = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="lightPink">
      <G clipPath="url(#clip0_415_3681)">
        <Path d="M12.5003 9.3342C12.5003 5.83655 10.9038 3.00079 8.93781 3.00079C6.97105 3.00079 5.37531 5.83655 5.37531 9.3342C5.37531 10.8563 6.33481 12.146 7.67762 12.6554L7.35439 20.4174C7.35439 21.2919 8.06332 22.0008 8.93781 22.0008C9.81229 22.0008 10.5212 21.2919 10.5212 20.4174L10.198 12.6561C11.5418 12.146 12.5003 10.8563 12.5003 9.3342Z" fill={`url(#gradient_${id})`}/>
        <Path d="M19.6251 8.74045L19.4272 3.00079H18.4376L18.2397 8.74045H17.4479L17.2501 3.00079H16.0626L15.8647 8.74045H15.0729L14.8751 3.00079H13.8854L13.6876 8.74045C13.6876 9.9479 14.4112 10.9822 15.4472 11.4463L15.0729 20.4174C15.0729 21.2919 15.7818 22.0008 16.6563 22.0008C17.5308 22.0008 18.2397 21.2919 18.2397 20.4174L17.8654 11.4463C18.9014 10.9822 19.6251 9.9479 19.6251 8.74045Z" fill={`url(#gradient_${id})`}/>
      </G>
      <Defs>
        <ClipPath id="clip0_415_3681">
          <Rect width="19" height="19" fill="white" transform="translate(3.00006 3)"/>
        </ClipPath>
      </Defs>
    </IconWithGradient>);
};
