import React, { memo, useCallback } from 'react';
import { rem } from 'design-system/values';
import { IconEdit } from 'design-system/icons/IconEdit';
import { SettingsItem } from 'design-system/SettingsItem';
import useColors from 'hooks/useColors';
import { useMainStackNavigation } from 'utils/types/navigationV6';
const iconSize = 44;
export const ADD_CATEGORY_ROW_HEIGHT = rem(iconSize + 32);
const AnalyticsSectionAddCategory = () => {
    const colors = useColors();
    const navigation = useMainStackNavigation();
    const onPress = useCallback(() => {
        navigation.navigate('ManageCategories');
    }, []);
    return (<SettingsItem iconSize={iconSize} icon={<IconEdit color={colors.elements.brand}/>} title="Manage categories" onPress={onPress}/>);
};
export default memo(AnalyticsSectionAddCategory);
