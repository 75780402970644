import { Platform } from 'react-native';
import Sentry from 'utils/sentry';
import { bundleId } from '../../constants';
export var Tier;
(function (Tier) {
    Tier["ultimate"] = "ULTIMATE";
    Tier["pro"] = "PRO";
    Tier["plus"] = "PLUS";
    Tier["free"] = "FREE";
})(Tier || (Tier = {}));
export const PremiumTiers = [Tier.ultimate, Tier.pro, Tier.plus];
export const nextTier = {
    [Tier.free]: Tier.plus,
    [Tier.plus]: Tier.pro,
    [Tier.pro]: Tier.ultimate,
    [Tier.ultimate]: undefined,
};
export var TierPeriod;
(function (TierPeriod) {
    TierPeriod["monthly"] = "MONTHLY";
    TierPeriod["yearly"] = "YEARLY";
})(TierPeriod || (TierPeriod = {}));
/**
 * iOS requires different prod/dev product ids
 */
const iosPackageName = (() => {
    if (Platform.OS !== 'ios')
        return '';
    switch (bundleId) {
        case 'com.emma-app.prod':
            return 'emmaapp';
        case 'com.emma-app.dev':
            return 'emmaappdev';
        default:
            Sentry.logError({
                filename: 'features/premium/entities.ts',
                message: `Failed to handle bundle id: ${bundleId}, defaulting to emmaapp`,
            });
            return 'emmaapp';
    }
})();
export const subscriptionGroupId = iosPackageName === 'emmaappdev' ? '20495486' : '20499273';
export const itemSkus = Platform.select({
    ios: [
        {
            id: `com.${iosPackageName}.plus.monthly`,
            tier: Tier.plus,
            period: TierPeriod.monthly,
        },
        {
            id: `com.${iosPackageName}.plus.yearly`,
            tier: Tier.plus,
            period: TierPeriod.yearly,
        },
        {
            id: `com.${iosPackageName}.pro.monthly`,
            tier: Tier.pro,
            period: TierPeriod.monthly,
        },
        {
            id: `com.${iosPackageName}.pro.yearly`,
            tier: Tier.pro,
            period: TierPeriod.yearly,
        },
        {
            id: `com.${iosPackageName}.ultimate.monthly`,
            tier: Tier.ultimate,
            period: TierPeriod.monthly,
        },
        {
            id: `com.${iosPackageName}.ultimate.yearly`,
            tier: Tier.ultimate,
            period: TierPeriod.yearly,
        },
    ],
    android: [
        {
            id: 'com.emmaapp.plus.monthly',
            tier: Tier.plus,
            period: TierPeriod.monthly,
        },
        {
            id: 'com.emmaapp.plus.yearly',
            tier: Tier.plus,
            period: TierPeriod.yearly,
        },
        {
            id: 'com.emmaapp.pro.monthly',
            tier: Tier.pro,
            period: TierPeriod.monthly,
        },
        {
            id: 'com.emmaapp.pro.yearly',
            tier: Tier.pro,
            period: TierPeriod.yearly,
        },
        {
            id: 'com.emmaapp.ultimate.monthly',
            tier: Tier.ultimate,
            period: TierPeriod.monthly,
        },
        {
            id: 'com.emmaapp.ultimate.yearly',
            tier: Tier.ultimate,
            period: TierPeriod.yearly,
        },
    ],
    default: [],
});
export const tierLevelMap = {
    [Tier.ultimate]: 3,
    [Tier.pro]: 2,
    [Tier.plus]: 1,
    [Tier.free]: 0,
};
export const periodMap = {
    [TierPeriod.monthly]: 1,
    [TierPeriod.yearly]: 2,
};
export const periodStringMap = {
    [TierPeriod.monthly]: '/mo',
    [TierPeriod.yearly]: '/yr',
};
export const tierLessThan = (tier, reference) => tierLevelMap[tier ?? Tier.free] < tierLevelMap[reference];
export const tierGreaterThan = (tier, reference) => tierLevelMap[tier ?? Tier.free] > tierLevelMap[reference];
export function isPremiumTier(tier) {
    return tier != null && tier !== Tier.free;
}
export function getNextTier(currentTier) {
    if (!currentTier || currentTier === Tier.free) {
        return Tier.plus;
    }
    return nextTier[currentTier];
}
export const tierFromProductId = (productId) => {
    if (productId.includes('.plus')) {
        return Tier.plus;
    }
    if (productId.includes('.pro')) {
        return Tier.pro;
    }
    if (productId.includes('.ultimate')) {
        return Tier.ultimate;
    }
    return Tier.free;
};
export const periodFromProductId = (productId) => {
    if (productId.includes('.monthly')) {
        return TierPeriod.monthly;
    }
    if (productId.includes('.yearly')) {
        return TierPeriod.yearly;
    }
    return undefined;
};
export const emmaProStatusToSKU = (emmaProStatus) => {
    if (emmaProStatus &&
        emmaProStatus.active &&
        emmaProStatus.period &&
        emmaProStatus.productId &&
        emmaProStatus.tier &&
        isPremiumTier(emmaProStatus.tier)) {
        const period = {
            yearly: TierPeriod.yearly,
            monthly: TierPeriod.monthly,
        }[emmaProStatus.period];
        if (period) {
            return {
                id: emmaProStatus.productId,
                tier: emmaProStatus.tier,
                period,
            };
        }
    }
    return undefined;
};
export var CompetitorId;
(function (CompetitorId) {
    CompetitorId[CompetitorId["Mint"] = 0] = "Mint";
    CompetitorId[CompetitorId["RocketMoney"] = 1] = "RocketMoney";
    CompetitorId[CompetitorId["YNAB"] = 2] = "YNAB";
    CompetitorId[CompetitorId["MonarchMoney"] = 3] = "MonarchMoney";
    CompetitorId[CompetitorId["Copilot"] = 4] = "Copilot";
    CompetitorId[CompetitorId["EveryDollar"] = 5] = "EveryDollar";
    CompetitorId[CompetitorId["Snoop"] = 6] = "Snoop";
    CompetitorId[CompetitorId["MoneyDashboard"] = 7] = "MoneyDashboard";
    CompetitorId[CompetitorId["Plum"] = 8] = "Plum";
    CompetitorId[CompetitorId["Chip"] = 9] = "Chip";
    CompetitorId[CompetitorId["Monzo"] = 10] = "Monzo";
    CompetitorId[CompetitorId["Curve"] = 11] = "Curve";
})(CompetitorId || (CompetitorId = {}));
