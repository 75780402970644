export const SKIPPED_PREMIUM_SCREEN = 'SKIPPED_PREMIUM_SCREEN';
export const SKIPPED_PREMIUM_SCREEN_SECOND = 'SKIPPED_PREMIUM_SCREEN_SECOND';
export const SKIPPED_PUSH_NOTIFICATIONS = 'SKIPPED_PUSH_NOTIFICATIONS';
export const CONNECTING_SECOND_LOGIN = 'CONNECTING_SECOND_LOGIN';
export const SKIPPED_SECOND_LOGIN = 'SKIPPED_SECOND_LOGIN';
export const SKIPPED_SET_BUDGETS = 'SKIPPED_SET_BUDGETS';
export const UPGRADED_TO_PREMIUM = 'UPGRADED_TO_PREMIUM';
export const VIEWED_PREMIUM_WELCOME_SCREEN = 'VIEWED_PREMIUM_WELCOME_SCREEN';
export const SET_SHOULD_SHOW_FAKE_LOADING_SCREEN = 'SET_SHOULD_SHOW_FAKE_LOADING_SCREEN';
export const SET_SIGN_IN_SPACE = 'SET_SIGN_IN_SPACE';
export const SET_SIGN_IN_NAVIGATION_KEY = 'SET_SIGN_IN_NAVIGATION_KEY';
