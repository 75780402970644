import { useEffect, useRef, useState } from 'react';
import { UpgradeWebModal } from 'design-system/UpgradeWebModal';
import { useAppSelector } from 'store/hooks';
import { benefits } from 'features/premium/benefits';
import { selectIsLoggedIn } from 'reducers/selectors';
import { isPremiumTier } from 'features/premium/entities';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import { isWeb } from '../../../constants';
import { selectBudgets } from '../selectors';
export const useBudgetRollover = (initialValue) => {
    const navigation = useMainStackNavigation();
    const budgets = useAppSelector(selectBudgets);
    const { isUnlockedForSpace } = useShouldShowUpgrade({
        benefitId: 'rollingBudgets',
    });
    const [isRolloverLocal, setRolloverLocal] = useState(() => {
        if (!isUnlockedForSpace) {
            return false;
        }
        if (initialValue !== undefined) {
            return initialValue;
        }
        return budgets.some((budget) => !!budget.shouldRollover);
    });
    const setOnUpgrade = useRef();
    useEffect(() => {
        if (setOnUpgrade.current && isUnlockedForSpace) {
            setRolloverLocal(true);
            setOnUpgrade.current = undefined;
        }
    }, [isUnlockedForSpace]);
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const onSetRollover = (value) => {
        if (value &&
            isPremiumTier(benefits.rollingBudgets.minTier) &&
            !isUnlockedForSpace) {
            setOnUpgrade.current = true;
            if (isWeb) {
                UpgradeWebModal.show(benefits.rollingBudgets.minTier);
                return;
            }
            if (isLoggedIn) {
                navigation.navigate('EmmaProModal', {
                    tier: benefits.rollingBudgets.minTier,
                });
            }
            else {
                const route = 'ModalOnboardingHowFreeTrialWorksScreen';
                navigation.navigate(route, {
                    isModal: true,
                    popOnUpgrade: true,
                });
            }
            return;
        }
        setRolloverLocal(value);
    };
    return {
        isRollover: isRolloverLocal,
        setRollover: onSetRollover,
        shouldUpgrade: !isUnlockedForSpace,
    };
};
