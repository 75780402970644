import React from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import useNavigateToAnalytics from 'features/analytics/hooks/useNavigateToAnalytics';
import Amount from 'design-system/Amount';
import { rem } from 'design-system/values';
import IconArrowTriangle from 'design-system/icons/IconArrowTriangle';
import IconArrowTriangleUp from 'design-system/icons/IconArrowTriangleUp';
import useColors from 'hooks/useColors';
import { useAppSelector } from 'store/hooks';
import { formatAmount } from 'utils/formatting';
import { interpolate } from 'utils/interpolations';
import { selectIsScrambled } from 'hooks/useScrambleMode';
import TouchableBounceCard from 'utils/packages/TouchableBounceCard';
import IncomeSpendingBarChart from 'features/analytics/components/chart/IncomeSpendingBarChart';
import { AnalyticsCardHeader } from './AnalyticsCardHeader';
const IncomeSpendingCard = ({ style, disabled }) => {
    const thisMonth = useAppSelector((store) => store.feed.feed.cards.thisMonth);
    const usePayday = thisMonth?.usePayday;
    const totalSpending = thisMonth?.spending;
    const totalIncome = thisMonth?.income;
    const incomeAmount = totalIncome?.amount || 0;
    const spendingAmount = totalSpending?.amount || 0;
    let incomePercent = totalIncome?.amount ? 100 : 0;
    let spendingPercent = totalSpending?.amount ? 100 : 0;
    if (incomeAmount > spendingAmount) {
        spendingPercent = interpolate(spendingAmount, {
            inputRange: [0, incomeAmount],
            outputRange: [0, 100],
        });
    }
    else {
        incomePercent = interpolate(incomeAmount, {
            inputRange: [0, spendingAmount],
            outputRange: [0, 100],
        });
    }
    const onPressAnalytics = useNavigateToAnalytics();
    const scrambled = useAppSelector(selectIsScrambled);
    const difference = incomeAmount - spendingAmount;
    const colors = useColors();
    return (<TouchableBounceCard onDark noMargin withPadding disabled={disabled} onPress={onPressAnalytics} style={[styles.cardWrapper, style]}>
      <AnalyticsCardHeader title={usePayday ? 'This period' : 'This month'} icon={difference <= 0 ? (<IconArrowTriangle color={colors.elements.negative}/>) : (<IconArrowTriangleUp color={colors.elements.positive}/>)} subTitle={formatAmount(Math.abs(difference), totalSpending?.currency, 0, scrambled)}/>
      <View style={styles.contentContainer}>
        <IncomeSpendingBarChart incomePercent={incomePercent} spendingPercent={spendingPercent}/>
        <View style={styles.barLabelsContainer}>
          <View style={styles.leftColumn}>
            <View style={styles.barLabelColumn}>
              <Amount Numbers-14 value={{
            value: Math.round(incomeAmount),
            currency: totalIncome?.currency,
        }} showFraction={false}/>
              <Text TextThin-12 Gray>
                Income
              </Text>
            </View>
          </View>
          <View style={styles.rightColumn}>
            <View style={styles.barLabelColumn}>
              <Amount Numbers-14 value={{
            value: Math.round(spendingAmount),
            currency: totalSpending?.currency,
        }} showFraction={false}/>
              <Text TextThin-12 Gray>
                Spending
              </Text>
            </View>
          </View>
        </View>
      </View>
    </TouchableBounceCard>);
};
const styles = StyleSheet.create({
    cardWrapper: {
        flex: 1,
        flexGrow: 1,
        flexShrink: 1,
        marginEnd: rem(4.5),
    },
    barLabelsContainer: {
        flex: 1,
        marginTop: rem(14),
        flexDirection: 'row',
    },
    barLabelColumn: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    contentContainer: {
        flex: 1,
        paddingTop: rem(10),
    },
    leftColumn: {
        flex: 1,
        alignItems: 'flex-end',
        paddingRight: rem(6),
    },
    rightColumn: {
        flex: 1.15,
        paddingLeft: rem(6),
        alignItems: 'flex-start',
    },
});
export default IncomeSpendingCard;
