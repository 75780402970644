import { put, take, fork, select, call, all, takeLatest, } from 'typed-redux-saga/macro';
import { buffers, eventChannel } from 'redux-saga';
import NetInfo from '@react-native-community/netinfo';
import { resetError, setNetwrokState } from 'actions';
import { SET_APP_STATE_STATUS } from 'actions/appState';
function* onNetInfoState(netInfoState) {
    const isConnected = netInfoState.isConnected ?? true;
    const isInternetReachable = netInfoState.isInternetReachable ?? true;
    const currentNetworkState = yield* select((store) => store.network);
    if (isConnected !== currentNetworkState.isConnected ||
        isInternetReachable !== currentNetworkState.isInternetReachable) {
        yield* put(setNetwrokState({ isConnected, isInternetReachable }));
        if (isConnected) {
            yield* put(resetError());
        }
    }
}
function* watchNetInfoState() {
    const channel = eventChannel((emitter) => {
        const unsubscribe = NetInfo.addEventListener((state) => {
            emitter(state);
        });
        return () => {
            unsubscribe();
        };
    }, buffers.sliding(1));
    while (true) {
        const netInfoState = yield* take(channel);
        yield* call(onNetInfoState, netInfoState);
    }
}
function* onAppState(appStateAction) {
    if (appStateAction.payload === 'active') {
        const netInfoState = yield* call(NetInfo.fetch);
        yield* onNetInfoState(netInfoState);
    }
}
function* netInfoSaga() {
    const netInfoState = yield* call(NetInfo.fetch);
    yield* call(onNetInfoState, netInfoState);
    yield* all([
        yield* fork(watchNetInfoState),
        yield* takeLatest(SET_APP_STATE_STATUS, onAppState),
    ]);
}
export default netInfoSaga;
