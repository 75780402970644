import React from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import { IconArrow } from 'design-system/icons';
import useStyles from 'hooks/useStyles';
import FastImage from 'utils/packages/rnFastImage';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableBounce from 'utils/packages/TouchableBounce';
import { isWeb } from '../../../constants';
const NotificationItem = ({ item, style, onPress, }) => {
    const { heading, text, coloredIconUrl, iconUrl } = item;
    const styles = useStyles(styleSet);
    const hasLink = !isWeb && item.data && 'appLink' in item.data && item.data.appLink;
    const pressed = () => {
        if (hasLink) {
            onPress(hasLink);
        }
    };
    return (<TouchableBounce style={[styles.row, style]} disabled={Boolean(!hasLink)} onPress={pressed}>
      <FastImage source={{ uri: coloredIconUrl || iconUrl }} style={staticStyles.image} resizeMode="contain"/>
      <View style={styles.description}>
        {heading && (<Text Text-16 OffBlack style={styles.title}>
            {heading}
          </Text>)}
        <Text TextThin-14 Secondary>
          {text}
        </Text>
      </View>
      {!!hasLink && <IconArrow style={styles.arrow}/>}
    </TouchableBounce>);
};
const staticStyles = StyleSheet.create({
    image: {
        width: rem(41),
        height: rem(41),
    },
});
const styleSet = createStyleSheets((colors) => ({
    row: {
        flexDirection: 'row',
        padding: rem(16),
        borderRadius: rem(16),
        backgroundColor: colors.cards.onDark,
    },
    description: {
        flex: 1,
        marginLeft: rem(18),
        flexDirection: 'column',
    },
    title: {
        marginBottom: rem(4),
    },
    arrow: {
        alignSelf: 'center',
        marginLeft: rem(16),
    },
}));
export default NotificationItem;
