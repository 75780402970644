import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
const IconCategory = (props) => {
    const id = useId();
    return (<IconWithGradient viewBox="0 0 24 24" id={id} width={24} height={24} defaultGradientKey="orange" {...props}>
      <Path fill={`url(#gradient_${id})`} d="M19.867 15.44H4.68a.194.194 0 0 0-.194.193v1.145c0 .668.544 1.211 1.212 1.211h13.151c.668 0 1.212-.543 1.212-1.211v-1.145a.194.194 0 0 0-.194-.194ZM19.625 11.252h-15c-.62 0-1.125.673-1.125 1.5 0 .828.505 1.5 1.125 1.5h15c.62 0 1.125-.672 1.125-1.5 0-.827-.505-1.5-1.125-1.5ZM4.672 10.398h15.203a.194.194 0 0 0 .194-.21c-.005-2.032-.801-3.706-2.304-4.842-1.38-1.042-3.278-1.594-5.491-1.594-2.214 0-4.113.552-5.492 1.594-1.507 1.14-2.303 2.82-2.303 4.858 0 .107.086.194.193.194Z"/>
    </IconWithGradient>);
};
export default IconCategory;
