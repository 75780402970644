import React from 'react';
import { View } from 'react-native';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import Text from './Text';
import Spacer from './Spacer';
import Modal from './ModalWeb';
import { rem } from './values';
import { QRCode } from './QRCode';
import { CardView } from './CardView';
const logo = require('../features/pay/img/bear@3x.png');
const RenewAccessWebModalContent = () => {
    const styles = useStyles(styleSet);
    return (<View>
      <Text TextThin-14 Secondary>
        {'This functionality is not available in the web version.\nScan the QR code to renew access to your bank account on the Emma app.'}
      </Text>
      <Spacer h={24}/>
      <CardView noMargin style={styles.qrContainer}>
        <QRCode value={'emma://logins?brokenConnections=true&refreshOnMount=true'} ecl="quartile" logo={logo}/>
      </CardView>
    </View>);
};
export const RenewAccessWebModal = {
    show: () => {
        Modal.show(<RenewAccessWebModalContent />, {
            title: 'Renew access',
        });
    },
    hide: () => {
        Modal.hide();
    },
};
const styleSet = createStyleSheets((colors) => ({
    qrContainer: {
        paddingVertical: rem(24),
        alignItems: 'center',
        backgroundColor: colors.borders.divider,
    },
}));
