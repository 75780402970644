import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import Modal from 'design-system/Modal';
import { useAppStore } from 'store/hooks';
import { selectUserId } from 'reducers/selectors';
import { rem } from 'design-system/values';
import PopUpMenuModal, { defaultPopUpWidth, } from 'design-system/PopUpMenuModal.web';
import AccountMenu from '../components/menu/AccountMenu';
import { isWeb } from '../../../constants';
export const useAccountMenu = (navigation, navigateBackAfterDelete = true) => {
    const store = useAppStore();
    const openMenu = useCallback((account, touchViewRef) => {
        const userId = selectUserId(store.getState());
        if (userId !== account.userId) {
            return;
        }
        let title = 'Edit Account';
        if (account.provider === 'MANUAL') {
            title = 'Edit Offline Account';
        }
        if (account.type === 'REAL_ESTATE' ||
            account.type === 'VEHICLE' ||
            account.type === 'OTHER')
            title = 'Edit Property';
        const content = (<AccountMenu account={account} navigation={navigation} navigateBackAfterDelete={navigateBackAfterDelete}/>);
        if (isWeb) {
            if (touchViewRef) {
                touchViewRef.current?.measure((_, __, ___, ____, fx, fy) => {
                    PopUpMenuModal.show(content, {
                        fx: fx - defaultPopUpWidth,
                        fy: fy + rem(40),
                        containerStyle: styles.containerStyles,
                    });
                });
            }
        }
        else {
            Modal.show(content, {
                title,
                containerStyle: styles.containerStyles,
            });
        }
    }, []);
    return openMenu;
};
const styles = StyleSheet.create({
    containerStyles: {
        ...(isWeb
            ? {
                padding: 0,
                backgroundColor: 'transparent',
            }
            : {
                paddingHorizontal: 0,
            }),
    },
});
