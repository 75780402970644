import React, { memo, useCallback, useMemo } from 'react';
import { format, isThisYear, parseISO } from 'date-fns';
import { View, Platform } from 'react-native';
import Text from 'design-system/Text';
import Amount from 'design-system/Amount';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import Section from 'design-system/Section';
import Indicator from 'design-system/Indicator';
import { CardView } from 'design-system/CardView';
import IconPlus from 'design-system/icons/IconPlus';
import SectionHeader from 'design-system/SectionHeader';
import { SettingsItem } from 'design-system/SettingsItem';
import { TextButton, TextButtonLink } from 'design-system/TextButton';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { useAppSelector } from 'store/hooks';
import FastImageCommon from 'common/FastImageCommon';
import createStyleSheets from 'utils/createStyleSheets';
import { selectListAccounts } from 'reducers/selectors';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { useSubscriptionScreenContext } from 'features/subscriptions/context/subscriptionScreenContext';
import { isWeb } from '../../../../constants';
const SubscriptionPrediction = ({ date, style, onDone, onEdit, amount, onPress, currency, accountId, subscriptionId, }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const accounts = useAppSelector(selectListAccounts);
    const { editing, hasData, isFetching } = useSubscriptionScreenContext();
    const navigation = useMainStackNavigation();
    const addMoreTxns = useCallback(() => {
        navigation.push('SubscriptionsSearch', {
            subscriptionId,
        });
    }, [subscriptionId]);
    const renderAccount = useCallback(() => {
        let account = null;
        if (accounts)
            account = accounts.find((item) => item.id === accountId);
        const accountNumber = account
            ? account.accountNumber?.substring(account.accountNumber.length - 4)
            : null;
        let imageUrl = account ? account.iconUrl : null;
        if (account && account.iconBase64)
            imageUrl = `data:image/png;base64,${account.iconBase64}`;
        return (<View style={styles.accountRow}>
        <FastImageCommon url={imageUrl} imageStyle={styles.accountIcon}/>
        <Text NumbersThin-14 Gray>
          {accountNumber}
        </Text>
      </View>);
    }, [accounts, styles.accountRow, styles.accountIcon, accountId]);
    const renderNextPayment = useMemo(() => {
        if (!date) {
            return null;
        }
        const parsedDate = parseISO(date);
        let bookingDate = format(parsedDate, 'd MMMM yyyy');
        if (isThisYear(parsedDate)) {
            bookingDate = format(parsedDate, 'MMMM d');
        }
        return (<Section title="Next payment" style={styles.cardSectionHeader} button={<TextButtonLink text="Edit" onPress={onPress}/>}>
        <CardView noMarginTop noMarginBottom>
          <View style={[styles.sectionHeader, style]}>
            <View style={styles.textContainer}>
              <Text Text-16>{bookingDate}</Text>
            </View>
            <View style={styles.amountContainer}>
              <Amount Numbers-16 style={undefined} value={{ value: amount, currency }}/>
              {renderAccount()}
            </View>
          </View>
        </CardView>
      </Section>);
    }, [
        amount,
        currency,
        date,
        onPress,
        renderAccount,
        style,
        styles.amountContainer,
        styles.cardSectionHeader,
        styles.sectionHeader,
        styles.textContainer,
    ]);
    const pastSectionHeader = useMemo(() => isFetching && !hasData ? (<View style={styles.indicatorContainer}>
          <Indicator dark/>
        </View>) : (<>
          <SectionHeader horizontalMargins style={styles.cardSectionHeader} button={!editing && !hasData ? null : (<TextButton text={editing ? 'Done' : 'Edit'} onPress={editing ? onDone : onEdit}/>)}>
            Past payments
          </SectionHeader>
          <Spacer.H16 />
          {!editing && (<View style={[styles.addMoreTxn, !hasData && styles.completeAddMoreTxn]}>
              <SettingsItem onPress={addMoreTxns} title="Add more transactions" icon={<IconPlus color={colors.text.brand}/>}/>
            </View>)}
        </>), [
        isFetching,
        styles.indicatorContainer,
        styles.cardSectionHeader,
        styles.addMoreTxn,
        styles.completeAddMoreTxn,
        editing,
        hasData,
        onDone,
        onEdit,
        addMoreTxns,
        colors.text.brand,
    ]);
    return (<>
      {renderNextPayment}
      {pastSectionHeader}
    </>);
};
const styleSet = createStyleSheets((colors) => ({
    sectionHeader: {
        padding: rem(16),
        flexDirection: 'row',
        justifyContent: 'center',
    },
    textContainer: {
        flex: 1,
        justifyContent: 'center',
    },
    amountContainer: {
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    accountRow: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    accountIcon: {
        width: rem(16),
        height: rem(16),
        marginEnd: rem(4),
        borderRadius: rem(8),
    },
    addMoreTxn: {
        marginHorizontal: rem(16),
        borderTopLeftRadius: rem(16),
        borderTopRightRadius: rem(16),
        backgroundColor: colors.cards.onDark,
        ...(Platform.OS !== 'android' && {
            overflow: 'hidden',
        }),
        ...(isWeb && {
            paddingTop: rem(8),
            paddingHorizontal: rem(8),
        }),
    },
    completeAddMoreTxn: {
        borderBottomLeftRadius: rem(16),
        borderBottomRightRadius: rem(16),
        ...(isWeb && {
            paddingBottom: rem(8),
        }),
    },
    cardSectionHeader: {
        marginTop: rem(24),
        marginBottom: 0,
    },
    indicatorContainer: {
        marginTop: rem(16),
        alignItems: 'center',
    },
}));
export default memo(SubscriptionPrediction);
