import React from 'react';
import { View } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import Squircle from 'design-system/Squircle';
import { CheckMarkSquareBadge } from 'design-system/badges/badges';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import { isWeb } from '../../constants';
export const PickerItem = ({ icon, label, value, onPress, inputLabel, selected, selectedIconFill, }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    return (<TouchableHighlight onCard hoverOnLight underlayColor={colors.background.underlay} activeOpacity={1} onPress={() => {
            onPress?.(value, label);
        }}>
      <View style={styles.pickerItemContainer}>
        <View>
          <Squircle color={isWeb ? colors.cards.onLight : colors.background.dark}>
            {icon}
          </Squircle>
          {selected && <CheckMarkSquareBadge fill={selectedIconFill}/>}
        </View>
        <Text Text-16 style={styles.label}>
          {inputLabel || label}
        </Text>
      </View>
    </TouchableHighlight>);
};
const styleSet = createStyleSheets((colors) => ({
    pickerItemContainer: {
        padding: rem(16),
        ...(isWeb
            ? {
                paddingHorizontal: rem(8),
            }
            : {
                backgroundColor: colors.cards.onDark,
            }),
        flexDirection: 'row',
        alignItems: 'center',
    },
    label: {
        marginLeft: rem(16),
    },
}));
