import moment from 'moment';
import { createSelector } from 'reselect';
import { selectRating } from 'reducers/selectors';
export const selectShouldShowNetScore = createSelector([
    (store) => store.user.user.latestFeedbackDate,
    (store) => store.user.user.latestFeedbackRating,
    (store) => store.user.user.firstConnectionAt,
    selectRating,
], (latestFeedbackDate, latestFeedbackRating, firstConnectionAt, sessionCount) => !!firstConnectionAt &&
    (!latestFeedbackDate ||
        moment().diff(latestFeedbackDate, 'months', true) > 6 ||
        (latestFeedbackRating === null &&
            moment().diff(latestFeedbackDate, 'months', true) > 3)) &&
    sessionCount > 10 &&
    moment().diff(moment(firstConnectionAt), 'days', true) > 7);
