import React, { PureComponent } from 'react';
import { StyleSheet, View } from 'react-native';
import Sentry from 'utils/sentry';
import Text from 'design-system/Text';
import Button from 'design-system/Button';
import FloatingButton from 'design-system/FloatingButton';
import { navigationRef } from 'utils/navigationv6';
import { AppView } from 'design-system/AppView';
import { rem } from 'design-system/values';
import { isWeb } from '../constants';
class ErrorBoundaries extends PureComponent {
    state = {
        showError: false,
    };
    componentDidCatch(err, errInfo) {
        const errorStack = errInfo && errInfo.componentStack
            ? errInfo.componentStack.toString()
            : 'NO DATA';
        Sentry.logError({ filename: 'ErrorBoundaries.js', err, data: errorStack });
        this.setState({
            showError: true,
        });
    }
    _openSupport = () => {
        this.setState({ showError: false }, () => {
            if (navigationRef.isReady()) {
                navigationRef.navigate('Support', {});
            }
        });
    };
    _onPress = () => {
        this.setState({ showError: false });
    };
    render() {
        if (!this.state.showError) {
            return this.props.children;
        }
        return (<AppView type="primary" isInTab={isWeb}>
        <View style={styles.wrap}>
          <View style={styles.topContainer}>
            <Text Text-48 White style={styles.emoji}>
              😵
            </Text>
            <Text Text-24 OffBlack centered style={styles.titleText}>
              Oops, something went wrong
            </Text>
            <Text TextThin-16 Secondary centered style={styles.titleDesc}>
              Something is not working. Try to update the app or reach out to{' '}
              <Text Text-16 Secondary>
                Customer Support
              </Text>
              , so we can help you.
            </Text>
          </View>

          {!isWeb && <FloatingButton top Chat onPress={this._openSupport}/>}
        </View>
        <Button brand floating onPress={this._onPress} title="Back to the app"/>
      </AppView>);
    }
}
const styles = StyleSheet.create({
    wrap: {
        flex: 1,
        justifyContent: 'space-between',
        padding: rem(20),
    },
    topContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    emoji: {
        marginBottom: rem(8),
    },
    titleText: {
        marginBottom: rem(8),
    },
    titleDesc: {
        marginBottom: rem(60),
    },
});
export default ErrorBoundaries;
