export const GET_VRP_CONSENT_BY_ID_REQUEST = 'GET_VRP_CONSENT_BY_ID_REQUEST';
export const GET_VRP_CONSENT_BY_ID_SUCCESS = 'GET_VRP_CONSENT_BY_ID_SUCCESS';
export const GET_VRP_CONSENT_BY_ID_FAILURE = 'GET_VRP_CONSENT_BY_ID_FAILURE';
export const GET_VRP_CONSENTS_REQUEST = 'GET_VRP_CONSENTS_REQUEST';
export const GET_VRP_CONSENTS_SUCCESS = 'GET_VRP_CONSENTS_SUCCESS';
export const GET_VRP_CONSENTS_FAILURE = 'GET_VRP_CONSENTS_FAILURE';
export const DELETE_VRP_CONSENT_BY_ID_REQUEST = 'DELETE_VRP_CONSENT_BY_ID_REQUEST';
export const DELETE_VRP_CONSENT_BY_ID_SUCCESS = 'DELETE_VRP_CONSENT_BY_ID_SUCCESS';
export const DELETE_VRP_CONSENT_BY_ID_FAILURE = 'DELETE_VRP_CONSENT_BY_ID_FAILURE';
export const GET_VRP_AUTHORISATION_LINK_REQUEST = 'GET_VRP_AUTHORISATION_LINK_REQUEST';
export const GET_VRP_AUTHORISATION_LINK_SUCCESS = 'GET_VRP_AUTHORISATION_LINK_SUCCESS';
export const GET_VRP_AUTHORISATION_LINK_FAILURE = 'GET_VRP_AUTHORISATION_LINK_FAILURE';
export const EDIT_VRP_CONSENT_REQUEST = 'EDIT_VRP_CONSENT_REQUEST';
export const EDIT_VRP_CONSENT_SUCCESS = 'EDIT_VRP_CONSENT_SUCCESS';
export const EDIT_VRP_CONSENT_FAILURE = 'GET_VRP_AUTHORISATION_LINK_FAILURE';
export const MAKE_VRP_PAYMENT_REQUEST = 'MAKE_VRP_PAYMENT_REQUEST';
export const MAKE_VRP_PAYMENT_SUCCESS = 'MAKE_VRP_PAYMENT_SUCCESS';
export const MAKE_VRP_PAYMENT_FAILURE = 'MAKE_VRP_PAYMENT_FAILURE';
