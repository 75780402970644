import { selectIsInvestAccountActive } from 'features/invest/reducers/selectors';
import { selectIsPotsAccountActive } from 'features/pots/reducers/selectors';
import { selectFeatureFlags, selectIsGBUser, selectListAccounts, } from 'reducers/selectors';
import { createSelector } from 'reselect';
import checkFeatureFlag from 'utils/featureFlags';
export const selectHasPreApprovedTransfersFeature = createSelector([
    selectFeatureFlags,
    selectIsGBUser,
    selectIsInvestAccountActive,
    selectIsPotsAccountActive,
], (featureFlags, isGBUser, isInvestAccountActive, isPotsAccountActive) => isGBUser &&
    (isInvestAccountActive || isPotsAccountActive) &&
    checkFeatureFlag(featureFlags, 'pre_approved_transfers_consent_alignment'));
export const selectVRPConsents = (store) => store.preApprovedTransfers.consents;
export const selectVRPConsentsArray = createSelector([selectVRPConsents, selectListAccounts], (consents, allAccounts) => Object.values(consents)
    .map((c) => ({
    consent: c,
    account: allAccounts.find((acc) => acc.id === c.accountFromId),
}))
    .filter((c) => c.account !== undefined));
export const selectVRPConsentById = createSelector([selectVRPConsents, selectListAccounts, (_, id) => id], (consents, allAccounts, id) => {
    if (consents[id]) {
        const account = allAccounts.find((acc) => acc.id === consents[id].accountFromId);
        if (account) {
            return {
                consent: consents[id],
                account,
            };
        }
    }
    return undefined;
});
