import { CALL_API } from 'middleware/api';
import * as types from './types';
export const getVRPConsentById = (consentId) => ({
    [CALL_API]: {
        endpoint: `/vrp-consents/${consentId}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_VRP_CONSENT_BY_ID_REQUEST,
            types.GET_VRP_CONSENT_BY_ID_SUCCESS,
            types.GET_VRP_CONSENT_BY_ID_FAILURE,
        ],
    },
});
export const getVRPConsents = () => ({
    [CALL_API]: {
        endpoint: `/vrp-consents`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_VRP_CONSENTS_REQUEST,
            types.GET_VRP_CONSENTS_SUCCESS,
            types.GET_VRP_CONSENTS_FAILURE,
        ],
    },
});
export const deleteVRPConsentById = (consentId) => ({
    [CALL_API]: {
        endpoint: `/vrp-consents/${consentId}`,
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        extra: { consentId },
        types: [
            types.DELETE_VRP_CONSENT_BY_ID_REQUEST,
            types.DELETE_VRP_CONSENT_BY_ID_SUCCESS,
            types.DELETE_VRP_CONSENT_BY_ID_FAILURE,
        ],
    },
});
export const getVRPAuthorisationLink = (body) => ({
    [CALL_API]: {
        endpoint: `/vrp-consents/ledger`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        types: [
            types.GET_VRP_AUTHORISATION_LINK_REQUEST,
            types.GET_VRP_AUTHORISATION_LINK_SUCCESS,
            types.GET_VRP_AUTHORISATION_LINK_FAILURE,
        ],
    },
});
export const editVRPConsent = (consentId, body) => ({
    [CALL_API]: {
        endpoint: `/vrp-consents/${consentId}`,
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        types: [
            types.EDIT_VRP_CONSENT_REQUEST,
            types.EDIT_VRP_CONSENT_SUCCESS,
            types.EDIT_VRP_CONSENT_FAILURE,
        ],
    },
});
export const makeVRPPayment = (consentId, body) => ({
    [CALL_API]: {
        endpoint: `/vrp-consents/${consentId}/makePayment`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        types: [
            types.MAKE_VRP_PAYMENT_REQUEST,
            types.MAKE_VRP_PAYMENT_SUCCESS,
            types.MAKE_VRP_PAYMENT_FAILURE,
        ],
    },
});
