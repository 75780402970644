import queryString from 'query-string';
import { CALL_API } from 'middleware/api';
import { prepareParams } from 'features/invest/utils/prepareParams';
export const getEmmaWalletActivitiesHistory = (paging) => ({
    [CALL_API]: {
        endpoint: `/wealth/ledger/wallet/activity?${queryString.stringify(prepareParams(paging))}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        extra: paging,
        types: [
            'GET_EMMA_WALLET_ACTIVITIES_REQUEST',
            'GET_EMMA_WALLET_ACTIVITIES_SUCCESS',
            'GET_EMMA_WALLET_ACTIVITIES_FAILURE',
        ],
    },
});
