import { useCallback, useEffect, useRef, useState } from 'react';
import { Keyboard, Platform, Dimensions, StatusBar, } from 'react-native';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { calculateSize, TOOLBAR_PADDING_VERTICAL } from '../config';
const isOSKeyboard = (keyboardType) => keyboardType === 'KeyboardAmountUpper' ||
    keyboardType === 'KeyboardAmountLower' ||
    keyboardType === undefined ||
    keyboardType === 'KeyboardReceipts';
const useKeyboard = (activeFilter, onSelectFilter, onRemoveFilter, onToggleAmountFilter, refSearchBar) => {
    /**
     * Space reserved for the current custom keyboard.
     * It's measured whenever the OS keyboard pops up
     */
    const [keyboardHeight, setKeyboardHeight] = useState(0);
    /**
     * Name of the current custom keyboard.
     * Undefined means there's the default text keyboard, or the keyboard is hidden
     */
    const [activeKeyboard, setActiveKeyboard] = useState();
    // Use refs to avoid recomputing callbacks on state changes
    const activeKeyboardRef = useRef();
    const activeFilterRef = useRef();
    const keyboardHeightRef = useRef(0);
    /**
     *  Some custom keyboards require invoking the OS keyboard
     *  In that case OS keyboard show/hide events should be handled differently
     *  This flag is used to distinguish between custom -> OS / custom -> custom
     */
    const waitingForOSKeyboardTransitionRef = useRef(false);
    // Sync refs with the state
    useEffect(() => {
        activeKeyboardRef.current = activeKeyboard;
    }, [activeKeyboard]);
    useEffect(() => {
        activeFilterRef.current = activeFilter;
    }, [activeFilter]);
    useEffect(() => {
        keyboardHeightRef.current = keyboardHeight;
    }, [keyboardHeight]);
    const handleActiveKeyboard = useCallback((activeKeyboard) => {
        if (activeKeyboard) {
            if (activeKeyboard === 'KeyboardAmountLower') {
                onToggleAmountFilter('AMOUNT_LOWER');
            }
            else if (activeKeyboard === 'KeyboardAmountUpper') {
                onToggleAmountFilter('AMOUNT_UPPER');
            }
            else if (activeKeyboard === 'KeyboardReceipts') {
                onToggleAmountFilter();
                onSelectFilter({
                    badgeText: 'Has receipt',
                    filterType: 'RECEIPT',
                });
                refSearchBar.current?.focus();
            }
            else {
                onToggleAmountFilter();
                Keyboard.dismiss();
            }
        }
    }, [onSelectFilter, onToggleAmountFilter, refSearchBar]);
    const showSpecialKeyboard = useCallback((keyboard) => {
        waitingForOSKeyboardTransitionRef.current = isOSKeyboard(activeKeyboardRef.current) || isOSKeyboard(keyboard);
        if (keyboard === 'KeyboardReceipts') {
            if (activeFilterRef.current?.RECEIPT) {
                onRemoveFilter(activeFilterRef.current.RECEIPT);
                return;
            }
        }
        setActiveKeyboard(keyboard);
        handleActiveKeyboard(keyboard);
    }, [handleActiveKeyboard, onRemoveFilter]);
    const collapseSpecialKeyboard = useCallback(() => {
        showSpecialKeyboard(undefined);
        setKeyboardHeight(0);
    }, [showSpecialKeyboard]);
    const hideSpecialKeyboard = useCallback(() => {
        collapseSpecialKeyboard();
        Keyboard.dismiss();
    }, [collapseSpecialKeyboard]);
    // Used by SearchTransactions.js when we scroll transaction list
    const keyboardHideFromScroll = useCallback(() => {
        if (keyboardHeightRef.current) {
            collapseSpecialKeyboard();
        }
    }, [collapseSpecialKeyboard]);
    const { width } = useAppFrameDimensions();
    useEffect(() => {
        let showEmitterSubscription;
        let hideEmitterSubscription;
        const handleKeyboardShow = (e) => {
            waitingForOSKeyboardTransitionRef.current = false;
            const size = calculateSize(width);
            const keyboardHeight = Platform.OS === 'ios'
                ? e.endCoordinates.height
                : Dimensions.get('window').height + (StatusBar.currentHeight ?? 0) - e.endCoordinates.screenY;
            const keyboardCombinedHeight = keyboardHeight + size + TOOLBAR_PADDING_VERTICAL * 2;
            setKeyboardHeight(keyboardCombinedHeight);
        };
        const handleKeyboardHide = () => {
            if (waitingForOSKeyboardTransitionRef.current) {
                waitingForOSKeyboardTransitionRef.current = false;
                return;
            }
            onToggleAmountFilter();
            collapseSpecialKeyboard();
        };
        if (Platform.OS === 'ios') {
            showEmitterSubscription = Keyboard.addListener('keyboardWillShow', handleKeyboardShow);
            hideEmitterSubscription = Keyboard.addListener('keyboardWillHide', handleKeyboardHide);
        }
        else {
            showEmitterSubscription = Keyboard.addListener('keyboardDidShow', handleKeyboardShow);
            hideEmitterSubscription = Keyboard.addListener('keyboardDidHide', handleKeyboardHide);
        }
        return () => {
            showEmitterSubscription.remove();
            hideEmitterSubscription.remove();
        };
    }, [collapseSpecialKeyboard, onToggleAmountFilter, showSpecialKeyboard, width]);
    return {
        keyboardHeight,
        activeKeyboard,
        showSpecialKeyboard,
        hideSpecialKeyboard,
        keyboardHideFromScroll,
    };
};
export default useKeyboard;
