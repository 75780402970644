import { put, select, takeEvery } from 'typed-redux-saga/macro';
import { selectCurrentPaydayRangeWithFallback } from 'reducers/selectors';
import { BUDGETING_PERIOD_CHANGED } from 'actions/types';
import { CANCEL_SUBSCRIPTIONS_SUCCESS, EDIT_SUBSCRIPTIONS_SUCCESS, FETCH_COMMITTED_FOR_CURRENT_PAYDAY_RANGE, } from '../actions/types';
import { getCommitted } from '../actions';
function* fetchCommittedForCurrentPaydayRange() {
    const currentPaydayRangeWithFallback = yield* select(selectCurrentPaydayRangeWithFallback);
    yield* put(getCommitted(currentPaydayRangeWithFallback.from, currentPaydayRangeWithFallback.to));
}
function* subscriptionsSaga() {
    yield* takeEvery((action) => [
        FETCH_COMMITTED_FOR_CURRENT_PAYDAY_RANGE,
        EDIT_SUBSCRIPTIONS_SUCCESS,
        CANCEL_SUBSCRIPTIONS_SUCCESS,
        BUDGETING_PERIOD_CHANGED,
    ].includes(action.type), fetchCommittedForCurrentPaydayRange);
}
export default subscriptionsSaga;
