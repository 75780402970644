import React, { useCallback, useMemo } from 'react';
import Animated from 'react-native-reanimated';
import LinearGradient from 'react-native-linear-gradient';
import { FlashList } from '@shopify/flash-list';
import IconPlus from 'design-system/icons/IconPlus';
import { SettingsItem } from 'design-system/SettingsItem';
import FlatListItemCard from 'design-system/FlatListItemCard';
import { rem, SCROLL_INDICATOR_INSETS } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
import { useCategories } from 'hooks/useCategory';
import { useMarginBottom } from 'hooks/useMarginBottom';
import { useAppSelector } from 'store/hooks';
import useColorScheme from 'hooks/useColorScheme';
import createStyleSheets from 'utils/createStyleSheets';
import useEstimatedListSize from 'hooks/useEstimatedListSize';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { selectOrderedCustomCategories } from 'reducers/selectors';
import { isWeb } from '../../../../constants';
import EditCategoryListVerticalRow from './EditCategoryVerticalListRow';
import EditCategoryVerticalListHeader from './EditCategoryVerticalListHeader';
const AnimatedFlashList = Animated.createAnimatedComponent(FlashList);
const keyExtractor = (item) => item.id;
const ITEM_SIZE = rem(76);
const EditCategoryVerticalList = ({ scrollHandler, onPressCategory, }) => {
    const customCategories = useAppSelector(selectOrderedCustomCategories);
    const categories = useCategories();
    const styles = useStyles(styleSet);
    const renderItem = useCallback(({ item, index }) => {
        const isDefaultCategory = categories[item.id] !== undefined;
        const category = categories[item.id] || item;
        return (<FlatListItemCard index={index + 1} itemsLength={customCategories.length + 1} style={styles.listItem}>
          <EditCategoryListVerticalRow category={category} isDefaultCategory={isDefaultCategory} onPress={onPressCategory} showEdit={!isDefaultCategory}/>
        </FlatListItemCard>);
    }, [customCategories.length, categories, onPressCategory, styles.listItem]);
    const colors = useColors();
    const navigation = useMainStackNavigation();
    const renderHeader = useMemo(() => (<>
        <EditCategoryVerticalListHeader />
        <FlatListItemCard index={0} itemsLength={customCategories.length + 1} style={styles.listItem}>
          <SettingsItem title="Add new category" onPress={() => {
            navigation.navigate('CustomCategoryEdit');
        }} renderIcon={() => (<LinearGradient {...colors.gradients.ultimate} style={styles.addIcon}>
                <IconPlus />
              </LinearGradient>)}/>
        </FlatListItemCard>
      </>), [
        colors.gradients.ultimate,
        customCategories.length,
        styles.addIcon,
        styles.listItem,
    ]);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const contentContainerStyle = useMarginBottom('paddingBottom', undefined, paddingHorizontalStyle);
    const estimatedListSize = useEstimatedListSize(customCategories);
    const colorScheme = useColorScheme();
    const baseProps = {
        onScroll: scrollHandler,
        data: customCategories,
        renderItem,
        scrollEventThrottle: 16,
        keyExtractor,
        contentContainerStyle,
        ListHeaderComponent: renderHeader,
        scrollIndicatorInsets: SCROLL_INDICATOR_INSETS,
    };
    if (isWeb) {
        return <Animated.FlatList {...baseProps}/>;
    }
    return (<AnimatedFlashList {...baseProps} extraData={colorScheme} estimatedItemSize={ITEM_SIZE} estimatedListSize={estimatedListSize}/>);
};
const styleSet = createStyleSheets((colors) => ({
    listItem: {
        backgroundColor: colors.cards.onDark,
    },
    addIcon: {
        width: rem(44),
        height: rem(44),
        borderRadius: rem(44),
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: rem(16),
    },
}));
export default EditCategoryVerticalList;
