import { SET_DID_ONBOARD_NETWORTH, SET_FEED_PERSONALIZATION, SET_DID_CLOSE_POTS_CTA_FROM_POTS, SET_DID_CLOSE_PUSH_NOTIFICATION_CARD, SET_DID_CLOSE_POTS_CTA_FROM_ANALYTICS, SET_DID_CLOSE_POTS_CTA_FROM_BUDGETING, SET_DID_CLOSE_POTS_CTA_FROM_TRANSACTIONS, SET_DEVICE_SETTINGS_KEY, SET_DID_CLOSE_UPGRADE_NETWORTH, } from 'actions/types/deviceSettings';
export const setDidClosePushNotificationCard = () => ({
    type: SET_DID_CLOSE_PUSH_NOTIFICATION_CARD,
});
export const setDidOnboardNetworth = () => ({
    type: SET_DID_ONBOARD_NETWORTH,
});
export const setDidCloseUpgradeNetworthCTA = () => ({
    type: SET_DID_CLOSE_UPGRADE_NETWORTH,
});
export const setDidClosePotsCTAfromAnalytics = () => ({
    type: SET_DID_CLOSE_POTS_CTA_FROM_ANALYTICS,
});
export const setDidClosePotsCTAfromBudgeting = () => ({
    type: SET_DID_CLOSE_POTS_CTA_FROM_BUDGETING,
});
export const setDidClosePotsCTAfromTransactions = () => ({
    type: SET_DID_CLOSE_POTS_CTA_FROM_TRANSACTIONS,
});
export const setDidClosePotsCTAfromPots = () => ({
    type: SET_DID_CLOSE_POTS_CTA_FROM_POTS,
});
export const setFeedPersonalization = (feedPersonalization) => ({
    feedPersonalization,
    type: SET_FEED_PERSONALIZATION,
});
export const setDeviceSettingsKey = (key, value) => ({
    key,
    value,
    type: SET_DEVICE_SETTINGS_KEY,
});
