import Alert from 'utils/packages/Alert';
export const rollingWarningAlert = () => new Promise((resolve) => {
    Alert.alert('Slow down 🤪', 'Setting the budget/s again will reset the rolling amount. Are you sure of this?', [
        {
            text: 'Yes',
            onPress: () => {
                resolve(true);
            },
            style: 'destructive',
        },
        {
            text: 'No',
            style: 'cancel',
            onPress: () => {
                resolve(false);
            },
        },
    ], { cancelable: false });
});
