import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
const IconHistory = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="green" colorKey="white">
      <Path d="M23.625 11.978C23.6369 18.383 18.4134 23.6205 12.0084 23.625C9.24211 23.6269 6.70111 22.6626 4.70428 21.051C4.18505 20.6319 4.14623 19.8538 4.61808 19.3819L5.14622 18.8538C5.54977 18.4502 6.19402 18.4061 6.64111 18.7608C8.11228 19.9282 9.97411 20.625 12 20.625C16.7674 20.625 20.625 16.7667 20.625 12C20.625 7.23258 16.7667 3.375 12 3.375C9.71184 3.375 7.63364 4.26417 6.09056 5.71556L8.46966 8.09466C8.94216 8.56716 8.60752 9.375 7.93936 9.375H1.125C0.710766 9.375 0.375 9.03923 0.375 8.625V1.81064C0.375 1.14248 1.18284 0.807844 1.65534 1.2803L3.96966 3.59461C6.05667 1.60012 8.88525 0.375 12 0.375C18.413 0.375 23.6131 5.56781 23.625 11.978ZM15.1448 15.671L15.6052 15.079C15.9867 14.5885 15.8983 13.8817 15.4079 13.5003L13.5 12.0164V7.125C13.5 6.50367 12.9963 6 12.375 6H11.625C11.0037 6 10.5 6.50367 10.5 7.125V13.4836L13.566 15.8684C14.0565 16.2498 14.7633 16.1615 15.1448 15.671Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default IconHistory;
