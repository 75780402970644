import React, { useEffect, useRef, useState } from 'react';
import { Animated, Easing } from 'react-native';
export const ShakeContainer = ({ style, children, registerCallback, shakeOnMount = true, }) => {
    const shakeValue = useRef(new Animated.Value(0)).current;
    const [trigger, setTrigger] = useState(false);
    const ignoredShakeOnMount = useRef(shakeOnMount);
    registerCallback(() => {
        setTrigger(!trigger);
    });
    useEffect(() => {
        const animation = Animated.timing(shakeValue, {
            duration: 400,
            toValue: 3,
            easing: Easing.bounce,
            useNativeDriver: true,
        });
        if (ignoredShakeOnMount.current) {
            animation.start();
        }
        ignoredShakeOnMount.current = true;
        return () => {
            shakeValue.setValue(0);
            animation.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger]);
    const interpolated = shakeValue.interpolate({
        inputRange: [0, 0.5, 1, 1.5, 2, 2.5, 3],
        outputRange: [0, -15, 0, 15, 0, -15, 0],
    });
    const animShake = {
        transform: [{ translateX: interpolated }],
    };
    return <Animated.View style={[style, animShake]}>{children}</Animated.View>;
};
export default ShakeContainer;
