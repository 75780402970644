import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import Spacer from 'design-system/Spacer';
import { AppView } from 'design-system/AppView';
import { HEIGHT_PLUS_PADDING } from 'design-system/Button';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { muteErrorAndResultButThrows, useFetchOld } from 'hooks';
import { renameTransaction } from 'actions';
import { isWeb } from '../../../constants';
import EditMultipleList from '../components/multiple/EditMultipleList';
import EditMultipleSelectAllButton from '../components/multiple/EditMultipleSelectAllButton';
const EditMultipleTransactionsRenameScreen = withAnimatedNavigationHeader(({ navigation, route, scrollHandler }) => {
    const [isSelectedAll, setSelectedAll] = useState(false);
    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (<EditMultipleSelectAllButton isSelectedAll={isSelectedAll} onPress={() => {
                    setSelectedAll(!isSelectedAll);
                }}/>),
        });
    }, [isSelectedAll]);
    const [, , isFetching, fetch] = useFetchOld(muteErrorAndResultButThrows);
    const onPressEdit = useCallback(async (selected) => {
        try {
            const { customName } = route.params;
            const idArr = Object.keys(selected);
            if (isWeb) {
                await fetch(renameTransaction(idArr, customName));
            }
            else {
                fetch(renameTransaction(idArr, customName));
            }
            navigation.navigate('LearningScreen', {
                keyBack: route.params.keyBack,
                editData: {
                    editType: 'rename',
                    customName,
                },
                transactionsCount: idArr.length,
            });
        }
        catch {
            //
        }
    }, [route.params]);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const listContainerStyle = useMarginBottom('paddingBottom', HEIGHT_PLUS_PADDING, paddingHorizontalStyle);
    const ListHeader = useMemo(() => <Spacer h={16}/>, []);
    return (<AppView withFrame={false}>
          <EditMultipleList scrollHandler={scrollHandler} isFetchingEdit={isFetching} transaction={route.params.transaction} isSelectedAll={isSelectedAll} listContainerStyle={listContainerStyle} onPressEdit={onPressEdit} ListHeader={ListHeader}/>
        </AppView>);
}, ({ colors }) => ({
    title: 'Rename',
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
export default EditMultipleTransactionsRenameScreen;
