import React, { useCallback } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import RowAccount from 'features/accounts/components/RowAccount';
import { selectActiveAccounts } from 'reducers/selectors';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import { useAppSelector } from 'store/hooks';
import { isDesktopOrWeb } from '../../../../constants';
const strings = {
    empty: 'There are no accounts connected to your transactions.',
};
const KeyboardAccounts = ({ onSelect, contentContainerStyle }) => {
    const data = useAppSelector(selectActiveAccounts);
    const styles = useStyles(styleSet);
    const renderItem = useCallback(({ item }) => (<RowAccount style={styles.row} data={item} onPress={() => {
            onSelect('KeyboardAccounts', {
                ...item,
                badgeText: item.name,
                filterType: 'ACCOUNT',
            });
        }} onCard={isDesktopOrWeb} showHidden/>), [onSelect, styles.row]);
    return (<FlatList style={styles.flatList} contentContainerStyle={[styles.contentContainerStyle, contentContainerStyle]} data={data} renderItem={renderItem} keyExtractor={(item) => item.name + item.id} ListEmptyComponent={EmptyComponent}/>);
};
const EmptyComponent = () => (<View style={staticStyles.emptyContainer}>
    <Text Text-16 centered>
      {strings.empty}
    </Text>
  </View>);
const staticStyles = StyleSheet.create({
    emptyContainer: {
        paddingTop: 40,
        paddingHorizontal: 40,
    },
});
const styleSet = createStyleSheets((colors) => ({
    contentContainerStyle: {
        paddingBottom: isDesktopOrWeb ? 16 : 50,
    },
    flatList: {
        backgroundColor: colors.background.light,
        ...(isDesktopOrWeb && {
            padding: 8,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            backgroundColor: colors.cards.onDark,
        }),
    },
    row: {
        paddingHorizontal: rem(16),
    },
}));
export default KeyboardAccounts;
