import { getIapLibSubscriptions } from 'features/premium/iap';
import { Platform } from 'react-native';
import { call, put } from 'typed-redux-saga/macro';
import { isNotNullable } from 'utils/isNotNullable';
import Sentry from 'utils/sentry';
import { LOAD_PROMO_IAP_SUCCESS } from '../actions';
import { getIapSubscriptionFromProducts, getOfferingForPlatform, } from '../entities';
export function* loadPromoIap(promotions) {
    const platformOfferings = promotions
        .map((promotion) => getOfferingForPlatform(promotion, Platform.OS))
        .filter(isNotNullable);
    if (platformOfferings.length === 0) {
        return;
    }
    const iapLibSubscriptions = yield* call(getIapLibSubscriptions, platformOfferings.map((p) => p.productId));
    if (iapLibSubscriptions.length === 0) {
        Sentry.logError({
            filename: 'loadIapForPromotion.ts',
            message: 'Failed to load in-app purchase for promotion',
            data: platformOfferings,
        });
        return;
    }
    const subscriptions = getIapSubscriptionFromProducts(platformOfferings, iapLibSubscriptions);
    yield* put({
        type: LOAD_PROMO_IAP_SUCCESS,
        payload: {
            subscriptions,
        },
    });
}
