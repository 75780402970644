import React from 'react';
import { Calendar as RNCalendar } from 'react-native-calendars';
import { View } from 'react-native';
import { rem } from 'design-system/values';
import { CalendarHeader } from 'design-system/calendar/CalendarHeader';
import { Day } from 'design-system/calendar/Day';
import Spacer from 'design-system/Spacer';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import ContentHeader from './ContentHeader';
import useIsCompactCalendar from './useIsCompactCalendar';
import { isWeb } from '../../constants';
export const Calendar = React.memo(({ style, hideDays, title = '', reset, blackMonthText, headerStyle, ...props }) => {
    const styles = useStyles(styleSet);
    const compact = useIsCompactCalendar();
    return (<View style={styles.calendarContainer}>
          <RNCalendar style={[styles.container, compact && styles.compact, style]} customHeader={(headerProps) => (<>
                {reset && (<>
                    <ContentHeader title={title} handleReset={reset}/>
                    <Spacer h={compact ? 8 : 16}/>
                  </>)}
                <CalendarHeader hideDays={hideDays} headerStyle={headerStyle} blackMonthText={blackMonthText} {...headerProps}/>
              </>)} hideExtraDays theme={{
            calendarBackground: 'transparent',
        }} dayComponent={({ date, state }) => (<Day key={date?.dateString} date={date} isToday={state === 'today'}/>)} {...props}/>
        </View>);
});
const styleSet = createStyleSheets((colors) => ({
    container: {
        borderRadius: rem(16),
        paddingBottom: rem(10),
        paddingLeft: rem(16),
        paddingRight: rem(16),
        paddingTop: rem(16),
        ...(!isWeb && {
            maxWidth: rem(390),
            minWidth: rem(360),
        }),
        backgroundColor: colors.cards.onDark,
    },
    compact: {
        paddingTop: rem(8),
        paddingBottom: rem(5),
        paddingLeft: rem(10),
        paddingRight: rem(10),
    },
    calendarContainer: {
        overflow: 'hidden',
        marginLeft: rem(16),
        marginRight: rem(16),
        borderRadius: rem(16),
    },
}));
