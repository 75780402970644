import React, { memo } from 'react';
import { TouchableOpacity, View } from 'react-native';
import FastImage from 'utils/packages/rnFastImage';
import { IconCloseSquircle, rem } from 'design-system';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableBounce from 'utils/packages/TouchableBounce';
const TransactionReceiptBox = ({ index, data, onPressDelete, onPressImage, }) => {
    const pressImage = () => {
        onPressImage?.(index);
    };
    const pressDelete = () => {
        if (!data.isUploading) {
            onPressDelete?.(data.id);
        }
    };
    const styles = useStyles(styleSet);
    const colors = useColors();
    return (<View style={styles.container}>
      <TouchableBounce onPress={pressImage} disabled={!onPressImage}>
        <FastImage style={styles.addBox} source={{ uri: data.url }} resizeMode={'cover'}/>
      </TouchableBounce>
      {!!onPressDelete && (<TouchableOpacity style={styles.deleteBadge} onPress={pressDelete}>
          <IconCloseSquircle width={rem(24)} height={rem(24)} crossColor={colors.elements.white}/>
        </TouchableOpacity>)}
    </View>);
};
export const styleSet = createStyleSheets((colors) => ({
    container: {
        marginEnd: rem(8),
    },
    addBox: {
        width: rem(64),
        height: rem(64),
        alignItems: 'center',
        borderRadius: rem(12),
        justifyContent: 'center',
        backgroundColor: colors.cards.transparentOnDark,
    },
    hoverStyle: {
        backgroundColor: colors.control.hoverOnLight,
    },
    deleteBadge: {
        position: 'absolute',
        top: rem(-4),
        right: rem(-4),
        // hit slop
        paddingBottom: 10,
        paddingLeft: 10,
    },
}));
export default memo(TransactionReceiptBox);
