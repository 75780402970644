import React, { useCallback } from 'react';
import NotificationBanner from 'design-system/NotificationBanner';
import { selectIsFitForDebtConsolidation, selectIsGBUser, } from 'reducers/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setDeviceSettingsKey } from 'actions/deviceSettings';
import { useMainStackNavigation } from 'utils/types/navigationV6';
const ConsolidateDebt = () => {
    const dispatch = useAppDispatch();
    const isGBUser = useAppSelector(selectIsGBUser);
    const navigation = useMainStackNavigation();
    const onCancel = useCallback(() => {
        dispatch(setDeviceSettingsKey('didCloseConsolidateDebtBanner', true));
    }, []);
    const handleContinue = useCallback(() => {
        const flow = isGBUser ? 'monevo' : 'monevo_us';
        navigation.navigate('LoanAmount', { flow, type: 'DEBT' });
    }, [isGBUser]);
    return (<NotificationBanner noMarginBottom noMarginTop={false} onCancel={onCancel} title="Consolidate debt" cancelButtonTitle="Dismiss" positiveButtonTitle="Continue" onPositiveBtnPress={handleContinue} description="See if you can consolidate your credit card debt with a loan"/>);
};
const ConsolidateDebtWrapper = () => {
    const didOpen = useAppSelector((state) => state.deviceSettings.didCloseConsolidateDebtBanner);
    const isFitForDebtConsolidation = useAppSelector(selectIsFitForDebtConsolidation);
    const isGBUser = useAppSelector(selectIsGBUser);
    if (!isGBUser || !isFitForDebtConsolidation || didOpen) {
        return null;
    }
    return <ConsolidateDebt />;
};
export default ConsolidateDebtWrapper;
