import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { rem } from 'design-system/values';
import { AppView } from 'design-system/AppView';
import { HEIGHT_PLUS_PADDING } from 'design-system/Button';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import { muteErrorAndResult, useFetchOld } from 'hooks';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { editTagsTransactions } from 'actions';
import { isWeb } from '../../../constants';
import TagItem from '../components/tags/TagItem';
import EditMultipleList from '../components/multiple/EditMultipleList';
import EditMultipleSelectAllButton from '../components/multiple/EditMultipleSelectAllButton';
const EditMultipleTransactionsTagsScreen = withAnimatedNavigationHeader(({ navigation, route, scrollHandler }) => {
    const { tags } = route.params;
    const [, , isFetching, fetch] = useFetchOld(muteErrorAndResult);
    const [isSelectedAll, setSelectedAll] = useState(false);
    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (<EditMultipleSelectAllButton isSelectedAll={isSelectedAll} onPress={() => {
                    setSelectedAll(!isSelectedAll);
                }}/>),
        });
    }, [isSelectedAll]);
    const ListHeader = useMemo(() => (<View style={styles.tagsContainer}>
            {tags.map((item, index) => (<TagItem onDark index={index} title={item} selected={false} key={String(index)}/>))}
          </View>), [tags]);
    const onPressEdit = useCallback(async (selected) => {
        const txnIds = Object.keys(selected);
        if (isWeb) {
            await fetch(editTagsTransactions(txnIds, tags));
        }
        else {
            fetch(editTagsTransactions(txnIds, tags));
        }
        navigation.navigate('LearningScreen', {
            keyBack: route.params.keyBack,
            editData: {
                editType: 'tags',
                tags,
            },
            transactionsCount: txnIds.length,
        });
    }, [route.params.keyBack, tags]);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const listContainerStyle = useMarginBottom('paddingBottom', HEIGHT_PLUS_PADDING, paddingHorizontalStyle);
    return (<AppView withFrame={false}>
          <EditMultipleList ListHeader={ListHeader} onPressEdit={onPressEdit} isFetchingEdit={isFetching} scrollHandler={scrollHandler} isSelectedAll={isSelectedAll} transaction={route.params.transaction} listContainerStyle={listContainerStyle}/>
        </AppView>);
}, ({ colors }) => ({
    title: 'Add tags',
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styles = StyleSheet.create({
    tagsContainer: {
        flexWrap: 'wrap',
        padding: rem(16),
        flexDirection: 'row',
    },
});
export default EditMultipleTransactionsTagsScreen;
