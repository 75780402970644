import { Dimensions, PixelRatio } from 'react-native';
import normalize from 'common/Normalize';
import Phone from 'utils/dimensions';
import { isWeb } from '../constants';
const { width: screenWidth, height: screenHeight } = Dimensions.get('window');
// Rooet Em size
export const rem = (value) => {
    if (isWeb) {
        return value;
    }
    return PixelRatio.roundToNearestPixel(normalize(value / 1.25));
};
export const SIDEBAR_WIDTH = rem(180);
export const SIDEBAR_WIDTH_LANDSCAPE = rem(180);
export const PAD_SIDE_WIDTH_RATIO = 0.35;
export const PAD_SIDE_WIDTH_RATIO_LANDSCAPE = 0.5;
export const PAD_TAB_SIDE_WIDTH_RATIO = 0.2;
export const PAD_TAB_SIDE_WIDTH_RATIO_LANDSCAPE = 0.4;
const ratio = screenHeight / screenWidth;
/**
 * A conditional function that can be used to render elements based on screen size,
 * mostly limited by height, and fine-tuned for iOS.
 * For example, on a screen there is a caption that is not so important and can be omitted if space is not enough.
 * Usually it happens for phones with big margins (like iPhone SE).
 *
 * Use it like this: if (a(false, true)) { render something that fits everywhere except smallest screens like iPhone 5 }
 * Or like this: marginTop: a(rem(20), rem(30), rem(30), rem(50), rem(50)) - to render bigger margin for an element inside a scrollview, for example, where you cannot set flex: 1
 *
 * @param {type} T - just any type
 * @param {T} small - what to return for iPhone 5 and short screens
 * @param {T} regular - what to return for iPhone 6 and most androids, or to all other screens if only 2 params are set
 * @param {T} big - what to return for iPhone 6 Plus or iPhone XS Max if 5th param was not set
 * @param {T} X - what to return specifically for iPhone X and alike, and iPhone 12 mini as well
 * @param {T} XMax - what to return specifically for iPhone XS Max and alike
 */
export function a(small, regular, big, X, XMax) {
    switch (true) {
        case screenHeight === 896 ||
            screenHeight === 926 ||
            screenHeight === 932 ||
            screenHeight === 956 /* 14/15/16 Pro Max */:
            {
                if (XMax !== undefined) {
                    return XMax;
                }
                return big !== undefined ? big : regular;
            }
        case screenHeight === 812 ||
            screenHeight === 844 ||
            screenHeight === 852 ||
            screenHeight === 874 /* 14/15/16 Pro */:
            return X !== undefined ? X : regular;
        case screenWidth <= 360 || ratio < 1.66:
            return small;
        case screenWidth < 414:
            return regular;
        default:
            return big !== undefined ? big : regular;
    }
}
export const hitSlop = {
    top: rem(16),
    bottom: rem(16),
    left: rem(12),
    right: rem(12),
};
// Small hitslop
export const hitSlopS = {
    top: rem(10),
    bottom: rem(10),
    left: rem(10),
    right: rem(10),
};
export const SCROLL_INDICATOR_INSETS = { right: 1 };
// todo this comes from the old pay feature, we can probably refactor and remove
const correction = Phone.isTallScreen() ? 1 : 0.8;
export const correct = (size) => rem(size) * correction;
