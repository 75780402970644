import React, { memo, useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { rem } from 'design-system/values';
import CustomContent from './CustomContent';
import WeeklyContent from './WeeklyContent';
import YearlyContent from './YearlyContent';
import SegmentControl from './SegmentControl';
import MonthlyContent from './MonthlyContent';
import QuarterlyContent from './QuarterlyContent';
import { AnalyticsFilterContext } from './context';
const Controller = () => {
    const { selectedTabIndex, setSelectedTabIndex } = useContext(AnalyticsFilterContext);
    const renderContent = () => {
        switch (selectedTabIndex) {
            case 0:
                return <WeeklyContent />;
            case 1:
                return <MonthlyContent />;
            case 2:
                return <QuarterlyContent />;
            case 3:
                return <YearlyContent />;
            case 4:
                return <CustomContent />;
            default:
                return null;
        }
    };
    return (<View style={styles.container}>
      <View style={styles.segmentControl}>
        <SegmentControl selectedTabIndex={selectedTabIndex} setSelectedTabIndex={setSelectedTabIndex}/>
      </View>
      <View>{renderContent()}</View>
    </View>);
};
export default memo(Controller);
const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
    },
    segmentControl: {
        paddingTop: rem(16),
    },
});
