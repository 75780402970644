import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
export const IconCamera = () => {
    const width = rem(12);
    const height = rem(10);
    const colors = useColors();
    return (<Svg width={width} height={height} fill="none" viewBox="0 0 12 10">
      <Path d="M7.547 5.172a1.797 1.797 0 1 1-3.594 0 1.797 1.797 0 0 1 3.594 0Z" fill={colors.elements.white}/>
      <Path d="M1.438 1.938A1.438 1.438 0 0 0 0 3.375v4.313a1.438 1.438 0 0 0 1.438 1.437h8.624A1.438 1.438 0 0 0 11.5 7.687V3.375a1.438 1.438 0 0 0-1.438-1.438H9.22c-.381 0-.747-.151-1.016-.42L7.609.92A1.438 1.438 0 0 0 6.592.5H4.908C4.526.5 4.16.652 3.89.921l-.595.595c-.27.27-.635.421-1.016.421h-.842Zm.359 1.437a.36.36 0 1 1 0-.719.36.36 0 0 1 0 .719Zm6.469 1.797a2.516 2.516 0 1 1-5.032 0 2.516 2.516 0 0 1 5.032 0Z" fill={colors.elements.white}/>
    </Svg>);
};
