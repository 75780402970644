import React, { forwardRef, useCallback } from 'react';
import { Pressable } from 'react-native';
const TouchableBounce = forwardRef(({ style, onPress, children, hoverStyle, ...props }, ref) => {
    const [isHovering, setIsHovering] = React.useState(false);
    const handleEvent = useCallback((event) => () => {
        setIsHovering(event === 'onHoverIn');
    }, []);
    return (<Pressable ref={ref} onPress={onPress} onHoverIn={handleEvent('onHoverIn')} onHoverOut={handleEvent('onHoverOut')} style={[style, isHovering && hoverStyle]} {...props}>
      {children}
    </Pressable>);
});
export default React.memo(TouchableBounce);
