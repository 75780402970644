import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import isNil from 'lodash/isNil';
import { selectColorSchemePreference } from 'reducers/selectors';
import overrideColorScheme from 'react-native-override-color-scheme';
import Modal from 'design-system/Modal';
import Text from 'design-system/Text';
import { BadgeBase, rem, SettingsItem } from 'design-system';
import { IconContrast, IconSun, IconMoon, IconSuccess, } from 'design-system/icons';
import { setUserColorScheme } from 'actions';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import { SettingsItemIcon } from 'design-system/SettingsItemIcon';
import { useAppDispatch, useAppSelector } from 'store/hooks';
const strings = {
    title: 'Appearance',
    system: {
        title: 'System',
        description: 'Using default system settings',
    },
    dark: {
        title: 'Dark',
    },
    light: {
        title: 'Light',
    },
};
const switchModesMap = {
    dark: 'dark',
    light: 'light',
    system: undefined,
};
export const AppearanceModal = {
    show: (colors) => {
        Modal.show(<AppearanceContent />, {
            containerStyle: {
                ...staticStyles.modal,
                backgroundColor: colors.cards.onDark,
            },
        });
    },
    hide: () => {
        Modal.hide();
    },
};
const IconWrapper = ({ children, checked, }) => {
    const styles = useStyles(styleSet);
    return (<View style={styles.icon}>
      <SettingsItemIcon>{children}</SettingsItemIcon>
      {checked && (<BadgeBase>
          <IconSuccess />
        </BadgeBase>)}
    </View>);
};
const AppearanceContent = () => {
    const styles = useStyles(styleSet);
    const userColorScheme = useAppSelector(selectColorSchemePreference);
    const dispatch = useAppDispatch();
    const handlePress = useCallback((key) => {
        dispatch(setUserColorScheme(switchModesMap[key]));
        overrideColorScheme.setScheme(switchModesMap[key]);
        Modal.hide();
    }, []);
    return (<>
      <View style={styles.textContainer}>
        <Text Text-20 centered>
          {strings.title}
        </Text>
      </View>
      <SettingsItem title={strings.system.title} description={strings.system.description} renderIcon={() => (<IconWrapper checked={isNil(userColorScheme)}>
            <IconContrast />
          </IconWrapper>)} onPress={() => handlePress('system')}/>
      <SettingsItem title={strings.dark.title} renderIcon={() => (<IconWrapper checked={userColorScheme === 'dark'}>
            <IconMoon />
          </IconWrapper>)} onPress={() => handlePress('dark')}/>
      <SettingsItem title={strings.light.title} renderIcon={() => (<IconWrapper checked={userColorScheme === 'light'}>
            <IconSun />
          </IconWrapper>)} onPress={() => handlePress('light')}/>
    </>);
};
const staticStyles = StyleSheet.create({
    modal: {
        paddingHorizontal: 0,
        paddingTop: rem(40),
    },
});
const styleSet = createStyleSheets(() => ({
    icon: {
        marginRight: rem(16),
    },
    textContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: rem(8),
        maxWidth: rem(240),
        alignSelf: 'center',
    },
}));
export default AppearanceContent;
