import React, { memo } from 'react';
import { View } from 'react-native';
import { Button, Spacer, rem } from 'design-system';
import Amount from 'design-system/Amount';
import Text, { lineHeight } from 'design-system/Text';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { isWeb } from '../../../../constants';
const TransactionHeaderText = ({ transaction, style, showFirstUpgradeButton, showRenameUpgradeButton, useCustomName, onPressUpgrade, }) => {
    const { amount, counterpartName, customName, currency, nativeAmount } = transaction;
    let firstBalance = amount;
    let firstCurrency = currency;
    let secondBalance = null;
    let secondCurreny = null;
    if (nativeAmount) {
        firstBalance = nativeAmount.amount;
        firstCurrency = nativeAmount.currency;
        secondBalance = amount;
        secondCurreny = currency;
    }
    const nameToDisplay = useCustomName
        ? customName || counterpartName
        : counterpartName;
    const styles = useStyles(styleSet);
    const { width } = useAppFrameDimensions(isWeb);
    return (<View style={[styles.transactionContent, style]}>
      {showFirstUpgradeButton ? (<Button hug square medium brandReversed onPress={onPressUpgrade} title="Upgrade"/>) : (<>
          <Amount Numbers-20 value={{ value: firstBalance, currency: firstCurrency }} showSign/>
          {nativeAmount && secondBalance ? (<Amount Numbers-14 value={{
                    value: secondBalance,
                    currency: secondCurreny,
                }} showSign/>) : null}
        </>)}
      <Text TextThin-14 Secondary numberOfLines={1} ellipsizeMode="tail" style={[styles.transactionTitle, { maxWidth: width }]}>
        {nameToDisplay}
      </Text>

      {showRenameUpgradeButton && (<>
          <Spacer h={8}/>
          <Button hug square medium brandReversed onPress={onPressUpgrade} title="Upgrade to see custom name"/>
        </>)}
    </View>);
};
const amountTitleSpacing = 4;
const styleSet = createStyleSheets(() => ({
    transactionContent: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    transactionTitle: {
        marginTop: rem(amountTitleSpacing),
        marginHorizontal: rem(16),
    },
}));
export const useTransactionHeaderTextHeight = (transaction, showRenameUpgradeButton, showFirstUpgradeButton) => {
    const { top } = useSafeAreaInsets();
    let extraHeight = 0;
    if (showFirstUpgradeButton) {
        extraHeight += rem(36);
    }
    else {
        extraHeight += rem(lineHeight.Numbers_20);
        if (transaction !== undefined &&
            transaction.nativeAmount &&
            transaction.amount) {
            extraHeight += rem(lineHeight.Numbers_14);
        }
    }
    if (showRenameUpgradeButton) {
        extraHeight += rem(48);
    }
    const viewHeight = extraHeight + rem(lineHeight.TextThin_14) + rem(amountTitleSpacing);
    return [viewHeight, viewHeight + top];
};
export default memo(TransactionHeaderText);
