const form = (params) => {
    const formBody = [];
    Object.entries(params).forEach(([key, value]) => {
        const encodedKey = encodeURIComponent(key);
        const encodedValue = encodeURIComponent(value);
        formBody.push(`${encodedKey}=${encodedValue}`);
    });
    return formBody.join('&');
};
export default form;
