import { FlashList } from '@shopify/flash-list';
import emoji from 'emoji-datasource';
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { View } from 'react-native';
import { EMOJI_CATEGORIES, } from 'design-system/emojiPicker/types';
import { rem } from 'design-system/values';
import EmojiItem from 'design-system/emojiPicker/EmojiItem';
import CategoryContainer from 'design-system/emojiPicker/CategoryContainer';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { isWeb } from '../../../constants';
const charFromUtf16 = (utf16) => String.fromCodePoint(...utf16.split('-').map((u) => `0x${u}`));
export const charFromEmojiObject = (obj) => charFromUtf16(obj.unified);
const itemWidth = rem(44 + 16);
const keyExtractor = (emoji) => emoji[0].unified;
export const webPickerWidth = rem(395);
const EmojiSelector = ({ onSelected, selected, categoryListStyle, }) => {
    const styles = useStyles(styleSet);
    const [selectedCategory, setSelectedCategory] = useState(EMOJI_CATEGORIES[0]);
    const { width: frameWidth, height } = useAppFrameDimensions();
    const width = isWeb ? webPickerWidth : frameWidth;
    const columnWidth = Math.round(width / 5);
    const rows = height > 900 || isWeb ? 4 : 3;
    const columns = useMemo(() => {
        const filteredEmojis = emoji
            .filter((e) => EMOJI_CATEGORIES.includes(e.category))
            .sort((a, b) => a.sort_order - b.sort_order);
        return filteredEmojis.reduce((prev, curr) => {
            const lastColumn = prev[prev.length - 1];
            if (!prev.length ||
                lastColumn[lastColumn.length - 1].category !== curr.category ||
                lastColumn?.length === rows) {
                return [...prev, [curr]];
            }
            return [...prev.slice(0, prev.length - 1), [...lastColumn, curr]];
        }, []);
    }, [rows]);
    const renderItem = useCallback(({ item }) => (<View style={[{ width: columnWidth }, styles.emojiList]}>
        {item.map((emoji) => {
            const char = charFromEmojiObject(emoji);
            return (<EmojiItem columnWidth={columnWidth} key={emoji.sort_order} item={emoji} onSelected={() => {
                    onSelected(char);
                }} isSelected={char === selected}/>);
        })}
      </View>), [columnWidth, onSelected, selected, styles.emojiList]);
    const onViewableItemsChanged = useCallback((info) => {
        const first = info.viewableItems[info.viewableItems.length - 1]?.item[0];
        if (!first)
            return;
        setSelectedCategory(first.category);
    }, []);
    const flatListRef = useRef(null);
    const isMomentumScrolling = useRef(false);
    const onMomentumScrollBegin = useCallback(() => {
        isMomentumScrolling.current = true;
    }, []);
    const onMomentumScrollEnd = useCallback(() => {
        isMomentumScrolling.current = false;
    }, []);
    const colors = useColors();
    const estimatedListSize = useMemo(() => ({
        width,
        height: rows * itemWidth + rem(16),
    }), [rows, width]);
    return (<View>
      <View style={styles.container}>
        <FlashList horizontal data={columns} ref={flatListRef} renderItem={renderItem} keyExtractor={keyExtractor} estimatedItemSize={columnWidth} estimatedListSize={estimatedListSize} showsHorizontalScrollIndicator={false} onMomentumScrollEnd={onMomentumScrollEnd} onMomentumScrollBegin={onMomentumScrollBegin} onViewableItemsChanged={onViewableItemsChanged} extraData={colors.cards.onLight + selected} keyboardShouldPersistTaps="handled"/>
      </View>
      <View style={[styles.categoryList, categoryListStyle]}>
        <CategoryContainer width={width} emojisArr={columns} itemSize={columnWidth} flatListRef={flatListRef} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} isMomentumScrolling={isMomentumScrolling}/>
      </View>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    categoryList: {
        marginTop: rem(16),
        flexDirection: 'row',
        borderRadius: rem(12),
        marginBottom: rem(32),
        paddingVertical: rem(8),
        marginHorizontal: rem(16),
        backgroundColor: isWeb ? colors.cards.onLight : colors.cards.onDark,
        ...colors.shadows.cardMedium,
    },
    emojiList: {
        paddingBottom: rem(16),
    },
    emojiItem: {
        width: rem(44),
        height: rem(44),
        borderRadius: rem(22),
        backgroundColor: colors.cards.onLight,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        overflow: 'hidden',
        marginVertical: rem(8),
    },
    container: {
        borderTopLeftRadius: rem(16),
        borderTopRightRadius: rem(16),
        // backgroundColor: colors.background.light,
    },
    emojiText: {
        fontSize: rem(24),
        color: colors.text.primary,
        fontFamily: 'System',
    },
}));
export default memo(EmojiSelector);
