import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
import useColors from 'hooks/useColors';
export const IconSuccess = ({ color, ...props }) => {
    const colors = useColors();
    const id = useId();
    const fillColor = color ?? colors.elements.onLight;
    const strokeColor = color ?? colors.elements.onLight;
    return (<IconWithGradient id={id} {...props} defaultGradientKey="lightPink">
      <Path d="M21.66 14.542a8.198 8.198 0 0 1-7.118 7.118 20.497 20.497 0 0 1-5.084 0 8.198 8.198 0 0 1-7.118-7.118 20.495 20.495 0 0 1 0-5.084A8.198 8.198 0 0 1 9.458 2.34a20.495 20.495 0 0 1 5.084 0 8.198 8.198 0 0 1 7.118 7.118 20.497 20.497 0 0 1 0 5.084Z" fill={`url(#gradient_${id})`}/>
      <Path fillRule="evenodd" clipRule="evenodd" d="M9.191.175a22.645 22.645 0 0 1 5.618 0 10.384 10.384 0 0 1 9.016 9.016 22.641 22.641 0 0 1 0 5.618 10.384 10.384 0 0 1-9.016 9.016 22.641 22.641 0 0 1-5.618 0A10.384 10.384 0 0 1 .175 14.81a22.645 22.645 0 0 1 0-5.618A10.385 10.385 0 0 1 9.19.175Zm5.344 21.457a8.174 8.174 0 0 0 7.096-7.097c.21-1.684.21-3.386 0-5.07a8.174 8.174 0 0 0-7.096-7.096 20.434 20.434 0 0 0-5.07 0A8.174 8.174 0 0 0 2.37 9.465a20.434 20.434 0 0 0 0 5.07 8.174 8.174 0 0 0 7.096 7.096c1.684.21 3.386.21 5.07 0Z" fill={fillColor}/>
      <Path d="m9.554 15.306-3.053-3.053a1.262 1.262 0 0 1 1.785-1.785l1.819 1.819a.484.484 0 0 0 .683 0l4.926-4.926A1.262 1.262 0 0 1 17.5 9.146l-6.16 6.16a1.262 1.262 0 0 1-1.785 0Z" fill={fillColor} stroke={strokeColor} strokeWidth={0.263}/>
    </IconWithGradient>);
};
