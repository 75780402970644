import React from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import IconArrow from 'design-system/icons/IconArrow';
const OverviewText = ({ arrowDirection, title, style }) => (<View style={[styles.label, style]}>
    <Text Text-16>{title}</Text>
    <IconArrow width={8} height={8} direction={arrowDirection} style={styles.arrowIcon}/>
  </View>);
const styles = StyleSheet.create({
    label: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    arrowIcon: {
        marginStart: rem(16),
    },
});
export default OverviewText;
