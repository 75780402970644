import { IconApple, IconFacebook } from 'design-system/icons';
import IconGoogle from 'design-system/icons/IconGoogle';
import { SettingsItem } from 'design-system/SettingsItem';
import { SettingsSection } from 'design-system/SettingsSection';
import { useSocialLogin } from 'features/settings/hooks/useSocialLogin';
import React from 'react';
const strings = {
    socialLogin: 'Social Sign in',
    facebook: 'Facebook',
    connectedFacebook: 'Connected Facebook account',
    apple: 'Apple',
    connectApple: 'Connect Apple account',
    connectedApple: 'Connected Apple account',
    google: 'Google',
    connectGoogle: 'Connect Google account',
    connectedGoogle: 'Connected Google account',
};
export const SocialLoginSection = () => {
    const { hasApple, hasFacebook, hasGoogle, onOpenSignInWithApple, onOpenSignInWithGoogle, isAppleSupported, } = useSocialLogin();
    return (<SettingsSection name={strings.socialLogin}>
      {hasFacebook && (<SettingsItem icon={<IconFacebook />} title={strings.facebook} description={strings.connectedFacebook} disabled/>)}
      {(isAppleSupported || hasApple) && (<SettingsItem icon={<IconApple />} title={strings.apple} onPress={onOpenSignInWithApple} description={hasApple ? strings.connectedApple : strings.connectApple}/>)}
      <SettingsItem icon={<IconGoogle />} title={strings.google} onPress={onOpenSignInWithGoogle} description={hasGoogle ? strings.connectedGoogle : strings.connectGoogle}/>
    </SettingsSection>);
};
