import React, { memo, useCallback, useRef, useState } from 'react';
import { View, TextInput, TouchableWithoutFeedback, ScrollView, } from 'react-native';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
import createStyleSheets from 'utils/createStyleSheets';
import { useAutoFocusInput } from 'hooks/useAutoFocusInput';
import TagItem from './TagItem';
import { isWeb } from '../../../../constants';
const TagsList = ({ tags, text, onSubmitEditing, onChange, onChangeText, }) => {
    const colors = useColors();
    const scrollView = useRef(null);
    const onContentSizeChange = useCallback(() => {
        scrollView.current?.scrollToEnd({ animated: true });
    }, []);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isTextInputFocused, setIsTextInputFocused] = useState(false);
    const handleFocus = useCallback(() => {
        setIsTextInputFocused(true);
    }, []);
    const handleBlur = useCallback(() => {
        setIsTextInputFocused(false);
    }, []);
    const backSpace = () => {
        if (selectedIndex === null) {
            setSelectedIndex(tags.length - 1);
            return;
        }
        removeIndex();
    };
    const onKeyPress = ({ nativeEvent, }) => {
        if (text.length !== 0 || nativeEvent.key !== 'Backspace') {
            return;
        }
        backSpace();
    };
    const submitEditing = () => {
        onSubmitEditing();
    };
    const removeIndex = () => {
        const newTags = [...tags];
        newTags.splice(selectedIndex || 0, 1);
        onChange(newTags);
        setSelectedIndex(null);
    };
    const changeText = (text) => {
        if (selectedIndex !== null) {
            setSelectedIndex(null);
        }
        onChangeText(text);
    };
    const onTagPressed = (index) => {
        setSelectedIndex(index !== selectedIndex ? index : null);
        if (isWeb && index !== selectedIndex) {
            textInputRef.current?.focus();
        }
    };
    const tagsToDisplay = tags && tags.length
        ? tags.map((tag, index) => (<TagItem title={tag} index={index} onCard={isWeb} onDark={!isWeb} key={`${index + 0}`} onPress={onTagPressed} selected={index === selectedIndex}/>))
        : [];
    const textInputRef = useRef(null);
    useAutoFocusInput(textInputRef);
    const focusInput = useCallback(() => {
        textInputRef.current?.focus();
    }, []);
    const styles = useStyles(styleSet);
    return (<View style={styles.container}>
      <TouchableWithoutFeedback onPress={focusInput}>
        <View style={[
            styles.cardContainer,
            isTextInputFocused && styles.focusedInput,
        ]}>
          <ScrollView keyboardShouldPersistTaps="always" ref={scrollView} onContentSizeChange={onContentSizeChange} scrollEventThrottle={8} contentContainerStyle={styles.contentContainer}>
            <View style={styles.labelsWrapper}>
              {tagsToDisplay}
              <View style={styles.inputContainer}>
                <TextInput value={text} ref={textInputRef} autoCorrect={false} blurOnSubmit={false} autoCapitalize="none" onKeyPress={onKeyPress} style={styles.textInput} allowFontScaling={false} onChangeText={changeText} onSubmitEditing={submitEditing} underlineColorAndroid="transparent" selectionColor={colors.text.selection} placeholderTextColor={colors.text.secondary} {...(isWeb && {
        onBlur: handleBlur,
        onFocus: handleFocus,
    })}/>
              </View>
            </View>
          </ScrollView>
        </View>
      </TouchableWithoutFeedback>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
        flexDirection: 'row',
        ...(isWeb
            ? {
                paddingTop: rem(72),
                paddingBottom: rem(16),
            }
            : {
                paddingTop: rem(16),
                paddingBottom: rem(24),
            }),
    },
    contentContainer: {
        paddingVertical: rem(11),
        paddingHorizontal: rem(14),
    },
    cardContainer: {
        flex: 1,
        ...(isWeb && {
            borderWidth: 1,
            cursor: 'text',
            borderRadius: rem(8),
            borderColor: colors.borders.divider,
            backgroundColor: colors.cards.onDark,
        }),
    },
    labelsWrapper: {
        flex: 1,
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    focusedInput: {
        ...(isWeb && {
            borderColor: colors.elements.brand,
        }),
    },
    inputContainer: {
        marginLeft: rem(8),
    },
    textInput: {
        width: 130,
        height: rem(48),
        fontSize: rem(14),
        lineHeight: rem(14) * 1.1,
        color: colors.text.primary,
        fontFamily: 'Montserrat-Regular',
        ...(isWeb && {
            height: rem(38),
            marginVertical: rem(5),
            caretColor: colors.text.selection,
        }),
    },
}));
export default memo(TagsList);
