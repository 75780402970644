import React, { memo, useRef } from 'react';
import { format } from 'date-fns';
import { View, StyleSheet } from 'react-native';
import { useAppSelector } from 'store/hooks';
import { useAutoFocusInput } from 'hooks/useAutoFocusInput';
import { isWeb } from '../../constants';
import Date from './DateInputField';
const DateInput = ({ state, onChange, onSubmitEditing, showYearError, onDark, }) => {
    const dayRef = useRef(null);
    const monthRef = useRef(null);
    const yearRef = useRef(null);
    const guessedHomeCountry = useAppSelector((store) => store.user.user.guessedHomeCountry);
    const monthFirst = guessedHomeCountry === 'US' || guessedHomeCountry === 'CA';
    const onChangeDay = (data) => {
        const parsedText = data.replace(/[^0-9]/g, '');
        const newState = { ...state, day: parsedText };
        onChange(newState);
        if (parsedText.length === 2) {
            if (monthFirst) {
                yearRef.current?.focus();
            }
            else {
                monthRef.current?.focus();
            }
        }
    };
    const onChangeMonth = (data) => {
        const parsedText = data.replace(/[^0-9]/g, '');
        const newState = { ...state, month: parsedText };
        onChange(newState);
        if (parsedText.length === 2) {
            if (monthFirst) {
                dayRef.current?.focus();
            }
            else {
                yearRef.current?.focus();
            }
        }
        if (parsedText.length === 0 && (state?.day?.length || 0) < 2)
            dayRef.current?.focus();
    };
    const onChangeYear = (data) => {
        const parsedText = data.replace(/[^0-9]/g, '');
        const newState = { ...state, year: parsedText };
        onChange(newState);
        if (parsedText.length === 0 && (state?.month?.length || 0) < 2)
            monthRef.current?.focus();
    };
    useAutoFocusInput(monthFirst ? monthRef : dayRef);
    const day = (<Date ref={dayRef} title="Day" placeholder="DD" maxLength={2} value={state.day} autoFocus={!monthFirst} onChangeText={onChangeDay} onDark={onDark}/>);
    const month = (<Date ref={monthRef} title="Month" placeholder="MM" maxLength={2} autoFocus={monthFirst} value={state.month} onChangeText={onChangeMonth} onDark={onDark}/>);
    return (<View style={styles.container}>
      {monthFirst ? (<>
          {month}
          {day}
        </>) : (<>
          {day}
          {month}
        </>)}
      <Date ref={yearRef} title="Year" placeholder="YYYY" maxLength={4} autoFocus={false} value={state.year} onChangeText={onChangeYear} onSubmitEditing={onSubmitEditing} error={showYearError} onDark={onDark}/>
    </View>);
};
export const toDate = (time) => ({
    day: time ? format(time, 'd') : undefined,
    month: time ? format(time, 'M') : undefined,
    year: time ? format(time, 'yyyy') : undefined,
});
const styles = StyleSheet.create({
    container: {
        ...(isWeb
            ? {}
            : {
                justifyContent: 'center',
            }),
        alignItems: 'center',
        flexDirection: 'row',
    },
});
export default memo(DateInput);
