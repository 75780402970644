import { useCallback } from 'react';
const useKeyPress = (textValue, activeFilter, onRemoveFilter, onResetLowerAmount, onResetUpperAmount) => {
    const onKeyPress = useCallback((event) => {
        if (event.nativeEvent.key === 'Backspace' &&
            textValue === '' &&
            activeFilter &&
            Object.keys(activeFilter).length) {
            const lastFilter = activeFilter[Object.keys(activeFilter)[Object.keys(activeFilter).length - 1]];
            if (Array.isArray(lastFilter)) {
                const latestFilter = lastFilter.pop();
                onRemoveFilter(latestFilter);
            }
            else if (lastFilter) {
                if (lastFilter.filterType === 'AMOUNT_LOWER') {
                    onResetLowerAmount();
                }
                if (lastFilter.filterType === 'AMOUNT_UPPER') {
                    onResetUpperAmount();
                }
                onRemoveFilter(lastFilter);
            }
        }
    }, [textValue, activeFilter, onRemoveFilter, onResetLowerAmount, onResetUpperAmount]);
    return onKeyPress;
};
export default useKeyPress;
