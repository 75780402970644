import React from 'react';
import { View, StyleSheet } from 'react-native';
import { rem } from './values';
export const Spacer = (props) => {
    if ('h' in props) {
        return <View style={{ height: rem(props.h) }}/>;
    }
    if ('w' in props) {
        return <View style={{ width: rem(props.w) }}/>;
    }
    if ('flex' in props) {
        if (typeof props.flex === 'number') {
            return <View style={{ flex: props.flex }}/>;
        }
    }
    return <View style={styles.flex}/>;
};
Spacer.H8 = () => <View style={styles.h8}/>;
Spacer.H16 = () => <View style={styles.h16}/>;
Spacer.W8 = () => <View style={styles.w8}/>;
Spacer.W16 = () => <View style={styles.w16}/>;
const styles = StyleSheet.create({
    flex: {
        flex: 1,
    },
    h8: { height: rem(8) },
    h16: { height: rem(16) },
    w8: { width: rem(8) },
    w16: { width: rem(16) },
});
export default Spacer;
