import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import useColors from 'hooks/useColors';
import { IconWithGradient } from './Icon';
export const IconStar = ({ notificationDot, ...props }) => {
    const colors = useColors();
    const id = useId();
    if (props.alt) {
        return (<IconWithGradient id={id} defaultGradientKey="orange" {...props}>
        <Path d="M11.0411 4.23944C11.4314 2.9209 11.6266 2.26163 12 2.26163C12.3734 2.26163 12.5686 2.9209 12.9589 4.23944L14.3582 8.96678C14.4336 9.22161 14.4713 9.34903 14.5611 9.43885C14.651 9.52868 14.7784 9.5664 15.0332 9.64183L19.7606 11.0411C21.0791 11.4314 21.7384 11.6266 21.7384 12C21.7384 12.3734 21.0791 12.5686 19.7606 12.9589L15.0332 14.3582C14.7784 14.4336 14.651 14.4713 14.5611 14.5611C14.4713 14.651 14.4336 14.7784 14.3582 15.0332L12.9589 19.7606C12.5686 21.0791 12.3734 21.7384 12 21.7384C11.6266 21.7384 11.4314 21.0791 11.0411 19.7606L9.64183 15.0332C9.5664 14.7784 9.52868 14.651 9.43885 14.5611C9.34903 14.4713 9.22161 14.4336 8.96678 14.3582L4.23944 12.9589C2.9209 12.5686 2.26163 12.3734 2.26163 12C2.26163 11.6266 2.9209 11.4314 4.23944 11.0411L8.96678 9.64183C9.22161 9.5664 9.34903 9.52868 9.43885 9.43885C9.52868 9.34903 9.5664 9.22161 9.64183 8.96678L11.0411 4.23944Z" fill={`url(#gradient_${id})`}/>
      </IconWithGradient>);
    }
    return (<IconWithGradient id={id} defaultGradientKey="orange" {...props}>
      {notificationDot ? (<>
          <Path fillRule="evenodd" clipRule="evenodd" d="M21.6094 9.15595C20.602 10.2873 19.1344 10.9999 17.5004 10.9999C14.4628 10.9999 12.0004 8.53747 12.0004 5.4999C12.0004 4.49777 12.2684 3.55823 12.7367 2.74907C12.5691 2.61128 12.3624 2.52308 12.1399 2.5H11.7189L11.5489 2.57L11.4399 2.61C11.3799 2.645 11.3259 2.688 11.2789 2.74L11.1889 2.81C11.1079 2.888 11.0409 2.98 10.9889 3.08L8.79888 7.57C8.62888 7.91 8.29688 8.14 7.91888 8.179L2.94888 8.9C2.53588 8.965 2.19388 9.254 2.05988 9.65C1.92188 10.047 2.02688 10.487 2.32888 10.78L5.93988 14.28C6.19888 14.535 6.31788 14.9 6.25888 15.259L5.36888 20.179C5.27188 20.774 5.66688 21.338 6.25888 21.45C6.50188 21.489 6.74988 21.45 6.96888 21.34L11.3989 19.019C11.4829 18.973 11.5749 18.943 11.6689 18.929H11.9399C12.1149 18.934 12.2859 18.978 12.4399 19.06L16.8689 21.37C17.2419 21.57 17.6969 21.54 18.0389 21.29C18.3879 21.049 18.5639 20.627 18.4889 20.21L17.5999 15.29C17.5409 14.934 17.6599 14.571 17.9189 14.32L21.6589 10.78C21.9669 10.491 22.0799 10.051 21.9489 9.65C21.8809 9.4543 21.7621 9.28494 21.6094 9.15595Z" fill={`url(#gradient_${id})`}/>
          <Path d="M21.5 5.5C21.5 7.70914 19.7091 9.5 17.5 9.5C15.2909 9.5 13.5 7.70914 13.5 5.5C13.5 3.29086 15.2909 1.5 17.5 1.5C19.7091 1.5 21.5 3.29086 21.5 5.5Z" fill={colors.text.negative}/>
        </>) : (<Path d="M17.919 14.32a1.1 1.1 0 0 0-.32.97l.89 4.92a1.08 1.08 0 0 1-.45 1.08 1.1 1.1 0 0 1-1.17.08l-4.43-2.31a1.13 1.13 0 0 0-.5-.13h-.27a.812.812 0 0 0-.27.09l-4.43 2.32c-.22.11-.467.15-.71.11a1.112 1.112 0 0 1-.89-1.27l.89-4.92a1.119 1.119 0 0 0-.32-.98l-3.61-3.5a1.08 1.08 0 0 1-.27-1.13c.135-.396.477-.685.89-.75l4.97-.72c.378-.04.71-.27.88-.61l2.19-4.49c.051-.1.118-.192.2-.27l.09-.07a.671.671 0 0 1 .16-.13l.11-.04.17-.07h.42c.377.04.708.264.88.6l2.22 4.47c.16.327.47.554.83.61l4.97.72c.42.06.77.35.91.75.13.401.017.841-.29 1.13l-3.74 3.54Z" fill={`url(#gradient_${id})`}/>)}
    </IconWithGradient>);
};
