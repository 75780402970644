import { CALL_API } from 'middleware/api';
import * as types from './types';
export const getBudgets = () => ({
    [CALL_API]: {
        endpoint: '/budgets',
        method: 'GET',
        addSpaceIdHeader: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        types: [
            types.GET_BUDGETS_REQUEST,
            types.GET_BUDGETS_SUCCESS,
            types.GET_BUDGETS_FAILURE,
        ],
    },
});
export const saveBudgets = (data) => ({
    [CALL_API]: {
        endpoint: '/budgets',
        method: 'POST',
        addSpaceIdHeader: true,
        body: JSON.stringify({
            ...data,
            skipListBudgetsOnResponse: true,
        }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        extra: {
            data,
        },
        types: [
            types.SAVE_BUDGETS_REQUEST,
            types.SAVE_BUDGETS_SUCCESS,
            types.SAVE_BUDGETS_FAILURE,
        ],
    },
});
