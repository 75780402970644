import React, { useMemo } from 'react';
import moment from 'moment';
import { SettingsItem } from 'design-system/SettingsItem';
import { IconCalendar } from 'design-system/icons/IconCalendar';
const TransactionOriginalDateItem = ({ transaction }) => {
    const originalDate = useMemo(() => {
        if (transaction.customDate &&
            !moment(transaction.bookingDate).isSame(transaction.customDate, 'day')) {
            return transaction.bookingDate;
        }
        return undefined;
    }, [transaction.bookingDate, transaction.customDate]);
    if (!originalDate) {
        return null;
    }
    return (<SettingsItem title="Original date" description={moment.utc(originalDate).format('ddd, MMM D YYYY')} icon={<IconCalendar gradientKey="skyBlue"/>}/>);
};
export default TransactionOriginalDateItem;
