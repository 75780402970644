import React, { memo } from 'react';
import { View, StyleSheet, InteractionManager, TouchableWithoutFeedback, } from 'react-native';
import { rem } from 'design-system/values';
import IconEmojiCategory from './emojiIcons/IconEmojiCategory';
import { isWeb } from '../../constants';
const CategoryItem = ({ category, scrollToIndex, selectedCategory, }) => {
    const selected = () => {
        InteractionManager.runAfterInteractions(() => {
            scrollToIndex();
        });
    };
    const isSelected = category === selectedCategory;
    return (<TouchableWithoutFeedback onPress={selected}>
      <View style={styles.categoryItem}>
        <IconEmojiCategory category={category} selected={isSelected}/>
      </View>
    </TouchableWithoutFeedback>);
};
export default memo(CategoryItem);
const styles = StyleSheet.create({
    categoryItem: {
        width: rem(30),
        height: rem(30),
        alignItems: 'center',
        borderRadius: rem(15),
        justifyContent: 'center',
        ...(isWeb && {
            // @ts-ignore
            cursor: 'pointer',
        }),
    },
});
