import { fork, take, put } from 'typed-redux-saga/macro';
import { buffers, eventChannel } from 'redux-saga';
import { AppState } from 'react-native';
import { setAppStateStatus } from 'actions/appState';
function* watchAppState() {
    const channel = eventChannel((emitter) => {
        const subscription = AppState.addEventListener('change', (state) => {
            emitter(state);
        });
        return () => {
            subscription.remove();
        };
    }, buffers.sliding(1));
    while (true) {
        const appState = yield* take(channel);
        yield* put(setAppStateStatus(appState));
    }
}
function* appStateSaga() {
    yield* fork(watchAppState);
}
export default appStateSaga;
