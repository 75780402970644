import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconLogout = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} width={24} height={24} {...props} defaultGradientKey="deepPink">
      <Path fill={`url(#gradient_${id})`} d="M17.3 2h-2.6c-3.2 0-5.2 2-5.2 5.2v4.05h6.25c.41 0 .75.34.75.75s-.34.75-.75.75H9.5v4.05c0 3.2 2 5.2 5.2 5.2h2.59c3.2 0 5.2-2 5.2-5.2V7.2C22.5 4 20.5 2 17.3 2Z"/>
      <Path fill={`url(#gradient_${id})`} d="m5.061 11.25 2.07-2.07c.15-.15.22-.34.22-.53s-.07-.39-.22-.53a.754.754 0 0 0-1.06 0l-3.35 3.35c-.29.29-.29.77 0 1.06l3.35 3.35c.29.29.77.29 1.06 0 .29-.29.29-.77 0-1.06l-2.07-2.07h4.44v-1.5h-4.44Z"/>
    </IconWithGradient>);
};
