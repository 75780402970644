import { useEffect, useCallback } from 'react';
import * as investActions from 'actions/invest';
import { useFetch } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useStatus from './useStatus';
import { selectTrustedContacts } from './selectors';
/**
 * Returns `undefined` when trading account status is NOT_STARTED.
 * In this case nothing should be rendered.
 * If it returns an empty array - that means user does not yet have any trusted contacts set up.
 * You should always render the last item.
 *
 * `update` callback may throw.
 */
const useTrustedContacts = () => {
    const status = useStatus();
    const trustedContacts = useAppSelector(selectTrustedContacts);
    const [fetchTrustedContacts] = useFetch();
    const [postTrustedContact] = useFetch();
    const dispatch = useAppDispatch();
    useEffect(() => {
        fetchTrustedContacts(investActions.getTrustedContacts())
            .then((response) => {
            const action = {
                type: 'CACHE_TRUSTED_CONTACTS',
                trustedContacts: response.trustedContacts,
            };
            dispatch(action);
        })
            .catch();
    }, [fetchTrustedContacts]);
    const update = useCallback(async (data) => {
        if (data.id) {
            // patch
            const { id, ...fields } = data;
            const response = await postTrustedContact(investActions.editTrustedContact(id, fields));
            dispatch({ type: 'CACHE_TRUSTED_CONTACTS', trustedContacts: [response.trustedContact] });
        }
        else {
            // post
            const fakeData = { firstName: ' ', lastName: ' ', email: ' ', phoneNumber: ' ' }; // in order to break required field validation
            const response = await postTrustedContact(investActions.addTrustedContact({ ...fakeData, ...data }));
            dispatch({ type: 'CACHE_TRUSTED_CONTACTS', trustedContacts: [response.trustedContact] });
        }
    }, [postTrustedContact]);
    return [status === 'NOT_STARTED' || status === undefined ? undefined : trustedContacts ?? [], update];
};
export const getDisplayName = (contact) => {
    const trimmedFirstName = contact.firstName.trim();
    if (trimmedFirstName) {
        return trimmedFirstName;
    }
    const trimmedLastName = contact.lastName.trim();
    if (trimmedLastName) {
        return trimmedLastName;
    }
    const trimmedPhoneNumber = contact.phoneNumber?.trim();
    if (trimmedPhoneNumber) {
        return trimmedPhoneNumber;
    }
    const trimmedEmail = contact.email?.trim();
    if (trimmedEmail) {
        return trimmedEmail;
    }
    return 'Add';
};
export default useTrustedContacts;
