import WebAmplitude from 'utils/packages/amplitude';
const Amplitude = __DEV__
    ? {
        logEvent: (key, params) => {
            log('====================AMPLITUDE_EVENT====================', false);
            log({ key, params: JSON.stringify(params || {}) }, true);
        },
        setUserId: () => { },
        setUserProperties: () => { },
    }
    : WebAmplitude;
export default Amplitude;
