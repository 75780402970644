import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
const IconEye = ({ ...props }) => {
    const id = useId();
    return (<IconWithGradient id={id} defaultGradientKey="darkPurple" {...props}>
      <Path fillRule="evenodd" clipRule="evenodd" d="M3.93799 8.5797C7.55608 2.47343 16.4439 2.47343 20.062 8.5797C21.3127 10.6905 21.3127 13.3095 20.062 15.4203C16.4439 21.5266 7.55608 21.5266 3.93799 15.4203C2.68734 13.3095 2.68734 10.6905 3.93799 8.5797ZM9.36532 12.0548C9.36532 10.5934 10.5446 9.41018 11.9995 9.41018C13.4547 9.41018 14.6347 10.5937 14.6347 12.0548C14.6347 13.5148 13.4548 14.6984 11.9995 14.6984C10.5444 14.6984 9.36532 13.5151 9.36532 12.0548ZM11.9995 8.17147C9.86264 8.17147 8.13103 9.90954 8.13103 12.0548C8.13103 14.1992 9.86276 15.9371 11.9995 15.9371C14.136 15.9371 15.869 14.1995 15.869 12.0548C15.869 9.90928 14.1361 8.17147 11.9995 8.17147Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default IconEye;
