export const inverseStatusBarStyle = (style) => {
    switch (style) {
        case 'always-light':
            return 'light-content';
        case 'always-dark':
            return 'dark-content';
        case 'dark-content':
            return 'light-content';
        case 'light-content':
            return 'dark-content';
        default:
            return 'default';
    }
};
const useStatusBar = () => {
    // No-op for web now
};
export default useStatusBar;
