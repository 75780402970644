import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState, } from 'react';
import { noop } from 'lodash';
import { View } from 'react-native';
import Animated, { useSharedValue, useAnimatedScrollHandler, } from 'react-native-reanimated';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import WebTabs from 'design-system/WebTabs.web';
import { HEIGHT } from 'design-system/Button';
import { AppView } from 'design-system/AppView';
import StatusBar from 'design-system/StatusBar';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import AccountsTopSync from 'features/settings/logins/AccountsTopSync';
import useAccountCallbacks from 'hooks/useAccountCallbacks';
import useColors from 'hooks/useColors';
import { selectAccountsSeparatedDebt } from 'reducers/selectors';
import { useAppDispatch, useAppSelector, useAppStore } from 'store/hooks';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import { useMarginBottom } from 'hooks/useMarginBottom';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import usePrivateRoute from 'hooks/usePrivateRoute';
import { getAccountGoals } from 'actions';
import AccountsHeaderGraph, { GRAPH_HEIGHT, } from '../components/AccountsHeaderGraph';
import AccountsOverviewSection from '../components/overview/AccountsOverviewSection';
import { accountOverviewScreenTitleToSelectedAccountTitleMap, } from '../types';
import NetWorthMainSection from '../components/overview/NetWorthMainSection';
import NetWorthAssetsSection from '../components/overview/NetWorthAssetsSection';
import NetWorthDebtSection from '../components/overview/NetWorthDebtSection';
const SNAP_OFFSETS = [0, GRAPH_HEIGHT + rem(32 + 4)];
const SNAP_OFFSETS_WITH_SELECTOR = [
    SNAP_OFFSETS[0],
    SNAP_OFFSETS[1] + rem(40 + 16),
];
const titles = [
    'Everyday',
    'Savings',
    'Investments',
    'Net Worth - Main',
];
const netWorthTitles = ['Net worth', 'Assets', 'Debt'];
const netWorthTitleMap = {
    'Net worth': 'Net Worth - Main',
    Assets: 'Net Worth - Asset',
    Debt: 'Net Worth - Debt',
};
const AccountsOverviewScreen = withAnimatedNavigationHeader(({ navigation, route, scrollY }) => {
    usePrivateRoute();
    const store = useAppStore();
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!store.getState().accounts.loadedGoals) {
            dispatch(getAccountGoals());
        }
    }, []);
    const styles = useStyles(styleSet);
    const colors = useColors();
    const scrollX = useSharedValue(0);
    const accounts = useAppSelector(selectAccountsSeparatedDebt);
    const { onRowPress, onOpenLogins, onOpenUpgrade } = useAccountCallbacks(false);
    const { title: initialTitle = 'Everyday' } = route.params || {};
    const scrollRef = useRef(null);
    useLayoutEffect(() => {
        navigation.setOptions({
            headerTintColor: colors.text.primary,
            headerRight: () => (<View style={styles.iconContainer}>
            <AccountsTopSync color={colors.text.primary}/>
            <Spacer w={16}/>
          </View>),
        });
    }, [colors.text.primary, scrollX, styles.iconContainer]);
    const { isUnlockedForSpace, onPressUpgrade } = useShouldShowUpgrade({
        benefitId: 'netWorth',
    });
    const onScroll = useAnimatedScrollHandler((event) => {
        // eslint-disable-next-line no-param-reassign
        scrollY.value = event.contentOffset.y;
    });
    const [selectedTab, setSelectedTab] = useState(initialTitle);
    // Handle tab changes
    const handleTabChange = useCallback((tab) => {
        setSelectedTab(tab);
        navigation.setParams({
            title: tab,
        });
    }, []);
    const header = useMemo(() => (<AccountsHeaderGraph scrollX={scrollX} title={selectedTab} blurValues={!isUnlockedForSpace} onPressUpgrade={onPressUpgrade}/>), [isUnlockedForSpace, onPressUpgrade, scrollX, selectedTab]);
    const paddingBottom = useMarginBottom('paddingBottom', HEIGHT + rem(24));
    const onPressAssets = useCallback(() => {
        setSelectedTab('Net Worth - Asset');
    }, []);
    const onPressDebt = useCallback(() => {
        setSelectedTab('Net Worth - Debt');
    }, []);
    const content = useMemo(() => {
        const item = selectedTab;
        if (item === 'Net Worth - Main') {
            return (<NetWorthMainSection onPressAssets={onPressAssets} onPressDebt={onPressDebt} blurValues={!isUnlockedForSpace}/>);
        }
        if (item === 'Net Worth - Asset') {
            return (<NetWorthAssetsSection accounts={accounts} onRowPress={onRowPress} onOpenLogins={onOpenLogins} onAddAccount={noop} openAlternative={noop} onOpenUpgrade={onOpenUpgrade} onRowLongPress={noop} isUnlockedForSpace={isUnlockedForSpace}/>);
        }
        if (item === 'Net Worth - Debt') {
            return (<NetWorthDebtSection accounts={accounts} onRowPress={onRowPress} onOpenLogins={onOpenLogins} onAddAccount={noop} openAlternative={noop} onOpenUpgrade={onOpenUpgrade} onRowLongPress={noop} isUnlockedForSpace={isUnlockedForSpace}/>);
        }
        return (<AccountsOverviewSection title={item} accounts={accounts} onRowPress={onRowPress} initialTitle={initialTitle} onOpenLogins={onOpenLogins} onOpenUpgrade={onOpenUpgrade} onRowLongPress={noop}/>);
    }, [
        selectedTab,
        accounts,
        onRowPress,
        initialTitle,
        onOpenLogins,
        onOpenUpgrade,
        onPressAssets,
        onPressDebt,
        isUnlockedForSpace,
    ]);
    return (<AppView style={styles.container} isInTab>
        <View style={styles.innerContainer}>
          <StatusBar barStyle="dark-content" animated/>
          <Animated.ScrollView ref={scrollRef} snapToEnd={false} onScroll={onScroll} decelerationRate="fast" scrollEventThrottle={16} showsVerticalScrollIndicator={false} snapToOffsets={SNAP_OFFSETS_WITH_SELECTOR} contentContainerStyle={paddingBottom}>
            <WebTabs tabs={titles} displayTabs={titles.map((t) => accountOverviewScreenTitleToSelectedAccountTitleMap[t])} activeTab={selectedTab === 'Net Worth - Debt' ||
            selectedTab === 'Net Worth - Asset'
            ? 'Net Worth - Main'
            : selectedTab} handleTabChange={handleTabChange}/>
            {header}
            {(selectedTab === 'Net Worth - Main' ||
            selectedTab === 'Net Worth - Asset' ||
            selectedTab === 'Net Worth - Debt') && (<View style={styles.netWorthPageControlContainer}>
                <WebTabs tabs={netWorthTitles} activeTab={{
                'Net Worth - Main': 'Net worth',
                'Net Worth - Asset': 'Assets',
                'Net Worth - Debt': 'Debt',
            }[selectedTab]} Secondary handleTabChange={(title) => {
                const tab = netWorthTitleMap[title];
                if (tab) {
                    handleTabChange(tab);
                }
            }}/>
              </View>)}
            {content}
          </Animated.ScrollView>
        </View>
      </AppView>);
}, ({ colors, route }) => ({
    hasCustomTitle: true,
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
    title: route.params?.title
        ? accountOverviewScreenTitleToSelectedAccountTitleMap[route.params?.title]
        : undefined,
}));
const styleSet = createStyleSheets(() => ({
    container: {
        overflow: 'hidden',
    },
    innerContainer: {
        flex: 1,
        overflow: 'hidden',
    },
    iconContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    netWorthPageControlContainer: {
        marginBottom: rem(16),
    },
}));
export default AccountsOverviewScreen;
