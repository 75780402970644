import React, { memo, useCallback } from 'react';
import { View } from 'react-native';
import { FlashList } from '@shopify/flash-list';
import { Indicator } from 'design-system';
import FlatListItemCard from 'design-system/FlatListItemCard';
import { rem, SCROLL_INDICATOR_INSETS } from 'design-system/values';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import useEstimatedListSize from 'hooks/useEstimatedListSize';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useStyles from 'hooks/useStyles';
import { useAppSelector } from 'store/hooks';
import createStyleSheets from 'utils/createStyleSheets';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { isWeb } from '../../../constants';
import MerchantItem, { MERCHANT_ITEM_HEIGHT } from './MerchantItem';
import NoSearchResults from './NoSearchResults';
const selectIsFetchingSearchTransactions = (store) => store.transactions.isFetchingSearchTransactions;
const keyExtractor = (item) => item.id.toString();
const MerchantsList = ({ onScroll, searchResults }) => {
    const navigation = useMainStackNavigation();
    const isFetching = useAppSelector(selectIsFetchingSearchTransactions);
    const handlePress = useCallback((item) => {
        navigation.navigate('EditMerchantBudget', { ...item, backCount: 3 });
    }, []);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const styles = useStyles(styleSet);
    const renderItem = useCallback(({ item, index }) => (<FlatListItemCard index={index} itemsLength={searchResults.length} style={styles.card} onPress={() => handlePress(item)}>
        <MerchantItem data={item}/>
      </FlatListItemCard>), [handlePress, searchResults.length, styles.card]);
    const estimatedListSize = useEstimatedListSize(searchResults);
    const paddingBottom = useMarginBottom('paddingBottom', undefined, paddingHorizontalStyle, styles.flashList);
    return (<View style={styles.flex}>
      {searchResults.length > 0 || !isFetching ? (<FlashList scrollIndicatorInsets={SCROLL_INDICATOR_INSETS} contentContainerStyle={paddingBottom} renderItem={renderItem} data={searchResults} keyExtractor={keyExtractor} onScroll={onScroll} keyboardDismissMode="on-drag" keyboardShouldPersistTaps="handled" ListEmptyComponent={NoSearchResults} estimatedItemSize={MERCHANT_ITEM_HEIGHT} estimatedListSize={estimatedListSize} extraData={styles.card}/>) : (<Indicator dark/>)}
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    flex: {
        flex: 1,
    },
    card: {
        backgroundColor: colors.cards.onDark,
    },
    flashList: {
        paddingTop: rem(8),
    },
}));
export default memo(MerchantsList);
