import { CALL_API } from '../middleware/api';
import * as types from './types';
const headersJson = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};
export const getReward = (id) => ({
    [CALL_API]: {
        endpoint: `/rewards/${id}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_REWARD_REQUEST,
            types.GET_REWARD_SUCCESS,
            types.GET_REWARD_FAILURE,
        ],
    },
});
export const getRewards = () => ({
    [CALL_API]: {
        endpoint: `/rewards`,
        method: 'GET',
        headers: headersJson,
        types: [
            types.GET_REWARDS_REQUEST,
            types.GET_REWARDS_SUCCESS,
            types.GET_REWARDS_FAILURE,
        ],
    },
});
export const getRewardsPublic = ({ countryCode, }) => ({
    [CALL_API]: {
        endpoint: `/rewards/public?countryCode=${countryCode}`,
        method: 'GET',
        headers: headersJson,
        types: [
            types.GET_REWARDS_REQUEST,
            types.GET_REWARDS_SUCCESS,
            types.GET_REWARDS_FAILURE,
        ],
        noAccessToken: true,
    },
});
export const getRewardLink = (id) => ({
    [CALL_API]: {
        endpoint: `/rewards/${id}/link`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_REWARD_LINK_REQUEST,
            types.GET_REWARD_LINK_SUCCESS,
            types.GET_REWARD_LINK_FAILURE,
        ],
    },
});
export const getRedemptions = () => ({
    [CALL_API]: {
        endpoint: '/redemptions',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_REDEMPTIONS_REQUEST,
            types.GET_REDEMPTIONS_SUCCESS,
            types.GET_REDEMPTIONS_FAILURE,
        ],
    },
});
export const claimRedemptions = (body) => ({
    [CALL_API]: {
        endpoint: '/redemptions/claim',
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.CLAIM_REDEMPTIONS_REQUEST,
            types.CLAIM_REDEMPTIONS_SUCCESS,
            types.CLAIM_REDEMPTIONS_FAILURE,
        ],
    },
});
