import { getStateFromPath } from '@react-navigation/native';
import { useCallback } from 'react';
import { dynamicLinks } from 'utils/packages/rnFirebase';
import { DeepLinkType, parseDeepLink, } from 'common/NavigatorActions/hooks/parseDeepLink';
import useEnergyCompare from 'features/feed/components/save/useEnergyCompare';
import useLoans from 'features/feed/components/save/useLoans';
import { getPotsAccount } from 'features/pots/actions';
import { selectDidViewFraudDetectionIntro } from 'features/quests/store/selectors';
import { selectHasCurrentRentReporting } from 'features/rent-reporting/selectors';
import { setSelectedSpace } from 'features/spaces/actions';
import { parse } from 'query-string';
import { selectAccountById } from 'reducers/selectors';
import { useAppDispatch, useAppStore } from 'store/hooks';
import Links from 'utils/links';
import { Tier } from 'features/premium/entities';
import { OnboardingFlow } from 'utils/types/navigation';
import { useLinkTo, useMainStackNavigation } from 'utils/types/navigationV6';
import { isDesktop } from '../constants';
export const parseId = {
    id: (id) => Number(id),
};
const getTier = (tier) => {
    if (tier === Tier.pro || tier === Tier.ultimate || tier === Tier.plus) {
        return tier;
    }
    return undefined;
};
export const linking = {
    prefixes: [],
    config: {
        screens: {
            Feed: 'feed',
            Save: 'save',
            Invest: 'invest',
            Reward: 'cashback/:id',
            Rewards: 'cashback',
            ClaimRewards: 'cashback-claim',
            Notifications: 'notifications/settings',
            Borrow: 'borrow',
            EmmaProModal: {
                path: 'upgrade/:skipTrialScreen/:tier?',
                parse: {
                    skipTrialScreen: (skipTrialScreen) => skipTrialScreen === 'true',
                },
            },
            ManagePremiumSubscription: 'manage-plan',
            InvestStock: {
                path: 'stocks/:stock',
                parse: {
                    stock: (stock) => ({
                        symbol: stock.toUpperCase(),
                    }),
                },
            },
            PensionBee: 'pensionbee',
            NetWorth: 'net-worth',
            AccountsOverview: 'accounts',
            MySpaces: 'spaces',
            Logins: 'logins',
            EditCategory: 'custom-categories',
            DataProtectionScreen: 'data-protection-screen',
            AutomationRules: 'automation-rules',
            Support: 'support',
            InviteScreen: 'invite',
            Subscriptions: 'subscriptions',
            Analytics: 'analytics',
            Budgeting: 'budgeting',
            EditBudget: 'budgeting/:flow',
            ExportingPeriod: 'export-data',
            OfflineAccounts: 'offline-accounts',
            Transactions: {
                path: 'accounts/:id',
                parse: parseId,
            },
            ActivityScreen: 'pay/activity',
            EditSpace: {
                path: 'spaces/:id/edit',
                parse: parseId,
            },
            GroupJoin: 'join-group',
            Group: {
                path: 'groups/:id',
                parse: parseId,
            },
            JoinSpace: 'join-space/:accessCode',
            ModalPaymentRequest: 'payment-request',
            AutoSave: 'auto-save',
            EmmaWallet: 'emma-wallet',
            SelectPotType: 'create-pot',
            TransactionsList: 'transactions',
            NotificationsList: 'notifications',
            CommittedList: 'committed-spending',
            Item: {
                path: 'transactions/:id',
                parse: parseId,
            },
            Category: 'analytics-category',
            MonthlyCategory: 'budgeting-category',
            InactivePayments: {
                path: 'subscriptions/inactive',
            },
            Subscription: {
                path: 'subscriptions/:id',
                parse: parseId,
            },
            AccountSettings: 'settings',
            AccountSettingsList: 'settings/account-details',
            PreferenceSettingsList: 'settings/preferences',
            AboutUs: 'settings/about-us',
            ImportantStuff: 'settings/important-stuff',
            PersonalDetails: 'settings/personal-details',
            ChangePasscode: 'settings/change-passcode',
            SearchTransactions: 'search-transactions',
            EditAccountName: {
                path: 'accounts/:id/rename',
                parse: parseId,
            },
            EditOverdraft: {
                path: 'accounts/:id/overdraft',
                parse: parseId,
            },
            SavingGoalEdit: {
                path: 'saving-goals/edit',
                parse: parseId,
            },
            AnalyticsIntro: 'analytics/intro',
            SelectMerchant: 'budgeting-merchant/select',
            EditMerchantBudgets: 'budgeting-merchant',
            EditMerchantBudget: 'budgeting-merchant/edit',
            UnbudgetedSpend: 'unbudgeted-spending',
            DummyAccountSetup: {
                path: 'offline-accounts/setup',
                parse: parseId,
            },
            DummyAccountCreated: {
                path: 'offline-accounts/created',
                parse: parseId,
            },
            DummyAccountLogo: {
                path: 'offline-accounts/logo',
                parse: parseId,
            },
            DummyAccountLogoType: {
                path: 'offline-accounts/logo-type',
                parse: parseId,
            },
            AddBalance: {
                path: 'offline-accounts/balance',
                parse: parseId,
            },
            AddAnEmoji: {
                path: 'offline-accounts/emoji',
                parse: parseId,
            },
            PickAccountType: 'offline-accounts/type',
            Property: 'offline-accounts/property-type',
        },
    },
    getStateFromPath: (path, options) => {
        const state = getStateFromPath(path, options);
        if (path.startsWith('/cashback?id=')) {
            const params = state?.routes[0]?.params;
            if ('id' in params) {
                const { id } = params;
                return getStateFromPath(`/cashback/${id}`, options);
            }
        }
        if (path === '/upgrade' || path.startsWith('/upgrade/')) {
            const params = state?.routes[0]
                ?.params;
            const tier = getTier(params?.tier);
            const skipTrialScreen = params && 'skipTrialScreen' in params
                ? !!params.skipTrialScreen
                : false;
            if (tier) {
                return getStateFromPath(`/upgrade/${skipTrialScreen}/${tier}`, options);
            }
            return getStateFromPath(`/upgrade/${skipTrialScreen}`, options);
        }
        if (path.startsWith('/budgeting/')) {
            const params = state?.routes[0]?.params;
            const flow = params.flow === 'edit' ? 'Edit' : 'Set';
            return getStateFromPath(`/budgeting/${flow}`, options);
        }
        return state;
    },
};
const abstractRoutes = ['/upgrade-to-paid', '/connections/fix'];
export const isValidAppLink = (rawRoute) => {
    const route = rawRoute.replace('/dashboard', '');
    if (abstractRoutes.includes(route)) {
        return true;
    }
    if (route.includes('/@')) {
        return true;
    }
    const result = getStateFromPath(route, {
        ...linking.config,
        screens: {
            ...linking.config?.screens,
            // These routes aren't part of the main config, we have custom handling for them
            RentReporting: 'rent-reporting',
            DataProtectionScreen: 'fraud-detection',
            BroadBandQuotes: 'broadband',
            UserInfo: 'loan',
            InvestAccount: 'invest/account',
            InvestActivity: 'invest/activity',
            InvestSelectDocument: 'wallet/start-poi',
            InvestChooseProofOfAddress: 'wallet/start-poa',
        },
    });
    return result !== undefined;
};
const useAppLink = () => {
    const linkTo = useLinkTo();
    const navigation = useMainStackNavigation();
    const store = useAppStore();
    const dispatch = useAppDispatch();
    const onCompare = useCallback((routeWithParams) => {
        navigation.navigate(routeWithParams);
    }, []);
    const energyCompare = useEnergyCompare(onCompare);
    const loans = useLoans();
    const appLinkTo = useCallback(async (rawRoute) => {
        if (rawRoute) {
            const route = rawRoute.replace('/dashboard', '');
            if (route === '/wallet/start-poi') {
                await dispatch(getPotsAccount());
                const requiredUserAction = store.getState().pots.account?.kycVerification?.requiredUserAction;
                if (requiredUserAction === 'UPLOAD_IDENTITY_DOCUMENT' && !isDesktop) {
                    navigation.navigate('WealthDocumentUploadIntroScreen', {
                        documentType: 'POI',
                        flow: OnboardingFlow.Wallet,
                    });
                }
            }
            if (route === '/wallet/start-poa') {
                await dispatch(getPotsAccount());
                const requiredUserAction = store.getState().pots.account?.kycVerification?.requiredUserAction;
                if (requiredUserAction === 'UPLOAD_PROOF_OF_ADDRESS' && !isDesktop) {
                    navigation.navigate('WealthDocumentUploadIntroScreen', {
                        documentType: 'POA',
                        flow: OnboardingFlow.Wallet,
                    });
                }
            }
            if (route === '/connections/fix') {
                navigation.navigate('Logins', {
                    brokenConnections: true,
                    refreshOnMount: true,
                });
            }
            if (route.includes('/invest/account')) {
                const investAccount = store.getState().invest.account;
                if (investAccount?.status === 'ACTIVE') {
                    navigation.navigate('InvestAccount');
                }
                else {
                    navigation.navigate('Invest');
                }
                return;
            }
            if (route.includes('/invest/activity')) {
                navigation.navigate('InvestActivity', {
                    forceReloadConfig: { forceReload: true },
                });
            }
            if (route.includes('/upgrade-to-paid')) {
                linkTo('/upgrade/true');
                return;
            }
            if (route.includes('/fraud-detection')) {
                const didViewFraudDetectionIntro = selectDidViewFraudDetectionIntro(store.getState());
                if (didViewFraudDetectionIntro) {
                    navigation.navigate('DataProtectionScreen');
                }
                else {
                    navigation.navigate('FraudProtectionIntro');
                }
                return;
            }
            if (route.includes('/rent-reporting')) {
                const hasRentReporting = selectHasCurrentRentReporting(store.getState());
                if (hasRentReporting) {
                    navigation.navigate('RentReporting');
                }
                else {
                    navigation.navigate('RentReportingOnboarding');
                }
                return;
            }
            if (route.includes('/loan')) {
                if (route.includes('type=debt')) {
                    loans('DEBT');
                    return;
                }
                if (route.includes('type=car-finance')) {
                    loans('CAR_FINANCE');
                    return;
                }
                if (route.includes('type=home-improvements')) {
                    loans('HOME_IMPROVEMENTS');
                    return;
                }
                if (route.includes('type=credit-cards')) {
                    loans('CREDIT_CARD');
                    return;
                }
                loans('LOAN');
                return;
            }
            if (route.includes('/broadband')) {
                energyCompare('Broadband');
                return;
            }
            if (route.includes('/@')) {
                const [userTag] = route.replace('/@', '').split('/');
                if (userTag) {
                    navigation.navigate('PayEnterAmount', {
                        to: {
                            itemType: 'pay',
                            contact: { handle: userTag, phoneNumbers: [] },
                        },
                    });
                }
            }
            const split = route.split('/')[2];
            if (route.startsWith('/accounts') && split) {
                const state = store.getState();
                const accountFromId = selectAccountById(state, Number(split));
                if (accountFromId?.spaceId !== state.spaces.selectedSpace) {
                    // If the account is not in the current space then don't navigate to it as it won't work yet
                    return;
                }
            }
            if (route.startsWith('/feed')) {
                const queryParams = route.split('?');
                const query = parse(queryParams[1]);
                if (query && query.spaceId && !Array.isArray(query.spaceId)) {
                    dispatch(setSelectedSpace(Number(query.spaceId)));
                }
            }
            try {
                linkTo(route);
            }
            catch (e) {
                //
            }
        }
    }, [energyCompare, linkTo, loans]);
    /**
     * Given a FULL univeral url navigates to the matching route
     * Examples:
     * Will resolve dynamic links like https://emmaapp.page.link/xoM5
     * Or emma-app.com links example https://emma-app.com/dashboard/invite
     * If it's not an app URL, opens in safari webview
     */
    const appLinkToFromUrl = useCallback(async (url, fallbackToSafari = true) => {
        let link = url;
        try {
            const resolved = await dynamicLinks().resolveLink(url);
            if (resolved?.url) {
                link = resolved?.url;
            }
        }
        catch (e) {
            //noop
        }
        const deepLinkData = parseDeepLink(link);
        if (deepLinkData.type === DeepLinkType.APP_LINK &&
            isValidAppLink(deepLinkData.routeStr)) {
            appLinkTo(deepLinkData.routeStr);
        }
        else if (fallbackToSafari) {
            Links.openLink(link);
        }
    }, [appLinkTo]);
    return { appLinkTo, appLinkToFromUrl, isValidAppLink };
};
export default useAppLink;
