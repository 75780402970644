import { LOGGED_OUT } from 'actions/types';
const initialState = {
    potActivities: {},
    isErrorInFetchingPotsAcc: undefined,
    autoSaveActivities: undefined,
    editingUpcomingPayment: undefined,
    upcomingAutosavePayments: undefined,
    fetchingAutosaveActivities: undefined,
    autosaveActivitiesPaginationToken: undefined,
    fetchingPotsAccount: undefined,
};
const unpersistedPotsReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGGED_OUT: {
            return initialState;
        }
        case 'GET_POT_ACTIVITIES_SUCCESS': {
            return {
                ...state,
                potActivities: {
                    ...state.potActivities,
                    [action.extra.potId]: action.payload.activities,
                },
            };
        }
        // TODO uncomment this later -> backend is yet to handle this
        // case 'GET_EMMA_WALLET_ACTIVITIES_SUCCESS':
        case 'GET_ALL_POT_ACTIVITIES_SUCCESS': {
            return {
                ...state,
                upcomingAutosavePayments: action.payload.upcomingPayments,
            };
        }
        case 'GET_POTS_ACCOUNT_SUCCESS': {
            return {
                ...state,
                fetchingPotsAccount: false,
                isErrorInFetchingPotsAcc: false,
            };
        }
        case 'GET_POTS_ACCOUNT_REQUEST': {
            return {
                ...state,
                fetchingPotsAccount: true,
            };
        }
        case 'GET_POTS_ACCOUNT_FAILURE': {
            return {
                ...state,
                fetchingPotsAccount: false,
                isErrorInFetchingPotsAcc: true,
            };
        }
        case 'GET_AUTOSAVE_ACTIVITIES_REQUEST': {
            return {
                ...state,
                fetchingAutosaveActivities: true,
                autosaveActivitiesPaginationToken: 'page' in action.extra && action.extra.page === 1 ? undefined : state.autosaveActivitiesPaginationToken,
            };
        }
        case 'GET_AUTOSAVE_ACTIVITIES_SUCCESS': {
            if (!state.autosaveActivitiesPaginationToken) {
                return {
                    ...state,
                    fetchingAutosaveActivities: false,
                    autoSaveActivities: action.payload.activities,
                    upcomingAutosavePayments: action.payload.upcomingPayments,
                    paginationToken: action.payload.activities.length < 30 ? null : action.payload.paging.next,
                };
            }
            return {
                ...state,
                fetchingAutosaveActivities: false,
                paginationToken: action.payload.paging?.next,
                upcomingAutosavePayments: action.payload.upcomingPayments,
                autoSaveActivities: [...(state.autoSaveActivities ?? []), ...action.payload.activities],
            };
        }
        case 'GET_AUTOSAVE_ACTIVITIES_FAILURE': {
            return {
                ...state,
                fetchingAutosaveActivities: false,
            };
        }
        case 'EDIT_AUTOSAVE_UPCOMING_PAYMENT_REQUEST': {
            return {
                ...state,
                editingUpcomingPayment: true,
            };
        }
        case 'EDIT_AUTOSAVE_UPCOMING_PAYMENT_SUCCESS': {
            return {
                ...state,
                editingUpcomingPayment: false,
                upcomingAutosavePayments: [action.payload.upcomingPayment],
            };
        }
        case 'EDIT_AUTOSAVE_UPCOMING_PAYMENT_FAILURE': {
            return {
                ...state,
                editingUpcomingPayment: false,
            };
        }
        case 'EDIT_SAVINGS_PLAN_SUCCESS': {
            return {
                ...state,
                upcomingAutosavePayments: action.payload.upcomingPayments,
            };
        }
        default: {
            return state;
        }
    }
};
export default unpersistedPotsReducer;
