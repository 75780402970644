import getSymbolFromCurrency from 'currency-symbol-map';
import React from 'react';
import { Text, Platform, StyleSheet, View, } from 'react-native';
import { formatAmount } from '../utils/formatting';
import CurrencySign from './CurrencySign';
import { rem } from './values';
const NunitoMap = {
    normal: 'Nunito-Regular',
    bold: 'Nunito-Bold',
    '100': 'Montserrat-Thin',
    '200': 'Montserrat-Thin',
    '300': 'Nunito-Light',
    '400': 'Nunito-Regular',
    '500': 'Nunito-Medium',
    '600': 'Nunito-SemiBold',
    '700': 'Nunito-Bold',
    '800': 'Nunito-ExtraBold',
    '900': 'Nunito-Black',
};
/*
  @deprecated use design-system/Amount
 */
export const Number = ({ style, light = false, currency, children, }) => {
    // merge default and custom
    let resultStyle = StyleSheet.flatten([
        light ? styles.numberTextLight : styles.numberText,
        style,
    ]);
    const fontColor = resultStyle.color?.toString() ?? '#17102A';
    const fontSize = resultStyle.fontSize ?? 0;
    const fontFamily = NunitoMap[resultStyle.fontWeight ?? '400'];
    const lineHeight = resultStyle.lineHeight
        ? resultStyle.lineHeight
        : fontSize * 1.5;
    // remove font weight to not interfere with the font family
    delete resultStyle.fontWeight;
    // merge the final style
    resultStyle = StyleSheet.flatten([resultStyle, { fontFamily, lineHeight }]);
    const Container = Platform.select({
        android: Text,
        default: View,
    });
    let sign;
    let content;
    if (typeof children === 'number') {
        const num = children;
        content = formatAmount(Math.abs(num));
        if (num < 0)
            sign = '-';
    }
    if (typeof children === 'string') {
        const str = children;
        if (str.charAt(0) === '-') {
            content = str.substr(1, str.length);
            sign = str.substr(0, 1);
        }
    }
    return (<Container style={styles.numberContainer}>
      {sign ? (<Text style={resultStyle} allowFontScaling={false}>
          {sign}
        </Text>) : null}
      {currency &&
            (currency === 'GBP' ? (<CurrencySign currency={currency} size={fontSize * 0.723} color={fontColor}/>) : (<Text style={resultStyle} allowFontScaling={false}>
            {getSymbolFromCurrency(currency)}
          </Text>))}
      <Text style={resultStyle} allowFontScaling={false}>
        {content ?? children}
      </Text>
    </Container>);
};
const styles = StyleSheet.create({
    numberContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    numberText: {
        color: '#17102A',
        fontSize: rem(16),
    },
    numberTextLight: {
        color: 'rgb(255,255,255)',
        fontSize: rem(16),
    },
});
