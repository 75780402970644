import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import useColors from 'hooks/useColors';
import { IconWithGradient } from '../Icon';
const IconStatusSent = ({ ...props }) => {
    const colors = useColors();
    const id = useId();
    return (<IconWithGradient id={id} {...props} width={18} height={18} viewBox="0 0 18 18" defaultGradientKey="skyBlue">
      <Path d="M0.286624 6.70701C0.706221 3.35023 3.35023 0.706221 6.70701 0.286624C8.22974 0.096282 9.77026 0.096282 11.293 0.286624C14.6498 0.706221 17.2938 3.35023 17.7134 6.70701C17.9037 8.22974 17.9037 9.77026 17.7134 11.293C17.2938 14.6498 14.6498 17.2938 11.293 17.7134C9.77026 17.9037 8.22974 17.9037 6.70701 17.7134C3.35023 17.2938 0.706221 14.6498 0.286624 11.293C0.096282 9.77026 0.096282 8.22974 0.286624 6.70701Z" fill={`url(#gradient_${id})`}/>
      <Path d="M12.7159 9.78538C12.6766 9.82565 12.5285 9.99788 12.3905 10.1395C11.5814 11.0305 9.47063 12.4888 8.36587 12.934C8.1981 13.0055 7.77392 13.1569 7.54729 13.1666C7.33013 13.1666 7.12311 13.1166 6.92557 13.0152C6.67932 12.8736 6.48177 12.6507 6.37353 12.3875C6.30385 12.2048 6.19561 11.6583 6.19561 11.6486C6.08737 11.0507 6.02783 10.0791 6.02783 9.00552C6.02783 7.9826 6.08737 7.05065 6.17599 6.44371C6.18614 6.43399 6.29438 5.75482 6.41277 5.52218C6.62993 5.09718 7.05411 4.83329 7.50805 4.83329L7.54729 4.83329C7.84293 4.84371 8.46465 5.1076 8.46465 5.11732C9.50986 5.56315 11.5719 6.94996 12.4006 7.87149C12.4006 7.87149 12.634 8.10829 12.7355 8.25621C12.8938 8.46871 12.9723 8.7319 12.9723 8.9951C12.9723 9.28885 12.8837 9.56246 12.7159 9.78538Z" fill={colors.background.light}/>
    </IconWithGradient>);
};
export default IconStatusSent;
