import * as React from 'react';
import { View, TouchableBounce } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
const strings = {
    title: 'Can’t find your account?',
    purpleText: 'Connect new one',
};
const ConnectNewAccount = ({ onPress }) => {
    const styles = useStyles(styleSet);
    return (<TouchableBounce onPress={onPress}>
      <View style={styles.container}>
        <Text TextThin-14 Gray centered>
          {strings.title}
        </Text>
        <Text Text-14 Primary centered>
          {strings.purpleText}
        </Text>
      </View>
    </TouchableBounce>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        height: rem(64),
        justifyContent: 'center',
        borderRadius: rem(16),
        paddingHorizontal: rem(16),
        alignItems: 'center',
        backgroundColor: colors.background.dark,
    },
}));
export default ConnectNewAccount;
