import { CALL_API } from '../middleware/api';
import * as types from './types';
export const setCurrentGroupInitial = (data) => ({
    type: types.SET_CURRENT_GROUP,
    payload: data,
});
// TODO would be better to cache all groups normalised in a record
export const setCurrentGroupByID = (groupId) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/${groupId}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.SET_CURRENT_GROUP_BY_ID_REQUEST,
            types.SET_CURRENT_GROUP_BY_ID_SUCCESS,
            types.SET_CURRENT_GROUP_BY_ID_FAILURE,
        ],
    },
});
export const postGroupPicture = (image, groupId) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/${groupId}/image`,
        method: 'PATCH',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        body: image,
        types: [
            types.POST_GROUP_PIC_REQUEST,
            types.POST_GROUP_PIC_SUCCESS,
            types.POST_GROUP_PIC_FAILURE,
        ],
    },
});
export const deleteGroupPicture = (groupId) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/${groupId}/image`,
        method: 'DELETE',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        types: [
            types.POST_GROUP_PIC_REQUEST,
            types.POST_GROUP_PIC_SUCCESS,
            types.POST_GROUP_PIC_FAILURE,
        ],
    },
});
export const getGroups = () => ({
    [CALL_API]: {
        endpoint: '/spending-groups?withNetBalance=true&withUserIds=true',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_GROUPS_REQUEST,
            types.GET_GROUPS_SUCCESS,
            types.GET_GROUPS_FAILURE,
        ],
    },
});
export const saveGroupTransaction = (spendingGroupID, body) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/${spendingGroupID}/transactions`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        types: [
            types.SAVE_GROUP_TRANSACTION_REQUEST,
            types.SAVE_GROUP_TRANSACTION_SUCCESS,
            types.SAVE_GROUP_TRANSACTION_FAILURE,
        ],
    },
});
export const deleteGroupTransaction = (groupId, transactionId) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/${groupId}/transactions/${transactionId}`,
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.DELETE_GROUP_TRANSACTION_REQUEST,
            types.DELETE_GROUP_TRANSACTION_SUCCESS,
            types.DELETE_GROUP_TRANSACTION_FAILURE,
        ],
    },
});
export const createGroups = (body) => ({
    [CALL_API]: {
        endpoint: '/spending-groups',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        types: [
            types.CREATE_GROUP_REQUEST,
            types.CREATE_GROUP_SUCCESS,
            types.CREATE_GROUP_FAILURE,
        ],
    },
});
export const renameGroup = (groupId, newName) => {
    const body = { name: newName || '' };
    return {
        [CALL_API]: {
            endpoint: `/spending-groups/${groupId}`,
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
            types: [
                types.RENAME_GROUP_REQUEST,
                types.RENAME_GROUP_SUCCESS,
                types.RENAME_GROUP_FAILURE,
            ],
        },
    };
};
export const deleteGroup = (groupId) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/${groupId}`,
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.DELETE_GROUP_REQUEST,
            types.DELETE_GROUP_SUCCESS,
            types.DELETE_GROUP_FAILURE,
        ],
    },
});
export const deleteGroupUser = (groupId, userId, isAdmin) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/${groupId}/members/${userId}`,
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        extra: {
            groupId,
            userId,
            isAdmin,
        },
        types: [
            types.DELETE_GROUP_USER_REQUEST,
            types.DELETE_GROUP_USER_SUCCESS,
            types.DELETE_GROUP_USER_FAILURE,
        ],
    },
});
export const addPeopleToGroup = (id, body) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/${id}/add-members`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        types: [
            types.ADD_PEOPLE_TO_GROUP_REQUEST,
            types.ADD_PEOPLE_TO_GROUP_SUCCESS,
            types.ADD_PEOPLE_TO_GROUP_FAILURE,
        ],
    },
});
export const joinGroup = (accessCode) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/join/${accessCode}`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.JOIN_GROUP_REQUEST,
            types.JOIN_GROUP_SUCCESS,
            types.JOIN_GROUP_FAILURE,
        ],
    },
});
export const getGroupDetails = (id) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/${id}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_GROUP_DETAILS_REQUEST,
            types.GET_GROUP_DETAILS_SUCCESS,
            types.GET_GROUP_DETAILS_FAILURE,
        ],
    },
});
