import React, { memo } from 'react';
import { donateShortcut } from 'utils/packages/siriShortcut';
import { analytics } from 'utils/packages/rnFirebase';
import { TextButtonLinkSeeAll } from 'design-system/TextButton';
import Amplitude from 'utils/amplitude';
import Facebook from 'utils/facebook';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { setTransactionsRequesting } from 'actions';
import { useAppDispatch } from 'store/hooks';
import { transactionsActivityType } from '../../../../constants';
const TransactionsSeeAllButton = () => {
    const nav = useMainStackNavigation();
    const dispatch = useAppDispatch();
    const seeAllPressed = () => {
        const opts = {
            activityType: transactionsActivityType,
            title: 'View All Transactions',
            keywords: ['Transactions'],
            persistentIdentifier: transactionsActivityType,
            isEligibleForSearch: true,
            isEligibleForPrediction: true,
            suggestedInvocationPhrase: 'View All Transactions',
        };
        dispatch(setTransactionsRequesting);
        nav.navigate('TransactionsList');
        Amplitude.logEvent('Feed.OpenAllTransactions');
        Facebook.logEvent('Open All Transactions');
        analytics().logEvent('Open_All_Transactions');
        donateShortcut(opts);
    };
    return <TextButtonLinkSeeAll onPress={seeAllPressed}/>;
};
export default memo(TransactionsSeeAllButton);
