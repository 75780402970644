import { Dimensions, Platform, StyleSheet, } from 'react-native';
import { omitBy, pickBy } from 'lodash';
const { width } = Dimensions.get('window');
const { height } = Dimensions.get('window');
const isIphoneX = () => height >= 812 && Platform.OS === 'ios';
const isTallScreen = () => height / width > 1.78;
const hasSafeArea = Platform.OS === 'ios' && isTallScreen();
export const splitStyle = (style, options) => {
    const { removeSize = false, removeBackground = false, removeMargins = false, removeBorders = false, removeTransformations = false, removePositioning = false, removeShadows = false, } = options ?? {};
    const flat = StyleSheet.flatten(style);
    const outer = omitBy(flat, (_value, key) => key === 'alignContent' ||
        key === 'alignItems' ||
        key === 'aspectRatio' ||
        (removeSize && key.endsWith('eight')) ||
        key.startsWith('flex') ||
        (removeSize && key.endsWith('idth')) ||
        key === 'justifyContent' ||
        key === 'overflow' ||
        key.startsWith('padding') ||
        (removeBackground && key === 'backgroundColor') ||
        (removeMargins && key.startsWith('margin')) ||
        (removeBorders && key.startsWith('border')) ||
        (removeTransformations &&
            (key.startsWith('transform') ||
                key === 'rotation' ||
                key.startsWith('scale') ||
                key.startsWith('translate'))) ||
        (removePositioning &&
            (key === 'alignSelf' ||
                key === 'bottom' ||
                key === 'elevation' ||
                key === 'end' ||
                key === 'left' ||
                key === 'position' ||
                key === 'right' ||
                key === 'start' ||
                key === 'top' ||
                key === 'zIndex')) ||
        (removeShadows && key.startsWith('shadow')));
    const inner = pickBy(flat, (_value, key) => key === 'alignContent' ||
        key === 'alignItems' ||
        key === 'aspectRatio' ||
        key.startsWith('flex') ||
        key === 'justifyContent' ||
        key === 'overflow' ||
        key.startsWith('padding'));
    return [outer, inner];
};
export default {
    isIphoneX,
    isTallScreen,
    hasSafeArea,
};
