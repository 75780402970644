import React, { useCallback } from 'react';
import { setDeviceSettingsKey } from 'actions/deviceSettings';
import NotificationBanner from 'design-system/NotificationBanner';
import { useAppDispatch } from 'store/hooks';
import { useSmartRulesNavigation } from 'hooks/useSmartRulesNavigation';
const SmartRuleCard = () => {
    const onPressSmartRules = useSmartRulesNavigation();
    const dispatch = useAppDispatch();
    const onCancel = useCallback(() => {
        dispatch(setDeviceSettingsKey('didCloseSmartRulesCard', true));
    }, []);
    return (<NotificationBanner noMarginBottom noMarginTop={false} title="Set a Smart Rule" cancelButtonTitle="Dismiss" positiveButtonTitle="Learn how" onPositiveBtnPress={onPressSmartRules} onCancel={onCancel} description="Whenever you transfer money from a linked account to an offline one, Emma can update the balance for you"/>);
};
export default SmartRuleCard;
