import React, { useCallback, useMemo, useRef } from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import getSymbolFromCurrency from 'currency-symbol-map';
import { rem } from 'design-system';
import Text, { styles as textStyles } from 'design-system/Text';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { TextInputMask, } from 'react-native-masked-text';
import createStyleSheets from 'utils/createStyleSheets';
var Sign;
(function (Sign) {
    Sign["Positive"] = "";
    Sign["Negative"] = "-";
})(Sign || (Sign = {}));
const PoistiveNegativeBalanceInput = ({ sign, currency, value, maxLength, onChangeText, onSubmitEditing, }) => {
    const styles = useStyles(styleSet);
    const colors = useColors();
    const [isFocused, setIsFocused] = React.useState(false);
    const handleFocus = useCallback(() => {
        setIsFocused(true);
    }, []);
    const handleBlur = useCallback(() => {
        setIsFocused(false);
    }, []);
    const inputStyle = useMemo(() => [
        styles.textInput,
        { width: `${value.length + 1}ch` },
    ], [styles.textInput, value.length]);
    const ref = useRef(null);
    const onPress = useCallback(() => {
        ref.current?.getElement().focus();
    }, []);
    return (<TouchableWithoutFeedback onPress={onPress}>
      <View style={[styles.container, isFocused && styles.focusedStyles]}>
        {sign === Sign.Negative && <Text Numbers-24>-</Text>}
        <TextInputMask ref={ref} style={inputStyle} type="money" allowFontScaling={false} underlineColorAndroid="transparent" options={{
            unit: getSymbolFromCurrency(currency),
            separator: '.',
            delimiter: ',',
            precision: 2,
        }} keyboardType="number-pad" onChangeText={onChangeText} includeRawValueInChangeText value={value} maxLength={maxLength} selectionColor={colors.text.selection} placeholderTextColor={colors.text.placeholder} onSubmitEditing={onSubmitEditing} onFocus={handleFocus} onBlur={handleBlur}/>
      </View>
    </TouchableWithoutFeedback>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        borderWidth: rem(1),
        borderRadius: rem(8),
        paddingVertical: rem(8),
        paddingHorizontal: rem(16),
        borderColor: colors.borders.divider,
        backgroundColor: colors.cards.onDark,
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
        cursor: 'text',
    },
    textInput: {
        flexDirection: 'row',
        ...textStyles.Numbers_24,
        color: colors.text.primary,
        alignSelf: 'flex-start',
        textAlign: 'left',
        fontFamily: 'Montserrat-Regular',
        caretColor: colors.text.selection,
    },
    focusedStyles: {
        borderColor: colors.elements.brand,
    },
}));
export default PoistiveNegativeBalanceInput;
