import React, { useCallback, useLayoutEffect } from 'react';
import { View } from 'react-native';
import { StackActions } from '@react-navigation/native';
import Text from 'design-system/Text';
import Button from 'design-system/Button';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import Squircle from 'design-system/Squircle';
import { AppView } from 'design-system/AppView';
import { CardView } from 'design-system/CardView';
import { IconBell } from 'design-system/IconBell';
import IconEyes from 'design-system/icons/IconEyes';
import { IconShieldDone } from 'design-system/icons';
import { SettingsItem } from 'design-system/SettingsItem';
import IconPrediction from 'design-system/icons/IconPrediction';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { selectHasSetBudget } from 'reducers/selectors';
import createStyleSheets from 'utils/createStyleSheets';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setDeviceSettingsKey } from 'actions/deviceSettings';
const TRUE_BALANCE_ITEMS = [
    {
        icon: <IconEyes />,
        title: 'Track recurring payments',
        description: 'See what payments you have upcoming ',
    },
    {
        icon: <IconBell gradientKey="lightPink"/>,
        title: 'Calculate real balance',
        description: 'Emma subtracts these payments from your available balance to give you an accurate picture of what you have left to spend',
    },
    {
        icon: <IconShieldDone gradientKey="darkPurple"/>,
        title: 'Stay in control',
        description: 'With True Balance, you can easily see how much money you have left to spend',
    },
];
const TrueBalanceIntro = ({ route, navigation, }) => {
    const colors = useColors();
    const dispatch = useAppDispatch();
    const styles = useStyles(styleSet);
    const hasSetBudget = useAppSelector(selectHasSetBudget);
    useLayoutEffect(() => {
        navigation.setOptions({
            headerTintColor: colors.elements.primary,
        });
    }, [colors.elements.primary]);
    const handlePress = useCallback(() => {
        if (hasSetBudget) {
            const resetAction = StackActions.replace('TrueBalance', {
                id: route.params.id,
            });
            navigation.dispatch({
                ...resetAction,
            });
        }
        else if (route.params.keyBack) {
            const resetAction = StackActions.replace('EditBudget', {
                flow: 'Set',
                keyBack: route.params.keyBack,
            });
            navigation.dispatch({
                ...resetAction,
            });
        }
        else {
            navigation.goBack();
        }
        dispatch(setDeviceSettingsKey('didViewTrueBalanceIntro', true));
    }, [hasSetBudget, route.params.id, route.params.keyBack]);
    return (<AppView paddingBottom>
      <View style={styles.container}>
        <View>
          <Spacer h={31}/>
          <CardView noMarginTop>
            <View style={styles.titleView}>
              <Text Text-20 centered>
                See your true balance
              </Text>
              <Text TextThin-14 Secondary centered>
                Take control of your finances
              </Text>
            </View>
            {TRUE_BALANCE_ITEMS.map((item) => (<SettingsItem key={item.title} icon={item.icon} title={item.title} description={item.description}/>))}
          </CardView>
          <View style={styles.squircleContainer}>
            <Squircle width={rem(46)} height={rem(46)} color={colors.background.dark}>
              <Squircle color={colors.cards.onLight}>
                <IconPrediction colorKey="brand"/>
              </Squircle>
            </Squircle>
          </View>
        </View>
      </View>
      <View style={styles.buttonContainer}>
        <Button brand onPress={handlePress} title="Continue"/>
      </View>
    </AppView>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
        justifyContent: 'center',
    },
    titleView: {
        padding: rem(16),
        marginTop: rem(23),
        borderBottomWidth: rem(1),
        borderBottomColor: colors.borders.divider,
    },
    squircleContainer: {
        left: 0,
        right: 0,
        top: rem(8),
        position: 'absolute',
        alignItems: 'center',
    },
    buttonContainer: {
        paddingHorizontal: rem(16),
    },
}));
export default TrueBalanceIntro;
