import React from 'react';
import { View, StyleSheet } from 'react-native';
import Text from 'design-system/Text';
import { rem, Spacer } from 'design-system';
const Legend = ({ color, title, metrics, containerStyles, }) => (<View style={[styles.container, containerStyles]}>
    <View style={[styles.colorView, { backgroundColor: color }]}/>
    <Spacer w={16}/>
    <View>
      <Text TextThin-14 Secondary numberOfLines={1}>
        {title}
      </Text>
      {typeof metrics === 'string' ? (<Text Numbers-16 numberOfLines={1}>
          {metrics}
        </Text>) : (metrics)}
    </View>
  </View>);
export default Legend;
const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        flexDirection: 'row',
    },
    colorView: {
        width: rem(8),
        height: rem(8),
        borderRadius: rem(8),
    },
});
