import React, { memo, useCallback, useMemo, useRef } from 'react';
import { View } from 'react-native';
import Animated, { withTiming, useAnimatedStyle, } from 'react-native-reanimated';
import TouchableBounce from 'utils/packages/TouchableBounce';
import { hitSlop, rem } from 'design-system/values';
import { PickerModal } from 'design-system/picker/PickerModal';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { ChartType, CHART_TYPE_ICONS, CHART_TYPE_TITLE, } from '../types';
import AssetsVsDebtInfo from './overview/AssetsVsDebtInfo';
import { isWeb } from '../../../constants';
export const SELECTOR_HEIGHT = rem(40 + 16);
export const CHART_TYPE_OPTIONS = [
    {
        value: ChartType.line,
        icon: CHART_TYPE_ICONS[ChartType.line],
        label: CHART_TYPE_TITLE[ChartType.line],
    },
    {
        value: ChartType.pie,
        icon: CHART_TYPE_ICONS[ChartType.pie],
        label: CHART_TYPE_TITLE[ChartType.pie],
    },
    {
        value: ChartType.assetVsDebt,
        icon: CHART_TYPE_ICONS[ChartType.assetVsDebt],
        label: CHART_TYPE_TITLE[ChartType.assetVsDebt],
    },
];
const GraphTypeSelector = ({ type, title, setType, selectedInfoObj, selectedInfoObjState, selectedInfoObj2, selectedInfoObj2State, blurValues, }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const icon = React.cloneElement(CHART_TYPE_ICONS[type], {
        colorKey: undefined,
        gradientKey: undefined,
        color: colors.text.secondary,
    });
    const Options = useMemo(() => {
        if (title === 'Net Worth - Main') {
            return [CHART_TYPE_OPTIONS[0], CHART_TYPE_OPTIONS[2]];
        }
        return [CHART_TYPE_OPTIONS[0], CHART_TYPE_OPTIONS[1]];
    }, [title]);
    const viewRef = useRef(null);
    const handlePress = useCallback(() => {
        PickerModal.show({
            hideAfterCallback: true,
            pickerOptions: Options,
            selectedIconFill: colors.gradients.lightPink.colors[0],
            selectedValue: CHART_TYPE_OPTIONS.find((item) => item.value === type),
            onSelected: (selected) => {
                setType(selected.value);
            },
        }, {
            title: 'Chart type',
            ...(isWeb && {
                containerStyle: {
                    //@ts-ignore
                    'box-shadow': '5px 10px 40px 0px rgba(19, 48, 104, 0.07)',
                },
            }),
        }, viewRef);
    }, [Options, colors.gradients.lightPink.colors, setType, type]);
    const animatedStyles = useAnimatedStyle(() => ({
        opacity: withTiming(title === 'Everyday' ? 0 : 1),
    }), [title]);
    return (<View style={styles.graphTypeSelectorContainer}>
      <AssetsVsDebtInfo type={type} title={title} selectedInfoObj={selectedInfoObj} selectedInfoObjState={selectedInfoObjState} selectedInfoObj2={selectedInfoObj2} selectedInfoObj2State={selectedInfoObj2State} blurValues={blurValues}/>
      <View style={styles.graphBtnWrapper}>
        <Animated.View style={animatedStyles}>
          <TouchableBounce hitSlop={hitSlop} onPress={handlePress} disabled={title === 'Everyday'} ref={viewRef}>
            <View style={styles.container}>{icon}</View>
          </TouchableBounce>
        </Animated.View>
      </View>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        width: rem(40),
        height: rem(40),
        alignItems: 'center',
        borderRadius: rem(8),
        justifyContent: 'center',
        backgroundColor: colors.background.dark,
    },
    graphTypeSelectorContainer: {
        left: 0,
        right: 0,
        zIndex: 1,
        top: rem(16),
        position: 'absolute',
        flexDirection: 'row',
        paddingLeft: rem(16),
        paddingRight: rem(16),
        height: SELECTOR_HEIGHT,
    },
    graphBtnWrapper: {
        justifyContent: 'center',
    },
}));
export default memo(GraphTypeSelector);
