import React, { useCallback, useRef } from 'react';
import { LayoutAnimation, Platform, } from 'react-native';
import { rem } from 'design-system/values';
import { IconActivity } from 'design-system/icons';
import IconGraph from 'design-system/icons/IconGraph';
import IconPieChart from 'design-system/icons/IconPieChart';
import { PickerModal } from 'design-system/picker/PickerModal';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { useAppDispatch } from 'store/hooks';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableBounce from 'utils/packages/TouchableBounce';
import { ChartType } from '../types';
import { setChartType } from '../actions';
export const CHART_TYPE_ICONS = {
    [ChartType.bar]: <IconGraph gradientKey="darkPurple"/>,
    [ChartType.linear]: <IconActivity alt gradientKey="apricot"/>,
    [ChartType.pie]: <IconPieChart gradientKey="deepPink"/>,
};
export const CHART_TYPE_OPTIONS = [
    {
        label: 'Income vs spending',
        value: ChartType.bar,
        icon: CHART_TYPE_ICONS[ChartType.bar],
    },
    {
        label: 'Spend over time',
        value: ChartType.linear,
        icon: CHART_TYPE_ICONS[ChartType.linear],
    },
    {
        label: 'Category pie chart',
        value: ChartType.pie,
        icon: CHART_TYPE_ICONS[ChartType.pie],
    },
];
const ChartViewSwitcher = ({ currentChart, }) => {
    const viewRef = useRef(null);
    const dispatch = useAppDispatch();
    const colors = useColors();
    const styles = useStyles(styleSet);
    const icon = React.cloneElement(CHART_TYPE_ICONS[currentChart], {
        color: colors.text.secondary,
        gradientKey: undefined,
    });
    const onToggleChartType = useCallback(() => {
        PickerModal.show({
            pickerOptions: CHART_TYPE_OPTIONS,
            selectedIconFill: colors.elements.negative,
            selectedValue: CHART_TYPE_OPTIONS.find((item) => item.value === currentChart),
            onSelected: (selected) => {
                if (Platform.OS === 'ios') {
                    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
                }
                dispatch(setChartType(selected.value));
            },
        }, { title: 'Chart type' }, viewRef);
    }, [colors.elements.negative, currentChart]);
    return (<TouchableBounce ref={viewRef} onPress={onToggleChartType} style={styles.selectorContainer} hoverStyle={styles.hoverOnLight}>
      {icon}
    </TouchableBounce>);
};
const styleSet = createStyleSheets((colors) => ({
    selectorContainer: {
        margin: rem(16),
        paddingVertical: rem(8),
        paddingHorizontal: rem(8),
        backgroundColor: colors.background.dark,
        alignSelf: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: rem(8),
    },
    hoverOnLight: {
        backgroundColor: colors.control.hoverOnLight,
    },
}));
export default ChartViewSwitcher;
