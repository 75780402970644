export default {
    API_URL: process.env.API_URL,
    SENTRY_DSN: process.env.SENTRY_DSN,
    PUSHANDROID: process.env.PUSHANDROID,
    ONE_SIGNAL: process.env.ONE_SIGNAL,
    ONE_SIGNAL_NUMBER: process.env.ONE_SIGNAL_NUMBER,
    FBID: process.env.FBID,
    STRIPE_ENV: process.env.STRIPE_ENV,
    STRIPE_ACCOUNT_ID: process.env.STRIPE_ACCOUNT_ID,
    STRIPE_MERCHANT_ID: process.env.STRIPE_MERCHANT_ID,
    STRIPE_PUBLISHABLE_KEY: process.env.STRIPE_PUBLISHABLE_KEY,
    FBAKTOKEN: process.env.FBAKTOKEN,
    CRISP_ID: process.env.CRISP_ID,
    AMPLITUDE_KEY: process.env.AMPLITUDE_KEY,
    PLAID_PUBLIC_KEY: process.env.PLAID_PUBLIC_KEY,
    APPLE_SERVICE: process.env.APPLE_SERVICE,
    STRIPE_KEY: process.env.STRIPE_KEY,
    ENABLE_INVEST_LOGS: process.env.ENABLE_INVEST_LOGS,
    GOOGLE_IOS_ID: process.env.GOOGLE_IOS_ID,
    GOOGLE_WEB_ID: process.env.GOOGLE_WEB_ID,
    BLOCK_EMULATED_ENVS: process.env.BLOCK_EMULATED_ENVS,
    IOS_APP_ID: process.env.IOS_APP_ID,
    APPSFLYER_DEV_KEY: process.env.APPSFLYER_DEV_KEY,
};
