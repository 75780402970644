export function extractManualAccounts(data, closed, spaceId) {
    /* eslint-enable no-redeclare */
    const manualAccounts = {};
    const closedAccountsArray = [];
    for (let index = 0; index < data.length; index += 1) {
        for (let x = 0; x < data[index].accounts.length; x += 1) {
            const account = { ...data[index].accounts[x] };
            if ((!spaceId || spaceId === account.spaceId) &&
                account.provider === 'MANUAL') {
                account.type = !account.hasBalanceHistory ? 'OTHER' : account.type;
                account.lastSuccessfulSync = data[index].lastSuccessfulSync;
                if (closed === null || (!closed && !account.isClosed)) {
                    const accounts = manualAccounts[account.type];
                    if (accounts) {
                        accounts.push(account);
                    }
                    else {
                        manualAccounts[account.type] = [account];
                    }
                }
                else if (closed && account.isClosed) {
                    closedAccountsArray.push(account);
                }
            }
        }
    }
    if (closed) {
        return closedAccountsArray;
    }
    return manualAccounts;
}
