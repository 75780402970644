import React, { useCallback } from 'react';
import { FlatList } from 'react-native';
import { selectMerchants } from 'reducers/selectors';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import { useAppSelector } from 'store/hooks';
import KeyboardMerchantsRow from './KeyboardMerchantsRow';
import EmptyComponent from './KeyboardEmpty';
import { isDesktopOrWeb } from '../../../../constants';
const strings = {
    empty: 'There are no merchants available in any of your transactions',
};
const keyExtractor = (item) => item.id.toString();
const KeyboardMerchants = ({ onSelect, contentContainerStyle }) => {
    const data = useAppSelector(selectMerchants);
    const renderItem = useCallback(({ item }) => (<KeyboardMerchantsRow item={item} onPress={() => {
            onSelect('KeyboardCategories', {
                ...item,
                badgeText: item.displayName,
                filterType: 'MERCHANT',
            });
        }}/>), [onSelect]);
    const styles = useStyles(styleSet);
    return (<FlatList style={styles.flatList} contentContainerStyle={[styles.contentContainerStyle, contentContainerStyle]} data={data} renderItem={renderItem} keyExtractor={keyExtractor} ListEmptyComponent={<EmptyComponent description={strings.empty}/>}/>);
};
const styleSet = createStyleSheets((colors) => ({
    contentContainerStyle: {
        paddingBottom: isDesktopOrWeb ? 16 : 50,
    },
    flatList: {
        backgroundColor: colors.background.light,
        ...(isDesktopOrWeb && {
            padding: 8,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            backgroundColor: colors.cards.onDark,
        }),
    },
}));
export default KeyboardMerchants;
