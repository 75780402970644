import React, { memo, useEffect, useImperativeHandle, useMemo, useState, } from 'react';
import { View, StyleSheet, Text, } from 'react-native';
import { useEventBusEffect } from 'hooks';
import { busEvents } from 'features/pay/constants';
import { formatAmount } from 'utils/formatting';
import useColors from 'hooks/useColors';
import Spacer from './Spacer';
import { rem, correct } from './values';
import ShakeContainer from './ShakeContainer';
import MoneyInputField from './MoneyInputField';
import MoneyInputPinPad from './MoneyInputPinPad';
const captions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0', '<'];
const LIMIT = 1000000;
const MANTISSA = 2;
const MoneyInput = React.forwardRef(({ onValueChange, registerShakeCallback, colors, min, restrictAmountsLessThanOne, max, showMaxHint, showMinHint, flex, onExceedMax, initialValue, header, numPadHeader, inputFieldStyle, pinPadStyle, loading, children, hideInitialMinAmountText, maxAmountPrefix, }, ref) => {
    const appColors = useColors();
    const shakeRef = React.useRef();
    const [value, setValue] = useState(initialValue ?? 0);
    const [fractionDigits, setFractionDigits] = useState(0);
    const [hideMinAmount, setHideMinAmount] = useState(hideInitialMinAmountText || false);
    useImperativeHandle(ref, () => ({
        clear: () => {
            shakeRef.current?.();
            setValue(0);
        },
        setAmount: (amount) => {
            if (amount > (max ?? LIMIT)) {
                onExceedMax?.();
                shakeRef.current?.();
            }
            else {
                setValue(amount);
            }
        },
        shake: () => {
            shakeRef.current?.();
        },
    }));
    const displayValue = useMemo(() => {
        if (fractionDigits === 0)
            return formatAmount(value);
        const offset = fractionDigits > 1 ? 1 : 0;
        const formatted = formatAmount(value, undefined, Math.min(fractionDigits - offset, MANTISSA));
        if (fractionDigits === 1)
            return formatted.substring(0, formatted.length - 1);
        return formatted;
    }, [value, fractionDigits]);
    const { input, pinpad } = colors ?? {};
    useEffect(() => {
        onValueChange?.(value);
    }, [value, onValueChange]);
    useEventBusEffect(() => {
        setValue(0);
    }, [
        busEvents.PAYMENT_SENT,
        busEvents.REQUEST_CREATED,
        busEvents.REQUEST_FULFILLED,
    ]);
    const onInput = (text) => {
        switch (text) {
            case '<':
                switch (fractionDigits) {
                    case 0:
                        if (value === 0)
                            shakeRef.current?.();
                        else
                            setValue(Math.floor(value / 10));
                        break;
                    case 1:
                        setFractionDigits(fractionDigits - 1);
                        break;
                    default:
                        const shift = 10 ** (fractionDigits - 2);
                        const newValue = Math.floor(value * shift) / shift;
                        setFractionDigits(fractionDigits - 1);
                        setValue(newValue);
                }
                break;
            case '.':
                if (restrictAmountsLessThanOne && value === 0) {
                    setHideMinAmount(false);
                    shakeRef.current?.();
                }
                else if (fractionDigits === 0) {
                    setFractionDigits(1);
                }
                else {
                    shakeRef.current?.();
                }
                break;
            default:
                let newValue = value;
                if (fractionDigits === 0) {
                    newValue = value * 10 + Number.parseInt(text, 10);
                }
                else if (fractionDigits < 3) {
                    setFractionDigits(fractionDigits + 1);
                    const shift = 10 ** fractionDigits;
                    newValue = value + Number.parseInt(text, 10) / shift;
                }
                newValue = Number(newValue.toFixed(2));
                if ((newValue === value && fractionDigits > 2) ||
                    newValue < 0 ||
                    newValue > (max ?? LIMIT)) {
                    shakeRef.current?.();
                    onExceedMax?.();
                }
                else {
                    setValue(newValue);
                }
        }
    };
    const registerCallback = (callback) => {
        shakeRef.current = callback;
        registerShakeCallback?.(callback);
    };
    const maxPrefix = maxAmountPrefix ?? 'Maximum amount is ';
    const hint = useMemo(() => {
        const hintColor = colors?.hintColor;
        const colorStyles = { color: hintColor ?? appColors.text.white };
        if (max !== undefined && showMaxHint) {
            if (min && value < min && showMinHint && !hideMinAmount) {
                return (<Text style={[styles.hint, colorStyles]}>
              Minimum amount is{' '}
              <Text style={[styles.hintNunito, colorStyles]}>
                {formatAmount(min, 'GBP', 2)}
              </Text>
            </Text>);
            }
            return (<Text style={[styles.hint, colorStyles]}>
            {maxPrefix}
            <Text style={[styles.hintNunito, colorStyles]}>
              {formatAmount(max, 'GBP', 2)}
            </Text>
          </Text>);
        }
        if (min && showMinHint && !hideMinAmount) {
            return (<Text style={[styles.hint, colorStyles]}>
            Minimum amount is{' '}
            <Text style={[styles.hintNunito, colorStyles]}>
              {formatAmount(min, 'GBP', 2)}
            </Text>
          </Text>);
        }
        return null;
    }, [
        colors?.hintColor,
        appColors.text.white,
        max,
        showMaxHint,
        min,
        showMinHint,
        hideMinAmount,
        value,
        maxPrefix,
    ]);
    return (<View style={[styles.container, flex && styles.flex]}>
        {header || (flex && <View style={styles.flex}/>)}
        <ShakeContainer shakeOnMount={value === 0} registerCallback={registerCallback}>
          <MoneyInputField style={[inputFieldStyle]} color={input}>
            {displayValue}
          </MoneyInputField>
        </ShakeContainer>
        {hint}
        {numPadHeader ?? <Spacer flex/>}
        {children}
        <View pointerEvents={loading ? 'none' : 'auto'}>
          <MoneyInputPinPad style={[styles.pinpad, pinPadStyle]} captions={captions} onInput={onInput} color={pinpad}/>
        </View>
      </View>);
});
const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    flex: {
        flex: 1,
    },
    pinpad: {
        marginTop: correct(25.5),
    },
    hint: {
        fontFamily: 'Montserrat-Light',
        fontSize: rem(14),
        letterSpacing: 0.5,
        alignSelf: 'center',
        textAlign: 'center',
    },
    hintNunito: {
        fontFamily: 'Nunito-Regular',
        fontSize: rem(14),
        letterSpacing: 0.5,
    },
});
export default memo(MoneyInput);
