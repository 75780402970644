import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { rem } from 'design-system';
import ListEmptyView from 'design-system/ListEmptyView';
const UnbudgetedCategoriesListEmpty = () => (<View style={styles.container}>
    <ListEmptyView compact title="No spend left" subTitle="It’s all good, this means you have set budgets for all your current spending categories!"/>
  </View>);
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: rem(24),
        marginBottom: rem(24),
    },
});
export default memo(UnbudgetedCategoriesListEmpty);
