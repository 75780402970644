import { rem } from 'design-system/values';
export const getTan = (radian) => Math.tan((radian * Math.PI) / 180);
export const getSin = (radian) => Math.sin((radian * Math.PI) / 180);
export const getCos = (radian) => Math.cos((radian * Math.PI) / 180);
const RADIUS = rem(150);
export const getFinalPoint = (theta, r) => {
    switch (true) {
        case theta <= 45: {
            const p = r * getTan(theta);
            return {
                x: r + p,
                y: 0,
                finalPoint: `${r + p},0`,
            };
        }
        case theta <= 90: {
            const p = r * getTan(90 - theta);
            return {
                x: 2 * r,
                y: r - p,
                finalPoint: `${2 * r},${r - p}`,
            };
        }
        case theta <= 135: {
            const p = r * getTan(theta - 90);
            return {
                x: 2 * r,
                y: r + p,
                finalPoint: `${2 * r},${r + p}`,
            };
        }
        case theta <= 180: {
            const p = r * getTan(180 - theta);
            return {
                x: r + p,
                y: 2 * r,
                finalPoint: `${r + p},${2 * r}`,
            };
        }
        case theta <= 225: {
            const p = r * getTan(theta - 180);
            return {
                x: r - p,
                y: 2 * r,
                finalPoint: `${r - p},${2 * r}`,
            };
        }
        case theta <= 270: {
            const p = r * getTan(270 - theta);
            return {
                x: 0,
                y: r + p,
                finalPoint: `0,${r + p}`,
            };
        }
        case theta <= 315: {
            const p = r * getTan(theta - 270);
            return {
                x: 0,
                y: r - p,
                finalPoint: `0,${r - p}`,
            };
        }
        default: {
            const p = r * getTan(360 - theta);
            return {
                x: r - p,
                y: 0,
                finalPoint: `${r - p},0`,
            };
        }
    }
};
export const getMidPoints = (prevTheta, theta, r) => {
    let midPoints = '';
    switch (true) {
        case theta >= 315: {
            if (prevTheta < 315) {
                midPoints += ` 0,0`;
            }
            if (prevTheta < 225) {
                midPoints += ` 0,${2 * r}`;
            }
            if (prevTheta < 135) {
                midPoints += ` ${2 * r},${2 * r}`;
            }
            if (prevTheta < 45) {
                midPoints += ` ${2 * r},0`;
            }
            return midPoints;
        }
        case theta >= 225: {
            if (prevTheta < 225) {
                midPoints += ` 0,${2 * r}`;
            }
            if (prevTheta < 135) {
                midPoints += ` ${2 * r},${2 * r}`;
            }
            if (prevTheta < 45) {
                midPoints += ` ${2 * r},0`;
            }
            return midPoints;
        }
        case theta >= 135: {
            if (prevTheta < 135) {
                midPoints += ` ${2 * r},${2 * r}`;
            }
            if (prevTheta < 45) {
                midPoints += ` ${2 * r},0`;
            }
            return midPoints;
        }
        case theta >= 45: {
            if (prevTheta < 45) {
                midPoints += ` ${2 * r},0`;
            }
            return midPoints;
        }
        default: {
            return midPoints;
        }
    }
};
export const getLabelPosition = (labelTheta, r) => {
    switch (true) {
        case labelTheta >= 270:
            return {
                top: RADIUS - r * getSin(labelTheta - 270) - 15,
                left: RADIUS - r * getCos(labelTheta - 270) - 15,
            };
        case labelTheta >= 180:
            return {
                left: RADIUS - r * getSin(labelTheta - 180) - 15,
                top: RADIUS + r * getCos(labelTheta - 180) - 15,
            };
        case labelTheta >= 90:
            return {
                left: r * getSin(labelTheta) + RADIUS - 15,
                top: RADIUS - r * getCos(labelTheta) - 15,
            };
        default:
            return {
                top: RADIUS - r * getCos(labelTheta) - 15,
                left: RADIUS + r * getSin(labelTheta) - 15,
            };
    }
};
export const getSmartPieSlice = (pieSlices, onSelectPieSlice) => {
    let total = 0;
    const data = [];
    pieSlices.forEach((slice) => {
        if (slice.value) {
            total += slice.value;
            data.push(slice);
        }
    });
    let thetaSum = 0;
    let lastPoint = `${RADIUS},${0}`;
    const modified = [];
    data.forEach((slice) => {
        const theta = (slice.value / total) * 360;
        if (theta < 4) {
            return;
        }
        const prevTheta = thetaSum;
        thetaSum += theta;
        const { finalPoint, x: finalX, y: finalY, } = getFinalPoint(thetaSum, RADIUS);
        const midPoints = getMidPoints(prevTheta, thetaSum, RADIUS);
        const polygonPoints = `${RADIUS},${RADIUS} ${finalPoint} ${midPoints} ${lastPoint}`;
        modified.push({
            ...slice,
            finalX,
            finalY,
            theta,
            polygonPoints,
            labelTheta: prevTheta + theta / 2,
            onPress: () => onSelectPieSlice?.(slice),
        });
        lastPoint = finalPoint;
    });
    return modified;
};
