import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState, useRef } from 'react';
import { View, ScrollView, TextInput, TouchableOpacity, Platform, Animated as RNAnimated } from 'react-native';
import Animated from 'react-native-reanimated';
import isNil from 'lodash/isNil';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import TotalAmountRow from 'features/feed/components/search/TotalAmountRow';
import useStyles from 'hooks/useStyles';
import StatusBar from 'design-system/StatusBar';
import useColors from 'hooks/useColors';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { sanitizeQuotes } from 'utils/text/sanitizeQuotes';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useAppSelector } from 'store/hooks';
import { IconSearch } from 'design-system/icons';
import IconClose from './icons/IconClose';
import Filters from './Filters';
import useCheckFilter from './hooks/useCheckFilter';
import useKeyPress from './hooks/useKeyPress';
import useAnimatedStyles from './hooks/useAnimatedStyles';
import useAmountHandlers from './hooks/useAmountHandlers';
import { isWeb } from '../../../../constants';
import styleSet, { MARGIN_HORIZONTAL, CLEAR_TOUCH_ZONE_WIDTH, CANCEL_MARGIN } from './styles';
const HIT_SLOP = { left: 10, right: 10, top: 10, bottom: 10 };
const ICON_SEARCH_SIZE = rem(24);
const ICON_SEARCH_LEFT_MARGIN_RAW = 16;
const ICON_SEARCH_RIGHT_MARGIN_RAW = 8;
const strings = {
    search: 'Search...',
    cancel: 'Cancel',
};
const DEFAULT_STATE = {
    textValue: '',
    amountLowerValue: '',
    amountUpperValue: '',
};
const SearchHeaderFiltered = forwardRef(({ activeFilter, totalAmount, refSearchBar, delayFocusTime, refAmountLowerInput, refAmountUpperInput, refSearchToolbar, headerStyles, currencySymbol, onToggleAmountFilter, onInputFocus, onChangeText, onCancel, onSelectFilter, onRemoveFilter, }, ref) => {
    const scrollView = useRef(null);
    const onContentSizeChange = useCallback(() => {
        scrollView.current?.scrollToEnd({ animated: true });
    }, []);
    const [state, setState] = useState(DEFAULT_STATE);
    const currency = useAppSelector((store) => store.user.user.currency);
    const { top } = useSafeAreaInsets();
    const onSetDefaultAmount = useCallback((amount) => {
        setState((currentState) => ({ ...currentState, amountUpperValue: amount }));
    }, []);
    useImperativeHandle(ref, () => ({
        onSetDefaultAmount,
    }));
    const checkFilter = useCheckFilter(onSelectFilter);
    const handleChangeText = useCallback((textValue) => {
        const textWithoutFilter = checkFilter(sanitizeQuotes(textValue));
        onChangeText(textWithoutFilter);
        setState((currentState) => ({
            ...currentState,
            textValue: textWithoutFilter,
        }));
    }, [checkFilter, onChangeText]);
    const clearSearch = useCallback(() => {
        setState(DEFAULT_STATE);
        onToggleAmountFilter();
        onRemoveFilter('CLEAR_FILTERS');
    }, [onToggleAmountFilter, onRemoveFilter]);
    const handleClearPress = useCallback(() => {
        clearSearch();
        refSearchBar.current?.focus();
    }, [clearSearch, refSearchBar]);
    const [searchBarAnimatedStyle, cancelAnimatedStyle, cancelWidth, onCancelLayout, handleCancel] = useAnimatedStyles(onCancel, clearSearch, refSearchBar, delayFocusTime);
    const onToggleSelectedFilter = useCallback((item) => {
        if (item.filterType === 'AMOUNT_UPPER') {
            refAmountUpperInput.current?.focus();
            refSearchToolbar.current?.showSpecialKeyboard('KeyboardAmountUpper');
        }
        else if (item.filterType === 'AMOUNT_LOWER') {
            refAmountLowerInput.current?.focus();
            refSearchToolbar.current?.showSpecialKeyboard('KeyboardAmountLower');
        }
        else {
            onRemoveFilter(item);
        }
    }, [refAmountUpperInput, refSearchToolbar, refAmountLowerInput, onRemoveFilter]);
    const onRemoveItem = useCallback((item) => {
        onRemoveFilter(item);
        if (item !== 'CLEAR_FILTERS') {
            if (item.filterType === 'AMOUNT_LOWER') {
                setState((currentState) => ({
                    ...currentState,
                    amountLowerValue: '',
                }));
            }
            if (item.filterType === 'AMOUNT_UPPER') {
                setState((currentState) => ({
                    ...currentState,
                    amountUpperValue: '',
                }));
            }
        }
    }, [onRemoveFilter]);
    const [onChangeAmountLower, onChangeAmountUpper, onResetLowerAmount, onResetUpperAmount, hideAmountInput] = useAmountHandlers(setState, onSelectFilter, onToggleAmountFilter, currencySymbol);
    const onKeyPress = useKeyPress(state.textValue, activeFilter, onRemoveFilter, onResetLowerAmount, onResetUpperAmount);
    const handleInputFocus = useCallback(() => {
        hideAmountInput();
        onInputFocus();
    }, [hideAmountInput, onInputFocus]);
    const { width } = useAppFrameDimensions();
    let textInputWidth;
    const filterLength = Object.keys(activeFilter).length;
    const placeholder = activeFilter && !filterLength ? strings.search : '';
    let showCross = false;
    let showSearchIcon = true;
    if (activeFilter && filterLength) {
        showCross = true;
        showSearchIcon = false;
        textInputWidth = 0;
    }
    else {
        textInputWidth =
            width -
                MARGIN_HORIZONTAL * 2 -
                (CANCEL_MARGIN + cancelWidth.value) -
                CLEAR_TOUCH_ZONE_WIDTH -
                ICON_SEARCH_SIZE -
                rem(ICON_SEARCH_LEFT_MARGIN_RAW + ICON_SEARCH_RIGHT_MARGIN_RAW);
    }
    if (state.textValue) {
        showCross = true;
    }
    const styles = useStyles(styleSet);
    const colors = useColors();
    const textInput = useMemo(() => (<TextInput ref={refSearchBar} placeholder={placeholder} placeholderTextColor={colors.text.placeholder} selectionColor={colors.text.selection} style={[styles.searchInput, { width: textInputWidth }]} value={state.textValue} onChangeText={handleChangeText} onKeyPress={onKeyPress} onFocus={handleInputFocus} autoCorrect={false} autoFocus={Platform.OS === 'android'}/>), [
        refSearchBar,
        placeholder,
        colors.text.placeholder,
        colors.text.selection,
        styles.searchInput,
        textInputWidth,
        state.textValue,
        handleChangeText,
        onKeyPress,
        handleInputFocus,
    ]);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    return (<RNAnimated.View style={[styles.header, { paddingTop: top }, headerStyles, paddingHorizontalStyle]}>
        <View style={styles.searchHeaderRow}>
          <StatusBar barStyle="dark-content" animated/>
          <Animated.View style={[styles.searchBar, searchBarAnimatedStyle]}>
            <ScrollView horizontal ref={scrollView} keyboardShouldPersistTaps="always" showsHorizontalScrollIndicator={false} onContentSizeChange={onContentSizeChange} contentContainerStyle={[styles.scrollViewContentContainer, showSearchIcon && styles.withSearchIcon]}>
              {showSearchIcon && (<>
                  <Spacer w={ICON_SEARCH_LEFT_MARGIN_RAW}/>
                  <IconSearch />
                  <Spacer w={ICON_SEARCH_RIGHT_MARGIN_RAW}/>
                </>)}
              <Filters activeFilter={activeFilter} amountLowerValue={state.amountLowerValue} amountUpperValue={state.amountUpperValue} refAmountLowerInput={refAmountLowerInput} refAmountUpperInput={refAmountUpperInput} onChangeAmountLower={onChangeAmountLower} onChangeAmountUpper={onChangeAmountUpper} onToggleSelectedFilter={onToggleSelectedFilter} onRemoveItem={onRemoveItem}/>
              {textInput}
            </ScrollView>
            {showCross && (<TouchableOpacity onPress={handleClearPress} style={styles.closeIcon}>
                <IconClose />
              </TouchableOpacity>)}
          </Animated.View>
          <Animated.View style={[styles.cancelContainer, cancelAnimatedStyle]} onLayout={onCancelLayout}>
            <TouchableOpacity hitSlop={HIT_SLOP} onPress={handleCancel}>
              <Text Text-14 Gray>
                {strings.cancel}
              </Text>
            </TouchableOpacity>
          </Animated.View>
        </View>
        {!isNil(totalAmount) && !!currency && <TotalAmountRow totalAmount={totalAmount} currency={currency}/>}
      </RNAnimated.View>);
});
export default React.memo(SearchHeaderFiltered);
