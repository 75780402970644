import React, { useMemo } from 'react';
import { View } from 'react-native';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableBounceCard from 'utils/packages/TouchableBounceCard';
import { CardView } from './CardView';
import Spacer from './Spacer';
import IconInfo from './icons/IconInfo';
const InfoCard = ({ children, icon, backgroundColor, color, gradientKey = 'white', colorKey, style, onPress, onLight, ...props }) => {
    const styles = useStyles(styleSet);
    const containerStyles = useMemo(() => [styles.container, backgroundColor ? { backgroundColor } : {}, style], [backgroundColor, style, styles.container]);
    const cardProps = {
        ...(onLight ? { onLight: true } : { onDark: true }),
    };
    const content = (<>
      {icon || (<IconInfo gradientKey={gradientKey} color={color} colorKey={colorKey}/>)}
      <Spacer w={16}/>
      <View style={styles.flex}>{children}</View>
    </>);
    const commonProps = {
        withPadding: true,
        style: containerStyles,
        noMarginTop: true,
        noMarginBottom: true,
        ...props,
    };
    return onPress ? (<TouchableBounceCard {...commonProps} {...cardProps} onPress={onPress}>
      {content}
    </TouchableBounceCard>) : (<CardView {...commonProps}>{content}</CardView>);
};
export default InfoCard;
const styleSet = createStyleSheets((colors) => ({
    flex: {
        flex: 1,
    },
    container: {
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: colors.background.transparent,
    },
}));
