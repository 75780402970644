import React, { memo, useCallback, useEffect, useState } from 'react';
import { View, Animated, StyleSheet } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Modal from 'utils/packages/rnModal';
import Button from 'design-system/Button';
import { IconCloseSquircle, IconSquareCircle } from 'design-system/icons';
import Text from 'design-system/Text';
import useColors from 'hooks/useColors';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { useDisableAndroidBackButton } from 'hooks';
import { screensToAvoidRenderingTopOf } from 'utils/types/navigation';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setShouldSeeDeviceRootedWarningModal } from 'actions/sessionSettings';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { noop } from 'lodash';
import ModalHandle from 'design-system/ModalHandle';
import { rem } from 'design-system/values';
export const DEVICE_ROOTED_KEY = 'DEVICE_ROOTED_WARNING_SHOWN';
const RootedDeviceWarningModal = () => {
    const colors = useColors();
    const dispatch = useAppDispatch();
    const navigation = useMainStackNavigation();
    const currentScreen = useAppSelector((state) => state.utils.currentScreen);
    const styles = useStyles(styleSet);
    const shouldSeeDeviceRootedWarningModal = useAppSelector((state) => state.sessionSettings.shouldSeeDeviceRootedWarningModal);
    const containerStyles = useMarginBottom('paddingBottom', 0, styles.container);
    const [visible, setVisible] = useState(false);
    useDisableAndroidBackButton(() => visible);
    useEffect(() => {
        if (shouldSeeDeviceRootedWarningModal) {
            let canShow = true;
            navigation.getState()?.routes.forEach((routeObj) => {
                if (screensToAvoidRenderingTopOf[routeObj.name]) {
                    canShow = false;
                }
            });
            if (canShow) {
                setVisible(true);
            }
        }
        else {
            setVisible(false);
        }
    }, [shouldSeeDeviceRootedWarningModal, currentScreen]);
    const closeScreen = useCallback(() => {
        AsyncStorage.setItem(DEVICE_ROOTED_KEY, 'true');
        dispatch(setShouldSeeDeviceRootedWarningModal(false));
    }, []);
    return (<Modal hasBackdrop isVisible={visible} swipeDirection="down" onBackButtonPress={noop} style={staticStyles.modal} onBackdropPress={noop} onSwipeComplete={closeScreen} useNativeDriverForBackdrop>
      <View style={styles.main}>
        <View style={styles.contentContainer}>
          <Animated.View style={styles.contentContainer}>
            <View style={containerStyles}>
              <ModalHandle />
              <IconSquareCircle style={styles.icon} width={72} height={72} color={colors.background.dark}>
                <IconCloseSquircle width={rem(40)} height={rem(40)}/>
              </IconSquareCircle>
              <Text Text-20 OffBlack style={styles.title}>
                Your device is rooted
              </Text>
              <Text TextThin-16 Gray centered style={styles.subtitle}>
                This app is running on a rooted system. The security of your
                account might be compromised by third parties. We strongly
                suggest to use Emma on non rooted devices.
              </Text>
              <Button gray style={styles.button} title="Don't show again" onPress={closeScreen}/>
            </View>
          </Animated.View>
        </View>
      </View>
    </Modal>);
};
const staticStyles = StyleSheet.create({
    modal: {
        margin: 0,
        backgroundColor: 'transparent',
        overflow: 'hidden',
    },
});
const styleSet = createStyleSheets((colors) => ({
    main: {
        flex: 1,
    },
    contentContainer: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    container: {
        paddingTop: rem(50),
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: rem(16),
        borderTopLeftRadius: rem(16),
        borderTopRightRadius: rem(16),
        backgroundColor: colors.cards.onDark,
    },
    icon: {
        marginBottom: rem(24),
    },
    title: {
        marginBottom: rem(4),
    },
    subtitle: {
        marginBottom: rem(32),
    },
    button: {
        alignSelf: 'stretch',
    },
}));
export default memo(RootedDeviceWarningModal);
