import React from 'react';
import { CardView } from 'design-system/CardView';
import { StyleSheet, View } from 'react-native';
import { IconSquareCircle } from 'design-system/icons';
import { rem } from 'design-system/values';
import Text from 'design-system/Text';
import Button from 'design-system/Button';
import useColors from 'hooks/useColors';
export const AddNewItem = ({ icon, title, subTitle, buttonText, onPress, }) => {
    const colors = useColors();
    return (<CardView style={styles.cardView}>
      {Boolean(icon) && (<View style={styles.iconContainer}>
          <IconSquareCircle color={colors.background.dark} scale={1.67}>
            {icon}
          </IconSquareCircle>
        </View>)}
      <View style={styles.textContainer}>
        <Text Text-16 style={styles.title}>
          {title}
        </Text>
        {Boolean(subTitle) && (<Text TextThin-14 Gray style={styles.subTitle}>
            {subTitle}
          </Text>)}
      </View>
      <Button gray title={buttonText || 'Add New'} style={styles.secondaryButton} onPress={onPress}/>
    </CardView>);
};
const styles = StyleSheet.create({
    cardView: {
        padding: rem(16),
        marginTop: 0,
    },
    textContainer: {
        maxWidth: rem(259),
        alignItems: 'center',
        alignSelf: 'center',
    },
    subTitle: { textAlign: 'center' },
    iconContainer: {
        alignItems: 'center',
        marginBottom: rem(16),
    },
    title: {
        marginBottom: rem(4),
    },
    secondaryButton: {
        marginTop: rem(16),
        height: rem(34),
        alignSelf: 'center',
        minWidth: rem(129),
    },
});
