import { useCallback } from 'react';
import { deleteAccount, getBankConnections } from 'actions';
import { muteAll, useFetchOld } from 'hooks';
import { useAppDispatch } from 'store/hooks';
export const useDeleteAccount = () => {
    const dispatch = useAppDispatch();
    const [, , , fetch] = useFetchOld(muteAll);
    const close = useCallback((account) => {
        if (!account)
            return;
        fetch(deleteAccount(account.id)).then((response) => {
            if (response) {
                dispatch(getBankConnections());
            }
        });
    }, []);
    return close;
};
