import React, { memo, useCallback } from 'react';
import { View, Platform, } from 'react-native';
import TouchableBounceCard from 'utils/packages/TouchableBounceCard';
import { CardView } from 'design-system';
import { rem } from 'design-system/values';
import SwitchingButton from 'features/subscriptions/components/row/SwitchingButton';
import RowTransaction from 'features/transactions/components/RowTransaction';
import useStyles from 'hooks/useStyles';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Amplitude } from 'utils';
import createStyleSheets from 'utils/createStyleSheets';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { addTransactionToCache } from 'actions';
import FeedCardCarousel from '../FeedCardCarousel';
import Section from '../Section';
import { isWeb } from '../../../../constants';
import TransactionsSeeAllButton from './TransactionsSeeAllButton';
const strings = {
    title: 'Transactions',
};
const keyExtractor = (item) => item.id.toString();
const Transactions = ({ data, style, onLayout }) => {
    const navigation = useMainStackNavigation();
    const { paddingHorizontalStyle } = useAppFrameDimensions(true);
    const dispatch = useAppDispatch();
    const onTransactionPressed = useCallback((data) => {
        dispatch(addTransactionToCache(data));
        navigation.navigate('Item', {
            id: data.id,
        });
    }, []);
    const onCompare = useCallback((routeWithParams) => {
        navigation.navigate(routeWithParams);
        Amplitude.logEvent('Feed.openCompare', { type: routeWithParams.name });
    }, []);
    const onPositionChange = useCallback(() => { }, []);
    const styles = useStyles(styleSet);
    const guessedHomeCountry = useAppSelector((store) => store.user.user.guessedHomeCountry);
    const renderItem = useCallback(({ item }) => (<TouchableBounceCard onDark noMargin style={styles.transactionContainer} onPress={() => onTransactionPressed(item)}>
        <RowTransaction item={item} disabled isTransactionFeed backgroundDark showUpgradeButton switchingButon={<SwitchingButton data={item.subscription} guessedHomeCountry={guessedHomeCountry} onOpenCompare={onCompare}/>}/>
      </TouchableBounceCard>), [
        guessedHomeCountry,
        onCompare,
        onTransactionPressed,
        styles.transactionContainer,
    ]);
    if (!data.length) {
        return null;
    }
    return (<Section title={strings.title} button={<TransactionsSeeAllButton />} onLayout={onLayout} withFrame={false}>
      <View style={style}>
        {isWeb ? (<View style={paddingHorizontalStyle}>
            <CardView webPadding noMarginTop noMarginBottom>
              {data.map((txn) => (<RowTransaction key={txn.id} item={txn} isTransactionFeed backgroundDark showUpgradeButton onPress={() => onTransactionPressed(txn)} switchingButon={<SwitchingButton data={txn.subscription} guessedHomeCountry={guessedHomeCountry} onOpenCompare={onCompare}/>}/>))}
            </CardView>
          </View>) : (<FeedCardCarousel data={data} renderItem={renderItem} keyExtractor={keyExtractor} onPositionChange={onPositionChange} rightOffset={65}/>)}
      </View>
    </Section>);
};
const styleSet = createStyleSheets((colors) => ({
    transactionContainer: {
        backgroundColor: colors.cards.onDark,
        borderRadius: rem(12),
        marginEnd: Platform.OS === 'android' ? rem(16) : 0,
    },
}));
export default memo(Transactions);
