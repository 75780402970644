import React, { memo } from 'react';
import { View, StyleSheet } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
const TagRow = ({ data, onPress }) => {
    const press = () => {
        onPress(data.label);
    };
    const { label, nOfTransactions } = data;
    const colors = useColors();
    return (<TouchableHighlight onCard hoverOnDark onPress={press} activeOpacity={1} underlayColor={colors.background.underlay}>
      <View style={styles.item}>
        <Text Text-16>#{label}</Text>
        <Text TextThin-14 Gray>
          {nOfTransactions !== undefined
            ? `${nOfTransactions} transactions`
            : ''}
        </Text>
      </View>
    </TouchableHighlight>);
};
const styles = StyleSheet.create({
    item: {
        paddingHorizontal: rem(16),
        paddingVertical: rem(8),
    },
});
export default memo(TagRow);
