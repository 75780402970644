import React, { memo } from 'react';
import { View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import { SquareBadge } from 'design-system/Badge';
import IconPlus from 'design-system/icons/IconPlus';
import IconCheck from 'design-system/icons/IconCheck';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableOpacity from 'utils/packages/TouchableOpacity';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import IconTransactionCategory from 'features/transactions/icons/IconTransactionCategory';
import { isWeb } from '../../../../constants';
const EditCategoryRow = ({ item, isDefaultCategory, selected, onPress, onLongPress, }) => {
    const press = () => {
        onPress(item, isDefaultCategory);
    };
    const longPress = () => {
        onLongPress(item, isDefaultCategory);
    };
    const renderImage = () => {
        if (item && (isDefaultCategory || 'emoji' in item)) {
            return (<IconTransactionCategory width={rem(32)} height={rem(32)} category={item}/>);
        }
        const title = item && item.displayName
            ? item.displayName.substring(0, 2).toUpperCase()
            : '';
        return (<View style={[styles.image]}>
        <Text Text-24 OffBlack>
          {title}
        </Text>
      </View>);
    };
    const { width } = useAppFrameDimensions(isWeb);
    const colors = useColors();
    const styles = useStyles(styleSet);
    return (<TouchableOpacity onCard hoverOnDark onPress={press} style={[styles.category, { width: width / 3 }]} {...(!isWeb && {
        onLongPress: longPress,
    })}>
      <View>
        <View style={styles.categoryContent}>
          <View style={styles.imageBadgeWrapper}>
            {item.id === 'custom1' ? (<LinearGradient {...colors.gradients.ultimate} style={styles.imageContainer}>
                <IconPlus width={rem(20)} height={rem(20)}/>
              </LinearGradient>) : (<>
                <View style={styles.imageContainer}>{renderImage()}</View>
                {selected && (<SquareBadge fill={colors.gradients.red.colors[0]}>
                    <IconCheck color={colors.elements.white}/>
                  </SquareBadge>)}
              </>)}
          </View>
          <Text Text-14 numberOfLines={1} ellipsizeMode="tail">
            {item.displayName}
          </Text>
        </View>
      </View>
    </TouchableOpacity>);
};
const styleSet = createStyleSheets((colors) => ({
    category: {
        paddingVertical: rem(24),
        ...(isWeb && {
            paddingHorizontal: rem(16),
        }),
    },
    categoryContent: {
        alignItems: 'center',
    },
    // To allow the badge to be positioned 2px outside the img
    imageBadgeWrapper: {
        width: rem(58),
        marginBottom: rem(16),
    },
    imageContainer: {
        height: rem(56),
        width: rem(56),
        borderRadius: rem(28),
        backgroundColor: colors.cards.onDark,
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        height: rem(56),
        width: rem(56),
        borderRadius: rem(28),
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
export default memo(EditCategoryRow);
