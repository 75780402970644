import React, { useCallback, useRef } from 'react';
import { View, Animated, TouchableBounce, StyleSheet, } from 'react-native';
import { rem } from 'design-system/values';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
const TouchableBounceHighlight = ({ style, children, onPress, hitSlop, }) => {
    const underlayOpacity = useRef(new Animated.Value(0));
    const styles = useStyles(styleSet);
    const onPressIn = useCallback(() => {
        Animated.spring(underlayOpacity.current, {
            toValue: 0.07,
            velocity: 0.1,
            bounciness: 0,
            useNativeDriver: true,
        }).start();
    }, []);
    const onPressOut = useCallback(() => {
        Animated.spring(underlayOpacity.current, {
            toValue: 0,
            velocity: 0.4,
            bounciness: 0,
            useNativeDriver: true,
        }).start();
    }, []);
    return (<TouchableBounce onPress={onPress} onPressIn={onPressIn} onPressOut={onPressOut} hitSlop={hitSlop}>
      <View style={style}>
        <Animated.View style={[{ opacity: underlayOpacity.current }, styles.underlay]}/>
        {children}
      </View>
    </TouchableBounce>);
};
const styleSet = createStyleSheets((colors) => ({
    underlay: {
        ...StyleSheet.absoluteFillObject,
        borderRadius: rem(16),
        backgroundColor: colors.background.underlay,
    },
}));
export default TouchableBounceHighlight;
