import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from './Icon';
export const IconCommunity = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="lightPink">
      <Path d="M5.308 7.886C5.308 5.74 7.064 4 9.23 4c2.166 0 3.923 1.74 3.923 3.886S11.397 11.77 9.23 11.77c-2.167 0-3.923-1.74-3.923-3.885Z" fill={`url(#gradient_${id})`}/>
      <Path d="m6.751 13.526.165-.026a14.832 14.832 0 0 1 4.63 0l.164.026c2.161.342 3.751 2.188 3.751 4.356 0 1.17-.957 2.118-2.138 2.118H5.139A2.129 2.129 0 0 1 3 17.882c0-2.168 1.59-4.014 3.751-4.356Z" fill={`url(#gradient_${id})`}/>
      <Path d="M14.77 4a.69.69 0 0 0-.693.686c0 .378.31.685.692.685 1.402 0 2.539 1.126 2.539 2.515 0 1.388-1.137 2.514-2.539 2.514a.69.69 0 0 0-.692.686c0 .378.31.685.692.685 2.167 0 3.923-1.74 3.923-3.885C18.692 5.74 16.936 4 14.77 4Z" fill={`url(#gradient_${id})`}/>
      <Path d="M15.915 13.492a.69.69 0 0 0-.692.685c0 .379.31.686.692.686h.894c.074 0 .148.006.222.017a3.048 3.048 0 0 1 2.584 3.002.75.75 0 0 1-.754.747h-1.886a.689.689 0 0 0-.692.685c0 .379.31.686.692.686h1.886A2.129 2.129 0 0 0 21 17.882c0-2.168-1.59-4.014-3.751-4.356a2.822 2.822 0 0 0-.44-.034h-.894Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
