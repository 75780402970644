import { createSelector } from 'reselect';
import { useAppSelector } from 'store/hooks';
import { selectIsDefaultSpace } from 'features/spaces/reducers/selectors';
import { assertUnreachable } from 'utils/types';
import { selectAccountStatus, selectAssetsForWatchListFilter, selectPendingAndQueuedOrders, selectStockNewsForSymbol, } from '../reducers/selectors';
import * as debug from '../debug';
import { generateFakeAccount } from '../utils';
import { SelectedPositionFilter } from '../types';
import { filterPortfolioByGainLoss, filterPortfolioByTotalValue, filterPortfolioByReturnPercentage, } from '../utils/format';
export { default as useStockMeta } from './useStockMeta';
export { default as useAccount } from './useAccount';
export const useStatus = () => useAppSelector(selectAccountStatus);
export const useBalance = () => useAppSelector((state) => debug.useFakeData
    ? generateFakeAccount().availableToInvest
    : (debug.fakeAccount || state.invest.account)?.availableToInvest);
export const useRequiredUserAction = () => useAppSelector((state) => debug.fakeRequiredUserAction || state.invest.account?.requiredUserAction);
export const useConnectedAccount = () => useAppSelector((state) => debug.emulateNoConnectedAccount ? null : state.invest.connectedAccount);
export const usePosition = (symbol) => useAppSelector((state) => symbol ? state.invest.positionsCache[symbol] : undefined);
const ordersSelector = createSelector((state, { symbol, status }) => {
    if (symbol) {
        // showing queued orders only by default
        if (status === 'queued' || status === undefined) {
            return state.invest.orderIdForSymbolCache[symbol];
        }
        if (status === 'pending') {
            return state.invest.pendingOrderIdForSymbolCache[symbol];
        }
        if (status === 'limitAndStop') {
            return state.invest.limitStopOrderIdForSymbolCache?.[symbol];
        }
        assertUnreachable(status);
    }
    if (status === 'queued' || status === undefined) {
        return state.invest.orderIdCache;
    }
    if (status === 'pending') {
        return state.invest.pendingOrderIdCache;
    }
    if (status === 'limitAndStop') {
        return state.invest.limitStopOrderIdCache;
    }
    assertUnreachable(status);
    return [];
}, (state) => state.invest.ordersCache, (orderIdsForSymbolCache, ordersCache) => {
    if (!orderIdsForSymbolCache) {
        return undefined;
    }
    const result = [];
    orderIdsForSymbolCache.forEach((orderId) => {
        const order = ordersCache[orderId];
        if (order) {
            result.push(order);
        }
    });
    return result;
});
export const useOrders = (symbol, status) => useAppSelector((state) => ordersSelector(state, { symbol, status }));
export const usePendingAndQueuedOrders = () => useAppSelector(selectPendingAndQueuedOrders);
const activitySelector = createSelector((state, symbol) => (symbol ? state.invest.activityIdForSymbolCache[symbol] : undefined), (state) => state.invest.activityCache, (activityIdsForSymbolCache, activityCache) => {
    if (!activityIdsForSymbolCache) {
        return undefined;
    }
    const result = [];
    const idSet = new Set(); // to avoid duplicate activities
    activityIdsForSymbolCache.forEach((activityId) => {
        if (!idSet.has(activityId)) {
            const activity = activityCache[activityId];
            if (activity) {
                result.push(activity);
                idSet.add(activity.id);
            }
            else if (__DEV__) {
                // eslint-disable-next-line no-console
                console.warn('Activity is not cached');
            }
        }
    });
    return result;
});
export const useActivity = (symbol) => useAppSelector((state) => activitySelector(state, symbol));
export const positionsSelectorSortedBySymbol = createSelector((state) => state.invest.positionsCache, (positionsCache) => {
    const symbols = Object.keys(positionsCache);
    symbols.sort();
    const result = [];
    symbols.forEach((symbol) => {
        const position = positionsCache[symbol];
        // the following condition will always return true since we are looping over keys of positionsCache
        if (position) {
            result.push(position);
        }
    });
    return result;
});
const positionsSelectorSorted = createSelector([
    (state) => state.invest.positionsCache,
    (_state, selectedPositionFilter) => selectedPositionFilter,
], (positionsCache, selectedPositionFilter) => {
    const symbols = Object.keys(positionsCache);
    switch (selectedPositionFilter) {
        case SelectedPositionFilter.TOTAL_VALUE:
            filterPortfolioByTotalValue(symbols, positionsCache);
            break;
        case SelectedPositionFilter.GAIN_LOSS:
            filterPortfolioByGainLoss(symbols, positionsCache);
            break;
        case SelectedPositionFilter.RETURN_PERCENT:
            filterPortfolioByReturnPercentage(symbols, positionsCache);
            break;
        default:
            break;
    }
    const result = [];
    symbols.forEach((symbol) => {
        const position = positionsCache[symbol];
        // the following condition will always return true since we are looping over keys of positionsCache
        if (position) {
            result.push(position);
        }
    });
    return result;
});
export const usePositions = (selectedPositionFilter) => useAppSelector((state) => positionsSelectorSorted(state, selectedPositionFilter));
export const selectPositionsLength = createSelector((state) => state.invest.positionsCache, (positionsCache) => {
    if (positionsCache) {
        return Object.keys(positionsCache).length;
    }
    return 0;
});
export const selectHasPositionsOrOrders = createSelector((state) => state.invest.positionsCache, selectPendingAndQueuedOrders, (positionsCache, { queuedOrders, pendingOrders }) => {
    if (queuedOrders.length || pendingOrders.length || Object.keys(positionsCache).length) {
        return true;
    }
    return false;
});
/**
 * useless to memo this because the values keeps changing
 */
export const useCurrentPrice = (symbol) => useAppSelector((state) => state.invest.currentPriceCache[symbol]);
export const useOnboardingState = () => useAppSelector((state) => state.invest.onboarding);
export const selectTrustedContacts = (state) => state.invest.trustedContacts;
/**
 * Plain selector hook that extracts price change from `invest.priceChangeCache`.
 * @param symbol The symbol you want price change for
 * @returns Cached 1D price change from snapshot or assets endpoint
 */
export const usePriceChange = (symbol) => useAppSelector((state) => state.invest.priceChangeCache?.[symbol]);
export const useRecentlyViewedStocksCache = () => useAppSelector((state) => state.invest.recentlyViewedCache);
export const useSectorsCache = () => useAppSelector((state) => state.invest.sectorsCache);
export const useCollectionsCache = () => useAppSelector((state) => state.invest.collectionsCache);
export const useWatchlistCache = () => useAppSelector((state) => state.invest.watchlists) || [];
export const useWatchListAssetsCache = () => useAppSelector((state) => state.watchList.watchListAssetCache) ||
    {};
export const useWatchListAssetsCacheForWatchList = (watchListId, selectedFilter) => useAppSelector((state) => selectAssetsForWatchListFilter(state, watchListId, selectedFilter)) || [];
export const useIsSymbolInWatchLists = (symbol) => {
    const watchLists = useAppSelector((state) => state.watchList.stockWatchListsInfoMap?.[symbol]);
    if (!watchLists)
        return undefined;
    const keys = Object.keys(watchLists);
    return !!keys?.length;
};
export const useWatchListStockInfoMap = (watchListId, symbol) => useAppSelector((state) => state.watchList.stockWatchListsInfoMap?.[symbol]?.[watchListId]) ||
    false;
export const useGetWatchListInfoForWatchListId = (id) => {
    const watchLists = useAppSelector((state) => state.invest.watchlists);
    if (!watchLists)
        return undefined;
    const watchList = watchLists.find((watchlist) => watchlist.id === id);
    if (watchList) {
        return { id: watchList.id, name: watchList?.name, emoji: watchList.emoji };
    }
    return undefined;
};
/**
 * Selects news for a given symbol.
 *
 * Guarantees news to be unique by `id` property.
 */
export const useStockNews = (symbol) => useAppSelector((state) => selectStockNewsForSymbol(state, symbol));
export const selectPortfolioBalance = createSelector(selectIsDefaultSpace, (state) => state.invest.positionsCache, (isDefaultSpace, positionsCache) => {
    if (!isDefaultSpace) {
        return {
            value: 0,
            currency: 'GBP',
        };
    }
    const positions = Object.values(positionsCache);
    return {
        value: positions.reduce((prev, curr) => prev + (curr?.amount?.value || 0), 0),
        currency: 'GBP',
    };
});
