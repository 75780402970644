import React from 'react';
import { Rect } from 'react-native-svg';
import { Icon } from 'design-system/icons/Icon';
import { useGradientColors } from 'hooks/useColors';
const IconGraph = ({ color, gradientKey = 'ultimate', gradientColors, ...props }) => {
    const gradient = useGradientColors(gradientKey, (color ? [color, color] : gradientColors), undefined, props.colorKey);
    return (<Icon {...props}>
      <Rect x="3" y="14" width="4" height="8" rx="2" fill={gradient[0]}/>
      <Rect x="10" y="7" width="4" height="15" rx="2" fill={gradient[0]}/>
      <Rect x="17" y="2" width="4" height="20" rx="2" fill={gradient[0]}/>
    </Icon>);
};
export default IconGraph;
