import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Crisp } from 'crisp-sdk-web';
import ErrorBoundaries from 'common/ErrorBoundaries';
import { SelectDateScreen } from 'design-system/screens/SelectDateScreen';
import { SuccessScreen } from 'design-system/screens/SuccessScreen';
import { useNativeStackScreenOptions } from 'hooks/navigation/useStackNavigatorScreenOptions';
import { TrueBalanceScreen } from 'features/accounts';
import AccountsOverviewScreen from 'features/accounts/screens/AccountsOverviewScreen';
import AccountTransactionsScreen from 'features/accounts/screens/AccountTransactionsScreen';
import AddBalanceScreen from 'features/accounts/screens/AddBalanceScreen';
import DummyAccountCreatedScreen from 'features/accounts/screens/DummyAccountCreatedScreen';
import DummyAccountLogoScreen from 'features/accounts/screens/DummyAccountLogoScreen';
import DummyAccountLogoTypeScreen from 'features/accounts/screens/DummyAccountLogoTypeScreen';
import DummyAccountSetupScreen from 'features/accounts/screens/DummyAccountSetupScreen';
import EditAccountNameScreen from 'features/accounts/screens/EditAccountNameScreen';
import EditOverdraftScreen from 'features/accounts/screens/EditOverdraftScreen';
import OfflineAccountsScreen from 'features/accounts/screens/OfflineAccountsScreen';
import PickAccountTypeScreen from 'features/accounts/screens/PickAccountTypeScreen';
import PickPropertyTypeScreen from 'features/accounts/screens/PickPropertyTypeScreen';
import AnalyticsIntroScreen from 'features/analytics/screens/AnalyticsIntroScreen';
import AnalyticsScreen from 'features/analytics/screens/AnalyticsScreen';
import CategoryDetailsScreen from 'features/analytics/screens/CategoryDetailsScreen';
import BudgetingScreen from 'features/budgeting/screens/BudgetingScreen';
import EditBudgetFrequencyScreen from 'features/budgeting/screens/EditBudgetFrequencyScreen';
import EditBudgetScreen from 'features/budgeting/screens/EditBudgetScreen';
import EditMerchantBudgetScreen from 'features/budgeting/screens/EditMerchantBudgetScreen';
import EditMerchantBudgetsScreen from 'features/budgeting/screens/EditMerchantBudgetsScreen';
import HolidaySelectionScreen from 'features/budgeting/screens/HolidaySelectionScreen';
import MonthlyCategoryScreen from 'features/budgeting/screens/MonthlyCategoryScreen';
import RolloverScreen from 'features/budgeting/screens/RolloverScreen';
import SelectMerchantScreen from 'features/budgeting/screens/SelectMerchantScreen';
import SelectPeriodScreen from 'features/budgeting/screens/SelectPeriodScreen';
import SetCategoryBudgetsScreen from 'features/budgeting/screens/SetCategoryBudgetsScreen';
import UnbudgetedSpendScreen from 'features/budgeting/screens/UnbudgetedSpendScreen';
import AddAnEmojiScreen from 'features/emoji-picker/screens/AddAnEmojiScreen';
import OneMillionNetWorth from 'features/net-worth/screens/OneMillionNetWorthScreen';
import NotificationsListScreen from 'features/notifications/screens/NotificationsListScreen';
import SavingGoalEditScreen from 'features/saving-goals/SavingGoalEditScreen';
import SearchTransactions from 'features/search/SearchTransactions';
import { AboutUs } from 'features/settings/accounts-settings/AboutUs';
import { AccountSettingsList } from 'features/settings/accounts-settings/AccountSettingsList';
import { AccountSettingsScreen } from 'features/settings/accounts-settings/AccountSettingsScreen';
import ChangePasscode from 'features/settings/accounts-settings/ChangePasscode';
import { ImportantStuff } from 'features/settings/accounts-settings/ImportantStuff';
import { PreferenceSettingsList } from 'features/settings/accounts-settings/PreferenceSettingsList';
import { NotificationsScreen } from 'features/settings/notifications/NotificationsScreen';
import { PersonalDetailsScreen } from 'features/settings/personal-details/PersonalDetailsScreen';
import AdjustSubscriptionScreen from 'features/subscriptions/screens/AdjustSubscriptionScreen';
import CommittedSpendingScreen from 'features/subscriptions/screens/CommittedSpendingScreen';
import CorrectedAmountScreen from 'features/subscriptions/screens/CorrectedAmountScreen';
import CorrectedDateScreen from 'features/subscriptions/screens/CorrectedDateScreen';
import CorrectedPredictionScreen from 'features/subscriptions/screens/CorrectedPredictionScreen';
import InactiveSubscriptionsScreen from 'features/subscriptions/screens/InactiveSubscriptionsScreen';
import RenameSubscriptionScreen from 'features/subscriptions/screens/RenameSubscriptionScreen';
import SubscriptionScreen from 'features/subscriptions/screens/SubscriptionScreen';
import SubscriptionSearchModalScreen from 'features/subscriptions/screens/SubscriptionSearchModalScreen';
import SubscriptionsScreen from 'features/subscriptions/screens/SubscriptionsScreen';
import SupportModal from 'features/support/screens/SupportModal';
import AddNoteScreen from 'features/transactions/screens/AddNoteScreen';
import AddTagsScreen from 'features/transactions/screens/AddTagsScreen';
import CustomCategoryCreatedScreen from 'features/transactions/screens/CustomCategoryCreatedScreen';
import CustomCategoryEditScreen from 'features/transactions/screens/CustomCategoryEditScreen';
import EditCategoryScreen from 'features/transactions/screens/EditCategoryScreen';
import EditCompleteScreen from 'features/transactions/screens/EditCompleteScreen';
import EditDateScreen from 'features/transactions/screens/EditDateScreen';
import EditMultipleTransactionsRename from 'features/transactions/screens/EditMultipleTransactionsRename';
import EditMultipleTransactionsScreen from 'features/transactions/screens/EditMultipleTransactionsScreen';
import EditMultipleTransactionsTagsScreen from 'features/transactions/screens/EditMultipleTransactionsTagsScreen';
import ManageCategoriesScreen from 'features/transactions/screens/ManageCategoriesScreen';
import RenameTransactionScreen from 'features/transactions/screens/RenameTransactionScreen';
import SplitTransactionScreen from 'features/transactions/screens/SplitTransactionScreen';
import SuccessSplitScreen from 'features/transactions/screens/SuccessSplitScreen';
import TransactionScreen from 'features/transactions/screens/TransactionScreen';
import TransactionsScreen from 'features/transactions/screens/TransactionsScreen';
import NotFound from 'features/web/NotFound';
import HomeScreen from 'features/web/screens/HomeScreen';
import Config from 'utils/packages/configs';
const Stack = createNativeStackNavigator();
const getId = ({ params }) => Number(params.id).toFixed();
const WebStack = () => {
    useEffect(() => {
        Crisp.configure(Config.CRISP_ID, { autoload: false });
    }, []);
    const screenOptions = useNativeStackScreenOptions();
    return (<ErrorBoundaries>
      <View style={styles.flex}>
        <Stack.Navigator screenOptions={screenOptions}>
          <Stack.Group>
            <Stack.Screen component={HomeScreen} name="Feed"/>
            <Stack.Screen component={BudgetingScreen} name="Budgeting"/>
            <Stack.Screen component={NotificationsListScreen} name="NotificationsList"/>
            <Stack.Screen component={MonthlyCategoryScreen} name="MonthlyCategory"/>
            <Stack.Screen component={CommittedSpendingScreen} name="CommittedList"/>
            <Stack.Screen component={TransactionsScreen} name="TransactionsList"/>
            <Stack.Screen component={TransactionScreen} name="Item" getId={getId}/>
            <Stack.Screen component={SupportModal} name="Support"/>
            <Stack.Screen component={AccountTransactionsScreen} name="Transactions"/>
            <Stack.Screen component={CategoryDetailsScreen} name="Category"/>
            <Stack.Screen component={AnalyticsScreen} name="Analytics"/>
            <Stack.Screen name="SelectPeriod" component={SelectPeriodScreen} getId={({ params }) => params.flow}/>
            <Stack.Screen name="AnalyticsIntro" component={AnalyticsIntroScreen}/>
            <Stack.Screen name="AccountsOverview" component={AccountsOverviewScreen} getId={({ params }) => String(params?.title)}/>
            <Stack.Screen name="EditAccountName" component={EditAccountNameScreen}/>
            <Stack.Screen name="EditOverdraft" component={EditOverdraftScreen}/>
            <Stack.Screen name="SavingGoalEdit" component={SavingGoalEditScreen}/>
            <Stack.Screen name="EditBudgetFrequency" component={EditBudgetFrequencyScreen} getId={({ params }) => params.flow}/>
            <Stack.Screen name="HolidaySelection" component={HolidaySelectionScreen} getId={({ params }) => params.flow}/>
            <Stack.Screen name="SelectDate" component={SelectDateScreen} getId={({ params }) => params.id}/>
            <Stack.Screen name="OneMillionNetWorth" component={OneMillionNetWorth}/>
            <Stack.Screen name="SearchTransactions" component={SearchTransactions}/>
            <Stack.Screen name="Notifications" component={NotificationsScreen}/>
            <Stack.Screen name="Subscriptions" component={SubscriptionsScreen}/>
            <Stack.Screen name="InactivePayments" component={InactiveSubscriptionsScreen}/>
            <Stack.Screen name="Subscription" component={SubscriptionScreen} getId={getId}/>
            <Stack.Screen name="RenameTransaction" component={RenameTransactionScreen} getId={getId}/>
            <Stack.Screen name="AddTags" component={AddTagsScreen}/>
            <Stack.Screen name="EditDate" component={EditDateScreen} getId={getId}/>
            <Stack.Screen name="AddNote" component={AddNoteScreen} getId={getId}/>
            <Stack.Screen name="SplitTransaction" component={SplitTransactionScreen}/>
            <Stack.Screen name="EditMultipleTransactionsTags" component={EditMultipleTransactionsTagsScreen}/>
            <Stack.Screen name="LearningScreen" component={EditCompleteScreen}/>
            <Stack.Screen name="SuccessSplit" component={SuccessSplitScreen}/>
            <Stack.Screen name="EditMultipleTransactionsRename" component={EditMultipleTransactionsRename}/>
            <Stack.Screen name="EditCategory" component={EditCategoryScreen}/>
            <Stack.Screen name="EditMultipleTransactions" component={EditMultipleTransactionsScreen}/>
            <Stack.Screen name="CustomCategoryEdit" component={CustomCategoryEditScreen}/>
            <Stack.Screen name="ManageCategories" component={ManageCategoriesScreen}/>
            <Stack.Screen name="CustomCategoryCreated" component={CustomCategoryCreatedScreen}/>
            <Stack.Screen name="UnbudgetedSpend" component={UnbudgetedSpendScreen}/>

            <Stack.Screen name="AccountSettings" component={AccountSettingsScreen}/>
            <Stack.Screen name="PersonalDetails" component={PersonalDetailsScreen}/>
            <Stack.Screen name="ChangePasscode" component={ChangePasscode}/>
            <Stack.Screen name="AccountSettingsList" component={AccountSettingsList}/>
            <Stack.Screen name="PreferenceSettingsList" component={PreferenceSettingsList}/>
            <Stack.Screen name="AboutUs" component={AboutUs}/>
            <Stack.Screen name="ImportantStuff" component={ImportantStuff}/>

            <Stack.Screen name="Success" component={SuccessScreen}/>
            <Stack.Screen name="CorrectedDate" component={CorrectedDateScreen}/>
            <Stack.Screen name="CorrectedAmount" component={CorrectedAmountScreen}/>
            <Stack.Screen name="PredictionFixed" component={CorrectedPredictionScreen}/>
            <Stack.Screen name="RenameSubscription" component={RenameSubscriptionScreen}/>
            <Stack.Screen name="SubscriptionsSearch" component={SubscriptionSearchModalScreen}/>
            <Stack.Screen name="AdjustSubscription" component={AdjustSubscriptionScreen}/>

            <Stack.Screen name="SelectMerchant" component={SelectMerchantScreen}/>
            <Stack.Screen name="EditMerchantBudgets" component={EditMerchantBudgetsScreen}/>
            <Stack.Screen name="EditMerchantBudget" component={EditMerchantBudgetScreen}/>
            <Stack.Screen name="EditBudget" component={EditBudgetScreen} getId={({ params }) => params.flow}/>
            <Stack.Screen name="Budgets" component={SetCategoryBudgetsScreen} getId={({ params }) => params.flow}/>
            <Stack.Screen name="Rollover" component={RolloverScreen}/>
            <Stack.Screen name="TrueBalance" component={TrueBalanceScreen}/>
            <Stack.Screen name="DummyAccountSetup" component={DummyAccountSetupScreen}/>
            <Stack.Screen name="DummyAccountCreated" component={DummyAccountCreatedScreen}/>
            <Stack.Screen name="DummyAccountLogo" component={DummyAccountLogoScreen}/>
            <Stack.Screen name="DummyAccountLogoType" component={DummyAccountLogoTypeScreen}/>
            <Stack.Screen name="AddBalance" component={AddBalanceScreen}/>
            <Stack.Screen name="AddAnEmoji" component={AddAnEmojiScreen}/>
            <Stack.Screen name="OfflineAccounts" component={OfflineAccountsScreen}/>
            <Stack.Screen name="PickAccountType" component={PickAccountTypeScreen}/>
            <Stack.Screen name="Property" component={PickPropertyTypeScreen}/>
          </Stack.Group>

          <Stack.Screen name="NotFound" component={NotFound}/>

          {/* Protected routes */}
        </Stack.Navigator>
      </View>
    </ErrorBoundaries>);
};
const styles = StyleSheet.create({
    flex: {
        flex: 1,
    },
});
export default WebStack;
