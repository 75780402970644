import { useCallback } from 'react';
import { LOGGED_OUT } from 'actions/types';
import { useAppDispatch } from 'store/hooks';
import { useReSelector } from '../utils/hooksApi';
export const getEvenetType = (name) => `${name.toUpperCase()}_UPDATE`;
// Reducer
export const createStateReducer = (name) => {
    const eventType = getEvenetType(name);
    return (state = {}, action) => {
        switch (action.type) {
            case eventType:
                return {
                    ...state,
                    data: action.payload,
                };
            case LOGGED_OUT: {
                return {};
            }
            default:
                return state;
        }
    };
};
// Hook
export const useSateReduxed = (name) => {
    const state = useReSelector((store) => store[name]);
    const dispatch = useAppDispatch();
    const send = useCallback((value) => {
        dispatch({
            type: getEvenetType(name),
            payload: value,
        });
    }, [name, dispatch]);
    return [state.data, send];
};
export default useSateReduxed;
