import { spawn } from 'typed-redux-saga/macro';
import budgetingAnalyticsSaga from 'features/analytics/sagas';
import feedSaga from 'features/feed/sagas';
import investSaga from 'features/invest/sagas';
// import { promotionsSaga } from 'features/promotions/sagas/promotionsSaga';
import rentReportingSaga from 'features/rent-reporting/sagas';
import smartRulesSaga from 'features/smart-rules/sagas';
import spaceSaga from 'features/spaces/sagas';
import subscriptionsSaga from 'features/subscriptions/sagas';
import appStateSaga from 'sagas/AppStateSaga';
import netInfoSaga from 'sagas/NetInfoSaga';
import refreshWalletActivitiesSaga from 'features/emmaWallet/sagas/refreshWalletActivitiesSaga';
import autosaveSagas from 'features/pots/sagas/autosaveSagas';
import refreshSaga from './RefreshSaga';
import onAppActiveSaga from './onAppActiveSaga';
import logoutSaga from './logoutSaga';
function* startupSaga() {
    yield* spawn(logoutSaga);
    yield* spawn(investSaga);
    yield* spawn(refreshWalletActivitiesSaga);
    yield* spawn(spaceSaga);
    yield* spawn(netInfoSaga);
    yield* spawn(appStateSaga);
    yield* spawn(budgetingAnalyticsSaga);
    yield* spawn(refreshSaga);
    yield* spawn(onAppActiveSaga);
    yield* spawn(subscriptionsSaga);
    yield* spawn(feedSaga);
    yield* spawn(autosaveSagas);
    // yield* spawn(promotionsSaga);
    yield* spawn(smartRulesSaga);
    yield* spawn(rentReportingSaga);
}
export default startupSaga;
