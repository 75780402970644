export const FETCH_APP_DATA = 'FETCH_APP_DATA';
export const FETCH_APP_DATA_COMPLETE = 'FETCH_APP_DATA_COMPLETE';
export const FETCH_DASHBOARD_MODAL_STACK = 'FETCH_DASHBOARD_MODAL_STACK';
export const fetchAppData = () => ({
    type: FETCH_APP_DATA,
});
export const fetchDashboardModalStack = () => ({
    type: FETCH_DASHBOARD_MODAL_STACK,
});
export const fetchAppDataComplete = () => ({
    type: FETCH_APP_DATA_COMPLETE,
});
