import React from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import Amount from 'design-system/Amount';
import Button from 'design-system/Button';
import { rem } from 'design-system/values';
import BlurAmountView from 'design-system/BlurAmountView';
import useColors from 'hooks/useColors';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import { isWeb } from '../../../../constants';
const AnalyticsRow = ({ icon, onPress, displayName, count, total, percentage = '', currency, children, onLayout, onPressUpgrade, blurValue, }) => {
    const colors = useColors();
    return (<TouchableHighlight onCard hoverOnLight onPress={onPress} style={styles.container} underlayColor={colors.background.underlay}>
      <View style={styles.content} onLayout={onLayout}>
        {icon}
        <View style={styles.innerContent}>
          {onPressUpgrade ? (<View style={styles.upgradeContainer}>
              <Text Text-16 numberOfLines={1} ellipsizeMode="tail" style={styles.title}>
                {displayName}
              </Text>
              <Button brandReversed square hug small title="Upgrade" onPress={onPressUpgrade}/>
            </View>) : (<>
              <View style={styles.textContainer}>
                <Text Text-16 numberOfLines={1} ellipsizeMode="tail" style={styles.title}>
                  {displayName}
                </Text>
                <BlurAmountView enabled={blurValue}>
                  <Amount Numbers-16 showSign value={{ value: total, currency }}/>
                </BlurAmountView>
              </View>
              <View style={styles.textContainer}>
                <Text TextThin-14 Secondary>
                  {count} {count === 1 ? 'Transaction' : 'Transactions'}
                </Text>
                <Text TextThin-14 Secondary>
                  {percentage}
                </Text>
                {children}
              </View>
            </>)}
        </View>
      </View>
    </TouchableHighlight>);
};
const styles = StyleSheet.create({
    container: {
        padding: rem(16),
        justifyContent: 'center',
        ...(isWeb && {
            paddingVertical: rem(16),
            paddingHorizontal: rem(8),
        }),
    },
    content: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    innerContent: {
        flex: 1,
        marginStart: rem(16),
    },
    upgradeContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        flexShrink: 1,
        paddingEnd: rem(14),
    },
    textContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});
export default AnalyticsRow;
