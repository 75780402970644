import React from 'react';
import { View } from 'react-native';
import { IconSquareCircle } from 'design-system/icons/IconSquareCircle';
import useColors from 'hooks/useColors';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
export const IconFloatingCircledSquare = ({ scale = 1, children, ...props }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    return (<View style={styles.container}>
      <IconSquareCircle scale={scale} color={colors.background.dark} {...props}>
        <IconSquareCircle scale={scale * 0.84} color={colors.buttons.brand}>
          {children}
        </IconSquareCircle>
      </IconSquareCircle>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        ...colors.shadows.recommendationCard,
    },
}));
