import { createSelector } from 'reselect';
import { QUESTS, QUESTS_GROUPS } from '../assets/quests';
export const selectQuestsSections = createSelector([(store) => store.quests.quests], (quests) => {
    const array = [...quests];
    const questsArray = [...QUESTS].slice(1);
    const filteredQuests = [];
    array.forEach((questCategory) => {
        if (QUESTS_GROUPS.includes(questCategory.group)) {
            let totalCount = 0;
            let completedCount = 0;
            const filteredData = questCategory.quests.filter(({ type, status }) => {
                const includes = questsArray.includes(type);
                if (includes) {
                    if (status === 'VIEWED' || status === 'UNLOCKED') {
                        completedCount += 1;
                    }
                    totalCount += 1;
                    return true;
                }
                return false;
            });
            filteredQuests.push({
                key: questCategory.group,
                data: filteredData,
                totalCount,
                completedCount,
            });
        }
    });
    return filteredQuests;
});
export const selectQuestsSummary = createSelector([(store) => store.user.user.questsSummary], (questsSummary) => {
    const { locked, unlocked, viewed } = questsSummary;
    const completed = unlocked + viewed;
    const total = completed + locked;
    return {
        ...questsSummary,
        total,
        completed,
        percentage: Math.floor((completed / total) * 100),
    };
});
export const didSetupFraudDetectionQuest = (quests) => {
    let quest;
    quests.find((cat) => {
        if (cat.group !== 'LIKE_A_PRO') {
            return false;
        }
        const q = cat.quests.find((q) => q.type === 'first_time.setup_fraud_detect');
        if (q) {
            quest = q;
            return true;
        }
        return false;
    });
    return quest?.status === 'UNLOCKED' || quest?.status === 'VIEWED';
};
export const selectDidViewFraudDetectionIntro = createSelector([
    (store) => store.quests.quests,
    (store) => store.deviceSettings.didViewFraudDetectionIntro,
], (quests, didViewFraudDetectionIntro) => {
    if (didViewFraudDetectionIntro) {
        return true;
    }
    return didSetupFraudDetectionQuest(quests);
});
