import React, { useCallback } from 'react';
import { Keyboard } from 'react-native';
import Modal from 'design-system/Modal';
import { Tier } from 'features/premium/entities';
import { selectConnectionsLimit } from 'features/premium/selectors';
import { selectUserTier } from 'features/premium/selectors/tier';
import { selectActiveConnectionsWithoutManual, selectIsLoggedIn, } from 'reducers/selectors';
import { useAppSelector } from 'store/hooks';
import Amplitude from 'utils/amplitude';
import AccountConnectionsLimitModalContent from '../components/AccountConnectionsLimitModalContent';
const getCurrentLimit = (connectionsLimitMap, currentConnections, activeTier) => {
    const currentLimit = connectionsLimitMap[activeTier || Tier.free];
    if (currentLimit === null) {
        return null;
    }
    if (currentConnections < currentLimit) {
        return null;
    }
    return currentLimit;
};
const useConnectionsLimitModal = () => {
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const connections = useAppSelector(selectActiveConnectionsWithoutManual);
    const connectionsLimit = useAppSelector(selectConnectionsLimit);
    const userTier = useAppSelector(selectUserTier);
    const open = useCallback((onContinue) => {
        if (connectionsLimit && isLoggedIn) {
            const currentLimit = getCurrentLimit(connectionsLimit, connections.length, userTier);
            if (currentLimit !== null) {
                Keyboard.dismiss();
                Amplitude.logEvent('Connections Limit Modal Opened');
                Modal.show(<AccountConnectionsLimitModalContent currentLimit={currentLimit}/>, {
                    title: 'Upgrade to add more',
                });
                return;
            }
        }
        onContinue();
    }, [connections.length, connectionsLimit, isLoggedIn, userTier]);
    return open;
};
export default useConnectionsLimitModal;
