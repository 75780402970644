import { DaysOfTheWeekNames, } from 'design-system/day-of-the-week-picker/values';
import { Frequencies, FrequencyTypes, } from 'reducers/types';
const nth = (d) => {
    if (d > 3 && d < 21)
        return `${d}th`;
    switch (d % 10) {
        case 1:
            return `${d}st`;
        case 2:
            return `${d}nd`;
        case 3:
            return `${d}rd`;
        default:
            return `${d}th`;
    }
};
export const incomeToPeriod = (income) => {
    if (!income) {
        return {
            frequency: Frequencies.Monthly,
        };
    }
    const { schedule: { recurrence }, currentPaydayRange, } = income;
    let frequency;
    const { frequency: frequencyType, byMonthDay, interval, onHoliday, weekday, weekNumber, } = recurrence;
    if (frequencyType === FrequencyTypes.Monthly) {
        switch (true) {
            case byMonthDay?.length === 2:
                frequency = Frequencies.TwiceAMonth;
                break;
            case byMonthDay?.[0] === 31 && onHoliday === 'BEFORE':
                frequency = Frequencies.LastWorkingDay;
                break;
            case !weekday && !weekNumber:
                frequency = Frequencies.FixedDate;
                break;
            case Boolean(weekday) && Boolean(weekNumber):
                frequency = Frequencies.EveryMonth;
                break;
            default:
        }
    }
    else {
        switch (interval) {
            case 4:
                frequency = Frequencies.EveryFourWeeks;
                break;
            case 2:
                frequency = Frequencies.EveryTwoWeeks;
                break;
            default:
                frequency = Frequencies.Weekly;
        }
    }
    return {
        onHoliday: onHoliday,
        days: byMonthDay || [],
        startDate: currentPaydayRange?.from,
        frequency,
        weekday: weekday || null,
        weekNumber: weekNumber || null,
    };
};
export const toBudgetApiPayload = ({ days, weekday, weekNumber, frequency, onHoliday, startDate, } = {}) => {
    switch (frequency) {
        case Frequencies.FixedDate:
            return {
                byMonthDay: days?.[0],
                frequency: FrequencyTypes.Monthly,
                interval: 1,
                weekday: null,
                weekNumber: null,
                onHoliday,
            };
        case Frequencies.LastWorkingDay:
            return {
                byMonthDay: 31,
                frequency: FrequencyTypes.Monthly,
                interval: 1,
                weekday: null,
                weekNumber: null,
                onHoliday: 'BEFORE',
            };
        case Frequencies.EveryMonth:
            return {
                byMonthDay: null,
                frequency: FrequencyTypes.Monthly,
                interval: 1,
                weekday: weekday,
                weekNumber: weekNumber,
                onHoliday,
            };
        case Frequencies.TwiceAMonth:
            return {
                byMonthDay: days,
                frequency: FrequencyTypes.Monthly,
                interval: 1,
                weekday: null,
                weekNumber: null,
                onHoliday,
            };
        case Frequencies.EveryTwoWeeks:
            return {
                frequency: FrequencyTypes.Weekly,
                weekday: weekday,
                weekNumber: null,
                interval: 2,
                byMonthDay: null,
                onHoliday,
                startDate,
            };
        case Frequencies.EveryFourWeeks:
            return {
                frequency: FrequencyTypes.Weekly,
                weekday: weekday,
                weekNumber: null,
                interval: 4,
                byMonthDay: null,
                onHoliday,
                startDate,
            };
        case Frequencies.Weekly:
            return {
                frequency: FrequencyTypes.Weekly,
                weekday: weekday,
                weekNumber: null,
                interval: 1,
                byMonthDay: null,
                onHoliday,
            };
        case Frequencies.Monthly:
            return {
                frequency: FrequencyTypes.Monthly,
                weekday: null,
                weekNumber: null,
                interval: 1,
                byMonthDay: days,
                onHoliday,
            };
        default:
            return null;
    }
};
export const toFrequencyText = ({ weekday, frequency, weekNumber, days } = {}, defaultText) => {
    switch (frequency) {
        case Frequencies.EveryTwoWeeks:
            return `Every 2 weeks on ${DaysOfTheWeekNames[weekday]}`;
        case Frequencies.EveryFourWeeks:
            return `Every 4 weeks on ${DaysOfTheWeekNames[weekday]}`;
        case Frequencies.EveryMonth:
            return `On the ${nth(weekNumber)} ${DaysOfTheWeekNames[weekday]} of every month`;
        case Frequencies.Monthly:
            return `First to last day of the month`;
        case Frequencies.TwiceAMonth:
            return `${nth(days?.[0])} & ${nth(days?.[1])} of every month`;
        case Frequencies.LastWorkingDay:
            return 'On the last working day';
        case Frequencies.FixedDate:
            return `${nth(days?.[0])} of every month`;
        case Frequencies.Weekly:
            return `Every ${DaysOfTheWeekNames[weekday]}`;
        default:
            return defaultText;
    }
};
export const getFrequencyType = (fr) => {
    if ([
        Frequencies.Weekly,
        Frequencies.EveryTwoWeeks,
        Frequencies.EveryFourWeeks,
        Frequencies.FixedDate,
    ].includes(fr)) {
        return FrequencyTypes.Weekly;
    }
    return FrequencyTypes.Monthly;
};
