import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import Amount from 'design-system/Amount';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import ToolTip from 'design-system/ToolTip';
import { CardView } from 'design-system/CardView';
import useColors from 'hooks/useColors';
import { selectIsScrambled } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { formatAmount } from 'utils/formatting';
import Links from 'utils/links';
import { interpolate } from 'utils/interpolations';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { setTransactionsRequesting } from 'actions';
import BudgetingCircleChart from 'features/feed/components/analytics/BudgetingCircleChart';
import { isWeb } from '../../../constants';
import BudgetingGraphLabel from './BudgetingGraphLabel';
import { BudgetRollingAmountLabel } from './BudgetRollingAmountLabel';
const strings = {
    amountToolTipTitle: 'How is it estimated?',
    amountToolTipSubTitle: `This is what's left from your total budget after subtracting both your committed and actual spending. This number is not correlated to your bank account balance.`,
};
const BudgetingCarouselItem = ({ item }) => {
    const colors = useColors();
    const dispatch = useAppDispatch();
    const interpolateConfig = {
        inputRange: [0, item?.totalBudget || 100],
        outputRange: [0, 100],
    };
    const budgetingCommitted = item.hasCircle && item.committed
        ? interpolate(item.committed + item.spending, interpolateConfig)
        : undefined;
    const budgetingSpent = interpolate(item.spending, interpolateConfig);
    const totalSpending = (item.hasCircle && item.committed ? item.committed : 0) + item.spending;
    const scrambled = useAppSelector(selectIsScrambled);
    const leftOfBudget = (item.totalBudget || 0) - totalSpending;
    const navigation = useMainStackNavigation();
    const onPressSpending = () => {
        dispatch(setTransactionsRequesting);
        navigation.navigate('TransactionsList', {
            from: item.from,
            to: item.to,
        });
    };
    const onPressCommittedSpending = () => {
        navigation.navigate('CommittedList', {
            fromDate: item.from,
            toDate: item.to,
        });
    };
    const onPressLearnMore = useCallback(() => {
        Links.openKnownLink('balanceAndBudget');
    }, []);
    return (<CardView style={styles.card} withPadding webPadding>
      <View style={styles.graphContainer}>
        <BudgetingCircleChart large committedPercent={budgetingCommitted} spendingPercent={budgetingSpent}/>
        <View style={styles.graphText}>
          <View style={styles.row}>
            <View style={styles.flex}/>
            <Amount Numbers-24 value={{
            value: Math.abs(leftOfBudget),
            currency: item.currency,
        }} centered showFraction={false} adjustsFontSizeToFit numberOfLines={1} removeZero/>
            {!isWeb && (<View style={styles.tooltipContainer}>
                <Spacer w={2}/>
                <ToolTip colorKey="secondary" title={strings.amountToolTipTitle} subtitle={strings.amountToolTipSubTitle} small learnMore onPressLearnMore={onPressLearnMore}/>
              </View>)}
          </View>
          <Text TextThin-14 Gray centered adjustsFontSizeToFit>
            {leftOfBudget >= 0 ? 'left of' : 'over your budget\nof'}{' '}
            {formatAmount(item.totalBudget, item.currency, 2, scrambled, true)}
          </Text>
        </View>
      </View>
      <BudgetingGraphLabel title="Spending" color={colors.gradients.ultimate.colors[0]} amount={{ amount: item.spending, currency: item.currency }} onPress={onPressSpending}/>
      {item.hasCircle && (<>
          {!isWeb && <Spacer.H8 />}
          <BudgetingGraphLabel title="Committed Spending" color={colors.gradients.darkPink.colors[0]} amount={{
                amount: item.hasCircle && item?.committed ? item.committed : 0,
                currency: item.currency,
            }} onPress={onPressCommittedSpending}/>
        </>)}
      {item.rollingAccumulatedLimit ? (<>
          <Spacer.H8 />
          <BudgetRollingAmountLabel rollingAccumulatedLimit={item.rollingAccumulatedLimit} currency={item.currency}/>
        </>) : null}
      <Spacer.W8 />
    </CardView>);
};
const styles = StyleSheet.create({
    card: {
        marginBottom: rem(16),
    },
    graphContainer: {
        justifyContent: 'center',
        alignSelf: 'center',
    },
    graphText: {
        position: 'absolute',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: rem(140),
    },
    row: {
        flexDirection: 'row',
    },
    flex: {
        flex: 1,
    },
    tooltipContainer: {
        flex: 1,
        flexDirection: 'row',
    },
});
export default BudgetingCarouselItem;
