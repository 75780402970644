import React from 'react';
import { a, rem } from 'design-system/values';
import IconGraph from 'design-system/icons/IconGraph';
import IconRepeat from 'design-system/icons/IconRepeat';
import IconTransaction from 'design-system/icons/IconTransaction';
import { SelectedPositionFilter } from './types';
export const ORDER_UPDATE_TIME_LIMIT = 60000; // ms
export const ORDER_UPDATE_INTERVAL = 1000; // ms
export const ACCOUNT_UPDATE_INTERVAL_DEFAULT = 5 * 60 * 1000; // ms, used when current trading account status is "good" (doesn't need faster updating)
export const ACCOUNT_UPDATE_INTERVAL_FAST = 1 * 60 * 1000; // ms, used when current trading account status implies user to wait for status update
export const PORTFOLIO_UPDATE_MIN_DELAY = 10 * 60 * 1000; // ms, sets minimum delay between updates. Updates happen when app is restored from background/inactive state
export const SHARE_QUANTITY_DECIMALS = 4;
export const ENABLE_QUICK_TOP_UP_OPTIONS = false;
export const STOCK_ICON_SIZE = 44; // with inner border of 1
export const ITEMS_IN_COLLAPSED_SECTION = 3;
export const MIN_TOP_UP_AMOUNT = 1;
export const MIN_WITHDRAW_AMOUNT = 0;
export const MIN_BUY_AMOUNT = 1; // WARNING: if changing to other than 1, please implement logic in MoneyInput or BuyCustomAmountScreen
export const MAX_CHART_RETRIES = 2; // total of 3 attempts max
export const TRADING_CHART_DEFAULT_PERIOD = '1D';
export const PORTFOLIO_CHART_DEFAULT_PERIOD = '1M';
export const RECENTLY_VIEWED_MAX_COUNT = 10;
export const WITHDRAW_POLL_INTERVAL = 5 * 1000;
export const WITHDRAW_POLL_ATTEMPTS = 3;
export const VIEW_FINDER_TOP = rem(a(160, 160, 160, 200, 200));
export const VIEW_FINDER_HEIGHT = rem(216);
export const VIEW_FINDER_TOP_OFFSET = rem(80);
/* URLs to various documents */
/**
 * Also referred to as "Broker Agreement" and "Account Agreement"
 */
export const ALPACA_CUSTOMER_AGREEMENT = 'https://files.alpaca.markets/disclosures/library/AcctAppMarginAndCustAgmt.pdf';
export const ORDER_EXECUTION_POLICY = 'https://emma-app.com/order-execution-policy';
export const RISK_DISCLOSURE = 'https://emma-app.com/risk-disclosure';
export const TRADING_TERMS = 'https://emma-app.com/trading-terms';
export const LEGAL_NOTICE = 'Emma Technologies Ltd is an appointed representative of RiskSave Technologies Ltd which is authorised and regulated by the Financial Conduct Authority (FRN 775330)';
/* Portfolio customizations */
export const ANIMATE_PORTFOLIO_VALUE = true;
export const PORTFOLIO_RESAMPLE_RANGE = 100;
export const CALCULATE_PORTFOLIO_VALUE_BASED_ON_POSITIONS = true;
export const ENABLE_STOCK_RESAMPLE = false;
export const STOCK_RESAMPLE_RANGE = 100;
/**
 * When this toggle is on, the stock chart will be automatically switching to the next period when selected/default period is failed to be retrieved from backend.
 */
export const ENABLE_STOCK_CHARTS_AUTO_SWITCHING_PERIODS = false;
/* Style config (metrics - paddings, margins) */
export const ROW_ITEM_VERTICAL_PADDING = 16;
export const ROW_ITEM_HORIZONTAL_PADDING = 16;
/* Onboarding customizations */
export const MIDDLE_NAME_REQUIRED = false;
export const ENABLE_CHECK_ALL = false;
export const USE_LINK_TO_CUSTOMER_AGREEMENT_ON_SIGNATURE = true;
export const CAN_SKIP_TO_SIGNATURE_IF_WAS_ALREADY_THERE = false;
export const SCROLL_Y_STATUS_BAR_HIDDEN_THRESHOLD = 40;
/**
 * Maximum file size in megabytes for the images which user uploads for KYC checks.
 */
export const KYC_MAX_UPLOAD_MB = 5 * 0.9; // 5MB reduced by 10% as a safety margin
export var OnboardingCardPosition;
(function (OnboardingCardPosition) {
    OnboardingCardPosition[OnboardingCardPosition["TOP"] = 0] = "TOP";
    OnboardingCardPosition[OnboardingCardPosition["BOTTOM"] = 1] = "BOTTOM";
})(OnboardingCardPosition || (OnboardingCardPosition = {}));
export const POSITION_FILTER_OPTIONS = [
    { label: 'Total value', value: SelectedPositionFilter.TOTAL_VALUE, icon: <IconTransaction /> },
    { label: 'Gain / loss', value: SelectedPositionFilter.GAIN_LOSS, icon: <IconRepeat /> },
    { label: 'Return %', value: SelectedPositionFilter.RETURN_PERCENT, icon: <IconGraph /> },
];
/* Discovery customizations */
export const SHOW_SECTORS = true;
/* Stock screen customizations */
export const STOCK_NEWS_SECTION_LIMIT = 10;
/* Stock news customizations */
export const USE_SAFARI_VIEW_OR_CHROME_TABS_FOR_NEWS = true;
/* Linking account customizations */
export const SHOW_CHANGE_ACCOUNT_VIA_SUPPORT_WARNING = false;
export const ALL_STOCKS_KEY = 'ALL_STOCKS';
