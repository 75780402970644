export const SET_CURRENT_GROUP = 'SET_CURRENT_GROUP';
export const SET_CURRENT_GROUP_BY_ID_REQUEST = 'SET_CURRENT_GROUP_BY_ID_REQUEST';
export const SET_CURRENT_GROUP_BY_ID_SUCCESS = 'SET_CURRENT_GROUP_BY_ID_SUCCESS';
export const SET_CURRENT_GROUP_BY_ID_FAILURE = 'SET_CURRENT_GROUP_BY_ID_FAILURE';
export const POST_GROUP_PIC_REQUEST = 'POST_GROUP_PIC_REQUEST';
export const POST_GROUP_PIC_SUCCESS = 'POST_GROUP_PIC_SUCCESS';
export const POST_GROUP_PIC_FAILURE = 'POST_GROUP_PIC_FAILURE';
export const DELETE_GROUP_PIC_REQUEST = 'DELETE_GROUP_PIC_REQUEST';
export const DELETE_GROUP_PIC_SUCCESS = 'DELETE_GROUP_PIC_SUCCESS';
export const DELETE_GROUP_PIC_FAILURE = 'DELETE_GROUP_PIC_FAILURE';
export const GET_GROUPS_REQUEST = 'GET_GROUPS_REQUEST';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE';
export const SAVE_GROUP_TRANSACTION_REQUEST = 'SAVE_GROUP_TRANSACTION_REQUEST';
export const SAVE_GROUP_TRANSACTION_SUCCESS = 'SAVE_GROUP_TRANSACTION_SUCCESS';
export const SAVE_GROUP_TRANSACTION_FAILURE = 'SAVE_GROUP_TRANSACTION_FAILURE';
export const DELETE_GROUP_TRANSACTION_REQUEST = 'DELETE_GROUP_TRANSACTION_REQUEST';
export const DELETE_GROUP_TRANSACTION_SUCCESS = 'DELETE_GROUP_TRANSACTION_SUCCESS';
export const DELETE_GROUP_TRANSACTION_FAILURE = 'DELETE_GROUP_TRANSACTION_FAILURE';
export const CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE';
export const RENAME_GROUP_REQUEST = 'RENAME_GROUP_REQUEST';
export const RENAME_GROUP_SUCCESS = 'RENAME_GROUP_SUCCESS';
export const RENAME_GROUP_FAILURE = 'RENAME_GROUP_FAILURE';
export const DELETE_GROUP_USER_REQUEST = 'DELETE_GROUP_USER_REQUEST';
export const DELETE_GROUP_USER_SUCCESS = 'DELETE_GROUP_USER_SUCCESS';
export const DELETE_GROUP_USER_FAILURE = 'DELETE_GROUP_USER_FAILURE';
export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';
export const ADD_PEOPLE_TO_GROUP_REQUEST = 'ADD_PEOPLE_TO_GROUP_REQUEST';
export const ADD_PEOPLE_TO_GROUP_SUCCESS = 'ADD_PEOPLE_TO_GROUP_SUCCESS';
export const ADD_PEOPLE_TO_GROUP_FAILURE = 'ADD_PEOPLE_TO_GROUP_FAILURE';
export const JOIN_GROUP_REQUEST = 'JOIN_GROUP_REQUEST';
export const JOIN_GROUP_SUCCESS = 'JOIN_GROUP_SUCCESS';
export const JOIN_GROUP_FAILURE = 'JOIN_GROUP_FAILURE';
export const GET_GROUP_DETAILS_REQUEST = 'GET_GROUP_DETAILS_REQUEST';
export const GET_GROUP_DETAILS_SUCCESS = 'GET_GROUP_DETAILS_SUCCESS';
export const GET_GROUP_DETAILS_FAILURE = 'GET_GROUP_DETAILS_FAILURE';
