import React from 'react';
import Svg, { Path } from 'react-native-svg';
import useColors from 'hooks/useColors';
const FeedIcon = ({ isActive }) => {
    const colors = useColors();
    return (<Svg width={32} height={32} viewBox="0 0 32 32">
      <Path fillRule="evenodd" clipRule="evenodd" fill={isActive ? colors.elements.brand : colors.elements.secondary} d="M23.8652 23.4175C23.5587 22.3669 22.5077 21.6622 21.7642 20.8764C20.6084 19.6548 22.3578 18.5589 22.9404 17.5833C23.394 16.8228 23.4571 15.7783 22.9382 15.0351C22.429 14.3059 21.527 13.7797 21.3526 12.8886C21.2315 12.2691 21.661 11.7108 21.7755 11.1175C21.8743 10.6062 21.932 10.0712 21.9063 9.55204C21.8884 9.19677 21.6688 8.71608 21.8796 8.3856C22.4318 7.52076 23.2619 6.38232 22.8986 5.36959C22.6464 4.66722 21.9226 4.04505 21.0596 4.00247C20.4892 3.97447 19.9599 4.1874 19.5283 4.50183C19.4853 4.53304 19.0072 4.9379 18.9971 4.93294C18.1328 4.62842 17.0664 4.47616 16.0001 4.47616C14.9334 4.47616 13.8674 4.62842 13.0027 4.93294C12.993 4.9379 12.5146 4.53304 12.4719 4.50183C12.0402 4.1874 11.5107 3.97447 10.9406 4.00247C10.0776 4.04505 9.35347 4.66722 9.10124 5.36959C8.73794 6.38232 9.56838 7.52076 10.1202 8.3856C10.331 8.71608 10.1114 9.19677 10.0939 9.55204C10.0678 10.0712 10.1256 10.6062 10.2247 11.1175C10.3392 11.7108 10.7684 12.2691 10.6473 12.8886C10.4729 13.7797 9.57089 14.3059 9.06171 15.0351C8.5428 15.7783 8.60586 16.8228 9.05983 17.5833C9.6421 18.5589 11.3914 19.6548 10.236 20.8764C9.49245 21.6622 8.44116 22.3669 8.13464 23.4175C7.63331 25.1358 8.50829 28.2443 11.158 27.9847C11.8793 27.9144 12.5642 27.6423 13.2421 27.4317C14.151 27.1487 15.0529 27.1727 16.0001 27.1727C16.9469 27.1727 17.8489 27.1487 18.7581 27.4317C19.436 27.6423 20.1206 27.9144 20.8418 27.9847C23.4919 28.2443 24.3669 25.1358 23.8652 23.4175Z"/>
    </Svg>);
};
export default FeedIcon;
