import React, { memo, useCallback, useEffect, useState } from 'react';
import { View, Linking, FlatList, StyleSheet, } from 'react-native';
import emoji from 'node-emoji';
import deepEqual from 'deep-equal';
import { rem } from 'design-system/values';
import SectionHeader from 'design-system/SectionHeader';
import { IconDocument } from 'design-system/icons/IconDocument';
import { SettingsItemNavigate } from 'design-system/SettingsItem';
import UploadReceiptModal from 'design-system/UploadReceiptModal.web';
import Alert from 'utils/packages/Alert';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useFetchOld, { muteAll } from 'hooks/useFetch';
import { deleteReceipt, getTransactionReceipts, uploadTransactionReceipt, } from 'actions/transactions';
import TransactionReceiptBox from './TransactionReceiptBox';
import TransactionReceiptBoxAdd from './TransactionReceiptBoxAdd';
const keyExtractor = (receipt) => receipt.id.toString();
const TransactionReceiptItem = ({ transaction }) => {
    const dispatch = useAppDispatch();
    const [, , , fetch] = useFetchOld(muteAll);
    const receipts = useAppSelector((store) => store.transactions.selected_transaction_receipts);
    const [state, setState] = useState({
        receipts: receipts || [],
    });
    useEffect(() => {
        if (!deepEqual(receipts, state.receipts)) {
            setState((s) => ({
                ...s,
                // This prevents a flash on the image
                // as the url changes on each API response
                receipts: receipts?.map((r, index, arr) => ({
                    ...r,
                    url: (index === arr.length - 1 ||
                        s.receipts.find((existingR) => existingR.id === r.id)) &&
                        state.receipts[index]
                        ? state.receipts[index].url
                        : r.url,
                })),
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [receipts]);
    const onUploadReceipt = useCallback((promises) => {
        Promise.all(promises).finally(() => {
            fetch(getTransactionReceipts(transaction.id));
        });
    }, [transaction]);
    const onImagesGot = useCallback((files) => {
        if (files.length) {
            const promises = [];
            // eslint-disable-next-line no-undef
            const newFilesToAppendToState = [];
            files.forEach((file) => {
                const body = new FormData();
                body.append('image', file.data);
                promises.push(fetch(uploadTransactionReceipt(transaction.id, body)));
                newFilesToAppendToState.push({
                    id: file.id,
                    isUploading: true,
                    url: file.selectedImg,
                });
            });
            setState((state) => ({
                ...state,
                receipts: [...state.receipts, ...newFilesToAppendToState],
            }));
            onUploadReceipt(promises);
        }
    }, [onUploadReceipt, transaction.id]);
    const uploadReceipt = useCallback(() => {
        UploadReceiptModal.show(onImagesGot);
    }, [onImagesGot]);
    const onPressDelete = useCallback((id) => {
        fetch(deleteReceipt(id)).then((response) => {
            dispatch(getTransactionReceipts(transaction.id));
            return response;
        });
    }, [transaction]);
    const pressedDelete = useCallback((data) => {
        Alert.alert(emoji.emojify('Delete Receipt :face_with_rolling_eyes:'), "Are you sure you want to delete this receipt? You won't be able to get it back.", [
            {
                text: 'Yes',
                style: 'destructive',
                onPress: () => {
                    onPressDelete(data);
                    setState((state) => ({
                        ...state,
                        receipts: state.receipts.filter((item) => item.id !== data),
                    }));
                },
            },
            { text: 'Cancel', style: 'cancel', onPress: () => { } },
        ]);
    }, [onPressDelete]);
    const renderItem = useCallback(({ item, index }) => (<TransactionReceiptBox data={item} index={index} onPressDelete={pressedDelete} onPressImage={() => {
            const url = item?.url;
            if (url) {
                Linking.openURL(url);
            }
        }}/>), [pressedDelete]);
    const renderHeader = useCallback(() => <TransactionReceiptBoxAdd onPressAdd={uploadReceipt}/>, [uploadReceipt]);
    if (!state.receipts?.length) {
        return (<View>
        <SettingsItemNavigate title="Receipt" icon={<IconDocument />} onPress={uploadReceipt}/>
      </View>);
    }
    return (<View>
      <SectionHeader style={styles.header}>Receipt</SectionHeader>
      <FlatList horizontal showsHorizontalScrollIndicator={false} contentContainerStyle={styles.scroll} keyExtractor={keyExtractor} renderItem={renderItem} data={state.receipts} ListHeaderComponent={renderHeader}/>
    </View>);
};
const styles = StyleSheet.create({
    scroll: {
        flexDirection: 'row',
        paddingTop: rem(4),
        paddingBottom: rem(24),
        paddingLeft: rem(16),
        paddingRight: rem(16),
    },
    header: {
        marginLeft: rem(16),
        marginTop: rem(16),
    },
});
export default memo(TransactionReceiptItem);
