import React, { useEffect, useMemo, useRef } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { CollapsableContainer, } from 'design-system/CollapsableContainer';
import Text from 'design-system/Text';
import ToolTip from 'design-system/ToolTip';
import { selectIsGBUser } from 'reducers/selectors';
import { useAppSelector } from 'store/hooks';
import { calculateTotalBalance } from 'utils/accounts';
import { sortSavingsAccounts } from 'utils/api/sortAccounts';
import { rem } from 'design-system/values';
import RowAccount from '../RowAccount';
import RowEmmaInvestAccount from '../RowEmmaInvestAccount';
import RowEmmaWallet from '../RowEmmaWallet';
import RowPotAccount from '../RowPotAccount';
import { keyExtractor } from './util';
import { isWeb } from '../../../../constants';
const AccountsSectionCollapsible = ({ title, data, withTotal, total, color, type, firstCTA, secondCTA, isCollapsedByDefault = true, reverse, rightContent, blurAmount, onRowPress, onRowLongPress, onOpenLogins, onOpenUpgrade, onAddAccount, openAlternative, }) => {
    const userIsGB = useAppSelector(selectIsGBUser);
    const sorted = useMemo(() => sortSavingsAccounts(data, reverse), [data, reverse]);
    const collapsibleContainerHandle = useRef(null);
    const prevDataLength = useRef(data.length);
    useEffect(() => {
        if (prevDataLength.current === 0) {
            if (data.length > 0) {
                collapsibleContainerHandle.current?.expand();
            }
        }
        prevDataLength.current = data.length;
    }, [data.length]);
    const calculatedTotal = useMemo(() => {
        if (total) {
            return total;
        }
        if (withTotal) {
            return calculateTotalBalance(data);
        }
        return undefined;
    }, [total, withTotal, data]);
    const flatList = useMemo(() => (<>
        {sorted.map((item) => {
            if ('currency' in item) {
                return (<RowAccount key={keyExtractor(item)} data={item} onOpenLogins={onOpenLogins} onRowLongPressed={onRowLongPress} onOpenUpgrade={onOpenUpgrade} onPress={onRowPress} style={styles.row} onCard showHidden/>);
            }
            if ('isEmmaWallet' in item) {
                return (<RowEmmaWallet onCard data={item} key={item.id} style={styles.row}/>);
            }
            if ('isEmmaInvestmentAccount' in item) {
                return (<RowEmmaInvestAccount onCard data={item} key={item.id} style={styles.row}/>);
            }
            return (<RowPotAccount onCard data={item} key={item.id} style={styles.row}/>);
        })}
        {!isWeb && onAddAccount && data?.length ? (<TouchableOpacity style={styles.addMoreContainer} onPress={() => onAddAccount(type)}>
            <Text Text-16 Primary centered>
              + Add more
            </Text>
          </TouchableOpacity>) : undefined}
      </>), [
        sorted,
        onAddAccount,
        data?.length,
        onOpenLogins,
        onRowLongPress,
        onOpenUpgrade,
        onRowPress,
        type,
    ]);
    const titleBottomContent = useMemo(() => {
        if (data?.length || isWeb) {
            return null;
        }
        if (firstCTA && onAddAccount) {
            const elements = [
                <TouchableOpacity key="firstCTA" onPress={() => {
                        onAddAccount(type);
                    }}>
          <Text TextThin-14 Primary>
            {firstCTA}
          </Text>
        </TouchableOpacity>,
            ];
            if (secondCTA && openAlternative && userIsGB) {
                elements.push(<Text key="or" TextThin-14 Gray>
            {' or '}
          </Text>);
                elements.push(<TouchableOpacity key="secondCTA" onPress={() => {
                        openAlternative(type);
                    }}>
            <Text TextThin-14 Primary>
              {secondCTA}
            </Text>
          </TouchableOpacity>);
            }
            return <View style={styles.callToActionRow}>{elements}</View>;
        }
        return null;
    }, [
        userIsGB,
        data,
        type,
        firstCTA,
        secondCTA,
        onAddAccount,
        openAlternative,
    ]);
    if ((!data || data.length <= 0) && !(onAddAccount && firstCTA)) {
        return null;
    }
    if (calculatedTotal && color) {
        return (<CollapsableContainer ref={collapsibleContainerHandle} style={styles.container} isCollapsedByDefault={isCollapsedByDefault} title={title} amount={calculatedTotal} color={color} titleBottomContent={titleBottomContent} rightContent={rightContent} hasData={!!sorted.length} blurAmount={blurAmount}>
        {flatList}
      </CollapsableContainer>);
    }
    const isHidden = title.startsWith('Hidden');
    return (<CollapsableContainer ref={collapsibleContainerHandle} style={styles.container} isCollapsedByDefault={isCollapsedByDefault} title={title} hasData={!!sorted.length} titleContent={isWeb ? undefined : (<ToolTip small offset={3} title={isHidden
                ? 'What are hidden accounts?'
                : 'What are closed accounts?'} subtitle={isHidden
                ? "Hidden account keep syncing but they aren't included in your analytics. While they remain connected, all transactions on these accounts will be hidden."
                : "Closed accounts don't sync anymore, but their data shows across the app. You can move them here once you have closed your account with a bank, so you don't lose your data."}/>)} rightContent={rightContent} titleBottomContent={titleBottomContent}>
      {flatList}
    </CollapsableContainer>);
};
const styles = StyleSheet.create({
    container: {
        marginHorizontal: rem(16),
        marginBottom: rem(16),
    },
    row: {
        paddingHorizontal: rem(16),
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
    addMoreContainer: {
        padding: rem(16),
    },
    callToActionRow: {
        flexDirection: 'row',
    },
});
export default AccountsSectionCollapsible;
