import React from 'react';
import { View } from 'react-native';
import IconPieChart from 'design-system/icons/IconPieChart';
import IconPlus from 'design-system/icons/IconPlus';
import Squircle from 'design-system/Squircle';
import Text from 'design-system/Text';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { rem } from 'design-system/values';
import TouchableBounceCard from 'utils/packages/TouchableBounceCard';
const SetupBudgetCard = ({ onPress, onLight, title, subTitle, noMarginBottom, }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    return (<TouchableBounceCard onDark onPress={onPress} noMarginBottom={noMarginBottom} style={[styles.card, onLight && styles.cardOnLight]}>
      <Squircle width={rem(44)} height={rem(44)} color={onLight ? colors.background.light : colors.background.dark}>
        <IconPieChart color={colors.gradients.ultimate.colors[0]}/>
      </Squircle>
      <View style={styles.content}>
        <View style={styles.titleContainer}>
          <Text Text-16>{title || 'Budget'}</Text>
          <Text TextThin-14 Secondary>
            {subTitle || 'Set up a budget'}
          </Text>
        </View>
        <View style={styles.plusIconContainer}>
          <IconPlus color={colors.text.textOnBrand}/>
        </View>
      </View>
    </TouchableBounceCard>);
};
const styleSet = createStyleSheets((colors) => ({
    card: {
        padding: rem(16),
        marginTop: 0,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cardOnLight: {
        backgroundColor: colors.cards.onLight,
    },
    content: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    titleContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        marginStart: rem(16),
    },
    plusIconContainer: {
        padding: rem(8),
        borderRadius: rem(8),
        backgroundColor: colors.buttons.brandLight,
    },
}));
export default SetupBudgetCard;
