import React, { useId } from 'react';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';
import { rem } from 'design-system/values';
const IconFingerprint = ({ width = rem(67), height = rem(67), color, ...props }) => {
    const id = useId();
    return (<Svg width={width} height={height} fill="none" viewBox="0 0 67 67" {...props}>
      <G clipPath={`url(#clip_a_${id})`} fill={color}>
        <Path d="M57.173 13.445a2.072 2.072 0 0 1-1.41-.547c-6.16-5.617-14.067-8.71-22.263-8.71-8.196 0-16.102 3.093-22.264 8.712a2.096 2.096 0 0 1-2.822-3.096C15.351 3.481 24.259 0 33.5 0c9.24 0 18.149 3.481 25.086 9.804.854.78.916 2.102.137 2.96a2.09 2.09 0 0 1-1.55.68Z"/>
        <Path d="M60.719 27.127a2.086 2.086 0 0 1-1.74-.927C53.168 17.546 43.643 12.378 33.5 12.378S13.833 17.546 8.02 26.2a2.094 2.094 0 1 1-3.478-2.334C11.133 14.053 21.96 8.194 33.5 8.194c11.54 0 22.367 5.86 28.958 15.675a2.092 2.092 0 0 1-1.74 3.258Z"/>
        <Path d="M26.887 67a2.09 2.09 0 0 1-1.488-.62c-.458-.46-11.187-11.412-11.187-21.956 0-10.944 8.652-19.846 19.288-19.846 10.636 0 19.288 8.902 19.288 19.846a2.095 2.095 0 0 1-4.188 0c0-8.635-6.772-15.659-15.1-15.659S18.4 35.79 18.4 44.424c0 8.841 9.874 18.908 9.975 19.008A2.092 2.092 0 0 1 26.887 67Z"/>
        <Path d="M40.114 67c-5.037 0-16.711-9.58-17.842-20.25-.4-3.772.801-7.63 3.208-10.321 2.093-2.34 4.921-3.638 7.961-3.655h.06c2.964 0 5.767 1.164 7.9 3.283 2.227 2.214 3.453 5.187 3.453 8.37v1.365c0 3.364 2.618 6.1 5.84 6.1s5.843-2.736 5.843-6.1v-.477c0-13.038-9.444-23.883-21.502-24.687-6.283-.427-12.316 1.745-16.97 6.094-4.83 4.517-7.599 10.971-7.599 17.705 0 5.538 1.876 10.935 5.282 15.195a2.094 2.094 0 0 1-3.272 2.613c-3.995-5-6.197-11.326-6.197-17.811 0-7.89 3.255-15.457 8.924-20.762 5.509-5.153 12.652-7.719 20.109-7.216 14.249.952 25.412 13.632 25.412 28.866v.477c0 5.673-4.5 10.287-10.03 10.287-5.53 0-10.028-4.614-10.028-10.287v-1.365c0-2.06-.787-3.975-2.216-5.4-1.34-1.334-3.096-2.065-4.947-2.065h-.04c-2.358.011-3.947 1.237-4.862 2.261-1.633 1.823-2.443 4.475-2.167 7.088.924 8.705 10.927 16.508 13.68 16.508a2.092 2.092 0 1 1 0 4.185Z"/>
        <Path d="M47.883 62.905c-7.493 0-16.642-9.157-16.642-19.846a2.095 2.095 0 0 1 4.188 0c0 8.285 6.965 15.658 12.454 15.658a2.094 2.094 0 0 1 0 4.188Z"/>
      </G>
      <Defs>
        <ClipPath id={`clip_a_${id}`}>
          <Path fill={color} d="M0 0h67v67H0z"/>
        </ClipPath>
      </Defs>
    </Svg>);
};
export default IconFingerprint;
