import React from 'react';
import { View, StyleSheet } from 'react-native';
import Text from 'design-system/Text';
import Button from 'design-system/Button';
import Squircle from 'design-system/Squircle';
import { IconHeart } from 'design-system/icons';
import { rem, Spacer } from 'design-system';
import useColors from 'hooks/useColors';
import Amplitude from 'utils/amplitude';
const ModalRating = React.memo(({ onPressed }) => {
    const colors = useColors();
    const isOkay = () => {
        onPressed(false);
        Amplitude.logEvent('Transaction.reviewTaken', { value: 'neutral' });
    };
    const isHate = () => {
        onPressed(false);
        Amplitude.logEvent('Transaction.reviewTaken', { value: 'hate' });
    };
    const onAccept = () => {
        onPressed(true);
        Amplitude.logEvent('Transaction.reviewTaken', { value: 'love' });
    };
    return (<View style={styles.modal}>
      <View style={styles.modalContainer}>
        <Squircle width={rem(64)} height={rem(64)} color={colors.background.dark}>
          <IconHeart color={colors.gradients.red.colors[0]}/>
        </Squircle>
        <Spacer h={24}/>
        <Text Text-20 centered>
          Do you love Emma?
        </Text>
        <Spacer h={24}/>
        <Button title="No, I don't" brandBorder onPress={isHate}/>
        <Spacer h={8}/>
        <Button title="It's okay" brandReversed onPress={isOkay}/>
        <Spacer h={8}/>
        <Button title="Yes, it's my fav!" brand onPress={onAccept}/>
      </View>
    </View>);
});
const styles = StyleSheet.create({
    modal: {
        paddingTop: rem(32),
        borderTopLeftRadius: rem(16),
        borderTopRightRadius: rem(16),
    },
    modalContainer: {
        paddingTop: rem(16),
        alignItems: 'center',
    },
});
export default ModalRating;
