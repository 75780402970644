import React, { memo } from 'react';
import { View } from 'react-native';
import Indicator from 'design-system/Indicator';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
const ChartLoadingOverlay = ({ requesting }) => {
    const styles = useStyles(styleSet);
    return requesting ? (<View style={styles.loaderWrapper}>
      <Indicator dark/>
    </View>) : null;
};
export default memo(ChartLoadingOverlay);
const styleSet = createStyleSheets((colors) => ({
    loaderWrapper: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: `${String(colors.cards.onDark)}80`,
    },
}));
