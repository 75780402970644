import { useCallback, useEffect, useRef, useState, } from 'react';
export const useTextInput = (initialState) => {
    const textInputRef = useRef(null);
    const [state, setState] = useState(undefined);
    useEffect(() => {
        if (initialState) {
            setState(initialState);
        }
    }, [initialState]);
    const clear = useCallback(() => {
        setState(undefined);
    }, []);
    return [state, setState, clear, textInputRef];
};
