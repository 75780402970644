import * as types from './types';
import { CALL_API } from '../middleware/api';
export const deleteAllIncomes = () => ({
    [CALL_API]: {
        endpoint: '/income',
        method: 'DELETE',
        addSpaceIdHeader: true,
        types: [
            types.DELETE_ALL_INCOMES_REQUEST,
            types.DELETE_ALL_INCOMES_SUCCESS,
            types.DELETE_ALL_INCOMES_FAILURE,
        ],
    },
});
export const setIncome = (data) => {
    // @ts-expect-error
    const body = {};
    body.isPrimary = data.isPrimary;
    // @ts-expect-error
    body.schedule = {};
    // @ts-expect-error
    body.schedule.recurrence = {};
    body.schedule.start = data.startDate;
    body.schedule.recurrence.frequency = data.frequency;
    body.schedule.recurrence.interval = data.interval;
    if (data.byMonthDay) {
        body.schedule.recurrence.byMonthDay = [];
        if (data && data.byMonthDay && data.byMonthDay.length)
            body.schedule.recurrence.byMonthDay = data.byMonthDay;
        else
            body.schedule.recurrence.byMonthDay[0] = data.byMonthDay;
    }
    body.schedule.recurrence.onHoliday = data.onHoliday;
    if (data.weekDay || data.weekday)
        body.schedule.recurrence.weekday = data.weekDay || data.weekday;
    if (data.weekNumber)
        body.schedule.recurrence.weekNumber = data.weekNumber;
    return {
        [CALL_API]: {
            endpoint: '/incomes',
            method: 'POST',
            addSpaceIdHeader: true,
            body: JSON.stringify({
                ...body,
                deleteAllPrevious: true,
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            types: [
                types.SET_INCOME_REQUEST,
                types.SET_INCOME_SUCCESS,
                types.SET_INCOME_FAILURE,
            ],
        },
    };
};
export const getIncomes = () => ({
    [CALL_API]: {
        endpoint: '/incomes',
        method: 'GET',
        addSpaceIdHeader: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_INCOME_REQUEST,
            types.GET_INCOME_SUCCESS,
            types.GET_INCOME_FAILURE,
        ],
    },
});
