import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from './Icon';
const IconLockUnlocked = ({ ...props }) => {
    const id = useId();
    return (<IconWithGradient id={id} defaultGradientKey="ultimate" {...props}>
      <Path fillRule="evenodd" clipRule="evenodd" d="M12.7485 4.49227C12.2598 4.37345 11.7482 4.37588 11.2606 4.49934L11.1984 4.51508C9.86418 4.85297 8.90433 5.9653 8.77467 7.27868L8.8085 7.27171C10.9128 6.83846 13.0872 6.83846 15.1915 7.27171L15.4427 7.32342C17.5171 7.75053 19.165 9.28147 19.6983 11.2772C20.1955 13.1377 20.0758 15.1033 19.3563 16.8937L19.2718 17.104C18.5 19.0245 16.7426 20.406 14.6498 20.7373L13.9687 20.8452C12.6648 21.0516 11.3352 21.0516 10.0313 20.8452L9.35019 20.7373C7.25734 20.406 5.49999 19.0245 4.72818 17.104L4.64368 16.8937C3.92417 15.1033 3.80447 13.1377 4.30168 11.2772C4.72319 9.69995 5.8408 8.413 7.31497 7.73181V7.5833C7.31497 5.50043 8.76161 3.68041 10.8344 3.15551L10.8965 3.13977C11.6182 2.95702 12.3755 2.95342 13.0989 3.1293C13.882 3.31967 14.5971 3.71341 15.1678 4.26842L15.4002 4.49442C15.6823 4.76875 15.6823 5.21352 15.4002 5.48784C15.1181 5.76217 14.6608 5.76217 14.3787 5.48784L14.1463 5.26184C13.7607 4.88688 13.2775 4.62088 12.7485 4.49227ZM12.5779 13.4206C12.5779 13.1103 12.3192 12.8587 12 12.8587C11.6809 12.8587 11.4221 13.1103 11.4221 13.4206V15.2938C11.4221 15.6042 11.6809 15.8558 12 15.8558C12.3192 15.8558 12.5779 15.6042 12.5779 15.2938V13.4206Z" fill={`url(#gradient_${id})`} fillOpacity="0.9"/>
    </IconWithGradient>);
};
export default IconLockUnlocked;
