import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import Animated, { withTiming, useSharedValue, useAnimatedStyle, } from 'react-native-reanimated';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import { IconClose } from 'design-system/icons/IconClose';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableBounce from 'utils/packages/TouchableBounce';
import Content from './Content';
const MultiSelectAccountModal = () => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const opacitySharedValue = useSharedValue(0);
    const [state, setState] = useState({
        isOpen: false,
        accounts: [],
        alreadySelected: [],
        onSelected: () => { },
        showExcludedTxns: true,
    });
    useEffect(() => {
        MultiSelectAccountModal.setStateRef = setState;
    }, []);
    useEffect(() => {
        if (state.isOpen) {
            opacitySharedValue.value = withTiming(1, {
                duration: 100,
            });
        }
    }, [state.isOpen]);
    const onClosed = useCallback(() => {
        opacitySharedValue.value = withTiming(0, {
            duration: 100,
        }, () => {
            setState((state) => ({ ...state, isOpen: false }));
        });
    }, []);
    const close = useCallback(() => {
        onClosed();
    }, [onClosed]);
    const containerAnimatedStyles = useAnimatedStyle(() => ({
        opacity: opacitySharedValue.value,
    }), [opacitySharedValue.value]);
    if (!state.isOpen) {
        return null;
    }
    return (<Animated.View style={[styles.container, containerAnimatedStyles]}>
      <View style={styles.contentContainer}>
        <View style={styles.headerRow}>
          <View style={styles.titleContainer}>
            <Text Text-20>Filter</Text>
          </View>
          <TouchableBounce onPress={close} style={styles.closeBtn} hoverStyle={styles.closeHoverStyles}>
            <IconClose color={colors.elements.primary}/>
          </TouchableBounce>
        </View>
        <Content {...state} setState={setState}/>
      </View>
    </Animated.View>);
};
MultiSelectAccountModal.show = (options) => {
    if (MultiSelectAccountModal.setStateRef) {
        MultiSelectAccountModal.setStateRef({ isOpen: true, ...options });
    }
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        ...StyleSheet.absoluteFillObject,
        zIndex: 100,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.6)',
    },
    contentContainer: {
        width: rem(600),
        padding: rem(16),
        borderRadius: rem(24),
        backgroundColor: colors.cards.onLight,
    },
    headerRow: {
        alignItems: 'center',
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: colors.borders.divider,
    },
    titleContainer: {
        flex: 1,
        justifyContent: 'center',
        paddingVertical: rem(16),
    },
    closeBtn: {
        width: rem(40),
        height: rem(40),
        alignItems: 'center',
        borderRadius: rem(8),
        justifyContent: 'center',
    },
    closeHoverStyles: {
        backgroundColor: colors.control.hoverOnLight,
    },
}));
export default MultiSelectAccountModal;
