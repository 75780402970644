import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { useMarginBottom } from 'hooks/useMarginBottom';
import KeyboardAvoidingView from './KeyboardAvoidingView';
import { rem } from './values';
const KeyboardAvoidingContainer = ({ keyboardVerticalOffset, children, style, isInTab, }) => {
    const marginBottom = useMarginBottom('marginBottom', undefined, styles.inner, isInTab ? styles.bottomMargin : undefined);
    return (<KeyboardAvoidingView keyboardVerticalOffset={Platform.OS === 'ios' && keyboardVerticalOffset
            ? keyboardVerticalOffset
            : 0} style={[styles.container, style]} behavior={Platform.OS === 'android' ? undefined : 'padding'} enabled>
      <View style={marginBottom}>{children}</View>
    </KeyboardAvoidingView>);
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginHorizontal: rem(16),
        justifyContent: 'space-evenly',
    },
    inner: {
        flex: 1,
    },
    bottomMargin: {
        marginBottom: rem(16),
    },
});
export default KeyboardAvoidingContainer;
