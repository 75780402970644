import React, { useCallback, useMemo } from 'react';
import Section from 'design-system/Section';
import { CardView } from 'design-system/CardView';
import { useAppSelector } from 'store/hooks';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { makeSelectTransactionById } from 'features/transactions/selectors';
import { styles } from './styles';
import RowTransaction from '../RowTransaction';
const TransactionLinkedTransactionSection = ({ transaction, }) => {
    const memoSelectTransactionByIdForLinked = useMemo(makeSelectTransactionById, []);
    const linkedTransaction = useAppSelector((store) => memoSelectTransactionByIdForLinked(store, 'relatedTransactionId' in transaction
        ? transaction.relatedTransactionId
        : undefined));
    const navigation = useMainStackNavigation();
    const openLinked = useCallback(() => {
        if (linkedTransaction) {
            navigation.push('Item', {
                id: linkedTransaction.id,
            });
        }
    }, [linkedTransaction]);
    if (!linkedTransaction) {
        return null;
    }
    return (<Section title="Linked Transaction" style={styles.section}>
      <CardView noMarginTop noMarginBottom webPadding>
        <RowTransaction backgroundDark onPress={openLinked} item={linkedTransaction}/>
      </CardView>
    </Section>);
};
export default TransactionLinkedTransactionSection;
