import React, { memo } from 'react';
import { Image, TouchableOpacity, useWindowDimensions, View, } from 'react-native';
import { Indicator } from 'design-system/Indicator';
import TouchableScale from 'design-system/TouchableScale';
import { rem } from 'design-system/values';
import Text from 'design-system/Text';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import useIsTablet from 'hooks/useIsTablet';
import normalize from '../Normalize';
const CircleButton = ({ item, secondOption, isTouchIDIcon, onPress, onPressOption, }) => {
    const pressed = () => {
        onPress(item);
    };
    const pressedOption = () => {
        onPressOption(item);
    };
    const renderNumber = () => (<View style={styles.elementContainer}>
      <TouchableScale style={styles.button} onPress={pressed}>
        <Text Numbers-24 OffBlack>
          {item.title}
        </Text>
      </TouchableScale>
    </View>);
    const styles = useStyles(styleSet);
    const isTablet = useIsTablet();
    const { width, height } = useWindowDimensions();
    const renderText = () => {
        const { id } = item;
        if (id === 10 && !secondOption) {
            return null;
        }
        const isImage = (id === 12 && isTouchIDIcon) || (id === 10 && secondOption === 'back');
        if (id === 10 && secondOption === 'fetching') {
            return (<View style={styles.cancelContainer}>
          <Indicator dark/>
        </View>);
        }
        return (<TouchableOpacity onPress={pressedOption} style={styles.cancelContainer}>
        {isImage && item.image ? (<Image source={item.image} resizeMode="contain" style={styles.imageWithTint}/>) : (<Text Text-16 OffBlack>
            {item.title}
          </Text>)}
      </TouchableOpacity>);
    };
    return (<View style={[
            styles.elementContainer,
            isTablet && width > height ? styles.squareTablet : styles.square,
        ]}>
      {item.id !== 10 && item.id !== 12 ? renderNumber() : renderText()}
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    elementContainer: {
        flex: 1,
        alignItems: 'center',
    },
    square: {
        aspectRatio: 1.3,
    },
    squareTablet: {
        aspectRatio: 1.8,
    },
    cancelContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        height: normalize(66),
        width: normalize(66),
    },
    button: {
        height: rem(80),
        width: rem(80),
        alignItems: 'center',
        justifyContent: 'center',
    },
    imageWithTint: {
        tintColor: colors.elements.primary,
    },
}));
export default memo(CircleButton);
