import React, { useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import { Animated, Easing, Vibration, View } from 'react-native';
import RNModal from 'utils/packages/rnModal';
import PinPad from 'common/passcode/PinPad';
import SmallCircleProgress from 'common/passcode/SmallCircleProgress';
import { usePasscodeVerifier } from 'hooks/usePasscodeVerifier';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import useForgotPasscode from 'hooks/useForgotPasscode';
import { useDefaultHeaderHeight } from 'hooks/useHeaderHeight';
import { AppView } from './AppView';
import { IconEmma } from './icons';
import Spacer from './Spacer';
import Text from './Text';
import { rem } from './values';
import StatusBar from './StatusBar';
import Modal from './Modal';
const strings = {
    title: 'Enter Your Pin Code',
    passcodeFailed: 'Your code is wrong!',
};
const PasscodeModal = () => {
    const [passcodeVerifier] = usePasscodeVerifier();
    const { onForgotPasscode, fetchingForgotStep } = useForgotPasscode(true);
    const [state, setState] = useState({
        visible: false,
        title: strings.title,
        options: {
            onSuccess: () => { },
            onFailure: () => { },
        },
        passcode: '',
        passcodeFailed: false,
    });
    const shakeAnimatedValue = useRef(new Animated.Value(0));
    const opacityAnimatedValue = useRef(new Animated.Value(1));
    const close = useCallback(() => {
        setState((state) => ({
            ...state,
            visible: false,
        }));
    }, []);
    const closeWithFailure = useCallback(() => {
        if (state.options.onFailure) {
            state.options.onFailure();
        }
        setState((state) => ({
            ...state,
            visible: false,
        }));
    }, [state.options]);
    const cleanup = useCallback(() => {
        if (PasscodeModal.onDidHideCallback) {
            PasscodeModal.onDidHideCallback();
            PasscodeModal.onDidHideCallback = undefined;
        }
    }, []);
    const onFilled = useCallback(async (passcodeEntered) => {
        try {
            if (await passcodeVerifier(passcodeEntered, true)) {
                close();
                state.options.onSuccess(passcodeEntered);
            }
            else {
                setState((oldState) => ({ ...oldState, passcodeFailed: true }));
                Vibration.vibrate();
                shakeAnimatedValue.current.setValue(0);
                Animated.timing(shakeAnimatedValue.current, {
                    duration: 400,
                    toValue: 3,
                    easing: Easing.bounce,
                    useNativeDriver: true,
                }).start(() => {
                    setState((oldState) => ({ ...oldState, passcode: '' }));
                });
            }
        }
        catch (e) {
            setState((oldState) => ({ ...oldState, passcode: '' }));
        }
    }, [close, state.options, passcodeVerifier]);
    const onPinPadButtonPress = useCallback((item) => {
        setState((oldState) => {
            const updatedPasscode = oldState.passcode + item.title;
            if (updatedPasscode.length <= 4) {
                return { ...oldState, passcode: updatedPasscode };
            }
            return oldState;
        });
    }, []);
    useEffect(() => {
        PasscodeModal.setStateRef = setState;
    }, []);
    useEffect(() => {
        if (state.passcode.length === 4) {
            onFilled(state.passcode);
        }
    }, [state.passcode, onFilled]);
    const styles = useStyles(styleSet);
    const subtitle = useMemo(() => {
        if (state.passcodeFailed) {
            return (<Text TextThin-14 Secondary centered style={styles.subtitle}>
          {strings.passcodeFailed}
        </Text>);
        }
        return null;
    }, [state.passcodeFailed, styles.subtitle]);
    const shakeAnimation = {
        transform: [
            {
                translateX: shakeAnimatedValue.current.interpolate({
                    inputRange: [0, 0.5, 1, 1.5, 2, 2.5, 3],
                    outputRange: [0, -15, 0, 15, 0, -15, 0],
                }),
            },
        ],
    };
    const defaultHeaderHeight = useDefaultHeaderHeight();
    const opacityAnimation = { opacity: opacityAnimatedValue.current };
    const forgot = useCallback(async (item) => {
        if (item.id === 10) {
            const res = await onForgotPasscode();
            if (res) {
                Modal.hide();
                Modal.hide(undefined, { top: true });
                close();
            }
        }
        else {
            close();
        }
    }, [close, onForgotPasscode]);
    return (<RNModal isVisible={state.visible} style={styles.modal} coverScreen swipeDirection="down" onSwipeComplete={closeWithFailure} onModalHide={cleanup}>
      <StatusBar barStyle="dark-content" animated/>
      <AppView style={[
            styles.container,
            { paddingTop: defaultHeaderHeight - rem(30) },
        ]} type="primary">
        <View style={styles.titleContainer}>
          <IconEmma colorKey="brand" height={52} width={52}/>
          <Spacer h={18}/>
          <Text Text-20 OffBlack centered style={styles.title}>
            {state.title}
          </Text>
          {subtitle}
        </View>
        <Animated.View style={[styles.passcodeContainer, shakeAnimation, opacityAnimation]}>
          <Spacer flex={0.5}/>
          <SmallCircleProgress itemStyle={styles.cell} length={state.passcode.length}/>
          <Spacer flex={1}/>
          <View style={styles.pinPadContainer}>
            <PinPad onPress={onPinPadButtonPress} onPressOption={forgot} secondOption={fetchingForgotStep ? 'fetching' : true}/>
          </View>
        </Animated.View>
      </AppView>
    </RNModal>);
};
PasscodeModal.show = (options) => {
    const setState = PasscodeModal.setStateRef;
    if (setState) {
        setState({
            visible: true,
            title: options.title ?? strings.title,
            options: {
                onSuccess: options.onSuccess,
                onFailure: options.onFailure,
            },
            passcode: '',
            passcodeFailed: false,
        });
    }
};
PasscodeModal.hide = (onDidHideCallback) => {
    const setState = PasscodeModal.setStateRef;
    if (setState) {
        PasscodeModal.onDidHideCallback = onDidHideCallback;
        setState((state) => ({ ...state, visible: false }));
    }
};
const styleSet = createStyleSheets((colors) => ({
    modal: {
        margin: 0,
    },
    container: {
        flex: 1,
    },
    titleContainer: {
        alignItems: 'center',
        flex: 0.7,
    },
    passcodeContainer: {
        flex: 3,
    },
    pinPadContainer: {
        flex: 0,
    },
    title: {
        marginHorizontal: rem(20),
    },
    subtitle: {
        marginHorizontal: rem(20),
    },
    cell: {
        backgroundColor: colors.text.brand,
    },
}));
export default PasscodeModal;
