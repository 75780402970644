import React, { useCallback } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { rem } from 'design-system';
import Text from 'design-system/Text';
const TransactionHelpItem = ({ title, subTitle, onPress, }) => {
    const [isHovering, setIsHovering] = React.useState(false);
    const handleEvent = useCallback((event) => () => {
        setIsHovering(event === 'onHoverIn');
    }, []);
    return (<Pressable style={styles.container} onHoverIn={handleEvent('onHoverIn')} onHoverOut={handleEvent('onHoverOut')} onPress={onPress}>
      <Text Text-16 OffBlack style={styles.title}>
        {title}
      </Text>
      <Text TextThin-14 Primary underline={isHovering}>
        {subTitle}
      </Text>
    </Pressable>);
};
const styles = StyleSheet.create({
    container: {
        padding: rem(16),
        flexShrink: 1,
    },
    title: {
        paddingBottom: rem(4),
    },
});
export default TransactionHelpItem;
