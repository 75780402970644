import i18n from 'i18n-js';

import en from './locales/en.json';
import en_US from './locales/en_us.json';

i18n.defaultLocale = 'en';
i18n.locale = 'en';
i18n.fallbacks = true;

i18n.translations.en = en;
i18n.translations['en-US'] = en_US;

export default i18n;
