import React from 'react';
import { View } from 'react-native';
import { rem } from 'design-system';
import { CIRCLE_SIZE } from 'design-system/LogoBankAccount';
import { IconEmma } from 'design-system/icons';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
const IconEmmaWallet = ({ onCard }) => {
    const styles = useStyles(styleSet);
    return (<View style={[
            styles.container,
            onCard && styles.onCard, // in dark mode, when shown on a card, should have darker background
        ]}>
      <IconEmma gradientKey="ultimate"/>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        alignItems: 'center',
        borderRadius: rem(22),
        width: rem(CIRCLE_SIZE),
        justifyContent: 'center',
        height: rem(CIRCLE_SIZE),
        backgroundColor: colors.cards.onLight,
    },
    onCard: {
        backgroundColor: colors.background.dark,
    },
}));
export default IconEmmaWallet;
