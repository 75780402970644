import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { getItems } from 'utils/jwtHelpers.web';
const checkCookies = () => {
    const cookies = getItems(['access_token', 'refresh_token']);
    const isSignedIn = Boolean(cookies[0][1] && cookies[1][1]);
    return isSignedIn;
};
export function useIsSignedInWeb() {
    const [isSignedIn, setIsSignedIn] = useState(checkCookies());
    const userId = useAppSelector((store) => store.user.user.id);
    const prevUser = useRef(userId);
    useEffect(() => {
        if (checkCookies()) {
            setIsSignedIn(true);
        }
        else if (userId < 0 && prevUser.current) {
            setIsSignedIn(false);
        }
        prevUser.current = userId;
    }, [userId]);
    return {
        isSignedIn,
    };
}
