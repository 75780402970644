import { createSelector } from 'reselect';
export const makeSelectTransactionById = () => createSelector([
    (store, transactionId) => transactionId && store.transactions.transactionCache
        ? store.transactions.transactionCache[transactionId]
        : undefined,
    (store) => store.user.categories,
    (store) => store.user.customCategories,
], (transaction, categories, customCategories) => {
    if (transaction) {
        const categoryId = 'isCompact' in transaction && transaction.isCompact
            ? transaction.categoryId
            : transaction.category?.id;
        const category = categories?.find((cat) => cat.id === categoryId) ||
            customCategories?.find((cat) => cat.id === categoryId) ||
            transaction.category;
        if (!category)
            return undefined;
        return {
            ...transaction,
            category,
        };
    }
    return undefined;
});
