export const GET_QUIZ_REQUEST = 'GET_QUIZ_REQUEST';
export const GET_QUIZ_SUCCESS = 'GET_QUIZ_SUCCESS';
export const GET_QUIZ_FAILURE = 'GET_QUIZ_FAILURE';
export const DISMISS_QUIZ_REQUEST = 'DISMISS_QUIZ_REQUEST';
export const DISMISS_QUIZ_SUCCESS = 'DISMISS_QUIZ_SUCCESS';
export const DISMISS_QUIZ_FAILURE = 'DISMISS_QUIZ_FAILURE';
export const POST_ANSWER_REQUEST = 'POST_ANSWER_REQUEST';
export const POST_ANSWER_SUCCESS = 'POST_ANSWER_SUCCESS';
export const POST_ANSWER_FAILURE = 'POST_ANSWER_FAILURE';
export const START_QUIZ_REQUEST = 'START_QUIZ_REQUEST';
export const START_QUIZ_SUCCESS = 'START_QUIZ_SUCCESS';
export const START_QUIZ_FAILURE = 'START_QUIZ_FAILURE';
