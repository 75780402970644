import { CALL_API } from '../middleware/api';
export const GET_ADDRESSES_REQUEST = 'GET_ADDRESSES_REQUEST';
export const GET_ADDRESSES_SUCCESS = 'GET_ADDRESSES_SUCCESS';
export const GET_ADDRESSES_FAILURE = 'GET_ADDRESSES_FAILURE';
export const POST_USER_ADDRESSES_REQUEST = 'POST_USER_ADDRESSES_REQUEST';
export const POST_USER_ADDRESSES_SUCCESS = 'POST_USER_ADDRESSES_SUCCESS';
export const POST_USER_ADDRESSES_FAILURE = 'POST_USER_ADDRESSES_FAILURE';
export const GET_USER_ADDRESSES_REQUEST = 'GET_USER_ADDRESSES_REQUEST';
export const GET_USER_ADDRESSES_SUCCESS = 'GET_USER_ADDRESSES_SUCCESS';
export const GET_USER_ADDRESSES_FAILURE = 'GET_USER_ADDRESSES_FAILURE';
export const UPDATE_USER_ADDRESSES_REQUEST = 'UPDATE_USER_ADDRESSES_REQUEST';
export const UPDATE_USER_ADDRESSES_SUCCESS = 'UPDATE_USER_ADDRESSES_SUCCESS';
export const UPDATE_USER_ADDRESSES_FAILURE = 'UPDATE_USER_ADDRESSES_FAILURE';
export const SELECT_ADDRESS = 'SELECT_ADDRESS';
export const CLEAR_ADDRESS_LIST = 'CLEAR_ADDRESS_LIST';
export const selectAddress = (data) => ({
    type: SELECT_ADDRESS,
    payload: data,
});
export const postUserAddress = (body) => ({
    [CALL_API]: {
        endpoint: '/user-addresses',
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            POST_USER_ADDRESSES_REQUEST,
            POST_USER_ADDRESSES_SUCCESS,
            POST_USER_ADDRESSES_FAILURE,
        ],
    },
});
export const getUserAddresses = () => ({
    [CALL_API]: {
        endpoint: '/user-addresses',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            GET_USER_ADDRESSES_REQUEST,
            GET_USER_ADDRESSES_SUCCESS,
            GET_USER_ADDRESSES_FAILURE,
        ],
    },
});
export const updateUserAddress = (id, body) => ({
    [CALL_API]: {
        endpoint: `/user-addresses/${id}`,
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            UPDATE_USER_ADDRESSES_REQUEST,
            UPDATE_USER_ADDRESSES_SUCCESS,
            UPDATE_USER_ADDRESSES_FAILURE,
        ],
    },
});
export const findAddress = (postcode) => ({
    [CALL_API]: {
        endpoint: `/user-addresses/lookup/${postcode}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            GET_ADDRESSES_REQUEST,
            GET_ADDRESSES_SUCCESS,
            GET_ADDRESSES_FAILURE,
        ],
    },
});
