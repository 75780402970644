import { loanTypeReasonValue, } from 'features/save/screens/monevo/models';
// TODO when other components are updated to typescript we should refactor this to use promises better
const getQuotes = async (route, getEnquiries, getSwitchingResults, onCompareCallBack) => {
    let enquiryId = null;
    const enquiryType = route === 'BroadBandQuotes' ? 'BROADBANDCHOICES' : 'MONEYSUPERMARKET';
    const response = await getEnquiries(enquiryType);
    if (response && response.payload && response.payload.length) {
        for (let index = 0; index < response.payload.length; index++) {
            if (response.payload[index].id && response.payload[index].partnerId) {
                const body = {};
                body.enquiryId = response.payload[index].id;
                body.partnerId = response.payload[index].partnerId;
                enquiryId = response.payload[index].id;
                getSwitchingResults(response.payload[index].id);
                break;
            }
        }
    }
    if (enquiryId)
        onCompareCallBack(route, enquiryId);
};
const getLoans = async (getEnquiries, type, loansCallBack, loanType) => {
    const response = await getEnquiries(type);
    const loanPurposeNumber = loanType
        ? loanTypeReasonValue[loanType]
        : undefined;
    if (response && response.payload && response.payload.length) {
        for (let index = 0; index < response.payload.length; index++) {
            const loanPurposeForIndex = response.payload[index].metadata?.qs?.loan_purpose;
            if (response.payload[index].metadata?.url &&
                ((!loanPurposeNumber &&
                    loanPurposeForIndex !== loanTypeReasonValue.CREDIT_CARD) ||
                    loanPurposeNumber === loanPurposeForIndex)) {
                loansCallBack(response.payload[index].metadata?.url, loanType);
                return;
            }
        }
    }
    loansCallBack(null, loanType);
};
const getCompare = async (route, getEnquiries, getSwitchingResults, getSwitchingQuotes, getEnergySwitchingResults, onCompareCallBack) => {
    let enquiryId = null;
    const enquiryType = route === 'Broadband' ? 'BROADBANDCHOICES' : 'MONEYSUPERMARKET';
    const response = await getEnquiries(enquiryType);
    if (response && response.type !== 'GET_ENQUIRIES_SUCCESS')
        return;
    if (response && response.payload && response.payload.length) {
        for (let index = 0; index < response.payload.length; index++) {
            if (response.payload[index].id && response.payload[index].partnerId) {
                const body = {};
                body.enquiryId = response.payload[index].id;
                body.partnerId = response.payload[index].partnerId;
                enquiryId = response.payload[index].id;
                getSwitchingResults(response.payload[index].id);
                break;
            }
        }
    }
    if (enquiryId) {
        if (route === 'Energy') {
            onCompareCallBack({
                name: 'EnergyQuotes',
                params: {
                    enquiryId,
                },
            });
        }
        else if (route === 'Broadband') {
            onCompareCallBack({
                name: 'BroadBandQuotes',
                params: {
                    enquiryId,
                },
            });
        }
    }
    else {
        const secondBody = {};
        secondBody.partner =
            route === 'Broadband' ? 'BROADBANDCHOICES' : 'MONEYSUPERMARKET';
        secondBody.type = route === 'Broadband' ? 'BROADBAND' : 'ENERGY';
        const thirdResponse = await getSwitchingQuotes(secondBody);
        if (thirdResponse && thirdResponse.type !== 'GET_SWITCHING_QUOTES_SUCCESS')
            return;
        const thirdBody = {};
        if (thirdResponse &&
            thirdResponse.payload &&
            thirdResponse.payload.enquiryId) {
            enquiryId = thirdResponse.payload.enquiryId;
            if (route === 'Broadband') {
                onCompareCallBack({
                    name: 'PostCode',
                    params: {
                        enquiryId,
                        data: thirdResponse.payload,
                    },
                });
                return;
            }
            thirdBody.action = 'ADVANCED_ENQUIRY';
            const fourthResponse = await getEnergySwitchingResults(enquiryId, thirdBody);
            if (fourthResponse &&
                fourthResponse.type !== 'GET_ENERGY_SWITCHING_RESULTS_SUCCESS')
                return;
            onCompareCallBack({
                name: 'AdvancedEnquiry',
                params: {
                    enquiryId,
                    data: fourthResponse.payload,
                },
            });
        }
    }
};
export default {
    getQuotes,
    getCompare,
    getLoans,
};
