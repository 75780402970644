import React, { memo } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import Indicator from 'design-system/Indicator';
import Links from 'utils/links';
const AboutInfoRow = ({ info, title, requesting, isLink = false, noPaddingHorizontal = false, }) => {
    const onLinkPress = () => {
        if (info) {
            Links.openLink(String(info));
        }
    };
    const renderInfo = () => {
        if (isLink && info) {
            return (<TouchableOpacity onPress={onLinkPress}>
          <Text Text-16 Primary numberOfLines={1}>
            {info}
          </Text>
        </TouchableOpacity>);
        }
        return (<Text Text-16 numberOfLines={1}>
        {info || '-'}
      </Text>);
    };
    return (<View style={[styles.container, noPaddingHorizontal && styles.noPaddingHorizontal]}>
      <Text TextThin-16 Gray>
        {title}
      </Text>
      <Spacer w={16}/>
      <View style={styles.infoContainer}>{requesting ? <Indicator dark/> : renderInfo()}</View>
    </View>);
};
export default memo(AboutInfoRow);
const styles = StyleSheet.create({
    container: {
        padding: rem(16),
        alignItems: 'center',
        flexDirection: 'row',
    },
    infoContainer: {
        flex: 1,
        alignItems: 'flex-end',
    },
    noPaddingHorizontal: {
        paddingHorizontal: 0,
        paddingVertical: rem(16),
    },
});
