import { CALL_API } from '../../../middleware/api';
import * as types from '../../../actions/types';
export const getNotifications = (page) => {
    const paging = page ? `&page=${page}` : '';
    return {
        [CALL_API]: {
            endpoint: `/notifications?&perPage=40${paging}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            types: [
                types.GET_NOTIFICATIONS_REQUEST,
                types.GET_NOTIFICATIONS_SUCCESS,
                types.GET_NOTIFICATIONS_FAILURE,
            ],
        },
    };
};
export const resetExternalUnreadNotifications = () => ({
    [CALL_API]: {
        endpoint: '/notifications/reset-external-unread-count',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.RESET_UNREAD_REQUEST,
            types.RESET_UNREAD_SUCCESS,
            types.RESET_UNREAD_FAILURE,
        ],
        muteAlert: true,
    },
});
