import { subWeeks, subMonths, subQuarters, subYears, endOfWeek, startOfMonth, endOfMonth, startOfQuarter, endOfQuarter, startOfYear, endOfYear, differenceInWeeks, differenceInMonths, differenceInQuarters, differenceInYears, } from 'date-fns';
export const getDateRange = (step, { from, to }, excludeCurrent) => {
    switch (step) {
        case 'custom':
            return { from, to, difference: 0 };
        case 'isoWeek': {
            const fromDate = subWeeks(from, 12);
            const difference = differenceInWeeks(to, from);
            const toDate = excludeCurrent ? subWeeks(to, 1) : endOfWeek(to);
            return { from: fromDate, to: toDate, difference };
        }
        case 'month': {
            const fromDate = startOfMonth(subMonths(from, 12));
            const difference = differenceInMonths(to, from);
            const toDate = excludeCurrent
                ? startOfMonth(subMonths(to, 1))
                : endOfMonth(to);
            return { from: fromDate, to: toDate, difference };
        }
        case 'quarter': {
            const fromDate = startOfQuarter(subQuarters(from, 4));
            const difference = differenceInQuarters(to, from);
            const toDate = excludeCurrent
                ? endOfQuarter(subQuarters(to, 1))
                : endOfQuarter(to);
            return { from: fromDate, to: toDate, difference };
        }
        case 'year': {
            const fromDate = startOfYear(subYears(from, 4));
            const difference = differenceInYears(to, from);
            const toDate = excludeCurrent ? subYears(to, 1) : endOfYear(to);
            return { from: fromDate, to: toDate, difference };
        }
        default:
            throw new Error('Unsupported date in getDateRange');
    }
};
