import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { rem } from 'design-system/values';
import Separator from 'design-system/Separator';
import useColors from 'hooks/useColors';
import { interpolate } from 'utils/interpolations';
import IncomeSpendingBarChart from './IncomeSpendingBarChart';
import AnalyticsGraphLabel from '../AnalyticsGraphLabel';
const strings = {
    toolTipTitle: 'How is it estimated?',
    incomeToolTipSubTitle: 'This is the total income you have received within the period you have selected. You can see all the transactions by scrolling at the bottom of the list and tapping on the income category.',
    spendingToolTipTitle: 'This is your total spending within the period you have selected. This is calculated by summing all the spending per category from the list below.',
};
const BarChart = ({ income, spending, currency, blurValue, }) => {
    const colors = useColors();
    const { incomePercent, spendingPercent } = useMemo(() => {
        let incomePercent = income ? 100 : 0;
        let spendingPercent = spending ? 100 : 0;
        if (income > spending) {
            spendingPercent = interpolate(spending, {
                inputRange: [0, income],
                outputRange: [0, 100],
            });
        }
        else {
            incomePercent = interpolate(income, {
                inputRange: [0, spending],
                outputRange: [0, 100],
            });
        }
        return { incomePercent, spendingPercent };
    }, [income, spending]);
    return (<>
      <IncomeSpendingBarChart large incomePercent={incomePercent} spendingPercent={spendingPercent}/>
      <Separator thick/>
      <View style={styles.labelContainer}>
        <AnalyticsGraphLabel title="Income" blurValue={blurValue} value={{ amount: income, currency }} color={colors.gradients.turquoise.colors[0]} toolTipTitle={strings.toolTipTitle} toolTipSubTitle={strings.incomeToolTipSubTitle}/>
        <AnalyticsGraphLabel title="Spending" blurValue={blurValue} value={{ amount: spending, currency }} color={colors.gradients.deepPink.colors[0]} toolTipTitle={strings.toolTipTitle} toolTipSubTitle={strings.spendingToolTipTitle}/>
      </View>
    </>);
};
const styles = StyleSheet.create({
    labelContainer: {
        padding: rem(16),
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
});
export default BarChart;
