import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import AccountBalance from 'features/accounts/components/overview/AccountBalance';
import OverviewText from './OverviewText';
import { isWeb } from '../../../../constants';
const OverviewRow = ({ item, onPress, arrowDirection = 'down', button, }) => {
    const onPressRow = () => {
        onPress(item.title);
    };
    const { title, color, amount, currency } = item;
    const colors = useColors();
    return (<TouchableHighlight onCard hoverOnLight underlayColor={colors.background.underlay} activeOpacity={1} onPress={onPressRow} style={styles.btn}>
      <View style={styles.row}>
        <OverviewText title={title} arrowDirection={arrowDirection}/>
        {button !== null && button !== undefined ? (button) : (<AccountBalance amount={Number(amount)} currency={currency} color={color}/>)}
      </View>
    </TouchableHighlight>);
};
const styles = StyleSheet.create({
    btn: {
        padding: rem(16),
        ...(isWeb && {
            paddingVertical: rem(16),
            marginHorizontal: rem(8),
            paddingHorizontal: rem(8),
        }),
    },
    row: {
        flex: 1,
        flexDirection: 'row',
    },
});
export default memo(OverviewRow);
