import React from 'react';
import { View } from 'react-native';
import TouchableBounce from 'utils/packages/TouchableBounce';
import useStyles from 'hooks/useStyles';
import IconCamera from '../../icons/IconCamera';
import { styleSet } from './TransactionReceiptBox';
const TransactionReceiptBoxAdd = ({ onPressAdd }) => {
    const styles = useStyles(styleSet);
    return (<View style={styles.container}>
      <TouchableBounce onPress={onPressAdd} style={styles.addBox} hoverStyle={styles.hoverStyle}>
        <IconCamera />
      </TouchableBounce>
    </View>);
};
export default TransactionReceiptBoxAdd;
