import { uniqBy } from 'lodash';
const getMonthlyTotals = (data) => {
    const arrayOfKeys = Object.keys(data);
    let array = [];
    for (let index = 0; index < arrayOfKeys.length; index++) {
        array = array.concat(data[arrayOfKeys[index]]);
    }
    if (array && array[0]) {
        if (array[0].isPayday || array[0].totalBudget)
            array[0].hasCircle = true;
    }
    return uniqBy(array, (data) => `${data.from}|${data.to}`);
};
export default getMonthlyTotals;
