export const SET_CURRENT_ROUTE_NAME = 'SET_CURRENT_ROUTE_NAME';
export const SET_UNHANDLED_NAVIGATE_ACTION = 'SET_UNHANDLED_NAVIGATE_ACTION';
export const SET_SCRAMBLED = 'SET_SCRAMBLED';
export const SET_PUSH_REQUEST = 'SET_PUSH_REQUEST';
export const SET_PUSH_SUCCESS = 'SET_PUSH_SUCCESS';
export const SET_PUSH_FAILURE = 'SET_PUSH_FAILURE';
export const SET_FCM_PUSH_REQUEST = 'SET_FCM_PUSH_REQUEST';
export const SET_FCM_PUSH_SUCCESS = 'SET_FCM_PUSH_SUCCESS';
export const SET_FCM_PUSH_FAILURE = 'SET_FCM_PUSH_FAILURE';
export const UNSUBSCRIBE_PUSH_REQUEST = 'UNSUBSCRIBE_PUSH_REQUEST';
export const UNSUBSCRIBE_PUSH_SUCCESS = 'UNSUBSCRIBE_PUSH_SUCCESS';
export const UNSUBSCRIBE_PUSH_FAILURE = 'UNSUBSCRIBE_PUSH_FAILURE';
export const GET_FEATURE_FLAGS_REQUEST = 'GET_FEATURE_FLAGS_REQUEST';
export const GET_FEATURE_FLAGS_SUCCESS = 'GET_FEATURE_FLAGS_SUCCESS';
export const GET_FEATURE_FLAGS_FAILURE = 'GET_FEATURE_FLAGS_FAILURE';
export const SET_APPSFLYER_REQUEST = 'SET_APPSFLYER_REQUEST';
export const SET_APPSFLYER_SUCCESS = 'SET_APPSFLYER_SUCCESS';
export const SET_APPSFLYER_FAILURE = 'SET_APPSFLYER_FAILURE';
export const GET_SERVER_HEALTH_REQUEST = 'GET_SERVER_HEALTH_REQUEST';
export const GET_SERVER_HEALTH_SUCCESS = 'GET_SERVER_HEALTH_SUCCESS';
export const GET_SERVER_HEALTH_FAILURE = 'GET_SERVER_HEALTH_FAILURE';
export const GET_MODAL_NOTIFICATIONS_REQUEST = 'GET_MODAL_NOTIFICATIONS_REQUEST';
export const GET_MODAL_NOTIFICATIONS_SUCCESS = 'GET_MODAL_NOTIFICATIONS_SUCCESS';
export const GET_MODAL_NOTIFICATIONS_FAILURE = 'GET_MODAL_NOTIFICATIONS_FAILURE';
export const SEEN_MODAL_NOTIFICATIONS_REQUEST = 'SEEN_MODAL_NOTIFICATIONS_REQUEST';
export const SEEN_MODAL_NOTIFICATIONS_SUCCESS = 'SEEN_MODAL_NOTIFICATIONS_SUCCESS';
export const SEEN_MODAL_NOTIFICATIONS_FAILURE = 'SEEN_MODAL_NOTIFICATIONS_FAILURE';
export const CLICKED_MODAL_NOTIFICATIONS_REQUEST = 'CLICKED_MODAL_NOTIFICATIONS_REQUEST';
export const CLICKED_MODAL_NOTIFICATIONS_SUCCESS = 'CLICKED_MODAL_NOTIFICATIONS_SUCCESS';
export const CLICKED_MODAL_NOTIFICATIONS_FAILURE = 'CLICKED_MODAL_NOTIFICATIONS_FAILURE';
export const DISMISS_MODAL_NOTIFICATIONS_REQUEST = 'DISMISS_MODAL_NOTIFICATIONS_REQUEST';
export const DISMISS_MODAL_NOTIFICATIONS_SUCCESS = 'DISMISS_MODAL_NOTIFICATIONS_SUCCESS';
export const DISMISS_MODAL_NOTIFICATIONS_FAILURE = 'DISMISS_MODAL_NOTIFICATIONS_FAILURE';
export const SET_COLOR_SCHEME = 'SET_COLOR_SCHEME';
export const CONNECTION_CHANGE_HANDLED = 'CONNECTION_CHANGE_HANDLED';
