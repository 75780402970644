import React, { memo, useMemo } from 'react';
import { Text, StyleSheet } from 'react-native';
const EmojiText = ({ children, fontSize }) => {
    const textStyles = useMemo(() => (fontSize ? [styles.emoji, { fontSize }] : styles.emoji), [fontSize]);
    return <Text style={textStyles}>{children}</Text>;
};
export default memo(EmojiText);
const styles = StyleSheet.create({
    emoji: {
        color: 'black',
        textAlign: 'center',
        fontFamily: 'System',
    },
});
