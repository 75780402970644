export const calculateTotalBalance = (data) => {
    let amount = 0;
    let currency = '';
    data.forEach((account) => {
        const balance = (() => {
            /**
             * Case for BankAccount
             */
            if ('currency' in account) {
                return getBalanceInMainCurrency(account);
            }
            /**
             * Case for Pot and emma InvestmentAccount
             */
            return {
                amount: account.balance.value,
                currency: account.balance.currency,
            };
        })();
        amount += balance.amount;
        currency = balance.currency;
    });
    return { amount, currency };
};
export const getBalanceInMainCurrency = (data) => {
    let balance = data.availableBalance ?? data.postedBalance;
    if (!data.isClosed) {
        if (data.type === 'CREDITCARD') {
            balance = data.creditCardBalance ?? 0;
        }
        if (data.type === 'LOAN') {
            balance = data.postedBalance;
        }
    }
    return { amount: balance, currency: data.currency };
};
/* Use it for offline accounts */
export const getBalanceInNativeCurrency = (data, userCurrency, priorityBalance, currency) => ({
    amount: priorityBalance ??
        data?.nativeBalance?.postedBalance ??
        data?.postedBalance ??
        0,
    currency: priorityBalance === undefined
        ? data?.nativeBalance?.currency ?? data?.currency ?? userCurrency ?? 'GBP'
        : currency ?? data?.currency ?? userCurrency ?? 'GBP',
});
