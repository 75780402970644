export const EDIT_ACCOUNT_REQUEST = 'EDIT_ACCOUNT_REQUEST';
export const EDIT_ACCOUNT_FAILURE = 'EDIT_ACCOUNT_FAILURE';
export const EDIT_ACCOUNT_SUCCESS = 'EDIT_ACCOUNT_SUCCESS';
export const SET_ACCOUNT_NAME_REQUEST = 'SET_ACCOUNT_NAME_REQUEST';
export const SET_ACCOUNT_NAME_FAILURE = 'SET_ACCOUNT_NAME_FAILURE';
export const SET_ACCOUNT_NAME_SUCCESS = 'SET_ACCOUNT_NAME_SUCCESS';
export const SET_ACCOUNT_TYPE_REQUEST = 'SET_ACCOUNT_TYPE_REQUEST';
export const SET_ACCOUNT_TYPE_FAILURE = 'SET_ACCOUNT_TYPE_FAILURE';
export const SET_ACCOUNT_TYPE_SUCCESS = 'SET_ACCOUNT_TYPE_SUCCESS';
export const SET_OVERDRAFT_REQUEST = 'SET_OVERDRAFT_REQUEST';
export const SET_OVERDRAFT_SUCCESS = 'SET_OVERDRAFT_SUCCESS';
export const SET_OVERDRAFT_FAILURE = 'SET_OVERDRAFT_FAILURE';
export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT_REQUEST';
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const GET_BALANCE_REQUEST = 'GET_BALANCE_REQUEST';
export const GET_BALANCE_FAILURE = 'GET_BALANCE_FAILURE';
export const GET_BALANCE_SUCCESS = 'GET_BALANCE_SUCCESS';
export const SET_BALANCE_STORAGE_RESET = 'SET_BALANCE_STORAGE_RESET';
export const GET_INVESTMENT_REQUEST = 'GET_INVESTMENT_REQUEST';
export const GET_INVESTMENT_SUCCESS = 'GET_INVESTMENT_SUCCESS';
export const GET_INVESTMENT_FAILURE = 'GET_INVESTMENT_FAILURE';
export const GET_FILTERS_REQUEST = 'GET_FILTERS_REQUEST';
export const GET_FILTERS_SUCCESS = 'GET_FILTERS_SUCCESS';
export const GET_FILTERS_FAILURE = 'GET_FILTERS_FAILURE';
export const EDIT_FILTERS_REQUEST = 'EDIT_FILTERS_REQUEST';
export const EDIT_FILTERS_SUCCESS = 'EDIT_FILTERS_SUCCESS';
export const EDIT_FILTERS_FAILURE = 'EDIT_FILTERS_FAILURE';
export const SET_FILTERS_REQUEST = 'SET_FILTERS_REQUEST';
export const SET_FILTERS_SUCCESS = 'SET_FILTERS_SUCCESS';
export const SET_FILTERS_FAILURE = 'SET_FILTERS_FAILURE';
export const SET_FILTER_PREVIEW_REQUEST = 'SET_FILTER_PREVIEW_REQUEST';
export const SET_FILTER_PREVIEW_SUCCESS = 'SET_FILTER_PREVIEW_SUCCESS';
export const SET_FILTER_PREVIEW_FAILURE = 'SET_FILTER_PREVIEW_FAILURE';
export const GET_AUTOMATION_RULES_REQUEST = 'GET_AUTOMATION_RULES_REQUEST';
export const GET_AUTOMATION_RULES_SUCCESS = 'GET_AUTOMATION_RULES_SUCCESS';
export const GET_AUTOMATION_RULES_FAILURE = 'GET_AUTOMATION_RULES_FAILURE';
export const DELETE_AUTOMATION_RULES_REQUEST = 'DELETE_AUTOMATION_RULES_REQUEST';
export const DELETE_AUTOMATION_RULES_SUCCESS = 'DELETE_AUTOMATION_RULES_SUCCESS';
export const DELETE_AUTOMATION_RULES_FAILURE = 'DELETE_AUTOMATION_RULES_FAILURE';
export const SET_AUTOMATION_RULES_REQUEST = 'SET_AUTOMATION_RULES_REQUEST';
export const SET_AUTOMATION_RULES_SUCCESS = 'SET_AUTOMATION_RULES_SUCCESS';
export const SET_AUTOMATION_RULES_FAILURE = 'SET_AUTOMATION_RULES_FAILURE';
export const SYNC_REQUEST = 'SYNC_ALL_REQUEST';
export const SYNC_FAILURE = 'SYNC_ALL_FAILURE';
export const SYNC_SUCCESS = 'SYNC_ALL_SUCCESS';
export const SYNC_ALL_REQUEST = 'SYNC_ALL_REQUEST';
export const SYNC_ALL_FAILURE = 'SYNC_ALL_FAILURE';
export const SYNC_ALL_SUCCESS = 'SYNC_ALL_SUCCESS';
export const GET_ACCOUNT_GOALS_REQUEST = 'GET_ACCOUNT_GOALS_REQUEST';
export const GET_ACCOUNT_GOALS_SUCCESS = 'GET_ACCOUNT_GOALS_SUCCESS';
export const GET_ACCOUNT_GOALS_FAILURE = 'GET_ACCOUNT_GOALS_FAILURE';
export const ADD_GOAL_REQUEST = 'ADD_GOAL_REQUEST';
export const ADD_GOAL_SUCCESS = 'ADD_GOAL_SUCCESS';
export const ADD_GOAL_FAILURE = 'ADD_GOAL_FAILURE';
export const EDIT_GOAL_REQUEST = 'EDIT_GOAL_REQUEST';
export const EDIT_GOAL_SUCCESS = 'EDIT_GOAL_SUCCESS';
export const EDIT_GOAL_FAILURE = 'EDIT_GOAL_FAILURE';
export const DELETE_GOAL_REQUEST = 'DELETE_GOAL_REQUEST';
export const DELETE_GOAL_SUCCESS = 'DELETE_GOAL_SUCCESS';
export const DELETE_GOAL_FAILURE = 'DELETE_GOAL_FAILURE';
export const RECONSENT_CONNECTIONS_REQUEST = 'RECONSENT_CONNECTIONS_REQUEST';
export const RECONSENT_CONNECTIONS_SUCCESS = 'RECONSENT_CONNECTIONS_SUCCESS';
export const RECONSENT_CONNECTIONS_FAILURE = 'RECONSENT_CONNECTIONS_FAILURE';
export const CHOOSE_RETAINED_CONNECTIONS_REQUEST = 'CHOOSE_RETAINED_CONNECTIONS_REQUEST_CONNECTIONS_REQUEST';
export const CHOOSE_RETAINED_CONNECTIONS_SUCCESS = 'CHOOSE_RETAINED_CONNECTIONS_SUCCESS';
export const CHOOSE_RETAINED_CONNECTIONS_FAILURE = 'CHOOSE_RETAINED_CONNECTIONS_REQUEST_CONNECTIONS_FAILURE';
export const RESET_INVESTMENT_BALANCES = 'RESET_INVESTMENT_BALANCES';
