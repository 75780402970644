export default function calculatePath(to, from, progress, layoutSize) {
    'worklet';
    let res;
    let toValue = to;
    let fromValue = from;
    if (progress !== 1) {
        const numOfPoints = Math.round(fromValue.length + (toValue.length - fromValue.length) * Math.min(progress, 0.5) * 2);
        if (fromValue.length !== numOfPoints) {
            const mappedFrom = [];
            const coef = (fromValue.length - 1) / (numOfPoints - 1);
            for (let i = 0; i < numOfPoints; i++) {
                const index = Math.round(i * coef);
                const value = fromValue[index];
                if (value) {
                    mappedFrom.push(value);
                }
            }
            fromValue = mappedFrom;
        }
        if (toValue.length !== numOfPoints) {
            const mappedTo = [];
            const coef = (toValue.length - 1) / (numOfPoints - 1);
            for (let i = 0; i < numOfPoints; i++) {
                const index = Math.round(i * coef);
                const value = toValue[index];
                if (value) {
                    mappedTo.push(value);
                }
            }
            toValue = mappedTo;
        }
        if (fromValue.length === toValue.length) {
            res = fromValue.map(({ x, y }, i) => {
                const { x: nX, y: nY } = toValue[i];
                const mX = (x + (nX - x) * progress) * layoutSize.width;
                const mY = (y + (nY - y) * progress) * layoutSize.height;
                return { x: mX, y: mY };
            });
        }
        else {
            res = toValue.map(({ x, y }) => ({
                x: x * layoutSize.width,
                y: y * layoutSize.height,
            }));
        }
    }
    else {
        res = toValue.map(({ x, y }) => ({
            x: x * layoutSize.width,
            y: y * layoutSize.height,
        }));
    }
    const path = res
        .map(({ x, y }) => {
        if (!Number.isNaN(Number(x)) && !Number.isNaN(Number(y))) {
            return `L ${x} ${y}`;
        }
        return ``;
    })
        .join(' ')
        .replace('L', 'M');
    return path;
}
