import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, View, } from 'react-native';
import Animated, { cancelAnimation, useAnimatedStyle, useSharedValue, withTiming, } from 'react-native-reanimated';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableBounce from 'utils/packages/TouchableBounce';
import Text from './Text';
import { rem } from './values';
import ModalConfirmationContent from './ModalConfirmationContent';
import IconClose from './icons/IconClose';
const strings = { error: 'Error', tryAgain: 'Try Again', ok: 'OK' };
const Modal = ({ top }) => {
    const colors = useColors();
    const opacitySharedValue = useSharedValue(0);
    const [state, setState] = useState({
        visible: false,
        startHiding: false,
        content: null,
        title: '',
    });
    const styles = useStyles(styleSet);
    const close = useCallback(() => {
        setState((state) => ({
            ...state,
            startHiding: true,
        }));
    }, []);
    const cleanup = useCallback(() => {
        if (Modal.onDidHideCallbacks) {
            const callback = Modal.onDidHideCallbacks[top ? 'top' : 'bottom'];
            if (typeof callback === 'function') {
                callback();
                Modal.onDidHideCallbacks[top ? 'top' : 'bottom'] = undefined;
            }
        }
    }, [top]);
    useEffect(() => {
        if (state.visible) {
            opacitySharedValue.value = withTiming(1, {
                duration: 100,
            });
        }
    }, [opacitySharedValue, state.visible]);
    useEffect(() => {
        if (state.startHiding) {
            cancelAnimation(opacitySharedValue);
            opacitySharedValue.value = withTiming(0, {
                duration: 100,
            }, () => {
                setState((oldState) => ({
                    ...oldState,
                    title: '',
                    content: null,
                    visible: false,
                    startHiding: false,
                }));
                cleanup();
            });
        }
    }, [cleanup, opacitySharedValue, state.startHiding]);
    useEffect(() => {
        if (Modal.setStateRefs === undefined)
            Modal.setStateRefs = {};
        Modal.setStateRefs[top ? 'top' : 'bottom'] = setState;
    }, [top]);
    const containerAnimatedStyles = useAnimatedStyle(() => ({
        opacity: opacitySharedValue.value,
    }), [opacitySharedValue.value]);
    const onPress = useCallback(() => {
        if (state.options?.onPress) {
            if (Modal.onDidHideCallbacks === undefined) {
                Modal.onDidHideCallbacks = {};
            }
            Modal.onDidHideCallbacks[top ? 'top' : 'bottom'] = state.options.onPress;
        }
        close();
    }, [state.options?.onPress, close, top]);
    const onPressCancel = useCallback(() => {
        if (state.options?.onPressCancel) {
            if (Modal.onDidHideCallbacks === undefined) {
                Modal.onDidHideCallbacks = {};
            }
            Modal.onDidHideCallbacks[top ? 'top' : 'bottom'] =
                state.options.onPressCancel;
        }
        close();
    }, [state.options?.onPressCancel, close, top]);
    if (!state.visible) {
        return null;
    }
    const { title } = state;
    let titleNode;
    if (typeof title === 'string') {
        if (state.title === '') {
            titleNode = null;
        }
        else {
            titleNode = (<Text Text-20 style={state.options?.textStyle}>
          {state.title}
        </Text>);
        }
    }
    else {
        titleNode = title;
    }
    if (state.options && 'showConfirmation' in state.options) {
        return (<Animated.View style={[
                styles.container,
                state.options?.containerStyle,
                containerAnimatedStyles,
            ]}>
        <ModalConfirmationContent options={{
                ...state.options,
                hideHandle: true,
            }} containerStyle={styles.contentContainerConfirmation} onPress={onPress} onPressCancel={onPressCancel} title={state.title} subtitle={state.subtitle}/>
      </Animated.View>);
    }
    if (state.options && 'showError' in state.options) {
        return (<Animated.View style={[
                styles.container,
                state.options?.containerStyle,
                containerAnimatedStyles,
            ]}>
        <ModalConfirmationContent options={{
                ...state.options,
                hideHandle: true,
                confirmButtonTitle: state.options.onPress
                    ? state.options.confirmButtonTitle || strings.tryAgain
                    : strings.ok,
            }} containerStyle={styles.contentContainerConfirmation} onPress={onPress} title={state.title} subtitle={state.subtitle}/>
      </Animated.View>);
    }
    return (<Animated.View style={[styles.container, containerAnimatedStyles]}>
      <View style={[
            styles.mainContainer,
            {
                backgroundColor: state.options?.lightBg
                    ? colors.cards.onLight
                    : colors.cards.onDark,
            },
        ]}>
        <View style={[
            styles.headerRow,
            state.options?.titleBottomBorder && styles.titleBottomBorder,
        ]}>
          <View style={styles.titleContainer}>{titleNode}</View>
          <TouchableBounce onPress={close} style={styles.closeBtn} hoverStyle={styles.closeHoverStyles}>
            <IconClose color={colors.elements.primary}/>
          </TouchableBounce>
        </View>
        <View style={[styles.contentContainer, state.options?.containerStyle]}>
          {state.content}
        </View>
      </View>
    </Animated.View>);
};
Modal.minHideTime = { top: 0, bottom: 0 };
Modal.show = (content, options) => {
    if (Modal.setStateRefs) {
        if (options?.onDidHideCallback) {
            if (Modal.onDidHideCallbacks === undefined) {
                Modal.onDidHideCallbacks = {};
            }
            Modal.onDidHideCallbacks[options?.top ? 'top' : 'bottom'] =
                options.onDidHideCallback;
        }
        const setState = Modal.setStateRefs[options?.top ? 'top' : 'bottom'];
        if (setState) {
            setState({
                visible: true,
                startHiding: false,
                title: options?.title ?? '',
                content,
                options,
            });
            if (options?.minShowTime) {
                Modal.minHideTime[options?.top ? 'top' : 'bottom'] =
                    new Date().getTime() + options.minShowTime;
            }
            else {
                Modal.minHideTime[options?.top ? 'top' : 'bottom'] = 0;
            }
        }
    }
};
Modal.showError = (message, onTryAgain, title, buttonText, onDidHideCallback) => {
    if (Modal.setStateRefs) {
        if (onDidHideCallback) {
            if (Modal.onDidHideCallbacks === undefined) {
                Modal.onDidHideCallbacks = {};
            }
            Modal.onDidHideCallbacks.top = onDidHideCallback;
        }
        const setState = Modal.setStateRefs.top;
        if (setState) {
            setState({
                visible: true,
                startHiding: false,
                title: title || strings.error,
                subtitle: message,
                content: null,
                options: {
                    showError: true,
                    onPress: onTryAgain,
                    confirmButtonTitle: buttonText || strings.tryAgain,
                },
            });
        }
    }
};
Modal.showConfirmation = (title, subtitle, confirmButtonTitle, cancelButtonTitle, onConfirm, onPressCancel, onBackdropPress) => {
    if (Modal.setStateRefs) {
        const setState = Modal.setStateRefs.top;
        if (setState) {
            setState({
                visible: true,
                title,
                subtitle,
                content: null,
                startHiding: false,
                options: {
                    showConfirmation: true,
                    onPress: onConfirm,
                    onPressCancel,
                    confirmButtonTitle,
                    cancelButtonTitle,
                    onBackdropPress,
                },
            });
        }
    }
};
Modal.hide = (onDidHideCallback, options) => {
    if (Modal.setStateRefs) {
        if (onDidHideCallback) {
            if (Modal.onDidHideCallbacks === undefined) {
                Modal.onDidHideCallbacks = {};
            }
            Modal.onDidHideCallbacks[options?.top ? 'top' : 'bottom'] =
                onDidHideCallback;
        }
        const setState = Modal.setStateRefs[options?.top ? 'top' : 'bottom'];
        if (setState) {
            const minHideTime = Modal.minHideTime[options?.top ? 'top' : 'bottom'];
            const now = new Date().getTime();
            if (now < minHideTime) {
                setTimeout(() => {
                    setState((state) => ({ ...state, startHiding: true }));
                }, minHideTime - now);
            }
            else {
                setState((state) => ({ ...state, startHiding: true }));
            }
        }
    }
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        ...StyleSheet.absoluteFillObject,
        zIndex: 100,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.6)',
    },
    mainContainer: {
        width: rem(600),
        borderRadius: rem(24),
    },
    contentContainerConfirmation: {
        width: rem(450),
        padding: rem(16),
        paddingTop: rem(32),
        borderRadius: rem(24),
        alignItems: 'center',
    },
    contentContainer: {
        paddingBottom: rem(16),
        paddingHorizontal: rem(16),
    },
    headerRow: {
        paddingTop: rem(16),
        flexDirection: 'row',
        paddingHorizontal: rem(16),
    },
    titleContainer: {
        flex: 1,
        justifyContent: 'center',
    },
    closeBtn: {
        width: rem(40),
        height: rem(40),
        alignItems: 'center',
        borderRadius: rem(8),
        justifyContent: 'center',
    },
    closeHoverStyles: {
        backgroundColor: colors.control.hoverOnLight,
    },
    titleBottomBorder: {
        paddingBottom: 8,
        borderBottomWidth: 1,
        borderBottomColor: colors.borders.divider,
    },
}));
export default Modal;
