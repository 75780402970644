import React, { memo } from 'react';
import { View } from 'react-native';
import { CardView } from 'design-system/CardView';
import SplitItem from './SplitItem';
const SplitList = ({ state, setState, onPressShowMenu, style, splitButtons, }) => {
    const onChangeText = (value, valueIndex) => {
        const isZero = (x) => Number.isNaN(x) || x < 0 || x === 0;
        const { data } = state;
        const newValue = Math.round(value * 100);
        const firstValue = Math.round(data[0].amount * 100);
        const valueChanged = Math.round(data[valueIndex].amount * 100);
        const difference = valueChanged - newValue;
        const firstWithDifference = (firstValue + difference) / 100;
        if (isZero(firstWithDifference) ||
            firstWithDifference > Math.abs(state.originalAmount))
            return;
        setState({
            ...state,
            data: data.map((item, index) => {
                if (index === 0) {
                    return {
                        ...item,
                        amount: firstWithDifference,
                    };
                }
                if (index === valueIndex) {
                    return { ...item, amount: value };
                }
                return item;
            }),
        });
    };
    const renderItem = (item, index) => (<SplitItem key={item.id.toString()} currency={item.currency} amount={item.amount} onChangeText={onChangeText} id={index} onPressShowMenu={onPressShowMenu} category={item.category} animate={index > 1}/>);
    const { data } = state;
    return (<View style={style}>
      <CardView noMarginTop>
        {data.map((item, index) => renderItem(item, index))}
        {splitButtons}
      </CardView>
    </View>);
};
export default memo(SplitList);
