import { createSelector } from 'reselect';
import { Platform } from 'react-native';
import { FORCE_SHOW_TRIAL } from 'config/debug';
import { selectPotProductWithTheHighestInterest } from 'features/pots/reducers/selectors';
import { getRequiredTier } from 'features/settings/logins/utils';
import { flatten } from 'lodash';
import { selectFeatureFlags, selectUser, selectActiveConnectionsWithoutManual, selectIsGBUser, } from 'reducers/selectors';
import checkFeatureFlag from 'utils/featureFlags';
import { benefits, benefitsData, benefitsDataHideDigital, benefitsTopFeatures, benefitsTopFeaturesGB, filterBenefitsArray, getCustomNameAndDescription, mapBenefitsToBenefitsDataArray, } from '../benefits';
import { isRunningTestflight } from '../../../constants';
import { checkIsBenefitIdAvailableOnFree } from '../checkIsBenefitIdAvailableOnFree';
import { Tier, TierPeriod, itemSkus, tierLevelMap, emmaProStatusToSKU, } from '../entities';
import { getIapSubscriptionPrices, isUnlocked } from '../iap';
import { canUseFreeTrial } from '../trial/utils';
export const selectFilteredBenefits = createSelector((store) => store.user.user.guessedHomeCountry, (store) => store.utils.featureFlags, (store) => store.pots.products, (guessedHomeCountry, featureFlags, potProducts) => {
    const hasInterestPotProduct = !!potProducts?.find((product) => product.type === 'INTEREST' || product.type === 'INTEREST_NOTICE');
    return filterBenefitsArray(Object.values(benefits), {
        homeCountry: guessedHomeCountry,
    }, featureFlags, hasInterestPotProduct);
});
export const selectBenefitsData = (store) => store.user.user.guessedHomeCountry === 'GB'
    ? benefitsDataHideDigital
    : benefitsData;
export const selectFilteredBenefitsData = createSelector([selectFilteredBenefits, selectBenefitsData], (benefitsArray, benefitsData) => {
    const benefits = benefitsArray.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {});
    return mapBenefitsToBenefitsDataArray(benefits, benefitsData);
});
export const selectActiveTier = createSelector([(store) => store.user.user], (user) => (user.emmaProStatus.active ? user.emmaProStatus.tier : undefined));
export const selectIsOnReverseTrial = (store) => store.user.user.emmaProStatus.reverseTrial?.active &&
    !store.user.user.emmaProStatus.paying;
export const selectAllIAPSubscriptionsWithPrices = createSelector([(store) => store.iapSubscriptions.subscriptions], (iapSubscriptions) => Object.values(iapSubscriptions || {})
    .map(getIapSubscriptionPrices)
    .filter((subscription) => subscription !== undefined));
export const selectAvailableIAPSubscriptionsWithPrices = createSelector([selectAllIAPSubscriptionsWithPrices], (iapSubscriptions) => iapSubscriptions.filter((subscription) => itemSkus.find((sku) => sku.id === subscription.id)));
const LIMIT_DEFAULT = {
    [Tier.free]: 2,
    [Tier.plus]: 4,
    [Tier.pro]: null,
    [Tier.ultimate]: null,
};
// Unused but keep in case we flag for tests
export const selectConnectionsLimit = createSelector([selectFeatureFlags], () => LIMIT_DEFAULT);
const DEFAULT_AGENCIES = {
    [Tier.free]: null,
    [Tier.plus]: ['EXPERIAN'],
    [Tier.pro]: ['EXPERIAN', 'EQUIFAX', 'TRANSUNION'],
    [Tier.ultimate]: ['EXPERIAN', 'EQUIFAX', 'TRANSUNION'],
};
export const selectRentReportingAgenciesTierMap = createSelector([(store) => store.utils.featureFlags], (featureFlags) => featureFlags.rent_reporting_two?.extra?.availableAgencies &&
    Object.keys(featureFlags.rent_reporting_two?.extra.availableAgencies)
        .length > 0
    ? featureFlags.rent_reporting_two?.extra.availableAgencies
    : DEFAULT_AGENCIES);
export const selectRentReportingAgenciesForOnboarding = createSelector([
    (store) => store.user.user.emmaProStatus,
    selectRentReportingAgenciesTierMap,
], (emmaProStatus, rentReportingAgenciesTierMap) => {
    if (emmaProStatus.active &&
        emmaProStatus.tier &&
        rentReportingAgenciesTierMap[emmaProStatus.tier]) {
        return rentReportingAgenciesTierMap[emmaProStatus.tier];
    }
    return rentReportingAgenciesTierMap[Tier.pro];
});
export const selectIsPlatformPaySupported = (store) => store.stripe.isPlatformPaySupported;
export const selectShouldShowStripe = createSelector([
    selectIsGBUser,
    selectIsPlatformPaySupported,
    (store) => store.user.user.emmaProStatus,
    (store) => store.utils.featureFlags,
], (isGBUser, isPlatformPaySupported, emmaProStatus, featureFlags) => {
    if (isGBUser && isRunningTestflight) {
        if (emmaProStatus.active && emmaProStatus.store !== 'stripe') {
            return false;
        }
        return true;
    }
    const canUseStripe = !!(isGBUser &&
        (isPlatformPaySupported ||
            checkFeatureFlag(featureFlags, 'subscription_via_stripe_card_fallback')) &&
        ((checkFeatureFlag(featureFlags, 'subscription_via_stripe_ios') &&
            Platform.OS === 'ios') ||
            (checkFeatureFlag(featureFlags, 'subscription_via_stripe_android') &&
                Platform.OS === 'android')));
    if (emmaProStatus.active) {
        if (emmaProStatus.store === 'stripe') {
            return true;
        }
        if (emmaProStatus.reverseTrial?.active && canUseStripe) {
            return true;
        }
        return false;
    }
    return canUseStripe;
});
export const selectStripePriceMap = (store) => store.stripe.priceMap;
export const FREE_TRIAL_TIER = Tier.pro;
export const FREE_TRIAL_DEFAULT_DAYS = 7;
export const selectDefaultFreeTrialDays = (store) => store.user.user.emmaProStatus.defaultFreeTrialDays || FREE_TRIAL_DEFAULT_DAYS;
export const selectEligibleFreeTrialDays = createSelector([
    selectShouldShowStripe,
    (store) => store.user.user.emmaProStatus.eligibleFreeTrialDays,
    selectDefaultFreeTrialDays,
], (showStripe, eligibleFreeTrialDays, defaultFreeTrialDays) => {
    if (showStripe) {
        return eligibleFreeTrialDays || defaultFreeTrialDays;
    }
    return defaultFreeTrialDays;
});
export const selectShouldSeePremiumTrialScreen = createSelector([
    (store) => store.user.user.emmaProStatus.active,
    selectAvailableIAPSubscriptionsWithPrices,
    (_, tier) => tier,
    (store) => store.iapSubscriptions.isEligibleForIntroOffer,
    selectShouldShowStripe,
    (store) => store.user.user.emmaProStatus.usedFreeTrial,
], (isPremiumActive, availableSubscriptions, tier, isEligibleForIntroOffer, shouldUseStripe, usedFreeTrial) => {
    if (__DEV__ && FORCE_SHOW_TRIAL) {
        return true;
    }
    /**
     * Check if the user hasnt already used the free trial from stores too while using stripe
     */
    if (shouldUseStripe) {
        return !isPremiumActive && !usedFreeTrial;
    }
    const hasFreeTrialForTier = availableSubscriptions
        .filter((subscription) => tier === subscription.tier)
        .every((subscription) => canUseFreeTrial(subscription, isEligibleForIntroOffer));
    return !isPremiumActive && hasFreeTrialForTier;
});
export const selectFilteredBenefitsWithCustom = createSelector([
    (store) => store.invest.tierFxRateMap,
    selectPotProductWithTheHighestInterest,
    (store) => store.paymentLimits.limits,
    selectConnectionsLimit,
    selectRentReportingAgenciesTierMap,
    selectFeatureFlags,
    selectFilteredBenefits,
    (store) => store.user.user.guessedHomeCountry,
    (store) => store.pots.savingsPlanTierFees,
    (_, tier) => tier,
], (tierFxRateMap, potProductWithTheHighestInterest, paymentLimitMap, connectionsLimitMap, rentReportingAgenciesTierMap, featureFlags, filteredBenefits, guessedHomeCountry, savingsPlanTierFees, tier) => {
    const currentTierLevel = tierLevelMap[tier];
    const interestPaymentFrequency = potProductWithTheHighestInterest?.interestPaymentFrequency;
    const rate = potProductWithTheHighestInterest?.rates.tierRates.find((rate) => rate.tier === tier);
    return filteredBenefits
        .map((item) => {
        const customData = getCustomNameAndDescription(item, featureFlags, tier, paymentLimitMap, rate ? { ...rate, interestPaymentFrequency } : undefined, tierFxRateMap, connectionsLimitMap, rentReportingAgenciesTierMap, guessedHomeCountry, savingsPlanTierFees);
        return {
            ...item,
            customData,
        };
    })
        .filter((item) => {
        const isBenefitIdAvailableOnFree = checkIsBenefitIdAvailableOnFree(item.id, guessedHomeCountry, featureFlags);
        return (((tierLevelMap[item.minTier] <= currentTierLevel &&
            (!item.maxTier ||
                tierLevelMap[item.maxTier] >= currentTierLevel ||
                tierLevelMap[item.maxTier] < tierLevelMap[item.minTier]) &&
            !isBenefitIdAvailableOnFree) ||
            (isBenefitIdAvailableOnFree && tier === Tier.free)) &&
            item.customData !== null);
    })
        .reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {});
});
export const selectFilteredBenefitsDataForTierWithCustom = createSelector([selectFilteredBenefitsWithCustom, selectBenefitsData], (benefitsWithCustom, benefitsData) => mapBenefitsToBenefitsDataArray(benefitsWithCustom, benefitsData));
export const selectFilteredBenefitsDataForTierWithCustomAndTopFeatures = createSelector([
    selectFilteredBenefitsWithCustom,
    (store) => store.user.user.guessedHomeCountry,
    selectBenefitsData,
    (store) => store.utils.featureFlags,
    (_, tier) => tier,
], (benefitsWithCustom, guessedHomeCountry, benefitsData, featureFlags, tier) => {
    const mappedBenefitsData = mapBenefitsToBenefitsDataArray(benefitsWithCustom, benefitsData);
    const topFeatures = {
        name: 'Top features',
        data: [],
        collapsible: false,
    };
    const topFeaturesForCountry = guessedHomeCountry === 'GB'
        ? benefitsTopFeaturesGB
        : benefitsTopFeatures;
    mappedBenefitsData.forEach((category) => {
        category.data.forEach((categoryBenefit) => {
            if (topFeaturesForCountry[tier].includes(categoryBenefit.id)) {
                topFeatures.data.push(categoryBenefit);
            }
        });
    });
    topFeatures.data.sort((a, b) => topFeaturesForCountry[tier].indexOf(a.id) -
        topFeaturesForCountry[tier].indexOf(b.id));
    if (benefitsTopFeaturesGB.FREE.includes('linkAccounts') &&
        tier === Tier.free) {
        topFeatures.data.push(benefits.linkAccounts);
    }
    return [topFeatures, ...mappedBenefitsData];
});
export const selectFilteredBenefitsDataForTierFlattened = createSelector([selectFilteredBenefitsDataForTierWithCustom], (filteredBenefits) => flatten(filteredBenefits.map((item) => item.data)));
export const selectShouldShowLoginsUpgrade = createSelector([selectActiveConnectionsWithoutManual, selectUser, selectConnectionsLimit], (connections, user, connectionsLimitMap) => {
    const requiredTier = getRequiredTier(connectionsLimitMap, connections.length);
    return requiredTier !== Tier.free && !isUnlocked(user, requiredTier);
});
export const selectIsOnSameStore = createSelector([(store) => store.user.user, selectShouldShowStripe], (user, shouldShowStripe) => {
    if (shouldShowStripe) {
        return user.emmaProStatus.store === 'stripe';
    }
    return (user.emmaProStatus.store ===
        Platform.select({
            ios: 'apple',
            macos: 'apple',
            android: 'google',
        }));
});
export const selectStripePaymentMethodMissing = (store) => {
    const { emmaProStatus } = store.user.user;
    return Boolean(emmaProStatus.autoRenewing &&
        emmaProStatus.store === 'stripe' &&
        store.stripe.customer &&
        !store.stripe.customer?.cardLast4);
};
export const selectSubscriptionPrices = createSelector([
    selectShouldShowStripe,
    selectStripePriceMap,
    selectAvailableIAPSubscriptionsWithPrices,
], (canUseStripe, stripePriceMap, subscriptions) => {
    if (canUseStripe) {
        return stripePriceMap;
    }
    const prices = {
        [TierPeriod.yearly]: {},
        [TierPeriod.monthly]: {},
    };
    subscriptions.forEach((sub) => {
        if (sub.period === TierPeriod.yearly) {
            prices[TierPeriod.yearly][sub.tier] = sub;
        }
        else if (sub.period === TierPeriod.monthly) {
            prices[TierPeriod.monthly][sub.tier] = sub;
        }
    });
    return prices;
}, {
    devModeChecks: { identityFunctionCheck: 'never' },
});
export const selectEmmaProStatusSKU = createSelector([(store) => store.user.user.emmaProStatus], (emmaProStatus) => emmaProStatusToSKU(emmaProStatus), {
    memoizeOptions: {
        resultEqualityCheck: (a, b) => {
            if (a === undefined) {
                if (b === undefined) {
                    return true;
                }
                return false;
            }
            if (b === undefined) {
                return false;
            }
            return a.id === b.id && a.period === b.period && a.tier === b.tier;
        },
    },
});
