import { rem } from 'design-system/values';
import useColors, { useGradientColors } from 'hooks/useColors';
import React, { useId } from 'react';
import { Defs, LinearGradient, Path, Stop, Svg } from 'react-native-svg';
import { IconWithGradient } from './Icon';
export const IconEmma = ({ withText, ...props }) => {
    const colors = useColors();
    const gradientColors = useGradientColors(props.gradientKey ?? 'ultimate', props.gradientColors, props.color, props.colorKey);
    const id = useId();
    if (withText) {
        return (<Svg width={props.width || rem(124)} height={props.height || rem(50)} viewBox="0 0 124 50" fill="none" {...props}>
        <Path fillRule="evenodd" clipRule="evenodd" d="M46.4835 17.3685V37.4913H59.0053V33.8015H50.3756V29.1469H58.1876V25.656H50.3756V21.0583H59.0053V17.3685H46.4835ZM62.4431 23.5272V37.4909H66.1941V29.3173C66.1941 27.7559 67.1249 26.5642 68.7888 26.5642C70.4807 26.5642 71.2425 27.699 71.2425 29.2034V37.4909H74.9649V29.3173C74.9649 27.8128 75.9243 26.5642 77.5316 26.5642C79.2521 26.5642 79.9853 27.699 79.9853 29.2034V37.4909H83.6232V28.4368C83.6232 24.6908 81.1695 23.1302 78.6035 23.1302C76.7699 23.1302 75.3035 23.7541 74.2038 25.4574C73.4985 23.953 72.0036 23.1302 70.0862 23.1302C68.5633 23.1302 66.7863 23.8673 66.0245 25.2305V23.5272H62.4431ZM87.4261 37.4909V23.5272H91.0075V25.2305C91.7693 23.8673 93.5456 23.1302 95.0685 23.1302C96.9859 23.1302 98.4809 23.953 99.1861 25.4574C100.286 23.7541 101.753 23.1302 103.586 23.1302C106.152 23.1302 108.606 24.6908 108.606 28.4368V37.4909H104.968V29.2034C104.968 27.699 104.234 26.5642 102.514 26.5642C100.907 26.5642 99.9479 27.8128 99.9479 29.3173V37.4909H96.2255V29.2034C96.2255 27.699 95.4637 26.5642 93.7712 26.5642C92.1072 26.5642 91.1765 27.7559 91.1765 29.3173V37.4909H87.4261ZM120.165 31.2198V31.8437C120.165 34.3137 118.698 35.0789 117.12 35.0789C115.906 35.0789 115.315 34.2849 115.315 33.462C115.315 32.3553 116.048 31.8437 117.034 31.7025L120.165 31.2198ZM119.121 28.9494L115.709 29.4595C113.396 29.8291 111.563 31.1628 111.563 33.689C111.563 35.8455 113.368 37.8896 116.329 37.8896C118.388 37.8896 119.713 36.9241 120.419 35.8174C120.419 36.357 120.474 37.123 120.559 37.4919H124C123.915 37.0098 123.831 36.0451 123.831 35.2784V28.4097C123.831 25.5997 122.195 23.103 117.795 23.103C114.044 23.103 112.07 25.5147 111.845 27.7007L115.173 28.4097C115.286 27.1899 116.16 26.1393 117.824 26.1393C119.403 26.1393 120.164 26.9629 120.164 27.9558C120.164 28.4378 119.911 28.8355 119.121 28.9494Z" fill={colors.elements.primary}/>
        <Path fillRule="evenodd" clipRule="evenodd" d="M30.739 40.4529C30.1452 38.2638 28.1088 36.796 26.6678 35.1588C24.4288 32.6132 27.818 30.3311 28.9468 28.298C29.8261 26.7137 29.9479 24.5379 28.9426 22.9894C27.9554 21.4703 26.2081 20.3744 25.8709 18.5176C25.6362 17.2267 26.4679 16.0634 26.6897 14.8275C26.8811 13.7626 26.9928 12.648 26.9431 11.5665C26.9089 10.8265 26.4834 9.82513 26.8918 9.13692C27.9618 7.33516 29.5701 4.96273 28.8661 2.8532C28.3776 1.38967 26.9746 0.093917 25.3027 0.0052182C24.1979 -0.0535581 23.1723 0.389936 22.3358 1.04556C22.253 1.11021 21.3262 1.95392 21.3069 1.94377C19.6318 1.30898 17.5661 0.991591 15.4997 0.991591C13.4339 0.991591 11.3676 1.30898 9.69308 1.94377C9.67331 1.95392 8.74705 1.11021 8.6642 1.04556C7.8272 0.389936 6.80205 -0.0535581 5.69674 0.0052182C4.0254 0.093917 2.62238 1.38967 2.13386 2.8532C1.42994 4.96273 3.03821 7.33516 4.10825 9.13692C4.51606 9.82513 4.09114 10.8265 4.05694 11.5665C4.00669 12.648 4.1184 13.7626 4.30975 14.8275C4.53209 16.0634 5.36322 17.2267 5.12858 18.5176C4.79132 20.3744 3.04409 21.4703 2.05743 22.9894C1.05206 24.5379 1.17392 26.7137 2.05315 28.298C3.18145 30.3311 6.57062 32.6132 4.33166 35.1588C2.89122 36.796 0.854835 38.2638 0.261022 40.4529C-0.710672 44.0324 0.984715 50.5079 6.11898 49.9683C7.51613 49.8208 8.84272 49.2544 10.1565 48.8152C11.9176 48.2264 13.6654 48.2755 15.4997 48.2755C17.3346 48.2755 19.0824 48.2264 20.843 48.8152C22.1567 49.2544 23.4839 49.8208 24.881 49.9683C30.0153 50.5079 31.7107 44.0324 30.739 40.4529Z" fill={`url(#gradient_${id})`}/>
        <Defs>
          <LinearGradient id={`gradient_${id}`} x1="0" y1="50" x2="29.0111" y2="50" gradientUnits="userSpaceOnUse">
            <Stop stopColor={gradientColors[0]}/>
            <Stop offset="1" stopColor={gradientColors[1]}/>
          </LinearGradient>
        </Defs>
      </Svg>);
    }
    return (<IconWithGradient id={id} {...props} defaultGradientKey="ultimate">
      <Path d="M18.099 18.686c-.238-.876-1.053-1.463-1.63-2.119-.895-1.019.461-1.932.913-2.746.352-.635.4-1.505-.002-2.126-.394-.608-1.094-1.046-1.229-1.79-.094-.517.239-.983.328-1.477.076-.427.12-.873.1-1.306-.012-.296-.183-.697-.02-.973.428-.721 1.072-1.67.79-2.515-.195-.586-.757-1.105-1.426-1.14-.442-.024-.852.154-1.187.416-.033.026-.404.364-.412.36-.67-.255-1.497-.381-2.324-.381-.827 0-1.654.127-2.324.38-.007.005-.379-.333-.412-.359-.335-.262-.745-.44-1.187-.416-.67.035-1.23.554-1.426 1.14-.282.845.362 1.794.79 2.515.164.276-.006.677-.02.973-.02.433.024.88.1 1.306.09.494.422.96.328 1.477-.135.743-.834 1.182-1.23 1.79-.401.62-.352 1.491 0 2.126.451.814 1.808 1.727.912 2.746-.577.656-1.392 1.243-1.63 2.12-.389 1.433.29 4.025 2.344 3.809.56-.06 1.09-.286 1.616-.461.705-.236 1.405-.216 2.14-.216.733 0 1.433-.02 2.138.216.525.175 1.056.402 1.616.46 2.054.217 2.733-2.376 2.344-3.809Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
