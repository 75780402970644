import React from 'react';
import { Path, Svg } from 'react-native-svg';
import useColors from 'hooks/useColors';
const EmmaBetaLogo = () => {
    const colors = useColors();
    return (<Svg width="100" height="38" viewBox="0 0 100 38" fill="none">
      <Path fillRule="evenodd" clipRule="evenodd" fill={colors.elements.brand} d="M22.8985 29.4619C22.4948 27.976 21.1103 26.9798 20.1306 25.8686C18.6084 24.1409 20.9126 22.592 21.6801 21.2121C22.2779 20.1368 22.3607 18.6601 21.6772 17.6091C21.006 16.578 19.8181 15.8342 19.5888 14.574C19.4293 13.6978 19.9947 12.9083 20.1455 12.0694C20.2756 11.3467 20.3516 10.5902 20.3178 9.85614C20.2945 9.35386 20.0053 8.67423 20.2829 8.20713C21.0104 6.98424 22.1038 5.37403 21.6252 3.94225C21.2931 2.94893 20.3392 2.06948 19.2026 2.00928C18.4515 1.96939 17.7541 2.27039 17.1854 2.71538C17.1291 2.75926 16.499 3.3319 16.4859 3.32501C15.3471 2.89417 13.9426 2.67875 12.5378 2.67875C11.1333 2.67875 9.72851 2.89417 8.59004 3.32501C8.57659 3.3319 7.94686 2.75926 7.89053 2.71538C7.32148 2.27039 6.62451 1.96939 5.87304 2.00928C4.73675 2.06948 3.78288 2.94893 3.45075 3.94225C2.97217 5.37403 4.06559 6.98424 4.79308 8.20713C5.07033 8.67423 4.78145 9.35386 4.75819 9.85614C4.72403 10.5902 4.79998 11.3467 4.93007 12.0694C5.08124 12.9083 5.64629 13.6978 5.48677 14.574C5.25748 15.8342 4.06958 16.578 3.39878 17.6091C2.71526 18.6601 2.79812 20.1368 3.39588 21.2121C4.16297 22.592 6.46717 24.1409 4.94497 25.8686C3.96566 26.9798 2.58118 27.976 2.17746 29.4619C1.51683 31.8913 2.66948 36.2864 6.16011 35.9201C7.10999 35.82 8.0119 35.4356 8.90509 35.1375C10.1024 34.7378 11.2907 34.7712 12.5378 34.7712C13.7853 34.7712 14.9735 34.7378 16.1705 35.1375C17.0637 35.4356 17.966 35.82 18.9159 35.9201C22.4065 36.2864 23.5591 31.8913 22.8985 29.4619"/>
      <Path fill={colors.text.primary} d="M76.343 13.2216V7.13987H78.6712C79.1107 7.13987 79.4759 7.20916 79.7669 7.34774C80.0599 7.48434 80.2787 7.67142 80.4232 7.90899C80.5697 8.14655 80.643 8.41579 80.643 8.71671C80.643 8.96418 80.5955 9.17601 80.5004 9.3522C80.4054 9.52642 80.2777 9.66796 80.1173 9.77685C79.957 9.88573 79.7778 9.96393 79.5799 10.0114V10.0708C79.7956 10.0827 80.0025 10.149 80.2005 10.2698C80.4004 10.3886 80.5638 10.5569 80.6905 10.7746C80.8172 10.9924 80.8805 11.2557 80.8805 11.5645C80.8805 11.8793 80.8043 12.1624 80.6519 12.4138C80.4994 12.6633 80.2698 12.8603 79.9629 13.0048C79.6561 13.1493 79.27 13.2216 78.8048 13.2216H76.343ZM77.4447 12.301H78.6296C79.0295 12.301 79.3175 12.2248 79.4937 12.0723C79.6719 11.9179 79.761 11.7199 79.761 11.4784C79.761 11.2983 79.7165 11.1359 79.6274 10.9914C79.5383 10.8449 79.4116 10.7301 79.2473 10.6469C79.0829 10.5618 78.887 10.5192 78.6593 10.5192H77.4447V12.301ZM77.4447 9.72637H78.5346C78.7246 9.72637 78.8959 9.69172 79.0483 9.62243C79.2007 9.55116 79.3205 9.45119 79.4076 9.3225C79.4967 9.19184 79.5413 9.03743 79.5413 8.85925C79.5413 8.62366 79.4581 8.42965 79.2918 8.27721C79.1275 8.12478 78.883 8.04856 78.5583 8.04856H77.4447V9.72637ZM81.9293 13.2216V7.13987H85.8847V8.0634H83.031V9.71449H85.6798V10.638H83.031V12.298H85.9085V13.2216H81.9293ZM86.8318 8.0634V7.13987H91.6841V8.0634H89.8043V13.2216H88.7115V8.0634H86.8318ZM92.7558 13.2216H91.5799L93.7209 7.13987H95.081L97.225 13.2216H96.0491L94.4247 8.38709H94.3772L92.7558 13.2216ZM92.7944 10.837H96.0016V11.7219H92.7944V10.837Z"/>
      <Path fillRule="evenodd" clipRule="evenodd" fill={colors.text.primary} d="M31.1611 13.2216V30.4432H41.9166V27.2854H34.5042V23.3018H41.2143V20.3142H34.5042V16.3794H41.9166V13.2216H31.1611ZM44.8695 18.4923V30.4429H48.0914V23.4477C48.0914 22.1114 48.8909 21.0915 50.3201 21.0915C51.7733 21.0915 52.4276 22.0627 52.4276 23.3502V30.4429H55.625V23.4477C55.625 22.1601 56.449 21.0915 57.8297 21.0915C59.3075 21.0915 59.9372 22.0627 59.9372 23.3502V30.4429H63.062V22.6941C63.062 19.4882 60.9544 18.1525 58.7503 18.1525C57.1753 18.1525 55.9158 18.6866 54.9712 20.1443C54.3655 18.8567 53.0814 18.1525 51.4344 18.1525C50.1264 18.1525 48.6 18.7834 47.9457 19.95V18.4923H44.8695ZM66.3285 30.4429V18.4923H69.4047V19.95C70.059 18.7834 71.5848 18.1525 72.8929 18.1525C74.5398 18.1525 75.8239 18.8567 76.4297 20.1443C77.3743 18.6866 78.6344 18.1525 80.2087 18.1525C82.4128 18.1525 84.5204 19.4882 84.5204 22.6941V30.4429H81.3956V23.3502C81.3956 22.0627 80.7659 21.0915 79.2881 21.0915C77.9075 21.0915 77.084 22.1601 77.084 23.4477V30.4429H73.8867V23.3502C73.8867 22.0627 73.2323 21.0915 71.7785 21.0915C70.3493 21.0915 69.5498 22.1114 69.5498 23.4477V30.4429H66.3285ZM94.4491 25.0758V25.6098C94.4491 27.7237 93.1896 28.3785 91.8336 28.3785C90.7912 28.3785 90.2832 27.699 90.2832 26.9948C90.2832 26.0476 90.9129 25.6098 91.7604 25.4889L94.4491 25.0758ZM93.5529 23.1328L90.6218 23.5694C88.6354 23.8857 87.0611 25.0271 87.0611 27.1891C87.0611 29.0347 88.6115 30.7841 91.1544 30.7841C92.9231 30.7841 94.0615 29.9578 94.6672 29.0106C94.6672 29.4725 94.7152 30.128 94.7878 30.4437H97.7434C97.6708 30.0312 97.5982 29.2055 97.5982 28.5494V22.6709C97.5982 20.266 96.193 18.1293 92.4139 18.1293C89.192 18.1293 87.4965 20.1932 87.3028 22.0641L90.1618 22.6709C90.2584 21.6269 91.0093 20.7279 92.4385 20.7279C93.7952 20.7279 94.4489 21.4327 94.4489 22.2824C94.4489 22.695 94.2312 23.0353 93.5529 23.1328Z"/>
    </Svg>);
};
export default EmmaBetaLogo;
