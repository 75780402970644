import React, { memo, useCallback, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import Animated, { Extrapolate, interpolate, useDerivedValue, useAnimatedStyle, } from 'react-native-reanimated';
import { rem } from 'design-system';
import SelectorControllableReanimated from 'design-system/SelectorControllableReanimated';
import useColors from 'hooks/useColors';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { TOTAL_CHART_HEIGHT } from '../AccountsHeaderGraph';
const NetworthPageControl = ({ scrollX, scrollY, carouselRef, onPressUpgrade, }) => {
    const colors = useColors();
    const { width } = useAppFrameDimensions();
    const scrollXX = useDerivedValue(() => interpolate(scrollX.value, [3 * width, 4 * width, 5 * width], [0, width, 2 * width], {
        extrapolateLeft: Extrapolate.CLAMP,
    }), [width]);
    const sections = useMemo(() => [
        { name: 'Net worth', width: rem(106) },
        { name: 'Assets', width: rem(78) },
        { name: 'Debt', width: rem(68) },
    ].map((item) => ({
        ...item,
        id: item.name,
        text: item.name,
        color: colors.buttons.brandGroup,
        selectedTextColor: colors.text.textOnBrand,
        unSelectedTextColor: colors.text.secondary,
    })), [colors]);
    const animatedStyles = useAnimatedStyle(() => ({
        transform: [
            {
                translateY: -scrollY.value,
            },
            {
                translateX: interpolate(scrollX.value, [2 * width, 3 * width], [width, 0], Extrapolate.CLAMP),
            },
        ],
    }), [width]);
    const setSelection = useCallback((index) => {
        if (onPressUpgrade) {
            onPressUpgrade();
            return;
        }
        carouselRef.current?.snapToItem(index + 3, false, true);
    }, [carouselRef, onPressUpgrade]);
    return (<Animated.View pointerEvents="box-none" style={[styles.container, animatedStyles]}>
      <SelectorControllableReanimated scrollX={scrollXX} sections={sections} screenWidth={width} spaceBetween={rem(4)} setSelection={setSelection} itemStyle={styles.itemStyle} selectorStyle={styles.selectorStyle} itemContainerStyle={styles.itemContainerStyle}/>
    </Animated.View>);
};
export default memo(NetworthPageControl);
export const NETWORTH_PAGE_CONTROL_HEIGHT = rem(58);
const styles = StyleSheet.create({
    container: {
        left: 0,
        right: 0,
        bottom: 0,
        paddingTop: rem(8),
        paddingLeft: rem(16),
        position: 'absolute',
        paddingBottom: rem(16),
        top: TOTAL_CHART_HEIGHT,
        height: NETWORTH_PAGE_CONTROL_HEIGHT,
    },
    selectorStyle: {
        margin: 0,
        padding: 0,
        borderRadius: rem(8),
        justifyContent: 'center',
    },
    itemContainerStyle: {
        margin: 0,
        padding: 0,
    },
    itemStyle: {
        paddingVertical: rem(4),
    },
});
