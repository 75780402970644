import React from 'react';
import CarouselRN, { PaginationIndicator, withCarouselContext, } from '@r0b0t3d/react-native-carousel';
import { View } from 'react-native';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
export const Carousel = withCarouselContext(({ indicatorConfigs = {}, activeIndicatorStyle = {}, paginationIndicatorContainerStyles = {}, ...props }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    return (<View>
        <CarouselRN style={styles.carousel} loop={false} inactiveOpacity={1} inactiveScale={0.82} {...props}/>
        {props.data?.length > 1 && (<PaginationIndicator containerStyle={[
                styles.indicatorContainer,
                paginationIndicatorContainerStyles,
            ]} activeIndicatorStyle={[
                styles.activeIndicatorStyle,
                activeIndicatorStyle,
            ]} indicatorConfigs={{
                spaceBetween: rem(8),
                indicatorWidth: rem(5),
                indicatorSelectedWidth: rem(15),
                indicatorColor: colors.elements.secondaryTransparent,
                ...indicatorConfigs,
            }}/>)}
      </View>);
});
const styleSet = createStyleSheets((colors) => ({
    carousel: {
        height: rem(183),
    },
    image: {
        height: rem(183),
        borderRadius: rem(24),
        alignSelf: 'center',
    },
    indicatorContainer: {
        marginTop: rem(24),
        alignSelf: 'center',
    },
    activeIndicatorStyle: {
        height: rem(5),
        borderRadius: rem(14),
        backgroundColor: colors.elements.primary,
    },
}));
