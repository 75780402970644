import React from 'react';
import { StyleSheet, View } from 'react-native';
import DashedLine from 'design-system/DashedLine';
const MaxLine = ({ y, yValue }) => {
    if (!y || yValue === undefined)
        return null;
    return (<View style={[
            styles.container,
            {
                top: y(yValue),
            },
        ]}>
      <DashedLine />
    </View>);
};
const styles = StyleSheet.create({
    container: {
        backgroundColor: 'transparent',
        padding: 0.1,
    },
});
export default MaxLine;
