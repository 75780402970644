import React, { forwardRef, useCallback } from 'react';
import { TextInput as RNTextInput, } from 'react-native';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
const TextInput = forwardRef(({ style, focusStyle, ...props }, ref) => {
    const styles = useStyles(styleSet);
    const [IsFocused, setIsFocused] = React.useState(false);
    const handleEvent = useCallback((event) => () => {
        setIsFocused(event === 'focus');
    }, []);
    return (<RNTextInput ref={ref} onBlur={handleEvent('blur')} onFocus={handleEvent('focus')} style={[
            styles.textInput,
            IsFocused && styles.focusedStyles,
            style,
            IsFocused && focusStyle,
        ]} {...props}/>);
});
const styleSet = createStyleSheets((colors) => ({
    textInput: {
        fontSize: rem(16),
        textAlign: 'left',
        borderWidth: rem(1),
        borderRadius: rem(8),
        paddingVertical: rem(8),
        paddingHorizontal: rem(16),
        fontFamily: 'Montserrat-Regular',
        caretColor: colors.text.selection,
        borderColor: colors.borders.divider,
        backgroundColor: colors.cards.onDark,
    },
    focusedStyles: {
        borderColor: colors.elements.brand,
    },
}));
export default React.memo(TextInput);
