import { Alert } from 'react-native';
import { noop } from 'lodash';
import { useActionSheet } from 'hooks/useActionSheet';
import Links from 'utils/links';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { useAppSelector } from 'store/hooks';
import { selectIsGBUser } from 'reducers/selectors';
const strings = {
    alert: {
        sorryTitle: 'Sorry to hear that 😭',
        amazingTitle: 'Amazing! 😊',
        okThanks: 'Ok, thanks',
        visitCommunity: 'Visit Community',
        bankNotSupportedMessage: "If you don't see your bank, we don't currently support it. You can check any ETA or request it on our Community forum.",
        missingSavingsAccountsMessage: "If you don't see your savings account, it means your bank doesn't currently support it.",
        featureRequestMessage: 'Post your feature request in our Community forum, so our product team will be able to see it.',
    },
    visitCommunity: 'Visit Community',
    ok: 'Ok, thanks',
    sheet: {
        title: 'How can we help you?',
        options: {
            missingBank: 'My bank is not here',
            missingSavings: 'My savings accounts are missing',
            featureRequest: 'I have a feature request',
            helpCenter: 'Visit the help center',
            chat: 'Chat to a human',
            cancel: 'Cancel',
        },
    },
};
const openMissingSavingsAccountsAlert = () => {
    Alert.alert(strings.alert.sorryTitle, strings.alert.missingSavingsAccountsMessage, [
        {
            text: strings.ok,
            style: 'cancel',
        },
    ]);
};
const openFeatureRequestAlert = () => {
    Alert.alert(strings.alert.amazingTitle, strings.alert.featureRequestMessage, [
        { text: strings.visitCommunity, onPress: Links.openEmmaCommunity },
        {
            text: strings.ok,
            style: 'cancel',
        },
    ]);
};
const openMissingBankAlert = () => {
    Alert.alert(strings.alert.sorryTitle, strings.alert.bankNotSupportedMessage, [
        {
            text: strings.visitCommunity,
            onPress: Links.openEmmaCommunityBankIntegrations,
        },
        {
            text: strings.ok,
            style: 'cancel',
        },
    ]);
};
const useSheetOptions = () => {
    const nav = useMainStackNavigation();
    const isGBUser = useAppSelector(selectIsGBUser);
    const sheetOptions = [
        { title: strings.sheet.options.missingBank, action: openMissingBankAlert },
        {
            title: strings.sheet.options.featureRequest,
            action: openFeatureRequestAlert,
        },
        {
            title: strings.sheet.options.helpCenter,
            action: Links.openEmmaHelp,
        },
        {
            title: strings.sheet.options.chat,
            action: () => nav.navigate('Support', {}),
        },
        { title: strings.sheet.options.cancel, action: noop },
    ];
    if (isGBUser) {
        sheetOptions.splice(1, 0, {
            title: strings.sheet.options.missingSavings,
            action: openMissingSavingsAccountsAlert,
        });
    }
    return sheetOptions;
};
export const useSupportActionSheet = () => {
    const { showActionSheetWithOptions } = useActionSheet();
    const options = useSheetOptions();
    const optionTitles = options.map((o) => o.title);
    return {
        showSupportActionSheet: () => showActionSheetWithOptions({
            options: optionTitles,
            title: strings.sheet.title,
            cancelButtonIndex: optionTitles.length - 1,
        }, (i) => {
            options[i].action();
        }),
    };
};
