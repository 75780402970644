export const GET_REWARD_REQUEST = 'GET_REWARD_REQUEST';
export const GET_REWARD_SUCCESS = 'GET_REWARD_SUCCESS';
export const GET_REWARD_FAILURE = 'GET_REWARD_FAILURE';
export const GET_REWARDS_REQUEST = 'GET_REWARDS_REQUEST';
export const GET_REWARDS_SUCCESS = 'GET_REWARDS_SUCCESS';
export const GET_REWARDS_FAILURE = 'GET_REWARDS_FAILURE';
export const GET_REWARD_LINK_REQUEST = 'GET_REWARD_LINK_REQUEST';
export const GET_REWARD_LINK_SUCCESS = 'GET_REWARD_LINK_SUCCESS';
export const GET_REWARD_LINK_FAILURE = 'GET_REWARD_LINK_FAILURE';
export const GET_REDEMPTIONS_REQUEST = 'GET_REDEMPTIONS_REQUEST';
export const GET_REDEMPTIONS_SUCCESS = 'GET_REDEMPTIONS_SUCCESS';
export const GET_REDEMPTIONS_FAILURE = 'GET_REDEMPTIONS_FAILURE';
export const CLAIM_REDEMPTIONS_REQUEST = 'CLAIM_REDEMPTIONS_REQUEST';
export const CLAIM_REDEMPTIONS_SUCCESS = 'CLAIM_REDEMPTIONS_SUCCESS';
export const CLAIM_REDEMPTIONS_FAILURE = 'CLAIM_REDEMPTIONS_FAILURE';
