import React, { useMemo, memo } from 'react';
import { View, StyleSheet } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import { TextButtonLinkSeeAll } from 'design-system/TextButton';
const SectionHeader = ({ title, subTitle, style, onSeeMorePress }) => {
    const renderTitleRight = useMemo(() => {
        if (onSeeMorePress) {
            return <TextButtonLinkSeeAll onPress={onSeeMorePress}/>;
        }
        return null;
    }, [onSeeMorePress]);
    return (<View style={[styles.container, style]}>
      <View style={styles.flex}>
        <Text Text-16 Secondary>
          {title}
        </Text>
        {!!subTitle && (<Text TextThin-14 Gray-70>
            {subTitle}
          </Text>)}
      </View>
      {renderTitleRight}
    </View>);
};
const styles = StyleSheet.create({
    container: {
        marginTop: rem(24),
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: rem(16),
        paddingHorizontal: rem(16),
    },
    flex: {
        flex: 1,
    },
});
export default memo(SectionHeader);
