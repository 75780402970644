import { REHYDRATE } from 'redux-persist';
import { CACHE_TRADING_ACCOUNT, GET_TRADING_ACCOUNT_SUCCESS } from 'features/invest/actions/types';
import { Tier } from 'features/premium/entities';
import { LOGGED_OUT } from 'actions/types';
import { formatPlural } from 'utils';
import { SavingsPlanRuleType, } from '../types';
import { sortPotProducts } from '../utils';
export const initialState = {
    account: undefined,
    walletBalance: undefined,
    availableToInvest: { value: 0, currency: 'GBP' },
    cashWithdrawable: { value: 0, currency: 'GBP' },
    cashUnsettled: { value: 0, currency: 'GBP' },
    pots: [],
    deletedPots: [],
    interestPotProductsTierRateMap: {},
    hideUpdateAccountCardInPots: undefined,
    productIdWithTheHighestInterest: undefined,
    hasFunded: undefined,
    hasCreatedPot: undefined,
    hasFundedPot: undefined,
    hasCompletedOnboardingFunnel: undefined,
    savingsPlan: undefined,
    savingsPlanMode: undefined,
    savingsPlanTierFees: undefined,
    savingsPlanFrequency: undefined,
};
const getSavingsPlanFromSavingsPlanResponse = (savingsPlanResponse) => {
    const distribution = {};
    const savingsPlanRules = {
        automated: false,
        customAmount: undefined,
        roundUpMultiplier: undefined,
    };
    savingsPlanResponse?.rules.forEach((rule) => {
        switch (rule.type) {
            case SavingsPlanRuleType.AUTOMATED: {
                savingsPlanRules.automated = true;
                break;
            }
            case SavingsPlanRuleType.ROUND_UP: {
                savingsPlanRules.roundUpMultiplier = rule.roundUpMultiplier;
                break;
            }
            case SavingsPlanRuleType.CUSTOM: {
                savingsPlanRules.customAmount = rule.amount;
                break;
            }
            default:
                break;
        }
    });
    let hasWallet = false;
    savingsPlanResponse.destinations?.forEach((destination) => {
        if (!hasWallet && destination.potId === 'WALLET') {
            hasWallet = true;
        }
        distribution[destination.potId] = destination.percentage;
    });
    const destinationLength = savingsPlanResponse.destinations.length;
    const savingsPlan = {
        distribution,
        id: savingsPlanResponse.id,
        bankId: savingsPlanResponse.bankId,
        bankInfo: savingsPlanResponse.bankInfo,
        sortCode: savingsPlanResponse.sortCode,
        isPaused: savingsPlanResponse.isPaused,
        totalAllocationItems: destinationLength,
        accountNumber: savingsPlanResponse.accountNumber,
        allowOverdraft: savingsPlanResponse.allowOverdraft,
        transactionFee: savingsPlanResponse.transactionFee,
        mandateStatus: savingsPlanResponse.mandateStatus,
        mandateStatusDescription: savingsPlanResponse.mandateStatusDescription,
        allocationText: !hasWallet
            ? `${destinationLength} ${formatPlural('pot', 'pots', destinationLength)}`
            : `Emma wallet & ${destinationLength - 1} ${formatPlural('pot', 'pots', destinationLength - 1)}`,
        ...savingsPlanRules,
    };
    return savingsPlan;
};
const reducer = (state = initialState, action) => {
    switch (action?.type) {
        case REHYDRATE: {
            if (action.payload && action.payload.pots) {
                return {
                    ...state,
                    ...action.payload.pots,
                };
            }
            return state;
        }
        case 'CREATE_TRADING_ACCOUNT_SUCCESS': {
            const { payload } = action;
            if (payload.success && state.account) {
                return {
                    ...state,
                    account: {
                        ...state.account,
                        status: 'OPENING_IN_PROGRESS',
                    },
                };
            }
            return state;
        }
        case 'POTS_ONBOARDING_FUNNEL_COMPLETED': {
            return {
                ...state,
                hasCompletedOnboardingFunnel: true,
            };
        }
        case CACHE_TRADING_ACCOUNT:
        case GET_TRADING_ACCOUNT_SUCCESS: {
            const { payload } = action;
            const newInfoObj = {};
            if (payload.account.cashWithdrawable) {
                newInfoObj.cashWithdrawable = payload.account.cashWithdrawable;
            }
            if (payload.account.cashUnsettled) {
                newInfoObj.cashUnsettled = payload.account.cashUnsettled;
            }
            if (payload.account.availableToInvest) {
                newInfoObj.availableToInvest = payload.account.availableToInvest;
            }
            if (payload.account.walletBalance) {
                newInfoObj.walletBalance = payload.account.walletBalance;
            }
            if ('hasFunded' in payload && payload.hasFunded !== undefined) {
                newInfoObj.hasFunded = payload.hasFunded;
            }
            return {
                ...state,
                ...newInfoObj,
            };
        }
        case 'CACHE_ACCOUNTS': {
            const { tradingAccount } = action;
            const newInfoObj = {};
            if (tradingAccount.cashWithdrawable) {
                newInfoObj.cashWithdrawable = tradingAccount.cashWithdrawable;
            }
            if (tradingAccount.availableToInvest) {
                newInfoObj.availableToInvest = tradingAccount.availableToInvest;
            }
            if (tradingAccount.cashUnsettled) {
                newInfoObj.cashUnsettled = tradingAccount.cashUnsettled;
            }
            if (tradingAccount.walletBalance) {
                newInfoObj.walletBalance = tradingAccount.walletBalance;
            }
            return {
                ...state,
                ...newInfoObj,
            };
        }
        case LOGGED_OUT:
            return initialState;
        case 'POTS_SET_ACCOUNT_STATUS':
            return { ...state, account: { ...state.account, status: action.status } };
        case 'GET_POTS_ACCOUNT_SUCCESS': {
            const finalAccount = {
                status: state.account?.status === 'OPENING_IN_PROGRESS' && action.payload.account.status === 'NOT_STARTED'
                    ? state.account.status
                    : action.payload.account.status,
                kycVerification: action.payload.account.kycVerification,
            };
            if (action.payload.savingsPlan) {
                const savingsPlan = getSavingsPlanFromSavingsPlanResponse(action.payload.savingsPlan);
                return {
                    ...state,
                    account: finalAccount,
                    hasFunded: action.payload.hasFunded,
                    hasFundedPot: action.payload.hasFundedPot,
                    hasCreatedPot: action.payload.hasCreatedPot,
                    savingsPlan,
                    savingsPlanMode: action.payload.savingsPlanMode,
                    savingsPlanTierFees: action.payload.savingsPlanTierFees,
                    savingsPlanFrequency: action.payload.savingsPlanFrequency,
                };
            }
            return {
                ...state,
                savingsPlan: undefined,
                savingsPlanMode: action.payload.savingsPlanMode,
                savingsPlanTierFees: action.payload.savingsPlanTierFees,
                savingsPlanFrequency: action.payload.savingsPlanFrequency,
                account: finalAccount,
                hasFunded: action.payload.hasFunded,
                hasFundedPot: action.payload.hasFundedPot,
                hasCreatedPot: action.payload.hasCreatedPot,
            };
        }
        case 'CREATE_SAVINGS_PLAN_SUCCESS': {
            if (action.payload.savingsPlan) {
                const savingsPlan = getSavingsPlanFromSavingsPlanResponse(action.payload.savingsPlan);
                return {
                    ...state,
                    savingsPlan,
                };
            }
            return state;
        }
        case 'EDIT_SAVINGS_PLAN_SUCCESS': {
            if (action.extra.skipSet) {
                return state;
            }
            const savingsPlan = getSavingsPlanFromSavingsPlanResponse(action.payload.savingsPlan);
            return {
                ...state,
                savingsPlan,
            };
        }
        case 'SAVE_INVEST_ACCOUNT_STATE': {
            if (typeof action.hasFunded === 'boolean') {
                return {
                    ...state,
                    hasFunded: action.hasFunded,
                };
            }
            return state;
        }
        case 'GET_POTS_SUCCESS':
            const sortedPots = [];
            const deletedPots = [];
            action.payload.pots.forEach((pot) => {
                if (pot.deletedAt) {
                    deletedPots.push(pot);
                }
                else if (pot.type === 'ACCUMULATED_INTEREST' || pot.type === 'ACCUMULATED_INTEREST_NOTICE') {
                    sortedPots.unshift(pot);
                }
                else {
                    sortedPots.push(pot);
                }
            });
            return {
                ...state,
                deletedPots,
                pots: sortedPots,
                walletBalance: action.payload.walletBalance,
                cashUnsettled: action.payload.cashUnsettled,
                cashWithdrawable: action.payload.cashWithdrawable,
                availableToInvest: action.payload.availableToInvest,
            };
        case 'CREATE_POT_SUCCESS':
            return {
                ...state,
                hasCreatedPot: true,
                pots: [...state.pots, action.payload.pot],
            };
        case 'UPDATE_POT_SUCCESS':
            return { ...state, pots: state.pots.map((pot) => (pot.id === action.payload.pot.id ? action.payload.pot : pot)) };
        case 'DELETE_POT_SUCCESS': {
            const { payload } = action;
            let deletedPot;
            const newState = {
                ...state,
                pots: state.pots.filter((pot) => {
                    if (pot.id === action.extra.potId) {
                        deletedPot = { ...pot, deletedAt: new Date().getTime() };
                    }
                    return pot.id !== action.extra.potId;
                }),
            };
            if (deletedPot) {
                newState.deletedPots = [...(state.deletedPots || []), deletedPot];
            }
            if (payload?.availableToInvest) {
                newState.availableToInvest = payload.availableToInvest;
            }
            if (payload?.cashWithdrawable) {
                newState.cashWithdrawable = payload.cashWithdrawable;
            }
            if (payload?.cashUnsettled) {
                newState.cashUnsettled = payload.cashUnsettled;
            }
            if (payload?.walletBalance) {
                newState.walletBalance = payload.walletBalance;
            }
            return newState;
        }
        case 'INTERNAL_TRANSFER_SUCCESS': {
            const { pots } = action.payload;
            const newState = { ...state };
            newState.pots = state.pots.map((existingPot) => {
                const updatedPot = pots.find((pot) => pot.id === existingPot.id);
                if (updatedPot) {
                    return updatedPot;
                }
                return existingPot;
            });
            return newState;
        }
        case 'DEPOSIT_POT_SUCCESS': {
            return {
                ...state,
                hasFundedPot: true,
                walletBalance: {
                    ...state.walletBalance,
                    ...action.payload.walletBalance,
                },
                availableToInvest: {
                    ...state.availableToInvest,
                    ...action.payload.availableToInvest,
                },
                cashUnsettled: {
                    ...state.cashUnsettled,
                    ...action.payload.cashUnsettled,
                },
                cashWithdrawable: {
                    ...state.cashWithdrawable,
                    ...action.payload.cashWithdrawable,
                },
                pots: state.pots.map((pot) => (pot.id === action.payload.pot.id ? action.payload.pot : pot)),
            };
        }
        case 'WITHDRAW_POT_SUCCESS': {
            return {
                ...state,
                walletBalance: {
                    ...state.walletBalance,
                    ...action.payload.walletBalance,
                },
                availableToInvest: {
                    ...state.availableToInvest,
                    ...action.payload.availableToInvest,
                },
                cashUnsettled: {
                    ...state.cashUnsettled,
                    ...action.payload.cashUnsettled,
                },
                cashWithdrawable: {
                    ...state.cashWithdrawable,
                    ...action.payload.cashWithdrawable,
                },
                pots: state.pots.map((pot) => (pot.id === action.payload.pot.id ? action.payload.pot : pot)),
            };
        }
        case 'GET_POTS_INFORMATION_SUCCESS': {
            const potProductTierRateMap = {};
            let interestPotsTierRateMap = {};
            let productIdWithTheHighestInterest;
            action.payload.products.forEach((potProduct) => {
                if (potProduct.type === 'INTEREST' || potProduct.type === 'INTEREST_NOTICE') {
                    const tierRateMap = {};
                    potProduct?.rates.tierRates.forEach((rateObj) => {
                        tierRateMap[rateObj.tier] = rateObj.rate.toFixed(2);
                    });
                    const currentProductUltimateRate = Number(tierRateMap[Tier.ultimate]);
                    const currentHighestUltimateRate = Number(interestPotsTierRateMap?.[Tier.ultimate]);
                    if (!currentHighestUltimateRate ||
                        (currentProductUltimateRate &&
                            currentProductUltimateRate > currentHighestUltimateRate &&
                            potProduct.isVisible)) {
                        interestPotsTierRateMap = tierRateMap;
                        productIdWithTheHighestInterest = potProduct.id;
                    }
                    potProductTierRateMap[potProduct.id] = tierRateMap;
                }
            });
            sortPotProducts(action.payload.products, potProductTierRateMap);
            return {
                ...state,
                interestPotsTierRateMap,
                productIdWithTheHighestInterest,
                products: action.payload.products,
                interestPotProductsTierRateMap: potProductTierRateMap,
            };
        }
        case 'HIDE_UPDATE_ACCOUNT_CARD_IN_POTS':
            return {
                ...state,
                hideUpdateAccountCardInPots: true,
            };
        default:
            return state;
    }
};
export default reducer;
