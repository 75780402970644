import getSymbolFromCurrency from 'currency-symbol-map';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import { View } from 'react-native';
import { rem, Spacer } from 'design-system';
import IconCircle from 'design-system/icons/IconCircle';
import Text from 'design-system/Text';
import IconTransactionCategory from 'features/transactions/icons/IconTransactionCategory';
import { selectIsScrambled } from 'hooks';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { useAppSelector } from 'store/hooks';
import createStyleSheets from 'utils/createStyleSheets';
import { formatAmount } from 'utils/formatting';
import TextInputMask from 'utils/packages/rnMaskedText';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import Logo from 'design-system/Logo';
import { isWeb } from '../../../constants';
const BudgetCategoryEditRow = ({ budget, onChange, hasAverages, withInitialFocus, }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const id = budget.key?.split('.')[1];
    const [value, setValue] = useState((budget.merchant ? budget.budgetLimit : budget.totalLimit) || 0);
    const [isFocused, setIsFocused] = useState(false);
    const scrambled = useAppSelector(selectIsScrambled);
    useEffect(() => {
        if (withInitialFocus) {
            const timeout = setTimeout(() => {
                textInputMaskRef.current?.getElement()?.focus();
            }, 350);
            return () => {
                clearTimeout(timeout);
            };
        }
        return () => { };
    }, [withInitialFocus]);
    const b = budget.merchant ? budget.budgetLimit : budget.totalLimit;
    useEffect(() => {
        if (b !== value) {
            setValue(b || 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [b]);
    const onChangeText = useCallback((value, rawValue) => {
        if (rawValue !== undefined) {
            onChange(budget.key || '', rawValue);
            setValue(rawValue);
        }
    }, [budget.key, onChange]);
    const textInputOptions = useMemo(() => ({
        unit: getSymbolFromCurrency(budget.currency),
        separator: '.',
        delimiter: ',',
        precision: 2,
    }), [budget.currency]);
    const textInputMaskRef = useRef();
    const renderAmountRow = useCallback(() => {
        if (budget.shouldRollover && budget.rollingAccumulatedLimit) {
            return (<Text TextThin-14 Gray>
          Rolling amount{' '}
          {formatAmount(budget.rollingAccumulatedLimit, budget.currency, 2, scrambled, true)}
        </Text>);
        }
        if (hasAverages) {
            return (<Text TextThin-14 Gray>
          3-months avg{' '}
          {formatAmount(budget.previousAverage, budget.currency, 2, scrambled, true)}
        </Text>);
        }
        return null;
    }, [
        budget.currency,
        budget.previousAverage,
        budget.rollingAccumulatedLimit,
        budget.shouldRollover,
        hasAverages,
        scrambled,
    ]);
    return (<TouchableHighlight onCard hoverOnLight activeOpacity={1} underlayColor={colors.background.underlay} onPress={() => {
            textInputMaskRef.current?.getElement()?.focus();
        }}>
      <View style={styles.container}>
        <IconCircle width={44} height={44}>
          {budget.merchant && budget.iconUrl ? (<Logo R44 uri={budget.iconUrl} resizeMode="contain"/>) : (<IconTransactionCategory category={{
                id: id,
                emoji: budget.emoji ?? undefined,
                color: budget.color ?? undefined,
            }}/>)}
        </IconCircle>
        <View style={styles.content}>
          <View style={styles.flex}>
            <Text Text-16>{budget.displayName}</Text>
            <Spacer h={rem(4)}/>
            {renderAmountRow()}
          </View>
          <TextInputMask selectionColor={colors.text.selection} ref={textInputMaskRef} keyboardType="number-pad" type="money" value={value} includeRawValueInChangeText underlineColorAndroid="transparent" onChangeText={onChangeText} options={textInputOptions} onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} returnKeyType="done" maxLength={12} style={[styles.textInput, isFocused && styles.focusedStyle]}/>
        </View>
      </View>
    </TouchableHighlight>);
};
const styleSet = createStyleSheets((colors) => ({
    flex: {
        flex: 1,
    },
    container: {
        padding: rem(16),
        flexDirection: 'row',
        alignItems: 'center',
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
    content: {
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        marginStart: rem(16),
    },
    textContainer: {
        flex: 1,
        marginRight: rem(8),
    },
    textInput: {
        fontFamily: 'Nunito-Bold',
        fontSize: rem(20),
        color: colors.text.primary,
        paddingLeft: rem(16),
        ...(isWeb && {
            width: rem(150),
            textAlign: 'right',
            backgroundColor: colors.background.dark,
        }),
    },
    focusedStyle: {
        color: colors.text.brand,
    },
}));
export default memo(BudgetCategoryEditRow);
