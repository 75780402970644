import React, { memo } from 'react';
import { StyleSheet, Platform } from 'react-native';
import FastImage from 'utils/packages/rnFastImage';
import normalize from './Normalize';
import checkSource from './imageSourceChecher';
const FastImageCommon = ({ url, imageStyle, onError, resizeMode = 'contain', }) => {
    const error = () => {
        if (typeof onError === 'function')
            onError();
    };
    const image = typeof url === 'string'
        ? {
            uri: url,
            cache: Platform.OS === 'ios' ? 'web' : 'immutable',
        }
        : url;
    return (<FastImage source={checkSource(image)} onError={error} style={[styles.image, imageStyle]} resizeMode={resizeMode}/>);
};
const styles = StyleSheet.create({
    image: {
        width: normalize(34.8),
        height: normalize(34.8),
        borderRadius: normalize(17.4),
    },
});
export default memo(FastImageCommon);
