import { useFocusEffect } from '@react-navigation/native';
import { useCallback } from 'react';
import { BackHandler } from 'react-native';
const useDisableAndroidBackButton = (callback) => {
    const onBackButtonPressAndroid = useCallback(() => {
        if (callback) {
            return callback();
        }
        return true;
    }, [callback]);
    const onFocus = useCallback(() => {
        const backHandler = BackHandler.addEventListener('hardwareBackPress', onBackButtonPressAndroid);
        return () => {
            backHandler.remove();
        };
    }, [onBackButtonPressAndroid]);
    useFocusEffect(onFocus);
};
export default useDisableAndroidBackButton;
