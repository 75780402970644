export const setTokens = (accessToken, refreshToken, expirationDate) => {
    if (accessToken) {
        window.localStorage.setItem('access_token', accessToken);
    }
    if (refreshToken) {
        window.localStorage.setItem('refresh_token', refreshToken);
    }
    if (expirationDate) {
        window.localStorage.setItem('expiration_date', expirationDate);
    }
};
export const removeTokens = () => {
    window.localStorage.removeItem('access_token');
    window.localStorage.removeItem('refresh_token');
};
export const getItems = (items) => items.map((item) => {
    const result = window.localStorage.getItem(item);
    return [item, result];
});
export const setClientId = (clientId) => window.localStorage.setItem('client_id', clientId);
export const getClientId = () => window.localStorage.getItem('client_id');
