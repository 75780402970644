import { REHYDRATE } from 'redux-persist';
import * as types from '../actions/types';
const initialState = {
    report: {},
    isFetching: false,
};
const reports = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE:
            const { reports } = action.payload || {};
            const returnState = JSON.parse(JSON.stringify(initialState));
            if (reports) {
                return Object.assign(returnState, reports, {
                    report: [],
                });
            }
            return state;
        case types.GET_REPORT_SUCCESS:
            return { ...state, report: action.payload, isFetching: false };
        case types.GET_REPORT_REQUEST:
            return { ...state, isFetching: true };
        case types.GET_REPORT_FAILURE:
            return { ...state, isFetching: false };
        case types.LOGGED_OUT: {
            return initialState;
        }
        default:
            return state;
    }
};
export default reports;
