import React, { memo, useMemo } from 'react';
import moment from 'moment';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import IconArrowTriangle from 'design-system/icons/IconArrowTriangle';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableOpacity from 'utils/packages/TouchableOpacity';
const TransactionDateButton = ({ transaction, onPressDate, }) => {
    const pressDate = () => {
        if (onPressDate) {
            onPressDate();
        }
    };
    const date = useMemo(() => {
        if (!transaction)
            return null;
        const { bookingDate, customDate } = transaction;
        const dateToDisplay = customDate || bookingDate;
        const showYear = moment(dateToDisplay).year() !== moment().year();
        return moment(dateToDisplay).format(showYear ? 'ddd, MMM D YYYY' : 'ddd, MMM D');
    }, [transaction]);
    const colors = useColors();
    const styles = useStyles(styleSet);
    if (!date)
        return null;
    return (<TouchableOpacity hoverOnDark onPress={pressDate} style={styles.dateButton}>
      <Text Text-14>{date}</Text>
      <IconArrowTriangle style={styles.arrowDown} color={colors.elements.primary}/>
    </TouchableOpacity>);
};
export const HEIGHT = 32;
const styleSet = createStyleSheets((colors) => ({
    dateButton: {
        height: rem(32),
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: rem(16),
        paddingRight: rem(12.83),
        backgroundColor: colors.cards.onDark,
        borderRadius: rem(8),
    },
    web: {
        paddingRight: rem(16),
    },
    arrowDown: {
        marginStart: rem(8),
    },
}));
export default memo(TransactionDateButton);
