import React, { memo, useEffect, useRef, useState } from 'react';
import { Animated, Easing, StyleSheet } from 'react-native';
import { hitSlop } from 'design-system/values';
import { IconDoubleRotateBold } from 'design-system/icons/IconDoubleRotateBold';
import { useAppSelector } from 'store/hooks';
import useForceSync from 'hooks/useForceSync';
import { selectIsSyncingManual } from 'reducers/selectors';
import TouchableOpacity from 'utils/packages/TouchableOpacity';
import { isWeb } from '../../../constants';
const AccountsTopSync = ({ style, color }) => {
    const [spinValue] = useState(new Animated.Value(0));
    const isSyncingManual = useAppSelector(selectIsSyncingManual);
    const [isFetchingSyncStatus, forceSync] = useForceSync();
    const isSpinning = isFetchingSyncStatus || isSyncingManual;
    const spinAnim = useRef(Animated.timing(spinValue, {
        toValue: 1,
        duration: 1000,
        easing: Easing.linear,
        useNativeDriver: true,
        isInteraction: false,
    }));
    const spin = () => {
        spinValue.setValue(0);
        spinAnim.current.start(({ finished }) => {
            if (isSpinning && finished) {
                spin();
            }
        });
    };
    useEffect(() => {
        if (isSpinning) {
            spin();
        }
        else {
            spinValue.setValue(0);
            spinAnim.current.reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSpinning]);
    const pressed = () => {
        forceSync();
    };
    const rotate = spinValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '360deg'],
    });
    return (<TouchableOpacity hoverOnDark onPress={pressed} hitSlop={!isWeb ? hitSlop : undefined} style={[style, isWeb && styles.navButton]}>
      <Animated.View style={[{ transform: [{ rotate }] }]}>
        <IconDoubleRotateBold color={color}/>
      </Animated.View>
    </TouchableOpacity>);
};
const styles = StyleSheet.create({
    navButton: {
        width: 40,
        height: 40,
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
export default memo(AccountsTopSync);
