import React from 'react';
import { StyleSheet, View } from 'react-native';
import { rem } from 'design-system/values';
import { Button } from 'design-system/Button';
import { ProgressBar } from 'design-system/ProgressBar';
import Amount from 'design-system/Amount';
import Text from 'design-system/Text';
import Squircle from 'design-system/Squircle';
import IconOtherMerchant from 'features/analytics/icons/IconOtherMerchant';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import TransactionLogo from 'features/transactions/components/TransactionLogo';
import { selectIsScrambled } from 'hooks';
import { useCategoryColors } from 'hooks/useCategory';
import useColors from 'hooks/useColors';
import { useAppSelector } from 'store/hooks';
import { formatAmount } from 'utils/formatting';
import { interpolate } from 'utils/interpolations';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import { isWeb } from '../../../constants';
const MerchantBudgetRow = ({ onPress, item }) => {
    const colors = useColors();
    const barColor = useCategoryColors(item);
    const scrambled = useAppSelector(selectIsScrambled);
    const positive = item.total > 0;
    const leftOfBudget = (item?.budgetLimit ?? 0) - Math.abs(item.total);
    const percentage = interpolate(positive ? 0 : Math.abs(item.total), {
        inputRange: [0, item?.budgetLimit ?? 0],
        outputRange: [0, 100],
    });
    const handlePress = () => {
        onPress(item);
    };
    const { isUnlockedForSpace, onPressUpgrade } = useShouldShowUpgrade({
        benefitId: 'merchantBudgets',
    });
    return (<TouchableHighlight onCard hoverOnLight style={styles.container} onPress={handlePress} underlayColor={colors.background.underlay}>
      <View style={styles.content}>
        {item.id === -1 ? (<Squircle color={colors.gradients.ultimate.colors[0]}>
            <IconOtherMerchant />
          </Squircle>) : (<TransactionLogo R44 transaction={{ category: item.category, merchant: item }}/>)}
        {isUnlockedForSpace ? (<View style={styles.innerContent}>
            <View style={styles.titleContainer}>
              <Text Text-16 style={styles.displayName}>
                {item.displayName}
              </Text>
              <Amount Numbers-16 value={{
                value: item.total,
                currency: item.currency,
            }} showFraction/>
            </View>
            {Boolean(item.budgetLimit) && (<Text TextThin-14 Gray style={styles.subTitle}>
                {formatAmount(positive ? item.budgetLimit : Math.abs(leftOfBudget), item.currency, 2, scrambled, true)}
                {leftOfBudget >= 0 || positive
                    ? ' left of'
                    : ' over your budget of'}{' '}
                {formatAmount(item.budgetLimit, item.currency, 2, scrambled, true)}
              </Text>)}
            <ProgressBar position={percentage} colors={barColor}/>
          </View>) : (<View style={styles.innerContentUpgrade}>
            <Text Text-16 style={styles.displayNameUpgrade}>
              {item.displayName}
            </Text>
            <Button title="Upgrade" small square hug brandReversed onPress={onPressUpgrade}/>
          </View>)}
      </View>
    </TouchableHighlight>);
};
const styles = StyleSheet.create({
    container: {
        padding: rem(16),
        justifyContent: 'center',
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
    content: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    innerContent: {
        flex: 1,
        marginStart: rem(16),
        flexDirection: 'column',
    },
    innerContentUpgrade: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginStart: rem(16),
    },
    displayNameUpgrade: {
        flexShrink: 1,
        paddingEnd: rem(12),
    },
    titleContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flex: 1,
    },
    displayName: {
        marginBottom: rem(4),
        flexShrink: 1,
        paddingEnd: rem(12),
    },
    subTitle: {
        marginBottom: rem(8),
    },
});
export default MerchantBudgetRow;
