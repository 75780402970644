import { REHYDRATE } from 'redux-persist';
import * as types from '../actions/types';
const initialState = {
    enquiries: [],
    energyResults: [],
    personalProjection: {},
    isFetching: false,
    isFetchingResults: false,
};
const switching = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE:
            const switchPayload = action.payload?.switching;
            if (switchPayload)
                return { ...initialState };
            return state;
        case types.GET_SWITCHING_QUOTES_REQUEST:
            return { ...state, isFetching: true };
        case types.GET_SWITCHING_QUOTES_SUCCESS:
            return { ...state, isFetching: false };
        case types.GET_ENERGY_SWITCHING_RESULTS_SUCCESS:
            if (action &&
                action.payload &&
                'type' in action.payload &&
                action.payload.type === 'BROADBAND')
                return { ...state, enquiries: action.payload.data.results };
            return { ...state, energyResults: action.payload };
        case types.GET_SWITCHING_RESULTS_REQUEST:
            return { ...state, isFetchingResults: true };
        case types.GET_SWITCHING_RESULTS_SUCCESS:
            return {
                ...state,
                enquiries: action.payload.data.results,
                personalProjection: action.payload.data.personalProjection,
                isFetchingResults: false,
            };
        case types.LOGGED_OUT: {
            return initialState;
        }
        default:
            return state;
    }
};
export default switching;
