import qs from 'query-string';
import { InteractionManager } from 'react-native';
import { getSubscriptions } from 'features/subscriptions/actions';
import { getBudgets } from 'features/budgeting/actions';
import { CALL_API } from '../middleware/api';
import * as types from './types';
export const forceRefreshAnalytics = (includeTotals) => ({
    type: types.FORCE_REFRESH_ANALYTICS,
    payload: {
        includeTotals,
    },
});
export const budgetingPeriodChanged = () => ({
    type: types.BUDGETING_PERIOD_CHANGED,
});
export const deleteCategory = (id) => ({
    [CALL_API]: {
        endpoint: `/categories/${id}`,
        method: 'DELETE',
        addSpaceIdHeader: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.DELETE_CATEGORY_REQUEST,
            types.DELETE_CATEGORY_SUCCESS,
            types.DELETE_CATEGORY_FAILURE,
        ],
    },
});
export const updateCategory = (id, body) => ({
    [CALL_API]: {
        endpoint: `/categories/${id}`,
        method: 'PATCH',
        addSpaceIdHeader: true,
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.UPDATE_CATEGORY_REQUEST,
            types.UPDATE_CATEGORY_SUCCESS,
            types.UPDATE_CATEGORY_FAILURE,
        ],
    },
});
export const createCategory = (body, currency) => ({
    [CALL_API]: {
        endpoint: '/categories',
        method: 'POST',
        addSpaceIdHeader: true,
        body: JSON.stringify(body),
        extra: {
            currency,
        },
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.CREATE_CATEGORY_REQUEST,
            types.CREATE_CATEGORY_SUCCESS,
            types.CREATE_CATEGORY_FAILURE,
        ],
    },
});
export const getBankFees = (from, to) => {
    let range = '';
    if (from && to)
        range = `&from=${from}&to=${to}`;
    return {
        [CALL_API]: {
            endpoint: `/bank-fees?${range}&includeTransactions=true`,
            method: 'GET',
            types: [
                types.GET_BANK_FEES_REQUEST,
                types.GET_BANK_FEES_SUCCESS,
                types.GET_BANK_FEES_FAILURE,
            ],
        },
    };
};
export const refreshApp = () => async (dispatch) => {
    InteractionManager.runAfterInteractions(() => {
        dispatch(getBudgets());
        dispatch(getSubscriptions());
    });
};
export const getMonthlyTotals = ({ dateFrom, dateTo, isPayDay, step, reset, firstItem, accountIds, }) => {
    let apiStep = isPayDay ? 'payperiod' : 'month';
    if (step)
        apiStep = step;
    return {
        [CALL_API]: {
            endpoint: `/analytics/totals/?withSpendingBreakdown=true&step=${apiStep}&dateFrom=${dateFrom}&dateTo=${dateTo}&${stringiyAccountIds(accountIds)}`,
            method: 'GET',
            addSpaceIdHeader: true,
            extra: {
                fromTo: `${dateFrom}|${dateTo}`,
                step,
                reset,
                firstItem,
                accountIds,
            },
            types: [
                types.GET_MONTHLY_TOTALS_REQUEST,
                types.GET_MONTHLY_TOTALS_SUCCESS,
                types.GET_MONTHLY_TOTALS_FAILURE,
            ],
        },
    };
};
export const getCategoryTotals = ({ dateFrom, dateTo, categoryId, merchantId, isPayDay, step, reset, accountIds, }) => (dispatch) => {
    const idString = categoryId
        ? `&categoryId=${categoryId}`
        : `&merchantId=${merchantId}`;
    let apiStep = isPayDay ? 'payperiod' : 'month';
    if (step)
        apiStep = step;
    return dispatch({
        [CALL_API]: {
            endpoint: `/analytics/totals/?step=${apiStep}&dateFrom=${dateFrom}&dateTo=${dateTo}${idString}&${stringiyAccountIds(accountIds)}`,
            method: 'GET',
            addSpaceIdHeader: true,
            extra: { from: dateFrom, to: dateTo, step, reset },
            types: [
                types.GET_CATEGORY_TOTALS_REQUEST,
                types.GET_CATEGORY_TOTALS_SUCCESS,
                types.GET_CATEGORY_TOTALS_FAILURE,
            ],
        },
    });
};
export const getMonthlyMerchants = (from, to, accountIds) => ({
    [CALL_API]: {
        endpoint: `/analytics/merchants/?dateFrom=${from}&dateTo=${to}&${stringiyAccountIds(accountIds)}`,
        method: 'GET',
        addSpaceIdHeader: true,
        extra: `${from}|${to}`,
        types: [
            types.MONTHLY_MERCHANTS_REQUEST,
            types.MONTHLY_MERCHANTS_SUCCESS,
            types.MONTHLY_MERCHANTS_FAILURE,
        ],
    },
});
const stringiyAccountIds = (accountIds) => {
    if (accountIds === undefined)
        return '';
    if (accountIds.length === 0) {
        return 'accountIds[]=';
    }
    return qs.stringify({ accountIds }).replace(/accountIds=/g, 'accountIds[]=');
};
export const getMonthlyCategories = (from, to, accountIds) => ({
    [CALL_API]: {
        endpoint: `/analytics/categories/?dateFrom=${from}&dateTo=${to}&${stringiyAccountIds(accountIds)}`,
        method: 'GET',
        addSpaceIdHeader: true,
        extra: `${from}|${to}`,
        types: [
            types.MONTHLY_CATEGORIES_REQUEST,
            types.MONTHLY_CATEGORIES_SUCCESS,
            types.MONTHLY_CATEGORIES_FAILURE,
        ],
    },
});
export const getMerchantExpenses = (from, to, merchantId, accountIds) => ({
    [CALL_API]: {
        endpoint: `/transactions/?dateFrom=${from}&dateTo=${to}&merchantId=${merchantId}&withoutInternal=true&${stringiyAccountIds(accountIds)}`,
        method: 'GET',
        addSpaceIdHeader: true,
        extra: from,
        types: [
            types.GET_MERCHANT_EXPENSES_REQUEST,
            types.GET_MERCHANT_EXPENSES_SUCCESS,
            types.GET_MERCHANT_EXPENSES_FAILURE,
        ],
    },
});
export const getCategoryExpenses = (from, to, categoryId, accountIds) => ({
    [CALL_API]: {
        endpoint: `/transactions/?dateFrom=${from}&dateTo=${to}&categoryId=${categoryId}&${stringiyAccountIds(accountIds)}`,
        method: 'GET',
        addSpaceIdHeader: true,
        extra: from,
        types: [
            types.GET_CATEGORY_EXPENSES_REQUEST,
            types.GET_CATEGORY_EXPENSES_SUCCESS,
            types.GET_CATEGORY_EXPENSES_FAILURE,
        ],
    },
});
export const isFetchingCategoryExpenses = () => ({
    type: types.IS_FETCHING_CATEGORY_EXPENSES,
});
export const isFetchingTotals = () => ({
    type: types.IS_FETCHING_TOTALS,
});
export const setBudgetingPosition = (data) => ({
    type: types.SET_BUDGETING_POSITION,
    payload: data,
});
export const resetAnalyticsFilter = () => ({
    type: types.RESET_ANALYTICS_FILTER,
});
export const setAnalyticsPosition = (position, firstItem, resetFilter) => ({
    type: types.SET_ANALYTICS_POSITION,
    payload: {
        position,
        firstItem,
        resetFilter,
    },
});
export const fetchAnalyticsBudgetingData = () => ({
    type: types.FETCH_ANALYTICS_BUDGETING_DATA,
});
export const fetchCategoriesMerchants = () => ({
    type: types.FETCH_CATEGORIES_MERCHANTS,
});
export const fetchMoreAnalyticsBudgetingData = () => ({
    type: types.FETCH_MORE_ANALYTICS_BUDGETING_DATA,
});
export const filterChanged = (step, from, to, accountIds) => ({
    type: types.ANALYTICS_FILTER_CHANGED,
    payload: {
        step,
        from,
        to,
        accountIds,
    },
});
