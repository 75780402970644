import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
const IconTag = (props) => {
    const id = useId();
    return (<IconWithGradient viewBox="0 0 24 24" id={id} width={24} height={24} defaultGradientKey="peach" {...props}>
      <Path fill={`url(#gradient_${id})`} d="M7.864 20.583c.66.66 1.73.66 2.39 0l8.18-8.181c.236-.235.416-.515.534-.814.822.23 1.622.353 2.326.348 1.744-.009 2.322-.772 2.512-1.229.19-.451.325-1.4-.899-2.647l-.04-.04c-.76-.76-1.862-1.46-3.113-1.98-.23-.094-.46-.18-.687-.261v-.014a1.85 1.85 0 0 0-1.825-1.825l-4.902-.068a2.384 2.384 0 0 0-1.73.705l-8.186 8.181a1.69 1.69 0 0 0 0 2.39l5.44 5.435ZM14.17 6.8a1.399 1.399 0 0 1 2.372 1.192c.537.38 1.179.732 1.866 1.017.244.1.479.19.704.266l-.027-1.97c.041.019.077.032.118.05 1.07.448 2.033 1.053 2.652 1.672l.027.027c.587.6.65.985.6 1.102-.153.366-1.802.691-4.305-.352a9.665 9.665 0 0 1-1.974-1.098c-.018.023-.04.045-.059.068a1.399 1.399 0 0 1-1.978 0A1.397 1.397 0 0 1 14.17 6.8Z"/>
    </IconWithGradient>);
};
export default IconTag;
