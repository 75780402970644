import * as types from 'actions/types';
const initialState = {
    cache: {},
};
const sharedBills = (state = initialState, action) => {
    switch (action.type) {
        case types.CACHE_SHARED_BILL_INFO: {
            const { payload: { id, users }, } = action;
            const sharedBillCache = { users };
            return { ...state, cache: { ...state.cache, [id]: sharedBillCache } };
        }
        case types.CLEAR_SHARED_BILL_INFO_CACHE: {
            const { payload: { id }, } = action;
            const updatedCache = { ...state.cache };
            delete updatedCache[id];
            return { ...state, cache: updatedCache };
        }
        case types.LOGGED_OUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
export default sharedBills;
