// this basically just returns source, but with an empty uri if it's not a string
// if uri is something like an object the app crashes
const checkSource = (source) => {
    if (typeof source === 'number')
        return source; // if it's an image require, there's nothing to check
    let uri = '';
    if (source &&
        source.uri &&
        typeof source.uri === 'string' &&
        source.uri.length > 7 // no need for a full link check
    ) {
        uri = source.uri;
    }
    return {
        ...source,
        uri,
    };
};
export default checkSource;
