import { CALL_API } from '../../../middleware/api';
import { GET_QUESTS_FAILURE, GET_QUESTS_REQUEST, GET_QUESTS_SUCCESS, POST_LOG_FAILURE, POST_LOG_REQUEST, POST_LOG_SUCCESS, SET_QUEST_VIEWED_FAILURE, SET_QUEST_VIEWED_REQUEST, SET_QUEST_VIEWED_SUCCESS, } from './types';
export const getQuests = () => ({
    [CALL_API]: {
        endpoint: '/quests',
        method: 'GET',
        types: [GET_QUESTS_REQUEST, GET_QUESTS_SUCCESS, GET_QUESTS_FAILURE],
    },
});
export const logQuest = (type) => ({
    [CALL_API]: {
        endpoint: `/events/${type}`,
        method: 'POST',
        types: [POST_LOG_REQUEST, POST_LOG_SUCCESS, POST_LOG_FAILURE],
        extra: type,
        muteAlert: true,
    },
});
export const setQuestViewed = (type) => ({
    [CALL_API]: {
        endpoint: `/quests/${type}/viewed`,
        method: 'POST',
        types: [
            SET_QUEST_VIEWED_REQUEST,
            SET_QUEST_VIEWED_SUCCESS,
            SET_QUEST_VIEWED_FAILURE,
        ],
    },
});
