import useColors from 'hooks/useColors';
import React from 'react';
import { View, StyleSheet, } from 'react-native';
import { rem } from './values';
export const ModalHandle = ({ style, color }) => {
    const colors = useColors();
    return (<View style={style ?? styles.container}>
      <View style={[
            styles.handle,
            { backgroundColor: color ?? colors.borders.strongDivider },
        ]}/>
    </View>);
};
const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        top: rem(12),
        left: 0,
        right: 0,
    },
    handle: {
        width: rem(48),
        height: rem(4),
        alignSelf: 'center',
        borderRadius: rem(2),
    },
});
export default ModalHandle;
