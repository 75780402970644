import { useMemo } from 'react';
import useColorScheme from './useColorScheme';
const useStyles = function useStyles(styleSet, lightModeOnly) {
    const colorScheme = useColorScheme();
    const styles = useMemo(() => (colorScheme === 'dark' ? styleSet.night : styleSet.day), [colorScheme, styleSet]);
    if (lightModeOnly) {
        return styleSet.day;
    }
    return styles;
};
/**
 * WARNING: This function doesn't perform any feature flag logic and should only be used inside of navigationOptions callback.
 * @param styleSet The styles generated by `createStyleSheets` utility
 * @param colorScheme The `theme` argument from navigation options
 * @returns Correct styles for set color scheme.
 */
export function getStylesForTheme(styleSet, colorScheme) {
    return colorScheme === 'dark' ? styleSet.night : styleSet.day;
}
export default useStyles;
