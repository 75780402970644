import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconEyeSlash = ({ ...props }) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="green">
      <Path fillRule="evenodd" clipRule="evenodd" d="M19.7455 5.32169L5.85977 19.2578C5.5585 19.5601 5.07006 19.5601 4.7688 19.2578C4.46754 18.9554 4.46754 18.4652 4.7688 18.1628L5.51323 17.4157C4.91868 16.8439 4.38692 16.1785 3.93799 15.4208C2.68734 13.3099 2.68734 10.6907 3.93799 8.5799C6.82254 3.7114 13.0431 2.73298 17.2796 5.60666L18.6545 4.22677C18.9558 3.92441 19.4442 3.92441 19.7455 4.22677C20.0467 4.52912 20.0467 5.01933 19.7455 5.32169ZM13.0857 9.81572C12.7578 9.65515 12.3894 9.56526 11.9995 9.56526C10.6298 9.56526 9.5196 10.6792 9.5196 12.0552C9.5196 12.4464 9.60908 12.8158 9.76887 13.1446L13.0857 9.81572Z" fill={`url(#gradient_${id})`}/>
      <Path d="M19.0047 8.31014C19.3709 8.09159 19.8443 8.21237 20.062 8.57989C21.3127 10.6907 21.3127 13.3099 20.062 15.4208C17.6482 19.4948 12.8946 20.8419 8.93695 19.4961C8.53343 19.3589 8.31715 18.9193 8.45387 18.5144C8.59059 18.1094 9.02854 17.8923 9.43206 18.0295C12.7646 19.1628 16.7281 18.018 18.7359 14.6293C19.6976 13.0063 19.6976 10.9944 18.7359 9.37131C18.5181 9.00379 18.6385 8.52869 19.0047 8.31014Z" fill={`url(#gradient_${id})`}/>
      <Path d="M15.2518 11.2809C15.6779 11.2809 16.0233 11.6276 16.0233 12.0552C16.0233 14.2855 14.2211 16.0925 11.9995 16.0925C11.5734 16.0925 11.2281 15.7459 11.2281 15.3183C11.2281 14.8907 11.5734 14.544 11.9995 14.544C13.3696 14.544 14.4804 13.4296 14.4804 12.0552C14.4804 11.6276 14.8258 11.2809 15.2518 11.2809Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default IconEyeSlash;
