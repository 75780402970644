import _ from 'lodash';
import { LOGGED_OUT } from 'actions/types';
import { sortAccordingToTimestamp } from 'utils/sort';
import { filterActivities } from '../utils';
import { SellOrderType, } from '../types';
import { getAnalystDetails, getParsedAnalystData } from '../utils/format';
import { ALL_STOCKS_KEY } from '../constants';
export const initialState = {
    filledDayTrades: undefined,
    pendingDayTrades: undefined,
    recurringBuys: [],
    recurringBuysForAssetId: {},
    historyForRecurringBuyId: {},
    pendingStockAlerts: {},
    isErrorInFetchingInvestAcc: undefined,
};
const unpersistedInvestReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ORDERS_REQUEST': {
            // case for limit/stop orders
            if (action.extra.orderTypes.includes(SellOrderType.LIMIT) ||
                action.extra.orderTypes.includes(SellOrderType.STOP)) {
                // case for portfolio list
                if (!action.extra.assetIds?.length) {
                    return {
                        ...state,
                        requestingLimitStopOrders: true,
                        requestingLimitStopOrdersPaginationToken: action.extra.paging?.token,
                    };
                }
                // case for list for a stock
                return {
                    ...state,
                    requestingLimitStopOrdersForAssetId: true,
                    requestingLimitStopOrdersAssetId: action.extra.assetIds[0],
                    requestingLimitStopOrdersPaginationTokenForAssetId: action.extra.paging?.token,
                };
            }
            // case for portfolio list
            if (!action.extra.assetIds?.length) {
                return {
                    ...state,
                    requestingMarketOrders: true,
                    requestingMarketOrdersPaginationToken: action.extra.paging?.token,
                };
            }
            // case for list for a stock
            return {
                ...state,
                requestingMarketOrdersForAssetId: true,
                requestingMarketOrdersAssetId: action.extra.assetIds[0],
                requestingMarketOrdersPaginationTokenForAssetId: action.extra.paging?.token,
            };
        }
        case 'GET_ORDERS_SUCCESS': {
            // case for limit/stop orders
            if (action.extra.orderTypes.includes(SellOrderType.LIMIT) ||
                action.extra.orderTypes.includes(SellOrderType.STOP)) {
                // case for portfolio list
                if (!action.extra.assetIds?.length) {
                    return {
                        ...state,
                        requestingLimitStopOrders: false,
                        requestingLimitStopOrdersPaginationToken: action.payload.paging.next,
                    };
                }
                // case for list for a stock
                return {
                    ...state,
                    requestingLimitStopOrdersForAssetId: false,
                    requestingLimitStopOrdersPaginationTokenForAssetId: action.payload.paging.next,
                };
            }
            // case for portfolio list
            if (!action.extra.assetIds?.length) {
                return {
                    ...state,
                    requestingMarketOrders: false,
                    requestingMarketOrdersPaginationToken: action.payload.paging.next,
                };
            }
            // case for list for a stock
            return {
                ...state,
                requestingMarketOrdersForAssetId: false,
                requestingMarketOrdersPaginationTokenForAssetId: action.payload.paging.next,
            };
        }
        case 'GET_ORDERS_FAILURE': {
            // case for limit/stop orders
            if (action.extra.orderTypes.includes(SellOrderType.LIMIT) ||
                action.extra.orderTypes.includes(SellOrderType.STOP)) {
                // case for portfolio list
                if (!action.extra.assetIds?.length) {
                    return {
                        ...state,
                        requestingLimitStopOrders: false,
                    };
                }
                // case for list for a stock
                return {
                    ...state,
                    requestingLimitStopOrdersForAssetId: false,
                };
            }
            // case for portfolio list
            if (!action.extra.assetIds?.length) {
                return {
                    ...state,
                    requestingMarketOrders: false,
                };
            }
            // case for list for a stock
            return {
                ...state,
                requestingMarketOrdersForAssetId: false,
            };
        }
        case 'INVEST_GET_ANALYST_INFO_SUCCESS_ACTION': {
            const analysisInfo = getParsedAnalystData(action.payload.analystInfo);
            if (analysisInfo) {
                return {
                    ...state,
                    analystInfoMap: {
                        ...state.analystInfoMap,
                        [action.extra.symbol]: analysisInfo,
                    },
                };
            }
            return state;
        }
        case 'INVEST_GET_TRIGGERED_STOCK_ALERTS_REQUEST_ACTION': {
            return {
                ...state,
                requestingTriggeredStockAlerts: true,
                triggeredStockAlertsPaginationAssetId: action.extra.assetId,
                triggeredStockAlertsPaginationToken: action.extra.paginationToken,
            };
        }
        case 'INVEST_GET_TRIGGERED_STOCK_ALERTS_SUCCESS_ACTION': {
            if (state.triggeredStockAlertsPaginationAssetId === action.extra.assetId) {
                return {
                    ...state,
                    triggeredStockAlerts: {
                        ...state.triggeredStockAlerts,
                        [action.extra.assetId]: action.payload.paging.page === 1
                            ? action.payload.alerts
                            : [...(state.triggeredStockAlerts?.[action.extra.assetId] || []), ...action.payload.alerts],
                    },
                    triggeredStockAlertsPaginationToken: action.payload.paging?.next,
                    requestingTriggeredStockAlerts: false,
                };
            }
            return state;
        }
        case 'INVEST_GET_TRIGGERED_STOCK_ALERTS_FAILURE_ACTION': {
            if (action.extra.assetId === state.triggeredStockAlertsPaginationAssetId) {
                return {
                    ...state,
                    requestingTriggeredStockAlerts: false,
                };
            }
            return state;
        }
        case 'INVEST_GET_STOCK_ALERTS_REQUEST_ACTION': {
            if (action.extra.assetId === ALL_STOCKS_KEY) {
                return {
                    ...state,
                    requestingAllPendingStockAlerts: true,
                    allPendingStockAlertsPaginationToken: action.extra.paginationToken,
                };
            }
            return {
                ...state,
                requestingPendingStockAlerts: true,
                pendingStockAlertsPaginationAssetId: action.extra.assetId,
                pendingStockAlertsPaginationToken: action.extra.paginationToken,
            };
        }
        case 'INVEST_GET_STOCK_ALERTS_FAILURE_ACTION': {
            if (action.extra.assetId === ALL_STOCKS_KEY) {
                return {
                    ...state,
                    requestingAllPendingStockAlerts: false,
                };
            }
            if (action.extra.assetId === state.pendingStockAlertsPaginationAssetId) {
                return {
                    ...state,
                    requestingPendingStockAlerts: false,
                };
            }
            return state;
        }
        case 'INVEST_GET_STOCK_ALERTS_SUCCESS_ACTION': {
            if (action.extra.assetId === ALL_STOCKS_KEY) {
                return {
                    ...state,
                    pendingStockAlerts: {
                        ...state.pendingStockAlerts,
                        [action.extra.assetId]: action.payload.paging.page === 1
                            ? action.payload.alerts
                            : [...(state.pendingStockAlerts?.[action.extra.assetId] || []), ...action.payload.alerts],
                    },
                    requestingAllPendingStockAlerts: false,
                    allPendingStockAlertsPaginationToken: action.payload.paging?.next,
                };
            }
            if (state.pendingStockAlertsPaginationAssetId === action.extra.assetId) {
                return {
                    ...state,
                    pendingStockAlerts: {
                        ...state.pendingStockAlerts,
                        [action.extra.assetId]: action.payload.paging.page === 1
                            ? action.payload.alerts
                            : [...(state.pendingStockAlerts?.[action.extra.assetId] || []), ...action.payload.alerts],
                    },
                    requestingPendingStockAlerts: false,
                    pendingStockAlertsPaginationToken: action.payload.paging?.next,
                };
            }
            return state;
        }
        case 'INVEST_CREATE_STOCK_ALERT_SUCCESS_ACTION': {
            return {
                ...state,
                pendingStockAlerts: {
                    ...state.pendingStockAlerts,
                    [action.extra.assetId]: [action.payload.alert, ...(state.pendingStockAlerts?.[action.extra.assetId] || [])],
                    ALL_STOCKS: [action.payload.alert, ...(state.pendingStockAlerts?.[ALL_STOCKS_KEY] || [])],
                },
            };
        }
        case 'INVEST_DELETE_STOCK_ALERT_SUCCESS': {
            const newAllStocksArr = state.pendingStockAlerts?.[ALL_STOCKS_KEY]?.filter((stockAlert) => stockAlert.alertId !== action.extra.alertId);
            const assetIdArr = state.pendingStockAlerts?.[action.extra.assetId]?.filter((stockAlert) => stockAlert.alertId !== action.extra.alertId);
            return {
                ...state,
                pendingStockAlerts: {
                    ...state.pendingStockAlerts,
                    ALL_STOCKS: newAllStocksArr,
                    [action.extra.assetId]: assetIdArr,
                },
            };
        }
        case 'GET_ANALYST_RATINGS_DETAILS_SUCCESS_ACTION': {
            const analystDetails = getAnalystDetails(action.payload.analystInfo);
            return {
                ...state,
                analystInfoDetailsMap: {
                    ...state.analystInfoDetailsMap,
                    [action.payload.symbol]: analystDetails,
                },
            };
        }
        case 'CACHE_ACCOUNTS':
        case 'CACHE_TRADING_ACCOUNT':
        case 'GET_TRADING_ACCOUNT_SUCCESS': {
            return {
                ...state,
                isErrorInFetchingInvestAcc: false,
            };
        }
        case 'GET_PATTERN_DAY_TRADING_INFO_SUCCESS': {
            return {
                ...state,
                filledDayTrades: action.payload.filledDayTrades,
                pendingDayTrades: action.payload.pendingDayTrades,
            };
        }
        case 'GET_PATTERN_DAY_TRADING_INFO_FAILURE': {
            return {
                ...state,
                filledDayTrades: undefined,
                pendingDayTrades: undefined,
            };
        }
        case 'GET_TRADING_ACCOUNT_FAILURE': {
            return {
                ...state,
                isErrorInFetchingInvestAcc: true,
            };
        }
        case 'GET_RECURRING_BUYS_SUCCESS': {
            if (action.extra) {
                return {
                    ...state,
                    recurringBuysForAssetId: {
                        ...state.recurringBuysForAssetId,
                        [action.extra]: action.payload.recurringBuys,
                    },
                };
            }
            return {
                ...state,
                recurringBuys: action.payload.recurringBuys,
            };
        }
        case 'DELETE_RECURRING_BUY_SUCCESS': {
            const { id, assetId } = action.extra;
            return {
                ...state,
                recurringBuys: state.recurringBuys?.filter((recurringBuy) => recurringBuy.id !== id),
                recurringBuysForAssetId: {
                    ...state.recurringBuysForAssetId,
                    [assetId]: state.recurringBuysForAssetId?.[assetId]?.filter((recurringBuy) => recurringBuy.id !== id) || [],
                },
            };
        }
        case 'UPDATE_RECURRING_BUY_SUCCESS': {
            const { recurringBuy } = action.payload;
            return {
                ...state,
                recurringBuys: state.recurringBuys?.map((oldRecurringBuy) => {
                    if (oldRecurringBuy.id === recurringBuy.id) {
                        return recurringBuy;
                    }
                    return oldRecurringBuy;
                }),
                recurringBuysForAssetId: {
                    ...state.recurringBuysForAssetId,
                    [recurringBuy.assetId]: state.recurringBuysForAssetId?.[recurringBuy.assetId]?.map((oldRecurringBuy) => {
                        if (oldRecurringBuy.id === recurringBuy.id) {
                            return recurringBuy;
                        }
                        return oldRecurringBuy;
                    }) || [],
                },
            };
        }
        case 'GET_RECURRING_BUY_ACTIVITIES_SUCCESS': {
            const { id } = action.extra;
            const { activities, paging } = action.payload;
            const filteredActivities = activities.filter(filterActivities);
            if (paging.page === 1) {
                return {
                    ...state,
                    historyForRecurringBuyId: {
                        ...state.historyForRecurringBuyId,
                        [id]: filteredActivities,
                    },
                };
            }
            return {
                ...state,
                historyForRecurringBuyId: {
                    ...state.historyForRecurringBuyId,
                    [id]: _.uniqBy(state.historyForRecurringBuyId?.[id]?.concat(filteredActivities) ?? filteredActivities, (i) => i.id).sort(sortAccordingToTimestamp),
                },
            };
        }
        case LOGGED_OUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
export default unpersistedInvestReducer;
