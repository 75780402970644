import { useCallback } from 'react';
import { getPaymentLimits } from 'features/pay/actions/paymentLimits';
import { useAppDispatch, useAppSelector, useAppStore } from 'store/hooks';
import useFocusEffect from './useFocusEffect';
/**
 * Use this hook when you need to show transfer limits, based on tier.
 *
 * @returns Specification of how many pounds you can transfer via transfers feature, for each tier.
 */
function useTierLimits() {
    const dispatch = useAppDispatch();
    const store = useAppStore();
    const tierLimits = useAppSelector((store) => store.paymentLimits.limits);
    /**
     * This callback fetches for tier limits data, but only once for the entire lifetime of the app session.
     */
    const fetchOnce = useCallback(() => {
        /**
         * This check will skip doing API request if we already have the result of it in cache.
         */
        if (!store.getState().paymentLimits.hasFetched) {
            dispatch(getPaymentLimits());
        }
    }, []);
    /**
     * If this hook is used by a screen (a tab screen) this effect makes sure we have the data.
     */
    useFocusEffect(fetchOnce);
    return tierLimits;
}
export default useTierLimits;
