export function getYExtremes(data1, data2, useAbsYFor1, useAbsYFor2) {
    if (!data1.length && !data2.length)
        return {
            smallestY: 0,
            greatestY: 0,
        };
    let smallestY = useAbsYFor1
        ? Math.abs(data1[0].balance)
        : data1[0].balance;
    let greatestY = useAbsYFor1
        ? Math.abs(data1[0].balance)
        : data1[0].balance;
    data1.forEach((eachData) => {
        const balanceToUse = useAbsYFor1
            ? Math.abs(eachData.balance)
            : eachData.balance;
        if (balanceToUse < smallestY) {
            smallestY = balanceToUse;
        }
        if (balanceToUse > greatestY) {
            greatestY = balanceToUse;
        }
    });
    data2.forEach((eachData) => {
        const balanceToUse = useAbsYFor2
            ? Math.abs(eachData.balance)
            : eachData.balance;
        if (balanceToUse < smallestY) {
            smallestY = balanceToUse;
        }
        if (balanceToUse > greatestY) {
            greatestY = balanceToUse;
        }
    });
    return {
        smallestY,
        greatestY,
    };
}
export function findYExtremes(data, useAbsY) {
    let smallestY = null;
    let greatestY = null;
    for (let i = 0; i < data.length; i += 1) {
        const d = data[i];
        if (d.y !== undefined) {
            const dy = useAbsY ? Math.abs(d.y) : d.y;
            if (smallestY === null || dy < smallestY.y) {
                smallestY = {
                    ...d,
                    x: d.x,
                    y: useAbsY ? Math.abs(d.y) : d.y,
                    actualY: dy,
                };
            }
            if (greatestY === null || dy > greatestY.y) {
                greatestY = {
                    ...d,
                    x: d.x,
                    y: useAbsY ? Math.abs(d.y) : d.y,
                    actualY: dy,
                };
            }
        }
    }
    return {
        greatestY: greatestY === null ? { x: data[0].x, y: 1 } : greatestY,
        smallestY: smallestY === null
            ? {
                ...data[data.length - 1],
                y: -1,
                x: data[data.length - 1].x,
                actualY: data[data.length - 1].y,
            }
            : smallestY,
    };
}
function addExtremesIfNeeded(res, data, includeExtremes, removePointsSurroundingExtremes) {
    if (includeExtremes) {
        const { greatestY, smallestY } = findYExtremes(data);
        const [ex1, ex2] = [greatestY, smallestY].sort((a, b) => a.x - b.x);
        let added1 = false;
        let added2 = false;
        const newRes = [];
        for (let i = 0; i < res.length; i++) {
            const d = res[i];
            let justAdded1 = false;
            let justAdded2 = false;
            if (!added1 &&
                (newRes.length === 0 || newRes[newRes.length - 1].x <= ex1.x) &&
                ex1.x <= d.x) {
                justAdded1 = true;
                added1 = true;
                if (ex1.x !== d.x) {
                    if (removePointsSurroundingExtremes) {
                        newRes.pop();
                    }
                    newRes.push(ex1);
                }
            }
            if (!added2 &&
                (newRes.length === 0 || newRes[newRes.length - 1].x <= ex2.x) &&
                ex2.x <= d.x) {
                justAdded2 = true;
                added2 = true;
                if (ex2.x !== d.x) {
                    if (!justAdded1 && removePointsSurroundingExtremes) {
                        newRes.pop();
                    }
                    newRes.push(ex2);
                }
            }
            if ((!justAdded1 && !justAdded2) ||
                !removePointsSurroundingExtremes ||
                i === res.length - 1) {
                newRes.push(d);
            }
        }
        if (!added1) {
            newRes.push(ex1);
        }
        if (!added2) {
            newRes.push(ex2);
        }
        return newRes;
    }
    return res;
}
export default function monotoneCubicInterpolation({ data, range, includeExtremes = false, removePointsSurroundingExtremes = true, }) {
    if (!data || data.length === 0) {
        return [];
    }
    const { x, y, extraInfo } = data.reduce((acc, curr) => {
        acc.x.push(curr.x);
        acc.y.push(curr.y);
        acc.extraInfo.push(curr);
        return acc;
    }, {
        x: [],
        y: [],
        extraInfo: [],
    });
    const n = x.length;
    const delta = [];
    const m = [];
    const alpha = [];
    const beta = [];
    const dist = [];
    const tau = [];
    for (let i = 0, ref = n - 1; ref >= 0 ? i < ref : i > ref; ref >= 0 ? (i += 1) : (i -= 1)) {
        delta[i] = (y[i + 1] - y[i]) / (x[i + 1] - x[i]);
        if (i > 0) {
            m[i] = (delta[i - 1] + delta[i]) / 2;
        }
    }
    // eslint-disable-next-line prefer-destructuring
    m[0] = delta[0];
    m[n - 1] = delta[n - 2];
    let toFix = [];
    for (let i = 0, ref2 = n - 1; ref2 >= 0 ? i < ref2 : i > ref2; ref2 >= 0 ? (i += 1) : (i -= 1)) {
        if (delta[i] === 0) {
            toFix.push(i);
        }
    }
    for (let idx = 0, len = toFix.length; idx < len; idx++) {
        const i = toFix[idx];
        // eslint-disable-next-line no-multi-assign
        m[i] = m[i + 1] = 0;
    }
    for (let i = 0, ref3 = n - 1; ref3 >= 0 ? i < ref3 : i > ref3; ref3 >= 0 ? (i += 1) : (i -= 1)) {
        alpha[i] = m[i] / delta[i];
        beta[i] = m[i + 1] / delta[i];
        // eslint-disable-next-line no-restricted-properties
        dist[i] = alpha[i] ** 2 + beta[i] ** 2;
        tau[i] = 3 / Math.sqrt(dist[i]);
    }
    toFix = [];
    for (let i = 0, ref4 = n - 1; ref4 >= 0 ? i < ref4 : i > ref4; ref4 >= 0 ? (i += 1) : (i -= 1)) {
        if (dist[i] > 9) {
            toFix.push(i);
        }
    }
    for (let jdx = 0, len2 = toFix.length; jdx < len2; jdx++) {
        const i = toFix[jdx];
        m[i] = tau[i] * alpha[i] * delta[i];
        m[i + 1] = tau[i] * beta[i] * delta[i];
    }
    const _x = x.slice(0, n);
    const _y = y.slice(0, n);
    const _m = m;
    const firstValue = _x[0];
    const lastValue = _x[_x.length - 1];
    const deltaX = lastValue - firstValue;
    const res = [];
    for (let j = 0; j < range; j++) {
        const interpolatedValue = firstValue + deltaX * (j / (range - 1));
        let i;
        let _ref;
        for (
        // eslint-disable-next-line no-multi-assign
        i = _ref = _x.length - 1; _ref <= 0 ? i <= 0 : i >= 0; _ref <= 0 ? (i += 1) : (i -= 1)) {
            if (_x[i] <= interpolatedValue) {
                break;
            }
        }
        const h = (_x[i + 1] || _x[i]) - _x[i];
        const t = h === 0 ? 0 : (interpolatedValue - _x[i]) / h;
        const t2 = t ** 2;
        const t3 = t ** 3;
        const h00 = 2 * t3 - 3 * t2 + 1;
        const h10 = t3 - 2 * t2 + t;
        const h01 = -2 * t3 + 3 * t2;
        const h11 = t3 - t2;
        const y = h00 * _y[i] +
            h10 * h * _m[i] +
            h01 * (_y[i + 1] || _y[i]) +
            h11 * h * (_m[i + 1] || _m[i]);
        res.push({
            ...extraInfo[i],
            y,
            x: interpolatedValue,
            actualY: extraInfo[i].y,
        });
    }
    return addExtremesIfNeeded(res, data, includeExtremes, removePointsSurroundingExtremes);
}
export const parse = (data, isAllZeros, yExtremes, useAbsY) => {
    let minY;
    let maxY;
    if (yExtremes) {
        minY = yExtremes.smallestY;
        maxY = yExtremes.greatestY;
    }
    else {
        const { greatestY, smallestY } = yExtremes || findYExtremes(data, useAbsY);
        minY = smallestY.y;
        maxY = greatestY.y;
    }
    const smallestX = data[0];
    const greatestX = data[data.length - 1];
    const parsedOriginalData = [];
    const parsedOriginalDataNullable = [];
    for (let i = 0; i < data.length; i += 1) {
        const d = data[i];
        if (d.y !== undefined) {
            const dy = useAbsY ? Math.abs(d.y) : d.y;
            const y = isAllZeros ? 1 : 1 - (dy - minY) / (maxY - minY);
            const dataPoint = {
                ...d,
                originalX: d.x,
                originalY: d.y,
                x: (d.x - smallestX.x) / (greatestX.x - smallestX.x),
                y,
            };
            parsedOriginalData.push(dataPoint);
            parsedOriginalDataNullable.push(dataPoint);
        }
        else {
            const dataPoint = {
                ...d,
                originalX: d.x,
                originalY: d.y,
                x: (d.x - smallestX.x) / (greatestX.x - smallestX.x),
                y: undefined,
            };
            parsedOriginalDataNullable.push(dataPoint);
        }
    }
    return [parsedOriginalData, parsedOriginalDataNullable];
};
