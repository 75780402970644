import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { IconArrow, rem } from 'design-system';
import Text from 'design-system/Text';
import IconLockUnlocked from 'design-system/icons/IconLockUnlocked';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import createStyleSheets from 'utils/createStyleSheets';
export const SEARCH_UPGRADE_BANNER_HEIGHT = rem(37);
const SearchTransactionUpgradeBanner = ({ isKeyboardVisible, onPress, }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const { bottom } = useSafeAreaInsets();
    const { paddingHorizontal } = useAppFrameDimensions();
    return (<TouchableOpacity style={[
            styles.container,
            { paddingHorizontal: paddingHorizontal + rem(16) },
            !isKeyboardVisible && bottom ? { paddingBottom: bottom } : undefined,
        ]} onPress={onPress}>
      <View style={styles.leftContainer}>
        <IconLockUnlocked height={16} width={16} style={styles.icon} color={colors.text.textOnBrandLight}/>
        <Text Text-14 color={colors.text.textOnBrandLight}>
          Unlock enhanced tracking
        </Text>
      </View>
      <IconArrow color={colors.text.textOnBrandLight}/>
    </TouchableOpacity>);
};
const styleSet = createStyleSheets((colors) => ({
    absolute: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
    },
    container: {
        backgroundColor: colors.buttons.brandGroup,
        paddingHorizontal: rem(16),
        paddingVertical: rem(8),
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    leftContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        marginRight: rem(8),
    },
}));
export default SearchTransactionUpgradeBanner;
