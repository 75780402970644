import { CALL_API } from 'middleware/api';
export const getPotBalanceHistory = (from, to) => ({
    [CALL_API]: {
        endpoint: `/wealth/pots/balance-history?from=${from}&to=${to}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        types: [
            'GET_POT_BALANCE_HISTORY_REQUEST',
            'GET_POT_BALANCE_HISTORY_SUCCESS',
            'GET_POT_BALANCE_HISTORY_FAILURE',
        ],
    },
});
export const getPortfolioBalanceHistory = (from, to) => ({
    [CALL_API]: {
        endpoint: `/wealth/trading/portfolio/balance-history?from=${from}&to=${to}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        types: [
            'GET_PORTFOLIO_BALANCE_HISTORY_REQUEST',
            'GET_PORTFOLIO_BALANCE_HISTORY_SUCCESS',
            'GET_PORTFOLIO_BALANCE_HISTORY_FAILURE',
        ],
    },
});
export const getEmmaWalletBalanceHistory = (from, to) => ({
    [CALL_API]: {
        endpoint: `/wealth/ledger/balance-history?from=${from}&to=${to}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        types: [
            'GET_EMMA_WALLET_BALANCE_HISTORY_REQUEST',
            'GET_EMMA_WALLET_BALANCE_HISTORY_SUCCESS',
            'GET_EMMA_WALLET_BALANCE_HISTORY_FAILURE',
        ],
    },
});
