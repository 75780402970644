import React, { memo, useMemo } from 'react';
import { View } from 'react-native';
import Animated, { Extrapolate, interpolate, interpolateColor, useAnimatedStyle, useDerivedValue, } from 'react-native-reanimated';
import { rem } from 'design-system';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import useColors from 'hooks/useColors';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
const TRANSLATE_DISTANCE = rem(13);
const PRIMARY_WIDTH = rem(15);
const OTHER_WIDTH = rem(5);
const OFFSET_2 = 0;
const INDICATOR_CONTAINER_WIDTH = PRIMARY_WIDTH + 2 * rem(39 + 4);
const SECONDARY_COLOR = 'secondaryTransparent';
const PageControl = ({ style, length, finite, scrollX, onColor, inverted, backgroundColor, }) => {
    const styles = useStyles(styleSet);
    const { width } = useAppFrameDimensions();
    const center = useDerivedValue(() => width / 2, [width]);
    const screenX = useDerivedValue(() => scrollX.value % width, [width, scrollX]);
    const translateX = useDerivedValue(() => interpolate(screenX.value, [0, width], [0, TRANSLATE_DISTANCE], Extrapolate.CLAMP), [width]);
    const colors = useColors();
    const transparentToTertiary = useAnimatedStyle(() => ({
        transform: [
            {
                translateX: translateX.value,
            },
        ],
        opacity: length <= 3
            ? 0
            : interpolate(screenX.value, [0, center.value], [0, 0.5], Extrapolate.CLAMP),
    }));
    const secondaryToTertiaryLeft = useAnimatedStyle(() => ({
        transform: [
            {
                translateX: translateX.value,
            },
        ],
        opacity: length <= 2
            ? 0
            : interpolate(screenX.value, [0, center.value], [0.5, 1], Extrapolate.CLAMP),
    }));
    const indicatorColors = useMemo(() => !onColor
        ? {
            secondaryToPrimary: [
                colors.elements[SECONDARY_COLOR],
                colors.elements.brand,
            ],
            primaryToSecondary: [
                colors.elements.brand,
                colors.elements[SECONDARY_COLOR],
            ],
            tertiaryToTransparent: [
                colors.elements[SECONDARY_COLOR],
                'transparent',
            ],
            primaryItem: { backgroundColor: colors.elements.brand },
            item: { backgroundColor: colors.elements[SECONDARY_COLOR] },
        }
        : {
            secondaryToPrimary: [
                colors.background.transparent,
                colors.elements.floatingWhite,
            ],
            primaryToSecondary: [
                colors.elements.floatingWhite,
                colors.background.transparent,
            ],
            tertiaryToTransparent: [
                colors.background.transparent,
                'transparent',
            ],
            primaryItem: { backgroundColor: colors.elements.floatingWhite },
            item: { backgroundColor: colors.background.transparent },
        }, [colors, onColor]);
    const secondaryToPrimary = useAnimatedStyle(() => ({
        transform: [
            {
                translateX: translateX.value,
            },
        ],
        backgroundColor: interpolateColor(screenX.value, [0, center.value], indicatorColors.secondaryToPrimary),
        opacity: length < 2 ? 0 : 1,
    }));
    const primaryToSecondary = useAnimatedStyle(() => ({
        transform: [
            {
                translateX: translateX.value,
            },
        ],
        backgroundColor: interpolateColor(screenX.value, [0, width], indicatorColors.primaryToSecondary),
    }));
    const secondaryToTertiaryRight = useAnimatedStyle(() => ({
        transform: [
            {
                translateX: translateX.value,
            },
        ],
        opacity: scrollX.value >= width
            ? interpolate(screenX.value, [0, center.value], [1, 0.5], Extrapolate.CLAMP)
            : 0,
    }));
    const tertiaryToTransparent = useAnimatedStyle(() => ({
        transform: [
            {
                translateX: translateX.value,
            },
        ],
        backgroundColor: interpolateColor(screenX.value, [0, center.value], indicatorColors.tertiaryToTransparent),
        opacity: scrollX.value >= width * 2 ? 0.5 : 0,
    }));
    const invertedStyle = useAnimatedStyle(() => ({
        transform: [{ scaleX: inverted ? -1 : 1 }],
    }), [inverted]);
    const finiteItemOutputRange = useMemo(() => [-((length - 1) * rem(5 + 8) + rem(4)), OFFSET_2], [length]);
    const finiteItemAnimatedStyle = useAnimatedStyle(() => ({
        backgroundColor,
        left: interpolate(scrollX.value, [0, (length - 1) * width], finiteItemOutputRange, Extrapolate.CLAMP),
    }));
    return (<Animated.View style={[styles.container, style, invertedStyle]}>
      <Animated.View style={[styles.item, transparentToTertiary, indicatorColors.item]}/>
      <Animated.View style={[styles.item, secondaryToTertiaryLeft, indicatorColors.item]}/>
      <Animated.View style={[styles.item, secondaryToPrimary, indicatorColors.item]}/>
      <View style={styles.spacer}/>
      <Animated.View style={[styles.item, primaryToSecondary, indicatorColors.item]}/>
      <Animated.View style={[styles.item, secondaryToTertiaryRight, indicatorColors.item]}/>
      <Animated.View style={[styles.item, tertiaryToTransparent, indicatorColors.item]}/>
      <Animated.View style={[styles.item, styles.itemTransparent, indicatorColors.item]}/>
      {finite && (<Animated.View style={[styles.finiteItem, finiteItemAnimatedStyle]}/>)}
      <View style={[styles.item, styles.primaryItem, indicatorColors.primaryItem]}/>
    </Animated.View>);
};
const HEIGHT = rem(3);
const styleSet = createStyleSheets(() => ({
    container: {
        flexDirection: 'row',
        alignSelf: 'center',
        marginBottom: rem(24),
        marginTop: rem(12),
        overflow: 'hidden',
        width: INDICATOR_CONTAINER_WIDTH,
    },
    item: {
        width: OTHER_WIDTH,
        height: HEIGHT,
        borderRadius: HEIGHT,
        marginHorizontal: rem(4),
    },
    itemTransparent: {
        opacity: 0,
    },
    spacer: {
        width: PRIMARY_WIDTH - OTHER_WIDTH,
    },
    primaryItem: {
        width: PRIMARY_WIDTH,
        position: 'absolute',
        left: rem(39),
        top: 0,
    },
    finiteItem: {
        width: rem(39),
        marginHorizontal: 0,
        position: 'absolute',
        top: 0,
        bottom: 0,
    },
}));
export default memo(PageControl);
