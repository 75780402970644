import React from 'react';
import { StyleSheet } from 'react-native';
import Separator from './Separator';
import Text from './Text';
import { rem } from './values';
const CardHeader = ({ children }) => (<>
    <Text Text-16 style={styles.title}>
      {children}
    </Text>
    <Separator />
  </>);
const styles = StyleSheet.create({
    title: {
        margin: rem(16),
    },
});
export default CardHeader;
