const ROUNDING_THRESHOLD = 0.019;
/**
 * Rounds a fractional number a.bb to a.99 or a.49 if it's within the threshold
 */
export const tryToRoundToZeroPointNinetyNine = (original) => {
    const decimalPart = Math.floor(original);
    const fractionalPart = original - decimalPart;
    let roundedFractionalPart = fractionalPart;
    if (fractionalPart > 1 - ROUNDING_THRESHOLD) {
        roundedFractionalPart = 0.99;
    }
    else if (fractionalPart > 0.5 - ROUNDING_THRESHOLD &&
        fractionalPart < 0.5 + ROUNDING_THRESHOLD) {
        roundedFractionalPart = 0.49;
    }
    else if (fractionalPart < ROUNDING_THRESHOLD) {
        roundedFractionalPart = -0.01;
    }
    return decimalPart + roundedFractionalPart;
};
