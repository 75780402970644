import React from 'react';
import { View } from 'react-native';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableBounce from 'utils/packages/TouchableBounce';
const CategoryBadgeChildren = ({ Icon, name, selected }) => {
    const textColorProps = selected ? { OnBrand: true } : {};
    return (<>
      {!!Icon && (<>
          <Icon />
          <Spacer w={8}/>
        </>)}
      <Text Text-14 numberOfLines={1} {...textColorProps}>
        {name}
      </Text>
    </>);
};
const CategoryBadge = ({ name, Icon, selected, onPress }) => {
    const styles = useStyles(styleSet);
    return (<TouchableBounce style={[styles.touchable, selected && styles.selectedBg]} onPress={onPress} hoverStyle={styles.hoverStyle}>
      <View style={styles.innerContainer}>
        <CategoryBadgeChildren name={name} Icon={Icon} selected={selected}/>
      </View>
    </TouchableBounce>);
};
const SPACING_BETWEEN_ITEMS = 8;
const BORDER_RADIUS = 12;
const styleSet = createStyleSheets((colors) => ({
    touchable: {
        marginHorizontal: rem(SPACING_BETWEEN_ITEMS / 2),
        paddingVertical: 0,
        paddingHorizontal: 0,
        borderRadius: rem(BORDER_RADIUS),
        backgroundColor: colors.cards.onDark,
    },
    innerContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        paddingVertical: rem(8),
        paddingHorizontal: rem(16),
    },
    selectedBg: {
        backgroundColor: colors.buttons.brandLight,
    },
    hoverStyle: {
        backgroundColor: colors.buttons.brandLight,
    },
}));
export default CategoryBadge;
