/**
 * A component that renders a list of choices.
 * Each choice is shown as a view with rounded corners,
 * a half-transparent white background,
 * and an arrow on the right.
 */
import React from 'react';
import { View } from 'react-native';
import Text from 'design-system/Text';
import { IconArrow } from 'design-system/icons/IconArrow';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import TouchableBounce from 'utils/packages/TouchableBounce';
export const ITEM_HEIGHT = rem(56);
export const ITEM_HEIGHT_BIG = rem(68);
const Choices = ({ numbers, data, icons, onLight, onSelect, }) => {
    const styles = useStyles(styleSet);
    const fontStyle = numbers
        ? { 'Numbers-16': true }
        : { 'Text-16': true };
    return (<>
      {data.map((text, index) => (<React.Fragment key={text}>
          {index > 0 && <Spacer h={16}/>}
          {onSelect ? (<TouchableBounce style={[
                    styles.itemContainer,
                    onLight && styles.itemContainerOnLight,
                ]} onPress={() => {
                    onSelect(text, index);
                }} hoverStyle={onLight ? styles.hoverOnLight : styles.hoverOnDark}>
              {icons && (<>
                  {icons[index]}
                  <Spacer w={16}/>
                </>)}
              <View style={styles.flex}>
                <Text {...fontStyle} OffBlack>
                  {text}
                </Text>
              </View>
              <Spacer w={16}/>
              <IconArrow />
            </TouchableBounce>) : (<View style={styles.itemContainer}>
              {icons && (<>
                  {icons[index]}
                  <Spacer w={16}/>
                </>)}
              <Text flex {...fontStyle} OffBlack>
                {text}
              </Text>
            </View>)}
        </React.Fragment>))}
    </>);
};
const styleSet = createStyleSheets((colors) => ({
    itemContainer: {
        flexDirection: 'row',
        width: '100%',
        padding: rem(16),
        alignItems: 'center',
        borderRadius: rem(16),
        backgroundColor: colors.cards.onDark,
    },
    itemContainerOnLight: {
        backgroundColor: colors.cards.onLight,
    },
    flex: {
        flex: 1,
    },
    hoverOnLight: {
        backgroundColor: colors.control.hoverOnLight,
    },
    hoverOnDark: {
        backgroundColor: colors.control.hoverOnDark,
    },
}));
export default Choices;
