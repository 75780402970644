import _ from 'lodash';
export const getAnalyticsCategories = (data) => {
    const categories = _.groupBy(data, (d) => {
        if (d && d.total && d.total > 0) {
            return 'Income';
        }
        return 'Spending';
    });
    return _.reduce(categories, (acc, next, index) => {
        let total = 0;
        for (let index = 0; index < next.length; index++) {
            if (next[index] && next[index].total)
                total += next[index].total;
        }
        acc.push({
            key: index,
            data: next,
            total,
        });
        return acc;
    }, []);
};
