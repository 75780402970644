import React, { memo } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Clipboard from '@react-native-clipboard/clipboard';
import Text from 'design-system/Text';
import { rem } from 'design-system';
const TransactionFooter = ({ realCounterpartName, account, }) => {
    const name = realCounterpartName?.toUpperCase();
    const onPressName = () => {
        if (name) {
            Clipboard.setString(name);
        }
    };
    return (<View style={styles.container}>
      {name && (<TouchableOpacity onPress={onPressName}>
          <Text Text-16 Gray style={styles.transactionFooter}>
            {name}
          </Text>
        </TouchableOpacity>)}
      {account && (<View style={styles.accountLabel}>
          {account.name ? (<Text TextThin-16 Gray numberOfLines={1} style={styles.accountLabelText}>
              {account.name}
            </Text>) : null}
          {Boolean(account.accountNumber?.length) && (<Text TextThin-16 OffBlack style={styles.accountNumberText}>
              *** {account.accountNumber}
            </Text>)}
        </View>)}
    </View>);
};
const styles = StyleSheet.create({
    container: {
        paddingHorizontal: rem(16),
        flexGrow: 0,
    },
    transactionFooter: {
        width: '100%',
        textAlign: 'center',
        paddingHorizontal: '5%',
    },
    accountLabel: {
        marginTop: rem(10),
        flex: 1,
        flexDirection: 'row',
        alignSelf: 'center',
        justifyContent: 'space-between',
    },
    accountLabelText: {
        flexShrink: 1,
    },
    accountNumberText: {
        paddingStart: rem(16),
    },
});
export default memo(TransactionFooter);
