import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
const IconInvest = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="plus">
      <Path fillRule="evenodd" clipRule="evenodd" d="M1 12C1 4.53882 4.53882 1 12 1C19.4612 1 23 4.53882 23 12C23 19.4612 19.4612 23 12 23C4.53882 23 1 19.4612 1 12ZM14.0798 11.8757L13.9538 11.7787C13.4321 11.3732 12.9802 10.973 12.7059 10.686L12.5444 10.4958C12.4598 10.394 12.3664 10.2742 12.3309 10.1973C12.2013 9.91602 12.238 9.59156 12.421 9.35604L12.4369 9.33554C12.5613 9.18595 12.9467 8.96811 12.9514 8.97175C13.5725 8.62199 15.008 8.13148 15.8359 8.03318L16.081 8.00812C16.1465 8.00256 16.2224 7.99809 16.2766 8.00088C16.4489 8.00326 16.6151 8.06666 16.7494 8.17107C16.8994 8.28761 17.003 8.44198 17.0494 8.61761L17.0911 8.87149L17.0989 8.92714C17.2277 9.70032 17.1201 11.3741 16.9024 12.1242L16.8683 12.2365C16.8247 12.3696 16.7558 12.5501 16.6911 12.6418C16.6037 12.7543 16.494 12.8415 16.3628 12.9039C16.191 12.9758 15.9976 12.9893 15.8194 12.9413C15.6985 12.9051 15.3754 12.7445 15.3704 12.7406C15.0486 12.5736 14.581 12.2583 14.0798 11.8757ZM7.16841 13.3043C7.41634 13.0015 7.8565 12.9424 8.17548 13.1692L10.4028 14.7531L11.9625 12.8478C12.2228 12.5298 12.6916 12.483 13.0096 12.7434C13.3277 13.0037 13.3744 13.4725 13.1141 13.7906L11.1141 16.2339C10.8661 16.5367 10.426 16.5958 10.107 16.3689L7.87964 14.7851L6.32002 16.6904C6.05969 17.0084 5.59085 17.0551 5.27283 16.7948C4.95481 16.5345 4.90804 16.0656 5.16837 15.7476L7.16841 13.3043Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default IconInvest;
