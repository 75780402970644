export const FORCE_REFRESH_ANALYTICS = 'FORCE_REFRESH_ANALYTICS';
export const BUDGETING_PERIOD_CHANGED = 'BUDGETING_PERIOD_CHANGED';
export const GET_BANK_FEES_REQUEST = 'GET_BANK_FEES_REQUEST';
export const GET_BANK_FEES_SUCCESS = 'GET_BANK_FEES_SUCCESS';
export const GET_BANK_FEES_FAILURE = 'GET_BANK_FEES_FAILURE';
export const SET_BUDGETING_POSITION = 'SET_BUDGETING_POSITION';
export const SET_ANALYTICS_POSITION = 'SET_ANALYTICS_POSITION';
export const RESET_ANALYTICS_FILTER = 'RESET_ANALYTICS_FILTER';
export const EDIT_TAGS_REQUEST = 'EDIT_TAGS_REQUEST';
export const EDIT_TAGS_SUCCESS = 'EDIT_TAGS_SUCCESS';
export const EDIT_TAGS_FAILURE = 'EDIT_TAGS_FAILURE';
export const EDIT_CATEGORY_REQUEST = 'EDIT_CATEGORY_REQUEST';
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS';
export const EDIT_CATEGORY_FAILURE = 'EDIT_CATEGORY_FAILURE';
export const IS_FETCHING_TOTALS = 'IS_FETCHING_TOTALS';
export const IS_FETCHING_CATEGORY_EXPENSES = 'IS_FETCHING_CATEGORY_EXPENSES';
export const SAVE_TOTAL_BUDGET_REQUEST = 'SAVE_TOTAL_BUDGET_REQUEST';
export const SAVE_TOTAL_BUDGET_SUCCESS = 'SAVE_TOTAL_BUDGET_SUCCESS';
export const SAVE_TOTAL_BUDGET_FAILURE = 'SAVE_TOTAL_BUDGET_FAILURE';
export const GET_MONTHLY_TOTALS_REQUEST = 'GET_MONTHLY_TOTALS_REQUEST';
export const GET_MONTHLY_TOTALS_SUCCESS = 'GET_MONTHLY_TOTALS_SUCCESS';
export const GET_MONTHLY_TOTALS_FAILURE = 'GET_MONTHLY_TOTALS_FAILURE';
export const GET_CATEGORY_TOTALS_REQUEST = 'GET_CATEGORY_TOTALS_REQUEST';
export const GET_CATEGORY_TOTALS_SUCCESS = 'GET_CATEGORY_TOTALS_SUCCESS';
export const GET_CATEGORY_TOTALS_FAILURE = 'GET_CATEGORY_TOTALS_FAILURE';
export const MONTHLY_MERCHANTS_REQUEST = 'MONTHLY_MERCHANTS_REQUEST';
export const MONTHLY_MERCHANTS_SUCCESS = 'MONTHLY_MERCHANTS_SUCCESS';
export const MONTHLY_MERCHANTS_FAILURE = 'MONTHLY_MERCHANTS_FAILURE';
export const MONTHLY_CATEGORIES_REQUEST = 'MONTHLY_CATEGORIES_REQUEST';
export const MONTHLY_CATEGORIES_SUCCESS = 'MONTHLY_CATEGORIES_SUCCESS';
export const MONTHLY_CATEGORIES_FAILURE = 'MONTHLY_CATEGORIES_FAILURE';
export const GET_MERCHANT_EXPENSES_REQUEST = 'GET_MERCHANT_EXPENSES_REQUEST';
export const GET_MERCHANT_EXPENSES_SUCCESS = 'GET_MERCHANT_EXPENSES_SUCCESS';
export const GET_MERCHANT_EXPENSES_FAILURE = 'GET_MERCHANT_EXPENSES_FAILURE';
export const GET_CATEGORY_EXPENSES_REQUEST = 'GET_CATEGORY_EXPENSES_REQUEST';
export const GET_CATEGORY_EXPENSES_SUCCESS = 'GET_CATEGORY_EXPENSES_SUCCESS';
export const GET_CATEGORY_EXPENSES_FAILURE = 'GET_CATEGORY_EXPENSES_FAILURE';
export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE';
export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';
export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';
export const FETCH_ANALYTICS_BUDGETING_DATA = 'FETCH_ANALYTICS_BUDGETING_DATA';
export const FETCH_MORE_ANALYTICS_BUDGETING_DATA = 'FETCH_MORE_ANALYTICS_BUDGETING_DATA';
export const FETCH_CATEGORIES_MERCHANTS = 'FETCH_CATEGORIES_MERCHANTS';
export const ANALYTICS_FILTER_CHANGED = 'ANALYTICS_FILTER_CHANGED';
