import React, { memo } from 'react';
import { CardView } from 'design-system/CardView';
import { useAppSelector } from 'store/hooks';
import UnbudgetedSpendRow from './UnbudgetedSpendRow';
import { selectUnbudgetCategories } from '../selectors';
import UnbudgetedCategoriesListEmpty from './UnbudgetedCategoriesListEmpty';
const UnbudgetedCategoriesList = ({ onPressCategory }) => {
    const unbudgetCategories = useAppSelector(selectUnbudgetCategories);
    return (<CardView webPadding>
      {!unbudgetCategories?.length ? (<UnbudgetedCategoriesListEmpty />) : (unbudgetCategories.map((item) => {
            const onPress = () => onPressCategory(item, 'category');
            return (<UnbudgetedSpendRow key={item.id} category={item} onPress={onPress}/>);
        }))}
    </CardView>);
};
export default memo(UnbudgetedCategoriesList);
