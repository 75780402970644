export const GET_SWITCHING_QUOTES_REQUEST = 'GET_SWITCHING_QUOTES_REQUEST';
export const GET_SWITCHING_QUOTES_SUCCESS = 'GET_SWITCHING_QUOTES_SUCCESS';
export const GET_SWITCHING_QUOTES_FAILURE = 'GET_SWITCHING_QUOTES_FAILURE';
export const GET_SWITCHING_RESULTS_REQUEST = 'GET_SWITCHING_RESULTS_REQUEST';
export const GET_SWITCHING_RESULTS_SUCCESS = 'GET_SWITCHING_RESULTS_SUCCESS';
export const GET_SWITCHING_RESULTS_FAILURE = 'GET_SWITCHING_RESULTS_FAILURE';
export const GET_ADVANCED_SWITCHING_RESULTS_REQUEST = 'GET_ADVANCED_SWITCHING_RESULTS_REQUEST';
export const GET_ADVANCED_SWITCHING_RESULTS_SUCCESS = 'GET_ADVANCED_SWITCHING_RESULTS_SUCCESS';
export const GET_ADVANCED_SWITCHING_RESULTS_FAILURE = 'GET_ADVANCED_SWITCHING_RESULTS_FAILURE';
export const GET_ENERGY_SWITCHING_RESULTS_REQUEST = 'GET_ENERGY_SWITCHING_RESULTS_REQUEST';
export const GET_ENERGY_SWITCHING_RESULTS_SUCCESS = 'GET_ENERGY_SWITCHING_RESULTS_SUCCESS';
export const GET_ENERGY_SWITCHING_RESULTS_FAILURE = 'GET_ENERGY_SWITCHING_RESULTS_FAILURE';
export const APPLY_SWITCHING_REQUEST = 'APPLY_SWITCHING_REQUEST';
export const APPLY_SWITCHING_SUCCESS = 'APPLY_SWITCHING_SUCCESS';
export const APPLY_SWITCHING_FAILURE = 'APPLY_SWITCHING_FAILURE';
export const GET_ENQUIRIES_REQUEST = 'GET_ENQUIRIES_REQUEST';
export const GET_ENQUIRIES_SUCCESS = 'GET_ENQUIRIES_SUCCESS';
export const GET_ENQUIRIES_FAILURE = 'GET_ENQUIRIES_FAILURE';
export const ON_SWITCH_COMPLETED_REQUEST = 'ON_SWITCH_COMPLETED_REQUEST';
export const ON_SWITCH_COMPLETED_SUCCESS = 'ON_SWITCH_COMPLETED_SUCCESS';
export const ON_SWITCH_COMPLETED_FAILURE = 'ON_SWITCH_COMPLETED_FAILURE';
export const POST_DISMISS_ENERGY_CARD_REQUEST = 'POST_DISMISS_ENERGY_CARD_REQUEST';
export const POST_DISMISS_ENERGY_CARD_SUCCESS = 'POST_DISMISS_ENERGY_CARD_SUCCESS';
export const POST_DISMISS_ENERGY_CARD_FAILURE = 'POST_DISMISS_ENERGY_CARD_FAILURE';
export const GET_AUTOFILL_REQUEST = 'GET_AUTOFILL_REQUEST';
export const GET_AUTOFILL_SUCCESS = 'GET_AUTOFILL_SUCCESS';
export const GET_AUTOFILL_FAILURE = 'GET_AUTOFILL_FAILURE';
