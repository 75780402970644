import React, { memo } from 'react';
import { View, StyleSheet, } from 'react-native';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import Text from 'design-system/Text';
import { isWeb } from '../constants';
const ScreenInfoHeader = ({ title, style, preTitle, subTitle, onLayout, smallSubTitle, }) => (<View style={[styles.container, style]} onLayout={onLayout}>
    {!!preTitle && (<>
        <Text Text-16 Secondary>
          {preTitle}
        </Text>
        <Spacer h={4}/>
      </>)}
    <Text Text-24>{title}</Text>
    {!!smallSubTitle && <Spacer h={4}/>}
    {isWeb && <Spacer h={8}/>}
    {!!subTitle &&
        (typeof subTitle === 'string' ? (<Text {...(smallSubTitle
            ? { 'TextThin-14': true }
            : { 'TextThin-16': true })} Secondary>
          {subTitle}
        </Text>) : (subTitle))}
  </View>);
const styles = StyleSheet.create({
    container: {
        paddingBottom: rem(24),
        paddingHorizontal: rem(16),
    },
});
export default memo(ScreenInfoHeader);
