import React from 'react';
import { View } from 'react-native';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
export const LineSeparator = ({ style, noMargin, ...props }) => {
    const styles = useStyles(styleSet);
    return (<View style={[styles.separator, style, noMargin && styles.noMargin]} {...props}/>);
};
const styleSet = createStyleSheets((colors) => ({
    separator: {
        height: rem(1),
        marginHorizontal: rem(16),
        backgroundColor: colors.borders.logoStroke,
    },
    noMargin: {
        marginHorizontal: rem(0),
    },
}));
