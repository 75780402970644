import React, { memo, useCallback } from 'react';
import { StyleSheet } from 'react-native';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import Button from 'design-system/Button';
import { rem } from 'design-system/values';
import Squircle from 'design-system/Squircle';
import { CardView } from 'design-system/CardView';
import IconTicketSolid from 'design-system/icons/IconTicketSolid';
import useColors from 'hooks/useColors';
import { useMainStackNavigation } from 'utils/types/navigationV6';
const StartInvestingCard = memo(() => {
    const colors = useColors();
    const navigation = useMainStackNavigation();
    const handleNavigation = useCallback(() => {
        navigation.navigate('Invest');
    }, []);
    return (<CardView noMarginTop withPadding style={styles.container}>
      <Squircle width={rem(64)} height={rem(64)} color={colors.background.dark}>
        <IconTicketSolid colorKey="brand" width={32} height={32}/>
      </Squircle>
      <Spacer.H16 />
      <Text Text-16>Start investing</Text>
      <Spacer h={4}/>
      <Text TextThin-14 centered Secondary>
        {'Open a free investment account and invest as\xa0little\xa0as\xa0£1 in the companies you love. Capital\xa0at\xa0Risk'}
      </Text>
      <Spacer.H16 />
      <Button hug small grey title="Get started" onPress={handleNavigation}/>
    </CardView>);
});
export default StartInvestingCard;
const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        paddingHorizontal: rem(32),
    },
    empty: {
        padding: rem(24),
    },
});
