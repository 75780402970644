import React from 'react';
import { Icon } from 'design-system/icons/Icon';
import { Path } from 'react-native-svg';
import useColors from 'hooks/useColors';
export const IconArrowLeftRight = ({ color, ...props }) => {
    const colors = useColors();
    return (<Icon {...props}>
      <Path d="M23.89 9.248a.772.772 0 0 1-.223.547l-4.624 4.624a.77.77 0 0 1-.547.224.768.768 0 0 1-.293-.054.77.77 0 0 1-.478-.717V11.56H10.79a.77.77 0 0 1-.77-.77V7.707a.77.77 0 0 1 .77-.77h6.936V4.623a.77.77 0 0 1 1.318-.54l4.624 4.625a.771.771 0 0 1 .223.54Zm-10.018 3.854H6.936V10.79a.77.77 0 0 0-.478-.71.77.77 0 0 0-.84.162L.994 14.866a.772.772 0 0 0-.223.548.77.77 0 0 0 .223.547l4.624 4.624a.769.769 0 0 0 .84.162.77.77 0 0 0 .478-.71v-2.311h6.936a.77.77 0 0 0 .77-.771v-3.083a.771.771 0 0 0-.77-.77Z" fill={color ?? colors.elements.white}/>
    </Icon>);
};
