import { useCallback, useEffect, useRef, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { changeIcon } from 'react-native-change-icon';
import APP_ICONS, { getAppIconByAssetName, iconStrings, } from 'features/app-icons/appIcons';
import { Alert, BackHandler, Platform } from 'react-native';
const STORE_KEY = 'ICON_NAME_2021';
const storeAppIcon = (appIcon) => AsyncStorage.setItem(STORE_KEY, JSON.stringify(appIcon));
const getStoredAppIcon = async () => {
    const appIconStr = await AsyncStorage.getItem(STORE_KEY);
    try {
        return appIconStr ? JSON.parse(appIconStr) : undefined;
    }
    catch (e) {
        return getAppIconByAssetName(appIconStr);
    }
};
export const useAppIcon = () => {
    const [value, setValue] = useState();
    const valueName = useRef();
    const switchIcon = useCallback(async (newIcon) => {
        if (valueName.current !== newIcon?.name) {
            setValue(newIcon);
            valueName.current = newIcon.name;
            changeIcon(newIcon.assetName);
            await storeAppIcon(newIcon);
            if (Platform.OS === 'android') {
                BackHandler.exitApp();
            }
        }
    }, []);
    const setAppIcon = useCallback((newIcon) => {
        if (valueName.current !== newIcon?.name) {
            if (Platform.OS === 'android') {
                Alert.alert(`${iconStrings[newIcon.name]} Icon`, 'To change the icon, we will need to turn off the Emma app', [
                    {
                        text: 'Cancel',
                        style: 'cancel',
                    },
                    {
                        text: 'OK',
                        onPress: () => switchIcon(newIcon),
                    },
                ], { cancelable: true });
            }
            else {
                switchIcon(newIcon);
            }
        }
    }, [switchIcon]);
    useEffect(() => {
        getStoredAppIcon().then((storedAppIcon) => {
            const appIcon = storedAppIcon || APP_ICONS.normal;
            setValue(appIcon);
            valueName.current = appIcon.name;
        });
    }, []);
    return [value, setAppIcon];
};
export default useAppIcon;
