import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconWork = ({ ...props }) => {
    const id = useId();
    return (<IconWithGradient id={id} defaultGradientKey="ultimate" {...props}>
      <Path fillRule="evenodd" clipRule="evenodd" d="M16.474 6.57a4.47 4.47 0 0 0-3.248-3.41 4.797 4.797 0 0 0-2.452 0 4.47 4.47 0 0 0-3.247 3.41 5.74 5.74 0 0 0-3.897 3.458c1.152.425 2.444.748 3.807.968v-.31c0-.393.322-.711.72-.711.398 0 .72.318.72.711v.502c2.048.217 4.198.217 6.245 0v-.502c0-.393.323-.711.72-.711.399 0 .721.318.721.711v.31c1.363-.22 2.655-.543 3.807-.968a5.74 5.74 0 0 0-3.896-3.459Zm-3.62-2.036a3.337 3.337 0 0 0-1.707 0 3.05 3.05 0 0 0-2.015 1.714 16.398 16.398 0 0 1 5.736 0 3.05 3.05 0 0 0-2.015-1.714Z" fill={`url(#gradient_${id})`}/>
      <Path d="M15.122 12.618v1.388c0 .393.323.712.72.712.399 0 .721-.319.721-.712v-1.57c1.5-.229 2.926-.574 4.205-1.036a9.91 9.91 0 0 1-.148 4.848 6.227 6.227 0 0 1-4.809 4.388 20.114 20.114 0 0 1-7.622 0 6.226 6.226 0 0 1-4.81-4.388 9.91 9.91 0 0 1-.147-4.848c1.279.462 2.705.807 4.205 1.036v1.57c0 .393.322.712.72.712.398 0 .72-.319.72-.712v-1.388a31.44 31.44 0 0 0 6.245 0Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
