import React from 'react';
import { StyleSheet } from 'react-native';
import Button from 'design-system/Button';
import { rem } from 'design-system/values';
import IconPlus from 'design-system/icons/IconPlus';
import useColors from 'hooks/useColors';
import useAddAccount from 'hooks/useAddAccount';
const OverviewAddButton = ({ style }) => {
    const addAccountModal = useAddAccount();
    const colors = useColors();
    return (<Button brandLight cards title="Add" onPress={addAccountModal} style={style} leftIcon={<IconPlus style={styles.iconLeft} width={rem(10)} height={rem(10)} color={colors.text.textOnBrand}/>}/>);
};
const styles = StyleSheet.create({
    iconLeft: {
        marginRight: -4,
    },
});
export default OverviewAddButton;
