export const colorMap = {
    ACCOUNT: 'lime',
    AMOUNT_LOWER: 'lightPink',
    AMOUNT_UPPER: 'green',
    CATEGORY: 'orange',
    DATES: 'plus',
    MERCHANT: 'darkPurple',
    RECEIPT: 'peach',
    TAG: 'skyBlue',
};
export const keyboardToFilterMap = {
    KeyboardCategories: 'CATEGORY',
    KeyboardMerchants: 'MERCHANT',
    KeyboardTags: 'TAG',
    KeyboardAmountUpper: 'AMOUNT_UPPER',
    KeyboardAmountLower: 'AMOUNT_LOWER',
    KeyboardDates: 'DATES',
    KeyboardReceipts: 'RECEIPT',
    KeyboardAccounts: 'ACCOUNT',
};
export const getFallbackColor = (filterType) => colorMap[filterType];
