import React from 'react';
import Spacer from 'design-system/Spacer';
import Text from 'design-system/Text';
import { rem } from './values';
export const toolTipWebContentWidth = rem(268);
const ToolTipContentWeb = ({ title, subtitle }) => (<>
    <Text Text-16>{title}</Text>
    <Spacer h={8}/>
    <Text TextThin-14 Gray>
      {subtitle}
    </Text>
  </>);
export default ToolTipContentWeb;
