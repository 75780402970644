/**
 * Get the primary/secondary balance from a BankAccount
 */
export const getBalance = (data, useNativeBalance = true) => {
    if ('currency' in data) {
        const nativeBalance = useNativeBalance ? data.nativeBalance : undefined;
        let firstBalance = data.availableBalance != null
            ? data.availableBalance
            : data.postedBalance;
        let firstCurrency = data.currency;
        let secondBalance = null;
        let secondCurrency = null;
        let useSecondForSort = true;
        if (nativeBalance) {
            firstBalance =
                nativeBalance.availableBalance != null
                    ? nativeBalance.availableBalance
                    : nativeBalance.postedBalance;
            firstCurrency = nativeBalance.currency;
            secondBalance =
                data.availableBalance != null
                    ? data.availableBalance
                    : data.postedBalance;
            secondCurrency = data.currency;
        }
        if (!data.isClosed) {
            if (data.type === 'CREDITCARD') {
                firstBalance = nativeBalance
                    ? nativeBalance.creditCardBalance
                    : data.creditCardBalance;
                secondBalance = nativeBalance ? data.creditCardBalance : null;
                useSecondForSort = false;
            }
            else if (data.type === 'LOAN') {
                firstBalance = nativeBalance
                    ? nativeBalance.postedBalance
                    : data.postedBalance;
                secondBalance = nativeBalance ? data.postedBalance : null;
                useSecondForSort = false;
            }
        }
        return [
            firstBalance !== null && firstBalance !== undefined
                ? { currency: firstCurrency, amount: firstBalance }
                : null,
            secondBalance !== null && secondBalance !== undefined && secondCurrency
                ? { currency: secondCurrency, amount: secondBalance }
                : null,
            useSecondForSort,
        ];
    }
    return [
        {
            amount: data.balance.value,
            currency: data.balance.currency,
        },
        {
            amount: data.balance.value,
            currency: data.balance.currency,
        },
        false,
    ];
};
