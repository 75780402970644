import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
import useColors from 'hooks/useColors';
export const IconMoneyBag = ({ notificationDot, ...props }) => {
    const colors = useColors();
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="ultimate">
      {notificationDot ? (<>
          <Path fillRule="evenodd" clipRule="evenodd" d="M15.1621 1.38131C12.9265 0.830445 10.5329 0.876439 8.32366 1.5193C7.92439 1.63484 7.73378 2.09843 7.9485 2.46478L8.92346 4.21017C10.4554 3.60059 12.6564 3.45962 14.4999 4.01046C14.4999 4.007 14.4999 4.00353 14.4999 4.00006C14.4999 3.05199 14.7398 2.15995 15.1621 1.38131ZM14.6903 5.4402C13.2783 4.76465 10.1568 4.79484 8.99949 5.61847C8.46388 5.99655 8.17874 6.59055 8.18428 7.21106C9.42204 6.55536 10.8734 6.24018 11.9998 6.24018C13.2057 6.24018 14.3445 6.51612 15.389 6.99954C15.0799 6.52534 14.842 6.00051 14.6903 5.4402ZM3.62012 17.1972C3.62012 12.726 7.27994 7.52844 11.9998 7.52844C16.7196 7.52844 20.3794 12.726 20.3794 17.1972C20.3794 22.3974 15.5966 22.9985 11.9998 22.9985C8.40293 22.9985 3.62012 22.3974 3.62012 17.1972Z" fill={`url(#gradient_${id})`}/>
          <Path d="M24 4C24 6.20914 22.2091 8 20 8C17.7909 8 16 6.20914 16 4C16 1.79086 17.7909 0 20 0C22.2091 0 24 1.79086 24 4Z" fill={colors.elements.negative}/>
        </>) : (<>
          <Path d="M15.6761 1.51924C13.2967 0.826839 10.7032 0.826839 8.32378 1.51924C7.92451 1.63478 7.7339 2.09837 7.94862 2.46472L8.92358 4.21011C10.6189 3.53555 13.1334 3.43483 15.0761 4.21041L16.0512 2.46472C16.266 2.09837 16.0753 1.63482 15.6761 1.51924Z" fill={`url(#gradient_${id})`}/>
          <Path d="M14.9928 5.61446C13.7868 4.76479 10.2489 4.72934 8.99961 5.61841C8.464 5.99649 8.17886 6.59049 8.18441 7.211C9.42216 6.5553 10.8735 6.24012 11.9999 6.24012C13.372 6.24012 14.6572 6.59737 15.8156 7.211C15.8209 6.61318 15.5552 6.01106 14.9928 5.61446Z" fill={`url(#gradient_${id})`}/>
          <Path fill-rule="evenodd" clip-rule="evenodd" d="M3.62024 17.1972C3.62024 12.726 7.28006 7.52838 11.9999 7.52838C16.7197 7.52838 20.3795 12.726 20.3795 17.1972C20.3795 22.3973 15.5967 22.9985 11.9999 22.9985C8.40305 22.9985 3.62024 22.3973 3.62024 17.1972Z" fill={`url(#gradient_${id})`}/>
        </>)}
    </IconWithGradient>);
};
