import React, { useLayoutEffect, useMemo } from 'react';
import { Platform, Text, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Animated, { Extrapolate, interpolate, interpolateColor, useAnimatedStyle, } from 'react-native-reanimated';
import normalize from 'common/Normalize';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import useColors from 'hooks/useColors';
import { useDefaultHeaderHeight } from 'hooks/useHeaderHeight';
import useStyles from 'hooks/useStyles';
import { TAB_SCREEN_HEADER_HEIGHT } from 'navigators/styles';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import createStyleSheets from 'utils/createStyleSheets';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { rem } from './values';
export const NavigationHeader = ({ children, scrollY, title, hideTitleOnScroll, renderTitle, leftContent, rightContent, style, absolute, outputColors, shadowInputRange = [0, 40], bgColorInputRange = [0, 40], headerColor, noPaddingTop, isIniOS13Modal, isInTab, skipSafeArea, regularTitleHeight, hasShadow = true, withFrame, ...props }) => {
    const navigation = useMainStackNavigation();
    const headerHeight = useDefaultHeaderHeight();
    const colors = useColors();
    const styles = useStyles(styleSet);
    useLayoutEffect(() => {
        if (!isInTab) {
            navigation.setOptions({
                ...(!!leftContent && {
                    headerLeft: () => leftContent,
                }),
                ...(!!rightContent && {
                    headerRight: () => rightContent,
                }),
            });
        }
    }, [isInTab, leftContent, rightContent]);
    const headerBackground = useAnimatedStyle(() => ({
        shadowOpacity: interpolate(scrollY.value, shadowInputRange, [0, 1]),
    }));
    const mask = useAnimatedStyle(() => ({
        backgroundColor: interpolateColor(scrollY.value, bgColorInputRange, outputColors ||
            [colors.background.dark, colors.cards.onDark]),
    }));
    const androidShadowOpacity = useAnimatedStyle(() => ({
        opacity: interpolate(scrollY.value, shadowInputRange, [0, 1], Extrapolate.CLAMP),
    }));
    const titleContainer = useAnimatedStyle(() => ({
        opacity: interpolate(scrollY.value, bgColorInputRange, hideTitleOnScroll ? [0, 1] : [1, 1], Extrapolate.CLAMP),
    }));
    const { top } = useSafeAreaInsets();
    const { paddingHorizontalStyle } = useAppFrameDimensions(isInTab);
    const containerStyle = useMemo(() => {
        let height = headerHeight;
        if (noPaddingTop)
            height = 0;
        if (isIniOS13Modal)
            height = undefined;
        if (isInTab && !regularTitleHeight)
            height = TAB_SCREEN_HEADER_HEIGHT + top;
        return [
            {
                height,
                backgroundColor: headerColor,
                paddingTop: skipSafeArea ? 0 : top,
            },
            styles.header,
        ];
    }, [
        headerColor,
        headerHeight,
        isInTab,
        noPaddingTop,
        regularTitleHeight,
        isIniOS13Modal,
        skipSafeArea,
        styles.header,
        top,
    ]);
    return (<Animated.View style={[
            styles.background,
            hasShadow ? headerBackground : styles.noShadow,
            absolute && styles.absolute,
            { backgroundColor: headerColor },
            style,
        ]} {...props}>
      <Animated.View style={[styles.mask, mask]}/>
      <View style={containerStyle}>
        {!!isInTab && <View style={styles.sideContainer}>{leftContent}</View>}
        <Animated.View style={[styles.titleContainer, titleContainer]}>
          {renderTitle ? (renderTitle(styles.title)) : (<Text style={styles.title}>{title}</Text>)}
        </Animated.View>
        {!!isInTab && <View style={styles.sideContainer}>{rightContent}</View>}
      </View>
      <View style={{ backgroundColor: headerColor }}>
        <View style={withFrame && paddingHorizontalStyle}>{children}</View>
        {Boolean(Platform.OS === 'android') && hasShadow && (<Animated.View style={androidShadowOpacity}>
            <LinearGradient pointerEvents="none" style={styles.androidShadow} colors={[
                colors.shadows.androidShadowGradientStart,
                colors.shadows.androidShadowGradientEnd,
            ]}/>
          </Animated.View>)}
      </View>
      {props.overlay && <props.overlay />}
    </Animated.View>);
};
const styleSet = createStyleSheets((colors) => ({
    absolute: {
        position: 'absolute',
    },
    mask: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
    header: {
        justifyContent: 'center',
        flexDirection: 'row',
        zIndex: 2,
    },
    background: {
        left: 0,
        right: 0,
        zIndex: 1,
        ...colors.shadows.card,
    },
    noShadow: {
        shadowOpacity: 0,
    },
    androidShadow: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 10,
    },
    titleContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    sideContainer: {
        flex: 1.1 / 3,
        justifyContent: 'center',
    },
    title: {
        color: colors.text.primary,
        fontFamily: 'Montserrat-Regular',
        fontSize: normalize(14.4),
        lineHeight: normalize(14.4) * 1.1,
    },
    titlePadded: {
        paddingVertical: rem(16),
    },
}));
