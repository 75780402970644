export const unique = (array, key) => {
    const keySet = new Set();
    return array.filter((item) => {
        if (keySet.has(item[key])) {
            return false;
        }
        keySet.add(item[key]);
        return true;
    });
};
