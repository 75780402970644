import { useState, useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { CALL_API } from '../middleware/api';
import * as fetch from './fetch';
export const muteAll = {
    muteError: true,
    muteResult: true,
    muteFetching: true,
};
export const muteAllButThrows = {
    ...muteAll,
    throws: true,
};
export const muteErrorAndFetching = {
    muteError: true,
    muteFetching: true,
};
export const muteErrorAndResult = {
    muteError: true,
    muteResult: true,
};
export const muteErrorAndResultButThrows = {
    throws: true,
    muteError: true,
    muteResult: true,
};
/**
 * Use this hook when you need to have the API result as component state.
 */
export const useFetchOld = (options) => {
    const [result, setResult] = useState(undefined);
    const [error, setError] = useState(undefined);
    const [fetching, setFetching] = useState(false);
    const dispatch = useAppDispatch();
    const fetchCall = useCallback(async (apiCall) => {
        if (!options?.muteFetching) {
            setFetching(true);
        }
        if (!options || !options?.muteResult) {
            setResult(undefined);
        }
        if (!options?.muteError) {
            setError(undefined);
        }
        const [, success] = apiCall[CALL_API].types;
        if (options?.debug)
            fetch.debugCall(apiCall[CALL_API]);
        const answer = options?.fakeResponse
            ? { type: success, payload: options.fakeResponse }
            : await dispatch(apiCall);
        if (options?.fakeResponse) {
            if (options?.delay) {
                await new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(true);
                    }, options.delay);
                });
            }
            dispatch({
                type: success,
                payload: options.fakeResponse,
                extra: options.extra,
            });
        }
        if (answer?.type === success) {
            const result = answer;
            if (!options?.muteResult) {
                setResult(result.payload);
            }
            if (options?.debug)
                fetch.debugAnswer(result);
            if (!options?.muteFetching) {
                setFetching(false);
            }
            return result.payload;
        }
        const error = answer;
        if (!options?.muteError) {
            setError(error);
        }
        if (options?.debug && error)
            fetch.debugError(error);
        if (!options?.muteFetching) {
            setFetching(false);
        }
        if (options?.throws) {
            if (error) {
                throw new fetch.ThrowableFetchError(error);
            }
            else {
                throw new Error('Refresh token expired');
            }
        }
        return null;
    }, [dispatch, options]);
    return [result, error, fetching, fetchCall];
};
export default useFetchOld;
