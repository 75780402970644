import React, { useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import Button from 'design-system/Button';
import { rem } from 'design-system/values';
import DateRangeSelector from 'design-system/date/DateRangeSelector';
import { isWeb } from '../../../../constants';
const SelectDateRangeModalContent = ({ data, onFinish }) => {
    const [range, setRange] = useState(data);
    const save = useCallback(() => {
        const { start, end } = range;
        if (start && end) {
            onFinish({ start, end });
        }
    }, [onFinish, range]);
    return (<View>
      {!isWeb ? (<Text Text-24 style={styles.title}>
          Select a range
        </Text>) : (<Spacer h={16}/>)}
      <Text TextThin-14 Gray style={styles.subtitle}>
        Select the period you are expecting this recurring payment to happen.
      </Text>
      <DateRangeSelector type="range" allowReverseDates start={range?.start} end={range?.end} style={styles.date} onChange={setRange}/>
      <Button brand title="Save" onPress={save}/>
    </View>);
};
const styles = StyleSheet.create({
    title: {
        marginTop: rem(49),
        marginBottom: rem(8),
    },
    subtitle: {
        marginBottom: rem(32),
    },
    date: {
        marginBottom: rem(35),
    },
});
export default SelectDateRangeModalContent;
