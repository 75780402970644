/* eslint-disable react/jsx-no-useless-fragment */
import React, { memo, useRef, useState, useEffect, useCallback, } from 'react';
import { View } from 'react-native';
import Animated from 'react-native-reanimated';
import { FlashList } from '@shopify/flash-list';
import Button from 'design-system/Button';
import Indicator from 'design-system/Indicator';
import { CardView } from 'design-system/CardView';
import SectionHeader from 'design-system/SectionHeader';
import { rem, SCROLL_INDICATOR_INSETS } from 'design-system/values';
import { getSimilarTransactions, resetSimilarTransactions, } from 'actions';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useEstimatedListSize from 'hooks/useEstimatedListSize';
import { keyExtractor } from '../../utils';
import { isWeb } from '../../../../constants';
import RowTransaction from '../RowTransaction';
import EditMultipleListRow from './EditMutipleListRow';
const estimatedItemSize = rem(98);
const AnimatedFlashList = Animated.createAnimatedComponent(FlashList);
const emptySelectedObj = {};
const EditMultipleList = ({ isFetchingEdit, listContainerStyle, scrollHandler, filterOut, isSelectedAll, transaction, onPressEdit, ListHeader, }) => {
    const styles = useStyles(styleSet);
    const [state, setState] = useState({
        selectedObj: emptySelectedObj,
    });
    const shouldLoadNextPage = useRef(false);
    const dispatch = useAppDispatch();
    const isFetching = useAppSelector((store) => store.transactions.isFetchingSimilar);
    const similarTransactions = useAppSelector((store) => store.transactions.similarTransactions);
    const pageCount = useAppSelector((store) => store.transactions.similarPageCount);
    const page = useAppSelector((store) => store.transactions.similarPage);
    useEffect(() => {
        dispatch(resetSimilarTransactions());
        dispatch(getSimilarTransactions({
            id: transaction.id,
            excludeCategoryId: filterOut?.id,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /**
     * hook to unselect all
     */
    useEffect(() => {
        if (!isSelectedAll) {
            setState((oldState) => ({
                ...oldState,
                selectedObj: emptySelectedObj,
            }));
        }
    }, [isSelectedAll]);
    /**
     * hook to select all
     */
    useEffect(() => {
        if (isSelectedAll) {
            setState((oldState) => ({
                ...oldState,
                selectedObj: similarTransactions.reduce((prev, curr) => ({
                    ...prev,
                    [curr.id]: curr.category.id,
                }), {}),
            }));
        }
    }, [isSelectedAll, similarTransactions]);
    const loadNext = useCallback(() => {
        if (typeof pageCount === 'number' && typeof page === 'number') {
            if (page < pageCount) {
                dispatch(getSimilarTransactions({
                    id: transaction.id,
                    page: page + 1,
                    excludeCategoryId: filterOut?.id,
                }));
            }
        }
    }, [filterOut?.id, page, pageCount, transaction.id]);
    useEffect(() => {
        if (!isFetching && (shouldLoadNextPage.current || isSelectedAll)) {
            shouldLoadNextPage.current = false;
            loadNext();
        }
    }, [isFetching, isSelectedAll, loadNext]);
    const onLoadNextPage = useCallback(() => {
        if (isFetching) {
            shouldLoadNextPage.current = true;
        }
        else {
            loadNext();
        }
    }, [isFetching, loadNext]);
    const onPress = useCallback(() => {
        onPressEdit({
            ...state.selectedObj,
            [transaction.id]: transaction.category.id,
        });
    }, [onPressEdit, state.selectedObj, transaction.category, transaction.id]);
    const onSelected = useCallback((data) => {
        setState((oldState) => {
            if (oldState.selectedObj[data.id]) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { [data.id]: id, ...newSelectedObj } = oldState.selectedObj;
                return {
                    ...oldState,
                    selectedObj: newSelectedObj,
                };
            }
            return {
                ...oldState,
                selectedObj: {
                    ...state.selectedObj,
                    [data.id]: data.category.id,
                },
            };
        });
    }, [state.selectedObj]);
    const onEndReached = useCallback(() => {
        onLoadNextPage();
    }, [onLoadNextPage]);
    const txnIndexLength = similarTransactions.length - 1;
    const renderItem = useCallback(({ item, index }) => (<EditMultipleListRow item={item} onSelected={onSelected} isFirstItem={index === 0} isLastItem={index === txnIndexLength} selected={!!state.selectedObj[item.id]}/>), [onSelected, state.selectedObj, txnIndexLength]);
    const renderListHeader = useCallback(() => (<View>
        {ListHeader || <></>}
        <CardView noMarginTop noMarginBottom webPadding>
          <RowTransaction item={transaction} disabled backgroundDark/>
        </CardView>
        {!similarTransactions.length ? null : (<SectionHeader horizontalMargins style={styles.sectionHeader}>
            Similar transactions
          </SectionHeader>)}
      </View>), [ListHeader, similarTransactions.length, styles.sectionHeader, transaction]);
    const renderProgress = useCallback(() => !similarTransactions.length && isFetching ? (<View style={styles.indicatorContainer}>
          <Indicator dark/>
        </View>) : null, [isFetching, similarTransactions.length, styles.indicatorContainer]);
    const estimatedListSize = useEstimatedListSize(similarTransactions);
    const selectedTxnLen = Object.keys(state.selectedObj).length + 1;
    const transactionText = selectedTxnLen > 1 ? 'transactions' : 'transaction';
    const title = `Apply edit to ${selectedTxnLen} ${transactionText}`;
    const baseProps = {
        renderItem,
        keyExtractor,
        onEndReached,
        scrollEventThrottle: 16,
        onScroll: scrollHandler,
        data: similarTransactions,
        onEndReachedThreshold: 0.8,
        ListFooterComponent: renderProgress,
        ListHeaderComponent: renderListHeader,
        contentContainerStyle: listContainerStyle,
        scrollIndicatorInsets: SCROLL_INDICATOR_INSETS,
    };
    return (<View style={styles.content}>
      {isWeb ? (<Animated.FlatList {...baseProps}/>) : (<AnimatedFlashList {...baseProps} extraData={state} estimatedListSize={estimatedListSize} estimatedItemSize={estimatedItemSize}/>)}
      <Button brand floating title={title} onPress={onPress} isFetching={isFetchingEdit ||
            (isSelectedAll &&
                (typeof page !== 'number' ||
                    typeof pageCount !== 'number' ||
                    page < (pageCount || 0)))}/>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    content: {
        flex: 1,
    },
    cardView: {
        backgroundColor: colors.cards.onLight,
    },
    sectionHeader: {
        marginTop: rem(24),
        marginBottom: rem(16),
    },
    indicatorContainer: {
        marginTop: rem(16),
    },
}));
export default memo(EditMultipleList);
