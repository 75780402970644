const getListAccounts = (data, spaceId) => {
    const accounts = [];
    for (let index = 0; index < data.length; index += 1) {
        for (let x = 0; x < data[index].accounts.length; x += 1) {
            const account = { ...data[index].accounts[x] };
            if (!spaceId || account.spaceId === spaceId) {
                accounts.push(account);
            }
        }
    }
    return accounts;
};
export default getListAccounts;
