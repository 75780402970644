import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
const StackHeader = ({ title, width }) => (<View style={[styles.reanimatedHeader, { width }]}>
    <Text Text-18 numberOfLines={1}>
      {title}
    </Text>
  </View>);
const styles = StyleSheet.create({
    reanimatedHeader: {
        alignItems: 'center',
    },
});
export default memo(StackHeader);
