import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
const IconMerchant = (props) => {
    const id = useId();
    return (<IconWithGradient viewBox="0 0 24 24" id={id} width={24} height={24} defaultGradientKey="darkPurple" {...props}>
      <Path fill={`url(#gradient_${id})`} d="M18.841 19.071a1.285 1.285 0 1 1-2.57 0 1.285 1.285 0 0 1 2.57 0Zm-9.643-1.285a1.286 1.286 0 1 0 0 2.57 1.286 1.286 0 0 0 0-2.57ZM21.1 7.113a.643.643 0 0 0-.513-.256H6.652L6 4.575a1.291 1.291 0 0 0-1.236-.932H3.412a.643.643 0 1 0 0 1.286h1.352l.782 2.736.007.024L7.67 15.1A1.937 1.937 0 0 0 9.525 16.5h7.703a1.937 1.937 0 0 0 1.855-1.399l2.12-7.424a.642.642 0 0 0-.104-.564Z"/>
    </IconWithGradient>);
};
export default IconMerchant;
