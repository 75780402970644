export const GET_RENT_REPORTING_REQUEST = 'GET_RENT_REPORTING_REQUEST';
export const GET_RENT_REPORTING_SUCCESS = 'GET_RENT_REPORTING_SUCCESS';
export const GET_RENT_REPORTING_FAILURE = 'GET_RENT_REPORTING_FAILURE';
export const GET_RENT_REPORTING_BY_ID_REQUEST = 'GET_RENT_REPORTING_BY_ID_REQUEST';
export const GET_RENT_REPORTING_BY_ID_SUCCESS = 'GET_RENT_REPORTING_BY_ID_SUCCESS';
export const GET_RENT_REPORTING_BY_ID_FAILURE = 'GET_RENT_REPORTING_BY_ID_FAILURE';
export const PATCH_RENT_REPORTING_BY_ID_REQUEST = 'PATCH_RENT_REPORTING_BY_ID_REQUEST';
export const PATCH_RENT_REPORTING_BY_ID_SUCCESS = 'PATCH_RENT_REPORTING_BY_ID_SUCCESS';
export const PATCH_RENT_REPORTING_BY_ID_FAILURE = 'PATCH_RENT_REPORTING_BY_ID_FAILURE';
export const DELETE_RENT_REPORTING_BY_ID_REQUEST = 'DELETE_RENT_REPORTING_BY_ID_REQUEST';
export const DELETE_RENT_REPORTING_BY_ID_SUCCESS = 'DELETE_RENT_REPORTING_BY_ID_SUCCESS';
export const DELETE_RENT_REPORTING_BY_ID_FAILURE = 'DELETE_RENT_REPORTING_BY_ID_FAILURE';
export const SET_UP_RENT_REPORTING_REQUEST = 'SET_UP_RENT_REPORTING_REQUEST';
export const SET_UP_RENT_REPORTING_SUCCESS = 'SET_UP_RENT_REPORTING_SUCCESS';
export const SET_UP_RENT_REPORTING_FAILURE = 'SET_UP_RENT_REPORTING_FAILURE';
export const GET_RENT_REPORTING_SUGGESTED_PAYMENTS_REQUEST = 'GET_RENT_REPORTING_SUGGESTED_PAYMENTS_REQUEST';
export const GET_RENT_REPORTING_SUGGESTED_PAYMENTS_SUCCESS = 'GET_RENT_REPORTING_SUGGESTED_PAYMENTS_SUCCESS';
export const GET_RENT_REPORTING_SUGGESTED_PAYMENTS_FAILURE = 'GET_RENT_REPORTING_SUGGESTED_PAYMENTS_FAILURE';
export const DIMISSED_UPGRADE_FOR_MORE_RENT_REPORTING_AGENCIES = 'DIMISSED_UPGRADE_FOR_MORE_RENT_REPORTING_AGENCIES ';
