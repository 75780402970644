import { LOGGED_OUT } from 'actions/types';
import { SET_SMART_RULES_FILTER_ACTIVE } from '../actions/types';
const initialState = {
    isFiltersActive: false,
};
export const smartRules = (state = initialState, action) => {
    switch (action.type) {
        case SET_SMART_RULES_FILTER_ACTIVE:
            return { ...state, isFiltersActive: action.payload };
        case LOGGED_OUT: {
            return initialState;
        }
        default:
            return state;
    }
};
