import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
const IconCategoryCash = (props) => (<Svg width={props.width} height={props.height} fill="none" {...props}>
    <Path d="M11.805 9.213c0 1.307 2.603 1.99 5.174 1.99s5.174-.683 5.174-1.99c0-2.612-10.348-2.612-10.348 0Z" fill={props.fill}/>
    <Path d="M3.05 14.786c0 1.306 2.602 1.99 5.173 1.99s5.174-.684 5.174-1.99c0-2.613-10.348-2.613-10.348 0Z" fill={props.fill}/>
    <Path d="M13.205 16.713a.4.4 0 0 0-.39-.012c-1.072.562-2.703.871-4.592.871-1.889 0-3.52-.31-4.591-.87a.4.4 0 0 0-.583.352v.12c0 1.306 2.603 1.99 5.174 1.99s5.174-.684 5.174-1.99v-.12a.398.398 0 0 0-.192-.34Z" fill={props.fill}/>
    <Path d="m10.61 8.816.148-.002a.397.397 0 0 0 .369-.257c.383-1.006 1.759-1.717 3.874-2a.398.398 0 0 0 .18-.717c-.854-.62-2.605-1.004-4.57-1.004-2.571 0-5.174.684-5.174 1.99s2.603 1.99 5.174 1.99Z" fill={props.fill}/>
    <Path d="m10.611 11.205.169-.003a.398.398 0 0 0 .354-.222c.053-.107.107-.192.165-.26a.398.398 0 0 0 .026-.481 1.858 1.858 0 0 1-.192-.375c-.06-.157-.294-.253-.442-.253l-.08.002c-1.889 0-3.52-.31-4.591-.87a.4.4 0 0 0-.583.352v.12c0 1.306 2.603 1.99 5.174 1.99Z" fill={props.fill}/>
    <Path d="M21.962 13.53a.4.4 0 0 0-.391-.012c-1.072.561-2.702.87-4.591.87a14.45 14.45 0 0 1-2.34-.183.399.399 0 0 0-.462.424l.016.158c0 .148-.021.3-.064.464a.399.399 0 0 0 .306.491c.773.156 1.652.238 2.544.238 2.57 0 5.174-.683 5.174-1.99v-.12a.398.398 0 0 0-.192-.34Z" fill={props.fill}/>
    <Path d="M21.961 11.141a.4.4 0 0 0-.39-.012c-1.072.561-2.703.87-4.592.87-1.889 0-3.52-.309-4.591-.87a.4.4 0 0 0-.583.353v.12c0 1.306 2.603 1.99 5.174 1.99s5.174-.684 5.174-1.99v-.12a.398.398 0 0 0-.192-.34Z" fill={props.fill}/>
    <Path d="M5.867 12.178a.394.394 0 0 0 .063-.005c.152-.024.307-.045.462-.064a.397.397 0 0 0 .1-.764 5.357 5.357 0 0 1-.472-.216.4.4 0 0 0-.583.353v.12c0 .098.017.198.052.303a.398.398 0 0 0 .378.273Z" fill={props.fill}/>
  </Svg>);
export default IconCategoryCash;
