import React, { useCallback } from 'react';
import { View } from 'react-native';
import { AppView } from 'design-system/AppView';
import Button from 'design-system/Button';
import StatusBar from 'design-system/StatusBar';
import Text from 'design-system/Text';
import { IconEmma } from 'design-system/icons';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import OnboardingSquircle from 'login/components/OnboardingSquircle';
import createStyleSheets from 'utils/createStyleSheets';
import Links from 'utils/links';
const OnboardingMobileWeb = () => {
    const styles = useStyles(styleSet);
    const onPressOpen = useCallback(() => {
        Links.openLink('https://emmaapp.page.link/6bTW');
    }, []);
    const colors = useColors();
    return (<View style={styles.container}>
      <AppView type="primary" withFrame={false} style={styles.contentContainer}>
        <StatusBar barStyle="dark-content" animated/>
        <View style={styles.titleContainer}>
          <OnboardingSquircle rotate color={colors.cards.onLight} large2 style={styles.logoContainer}>
            <IconEmma width={80} height={80}/>
          </OnboardingSquircle>
          <Text Text-24 OffBlack centered style={styles.title}>
            Welcome to{'\n'}Emma for Web
          </Text>
          <Text TextThin-16 Secondary centered>
            We don’t currently support a mobile web version. Switch to the app
            to use Emma on a mobile phone.
          </Text>
        </View>
        <Button title="Open the app" brand floating onPress={onPressOpen}/>
      </AppView>
    </View>);
};
const styleSet = createStyleSheets(() => ({
    container: {
        //@ts-ignore
        position: 'fixed',
        //@ts-ignore
        height: '100dvh',
        overflow: 'hidden',
    },
    contentContainer: {
        paddingVertical: rem(32),
    },
    titleContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: rem(32),
        marginBottom: rem(32),
        paddingBottom: rem(12),
    },
    title: {
        marginBottom: rem(8),
    },
    logoContainer: {
        flexShrink: 1,
        alignSelf: 'center',
        marginBottom: rem(32),
    },
}));
export default OnboardingMobileWeb;
