import { useCallback } from 'react';
const useAmountHandlers = (setState, onSelectFilter, onToggleAmountFilter, currencySymbol) => {
    const onChangeAmountLower = useCallback((text) => {
        const value = text.replace(/,/g, '.');
        onSelectFilter({
            value,
            badgeText: `<${currencySymbol}`,
            filterType: 'AMOUNT_LOWER',
        });
        setState((currentState) => ({
            ...currentState,
            amountLowerValue: value,
        }));
    }, [currencySymbol, onSelectFilter, setState]);
    const onChangeAmountUpper = useCallback((text) => {
        const value = text.replace(/,/g, '.');
        onSelectFilter({
            value,
            badgeText: `>${currencySymbol}`,
            filterType: 'AMOUNT_UPPER',
        });
        setState((currentState) => ({
            ...currentState,
            amountUpperValue: value,
        }));
    }, [currencySymbol, onSelectFilter, setState]);
    const onResetLowerAmount = useCallback(() => {
        setState((currentState) => ({ ...currentState, amountLowerValue: '' }));
    }, [setState]);
    const onResetUpperAmount = useCallback(() => {
        setState((currentState) => ({ ...currentState, amountUpperValue: '' }));
    }, [setState]);
    const hideAmountInput = useCallback(() => {
        onToggleAmountFilter();
        setState((currentState) => ({ ...currentState, selectedFilter: null }));
    }, [onToggleAmountFilter, setState]);
    return [onChangeAmountLower, onChangeAmountUpper, onResetLowerAmount, onResetUpperAmount, hideAmountInput];
};
export default useAmountHandlers;
