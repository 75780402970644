import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from './Icon';
export const IconLock = ({ alt, ...props }) => {
    const id = useId();
    if (alt) {
        return (<IconWithGradient id={id} {...props} defaultGradientKey="lightPink">
        <Path fillRule="evenodd" clipRule="evenodd" d="M11.1374 4.51386C11.703 4.37049 12.2969 4.37049 12.8625 4.51386C14.1819 4.84832 15.1258 5.96028 15.2306 7.26587L15.1915 7.25782C13.0872 6.82413 10.9128 6.82413 8.8085 7.25782L8.76936 7.26589C8.87413 5.96028 9.81803 4.84832 11.1374 4.51386ZM16.685 7.50839V7.7184C18.1592 8.40029 19.2768 9.68843 19.6983 11.2674C20.1955 13.1297 20.0758 15.0973 19.3563 16.8896L19.2718 17.1C18.5 19.0225 16.7426 20.4054 14.6498 20.7371L13.9687 20.845C12.6648 21.0517 11.3352 21.0517 10.0313 20.845L9.35019 20.7371C7.25734 20.4054 5.49999 19.0225 4.72818 17.1L4.64368 16.8895C3.92417 15.0973 3.80447 13.1297 4.30168 11.2674C4.72321 9.68846 5.84078 8.40033 7.31494 7.71843V7.50838C7.31494 5.45953 8.7365 3.66924 10.7734 3.15292C11.5777 2.94903 12.4223 2.94903 13.2266 3.15292C15.2634 3.66924 16.685 5.45954 16.685 7.50839ZM12 12.8504C12.3192 12.8504 12.5779 13.1022 12.5779 13.4129V15.288C12.5779 15.5987 12.3192 15.8506 12 15.8506C11.6809 15.8506 11.4221 15.5987 11.4221 15.288V13.4129C11.4221 13.1022 11.6809 12.8504 12 12.8504Z" fill={`url(#gradient_${id})`}/>
      </IconWithGradient>);
    }
    return (<IconWithGradient id={id} {...props} defaultGradientKey="white">
      <Path fillRule="evenodd" clipRule="evenodd" d="M17.5227 7.39601V8.92935C19.2451 9.46696 20.5 11.0261 20.5 12.8884V17.8253C20.5 20.1308 18.5886 22 16.2322 22H7.7688C5.41136 22 3.5 20.1308 3.5 17.8253V12.8884C3.5 11.0261 4.75595 9.46696 6.47729 8.92935V7.39601C6.48745 4.41479 8.95667 2 11.9848 2C15.0535 2 17.5227 4.41479 17.5227 7.39601ZM12.0051 3.73904C14.0678 3.73904 15.7445 5.37871 15.7445 7.39601V8.7137H8.25553V7.37613C8.26569 5.36878 9.94232 3.73904 12.0051 3.73904ZM12.8891 16.4549C12.8891 16.9419 12.4928 17.3294 11.9949 17.3294C11.5072 17.3294 11.1109 16.9419 11.1109 16.4549V14.2488C11.1109 13.7718 11.5072 13.3843 11.9949 13.3843C12.4928 13.3843 12.8891 13.7718 12.8891 14.2488V16.4549Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default IconLock;
