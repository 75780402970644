import * as React from 'react';
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconUploadCloud = ({ ...props }) => {
    const id = React.useId();
    return (<IconWithGradient id={id} defaultGradientKey="green" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
      <G clipPath="url(#clip0_582_76256)">
        <Path strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" stroke={`url(#gradient_${id})`} d="M16.5 16.4995L12.5 12.4995M12.5 12.4995L8.49996 16.4995M12.5 12.4995V21.4995M20.89 18.8895C21.8653 18.3578 22.6358 17.5164 23.0798 16.4981C23.5239 15.4799 23.6162 14.3427 23.3422 13.2662C23.0682 12.1896 22.4434 11.235 21.5666 10.5529C20.6898 9.87088 19.6108 9.50023 18.5 9.4995H17.24C16.9373 8.32874 16.3731 7.24183 15.5899 6.32049C14.8067 5.39915 13.8248 4.66735 12.7181 4.1801C11.6113 3.69286 10.4085 3.46285 9.20008 3.50738C7.99164 3.5519 6.80903 3.8698 5.74114 4.43716C4.67325 5.00453 3.74787 5.8066 3.03458 6.78308C2.32129 7.75956 1.83865 8.88503 1.62294 10.0749C1.40723 11.2647 1.46407 12.488 1.78917 13.6528C2.11428 14.8175 2.6992 15.8934 3.49996 16.7995"/>
      </G>
      <Defs>
        <ClipPath id="clip0_582_76256">
          <Rect width="24" height="24" fill="white" transform="translate(0.5 0.5)"/>
        </ClipPath>
      </Defs>
    </IconWithGradient>);
};
