import { Image } from 'react-native';
const cacheControl = {
    immutable: 'immutable',
    web: 'web',
    cacheOnly: 'cacheOnly',
};
const FastImage = {
    cacheControl,
    ...Image,
};
export default FastImage;
