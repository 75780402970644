import React, { useState, useCallback, useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity, } from 'react-native';
import Animated, { withTiming, useSharedValue, useAnimatedStyle, cancelAnimation, } from 'react-native-reanimated';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { rem } from './values';
const initialState = {
    visible: false,
    content: null,
    fx: undefined,
    fy: undefined,
    onHoverOnly: undefined,
};
export const defaultPopUpWidth = 350;
const PopUpMenuModal = () => {
    const opacitySharedValue = useSharedValue(0);
    const [state, setState] = useState(initialState);
    const styles = useStyles(styleSet);
    const close = useCallback(() => {
        setState((state) => ({
            ...state,
            visible: false,
        }));
    }, []);
    const cleanup = useCallback(() => {
        if (PopUpMenuModal.onDidHideCallback) {
            const callback = PopUpMenuModal.onDidHideCallback;
            if (typeof callback === 'function') {
                callback();
                PopUpMenuModal.onDidHideCallback = undefined;
            }
        }
    }, []);
    useEffect(() => {
        if (state.visible) {
            cancelAnimation(opacitySharedValue);
            opacitySharedValue.value = withTiming(1, {
                duration: 100,
            });
        }
    }, [opacitySharedValue, state.visible]);
    useEffect(() => {
        if (!state.visible) {
            cleanup();
        }
    }, [cleanup, state.visible]);
    useEffect(() => {
        if (PopUpMenuModal.setStateRef === undefined)
            PopUpMenuModal.setStateRef = undefined;
        PopUpMenuModal.setStateRef = setState;
    }, []);
    const containerAnimatedStyles = useAnimatedStyle(() => ({
        opacity: opacitySharedValue.value,
    }), [opacitySharedValue.value]);
    if (!state.visible || !state.fx || !state.fy) {
        return null;
    }
    return (<Animated.View style={[styles.container, containerAnimatedStyles]} pointerEvents={'box-none'}>
      {!state.onHoverOnly && (<TouchableOpacity style={styles.touchable} onPress={close}>
          <View />
        </TouchableOpacity>)}
      <View style={[
            styles.contentContainer,
            {
                width: state.modalWidth || defaultPopUpWidth,
                top: state.fy,
                left: state.fx,
            },
            state.options?.containerStyle,
        ]}>
        {state.content}
      </View>
    </Animated.View>);
};
PopUpMenuModal.show = (content, options) => {
    if (PopUpMenuModal.setStateRef) {
        if (options?.onDidHideCallback) {
            if (PopUpMenuModal.onDidHideCallback === undefined) {
                PopUpMenuModal.onDidHideCallback = undefined;
            }
            PopUpMenuModal.onDidHideCallback = options.onDidHideCallback;
        }
        const setState = PopUpMenuModal.setStateRef;
        if (setState) {
            setState({
                visible: true,
                content,
                options,
                fx: options.fx,
                fy: options.fy,
                onHoverOnly: !!options.onHoverOnly,
            });
        }
    }
};
PopUpMenuModal.hide = (onDidHideCallback) => {
    if (PopUpMenuModal.setStateRef) {
        if (onDidHideCallback) {
            if (PopUpMenuModal.onDidHideCallback === undefined) {
                PopUpMenuModal.onDidHideCallback = undefined;
            }
            PopUpMenuModal.onDidHideCallback = onDidHideCallback;
        }
        const setState = PopUpMenuModal.setStateRef;
        if (setState) {
            setState(initialState);
        }
    }
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        ...StyleSheet.absoluteFillObject,
        zIndex: 99,
    },
    contentContainer: {
        padding: rem(8),
        overflow: 'hidden',
        borderWidth: rem(1),
        position: 'absolute',
        borderRadius: rem(14),
        borderColor: colors.borders.divider,
        backgroundColor: colors.background.light,
    },
    touchable: {
        flex: 1,
        cursor: 'auto',
    },
}));
export default PopUpMenuModal;
