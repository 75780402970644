import React, { useEffect, useMemo, useRef } from 'react';
import { StyleSheet, View, } from 'react-native';
import Button from 'design-system/Button';
import { rem } from 'design-system/values';
import Text from 'design-system/Text';
import LogoBankAccount from 'design-system/LogoBankAccount';
import useColors from 'hooks/useColors';
import normalize from 'common/Normalize';
import { useAppSelector } from 'store/hooks';
import { selectIsScrambled } from 'hooks/useScrambleMode';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import EditTransactionSelect from 'features/transactions/components/multiple/EditTransactionSelect';
import RowAccountBalance from './RowAccountBalance';
import { isWeb } from '../../../constants';
/**
 * If you need to show this within a card, use `onCard`, which makes logo icons use darker background in dark mode.
 */
const RowAccount = ({ data, disabled, subtitle, style, selected, isSelectingAccounts, primaryBalanceOnly, tintColor, onCard, small, hideLogo, onPress, onRowLongPressed, onOpenLogins, onOpenUpgrade, forceShowAction, hideActionRequiredText, logo, showHidden, isMultipleSelect, }) => {
    const disableCTA = useRef(false);
    const touchViewRef = useRef(null);
    const timeoutRef = useRef();
    const openLogins = () => {
        if (disableCTA.current) {
            return;
        }
        disableCTA.current = true;
        if (onOpenLogins) {
            onOpenLogins(data, touchViewRef);
        }
        timeoutRef.current = setTimeout(() => {
            disableCTA.current = false;
        }, 1000);
    };
    useEffect(() => () => {
        clearTimeout(timeoutRef.current);
    }, []);
    const openUpgrade = () => {
        if (onOpenUpgrade)
            onOpenUpgrade(data, touchViewRef);
    };
    const pressed = () => {
        if (onPress)
            onPress(data, touchViewRef);
    };
    const longPressed = () => {
        if (onRowLongPressed)
            onRowLongPressed(data, touchViewRef);
    };
    const renderSelect = () => {
        if (!isSelectingAccounts)
            return null;
        return (<View style={styles.selectWrap}>
        <EditTransactionSelect selected={selected} style={styles.checkOnSquircle} isMultipleSelect={isMultipleSelect}/>
      </View>);
    };
    const showRenewAccess = data.needsReauth ||
        data.needsReconsent ||
        (!data.isClosed && data.isRemoved);
    const renderAccountBalance = () => {
        if (onOpenUpgrade &&
            (data.ultimatePreviewAccount ||
                data.proPreviewAccount ||
                data.deactivatedOverQuota)) {
            return (<View style={styles.buttonContainer} pointerEvents={isWeb ? 'none' : 'auto'}>
          <Button brandReversed small square containerStyle={styles.renewButton} onPress={openUpgrade} title={data.deactivatedOverQuota ? 'Activate' : 'Upgrade'}/>
        </View>);
        }
        if (showRenewAccess && (!disabled || forceShowAction) && onOpenLogins) {
            return (<View style={styles.buttonContainer}>
          <Button brandReversed small square containerStyle={styles.renewButton} onPress={openLogins} title="Renew access"/>
        </View>);
        }
        return (<RowAccountBalance data={data} subtitle={subtitle} primaryBalanceOnly={primaryBalanceOnly} tintColor={tintColor} small={small}/>);
    };
    const scrambled = useAppSelector(selectIsScrambled);
    const colors = useColors();
    const renderDetails = () => {
        let { sortCode, accountNumber } = data;
        if (scrambled) {
            sortCode = '00-00-00';
            accountNumber = '12345678';
        }
        else if (sortCode) {
            sortCode = sortCode.replace(/(\d\d)(\d\d)(\d\d)/, '$1-$2-$3');
        }
        if (data.type !== 'CREDITCARD' &&
            accountNumber &&
            accountNumber.length === 4) {
            accountNumber = `•••••• ${data.accountNumber}`;
        }
        if (data.type === 'CREDITCARD' &&
            accountNumber &&
            accountNumber.length === 4) {
            accountNumber = `•••• •••• •••• ${data.accountNumber}`;
        }
        if (!showRenewAccess && !sortCode && !accountNumber)
            return null;
        const color = tintColor === colors.text.white ? { White: true } : { Gray: true };
        return (<Text TextThin-14 numberOfLines={1} ellipsizeMode="tail" {...color}>
        {showRenewAccess && !hideActionRequiredText
                ? 'Action required'
                : `${sortCode || ''}  ${accountNumber || ''}`}
      </Text>);
    };
    const icon = !selected
        ? logo ||
            (hideLogo ? null : (<LogoBankAccount onCard={onCard} data={data} showHidden={showHidden}/>))
        : renderSelect();
    const primaryColor = useMemo(() => {
        if (tintColor === colors.text.white) {
            return { White: true };
        }
        if (data.isHidden && showHidden) {
            return { Gray: true };
        }
        return { OffBlack: true };
    }, [tintColor, colors.text.white, data.isHidden, showHidden]);
    const content = (<TouchableHighlight onCard hoverOnLight disabled={disabled} underlayColor={colors.background.underlay} activeOpacity={1} onLongPress={longPressed} onPress={pressed}>
      <View style={[styles.rowAccount, style]}>
        {icon}
        <View style={[styles.textContainer, hideLogo && styles.textContainerSmall]}>
          <Text {...(small ? { 'Text-14': true } : { 'Text-16': true })} numberOfLines={1} ellipsizeMode="tail" {...primaryColor}>
            {data.name}
          </Text>
          {renderDetails()}
        </View>
        {renderAccountBalance()}
      </View>
    </TouchableHighlight>);
    if (isWeb) {
        return <View ref={touchViewRef}>{content}</View>;
    }
    return content;
};
const styles = StyleSheet.create({
    rowAccount: {
        flexDirection: 'row',
        paddingTop: rem(16),
        paddingBottom: rem(15),
        ...(isWeb && {
            paddingHorizontal: 8,
        }),
    },
    textContainer: {
        flex: 1,
        flexGrow: 1,
        justifyContent: 'center',
        paddingHorizontal: rem(16),
    },
    textContainerSmall: {
        paddingLeft: 0,
        paddingRight: rem(16),
    },
    renewButton: {
        paddingHorizontal: rem(20),
    },
    selectWrap: {
        justifyContent: 'center',
        alignItems: 'center',
        height: normalize(34.8),
    },
    buttonContainer: {
        justifyContent: 'center',
    },
    checkOnSquircle: {
        marginRight: rem(8),
        marginLeft: rem(8),
    },
});
export default RowAccount;
