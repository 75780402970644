import { REHYDRATE } from 'redux-persist';
import * as types from 'actions/types';
const initialState = {
    beneficiaries: [],
};
const beneficiaries = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE: {
            if (action.payload && action.payload.beneficiaries) {
                return {
                    ...initialState,
                    ...action.payload.beneficiaries,
                };
            }
            return state;
        }
        case types.SAVE_BENEFICIARY_SUCCESS: {
            const { payload } = action;
            return {
                ...state,
                beneficiaries: [...state.beneficiaries, payload.beneficiary],
            };
        }
        case types.UPDATE_BENEFICIARY_SUCCESS: {
            const { payload } = action;
            return {
                ...state,
                beneficiaries: state.beneficiaries.map((item) => item.id === payload.beneficiary.id ? payload.beneficiary : item),
            };
        }
        case types.DELETE_BENEFICIARY_SUCCESS: {
            const { extra: { id }, } = action;
            return {
                ...state,
                beneficiaries: state.beneficiaries.filter((item) => item.id !== id),
            };
        }
        case types.GET_BENEFICIARIES_SUCCESS: {
            const { payload } = action;
            return { ...state, beneficiaries: payload.beneficiaries };
        }
        case types.LOGGED_OUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
export default beneficiaries;
