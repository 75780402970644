import React, { memo, useCallback, useMemo } from 'react';
import { View, StyleSheet, SectionList, } from 'react-native';
import Animated from 'react-native-reanimated';
import Button from 'design-system/Button';
import SectionHeader from 'design-system/SectionHeader';
import { TextButtonLink } from 'design-system/TextButton';
import { rem, SCROLL_INDICATOR_INSETS } from 'design-system/values';
import { useCategories } from 'hooks/useCategory';
import { useAppSelector } from 'store/hooks';
import { selectOrderedCategories, selectOrderedCustomCategories, } from 'reducers/selectors';
import EditCategoryRow from './EditCategoryRow';
import { isWeb } from '../../../../constants';
const custom = [{ id: 'custom1' }];
const keyExtractor = (item) => item[0].id;
const AnimatedSectionList = Animated.createAnimatedComponent(SectionList);
const splitIntoRows = (array) => array.reduce((curr, item, index) => {
    const chunkIndex = Math.floor(index / 3);
    const newArray = curr;
    if (!newArray[chunkIndex]) {
        newArray[chunkIndex] = []; // new row
    }
    newArray[chunkIndex].push(item);
    return newArray;
}, []);
const EditCategoryList = ({ scrollHandler, contentContainerStyle, selectedCategory, onPressCategory, onLongPressCategory, onPressAdd, onPressManage, }) => {
    const userCategories = useAppSelector(selectOrderedCategories);
    const customCategories = useAppSelector(selectOrderedCustomCategories);
    const categories = useCategories();
    const renderItem = useCallback((item, isDefaultCategory) => {
        const category = categories[item.id] || item;
        const id = selectedCategory?.id != null ? selectedCategory.id : null;
        return (<EditCategoryRow key={item.id} onLongPress={onLongPressCategory} isDefaultCategory={isDefaultCategory} onPress={onPressCategory} item={category} selected={id === category.id}/>);
    }, [categories, onLongPressCategory, onPressCategory, selectedCategory]);
    const renderRow = useCallback(({ item: row, section: { key } }) => (<View style={styles.row}>
        {row.map((item) => renderItem(item, key === 'defaultCategories'))}
      </View>), [renderItem]);
    const renderHeader = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ section }) => (<SectionHeader style={styles.sectionHeader} largeVerticalMargins={isWeb} button={section.key === 'customCategories' ? (<TextButtonLink text="Manage" onPress={onPressManage}/>) : undefined}>
        {section.title}
      </SectionHeader>), [onPressManage]);
    const renderFooter = useMemo(() => (<Button brand onPress={onPressAdd} style={styles.footer} title="Add more categories"/>), [onPressAdd]);
    const sections = useMemo(() => [
        {
            key: 'customCategories',
            title: 'Custom categories',
            data: splitIntoRows(custom.concat(customCategories)),
        },
        {
            key: 'defaultCategories',
            title: 'Default',
            data: splitIntoRows(
            // We can use default categories from the local record if the array has failed to load
            userCategories?.length ? userCategories : Object.values(categories)),
        },
    ], [categories, customCategories, userCategories]);
    return (<AnimatedSectionList sections={sections} renderItem={renderRow} scrollEventThrottle={16} onScroll={scrollHandler} keyExtractor={keyExtractor} renderSectionHeader={renderHeader} ListFooterComponent={renderFooter} stickySectionHeadersEnabled={false} contentContainerStyle={contentContainerStyle} scrollIndicatorInsets={SCROLL_INDICATOR_INSETS}/>);
};
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
    },
    footer: {
        alignItems: 'center',
        justifyContent: 'center',
        margin: rem(16),
    },
    sectionHeader: {
        marginHorizontal: rem(16),
    },
});
export default memo(EditCategoryList);
