import React, { memo } from 'react';
import ItemBadge from './ItemBadge';
const Filters = ({ activeFilter, amountLowerValue, amountUpperValue, refAmountUpperInput, refAmountLowerInput, onToggleSelectedFilter, onRemoveItem, onChangeAmountUpper, onChangeAmountLower, }) => {
    const renderItem = (item) => {
        let toRender;
        const filter = activeFilter[item];
        if (Array.isArray(filter)) {
            toRender = filter;
        }
        else {
            toRender = [filter];
        }
        return (<React.Fragment key={item}>
        {toRender.map((filter) => (<ItemBadge key={filter.badgeText} item={filter} amountUpperValue={amountUpperValue} amountLowerValue={amountLowerValue} refAmountUpperInput={refAmountUpperInput} refAmountLowerInput={refAmountLowerInput} onToggleSelectedFilter={onToggleSelectedFilter} onRemoveItem={onRemoveItem} onChangeAmountUpper={onChangeAmountUpper} onChangeAmountLower={onChangeAmountLower}/>))}
      </React.Fragment>);
    };
    if (!activeFilter || !Object.keys(activeFilter).length) {
        return null;
    }
    return <>{Object.keys(activeFilter).map((filterKey) => renderItem(filterKey))}</>;
};
export default memo(Filters);
