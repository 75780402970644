import React, { useState } from 'react';
import { View, Text } from 'react-native';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import Amount from 'design-system/Amount';
import { rem } from 'design-system/values';
const DASH_WIDTH = rem(5);
const SPACE_BETWEEN_DASHES = rem(5);
const END_DOT_WIDTH = rem(7);
const DashedLineWithValue = ({ average, position, doubleCaption, setWidth, }) => {
    const { width: screenWidth } = useAppFrameDimensions();
    const [currencyValueWidth, setCurrencyValueWidth] = useState(0);
    const styles = useStyles(styleSet);
    const amount = average?.amount;
    const currency = average?.currency;
    const onLayout = (event) => {
        const { width } = event.nativeEvent.layout;
        setCurrencyValueWidth(width);
        setWidth(width);
    };
    const availableWidthForDashes = screenWidth -
        currencyValueWidth -
        END_DOT_WIDTH -
        SPACE_BETWEEN_DASHES -
        rem(32);
    const numberOfDashes = Math.floor(availableWidthForDashes / (DASH_WIDTH + SPACE_BETWEEN_DASHES));
    const BOTTOM_MARGIN = doubleCaption ? 40 : 20;
    return (<View pointerEvents="none" style={[
            styles.container,
            {
                width: screenWidth,
                bottom: rem(BOTTOM_MARGIN) + position,
            },
        ]}>
      {Array.from({ length: numberOfDashes }).map((_, index) => (<View key={index} style={styles.dash}/>))}
      <View style={styles.endDot}/>

      <Text>
        {amount && (<View onLayout={onLayout}>
            <Amount Numbers-16 showFraction={false} value={{ value: amount, currency }}/>
          </View>)}
      </Text>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        position: 'absolute',
        left: 0,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    dash: {
        width: DASH_WIDTH,
        height: rem(2),
        backgroundColor: colors.text.tertiary,
        marginRight: SPACE_BETWEEN_DASHES,
        borderRadius: rem(1),
    },
    endDot: {
        width: END_DOT_WIDTH,
        height: END_DOT_WIDTH,
        borderRadius: END_DOT_WIDTH / 2,
        backgroundColor: colors.text.tertiary,
        marginRight: SPACE_BETWEEN_DASHES,
    },
}));
export default DashedLineWithValue;
