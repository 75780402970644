import { SubscriptionPlatform } from 'react-native-iap';
import { TierPeriod, periodFromProductId, tierFromProductId, } from 'features/premium/entities';
import { canUseFreeTrial, formatMonthlyPriceFromYearly, } from 'features/premium/trial/utils';
import { tryToRoundToZeroPointNinetyNine } from 'features/premium/tryToRoundToZeroPointNinetyNine';
import moment from 'moment';
import { formatAmount } from 'utils/formatting';
import { getIapSubscriptionPrices } from 'features/premium/iap';
export const isPromotionAvailable = (promotion) => {
    const availableTo = new Date(promotion.availableTo).getTime();
    const availableFrom = new Date(promotion.availableFrom).getTime();
    const now = Date.now();
    return (now <= availableTo && now >= availableFrom && promotion.acceptedAt == null);
};
export const shouldOpenPromotionOnStartup = (promotion) => promotion.fullScreenSeenAt == null && isPromotionAvailable(promotion);
export const shouldDisplayPromotionInFeed = (promotion) => promotion.feedCardDismissedAt == null && isPromotionAvailable(promotion);
export const getOfferingForPlatform = (promotion, platform, shouldShowStripe, eligibleFreeTrialDays) => {
    if (platform !== 'ios' && platform !== 'android') {
        return null;
    }
    const { id: promotionId, offering } = promotion;
    if (shouldShowStripe) {
        if (offering.stripe1mProduct) {
            const productId = offering.stripe1mProduct;
            const discount = offering.stripe1mDiscount ?? 0;
            if (productId) {
                return {
                    freeTrialDays: eligibleFreeTrialDays || null,
                    promotionId,
                    productId,
                    discount,
                    period: TierPeriod.monthly,
                    type: 'stripe',
                };
            }
        }
        if (offering.stripe12mProduct) {
            const productId = offering.stripe12mProduct;
            const discount = offering.stripe12mDiscount ?? 0;
            if (productId) {
                return {
                    freeTrialDays: eligibleFreeTrialDays || null,
                    promotionId,
                    productId,
                    discount,
                    period: TierPeriod.yearly,
                    type: 'stripe',
                };
            }
        }
        return null;
    }
    const base = {
        freeTrialDays: offering.freeTrialDays,
    };
    const type = platform === 'ios'
        ? SubscriptionPlatform.ios
        : SubscriptionPlatform.android;
    if (offering.ios1mProduct || offering.android1mProduct) {
        const productId = platform === 'ios' ? offering.ios1mProduct : offering.android1mProduct;
        const discount = (platform === 'ios'
            ? offering.ios1mDiscount
            : offering.android1mDiscount) ?? 0;
        if (productId != null) {
            return {
                ...base,
                promotionId,
                productId,
                discount,
                period: TierPeriod.monthly,
                type,
            };
        }
    }
    if (offering.ios12mProduct || offering.android12mProduct) {
        const productId = platform === 'ios' ? offering.ios12mProduct : offering.android12mProduct;
        const discount = (platform === 'ios'
            ? offering.ios12mDiscount
            : offering.android12mDiscount) ?? 0;
        if (productId != null) {
            return {
                ...base,
                promotionId,
                productId,
                discount,
                period: TierPeriod.yearly,
                type,
            };
        }
    }
    return null;
};
export const getDiscountByIdFromSubscription = (subscription, offerId) => {
    if (subscription.platform === SubscriptionPlatform.ios) {
        const iosDiscount = subscription.discounts?.find((dis) => dis.identifier === offerId);
        if (!iosDiscount)
            return null;
        return { type: SubscriptionPlatform.ios, discount: iosDiscount };
    }
    if (subscription.platform === SubscriptionPlatform.android) {
        const subscriptionOffer = subscription.subscriptionOfferDetails?.find((offerDetails) => offerDetails.offerId === offerId);
        if (!subscriptionOffer)
            return null;
        return { type: SubscriptionPlatform.android, discount: subscriptionOffer };
    }
    return null;
};
export const getDiscountFromSubscription = (subscription, promotion) => {
    if (subscription.platform === 'stripe') {
        if (subscription.period === TierPeriod.monthly &&
            promotion.offering.stripe1mCouponId &&
            promotion.offering.stripe1mDiscountPrice) {
            return {
                type: 'stripe',
                discount: {
                    couponId: promotion.offering.stripe1mCouponId,
                    price: promotion.offering.stripe1mDiscountPrice.toString(),
                    numberOfPeriods: promotion.offering.stripe1mDiscountPeriods || 1,
                    currency: subscription.currency,
                },
            };
        }
        if (subscription.period === TierPeriod.yearly &&
            promotion.offering.stripe12mCouponId &&
            promotion.offering.stripe12mDiscountPrice) {
            return {
                type: 'stripe',
                discount: {
                    couponId: promotion.offering.stripe12mCouponId,
                    price: promotion.offering.stripe12mDiscountPrice.toString(),
                    numberOfPeriods: promotion.offering.stripe12mDiscountPeriods || 1,
                    currency: subscription.currency,
                },
            };
        }
        return null;
    }
    if (subscription.platform === SubscriptionPlatform.ios) {
        const iosDiscountId = subscription.period === TierPeriod.monthly
            ? promotion.offering.ios1mOfferId?.offerId
            : promotion?.offering.ios12mOfferId?.offerId;
        const iosDiscount = subscription.discounts?.find((dis) => dis.identifier === iosDiscountId);
        if (!iosDiscount)
            return null;
        return { type: SubscriptionPlatform.ios, discount: iosDiscount };
    }
    if (subscription.platform === SubscriptionPlatform.android) {
        const tag = subscription.period === TierPeriod.monthly
            ? promotion?.offering.android1mTag
            : promotion?.offering.android12mTag;
        if (!tag)
            return null;
        const subscriptionOffer = subscription.subscriptionOfferDetails?.find((offerDetails) => offerDetails.offerTags.includes(tag));
        if (!subscriptionOffer)
            return null;
        return { type: SubscriptionPlatform.android, discount: subscriptionOffer };
    }
    return null;
};
const periodSymbolMap = {
    [TierPeriod.yearly]: 'y',
    [TierPeriod.monthly]: 'm',
};
export const microsToFloat = (price) => price ? Number(price) / 1000000 : 0;
export const getDiscountedPriceAndCanUseFreeTrial = (subscription, platformDiscount, isEligibleForIntroOffer) => {
    if (subscription.platform === 'stripe' &&
        platformDiscount?.type === 'stripe') {
        return {
            discountedPrice: Number(platformDiscount?.discount.price),
            canUseFreeTrial: isEligibleForIntroOffer === true,
            discountedPeriod: subscription.period,
            numberOfPeriods: platformDiscount.discount.numberOfPeriods,
            currency: platformDiscount.discount.currency,
            paymentMode: 'PAYASYOUGO',
        };
    }
    if (!platformDiscount) {
        // we can also try to use the introductory prices for iOS
        if (subscription.platform === SubscriptionPlatform.ios &&
            subscription.introductoryPriceAsAmountIOS &&
            (isEligibleForIntroOffer === true ||
                // If the check hasn't ran yet we default to show the trial
                isEligibleForIntroOffer === undefined)) {
            return {
                discountedPrice: subscription.introductoryPricePaymentModeIOS === 'PAYUPFRONT'
                    ? Number(subscription.introductoryPriceAsAmountIOS) /
                        Number(subscription.introductoryPriceNumberOfPeriodsIOS || 1)
                    : Number(subscription.introductoryPriceAsAmountIOS),
                discountedPriceToPayUpfront: subscription.introductoryPricePaymentModeIOS === 'PAYUPFRONT'
                    ? Number(subscription.introductoryPriceAsAmountIOS)
                    : undefined,
                discountedPeriod: subscription.introductoryPriceSubscriptionPeriodIOS === 'MONTH'
                    ? TierPeriod.monthly
                    : TierPeriod.yearly,
                canUseFreeTrial: canUseFreeTrial(subscription, isEligibleForIntroOffer),
                currency: subscription.currency,
                // This will always be 1 for PAYASYOUGO or FREETRIAL
                numberOfPeriods: Number(subscription.introductoryPriceNumberOfPeriodsIOS),
                paymentMode: subscription.introductoryPricePaymentModeIOS || 'PAYASYOUGO',
            };
        }
        return null;
    }
    if (platformDiscount.type === SubscriptionPlatform.ios &&
        subscription.platform === SubscriptionPlatform.ios) {
        return {
            discountedPrice: platformDiscount.discount.paymentMode === 'PAYUPFRONT'
                ? Number(platformDiscount.discount.price) /
                    Number(platformDiscount.discount.numberOfPeriods)
                : Number(platformDiscount.discount.price),
            discountedPriceToPayUpfront: platformDiscount.discount.paymentMode === 'PAYUPFRONT'
                ? Number(platformDiscount.discount.price)
                : undefined,
            discountedPeriod: platformDiscount.discount.subscriptionPeriod === 'MONTH'
                ? TierPeriod.monthly
                : TierPeriod.yearly,
            canUseFreeTrial: platformDiscount.discount.paymentMode === 'FREETRIAL',
            currency: subscription.currency,
            numberOfPeriods: Number(platformDiscount.discount.numberOfPeriods),
            paymentMode: platformDiscount.discount.paymentMode,
        };
    }
    if (platformDiscount.type === SubscriptionPlatform.android &&
        subscription.platform === SubscriptionPlatform.android) {
        const canUseFreeTrial = platformDiscount.discount.pricingPhases.pricingPhaseList[0]
            .priceAmountMicros === '0';
        const phaseList = 
        // Remove the free trial pricing phase
        canUseFreeTrial
            ? platformDiscount.discount.pricingPhases.pricingPhaseList.slice(1)
            : platformDiscount.discount.pricingPhases.pricingPhaseList;
        // todo calculate the number of periods this discount applies to
        if (phaseList[0]) {
            // const numberOfPeriods = phaseList[0].billingPeriod.substring(1, phaseList[0].billingPeriod.length -2);
            const duration = moment.duration(phaseList[0].billingPeriod);
            const paymentMode = duration.months() > 1 || duration.years() > 1
                ? 'PAYUPFRONT'
                : 'PAYASYOUGO';
            const numberOfPeriods = canUseFreeTrial
                ? 1
                : duration.months() || duration.years();
            const discountedPeriod = duration.months()
                ? TierPeriod.monthly
                : TierPeriod.yearly;
            return {
                discountedPrice: paymentMode === 'PAYUPFRONT'
                    ? microsToFloat(phaseList[0].priceAmountMicros) / numberOfPeriods
                    : microsToFloat(phaseList[0].priceAmountMicros),
                discountedPeriod,
                discountedPriceToPayUpfront: paymentMode === 'PAYUPFRONT'
                    ? microsToFloat(phaseList[0].priceAmountMicros)
                    : null,
                canUseFreeTrial,
                currency: phaseList[0].priceCurrencyCode,
                numberOfPeriods: paymentMode === 'PAYUPFRONT'
                    ? numberOfPeriods
                    : phaseList[0].billingCycleCount,
                paymentMode,
            };
        }
    }
    return null;
};
const calculateSavingPerYear = (subscriptionPeriod, discountedPeriod, discountedPriceToPayUpfront, discountedPrice, numberOfPeriods, originalSubscriptionPrice, originalPrice, monthlySubscriptionPrices) => {
    if (subscriptionPeriod === discountedPeriod ||
        (subscriptionPeriod === TierPeriod.yearly &&
            discountedPeriod === TierPeriod.monthly &&
            monthlySubscriptionPrices)) {
        if (subscriptionPeriod === discountedPeriod) {
            // The discount period is the same as the base subscription period
            return subscriptionPeriod === TierPeriod.yearly
                ? originalPrice - Number(discountedPrice)
                : Number(originalSubscriptionPrice?.price || 0) * numberOfPeriods -
                    (discountedPriceToPayUpfront || discountedPrice * numberOfPeriods);
        }
        // Periods dont match, we can calculate saving from the original price
        return (Number(monthlySubscriptionPrices?.price || 0) * numberOfPeriods -
            (discountedPriceToPayUpfront || discountedPrice * numberOfPeriods));
    }
    return null; // We can't calculate this, we don't know the original price
};
export const getPrices = (subscription, monthlySubscription, iapDiscount, period, isEligibleForIntroOffer) => {
    const discountedPriceAndCanUseFreeTrial = getDiscountedPriceAndCanUseFreeTrial(subscription, iapDiscount, isEligibleForIntroOffer);
    if (!discountedPriceAndCanUseFreeTrial)
        return null;
    const { discountedPrice, currency, canUseFreeTrial, numberOfPeriods, discountedPeriod, discountedPriceToPayUpfront, paymentMode, } = discountedPriceAndCanUseFreeTrial;
    const thisSubscriptionPrice = subscription.platform === 'stripe'
        ? subscription
        : getIapSubscriptionPrices(subscription);
    const monthlySubscriptionPrices = monthlySubscription?.platform === 'stripe'
        ? monthlySubscription
        : getIapSubscriptionPrices(monthlySubscription || undefined);
    const originalPriceToShow = subscription.period === TierPeriod.yearly &&
        monthlySubscriptionPrices?.price
        ? Number(monthlySubscriptionPrices.price) * 12
        : Number(thisSubscriptionPrice?.price);
    const originalPriceRounded = tryToRoundToZeroPointNinetyNine(originalPriceToShow);
    const percentOff = Math.abs(Math.floor(((Number(discountedPrice) - originalPriceRounded) /
        originalPriceRounded) *
        100));
    const savingPerYearRaw = calculateSavingPerYear(subscription.period, discountedPeriod, discountedPriceToPayUpfront, discountedPrice, numberOfPeriods, thisSubscriptionPrice, originalPriceToShow, monthlySubscriptionPrices);
    return {
        discountedPrice: `${formatAmount(Number(discountedPrice), currency, 2)}/${periodSymbolMap[discountedPeriod]}`,
        discountedPriceToPayUpfront: discountedPriceToPayUpfront
            ? formatAmount(Number(discountedPriceToPayUpfront), currency, 2)
            : null,
        discountedMonthlyPrice: monthlySubscription && discountedPeriod === TierPeriod.yearly
            ? `${formatMonthlyPriceFromYearly({
                price: discountedPrice,
                currency,
            })}/${periodSymbolMap[monthlySubscription.period]}`
            : null,
        originalMonthlyPrice: monthlySubscriptionPrices?.price
            ? `${formatAmount(Number(monthlySubscriptionPrices.price), currency, 2)}/${periodSymbolMap[monthlySubscriptionPrices.period]}`
            : null,
        canUseFreeTrial,
        originalPrice: `${formatAmount(originalPriceRounded, currency, 2)}/${periodSymbolMap[period]}`,
        savingPerYear: savingPerYearRaw
            ? formatAmount(Math.floor(savingPerYearRaw), currency, 0)
            : null,
        originalYearlyPriceRaw: subscription.period === TierPeriod.monthly
            ? (Number(thisSubscriptionPrice?.price) || 0) * 12
            : originalPriceToShow,
        savingPerYearRaw: savingPerYearRaw ? +savingPerYearRaw.toFixed(2) : null,
        discountedPeriod,
        percentOff,
        numberOfPeriods,
        paymentMode,
    };
};
export const getIapSubscriptionFromProducts = (products, iapLibSubscriptions) => {
    const subscriptions = products
        .map((product) => {
        const matchingSubscription = iapLibSubscriptions.find((sub) => sub.productId === product.productId);
        if (!matchingSubscription) {
            return null;
        }
        const period = product.period || periodFromProductId(product.productId);
        if (!period) {
            return null;
        }
        return {
            ...matchingSubscription,
            id: product.productId,
            tier: tierFromProductId(product.productId),
            period,
        };
    })
        .filter((subscription) => subscription !== null &&
        (subscription.platform === SubscriptionPlatform.ios ||
            subscription.platform === SubscriptionPlatform.android));
    return subscriptions;
};
export const getStripeProduct = (stripePriceMap, productId) => {
    const flattened = Object.values(stripePriceMap).flatMap((byPeriod) => Object.values(byPeriod));
    return (flattened.find((stripeProduct) => stripeProduct.id === productId) || null);
};
