export const GET_EXPORT_DATA_REQUEST = 'GET_EXPORT_DATA_REQUEST';
export const GET_EXPORT_DATA_SUCCESS = 'GET_EXPORT_DATA_SUCCESS';
export const GET_EXPORT_DATA_FAILURE = 'GET_EXPORT_DATA_FAILURE';
export const GET_REFERRALS_REQUEST = 'GET_REFERRALS_REQUEST';
export const GET_REFERRALS_SUCCESS = 'GET_REFERRALS_SUCCESS';
export const GET_REFERRALS_FAILURE = 'GET_REFERRALS_FAILURE';
export const GET_CREDIT_REFERRALS_REQUEST = 'GET_CREDIT_REFERRALS_REQUEST';
export const GET_CREDIT_REFERRALS_SUCCESS = 'GET_CREDIT_REFERRALS_SUCCESS';
export const GET_CREDIT_REFERRALS_FAILURE = 'GET_CREDIT_REFERRALS_FAILURE';
export const GET_ACTIVE_REFERRAL_PROGRAM_REQUEST = 'GET_ACTIVE_REFERRAL_PROGRAM_REQUEST';
export const GET_ACTIVE_REFERRAL_PROGRAM_SUCCESS = 'GET_ACTIVE_REFERRAL_PROGRAM_SUCCESS';
export const GET_ACTIVE_REFERRAL_PROGRAM_FAILURE = 'GET_ACTIVE_REFERRAL_PROGRAM_FAILURE';
export const SET_REFERRAL_SEEN_REQUEST = 'SET_REFERRAL_SEEN_REQUEST';
export const SET_REFERRAL_SEEN_SUCCESS = 'SET_REFERRAL_SEEN_SUCCESS';
export const SET_REFERRAL_SEEN_FAILURE = 'GET_ACTIVE_REFERRAL_PROGRAM_FAILURE';
export const POST_PROFILE_PIC_REQUEST = 'POST_PROFILEPIC_REQUEST';
export const POST_PROFILE_PIC_SUCCESS = 'POST_PROFILE_PIC_SUCCESS';
export const POST_PROFILE_PIC_FAILURE = 'POST_PROFILE_PIC_FAILURE';
export const DELETE_PROFILE_PIC_REQUEST = 'DELETE_PROFILEPIC_REQUEST';
export const DELETE_PROFILE_PIC_SUCCESS = 'DELETE_PROFILE_PIC_SUCCESS';
export const DELETE_PROFILE_PIC_FAILURE = 'DELETE_PROFILE_PIC_FAILURE';
export const SET_LAST_SESSION = 'SET_LAST_SESSION';
export const REFRESH_ON_START = 'REFRESH_ON_START';
export const GET_INCOME_REQUEST = 'GET_INCOME_REQUEST';
export const GET_INCOME_SUCCESS = 'GET_INCOME_SUCCESS';
export const GET_INCOME_FAILURE = 'GET_INCOME_FAILURE';
export const SET_INCOME_REQUEST = 'SET_INCOME_REQUEST';
export const SET_INCOME_SUCCESS = 'SET_INCOME_SUCCESS';
export const SET_INCOME_FAILURE = 'SET_INCOME_FAILURE';
export const SET_PRIMARY_INCOME_REQUEST = 'SET_PRIMARY_INCOME_REQUEST';
export const SET_PRIMARY_INCOME_SUCCESS = 'SET_PRIMARY_INCOME_SUCCESS';
export const SET_PRIMARY_INCOME_FAILURE = 'SET_PRIMARY_INCOME_FAILURE';
export const DELETE_INCOME_REQUEST = 'DELETE_INCOME_REQUEST';
export const DELETE_INCOME_SUCCESS = 'DELETE_INCOME_SUCCESS';
export const DELETE_INCOME_FAILURE = 'DELETE_INCOME_FAILURE';
export const DELETE_ALL_INCOMES_REQUEST = 'DELETE_ALL_INCOMES_REQUEST';
export const DELETE_ALL_INCOMES_SUCCESS = 'DELETE_ALL_INCOMES_SUCCESS';
export const DELETE_ALL_INCOMES_FAILURE = 'DELETE_ALL_INCOMES_FAILURE';
export const SET_PASSCODE_AUTH = 'SET_PASSCODE_AUTH';
export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';
export const DEACTIVATE_USER_REQUEST = 'DEACTIVATE_USER_REQUEST';
export const DEACTIVATE_USER_SUCCESS = 'DEACTIVATE_USER_SUCCESS';
export const DEACTIVATE_USER_FAILURE = 'DEACTIVATE_USER_FAILURE';
export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_COMPLETED = 'LOGIN_REQUEST_COMPLETED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const SET_SETUP_PASSCODE = 'SET_SETUP_PASSCODE';
export const PASSCODE_SETUP_FAILED = 'PASSCODE_SETUP_FAILED';
export const PASSCODE_ON = 'PASSCODE_ON';
export const PASSCODE_OFF = 'PASSCODE_OFF';
export const SET_TOUCH_ID = 'SET_TOUCH_ID';
export const LOG_OUT = 'LOG_OUT';
export const LOGGED_OUT = 'LOGGED_OUT';
export const CONNECTIONS_REQUEST = 'CONNECTIONS_REQUEST';
export const CONNECTIONS_SUCCESS = 'CONNECTIONS_SUCCESS';
export const CONNECTIONS_FAILURE = 'CONNECTIONS_FAILURE';
export const CONNECTION_REQUEST = 'CONNECTION_REQUEST';
export const CONNECTION_SUCCESS = 'CONNECTION_SUCCESS';
export const CONNECTION_FAILURE = 'CONNECTION_FAILURE';
export const DELETE_CONNECTION_REQUEST = 'DELETE_CONNECTION_REQUEST';
export const DELETE_CONNECTION_SUCCESS = 'DELETE_CONNECTION_SUCCESS';
export const DELETE_CONNECTION_FAILURE = 'DELETE_CONNECTION_FAILURE';
export const CLOSED_CONNECTION_REQUEST = 'CLOSED_CONNECTION_REQUEST';
export const CLOSED_CONNECTION_SUCCESS = 'CLOSED_CONNECTION_SUCCESS';
export const CLOSED_CONNECTION_FAILURE = 'CLOSED_CONNECTION_FAILURE';
export const HIDE_REQUEST = 'HIDE_REQUEST';
export const HIDE_SUCCESS = 'HIDE_SUCCESS';
export const HIDE_FAILURE = 'HIDE_FAILURE';
export const CLOSE_ACCOUNT_REQUEST = 'CLOSE_ACCOUNT_REQUEST';
export const CLOSE_ACCOUNT_SUCCESS = 'CLOSE_ACCOUNT_SUCCESS';
export const CLOSE_ACCOUNT_FAILURE = 'CLOSE_ACCOUNT_FAILURE';
export const PREFERENCES_REQUEST = 'PREFERENCES_REQUEST';
export const PREFERENCES_SUCCESS = 'PREFERENCES_SUCCESS';
export const PREFERENCES_FAILURE = 'PREFERENCES_FAILURE';
export const UPDATE_PREFERENCES_REQUEST = 'UPDATE_PREFERENCES_REQUEST';
export const UPDATE_PREFERENCES_SUCCESS = 'UPDATE_PREFERENCES_SUCCESS';
export const UPDATE_PREFERENCES_FAILURE = 'UPDATE_PREFERENCES_FAILURE';
export const SET_WAITING_STATUS = 'SET_WAITING_STATUS';
export const SET_FIRST_BOARDING = 'SET_FIRST_BOARDING';
export const SET_CONNECTIONS_STATUS = 'SET_CONNECTIONS_STATUS';
export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE';
export const SET_USER_HANDLE_REQUEST = 'SET_USER_HANDLE_REQUEST';
export const SET_USER_HANDLE_SUCCESS = 'SET_USER_HANDLE_SUCCESS';
export const SET_USER_HANDLE_FAILURE = 'SET_USER_HANDLE_FAILURE';
export const SET_PASSCODE_REQUEST = 'SET_PASSCODE_REQUEST';
export const SET_PASSCODE_SUCCESS = 'SET_PASSCODE_SUCCESS';
export const SET_PASSCODE_FAILURE = 'SET_PASSCODE_FAILURE';
export const SET_PASSCODE_IN_KEYCHAIN_SUCCESS = 'SET_PASSCODE_IN_KEYCHAIN_SUCCESS';
export const VERIFY_PASSCODE_REQUEST = 'VERIFY_PASSCODE_REQUEST';
export const VERIFY_PASSCODE_SUCCESS = 'VERIFY_PASSCODE_SUCCESS';
export const VERIFY_PASSCODE_FAILURE = 'VERIFY_PASSCODE_FAILURE';
export const RESET_PASSCODE_REQUEST = 'RESET_PASSCODE_REQUEST';
export const RESET_PASSCODE_SUCCESS = 'RESET_PASSCODE_SUCCESS';
export const RESET_PASSCODE_FAILURE = 'RESET_PASSCODE_FAILURE';
export const LINK_NUMBER_REQUEST_CODE_REQUEST = 'LINK_NUMBER_REQUEST_CODE_REQUEST';
export const LINK_NUMBER_REQUEST_CODE_SUCCESS = 'LINK_NUMBER_REQUEST_CODE_SUCCESS';
export const LINK_NUMBER_REQUEST_CODE_FAILURE = 'LINK_NUMBER_REQUEST_CODE_FAILURE';
export const LINK_NUMBER_VERIFY_CODE_REQUEST = 'LINK_NUMBER_VERIFY_CODE_VERIFY';
export const LINK_NUMBER_VERIFY_CODE_SUCCESS = 'LINK_NUMBER_VERIFY_CODE_SUCCESS';
export const LINK_NUMBER_VERIFY_CODE_FAILURE = 'LINK_NUMBER_VERIFY_CODE_FAILURE';
export const LINK_NUMBER_COMPLETE = 'LINK_NUMBER_COMPLETE';
export const SESSION_COUNT = 'SESSION_COUNT';
export const INVITE_ADDRESS_BOOK_REQUEST = 'INVITE_ADDRESS_BOOK_REQUEST';
export const INVITE_ADDRESS_BOOK_SUCCESS = 'INVITE_ADDRESS_BOOK_SUCCESS';
export const INVITE_ADDRESS_BOOK_FAILURE = 'INVITE_ADDRESS_BOOK_FAILURE';
export const SEND_REFERRAL_CODE_REQUEST = 'SEND_REFERRAL_CODE_REQUEST';
export const SEND_REFERRAL_CODE_SUCCESS = 'SEND_REFERRAL_CODE_SUCCESS';
export const SEND_REFERRAL_CODE_FAILURE = 'SEND_REFERRAL_CODE_FAILURE';
export const LINK_FACEBOOK_REQUEST = 'LINK_FACEBOOK_REQUEST';
export const LINK_FACEBOOK_SUCCESS = 'LINK_FACEBOOK_SUCCESS';
export const LINK_FACEBOOK_FAILURE = 'LINK_FACEBOOK_FAILURE';
export const LINK_APPLE_REQUEST = 'LINK_APPLE_REQUEST';
export const LINK_APPLE_SUCCESS = 'LINK_APPLE_SUCCESS';
export const LINK_APPLE_FAILURE = 'LINK_APPLE_FAILURE';
export const LINK_GOOGLE_REQUEST = 'LINK_GOOGLE_REQUEST';
export const LINK_GOOGLE_SUCCESS = 'LINK_GOOGLE_SUCCESS';
export const LINK_GOOGLE_FAILURE = 'LINK_GOOGLE_FAILURE';
export const REQUEST_EMAIL_VERIFICATION_REQUEST = 'REQUEST_EMAIL_VERIFICATION_REQUEST';
export const REQUEST_EMAIL_VERIFICATION_SUCCESS = 'REQUEST_EMAIL_VERIFICATION_SUCCESS';
export const REQUEST_EMAIL_VERIFICATION_FAILURE = 'REQUEST_EMAIL_VERIFICATION_FAILURE';
export const SUBMIT_EMAIL_VERIFICATION_CODE_REQUEST = 'SUBMIT_EMAIL_VERIFICATION_CODE_REQUEST';
export const SUBMIT_EMAIL_VERIFICATION_CODE_SUCCESS = 'SUBMIT_EMAIL_VERIFICATION_CODE_SUCCESS';
export const SUBMIT_EMAIL_VERIFICATION_CODE_FAILURE = 'SUBMIT_EMAIL_VERIFICATION_CODE_FAILURE';
export const PAY_REFERRAL_REQUEST = 'PAY_REFERRAL_REQUEST';
export const PAY_REFERRAL_SUCCESS = 'PAY_REFERRAL_SUCCESS';
export const PAY_REFERRAL_FAILURE = 'PAY_REFERRAL_FAILURE';
export const GET_ADDITIONAL_USER_INFO_REQUEST = 'GET_ADDITIONAL_USER_INFO_REQUEST';
export const GET_ADDITIONAL_USER_INFO_SUCCESS = 'GET_ADDITIONAL_USER_INFO_SUCCESS';
export const GET_ADDITIONAL_USER_INFO_FAILURE = 'GET_ADDITIONAL_USER_INFO_FAILURE';
export const POST_ADDITIONAL_USER_INFO_REQUEST = 'POST_ADDITIONAL_USER_INFO_REQUEST';
export const POST_ADDITIONAL_USER_INFO_SUCCESS = 'POST_ADDITIONAL_USER_INFO_SUCCESS';
export const POST_ADDITIONAL_USER_INFO_FAILURE = 'POST_ADDITIONAL_USER_INFO_FAILURE';
export const IS_EMMA_PRO_MODAL_OPEN = 'IS_EMMA_PRO_MODAL_OPEN';
export const POST_USER_NATIONALITIES_REQUEST = 'POST_USER_NATIONALITIES_REQUEST';
export const POST_USER_NATIONALITIES_SUCCESS = 'POST_USER_NATIONALITIES_SUCCESS';
export const POST_USER_NATIONALITIES_FAILURE = 'POST_USER_NATIONALITIES_FAILURE';
export const UPDATE_KEYCHAIN_USER = 'UPDATE_KEYCHAIN_USER';
