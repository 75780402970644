import React, { useCallback } from 'react';
import { Image, StyleSheet, TouchableHighlight, View } from 'react-native';
import { SquareBadge } from 'design-system';
import Text from 'design-system/Text';
import IconCheckMark from 'design-system/icons/IconCheckMark';
import IconLock from 'design-system/icons/IconLock';
import { iconStrings } from 'features/app-icons/appIcons';
import useRequiredTierCallback from 'features/premium/hooks/useRequiredTierCallback';
import useColors from 'hooks/useColors';
const CheckMarkBadge = (props) => (<SquareBadge {...props} border>
    <IconCheckMark />
  </SquareBadge>);
const LockBadge = (props) => (<SquareBadge {...props} border>
    <IconLock width={11} height={11}/>
  </SquareBadge>);
const AppIconItem = ({ appIcon, selected = false, locked = false, disabled = false, onPress, }) => {
    const colors = useColors();
    const onPressWithRequiredTier = useRequiredTierCallback(useCallback(() => onPress?.(appIcon), [appIcon, onPress]), appIcon.tier, undefined, undefined, true);
    return (<TouchableHighlight underlayColor={colors.background.underlay} disabled={disabled} onPress={onPressWithRequiredTier}>
      <View style={styles.container}>
        <View style={styles.iconContainer}>
          <Image source={{ uri: appIcon.assetName }} style={styles.icon}/>
          {selected && <CheckMarkBadge />}
          {locked && <LockBadge />}
        </View>
        <Text Text-16>{iconStrings[appIcon.name]}</Text>
      </View>
    </TouchableHighlight>);
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 16,
    },
    iconContainer: {
        marginVertical: 16,
        marginRight: 16,
    },
    icon: {
        borderRadius: 12,
        width: 48,
        height: 48,
    },
});
export default React.memo(AppIconItem);
