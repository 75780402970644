import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
const IconCategoryTransport = (props) => (<Svg width={props.width} height={props.height} fill="none" {...props}>
    <Path d="M6.492 8.484H4.874a.405.405 0 0 0 0 .81h1.618a.405.405 0 0 0 0-.81Z" fill={props.fill}/>
    <Path d="M19.415 9.576c-.077-.241-1.62-3.512-2.197-3.863-.601-.365-4.197-.463-4.66-.463-.462 0-4.057.098-4.658.463-.578.35-2.12 3.622-2.198 3.863a.405.405 0 0 0 .772.245c.127-.402 1.53-3.14 1.838-3.411.342-.164 2.861-.351 4.247-.351 1.385 0 3.905.187 4.219.331.335.292 1.739 3.029 1.866 3.43a.405.405 0 0 0 .771-.244Z" fill={props.fill}/>
    <Path d="M6.492 16.575a.398.398 0 0 0-.385.308l-2.446-.612v-.1a.405.405 0 0 0-.808 0v1.617c0 .669.544 1.213 1.213 1.213h1.617c.67 0 1.214-.544 1.214-1.213v-.81a.405.405 0 0 0-.405-.404Z" fill={props.fill}/>
    <Path d="M21.86 15.766a.405.405 0 0 0-.404.404v.101l-2.446.612a.398.398 0 0 0-.385-.308.405.405 0 0 0-.404.404v.809c0 .669.544 1.213 1.213 1.213h1.618c.669 0 1.213-.544 1.213-1.213V16.17a.405.405 0 0 0-.405-.404Z" fill={props.fill}/>
    <Path d="M20.243 8.484h-1.618a.405.405 0 0 0 0 .81h1.618a.405.405 0 0 0 0-.81Z" fill={props.fill}/>
    <Path d="m22.234 12.299-1.104.22c-.136.022-2.852.413-3.54.413-.099.079-.188.598-.177 1.084 2.465-.117 4.385-.271 4.415-.273l.436-.06c0-.536-.009-.993-.03-1.384Z" fill={props.fill}/>
    <Path d="M21.57 10.522c-.18-.053-.275-.185-.455-.453l-.138-.202c-.242-.338-.71-.352-1.162-.365a3.136 3.136 0 0 1-.453-.034.394.394 0 0 0-.138-.125c-.755-.415-3.614-.859-6.665-.859-3.05 0-5.91.444-6.664.86a.399.399 0 0 0-.14.125c-.101.023-.317.03-.453.034-.45.013-.918.026-1.16.364l-.138.203c-.18.268-.275.4-.456.453-.317.093-.49.418-.587 1.01l.8.199c.012 0 2.65.392 3.54.392.307 0 .576.117.78.337.429.463.449 1.275.438 1.591 1.302.055 2.707.094 4.04.094 1.336 0 2.741-.04 4.045-.094-.01-.357.01-1.252.411-1.675a.778.778 0 0 1 .575-.253c.524 0 2.635-.289 3.405-.4l1.157-.232c-.097-.567-.27-.88-.582-.97Zm-6.18 2.815H9.728a.405.405 0 0 1 0-.809h5.662a.405.405 0 0 1 0 .81Z" fill={props.fill}/>
    <Path d="M21.914 14.546c-.07.006-5.074.408-9.355.408-4.28 0-9.284-.402-9.333-.405l-.351-.037c.043.878.178 2.194.597 2.417.437.232 5.49.452 9.087.452 3.598 0 8.65-.22 9.087-.453.42-.224.555-1.549.598-2.426l-.33.044Z" fill={props.fill}/>
    <Path d="M7.485 13.01a.226.226 0 0 0-.184-.076c-.954 0-3.588-.393-3.7-.409l-.72-.18c-.02.384-.028.833-.028 1.353l.445.046c.026.002 1.945.156 4.41.274.008-.293-.03-.801-.223-1.008Z" fill={props.fill}/>
  </Svg>);
export default IconCategoryTransport;
