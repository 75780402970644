import React, { useEffect, useRef } from 'react';
import RNConfetti from 'utils/confetti';
const Confetti = () => {
    const confettiView = useRef(null);
    useEffect(() => {
        Confetti.confettiRef = confettiView;
    }, []);
    return <RNConfetti confettiCount={200} ref={confettiView}/>;
};
Confetti.startConfetti = () => {
    Confetti.confettiRef?.current?.startConfetti();
};
export default Confetti;
