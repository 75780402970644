import { useCallback } from 'react';
import AddAccountModal from 'features/accounts/components/AddAccountModal';
import Amplitude from 'utils/amplitude';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import usePlaidLink from './usePlaidLink';
const useAddAccount = () => {
    const { navigate } = useMainStackNavigation();
    const plaidLink = usePlaidLink();
    const addAccount = useCallback(() => {
        Amplitude.logEvent('Accounts.OpenAddAnAccount');
        AddAccountModal.show({
            navigate,
            onOpenPlaid: plaidLink,
            onAddOffline: () => {
                navigate('PickAccountType', {});
            },
            onAddProperty: () => {
                navigate('Property', {});
            },
        });
    }, [plaidLink]);
    return addAccount;
};
export default useAddAccount;
