import React, { memo, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { rem } from 'design-system/values';
import Indicator from 'design-system/Indicator';
import { CardView } from 'design-system/CardView';
import { useAppSelector } from 'store/hooks';
import { selectAnalyticsPosition } from 'reducers/selectors';
export const AnalyticsCarousel = ({ data, type, renderItem, onEndReached, isSwitchingTotalsStep, }) => {
    const position = useAppSelector((store) => selectAnalyticsPosition(store, type));
    useEffect(() => {
        const dataLength = data.length;
        if (dataLength && position === dataLength - 1) {
            onEndReached();
        }
    }, [data.length, onEndReached, position]);
    if (!data?.length || !data?.[position] || isSwitchingTotalsStep) {
        return (<CardView style={styles.loadingCard}>
        <Indicator dark/>
      </CardView>);
    }
    return renderItem({ item: data[position], index: position });
};
const styles = StyleSheet.create({
    loadingCard: {
        minHeight: rem(291),
    },
});
export default memo(AnalyticsCarousel);
