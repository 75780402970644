import React, { memo, useEffect, useMemo, useState } from 'react';
import { View, Platform, StyleSheet } from 'react-native';
import FastImage from 'utils/packages/rnFastImage';
import FastImageCommon from 'common/FastImageCommon';
import Text from 'design-system/Text';
import Logo from 'design-system/Logo';
import { rem } from 'design-system/values';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import useColorScheme from 'hooks/useColorScheme';
import IconTransactionCategory from '../icons/IconTransactionCategory';
/**
 * Encapsulates all logic around how to display the logo for a transaction
 */
const TransactionLogo = ({ transaction, shadow = false, border = false, style, light, backgroundDark, ...size }) => {
    const styles = useStyles(styleSet);
    const colorScheme = useColorScheme();
    const source = useMemo(() => {
        let image = null;
        if (transaction?.merchant?.iconUrl) {
            image = transaction.merchant.iconUrl;
        }
        if (transaction?.suggestedData?.iconUrl) {
            image = transaction.suggestedData.iconUrl;
        }
        return typeof image === 'string'
            ? {
                uri: image,
                cache: (Platform.OS === 'ios'
                    ? FastImage.cacheControl?.web
                    : FastImage.cacheControl?.immutable) || 'web',
            }
            : image;
    }, [transaction.merchant?.iconUrl, transaction.suggestedData?.iconUrl]);
    const [useFallback, setUseFallback] = useState(false);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        setUseFallback(false);
    }, [source]);
    const onError = () => {
        setUseFallback(true);
    };
    const onLoad = () => {
        setLoaded(true);
    };
    const title = transaction.category?.displayName
        ? transaction.category.displayName.substring(0, 2).toUpperCase()
        : transaction.merchant?.displayName.substring(0, 2).toUpperCase() || '';
    const remSize = rem('R44' in size ? 44 : 70);
    const borderRadius = useMemo(() => Math.ceil(remSize), [remSize]);
    const contentWidth = useMemo(() => (border ? remSize - rem(7.24) * 2 : remSize), [remSize, border]);
    const categoryIconWidth = useMemo(() => contentWidth * 0.6, [contentWidth]);
    const renderText = () => {
        // Emoji will be handled by IconTransactionCategory
        if (transaction?.category?.emoji)
            return null;
        const color = transaction.category?.color
            ? { White: true }
            : { Gray: true };
        return remSize > 48 ? (<Text Text-32 centered {...color}>
        {title}
      </Text>) : (<Text Text-20 centered {...color}>
        {title}
      </Text>);
    };
    if (source && !useFallback && 'R44' in size) {
        return (<Logo resizeMode="contain" uri={source?.uri} R44 transparent={!loaded} onError={onError} onLoad={onLoad}/>);
    }
    return (<View style={[shadow && styles.shadow, style]}>
      {Boolean(border) && colorScheme === 'light' && (<View style={[
                styles.background,
                !!light && styles.lightBg,
                { borderRadius, width: remSize, height: remSize },
            ]}/>)}
      <View style={[
            styles.imageContainer,
            shadow && styles.shadowInner,
            shadow && border && styles.shadowInnerLight,
            { width: remSize, height: remSize, borderRadius },
        ]}>
        <View style={[
            styles.imageWrap,
            { borderRadius },
            backgroundDark && styles.backgroundDark,
        ]}>
          {source && !useFallback && !('R44' in size) ? (<FastImageCommon url={source} imageStyle={[
                staticStyles.image,
                backgroundDark
                    ? undefined
                    : styles.imageBackground,
                {
                    width: contentWidth,
                    height: contentWidth,
                    borderRadius,
                },
            ]} onError={onError}/>) : (<View style={[
                staticStyles.image,
                { width: contentWidth, height: contentWidth },
            ]}>
              <IconTransactionCategory width={categoryIconWidth} height={categoryIconWidth} category={transaction.category}>
                {renderText()}
              </IconTransactionCategory>
            </View>)}
        </View>
      </View>
    </View>);
};
const staticStyles = StyleSheet.create({
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
    },
});
const styleSet = createStyleSheets((colors) => ({
    backgroundDark: {
        backgroundColor: colors.background.dark,
    },
    imageWrap: {
        backgroundColor: colors.cards.onLight,
        overflow: 'hidden',
    },
    imageContainer: {
        borderWidth: rem(7.24),
        borderColor: colors.background.transparent,
        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imageBackground: {
        backgroundColor: colors.background.logo,
    },
    background: {
        backgroundColor: colors.background.brand,
        position: 'absolute',
    },
    lightBg: {
        backgroundColor: colors.cards.onDark,
    },
    shadow: {
        ...colors.shadows.logoShadow,
    },
    shadowInner: {
        ...colors.shadows.logoShadowInner,
    },
    shadowInnerLight: {
        ...colors.shadows.logoShadowInnerLight,
    },
    emoji: {
        fontFamily: 'System',
        textAlign: 'center',
        color: 'black',
    },
}));
export default memo(TransactionLogo, (prev, next) => prev.shadow === next.shadow &&
    prev.backgroundDark === next.backgroundDark &&
    prev.border === next.border &&
    prev.transaction.suggestedData?.iconUrl ===
        next.transaction.suggestedData?.iconUrl &&
    prev.transaction.merchant?.iconUrl === next.transaction.merchant?.iconUrl &&
    prev.transaction.merchant?.displayName ===
        next.transaction.merchant?.displayName &&
    prev.transaction.category?.color === next.transaction.category?.color &&
    prev.transaction.category?.id === next.transaction.category?.id &&
    prev.transaction.category?.emoji === next.transaction.category?.emoji &&
    prev.transaction.category?.displayName ===
        next.transaction.category?.displayName &&
    prev.style === next.style);
