import Modal from 'design-system/Modal';
import { StyleSheet, View } from 'react-native';
import { SettingsItem } from 'design-system/SettingsItem';
import { IconEdit, IconTrash } from 'design-system/icons';
import React from 'react';
import { rem } from 'design-system/values';
import { LineSeparator } from 'design-system/LineSeparator';
export const EditDeleteModal = {
    show: ({ key, onDelete, onEdit, options = {}, }) => {
        Modal.show(<View style={styles.modalContent}>
        <SettingsItem icon={<IconEdit gradientKey="lightPink"/>} title="Edit" onPress={() => {
                onEdit?.(key);
                Modal.hide();
            }}/>
        <LineSeparator />
        <SettingsItem icon={<IconTrash gradientKey="red"/>} title="Delete" onPress={() => {
                onDelete?.(key);
                Modal.hide();
            }}/>
      </View>, {
            containerStyle: styles.modal,
            title: 'Edit Goal',
            ...options,
        });
    },
    hide: () => {
        Modal.hide();
    },
};
const styles = StyleSheet.create({
    modal: {
        paddingHorizontal: 0,
        overflow: 'hidden',
    },
    modalContent: {
        paddingVertical: rem(20),
    },
    content: {
        paddingTop: rem(16),
        paddingBottom: rem(100),
    },
    noGoalsCard: {
        marginVertical: 0,
        padding: rem(16),
        justifyContent: 'center',
        alignItems: 'center',
    },
    noGoalsTextContainer: {
        alignItems: 'center',
        marginVertical: rem(16),
    },
    secondaryButton: {
        height: rem(34),
        alignSelf: 'center',
        minWidth: rem(129),
    },
});
