export const prepareParams = (params) => {
    const result = {};
    Object.keys(params).forEach((key) => {
        const value = params[key];
        if (typeof value === 'string') {
            result[key] = value;
        }
        else if (typeof value === 'number') {
            result[key] = value;
        }
        else if (value instanceof Array) {
            result[key] = value;
        }
        else {
            Object.keys(value).forEach((subKey) => {
                result[`${key}[${subKey}]`] = value[subKey];
            });
        }
    });
    return result;
};
