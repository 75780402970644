import React, { useCallback } from 'react';
import { SettingsItem } from 'design-system/SettingsItem';
import { CardView } from 'design-system/CardView';
import Section from 'design-system/Section';
import IconLoading from 'design-system/icons/IconLoading';
import Links from 'utils/links';
import TransactionHelpItem from './TransactionHelpItem';
import { styles } from './styles';
const TransactionPendingSection = () => {
    const openHelp = useCallback(() => {
        Links.openKnownLink('pendingTxnHelp');
    }, []);
    return (<>
      <Section title="Status" style={styles.section}>
        <CardView noMarginTop noMarginBottom>
          <SettingsItem icon={<IconLoading />} title="Pending transaction"/>
        </CardView>
      </Section>
      <Section title="Help" style={styles.section}>
        <CardView noMarginTop>
          <TransactionHelpItem onPress={openHelp} subTitle="Learn more in our help center" title="Want to know more about pending?"/>
        </CardView>
      </Section>
    </>);
};
export default TransactionPendingSection;
