import React, { useCallback } from 'react';
import { HelpCallToAction } from 'design-system/HelpCallToAction';
import Links from 'utils/links';
const AmendYourBudgetsHelp = () => {
    const onHelpPress = useCallback(() => {
        Links.openKnownLink('budgetingHelp');
    }, []);
    return (<HelpCallToAction onDark onPress={onHelpPress} subTitle="Learn how to do it" title="Need to amend your budgets?"/>);
};
export default AmendYourBudgetsHelp;
