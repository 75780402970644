import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import SectionHeader from 'design-system/SectionHeader';
import { SettingsItemNavigate } from 'design-system/SettingsItem';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { useOpenSearch } from 'features/transactions/hooks/useOpenSearch';
import TagItem from '../tags/TagItem';
import IconTags from '../../icons/IconTags';
import { styles as sectionStyles } from './styles';
const TransactionTagsItem = ({ transaction }) => {
    const navigation = useMainStackNavigation();
    const openAddTags = useCallback(() => {
        navigation.navigate('AddTags', {
            type: 'single',
            id: transaction.id,
            transaction,
            tags: transaction.labels || [],
        });
    }, [transaction]);
    const handleOpenSearch = useOpenSearch();
    const handleTagPress = useCallback((label) => () => {
        handleOpenSearch({
            filterType: 'TAG',
            filterLabel: label,
        });
    }, [handleOpenSearch]);
    return transaction.labels && transaction.labels?.length > 0 ? (<SettingsItemNavigate onPress={openAddTags}>
      <SectionHeader onLight style={sectionStyles.itemWithContentTitle}>
        Tags
      </SectionHeader>
      <View style={styles.tagContainer}>
        {transaction.labels.map((label, index) => (<TagItem key={label} index={index} compact title={label} selected onPress={handleTagPress(label)}/>))}
      </View>
    </SettingsItemNavigate>) : (<SettingsItemNavigate icon={<IconTags />} title="Add tags" onPress={openAddTags}/>);
};
const styles = StyleSheet.create({
    tagContainer: {
        alignSelf: 'flex-start',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
});
export default TransactionTagsItem;
