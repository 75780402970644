const DIGITAL = [
    'savingGoals',
    'trueBalance',
    'billReminders',
    'merchantBudgets',
];
export const checkIsBenefitIdAvailableOnFree = (benefitId, guessedHomeCountry, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
_) => {
    if (benefitId && guessedHomeCountry === 'GB') {
        return DIGITAL.includes(benefitId);
    }
    return false;
};
