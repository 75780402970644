import React from 'react';
import { Path, Defs, LinearGradient, Stop, Svg } from 'react-native-svg';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
const IconPersonal = () => {
    const width = 44;
    const height = 44;
    const colors = useColors();
    return (<Svg width={rem(width)} height={rem(height)} viewBox="0 0 44 44" fill="none">
      <Path d="M22 0C7.07763 0 0 7.07763 0 22C0 36.9224 7.07763 44 22 44C36.9224 44 44 36.9224 44 22C44 7.07763 36.9224 0 22 0Z" fill={colors.background.dark}/>
      <Path d="M19.2317 28.4456V26.0249C19.2317 25.4069 19.7363 24.906 20.3588 24.906H22.6341C22.9331 24.906 23.2197 25.0239 23.4311 25.2337C23.6425 25.4435 23.7612 25.7281 23.7612 26.0249V28.4456C23.7593 28.7025 23.8608 28.9495 24.0431 29.1318C24.2254 29.3141 24.4735 29.4167 24.7323 29.4167H26.2847C27.0097 29.4185 27.7057 29.1339 28.219 28.6256C28.7323 28.1174 29.0208 27.4272 29.0208 26.7075V19.8113C29.0208 19.2299 28.7612 18.6784 28.312 18.3054L23.0311 14.1184C22.1125 13.3843 20.7963 13.408 19.9051 14.1747L14.7447 18.3054C14.2742 18.6674 13.9931 19.2205 13.9792 19.8113V26.7004C13.9792 28.2006 15.2042 29.4167 16.7153 29.4167H18.2322C18.7697 29.4167 19.2065 28.9862 19.2104 28.4526L19.2317 28.4456Z" fill="url(#paint0_linear)"/>
      <Defs>
        <LinearGradient id="paint0_linear" x1="11.2836" y1="14.9085" x2="28.1666" y2="31.4441" gradientUnits="userSpaceOnUse">
          <Stop stopColor={colors.gradients.apricot.colors[0]}/>
          <Stop offset="1" stopColor={colors.gradients.apricot.colors[1]}/>
        </LinearGradient>
      </Defs>
    </Svg>);
};
export default IconPersonal;
