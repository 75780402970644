import React, { useMemo } from 'react';
import { Platform, View } from 'react-native';
import { SquircleView } from 'react-native-figma-squircle';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import useColorScheme from 'hooks/useColorScheme';
import { isWeb } from '../../constants';
const SIZES = {
    small: {
        borderRadius: rem(16),
        size: rem(60),
    },
    medium: {
        borderRadius: rem(18),
        size: rem(68),
    },
    default: {
        borderRadius: rem(20),
        size: rem(80),
    },
    large2: {
        borderRadius: rem(32),
        size: rem(120),
    },
    large1: {
        borderRadius: rem(22),
        size: rem(94),
    },
    large: {
        borderRadius: rem(42),
        size: rem(140),
    },
};
const OnboardingSquircle = ({ style, rotate, children, withShadow, color, ...sizeProp }) => {
    const colors = useColors();
    const colorScheme = useColorScheme();
    const dimensions = (() => {
        if ('medium' in sizeProp && sizeProp.medium) {
            return SIZES.medium;
        }
        if ('small' in sizeProp) {
            return SIZES.small;
        }
        if ('large' in sizeProp) {
            return SIZES.large;
        }
        if ('large1' in sizeProp) {
            return SIZES.large1;
        }
        if ('large2' in sizeProp) {
            return SIZES.large2;
        }
        return SIZES.default;
    })();
    const { size } = dimensions;
    const styles = useStyles(styleSet);
    const squircleParams = useMemo(() => ({
        cornerSmoothing: 1,
        cornerRadius: dimensions.borderRadius,
        fillColor: color || colors.elements.white,
    }), [color, colors.elements.white, dimensions.borderRadius]);
    if (rotate) {
        return (<View style={[styles.squircle, { width: size, height: size }, style]}>
        {!!(withShadow &&
                Platform.OS === 'android' &&
                colorScheme === 'light') && (<View style={styles.shadowViewWrapper}>
            <View style={styles.shadowViewAndroid}/>
          </View>)}
        <SquircleView style={[
                styles.squircle,
                { width: size, height: size },
                styles.squircleRotate,
                withShadow && Platform.OS === 'ios' && styles.forceShadowiOS,
            ]} squircleParams={squircleParams}/>
        {isWeb ? <View style={styles.webWrapper}>{children}</View> : children}
      </View>);
    }
    return (<SquircleView style={[styles.squircle, style]} squircleParams={squircleParams}>
      {children}
    </SquircleView>);
};
const SQUIRCLE_SIZE = rem(80);
const styleSet = createStyleSheets((colors) => ({
    webWrapper: {
        position: 'absolute',
    },
    squircle: {
        width: SQUIRCLE_SIZE,
        height: SQUIRCLE_SIZE,
        justifyContent: 'center',
        alignItems: 'center',
    },
    squircleRotate: {
        position: 'absolute',
        transform: [{ rotate: '45deg' }],
    },
    shadowViewWrapper: {
        width: SQUIRCLE_SIZE,
        height: SQUIRCLE_SIZE,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
    },
    shadowViewAndroid: {
        width: SQUIRCLE_SIZE / 2,
        height: SQUIRCLE_SIZE / 2,
        transform: [{ rotate: '45deg' }],
        backgroundColor: colors.elements.white,
        ...colors.shadows.squircle,
    },
    forceShadowiOS: {
        ...colors.shadows.squircle,
    },
}));
export default OnboardingSquircle;
