import React, { useMemo } from 'react';
import QRCodeUtil from 'qrcode';
import Svg, * as Elements from 'react-native-svg';
import useColors from 'hooks/useColors';
import { rem as mobileRem } from './values';
import { isWeb } from '../constants';
const padding = 1;
const rem = (x) => (isWeb ? x : mobileRem(x));
const generateMatrix = (value, errorCorrectionLevel) => {
    const arr = Array.prototype.slice.call(QRCodeUtil.create(value, { errorCorrectionLevel }).modules.data, 0);
    const sqrt = Math.sqrt(arr.length);
    return arr.reduce((rows, key, index) => (index % sqrt === 0
        ? rows.push([key])
        : rows[rows.length - 1].push(key)) && rows, []);
};
export const QRCode = ({ ecl = 'M', logo, logoMargin = 4, logoSize = 40, size = 226, value, }) => {
    const colors = useColors();
    const color = colors.elements.black;
    const backgroundColor = colors.elements.white;
    const dots = useMemo(() => {
        const dots = [];
        const matrix = generateMatrix(value, ecl);
        const cellSize = (rem(size) - padding * 2) / (matrix.length + 6);
        const qrList = [
            { x: 0, y: 0 },
            { x: 1, y: 0 },
            { x: 0, y: 1 },
        ];
        dots.push(<Elements.Rect key="mainframe" fill={backgroundColor} height={rem(size) - padding * 2} width={rem(size) - padding * 2} x={padding} y={padding} rx={6 * cellSize} ry={6 * cellSize}/>);
        qrList.forEach(({ x, y }) => {
            const x1 = (matrix.length - 7) * cellSize * x + 3 * cellSize + padding;
            const y1 = (matrix.length - 7) * cellSize * y + 3 * cellSize + padding;
            dots.push(<Elements.Rect key={`findpatternframe-${x}-${y}`} stroke={color} strokeWidth={cellSize} fill="transparent" width={6 * cellSize} height={6 * cellSize} rx={2 * cellSize} ry={2 * cellSize} x={x1 + 0.5 * cellSize} y={y1 + 0.5 * cellSize}/>);
            dots.push(<Elements.Rect key={`findpatternfill-${x}-${y}`} fill={color} width={cellSize * 3} height={cellSize * 3} rx={cellSize} ry={cellSize} x={x1 + 2 * cellSize} y={y1 + 2 * cellSize}/>);
        });
        let deadZone = Math.round(rem(logoSize + logoMargin * 2) / cellSize);
        if ((matrix.length - deadZone) % 2 > 0) {
            deadZone -= 1;
        }
        const goodZone = (matrix.length - deadZone) / 2;
        matrix.forEach((row, i) => {
            let deadZoneTop = row.length;
            let deadZoneBottom = -1;
            if (i >= goodZone && i < matrix.length - goodZone) {
                deadZoneTop = goodZone;
                deadZoneBottom = row.length - goodZone;
            }
            row.forEach((column, j) => {
                if (column) {
                    if (!((i < 7 && j < 7) ||
                        (i > matrix.length - 8 && j < 7) ||
                        (i < 7 && j > matrix.length - 8))) {
                        if (j < deadZoneTop || j >= deadZoneBottom) {
                            dots.push(<Elements.Circle key={`dot-${i + 0}-${j + 0}`} cx={i * cellSize + cellSize / 2 + 3 * cellSize + padding} cy={j * cellSize + cellSize / 2 + 3 * cellSize + padding} fill={color} r={cellSize / (isWeb ? 2.5 : 3)} // calculate size of single dots
                            />);
                        }
                    }
                }
            });
        });
        return dots;
    }, [backgroundColor, color, ecl, logoMargin, logoSize, size, value]);
    const logoPosition = size / 2 - logoSize / 2;
    return (<Svg height={rem(size)} width={rem(size)}>
      <Elements.Defs>
        <Elements.ClipPath id="clip-logo">
          <Elements.Rect height={rem(logoSize)} width={rem(logoSize)}/>
        </Elements.ClipPath>
      </Elements.Defs>
      {dots}
      {logo && (<Elements.G x={rem(logoPosition)} y={rem(logoPosition)}>
          <Elements.Image clipPath="url(#clip-logo)" width={rem(logoSize)} height={rem(logoSize)} href={logo} preserveAspectRatio="xMidYMid slice"/>
        </Elements.G>)}
    </Svg>);
};
export default { QRCode };
