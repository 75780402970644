import day from 'design-system/colors/day';
import night from 'design-system/colors/night';
import useColorScheme from './useColorScheme';
const useColors = (lightModeOnly) => {
    const colorScheme = useColorScheme();
    if (lightModeOnly) {
        return day;
    }
    return colorScheme === 'dark' ? night : day; // feature flag is checked in `useColorScheme`
};
/**
 * @param gradientKey Mandatory. Should always be set, but takes least priority
 * @param gradientColors Takes precedence
 * @param overrideColor Used only when `gradientColors` is omitted
 * @param colorKey Used when both `gradientColors` and `overrideColor` are not set
 * @returns [colorA, colorB]
 */
export const useGradientColors = (
/**
 * Mandatory. Should always be set, but takes least priority
 */
gradientKey, 
/**
 * Takes precedence
 */
gradientColors, 
/**
 * Used only when `gradientColors` is omitted
 */
overrideColor, colorKey) => {
    const colors = useColors();
    if (gradientColors) {
        return gradientColors;
    }
    if (overrideColor) {
        return [overrideColor, overrideColor];
    }
    if (colorKey) {
        return [colors.elements[colorKey], colors.elements[colorKey]];
    }
    return colors.gradients[gradientKey].colors;
};
/**
 * WARNING: This function doesn't perform any feature flag logic and should only be used inside of navigationOptions callback.
 * @param colorScheme The `theme` argument from navigation options
 * @returns Correct color set for given color scheme.
 */
export const getColorsFromColorScheme = (colorScheme) => (colorScheme === 'dark' ? night : day);
export default useColors;
