import { REGISTER_USER_SUCCESS } from 'actions/types';
import { call, select } from 'typed-redux-saga/macro';
import { storeKeychainUser } from 'utils/keychainUser';
export function* updateKeychainUserSaga(action) {
    if (action.type === REGISTER_USER_SUCCESS &&
        !action.extra.firstName &&
        !action.extra.lastName) {
        // First name/last name have not changed, don't need to save the update
        return;
    }
    const user = yield* select((store) => store.user.user);
    if (user.id > -1) {
        yield* call(storeKeychainUser, user.id, {
            user: {
                firstName: user.firstName,
                lastName: user.lastName,
                userImage: user.userImage,
            },
        });
    }
}
