import React from 'react';
import { View } from 'react-native';
import FastImage from 'utils/packages/rnFastImage';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
const HeaderImage = ({ source, style, imageHasWhiteBackground, onDark, height, imgStyle, resizeMode = 'cover', }) => {
    const styles = useStyles(styleSet);
    return (<View style={[
            styles.container,
            imageHasWhiteBackground && styles.containerWhiteBg,
            onDark &&
                (imageHasWhiteBackground ? styles.onDarkWhiteImage : styles.onDark),
            style,
        ]}>
      <FastImage source={source} style={[
            styles.image,
            imageHasWhiteBackground && styles.imageWhiteBg,
            imgStyle,
            height ? { height } : undefined,
        ]} resizeMode={resizeMode}/>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        width: '100%',
        padding: rem(8),
        height: rem(317),
        borderRadius: rem(24),
        backgroundColor: colors.cards.onLight,
        marginBottom: rem(32),
    },
    containerWhiteBg: {
        padding: rem(4),
        height: rem(309),
        borderRadius: rem(20),
        marginBottom: rem(24),
    },
    image: {
        width: '100%',
        height: rem(301),
        borderRadius: rem(24),
    },
    imageWhiteBg: {
        borderRadius: rem(20),
    },
    onDarkWhiteImage: {
        backgroundColor: colors.cards.transparentOnDark,
    },
    onDark: {
        backgroundColor: colors.cards.onDark,
    },
}));
export default HeaderImage;
