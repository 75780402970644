import React, { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { Alert, FlatList, View, } from 'react-native';
import { FlashList } from '@shopify/flash-list';
import { format, parseISO } from 'date-fns';
import Text from 'design-system/Text';
import Indicator from 'design-system/Indicator';
import StatusBar from 'design-system/StatusBar';
import { AppView } from 'design-system/AppView';
import { CardView } from 'design-system/CardView';
import Button, { HEIGHT } from 'design-system/Button';
import ListEmptyView from 'design-system/ListEmptyView';
import IconTooltip from 'design-system/icons/IconTooltip';
import { rem, SCROLL_INDICATOR_INSETS } from 'design-system/values';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import NavigationHeaderRightIconButton from 'design-system/NavigationHeaderRightIconButton';
import Amplitude from 'utils/amplitude';
import useColors from 'hooks/useColors';
import { muteErrorAndResult, useFetchOld } from 'hooks';
import useEstimatedListSize from 'hooks/useEstimatedListSize';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import useStyles from 'hooks/useStyles';
import { selectCommittedTransactions, selectCurrentPaydayRangeWithFallback, } from 'reducers/selectors';
import { useAppSelector } from 'store/hooks';
import createStyleSheets from 'utils/createStyleSheets';
import Links from 'utils/links';
import usePrivateRoute from 'hooks/usePrivateRoute';
import { getCommitted } from '../actions';
import CommittedSpendingRow from '../components/CommittedSpendingRow';
import { isWeb } from '../../../constants';
const keyExtractor = (item) => {
    if (item.type === 'header') {
        return item.title;
    }
    return (item.data.id.toString() + (item.data.prediction?.date?.toString() || ''));
};
const typeExtractor = ({ type }) => type;
const ESTIMATED_ITEM_SIZE = rem(75);
const addLastInSectionInfo = (flashListData) => {
    const lastItem = flashListData.pop();
    if (lastItem && 'type' in lastItem && lastItem.type !== 'header') {
        flashListData.push({
            ...lastItem,
            isLastInSection: true,
        });
    }
};
const CommittedSpendingScreen = withAnimatedNavigationHeader(({ navigation, route, onScrollY }) => {
    usePrivateRoute();
    const colors = useColors();
    const styles = useStyles(styleSet);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const [, , fetching, fetch] = useFetchOld(muteErrorAndResult);
    const paydayRange = useAppSelector(selectCurrentPaydayRangeWithFallback);
    const toFromObj = useMemo(() => {
        const to = route.params?.toDate;
        const from = route.params?.fromDate;
        if (to && from) {
            return {
                to,
                from,
                toFormatted: format(parseISO(to || paydayRange.to), "do 'of' MMMM"),
            };
        }
        return {
            to: paydayRange.to,
            from: paydayRange.from,
            toFormatted: format(parseISO(to || paydayRange.to), "do 'of' MMMM"),
        };
    }, [
        paydayRange.from,
        paydayRange.to,
        route.params?.fromDate,
        route.params?.toDate,
    ]);
    const openCommittedSpendingHelp = useCallback(() => {
        Links.openKnownLink('committedSpendingHelp');
    }, []);
    useLayoutEffect(() => {
        if (!isWeb) {
            navigation.setOptions({
                headerRight: () => (<NavigationHeaderRightIconButton onPress={openCommittedSpendingHelp}>
              <IconTooltip />
            </NavigationHeaderRightIconButton>),
            });
        }
    }, [colors.background.light, openCommittedSpendingHelp]);
    const guessedHomeCountry = useAppSelector((store) => store.user.user.guessedHomeCountry);
    const committed = useAppSelector((store) => store.subscriptions.committed);
    const committedTransactions = useAppSelector(selectCommittedTransactions);
    const sections = useMemo(() => {
        const array = [];
        const flattenAndPush = (arrayToFlatten) => {
            let hasFilledFirstItem = false;
            arrayToFlatten.forEach(({ data }) => {
                data.forEach((subscription) => {
                    array.push({
                        type: 'subscription',
                        data: subscription,
                        isFirstInSection: !hasFilledFirstItem,
                    });
                    hasFilledFirstItem = true;
                });
            });
        };
        if (committedTransactions.arrayToBePaid.length) {
            const { currency } = committedTransactions.arrayToBePaid[0].data[0];
            array.push({
                type: 'header',
                title: 'Upcoming',
                total: committedTransactions.totalToBePaid,
                currency,
            });
            flattenAndPush(committedTransactions.arrayToBePaid);
            addLastInSectionInfo(array);
        }
        if (committedTransactions.arrayPaid.length) {
            const { currency } = committedTransactions.arrayPaid[0].data[0];
            array.push({
                type: 'header',
                title: 'Already Paid',
                total: committedTransactions.totalPaid,
                currency,
            });
            flattenAndPush(committedTransactions.arrayPaid);
            addLastInSectionInfo(array);
        }
        return array;
    }, [committedTransactions]);
    useEffect(() => {
        fetch(getCommitted(toFromObj.from, toFromObj.to));
    }, [toFromObj.from, toFromObj.to]);
    const bottom = useMarginBottom('bottom', undefined, styles.button);
    const openSubscription = useCallback((data) => {
        if (data?.isInternal) {
            Alert.alert('Internal transfer 🧙‍♂️', "Emma intelligently excludes your credit card repayments, standing orders and internal transfers, so they don't double count in your Analytics.", [
                {
                    text: 'Learn more',
                    onPress: openCommittedSpendingHelp,
                },
                {
                    text: 'Ok, thanks',
                    style: 'cancel',
                },
            ]);
            return;
        }
        navigation.navigate('Subscription', {
            id: data.id,
        });
        Amplitude.logEvent('Committed.OpenSubscriptionMenu');
    }, [openCommittedSpendingHelp]);
    const onOpenCompare = useCallback((routeWithParams) => {
        navigation.navigate(routeWithParams);
        Amplitude.logEvent('Committed.openCompare', {
            type: routeWithParams.name,
        });
    }, []);
    const openSubscriptions = useCallback(() => {
        navigation.navigate('Subscriptions');
        Amplitude.logEvent('Committed.OpenSubscriptions');
    }, []);
    const renderItem = useCallback(({ item }) => (<CommittedSpendingRow item={item} onOpenCompare={onOpenCompare} openSubscription={openSubscription} guessedHomeCountry={guessedHomeCountry}/>), [onOpenCompare, openSubscription, guessedHomeCountry]);
    const scrolled = useCallback(({ nativeEvent: { contentOffset: { y }, }, }) => onScrollY(y), [onScrollY]);
    const estimatedListSize = useEstimatedListSize(sections);
    const renderEmpty = useMemo(() => {
        if ((committed && !committed.subscriptions) || fetching) {
            return (<CardView style={styles.indicatorContainer}>
            <Indicator dark/>
          </CardView>);
        }
        return (<ListEmptyView title="No committed payments" style={styles.centered} onCard/>);
    }, [committed, fetching, styles.centered, styles.indicatorContainer]);
    const contentContainerStyle = useMarginBottom('paddingBottom', HEIGHT + rem(16), paddingHorizontalStyle);
    const renderHeader = useMemo(() => sections.length ? (<View style={styles.spendingUntilCard}>
            <Text TextThin-16 OnBrand centered>
              Your spending until{' '}
              <Text Text-16 OnBrand>
                {toFromObj.toFormatted}
              </Text>
            </Text>
          </View>) : undefined, [sections.length, styles.spendingUntilCard, toFromObj.toFormatted]);
    const baseProps = {
        onScroll: scrolled,
        contentContainerStyle,
        scrollEventThrottle: 16,
        scrollIndicatorInsets: SCROLL_INDICATOR_INSETS,
        renderItem,
        ListHeaderComponent: renderHeader,
        ListEmptyComponent: renderEmpty,
        data: sections,
        keyExtractor,
    };
    return (<AppView withFrame={false}>
        <View style={styles.content}>
          <StatusBar barStyle="dark-content" animated/>
          {isWeb ? (<FlatList {...baseProps}/>) : (<FlashList {...baseProps} getItemType={typeExtractor} estimatedItemSize={ESTIMATED_ITEM_SIZE} estimatedListSize={estimatedListSize}/>)}
          {!isWeb && (<Button brand floating style={bottom} onPress={openSubscriptions} title="Manage your recurring payments"/>)}
        </View>
      </AppView>);
}, ({ colors }) => ({
    title: 'Committed Spending',
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styleSet = createStyleSheets((colors) => ({
    content: {
        flex: 1,
    },
    button: {
        position: 'absolute',
        left: rem(16),
        right: rem(16),
    },
    spendingUntilCard: {
        paddingHorizontal: rem(12),
        paddingVertical: rem(16),
        marginHorizontal: rem(16),
        marginBottom: rem(8),
        marginTop: rem(16),
        borderRadius: rem(16),
        backgroundColor: colors.background.brandLight,
    },
    centered: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    indicatorContainer: {
        alignItems: 'center',
        paddingVertical: '25%',
        justifyContent: 'center',
    },
}));
export default CommittedSpendingScreen;
