import React, { memo } from 'react';
import { FlatList, StyleSheet } from 'react-native';
import { useMarginBottom } from 'hooks/useMarginBottom';
import { rem } from 'design-system/values';
import CircleButton from './CircleButton';
const numbers = [
    { id: 1, title: '1' },
    { id: 2, title: '2' },
    { id: 3, title: '3' },
    { id: 4, title: '4' },
    { id: 5, title: '5' },
    { id: 6, title: '6' },
    { id: 7, title: '7' },
    { id: 8, title: '8' },
    { id: 9, title: '9' },
    { id: 10, title: 'Forgot', image: require('../img/back.png') },
    { id: 11, title: '0' },
    { id: 12, title: 'Cancel', image: require('./img/fingerprint.png') },
];
const keyExtractor = (item) => item.title;
const PinPad = ({ secondOption, isTouchIDIcon, onPress, onPressOption, }) => {
    const marginBottom = useMarginBottom('marginBottom', undefined, styles.item);
    const renderItem = ({ item }) => (<CircleButton item={item} secondOption={secondOption} isTouchIDIcon={isTouchIDIcon} onPress={onPress} onPressOption={onPressOption}/>);
    return (<FlatList scrollEnabled={false} showsVerticalScrollIndicator={false} numColumns={3} data={numbers} renderItem={renderItem} style={marginBottom} keyExtractor={keyExtractor}/>);
};
const styles = StyleSheet.create({
    item: {
        marginHorizontal: rem(16),
    },
});
export default memo(PinPad);
