import React, { useCallback } from 'react';
import TouchableBounce from 'utils/packages/TouchableBounce';
import IconMagicBall from 'design-system/icons/IconMagicBall';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import * as selectors from 'reducers/selectors';
import getSymbolFromCurrency from 'currency-symbol-map';
import useStyles from 'hooks/useStyles';
import { useAppSelector } from 'store/hooks';
import createStyleSheets from 'utils/createStyleSheets';
import { useMainStackNavigation } from 'utils/types/navigationV6';
const strings = {
    findOut: 'Find out',
};
const OneMillion = () => {
    const feed = useAppSelector(selectors.selectFeed);
    const styles = useStyles(styleSet);
    const balance = feed.cards.overview?.netWorth;
    const navigation = useMainStackNavigation();
    const onPress = useCallback(() => {
        navigation.navigate('OneMillionNetWorth');
    }, []);
    if (feed?.cards?.oneMillionNetWorth?.yearsLeft) {
        const currencyValue = balance && balance.currency
            ? getSymbolFromCurrency(balance.currency) || ''
            : '';
        return (<TouchableBounce style={styles.container} onPress={onPress} hoverStyle={styles.hoverOnDark}>
        <Text TextThin-14 Gray flex style={styles.text}>
          How long would it take you to go up to {currencyValue}1m?{'\n'}
          <Text Text-14 Primary>
            {strings.findOut}
          </Text>
        </Text>
        <IconMagicBall />
      </TouchableBounce>);
    }
    return null;
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: colors.cards.onDark,
        borderRadius: rem(12),
        marginHorizontal: rem(16),
        padding: rem(16),
        marginBottom: rem(16),
    },
    text: {
        flexShrink: 1,
    },
    hoverOnDark: {
        backgroundColor: colors.control.hoverOnDark,
    },
}));
export default OneMillion;
