import { CALL_API } from '../middleware/api';
import * as types from './types';
export const getMonitoredAccounts = () => ({
    [CALL_API]: {
        endpoint: '/data-breaches/monitored-accounts',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_MONITORED_ACC_REQUEST,
            types.GET_MONITORED_ACC_SUCCESS,
            types.GET_MONITORED_ACC_FAILURE,
        ],
    },
});
export const saveMonitoredPhone = (phone) => ({
    [CALL_API]: {
        endpoint: '/data-breaches/monitored-account',
        method: 'PUT',
        body: JSON.stringify({
            accountType: 'phone',
            account: phone,
        }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.SAVE_MONITORED_PHONE_REQUEST,
            types.SAVE_MONITORED_PHONE_SUCCESS,
            types.SAVE_MONITORED_PHONE_FAILURE,
        ],
    },
});
export const saveMonitoredEmail = (email) => ({
    [CALL_API]: {
        endpoint: '/data-breaches/monitored-account',
        method: 'PUT',
        body: JSON.stringify({
            accountType: 'email',
            account: email,
        }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.SAVE_MONITORED_EMAIL_REQUEST,
            types.SAVE_MONITORED_EMAIL_SUCCESS,
            types.SAVE_MONITORED_EMAIL_FAILURE,
        ],
    },
});
export const deleteMonitoredAcc = (accId) => ({
    [CALL_API]: {
        endpoint: `/data-breaches/monitored-account/${accId}`,
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.DELETE_MONITORED_ACC_REQUEST,
            types.DELETE_MONITORED_ACC_SUCCESS,
            types.DELETE_MONITORED_ACC_FAILURE,
        ],
    },
});
export const getBreaches = (page = 1) => ({
    [CALL_API]: {
        endpoint: `/data-breaches?&page=${page}&perPage=20`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_BREACHES_REQUEST,
            types.GET_BREACHES_SUCCESS,
            types.GET_BREACHES_FAILURE,
        ],
    },
});
export const resetBreaches = () => ({
    type: types.RESET_BREACHES,
});
