/**
 * A component that shows some statistics with money amounts.
 *
 * For example, in Net Worth feature:
 *
 *   Assets      $290.00
 *   Debt        $580.00
 *   Net worth  -$290.00
 *
 * It does not calculate the "total" row for you.
 * Rows are not tapable.
 * Titles must be unique, they are used as keys.
 */
import React, { memo } from 'react';
import { View } from 'react-native';
import OverviewRow from 'design-system/OverviewRow';
import { rem } from 'design-system/values';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import { isWeb } from '../constants';
const OverviewSection = ({ items, onPress }) => {
    const styles = useStyles(styleSet);
    return (<View style={styles.container}>
      {items.map((item) => (<OverviewRow item={item} key={item.title} onPress={onPress}/>))}
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        overflow: 'hidden',
        backgroundColor: colors.cards.onDark,
        borderRadius: rem(16),
        marginHorizontal: rem(16),
        marginBottom: rem(16),
        ...(isWeb && {
            paddingTop: rem(8),
            paddingHorizontal: rem(8),
        }),
    },
}));
export default memo(OverviewSection);
