import { REHYDRATE } from 'redux-persist';
import * as types from '../actions/types';
const initialState = {
    quiz: [],
    score: null,
    answer: null,
    isQuizFetching: false,
};
const quiz = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE:
            const { quiz } = action.payload || {};
            const returnState = JSON.parse(JSON.stringify(initialState));
            if (quiz) {
                return Object.assign(returnState, quiz, {
                    quiz: [],
                });
            }
            return state;
        case types.GET_QUIZ_REQUEST:
            return { ...state, isQuizFetching: true };
        case types.GET_RECEIPTS_FAILURE:
            return { ...state, isQuizFetching: false };
        case types.GET_QUIZ_SUCCESS:
            return { ...state, quiz: action.payload, isQuizFetching: false };
        case types.POST_ANSWER_SUCCESS:
            return { ...state };
        case types.LOGGED_OUT: {
            return initialState;
        }
        default:
            return state;
    }
};
export default quiz;
