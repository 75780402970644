import { AppRegistry } from 'react-native';

import './js/utils/log';
import name from './app.json';
import WebRoot from './js/WebRoot';
import Sentry from './js/utils/sentry';

import './js/utils/defaultProps';

import montserratBold from './assets/fonts/Montserrat-Bold.ttf';
import montserratLight from './assets/fonts/Montserrat-Light.ttf';
import montserratRegular from './assets/fonts/Montserrat-Regular.ttf';

import nunitoBold from './assets/fonts/Nunito-Bold.ttf';
import nunitoBlack from './assets/fonts/Nunito-Black.ttf';
import nunitoSemiBold from './assets/fonts/Nunito-SemiBold.ttf';
import nunitoRegular from './assets/fonts/Nunito-Regular.ttf';
import nunitoExtraBold from './assets/fonts/Nunito-ExtraBold.ttf';

const regularFontStyles = `@font-face {
  src: url(${montserratRegular});
  font-family: 'Montserrat-Regular';
}`;
const lightFontStyles = `@font-face {
  src: url(${montserratLight});
  font-family: 'Montserrat-Light';
}`;
const boldFontStyles = `@font-face {
  src: url(${montserratBold});
  font-family: 'Montserrat-Bold';
}`;

const numberRegularFontStyles = `@font-face {
  src: url(${nunitoRegular});
  font-family: 'Nunito-Regular';
}`;
const numberBoldFontStyles = `@font-face {
  src: url(${nunitoBold});
  font-family: 'Nunito-Bold';
}`;
const numberSemiBoldFontStyles = `@font-face {
  src: url(${nunitoSemiBold});
  font-family: 'Nunito-SemiBold';
}`;
const numberBlackFontStyles = `@font-face {
  src: url(${nunitoBlack});
  font-family: 'Nunito-Black';
}`;
const numberExtraBoldFontStyles = `@font-face {
  src: url(${nunitoExtraBold});
  font-family: 'Nunito-ExtraBold';
}`;

// eslint-disable-next-line no-undef
const style = document.createElement('style');

style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = regularFontStyles;
} else {
  // eslint-disable-next-line no-undef
  style.appendChild(document.createTextNode(numberBoldFontStyles));
  // eslint-disable-next-line no-undef
  style.appendChild(document.createTextNode(numberSemiBoldFontStyles));
  // eslint-disable-next-line no-undef
  style.appendChild(document.createTextNode(numberBlackFontStyles));
  // eslint-disable-next-line no-undef
  style.appendChild(document.createTextNode(numberRegularFontStyles));
  // eslint-disable-next-line no-undef
  style.appendChild(document.createTextNode(numberExtraBoldFontStyles));

  // eslint-disable-next-line no-undef
  style.appendChild(document.createTextNode(boldFontStyles));
  // eslint-disable-next-line no-undef
  style.appendChild(document.createTextNode(lightFontStyles));
  // eslint-disable-next-line no-undef
  style.appendChild(document.createTextNode(regularFontStyles));
}

// eslint-disable-next-line no-undef
document.head.appendChild(style);

Sentry.init();

AppRegistry.registerComponent(name, () => WebRoot);
AppRegistry.runApplication(name, {
  initialProps: {},
  // eslint-disable-next-line no-undef
  rootTag: document.getElementById('app-root'),
});
