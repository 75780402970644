import React, { useEffect, useRef, useState } from 'react';
import { View, StyleSheet, Image, Animated } from 'react-native';
export default (props) => {
    const delay = props.delay ?? 0;
    const animatedValue = useRef(new Animated.Value(0.55)).current;
    const [width, setWidth] = useState(144);
    const onLayout = (event) => setWidth(event.nativeEvent.layout.width);
    useEffect(() => {
        const animation = Animated.sequence([
            Animated.delay(delay),
            Animated.loop(Animated.sequence([
                Animated.timing(animatedValue, {
                    toValue: 1,
                    duration: 800,
                    useNativeDriver: true,
                }),
                Animated.timing(animatedValue, {
                    toValue: 0.55,
                    duration: 800,
                    useNativeDriver: true,
                }),
            ])),
        ]);
        animation.start();
        return () => {
            animation.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<View style={props.style} onLayout={onLayout}>
      <Image source={require('../img/face.png')} style={styles.face}/>
      <Animated.Image source={require('../img/eye.png')} style={[
            styles.leftEye,
            {
                transform: [
                    { scale: animatedValue },
                    {
                        translateX: animatedValue.interpolate({
                            inputRange: [0, 1],
                            outputRange: [0.5 * width, 0],
                        }),
                    },
                ],
            },
        ]}/>
      <Animated.Image source={require('../img/eye.png')} style={[
            styles.rightEye,
            {
                transform: [
                    { scale: animatedValue },
                    {
                        translateX: animatedValue.interpolate({
                            inputRange: [0, 1],
                            outputRange: [-0.5 * width, 0],
                        }),
                    },
                ],
            },
        ]}/>
    </View>);
};
const styles = StyleSheet.create({
    face: {
        width: '100%',
        height: '100%',
    },
    leftEye: {
        width: '81%',
        height: '81%',
        top: '5%',
        left: '-24%',
        position: 'absolute',
    },
    rightEye: {
        width: '81%',
        height: '81%',
        position: 'absolute',
        top: '5%',
        right: '-24%',
    },
});
