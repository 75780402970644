import React from 'react';
import { View, StyleSheet } from 'react-native';
import useColors from 'hooks/useColors';
import Text from './Text';
import StatusIcon from './StatusIcon';
import IconStatusDone from './icons/status/IconStatusDone';
import IconStatusPending from './icons/status/IconStatusPending';
import Spacer from './Spacer';
const AddressVerificationStatusView = ({ status, ...props }) => {
    const colors = useColors();
    if (status === 'SUCCESS') {
        return (<View style={styles.row} {...props}>
          <IconStatusDone gradientKey="ultimate"/>
          <Spacer w={8}/>
          <Text TextThin-14 Secondary>
            Verified
          </Text>
        </View>);
    }
    if (status === 'PENDING') {
        return (<View style={styles.row} {...props}>
          <IconStatusPending bgColor={colors.elements.secondaryLight} color={colors.background.dark}/>
          <Spacer w={8}/>
          <Text TextThin-14 Secondary>
            Verification pending
          </Text>
        </View>);
    }
    if (status === 'FAIL') {
        return (<View style={styles.row} {...props}>
          <StatusIcon state="cancelled-red"/>
          <Spacer w={8}/>
          <Text TextThin-14 Secondary>
            Rejected
          </Text>
        </View>);
    }
    return (<View style={styles.row} {...props}>
        <StatusIcon state="cancelled-red"/>
        <Spacer w={8}/>
        <Text TextThin-14 Secondary>
          Not verified
        </Text>
      </View>);
};
export default AddressVerificationStatusView;
const styles = StyleSheet.create({
    row: {
        alignItems: 'center',
        flexDirection: 'row',
    },
});
