import { useCallback } from 'react';
import Alert from 'utils/packages/Alert';
const useActionSheet = () => {
    const showActionSheetWithOptions = useCallback((options, callback) => {
        const alertButtons = options.options.map((option, index) => ({
            text: option,
            style: 'cancel',
            onPress: () => callback(index),
        }));
        Alert.alert('Slow down 🤪', options.title, alertButtons);
    }, []);
    return { showActionSheetWithOptions };
};
export { useActionSheet };
