import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import Amount from 'design-system/Amount';
import { rem } from 'design-system/values';
const AnalyticsSectionHeader = ({ title, amount, currency, }) => (<View style={styles.container}>
    <Text Text-16 Gray flex>
      {title}
    </Text>
    <Amount showSign Numbers-16 Primary value={{ value: amount, currency }}/>
  </View>);
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        marginHorizontal: rem(16),
        marginVertical: rem(8),
    },
});
export default memo(AnalyticsSectionHeader);
