import { useCallback } from 'react';
import { useFetchSearchTransactionData } from 'hooks';
import Amplitude from 'utils/amplitude';
import { useMainStackNavigation } from 'utils/types/navigationV6';
export const useOpenSearch = () => {
    const navigation = useMainStackNavigation();
    const fetchSearchData = useFetchSearchTransactionData();
    return useCallback((params) => {
        Amplitude.logEvent('Transactions.OpenSearch', { type: 'Transactions' });
        fetchSearchData();
        navigation.navigate('SearchTransactions', params || {});
    }, [fetchSearchData]);
};
