import React, { useRef } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { hitSlop, rem } from 'design-system/values';
import { IconClose } from 'design-system/icons/IconClose';
import { IconEdit } from 'design-system/icons/IconEdit';
import NavigationHeaderRightIconButton from 'design-system/NavigationHeaderRightIconButton';
import { useAccountMenu } from 'features/accounts/hooks/useAccountMenu';
import IconMore from 'features/subscriptions/icons/IconMore';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import Amplitude from 'utils/amplitude';
import createStyleSheets from 'utils/createStyleSheets';
import { useAppSelector } from 'store/hooks';
import { selectUserId } from 'reducers/selectors';
import { isWeb } from '../../../../constants';
const AccountsHeaderRightButtons = ({ isEditing, onPressEdit, account, navigation, }) => {
    const userId = useAppSelector(selectUserId);
    const pressEdit = () => onPressEdit?.(!isEditing);
    const touchViewRef = useRef(null);
    const openMenu = useAccountMenu(navigation);
    const pressMenu = () => {
        openMenu(account, touchViewRef);
        Amplitude.logEvent('Accounts.OpenAccountMenu');
    };
    const colors = useColors();
    const styles = useStyles(styleSet);
    return (<View style={styles.headerRightContainer}>
      <NavigationHeaderRightIconButton onPress={pressEdit}>
        {isEditing ? (<IconClose alt color={colors.text.primary} style={styles.closeIcon}/>) : (<IconEdit color={colors.text.primary}/>)}
      </NavigationHeaderRightIconButton>

      {userId === account.userId ? (<View ref={touchViewRef} style={styles.menuButton}>
          {isWeb ? (<NavigationHeaderRightIconButton onPress={pressMenu}>
              <IconMore color={colors.text.primary}/>
            </NavigationHeaderRightIconButton>) : (<TouchableOpacity hitSlop={hitSlop} onPress={pressMenu}>
              <IconMore color={colors.text.primary}/>
            </TouchableOpacity>)}
        </View>) : null}
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    headerRightContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    closeIcon: {
        color: colors.elements.white, // Figma: Emma Design System -> Headers -> Header Actions
    },
    menuButton: {
        ...(!isWeb && { marginLeft: rem(16) }),
    },
}));
export default AccountsHeaderRightButtons;
