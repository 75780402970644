import { muteErrorAndResult, useFetchOld, useImagePicker } from 'hooks';
import ImageResizer from 'react-native-image-resizer';
import { getUser, postProfilePicture, deleteProfilePicture } from 'actions';
import { isEmpty } from 'lodash';
import useAppRating from 'hooks/useAppRating';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { UPDATE_KEYCHAIN_USER } from 'actions/types';
export const useProfileImagePicker = () => {
    const rateApp = useAppRating();
    const [, , isImageUploading, fetch] = useFetchOld(muteErrorAndResult);
    const userImage = useAppSelector((store) => store.user.user.userImage);
    const dispatch = useAppDispatch();
    const removeProfileImagePicker = async () => {
        await fetch(deleteProfilePicture());
        await fetch(getUser());
        dispatch({ type: UPDATE_KEYCHAIN_USER });
    };
    const { showImagePicker } = useImagePicker({
        title: 'Select Profile Picture',
        onRemove: removeProfileImagePicker,
    });
    const showProfileImagePicker = async () => {
        const asset = await showImagePicker({ hasRemove: !isEmpty(userImage) });
        if (asset && asset.uri) {
            const resizedImage = await ImageResizer.createResizedImage(asset.uri, 300, 300, 'JPEG', 90);
            // eslint-disable-next-line no-undef
            const body = new FormData();
            body.append('image', {
                uri: resizedImage.uri,
                name: resizedImage.name,
                type: 'image/jpeg',
            });
            const response = await fetch(postProfilePicture(body));
            if (response && response.status?.toLowerCase() === 'saved') {
                fetch(getUser()).then(() => {
                    dispatch({ type: UPDATE_KEYCHAIN_USER });
                });
            }
            rateApp();
        }
    };
    return {
        showProfileImagePicker,
        isImageUploading,
    };
};
