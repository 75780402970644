import { Crisp } from 'crisp-sdk-web';
import { useCallback } from 'react';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { isWeb } from '../../../constants';
const useOpenSupport = () => {
    const navigation = useMainStackNavigation();
    const openContactSupport = useCallback(() => {
        if (isWeb) {
            Crisp.chat.open();
        }
        else {
            navigation.navigate('Support', {});
        }
    }, []);
    return openContactSupport;
};
export default useOpenSupport;
