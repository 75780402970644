import * as types from '../../../actions/types';
import { CALL_API } from '../../../middleware/api';
export const sendReferralCode = (body) => ({
    [CALL_API]: {
        endpoint: '/referrals/register',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        types: [
            types.SEND_REFERRAL_CODE_REQUEST,
            types.SEND_REFERRAL_CODE_SUCCESS,
            types.SEND_REFERRAL_CODE_FAILURE,
        ],
        muteAlert: true,
    },
});
export const payReferral = (body) => ({
    [CALL_API]: {
        endpoint: '/referrals/paypal-cashout',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        types: [
            types.PAY_REFERRAL_REQUEST,
            types.PAY_REFERRAL_SUCCESS,
            types.PAY_REFERRAL_FAILURE,
        ],
    },
});
export const getReferrals = () => ({
    [CALL_API]: {
        endpoint: '/referrals',
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        types: [
            types.GET_REFERRALS_REQUEST,
            types.GET_REFERRALS_SUCCESS,
            types.GET_REFERRALS_FAILURE,
        ],
    },
});
export const getCreditReferrals = () => ({
    [CALL_API]: {
        endpoint: '/referrals/credit',
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        types: [
            types.GET_CREDIT_REFERRALS_REQUEST,
            types.GET_CREDIT_REFERRALS_SUCCESS,
            types.GET_CREDIT_REFERRALS_FAILURE,
        ],
    },
});
export const getActiveReferralProgram = () => ({
    [CALL_API]: {
        endpoint: '/referral-programs/active',
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        types: [
            types.GET_ACTIVE_REFERRAL_PROGRAM_REQUEST,
            types.GET_ACTIVE_REFERRAL_PROGRAM_SUCCESS,
            types.GET_ACTIVE_REFERRAL_PROGRAM_FAILURE,
        ],
    },
});
export const setReferralSeen = (body) => ({
    [CALL_API]: {
        endpoint: '/referrals/seen',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        extra: body,
        types: [
            types.SET_REFERRAL_SEEN_REQUEST,
            types.SET_REFERRAL_SEEN_SUCCESS,
            types.SET_REFERRAL_SEEN_FAILURE,
        ],
    },
});
