import React, { memo } from 'react';
import Animated, { interpolate, useAnimatedStyle, } from 'react-native-reanimated';
import { StyleSheet } from 'react-native';
import Text from 'design-system/Text';
const ReanimatedStackHeader = ({ title, scrollY, width, }) => {
    const opacityReanimatedStyles = useAnimatedStyle(() => ({
        opacity: interpolate(scrollY?.value, [40, 50], [0, 1]),
    }));
    return (<Animated.View style={[styles.reanimatedHeader, { width }, opacityReanimatedStyles]}>
      <Text Text-18 numberOfLines={1}>
        {title}
      </Text>
    </Animated.View>);
};
const styles = StyleSheet.create({
    reanimatedHeader: {
        alignItems: 'center',
    },
});
export default memo(ReanimatedStackHeader);
