import { getPlaidLink, getSyncingStatus, linkPlaidConnection, setConnectionsStatus, } from 'actions';
import { useCallback } from 'react';
import { selectConnections } from 'reducers/selectors';
import { useAppDispatch, useAppStore } from 'store/hooks';
import { openPlaid } from 'utils/plaidlink';
import useAppRating from './useAppRating';
const usePlaidLink = () => {
    const dispatch = useAppDispatch();
    const store = useAppStore();
    const rateApp = useAppRating();
    const open = useCallback(() => {
        const dispatchGetPlaidLink = () => dispatch(getPlaidLink());
        const dispatchLinkPlaidConnection = (data) => dispatch(linkPlaidConnection(data));
        const dispatchSetConnectionsStatus = (data) => dispatch(setConnectionsStatus(data));
        const dispatchGetSyncingStatus = (data) => {
            const state = store.getState();
            if (state.user.isLoggedIn && selectConnections(state).length >= 1) {
                rateApp();
            }
            dispatch(getSyncingStatus(data));
        };
        openPlaid(dispatchGetPlaidLink, dispatchLinkPlaidConnection, dispatchSetConnectionsStatus, dispatchGetSyncingStatus);
    }, [dispatch, rateApp, store]);
    return open;
};
export default usePlaidLink;
