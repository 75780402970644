import React, { memo } from 'react';
import { View } from 'react-native';
import useColors from 'hooks/useColors';
import { rem } from './values';
import Squircle from './Squircle';
import IconCheck from './icons/IconCheck';
const RadioButton = ({ style, type, isChecked, testID, }) => {
    const colors = useColors();
    return (<View style={style} testID={testID}>
      <Squircle height={rem(24)} width={rem(24)} color={isChecked ? colors.elements.brand : colors.cards.transparentOnDark}>
        <Squircle width={rem(22)} height={rem(22)} color={isChecked ? colors.background.light : colors.background.dark}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isChecked ? (type === 'multiple' ? (<IconCheck width={rem(16)} thick color={colors.elements.brand}/>) : (<Squircle width={rem(14)} height={rem(14)} color={colors.elements.brand}/>)) : null}
        </Squircle>
      </Squircle>
    </View>);
};
export default memo(RadioButton);
