import moment from 'moment';
import { useCallback, useRef, useState } from 'react';
import { useAppDispatch, useAppStore } from 'store/hooks';
import { apiMiddleware, CALL_API, } from '../middleware/api';
export class ThrowableFetchError extends Error {
    fetchError;
    constructor(error) {
        super(error.errorMessage || error.error);
        this.name = 'ThrowableFetchError';
        this.fetchError = error;
    }
}
// Hook
export const mapToCommonErrorAction = (error, errorMessage) => ({
    ...error,
    errorMessage,
    errorType: 'SetError',
});
export const createApiModule = (moduleName) => (eventName) => [
    `Fetch.${moduleName}.${eventName}_REQUEST`,
    `Fetch.${moduleName}.${eventName}_SUCCESS`,
    `Fetch.${moduleName}.${eventName}_FAILURE`,
];
export const useFetch = (hookOptions) => {
    const callRef = useRef(0);
    const store = useAppStore();
    const dispatch = useAppDispatch();
    const [fetching, setFetching] = useState(false);
    const fetch = useCallback(async (apiCall, options) => {
        // Memoize current call timestamp
        const timeStamp = moment().unix();
        callRef.current = timeStamp;
        if (!hookOptions?.muteFetching) {
            setFetching(true);
        }
        if (hookOptions?.debug)
            debugCall(apiCall[CALL_API]);
        const answer = await apiMiddleware({
            getState: store.getState,
            dispatch: (action) => action,
        })((action) => {
            // Dispatch only error for common handling
            if (action.type === apiCall[CALL_API].types[2]) {
                dispatch({
                    ...action,
                    errorHideModal: hookOptions?.muteAlert ||
                        options?.muteAlert ||
                        apiCall[CALL_API].muteAlert,
                });
            }
            return action;
        })(apiCall);
        // Drop the flag if this call was the last one
        if (timeStamp === callRef.current && !hookOptions?.muteFetching) {
            setFetching(false);
        }
        const [request, success, failure] = apiCall[CALL_API].types;
        if (!answer) {
            const error = {
                request,
                type: failure,
                errorType: 'InvalidToken',
            };
            throw error;
        }
        if (answer?.type === success) {
            if (hookOptions?.debug)
                debugAnswer(answer);
            return answer.payload;
        }
        const error = answer;
        if (hookOptions?.debug)
            debugError(error);
        throw error;
    }, [hookOptions?.muteFetching, hookOptions?.debug, hookOptions?.muteAlert]);
    return [fetch, fetching];
};
export const debugCall = (request) => {
    // eslint-disable-next-line no-console
    console.debug('--->', request.method, request.endpoint, '\nHeaders:\n', request.headers, '\nBody:\n', request.body);
};
export const debugAnswer = (result) => {
    // eslint-disable-next-line no-console
    console.debug('<---', result.apiPayload?.status, result.apiPayload?.statusText ?? '', result.apiPayload?.url, '\nHeaders:\n', result.apiPayload?.headers, '\nBody:\n', result.payload);
};
export const debugError = (error) => {
    const payload = error.payload ?? error.apiPayload;
    // eslint-disable-next-line no-console
    console.debug('<---', payload?.status, payload?.statusText ?? '', payload?.url, '\nHeaders:\n', payload?.headers, '\nBody:\n', error);
};
export default useFetch;
