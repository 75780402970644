import React, { useCallback, useLayoutEffect, useMemo } from 'react';
import { View } from 'react-native';
import Choices from 'design-system/Choices';
import Spacer from 'design-system/Spacer';
import StatusBar from 'design-system/StatusBar';
import { rem } from 'design-system/values';
import { AppView } from 'design-system/AppView';
import { SettingsItemIcon } from 'design-system/SettingsItemIcon';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { DUMMY_ACCOUNT_ICON_MAP } from '../hooks/useDummyAccountLogo';
import { isWeb } from '../../../constants';
import { DUMMY_ACCOUNT_TEXT_MAP } from '../constants';
const strings = {
    screenTitle: 'Account type',
};
const PickAccountTypeScreen = ({ navigation, route, }) => {
    const colors = useColors();
    useLayoutEffect(() => {
        navigation.setOptions({
            title: strings.screenTitle,
            headerTintColor: colors.text.primary,
        });
    }, [colors.background.dark, colors.text.primary]);
    const { type } = route.params;
    const onSelect = useCallback((item) => {
        const params = route.params ? route.params : {};
        let type;
        switch (item) {
            case DUMMY_ACCOUNT_TEXT_MAP['CHECKING']:
                type = 'CHECKING';
                break;
            case DUMMY_ACCOUNT_TEXT_MAP['CREDITCARD']:
                type = 'CREDITCARD';
                break;
            case DUMMY_ACCOUNT_TEXT_MAP['SAVINGS']:
                type = 'SAVINGS';
                break;
            case DUMMY_ACCOUNT_TEXT_MAP['LOAN']:
                type = 'LOAN';
                break;
            case DUMMY_ACCOUNT_TEXT_MAP['INVESTMENT']:
                type = 'INVESTMENT';
                break;
            case DUMMY_ACCOUNT_TEXT_MAP['PENSION']:
                type = 'PENSION';
                break;
            default:
                break;
        }
        navigation.navigate('AddBalance', {
            ...params,
            type,
            keyBack: route.key,
        });
    }, [route.key, route.params]);
    const styles = useStyles(styleSet);
    const choices = useMemo(() => {
        if (type === 'CHECKINGCARDS') {
            return ['CHECKING', 'CREDITCARD'];
        }
        if (type === 'DEBT') {
            return ['CREDITCARD', 'LOAN'];
        }
        return [
            'CHECKING',
            'CREDITCARD',
            'SAVINGS',
            'LOAN',
            'INVESTMENT',
            'PENSION',
        ];
    }, [type]);
    const [data, icons] = useMemo(() => choices.reduce((prev, curr) => [
        [...prev[0], DUMMY_ACCOUNT_TEXT_MAP[curr]],
        [
            ...prev[1],
            <SettingsItemIcon iconSize={44}>
              {DUMMY_ACCOUNT_ICON_MAP[curr]}
            </SettingsItemIcon>,
        ],
    ], [[], []]), [choices]);
    return (<AppView avoidHeader isInTab={isWeb}>
      <View style={styles.container}>
        <StatusBar barStyle="dark-content" animated/>
        <Spacer h={16}/>
        <Choices data={data} icons={icons} onSelect={onSelect}/>
      </View>
    </AppView>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
        paddingHorizontal: rem(16),
        backgroundColor: colors.background.dark,
    },
}));
export default PickAccountTypeScreen;
