import { useDispatch, useSelector, useStore, } from 'react-redux';
/**
 * Use throughout the app instead of `useDispatch`
 * See https://redux.js.org/usage/usage-with-typescript#define-typed-hooks
 */
export const useAppDispatch = useDispatch;
/**
 * Use throughout the app instead of `useSelector`
 * See https://redux.js.org/usage/usage-with-typescript#define-typed-hooks
 */
export const useAppSelector = useSelector;
/**
 * Use throughout the app instead of `useStore`
 * See https://redux.js.org/usage/usage-with-typescript#define-typed-hooks
 */
// @ts-expect-error Thunk actions and api calls don't match satisfy the default Action type
export const useAppStore = useStore;
