import { useCallback } from 'react';
import { Platform } from 'react-native';
import { appleAuth, appleAuthAndroid, } from 'utils/packages/rnAppleAuthentication';
import Config from 'utils/packages/configs';
const uuidV4 = require('uuid/v4');
const useAppleSignIn = () => {
    const signIn = useCallback(async () => {
        if (Platform.OS === 'android') {
            try {
                appleAuthAndroid.configure({
                    clientId: Config.APPLE_SERVICE,
                    redirectUri: `${Config.API_URL}/oauth/redirect/apple`,
                    responseType: appleAuthAndroid.ResponseType.CODE,
                    scope: appleAuthAndroid.Scope.ALL,
                    state: uuidV4(),
                });
                const response = await appleAuthAndroid.signIn();
                return {
                    authorizationCode: response.code,
                    fullName: response.user?.name
                        ? {
                            givenName: response.user?.name?.firstName || null,
                            familyName: response.user?.name?.lastName || null,
                            namePrefix: null,
                            middleName: null,
                            nameSuffix: null,
                            nickname: null,
                        }
                        : null,
                };
            }
            catch (error) {
                if (error.code === appleAuthAndroid.Error.SIGNIN_CANCELLED) {
                    return undefined;
                }
                throw error;
            }
        }
        // performs login request
        try {
            const appleAuthRequestResponse = await appleAuth.performRequest({
                requestedOperation: appleAuth.Operation.LOGIN,
                requestedScopes: [appleAuth.Scope.EMAIL, appleAuth.Scope.FULL_NAME],
            });
            // get current authentication state for user
            const credentialState = await appleAuth.getCredentialStateForUser(appleAuthRequestResponse.user);
            // use credentialState response to ensure the user is authenticated
            if (credentialState === appleAuth.State.AUTHORIZED &&
                appleAuthRequestResponse.authorizationCode) {
                return {
                    authorizationCode: appleAuthRequestResponse.authorizationCode,
                    fullName: appleAuthRequestResponse.fullName,
                };
            }
        }
        catch (error) {
            if (error.code === appleAuth.Error.CANCELED) {
                return undefined;
            }
            throw error;
        }
        return undefined;
    }, []);
    return [signIn];
};
export default useAppleSignIn;
