import React, { memo, useCallback } from 'react';
import Links from 'utils/links';
import { HelpCallToAction } from 'design-system';
const BankLoginsHelp = () => {
    const onHelpPress = useCallback(() => {
        Links.openKnownLink('bankLoginsHelp');
    }, []);
    return (<HelpCallToAction onDark onPress={onHelpPress} subTitle="Learn how to do it" title="Need to amend your accounts?"/>);
};
export default memo(BankLoginsHelp);
