import React from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import Amount from 'design-system/Amount';
import { rem } from 'design-system/values';
import { IconMoneyBills } from 'design-system/icons';
import IconCircle from 'design-system/icons/IconCircle';
import useColors from 'hooks/useColors';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import { isWeb } from '../../../constants';
const strings = {
    title: 'Other categories',
    subtitle: 'Tap to set more category budgets',
};
const UnbudgetedCategoryRow = ({ total, currency, onPress, }) => {
    const colors = useColors();
    return (<TouchableHighlight onCard hoverOnLight onPress={onPress} style={styles.container} underlayColor={colors.background.underlay}>
      <View style={styles.content}>
        <IconCircle width={44} height={44} color={colors.gradients.ultimate.colors[0]}>
          <IconMoneyBills color={colors.background.light}/>
        </IconCircle>
        <View style={styles.innerContent}>
          <View style={styles.titleContainer}>
            <Text Text-16 style={styles.title}>
              {strings.title}
            </Text>
            <Amount Numbers-16 value={{
            value: total,
            currency,
        }}/>
          </View>
          <Text TextThin-14 Gray style={styles.subTitle}>
            {strings.subtitle}
          </Text>
        </View>
      </View>
    </TouchableHighlight>);
};
const styles = StyleSheet.create({
    container: {
        padding: rem(16),
        justifyContent: 'center',
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
    content: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    innerContent: {
        flex: 1,
        marginStart: rem(16),
        flexDirection: 'column',
    },
    titleContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flex: 1,
    },
    title: {
        marginBottom: rem(4),
        flexShrink: 1,
        paddingEnd: rem(12),
    },
    subTitle: {
        marginBottom: rem(8),
    },
});
export default UnbudgetedCategoryRow;
