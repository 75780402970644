import axios from 'axios';
const debug = __DEV__ && true; // feel free to turn this on to see all network logs
const onlyRequest = __DEV__ && false; // turn this on if you don't want to see response bodies
const debugUrlFilter = /./; // filter logs by any regexp here
// eslint-disable-next-line import/prefer-default-export
export const tryAtMost = async (url, options, maxRetries, returnRawError) => {
    try {
        if (debug && url.match(debugUrlFilter)) {
            log(`${options.method} ${url}`, false);
            if (options.data) {
                try {
                    log(options.data);
                }
                catch (error) {
                    // no op
                }
            }
        }
        const res = await axios(url, options);
        if (debug && url.match(debugUrlFilter)) {
            if (!onlyRequest) {
                log(res.data, true, 'green');
            }
            log(`[${res.status}] ${options.method} ${url}: ${JSON.stringify(res.data).length}`, false, 'green');
        }
        return res;
    }
    catch (error) {
        const { response } = error;
        if (response) {
            if (returnRawError) {
                throw error;
            }
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (debug && url.match(debugUrlFilter)) {
                log(`[${response.status}] ${options.method} ${url}`, false, 'red');
                try {
                    log(`\n${JSON.stringify(response.data, null, '  ')}`, false, 'yellow');
                }
                catch {
                    log(`\n${response.data}`, false, 'yellow');
                }
                if (options.headers && options.headers.Authorization) {
                    log(`access_token was: ${options.headers.Authorization.replace('Bearer ', '')}`, false);
                }
            }
            const rejectError = new Error();
            const errorJson = response.data;
            rejectError.message = typeof errorJson.user_message === 'string' ? errorJson.user_message : '';
            rejectError.status = response.status || 500;
            rejectError.code = errorJson.code;
            throw rejectError;
        }
        if (error.request) {
            // The request was made but no response was received - let's try again
            if (maxRetries > 0) {
                return tryAtMost(url, options, maxRetries - 1, returnRawError);
            }
            if (debug) {
                log(`\nEMPTY RESPONSE`, false, 'yellow');
            }
            if (error.code === 'ECONNABORTED') {
                const timeoutError = new Error('This is awkward, please try again.');
                throw timeoutError;
            }
            throw error;
        }
        // error is not related to network. Which means: code above has an error
        log(`Error in ${options.method} ${url}:\n\t${error.message}`, false, 'red'); // this error should be fixed before it gets to production
        throw error;
    }
};
