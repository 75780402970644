import React from 'react';
import { StyleSheet } from 'react-native';
import { CardView, rem } from 'design-system';
import UpgradeButton from 'design-system/UpgradeButton';
const UpgradeToSeeMoreCard = ({ onPressUpgrade, withPadding = true, }) => (<CardView withPadding={withPadding} style={withPadding && styles.upgradeCard}>
    <UpgradeButton onPress={onPressUpgrade} onCard additionalText={'Find, track and cancel all your recurring payments in one place.\n\n'}/>
  </CardView>);
const styles = StyleSheet.create({
    upgradeCard: {
        paddingVertical: rem(32),
    },
});
export default UpgradeToSeeMoreCard;
