import React, { memo, useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { rem } from 'design-system/values';
import SuggestionsListItem from './SuggestionsListItem';
const SuggestionsListWrap = ({ suggestions, contentContainerStyle, didUserAlterName, suggestedName, handleSuggestionPress, }) => {
    const onSuggestionPress = useCallback((name, emoji) => () => {
        handleSuggestionPress(name, emoji);
    }, [handleSuggestionPress]);
    return (<View style={[styles.contentContainerStyles, contentContainerStyle]}>
      <View style={styles.itemsWrapper}>
        {suggestions.flat().map((suggestion) => (<View style={styles.item} key={suggestion.name}>
            <SuggestionsListItem suggestion={suggestion} didUserAlterName={didUserAlterName} suggestedName={suggestedName} onPress={onSuggestionPress}/>
          </View>))}
      </View>
    </View>);
};
const styles = StyleSheet.create({
    contentContainerStyles: {
        paddingTop: rem(4),
        paddingHorizontal: rem(12),
    },
    itemsWrapper: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    item: {
        marginBottom: rem(8),
    },
});
export default memo(SuggestionsListWrap);
