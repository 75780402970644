import { useCallback } from 'react';
import { closeAccount, refreshApp } from 'actions';
import { muteAll, useFetchOld } from 'hooks';
import useRefreshAccounts from 'hooks/useRefreshAccounts';
import { useAppDispatch } from 'store/hooks';
export const useCloseAccount = () => {
    const dispatch = useAppDispatch();
    const [, , , fetch] = useFetchOld(muteAll);
    const refreshAccounts = useRefreshAccounts();
    const close = useCallback((account) => {
        if (!account)
            return;
        const body = {
            isClosed: !account.isClosed,
        };
        fetch(closeAccount(account.id, body)).then((response) => {
            if (response) {
                refreshAccounts(account.type);
                dispatch(refreshApp());
            }
        });
    }, [refreshAccounts]);
    return close;
};
