import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconBell = ({ alt, ...props }) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="ultimate">
      {alt ? (<>
          <Path strokeWidth="2" fillRule="evenodd" clipRule="evenodd" strokeLinecap="round" strokeLinejoin="round" stroke={`url(#gradient_${id})`} d="M11.65 17.2629C16.7253 17.2629 19.0732 16.6118 19.3 13.9985C19.3 11.3869 17.663 11.5549 17.663 8.3506C17.663 5.84773 15.2907 3 11.65 3C8.00929 3 5.63696 5.84773 5.63696 8.3506C5.63696 11.5549 4 11.3869 4 13.9985C4.22766 16.6217 6.5756 17.2629 11.65 17.2629Z"/>
          <Path strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" stroke={`url(#gradient_${id})`} d="M13.8004 19.9714C12.5726 21.3347 10.6574 21.3508 9.41797 19.9714"/>
        </>) : (<Path fillRule="evenodd" clipRule="evenodd" fill={`url(#gradient_${id})`} d="M18.7071 8.79633C18.7071 10.0523 19.039 10.7925 19.7695 11.6456C20.3231 12.2741 20.5 13.0808 20.5 13.956C20.5 14.8302 20.2128 15.6601 19.6373 16.3339C18.884 17.1417 17.8215 17.6573 16.7372 17.747C15.1659 17.8809 13.5937 17.9937 12.0005 17.9937C10.4063 17.9937 8.83505 17.9263 7.26375 17.747C6.17846 17.6573 5.11602 17.1417 4.36367 16.3339C3.78822 15.6601 3.5 14.8302 3.5 13.956C3.5 13.0808 3.6779 12.2741 4.23049 11.6456C4.98384 10.7925 5.29392 10.0523 5.29392 8.79633V8.3703C5.29392 6.68834 5.71333 5.58852 6.577 4.51186C7.86106 2.9417 9.91935 2 11.9558 2H12.0452C14.1254 2 16.2502 2.98702 17.5125 4.62466C18.3314 5.67916 18.7071 6.73265 18.7071 8.3703V8.79633ZM9.07367 20.0608C9.07367 19.5573 9.53582 19.3266 9.96318 19.2279C10.4631 19.1222 13.5093 19.1222 14.0092 19.2279C14.4366 19.3266 14.8987 19.5573 14.8987 20.0608C14.8738 20.5402 14.5926 20.9653 14.204 21.2352C13.7001 21.628 13.1088 21.8767 12.4906 21.9664C12.1487 22.0107 11.8128 22.0117 11.4828 21.9664C10.8636 21.8767 10.2723 21.628 9.76938 21.2342C9.37978 20.9653 9.09852 20.5402 9.07367 20.0608Z"/>)}
    </IconWithGradient>);
};
