import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from './Icon';
const IconGift = ({ ...props }) => {
    const id = useId();
    return (<IconWithGradient id={id} defaultGradientKey="orange" {...props}>
      <Path d="M8.34375 3H8.42109C9.54258 3 10.5832 3.59449 11.1527 4.5627L12 6.00445L12.8473 4.5627C13.4168 3.59449 14.4574 3 15.5789 3H15.6562C17.3648 3 18.75 4.38516 18.75 6.09375C18.75 6.6 18.627 7.04648 18.4125 7.5H19.875C20.4973 7.5 21 8.00273 21 8.625V10.875C21 11.4973 20.4973 12 19.875 12H4.125C3.50379 12 3 11.4973 3 10.875V8.625C3 8.00273 3.50379 7.5 4.125 7.5H5.5875C5.37164 7.04648 5.25 6.6 5.25 6.09375C5.25 4.38516 6.63516 3 8.34375 3ZM9.69727 5.41805C9.43008 4.96559 8.94492 4.6875 8.42109 4.6875H8.34375C7.5668 4.6875 6.9375 5.31715 6.9375 6.09375C6.9375 6.8707 7.5668 7.5 8.34375 7.5H10.9207L9.69727 5.41805ZM15.6562 4.6875H15.5789C15.0551 4.6875 14.5699 4.96559 14.3027 5.41805L13.0793 7.5H15.6562C16.4332 7.5 17.0625 6.8707 17.0625 6.09375C17.0625 5.31715 16.4332 4.6875 15.6562 4.6875ZM4.125 13.125H10.875V21H5.8125C4.88051 21 4.125 20.2441 4.125 19.3125V13.125ZM13.125 21V13.125H19.875V19.3125C19.875 20.2441 19.1191 21 18.1875 21H13.125Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default IconGift;
