import React, { useCallback, useMemo } from 'react';
import { useSharedValue } from 'react-native-reanimated';
const SelectedCategoryContext = React.createContext({});
export const SelectedCategoryContextProvider = ({ ...props }) => {
    const selectedCategoryId = useSharedValue(undefined);
    const selectCategoryId = useCallback((categoryId) => {
        selectedCategoryId.value = categoryId;
    }, [selectedCategoryId]);
    const providerValue = useMemo(() => ({ selectedCategoryId, selectCategoryId }), [selectCategoryId, selectedCategoryId]);
    return <SelectedCategoryContext.Provider value={providerValue} {...props}/>;
};
const useSelectedCategory = () => React.useContext(SelectedCategoryContext);
export default useSelectedCategory;
