import { useNavigationState } from '@react-navigation/native';
export const getCurrentRoute = (state) => {
    let node = state.routes[state.index || 0];
    while (node.state?.routes && typeof node.state?.index === 'number') {
        node = node.state?.routes[node.state?.index];
    }
    return node;
};
export function useNavigationWebState() {
    return useNavigationState((state) => {
        if (state) {
            const currentRoute = getCurrentRoute(state);
            if (currentRoute) {
                return {
                    currentScreen: currentRoute.name,
                };
            }
        }
        return {
            currentScreen: '',
        };
    });
}
