import { useNavigation, useRoute, useLinkTo as useLinkToNative, } from '@react-navigation/native';
export const useWaitingNavigation = () => {
    const navigation = useNavigation();
    return navigation;
};
export const useLoginStackNavigation = () => {
    const navigation = useNavigation();
    return navigation;
};
export const useMainStackRoute = () => {
    const navigation = useRoute();
    return navigation;
};
export const useMainStackNavigation = () => {
    const navigation = useNavigation();
    return navigation;
};
export const useLinkTo = () => {
    const linkTo = useLinkToNative();
    return linkTo;
};
