import React from 'react';
import { View, StyleSheet, SectionList, } from 'react-native';
import { rem } from 'design-system/values';
import { useKeyboard } from 'hooks/useKeyBoard';
import SectionHeader from './SectionHeader';
export const SectionItemsList = ({ style, contentContainerStyle, sections, renderItem, keyExtractor, onEndReached, ...sectionListProps }) => {
    const keyboardHeight = useKeyboard();
    return (<SectionList style={style} initialNumToRender={20} windowSize={10} renderItem={renderItem} sections={sections} stickySectionHeadersEnabled={false} keyboardShouldPersistTaps="always" contentContainerStyle={[
            { paddingBottom: keyboardHeight + rem(20) },
            contentContainerStyle,
        ]} keyExtractor={keyExtractor} ListFooterComponent={() => <View style={styles.spacer}/>} onEndReached={onEndReached} onEndReachedThreshold={0.8} renderSectionHeader={({ section: { title } }) => (<SectionHeader verticalMargins horizontalMargins onLight>
          {title}
        </SectionHeader>)} showsVerticalScrollIndicator={false} {...sectionListProps}/>);
};
const styles = StyleSheet.create({
    spacer: {
        height: rem(24),
    },
});
