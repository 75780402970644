import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from './Icon';
export const IconBoldTrash = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="apricot">
      <Path fillRule="evenodd" clipRule="evenodd" d="M12 3C11.3742 3 10.753 3.11031 10.1712 3.32613C9.58938 3.54196 9.05585 3.86006 8.60284 4.26577C8.14968 4.67161 7.78526 5.1579 7.53483 5.69935C7.38345 6.02664 7.27593 6.36939 7.21453 6.72006H3.69231C3.30996 6.72006 3 7.03235 3 7.41757C3 7.8028 3.30996 8.11509 3.69231 8.11509H4.84615V12.1622C4.84615 13.6812 5.05998 15.1924 5.48125 16.6508C6.11125 18.8318 7.92504 20.4569 10.1486 20.8324L10.2942 20.857C11.4235 21.0477 12.5765 21.0477 13.7058 20.857L13.8514 20.8324C16.0749 20.4569 17.8887 18.8319 18.5187 16.6509C18.94 15.1924 19.1538 13.6811 19.1538 12.1622V8.11509H20.3077C20.69 8.11509 21 7.8028 21 7.41757C21 7.03235 20.69 6.72006 20.3077 6.72006H16.7855C16.7241 6.36939 16.6165 6.02664 16.4652 5.69935C16.2147 5.1579 15.8503 4.67161 15.3972 4.26577C14.9441 3.86007 14.4106 3.54196 13.8288 3.32613C13.247 3.11031 12.6258 3 12 3ZM10.6496 4.63524C11.0757 4.47716 11.5348 4.39502 12 4.39502C12.4652 4.39502 12.9243 4.47716 13.3504 4.63524C13.7765 4.79331 14.1588 5.02324 14.4773 5.30842C14.7955 5.59346 15.0431 5.92736 15.2101 6.28858C15.2753 6.42941 15.3278 6.57365 15.3678 6.72006L8.63224 6.72006C8.67215 6.57365 8.72473 6.42941 8.78987 6.28858C8.95694 5.92736 9.20445 5.59346 9.52273 5.30842C9.84116 5.02324 10.2235 4.79331 10.6496 4.63524ZM10.1538 11.3701C10.5362 11.3701 10.8462 11.6824 10.8462 12.0677V15.7877C10.8462 16.1729 10.5362 16.4852 10.1538 16.4852C9.7715 16.4852 9.46154 16.1729 9.46154 15.7877V12.0677C9.46154 11.6824 9.7715 11.3701 10.1538 11.3701ZM13.8462 11.3701C14.2285 11.3701 14.5385 11.6824 14.5385 12.0677V15.7877C14.5385 16.1729 14.2285 16.4852 13.8462 16.4852C13.4638 16.4852 13.1538 16.1729 13.1538 15.7877V12.0677C13.1538 11.6824 13.4638 11.3701 13.8462 11.3701Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default IconBoldTrash;
