import { putResolve, select } from 'typed-redux-saga/macro';
import { reverseTrialActivate, } from 'features/premium/actions/reverseTrial';
import { Tier } from 'features/premium/entities';
import { Platform } from 'react-native';
import { selectFeatureFlags, selectUser } from 'reducers/selectors';
import { isUnlocked } from 'utils';
import checkFeatureFlag from 'utils/featureFlags';
import { GET_PROMOTIONS_SUCCESS, getPromotions, } from '../actions';
import { getOfferingForPlatform, shouldOpenPromotionOnStartup, } from '../entities';
import { loadPromoIap } from './loadPromoIap';
export function* checkReverseTrial() {
    const user = yield* select(selectUser);
    if (!user.emmaProStatus.reverseTrial?.active &&
        user.emmaProStatus.reverseTrial?.startAcknowledgedAt &&
        !user.emmaProStatus.reverseTrial.endAcknowledgedAt) {
        // If the user is on pro and is paying then they have already upgraded to pro while on the reverse trial
        // we dont need to acknowledge the end
        if (isUnlocked(user, Tier.pro) && user.emmaProStatus.paying) {
            return null;
        }
        return 'ended';
    }
    const featureFlags = yield* select(selectFeatureFlags);
    if (!checkFeatureFlag(featureFlags, 'reverse_trial')) {
        return null;
    }
    if ((!isUnlocked(user, Tier.pro) && !user.emmaProStatus.reverseTrial?.active) ||
        (user.emmaProStatus.reverseTrial?.active &&
            !user.emmaProStatus.reverseTrial?.startAcknowledgedAt)) {
        const reverseTrial = yield* putResolve(reverseTrialActivate());
        if (reverseTrial?.payload) {
            const { status } = reverseTrial.payload;
            if (status === 'activated' || status === 'already_active') {
                return 'start';
            }
        }
    }
    return null;
}
export function* checkPromotions() {
    try {
        const promotionsResult = yield* putResolve(getPromotions());
        if (promotionsResult?.type === GET_PROMOTIONS_SUCCESS) {
            const promotions = yield* select((state) => state.promotionsState.promotions);
            yield* loadPromoIap(promotions);
            const promotion = promotions.find(shouldOpenPromotionOnStartup);
            const offering = promotion
                ? getOfferingForPlatform(promotion, Platform.OS)
                : null;
            if (promotion && offering) {
                return promotion.id;
            }
            return null;
        }
        return null;
    }
    catch (e) {
        //
    }
    return null;
}
// todo we need to deal with coming from a notif or on 10 min refresh
// export function* promotionsSaga() {
//   yield* takeEvery(
//     (action: AnyAction) => [GET_PROMOTIONS_SUCCESS].includes(action.type),
//     handlePromotionsLoaded
//   );
// }
