import React, { memo } from 'react';
import Image from 'utils/packages/rnFastImage';
import { rem } from 'design-system/values';
import { AvatarWrapper } from './AvatarWrapper';
import { AvatarText } from './AvatarText';
export const Avatar = memo(({ imageUrl, name, lastName, color, fontColor, size, style, primary, borderWidth = 0, resizeMode, }) => {
    const imageStyle = {
        width: rem(size),
        height: rem(size),
        borderRadius: size / 2,
    };
    return (<AvatarWrapper color={color} size={size} style={style} borderWidth={borderWidth}>
        {imageUrl ? (<Image style={imageStyle} source={{ uri: imageUrl }} resizeMode={resizeMode}/>) : (<AvatarText primary={primary} name={name} lastName={lastName} fontColor={fontColor}/>)}
      </AvatarWrapper>);
});
