import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from './Icon';
export const IconProfile = ({ ...props }) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="turquoise">
      <Path d="M12 3.5c-2.434 0-4.407 1.957-4.407 4.371 0 2.415 1.973 4.372 4.407 4.372s4.407-1.957 4.407-4.372C16.407 5.457 14.434 3.5 12 3.5Z" fill={`url(#gradient_${id})`}/>
      <Path d="M14.601 14.188a16.635 16.635 0 0 0-5.202 0l-.185.029C6.786 14.6 5 16.678 5 19.117A2.393 2.393 0 0 0 7.403 21.5h9.194A2.393 2.393 0 0 0 19 19.117c0-2.439-1.787-4.516-4.214-4.9l-.185-.03Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
