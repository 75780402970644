export const splitArrayToNParts = (array, parts) => {
    const result = [];
    for (let i = parts; i > 0; i--) {
        result.push(array.splice(0, Math.ceil(array.length / i)));
    }
    return result;
};
/**
 *  This function sorts the given array with key which as type string
 */
export const sortAscByStringWithKey = (array, key) => array.sort((a, b) => a[key].localeCompare(b[key]));
export function splitIntoRows(array, numberPerRow) {
    return array.reduce((curr, item, index) => {
        const chunkIndex = Math.floor(index / numberPerRow);
        const newArray = curr;
        if (!newArray[chunkIndex]) {
            newArray[chunkIndex] = []; // new row
        }
        newArray[chunkIndex].push(item);
        return newArray;
    }, []);
}
