import { useCallback, useRef } from 'react';
/**
 * Always returns the same callback reference
 * @param callback
 */
export const useRefCallback = (callback) => {
    const callbackRef = useRef(callback);
    callbackRef.current = callback;
    return useCallback((...args) => callbackRef.current(...args), []);
};
