import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Avatar } from 'design-system/avatar';
import Text from 'design-system/Text';
import useColors from 'hooks/useColors';
import { useAppSelector } from 'store/hooks';
import { selectUser } from 'reducers/selectors';
export const AccountSettingsHeader = React.memo(() => {
    const user = useAppSelector(selectUser);
    const colors = useColors();
    return (<View style={styles.profileInfoContainer}>
      <View style={styles.textContainer}>
        <Text Text-32 adjustsFontSizeToFit numberOfLines={1}>
          {`${user.firstName} ${user.lastName}`}
        </Text>
        <Text Text-14 Secondary>
          @{user.urlHandle}
        </Text>
      </View>
      <Avatar primary name={user.firstName} size={56} lastName={user.lastName} imageUrl={user.userImage?.url} color={colors.background.transparent} fontColor={colors.text.secondary} borderWidth={2} style={{
            borderColor: user.userImage
                ? colors.borders.iconStroke
                : colors.text.secondary,
        }}/>
    </View>);
});
const styles = StyleSheet.create({
    textContainer: {
        flex: 1,
        paddingRight: 16,
    },
    profileInfoContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 16,
    },
});
