import React, { useCallback, useEffect, useMemo } from 'react';
import Spacer from 'design-system/Spacer';
import Section from 'design-system/Section';
import FlatListItemCard from 'design-system/FlatListItemCard';
import { TextButtonLinkSeeAll } from 'design-system/TextButton';
import useStyles from 'hooks/useStyles';
import { addTransactionToCache } from 'actions';
import createStyleSheets from 'utils/createStyleSheets';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { useOpenSearch } from 'features/transactions/hooks/useOpenSearch';
import { selectSearchTransactionsFormatted } from 'features/search/selectors';
import { useFetchSearchTransactionData } from 'hooks/useFetchSearchTransactionData';
import RowTransaction from '../RowTransaction';
const NUMBER_TO_SHOW = 3;
const CustomCategoryTransactionsPreview = ({ category }) => {
    const fetchSearchData = useFetchSearchTransactionData();
    const openSearch = useOpenSearch();
    useEffect(() => {
        fetchSearchData();
    }, [fetchSearchData]);
    const search = useAppSelector(selectSearchTransactionsFormatted);
    const categoryTransactions = useMemo(() => search.filter((t) => t.categoryId === category.id), [category.id, search]);
    const transactions = useMemo(() => categoryTransactions.slice(0, NUMBER_TO_SHOW).map((t) => ({
        ...t,
        category: t.category ? t.category : category,
    })), [category, categoryTransactions]);
    const styles = useStyles(styleSet);
    const seeAll = useMemo(() => categoryTransactions.length > NUMBER_TO_SHOW ? (<TextButtonLinkSeeAll onPress={() => {
            openSearch({
                filterType: 'CATEGORY',
                category,
            });
        }}/>) : null, [category, categoryTransactions.length, openSearch]);
    const navigation = useMainStackNavigation();
    const dispatch = useAppDispatch();
    const onPressTransaction = useCallback((item) => {
        dispatch(addTransactionToCache({ ...item, isCompact: true }));
        navigation.push('Item', {
            id: item.id,
        });
    }, []);
    if (!transactions.length) {
        return null;
    }
    return (<>
      <Spacer h={8}/>
      <Section title="Transactions" button={seeAll}>
        {transactions.map((t, index, { length }) => (<FlatListItemCard index={index} itemsLength={length} style={styles.card} key={t.id}>
            <RowTransaction item={t} key={t.id} backgroundDark onPress={onPressTransaction} isTransactionFeed/>
          </FlatListItemCard>))}
      </Section>
    </>);
};
const styleSet = createStyleSheets((colors) => ({
    card: {
        backgroundColor: colors.cards.onDark,
    },
}));
export default CustomCategoryTransactionsPreview;
