import React from 'react';
import { View } from 'react-native';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import NotFoundImage from 'features/web/NotFound/NotFoundImage';
import Text from './Text';
import { rem } from './values';
import { CardView } from './CardView';
const Code404Card = () => {
    const styles = useStyles(styleSet);
    return (<CardView style={styles.outerCard} noMargin>
      <CardView style={styles.imageCard} noMargin>
        <NotFoundImage />
      </CardView>
      <View>
        <Text Text-24 style={styles.itemTitle}>
          Oops, wrong place
        </Text>
        <Text TextThin-14 Secondary>
          Sorry, but it looks like we couldn’t find the page you’re looking for.
        </Text>
      </View>
    </CardView>);
};
export default Code404Card;
const styleSet = createStyleSheets((colors) => ({
    outerCard: {
        marginTop: rem(16),
        borderRadius: rem(24),
        paddingHorizontal: rem(24),
        paddingVertical: rem(32),
        marginHorizontal: rem(16),
    },
    imageCard: {
        backgroundColor: colors.background.dark,
        padding: rem(28),
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: rem(40),
        borderWidth: rem(1),
        borderColor: colors.borders.divider,
    },
    itemTitle: {
        marginBottom: rem(4),
    },
}));
