import React from 'react';
import { View } from 'react-native';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import IconTransactionCategory from 'features/transactions/icons/IconTransactionCategory';
import useColors from 'hooks/useColors';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import { isWeb } from '../../../../constants';
const KeyboardCategoriesRow = ({ item, onPress }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    return (<TouchableHighlight hoverOnLight onCard onPress={onPress} underlayColor={colors.background.underlay}>
      <View style={styles.container}>
        <View style={styles.icon}>
          <IconTransactionCategory category={item}/>
        </View>
        <Spacer w={16}/>
        <Text Text-14>{item.displayName}</Text>
      </View>
    </TouchableHighlight>);
};
const ICON_SIZE = 44;
const styleSet = createStyleSheets((colors) => ({
    container: {
        paddingVertical: rem(16),
        paddingHorizontal: rem(16),
        flexDirection: 'row',
        alignItems: 'center',
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
    icon: {
        width: rem(ICON_SIZE),
        height: rem(ICON_SIZE),
        borderRadius: rem(ICON_SIZE),
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: isWeb ? colors.background.dark : colors.cards.onLight,
    },
}));
export default KeyboardCategoriesRow;
