import { createSelector } from 'reselect';
import checkFeatureFlag from 'utils/featureFlags';
export const selectHasRentReporting = createSelector([
    (store) => store.utils.featureFlags,
    (store) => store.user.user.guessedHomeCountry,
], (featureFlags, homeCountry) => homeCountry === 'GB' && checkFeatureFlag(featureFlags, 'rent_reporting_two'));
export const selectCurrentRentReportingState = (store) => store.rentReporting.data?.current || undefined;
export const selectHasCurrentRentReporting = createSelector([selectHasRentReporting, selectCurrentRentReportingState], (hasRentReporting, currentState) => Boolean(hasRentReporting && currentState));
const EMPTY = [];
export const selectPastRentReportingState = (store) => store.rentReporting.data?.past || EMPTY;
export const selectHasRentReportingData = (store) => Boolean(store.rentReporting.data?.current) ||
    (store.rentReporting.data?.past != null &&
        store.rentReporting.data?.past.length > 0);
export const selectRentReportingStateById = createSelector([
    selectCurrentRentReportingState,
    selectPastRentReportingState,
    (_, id) => id,
], (currentRentReportingData, pastRentReportingData, id) => {
    let rentReportingData;
    if (id === currentRentReportingData?.id) {
        rentReportingData = currentRentReportingData;
    }
    else {
        rentReportingData = pastRentReportingData.find((data) => data.id === id);
    }
    if (!rentReportingData)
        return undefined;
    return rentReportingData;
}, { devModeChecks: { identityFunctionCheck: 'never' } });
export const selectIsLoadingRentReportingData = (store) => store.rentReporting.isLoadingRentReportingData === true;
export const selectIsLoadingSuggestedPayments = (store) => store.rentReporting.suggestedPayments?.isLoading === true;
export const selectSuggestedPayments = (store) => store.rentReporting.suggestedPayments?.transactions;
export const selectSuggestedPaymentsPaging = (store) => store.rentReporting.suggestedPayments?.paging;
