import React, { memo, useCallback, useContext, useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import Spacer from 'design-system/Spacer';
import { CalendarRange } from 'design-system/calendar/CalendarRange';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import { formatPeriodDate } from 'utils/formatting';
import { minDate } from './constants';
import { DateFilterContext } from './context';
import { isWeb } from '../../constants';
const WeeklyContent = () => {
    const styles = useStyles(styleSet);
    const { todayDateStr, weeklyRange, setWeeklyRange } = useContext(DateFilterContext);
    const onChangeRange = useCallback((range) => {
        const fromDate = range[0] ? parseISO(range[0]) : undefined;
        const toDate = range[1] ? parseISO(range[1]) : undefined;
        if (fromDate && toDate) {
            setWeeklyRange([fromDate, toDate]);
        }
        else {
            setWeeklyRange(undefined);
        }
    }, [setWeeklyRange]);
    const title = useMemo(() => {
        if (weeklyRange) {
            return formatPeriodDate({ from: weeklyRange[0], to: weeklyRange[1] });
        }
        return '';
    }, [weeklyRange]);
    const initialDates = useMemo(() => weeklyRange
        ? [
            format(weeklyRange[0], 'yyyy-MM-dd'),
            format(weeklyRange[1], 'yyyy-MM-dd'),
        ]
        : undefined, [weeklyRange]);
    return (<>
      <Spacer h={16}/>
      <CalendarRange hideDays firstDay={1} showReset selectWeek showDisabled title={title} blackMonthText minDate={minDate} maxDate={todayDateStr} hideExtraDays={false} initialDates={initialDates} onChangeRange={onChangeRange} style={styles.calendarContainer} headerStyle={styles.headerStyle} initialDate={initialDates ? initialDates[1] : todayDateStr}/>
      <Spacer h={16}/>
    </>);
};
export default memo(WeeklyContent);
const styleSet = createStyleSheets((colors) => ({
    calendarContainer: {
        maxWidth: '100%',
        backgroundColor: isWeb ? colors.background.light : colors.cards.onDark,
    },
    headerStyle: {
        paddingHorizontal: 0,
    },
}));
