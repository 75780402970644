import { EDIT_CATEGORY_SUCCESS } from 'actions/types';
const REFRESH_GRAPH_CATEGORIES = ['income', 'internal'];
export const checkIfNeedToRefreshMonthlyTotals = (action) => {
    if (action.type === EDIT_CATEGORY_SUCCESS) {
        const editCategoryAction = action;
        return (REFRESH_GRAPH_CATEGORIES.includes(editCategoryAction.extra.category.id) ||
            Object.values(editCategoryAction.extra.data).find((categoryId) => REFRESH_GRAPH_CATEGORIES.includes(categoryId)) !== undefined);
    }
    return true;
};
