// Converts a #ffffff hex string into an [r,g,b] array
function h2r(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.toLowerCase());
    return result
        ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16),
        ]
        : null;
}
// Inverse of the above
function r2h(rgb) {
    // eslint-disable-next-line no-bitwise
    return `#${((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2])
        .toString(16)
        .slice(1)}`;
}
// Interpolates two [r,g,b] colors and returns an [r,g,b] of the result
// Taken from the awesome ROT.js roguelike dev library at
// https://github.com/ondras/rot.js
function interpolateColor(color1, color2, factor) {
    const result = color1.slice();
    for (let i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return result;
}
/**
 * Works just like AnimatedValue.interpolate, but for regular numbers and colors.
 * Will clamp on edges.
 *
 * The colors should be in hex format only, without alpha-channel, having length 7.
 * If colors are of wrong format then outputRange[0] will be returned.
 *
 * @param value - A numeric value to interpolate that will be compared against inputRange
 * @param inputRange - The range for input value, 2 numbers.
 * @param outputRange - The range for the result, 2 colors.
 * @returns A color like #abcdef that lies within outputRange.
 */
export function interpolateColors(value, { inputRange, outputRange }) {
    if (!value || value < inputRange[0]) {
        return outputRange[0];
    }
    if (value > inputRange[1]) {
        return outputRange[1];
    }
    const factor = (value - inputRange[0]) / (inputRange[1] - inputRange[0]);
    const [outA, outB] = outputRange;
    const outAColor = h2r(outA);
    const outBColor = h2r(outB);
    if (outAColor && outBColor) {
        return r2h(interpolateColor(outAColor, outBColor, factor));
    }
    return outputRange[0];
}
export function interpolate(value, { inputRange, outputRange }) {
    if (!value || value < inputRange[0]) {
        return outputRange[0];
    }
    if (value > inputRange[1]) {
        return outputRange[1];
    }
    const factor = (value - inputRange[0]) / (inputRange[1] - inputRange[0]);
    return outputRange[0] + (outputRange[1] - outputRange[0]) * factor;
}
