import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import Animated, { withTiming, useSharedValue, useAnimatedStyle, cancelAnimation, } from 'react-native-reanimated';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { rem } from './values';
import Text from './Text';
import { Spacer } from './Spacer';
import Button from './Button';
const defaultButtons = [
    {
        title: 'OK',
        type: 'Normal',
        onPress: () => Modal.hide(),
    },
];
const Modal = () => {
    const opacitySharedValue = useSharedValue(0);
    const [state, setState] = useState({
        title: '',
        message: '',
        visible: false,
        startHiding: false,
        buttons: defaultButtons,
    });
    const styles = useStyles(styleSet);
    useEffect(() => {
        if (state.visible) {
            cancelAnimation(opacitySharedValue);
            opacitySharedValue.value = withTiming(1, {
                duration: 100,
            });
        }
    }, [opacitySharedValue, state.visible]);
    useEffect(() => {
        if (state.startHiding) {
            opacitySharedValue.value = withTiming(0, {
                duration: 100,
            }, () => {
                setState((oldState) => ({
                    ...oldState,
                    title: '',
                    content: null,
                    visible: false,
                    startHiding: false,
                }));
            });
        }
    }, [opacitySharedValue, state.startHiding]);
    useEffect(() => {
        if (Modal.setStateRef === undefined) {
            Modal.setStateRef = setState;
        }
    }, []);
    const containerAnimatedStyles = useAnimatedStyle(() => ({
        opacity: opacitySharedValue.value,
    }), [opacitySharedValue.value]);
    if (!state.visible || !state.title || !state.buttons.length) {
        return null;
    }
    return (<Animated.View style={[styles.container, containerAnimatedStyles]}>
      <View style={styles.contentContainer}>
        <View style={styles.infoContainer}>
          <Text Text-20 centered>
            {state.title}
          </Text>
          <Spacer h={8}/>
          <Text TextThin-16 centered Secondary>
            {state.message}
          </Text>
        </View>
        <Spacer h={4}/>
        <View style={[
            styles.btnContainer,
            state.buttons.length === 2 && styles.btnRowContainer,
        ]}>
          {state.buttons.map((btn) => (<View style={styles.btn}>
              <Button title={btn.title} onPress={btn.onPress} {...(btn.type === 'Cancel'
            ? { brandReversed: true }
            : { brand: true })}/>
            </View>))}
        </View>
      </View>
    </Animated.View>);
};
Modal.show = (title, message, buttons) => {
    if (Modal.setStateRef) {
        const setState = Modal.setStateRef;
        if (setState) {
            const buttonsArrPositive = [];
            const buttonsArrNegative = [];
            buttons?.forEach((btnObj) => {
                if (btnObj.style === 'cancel' || btnObj.style === 'destructive') {
                    buttonsArrNegative.push({
                        title: btnObj.text || 'Cancel',
                        type: 'Cancel',
                        onPress: () => {
                            btnObj.onPress?.();
                            Modal.hide();
                        },
                    });
                }
                else {
                    buttonsArrPositive.push({
                        title: btnObj.text || 'OK',
                        type: 'Normal',
                        onPress: () => {
                            btnObj.onPress?.();
                            Modal.hide();
                        },
                    });
                }
            });
            const finalButtonsArr = [...buttonsArrPositive, ...buttonsArrNegative];
            setState({
                title,
                message,
                visible: true,
                startHiding: false,
                buttons: finalButtonsArr?.length < 1 ? defaultButtons : finalButtonsArr,
            });
        }
    }
};
Modal.hide = () => {
    if (Modal.setStateRef) {
        const setState = Modal.setStateRef;
        if (setState) {
            setState((state) => ({ ...state, startHiding: true }));
        }
    }
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        ...StyleSheet.absoluteFillObject,
        zIndex: 100,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.6)',
    },
    contentContainer: {
        width: rem(500),
        borderRadius: rem(24),
        backgroundColor: colors.cards.onDark,
    },
    infoContainer: {
        padding: rem(16),
        marginTop: rem(16),
        alignItems: 'center',
        marginHorizontal: rem(16),
    },
    btnContainer: {
        flex: 1,
        padding: rem(12),
    },
    btnRowContainer: {
        flexDirection: 'row',
    },
    btn: {
        flex: 1,
        padding: rem(4),
    },
}));
export default Modal;
