export const UNDER_REVIEW_ACCOUNT_STATUS = [
    'KYC_PENDING',
    'NOT_ELIGIBLE',
    'OPENING_IN_PROGRESS',
    'CLOSING_IN_PROGRESS',
    'CLOSED',
    'FROZEN', // your account is temporary frozen
];
export var VerificationType;
(function (VerificationType) {
    VerificationType["Identity"] = "IDENTITY";
    VerificationType["Trading"] = "TRADING";
})(VerificationType || (VerificationType = {}));
export var KYCStatus;
(function (KYCStatus) {
    KYCStatus["Success"] = "SUCCESS";
    KYCStatus["Fail"] = "FAIL";
    KYCStatus["Pending"] = "PENDING";
})(KYCStatus || (KYCStatus = {}));
export var SingleInputScreenFlows;
(function (SingleInputScreenFlows) {
    SingleInputScreenFlows["editPotName"] = "EditPotName";
})(SingleInputScreenFlows || (SingleInputScreenFlows = {}));
// Create Pot Screen is used to create pot, create goal and edit goal
export var CreatePotScreenFlows;
(function (CreatePotScreenFlows) {
    CreatePotScreenFlows["editGoalFlow"] = "EditGoalFlow";
    CreatePotScreenFlows["createGoalFlow"] = "CreateGoalFlow";
})(CreatePotScreenFlows || (CreatePotScreenFlows = {}));
export const roundUpOptions = [1, 2, 3, 4, 5];
export const amountOptions = [10, 25, 50];
export var SavingsPlanRuleType;
(function (SavingsPlanRuleType) {
    SavingsPlanRuleType["AUTOMATED"] = "AUTOMATED";
    SavingsPlanRuleType["ROUND_UP"] = "ROUND_UP";
    SavingsPlanRuleType["CUSTOM"] = "CUSTOM";
})(SavingsPlanRuleType || (SavingsPlanRuleType = {}));
