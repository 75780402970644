import React, { useEffect } from 'react';
import { CodeField, useBlurOnFulfill, useClearByFocusCell, } from 'react-native-confirmation-code-field';
import Text from 'design-system/Text';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
const CELL_COUNT = 4;
const WebPasscode = ({ value, setValue, failed = false, errorMessage = '', title, subTitle = '', }) => {
    const styles = useStyles(styleSet);
    const colors = useColors();
    const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT });
    const [props, getCellOnLayoutHandler] = useClearByFocusCell({
        value,
        setValue,
    });
    const [clearFailed, setClearFailed] = React.useState(false);
    useEffect(() => {
        setClearFailed(false);
        if (ref.current) {
            ref.current.focus();
        }
        if (failed && value.length > 0) {
            setClearFailed(true);
        }
    }, [failed, ref, value]);
    const renderCell = ({ index, symbol, isFocused, }) => {
        let textChild = null;
        if (symbol) {
            textChild = '•';
        }
        else if (isFocused) {
            textChild = null;
        }
        return (<Text Text-32 Secondary key={index} style={[
                styles.cell,
                isFocused && {
                    borderColor: failed && !clearFailed ? colors.text.negative : colors.text.brand,
                },
            ]} onLayout={getCellOnLayoutHandler(index)}>
        {textChild}
      </Text>);
    };
    return (<>
      <Text Text-24 style={styles.title}>
        {title}
      </Text>
      <Text Secondary TextThin-14 style={styles.title}>
        {subTitle}
      </Text>

      <CodeField ref={ref} {...props} value={value} onChangeText={setValue} cellCount={CELL_COUNT} keyboardType="number-pad" textContentType="oneTimeCode" renderCell={renderCell} rootStyle={styles.codeRoot}/>
      {failed && !clearFailed && (<Text TextThin-14 Red style={styles.errormsg}>
          {errorMessage}
        </Text>)}
    </>);
};
export default WebPasscode;
const styleSet = createStyleSheets((colors) => ({
    codeRoot: {
        marginVertical: 4,
        justifyContent: 'flex-start',
        gap: 8,
        width: 248,
    },
    cell: {
        width: 56,
        height: 56,
        borderRadius: 12,
        padding: 8,
        borderWidth: 2,
        borderColor: 'transparent',
        backgroundColor: colors.cards.onDark,
        textAlign: 'center',
    },
    errormsg: {
        marginVertical: 8,
    },
    title: {
        paddingBottom: 16,
    },
}));
