import React from 'react';
import { Path, Svg } from 'react-native-svg';
import useColors from 'hooks/useColors';
const SaveIcon = ({ isActive }) => {
    const colors = useColors();
    return (<Svg width={32} height={32} viewBox="0 0 32 32">
      <Path fillRule="evenodd" clipRule="evenodd" fill={isActive ? colors.elements.brand : colors.elements.secondary} d="M21.611 7.97749C21.6719 7.73067 21.7042 7.47261 21.7042 7.207C21.7042 5.43582 20.2684 4 18.4972 4C16.726 4 15.2902 5.43582 15.2902 7.207C15.2902 7.47261 15.3225 7.73067 15.3834 7.97749H21.611ZM9.56354 7.97749C10.0432 6.81051 11.1911 5.98881 12.531 5.98881C13.3029 5.98881 14.011 6.26148 14.5644 6.71574C14.5574 6.81534 14.5538 6.9159 14.5538 7.0173C14.5538 7.34765 14.5919 7.6691 14.664 7.97749H9.56354ZM15.9021 4.12273C15.622 4.0428 15.3262 4 15.0204 4C14.0162 4 13.1197 4.46157 12.5317 5.18414C13.3596 5.1843 14.1314 5.42389 14.7819 5.83743C15.0101 5.17542 15.399 4.58829 15.9021 4.12273ZM9.51127 8.97184H22.1915C23.3931 8.97184 24.3672 9.94593 24.3672 11.1475C24.3672 12.3491 23.3931 13.3232 22.1915 13.3232H9.51128C8.30967 13.3232 7.33557 12.3491 7.33557 11.1475C7.33557 9.94593 8.30967 8.97184 9.51127 8.97184ZM6.34082 18.3112C6.34082 16.7061 6.87084 15.2086 7.78706 13.9436C8.28326 14.2657 8.87523 14.4528 9.51089 14.4528H22.1911C22.7897 14.4528 23.3495 14.2869 23.827 13.9986C24.719 15.2519 25.2337 16.7293 25.2337 18.3112C25.2337 20.635 24.1228 22.7334 22.3374 24.227L22.7008 26.55C22.8 27.1839 22.4063 27.7908 21.7882 27.9569C21.1893 28.1178 20.5633 27.8149 20.3162 27.2447L19.6864 25.7913C18.4978 26.2601 17.1776 26.5211 15.7873 26.5211C14.4 26.5211 13.0826 26.2612 11.896 25.7944L10.8609 27.2591C10.4909 27.7827 9.78666 27.9421 9.22825 27.6287C8.68729 27.325 8.4495 26.67 8.66918 26.0886L9.34049 24.3121C7.49468 22.8136 6.34082 20.6795 6.34082 18.3112Z"/>
    </Svg>);
};
export default SaveIcon;
