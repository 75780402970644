export const GET_BENEFICIARIES_REQUEST = 'GET_BENEFICIARIES_REQUEST';
export const GET_BENEFICIARIES_SUCCESS = 'GET_BENEFICIARIES_SUCCESS';
export const GET_BENEFICIARIES_FAILURE = 'GET_BENEFICIARIES_FAILURE';
export const SAVE_BENEFICIARY_REQUEST = 'SAVE_BENEFICIARY_REQUEST';
export const SAVE_BENEFICIARY_SUCCESS = 'SAVE_BENEFICIARY_SUCCESS';
export const SAVE_BENEFICIARY_FAILURE = 'SAVE_BENEFICIARY_FAILURE';
export const UPDATE_BENEFICIARY_REQUEST = 'UPDATE_BENEFICIARY_REQUEST';
export const UPDATE_BENEFICIARY_SUCCESS = 'UPDATE_BENEFICIARY_SUCCESS';
export const UPDATE_BENEFICIARY_FAILURE = 'UPDATE_BENEFICIARY_FAILURE';
export const DELETE_BENEFICIARY_REQUEST = 'DELETE_BENEFICIARY_REQUEST';
export const DELETE_BENEFICIARY_SUCCESS = 'DELETE_BENEFICIARY_SUCCESS';
export const DELETE_BENEFICIARY_FAILURE = 'DELETE_BENEFICIARY_FAILURE';
