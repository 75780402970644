import React, { useCallback, useLayoutEffect, useMemo } from 'react';
import { View } from 'react-native';
import Choices from 'design-system/Choices';
import Spacer from 'design-system/Spacer';
import StatusBar from 'design-system/StatusBar';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { AppView } from 'design-system/AppView';
import { SettingsItemIcon } from 'design-system/SettingsItemIcon';
import createStyleSheets from 'utils/createStyleSheets';
import { isWeb } from '../../../constants';
import { DUMMY_ACCOUNT_TEXT_MAP } from '../constants';
import { DUMMY_ACCOUNT_ICON_MAP } from '../hooks/useDummyAccountLogo';
const strings = {
    screenTitle: 'Property type',
};
const PROPERTY = ['REAL_ESTATE', 'VEHICLE', 'OTHER'];
const PickPropertyTypeScreen = ({ navigation, route, }) => {
    const colors = useColors();
    useLayoutEffect(() => {
        navigation.setOptions({
            title: strings.screenTitle,
            headerTintColor: colors.text.primary,
        });
    }, [colors.background.dark, colors.text.primary]);
    const onSelect = useCallback((item) => {
        let type;
        switch (item) {
            case DUMMY_ACCOUNT_TEXT_MAP['REAL_ESTATE']:
                type = 'REAL_ESTATE';
                break;
            case DUMMY_ACCOUNT_TEXT_MAP['VEHICLE']:
                type = 'VEHICLE';
                break;
            case DUMMY_ACCOUNT_TEXT_MAP['OTHER']:
                type = 'OTHER';
                break;
            default:
                break;
        }
        navigation.navigate('AddBalance', {
            type,
            keyBack: route.key,
        });
    }, [route.key]);
    const [data, icons] = useMemo(() => PROPERTY.reduce((prev, curr) => [
        [...prev[0], DUMMY_ACCOUNT_TEXT_MAP[curr]],
        [
            ...prev[1],
            <SettingsItemIcon iconSize={44}>
              {DUMMY_ACCOUNT_ICON_MAP[curr]}
            </SettingsItemIcon>,
        ],
    ], [[], []]), []);
    const styles = useStyles(styleSet);
    return (<AppView isInTab={isWeb} avoidHeader>
      <View style={styles.container}>
        <StatusBar barStyle="dark-content" animated/>
        <Spacer h={16}/>
        <Choices data={data} icons={icons} onSelect={onSelect}/>
      </View>
    </AppView>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
        paddingHorizontal: rem(16),
        backgroundColor: colors.background.dark,
    },
}));
export default PickPropertyTypeScreen;
