import { CALL_API } from '../middleware/api';
import * as types from './types';
export const dismissQuiz = (id) => ({
    [CALL_API]: {
        endpoint: `/quiz/${id}/dismiss`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.DISMISS_QUIZ_REQUEST,
            types.DISMISS_QUIZ_SUCCESS,
            types.DISMISS_QUIZ_FAILURE,
        ],
    },
});
export const getQuiz = (id) => ({
    [CALL_API]: {
        endpoint: `/quiz/${id}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_QUIZ_REQUEST,
            types.GET_QUIZ_SUCCESS,
            types.GET_QUIZ_FAILURE,
        ],
    },
});
export const startQuiz = (id) => ({
    [CALL_API]: {
        endpoint: `/quiz/${id}/played`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.START_QUIZ_REQUEST,
            types.START_QUIZ_SUCCESS,
            types.START_QUIZ_FAILURE,
        ],
    },
});
export const postAnswer = (quizId, id, body) => ({
    [CALL_API]: {
        endpoint: `/quiz/${quizId}/question/${id}/answer`,
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.POST_ANSWER_REQUEST,
            types.POST_ANSWER_SUCCESS,
            types.POST_ANSWER_FAILURE,
        ],
    },
});
