import React, { useCallback } from 'react';
import { View, StyleSheet } from 'react-native';
import Animated, { useAnimatedScrollHandler, useSharedValue } from 'react-native-reanimated';
import Modal from 'design-system/Modal';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import Text, { lineHeight } from 'design-system/Text';
import { SelectableAccountCard } from 'design-system/SelectableAccountCard';
import { useAppSelector } from 'store/hooks';
import { useMarginBottom } from 'hooks/useMarginBottom';
import { selectValidGBAccounts } from 'reducers/selectors';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import PopUpMenuModal, { defaultPopUpWidth } from 'design-system/PopUpMenuModal.web';
import ConnectNewAccountView from '../components/ConnectNewAccountView';
import { isWeb } from '../../../constants';
const strings = { modalTitle: 'Pick an account' };
const HEIGHT = rem(76 + 16);
const keyExtractor = (item) => item.id.toString();
const getItemLayout = (_, index) => ({
    length: rem(HEIGHT),
    offset: rem(HEIGHT) * index,
    index,
});
const useAccountPicker = () => {
    const allBankAccounts = useAppSelector(selectValidGBAccounts);
    const { navigate } = useMainStackNavigation();
    const paddingBottom = useMarginBottom('paddingBottom');
    const scrollY = useSharedValue(0);
    const onScroll = useAnimatedScrollHandler((e) => {
        scrollY.value = e.contentOffset.y;
    }, []);
    const openAccountPicker = useCallback((bankAccounts, params, touchViewRef) => {
        const accounts = bankAccounts || allBankAccounts;
        if (accounts.length || params?.emptyText) {
            let isResolved = false;
            return new Promise((resolve, reject) => {
                const shouldBeScrollable = accounts.length >= 7; // 6 rows fit on any screen
                const subTitle = params?.subTitle ? (<Text TextThin-16 Gray centered style={styles.subTitle}>
              {params.subTitle}
            </Text>) : null;
                const connectNewAccountView = (<View style={styles.standardHorizontalPadding}>
              <Spacer h={24}/>
              <ConnectNewAccountView onPress={() => {
                        reject(new Error());
                        Modal.hide(() => {
                            navigate('ChooseBank', { back: true, investments: false });
                        });
                    }}/>
            </View>);
                const DEFAULT_HEIGHT = rem(HEIGHT) * (isWeb ? 3.5 : 5.5) + rem(16 + 36 + (params?.subTitle ? lineHeight.TextThin_20 : 0));
                const maxHeight = Math.min(DEFAULT_HEIGHT, accounts.length * rem(HEIGHT));
                const content = !accounts.length ? (<View style={styles.emptyContainer}>
              <Text Text-16 centered>
                {params?.emptyText}
              </Text>
            </View>) : (<>
              <Animated.FlatList style={{ maxHeight }} data={accounts} initialNumToRender={7} windowSize={4} maxToRenderPerBatch={4} keyExtractor={keyExtractor} contentContainerStyle={isWeb ? undefined : paddingBottom} onScroll={onScroll} scrollEventThrottle={16} getItemLayout={getItemLayout} renderItem={({ item: account }) => (<>
                    <SelectableAccountCard alt data={account} white isMultipleSelect={false} onPress={() => {
                            isResolved = true;
                            resolve(account);
                            (isWeb ? PopUpMenuModal : Modal).hide();
                        }} type="single" isSelected={params?.selectedIds?.find((acc) => acc === account.id) !== undefined}/>
                    {!isWeb && <Spacer h={16}/>}
                  </>)}/>
              {!params?.hideConnectNewAccountView && !shouldBeScrollable ? connectNewAccountView : null}
            </>);
                const title = (<View style={styles.title}>
              <Text Text-20 centered>
                {params?.title || strings.modalTitle}
              </Text>
              {subTitle}
            </View>);
                if (isWeb) {
                    touchViewRef?.current?.measure((_, __, width, height, fx, fy) => {
                        PopUpMenuModal.show(content, {
                            onDidHideCallback: () => {
                                if (!isResolved) {
                                    reject(new Error());
                                }
                            },
                            containerStyle: styles.modalContainerStyle,
                            fx: fx + width - defaultPopUpWidth,
                            fy: fy + height - rem(8),
                        });
                    });
                }
                Modal.show(content, {
                    title,
                    onDidHideCallback: () => {
                        if (!isResolved) {
                            reject(new Error());
                        }
                    },
                    scrollable: shouldBeScrollable
                        ? {
                            maxHeight,
                            outsideContent: !params?.hideConnectNewAccountView ? (<View style={paddingBottom}>{connectNewAccountView}</View>) : undefined,
                            scrollValue: scrollY,
                        }
                        : undefined,
                    propagateSwipe: shouldBeScrollable,
                    containerStyle: styles.modalContainerStyle,
                });
            });
        }
        if (!params?.emptyText) {
            navigate('ChooseBank', { back: true });
        }
        throw new Error();
    }, [allBankAccounts, onScroll, paddingBottom, scrollY]);
    return openAccountPicker;
};
const styles = StyleSheet.create({
    emptyContainer: {
        marginTop: rem(36),
        paddingHorizontal: rem(32),
    },
    title: {
        marginTop: rem(36),
        marginBottom: rem(16),
    },
    subTitle: {
        marginTop: rem(4),
        marginBottom: rem(16),
    },
    modalContainerStyle: {
        ...(!isWeb && {
            paddingHorizontal: 0,
        }),
    },
    standardHorizontalPadding: {
        paddingHorizontal: rem(16),
    },
});
export default useAccountPicker;
