import React, { useCallback } from 'react';
import { useActionSheet } from 'hooks/useActionSheet';
import { useWaitingNavigation } from 'utils/types/navigationV6';
import { HelpNavigationButton } from 'design-system/TextButton';
const HelpButton = ({ black }) => {
    const nav = useWaitingNavigation();
    const actionSheet = useActionSheet();
    const onPress = useCallback(() => {
        actionSheet.showActionSheetWithOptions({
            options: ['Chat with a human', 'Cancel'],
            cancelButtonIndex: 1,
        }, (i) => {
            if (i === 0) {
                nav.navigate('Support', { isWaitingScreen: true });
            }
        });
    }, [actionSheet, nav]);
    return <HelpNavigationButton onPress={onPress} black={black}/>;
};
export default HelpButton;
