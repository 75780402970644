import { CALL_API } from 'middleware/api';
export const PATCH_PROMOTION_REQUEST = 'PATCH_PROMOTION_REQUEST';
export const PATCH_PROMOTION_SUCCESS = 'PATCH_PROMOTION_SUCCESS';
export const PATCH_PROMOTION_ERROR = 'PATCH_PROMOTION_ERROR';
export var PromotionPatchType;
(function (PromotionPatchType) {
    PromotionPatchType["FULL_SCREEN_SEEN"] = "FULL_SCREEN_SEEN";
    PromotionPatchType["FEED_CARD_DISMISSED"] = "FEED_CARD_DISMISSED";
    PromotionPatchType["ACCEPTED"] = "ACCEPTED";
})(PromotionPatchType || (PromotionPatchType = {}));
export const patchPromotions = ({ promotionId, patchType, }) => ({
    [CALL_API]: {
        endpoint: `/promotions/${String(promotionId)}`,
        method: 'PATCH',
        body: JSON.stringify((() => {
            const now = new Date().toISOString();
            switch (patchType) {
                case PromotionPatchType.FEED_CARD_DISMISSED:
                    return { feedCardDismissedAt: now };
                case PromotionPatchType.FULL_SCREEN_SEEN:
                    return { fullScreenSeenAt: now };
                case PromotionPatchType.ACCEPTED:
                    return { acceptedAt: now };
                default:
                    return {};
            }
        })()),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            PATCH_PROMOTION_REQUEST,
            PATCH_PROMOTION_SUCCESS,
            PATCH_PROMOTION_ERROR,
        ],
    },
});
