export const BANKS_REQUEST = 'BANKS_REQUEST';
export const BANKS_SUCCESS = 'BANKS_SUCCESS';
export const BANKS_FAILURE = 'BANKS_FAILURE';
export const LINK_REQUEST = 'LINK_REQUEST';
export const LINK_SUCCESS = 'LINK_SUCCESS';
export const LINK_FAILURE = 'LINK_FAILURE';
export const PLAID_LINK_REQUEST = 'PLAID_LINK_REQUEST';
export const PLAID_LINK_SUCCESS = 'PLAID_LINK_SUCCESS';
export const PLAID_LINK_FAILURE = 'PLAID_LINK_FAILURE';
export const LINK_PLAID_CONNECTION_REQUEST = 'LINK_PLAID_CONNECTION_REQUEST';
export const LINK_PLAID_CONNECTION_SUCCESS = 'LINK_PLAID_CONNECTION_SUCCESS';
export const LINK_PLAID_CONNECTION_FAILURE = 'LINK_PLAID_CONNECTION_FAILURE';
export const LOGIN_BANK_REQUEST = 'LOGIN_BANK_REQUEST';
export const LOGIN_BANK_SUCCESS = 'LOGIN_BANK_SUCCESS';
export const LOGIN_BANK_FAILURE = 'LOGIN_BANK_FAILURE';
export const IS_FETCHING_BANKS = 'IS_FETCHING_BANKS';
