import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import useColors from 'hooks/useColors';
import { IconWithGradient } from '../Icon';
const IconStatusDone = ({ ...props }) => {
    const id = useId();
    const colors = useColors();
    return (<IconWithGradient id={id} {...props} width={18} height={18} viewBox="0 0 18 18" defaultGradientKey="negativeCards">
      <Path d="M0.286624 6.70701C0.706221 3.35023 3.35023 0.706221 6.70701 0.286624V0.286624C8.22974 0.096282 9.77026 0.096282 11.293 0.286624V0.286624C14.6498 0.706221 17.2938 3.35023 17.7134 6.70701V6.70701C17.9037 8.22974 17.9037 9.77026 17.7134 11.293V11.293C17.2938 14.6498 14.6498 17.2938 11.293 17.7134V17.7134C9.77026 17.9037 8.22974 17.9037 6.70701 17.7134V17.7134C3.35023 17.2938 0.706221 14.6498 0.286624 11.293V11.293C0.096282 9.77026 0.096282 8.22974 0.286624 6.70701V6.70701Z" fill={`url(#gradient_${id})`}/>
      <Path d="M8.50293 12.9737C8.02688 13.4498 7.25444 13.4498 6.77862 12.9737L4.10704 10.3021C3.63099 9.82631 3.63099 9.05388 4.10704 8.57805C4.58286 8.102 5.35529 8.102 5.83134 8.57805L7.42308 10.1696C7.54324 10.2895 7.7383 10.2895 7.8587 10.1696L12.1687 5.8596C12.6445 5.38355 13.4169 5.38355 13.893 5.8596C14.1216 6.08821 14.25 6.39838 14.25 6.72164C14.25 7.0449 14.1216 7.35507 13.893 7.58368L8.50293 12.9737Z" fill={colors.background.light}/>
    </IconWithGradient>);
};
export default IconStatusDone;
