import React from 'react';
import { StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { rem } from 'design-system';
import Text from 'design-system/Text';
import useColors from 'hooks/useColors';
const TextBadge = ({ style, children, ...props }) => {
    const colors = useColors();
    return (<LinearGradient style={[styles.container, style]} {...colors.gradients.ultimate} {...props}>
      <Text Text-12 White>
        {children}
      </Text>
    </LinearGradient>);
};
const styles = StyleSheet.create({
    container: {
        borderRadius: rem(8),
        paddingHorizontal: rem(12),
        paddingVertical: rem(6),
        justifyContent: 'center',
    },
});
export default TextBadge;
