import useColors from 'hooks/useColors';
import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import { rem } from './values';
import { isWeb } from '../constants';
const FlatListItemCard = ({ children, index, itemsLength, style, onPress, }) => {
    const colors = useColors();
    const borderStyles = [
        index === 0 && styles.itemContainerFirst,
        index === (itemsLength || 0) - 1 && styles.itemContainerLast,
        index === 0 &&
            itemsLength !== undefined &&
            itemsLength === 0 &&
            styles.itemContainerSolo,
    ];
    if (onPress) {
        return (<View style={[borderStyles, styles.itemContainer, style]}>
        <TouchableHighlight onCard hoverOnLight onPress={onPress} activeOpacity={1} disabled={!onPress} style={borderStyles} underlayColor={colors.background.underlay}>
          {children}
        </TouchableHighlight>
      </View>);
    }
    return (<View style={[borderStyles, styles.itemContainer, style]}>{children}</View>);
};
const styles = StyleSheet.create({
    itemContainer: {
        marginHorizontal: rem(16),
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
    itemContainerSolo: {
        borderRadius: rem(16),
        ...(Platform.OS === 'ios' && {
            overflow: 'hidden',
        }),
    },
    itemContainerFirst: {
        borderTopLeftRadius: rem(16),
        borderTopRightRadius: rem(16),
        ...(Platform.OS === 'ios' && {
            overflow: 'hidden',
        }),
        ...(isWeb && {
            paddingTop: rem(8),
        }),
    },
    itemContainerLast: {
        borderBottomLeftRadius: rem(16),
        borderBottomRightRadius: rem(16),
        ...(Platform.OS === 'ios' && {
            overflow: 'hidden',
        }),
        ...(isWeb && {
            paddingBottom: rem(8),
        }),
    },
});
export default FlatListItemCard;
