import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
const IconCar = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} defaultGradientKey="turquoise" {...props}>
      <Path d="M5.89044 8.48529H4.27277C4.04954 8.48529 3.86835 8.66648 3.86835 8.88972C3.86835 9.11296 4.04954 9.29415 4.27277 9.29415H5.89044C6.11368 9.29415 6.29487 9.11296 6.29487 8.88972C6.29487 8.66648 6.11368 8.48529 5.89044 8.48529Z" fill={`url(#gradient_${id})`}/>
      <Path d="M18.8132 9.57642C18.7363 9.33457 17.1931 6.0645 16.6156 5.71346C16.0146 5.34786 12.4194 5.25 11.9567 5.25C11.4941 5.25 7.89886 5.34786 7.29788 5.71346C6.72038 6.06367 5.17714 9.33457 5.10029 9.57642C5.03234 9.78915 5.15124 10.0164 5.36398 10.0844C5.57914 10.1515 5.8048 10.0334 5.87191 9.82066C5.99888 9.41866 7.40139 6.68159 7.70955 6.40982C8.05172 6.24645 10.5712 6.05882 11.9567 6.05882C13.3423 6.05882 15.8617 6.24645 16.1756 6.39045C16.5112 6.68163 17.9146 9.4187 18.0416 9.8207C18.0966 9.99298 18.2559 10.103 18.4274 10.103C18.4678 10.103 18.5091 10.0973 18.5503 10.0844C18.7622 10.0164 18.8811 9.78912 18.8132 9.57642Z" fill={`url(#gradient_${id})`}/>
      <Path d="M5.88976 16.5736C5.70049 16.5736 5.54842 16.7062 5.50474 16.8817L3.05885 16.2703V16.1692C3.05885 15.9459 2.87766 15.7647 2.65442 15.7647C2.43118 15.7647 2.25003 15.946 2.25003 16.1692V17.7869C2.25003 18.4558 2.79436 19.0001 3.46327 19.0001H5.08094C5.74985 19.0001 6.29418 18.4558 6.29418 17.7869V16.978C6.29418 16.7548 6.11299 16.5736 5.88976 16.5736Z" fill={`url(#gradient_${id})`}/>
      <Path d="M21.258 15.7648C21.0348 15.7648 20.8536 15.946 20.8536 16.1693V16.2704L18.4077 16.8818C18.364 16.7063 18.212 16.5737 18.0227 16.5737C17.7995 16.5737 17.6183 16.7549 17.6183 16.9781V17.7869C17.6183 18.4558 18.1626 19.0002 18.8315 19.0002H20.4492C21.1181 19.0002 21.6624 18.4558 21.6624 17.7869V16.1693C21.6624 15.946 21.4813 15.7648 21.258 15.7648Z" fill={`url(#gradient_${id})`}/>
      <Path d="M19.6404 8.48529H18.0227C17.7995 8.48529 17.6183 8.66648 17.6183 8.88972C17.6183 9.11296 17.7995 9.29415 18.0227 9.29415H19.6404C19.8636 9.29415 20.0448 9.11296 20.0448 8.88972C20.0448 8.66648 19.8636 8.48529 19.6404 8.48529Z" fill={`url(#gradient_${id})`}/>
      <Path d="M21.6318 12.3005L20.5286 12.5213C20.3927 12.5423 17.6766 12.9338 16.9883 12.9338C16.8896 13.0122 16.8007 13.5315 16.8112 14.0176C19.2765 13.9012 21.1958 13.7467 21.2266 13.7442L21.6626 13.6852C21.6617 13.149 21.6536 12.6911 21.6318 12.3005Z" fill={`url(#gradient_${id})`}/>
      <Path d="M20.9677 10.5227C20.7865 10.4702 20.6918 10.3383 20.5123 10.0698L20.374 9.86759C20.1321 9.53031 19.6647 9.51575 19.2125 9.50282C19.0766 9.49877 18.8614 9.49232 18.7595 9.46885C18.7248 9.4179 18.6778 9.37502 18.6212 9.34431C17.8666 8.92934 15.0074 8.48529 11.9565 8.48529C8.90562 8.48529 6.04639 8.92934 5.29176 9.34427C5.23515 9.37502 5.18821 9.41786 5.15265 9.46965C5.05074 9.49312 4.83558 9.49956 4.69969 9.50362C4.24837 9.51655 3.78085 9.53031 3.53904 9.86759L3.40073 10.0706C3.22117 10.3392 3.12654 10.471 2.94535 10.5236C2.62827 10.6166 2.4544 10.9417 2.35814 11.533L3.15725 11.7328C3.17018 11.7328 5.8086 12.1251 6.69912 12.1251C7.00485 12.1251 7.27419 12.2415 7.47802 12.4615C7.90671 12.925 7.92692 13.7371 7.91641 14.0533C9.21781 14.1075 10.6227 14.1472 11.9565 14.1472C13.2919 14.1472 14.6976 14.1075 16.0007 14.0533C15.9918 13.6958 16.012 12.8005 16.4124 12.3774C16.5693 12.2124 16.7674 12.1251 16.9874 12.1251C17.5108 12.1251 19.6226 11.8363 20.3918 11.7247L21.5492 11.4925C21.4521 10.9255 21.2783 10.6133 20.9677 10.5227ZM14.7874 13.3383H9.1256C8.90236 13.3383 8.72117 13.1571 8.72117 12.9338C8.72117 12.7106 8.90236 12.5294 9.1256 12.5294H14.7874C15.0106 12.5294 15.1918 12.7106 15.1918 12.9338C15.1918 13.1571 15.0107 13.3383 14.7874 13.3383Z" fill={`url(#gradient_${id})`}/>
      <Path d="M21.3118 14.5483C21.2415 14.5539 16.2373 14.9559 11.957 14.9559C7.67663 14.9559 2.67243 14.5539 2.62307 14.5507L2.27202 14.5143C2.3157 15.3919 2.45079 16.7079 2.86973 16.9311C3.3065 17.1632 8.35923 17.3824 11.9569 17.3824C15.5546 17.3824 20.6073 17.1632 21.0433 16.9303C21.4639 16.7063 21.599 15.3814 21.6418 14.5038L21.3118 14.5483Z" fill={`url(#gradient_${id})`}/>
      <Path d="M6.88299 13.0098C6.83283 12.9556 6.77944 12.9338 6.69857 12.9338C5.74417 12.9338 3.1106 12.5415 2.99898 12.5254L2.27831 12.3458C2.25811 12.7291 2.25083 13.178 2.25003 13.6981L2.69571 13.7442C2.7216 13.7466 4.64012 13.9003 7.10543 14.0176C7.1135 13.7256 7.07468 13.2169 6.88299 13.0098Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default IconCar;
