import React, { memo, useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
const IconChevron = ({ width = 12, height = 7, direction, ...props }) => {
    const id = useId();
    const styles = [
        direction === 'left' && { transform: [{ rotateZ: '90deg' }] },
        direction === 'right' && { transform: [{ rotateZ: '-90deg' }] },
    ];
    return (<IconWithGradient id={id} width={width} style={styles} height={height} viewBox="0 0 12 7" defaultGradientKey="ultimate" {...props}>
      <Path strokeLinecap="round" d="M1 0.5L6 5.5L11 0.5" stroke={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default memo(IconChevron);
