import React, { useId } from 'react';
import { G, Path, Defs, ClipPath, Rect } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconFavorite = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} defaultGradientKey="pro" {...props}>
      <G clipPath="url(#clip0_415_3712)">
        <Path fill={`url(#gradient_${id})`} d="M20.9636 14.9476L19.8836 19.0426C19.5761 18.9676 19.3061 18.8251 19.1486 18.6376C18.8186 18.2401 18.2936 18.0151 17.7161 18.0151C17.1461 18.0151 16.6211 18.2401 16.2911 18.6376C16.0511 18.9301 15.5261 19.1251 15.0011 19.1251C14.4686 19.1251 13.9586 18.9376 13.7111 18.6376C13.3811 18.2401 12.8561 18.0151 12.2861 18.0151C11.7086 18.0151 11.1836 18.2401 10.8536 18.6376C10.6961 18.8251 10.4261 18.9601 10.1186 19.0426L9.03856 14.9476C8.97106 14.6851 9.10606 14.4076 9.35356 14.2951L14.7686 11.8726C14.9186 11.8126 15.0836 11.8126 15.2336 11.8726L20.6486 14.2951C20.8961 14.4076 21.0311 14.6851 20.9636 14.9476Z"/>
        <Path fill={`url(#gradient_${id})`} d="M20.438 21C19.3392 21 18.32 20.568 17.7117 19.845C17.1185 20.568 16.0992 21 15.0005 21C13.9017 21 12.8825 20.568 12.2742 19.845C11.681 20.568 10.6617 21 9.56299 21C9.25249 21 9.00049 20.748 9.00049 20.4375C9.00049 20.127 9.25249 19.875 9.56299 19.875C10.3227 19.875 11.0367 19.5863 11.4275 19.1213C11.825 18.6488 12.7385 18.6495 13.1352 19.1213C13.5267 19.5863 14.2407 19.875 15.0005 19.875C15.7602 19.875 16.4742 19.5863 16.865 19.1213C17.2625 18.6488 18.176 18.6495 18.5727 19.1213C18.9635 19.5863 19.6782 19.875 20.438 19.875C20.7485 19.875 21.0005 20.127 21.0005 20.4375C21.0005 20.748 20.7485 21 20.438 21Z"/>
        <Path fill={`url(#gradient_${id})`} d="M14.4382 10.4999V11.2049L10.5007 12.9599V11.9549C10.9657 11.5424 11.4757 11.0474 11.9632 10.4999H14.4382Z"/>
        <Path fill={`url(#gradient_${id})`} d="M18.938 10.4999H15.563V11.2034L19.5005 12.9599V11.0624C19.5005 10.7519 19.2485 10.4999 18.938 10.4999V10.4999Z"/>
        <Path fill={`url(#gradient_${id})`} d="M15.563 6.75012V5.81262C15.563 5.50212 15.311 5.25012 15.0005 5.25012C14.69 5.25012 14.438 5.50212 14.438 5.81262V6.75012H15.563Z"/>
        <Path fill={`url(#gradient_${id})`} d="M17.2512 8.0625V9.75H12.7512V9.555C13.2312 8.91 13.6437 8.22 13.9137 7.5H16.6887C16.9962 7.5 17.2512 7.755 17.2512 8.0625Z"/>
        <Path fill={`url(#gradient_${id})`} d="M7.875 12C7.75725 12 7.6395 11.9633 7.53975 11.889C7.07475 11.5433 3 8.4375 3 5.8035C3 4.25775 4.2615 3 5.8125 3C6.60975 3 7.353 3.3345 7.875 3.8955C8.397 3.3345 9.14025 3 9.9375 3C11.4885 3 12.75 4.25775 12.75 5.8035C12.75 8.4375 8.67525 11.5433 8.21025 11.889C8.1105 11.9633 7.99275 12 7.875 12Z"/>
      </G>
      <Defs>
        <ClipPath id="clip0_415_3712">
          <Rect width="18" height="18" fill="white" transform="translate(3 3)"/>
        </ClipPath>
      </Defs>
    </IconWithGradient>);
};
