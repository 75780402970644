import { appleAuth } from 'utils/packages/rnAppleAuthentication';
import { getUser, linkApple, linkGoogle } from 'actions';
import { muteAll, useFetchOld } from 'hooks';
import useGoogleSignIn from 'login/hooks/useGoogleSignIn';
import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useAppleSignIn from '../../../login/hooks/useAppleSignIn';
export const useSocialLogin = () => {
    const dispatch = useAppDispatch();
    const userOauths = useAppSelector((store) => store.user.user.userOauths);
    const [, , , fetch] = useFetchOld(muteAll);
    const providerMap = useMemo(() => userOauths.reduce((prev, curr) => ({ [curr.provider]: true, ...prev }), {}), [userOauths]);
    const [googleSignIn] = useGoogleSignIn();
    const onOpenSignInWithGoogle = useCallback(async () => {
        if (providerMap.GOOGLE)
            return;
        const googleResult = await googleSignIn();
        if (googleResult) {
            const { token } = googleResult;
            const result = await fetch(linkGoogle(token));
            if (result) {
                dispatch(getUser());
            }
        }
    }, [googleSignIn, providerMap.GOOGLE]);
    const [appleSignIn] = useAppleSignIn();
    const onOpenSignInWithApple = useCallback(async () => {
        if (providerMap.APPLE)
            return;
        const appleResult = await appleSignIn();
        if (appleResult) {
            const { authorizationCode, fullName } = appleResult;
            const firstName = fullName?.givenName;
            const lastName = fullName?.familyName;
            const result = await fetch(linkApple(authorizationCode, 'ios', firstName, lastName));
            if (result) {
                dispatch(getUser());
            }
        }
    }, [appleSignIn, providerMap.APPLE]);
    return {
        onOpenSignInWithApple,
        onOpenSignInWithGoogle,
        hasApple: providerMap.APPLE,
        hasFacebook: providerMap.FACEBOOK,
        hasGoogle: providerMap.GOOGLE,
        isAppleSupported: appleAuth.isSupported,
    };
};
