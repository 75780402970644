import { useCallback, useEffect, useMemo } from 'react';
import { putResolve } from 'typed-redux-saga/macro';
import { LOGGED_OUT } from 'actions/types';
import { useAppDispatch } from 'store/hooks';
import { useReSelector } from 'utils/hooksApi';
export const ACTION_TYPE = 'EVENT_BUS.EVENT';
// Events
export const createEventBusModule = (moduleName) => (eventName) => `EventBus.${moduleName}.${eventName}`;
// Reducer
export const createEventBusReducer = () => {
    const initialState = { event: undefined };
    return (state = initialState, action) => {
        switch (action.type) {
            case ACTION_TYPE:
                return { ...state, event: action.payload, extra: action.extra };
            case LOGGED_OUT: {
                return initialState;
            }
            default:
                return state;
        }
    };
};
// Observer hook.
export const useEventBusEffect = (effect, events) => {
    const eventsMap = useMemo(() => {
        if (!events.length) {
            throw new RangeError('Empty listeners are forbidden');
        }
        return events.reduce((prev, next) => ({ ...prev, [next]: true }), {});
    }, [events]);
    const { event, extra } = useReSelector((store) => store.eventBus);
    useEffect(() => {
        if (event && eventsMap[event])
            effect(extra);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event, eventsMap, effect]);
};
// Event source hook
export const useEventBusDispatch = () => {
    const dispatch = useAppDispatch();
    return useCallback(async (event, extra) => {
        await dispatch({
            type: ACTION_TYPE,
            payload: event,
            extra,
        });
        await dispatch({
            type: ACTION_TYPE,
            payload: undefined,
            extra: undefined,
        });
    }, []);
};
export function* putEventBusEvent(event, extra) {
    yield putResolve({
        type: ACTION_TYPE,
        payload: event,
        extra,
    });
    yield putResolve({
        type: ACTION_TYPE,
        payload: undefined,
        extra: undefined,
    });
}
