import { getPreferences, updatePreferences } from 'actions';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
const selectPreferences = (store) => store.user.preferences;
export const usePreferences = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getPreferences());
    }, []);
    const preferences = useAppSelector(selectPreferences);
    const isQuizActive = preferences?.['feed.quiz']?.value;
    const isReportsActive = preferences?.['feed.report.weekly']?.value;
    return {
        isReportsActive,
        isQuizActive,
        updateIsQuizActive: (isActive) => {
            dispatch(updatePreferences({
                'feed.quiz': isActive,
            }));
        },
        updateIsReportsActive: (isActive) => {
            dispatch(updatePreferences({
                'feed.report.weekly': isActive,
                'feed.report.monthly': isActive,
            }));
        },
    };
};
