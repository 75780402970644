import { useCallback } from 'react';
import { getRedemptions } from 'actions';
import useFetchOld, { muteErrorAndResult } from 'hooks/useFetch';
import { useAppSelector } from 'store/hooks';
const redemptionsSelector = (store) => store.rewards.redemptions;
export const useRedemptions = () => {
    const [, , isRedemptionsFetching, fetch] = useFetchOld(muteErrorAndResult);
    const redemptions = useAppSelector(redemptionsSelector);
    const fetchRedemptions = useCallback(() => fetch(getRedemptions()), []);
    return {
        isRedemptionsFetching,
        fetchRedemptions,
        redemptions,
    };
};
