/**
 * Reducer for holding stock news cache.
 *
 * This reducer should not be persisted.
 *
 * If we decide to make it persist between app sessions, please move it to invest.
 */
import { removeLineBreaksAndTabs } from 'utils/formatting';
import { LOGGED_OUT } from 'actions/types';
import { assertUnreachable } from 'utils/types';
const initialState = {
    cache: {},
};
/**
 * Since backend doesn't provide id, we use this for generating it.
 * @param news The raw news object from API response
 * @returns the key string
 */
const getKey = (news) => `${news.headline}-${news.datetime}`;
const transformItemToNews = (news) => ({
    id: getKey(news),
    source: news.source,
    url: news.url,
    image: news.image,
    headline: removeLineBreaksAndTabs(news.headline),
    mTimestamp: Math.floor(parseInt(news.datetime, 10) / 60000),
});
const newsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_STOCK_NEWS_SUCCESS': {
            const { payload, extra: { symbol }, } = action;
            const keySet = new Set();
            const news = [];
            payload.forEach((item) => {
                const newsItem = transformItemToNews(item);
                const { id } = newsItem;
                if (keySet.has(id)) {
                    // skip this item, it's a duplicate
                }
                else {
                    keySet.add(id);
                    news.push(newsItem);
                }
            });
            if (news.length > 0) {
                return { ...state, cache: { ...state.cache, [symbol]: news } };
            }
            return state;
        }
        case LOGGED_OUT: {
            return initialState;
        }
        default:
            assertUnreachable(action);
            return state;
    }
};
export default newsReducer;
