import { createSelector } from 'reselect';
import { selectPortfolioBalance } from 'features/invest/hooks/selectors';
import { selectPotsTotalBalance } from 'features/pots/reducers/selectors';
import { selectCurrentPaydayRangeWithFallback, selectDefaultCurrency, selectEmmaBalanceWithSpaceFilter, selectFeed, } from 'reducers/selectors';
import getToBePaid from 'utils/api/getToBePaid';
export const selectTotalNetworthBalance = createSelector(selectFeed, selectPotsTotalBalance, selectPortfolioBalance, selectEmmaBalanceWithSpaceFilter, selectDefaultCurrency, (feed, potsBalance, portfolioBalance, emmaWalletBalance, defaultCurrency) => ({
    amount: (feed.cards.overview?.netWorth?.amount || 0) +
        potsBalance.value +
        portfolioBalance.value +
        emmaWalletBalance.value,
    currency: feed.cards.overview?.netWorth?.currency || defaultCurrency,
}));
export const selectTotalAssetsBalance = createSelector(selectFeed, selectPotsTotalBalance, selectPortfolioBalance, selectEmmaBalanceWithSpaceFilter, selectDefaultCurrency, (feed, potsBalance, portfolioBalance, emmaWalletBalance, defaultCurrency) => ({
    amount: (feed.cards.overview?.totalAssets?.amount || 0) +
        potsBalance.value +
        portfolioBalance.value +
        emmaWalletBalance.value,
    currency: feed.cards.overview?.netWorth?.currency || defaultCurrency,
}));
export const selectTotalDebtsBalance = createSelector(selectFeed, selectDefaultCurrency, (feed, defaultCurrency) => ({
    amount: feed.cards.overview?.totalDebt?.amount || 0,
    currency: feed.cards.overview?.netWorth?.currency || defaultCurrency,
}));
const EMPTY_UPCOMING = [];
export const selectUpcomingPayments = createSelector([
    selectCurrentPaydayRangeWithFallback,
    (store) => store.subscriptions.committed,
    (_, accountId) => accountId,
], (currentPaydayRange, committed, accountId) => {
    if (currentPaydayRange?.from &&
        currentPaydayRange.to &&
        committed &&
        Object.keys(committed).length &&
        accountId !== undefined) {
        let arrayToBePaid = getToBePaid(committed.subscriptions);
        if (accountId)
            arrayToBePaid = arrayToBePaid.filter((item) => item.accountId === accountId);
        arrayToBePaid = arrayToBePaid.filter((item) => currentPaydayRange?.from &&
            currentPaydayRange.to &&
            item.prediction &&
            item.prediction.date > currentPaydayRange?.from &&
            item.prediction.date < currentPaydayRange?.to);
        return arrayToBePaid;
    }
    return EMPTY_UPCOMING;
});
export const selectTrueBalance = createSelector([selectUpcomingPayments], (arrayToBePaid) => {
    let upcomingSum = 0;
    for (let i = 0; i < arrayToBePaid.length; i++) {
        const amount = arrayToBePaid[i]?.convertedPrice?.amount;
        if (amount !== undefined)
            upcomingSum += amount;
    }
    return upcomingSum;
});
