import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { CardView } from 'design-system/CardView';
import { SettingsItemNavigate } from 'design-system/SettingsItem';
import { AppView } from 'design-system/AppView';
import Button from 'design-system/Button';
import EmojiText from 'design-system/EmojiText';
import Modal from 'design-system/Modal';
import StatusBar from 'design-system/StatusBar';
import EmojiPickerModal from 'design-system/emojiPicker/EmojiPickerModal';
import { IconEmma } from 'design-system/icons';
import { rem } from 'design-system/values';
import WebEmojiPickerWeb from 'features/transactions/components/WebEmojiPicker.web';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import useColors from 'hooks/useColors';
import { useMarginBottom } from 'hooks/useMarginBottom';
import usePrivateRoute from 'hooks/usePrivateRoute';
import { isWeb } from '../../../constants';
import { webPickerWidth } from '../components/EmojiSelector';
const AddAnEmojiScreen = ({ navigation, route, }) => {
    usePrivateRoute();
    const colors = useColors();
    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Add emoji',
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary]);
    const [emojiSelected, setSelectedEmoji] = useState(route.params.emoji || '');
    const keyBack = route.params.keyBack || (isWeb ? undefined : route.key);
    const { params } = route;
    const [webEmojiPickerState, setWebEmojiPickerState] = useState(undefined);
    const { sidebarWidth, width } = useAppFrameDimensions(isWeb);
    const emojiViewRef = useRef(null);
    const clearWebEmojiPickerState = useCallback(() => {
        setWebEmojiPickerState(undefined);
    }, []);
    const onEmojiSelected = useCallback((e) => {
        clearWebEmojiPickerState();
        setSelectedEmoji(e);
    }, [clearWebEmojiPickerState]);
    const onPressEmoji = useCallback(() => {
        if (isWeb) {
            if (webEmojiPickerState) {
                clearWebEmojiPickerState();
                return;
            }
            emojiViewRef.current?.measure((_, __, ___, height, fx, fy) => {
                setWebEmojiPickerState({
                    fx: fx - sidebarWidth + (width - webPickerWidth - rem(36)),
                    fy: fy + height - rem(8),
                });
            });
        }
        else {
            Modal.show(<EmojiPickerModal defaultSelectedEmoji={emojiSelected || undefined} onEmojiSelected={onEmojiSelected}/>, {
                title: 'Choose Emoji',
                propagateSwipe: true,
                containerStyle: {
                    paddingHorizontal: 0,
                },
            });
        }
    }, [
        clearWebEmojiPickerState,
        emojiSelected,
        onEmojiSelected,
        sidebarWidth,
        webEmojiPickerState,
        width,
    ]);
    const saveDetails = useCallback(() => {
        clearWebEmojiPickerState();
        navigation.navigate('DummyAccountCreated', {
            ...params,
            emoji: emojiSelected,
            keyBack,
        });
    }, [clearWebEmojiPickerState, emojiSelected, keyBack, params]);
    const marginBottom = useMarginBottom('marginBottom', undefined, styles.saveButton);
    return (<AppView type="secondary" isInTab={isWeb} avoidHeader>
      <StatusBar animated barStyle="dark-content"/>
      <View style={styles.container}>
        <View ref={emojiViewRef}>
          <CardView webPadding={isWeb}>
            <SettingsItemNavigate title="Emoji" description="Change" icon={emojiSelected ? (<EmojiText fontSize={20}>{emojiSelected}</EmojiText>) : (<IconEmma />)} iconSize={44} titleFirst onPress={onPressEmoji} isSelected/>
          </CardView>
        </View>
      </View>
      {isWeb && (<WebEmojiPickerWeb {...webEmojiPickerState} onEmojiSelected={onEmojiSelected} defaultSelectedEmoji={emojiSelected || undefined}/>)}
      <Button brand onPress={saveDetails} style={marginBottom} disabled={!emojiSelected} title="Continue"/>
    </AppView>);
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    saveButton: {
        marginHorizontal: rem(16),
    },
});
export default AddAnEmojiScreen;
