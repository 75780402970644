import React from 'react';
import { Platform, View } from 'react-native';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import CardHeader from './CardHeader';
import { isWeb } from '../constants';
export const CardView = ({ webPadding, children, noMarginTop, noMarginBottom, withPadding, style, noMargin, title, selectable, isSelected, hasError, ...props }) => {
    const styles = useStyles(styleSet);
    return (<View style={[
            styles.container,
            noMarginTop && styles.noMarginTop,
            noMarginBottom && styles.noMarginBottom,
            noMargin && styles.noMargin,
            withPadding && styles.withPadding,
            webPadding && styles.webPadding,
            ...(selectable
                ? [
                    styles.selectableCard,
                    isSelected && styles.selectableActiveCard,
                    hasError && styles.selectableCardWithError,
                ]
                : []),
            style,
        ]} {...props}>
      {Boolean(title) && <CardHeader>{title}</CardHeader>}
      {children}
    </View>);
};
export const CARD_VIEW_BORDER_RADIUS = rem(16);
const styleSet = createStyleSheets((colors) => ({
    container: {
        backgroundColor: colors.cards.onDark,
        borderRadius: CARD_VIEW_BORDER_RADIUS,
        margin: rem(16),
        ...(Platform.OS !== 'android' && {
            overflow: 'hidden',
        }),
    },
    noMarginTop: {
        marginTop: 0,
    },
    noMarginBottom: {
        marginBottom: 0,
    },
    noMargin: {
        margin: 0,
    },
    withPadding: {
        padding: rem(16),
    },
    webPadding: {
        ...(isWeb && {
            padding: rem(8),
        }),
    },
    selectableActiveCard: {
        ...(isWeb && {
            borderColor: colors.elements.brand,
        }),
    },
    selectableCardWithError: {
        ...(isWeb && {
            borderWidth: rem(1),
            borderColor: colors.elements.negative,
        }),
    },
    selectableCard: {
        ...(isWeb && {
            borderWidth: rem(1),
            borderColor: colors.cards.onDark,
        }),
    },
}));
