import { useAppSelector } from 'store/hooks';
import * as debug from '../debug';
/**
 * This hook provides current (cached) trading account status.
 */
const useStatus = () => {
    const cachedStatus = useAppSelector((state) => debug.fakeStatus || (state.invest.account ? state.invest.account.status : state.invest.status));
    return cachedStatus;
};
export default useStatus;
