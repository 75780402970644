import React, { useId } from 'react';
import { ClipPath, Defs, Ellipse, G, Path, Rect } from 'react-native-svg';
import { rem } from 'design-system/values';
import { Icon, IconWithGradient } from 'design-system/icons/Icon';
export const IconCoins = ({ alt, width = rem(14), height = rem(12), ...props }) => {
    const id = useId();
    if (alt) {
        return (<IconWithGradient id={id} {...props} defaultGradientKey="white">
        <G clipPath="url(#clip0_415_1441)">
          <Path d="M18.7497 7.49939C18.7497 9.63689 15.5247 11.2494 11.2497 11.2494C6.9747 11.2494 3.74969 9.63689 3.74969 7.49939C3.74969 5.36189 6.9747 3.74939 11.2497 3.74939C15.5247 3.74939 18.7497 5.36189 18.7497 7.49939ZM12.7497 18.7494C9.5622 18.7494 6.83969 17.9244 5.24969 16.5369C5.29469 18.6594 8.4972 20.2494 12.7497 20.2494C17.0022 20.2494 20.1822 18.6669 20.2497 16.5669C18.6822 17.9244 16.0272 18.7494 12.7497 18.7494ZM11.2497 15.7494C9.32002 15.7898 7.40268 15.4324 5.6172 14.6994C6.57719 16.2144 9.3222 17.2494 12.7497 17.2494C17.0247 17.2494 20.2497 15.6369 20.2497 13.4994C20.2436 13.0356 20.0943 12.585 19.8222 12.2094C18.7047 14.3544 15.4722 15.7494 11.2497 15.7494ZM11.9997 12.7494H11.2497H10.4997C7.5597 12.6369 5.16719 11.8344 3.74969 10.5819C3.81719 12.6669 7.0197 14.2494 11.2497 14.2494C15.4797 14.2494 18.6822 12.6669 18.7497 10.5669C17.3022 11.8194 14.9097 12.6219 11.9997 12.7494Z" fill={`url(#gradient_${id})`}/>
        </G>
        <Defs>
          <ClipPath id="clip0_415_1441">
            <Rect width="18" height="18" fill="white" transform="translate(3 3)"/>
          </ClipPath>
        </Defs>
      </IconWithGradient>);
    }
    const iconColor = props.color || 'transparent';
    return (<Icon fill="none" width={width} height={height} viewBox="0 0 14 12" {...props}>
      <Path fill={iconColor} fillRule="evenodd" clipRule="evenodd" d="M13.296 5.234c-1.11.65-3.417 1.096-6.083 1.096-2.667 0-4.974-.446-6.084-1.096-.45.264-.704.561-.704.875 0 1.089 3.039 1.97 6.788 1.97C10.96 8.08 14 7.199 14 6.11c0-.314-.253-.611-.704-.875ZM13.296 8.742c-1.11.65-3.417 1.096-6.083 1.096-2.667 0-4.974-.447-6.084-1.096-.45.264-.704.56-.704.875 0 1.088 3.039 1.97 6.788 1.97 3.748 0 6.787-.881 6.787-1.97 0-.314-.253-.611-.704-.875Z"/>
      <Ellipse cx={7.213} cy={2.383} rx={6.787} ry={1.971} fill={iconColor}/>
    </Icon>);
};
