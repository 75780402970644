import { formatStringList } from 'utils';
import { RentReportingFrequency } from '../types';
export const RENTAL_FREQUENCIES = {
    [RentReportingFrequency.WEEKLY]: {
        label: 'Weekly',
        gradientKey: 'ultimate',
    },
    [RentReportingFrequency.FORTNIGHTLY]: {
        label: 'Fortnightly',
        gradientKey: 'darkPink',
    },
    [RentReportingFrequency.MONTHLY]: {
        label: 'Monthly',
        gradientKey: 'apricot',
    },
    // These types won't be selectable in the frontend yet, adding for backwards compat in case we ever add
    [RentReportingFrequency.QUARTERLY]: {
        label: 'Quarterly',
        gradientKey: 'orange',
    },
    [RentReportingFrequency.ANNUALLY]: {
        label: 'Annually',
        gradientKey: 'lightPink',
    },
    [RentReportingFrequency.PERIODIC]: {
        label: 'Periodic',
        gradientKey: 'darkPurple',
    },
};
export const RENT_REPORTING_AGENCIES = {
    EQUIFAX: {
        label: 'Equifax',
        id: 'EQUIFAX',
        image: require('../img/equifax.png'),
    },
    EXPERIAN: {
        label: 'Experian',
        id: 'EXPERIAN',
        image: require('../img/experian.png'),
    },
    TRANSUNION: {
        label: 'TransUnion',
        id: 'TRANSUNION',
        image: require('../img/transunion.png'),
    },
};
export const formatRentReportingAgencies = (array) => {
    if (!array)
        return '';
    const formattedAgencies = array.map((agency) => RENT_REPORTING_AGENCIES[agency]?.label ||
        agency.charAt(0).toUpperCase() + agency.slice(1).toLowerCase());
    return formatStringList(formattedAgencies);
};
