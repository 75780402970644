import { useMemo } from 'react';
import { useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import useHeaderHeight from './useHeaderHeight';
// There seems to be an issue with FlashList where if the list is initially
// empty then setting an estimatedListSize messes up future renders (see ACC-2119)
const useEstimatedListSize = (items, extraHeight = 0, subtractStatusBarHeight) => {
    const { height, width } = useWindowDimensions();
    const headerHeight = useHeaderHeight();
    const { top } = useSafeAreaInsets();
    const estimatedListSize = useMemo(() => !items || items.length === 0
        ? undefined
        : {
            height: height -
                headerHeight -
                extraHeight -
                (subtractStatusBarHeight ? top : 0),
            width,
        }, [
        extraHeight,
        headerHeight,
        height,
        items,
        subtractStatusBarHeight,
        top,
        width,
    ]);
    return estimatedListSize;
};
export default useEstimatedListSize;
