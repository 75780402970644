import { useEffect, useRef } from 'react';
/**
 *
 * @param action an action to be run in a polling manner. The hook will run the most recent action provided.
 * @param delay an interval in ms between subsequent actions run. Set number to start polling and undefined/null to stop polling.
 */
export const useInterval = (action, delay) => {
    const actionRef = useRef();
    useEffect(() => {
        actionRef.current = action;
    }, [action]);
    useEffect(() => {
        if (!delay)
            return () => { };
        const id = setInterval(() => actionRef?.current?.(), delay);
        return () => clearInterval(id);
    }, [delay]);
};
/**
 *
 * @param action an action to be run in a polling manner. The hook will run the same action that was provided with the delay object.
 * @param delay a delay in ms to wait before running the action. Set number to deffer the action and undefined/null to cancel the job.
 */
export const useTimeout = (action, delay) => {
    useEffect(() => {
        if (!delay)
            return () => { };
        const id = setTimeout(() => action(), delay);
        return () => clearTimeout(id);
        // To avoid resetting timer on wach action change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delay]);
};
