import React, { memo, useCallback, useContext, useMemo } from 'react';
import { InteractionManager, View, } from 'react-native';
import Button from 'design-system/Button';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import { getAccountGoals } from 'actions/connections';
import createStyleSheets from 'utils/createStyleSheets';
import { useAppDispatch, useAppStore } from 'store/hooks';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useBalances } from 'features/accounts/hooks/useBalances';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import Card from '../Card';
import OverviewRow from './OverviewRow';
import { isWeb } from '../../../../constants';
import OverviewLastSynced from './OverviewLastSynced';
import { WalkthroughContext } from '../walkthrough/context';
const keyExtractor = (data) => data.title;
const Overview = ({ data, onLayout, style }) => {
    const array = useBalances(data);
    const nav = useMainStackNavigation();
    const store = useAppStore();
    const dispatch = useAppDispatch();
    const { isUnlockedForSpace } = useShouldShowUpgrade({
        benefitId: 'netWorth',
    });
    const rowPressed = useCallback((title) => {
        if (title === 'Everyday' ||
            title === 'Investments' ||
            title === 'Savings') {
            nav.navigate('AccountsOverview', { title });
        }
        else if (title === 'Net worth') {
            nav.navigate('AccountsOverview', { title: 'Net Worth - Main' });
        }
        InteractionManager.runAfterInteractions(() => {
            if (!store.getState().accounts.loadedGoals) {
                dispatch(getAccountGoals());
            }
        });
    }, [nav]);
    const onPressTrack = useCallback(() => {
        rowPressed('Net worth');
    }, [rowPressed]);
    const layout = useCallback((event) => {
        onLayout?.('overview', event);
    }, [onLayout]);
    const styles = useStyles(styleSet);
    const upgradeButton = useMemo(() => isUnlockedForSpace ? undefined : (<Button extra small cards brandReversed title="Track" hug onPress={onPressTrack} containerStyle={styles.upgradeButton}/>), [isUnlockedForSpace, onPressTrack, styles.upgradeButton]);
    const { paddingHorizontalStyle } = useAppFrameDimensions(true);
    const isInWalkthrough = useContext(WalkthroughContext);
    return (<View onLayout={layout} style={!isInWalkthrough && paddingHorizontalStyle}>
      <Card style={[styles.container, style]}>
        {isWeb && <Spacer h={8}/>}
        {array.map((item) => (<OverviewRow key={keyExtractor(item)} onPress={rowPressed} item={item} arrowDirection="right" button={item.title === 'Net worth' ? upgradeButton : undefined}/>))}
        <OverviewLastSynced />
      </Card>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        marginHorizontal: rem(16),
        marginBottom: rem(16),
    },
    upgradeButton: {
        backgroundColor: 'transparent',
        borderColor: colors.buttons.brandGroup,
        borderWidth: 1,
    },
}));
export default memo(Overview);
