import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconMoneyCheck = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="ultimate">
      <Path d="M17.3008 4H8.3628C5.6814 4 3.8938 5.3407 3.8938 8.469V11.6509C3.8938 11.9816 4.23344 12.1872 4.5284 12.0531C5.40432 11.6509 6.41431 11.499 7.47794 11.6867C9.82863 12.1068 11.5537 14.2876 11.4911 16.6741C11.4822 17.0495 11.4285 17.4159 11.3302 17.7735C11.2587 18.0505 11.4822 18.3097 11.7682 18.3097H17.3008C19.9822 18.3097 21.7698 16.969 21.7698 13.8407V8.469C21.7698 5.3407 19.9822 4 17.3008 4ZM12.8318 13.3849C11.5984 13.3849 10.5973 12.3838 10.5973 11.1504C10.5973 9.91696 11.5984 8.9159 12.8318 8.9159C14.0652 8.9159 15.0663 9.91696 15.0663 11.1504C15.0663 12.3838 14.0652 13.3849 12.8318 13.3849ZM19.3118 12.938C19.3118 13.3045 19.008 13.6083 18.6415 13.6083C18.275 13.6083 17.9711 13.3045 17.9711 12.938V9.3628C17.9711 8.99634 18.275 8.69245 18.6415 8.69245C19.008 8.69245 19.3118 8.99634 19.3118 9.3628V12.938Z" fill={`url(#gradient_${id})`}/>
      <Path d="M6.5752 12.938C4.5999 12.938 3 14.5379 3 16.5132C3 17.1835 3.1877 17.8181 3.5184 18.3544C4.13513 19.3912 5.27025 20.0884 6.5752 20.0884C7.88015 20.0884 9.01527 19.3912 9.63199 18.3544C9.9627 17.8181 10.1504 17.1835 10.1504 16.5132C10.1504 14.5379 8.5505 12.938 6.5752 12.938ZM8.33598 16.2182L6.43219 17.979C6.30706 18.0952 6.13724 18.1578 5.97635 18.1578C5.80653 18.1578 5.63671 18.0952 5.50264 17.9611L4.61778 17.0763C4.35858 16.8171 4.35858 16.3881 4.61778 16.1289C4.87698 15.8697 5.306 15.8697 5.56521 16.1289L5.99423 16.5579L7.42431 15.2351C7.69245 14.9848 8.12147 15.0027 8.37174 15.2708C8.622 15.5389 8.60412 15.968 8.33598 16.2182Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
