import { useCallback } from 'react';
import { useAppStore } from 'store/hooks';
import { useMainStackNavigation } from 'utils/types/navigationV6';
export const useSmartRulesNavigation = () => {
    const { navigate } = useMainStackNavigation();
    const store = useAppStore();
    const onPressSmartRules = useCallback(() => {
        const { didViewSmartRulesIntro } = store.getState().deviceSettings;
        const hasRule = store.getState().accounts.automationRules?.length;
        if (didViewSmartRulesIntro || hasRule) {
            navigate('AutomationRules');
        }
        else {
            navigate('SmartRuleIntro');
        }
    }, []);
    return onPressSmartRules;
};
