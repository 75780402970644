import React, { useCallback, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import FingerprintScanner from 'utils/packages/fingerprintScanner';
import { setTouchId } from 'actions';
import { rem } from 'design-system';
import * as Icons from 'design-system/icons';
import IconFingerprint from 'design-system/icons/IconFingerprint';
import { SettingsItem, SettingsItemSwitch } from 'design-system/SettingsItem';
import useColors from 'hooks/useColors';
import { selectBiometricsEnabled } from 'reducers/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import Phone from 'utils/dimensions';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { isWeb } from '../../../constants';
const strings = {
    changePasscode: {
        title: 'Change Passcode',
    },
    setPasscode: {
        title: 'Set Passcode',
    },
    biometrics: {
        titleiOS: 'Enable Touch ID',
        titleAndroid: 'Enable Biometrics',
    },
};
export const SecuritySettingsSection = () => {
    const colors = useColors();
    const { navigate } = useMainStackNavigation();
    const dispatch = useAppDispatch();
    const isTouchActive = useAppSelector(selectBiometricsEnabled);
    // Note, for FaceID we don't show this option, it's always on
    const [hasBiometrics, setHasBiometrics] = useState(false);
    useEffect(() => {
        const checkTouchId = async () => {
            try {
                await FingerprintScanner.isSensorAvailable();
                setHasBiometrics(true);
            }
            catch (e) {
                // noop
            }
        };
        if (!Phone.isIphoneX()) {
            checkTouchId();
        }
    }, []);
    const hasPinSet = useAppSelector((store) => store.user.user.pinLastChangedAt);
    const onPressChangePasscode = useCallback(() => {
        if (hasPinSet) {
            navigate('ChangePasscode');
        }
        else {
            navigate('SetupPasscode', { allowBack: true });
        }
    }, [hasPinSet]);
    const onBiometricsValueChange = (val) => {
        dispatch(setTouchId(val));
    };
    if (isWeb && !hasPinSet) {
        return null;
    }
    return (<>
      <SettingsItem title={hasPinSet ? strings.changePasscode.title : strings.setPasscode.title} icon={<Icons.IconLock color={colors.gradients.red.colors[0]}/>} onPress={onPressChangePasscode}/>
      {hasBiometrics && (<SettingsItemSwitch title={Platform.select({
                ios: strings.biometrics.titleiOS,
                default: strings.biometrics.titleAndroid,
            })} icon={<IconFingerprint width={rem(20)} height={rem(20)} color={colors.elements.green}/>} onValueChange={onBiometricsValueChange} value={isTouchActive ?? false}/>)}
    </>);
};
