import { call, delay, takeLatest } from 'typed-redux-saga/macro';
import { getTradingAccountFn } from '../api/account';
import { WITHDRAW_POLL_INTERVAL, WITHDRAW_POLL_ATTEMPTS } from '../constants';
/**
 * Update Trading Account after delay of 5s
 */
function* pollTradingAccount() {
    let i = WITHDRAW_POLL_ATTEMPTS;
    while (i > 0) {
        yield* call(getTradingAccountFn);
        i -= 1;
        yield* delay(WITHDRAW_POLL_INTERVAL);
    }
}
function* withdrawSagas() {
    yield* takeLatest((action) => action.type === 'WITHDRAW_SUCCESS', pollTradingAccount); // after user withdraws, start polling to get trading account to update balance
}
export default withdrawSagas;
