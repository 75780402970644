import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconLightBulb = ({ alt, ...props }) => {
    const id = useId();
    if (alt) {
        return (<IconWithGradient id={id} {...props} defaultGradientKey="orange">
        <Path d="M12 4.88a.625.625 0 0 1-.625-.624V2.625a.625.625 0 0 1 1.25 0v1.63c0 .346-.28.626-.625.626Z" fill={`url(#gradient_${id})`}/>
        <Path d="M17.478 7.148a.625.625 0 0 1-.442-1.068l1.154-1.153a.625.625 0 1 1 .884.884l-1.153 1.154a.627.627 0 0 1-.443.183Z" fill={`url(#gradient_${id})`}/>
        <Path d="M21.375 12.625h-1.63a.625.625 0 0 1 0-1.25h1.63a.625.625 0 0 1 0 1.25Z" fill={`url(#gradient_${id})`}/>
        <Path d="M18.632 19.258a.62.62 0 0 1-.442-.184l-1.153-1.153a.625.625 0 0 1 .884-.884l1.153 1.153a.625.625 0 0 1-.442 1.067Z" fill={`url(#gradient_${id})`}/>
        <Path d="M5.371 19.258a.625.625 0 0 1-.442-1.067l1.154-1.154a.625.625 0 0 1 .884.884l-1.153 1.153a.623.623 0 0 1-.443.183Z" fill={`url(#gradient_${id})`}/>
        <Path d="M4.256 12.625H2.625a.625.625 0 0 1 0-1.25h1.63a.625.625 0 0 1 0 1.25Z" fill={`url(#gradient_${id})`}/>
        <Path d="M6.525 7.148a.62.62 0 0 1-.442-.183L4.93 5.81a.625.625 0 1 1 .884-.884l1.153 1.154a.625.625 0 0 1-.442 1.067Z" fill={`url(#gradient_${id})`}/>
        <Path d="M14.5 19.5v1.042c0 .8-.658 1.458-1.458 1.458h-2.084c-.7 0-1.458-.533-1.458-1.7v-.8h5Z" fill={`url(#gradient_${id})`}/>
        <Path d="M15.674 7.469a5.855 5.855 0 0 0-4.925-1.167c-2.208.458-4 2.258-4.458 4.467-.467 2.266.383 4.516 2.2 5.891.492.367.833.934.95 1.592v.008c.017-.008.042-.008.058-.008h5c.017 0 .025 0 .042.008v-.008c.117-.633.492-1.217 1.067-1.667a5.82 5.82 0 0 0 2.225-4.583 5.81 5.81 0 0 0-2.159-4.533Zm-.55 4.95a.63.63 0 0 1-.625-.625 2.29 2.29 0 0 0-2.291-2.292.63.63 0 0 1-.625-.625.63.63 0 0 1 .625-.625 3.55 3.55 0 0 1 3.541 3.542.63.63 0 0 1-.625.625Z" fill={`url(#gradient_${id})`}/>
        <Path d="M9.444 18.25h.06c-.018 0-.043 0-.06.009v-.009Z" fill={`url(#gradient_${id})`}/>
        <Path d="M14.543 18.25v.009c-.017-.009-.026-.009-.043-.009h.043Z" fill={`url(#gradient_${id})`}/>
      </IconWithGradient>);
    }
    return (<IconWithGradient id={id} {...props} defaultGradientKey="orange">
      <Path d="M12 5.305a.5.5 0 0 1-.5-.5V3.5a.5.5 0 0 1 1 0v1.305a.5.5 0 0 1-.5.5ZM16.382 7.118a.5.5 0 0 1-.353-.854l.922-.922a.5.5 0 1 1 .707.707l-.922.923a.501.501 0 0 1-.354.146ZM19.5 11.5h-1.305a.5.5 0 0 1 0-1H19.5a.5.5 0 0 1 0 1ZM17.304 16.806a.496.496 0 0 1-.353-.147l-.923-.922a.5.5 0 1 1 .708-.708l.922.923a.5.5 0 0 1-.354.854ZM6.697 16.806a.5.5 0 0 1-.353-.854l.922-.923a.5.5 0 0 1 .708.708l-.923.922a.499.499 0 0 1-.354.147ZM5.805 11.5H4.5a.5.5 0 0 1 0-1h1.305a.5.5 0 0 1 0 1ZM7.62 7.118a.496.496 0 0 1-.354-.146l-.922-.923a.5.5 0 1 1 .707-.707l.923.922a.5.5 0 0 1-.354.854ZM14 17v.833c0 .64-.527 1.167-1.167 1.167h-1.666c-.56 0-1.167-.427-1.167-1.36V17h4ZM14.94 7.375A4.684 4.684 0 0 0 11 6.442c-1.766.366-3.2 1.806-3.566 3.573-.373 1.813.307 3.613 1.76 4.713.393.294.667.747.76 1.274v.006c.013-.006.033-.006.046-.006h4c.014 0 .02 0 .034.006v-.006c.093-.507.393-.974.853-1.334a4.657 4.657 0 0 0 1.78-3.666 4.648 4.648 0 0 0-1.727-3.627Zm-.44 3.96a.504.504 0 0 1-.5-.5c0-1.013-.82-1.833-1.833-1.833a.504.504 0 0 1-.5-.5c0-.274.227-.5.5-.5a2.84 2.84 0 0 1 2.834 2.833c0 .273-.227.5-.5.5Z" fill={`url(#gradient_${id})`}/>
      <Path d="M9.955 16h.048c-.014 0-.034 0-.048.007V16ZM14.035 16v.007c-.014-.007-.02-.007-.034-.007h.034Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default IconLightBulb;
