import React from 'react';
import { View } from 'react-native';
import { rem } from 'design-system/values';
import AccountCard from 'design-system/AccountCard';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import { isWeb } from '../constants';
import RadioButton from './RadioButton';
export const SelectableAccountCard = ({ isSelected, data, type = 'multiple', onPress, ...props }) => {
    const colors = useColors();
    const styles = useStyles(stylesSet);
    return (<TouchableHighlight onCard hoverOnLight activeOpacity={1} onPress={() => {
            onPress?.(data);
        }} underlayColor={colors.background.underlay}>
      <View style={styles.container}>
        <RadioButton isChecked={isSelected} type={type}/>
        <AccountCard {...props} alt onCard data={data} transparent={isWeb} style={styles.accountCard}/>
      </View>
    </TouchableHighlight>);
};
const stylesSet = createStyleSheets((colors) => ({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        ...(isWeb
            ? {
                paddingLeft: rem(8),
            }
            : {
                paddingHorizontal: rem(16),
            }),
        backgroundColor: isWeb ? 'transparent' : colors.cards.onDark,
    },
    accountCard: {
        flex: 1,
        paddingRight: 0,
        paddingLeft: rem(isWeb ? 8 : 16),
        paddingHorizontal: 0,
        justifyContent: 'space-evenly',
    },
}));
