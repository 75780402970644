import { FlashList } from '@shopify/flash-list';
import { noop } from 'lodash';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState, } from 'react';
import { Keyboard, Platform, View, } from 'react-native';
import Animated, { Extrapolation, interpolate, useAnimatedScrollHandler, useAnimatedStyle, useSharedValue, } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Button from 'design-system/Button';
import { rem, SCROLL_INDICATOR_INSETS } from 'design-system/values';
import Spacer from 'design-system/Spacer';
import { HelpCallToAction } from 'design-system/HelpCallToAction';
import { IconEdit } from 'design-system/icons/IconEdit';
import { AppView } from 'design-system/AppView';
import { NavigationHeader } from 'design-system/NavigationHeader';
import Indicator from 'design-system/Indicator';
import { NavigationTextButton } from 'design-system/TextButton';
import KeyboardAvoidingView from 'design-system/KeyboardAvoidingView';
import Text, { lineHeight } from 'design-system/Text';
import { useHeaderHeight, useKeyboardAdjustPan, useRefCallback } from 'hooks';
import { useActionSheet } from 'hooks/useActionSheet';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { selectDefaultCurrency, selectIsLoggedIn } from 'reducers/selectors';
import { useAppSelector } from 'store/hooks';
import usePrivateRoute from 'hooks/usePrivateRoute';
import createStyleSheets from 'utils/createStyleSheets';
import { extractKeyFromKey } from 'utils/flatListHelpers';
import useEstimatedListSize from 'hooks/useEstimatedListSize';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import { useNativeStackScreenOptions } from 'hooks/navigation/useStackNavigatorScreenOptions';
import { useRequiredBenefitCallbackWithSpaces } from 'features/premium/hooks/useRequiredBenefitCallback';
import Amplitude from '../../../utils/amplitude';
import Links from '../../../utils/links';
import { BudgetActionRow } from '../components/BudgetActionRow';
import BudgetCategoryEditRow from '../components/BudgetCategoryEditRow';
import { RollingBudgetsSwitchCard } from '../components/RollingBudgetsSwitchCard';
import SetCategoryBudgetsAllocation, { allocationCardHeight, } from '../components/SetCategoryBudgetsAllocation';
import { useBudgetRollover } from '../hooks/useBudgetRollover';
import { useClearCategoryBudgetsModal } from '../hooks/useClearCategoryBudgetsModal';
import { useSaveBudget } from '../hooks/useSaveBudget';
import { selectBudgets, selectCategoryBudgets } from '../selectors';
import { isWeb } from '../../../constants';
const openHelp = () => {
    Links.openLink('https://help.emma-app.com/en/category/budgets-1s6usi4/');
};
const itemHeight = rem(82);
const ESTIMATED_ITEM_SIZE = itemHeight;
const keyExtractor = extractKeyFromKey('key');
const AnimatedFlashList = Animated.createAnimatedComponent(FlashList);
const getPageTitle = (flow) => {
    if (isWeb) {
        if (flow === 'Set') {
            return 'Set Category Budgets';
        }
        if (flow === 'Confirm') {
            return 'Confirm Category Budgets';
        }
        return 'Edit Category Budget';
    }
    return undefined;
};
const SetCategoryBudgetsScreen = ({ navigation, route, }) => {
    usePrivateRoute();
    useKeyboardAdjustPan();
    const headerHeight = useHeaderHeight();
    const colors = useColors();
    const goBack = route.params.goBack === undefined ? true : route.params.goBack;
    const { categoryId, flow, callback } = route.params;
    const userCurrency = useAppSelector(selectDefaultCurrency);
    const styles = useStyles(styleSet);
    const budgets = useAppSelector(selectBudgets);
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const categoryBudgets = useAppSelector(selectCategoryBudgets);
    const isFetchingBudgets = useAppSelector((store) => store.budgeting.isFetchingBudgets);
    const shouldResetRollingAmountRef = useRef(false);
    const scrollViewRef = useRef(null);
    const settingBudget = route.params.monthlyBudget;
    const monthlyBudget = useMemo(() => {
        const overall = budgets.find((budget) => budget.key === 'overall.monthly');
        return settingBudget !== undefined
            ? {
                totalLimit: settingBudget,
                currency: userCurrency,
                key: 'overall.monthly',
                type: 'overall',
                previousAverage: overall?.previousAverage,
            }
            : overall;
    }, [budgets, settingBudget, userCurrency]);
    const [budgetState, setBudgetState] = useState(() => categoryBudgets.map((budget) => {
        // Populate the category budgets with the limit from the previous screen if it was there
        const previousScreenState = route.params.budgetState || [];
        const prev = previousScreenState.find((b) => b.key === budget.key);
        return {
            ...budget,
            totalLimit: prev?.totalLimit || budget.totalLimit,
        };
    }));
    const onResetRollingAmounts = useCallback(() => {
        shouldResetRollingAmountRef.current = true;
        setBudgetState((state) => state.map((budget) => ({
            ...budget,
            rollingAccumulatedLimit: null,
        })));
    }, []);
    const clearBudgets = useCallback(() => {
        setBudgetState((state) => state.map((budget) => ({ ...budget, totalLimit: 0 })));
    }, []);
    const openCategoryBudgetsModal = useClearCategoryBudgetsModal({
        onClearRollingPress: onResetRollingAmounts,
        onClearBudgetPress: clearBudgets,
    });
    const handleClear = useRefCallback(() => {
        if (isRollover) {
            openCategoryBudgetsModal();
        }
        else {
            clearBudgets();
        }
    });
    const scrollY = useSharedValue(0);
    const [headerInfoHeight, setHeaderInfoHeight] = useState(0);
    const globalScreenOptions = useNativeStackScreenOptions();
    const titleContainerStyle = useAnimatedStyle(() => ({
        opacity: interpolate(scrollY?.value, [0, headerInfoHeight], [0, 1], Extrapolation.CLAMP),
    }));
    useLayoutEffect(() => {
        navigation.setOptions({
            title: getPageTitle(route.params.flow),
            headerTitle: '',
            headerRight: isWeb
                ? undefined
                : () => <NavigationTextButton text="Clear" onPress={handleClear}/>,
            headerTintColor: colors.text.primary,
        });
    }, [
        colors.text.primary,
        globalScreenOptions.headerTitleStyle,
        handleClear,
        route.params.flow,
        styles.headerTitleContainer,
        titleContainerStyle,
    ]);
    useEffect(() => {
        if (categoryId) {
            const index = budgetState.findIndex((budget) => budget.categoryId === categoryId);
            const timeout = setTimeout(() => {
                // @ts-ignore
                scrollViewRef.current?.scrollToIndex({
                    index,
                    animated: true,
                    viewOffset: headerHeight + rem(137),
                });
            }, 250);
            return () => {
                clearTimeout(timeout);
            };
        }
        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        // User probably added a new category, or we just loaded in budgets
        setBudgetState((s) => categoryBudgets.map((budget) => {
            const curr = s.find((b) => b.key === budget.key);
            return {
                ...budget,
                totalLimit: curr?.totalLimit || budget.totalLimit,
            };
        }));
    }, [categoryBudgets]);
    const totalSpending = useMemo(() => budgetState.reduce((prev, budget) => {
        if (budget.type !== 'overall' && budget.totalLimit) {
            return prev + budget.totalLimit;
        }
        return prev;
    }, 0), [budgetState]);
    const onPressAddMoreBudgets = useCallback(() => {
        Keyboard.dismiss();
        navigation.navigate('ManageCategories');
        Amplitude.logEvent('Budgets.CustomCategorySetup');
    }, []);
    const actionSheet = useActionSheet();
    const [saving, save, getBudgets] = useSaveBudget();
    useEffect(() => {
        if (budgets.length === 0) {
            getBudgets();
        }
        Amplitude.logEvent('Category Budgets Screen', {
            type: isLoggedIn ? 'in_app' : 'onboarding',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { isRollover, setRollover } = useBudgetRollover(route.params.shouldRollover);
    const saveDetails = useRequiredBenefitCallbackWithSpaces(useCallback(async (increaseToMatchTotal) => {
        const editedMonthlyBudget = increaseToMatchTotal
            ? totalSpending
            : undefined;
        const budgetStateAltered = monthlyBudget
            ? [monthlyBudget, ...budgetState]
            : budgetState;
        if (flow === 'Edit' || flow === 'Confirm') {
            navigation.navigate('EditBudget', {
                flow,
                keyBack: route.key,
                editedMonthlyBudget,
                shouldRollover: isRollover,
                budgetState: budgetStateAltered,
                shouldResetRollingAmount: shouldResetRollingAmountRef.current,
                period: route.params.period,
            });
        }
        else if (flow === 'Set') {
            navigation.navigate('Rollover', {
                editedMonthlyBudget,
                budgetState: budgetStateAltered,
                shouldResetRollingAmount: shouldResetRollingAmountRef.current,
                period: route.params.period,
            });
        }
        else if (goBack) {
            const res = await save(editedMonthlyBudget, isRollover, budgetState, undefined, shouldResetRollingAmountRef.current);
            if (res === null)
                return;
            navigation.goBack();
        }
    }, [
        budgetState,
        flow,
        goBack,
        isRollover,
        monthlyBudget,
        route.key,
        route.params.period,
        save,
        totalSpending,
    ]), 'budgeting', flow === 'Set' || flow === 'Confirm');
    const onPressSave = useCallback(() => {
        if (monthlyBudget?.totalLimit !== null &&
            monthlyBudget?.totalLimit !== undefined &&
            totalSpending > monthlyBudget?.totalLimit) {
            const difference = totalSpending - monthlyBudget.totalLimit;
            const currency = budgets && budgets[0] ? budgets[0].currency : '';
            const options = ['Save anyway', 'Increase Total Budget', 'Cancel'];
            const title = `The total of your budgeting categories exceeds your total budget by ${getSymbolFromCurrency(currency)}${difference}`;
            actionSheet.showActionSheetWithOptions({
                title,
                options,
                cancelButtonIndex: 2,
            }, (idx) => {
                if (idx !== undefined && idx < 2) {
                    saveDetails(idx === 1);
                }
            });
            return;
        }
        saveDetails();
    }, [
        actionSheet,
        budgets,
        monthlyBudget?.totalLimit,
        saveDetails,
        totalSpending,
    ]);
    const onSetBudget = useCallback((key, value) => {
        setBudgetState((state) => state.map((budget) => {
            if (budget.key === key) {
                return {
                    ...budget,
                    totalLimit: value,
                };
            }
            return budget;
        }));
    }, []);
    const { bottom } = useSafeAreaInsets();
    const { width, paddingHorizontal } = useAppFrameDimensions(isWeb);
    const hasAverages = useMemo(() => budgetState.find((budget) => Boolean(budget.previousAverage)) !==
        undefined, [budgetState]);
    const contentContainerStyles = useMemo(() => ({
        paddingTop: undefined,
        paddingBottom: bottom + rem(80),
        ...(isWeb
            ? {
                width,
                marginLeft: paddingHorizontal,
            }
            : {
                paddingHorizontal,
            }),
    }), [bottom, paddingHorizontal, width]);
    const linesRef = useRef({});
    const headerInputRange = useMemo(() => [0, headerInfoHeight], [headerInfoHeight]);
    const updatedMeasurement = useCallback(() => {
        const measurements = linesRef.current;
        if (measurements.title !== undefined &&
            measurements.subTitle !== undefined) {
            setHeaderInfoHeight(rem(24 +
                4 +
                measurements.title * lineHeight.Text_24 +
                measurements.subTitle * lineHeight.TextThin_14));
        }
    }, []);
    const onTextLayoutTitle = useCallback((event) => {
        const lines = event.nativeEvent.lines.length;
        if (linesRef.current.title === lines)
            return;
        linesRef.current = {
            ...linesRef.current,
            title: lines,
        };
        updatedMeasurement();
    }, [updatedMeasurement]);
    const onTextLayoutSubTitle = useCallback((event) => {
        const lines = event.nativeEvent.lines.length;
        if (linesRef.current.subTitle === lines)
            return;
        linesRef.current = {
            ...linesRef.current,
            subTitle: lines,
        };
        updatedMeasurement();
    }, [updatedMeasurement]);
    const onNewFunnelTextLayout = useCallback((event) => {
        if (isWeb) {
            setHeaderInfoHeight(rem(event.nativeEvent.layout.height + 24));
        }
    }, []);
    const ListHeaderComponent = useMemo(() => (<>
        <View style={styles.headerContainer} onLayout={onNewFunnelTextLayout}>
          <Text Text-24 onTextLayout={onTextLayoutTitle}>
            Select how much you want to spend on each category
          </Text>
          <Spacer h={4}/>
          <Text TextThin-14 Secondary onTextLayout={onTextLayoutSubTitle}>
            Category budgets will help you track your day to day spending.
          </Text>
        </View>
        <View style={{ height: allocationCardHeight + rem(21 + 16) }}/>
        {isLoggedIn ? (<View style={styles.startOfCard}>
            <TouchableHighlight onCard hoverOnLight onPress={onPressAddMoreBudgets} underlayColor={colors.background.underlay}>
              <BudgetActionRow title="Manage categories" icon={<IconEdit color={colors.text.brand}/>}/>
            </TouchableHighlight>
          </View>) : null}
      </>), [
        colors.background.underlay,
        colors.text.brand,
        isLoggedIn,
        onNewFunnelTextLayout,
        onPressAddMoreBudgets,
        onTextLayoutSubTitle,
        onTextLayoutTitle,
        styles.headerContainer,
        styles.startOfCard,
    ]);
    const ListFooterComponent = useCallback(() => (<>
        <View style={styles.endOfCard}>
          <RollingBudgetsSwitchCard value={isRollover} onValueChange={setRollover}/>
        </View>
        <Spacer h={16}/>
        <HelpCallToAction style={styles.help} title="Need to amend your budgets?" subTitle="Learn how to do it" onPress={openHelp}/>
        <Spacer h={64}/>
      </>), [isRollover, setRollover, styles.endOfCard, styles.help]);
    const renderItem = useCallback(({ item: budget, index }) => (<View style={[
            styles.eachItem,
            index === 0 && !isLoggedIn && styles.startOfCard,
        ]}>
        <BudgetCategoryEditRow budget={budget} key={budget.key} onChange={onSetBudget} hasAverages={hasAverages} withInitialFocus={categoryId === budget.categoryId}/>
      </View>), [
        categoryId,
        hasAverages,
        isLoggedIn,
        onSetBudget,
        styles.eachItem,
        styles.startOfCard,
    ]);
    const onScrollList = useAnimatedScrollHandler((event) => {
        scrollY.value = event.contentOffset.y;
    });
    const estimatedListSize = useEstimatedListSize(budgetState);
    let buttonTitle = 'Done';
    if (flow === 'Confirm' || flow === 'Set') {
        buttonTitle = 'Continue';
    }
    else if (callback === undefined) {
        buttonTitle = 'Save';
    }
    const baseProps = {
        data: budgetState,
        ref: scrollViewRef,
        scrollEventThrottle: 16,
        renderItem,
        onScroll: onScrollList,
        keyExtractor,
        onScrollToIndexFailed: noop,
        keyboardShouldPersistTaps: 'always',
        ListHeaderComponent,
        ListFooterComponent,
        contentContainerStyle: contentContainerStyles,
        scrollIndicatorInsets: SCROLL_INDICATOR_INSETS,
    };
    return (<AppView withFrame={false}>
      <NavigationHeader hasShadow={false} scrollY={scrollY} bgColorInputRange={headerInputRange} shadowInputRange={headerInputRange} withFrame title="Category Budgets" hideTitleOnScroll/>
      {isFetchingBudgets && !budgets.length ? (<Indicator dark/>) : (<>
          <KeyboardAvoidingView style={styles.flex} behavior={Platform.OS === 'ios' ? 'padding' : undefined}>
            {isWeb ? (<Animated.FlatList {...baseProps}/>) : (<AnimatedFlashList {...baseProps} estimatedListSize={estimatedListSize} estimatedItemSize={ESTIMATED_ITEM_SIZE} extraData={colors.background.dark}/>)}
          </KeyboardAvoidingView>
          {!!headerInfoHeight && (<SetCategoryBudgetsAllocation scrollY={scrollY} totalAllocated={totalSpending || 0} totalBudget={monthlyBudget?.totalLimit || 0} currency={monthlyBudget?.currency || userCurrency} headerInfoHeight={headerInfoHeight}/>)}
          <Button brand floating onPress={onPressSave} isFetching={saving} title={buttonTitle}/>
        </>)}
    </AppView>);
};
const styleSet = createStyleSheets((colors) => ({
    listCard: {
        marginTop: 0,
    },
    flex: {
        flex: 1,
    },
    help: {
        backgroundColor: colors.cards.onDark,
        marginBottom: rem(8),
    },
    endOfCard: {
        marginHorizontal: rem(16),
        borderBottomLeftRadius: rem(16),
        borderBottomRightRadius: rem(16),
        backgroundColor: colors.cards.onDark,
        ...(Platform.OS === 'ios' && {
            overflow: 'hidden',
        }),
        ...(isWeb && {
            paddingBottom: rem(8),
            paddingHorizontal: rem(8),
        }),
    },
    startOfCard: {
        marginHorizontal: rem(16),
        borderTopLeftRadius: rem(16),
        borderTopRightRadius: rem(16),
        backgroundColor: colors.cards.onDark,
        ...(Platform.OS === 'ios' && {
            overflow: 'hidden',
        }),
        ...(isWeb && {
            paddingTop: rem(8),
            paddingHorizontal: rem(8),
        }),
    },
    eachItem: {
        marginHorizontal: rem(16),
        backgroundColor: colors.cards.onDark,
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
    spacer: {
        height: rem(1),
        backgroundColor: colors.background.dark,
    },
    headerContainer: {
        paddingHorizontal: rem(16),
    },
    headerTitleContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
export default SetCategoryBudgetsScreen;
