import { CALL_API } from 'middleware/api';
const GET_PAYMENTS_LIMITS_REQUEST = 'GET_PAYMENTS_LIMITS_REQUEST';
export const GET_PAYMENTS_LIMITS_SUCCESS = 'GET_PAYMENTS_LIMITS_SUCCESS';
const GET_PAYMENTS_LIMITS_FAILURE = 'GET_PAYMENTS_LIMITS_FAILURE';
export const getPaymentLimits = () => ({
    [CALL_API]: {
        endpoint: '/payments/limits',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            GET_PAYMENTS_LIMITS_REQUEST,
            GET_PAYMENTS_LIMITS_SUCCESS,
            GET_PAYMENTS_LIMITS_FAILURE,
        ],
    },
});
