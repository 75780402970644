import React from 'react';
import LinearGradient from 'react-native-linear-gradient';
import { Text, View } from 'react-native';
import { rem } from 'design-system';
import { useCustomCategoryColors } from 'hooks/useCategory';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
const EmojiIcon = ({ children, style, color, }) => {
    const styles = useStyles(styleSet);
    const customCategoryColors = useCustomCategoryColors();
    const gradientColors = customCategoryColors.find((c) => c[0] === color);
    return (<View style={[styles.emojiContainer, style]}>
      {gradientColors?.[2] !== undefined && (<LinearGradient style={styles.gradient} colors={gradientColors[2]}/>)}
      <Text style={styles.emoji}>{children}</Text>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    emojiContainer: {
        height: rem(56),
        width: rem(56),
        backgroundColor: colors.cards.onLight,
        borderRadius: rem(28),
        alignItems: 'center',
        overflow: 'hidden',
        justifyContent: 'center',
    },
    emoji: {
        fontFamily: 'System',
        fontSize: rem(28),
        lineHeight: rem(28) * 1.4,
        color: colors.text.primary,
    },
    gradient: {
        width: '100%',
        height: '100%',
        position: 'absolute',
    },
}));
export default EmojiIcon;
