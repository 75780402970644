import { noop } from 'lodash';
import { Platform } from 'react-native';
import { runOnJS } from 'react-native-reanimated';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
const scrollEndHapticFeedbackOptions = {
    enableVibrateFallback: false,
    ignoreAndroidSystemSettings: false,
};
export const triggerOnScrollEndHapticFeedback = () => {
    if (Platform.OS === 'ios')
        ReactNativeHapticFeedback.trigger('impactLight', scrollEndHapticFeedbackOptions);
};
export const triggerSelectionHapticFeedback = () => {
    ReactNativeHapticFeedback.trigger('selection', scrollEndHapticFeedbackOptions);
};
export const triggerReleaseHapticFeedback = () => {
    ReactNativeHapticFeedback.trigger('keyboardRelease', scrollEndHapticFeedbackOptions);
};
export const triggerInputErrorHapticFeedback = () => {
    ReactNativeHapticFeedback.trigger('selection', scrollEndHapticFeedbackOptions);
};
export const triggerSelectorPress = Platform.select({
    ios: () => ReactNativeHapticFeedback.trigger('impactMedium'),
    default: noop,
});
export const triggerTimePeriodChange = () => {
    ReactNativeHapticFeedback.trigger(Platform.OS === 'ios' ? 'selection' : 'clockTick');
};
const chartSelectionImpact = () => {
    ReactNativeHapticFeedback.trigger(Platform.OS === 'ios' ? 'impactHeavy' : 'clockTick');
};
export const triggerChartSelectionFeedback = () => {
    'worklet';
    runOnJS(chartSelectionImpact)();
};
