import React, { useRef, useState, useCallback, useMemo } from 'react';
import { Animated, Pressable, } from 'react-native';
const TouchableScale = ({ finalScaleValue = 2, style, onPress, children, ...props }) => {
    const scaleValue = useRef(new Animated.Value(1));
    const [height, setHeight] = useState(0);
    const onPressIn = useCallback(() => {
        Animated.timing(scaleValue.current, {
            toValue: finalScaleValue,
            duration: 100,
            useNativeDriver: true,
        }).start();
    }, [finalScaleValue]);
    const onPressOut = useCallback(() => {
        Animated.timing(scaleValue.current, {
            toValue: 1,
            duration: 100,
            useNativeDriver: true,
        }).start();
    }, []);
    const onLayout = useCallback((event) => {
        setHeight(event.nativeEvent.layout.height);
    }, []);
    const animatedViewStyle = useMemo(() => ({
        transform: [
            { scale: scaleValue.current },
            {
                translateY: scaleValue.current.interpolate({
                    inputRange: [1, 2],
                    outputRange: [0, -height / 8],
                }),
            },
        ],
    }), [height]);
    return (<Pressable style={style} onPress={onPress} onPressIn={onPressIn} onPressOut={onPressOut} {...props}>
      <Animated.View onLayout={onLayout} style={animatedViewStyle}>
        {children}
      </Animated.View>
    </Pressable>);
};
export default TouchableScale;
