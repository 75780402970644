import React, { memo } from 'react';
import Svg from 'react-native-svg';
import { StyleSheet, View } from 'react-native';
import { rem } from 'design-system/values';
import Pie from './Pie';
import { getSmartPieSlice } from './utils';
import { isWeb } from '../../constants';
export const PIE_CHART_HEIGHT = rem(300);
const InteractivePieChart = ({ children, pieSlices, onSelectPieSlice, selectedPieSliceKey, }) => {
    const smartSlices = getSmartPieSlice(pieSlices, onSelectPieSlice);
    return (<View style={styles.container}>
      <Svg width={PIE_CHART_HEIGHT} height={PIE_CHART_HEIGHT} viewBox={`0 0 ${PIE_CHART_HEIGHT} ${PIE_CHART_HEIGHT}`}>
        {smartSlices.map((slice, index) => (<Pie key={slice.key} theta={slice.theta} sliceKey={slice.key} finalY={slice.finalY} finalX={slice.finalX} onPress={slice.onPress} category={slice.category} hideIcon={slice.hideIcon} fill={slice.selectedColor} labelTheta={slice.labelTheta} selected={selectedPieSliceKey} clipPathId={`${index}_clip`} polygonPoints={slice.polygonPoints}/>))}
      </Svg>
      <View style={styles.childrenContainer} pointerEvents="none">
        {children}
      </View>
    </View>);
};
const styles = StyleSheet.create({
    container: {
        alignSelf: 'center',
        width: PIE_CHART_HEIGHT,
        height: PIE_CHART_HEIGHT,
        ...(isWeb && {
            cursor: 'pointer',
        }),
    },
    childrenContainer: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
});
export default memo(InteractivePieChart);
