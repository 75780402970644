import { useState, useEffect } from 'react';
import { Keyboard } from 'react-native';
export const useKeyboard = (will) => {
    const [keyboardHeight, setKeyboardHeight] = useState(0);
    useEffect(() => {
        const didShowSubscription = Keyboard.addListener(will ? 'keyboardWillShow' : 'keyboardDidShow', keyboardDidShow);
        const didHideSubscription = Keyboard.addListener(will ? 'keyboardWillHide' : 'keyboardDidHide', keyboardDidHide);
        return () => {
            didShowSubscription.remove();
            didHideSubscription.remove();
        };
    }, [will]);
    const keyboardDidShow = (e) => setKeyboardHeight(e.endCoordinates.height);
    const keyboardDidHide = () => setKeyboardHeight(0);
    return keyboardHeight;
};
