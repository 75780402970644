import React, { useLayoutEffect } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { Indicator } from 'design-system/Indicator';
import { rem } from 'design-system/values';
import { SettingsSection } from 'design-system/SettingsSection';
import { SettingsItem } from 'design-system/SettingsItem';
import { AppView } from 'design-system/AppView';
import { IconRouting } from 'design-system/icons/IconRouting';
import { IconTwitter } from 'design-system/icons/IconTwitter';
import { IconFacebook } from 'design-system/icons/IconFacebook';
import { IconInstagram } from 'design-system/icons/IconInstagram';
import { IconTiktok } from 'design-system/icons/IconTiktok';
import { IconWork } from 'design-system/icons/IconWork';
import { IconCommunity } from 'design-system/icons/IconCommunity';
import { IconBlog } from 'design-system/icons/IconBlog';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import usePrivateRoute from 'hooks/usePrivateRoute';
import { useAppSelector } from 'store/hooks';
import Links from 'utils/links';
import { HelpButton } from './AccountSettingsScreen';
import { isWeb } from '../../../constants';
const strings = {
    sectionName: 'About us',
    twitter: {
        title: 'Follow us on X',
    },
    facebook: {
        title: 'Like us on Facebook',
    },
    instagram: {
        title: 'Follow us on Instagram',
    },
    tiktok: {
        title: 'Follow us on TikTok',
    },
    work: {
        title: 'Work at Emma',
    },
    roadMap: {
        title: 'Roadmap',
    },
    community: {
        title: 'Community',
    },
    blog: {
        title: 'Read our blog',
    },
};
export const AboutUs = withAnimatedNavigationHeader(({ navigation, onScroll }) => {
    usePrivateRoute();
    const userId = useAppSelector((state) => state.user.user.id);
    useLayoutEffect(() => {
        if (!isWeb) {
            navigation.setOptions({
                headerRight: () => <HelpButton />,
            });
        }
    }, []);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    if (userId === -1) {
        return (<AppView type="secondary" withFrame={false}>
          <Indicator dark/>
        </AppView>);
    }
    return (<AppView withFrame={false}>
        <ScrollView onScroll={onScroll} style={paddingHorizontalStyle} scrollEventThrottle={16}>
          <SettingsSection noMarginTop style={styles.marginTop}>
            <SettingsItem title={strings.twitter.title} icon={<IconTwitter />} onPress={Links.openEmmaTwitter}/>
            <SettingsItem title={strings.facebook.title} onPress={Links.openEmmaFacebook} icon={<IconFacebook />}/>
            <SettingsItem title={strings.instagram.title} onPress={Links.openEmmaInstagram} icon={<IconInstagram />}/>
            <SettingsItem title={strings.tiktok.title} onPress={Links.openEmmaTiktok} icon={<IconTiktok />}/>
          </SettingsSection>
          <SettingsSection noMarginTop style={styles.marginTop}>
            <SettingsItem title={strings.work.title} icon={<IconWork gradientKey="green"/>} onPress={Links.openEmmaJobs}/>
            <SettingsItem title={strings.roadMap.title} onPress={Links.openEmmaRoadMap} icon={<IconRouting />}/>
            <SettingsItem title={strings.community.title} onPress={Links.openEmmaCommunity} icon={<IconCommunity />}/>
            <SettingsItem title={strings.blog.title} icon={<IconBlog />} onPress={Links.openEmmaBlog}/>
          </SettingsSection>
        </ScrollView>
      </AppView>);
}, ({ colors }) => ({
    title: strings.sectionName,
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styles = StyleSheet.create({
    marginTop: {
        marginTop: rem(16),
    },
});
