export const removeEmptyAmountFilters = (activeFilter, selectedFilterType) => {
    if (!activeFilter || Object.keys(activeFilter).length === 0) {
        return activeFilter;
    }
    const cleanActiveFilter = { ...activeFilter };
    // if AMOUNT_LOWER selected remove empty AMOUNT_UPPER
    if ((!selectedFilterType || selectedFilterType === 'AMOUNT_LOWER') &&
        cleanActiveFilter.AMOUNT_UPPER &&
        !(Array.isArray(cleanActiveFilter.AMOUNT_UPPER) ||
            cleanActiveFilter.AMOUNT_UPPER.value)) {
        delete cleanActiveFilter.AMOUNT_UPPER;
    }
    // if AMOUNT_UPPER selected remove empty AMOUNT_LOWER
    if ((!selectedFilterType || selectedFilterType === 'AMOUNT_UPPER') &&
        cleanActiveFilter.AMOUNT_LOWER &&
        !(Array.isArray(cleanActiveFilter.AMOUNT_LOWER) ||
            cleanActiveFilter.AMOUNT_LOWER.value)) {
        delete cleanActiveFilter.AMOUNT_LOWER;
    }
    return cleanActiveFilter;
};
