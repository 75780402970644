import { createFetchReducer } from '../hooks/useFetchReduxed';
import { apiEvents } from '../features/pay/constants';
const payments = createFetchReducer('payments', apiEvents.paymentDefaultAccount[0], apiEvents.paymentDefaultAccount[1], apiEvents.paymentDefaultAccount[2], (payload) => {
    const { defaultAccount } = payload;
    if (defaultAccount)
        return {
            accountNumber: defaultAccount.accountNumber,
            sortCode: defaultAccount.sortCode,
        };
    return undefined;
}, false);
export default payments;
