import React from 'react';
import { View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Text from 'design-system/Text';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import TouchableBounce from 'utils/packages/TouchableBounce';
const toColors = ({ Brand, BrandGroup, Dark, TransparentOnDark, colorSet, }) => {
    switch (true) {
        case Boolean(Brand):
            return [colorSet.elements.brandLight, colorSet.elements.brandLight];
        case Boolean(BrandGroup):
            return [colorSet.buttons.brandGroup, colorSet.buttons.brandGroup];
        case Boolean(Dark):
            return [colorSet.background.dark, colorSet.background.dark];
        case Boolean(TransparentOnDark):
            return [
                colorSet.cards.transparentOnDark,
                colorSet.cards.transparentOnDark,
            ];
        default:
            return [colorSet.cards.onDark, colorSet.cards.onDark];
    }
};
export const Chip = ({ Brand, BrandGroup, BrandOutline, Dark, TransparentOnDark, text, id, type = 'squared', style, icon: Icon, onPress, containerStyle, noMarginEnd, ...props }) => {
    const chip = { id, text };
    const colorSet = useColors();
    const styles = useStyles(styleSet);
    return (<TouchableBounce onPress={() => onPress?.(chip)} style={style} {...props} onLayout={undefined}>
      <LinearGradient colors={toColors({
            Brand,
            BrandGroup,
            Dark,
            TransparentOnDark,
            colorSet,
        })} useAngle locations={[0, 1]} style={[
            styles.linearGradient,
            !noMarginEnd && styles.linearGradientMargin,
            type === 'squared' && styles.squared,
            type === 'squared-small' && styles.squaredSmall,
            type === 'rounded' && styles.rounded,
            BrandOutline && styles.outline,
        ]} onLayout={props.onLayout}>
        <View style={[
            type === 'squared-small' ? styles.containerSmall : styles.container,
            containerStyle,
        ]}>
          {Icon && (<Icon color={Brand ? colorSet.text.textOnBrand : colorSet.text.secondary} style={styles.iconStyle}/>)}
          {Brand || BrandGroup || BrandOutline ? (<Text Text-14 OnBrand>
              {text}
            </Text>) : (<Text Text-14 Gray>
              {text}
            </Text>)}
        </View>
      </LinearGradient>
    </TouchableBounce>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flexDirection: 'row',
        paddingLeft: rem(18),
        paddingRight: rem(16),
        paddingVertical: rem(10.5),
        alignItems: 'center',
        justifyContent: 'center',
    },
    containerSmall: {
        flexDirection: 'row',
        paddingLeft: rem(18),
        paddingRight: rem(16),
        paddingVertical: rem(6),
        alignItems: 'center',
        justifyContent: 'center',
    },
    linearGradient: {
        borderRadius: rem(12.5),
        borderWidth: rem(1),
        borderColor: 'transparent',
    },
    linearGradientMargin: {
        marginEnd: rem(8),
    },
    squared: {
        borderRadius: rem(12.5),
    },
    squaredSmall: {
        borderRadius: rem(8),
    },
    rounded: {
        borderRadius: rem(50),
    },
    iconStyle: {
        marginRight: rem(8),
    },
    outline: {
        borderColor: colors.text.brand,
    },
}));
