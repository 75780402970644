import moment from 'moment';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, } from 'react';
import { StyleSheet, View } from 'react-native';
import { resetInvestmentBalances, getAccountGoals } from 'actions';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import { AppView } from 'design-system/AppView';
import { Spacer } from 'design-system/Spacer';
import AlertView, { AlertButton } from 'design-system/AlertView';
import Button, { HEIGHT } from 'design-system/Button';
import IconPrediction from 'design-system/icons/IconPrediction';
import StatusBar from 'design-system/StatusBar';
import UpgradeCard from 'design-system/UpgradeCard';
import { rem } from 'design-system/values';
import AccountsHeaderRightButtons from 'features/accounts/components/header/AccountsHeaderRightButtons';
import { useRequiredBenefitCallbackWithSpaces } from 'features/premium/hooks/useRequiredBenefitCallback';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import { fetchCommittedForCurrentPaydayRange } from 'features/subscriptions/actions';
import useColors from 'hooks/useColors';
import { useMarginBottom } from 'hooks/useMarginBottom';
import { useOpenConnectionLogin } from 'hooks/useOpenConnectionLogin';
import { selectAccountById, selectCurrentPaydayRangeWithFallback, selectHasSetBudget, } from 'reducers/selectors';
import { useAppDispatch, useAppSelector, useAppStore } from 'store/hooks';
import StackHeader from 'design-system/hoc/StackHeader';
import OpenPotsAccountNotificationBanner from 'features/pots/components/OpenPotsAccountNotificationBanner';
import usePrivateRoute from 'hooks/usePrivateRoute';
import TransactionsList from '../../transactions/components/TransactionsList';
import AccountsInvestmentHeader from '../components/header/AccountInvestmentsHeader';
import AccountsHeader, { ACCOUNTS_HEADER_BOTTOM_HEIGHT, } from '../components/header/AccountsHeader';
import ConsolidateDebtBanner from '../components/header/ConsolidateDebtBanner';
import SmartRuleCard from '../components/SmartRuleCard';
import { isWeb } from '../../../constants';
import { selectUpcomingPayments } from '../selectors';
import useInvestmentGraphBalanceHistory from '../hooks/useInvestmentGraphBalanceHistory';
const AccountTransactionsScreen = withAnimatedNavigationHeader(({ navigation, route, scrollY }) => {
    usePrivateRoute();
    const dispatch = useAppDispatch();
    const store = useAppStore();
    const hasSetBudget = useAppSelector(selectHasSetBudget);
    const didViewTrueBalanceIntro = useAppSelector((state) => state.deviceSettings.didViewTrueBalanceIntro);
    useEffect(() => {
        if (!store.getState().accounts.loadedGoals) {
            dispatch(getAccountGoals());
        }
    }, []);
    const colors = useColors();
    const transactionsListRef = useRef(null);
    const data = useAppSelector((store) => selectAccountById(store, route.params.id));
    useLayoutEffect(() => {
        navigation.setOptions({
            ...(data &&
                (data.type === 'INVESTMENT' ||
                    (data.type === 'CRYPTO' && data.nativeBalance))
                ? {}
                : {
                    headerTitle: () => (<StackHeader width={200} title={data?.name || ''}/>),
                }),
            headerTintColor: colors.text.primary,
            headerRight: data
                ? () => (<AccountsHeaderRightButtons onPressEdit={transactionsListRef.current?.onEdit} isEditing={Boolean(route.params.isEditing)} account={data} navigation={navigation}/>)
                : undefined,
        });
    }, [colors.text.primary, data, route.params.isEditing]);
    useEffect(() => {
        const unsubscribe = navigation.addListener('beforeRemove', () => {
            dispatch(resetInvestmentBalances());
        });
        return unsubscribe;
    }, []);
    const investmentBalances = useAppSelector((store) => store.accounts.investmentBalances);
    const forceRefresh = useAppSelector((store) => store.expenses.forceRefresh);
    const previousForceRefresh = useRef(forceRefresh);
    const currentPaydayRange = useAppSelector(selectCurrentPaydayRangeWithFallback);
    const loadInvestmentGraphBalanceHistory = useInvestmentGraphBalanceHistory();
    useEffect(() => {
        if (data?.type === 'INVESTMENT' ||
            (data?.type === 'CRYPTO' && data?.nativeBalance)) {
            loadInvestmentGraphBalanceHistory(data.id);
        }
    }, [
        data?.type,
        data?.nativeBalance,
        data?.id,
        loadInvestmentGraphBalanceHistory,
    ]);
    const accountId = route.params.id || data?.id;
    const hasUpcoming = useAppSelector((store) => selectUpcomingPayments(store, accountId).length > 0);
    useEffect(() => {
        if (!hasUpcoming ||
            (forceRefresh && previousForceRefresh.current !== forceRefresh)) {
            dispatch(fetchCommittedForCurrentPaydayRange());
        }
        previousForceRefresh.current = forceRefresh;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPaydayRange, forceRefresh]);
    const addTransaction = useCallback(() => {
        if (data) {
            navigation.navigate('AddAmount', {
                data,
            });
        }
    }, [data]);
    const onScroll = useCallback((y) => {
        // eslint-disable-next-line no-param-reassign
        scrollY.value = y;
    }, [scrollY]);
    const { isUnlockedForSpace: isTrueBalanceUnlocked } = useShouldShowUpgrade({
        benefitId: 'trueBalance',
    });
    const onPressUpgradeTrueBalance = useRequiredBenefitCallbackWithSpaces(useCallback(() => {
        if (!isWeb && !didViewTrueBalanceIntro) {
            navigation.navigate('TrueBalanceIntro', {
                keyBack: route.key,
                id: route.params.id,
            });
            return;
        }
        if (hasSetBudget) {
            navigation.navigate('TrueBalance', { id: route.params.id });
            return;
        }
        navigation.navigate('EditBudget', {
            flow: 'Set',
            keyBack: route.key,
        });
    }, [didViewTrueBalanceIntro, hasSetBudget, route.key, route.params.id]), 'trueBalance');
    const openLogin = useOpenConnectionLogin(navigation);
    const didCloseSmartRulesCard = useAppSelector((store) => store.deviceSettings.didCloseSmartRulesCard);
    const header = useMemo(() => {
        if (!data) {
            return null;
        }
        return (<View style={[styles.headerWrapper]}>
            {data.type === 'INVESTMENT' ||
                (data.type === 'CRYPTO' && data.nativeBalance) ? (<AccountsInvestmentHeader data={data} investmentBalances={investmentBalances}/>) : (<AccountsHeader data={data}/>)}
            {isWeb ? null : (<>
                {data.type === 'CREDITCARD' && <ConsolidateDebtBanner />}
                {data.provider === 'MANUAL' && !didCloseSmartRulesCard && (<SmartRuleCard />)}
                {data.type === 'SAVINGS' && (<OpenPotsAccountNotificationBanner from="Transactions" noMarginBottom noMarginTop={false} buttonText="Continue"/>)}
              </>)}
            <View>
              {(!hasSetBudget || !isTrueBalanceUnlocked) && hasUpcoming && (<>
                  <Spacer h={16}/>
                  <UpgradeCard noMarginBottom btnText="Enable true balance" icon={<IconPrediction colorKey="brand"/>} title="See your true balance" description="Let us account for your upcoming transactions so you can see the actual available amount you can spend." onPressUpgrade={onPressUpgradeTrueBalance}/>
                </>)}
              {data.needsReauth || data.needsReconsent ? (<AlertView style={styles.alert} title="There's a problem with this account">
                  <AlertButton onPress={() => {
                    openLogin({ type: 'account', data });
                }}>
                    Let&apos;s fix this
                  </AlertButton>
                </AlertView>) : null}
            </View>
          </View>);
    }, [
        data,
        investmentBalances,
        didCloseSmartRulesCard,
        hasSetBudget,
        isTrueBalanceUnlocked,
        hasUpcoming,
        onPressUpgradeTrueBalance,
        openLogin,
    ]);
    const openSubscriptions = useRequiredBenefitCallbackWithSpaces(useCallback(() => {
        const toDate = currentPaydayRange.to;
        const fromDate = currentPaydayRange.from;
        navigation.navigate('CommittedList', {
            toDate: moment(toDate).format('YYYY-MM-DD'),
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
        });
    }, [currentPaydayRange.from, currentPaydayRange.to]), 'recurringPayments');
    const paddingBottom = useMarginBottom('paddingBottom', HEIGHT + rem(16));
    const showAddButton = data?.provider === 'MANUAL' && !isWeb;
    return (<AppView withFrame={false}>
          <StatusBar barStyle="dark-content" animated/>
          <TransactionsList ref={transactionsListRef} navigation={navigation} onScrollY={onScroll} ListHeaderComponent={header} accountId={route.params.id} from={route.params?.data?.from} to={route.params?.data?.to} openSubscriptions={openSubscriptions} contentContainerStyle={showAddButton ? paddingBottom : undefined}>
            {showAddButton && (<Button brand floating onPress={addTransaction} title="Add transaction"/>)}
          </TransactionsList>
        </AppView>);
}, ({ colors }) => ({
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
    hasCustomTitle: true,
}));
const styles = StyleSheet.create({
    alert: {
        marginTop: ACCOUNTS_HEADER_BOTTOM_HEIGHT,
        marginBottom: rem(16),
    },
    headerWrapper: {
        overflow: 'visible',
    },
});
export default AccountTransactionsScreen;
