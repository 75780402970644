import React, { useCallback, useLayoutEffect, useEffect, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { rem } from 'design-system/values';
import { AppView } from 'design-system/AppView';
import CardWithBadge from 'design-system/CardWithBadge';
import SectionHeader from 'design-system/SectionHeader';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import Button from 'design-system/Button';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import useSavePeriodAndNavigateToAnalytics from 'features/analytics/hooks/useSavePeriodAndNavigateToAnalytics';
import AccountHelpButton from 'features/invest/components/AccountHelpButton';
import { useRequiredBenefitCallbackWithSpaces } from 'features/premium/hooks/useRequiredBenefitCallback';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useMarginBottom } from 'hooks/useMarginBottom';
import Animated from 'react-native-reanimated';
import { Frequencies } from 'reducers/types';
import { getIncomes } from 'actions';
import Modal from 'utils/packages/Modal';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import EmptyComponent from 'design-system/EmptyComponent';
import { incomeToPeriod } from '../utils';
import { selectIncomes } from '../selectors';
import { isWeb } from '../../../constants';
const strings = {
    title: 'Select your period',
    description: 'Your budgeting and analytics will sync to this period.',
    modalTitle: 'Period',
    modalDescription: 'The period is the cycle you will use to sync your budgets and analytics. For example, if you get paid on the last working day of each month you can set your period to this so you can track your spending throughout your pay cycle.',
};
const options = [
    {
        label: 'First to last day of the month',
        value: Frequencies.Monthly,
        description: 'Your period will reset from first to last day of the month',
    },
    {
        label: 'Last working day of the month',
        value: Frequencies.LastWorkingDay,
        description: 'Your period will reset on the last working day of the month',
    },
    {
        label: 'Fixed monthly date',
        title: 'Select the date',
        value: Frequencies.FixedDate,
        description: 'Your period will reset on the exact date you choose',
        description2: 'This should include both your day to day spending and recurring payments.',
    },
    {
        label: 'Variable monthly date',
        title: 'Select the date',
        value: Frequencies.EveryMonth,
        description: 'Your period will reset every month on a specific day of the week',
        description2: 'This should include both your day to day spending and recurring payments.',
    },
    {
        label: 'Twice a month',
        title: 'Select 2 dates',
        value: Frequencies.TwiceAMonth,
        description: 'Your period will reset twice a month',
        description2: 'This should include both your day to day spending and recurring payments.',
    },
    {
        label: 'Every 4 weeks',
        title: 'Select date',
        value: Frequencies.EveryFourWeeks,
        description: 'Your period will reset every 4 weeks',
        description2: 'Select day of the week when you want your period to reset.',
    },
    {
        label: 'Every 2 weeks',
        title: 'Select date',
        value: Frequencies.EveryTwoWeeks,
        description: 'Your period will reset every 2 weeks',
        description2: 'Select day of the week when you want your period to reset.',
    },
    {
        label: 'Every week',
        title: 'Select date',
        value: Frequencies.Weekly,
        description: 'Your period will reset every week',
        description2: 'Select day of the week when you want your period to reset.',
    },
];
const empty = {};
const SelectPeriodScreen = withAnimatedNavigationHeader(({ navigation, scrollHandler, route }) => {
    const dispatch = useAppDispatch();
    useLayoutEffect(() => {
        navigation.setOptions(isWeb
            ? {
                headerTitle: EmptyComponent,
            }
            : {
                headerRight: () => <AccountHelpButton black/>,
            });
    }, []);
    useEffect(() => {
        dispatch(getIncomes());
    }, []);
    const { flow, monthlyBudget, budgetState } = route.params;
    const income = useAppSelector(selectIncomes);
    const currentPeriod = incomeToPeriod(income);
    const period = route.params.period || currentPeriod || empty;
    const [isFetching, save] = useSavePeriodAndNavigateToAnalytics();
    const { isUnlockedForSpace } = useShouldShowUpgrade({
        benefitId: 'budgeting',
    });
    const sortedOptions = useMemo(() => {
        if (!isUnlockedForSpace) {
            return options;
        }
        return [...options].sort((a, b) => {
            if (a.value === currentPeriod?.frequency)
                return -1;
            if (b.value === currentPeriod?.frequency)
                return 1;
            return 0;
        });
    }, [currentPeriod?.frequency, isUnlockedForSpace]);
    const onPressStandard = useCallback(async (frequency) => {
        if (isFetching)
            return;
        const newPeriod = { ...period, frequency };
        if (flow === 'SetAnalytics') {
            save(newPeriod);
        }
        else if (flow === 'Set') {
            navigation.navigate('Budgets', {
                flow,
                monthlyBudget,
                budgetState,
                period: newPeriod,
            });
        }
        else {
            navigation.navigate('EditBudget', {
                flow,
                budgetState,
                period: newPeriod,
            });
        }
    }, [budgetState, flow, isFetching, monthlyBudget, period, save]);
    const onPressFirstToLastDay = useCallback(() => {
        onPressStandard(options[0].value);
    }, [onPressStandard]);
    const onPressLastWorkingDay = useRequiredBenefitCallbackWithSpaces(useCallback(() => {
        onPressStandard(options[1].value);
    }, [onPressStandard]), 'budgeting');
    const onPressCustom = useRequiredBenefitCallbackWithSpaces(useCallback((option) => {
        if (isFetching)
            return;
        navigation.navigate('EditBudgetFrequency', {
            flow,
            monthlyBudget,
            budgetState,
            period,
            option,
            onSavePeriod: route.params.flow === 'SetAnalytics'
                ? (period) => {
                    save(period);
                }
                : undefined,
        });
    }, [
        budgetState,
        flow,
        isFetching,
        monthlyBudget,
        period,
        route.params.flow,
        save,
    ]), 'budgeting');
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const paddingBottom = useMarginBottom('paddingBottom', undefined, paddingHorizontalStyle);
    const getOnPressFunction = useCallback((option) => {
        switch (option.value) {
            case Frequencies.Monthly:
                return onPressFirstToLastDay;
            case Frequencies.LastWorkingDay:
                return onPressLastWorkingDay;
            default:
                return () => onPressCustom(option);
        }
    }, [onPressCustom, onPressFirstToLastDay, onPressLastWorkingDay]);
    const showModal = useCallback(() => {
        Modal.show(<>
          <Spacer h={8}/>
          <Text TextThin-14 Gray centered={!isWeb}>
            {strings.modalDescription}
          </Text>
          <Spacer h={40}/>
          <Button brand title="Got it" onPress={() => {
                Modal.hide(undefined, { top: true });
            }}/>
        </>, { title: strings.modalTitle, top: true });
    }, []);
    return (<AppView withFrame={false}>
        <Animated.ScrollView scrollEventThrottle={8} onScroll={scrollHandler} showsVerticalScrollIndicator={false} style={styles.container} contentContainerStyle={paddingBottom}>
          <View style={styles.headerContainer}>
            <Text Text-24 style={styles.title}>
              {strings.title}
            </Text>
            <Text TextThin-14 Gray>
              {strings.description}{' '}
              <Text Text-14 Primary onPress={showModal} suppressHighlighting>
                Learn more
              </Text>
            </Text>
          </View>

          {!isUnlockedForSpace && (<SectionHeader verticalMargins>Your plan</SectionHeader>)}
          <CardWithBadge key={sortedOptions[0].value} title={sortedOptions[0].label} description={sortedOptions[0].description} onPress={getOnPressFunction(sortedOptions[0])} preText={!isUnlockedForSpace ||
            sortedOptions[0].value === currentPeriod?.frequency
            ? 'CURRENT PERIOD'
            : undefined}/>

          {!isUnlockedForSpace && (<SectionHeader verticalMargins>Premium plans</SectionHeader>)}
          {sortedOptions.slice(1).map((option) => (<CardWithBadge key={option.value} title={option.label} description={option.description} onPress={getOnPressFunction(option)} preText={isUnlockedForSpace && option.value === currentPeriod?.frequency
                ? 'CURRENT PERIOD'
                : undefined}/>))}
        </Animated.ScrollView>
      </AppView>);
}, ({ colors }) => ({
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
    title: 'Select your period',
    hasCustomTitle: true,
}));
const styles = StyleSheet.create({
    container: {
        marginHorizontal: rem(16),
    },
    headerContainer: {
        paddingVertical: rem(16),
    },
    title: {
        marginBottom: rem(9),
    },
});
export default SelectPeriodScreen;
