import React from 'react';
import { useAppSelector } from 'store/hooks';
import { selectIsDefaultSpace } from 'features/spaces/reducers/selectors';
import { selectIsGBUser } from 'reducers/selectors';
import BankLoginsHelp from './BankLoginsHelp';
import StartSavingCard from './StartSavingCard';
import StartInvestingCard from './StartInvestingCard';
import { isWeb } from '../../../../constants';
const AccountOverviewFooter = ({ title, dataLength, }) => {
    const isGBUser = useAppSelector(selectIsGBUser);
    const isDefaultSpace = useAppSelector(selectIsDefaultSpace);
    const potsAccountStatus = useAppSelector((state) => state.pots.account?.status);
    const investAccountStatus = useAppSelector((state) => state.invest.account?.status);
    switch (title) {
        case 'Investments': {
            if (!isWeb &&
                isGBUser &&
                investAccountStatus !== 'ACTIVE' &&
                !!dataLength &&
                isDefaultSpace) {
                return (<>
            <StartInvestingCard />
            <BankLoginsHelp />
          </>);
            }
            return <BankLoginsHelp />;
        }
        case 'Savings': {
            if (!isWeb &&
                isGBUser &&
                potsAccountStatus !== 'ACTIVE' &&
                !!dataLength &&
                isDefaultSpace) {
                return (<>
            <StartSavingCard />
            <BankLoginsHelp />
          </>);
            }
            return <BankLoginsHelp />;
        }
        case 'Everyday': {
            return <BankLoginsHelp />;
        }
        default:
            return null;
    }
};
export default AccountOverviewFooter;
