import { useCallback } from 'react';
import AndroidKeyboardAdjust from 'rn-android-keyboard-adjust';
import useFocusEffect from './useFocusEffect';
export const useKeyboardAdjustPan = () => {
    useFocusEffect(useCallback(() => {
        AndroidKeyboardAdjust.setAdjustPan();
        return () => {
            // Default value, specified in AndroidManifest.xml
            AndroidKeyboardAdjust.setAdjustResize();
        };
    }, []));
};
