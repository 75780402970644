import { useMemo } from 'react';
import { useWindowDimensions } from 'react-native';
const getSidebarWidth = (width) => {
    if (width < 796) {
        return 0;
    }
    if (width < 1025) {
        return 220;
    }
    if (width < 1441) {
        return 260;
    }
    return 300;
};
/**
 * This will be updated later in another PR
 * just added this to make things more workable
 */
const useAppFrameDimensions = (isInTab) => {
    const { width, height } = useWindowDimensions();
    return useMemo(() => {
        const sidebarWidth = isInTab ? getSidebarWidth(width) : 0;
        const widthForContent = width - sidebarWidth;
        const shouldUseContentWidth = widthForContent < 600;
        const usableWidth = shouldUseContentWidth ? widthForContent : 600;
        const padding = shouldUseContentWidth
            ? 0
            : (widthForContent - usableWidth) / 2;
        const paddingHorizontalStyle = { paddingHorizontal: padding };
        return {
            height,
            sidebarWidth,
            width: usableWidth,
            paddingHorizontalStyle,
            paddingHorizontal: padding,
            contentWidth: widthForContent,
        };
    }, [height, isInTab, width]);
};
export default useAppFrameDimensions;
