import { Platform } from 'react-native';
import { CALL_API } from 'middleware/api';
import { VALIDATE_PURCHASE_FAILURE, VALIDATE_PURCHASE_REQUEST, VALIDATE_PURCHASE_SUCCESS, } from './promotions';
export const validatePurchase = (params) => {
    const endpoint = Platform.OS === 'ios'
        ? '/in-app-purchases/ios'
        : '/in-app-purchases/android';
    return {
        [CALL_API]: {
            endpoint,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
            types: [
                VALIDATE_PURCHASE_REQUEST,
                VALIDATE_PURCHASE_SUCCESS,
                VALIDATE_PURCHASE_FAILURE,
            ],
        },
    };
};
