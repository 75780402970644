import { setClientId, getClientId as retrieveClientId } from './jwtHelpers';
// eslint-disable-next-line import/no-extraneous-dependencies
const uuidV4 = require('uuid/v4');
export const getClientId = async () => {
    let client_id = null;
    try {
        client_id = await retrieveClientId();
    }
    catch (error) {
        // No op
    }
    if (!client_id) {
        client_id = uuidV4();
        setClientId(client_id);
    }
    return client_id;
};
