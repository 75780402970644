import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
const IconReceipt = (props) => {
    const id = useId();
    return (<IconWithGradient viewBox="0 0 24 24" id={id} width={24} height={24} defaultGradientKey="peach" {...props}>
      <Path fill={`url(#gradient_${id})`} d="M12.785 20.501a4.83 4.83 0 0 1-3.482-1.368 5.026 5.026 0 0 1-1.518-3.476V7.013A3.636 3.636 0 0 1 8.88 4.5a3.496 3.496 0 0 1 2.514-.996 3.495 3.495 0 0 1 2.52.992 3.637 3.637 0 0 1 1.097 2.518v8.652a2.289 2.289 0 0 1-.696 1.514 2.202 2.202 0 0 1-1.525.617c-.566 0-1.111-.22-1.524-.617a2.288 2.288 0 0 1-.697-1.514v-7.98c0-.226.088-.442.245-.601a.825.825 0 0 1 1.178 0c.156.16.244.375.244.6v7.981a.573.573 0 0 0 .184.357.552.552 0 0 0 .74 0 .573.573 0 0 0 .184-.357V7.013a1.928 1.928 0 0 0-.61-1.313 1.854 1.854 0 0 0-1.34-.497 1.854 1.854 0 0 0-1.334.5c-.363.338-.581.81-.607 1.31v8.644a3.308 3.308 0 0 0 1.03 2.274 3.18 3.18 0 0 0 2.303.87 3.18 3.18 0 0 0 2.304-.87 3.309 3.309 0 0 0 1.03-2.274V7.013c0-.225.087-.441.244-.6a.825.825 0 0 1 1.178 0c.156.159.244.375.244.6v8.644a5.026 5.026 0 0 1-1.518 3.476 4.83 4.83 0 0 1-3.482 1.368Z"/>
    </IconWithGradient>);
};
export default IconReceipt;
