export const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_FAILURE = 'GET_REPORT_FAILURE';
export const DISMISS_REPORT_REQUEST = 'DISMISS_REPORT_REQUEST';
export const DISMISS_REPORT_SUCCESS = 'DISMISS_REPORT_SUCCESS';
export const DISMISS_REPORT_FAILURE = 'DISMISS_REPORT_FAILURE';
export const ON_REPORT_FINISHED_REQUEST = 'ON_REPORT_FINISHED_REQUEST';
export const ON_REPORT_FINISHED_SUCCESS = 'ON_REPORT_FINISHED_SUCCESS';
export const ON_REPORT_FINISHED_FAILURE = 'ON_REPORT_FINISHED_FAILURE';
export const ON_REPORT_OPENED_REQUEST = 'ON_REPORT_OPENED_REQUEST';
export const ON_REPORT_OPENED_SUCCESS = 'ON_REPORT_OPENED_SUCCESS';
export const ON_REPORT_OPENED_FAILURE = 'ON_REPORT_OPENED_FAILURE';
export const ON_REPORT_SWIPED_REQUEST = 'ON_REPORT_SWIPED_REQUEST';
export const ON_REPORT_SWIPED_SUCCESS = 'ON_REPORT_SWIPED_SUCCESS';
export const ON_REPORT_SWIPED_FAILURE = 'ON_REPORT_SWIPED_FAILURE';
