import moment from 'moment';
import { put, select, takeEvery } from 'typed-redux-saga/macro';
import { REFRESH_ON_START } from 'actions/types';
import { getRentReporting } from '../actions';
import { selectCurrentRentReportingState } from '../selectors';
function* refreshRentReportingSaga() {
    const currentRentReportingData = yield* select(selectCurrentRentReportingState);
    if (currentRentReportingData?.nextPaymentDate &&
        moment(currentRentReportingData.nextPaymentDate).isBefore(moment())) {
        yield* put(getRentReporting({ withRentTransactions: false, isActive: true }));
    }
}
function* rentReportingSaga() {
    yield* takeEvery((action) => [REFRESH_ON_START].includes(action.type), refreshRentReportingSaga);
}
export default rentReportingSaga;
