import { CALL_API } from 'middleware/api';
import * as types from './types';
const BASE_URL = '/reverse-trial';
export const reverseTrialActivate = () => ({
    [CALL_API]: {
        endpoint: `${BASE_URL}/activate`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.REVERSE_TRIAL_ACTIVATE_REQUEST,
            types.REVERSE_TRIAL_ACTIVATE_SUCCESS,
            types.REVERSE_TRIAL_ACTIVATE_FAILURE,
        ],
    },
});
export const reverseTrialCancel = () => ({
    [CALL_API]: {
        endpoint: `${BASE_URL}/cancel`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.REVERSE_TRIAL_CANCEL_REQUEST,
            types.REVERSE_TRIAL_CANCEL_SUCCESS,
            types.REVERSE_TRIAL_CANCEL_FAILURE,
        ],
    },
});
export const reverseTrialDeleteFunnelEligbility = () => ({
    [CALL_API]: {
        endpoint: `${BASE_URL}/delete-funnel/eligibility`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.REVERSE_TRIAL_DELETE_FUNNEL_ELIGBILITY_REQUEST,
            types.REVERSE_TRIAL_DELETE_FUNNEL_ELIGBILITY_SUCCESS,
            types.REVERSE_TRIAL_DELETE_FUNNEL_ELIGBILITY_FAILURE,
        ],
    },
});
export const reverseTrialDeleteFunnelStart = () => ({
    [CALL_API]: {
        endpoint: `${BASE_URL}/delete-funnel/start`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.REVERSE_TRIAL_DELETE_FUNNEL_START_REQUEST,
            types.REVERSE_TRIAL_DELETE_FUNNEL_START_SUCCESS,
            types.REVERSE_TRIAL_DELETE_FUNNEL_START_FAILURE,
        ],
    },
});
export const reverseTrialAcknowledgeStart = () => ({
    [CALL_API]: {
        endpoint: `${BASE_URL}/acknowledge-start`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.REVERSE_TRIAL_ACKNOWLEDGE_START_REQUEST,
            types.REVERSE_TRIAL_ACKNOWLEDGE_START_SUCCESS,
            types.REVERSE_TRIAL_ACKNOWLEDGE_START_FAILURE,
        ],
    },
});
export const reverseTrialAcknowledgeEnd = () => ({
    [CALL_API]: {
        endpoint: `${BASE_URL}/acknowledge-end`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.REVERSE_TRIAL_ACKNOWLEDGE_END_REQUEST,
            types.REVERSE_TRIAL_ACKNOWLEDGE_END_SUCCESS,
            types.REVERSE_TRIAL_ACKNOWLEDGE_END_FAILURE,
        ],
    },
});
export const reverseTrialConversionEvent = () => ({
    [CALL_API]: {
        endpoint: `${BASE_URL}/conversion-event`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.REVERSE_TRIAL_CONVERSION_EVENT_REQUEST,
            types.REVERSE_TRIAL_CONVERSION_EVENT_SUCCESS,
            types.REVERSE_TRIAL_CONVERSION_EVENT_FAILURE,
        ],
    },
});
