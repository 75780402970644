import React from 'react';
import { useRoute } from '@react-navigation/native';
import IconRotate from 'design-system/icons/IconRotate';
import AlertView, { AlertButton } from 'design-system/AlertView';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { isWeb } from '../../../constants';
const SetupBudgetView = () => {
    const route = useRoute();
    const navigation = useMainStackNavigation();
    const onPressSetup = () => {
        navigation.navigate('EditBudget', {
            flow: 'Set',
            keyBack: route.key,
        });
    };
    return (<AlertView type="info" title="Set up budgeting" icon={<IconRotate />} subTitle="Track your spending and save more, set up a budget.">
      {!isWeb && <AlertButton onPress={onPressSetup}>Set up</AlertButton>}
    </AlertView>);
};
export default React.memo(SetupBudgetView);
