import useFetch, { muteErrorAndResult } from 'hooks/useFetch';
import { useCallback, useMemo } from 'react';
import { groupBy, isEmpty, sortBy } from 'lodash';
import Amplitude from 'utils/amplitude';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import Links from 'utils/links';
import { getRewards, getRewardLink } from 'actions';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { formatAmount } from 'utils/formatting';
import { RewardTypes } from 'reducers/types';
import { useMainStackNavigation } from 'utils/types/navigationV6';
const rewardsSelector = (store) => store.rewards.rewards;
const byType = (reward) => reward.type;
export const useRewards = (searchWords) => {
    const [, , isRewardsFetching, fetch] = useFetch(muteErrorAndResult);
    const rewards = useAppSelector(rewardsSelector);
    const navigation = useMainStackNavigation();
    const featuredRewards = useMemo(() => rewards.filter((r) => r.isFeatured), [rewards]);
    const topOfferRewards = useMemo(() => rewards.filter((r) => r.isTopOffer), [rewards]);
    const newRewards = useMemo(() => rewards.slice().sort((a, b) => {
        if (!b.createdAt)
            return 1;
        if (!a.createdAt)
            return -1;
        return (new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    }), [rewards]);
    const sortedByTypeRewards = useMemo(() => sortBy(rewards, byType), [rewards]);
    const normalizedByTypeRewards = useMemo(() => ({
        ...groupBy(sortedByTypeRewards, byType),
        [RewardTypes.Popular]: featuredRewards,
        [RewardTypes.TopOffers]: topOfferRewards,
        [RewardTypes.NewOffers]: newRewards,
    }), [featuredRewards, newRewards, sortedByTypeRewards, topOfferRewards]);
    const normalizedByTypeRewardsSliced = useMemo(() => {
        const slicedMap = {};
        Object.keys(normalizedByTypeRewards).forEach((rewardType) => {
            if (rewardType === RewardTypes.TopOffers) {
                slicedMap[rewardType] = normalizedByTypeRewards[rewardType];
            }
            else {
                slicedMap[rewardType] = normalizedByTypeRewards[rewardType].slice(0, 10);
            }
        });
        return slicedMap;
    }, [normalizedByTypeRewards]);
    const filteredBySearchRewards = useMemo(() => {
        if (!searchWords) {
            return sortedByTypeRewards;
        }
        const keywords = searchWords
            .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, ' ')
            .split(' ');
        return sortedByTypeRewards.filter((reward) => keywords.every((word) => reward.partner.name.toLowerCase().match(word.toLowerCase()) ||
            reward.title.toLowerCase().match(word.toLowerCase())));
    }, [searchWords, sortedByTypeRewards]);
    const fetchRewards = useCallback(() => fetch(getRewards()), []);
    const openReward = useCallback((reward) => {
        navigation.push('Reward', { reward });
    }, []);
    const { isUnlocked, onPressUpgrade } = useShouldShowUpgrade({
        benefitId: 'cashback',
    });
    const dispatch = useAppDispatch();
    const openRewardLink = useCallback((reward, eventName) => {
        if (!reward)
            return;
        const { id } = reward;
        Amplitude.logEvent(eventName, { reward });
        if (!isUnlocked) {
            onPressUpgrade();
            return;
        }
        Promise.resolve(dispatch(getRewardLink(id))).then((response) => {
            if (response && response.payload) {
                Links.openLink(response.payload.url);
            }
            return response;
        });
    }, [isUnlocked, onPressUpgrade]);
    return {
        fetchRewards,
        featuredRewards,
        isRewardsFetching,
        hasRewards: Boolean(rewards.length),
        normalizedByTypeRewards,
        normalizedByTypeRewardsSliced,
        filteredBySearchRewards,
        sortedByTypeRewards,
        rewards,
        openReward,
        openRewardLink,
    };
};
export const filterRewardsBy = ({ rewards, isFeatured, isTopOffer, rewardTypes, }) => {
    if (isEmpty(rewardTypes) &&
        isTopOffer === undefined &&
        isFeatured === undefined) {
        return rewards;
    }
    const shouldCheckRewardTypes = !isEmpty(rewardTypes);
    return rewards.filter((r) => {
        const hasInTypes = shouldCheckRewardTypes
            ? rewardTypes.includes(r.type)
            : true;
        const isFeaturedCheck = isFeatured ? r.isFeatured : true;
        const isTopRewardCheck = isTopOffer ? r.isTopOffer : true;
        return hasInTypes && isFeaturedCheck && isTopRewardCheck;
    });
};
export const getRewardTextToShow = (reward) => {
    if (!reward)
        return null;
    let rewardToShow = null;
    if (reward.rewardAmount)
        rewardToShow = `${formatAmount(reward.rewardAmount, reward.currency)} cashback`;
    if (reward.userCashbackPercent) {
        rewardToShow = `${reward.userCashbackPercent}% cashback`;
        if (reward.canRewardBeBoosted)
            rewardToShow = `${reward.userCashbackPercent * 2}% cashback`;
    }
    if (!rewardToShow)
        rewardToShow = reward.rewardText;
    return rewardToShow;
};
export const getPercentRewardTextToShow = (reward) => {
    let rewardToShow = null;
    if (reward.rewardAmount)
        rewardToShow = formatAmount(reward.rewardAmount, reward.currency);
    if (reward.userCashbackPercent) {
        rewardToShow = `${reward.userCashbackPercent}%`;
        if (reward.canRewardBeBoosted)
            rewardToShow = `${reward.userCashbackPercent * 2}%`;
    }
    if (!rewardToShow)
        rewardToShow = reward.rewardText;
    return rewardToShow;
};
export const getOnlyPercentRewardTextToShow = (reward) => {
    try {
        const regex = /^[0-9]*(.[0-9]{0,2})?%/;
        const match = reward.rewardTextToShow?.match(regex);
        return match && Array.isArray(match) && match.length ? match[0] : null;
    }
    catch {
        return null;
    }
};
