import React, { useId, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import Animated, { interpolate, runOnJS, useAnimatedReaction, useDerivedValue, } from 'react-native-reanimated';
import { Circle, ClipPath, Defs, ForeignObject, Line, Polygon, } from 'react-native-svg';
import useColors from 'hooks/useColors';
import { rem } from 'design-system/values';
import { CategoryLabel } from './CategoryLabel';
import { getLabelPosition } from './utils';
const AnimatedCircle = Animated.createAnimatedComponent(Circle);
const [pressed, idle, active] = [0, 0.5, 1];
const inputRange = [pressed, idle, active];
const PIE_RADIUS = rem(150);
const RADIUS_OUTPUT_RANGE = [rem(115), rem(125), rem(130)];
const Pie = ({ fill, theta, finalX, finalY, onPress, selected, sliceKey, category, hideIcon, labelTheta, clipPathId, polygonPoints, }) => {
    const colors = useColors();
    const fillColor = useMemo(() => fill || colors.elements.brand, [colors.elements.brand, fill]);
    const isSelected = useDerivedValue(() => !!(selected && selected.value === sliceKey), []);
    const [selectedState, setSelectedState] = useState(false);
    useAnimatedReaction(() => isSelected, (selected) => {
        runOnJS(setSelectedState)(selected.value);
    }, [isSelected]);
    const labelPosOutputRange = useMemo(() => {
        const { left, top } = getLabelPosition(labelTheta, rem(94));
        return {
            top,
            left,
        };
    }, [labelTheta]);
    const id = useId();
    return (<>
      <Defs>
        <ClipPath id={`${id}_${clipPathId}`}>
          <Polygon points={polygonPoints}/>
        </ClipPath>
      </Defs>
      <AnimatedCircle id={`${id}_circle`} cx="50%" cy="50%" 
    //@ts-ignore
    onClick={onPress} fill={selectedState ? fillColor : colors.background.dark} r={interpolate(selectedState ? active : idle, inputRange, RADIUS_OUTPUT_RANGE)} 
    // onPressOut={onPressOut}
    clipPath={`url(#${id}_${clipPathId})`}/>
      {theta !== 360 && (<Line id={`${id}_line`} x2={finalX} y2={finalY} x1={PIE_RADIUS} y1={PIE_RADIUS} strokeWidth={rem(8)} stroke={colors.cards.onDark}/>)}
      {!hideIcon && theta >= 30 && (<ForeignObject x={labelPosOutputRange.left} y={labelPosOutputRange.top} width="30" height="30" pointerEvents="none">
          <View pointerEvents="none" style={[styles.labelStyles]}>
            {('currency' in category || 'sector' in category) && (<CategoryLabel sliceKey={sliceKey} category={category} selected={selected}/>)}
          </View>
        </ForeignObject>)}
    </>);
};
const styles = StyleSheet.create({
    labelStyles: {
        width: 30,
        height: 30,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
export default Pie;
