/**
 * Reducer for holding transfer details.
 *
 * This reducer should not be persisted.
 *
 * If we decide to make it persist between app sessions, please move it to invest.
 */
import { LOGGED_OUT } from 'actions/types';
import { assertUnreachable } from 'utils/types';
const initialState = {
    transferDetails: undefined,
};
const transferDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_TRANSFER_DETAILS_SUCCESS': {
            return {
                ...state,
                transferDetails: action.payload,
            };
        }
        case LOGGED_OUT: {
            return initialState;
        }
        default:
            assertUnreachable(action);
            return state;
    }
};
export default transferDetailsReducer;
