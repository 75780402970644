import React, { useId } from 'react';
import useColors from 'hooks/useColors';
import IconEmojiCategoryActivity from './IconEmojiCategoryActivity';
import IconEmojiCategoryAnimalsAndNature from './IconEmojiCategoryAnimalsAndNature';
import IconEmojiCategoryFlags from './IconEmojiCategoryFlags';
import IconEmojiCategoryFoodAndDrink from './IconEmojiCategoryFoodAndDrink';
import IconEmojiCategoryObjects from './IconEmojiCategoryObjects';
import IconEmojiCategoryPlaces from './IconEmojiCategoryPlaces';
import IconEmojiCategorySmileysAndPeople from './IconEmojiCategorySmileysAndPeople';
import IconEmojiCategorySymbols from './IconEmojiCategorySymbols';
const IconEmojiCategory = ({ category, selected, ...props }) => {
    const colors = useColors();
    const id = useId();
    const newProps = {
        ...props,
        id,
        color: selected ? undefined : colors.text.secondary,
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
    };
    switch (category) {
        case 'Activities':
            return <IconEmojiCategoryActivity {...newProps}/>;
        case 'Flags':
            return (<IconEmojiCategoryFlags {...newProps} width={11} height={15} viewBox="0 0 11 15"/>);
        case 'Food & Drink':
            return <IconEmojiCategoryFoodAndDrink {...newProps}/>;
        case 'Animals & Nature':
            return <IconEmojiCategoryAnimalsAndNature {...newProps}/>;
        case 'Objects':
            return (<IconEmojiCategoryObjects {...newProps} width={12} height={16} viewBox="0 0 12 16"/>);
        case 'Smileys & Emotion':
            return <IconEmojiCategorySmileysAndPeople {...newProps}/>;
        case 'Travel & Places':
            return <IconEmojiCategoryPlaces {...newProps}/>;
        case 'Symbols':
            return <IconEmojiCategorySymbols {...newProps}/>;
        default:
            return null;
    }
};
export default IconEmojiCategory;
