import { LOGGED_OUT } from 'actions/types';
import { deduplicateWithKey } from 'utils';
export const initialState = {
    billingHistory: [],
    page: undefined,
    requestingHistory: false,
};
const sortWithDate = (b, a) => new Date(a.date).getTime() - new Date(b.date).getTime();
const billingHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGGED_OUT: {
            return initialState;
        }
        case 'GET_BILLING_HISTORY_REQUEST': {
            return {
                ...state,
                requestingHistory: true,
                page: action.extra.page,
            };
        }
        case 'GET_BILLING_HISTORY_SUCCESS': {
            if (action.payload.page === state.page) {
                if (action.payload.page === 1) {
                    return {
                        ...state,
                        requestingHistory: false,
                        billingHistory: action.payload.invoices,
                        page: action.payload.invoices.length < 20
                            ? null
                            : action.payload.page + 1,
                    };
                }
                return {
                    ...state,
                    requestingHistory: false,
                    billingHistory: deduplicateWithKey([...action.payload.invoices, ...(state.billingHistory || [])], 'id').sort(sortWithDate),
                    page: action.payload.invoices.length < 20
                        ? null
                        : action.payload.page + 1,
                };
            }
            return state;
        }
        case 'GET_BILLING_HISTORY_FAILURE': {
            return {
                ...state,
                requestingHistory: false,
            };
        }
        default: {
            return state;
        }
    }
};
export default billingHistoryReducer;
