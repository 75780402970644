import { createSelectorCreator } from 'reselect';
const identity = (func) => func;
/**
 * Use this selector to use reslect composition syntax, but without any transformation logic
 * See https://reselect.js.org/faq/#what-if-i-want-to-use-createselector-without-memoization
 */
export const createNonMemoizedSelector = createSelectorCreator({
    memoize: identity,
    argsMemoize: identity,
});
