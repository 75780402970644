import React, { useContext } from 'react';
import { StyleSheet, } from 'react-native';
import { rem } from 'design-system/values';
import DesignSystemSection from 'design-system/Section';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { WalkthroughContext } from './walkthrough/context';
const Section = ({ style, title, button, onLayout, children, withFrame = true, }) => {
    const { paddingHorizontalStyle } = useAppFrameDimensions(true);
    const isInWalkthrough = useContext(WalkthroughContext);
    const frameValue = withFrame && !isInWalkthrough;
    return (<DesignSystemSection style={[styles.container, frameValue && paddingHorizontalStyle, style]} titleContainerStyle={!frameValue && !isInWalkthrough && paddingHorizontalStyle} title={title} button={button} onLayout={onLayout}>
      {children}
    </DesignSystemSection>);
};
const styles = StyleSheet.create({
    container: {
        marginBottom: rem(24),
    },
});
export default Section;
