import React from 'react';
import emoji from 'node-emoji';
import { CommonActions } from '@react-navigation/native';
import IconScissors from 'design-system/icons/IconScissors';
import { SettingsItemNavigate } from 'design-system/SettingsItem';
import Alert from 'utils/packages/Alert';
import { muteAll, useFetchOld } from 'hooks/useFetch';
import { unsplitTransaction } from 'actions/transactions';
import { useMainStackNavigation } from 'utils/types/navigationV6';
const TransactionSplitItem = ({ transaction }) => {
    const [, , , fetch] = useFetchOld(muteAll);
    const navigation = useMainStackNavigation();
    const onSplitTransaction = () => {
        if (!('splitFromId' in transaction)) {
            return;
        }
        const { splitFromId } = transaction;
        if (transaction.isPending) {
            Alert.alert(emoji.emojify('Hey, slow down :face_with_rolling_eyes:'), "Pending transactions like to change, so we can't edit them yet. Don't worry, they don't count in Analytics.");
        }
        else if (splitFromId) {
            Alert.alert(emoji.emojify('Hey, slow down :face_with_rolling_eyes:'), 'Are you sure you want to unsplit this transaction? It will revert back to the original.', [
                {
                    text: 'No',
                    onPress: () => { },
                    style: 'cancel',
                },
                {
                    text: 'Yes',
                    onPress: () => {
                        fetch(unsplitTransaction(splitFromId));
                        if (navigation.canGoBack()) {
                            navigation.goBack();
                        }
                        else {
                            navigation.dispatch(CommonActions.reset({
                                index: 0,
                                routes: [{ name: 'Feed' }],
                            }));
                        }
                    },
                },
            ]);
        }
        else {
            navigation.navigate('SplitTransaction', {
                id: transaction.id,
            });
        }
    };
    return (<SettingsItemNavigate icon={<IconScissors />} titleFirst title={'splitFromId' in transaction && transaction?.splitFromId
            ? 'Unsplit this transaction'
            : 'Split this transaction'} disabled={Boolean('isCompact' in transaction && transaction.isCompact)} description={'splitFromId' in transaction && transaction?.splitFromId
            ? undefined
            : 'Assign it to more than one category'} onPress={onSplitTransaction}/>);
};
export default TransactionSplitItem;
