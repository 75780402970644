import { useMemo } from 'react';
import { Platform } from 'react-native';
import { CardStyleInterpolators, } from '@react-navigation/stack';
import EmptyComponent from 'design-system/EmptyComponent';
import useColors from 'hooks/useColors';
import normalize from 'common/Normalize';
import { isAndroid } from '../../constants';
export const useNativeStackScreenOptions = () => {
    const colors = useColors();
    const screenOptions = useMemo(() => ({
        title: '',
        headerTransparent: true,
        headerTitleAlign: 'center',
        headerShadowVisible: false,
        headerBackTitleVisible: false,
        headerTintColor: colors.elements.white,
        headerTitleStyle: {
            fontFamily: 'Montserrat-Regular',
            fontSize: normalize(14.4),
            lineHeight: normalize(14.4) * 1.1,
            fontWeight: null,
            textAlign: 'center',
            marginHorizontal: 8,
        },
        headerStyle: {
            backgroundColor: 'transparent',
        },
        contentStyle: {
            backgroundColor: colors.background.dark,
        },
        animation: Platform.OS === 'ios' ? 'default' : 'fade_from_bottom',
        headerBackButtonMenuEnabled: false,
        fullScreenGestureEnabled: true,
    }), [colors.background.dark, colors.elements.white]);
    return screenOptions;
};
export const cardStyleInterpolatorForScreen = Platform.OS === 'android'
    ? CardStyleInterpolators.forFadeFromBottomAndroid
    : CardStyleInterpolators.forHorizontalIOS;
export const cardStyleInterpolatorForModal = Platform.OS === 'android'
    ? CardStyleInterpolators.forFadeFromBottomAndroid
    : CardStyleInterpolators.forVerticalIOS;
export const cardOptions = {
    presentation: 'card',
    cardStyleInterpolator: cardStyleInterpolatorForScreen,
};
export const modalOptions = {
    headerLeft: EmptyComponent,
    presentation: 'modal',
    cardStyleInterpolator: cardStyleInterpolatorForModal,
};
export const nativeModalOptions = {
    headerLeft: EmptyComponent,
    animation: 'slide_from_bottom',
    gestureDirection: 'vertical',
};
export const iOS13nativeModalOptions = {
    presentation: 'modal',
    headerLeft: EmptyComponent,
    gestureDirection: 'vertical',
    animation: isAndroid ? 'slide_from_bottom' : 'default',
};
const forModal = () => ({
    cardStyle: {
        opacity: 1,
    },
});
const openConfig = {
    animation: 'timing',
    config: {
        duration: 300,
    },
};
const closeConfig = {
    animation: 'timing',
    config: {
        duration: 150,
    },
};
export const searchScreenTransitionSpec = {
    open: openConfig,
    close: closeConfig,
};
export const searchScreenCardInterpolator = () => ({
    cardStyle: {
        opacity: 1,
    },
});
export const transparentModalOptions = {
    headerLeft: EmptyComponent,
    presentation: 'transparentModal',
    cardStyleInterpolator: forModal,
};
export const transparentNativeModalOptions = {
    headerLeft: EmptyComponent,
    animation: 'fade',
    presentation: 'containedTransparentModal',
    contentStyle: { backgroundColor: '#00000090' },
};
