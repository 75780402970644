import React, { memo, useContext } from 'react';
import { View } from 'react-native';
import WeeklyContent from './WeeklyContent';
import MonthlyContent from './MonthlyContent';
import QuarterlyContent from './QuarterlyContent';
import YearlyContent from './YearlyContent';
import CustomContent from './CustomContent';
import { DateFilterContext } from './context';
import { options } from './constants';
const Controller = () => {
    const { period } = useContext(DateFilterContext);
    const renderContent = () => {
        switch (period) {
            case options[0]:
                return <WeeklyContent />;
            case options[1]:
                return <MonthlyContent />;
            case options[2]:
                return <QuarterlyContent />;
            case options[3]:
                return <YearlyContent />;
            case options[5]:
                return <CustomContent />;
            default:
                return null;
        }
    };
    return <View>{renderContent()}</View>;
};
export default memo(Controller);
