import React from 'react';
import { StyleSheet, View } from 'react-native';
import Separator from './Separator';
import { rem } from './values';
export const SeparatedList = ({ children, style, margins = true, }) => {
    const newChildren = React.Children.toArray(children).map((child, index, arr) => (<React.Fragment key={typeof child === 'object' && 'key' in child
            ? child.key || undefined
            : undefined}>
        {child}
        {index < arr.length - 1 && (<Separator style={[margins && styles.separator]}/>)}
      </React.Fragment>));
    return <View style={style}>{newChildren}</View>;
};
const styles = StyleSheet.create({
    separator: {
        marginHorizontal: rem(16),
    },
});
