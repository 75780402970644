import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconEyes = ({ size = 24, ...props }) => {
    const id = useId();
    return (<IconWithGradient id={id} width={size} height={size} defaultGradientKey="pro" {...props}>
      <Path d="M15.9563 6.3457C14.3191 6.3457 12.8737 7.38396 12 8.96841C11.1263 7.38396 9.68116 6.3457 8.04369 6.3457C5.37317 6.3457 3.20819 9.10139 3.20819 12.5C3.20819 15.8986 5.37317 18.6542 8.04369 18.6542C9.68089 18.6542 11.1263 17.616 12 16.0315C12.8737 17.6157 14.3188 18.6542 15.9563 18.6542C18.6268 18.6542 20.7918 15.8986 20.7918 12.5C20.7918 9.10139 18.6268 6.3457 15.9563 6.3457ZM8.04369 16.8959C7.05708 16.8959 6.16938 16.2063 5.60176 15.1131C5.68226 15.1238 5.76221 15.1375 5.84573 15.1375C6.81695 15.1375 7.6041 14.3504 7.6041 13.3792C7.6041 12.4079 6.81558 11.6208 5.84573 11.6208C5.51879 11.6208 5.26328 11.7032 5.01491 11.8378C5.24707 9.75802 6.53259 8.10407 8.04369 8.10407C9.71138 8.10407 11.1208 10.1179 11.1208 12.5C11.1208 14.882 9.71138 16.8959 8.04369 16.8959ZM15.9563 16.8959C14.9697 16.8959 14.082 16.2063 13.5144 15.1131C13.5963 15.1238 13.6512 15.1375 13.7584 15.1375C14.7296 15.1375 15.5167 14.3504 15.5167 13.3792C15.5167 12.4079 14.7296 11.6208 13.7584 11.6208C13.4561 11.6208 13.1759 11.7041 12.9275 11.8384C13.1594 9.75802 14.4452 8.10407 15.9563 8.10407C17.624 8.10407 19.0334 10.1179 19.0334 12.5C19.0334 14.882 17.624 16.8959 15.9563 16.8959Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default IconEyes;
