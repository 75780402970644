import React, { memo } from 'react';
import { View, Platform } from 'react-native';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableBounce from 'utils/packages/TouchableBounce';
import { FREQUENCY } from 'features/subscriptions/utils/frequency';
import { isWeb } from '../../../../constants';
const options = {
    enableVibrateFallback: false,
    ignoreAndroidSystemSettings: false,
};
const SubscriptionFrequencyModalContent = ({ paymentFrequency, onSelect, }) => {
    const styles = useStyles(styleSet);
    const pressItem = (item) => () => {
        if (Platform.OS === 'ios') {
            ReactNativeHapticFeedback.trigger('impactLight', options);
        }
        onSelect(Number(item));
    };
    return (<View style={styles.header}>
      {!isWeb ? (<Text Text-24 style={styles.title}>
          Frequency
        </Text>) : (<Spacer h={16}/>)}
      <View style={styles.itemContainer}>
        {Object.keys(FREQUENCY).map((key) => (<TouchableBounce key={key} onPress={pressItem(key)} hoverStyle={styles.buttonSelected} style={[
                styles.button,
                paymentFrequency === Number(key) ? styles.buttonSelected : {},
            ]}>
            {paymentFrequency === Number(key) ? (<Text Text-16 White>
                {FREQUENCY[Number(key)]}
              </Text>) : (<Text Text-16 Secondary>
                {FREQUENCY[Number(key)]}
              </Text>)}
          </TouchableBounce>))}
      </View>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    title: {
        ...(isWeb
            ? {
                marginVertical: rem(16),
            }
            : {
                marginBottom: rem(33),
                marginTop: rem(60),
            }),
    },
    header: {
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    itemContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    button: {
        backgroundColor: colors.background.dark,
        paddingVertical: rem(9),
        paddingHorizontal: rem(24),
        borderRadius: rem(24),
        marginEnd: rem(8),
        marginBottom: rem(16),
    },
    buttonSelected: {
        backgroundColor: colors.gradients.ultimate.colors[0],
    },
}));
export default memo(SubscriptionFrequencyModalContent);
