import React from 'react';
import { View, StyleSheet } from 'react-native';
import { NETWORTH_PAGE_CONTROL_HEIGHT } from './NetworthPageControl';
import { isWeb } from '../../../../constants';
const NetworthSpacingView = () => <View style={styles.container}/>;
export default NetworthSpacingView;
const styles = StyleSheet.create({
    container: {
        height: isWeb ? 0 : NETWORTH_PAGE_CONTROL_HEIGHT,
    },
});
