import { Tier } from 'features/premium/entities';
export const iconStrings = {
    normal: 'Standard',
    pride: 'Pride',
    black: 'Black',
    plus: 'Plus',
    pro: 'Pro',
    ultimate: 'Ultimate',
    slavaukraini: 'Slava Ukraini!',
    blackculture: 'Black culture',
    investor: 'Investor',
};
const APP_ICONS = {
    normal: {
        assetName: 'normal',
        name: 'normal',
    },
    pride: {
        assetName: 'pride',
        name: 'pride',
    },
    black: {
        assetName: 'black',
        name: 'black',
    },
    slavaukraini: {
        assetName: 'slavaukraini',
        name: 'slavaukraini',
    },
    blackculture: {
        assetName: 'blackculture',
        name: 'blackculture',
    },
    plus: {
        assetName: 'gold',
        name: 'plus',
        tier: Tier.plus,
    },
    pro: {
        assetName: 'green',
        name: 'pro',
        tier: Tier.pro,
    },
    ultimate: {
        assetName: 'purple',
        name: 'ultimate',
        tier: Tier.ultimate,
    },
    investor: {
        name: 'investor',
        assetName: 'investor',
    },
};
export const getAppIconByTier = (tier) => Object.values(APP_ICONS).find((icon) => icon.tier === tier);
export const getAppIconByName = (name) => APP_ICONS[name];
export const getAppIconByAssetName = (assetName) => Object.values(APP_ICONS).find((icon) => icon.assetName === assetName);
export default APP_ICONS;
