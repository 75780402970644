import React, { useCallback } from 'react';
import { View } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import IconCheck from 'design-system/icons/IconCheck';
import { IconEdit } from 'design-system/icons/IconEdit';
import { SettingsItem, SettingsItemRightContent, } from 'design-system/SettingsItem';
import { SquareBadge } from 'design-system/Badge';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import IconTransactionCategory from 'features/transactions/icons/IconTransactionCategory';
const EditCategoryListVerticalRow = ({ category, isDefaultCategory, isSelected, showEdit, onPress, }) => {
    const styles = useStyles(styleSet);
    const colors = useColors();
    const renderIcon = useCallback(() => {
        const render = () => {
            if (category && (isDefaultCategory || 'emoji' in category)) {
                return (<IconTransactionCategory width={rem(24)} height={rem(24)} category={category}/>);
            }
            const title = category && category.displayName
                ? category.displayName.substring(0, 2).toUpperCase()
                : '';
            return (<View style={[styles.image]}>
          <Text Text-20 OffBlack>
            {title}
          </Text>
        </View>);
        };
        return (<View style={styles.imageBadgeWrapper}>
        <View style={styles.imageContainer}>{render()}</View>
        {isSelected && (<SquareBadge fill={colors.gradients.red.colors[0]} border>
            <IconCheck color={colors.elements.white}/>
          </SquareBadge>)}
      </View>);
    }, [
        category,
        colors.elements.white,
        colors.gradients.red.colors,
        isDefaultCategory,
        isSelected,
        styles.image,
        styles.imageBadgeWrapper,
        styles.imageContainer,
    ]);
    const press = useCallback(() => {
        onPress?.(category, Boolean(isDefaultCategory));
    }, [category, isDefaultCategory, onPress]);
    return (<SettingsItem title={category.displayName} renderIcon={renderIcon} onPress={press}>
      <SettingsItemRightContent>
        {showEdit && <IconEdit colorKey="secondary"/>}
      </SettingsItemRightContent>
    </SettingsItem>);
};
const styleSet = createStyleSheets((colors) => ({
    category: {
        paddingVertical: rem(24),
    },
    categoryContent: {
        alignItems: 'center',
    },
    imageBadgeWrapper: {
        width: rem(44),
        marginRight: rem(16),
    },
    imageContainer: {
        height: rem(44),
        width: rem(44),
        borderRadius: rem(28),
        backgroundColor: colors.background.dark,
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        height: rem(44),
        width: rem(44),
        borderRadius: rem(28),
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
export default EditCategoryListVerticalRow;
