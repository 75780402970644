import { CALL_API } from 'middleware/api';
import { GET_STRIPE_CREDITS_FAILURE, GET_STRIPE_CREDITS_REQUEST, GET_STRIPE_CREDITS_SUCCESS, GET_STRIPE_SUBSCRIPTION_PURCHASE_PREVIEW_FAILURE, GET_STRIPE_SUBSCRIPTION_PURCHASE_PREVIEW_REQUEST, GET_STRIPE_SUBSCRIPTION_PURCHASE_PREVIEW_SUCCESS, } from './types';
export const setIsPlatformPaySupported = (isPlatformPaySupported) => ({
    type: 'SET_IS_PLATFORM_PAY_SUPPORTED',
    isPlatformPaySupported,
});
export const getStripePrices = () => ({
    [CALL_API]: {
        endpoint: `/stripe/prices`,
        method: 'GET',
        types: [
            'GET_STRIPE_PRICES_REQUEST',
            'GET_STRIPE_PRICES_SUCCESS',
            'GET_STRIPE_PRICES_FAILURE',
        ],
    },
});
export const getStripeCustomer = () => ({
    [CALL_API]: {
        endpoint: `/stripe/customer`,
        method: 'GET',
        types: [
            'GET_STRIPE_CUSTOMER_REQUEST',
            'GET_STRIPE_CUSTOMER_SUCCESS',
            'GET_STRIPE_CUSTOMER_FAILURE',
        ],
    },
});
export const createStripeCustomer = () => ({
    [CALL_API]: {
        endpoint: `/stripe/customer`,
        method: 'POST',
        types: [
            'POST_STRIPE_CUSTOMER_REQUEST',
            'POST_STRIPE_CUSTOMER_SUCCESS',
            'POST_STRIPE_CUSTOMER_FAILURE',
        ],
    },
});
export const setUpIntent = () => ({
    [CALL_API]: {
        endpoint: `/stripe/setup-intent`,
        method: 'POST',
        types: [
            'SET_UP_STRIPE_INTENT_REQUEST',
            'SET_UP_STRIPE_INTENT_SUCCESS',
            'SET_UP_STRIPE_INTENT_FAILURE',
        ],
        muteAlert: true,
    },
});
export const setUpIntentConfirm = (paymentMethodId) => ({
    [CALL_API]: {
        endpoint: `/stripe/setup-intent/confirm`,
        method: 'POST',
        body: JSON.stringify({ paymentMethodId }),
        muteAlert: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            'SET_UP_STRIPE_INTENT_CONFIRM_REQUEST',
            'SET_UP_STRIPE_INTENT_CONFIRM_SUCCESS',
            'SET_UP_STRIPE_INTENT_CONFIRM_FAILURE',
        ],
    },
});
export const subscribeViaStripe = (productId, couponId) => ({
    [CALL_API]: {
        endpoint: `/stripe/subscription`,
        method: 'POST',
        body: JSON.stringify({ priceId: productId, couponId }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            'SUBSCRIBE_VIA_STRIPE_REQUEST',
            'SUBSCRIBE_VIA_STRIPE_SUCCESS',
            'SUBSCRIBE_VIA_STRIPE_FAILURE',
        ],
        muteAlert: true,
    },
});
export const cancelStripeSubscription = () => ({
    [CALL_API]: {
        endpoint: `/stripe/subscription/cancel`,
        method: 'POST',
        types: [
            'CANCEL_STRIPE_SUBSCRIPTION_REQUEST',
            'CANCEL_STRIPE_SUBSCRIPTION_SUCCESS',
            'CANCEL_STRIPE_SUBSCRIPTION_FAILURE',
        ],
    },
});
export const reactivateStripeSubscription = () => ({
    [CALL_API]: {
        endpoint: `/stripe/subscription/reactivate`,
        method: 'POST',
        types: [
            'REACTIVATE_STRIPE_SUBSCRIPTION_REQUEST',
            'REACTIVATE_STRIPE_SUBSCRIPTION_SUCCESS',
            'REACTIVATE_STRIPE_SUBSCRIPTION_FAILURE',
        ],
    },
});
export const changeSubscriptionViaStripe = (productId, couponId, prorationDate) => ({
    [CALL_API]: {
        endpoint: `/stripe/subscription`,
        method: 'PATCH',
        body: JSON.stringify({ priceId: productId, couponId, prorationDate }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            'CHANGE_SUBSCRIPTION_VIA_STRIPE_REQUEST',
            'CHANGE_SUBSCRIPTION_VIA_STRIPE_SUCCESS',
            'CHANGE_SUBSCRIPTION_VIA_STRIPE_FAILURE',
        ],
        muteAlert: true,
    },
});
export const getStripeSubscriptionPurchasePreview = (body) => ({
    [CALL_API]: {
        endpoint: `/stripe/subscription/preview`,
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            GET_STRIPE_SUBSCRIPTION_PURCHASE_PREVIEW_REQUEST,
            GET_STRIPE_SUBSCRIPTION_PURCHASE_PREVIEW_SUCCESS,
            GET_STRIPE_SUBSCRIPTION_PURCHASE_PREVIEW_FAILURE,
        ],
        muteAlert: false,
    },
});
export const refundStripeSubscription = (body) => ({
    [CALL_API]: {
        endpoint: `/stripe/subscription/refund`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        types: [
            'REFUND_STRIPE_SUBSCRIPTION_REQUEST',
            'REFUND_STRIPE_SUBSCRIPTION_SUCCESS',
            'REFUND_STRIPE_SUBSCRIPTION_FAILURE',
        ],
    },
});
export const getStripeCredit = () => ({
    [CALL_API]: {
        endpoint: `/stripe/credit`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        types: [
            GET_STRIPE_CREDITS_REQUEST,
            GET_STRIPE_CREDITS_SUCCESS,
            GET_STRIPE_CREDITS_FAILURE,
        ],
    },
});
