import { useEffect } from 'react';
import { OnboardingFlow } from 'utils/types/navigation';
import Amplitude from 'utils/amplitude';
export const amplitudeSource = 'invest';
export const amplitudeSourceForPots = 'pots';
export const amplitudeSourceForWallet = 'wallet';
export const amplitudeSourceForAddress = 'address';
export const getOnboardingFlow = (flow) => {
    switch (flow) {
        case OnboardingFlow.Pots:
            return amplitudeSourceForPots;
        case OnboardingFlow.Wallet:
            return amplitudeSourceForWallet;
        case OnboardingFlow.Address:
            return amplitudeSourceForAddress;
        case 'onboarding':
            return 'onboarding';
        default:
            return amplitudeSource;
    }
};
export const useAmplitudeOnMountEvent = (flow, ...args) => {
    const source = getOnboardingFlow(flow);
    useEffect(() => {
        if (args.length === 1) {
            Amplitude.logEvent(args[0], { source });
        }
        else {
            Amplitude.logEvent(args[0], { source, ...args[1] });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
