import { CALL_API } from 'middleware/api';
export const GET_PROMOTIONS_REQUEST = 'GET_PROMOTIONS_REQUEST';
export const GET_PROMOTIONS_SUCCESS = 'GET_PROMOTIONS_SUCCESS';
export const GET_PROMOTIONS_ERROR = 'GET_PROMOTIONS_ERROR';
export const GET_CANCELLATION_OFFER_REQUEST = 'GET_CANCELLATION_OFFER_REQUEST';
export const GET_CANCELLATION_OFFER_SUCCESS = 'GET_CANCELLATION_OFFER_SUCCESS';
export const GET_CANCELLATION_OFFER_ERROR = 'GET_CANCELLATION_OFFER_ERROR';
export const GET_INTRODUCTORY_CANCELLATION_OFFER_REQUEST = 'GET_INTRODUCTORY_CANCELLATION_OFFER_REQUEST';
export const GET_INTRODUCTORY_CANCELLATION_OFFER_SUCCESS = 'GET_INTRODUCTORY_CANCELLATION_OFFER_SUCCESS';
export const GET_INTRODUCTORY_CANCELLATION_OFFER_ERROR = 'GET_INTRODUCTORY_CANCELLATION_OFFER_ERROR';
export const getPromotions = () => ({
    [CALL_API]: {
        endpoint: '/promotions?withNativeOffers=true',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            GET_PROMOTIONS_REQUEST,
            GET_PROMOTIONS_SUCCESS,
            GET_PROMOTIONS_ERROR,
        ],
    },
});
export const getCancellationOffer = (platform) => ({
    [CALL_API]: {
        endpoint: `/cancellation-offer?platform=${platform}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            GET_CANCELLATION_OFFER_REQUEST,
            GET_CANCELLATION_OFFER_SUCCESS,
            GET_CANCELLATION_OFFER_ERROR,
        ],
    },
});
export const getIntroductoryCancellationOffer = (platform, env) => ({
    [CALL_API]: {
        endpoint: `/cancellation-offer/introductory?platform=${platform}&env=${env}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            GET_INTRODUCTORY_CANCELLATION_OFFER_REQUEST,
            GET_INTRODUCTORY_CANCELLATION_OFFER_SUCCESS,
            GET_INTRODUCTORY_CANCELLATION_OFFER_ERROR,
        ],
    },
});
