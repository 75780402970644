import React, { forwardRef, useCallback } from 'react';
import { Pressable } from 'react-native';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { rem } from 'design-system/values';
const TouchableOpacity = forwardRef(({ style, onCard, onPress, children, ...props }, ref) => {
    const styles = useStyles(styleSet);
    const [isHovering, setIsHovering] = React.useState(false);
    const handleEvent = useCallback((event) => () => {
        setIsHovering(event === 'onHoverIn');
    }, []);
    return (<Pressable ref={ref} onPress={onPress} onHoverIn={handleEvent('onHoverIn')} onHoverOut={handleEvent('onHoverOut')} style={[
            style,
            onCard && styles.onCard,
            isHovering && 'hoverStyle' in props && props.hoverStyle,
            isHovering && 'hoverOnDark' in props && styles.hoverOnDark,
            isHovering && 'hoverOnLight' in props && styles.hoverOnLight,
        ]} {...props}>
      {children}
    </Pressable>);
});
const styleSet = createStyleSheets((colors) => ({
    onCard: {
        // This is required for hover
        borderRadius: rem(10),
    },
    hoverOnDark: {
        backgroundColor: colors.control.hoverOnDark,
    },
    hoverOnLight: {
        backgroundColor: colors.control.hoverOnLight,
    },
}));
export default React.memo(TouchableOpacity);
