import { spawn, call, takeLatest, put } from 'typed-redux-saga/macro';
import { CommonActions } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { loggedOut } from 'actions';
import { LOG_OUT } from 'actions/types';
import { removeTokens } from 'utils/jwtHelpers';
import { navigationRef } from 'utils/navigationv6';
import { removeKeychainUser } from 'utils/keychainUser';
import { disablePushNotifications } from 'utils/packages/pushNotifications';
import { isWeb } from '../constants';
function* logoutWorker() {
    try {
        yield* call(removeTokens);
        yield* call(removeKeychainUser);
        yield* spawn(AsyncStorage.removeItem, 'referral_code');
        yield* spawn(disablePushNotifications);
        if (isWeb) {
            navigationRef.dispatch(CommonActions.reset({
                index: 0,
                routes: [{ name: 'Feed' }],
            }));
        }
    }
    catch {
        //
    }
    finally {
        yield* put(loggedOut());
    }
}
function* logoutSaga() {
    yield* takeLatest(LOG_OUT, logoutWorker);
}
export default logoutSaga;
