import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { rem } from 'design-system/values';
import { AppView } from 'design-system/AppView';
import { HEIGHT_PLUS_PADDING } from 'design-system/Button';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
import { useCategories } from 'hooks/useCategory';
import createStyleSheets from 'utils/createStyleSheets';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { muteErrorAndResultButThrows, useFetchOld } from 'hooks';
import { editTransactions } from 'actions';
import { isWeb } from '../../../constants';
import EditMultipleList from '../components/multiple/EditMultipleList';
import IconTransactionCategory from '../icons/IconTransactionCategory';
import EditMultipleSelectAllButton from '../components/multiple/EditMultipleSelectAllButton';
const EditMultipleTransactionsScreen = withAnimatedNavigationHeader(({ navigation, route, scrollHandler }) => {
    const colors = useColors();
    const categories = useCategories();
    const { category } = route.params;
    const categoryItem = typeof categories[category.id] !== 'undefined'
        ? categories[category.id]
        : category;
    const [, , isFetching, fetch] = useFetchOld(muteErrorAndResultButThrows);
    const [isSelectedAll, setSelectedAll] = useState(false);
    const onPressEdit = useCallback(async (selected) => {
        try {
            await fetch(editTransactions(selected, category));
            navigation.navigate('LearningScreen', {
                keyBack: route.params.keyBack,
                editData: {
                    editType: 'categorise',
                    category,
                },
                transactionsCount: Object.keys(selected).length,
            });
        }
        catch {
            //
        }
    }, [category, route.params.keyBack]);
    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (<EditMultipleSelectAllButton isSelectedAll={isSelectedAll} onPress={() => {
                    setSelectedAll(!isSelectedAll);
                }}/>),
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary, isSelectedAll]);
    const styles = useStyles(styleSet);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const listContainerStyle = useMarginBottom('paddingBottom', HEIGHT_PLUS_PADDING, styles.listContainerStyle, paddingHorizontalStyle);
    const ListHeader = useMemo(() => (<View style={styles.listHeader}>
            <View style={styles.imageContainer}>
              <IconTransactionCategory width={rem(33)} height={rem(33)} category={categoryItem}/>
            </View>
          </View>), [categoryItem, styles.imageContainer, styles.listHeader]);
    return (<AppView withFrame={false}>
          <EditMultipleList isFetchingEdit={isFetching} scrollHandler={scrollHandler} transaction={route.params.transaction} filterOut={route.params.filterOut} isSelectedAll={isSelectedAll} listContainerStyle={listContainerStyle} onPressEdit={onPressEdit} ListHeader={ListHeader}/>
        </AppView>);
}, ({ colors, route }) => {
    const { category } = route.params;
    return {
        title: category.displayName,
        headerColorOutputRange: [
            colors.background.dark,
            colors.cards.onDark,
        ],
    };
});
const styleSet = createStyleSheets((colors) => ({
    listContainerStyle: {
        paddingTop: rem(16),
    },
    imageContainer: {
        height: rem(56),
        width: rem(56),
        marginTop: rem(8),
        alignItems: 'center',
        borderRadius: rem(28),
        marginBottom: rem(24),
        justifyContent: 'center',
        backgroundColor: colors.cards.onDark,
    },
    listHeader: {
        alignItems: 'center',
    },
}));
export default EditMultipleTransactionsScreen;
