import React, { useLayoutEffect, useRef, useState } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import { View } from 'react-native';
import Text from 'design-system/Text';
import Button from 'design-system/Button';
import { rem } from 'design-system/values';
import StatusBar from 'design-system/StatusBar';
import { AppView } from 'design-system/AppView';
import RNMaskedText from 'utils/packages/rnMaskedText';
import { useAutoFocusInput } from 'hooks/useAutoFocusInput';
import KeyboardAvoidingContainer from 'design-system/KeyboardAvoidingContainer';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { isWeb } from '../../../constants';
const CorrectedAmount = ({ navigation, route, }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    useLayoutEffect(() => {
        navigation.setOptions({
            headerTitle: '',
            title: 'Adjust Amount',
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary]);
    const { subscription } = route.params;
    const [state, setState] = useState({
        rawData: undefined,
        // This needs to be a number or TextInputMask breaks, think the types are wrong in the lib
        value: subscription.nativePrice.amount,
    });
    const openDatePrediction = () => {
        const correctedAmount = state.rawData ? state.rawData : state.value;
        navigation.navigate('CorrectedDate', {
            subscription,
            correctedAmount: correctedAmount.toString(),
            keyBack: route.key,
        });
    };
    const onChangeText = (data, rawData) => {
        setState({ value: data, rawData });
    };
    const { nativePrice } = subscription;
    const currency = getSymbolFromCurrency(nativePrice.currency);
    const textOptions = {
        unit: currency,
        separator: '.',
        delimiter: ',',
        precision: 2,
    };
    const textInputRef = useRef(null);
    useAutoFocusInput(textInputRef);
    return (<AppView isInTab={isWeb} avoidHeader>
      <StatusBar barStyle="dark-content" animated/>
      <KeyboardAvoidingContainer>
        <View style={styles.innerContent}>
          <Text Text-24>
            What&apos;s the expected amount for{' '}
            {subscription.merchantInfo?.displayName}?
          </Text>
          <View style={styles.alignCenter}>
            <RNMaskedText ref={textInputRef} style={[styles.textinput]} type="money" allowFontScaling={false} underlineColorAndroid="transparent" options={textOptions} includeRawValueInChangeText onChangeText={onChangeText} selectionColor={colors.text.selection} onSubmitEditing={openDatePrediction} value={state.value}/>
          </View>
        </View>
        <Button brand onPress={openDatePrediction} title="Next"/>
      </KeyboardAvoidingContainer>
    </AppView>);
};
const styleSet = createStyleSheets((colors) => ({
    alignCenter: {
        flex: 1,
        justifyContent: 'center',
    },
    innerContent: {
        flex: 1,
        flexDirection: 'column',
    },
    textinput: {
        color: colors.text.primary,
        fontSize: rem(64),
        fontFamily: 'Nunito-ExtraBold',
        textAlign: 'center',
    },
}));
export default CorrectedAmount;
