import React, { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import Indicator from 'design-system/Indicator';
import ScrollView from 'design-system/ScrollView';
import Button, { HEIGHT } from 'design-system/Button';
import { CardView } from 'design-system/CardView';
import EmptyAccounts from 'features/accounts/components/EmptyAccounts';
import AccountsSectionCollapsible from 'features/accounts/components/overview/AccountSectionCollapsible';
import { rem } from 'design-system/values';
import * as selectors from 'reducers/selectors';
import useAccountCallbacks from 'hooks/useAccountCallbacks';
import useColors from 'hooks/useColors';
import { useMarginBottom } from 'hooks/useMarginBottom';
import { getAccountGoals } from 'actions';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import UpgradeCard from 'design-system/UpgradeCard';
import { IconWifi } from 'design-system/icons/IconWifi';
import { formatPlural } from 'utils/formatting';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import StatusBar from 'design-system/StatusBar';
import { AppView } from 'design-system/AppView';
import usePrivateRoute from 'hooks/usePrivateRoute';
import i18n from '../../../i18n';
import { isWeb } from '../../../constants';
const strings = {
    screenTitle: 'Offline accounts',
    addOfflineAccount: 'Add an offline account',
    savings: 'Savings',
    creditCards: 'Credit Cards',
    investments: 'Investments',
    pensions: 'Pensions',
    loans: 'Loans',
    realEstate: 'Real Estate',
    vehicles: 'Vehicles',
    otherAssets: 'Other assets',
    closed: 'Closed',
};
const OfflineAccountsScreen = ({ navigation, route }) => {
    usePrivateRoute();
    const colors = useColors();
    useLayoutEffect(() => {
        navigation.setOptions({
            title: strings.screenTitle,
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary]);
    const isFetching = useAppSelector(selectors.selectIsUserFetching);
    const manualAccounts = useAppSelector(selectors.selectActiveManualAccounts);
    const closedAccounts = useAppSelector(selectors.selectClosedManualAccounts);
    const onAddAccount = useCallback(() => {
        navigation.navigate('PickAccountType', { keyBack: route.key });
    }, [route.key]);
    const { onRowPress, onRowLongPress, onOpenLogins } = useAccountCallbacks(false);
    const loadedGoals = useAppSelector((store) => store.accounts.loadedGoals);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!loadedGoals) {
            dispatch(getAccountGoals());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { isUnlockedForSpace, onPressUpgrade } = useShouldShowUpgrade({
        benefitId: 'offlineAccounts',
    });
    const showUpgrade = !isUnlockedForSpace;
    const total = useMemo(() => Object.keys(manualAccounts).reduce((prev, curr) => {
        const type = manualAccounts[curr];
        return type ? prev + type.length : prev;
    }, 0), [manualAccounts]);
    const rightContent = useMemo(() => (showUpgrade ? <Button small square brandReversed title="Upgrade" onPress={onPressUpgrade}/> : null), [onPressUpgrade, showUpgrade]);
    const content = useMemo(() => {
        if (isFetching) {
            return (<CardView style={styles.loading}>
          <Indicator dark/>
        </CardView>);
        }
        if (manualAccounts && !Object.keys(manualAccounts).length) {
            return (<>
          <EmptyAccounts onPress={onAddAccount}/>
          <AccountsSectionCollapsible title={strings.closed} onRowPress={onRowPress} onRowLongPress={onRowLongPress} data={closedAccounts} onOpenLogins={onOpenLogins} rightContent={rightContent} onOpenUpgrade={onPressUpgrade}/>
        </>);
        }
        return (<>
        {!isUnlockedForSpace && (<UpgradeCard icon={<IconWifi colorKey="brand" off/>} title={`You have ${total} offline ${formatPlural('account', 'accounts', total)}`} description="Upgrade your plan to see existing and add unlimited offline accounts" onPressUpgrade={onPressUpgrade}/>)}
        <AccountsSectionCollapsible title={i18n.t('current')} data={manualAccounts['CHECKING'] ?? []} withTotal isCollapsedByDefault={false} color={colors.text.income} onRowPress={onRowPress} onRowLongPress={onRowLongPress} onOpenLogins={onOpenLogins} rightContent={rightContent} onOpenUpgrade={onPressUpgrade}/>
        <AccountsSectionCollapsible title={strings.savings} data={manualAccounts['SAVINGS'] ?? []} withTotal isCollapsedByDefault={false} color={colors.text.feed} onRowPress={onRowPress} onRowLongPress={onRowLongPress} onOpenLogins={onOpenLogins} rightContent={rightContent} onOpenUpgrade={onPressUpgrade}/>
        <AccountsSectionCollapsible title={strings.creditCards} data={manualAccounts['CREDITCARD'] ?? []} withTotal isCollapsedByDefault={false} color={colors.text.debt} onRowPress={onRowPress} onRowLongPress={onRowLongPress} onOpenLogins={onOpenLogins} rightContent={rightContent} onOpenUpgrade={onPressUpgrade}/>
        <AccountsSectionCollapsible title={strings.investments} data={manualAccounts['INVESTMENT'] ?? []} withTotal isCollapsedByDefault={false} color={colors.text.positive} onRowPress={onRowPress} onRowLongPress={onRowLongPress} onOpenLogins={onOpenLogins} rightContent={rightContent} onOpenUpgrade={onPressUpgrade}/>
        <AccountsSectionCollapsible title={strings.pensions} data={manualAccounts['PENSION'] ?? []} withTotal isCollapsedByDefault={false} color={colors.text.positive} onRowPress={onRowPress} onRowLongPress={onRowLongPress} onOpenLogins={onOpenLogins} rightContent={rightContent} onOpenUpgrade={onPressUpgrade}/>
        <AccountsSectionCollapsible title={strings.loans} data={manualAccounts['LOAN'] ?? []} withTotal isCollapsedByDefault={false} color={colors.text.debt} onRowPress={onRowPress} onRowLongPress={onRowLongPress} onOpenLogins={onOpenLogins} rightContent={rightContent} onOpenUpgrade={onPressUpgrade}/>
        <AccountsSectionCollapsible title={strings.realEstate} data={manualAccounts['REAL_ESTATE'] ?? []} withTotal isCollapsedByDefault={false} color={colors.text.income} onRowPress={onRowPress} onRowLongPress={onRowLongPress} onOpenLogins={onOpenLogins} rightContent={rightContent} onOpenUpgrade={onPressUpgrade}/>
        <AccountsSectionCollapsible title={strings.vehicles} data={manualAccounts['VEHICLE'] ?? []} withTotal isCollapsedByDefault={false} color={colors.text.income} type="VEHICLE" onRowPress={onRowPress} onRowLongPress={onRowLongPress} onOpenLogins={onOpenLogins} rightContent={rightContent} onOpenUpgrade={onPressUpgrade}/>
        <AccountsSectionCollapsible title={strings.otherAssets} data={manualAccounts['OTHER'] ?? []} withTotal isCollapsedByDefault={false} color={colors.text.income} onRowPress={onRowPress} onRowLongPress={onRowLongPress} onOpenLogins={onOpenLogins} rightContent={rightContent} onOpenUpgrade={onPressUpgrade}/>
        <AccountsSectionCollapsible title={strings.closed} onRowPress={onRowPress} onRowLongPress={onRowLongPress} data={closedAccounts} onOpenLogins={onOpenLogins} rightContent={rightContent} onOpenUpgrade={onPressUpgrade}/>
      </>);
    }, [
        isFetching,
        manualAccounts,
        isUnlockedForSpace,
        total,
        onPressUpgrade,
        colors.text.income,
        colors.text.feed,
        colors.text.debt,
        colors.text.positive,
        onRowPress,
        onRowLongPress,
        onOpenLogins,
        rightContent,
        closedAccounts,
        onAddAccount,
    ]);
    const paddingBottom = useMarginBottom('paddingBottom', rem(24) + HEIGHT);
    return (<AppView withFrame={false} isInTab={isWeb}>
      <StatusBar barStyle="dark-content" animated/>
      <ScrollView secondary contentContainerStyle={paddingBottom} isInTab={isWeb}>
        {content}
      </ScrollView>
      <Button brand floating preventDoubleTap title={strings.addOfflineAccount} onPress={onAddAccount}/>
    </AppView>);
};
const styles = StyleSheet.create({
    loading: {
        height: rem(120),
        marginTop: 0,
    },
});
export default OfflineAccountsScreen;
