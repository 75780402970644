import { CALL_API } from 'middleware/api';
/**
 * GET `/wealth/trading/pending-top-ups`
 */
export const getPendingTopups = () => ({
    [CALL_API]: {
        endpoint: '/wealth/trading/pending-top-ups',
        method: 'GET',
        headers: { Accept: 'application/json' },
        types: ['GET_PENDING_TOPUPS_REQUEST', 'GET_PENDING_TOPUPS_SUCCESS', 'GET_PENDING_TOPUPS_ERROR'],
        muteAlert: true,
    },
});
