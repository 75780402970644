import React, { useState, useCallback, useMemo } from 'react';
import moment from 'moment';
import { View, StyleSheet } from 'react-native';
import Button from 'design-system/Button';
import { rem } from 'design-system/values';
import { TextButton } from 'design-system/TextButton';
import useStyles from 'hooks/useStyles';
import Modal from 'utils/packages/Modal';
import createStyleSheets from 'utils/createStyleSheets';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import useIsCompactCalendar from 'design-system/calendar/useIsCompactCalendar';
import Header from './Header';
import Controller from './Controller';
import { isWeb } from '../../constants';
import { AnalyticsFilterContext } from './context';
import { filterStartYear, defaultSelectedSegmentIndex } from './constants';
const currentYear = moment().year();
export const getYearArrArr = (start, end, selected) => {
    const yearData = [];
    let hasStart = false;
    let endRef = end;
    const periodLength = isWeb ? 12 : 6;
    while (!hasStart) {
        const startRef = endRef - periodLength + 1;
        const arr = Array.from({ length: endRef - startRef + 1 }, (_, i) => startRef + i);
        yearData.unshift(arr);
        endRef -= periodLength;
        if (startRef < start) {
            hasStart = true;
        }
    }
    const defaultYearIndex = yearData.findIndex((data) => data.includes(selected));
    return {
        yearData,
        defaultYearIndex: defaultYearIndex > -1 ? defaultYearIndex : 0,
    };
};
const AnalyticsFilter = ({ onSave, onReset, showReset, defaults, }) => {
    const { yearData, defaultYearIndex } = getYearArrArr(filterStartYear, currentYear, defaults?.yearly || currentYear);
    /**
     * Segment control state
     */
    const [selectedTabIndex, setSelectedTabIndex] = useState(() => {
        if (defaults?.weeklyRange) {
            return 0;
        }
        if (defaults?.monthly !== undefined) {
            return 1;
        }
        if (defaults?.quarterly !== undefined) {
            return 2;
        }
        if (defaults?.yearly !== undefined) {
            return 3;
        }
        if (defaults?.custom) {
            return 4;
        }
        return defaultSelectedSegmentIndex;
    });
    /**
     * Weekly segment states start
     */
    const [weeklyRange, setWeeklyRange] = useState(defaults?.weeklyRange);
    /**
     * Weekly segment states end
     */
    /**
     * Monthly segment states start
     */
    // Stores year as number
    // Stores month as index
    const [selectedYear, setSelectedYear] = useState(defaults?.monthly?.currentYear ||
        defaults?.quarterly?.currentYear ||
        currentYear);
    const [selectedMonth, setSelectedMonth] = useState(defaults?.monthly?.currentMonth);
    /**
     * Monthly segment states end
     */
    const [selectedQuarter, setSelectedQuarter] = useState(defaults?.quarterly?.currentQuarter);
    /**
     * Year segment states start
     */
    const [selectedYearFilter, setSelectedYearFilter] = useState(defaults?.yearly);
    const [selectedYearFilterIndex, setSelectedYearFilterIndex] = useState(defaultYearIndex);
    /**
     * Year segment states end
     */
    /**
     * Custom segment states start
     */
    const [to, setTo] = useState(defaults?.custom?.[1]);
    const [from, setFrom] = useState(defaults?.custom?.[0]);
    /**
     * Custom segment states end
     */
    const currentStates = useMemo(() => ({
        currentYear: moment().year(),
        currentMonth: moment().month(),
        todayDateStr: new Date().toISOString().slice(0, 10),
    }), 
    // recalculate the above variables on filter open/close
    []);
    const handleReset = () => {
        onReset?.();
    };
    const handleSave = () => {
        switch (selectedTabIndex) {
            case 0:
                if (weeklyRange) {
                    onSave({
                        from: weeklyRange[0],
                        to: weeklyRange[1],
                        type: 'week',
                    });
                }
                break;
            case 1:
                if (selectedMonth !== undefined) {
                    onSave({
                        month: selectedMonth + 1,
                        year: selectedYear,
                        type: 'month',
                    });
                }
                break;
            case 2:
                if (selectedQuarter !== undefined) {
                    onSave({
                        year: selectedYear,
                        quarter: selectedQuarter,
                        type: 'quarter',
                    });
                }
                break;
            case 3:
                if (selectedYearFilter !== undefined) {
                    onSave({ year: selectedYearFilter, type: 'year' });
                }
                break;
            case 4:
                if (from && to) {
                    onSave({ from, to, type: 'custom' });
                }
                break;
            default:
                break;
        }
    };
    const { height } = useAppFrameDimensions();
    const styles = useStyles(modalStyleSet);
    const paddingBottom = useMarginBottom('paddingBottom', undefined, styles.container);
    const compact = useIsCompactCalendar();
    const contextValue = useMemo(() => ({
        selectedTabIndex,
        setSelectedTabIndex,
        ...currentStates,
        selectedYear,
        selectedMonth,
        setSelectedYear,
        setSelectedMonth,
        selectedQuarter,
        setSelectedQuarter,
        weeklyRange,
        setWeeklyRange,
        yearData,
        defaultYearIndex,
        selectedYearFilter,
        setSelectedYearFilter,
        selectedYearFilterIndex,
        setSelectedYearFilterIndex,
        to,
        from,
        setTo,
        setFrom,
    }), [
        currentStates,
        defaultYearIndex,
        from,
        selectedMonth,
        selectedQuarter,
        selectedTabIndex,
        selectedYear,
        selectedYearFilter,
        selectedYearFilterIndex,
        to,
        weeklyRange,
        yearData,
    ]);
    return (<View style={paddingBottom}>
      <AnalyticsFilterContext.Provider value={contextValue}>
        <View style={{
            height: isWeb ? undefined : height * (compact ? 0.95 : 0.9),
        }}>
          <Header />
          <Controller />
          <View style={styles.buttonContainer}>
            {showReset && (<TextButton style={[
                styles.resetButton,
                compact && styles.resetButtonCompact,
            ]} onPress={handleReset} text="Reset to budgeting period"/>)}

            <Button brand onPress={handleSave} title="Save"/>
          </View>
        </View>
      </AnalyticsFilterContext.Provider>
    </View>);
};
const useAnalyticsFilter = () => {
    const show = useCallback((onSave, onReset, defaults) => {
        Modal.show(<AnalyticsFilter onSave={onSave} onReset={onReset} showReset={onReset !== undefined} defaults={defaults}/>, {
            lightBg: true,
            propagateSwipe: true,
            containerStyle: styles.modal,
            title: isWeb ? 'Filter period' : undefined,
        });
    }, []);
    const hide = useCallback(() => {
        Modal.hide();
    }, []);
    return [show, hide];
};
export default useAnalyticsFilter;
const styles = StyleSheet.create({
    modal: {
        margin: 0,
        paddingBottom: 0,
        paddingHorizontal: 0,
    },
});
const modalStyleSet = createStyleSheets((colors) => ({
    container: {
        ...(!isWeb && {
            backgroundColor: colors.background.dark,
        }),
    },
    resetButton: {
        alignSelf: 'center',
        marginTop: rem(8),
        marginBottom: rem(16),
    },
    resetButtonCompact: {
        marginBottom: rem(8),
    },
    buttonContainer: {
        alignItems: 'center',
        marginHorizontal: rem(16),
    },
}));
