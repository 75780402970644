import { noop } from 'lodash';
import React from 'react';
import { HelpNavigationButton } from 'design-system';
import { useActionSheet } from 'hooks/useActionSheet';
import Links from 'utils/links';
import { useMainStackNavigation } from 'utils/types/navigationV6';
export const accountHelpButtonStrings = {
    title: 'How can we help you?',
    options: {
        helpCenter: 'Visit the help center',
        chat: 'Chat to a human',
        pendingTopups: 'My deposit is still pending',
        cancel: 'Cancel',
    },
};
const AccountHelpButton = ({ black, showPendingDepositHelp, }) => {
    const { showActionSheetWithOptions } = useActionSheet();
    const navigation = useMainStackNavigation();
    const sheetOptions = [
        {
            title: accountHelpButtonStrings.options.helpCenter,
            action: Links.openEmmaHelp,
        },
        {
            title: accountHelpButtonStrings.options.chat,
            action: () => navigation.navigate('Support', {}),
        },
        { title: accountHelpButtonStrings.options.cancel, action: noop },
    ];
    if (showPendingDepositHelp) {
        sheetOptions.unshift({
            title: accountHelpButtonStrings.options.pendingTopups,
            action: Links.openPendingDepositHelp,
        });
    }
    const onPress = () => {
        showActionSheetWithOptions({
            options: sheetOptions.map((o) => o.title),
            title: accountHelpButtonStrings.title,
            cancelButtonIndex: sheetOptions.length - 1,
        }, (i) => {
            if (i !== undefined) {
                sheetOptions[i].action();
            }
        });
    };
    return <HelpNavigationButton black={black} onPress={onPress}/>;
};
export default AccountHelpButton;
