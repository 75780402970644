import { useCallback, useRef } from 'react';
import { UpgradeWebModal } from 'design-system/UpgradeWebModal';
import { Tier } from 'features/premium/entities';
import { selectIsGBUser } from 'reducers/selectors';
import { setTransactionsRequesting } from 'actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { useAccountMenu } from 'features/accounts/hooks/useAccountMenu';
import AddAccountModal from 'features/accounts/components/AddAccountModal';
import { isWeb } from '../constants';
import usePlaidLink from './usePlaidLink';
import { useOpenConnectionLogin } from './useOpenConnectionLogin';
const useAccountCallbacks = (navigateBackAfterDelete = true) => {
    const isGBUser = useAppSelector(selectIsGBUser);
    const dispatch = useAppDispatch();
    const navigation = useMainStackNavigation();
    const openPlaidTapTime = useRef();
    const onOpenUpgrade = useCallback((data) => {
        const tier = data.ultimatePreviewAccount ? Tier.ultimate : Tier.pro;
        if (isWeb) {
            UpgradeWebModal.show(tier);
            return;
        }
        if (data.deactivatedOverQuota) {
            navigation.navigate('LoginsUpgrade', { fromConnectionId: data.bankConnectionId });
        }
        else {
            navigation.navigate('EmmaProModal', {
                tier,
                isTierRequired: true,
            });
        }
    }, []);
    const onRowLongPress = useAccountMenu(navigation, navigateBackAfterDelete);
    const onRowPress = useCallback((data, touchViewRef) => {
        if (data && (data.type === 'REAL_ESTATE' || data.type === 'VEHICLE' || data.type === 'OTHER')) {
            onRowLongPress(data, touchViewRef);
            return;
        }
        if (!data.ultimatePreviewAccount && !data.proPreviewAccount && !data.deactivatedOverQuota) {
            dispatch(setTransactionsRequesting);
            navigation.navigate('Transactions', {
                id: data.id,
            });
        }
        else {
            onOpenUpgrade(data, touchViewRef);
        }
    }, [onOpenUpgrade, onRowLongPress]);
    const login = useOpenConnectionLogin(navigation);
    const onOpenLogins = useCallback((data) => {
        login({ type: 'account', data });
    }, [login]);
    const openPlaid = usePlaidLink();
    const onAddAccount = useCallback((type) => {
        if (type === 'OTHER' || type === 'VEHICLE' || type === 'REAL_ESTATE' || type === 'LOAN') {
            navigation.navigate('AddBalance', { type });
        }
        else {
            AddAccountModal.show({
                navigate: navigation.navigate,
                onOpenPlaid: () => {
                    const now = new Date().getTime();
                    if (!openPlaidTapTime.current || now - openPlaidTapTime.current > 1000) {
                        openPlaidTapTime.current = now;
                        openPlaid();
                    }
                },
                onAddOffline: () => {
                    navigation.navigate('PickAccountType', {});
                },
                onAddProperty: () => {
                    navigation.navigate('Property', {});
                },
            });
        }
    }, [openPlaid]);
    const openAlternative = useCallback((type) => {
        if (type === 'LOAN') {
            const flow = isGBUser ? 'monevo' : 'monevo_us';
            navigation.navigate('LoanAmount', { flow });
        }
        else if (type === 'SAVINGS') {
            navigation.navigate('Save', { tab: 'pots' });
        }
        else if (type === 'INVESTMENT') {
            navigation.navigate('Invest');
        }
    }, [isGBUser]);
    return {
        onRowPress,
        onRowLongPress,
        onOpenLogins,
        onOpenUpgrade,
        onAddAccount,
        openAlternative,
    };
};
export default useAccountCallbacks;
