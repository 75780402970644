import React from 'react';
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';
import { rem } from 'design-system/values';
const IconOtherMerchant = ({ width = rem(20), height = rem(20), ...props }) => (<Svg width={width} height={height} viewBox="0 0 20 20" fill="none" {...props}>
    <G clipPath="url(#clip0_12106_23508)">
      <Path d="M9.23166 1.96524C9.14494 0.92852 8.35939 0.110161 7.36486 0.0410201C6.76642 4.50669e-06 5.94494 -0.0195267 4.81955 0.0281295C3.69416 0.0757858 2.86994 0.165239 2.26564 0.256645C1.26525 0.410942 0.410957 1.26563 0.25666 2.26563C0.165254 2.86993 0.0781443 3.69454 0.0281443 4.81954C-0.0218557 5.94454 -0.000761972 6.76602 0.0410349 7.36485C0.110566 8.35938 0.928535 9.14493 1.96525 9.23164C2.59025 9.28243 3.44338 9.31836 4.6133 9.31485C5.78322 9.31133 6.63869 9.27071 7.26213 9.21446C7.76507 9.16971 8.23627 8.94956 8.59332 8.59252C8.95036 8.23548 9.1705 7.76428 9.21525 7.26133C9.2715 6.63829 9.31252 5.78282 9.31564 4.6125C9.31877 3.44219 9.28244 2.58829 9.23166 1.96524V1.96524Z" fill="white" fillOpacity="0.8"/>
      <Path d="M19.7434 2.26564C19.5891 1.26564 18.7344 0.410957 17.7344 0.25666C17.1301 0.165254 16.3055 0.0781443 15.1805 0.0281443C14.0555 -0.0218557 13.234 -0.000761972 12.6352 0.0410349C11.6406 0.110176 10.8551 0.928535 10.7684 1.96525C10.7176 2.59025 10.6817 3.44338 10.6852 4.6133C10.6887 5.78322 10.7293 6.63869 10.7856 7.26213C10.8302 7.7651 11.0504 8.23634 11.4074 8.5934C11.7645 8.95045 12.2357 9.17058 12.7387 9.21525C13.3637 9.2715 14.2172 9.31252 15.3875 9.31564C16.5578 9.31877 17.4125 9.28322 18.0356 9.23244C19.0719 9.14572 19.8903 8.35978 19.9598 7.36564C20.0016 6.76682 20.0203 5.94572 19.9727 4.82033C19.925 3.69494 19.8348 2.86994 19.7434 2.26564V2.26564Z" fill="white" fillOpacity="0.4"/>
      <Path d="M9.21446 12.7378C9.16978 12.2348 8.94966 11.7636 8.5926 11.4065C8.23555 11.0495 7.7643 10.8293 7.26133 10.7847C6.63633 10.7284 5.78282 10.6874 4.6125 10.6843C3.44219 10.6811 2.5875 10.7167 1.96446 10.7675C0.928911 10.855 0.110161 11.6405 0.0410201 12.6351C4.50669e-06 13.2339 -0.0195267 14.055 0.0281295 15.1804C0.0757858 16.3058 0.165239 17.13 0.256645 17.7343C0.410942 18.7343 1.26563 19.589 2.26563 19.7433C2.86875 19.8347 3.69336 19.9229 4.81836 19.9718C5.94336 20.0206 6.76485 20.0007 7.36368 19.9589C8.35743 19.8897 9.14375 19.071 9.23047 18.0347C9.28125 17.4116 9.31719 16.5565 9.31368 15.3866C9.31016 14.2167 9.27071 13.3593 9.21446 12.7378V12.7378Z" fill="white" fillOpacity="0.8"/>
      <Path d="M19.961 12.6351C19.8918 11.6413 19.0731 10.855 18.0367 10.7683C17.4137 10.7175 16.5586 10.6815 15.3887 10.6851C14.2188 10.6886 13.3629 10.7292 12.7399 10.7854C12.2369 10.8301 11.7656 11.0502 11.4086 11.4073C11.0515 11.7644 10.8314 12.2356 10.7867 12.7386C10.7305 13.3636 10.6895 14.2171 10.6863 15.3874C10.6832 16.5577 10.7188 17.4124 10.7696 18.0354C10.8563 19.0718 11.6426 19.8905 12.6363 19.9597C13.2352 20.0015 14.0563 20.0202 15.1817 19.9725C16.3071 19.9249 17.1313 19.8354 17.7344 19.744C18.736 19.5897 19.5903 18.7351 19.7445 17.7339C19.836 17.1308 19.9242 16.3061 19.9731 15.1811C20.0219 14.0561 20 13.2339 19.961 12.6351Z" fill="white" fillOpacity="0.8"/>
    </G>
    <Defs>
      <ClipPath id="clip0_12106_23508">
        <Rect width="20" height="20" fill="white"/>
      </ClipPath>
    </Defs>
  </Svg>);
export default IconOtherMerchant;
