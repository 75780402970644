import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconDiamondNew = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="ultimate">
      <Path d="M14.2889 14.1572H9.71191L12.0004 20.4505L14.2889 14.1572Z" fill={`url(#gradient_${id})`}/>
      <Path d="M15.5352 14.1572L12.5505 22.3653L12.5015 22.5001L21.6028 14.1572H15.5352Z" fill={`url(#gradient_${id})`}/>
      <Path d="M8.46451 14.1572H2.39697L11.4983 22.5001L8.46451 14.1572Z" fill={`url(#gradient_${id})`}/>
      <Path d="M6.06543 8.29785L8.87684 12.5149L11.6882 8.29785H6.06543Z" fill={`url(#gradient_${id})`}/>
      <Path d="M12.3154 8.29785L15.1268 12.5149L17.9382 8.29785H12.3154Z" fill={`url(#gradient_${id})`}/>
      <Path d="M12.0014 9.94043L9.97119 12.9857H14.0316L12.0014 9.94043Z" fill={`url(#gradient_${id})`}/>
      <Path d="M5.04688 8.88379L2.3125 12.9854H7.78125L5.04688 8.88379Z" fill={`url(#gradient_${id})`}/>
      <Path d="M18.9556 8.88379L16.2212 12.9854H21.6899L18.9556 8.88379Z" fill={`url(#gradient_${id})`}/>
      <Path d="M18.2427 6.84427C18.0927 6.84427 17.9427 6.78704 17.8284 6.67263C17.5995 6.44384 17.5995 6.07282 17.8284 5.84399L19.4856 4.18673C19.7144 3.95794 20.0854 3.95794 20.3143 4.18673C20.5431 4.41552 20.5431 4.78653 20.3143 5.01536L18.657 6.67263C18.5426 6.78704 18.3926 6.84427 18.2427 6.84427Z" fill={`url(#gradient_${id})`}/>
      <Path d="M12 6.01562C11.6764 6.01562 11.4141 5.75328 11.4141 5.42969V3.08594C11.4141 2.76234 11.6764 2.5 12 2.5C12.3236 2.5 12.5859 2.76234 12.5859 3.08594V5.42969C12.5859 5.75328 12.3236 6.01562 12 6.01562Z" fill={`url(#gradient_${id})`}/>
      <Path d="M5.7593 6.84423C5.60934 6.84423 5.45938 6.787 5.345 6.67259L3.68773 5.01536C3.45891 4.78657 3.45891 4.41556 3.68773 4.18673C3.91652 3.95794 4.28754 3.95794 4.51637 4.18673L6.17363 5.84399C6.40246 6.07278 6.40246 6.4438 6.17363 6.67263C6.05922 6.787 5.90926 6.84423 5.7593 6.84423Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
