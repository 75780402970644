export const ON_SUBMIT_FEEDBACK_REQUEST = 'ON_SUBMIT_FEEDBACK_REQUEST';
export const ON_SUBMIT_FEEDBACK_SUCCESS = 'ON_SUBMIT_FEEDBACK_SUCCESS';
export const ON_SUBMIT_FEEDBACK_FAILURE = 'ON_SUBMIT_FEEDBACK_FAILURE';
export const SET_TRANSACTIONS_REQUESTING = 'SET_TRANSACTIONS_REQUESTING';
export const TRANSACTIONS_REQUEST = 'TRANSACTIONS_REQUEST';
export const TRANSACTIONS_SUCCESS = 'TRANSACTIONS_SUCCESS';
export const TRANSACTIONS_FAILURE = 'TRANSACTIONS_FAILURE';
export const SEARCH_TRANSACTIONS_REQUEST = 'SEARCH_TRANSACTIONS_REQUEST';
export const SEARCH_TRANSACTIONS_SUCCESS = 'SEARCH_TRANSACTIONS_SUCCESS';
export const SEARCH_TRANSACTIONS_FAILURE = 'SEARCH_TRANSACTIONS_FAILURE';
export const LINKED_TRANSACTION_REQUEST = 'LINKED_TRANSACTION_REQUEST';
export const LINKED_TRANSACTION_SUCCESS = 'LINKED_TRANSACTION_SUCCESS';
export const LINKED_TRANSACTION_FAILURE = 'LINKED_TRANSACTION_FAILURE';
export const GET_ORIGINAL_TRANSACTION_REQUEST = 'GET_ORIGINAL_TRANSACTION_REQUEST';
export const GET_ORIGINAL_TRANSACTION_SUCCESS = 'GET_ORIGINAL_TRANSACTION_SUCCESS';
export const GET_ORIGINAL_TRANSACTION_FAILURE = 'GET_ORIGINAL_TRANSACTION_FAILURE';
export const SIMILAR_TRANSACTIONS_REQUEST = 'SIMILAR_TRANSACTIONS_REQUEST';
export const SIMILAR_TRANSACTIONS_SUCCESS = 'SIMILAR_TRANSACTIONS_SUCCESS';
export const SIMILAR_TRANSACTIONS_FAILURE = 'SIMILAR_TRANSACTIONS_FAILURE';
export const RESET_SIMILAR_TRANSACTIONS = 'RESET_SIMILAR_TRANSACTIONS';
export const IS_FETCHING_BANK_TRANSACTIONS = 'IS_FETCHING_BANK_TRANSACTIONS';
export const RESET_TRANSACTION = 'RESET_TRANSACTION';
export const SAVE_NOTE_REQUEST = 'SAVE_NOTE_REQUEST';
export const SAVE_NOTE_SUCCESS = 'SAVE_NOTE_SUCCESS';
export const SAVE_NOTE_FAILURE = 'SAVE_NOTE_FAILURE';
export const GET_TAGS_REQUEST = 'GET_TAGS_REQUEST';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_FAILURE = 'GET_TAGS_FAILURE';
export const SET_TAGS_REQUEST = 'SET_TAGS_REQUEST';
export const SET_TAGS_SUCCESS = 'SET_TAGS_SUCCESS';
export const SET_TAGS_FAILURE = 'SET_TAGS_FAILURE';
export const CHANGE_DATE_TRANSACTION_REQUEST = 'CHANGE_DATE_TRANSACTION_REQUEST';
export const CHANGE_DATE_TRANSACTION_SUCCESS = 'CHANGE_DATE_TRANSACTION_SUCCESS';
export const CHANGE_DATE_TRANSACTION_FAILURE = 'CHANGE_DATE_TRANSACTION_FAILURE';
export const RENAME_TRANSACTION_REQUEST = 'RENAME_TRANSACTION_REQUEST';
export const RENAME_TRANSACTION_SUCCESS = 'RENAME_TRANSACTION_SUCCESS';
export const RENAME_TRANSACTION_FAILURE = 'RENAME_TRANSACTION_FAILURE';
export const DELETE_TRANSACTION_REQUEST = 'DELETE_TRANSACTION_REQUEST';
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS';
export const DELETE_TRANSACTION_FAILURE = 'DELETE_TRANSACTION_FAILURE';
export const CREATE_TRANSACTION_REQUEST = 'CREATE_TRANSACTION_REQUEST';
export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS';
export const CREATE_TRANSACTION_FAILURE = 'CREATE_TRANSACTION_FAILURE';
export const GET_TRANSACTION_REQUEST = 'GET_TRANSACTION_REQUEST';
export const GET_TRANSACTION_SUCCESS = 'GET_TRANSACTION_SUCCESS';
export const GET_TRANSACTION_FAILURE = 'GET_TRANSACTION_FAILURE';
export const ADD_TRANSACTION_TO_CACHE = 'ADD_TRANSACTION_TO_CACHE';
export const GET_MERCHANT_STATS_REQUEST = 'GET_MERCHANT_STATS_REQUEST';
export const GET_MERCHANT_STATS_SUCCESS = 'GET_MERCHANT_STATS_SUCCESS';
export const GET_MERCHANT_STATS_FAILURE = 'GET_MERCHANT_STATS_FAILURE';
export const GET_RECEIPTS_REQUEST = 'GET_RECEIPTS_REQUEST';
export const GET_RECEIPTS_SUCCESS = 'GET_RECEIPTS_SUCCESS';
export const GET_RECEIPTS_FAILURE = 'GET_RECEIPTS_FAILURE';
export const DELETE_RECEIPT_REQUEST = 'DELETE_RECEIPT_REQUEST';
export const DELETE_RECEIPT_SUCCESS = 'DELETE_RECEIPT_SUCCESS';
export const DELETE_RECEIPT_FAILURE = 'DELETE_RECEIPT_FAILURE';
export const UPLOAD_RECEIPT_REQUEST = 'UPLOAD_RECEIPT_REQUEST';
export const UPLOAD_RECEIPT_SUCCESS = 'UPLOAD_RECEIPT_SUCCESS';
export const UPLOAD_RECEIPT_FAILURE = 'UPLOAD_RECEIPT_FAILURE';
export const SPLIT_TRANSACTION_REQUEST = 'SPLIT_TRANSACTION_REQUEST';
export const SPLIT_TRANSACTION_SUCCESS = 'SPLIT_TRANSACTION_SUCCESS';
export const SPLIT_TRANSACTION_FAILURE = 'SPLIT_TRANSACTION_FAILURE';
export const UNSPLIT_TRANSACTION_REQUEST = 'UNSPLIT_TRANSACTION_REQUEST';
export const UNSPLIT_TRANSACTION_SUCCESS = 'UNSPLIT_TRANSACTION_SUCCESS';
export const UNSPLIT_TRANSACTION_FAILURE = 'UNSPLIT_TRANSACTION_FAILURE';
