import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { rem } from 'design-system/values';
import { Icon, IconWithGradient } from './Icon';
export const IconFacebook = (props) => {
    const width = rem(24);
    const height = rem(24);
    const id = useId();
    if (props.alt) {
        return (<Icon width={width} height={height} {...props}>
        <Path d="M24.5 12C24.5 5.37308 19.1269 0 12.5 0C5.87308 0 0.5 5.37308 0.5 12C0.5 17.989 4.88757 22.9537 10.6252 23.8549V15.4696H7.57749V12H10.6252V9.35565C10.6252 6.34847 12.4173 4.68626 15.1579 4.68626C16.4707 4.68626 17.8444 4.92083 17.8444 4.92083V7.874H16.3307C14.8406 7.874 14.3748 8.79876 14.3748 9.74884V12H17.7026L17.1711 15.4696H14.3748V23.8549C20.1124 22.9554 24.5 17.9907 24.5 12Z" fill="#1977F3"/>
        <Path d="M17.1712 15.4696L17.7028 12H14.375V9.74886C14.375 8.80047 14.8391 7.87402 16.3308 7.87402H17.8445V4.92085C17.8445 4.92085 16.4709 4.68628 15.158 4.68628C12.4175 4.68628 10.6253 6.34681 10.6253 9.35566V12H7.57764V15.4696H10.6253V23.8549C11.2362 23.9511 11.8623 24 12.5002 24C13.138 24 13.7641 23.9494 14.375 23.8549V15.4696H17.1712Z" fill="#F8F9FF"/>
      </Icon>);
    }
    return (<IconWithGradient id={id} width={width} height={height} defaultGradientKey="skyBlue" {...props}>
      <Path fillRule="evenodd" clipRule="evenodd" d="M15 2c-1.651 0-3.207.503-4.352 1.648C9.503 4.793 9 6.35 9 8v2H8a2 2 0 1 0 0 4h1v6a2 2 0 1 0 4 0v-6h2a2 2 0 1 0 0-4h-2V8c0-.849.247-1.293.477-1.523C13.707 6.247 14.15 6 15 6h2a2 2 0 1 0 0-4h-2Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
