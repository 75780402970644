import React, { useCallback } from 'react';
import { CardView } from 'design-system/CardView';
import Section from 'design-system/Section';
import { IconWalletCoin } from 'design-system/icons/IconWalletCoin';
import { SettingsItemDescription, SettingsItemTitle, } from 'design-system/SettingsItem';
import { useRewards } from 'features/rewards/hooks/useRewards';
import { SettingsItemIcon } from 'design-system/SettingsItemIcon';
import createStyleSheets from 'utils/createStyleSheets';
import { Button, rem } from 'design-system';
import useStyles from 'hooks/useStyles';
import { View } from 'react-native';
import Amplitude from 'utils/amplitude';
const TransactionCashbackSection = ({ reward }) => {
    const styles = useStyles(styleSet);
    const { openReward } = useRewards();
    const onOpenReward = useCallback((reward) => {
        Amplitude.logEvent('Open Reward', {
            source: 'transaction_screen',
            reward,
        });
        openReward(reward);
    }, [openReward]);
    if (reward) {
        return (<Section title="Cashback" style={styles.section}>
        <CardView noMarginTop noMarginBottom withPadding>
          <SettingsItemIcon style={styles.icon}>
            <IconWalletCoin />
          </SettingsItemIcon>
          <SettingsItemTitle>Make free money</SettingsItemTitle>
          <SettingsItemDescription>
            {`Earn up to ${reward.rewardText} at ${reward.title} next time you shop online via the Emma cashback section.`}
          </SettingsItemDescription>
          <View style={styles.button}>
            <Button hug small square brandLight title="Get cashback" onPress={() => onOpenReward(reward)}/>
          </View>
        </CardView>
      </Section>);
    }
    return null;
};
const styleSet = createStyleSheets(() => ({
    section: {
        marginBottom: rem(24),
    },
    icon: {
        marginBottom: rem(20),
    },
    button: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: rem(20),
    },
}));
export default TransactionCashbackSection;
