import React, { useLayoutEffect } from 'react';
import { useAnimatedScrollHandler, useSharedValue } from 'react-native-reanimated';
import { View, StatusBar, Dimensions } from 'react-native';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { useHeaderHeight } from 'hooks';
import createStyleSheets from 'utils/createStyleSheets';
import { useNativeStackScreenOptions } from 'hooks/navigation/useStackNavigatorScreenOptions';
import BackgroundView from './BackgroundView';
import AnimatedWhiteBg from './AnimatedWhiteBg';
import ReanimatedStackHeader from '../ReanimatedStackHeader';
const withInverseNavigationHeader = (ScreenComponent, screenOptions, defaultHeaderHeight) => {
    const Wrapper = ({ navigation, ...props }) => {
        const scrollY = useSharedValue(0);
        const headerHeight = useHeaderHeight();
        const headerHeightSharedValue = useSharedValue(defaultHeaderHeight || Dimensions.get('screen').height);
        const { headerTitleStyle } = useNativeStackScreenOptions();
        const colors = useColors();
        const styles = useStyles(styleSet);
        const color = colors.gradients.brandGradient;
        const scrollHandler = useAnimatedScrollHandler((event) => {
            // eslint-disable-next-line no-param-reassign
            scrollY.value = event.contentOffset.y;
        });
        useLayoutEffect(() => {
            const headerTitleWidth = screenOptions?.hasHeaderRight ? rem(150) : '100%';
            navigation.setOptions({
                ...(!screenOptions.hasCustomTitle
                    ? {
                        headerTitle: () => screenOptions?.title ? (<ReanimatedStackHeader title={screenOptions?.title} scrollY={scrollY} width={headerTitleWidth}/>) : null,
                    }
                    : {}),
                headerTitleStyle: {
                    ...headerTitleStyle,
                },
            });
        }, [headerTitleStyle, scrollY]);
        return (<View style={styles.container}>
        <StatusBar barStyle="light-content" animated/>
        <BackgroundView scrollY={scrollY} color={color} navHeaderHeight={headerHeight} headerContainerHeight={headerHeightSharedValue}/>
        <View style={[{ marginTop: headerHeight }, styles.contentContainer]}>
          <AnimatedWhiteBg scrollY={scrollY} headerContainerHeight={headerHeightSharedValue}/>
          <ScreenComponent {...props} scrollY={scrollY} navigation={navigation} scrollHandler={scrollHandler} headerHeightSharedValue={headerHeightSharedValue}/>
        </View>
      </View>);
    };
    return Wrapper;
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
        backgroundColor: colors.background.light,
    },
    contentContainer: {
        flex: 1,
        overflow: 'hidden',
    },
}));
export default withInverseNavigationHeader;
