import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import useColors from 'hooks/useColors';
import { IconWithGradient } from '../Icon';
const IconStatusCancelled = ({ ...props }) => {
    const colors = useColors();
    const id = useId();
    return (<IconWithGradient id={id} {...props} width={18} height={18} viewBox="0 0 18 18" defaultGradientKey="negativeCards">
      <Path d="M0.286624 6.70701C0.706221 3.35023 3.35023 0.706221 6.70701 0.286624C8.22974 0.096282 9.77026 0.096282 11.293 0.286624C14.6498 0.706221 17.2938 3.35023 17.7134 6.70701C17.9037 8.22974 17.9037 9.77026 17.7134 11.293C17.2938 14.6498 14.6498 17.2938 11.293 17.7134C9.77026 17.9037 8.22974 17.9037 6.70701 17.7134C3.35023 17.2938 0.706221 14.6498 0.286624 11.293C0.096282 9.77026 0.096282 8.22974 0.286624 6.70701Z" fill={`url(#gradient_${id})`}/>
      <Path fillRule="evenodd" clipRule="evenodd" d="M7.27615 5.39051C6.75545 4.86981 5.91123 4.86981 5.39053 5.39051C4.86982 5.91121 4.86982 6.75543 5.39053 7.27613L7.11438 8.99999L5.3905 10.7239C4.8698 11.2446 4.8698 12.0888 5.3905 12.6095C5.9112 13.1302 6.75542 13.1302 7.27612 12.6095L9.00001 10.8856L10.7239 12.6095C11.2446 13.1302 12.0888 13.1302 12.6095 12.6095C13.1302 12.0888 13.1302 11.2446 12.6095 10.7239L10.8856 8.99999L12.6095 7.27615C13.1302 6.75545 13.1302 5.91123 12.6095 5.39053C12.0888 4.86982 11.2445 4.86982 10.7238 5.39053L9.00001 7.11437L7.27615 5.39051Z" fill={colors.background.light}/>
    </IconWithGradient>);
};
export default IconStatusCancelled;
