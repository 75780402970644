import { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Platform } from 'react-native';
import Modal from 'design-system/Modal';
import { getToken, signInV2, storeAccessAndRefreshToken, } from 'actions/signin';
import { getFeed } from 'actions/feed';
import useFetchOld from 'hooks/useFetch';
import { SIGN_IN_RESET } from 'actions/types';
import { storePasscode } from 'utils/passcode';
import { useAppDispatch, useAppStore } from 'store/hooks';
import { setPasscodeInKeychainSuccess } from 'actions/user';
import { getClientId, setClientId } from 'utils/jwtHelpers';
import { updateTradingAccount } from 'features/invest/api/account';
import { useLoginStackNavigation } from 'utils/types/navigationV6';
import { useLoadInitialData, } from './useLoadInitialData';
import useAppleSignIn from './useAppleSignIn';
import useGoogleSignIn from './useGoogleSignIn';
import useFacebookSignIn from './useFacebookSignIn';
import { useNavigateToNextScreen } from './useNavigateToNextScreen';
import { isWeb } from '../../constants';
const ALT_STEP_STRINGS = {
    apple: 'Apple',
    facebook: 'Facebook',
    google: 'Google',
    phone_number: 'Phone number',
    pin: 'Passcode',
    sms_otp: 'SMS',
    token: '',
    load_initial_data: 'load_initial_data', // client side only
};
const useSignIn = ({ reset, } = {}) => {
    const [, , , fetchSignIn] = useFetchOld({
        debug: false,
    });
    const [, , , fetchSignInThrows] = useFetchOld({
        debug: false,
        throws: true,
    });
    const [, , , fetchToken] = useFetchOld({ debug: false });
    const [isFetching, setIsFetching] = useState(null);
    const [googleSignIn] = useGoogleSignIn();
    const [facebookSignIn] = useFacebookSignIn();
    const [appleSignIn] = useAppleSignIn();
    const { loadInitialData } = useLoadInitialData();
    const dispatch = useAppDispatch();
    const navigation = useLoginStackNavigation();
    useEffect(() => {
        if (reset) {
            dispatch({
                type: SIGN_IN_RESET,
            });
        }
    }, [reset, dispatch]);
    const cachedClientId = useRef();
    const store = useAppStore();
    const navigateToNextScreen = useNavigateToNextScreen();
    const handleNextStep = useCallback(async (nextStep, params) => {
        switch (nextStep) {
            // None of the flows currently have facebook or apple as next or alternative step, but future-proofing
            case 'apple': {
                const result = await appleSignIn();
                if (result) {
                    const appleResult = await fetchSignIn(signInV2({
                        ...params,
                        apple: {
                            applicationType: Platform.OS === 'android' ? 'web' : 'ios',
                            authorizationCode: result.authorizationCode,
                        },
                    }));
                    if (appleResult && appleResult.nextStep !== 'apple') {
                        await handleNextStep(appleResult.nextStep, { ...params });
                    }
                }
                break;
            }
            case 'facebook': {
                const result = await facebookSignIn();
                if (result) {
                    const facebookResult = await fetchSignIn(signInV2({
                        ...params,
                        facebook: result,
                    }));
                    if (facebookResult && facebookResult.nextStep !== 'facebook') {
                        await handleNextStep(facebookResult.nextStep, { ...params });
                    }
                }
                break;
            }
            case 'google': {
                const result = await googleSignIn();
                if (result) {
                    const googleResult = await fetchSignIn(signInV2({
                        ...params,
                        google: {
                            token: result.token,
                        },
                    }));
                    if (googleResult && googleResult.nextStep !== 'google') {
                        await handleNextStep(googleResult.nextStep, { ...params });
                    }
                }
                break;
            }
            case 'phone_number':
                // forward to PhoneVerification
                navigation.replace('PhoneVerificationScreen', { flow: 'signIn' });
                break;
            case 'sms_otp':
                navigation.navigate('PhoneVerificationConfirmScreen', {
                    flow: 'signIn',
                    phoneNumber: params?.phoneNumber?.phoneNumber,
                    prefix: params?.phoneNumber?.countryCode,
                });
                break;
            case 'pin':
                navigation.navigate('SignInPasscodeScreen', {
                    flow: 'sign-in',
                });
                break;
            case 'token':
                // we're ready to sign in, call token endpoint
                const tokenResponse = await fetchToken(getToken(params));
                if (tokenResponse) {
                    // sign in complete
                    await storeAccessAndRefreshToken(tokenResponse);
                    await handleNextStep('load_initial_data', { ...params });
                }
                break;
            case 'load_initial_data':
                const onSuccess = async ({ user }) => {
                    if (!isWeb) {
                        // no need for try catch because storePasscode doesnt throw
                        const result = await storePasscode(undefined, user.user.id);
                        if (result) {
                            /**
                             * There was no pin stored in the storePasscode generator
                             * we store the pin if it is present in params
                             *
                             * case of phone number -> pin -> sms code
                             */
                            if (user.user.pinLastChangedAt) {
                                dispatch(setPasscodeInKeychainSuccess(user.user.pinLastChangedAt));
                            }
                        }
                        else if (params.pin) {
                            /**
                             * There was no pin stored in the storePasscode generator
                             * we store the pin if it is present in params
                             *
                             * case of phone number -> sms code -> pin
                             */
                            const result = await storePasscode(params.pin, user.user.id.toString());
                            if (result && user.user.pinLastChangedAt) {
                                dispatch(setPasscodeInKeychainSuccess(user.user.pinLastChangedAt));
                            }
                        }
                        updateTradingAccount(true);
                    }
                    dispatch(getFeed(true));
                    navigateToNextScreen();
                };
                const onError = (e) => {
                    Modal.showError(e.message, () => {
                        signIn({
                            loadInitialData: true,
                            clientId: params.clientId,
                            pin: params.pin,
                        });
                    }, undefined, undefined, undefined);
                };
                await loadInitialData(onSuccess, onError);
                break;
            default:
                Alert.alert('Error', 'No available login methods, please ensure your app is up to date.');
        }
    }, 
    // Need to avoid a loop here
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
        facebookSignIn,
        googleSignIn,
        fetchToken,
        appleSignIn,
        fetchSignIn,
        loadInitialData,
    ]);
    const alternativeStep = useCallback(async (fallback) => {
        const { signInParamters, currentState } = store.getState().signIn;
        if (currentState && signInParamters) {
            const handleAlternativeStep = async (alternativeStep) => {
                if (alternativeStep === 'sms_otp') {
                    const sendResult = await fetchSignIn(signInV2({
                        ...signInParamters,
                        clientId: currentState.clientId,
                        sendSmsOtp: true,
                    }));
                    if (sendResult) {
                        handleNextStep(sendResult?.nextStep, {
                            ...signInParamters,
                            clientId: currentState.clientId,
                        });
                    }
                }
                else {
                    handleNextStep(alternativeStep, {
                        ...signInParamters,
                        clientId: currentState.clientId,
                    });
                }
            };
            if (currentState.alternativeSteps &&
                currentState.alternativeSteps.length > 1) {
                Alert.alert('Sign in another way', 'You can sign in using these methods', [
                    ...currentState.alternativeSteps.map((s) => ({
                        text: ALT_STEP_STRINGS[s],
                        onPress: () => handleAlternativeStep(s),
                    })),
                    {
                        text: 'Cancel',
                        style: 'cancel',
                    },
                ]);
            }
            else if (currentState.alternativeSteps &&
                currentState.alternativeSteps.length === 1) {
                return handleAlternativeStep(currentState.alternativeSteps[0]);
            }
            else if (fallback) {
                // No alternative steps available use the fallback
                fallback();
            }
            else {
                navigation.navigate('Support', {});
            }
        }
        return undefined;
    }, [handleNextStep, navigation, fetchSignIn]);
    const signIn = useCallback(async (params) => {
        if (reset) {
            // Since this is the 'start' of the flow we must reset the state
            dispatch({
                type: SIGN_IN_RESET,
            });
        }
        const { signInParamters } = store.getState().signIn;
        let clientId = cachedClientId.current;
        if (signInParamters && !clientId) {
            // If we're half way through a sign in flow we should already have
            clientId = (await getClientId()) || undefined;
            cachedClientId.current = clientId;
        }
        if (params.apple) {
            setIsFetching('apple');
        }
        else if (params.facebook) {
            setIsFetching('facebook');
        }
        else if (params.google) {
            setIsFetching('google');
        }
        else if (params.pin) {
            setIsFetching('pin');
        }
        else if (params.smsOtp) {
            setIsFetching('sms_otp');
        }
        else if (params.loadInitialData) {
            setIsFetching('load_initial_data');
        }
        const postParams = reset
            ? params
            : {
                ...signInParamters,
                ...params,
                clientId,
            };
        try {
            if (params.loadInitialData) {
                await handleNextStep('load_initial_data', {
                    loadInitialData: true,
                    clientId,
                    pin: params.pin,
                });
                return false;
            }
            // For pin or smsOtp we want this to throw, so we can handle inline errors
            const result = await (params.pin || params.smsOtp
                ? fetchSignInThrows(signInV2(postParams, true))
                : fetchSignIn(signInV2(postParams)));
            if (!result) {
                return false;
            }
            if (result.clientId !== clientId) {
                clientId = result.clientId;
                cachedClientId.current = clientId;
                setClientId(result.clientId);
            }
            await handleNextStep(result.nextStep, {
                ...signInParamters,
                ...params,
                clientId,
            });
        }
        finally {
            setIsFetching(null);
        }
        return true;
    }, [reset, fetchSignInThrows, fetchSignIn, handleNextStep]);
    return [signIn, isFetching, alternativeStep, handleNextStep];
};
export default useSignIn;
