import React, { memo, useCallback, useContext, useMemo } from 'react';
import { parseISO, format } from 'date-fns';
import Spacer from 'design-system/Spacer';
import { CalendarRange } from 'design-system/calendar/CalendarRange';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import { formatPeriodDate } from 'utils/formatting';
import { minDate } from './constants';
import { DateFilterContext } from './context';
import { isWeb } from '../../constants';
const CustomContent = () => {
    const styles = useStyles(styleSet);
    const { to, from, setTo, setFrom, todayDateStr } = useContext(DateFilterContext);
    const onChangeRange = useCallback((range) => {
        const fromDate = range[0] ? parseISO(range[0]) : undefined;
        const toDate = range[1] ? parseISO(range[1]) : undefined;
        setFrom(fromDate);
        setTo(toDate);
    }, [setFrom, setTo]);
    const title = useMemo(() => (from && to ? formatPeriodDate({ from, to }) : ''), [from, to]);
    const initalDates = useMemo(() => 
    // eslint-disable-next-line no-nested-ternary
    from
        ? to
            ? [format(from, 'yyyy-MM-dd'), format(to, 'yyyy-MM-dd')]
            : [format(from, 'yyyy-MM-dd')]
        : undefined, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    return (<>
      <Spacer h={16}/>
      <CalendarRange hideDays firstDay={1} showReset showDisabled title={title} blackMonthText minDate={minDate} maxDate={todayDateStr} initialDates={initalDates} onChangeRange={onChangeRange} style={styles.calendarContainer} headerStyle={styles.headerStyle}/>
      <Spacer h={16}/>
    </>);
};
export default memo(CustomContent);
const styleSet = createStyleSheets((colors) => ({
    calendarContainer: {
        maxWidth: '100%',
        backgroundColor: isWeb ? colors.background.light : colors.cards.onDark,
    },
    headerStyle: {
        paddingHorizontal: 0,
    },
}));
