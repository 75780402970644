import React from 'react';
import { View } from 'react-native';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { rem } from './values';
export const Separator = ({ style, thick }) => {
    const styles = useStyles(styleSet);
    return <View style={[styles.separator, thick && styles.thick, style]}/>;
};
const styleSet = createStyleSheets((colors) => ({
    separator: {
        height: rem(1),
        backgroundColor: colors.background.dark,
    },
    thick: {
        height: rem(2),
    },
}));
export default Separator;
