import React, { useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import Indicator from 'design-system/Indicator';
import { AppView } from 'design-system/AppView';
import Code404Card from 'design-system/Code404Card';
import { useAppSelector } from 'store/hooks';
import { getTransaction } from 'actions/transactions';
import useFetchOld, { muteErrorAndResult } from 'hooks/useFetch';
import { isWeb } from '../../../constants';
import { makeSelectTransactionById } from '../selectors';
/**
 *
 * This hook tries to select transaction from transactionId
 * If transaction doesnt exist in reducer, it tries to get the transaction using transactionId
 *
 */
export const useTransaction = (transactionId) => {
    const [, , requesting, fetch] = useFetchOld(muteErrorAndResult);
    const memoSelectTransactionById = useMemo(makeSelectTransactionById, []);
    const transaction = useAppSelector((store) => memoSelectTransactionById(store, transactionId));
    const isInitialTransactionDefinedRef = useRef(!!transaction);
    const [shouldFetchInitialTxn, setShouldFetchInitialTxn] = useState(!Number.isNaN(transactionId) ? !transaction : false);
    useEffect(() => {
        /**
         * Fetching the txn if txn isnt already cached
         */
        if (!transaction && transactionId) {
            const getTxn = async () => {
                await fetch(getTransaction(transactionId));
                setShouldFetchInitialTxn(false);
            };
            getTxn();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const LoadingOr404 = useMemo(() => (<AppView isInTab={isWeb} avoidHeader>
        {requesting || shouldFetchInitialTxn ? (<View style={styles.indicatorContainer}>
            <Indicator dark/>
          </View>) : (<Code404Card />)}
      </AppView>), [requesting, shouldFetchInitialTxn]);
    return {
        transaction,
        LoadingOr404,
        isInitialTransactionDefinedRef,
    };
};
const styles = StyleSheet.create({
    indicatorContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
