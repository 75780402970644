import React, { useCallback } from 'react';
import { View, TouchableHighlight } from 'react-native';
import { rem } from 'design-system';
import Text from 'design-system/Text';
import { CIRCLE_SIZE } from 'design-system/LogoBankAccount';
import useColors from 'hooks/useColors';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import Amount from 'design-system/Amount';
import { IconEmma } from 'design-system/icons';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { isWeb } from '../../../constants';
/**
 * If you need to show this within a card, use `onCard`, which makes logo icons use darker background in dark mode.
 */
const RowEmmaInvestAccount = ({ data, style, onCard }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const { navigate } = useMainStackNavigation();
    const handlePress = useCallback(() => {
        navigate('Invest');
    }, []);
    return (<TouchableHighlight underlayColor={colors.background.underlay} activeOpacity={1} onPress={handlePress} disabled={isWeb}>
      <View style={[styles.rowAccount, style]}>
        <View style={[
            styles.container,
            onCard && styles.onCard, // in dark mode, when shown on a card, should have darker background
        ]}>
          <IconEmma gradientKey="ultimate"/>
        </View>
        <View style={styles.textContainer}>
          <Text Text-16 numberOfLines={1} ellipsizeMode="tail">
            {data.name}
          </Text>
        </View>
        <Amount Numbers-16 value={data.balance}/>
      </View>
    </TouchableHighlight>);
};
const styleSet = createStyleSheets((colors) => ({
    rowAccount: {
        paddingTop: rem(16),
        alignItems: 'center',
        flexDirection: 'row',
        paddingBottom: rem(16),
    },
    textContainer: {
        flex: 1,
        flexGrow: 1,
        justifyContent: 'center',
        paddingHorizontal: rem(16),
    },
    container: {
        alignItems: 'center',
        borderRadius: rem(22),
        width: rem(CIRCLE_SIZE),
        justifyContent: 'center',
        height: rem(CIRCLE_SIZE),
        backgroundColor: colors.cards.onLight,
    },
    onCard: {
        backgroundColor: colors.background.dark,
    },
}));
export default RowEmmaInvestAccount;
