import React from 'react';
import { StyleSheet } from 'react-native';
import { logout } from 'actions';
import { SHOW_LOGOUT } from 'config/debug';
import { SettingsItem, SettingsItemBase, SettingsItemRightContent, } from 'design-system/SettingsItem';
import { Indicator } from 'design-system/Indicator';
import { rem } from 'design-system/values';
import { SettingsSection } from 'design-system/SettingsSection';
import * as Icons from 'design-system/icons';
import { refundStripeSubscription } from 'features/premium/actions/stripe';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useRestoreInAppPurchases } from 'hooks';
import { useMarginBottom } from 'hooks/useMarginBottom';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import DeleteModal from '../delete-account/DeleteModal';
import { ManageSubscriptionsItem } from './ManageSubscriptionsItem';
import { isWeb } from '../../../constants';
const strings = {
    deleteAccount: {
        title: 'Delete Account',
    },
    restorePurchases: {
        title: 'Restore in-app purchases',
    },
};
export const DeleteAccountSettingsSection = () => {
    const { navigate } = useMainStackNavigation();
    const dispatch = useAppDispatch();
    const refundableAmount = useAppSelector((store) => store.user.user.emmaProStatus.refundableAmount?.amount);
    const { openRestorePurchases, isFetching } = useRestoreInAppPurchases();
    const paddingBottomStyle = useMarginBottom('paddingBottom', undefined, styles.marginTop);
    return (<SettingsSection style={paddingBottomStyle} noMarginTop>
      {!isWeb && (<>
          <ManageSubscriptionsItem />
          <SettingsItemBase title={strings.restorePurchases.title} onPress={openRestorePurchases} icon={<Icons.IconArrowBack gradientKey="lightPink"/>}>
            <SettingsItemRightContent>
              {isFetching && <Indicator dark/>}
            </SettingsItemRightContent>
          </SettingsItemBase>
          <SettingsItem title={strings.deleteAccount.title} onPress={() => {
                DeleteModal.show({
                    onPressDelete: () => {
                        navigate('DeleteReasons');
                    },
                    onPressNotDelete: () => { },
                });
            }} icon={<Icons.IconTrash />}/>
        </>)}

      {__DEV__ && SHOW_LOGOUT && !isWeb && !!refundableAmount && (<SettingsItem title="Refund" onPress={() => {
                dispatch(refundStripeSubscription({
                    amount: refundableAmount,
                    cancelImmediately: true,
                }));
            }} icon={<Icons.IconArrowBack />}/>)}
      {(isWeb || (__DEV__ && SHOW_LOGOUT)) && (<SettingsItem title="Logout" onPress={() => {
                dispatch(logout());
            }} icon={<Icons.IconLogout />}/>)}
    </SettingsSection>);
};
const styles = StyleSheet.create({
    marginTop: {
        marginTop: rem(16),
    },
});
