export const SET_IAP_SUBSCRIPTIONS = 'SET_IAP_SUBSCRIPTIONS';
export const SET_IS_ELIGIBLE_FOR_INTRO_OFFER = 'SET_IS_ELIGIBLE_FOR_INTRO_OFFER';
export const IAP_PURCHASE_FLOW_STARTED = 'IAP_PURCHASE_FLOW_STARTED';
export const IAP_PURCHASE_FLOW_ENDED_WITH_SUCCESS = 'IAP_PURCHASE_FLOW_ENDED_WITH_SUCCESS';
export const IAP_PURCHASE_FLOW_ENDED_WITH_ERROR = 'IAP_PURCHASE_FLOW_ENDED_WITH_ERROR';
export const setIapSubscriptions = (payload) => ({
    type: SET_IAP_SUBSCRIPTIONS,
    payload,
});
export const setIsEligibleForIntroOffer = (payload) => ({
    type: SET_IS_ELIGIBLE_FOR_INTRO_OFFER,
    payload,
});
export const setIAPPurchaseFlowStarted = (payload) => ({
    type: IAP_PURCHASE_FLOW_STARTED,
    payload,
});
export const iapPurchaseFlowEndedWithError = () => ({
    type: IAP_PURCHASE_FLOW_ENDED_WITH_ERROR,
});
