import { NETWORK_UPDATE_STATE } from '../actions/types';
export const network = (state = {
    isConnected: true,
    isInternetReachable: true,
}, action) => {
    switch (action.type) {
        case NETWORK_UPDATE_STATE:
            return action.payload;
        default:
            return state;
    }
};
