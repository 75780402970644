const checkFeatureFlag = (flags, key, value, negativeValue) => {
    if (flags) {
        const flag = flags[key];
        if (flag) {
            if ((value !== undefined && flag.value === value) ||
                (negativeValue !== undefined &&
                    flag.value !== undefined &&
                    flag.value !== negativeValue) ||
                (value === undefined && flag.value === 'yes')) {
                return true;
            }
        }
    }
    return false;
};
export default checkFeatureFlag;
