import React from 'react';
import Text from 'design-system/Text';
import { View } from 'react-native';
import styleSet from 'features/feed/components/search/styles';
import Amount from 'design-system/Amount';
import useStyles from 'hooks/useStyles';
const strings = {
    totalSpent: 'Total',
    total: 'Total',
};
const TotalAmountRow = ({ totalAmount, currency }) => {
    const styles = useStyles(styleSet);
    return (<View style={[styles.searchHeaderRow, styles.totalSpentContainer]}>
      <Text Text-16 Gray>
        {totalAmount > 0 ? strings.total : strings.totalSpent}
      </Text>
      {totalAmount > 0 ? (<Amount Blue Numbers-16 value={{ value: totalAmount, currency }} showSign/>) : (<Amount OffBlack Numbers-16 value={{ value: totalAmount, currency }} showSign/>)}
    </View>);
};
export default TotalAmountRow;
