import React, { useId } from 'react';
import { G, Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconTechnology = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="white">
      <G clipPath="url(#clip0_415_3613)">
        <Path fill={`url(#gradient_${id})`} d="M4.96912 9.07129C6.04943 9.07129 7.03822 8.4802 7.54974 7.52863C7.8124 7.04168 8.39091 6.72754 9.02377 6.72754H10.2895C10.9223 6.72754 11.5008 7.04168 11.7629 7.52805C12.275 8.4802 13.2638 9.07129 14.3441 9.07129C15.9595 9.07129 17.2738 7.75695 17.2738 6.1416C17.2738 4.52625 15.9595 3.21191 14.3441 3.21191C13.2638 3.21191 12.275 3.80301 11.7635 4.75457C11.5008 5.24152 10.9223 5.55566 10.2895 5.55566H9.02377C8.39091 5.55566 7.8124 5.24152 7.55033 4.75516C7.03822 3.80301 6.04943 3.21191 4.96912 3.21191C3.35376 3.21191 2.00037 4.52625 2.00037 6.1416C2.00037 7.75695 3.35376 9.07129 4.96912 9.07129ZM13.7582 5.55566H14.9301V6.72754H13.7582V5.55566Z"/>
        <Path fill={`url(#gradient_${id})`} d="M4.9693 20.7905C6.04961 20.7905 7.0384 20.1994 7.54992 19.2479C7.81258 18.7609 8.3911 18.4468 9.02395 18.4468H10.2897C10.9225 18.4468 11.501 18.7609 11.7631 19.2473C12.2752 20.1994 13.264 20.7905 14.3443 20.7905C15.9597 20.7905 17.274 19.4762 17.274 17.8608C17.274 16.2455 15.9597 14.9312 14.3443 14.9312C13.264 14.9312 12.2752 15.5222 11.7637 16.4738C11.501 16.9608 10.9225 17.2749 10.2897 17.2749H9.02395C8.3911 17.2749 7.81258 16.9608 7.55051 16.4744C7.0384 15.5222 6.04961 14.9312 4.9693 14.9312C3.35395 14.9312 2.00055 16.2455 2.00055 17.8608C2.00055 19.4762 3.35395 20.7905 4.9693 20.7905ZM13.7584 17.2749H14.9302V18.4468H13.7584V17.2749Z"/>
        <Path fill={`url(#gradient_${id})`} d="M16.4516 10.6137C16.1889 11.1007 15.6104 11.4148 14.9776 11.4148H13.7119C13.079 11.4148 12.5005 11.1007 12.2384 10.6143C11.7263 9.66214 10.7375 9.07104 9.65723 9.07104C8.04188 9.07104 6.72754 10.3854 6.72754 12.0007C6.72754 13.6161 8.04188 14.9304 9.65723 14.9304C10.7375 14.9304 11.7263 14.3393 12.2379 13.3878C12.5005 12.9008 13.079 12.5867 13.7119 12.5867H14.9776C15.6104 12.5867 16.1889 12.9008 16.451 13.3872C16.9631 14.3393 17.9519 14.9304 19.0322 14.9304C20.6476 14.9304 22.001 13.6161 22.001 12.0007C22.001 10.3854 20.6476 9.07104 19.0322 9.07104C17.9519 9.07104 16.9631 9.66214 16.4516 10.6137ZM10.2432 12.5867H9.07129V11.4148H10.2432V12.5867Z"/>
      </G>
    </IconWithGradient>);
};
