import React from 'react';
import { StyleSheet } from 'react-native';
import { CardView } from 'design-system/CardView';
const Card = ({ webPadding, children, withPadding, style, }) => (<CardView withPadding={withPadding} webPadding={webPadding} style={[styles.container, style]}>
    {children}
  </CardView>);
const styles = StyleSheet.create({
    container: {
        margin: 0,
    },
});
export default Card;
