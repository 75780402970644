import React, { memo, useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View, } from 'react-native';
import Animated, { interpolate, interpolateColor, useAnimatedStyle, } from 'react-native-reanimated';
import { styles as textStyles } from 'design-system/Text';
import useColors from 'hooks/useColors';
import { rem } from './values';
const SelectorControllableReanimated = React.memo(({ sections, style, selectorStyle, itemContainerStyle, itemStyle, scrollX, screenWidth, setSelection, spaceBetween = 0, withBackground, }) => {
    const itemsLayoutsTranslates = useMemo(() => {
        if (sections.length < 2) {
            return [0, 0];
        }
        const layouts = sections.map((sc) => sc.width || 0);
        return layouts.map((_, index) => layouts.slice(0, index).reduce((cv, sum) => sum + cv + spaceBetween, 0));
    }, [sections, spaceBetween]);
    const interpolateInput = useMemo(() => Array(sections.length < 2 ? 2 : sections.length)
        .fill(0)
        .map((_, i) => i * screenWidth), [screenWidth, sections.length]);
    const colors = useColors();
    const backgroundColors = useMemo(() => {
        if (sections.length < 2) {
            return [
                sections[0]?.color || colors.elements.primary,
                colors.elements.primary,
            ];
        }
        return sections.map((s) => s.color || colors.elements.primary);
    }, [colors.elements.primary, sections]);
    const widthOutput = useMemo(() => Array(sections.length < 2 ? 2 : sections.length)
        .fill(0)
        .map((_, i) => {
        const sc = sections[i];
        return sc ? sc.width || 0 : 0;
    }), [sections]);
    const transformStyle = useAnimatedStyle(() => ({
        width: interpolate(scrollX.value, interpolateInput, widthOutput),
        transform: [
            {
                translateX: interpolate(scrollX.value, interpolateInput, itemsLayoutsTranslates),
            },
        ],
        backgroundColor: interpolateColor(scrollX.value, interpolateInput, backgroundColors),
    }), [itemsLayoutsTranslates, interpolateInput, widthOutput]);
    return (<View style={[styles.container, style]}>
        {withBackground && (<View style={[
                styles.itemContainer,
                StyleSheet.absoluteFill,
                itemContainerStyle,
            ]}>
            {sections.map((section) => (<View key={section.id} style={[
                    itemStyle,
                    {
                        marginRight: spaceBetween,
                        width: section.width,
                        backgroundColor: colors.cards.onDark,
                    },
                ]}/>))}
          </View>)}
        <Animated.View style={[styles.selector, selectorStyle, transformStyle]}/>
        <View style={[styles.itemContainer, itemContainerStyle]}>
          {sections.map((section, index) => (<TouchableOpacity onPress={() => setSelection(index, section)} key={section.id}>
              <View style={[
                styles.item,
                { marginRight: spaceBetween, width: section.width },
                itemStyle,
            ]}>
                <View style={styles.itemInnerContainer}>
                  <SelectorText scrollX={scrollX} index={index} screenWidth={screenWidth} selectedColor={section.selectedTextColor} unselectedColor={section.unSelectedTextColor}>
                    {section.text}
                  </SelectorText>
                  {section.rightIcon && (<View style={styles.rightIconWrapper}>
                      {section.rightIcon}
                    </View>)}
                </View>
              </View>
            </TouchableOpacity>))}
        </View>
      </View>);
});
export const SelectorText = React.memo(({ index, children, scrollX, screenWidth, selectedColor, unselectedColor, }) => {
    const inputRange = useMemo(() => [
        screenWidth * index - screenWidth * 0.95,
        screenWidth * index,
        screenWidth * index + screenWidth * 1.05,
    ], [index, screenWidth]);
    const style = useAnimatedStyle(() => ({
        color: interpolateColor(scrollX.value, inputRange, [
            unselectedColor,
            selectedColor,
            unselectedColor,
        ]),
    }), [
        inputRange[0],
        inputRange[1],
        inputRange[2],
        unselectedColor,
        selectedColor,
    ]);
    return (<Animated.Text style={[textStyles.Text_14, style]} maxFontSizeMultiplier={2}>
        {children}
      </Animated.Text>);
});
export default memo(SelectorControllableReanimated);
const styles = StyleSheet.create({
    container: {
        width: '100%',
        borderRadius: rem(20),
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        paddingVertical: rem(2),
    },
    selector: {
        position: 'absolute',
        left: rem(0),
        top: rem(0),
        bottom: rem(0),
    },
    item: {
        borderRadius: rem(28),
        paddingVertical: rem(8),
        alignItems: 'center',
        justifyContent: 'center',
    },
    itemContainer: {
        flexDirection: 'row',
        flex: 1,
    },
    rightIconWrapper: {
        marginLeft: rem(8),
    },
    itemInnerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
});
