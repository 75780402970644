import { rem } from 'design-system/values';
import IconCategory from './icons/toolbar/IconCategory';
import IconMerchant from './icons/toolbar/IconMerchant';
import IconTag from './icons/toolbar/IconTag';
import IconAmountUpper from './icons/toolbar/IconAmountUpper';
import IconAmountLower from './icons/toolbar/IconAmountLower';
import IconDates from './icons/toolbar/IconDates';
import IconReceipt from './icons/toolbar/IconReceipt';
import IconAccount from './icons/toolbar/IconAccount';
export const TOOLBAR_BUTTONS = [
    {
        Icon: IconCategory,
        keyboardName: 'KeyboardCategories',
    },
    {
        Icon: IconMerchant,
        keyboardName: 'KeyboardMerchants',
    },
    {
        Icon: IconTag,
        keyboardName: 'KeyboardTags',
    },
    {
        Icon: IconAmountLower,
        keyboardName: 'KeyboardAmountLower',
    },
    {
        Icon: IconAmountUpper,
        keyboardName: 'KeyboardAmountUpper',
    },
    {
        Icon: IconDates,
        keyboardName: 'KeyboardDates',
    },
    {
        Icon: IconReceipt,
        keyboardName: 'KeyboardReceipts',
    },
    {
        Icon: IconAccount,
        keyboardName: 'KeyboardAccounts',
    },
];
export const MARGIN_HORIZONTAL = rem(16);
export const INNER_MARGIN = rem(8);
export const TOOLBAR_PADDING_VERTICAL = rem(8);
export const calculateSize = (width) => {
    const innerWidth = width - 2 * MARGIN_HORIZONTAL;
    const allMargins = INNER_MARGIN * (TOOLBAR_BUTTONS.length - 1);
    return (innerWidth - allMargins) / TOOLBAR_BUTTONS.length;
};
