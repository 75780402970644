import { REHYDRATE } from 'redux-persist';
import * as types from '../actions/types';
const initialState = {
    recommendations: [],
};
const recommendations = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE:
            const recommendations = action.payload?.recommendations;
            if (recommendations)
                return { ...initialState };
            return state;
        case types.GET_RECOMMENDATIONS_SUCCESS:
            return { ...state, recommendations: action.payload.recommendations };
        case types.DISMISS_RECOMMENDATIONS_SUCCESS: {
            return {
                ...state,
                recommendations: state.recommendations.filter((r) => r.id !== action.payload.recommendation.id),
            };
        }
        case types.LOGGED_OUT: {
            return initialState;
        }
        default:
            return state;
    }
};
export default recommendations;
