import emoji from 'node-emoji';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Platform, View } from 'react-native';
import Alert from 'utils/packages/Alert';
import { AppView } from 'design-system/AppView';
import Button from 'design-system/Button';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import StatusBar from 'design-system/StatusBar';
import { styles as textStyles } from 'design-system/Text';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { useMarginBottom } from 'hooks/useMarginBottom';
import createStyleSheets from 'utils/createStyleSheets';
import { useAutoFocusInput } from 'hooks/useAutoFocusInput';
import KeyboardAvoidingView from 'design-system/KeyboardAvoidingView';
import TextInput from 'utils/packages/TextInput';
import { useAppSelector } from 'store/hooks';
import { selectAccountById } from 'reducers/selectors';
import usePrivateRoute from 'hooks/usePrivateRoute';
import { isWeb } from '../../../constants';
const strings = {
    screenTitle: 'Name the account',
    rename: 'Rename',
    placeholder: 'Choose Name',
    next: 'Next',
    errorTitle: 'Slow down :face_with_rolling_eyes:',
    errorMessage: 'You must enter a name.',
};
const DummyAccountSetupScreen = ({ navigation, route }) => {
    usePrivateRoute();
    const colors = useColors();
    const styles = useStyles(styleSet);
    const name = useAppSelector((store) => selectAccountById(store, route.params.id)?.name);
    useLayoutEffect(() => {
        navigation.setOptions({
            headerTintColor: colors.text.primary,
            title: route.params.isChangingName ? strings.rename : strings.screenTitle,
        });
    }, [colors.text.primary, route.params.isChangingName]);
    const [value, setValue] = useState(name || '');
    useEffect(() => {
        if (name && !value) {
            setValue(name);
        }
    }, [name]);
    const textInputRef = useRef(null);
    useAutoFocusInput(textInputRef);
    const onSubmit = useCallback(async () => {
        if (value.length < 1) {
            Alert.alert(emoji.emojify(strings.errorTitle), strings.errorMessage);
            return;
        }
        const keyBack = route.params.keyBack || (isWeb ? undefined : route.key);
        const { isChangingName } = route.params;
        const params = {
            ...route.params,
            keyBack,
            name: value,
        };
        if (isChangingName) {
            navigation.navigate('DummyAccountCreated', params);
        }
        else {
            navigation.navigate('DummyAccountLogoType', params);
        }
    }, [route.key, route.params, value]);
    const onChangeText = useCallback((text) => {
        setValue(text);
    }, []);
    useAutoFocusInput(textInputRef);
    const textInput = useMemo(() => (<TextInput ref={textInputRef} style={styles.textInput} value={value} placeholder={strings.placeholder} placeholderTextColor={colors.text.placeholder} selectionColor={colors.text.selection} underlineColorAndroid="transparent" textAlign="center" autoCapitalize="words" onChangeText={onChangeText} onSubmitEditing={onSubmit} focusStyle={styles.textInputFocus}/>), [colors, styles, value, onChangeText, onSubmit]);
    const paddingBottom = useMarginBottom('paddingBottom');
    const button = useMemo(() => (<View style={[styles.buttonContainer, paddingBottom]}>
        <Button brand title={strings.next} onPress={onSubmit}/>
      </View>), [styles.buttonContainer, paddingBottom, onSubmit]);
    return (<AppView type={isWeb ? 'secondary' : 'primary'} isInTab={isWeb} avoidHeader={isWeb}>
      <StatusBar barStyle="dark-content" animated/>
      {Platform.OS === 'android' ? (<View style={styles.container}>
          <Spacer flex/>
          {textInput}
          <Spacer flex/>
          {button}
        </View>) : (<KeyboardAvoidingView style={styles.container} behavior="padding">
          {isWeb ? <Spacer h={72}/> : <Spacer flex/>}
          {textInput}
          <Spacer flex/>
          {button}
        </KeyboardAvoidingView>)}
    </AppView>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
        ...(isWeb && {
            paddingHorizontal: rem(16),
        }),
    },
    header: {
        backgroundColor: 'transparent',
    },
    buttonContainer: {
        paddingHorizontal: isWeb ? 0 : rem(16),
    },
    textInputFocus: {
        color: colors.text.primary,
    },
    textInput: {
        ...(isWeb
            ? {
                paddingVertical: rem(16),
                color: colors.text.secondary,
            }
            : {
                ...textStyles.TextThin_20,
                alignSelf: 'stretch',
                color: colors.text.primary,
                fontSize: rem(26),
                lineHeight: undefined,
            }),
    },
    stretch: {
        alignSelf: 'stretch',
    },
}));
export default DummyAccountSetupScreen;
