import { useCallback } from 'react';
import { benefits } from '../benefits';
import { useIsTierUnlockedForSpace } from './useIsTierUnlockedForSpace';
import useRequiredTierCallback from './useRequiredTierCallback';
export const useRequiredBenefitCallbackWithSpaces = (cb, benefitId, disabled = false) => {
    const isUnlockedForSpace = useIsTierUnlockedForSpace(benefits[benefitId].minTier);
    const validIf = useCallback(() => isUnlockedForSpace || disabled, [disabled, isUnlockedForSpace]);
    const requiredCallback = useRequiredTierCallback(cb, benefits[benefitId].minTier, validIf, benefitId);
    return requiredCallback;
};
const useRequiredBenefitCallback = (cb, benefitId, hideModal, skipTrialScreen, upsellTrial) => useRequiredTierCallback(cb, benefits[benefitId].minTier, undefined, benefitId, hideModal, skipTrialScreen, upsellTrial);
export default useRequiredBenefitCallback;
