import React from 'react';
import { StyleSheet } from 'react-native';
import Modal from 'design-system/Modal';
import QuotesModalContent from './QuotesModalContent';
const QuotesModal = {
    show: (url, flow, type) => {
        Modal.show(<QuotesModalContent url={url} flow={flow} type={type} hideModal={() => Modal.hide()}/>, {
            containerStyle: styles.modal,
            title: 'Your quotes',
        });
    },
    hide: () => {
        Modal.hide();
    },
};
const styles = StyleSheet.create({
    modal: {
        paddingHorizontal: 0,
        overflow: 'hidden',
    },
});
export default QuotesModal;
