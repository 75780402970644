import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconNote = (props) => {
    const id = useId();
    if (props.alt) {
        return (<IconWithGradient id={id} {...props} defaultGradientKey="pro">
        <Path fill={`url(#gradient_${id})`} d="M12.6761 19.9589C12.9508 20.0228 12.976 20.3827 12.7084 20.4719L11.1284 20.9919C7.15839 22.2719 5.06839 21.2019 3.77839 17.2319L2.49839 13.2819C1.21839 9.31191 2.27839 7.21191 6.24839 5.93191L6.77238 5.75838C7.17525 5.62497 7.56731 6.02903 7.45292 6.4377C7.39622 6.64027 7.34167 6.85168 7.28839 7.07192L6.30839 11.2619C5.20839 15.9719 6.81839 18.5719 11.5284 19.6919L12.6761 19.9589Z"/>
        <Path fill={`url(#gradient_${id})`} d="M17.1702 3.20854L15.5002 2.81854C12.1602 2.02854 10.1702 2.67854 9.00018 5.09854C8.70018 5.70854 8.46018 6.44854 8.26018 7.29854L7.28018 11.4885C6.30018 15.6685 7.59018 17.7285 11.7602 18.7185L13.4402 19.1185C14.0202 19.2585 14.5602 19.3485 15.0602 19.3885C18.1802 19.6885 19.8402 18.2285 20.6802 14.6185L21.6602 10.4385C22.6402 6.25854 21.3602 4.18854 17.1702 3.20854ZM15.2902 13.3285C15.2002 13.6685 14.9002 13.8885 14.5602 13.8885C14.5002 13.8885 14.4402 13.8785 14.3702 13.8685L11.4602 13.1285C11.0602 13.0285 10.8202 12.6185 10.9202 12.2185C11.0202 11.8185 11.4302 11.5785 11.8302 11.6785L14.7402 12.4185C15.1502 12.5185 15.3902 12.9285 15.2902 13.3285ZM18.2202 9.94854C18.1302 10.2885 17.8302 10.5085 17.4902 10.5085C17.4302 10.5085 17.3702 10.4985 17.3002 10.4885L12.4502 9.25854C12.0502 9.15854 11.8102 8.74854 11.9102 8.34854C12.0102 7.94854 12.4202 7.70854 12.8202 7.80854L17.6702 9.03854C18.0802 9.12854 18.3202 9.53854 18.2202 9.94854Z"/>
      </IconWithGradient>);
    }
    return (<IconWithGradient id={id} {...props} defaultGradientKey="pro">
      <Path fillRule="evenodd" clipRule="evenodd" d="M16.523 15.7c.6-.09 1.371-.106 2.43-.11a6.195 6.195 0 0 1-4.31 3.668l-.005.001c.01-.762.037-1.35.112-1.825.097-.62.264-.964.513-1.213.255-.255.61-.425 1.26-.521ZM14.97 3.344a13.006 13.006 0 0 0-5.94 0A7.63 7.63 0 0 0 3.344 9.03a13.006 13.006 0 0 0 0 5.94 7.63 7.63 0 0 0 5.686 5.686 13.006 13.006 0 0 0 5.94 0 7.63 7.63 0 0 0 5.686-5.686 13.006 13.006 0 0 0 0-5.94 7.63 7.63 0 0 0-5.686-5.686ZM8.554 9.128c0-.317.257-.574.574-.574H12a.574.574 0 0 1 0 1.148H9.128a.574.574 0 0 1-.574-.574Zm0 2.872c0-.317.257-.574.574-.574h4.787a.574.574 0 1 1 0 1.148H9.128A.574.574 0 0 1 8.554 12Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
