import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Svg, Path } from 'react-native-svg';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
const SuperSquircle = ({ size = 44, color, children, style, }) => {
    const width = rem(size);
    const height = rem(size);
    const colors = useColors();
    return (<View style={[style, styles.container, { width, height }]}>
      <View style={StyleSheet.absoluteFill}>
        <Svg width={width} height={height} viewBox="0 0 44 44" fill="none">
          <Path d="M21.9988 0.0032959C7.07724 0.0032959 0 7.08053 0 22.0021C0 36.9236 7.07724 44.0008 21.9988 44.0008C36.9203 44.0008 43.9975 36.9236 43.9975 22.0021C43.9975 7.08053 36.9203 0.0032959 21.9988 0.0032959Z" fill={color ?? colors.background.dark}/>
        </Svg>
      </View>
      {children}
    </View>);
};
const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
    },
});
export default SuperSquircle;
