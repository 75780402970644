import { useEffect } from 'react';
import { CommonActions } from '@react-navigation/native';
import { getItems } from 'utils/jwtHelpers';
import { useAppDispatch } from 'store/hooks';
import { fetchAppData } from 'actions/refresh';
import { useMainStackNavigation } from 'utils/types/navigationV6';
const usePrivateRoute = (requiresRedirect = true) => {
    const dispatch = useAppDispatch();
    const navigation = useMainStackNavigation();
    useEffect(() => {
        const checkTokens = async () => {
            const tokens = await getItems(['access_token', 'refresh_token']);
            const accessToken = tokens?.[0]?.[1];
            const refreshToken = tokens?.[1]?.[1];
            if (!accessToken || !refreshToken) {
                if (requiresRedirect) {
                    navigation.dispatch(CommonActions.reset({
                        index: 0,
                        routes: [{ name: 'Feed' }],
                    }));
                }
            }
            else {
                const routeIndex = navigation.getState().index;
                if (routeIndex === 0) {
                    dispatch(fetchAppData());
                }
            }
        };
        checkTokens();
    }, []);
};
export default usePrivateRoute;
