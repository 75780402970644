import React, { useCallback, useLayoutEffect } from 'react';
import { StyleSheet } from 'react-native';
import Text from 'design-system/Text';
import Button from 'design-system/Button';
import { rem } from 'design-system/values';
import { AppView } from 'design-system/AppView';
import StatusBar from 'design-system/StatusBar';
import { ButtonGroup } from 'design-system/ButtonGroup';
import EmojiIcon from 'design-system/emojiPicker/EmojiIcon';
import { CardView } from 'design-system/CardView';
import Squircle from 'design-system/Squircle';
import Amplitude from 'utils/amplitude';
import useColors from 'hooks/useColors';
import { getCategories } from 'actions/user';
import { goBackWithKey } from 'utils/navigationv6';
import { getBudgets } from 'features/budgeting/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { createCategory, updateCategory } from 'actions/expenses';
import { muteAll, muteErrorAndResultButThrows, useFetchOld } from 'hooks';
import { useRequiredBenefitCallbackWithSpaces } from 'features/premium/hooks/useRequiredBenefitCallback';
import { isWeb } from '../../../constants';
const CustomCategoryCreatedScreen = ({ navigation, route }) => {
    const dispatch = useAppDispatch();
    const currency = useAppSelector((store) => store.user.user.currency);
    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Custom category',
        });
    }, []);
    const colors = useColors();
    useLayoutEffect(() => {
        navigation.setOptions({
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary]);
    const [, , fetching, fetch] = useFetchOld(isWeb ? muteErrorAndResultButThrows : muteAll);
    const { id } = route.params.category || {};
    const onCreate = useCallback(() => {
        dispatch(getCategories());
        dispatch(getBudgets());
        Amplitude.logEvent('Premium.CustomCategoryCreated');
    }, []);
    const onUpdate = useCallback(() => {
        dispatch(getCategories());
        Amplitude.logEvent('Premium.CustomCategoryUpdated');
    }, []);
    const onPressConfirm = useRequiredBenefitCallbackWithSpaces(useCallback(async () => {
        try {
            const { displayName, color, emoji } = route.params?.category || {};
            const { keyBack } = route.params;
            if (id) {
                if (isWeb) {
                    const response = await fetch(updateCategory(id, { displayName, color, emoji }));
                    if (response) {
                        onUpdate();
                    }
                }
                else {
                    fetch(updateCategory(id, { displayName, color, emoji })).then((response) => {
                        if (response) {
                            onUpdate();
                        }
                    });
                }
                goBackWithKey(navigation, keyBack);
                return;
            }
            if (isWeb) {
                const response = await fetch(createCategory({ displayName, color, emoji }, currency));
                if (response) {
                    onCreate();
                }
            }
            else {
                fetch(createCategory({ displayName, color, emoji }, currency)).then((response) => {
                    if (response) {
                        onCreate();
                    }
                    return response;
                });
            }
            goBackWithKey(navigation, keyBack);
        }
        catch {
            //
        }
    }, [currency, id, onCreate, onUpdate, route.params]), 'customCategories');
    const goBack = useCallback(() => {
        const { keyBack } = route.params;
        goBackWithKey(navigation, keyBack);
    }, [route.params]);
    const { displayName, emoji, color } = route.params?.category || {};
    return (<AppView type="secondary" paddingBottom isInTab={isWeb} avoidHeader style={styles.container}>
        <StatusBar barStyle="dark-content" animated/>
        <CardView style={styles.content}>
          <Text Text-20>{displayName}</Text>
          <Squircle height={44} width={44} color={colors.background.dark}>
            <EmojiIcon color={color}>{emoji}</EmojiIcon>
          </Squircle>
        </CardView>
        <ButtonGroup type="default">
          <Button brandReversed title="Go back" onPress={goBack} isFetching={fetching}/>
          <Button brand isFetching={fetching} title={id === undefined ? 'Create Custom Category' : 'Continue'} onPress={onPressConfirm} testID="continueButton"/>
        </ButtonGroup>
      </AppView>);
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'space-between',
    },
    content: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: rem(16),
    },
});
export default CustomCategoryCreatedScreen;
