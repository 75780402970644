import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import { rem } from 'design-system/values';
import { CardView } from 'design-system/CardView';
import ListEmptyView from 'design-system/ListEmptyView';
const EmptyComponent = memo(() => (<CardView noMarginTop>
    <ListEmptyView style={styles.empty} title="Looking empty…" subTitle="Connect an account to see something here!" compact/>
  </CardView>));
export default EmptyComponent;
const styles = StyleSheet.create({
    empty: {
        padding: rem(24),
    },
});
