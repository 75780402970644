import * as React from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
const IconBank = (props) => {
    const id = React.useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="darkPink">
      <Path fillRule="evenodd" clipRule="evenodd" d="m12.133 3.055 8.335 4.152H3.332l8.335-4.152a.522.522 0 0 1 .466 0ZM4.13 10.182a.527.527 0 0 0 .369.154h14.804a.527.527 0 0 0 .37-.154c.105-.106.898-.932 1.128-1.932H3c.23 1 1.023 1.826 1.129 1.932Zm4.12 1.197H5.087v6.292H8.25V11.38ZM3.523 20.8a.521.521 0 0 1-.489-.704c.203-.544.583-1.02.738-1.2a.52.52 0 0 1 .395-.182h15.468a.52.52 0 0 1 .395.182c.155.18.535.656.738 1.2a.52.52 0 0 1-.489.704H3.522Zm6.814-9.421h3.128v6.292h-3.128V11.38Zm8.378 0H15.55v6.292h3.164V11.38Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default IconBank;
