import React, { memo, useCallback, useEffect, useRef } from 'react';
import { noop } from 'lodash';
import { TouchableOpacity, View, Pressable } from 'react-native';
import Text from 'design-system/Text';
import Modal from 'design-system/Modal';
import Spacer from 'design-system/Spacer';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import ToolTipContent from './ToolTipContent';
import IconTooltip from './icons/IconTooltip';
import { hitSlopS, rem } from './values';
import { isWeb } from '../constants';
import PopUpMenuModal from './PopUpMenuModal.web';
import ToolTipContentWeb, { toolTipWebContentWidth } from './ToolTipContentWeb';
const BtnComp = isWeb ? Pressable : TouchableOpacity;
const HIT_SLOP = { top: 12, bottom: 12, left: 12, right: 12 };
const ToolTip = ({ title, offset, subtitle, message, colorKey, centered, onModalShown, onModalHidden = noop, small, learnMore, tootTipBtnText, onExtraBtnPress, onPressLearnMore, toolTipExtraBtnText, }) => {
    const styles = useStyles(styleSet);
    const touchViewRef = useRef(null);
    const [isHovering, setIsHovering] = React.useState(false);
    const onHoverIn = useCallback(() => {
        if (touchViewRef) {
            touchViewRef.current?.measure((_, __, ___, ____, fx, fy) => {
                PopUpMenuModal.show(<ToolTipContentWeb title={title} subtitle={subtitle}/>, {
                    fy: fy + rem(20),
                    onHoverOnly: true,
                    modalWidth: toolTipWebContentWidth,
                    fx: fx - toolTipWebContentWidth / 2,
                    containerStyle: styles.webModal,
                });
            });
        }
    }, [styles.webModal, subtitle, title]);
    useEffect(() => {
        if (isHovering) {
            onHoverIn();
        }
        else {
            PopUpMenuModal.hide();
        }
    }, [isHovering, onHoverIn]);
    const onPress = useCallback(() => {
        if (!isWeb) {
            onModalShown?.();
            Modal.show(<ToolTipContent subtitle={subtitle} onPress={() => {
                    Modal.hide(undefined, { top: true });
                    onModalHidden?.();
                }} learnMore={learnMore} btnText={tootTipBtnText} extraBtnText={toolTipExtraBtnText} onExtraBtnPress={() => {
                    Modal.hide(undefined, { top: true });
                    onExtraBtnPress?.();
                }} onPressLearnMore={onPressLearnMore}/>, {
                title,
                top: true,
                textStyle: styles.title,
                onDidHideCallback: onModalHidden,
            });
        }
    }, [
        onModalShown,
        subtitle,
        learnMore,
        tootTipBtnText,
        toolTipExtraBtnText,
        onPressLearnMore,
        title,
        styles.title,
        onModalHidden,
        onExtraBtnPress,
    ]);
    const handleHoverIn = useCallback(() => {
        if (isWeb) {
            setIsHovering(true);
        }
    }, []);
    const handleHoverOut = useCallback(() => {
        if (isWeb) {
            setIsHovering(false);
        }
    }, []);
    if (message) {
        return (<BtnComp ref={touchViewRef} onPress={onPress} hitSlop={HIT_SLOP} onHoverIn={handleHoverIn} onHoverOut={handleHoverOut} style={[styles.row, centered && styles.centered]}>
        <View>
          <Spacer h={2}/>
          <IconTooltip colorKey={colorKey} height={16} width={16}/>
        </View>
        <Spacer w={8}/>
        <Text TextThin-14 Gray>
          {message}
        </Text>
      </BtnComp>);
    }
    if (small) {
        return (<View>
        {!!offset && <Spacer h={offset}/>}
        <BtnComp onPress={onPress} ref={touchViewRef} hitSlop={hitSlopS} onHoverIn={handleHoverIn} onHoverOut={handleHoverOut}>
          <IconTooltip height={16} width={16} colorKey={colorKey} testID="icon"/>
        </BtnComp>
      </View>);
    }
    return (<BtnComp hitSlop={HIT_SLOP} onPress={onPress} ref={touchViewRef} onHoverIn={handleHoverIn} onHoverOut={handleHoverOut}>
      <IconTooltip colorKey={colorKey} testID="icon"/>
    </BtnComp>);
};
const styleSet = createStyleSheets(() => ({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    centered: {
        alignSelf: 'stretch',
        justifyContent: 'center',
    },
    title: {
        textAlign: 'center',
    },
    webModal: {
        padding: rem(16),
    },
}));
export default memo(ToolTip);
