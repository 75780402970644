import { ACTION_TYPE } from 'hooks/eventBus';
import { REHYDRATE } from 'redux-persist';
import { IAP_PURCHASE_FLOW_ENDED_WITH_ERROR, IAP_PURCHASE_FLOW_STARTED, SET_IAP_SUBSCRIPTIONS, SET_IS_ELIGIBLE_FOR_INTRO_OFFER, VALIDATE_PURCHASE_REQUEST, } from '../actions';
const initialState = {
    availableSubscriptions: [],
    subscriptions: {},
    isEligibleForIntroOffer: undefined,
    isPurchasingSubscription: undefined,
    isConnected: false,
};
export const iapSubscriptions = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE: {
            return {
                ...state,
                availableSubscriptions: [],
                isConnected: false,
                isPurchasingSubscription: false,
                purchasingSubscription: undefined,
            };
        }
        case SET_IAP_SUBSCRIPTIONS:
            return {
                ...state,
                subscriptions: action.payload && action.payload.length > 0
                    ? action.payload.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), state.subscriptions || {})
                    : {},
                isConnected: true,
            };
        case SET_IS_ELIGIBLE_FOR_INTRO_OFFER:
            return { ...state, isEligibleForIntroOffer: action.payload };
        case VALIDATE_PURCHASE_REQUEST:
            return { ...state, isPurchasingSubscription: true };
        case IAP_PURCHASE_FLOW_STARTED: {
            return {
                ...state,
                purchasingSubscription: action.payload,
            };
        }
        case ACTION_TYPE:
            if (action.payload === 'IAP_PURCHASE_FLOW_ENDED_WITH_SUCCESS') {
                return {
                    ...state,
                    isPurchasingSubscription: false,
                    purchasingSubscription: undefined,
                };
            }
            return state;
        case IAP_PURCHASE_FLOW_ENDED_WITH_ERROR:
            return { ...state, isPurchasingSubscription: false };
        default:
            return state;
    }
};
