import React from 'react';
import { View } from 'react-native';
import Text, { lineHeight } from 'design-system/Text';
import { rem } from 'design-system/values';
import { TextButton } from 'design-system/TextButton';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import useIsCompactCalendar from './useIsCompactCalendar';
const ContentHeader = ({ title, handleReset, style, }) => {
    const styles = useStyles(styleSet);
    const compact = useIsCompactCalendar();
    const hideReset = handleReset === undefined;
    return (<View style={[styles.container, compact && styles.compact, style]}>
      <Text Text-14 flex Secondary centered={hideReset}>
        {title}
      </Text>
      {!hideReset && (typeof title !== 'string' || title?.length > 0) && (<TextButton small text="Reset" onPress={handleReset}/>)}
    </View>);
};
export default ContentHeader;
const styleSet = createStyleSheets((colors) => ({
    container: {
        alignItems: 'center',
        flexDirection: 'row',
        borderBottomWidth: 1,
        paddingBottom: rem(16),
        borderBottomColor: colors.borders.divider,
        minHeight: lineHeight.Text_14 + rem(17),
    },
    compact: {
        paddingBottom: rem(8),
        minHeight: lineHeight.Text_14 + rem(9),
    },
}));
