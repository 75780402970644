import { Appearance, Linking, Platform } from 'react-native';
import InAppBrowser from 'utils/packages/inAppBrowser';
import { URL } from 'utils/packages/rnUrlPolyfill';
import StatusBar from 'design-system/StatusBar';
import Sentry from './sentry';
import { isWeb } from '../constants';
export const forceProtocol = (url) => {
    try {
        const parsed = new URL(url);
        return parsed.href;
    }
    catch (e) {
        if (url.startsWith('http://')) {
            return url.replace('http://', 'https://');
        }
        if (!url.startsWith('https://')) {
            return `https://${url}`;
        }
        return url;
    }
};
const openLink = async (inputUrl, readerMode = false) => {
    if (isWeb) {
        Linking.openURL(inputUrl).catch((err) => {
            Sentry.logError({ filename: inputUrl, message: err });
        });
    }
    else {
        const url = forceProtocol(inputUrl);
        if (await InAppBrowser.isAvailable()) {
            const style = StatusBar.pushStackEntryWithoutChecking({
                barStyle: Appearance.getColorScheme() === 'dark'
                    ? 'light-content'
                    : 'dark-content',
            });
            try {
                await InAppBrowser.open(url, {
                    readerMode,
                    animated: true,
                    modalTransitionStyle: 'coverVertical',
                    modalEnabled: false,
                });
            }
            catch (error) {
                Sentry.logError({ filename: url, message: error.toString() });
            }
            finally {
                StatusBar.popStackEntry(style);
            }
        }
    }
};
export const knownLinks = {
    autosaveInfo: 'https://help.emma-app.com/en/article/what-is-an-autosave-cu6zz4/',
    fxFees: 'https://help.emma-app.com/en/article/what-is-the-emma-invest-fx-currency-conversion-fee-o9asm7/',
    cryptoLogin: 'https://help.emma-app.com/en/category/cryptocurrency-blockchain-ij01s7/',
    emmaWalletInfo: 'https://emma-app.com/wallet-terms',
    potsTnc: 'https://emma-app.com/pots-terms',
    privacy: 'https://emma-app.com/app-privacy',
    terms: 'https://emma-app.com/app-terms',
    facebook: 'https://www.facebook.com/emmafinance',
    twitter: 'https://twitter.com/emma_finance',
    instagram: 'https://www.instagram.com/emma_finance',
    tiktok: 'https://www.tiktok.com/@emma_finance',
    reddit: 'https://www.reddit.com/r/emmafinance/',
    emma: 'https://emma-app.com',
    jobs: 'https://emma-app.com/jobs',
    blog: 'https://emma-app.com/blog',
    community: 'https://community.emma-app.com/',
    communityBankIntegrations: 'https://community.emma-app.com/c/integrations-requests/32',
    help: 'https://help.emma-app.com/en/',
    appStore: 'https://itunes.apple.com/app/id1270062373',
    playStore: 'https://play.google.com/store/apps/details?id=com.emmaprod',
    playStoreSubscriptions: 'https://play.google.com/store/account/subscriptions',
    referral: 'https://help.emma-app.com/en/article/referral-program-terms-and-conditions-9whdkj/',
    smartRules: 'https://help.emma-app.com/en/article/smart-rules-6dfta8/',
    transactionCategories: 'https://help.emma-app.com/en/article/change-a-transaction-category-iipyy2/',
    howSafeIsEmmaApp: 'https://help.emma-app.com/en/article/how-safe-is-emma-app-kfmgsi/',
    whyDoINeedToConnectABank: 'https://help.emma-app.com/en/article/why-do-i-need-to-connect-a-bank-account-to-emma-bptg3b/',
    codeIsNotArriving: 'https://help.emma-app.com/en/article/my-phone-number-verification-code-is-not-arriving-bvzysw/',
    whatIsMyPasscode: 'https://help.emma-app.com/en/article/what-is-my-4-digit-passcode-neags0',
    signUpTroubleshooting: 'https://help.emma-app.com/en/article/signup-troubleshooting-121894n/',
    signInTroubleshooting: 'https://help.emma-app.com/en/article/signing-troubleshooting-17vc0m5/',
    pendingDepositHelp: 'https://help.emma-app.com/en/article/how-do-i-top-up-my-emma-investment-account-and-how-long-does-it-take-1js9sse/',
    amendAnalytics: 'https://help.emma-app.com/en/category/amend-your-analytics-155lckn/',
    depositFAQ: 'https://help.emma-app.com/en/article/how-long-does-it-take-to-deposit-money-in-emma-68xzrr/?bust=1675269981517',
    rentReportingTerms: 'http://emma-app.com/rent-reporting-terms',
    whyDoINeedToSelectLogins: 'https://help.emma-app.com/en/article/why-do-i-need-to-select-bank-logins-1iyl7pa/?bust=1681819634437',
    whatAreInactiveLogins: 'https://help.emma-app.com/en/article/what-are-inactive-bank-logins-yxqsnm/',
    withdrawalInfo: 'https://help.emma-app.com/en/article/how-long-do-pot-withdrawals-take-2rk4n3/',
    budgetingHelp: 'https://help.emma-app.com/en/category/budgeting-1s6usi4/',
    potsHelp: 'https://help.emma-app.com/en/category/emma-save-1q0zv9n/',
    spacesHelp: 'https://help.emma-app.com/en/article/emma-spaces-170x1at/',
    bankLoginsHelp: 'https://help.emma-app.com/en/category/accounts-18ix4l7/',
    pendingTxnHelp: 'https://help.emma-app.com/en/article/amend-pending-transactions-4wlf1y/',
    splitTxnHelp: 'https://help.emma-app.com/en/article/split-a-transaction-1jytjas/',
    groupsHelp: 'https://help.emma-app.com/en/category/pay-transfers-groups-9n3m27/',
    committedSpendingHelp: 'https://help.emma-app.com/en/article/learn-committed-spending-1cg2t8v/',
    rentReportingWhatCanWeReport: 'https://help.emma-app.com/en/article/what-can-be-included-in-rent-reporting-1ju8tch/',
    createCreditBuilderLink: 'https://start.credito.co.uk/emma/',
    noSubscriptions: 'https://help.emma-app.com/en/article/premium-plans-not-loading-kxl3gv/',
    paidPlanTncs: 'http://emma-app.com/paid-plans-terms ',
    kycProcess: 'https://help.emma-app.com/en/article/emma-kyc-process-10ui2bb/',
    roadMap: 'https://www.notion.so/emmafinance/Emma-Transparent-Product-Roadmap-e2844fc1e3174d20a04dcc1a4e85d2c9',
    unsettledCashInfo: 'https://help.emma-app.com/en/article/what-is-the-unsettled-cash-balance-in-emma-1jjhsuc/',
    webSignInHelp: 'https://help.emma-app.com/en/article/how-do-i-sign-in-to-the-emma-web-dashboard-tyh4i7/',
    balanceAndBudget: 'https://help.emma-app.com/en/article/why-is-my-left-of-different-from-my-available-balance-nklig0/',
    unknownMerchants: 'https://help.emma-app.com/en/article/what-are-unknown-merchants-in-analytics-tg8a8v/?bust=1725553312590',
    yapilyTerms: 'https://www.yapily.com/legal/end-user-terms',
    yapilyPrivacy: 'https://www.yapily.com/legal/privacy-policy',
    patternDayTrading: 'https://help.emma-app.com/en/article/what-is-pattern-day-trading-1bazbf2/',
};
const openKnownLink = (link) => openLink(knownLinks[link]);
export default {
    openLink,
    openKnownLink,
    openKycProcessLink: () => openKnownLink('kycProcess'),
    openPotsTnc: () => openKnownLink('potsTnc'),
    openEmmaFacebook: () => openKnownLink('facebook'),
    openEmmaTwitter: () => openKnownLink('twitter'),
    openEmmaInstagram: () => openKnownLink('instagram'),
    openEmmaTiktok: () => openKnownLink('tiktok'),
    openEmmaReddit: () => openKnownLink('reddit'),
    openEmmaJobs: () => openKnownLink('jobs'),
    openEmmaBlog: () => openKnownLink('blog'),
    openEmmaCommunity: () => openKnownLink('community'),
    openEmmaRoadMap: () => openKnownLink('roadMap'),
    openEmmaCommunityBankIntegrations: () => openKnownLink('communityBankIntegrations'),
    openEmmaPrivacy: () => openKnownLink('privacy'),
    openEmmaTerms: () => openKnownLink('terms'),
    openEmmaHelp: () => openKnownLink('help'),
    openCryptoLoginInfo: () => openKnownLink('cryptoLogin'),
    openPendingDepositHelp: () => openKnownLink('pendingDepositHelp'),
    openEmmaReferral: () => openKnownLink('referral'),
    openSmartRules: () => openKnownLink('smartRules'),
    openManageSubscriptions: () => {
        if (Platform.OS === 'android') {
            Linking.openURL(knownLinks.playStoreSubscriptions);
        }
    },
    openWalletTerms: () => openKnownLink('emmaWalletInfo'),
    openEmmaInAppStore: () => openKnownLink('appStore'),
    openTransactionCategories: () => openKnownLink('transactionCategories'),
    openDepositFAQ: () => openKnownLink('depositFAQ'),
    openHowSafeIsEmmaApp: () => openKnownLink('howSafeIsEmmaApp'),
};
