import React, { memo, useCallback, useRef } from 'react';
import { View, ScrollView, Pressable } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import { options } from './constants';
import { isWeb } from '../../constants';
const SegmentControl = ({ selectedTabIndex, setSelectedTabIndex, }) => {
    const styles = useStyles(styleSet);
    const scrollViewRef = useRef(null);
    const itemRefs = useRef([]);
    const [isHovering, setIsHovering] = React.useState(undefined);
    const onLayoutItem = (index) => (event) => {
        const { x, width } = event.nativeEvent.layout;
        itemRefs.current[index] = { x, width };
    };
    const handlePress = (index) => {
        setSelectedTabIndex(index);
        const itemLayout = itemRefs.current[index];
        if (scrollViewRef.current && itemLayout) {
            scrollViewRef.current.scrollTo({
                x: itemLayout.x - itemLayout.width / 2 - rem(16),
                animated: true,
            });
        }
    };
    const handleEvent = useCallback((event, option) => () => {
        setIsHovering(event === 'onHoverIn' ? option : undefined);
    }, []);
    return (<View style={styles.container}>
      <ScrollView horizontal showsHorizontalScrollIndicator={false} ref={scrollViewRef}>
        {options.map((option, index) => (<Pressable key={option} style={[
                styles.optionWrapper,
                index === 0 && styles.marginStart,
                isHovering === option && styles.hoverOnLight,
                index === options.length - 1 && styles.marginEnd,
                selectedTabIndex === index ? styles.selected : undefined,
            ]} onHoverIn={handleEvent('onHoverIn', option)} onHoverOut={handleEvent('onHoverOut', option)} onLayout={onLayoutItem(index)} onPress={() => handlePress(index)}>
            <Text Text-14 {...(selectedTabIndex === index
            ? { OnBrand: true }
            : { Secondary: true })}>
              {option}
            </Text>
          </Pressable>))}
      </ScrollView>
    </View>);
};
export default memo(SegmentControl);
const styleSet = createStyleSheets((colors) => ({
    container: {
        alignItems: 'center',
    },
    optionWrapper: {
        paddingVertical: rem(6),
        paddingHorizontal: rem(16),
        borderRadius: rem(8),
        marginRight: rem(7),
        backgroundColor: isWeb ? colors.background.light : colors.cards.onDark,
    },
    hoverOnLight: {
        backgroundColor: colors.control.hoverOnLight,
    },
    selected: {
        backgroundColor: colors.buttons.brandLight,
    },
    marginStart: {
        marginLeft: rem(16),
    },
    marginEnd: {
        marginRight: rem(16),
    },
}));
