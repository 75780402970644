import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import Amount from 'design-system/Amount';
import { CardView } from 'design-system/CardView';
import Section from 'design-system/Section';
import { Separator } from 'design-system/Separator';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import { useOpenSearch } from 'features/transactions/hooks/useOpenSearch';
import { useAppSelector } from 'store/hooks';
import TransactionTouchableRow from './TransactionTouchableRow';
import { styles as sectionStyles } from './styles';
const TransactionMerchantHistorySection = ({ transaction, }) => {
    const data = useAppSelector((store) => store.transactions.merchantStats);
    const handleOpenSearch = useOpenSearch();
    const onRowPress = useCallback(() => {
        if (!transaction.merchant || !transaction.merchantId)
            return;
        handleOpenSearch({
            filterType: 'MERCHANT',
            merchant: {
                ...transaction.merchant,
                id: transaction.merchantId,
                displayName: transaction.merchant?.displayName,
                iconUrl: transaction.merchant?.iconUrl,
            },
        });
    }, [handleOpenSearch, transaction.merchant, transaction.merchantId]);
    if (!data || !transaction.merchant || !data.nTransactions) {
        return null;
    }
    const { nTransactions, average, total, currency } = data;
    return (<Section title={transaction.merchant.displayName} style={sectionStyles.section}>
      <CardView noMarginTop noMarginBottom>
        <View>
          <TransactionTouchableRow onPress={onRowPress}>
            <View style={styles.contentInline}>
              <Text Text-16 flex>
                Number of transactions
              </Text>
              <Text Text-16>{nTransactions}</Text>
            </View>
          </TransactionTouchableRow>
          <Separator />
          <TransactionTouchableRow onPress={onRowPress}>
            <View style={styles.contentInline}>
              <Text Text-16 flex>
                Average spend
              </Text>
              <Amount Numbers-16 value={{ value: average, currency }}/>
            </View>
          </TransactionTouchableRow>
          <TransactionTouchableRow onPress={onRowPress}>
            <View style={styles.contentInline}>
              <Text Text-16 flex>
                Total spent
              </Text>
              <Amount Numbers-16 value={{ value: total, currency }}/>
            </View>
          </TransactionTouchableRow>
        </View>
      </CardView>
    </Section>);
};
const styles = StyleSheet.create({
    contentInline: {
        flexDirection: 'row',
        paddingVertical: rem(16),
        paddingHorizontal: rem(16),
    },
});
export default TransactionMerchantHistorySection;
