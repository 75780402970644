import { getEnergySwitchingResults, getEnquiries, getSwitchingQuotes, getSwitchingResults, } from 'actions';
import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import Energy from 'utils/energy';
const useEnergyCompare = (openCompare) => {
    const dispatch = useAppDispatch();
    const callback = useCallback((type) => {
        const dispatchGetEnquiries = (data) => dispatch(getEnquiries(data));
        const dispatchGetSwitchingResults = (id) => dispatch(getSwitchingResults(id));
        const dispatchGetSwitchingQuotes = (params) => dispatch(getSwitchingQuotes(params));
        const dispatchGetEnergySwitchingResults = (id, params) => dispatch(getEnergySwitchingResults(id, params));
        Energy.getCompare(type, dispatchGetEnquiries, dispatchGetSwitchingResults, dispatchGetSwitchingQuotes, dispatchGetEnergySwitchingResults, openCompare);
    }, [openCompare]);
    return callback;
};
export const mapMerchantTypeToCompareType = (type) => {
    if (type === 'GAS_AND_ELECTRICITY')
        return 'Energy';
    if (type === 'BROADBAND')
        return 'Broadband';
    return 'Habito';
};
export default useEnergyCompare;
