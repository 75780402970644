import React, { memo, useCallback } from 'react';
import { View, StyleSheet } from 'react-native';
import { IconArrow, rem } from 'design-system';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import AccountBalance from 'features/accounts/components/overview/AccountBalance';
import useColors from 'hooks/useColors';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import BlurAmountView from './BlurAmountView';
import { isWeb } from '../constants';
const OverviewRow = ({ item, onPress }) => {
    const { title, color, amount } = item;
    const colors = useColors();
    const handlePress = useCallback(() => {
        if (item.title === 'Debt' || item.title === 'Assets') {
            onPress?.(item.title);
        }
    }, [item.title, onPress]);
    const isPressable = !!(onPress &&
        (item.title === 'Debt' || item.title === 'Assets'));
    return (<TouchableHighlight onCard hoverOnLight activeOpacity={1} onPress={handlePress} disabled={!isPressable} underlayColor={colors.background.underlay}>
      <View style={styles.row}>
        <View style={styles.titleContainer}>
          <Text Text-16>{title}</Text>
          <Spacer w={16}/>
          {isPressable && (<>
              <IconArrow width={8} height={8} direction="right"/>
              <Spacer w={16}/>
            </>)}
        </View>
        <BlurAmountView enabled={item.blurValue}>
          <AccountBalance amount={Number(amount.amount)} currency={amount.currency} color={color}/>
        </BlurAmountView>
      </View>
    </TouchableHighlight>);
};
const styles = StyleSheet.create({
    row: {
        padding: rem(16),
        alignItems: 'center',
        flexDirection: 'row',
        ...(isWeb && {
            paddingVertical: rem(16),
            paddingHorizontal: rem(8),
        }),
    },
    titleContainer: {
        flex: 1,
        alignItems: 'center',
        flexDirection: 'row',
    },
});
export default memo(OverviewRow);
