import React from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
const TitledView = ({ title, children, style, ...props }) => (<View {...props} style={[styles.title, style]}>
    <Text Text-16 Gray style={styles.title}>
      {title}
    </Text>
    {children}
  </View>);
const styles = StyleSheet.create({
    title: {
        marginBottom: rem(16),
    },
});
export default TitledView;
