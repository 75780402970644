import { StyleSheet } from 'react-native';
import { rem } from 'design-system/values';
import { isWeb } from '../../../../constants';
export const styles = StyleSheet.create({
    section: {
        marginBottom: rem(24),
    },
    itemWithContentTitle: {
        marginBottom: rem(8),
    },
    topCard: {
        marginBottom: rem(8),
    },
    webPadding: {
        ...(isWeb && {
            padding: rem(8),
        }),
    },
});
