import { useCallback, useEffect, useRef } from 'react';
import { Keyboard } from 'react-native';
export const useGetIsKeyboardOpen = () => {
    const keyboardOpenRef = useRef(false);
    useEffect(() => {
        const showListener = Keyboard.addListener('keyboardDidShow', () => {
            keyboardOpenRef.current = true;
        });
        const hideListener = Keyboard.addListener('keyboardDidHide', () => {
            keyboardOpenRef.current = false;
        });
        return () => {
            showListener.remove();
            hideListener.remove();
        };
    }, []);
    return useCallback(() => keyboardOpenRef.current, []);
};
