import React, { useMemo, useState, useEffect, useCallback, useLayoutEffect, } from 'react';
import { View, FlatList, Keyboard, Platform, StyleSheet, InteractionManager, } from 'react-native';
import emoji from 'node-emoji';
import deepEqual from 'deep-equal';
import Text from 'design-system/Text';
import Modal from 'design-system/Modal';
import Separator from 'design-system/Separator';
import { AppView } from 'design-system/AppView';
import { hitSlop, rem } from 'design-system/values';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import useColors from 'hooks/useColors';
import { muteAll, useFetchOld, useFocusEffect } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import TouchableOpacity from 'utils/packages/TouchableOpacity';
import { setTags, editTagsTransactions, getTags } from 'actions';
import { isWeb } from '../../../constants';
import TagRow from '../components/tags/TagRow';
import TagsList from '../components/tags/TagsList';
import useCheckTransactionsModal from '../hooks/useCheckTransactionsModal';
import useRateAppAfterTransactionEdit from '../hooks/useRateAppAfterTransactionEdit';
const hashtagRegex = require('hashtag-regex');
const placeholders = [
    emoji.emojify('#:moneybag:'),
    emoji.emojify('#:pizza:'),
    emoji.emojify('#:fries:'),
    emoji.emojify('#:hamburger:'),
    emoji.emojify('#:icecream:'),
    emoji.emojify('#:coffee:'),
    emoji.emojify('#:rocket:'),
    emoji.emojify('#:sushi:'),
    emoji.emojify('#:ramen:'),
    emoji.emojify('#:rice_ball:'),
];
const keyExtractor = (item) => item.label;
const AddTagsScreen = withAnimatedNavigationHeader(({ navigation, route }) => {
    const rateApp = useRateAppAfterTransactionEdit();
    const userTags = useAppSelector((store) => store.transactions.tags);
    const editData = route.params;
    const [state, setState] = useState({
        tags: editData.type === 'single' ? editData.tags : [],
        text: '',
        error: false,
        listPadding: 0,
        separator: false,
    });
    useEffect(() => {
        InteractionManager.runAfterInteractions(() => {
            dispatch(getTags());
        });
        if (Platform.OS === 'ios') {
            const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', (e) => {
                setState((s) => ({ ...s, listPadding: e.endCoordinates.height }));
            });
            const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
                setState((s) => ({ ...s, listPadding: 0 }));
            });
            return () => () => {
                keyboardDidShowListener?.remove();
                keyboardDidHideListener?.remove();
            };
        }
        return () => { };
    }, []);
    useFocusEffect(() => {
        // () =>didFocus this.tags.textinput.focus()
        // todo focus the tags input
    });
    const getTagsToSave = useCallback(() => {
        const tagsToSave = state.tags;
        if (state.text.length > 0) {
            return [...tagsToSave, state.text.substring(1).replace(/\s/g, '')];
        }
        return tagsToSave;
    }, [state]);
    const openSimilarTransactions = useCallback(() => {
        if (editData.type === 'single') {
            const { transaction } = editData;
            const tagsToSave = getTagsToSave();
            if (isWeb) {
                navigation.navigate('EditMultipleTransactionsTags', {
                    tags: tagsToSave,
                    transaction,
                    isSelectedAll: true,
                    keyBack: route.key,
                });
            }
            else {
                Modal.hide(() => {
                    navigation.navigate('EditMultipleTransactionsTags', {
                        tags: tagsToSave,
                        transaction,
                        isSelectedAll: true,
                        keyBack: route.key,
                    });
                });
            }
        }
    }, [editData, getTagsToSave, route.key]);
    const [, , , fetch] = useFetchOld(muteAll);
    const dispatch = useAppDispatch();
    const saveTagsForTransaction = useCallback(() => {
        if (editData.type === 'single') {
            Modal.hide(() => {
                navigation.goBack();
                const { tags, id } = editData;
                const tagsToSave = getTagsToSave();
                if (!deepEqual(tags, tagsToSave)) {
                    fetch(setTags(id, tagsToSave));
                }
                rateApp();
            });
        }
    }, [editData, getTagsToSave, rateApp]);
    const openCheckTransactionsModal = useCheckTransactionsModal(saveTagsForTransaction, openSimilarTransactions);
    const handleSave = useCallback(async () => {
        if (editData.type === 'multi') {
            navigation.goBack();
            const tagsToSave = getTagsToSave();
            await fetch(editTagsTransactions(editData.ids, tagsToSave));
            editData.onEditFinished?.();
            rateApp();
        }
        else if (isWeb) {
            openSimilarTransactions();
        }
        else {
            openCheckTransactionsModal();
        }
        Keyboard.dismiss();
    }, [
        editData,
        getTagsToSave,
        openCheckTransactionsModal,
        openSimilarTransactions,
        rateApp,
    ]);
    const colors = useColors();
    useLayoutEffect(() => {
        const transaction = 'transaction' in editData ? editData.transaction : undefined;
        const nameToDisplay = transaction
            ? transaction?.customName || transaction?.counterpartName
            : 'Add tags';
        navigation.setOptions({
            title: nameToDisplay,
            headerRight: () => (<TouchableOpacity onCard hoverOnDark onPress={handleSave} style={styles.headerRightBtn} hitSlop={!isWeb ? hitSlop : undefined}>
            <Text Text-16 OffBlack>
              Next
            </Text>
          </TouchableOpacity>),
        });
    }, [colors.background.light, editData, handleSave]);
    const onChangeTags = useCallback((tags) => {
        setState((state) => ({ ...state, tags }));
    }, []);
    const onChangeText = (text) => {
        if (text.length === 1 && text.charAt(0) === ' ')
            return;
        if (text.length >= 20) {
            setState({ ...state, error: true });
            return;
        }
        // Escape string
        let newText = text.replace(/[.*+?^${}()|[\]\\]/g, '');
        if (newText.charAt(0) !== '#' && newText.length)
            newText = `#${text}`;
        const regex = hashtagRegex();
        if (!regex.exec(newText) &&
            !(newText.length === 1 && newText.charAt(0) === '#') &&
            newText.length)
            return;
        const parseWhen = [' '];
        if (state.tags &&
            state.tags.indexOf(newText.substring(1).slice(0, -1).replace(/\s/g, '')) !== -1 &&
            parseWhen.indexOf(newText.charAt(newText.length - 1)) > -1) {
            return;
        }
        const lastTyped = newText.charAt(newText.length - 1);
        if (lastTyped === ' ') {
            setState((state) => ({
                ...state,
                tags: [
                    ...state.tags,
                    newText.substring(1).slice(0, -1).replace(/\s/g, ''),
                ],
                text: '',
            }));
        }
        else {
            setState({
                ...state,
                text: newText.replace(/\s/g, ''),
                error: false,
            });
        }
    };
    const onTagPressed = useCallback((item) => {
        setState((state) => ({
            ...state,
            tags: [...state.tags, item],
            text: '',
        }));
    }, []);
    const renderItem = useCallback(({ item }) => {
        if (state.tags && state.tags.indexOf(item.label) > -1)
            return null;
        return <TagRow data={item} onPress={onTagPressed}/>;
    }, [onTagPressed, state.tags]);
    const { error } = state;
    const data = useMemo(() => {
        let missingUserTags = [];
        if (editData.type === 'single') {
            const record = userTags.reduce((prev, curr) => ({
                ...prev,
                [curr.label]: true,
            }), {});
            missingUserTags = editData.tags
                .filter((tag) => record[tag] === undefined)
                .map((label) => ({ label }));
        }
        return missingUserTags
            .concat(userTags)
            .filter((data) => data.label.match(state.text.replace('#', '')));
    }, [editData, state.text, userTags]);
    const placeholder = useMemo(() => placeholders[Math.floor(Math.random() * placeholders.length)], []);
    const handleScroll = useCallback((event) => {
        const { y } = event.nativeEvent.contentOffset;
        setState((state) => ({
            ...state,
            separator: y > 0,
        }));
    }, []);
    const flatListContentContainerStyle = useMemo(() => ({
        paddingBottom: state.listPadding,
    }), [state.listPadding]);
    return (<AppView isInTab={isWeb}>
        <View style={styles.scrollWrapper}>
          <TagsList tags={state.tags} text={state.text} onSubmitEditing={handleSave} onChange={onChangeTags} onChangeText={onChangeText}/>
        </View>
        <View style={styles.hintContainer}>
          <Text TextThin-14 centered style={styles.hintText}>
            Add {placeholder} separated by space.
          </Text>
          {error ? (<Text TextThin-14 Red centered>
              {emoji.emojify('Too many characters :warning:')}
            </Text>) : null}
        </View>
        <Separator style={!state.separator && styles.sepatorHidden}/>
        <FlatList data={data} renderItem={renderItem} scrollEventThrottle={8} onScroll={handleScroll} keyExtractor={keyExtractor} keyboardShouldPersistTaps="always" contentContainerStyle={flatListContentContainerStyle}/>
      </AppView>);
}, ({ colors }) => ({
    title: 'Edit tags',
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styles = StyleSheet.create({
    headerRightBtn: {
        ...(isWeb && {
            marginRight: rem(16),
            paddingVertical: rem(8),
            paddingHorizontal: rem(16),
        }),
    },
    scrollWrapper: {
        height: rem(isWeb ? 232 : 150),
    },
    sepatorHidden: {
        opacity: 0,
    },
    hintContainer: {
        marginTop: rem(10),
        marginBottom: rem(16),
    },
    hintText: {
        marginBottom: rem(2),
    },
});
export default AddTagsScreen;
