import React, { memo, useMemo } from 'react';
import { View, StyleSheet, } from 'react-native';
import Text from './Text';
import { Spacer } from './Spacer';
import Indicator from './Indicator';
import { rem } from './values';
const CardRow = ({ info, title, description, requesting, infoStyle, small, numberOfLines = 1, noPaddingHorizontal = false, showDashOnEmpty = true, testID, style, }) => {
    const renderInfo = useMemo(() => typeof info === 'string' || typeof info === 'number' || !info ? (<Text {...(small ? { 'Text-14': true } : { 'Text-16': true })} numberOfLines={numberOfLines} style={infoStyle}>
          {info || (showDashOnEmpty ? '-' : '')}
        </Text>) : (info), [info, infoStyle, numberOfLines, showDashOnEmpty, small]);
    const titleContent = typeof title === 'string' || typeof title === 'number' || !title ? (<Text {...(small ? { 'TextThin-14': true } : { 'TextThin-16': true })} Gray>
        {title}
      </Text>) : (title);
    return (<View testID={testID} style={[
            styles.container,
            !!description && styles.containerWithDescription,
            style,
            noPaddingHorizontal && styles.noPaddingHorizontal,
        ]}>
      {description ? (<View>
          {titleContent}
          <Spacer h={4}/>
          <Text {...(small ? { 'Text-14': true } : { 'Text-16': true })} OffBlack>
            {description}
          </Text>
        </View>) : (titleContent)}
      <Spacer w={16}/>
      <View style={styles.infoContainer}>
        {requesting ? <Indicator dark/> : renderInfo}
      </View>
    </View>);
};
export default memo(CardRow);
const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: rem(16),
        alignItems: 'center',
        flexDirection: 'row',
    },
    containerWithDescription: {
        alignItems: 'flex-end',
    },
    infoContainer: {
        flex: 1,
        alignItems: 'flex-end',
    },
    noPaddingHorizontal: {
        paddingHorizontal: 0,
        paddingVertical: rem(16),
    },
});
