import React from 'react';
import { View, StyleSheet } from 'react-native';
import Text from 'design-system/Text';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import WebTouchableOpacity from 'utils/packages/TouchableOpacity';
import { rem } from './values';
import IconArrowTriangleRight from './icons/IconArrowTriangleRight';
export const TextButtonLinkSeeAll = ({ small, color = { Primary: true }, ...props }) => {
    const seeAllBtnStyles = useStyles(styleSet);
    return (<WebTouchableOpacity {...props} style={seeAllBtnStyles.seeAllBtn} hoverStyle={seeAllBtnStyles.hover}>
      <View style={styles.container}>
        <Text {...(small ? { 'Text-14': true } : { 'Text-16': true })} {...color}>
          See all
        </Text>
        <IconArrowTriangleRight style={styles.icon}/>
      </View>
    </WebTouchableOpacity>);
};
export const TextButtonLink = ({ text, small, color = { Primary: true }, ...props }) => {
    const seeAllBtnStyles = useStyles(styleSet);
    return (<WebTouchableOpacity {...props} style={seeAllBtnStyles.seeAllBtn} hoverStyle={seeAllBtnStyles.hover}>
      <View style={styles.container}>
        <Text {...(small ? { 'Text-14': true } : { 'Text-16': true })} {...color}>
          {text}
        </Text>
        <IconArrowTriangleRight style={styles.icon}/>
      </View>
    </WebTouchableOpacity>);
};
export const TextButton = ({ text, small, children, color = { Primary: true }, ...props }) => {
    const seeAllBtnStyles = useStyles(styleSet);
    return (<WebTouchableOpacity {...props} style={seeAllBtnStyles.seeAllBtn} hoverStyle={seeAllBtnStyles.hover}>
      <View style={styles.container}>
        <Text {...(small ? { 'Text-14': true } : { 'Text-16': true })} {...color}>
          {text}
        </Text>
        {children}
      </View>
    </WebTouchableOpacity>);
};
export const HelpNavigationButton = React.memo(({ black, onPress }) => (<TextButton color={black ? { OffBlack: true } : { White: true }} text="Help" onPress={onPress}/>));
export const NavigationTextButton = ({ ...props }) => (<TextButton color={{ OffBlack: true }} {...props} style={props.style} text={props.text}/>);
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        marginStart: rem(8),
    },
});
const styleSet = createStyleSheets((colors) => ({
    seeAllBtn: {
        borderRadius: rem(8),
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: rem(6),
        paddingHorizontal: rem(12),
    },
    hover: {
        backgroundColor: colors.buttons.brandGroupHover,
    },
}));
