import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconTrophy = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="darkPurple">
      <Path d="M16.4531 2.5H7.54688C7.2392 2.5 6.99023 2.74897 6.99023 3.05664V8.66016C6.99023 11.4227 9.23747 13.6699 12 13.6699C14.7625 13.6699 17.0098 11.4227 17.0098 8.66016V3.05664C17.0098 2.74897 16.7608 2.5 16.4531 2.5ZM14.6152 7.29846L13.7205 8.17039L13.932 9.40108C13.9678 9.60982 13.8819 9.82075 13.7107 9.94522C13.5429 10.0677 13.315 10.0881 13.1242 9.98763L12.019 9.40654L10.9139 9.98763C10.7275 10.0866 10.5003 10.0697 10.3274 9.94577C10.1561 9.82075 10.0702 9.60982 10.1061 9.40108L10.3171 8.17039L9.42231 7.29846C9.27064 7.15062 9.21628 6.92938 9.28152 6.72824C9.34675 6.52655 9.52124 6.37979 9.73106 6.34936L10.9672 6.16941L11.52 5.0496C11.707 4.66908 12.3311 4.66908 12.5181 5.0496L13.0709 6.16941L14.3065 6.34936C14.5163 6.37979 14.6908 6.52655 14.756 6.72824C14.8212 6.92934 14.7669 7.15058 14.6152 7.29846Z" fill={`url(#gradient_${id})`}/>
      <Path d="M16.9931 20.8086L15.8798 17.4687C15.8178 17.2208 15.5955 17.0468 15.34 17.0468H13.6701V14.5458C13.1384 14.6969 12.5796 14.7832 12.0002 14.7832C11.4207 14.7832 10.862 14.6969 10.3303 14.5458V17.0468H8.66034C8.40484 17.0468 8.18252 17.2208 8.12054 17.4687L7.00726 20.8085C6.9654 20.9748 7.00292 21.151 7.10839 21.2858C7.21385 21.4212 7.37583 21.5 7.54706 21.5H16.4533C16.6245 21.5 16.7865 21.4212 16.892 21.2858C16.9974 21.151 17.035 20.9749 16.9931 20.8086ZM13.1135 19.8301H10.8869C10.5792 19.8301 10.3303 19.5811 10.3303 19.2734C10.3303 18.9657 10.5792 18.7168 10.8869 18.7168H13.1135C13.4211 18.7168 13.6701 18.9657 13.6701 19.2734C13.6701 19.5811 13.4211 19.8301 13.1135 19.8301Z" fill={`url(#gradient_${id})`}/>
      <Path d="M19.8723 3.09523C19.5505 2.7169 19.0814 2.5 18.5857 2.5H18.123C18.123 2.90279 18.123 3.21042 18.123 3.61328H18.5857C18.7547 3.61328 18.9145 3.72431 19.0243 3.85371C19.0825 3.92166 19.1744 4.06464 19.1428 4.25924C18.9878 5.21889 18.635 6.13056 18.123 6.94344V8.75616C19.2278 7.55931 19.9798 6.05838 20.242 4.43696C20.317 3.9722 20.1822 3.45943 19.8723 3.09523Z" fill={`url(#gradient_${id})`}/>
      <Path d="M5.4144 3.61328H5.87701C5.87701 3.21042 5.87701 2.90279 5.87701 2.5H5.4144C4.91866 2.5 4.44952 2.7169 4.12771 3.09523C3.81788 3.45943 3.68306 3.9722 3.75806 4.43644C4.02028 6.05827 4.77219 7.55927 5.87701 8.75612V6.94336C5.36508 6.13041 5.01228 5.2186 4.85724 4.25869C4.8257 4.06464 4.91758 3.92166 4.97573 3.85371C5.08554 3.72431 5.24537 3.61328 5.4144 3.61328Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
