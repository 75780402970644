import React, { useCallback } from 'react';
import { Platform, StyleSheet, View, } from 'react-native';
import Animated from 'react-native-reanimated';
import { rem } from 'design-system/values';
import SuggestionListItem from './SuggestionsListItem';
const isAndroid = Platform.OS === 'android';
const EachSuggestion = ({ data, onPress, onLayout, onScroll, animatedProps, scrollViewRef, suggestedName, didUserAlterName, contentContainerStyle, }) => {
    const renderList = useCallback(() => data.map((suggestion) => (<SuggestionListItem key={suggestion.name} suggestion={suggestion} didUserAlterName={didUserAlterName} suggestedName={suggestedName} onPress={onPress}/>)), [data, didUserAlterName, onPress, suggestedName]);
    return (<Animated.ScrollView bounces horizontal ref={scrollViewRef} onScroll={onScroll} scrollEventThrottle={16} animatedProps={animatedProps} style={styles.overflowVisible} keyboardShouldPersistTaps="handled" showsHorizontalScrollIndicator={false} decelerationRate={isAndroid ? 0.8 : 'normal'} contentContainerStyle={[
            styles.contentContainerStyles,
            contentContainerStyle,
        ]}>
      <View style={styles.itemsWrapper} onLayout={onLayout}>
        {renderList()}
      </View>
    </Animated.ScrollView>);
};
const styles = StyleSheet.create({
    contentContainerStyles: {
        paddingTop: rem(4),
        paddingBottom: rem(4),
        paddingHorizontal: rem(12),
    },
    itemsWrapper: {
        flexDirection: 'row',
    },
    overflowVisible: {
        overflow: 'visible',
    },
});
export default EachSuggestion;
