/**
 * This function takes in axiosError and type and prepares the axiosError(if axiosError) to
 * the respective failure action payload so that the error.ts file will catch it
 *
 * NOTE: will be deleted later when we switch the tryAtMost to useFetch
 */
export const transformAxiosErrorToFailureApiPayload = (axiosErr) => {
    if (axiosErr.response) {
        return {
            errorType: 'ApiError',
            muteAlert: true,
            method: axiosErr.response.config.method,
            payload: {
                headers: axiosErr.response.headers,
                ok: false,
                status: axiosErr.response.status,
                statusText: axiosErr.message,
                // eslint-disable-next-line no-underscore-dangle
                url: axiosErr.request._url,
            },
        };
    }
    return undefined;
};
