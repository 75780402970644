import React, { useEffect } from 'react';
import { View, Platform } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue, withDelay, withTiming, } from 'react-native-reanimated';
import { rem } from 'design-system/values';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
import { LinearGradientAnimated } from './AnimatedComponents';
import { isWeb } from '../constants';
export const ProgressBar = ({ colors, barBackgroundColor, position = 0, style, animateInitial, animateProgress = true, thin, }) => {
    const width = useSharedValue(animateInitial && !isWeb ? 0 : position);
    useEffect(() => {
        if (!isWeb && animateProgress) {
            const timeout = setTimeout(() => {
                width.value = withDelay(200, withTiming(position));
            });
            return () => {
                clearTimeout(timeout);
            };
        }
        width.value = position;
        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [position]);
    // eslint-disable-next-line arrow-body-style
    const animatedStyle = useAnimatedStyle(() => {
        // Don't try to remove "return" keyword from here.
        // Reanimated needs to have it here, in order to correctly determine shared values used within the worklet.
        return {
            width: `${width.value >= 100 ? 100 : width.value}%`,
        };
    }, []);
    const styles = useStyles(styleSet);
    const colorPalette = useColors();
    return (<View style={[styles.progress, styles.bar, thin && styles.barThin, style]}>
      <View style={[
            styles.filledBarContainer,
            {
                backgroundColor: barBackgroundColor ?? colorPalette.background.dark,
            },
        ]}>
        {isWeb ? (<Animated.View style={[
                styles.bar,
                thin && styles.barThin,
                animatedStyle,
                { backgroundColor: colors[0] },
            ]}/>) : (<LinearGradientAnimated colors={colors} style={[styles.bar, thin && styles.barThin, animatedStyle]}/>)}
      </View>
    </View>);
};
const styleSet = createStyleSheets(() => ({
    progress: {
        ...(Platform.OS !== 'android' && {
            overflow: 'hidden',
        }),
        flexDirection: 'row',
        borderRadius: rem(14),
    },
    filledBarContainer: {
        flex: 1,
    },
    bar: {
        height: rem(4),
        borderRadius: rem(14),
    },
    barThin: {
        height: rem(2),
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: rem(2),
        borderBottomRightRadius: rem(2),
    },
}));
export default ProgressBar;
