import React, { memo, useMemo } from 'react';
import { View } from 'react-native';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import SectionHeader from 'features/invest/components/SectionHeader';
import RowTransaction from 'features/transactions/components/RowTransaction';
import SwitchingButton from 'features/subscriptions/components/row/SwitchingButton';
import { isWeb } from '../../../constants';
const MonthlyCategoryTransactionRow = ({ item, onPress, onCompare, guessesHomeCountry, isEditingTransactions, selected, }) => {
    const styles = useStyles(styleSet);
    const borderStyles = useMemo(() => [
        item.isLastInSection && styles.lastItem,
        item.isFirstInSection && styles.firstItem,
    ], [
        item.isLastInSection,
        item.isFirstInSection,
        styles.lastItem,
        styles.firstItem,
    ]);
    if ('sectionTitle' in item) {
        return <SectionHeader title={item.sectionTitle}/>;
    }
    return (<View style={[styles.wrapper, borderStyles]}>
      <RowTransaction item={item} onPress={onPress} showUpgradeButton switchingButon={<SwitchingButton data={
            // Add merchandInfo if coming from SearchTransactions
            !item.subscription && item && item.merchant
                ? { merchantInfo: item.merchant }
                : item.subscription} onOpenCompare={onCompare} guessedHomeCountry={guessesHomeCountry}/>} backgroundDark isEditingTransactions={isEditingTransactions} selected={selected} btnStyles={borderStyles}/>
    </View>);
};
export default memo(MonthlyCategoryTransactionRow);
const styleSet = createStyleSheets((colors) => ({
    wrapper: {
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
        marginHorizontal: rem(16),
        backgroundColor: colors.cards.onDark,
    },
    firstItem: {
        ...(isWeb && {
            paddingTop: rem(8),
        }),
        borderTopLeftRadius: rem(16),
        borderTopRightRadius: rem(16),
    },
    lastItem: {
        ...(isWeb && {
            paddingBottom: rem(8),
        }),
        borderBottomLeftRadius: rem(16),
        borderBottomRightRadius: rem(16),
    },
}));
