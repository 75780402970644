import { IconWithGradient } from 'design-system/icons/Icon';
import React, { useId } from 'react';
import { Path, Rect } from 'react-native-svg';
export const IconWifi = ({ off = false, ...props }) => {
    const id = useId();
    return off ? (<IconWithGradient id={id} defaultGradientKey="skyBlue" {...props}>
      <Path fillRule="evenodd" clipRule="evenodd" d="M6.766 5.68a14.303 14.303 0 0 0-4.413 3.014 1.207 1.207 0 0 0 1.707 1.707 11.853 11.853 0 0 1 4.572-2.855L6.766 5.68Zm3.127 3.127a10.272 10.272 0 0 0-4.724 2.703 1.207 1.207 0 0 0 1.707 1.707 7.862 7.862 0 0 1 5.12-2.306L9.894 8.807Zm5.45 2.622-2.945-2.946h.08c2.76 0 5.355 1.075 7.307 3.027a1.207 1.207 0 0 1-1.707 1.707 7.9 7.9 0 0 0-2.735-1.788Zm-1.693 1.135 3.391 3.391a1.214 1.214 0 0 1-.92.426c-.308 0-.617-.118-.853-.354a3.918 3.918 0 0 0-2.791-1.156c-1.055 0-2.046.41-2.79 1.156a1.206 1.206 0 1 1-1.708-1.707 6.317 6.317 0 0 1 4.498-1.863c.398 0 .79.036 1.173.107Zm-2.723-5.55L8.87 4.955c1.164-.301 2.374-.456 3.608-.456 3.825 0 7.42 1.49 10.124 4.194a1.208 1.208 0 0 1-1.707 1.707 11.823 11.823 0 0 0-8.417-3.487c-.523 0-1.04.033-1.55.1Zm.093 10.347a2.062 2.062 0 1 0 2.917 2.916 2.062 2.062 0 0 0-2.917-2.916Z" fill={`url(#gradient_${id})`}/>
      <Rect x={3} y={3.914} width={2} height={23.866} rx={1} transform="rotate(-45 3 3.914)" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>) : (<IconWithGradient id={id} defaultGradientKey="skyBlue" {...props}>
      <Path d="M11.02 17.36a2.063 2.063 0 1 0 2.917 2.917 2.063 2.063 0 0 0-2.917-2.916Z" fill={`url(#gradient_${id})`}/>
      <Path d="M12.477 12.457A6.316 6.316 0 0 0 7.98 14.32a1.206 1.206 0 1 0 1.706 1.707 3.918 3.918 0 0 1 2.791-1.156c1.055 0 2.046.41 2.791 1.156a1.205 1.205 0 0 0 1.707 0 1.206 1.206 0 0 0 0-1.707 6.316 6.316 0 0 0-4.498-1.863Z" fill={`url(#gradient_${id})`}/>
      <Path d="M12.477 8.483c-2.76 0-5.356 1.075-7.308 3.027a1.207 1.207 0 1 0 1.707 1.707 7.87 7.87 0 0 1 5.601-2.32 7.87 7.87 0 0 1 5.6 2.32 1.204 1.204 0 0 0 1.707 0 1.207 1.207 0 0 0 0-1.707 10.265 10.265 0 0 0-7.307-3.027Z" fill={`url(#gradient_${id})`}/>
      <Path d="M22.602 8.694A14.22 14.22 0 0 0 12.478 4.5 14.22 14.22 0 0 0 2.353 8.694a1.207 1.207 0 0 0 1.707 1.707 11.825 11.825 0 0 1 8.418-3.487c3.18 0 6.169 1.238 8.417 3.487a1.207 1.207 0 0 0 1.707-1.707Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
