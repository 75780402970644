import { LOGGED_OUT } from 'actions/types';
const initialState = {
    activities: [],
    paginationToken: undefined,
    requestingActivities: false,
};
const emmaWalletReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGGED_OUT: {
            return initialState;
        }
        case 'GET_EMMA_WALLET_ACTIVITIES_REQUEST': {
            return {
                ...state,
                requestingActivities: true,
                paginationToken: 'page' in action.extra && action.extra.page === 1
                    ? undefined
                    : state.paginationToken,
            };
        }
        case 'GET_EMMA_WALLET_ACTIVITIES_SUCCESS': {
            if (!state.paginationToken) {
                return {
                    ...state,
                    requestingActivities: false,
                    activities: action.payload.activities,
                    paginationToken: action.payload.activities.length < 30
                        ? null
                        : action.payload.paging.next,
                };
            }
            return {
                ...state,
                requestingActivities: false,
                paginationToken: action.payload.paging.next,
                activities: [...(state.activities ?? []), ...action.payload.activities],
            };
        }
        case 'GET_EMMA_WALLET_ACTIVITIES_FAILURE': {
            return {
                ...state,
                requestingActivities: false,
            };
        }
        default: {
            return state;
        }
    }
};
export default emmaWalletReducer;
