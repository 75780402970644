import React from 'react';
import { View, StyleSheet, TouchableHighlight } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
const TapableCard = ({ title, subtitle, IconComponent, onPress, }) => {
    const colors = useColors();
    return (<TouchableHighlight onPress={onPress} underlayColor={colors.background.underlay} activeOpacity={1}>
      <View style={styles.container}>
        <IconComponent />
        <View style={styles.infoContainer}>
          <Text Text-16>{title}</Text>
          <Text TextThin-14 Gray>
            {subtitle}
          </Text>
        </View>
      </View>
    </TouchableHighlight>);
};
const styles = StyleSheet.create({
    container: {
        padding: rem(16),
        flexDirection: 'row',
        alignItems: 'center',
    },
    infoContainer: {
        flex: 1,
        marginLeft: rem(20),
    },
});
export default TapableCard;
