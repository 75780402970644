import React, { useCallback } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import ComingSoonIcon from './ComingSoonIcon';
const NavBarItem = ({ Icon, title, onPress, isActive, showComingSoon, }) => {
    const styles = useStyles(styleSet);
    const [isHovering, setIsHovering] = React.useState(false);
    const handleEvent = useCallback((event) => () => {
        setIsHovering(event === 'onHoverIn');
    }, []);
    return (<View>
      <Pressable onPress={onPress} onHoverIn={handleEvent('onHoverIn')} onHoverOut={handleEvent('onHoverOut')} style={[
            styles.container,
            isHovering && styles.hovering,
            isActive && styles.active,
        ]}>
        <Icon isActive={isActive}/>
        <Spacer w={16}/>
        <Text Text-16 {...(isActive ? { Primary: true } : { Secondary: true })}>
          {title}
        </Text>
      </Pressable>
      {showComingSoon && isHovering && (<View pointerEvents="none" style={styles.hoveringContainer}>
          <ComingSoonIcon />
          <Spacer w={16}/>
          <Text Text-14 Secondary>
            COMING SOON
          </Text>
        </View>)}
    </View>);
};
export default React.memo(NavBarItem);
const styleSet = createStyleSheets((colors) => ({
    container: {
        alignItems: 'center',
        flexDirection: 'row',
        borderRadius: rem(16),
        paddingVertical: rem(12),
        paddingHorizontal: rem(16),
    },
    hoveringContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        borderRadius: rem(16),
        paddingVertical: rem(12),
        paddingHorizontal: rem(16),
        backgroundColor: colors.cards.onDark,
        ...StyleSheet.absoluteFillObject,
    },
    hovering: {
        backgroundColor: colors.control.hoverOnDark,
    },
    active: {
        backgroundColor: colors.cards.onDark,
    },
}));
