import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
const IconAmountLower = (props) => {
    const id = useId();
    return (<IconWithGradient viewBox="0 0 24 24" id={id} width={24} height={24} defaultGradientKey="lightPink" {...props}>
      <Path fill={`url(#gradient_${id})`} fillRule="evenodd" d="m10.602 12.314 3.592-3.193a1.5 1.5 0 1 0-1.993-2.242l-4.061 3.61a2.5 2.5 0 0 0 .212 3.906l3.976 2.827a1.5 1.5 0 0 0 1.739-2.445l-3.465-2.463Z" clipRule="evenodd"/>
    </IconWithGradient>);
};
export default IconAmountLower;
