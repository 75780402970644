import React, { useCallback, useState } from 'react';
import { interpolate, useAnimatedProps, useAnimatedRef, useAnimatedScrollHandler, useSharedValue, } from 'react-native-reanimated';
import { rem } from 'design-system/values';
import EachSuggestion from 'design-system/suggestions/EachSuggestion';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
const Suggestions = ({ suggestions, suggestedName, didUserAlterName, contentContainerStyle, handleSuggestionPress, }) => {
    const [mainContainer1Width, setMainContainer1Width] = useState(0);
    const [mainContainer2Width, setMainContainer2Width] = useState(0);
    const { width: WINDOW_WIDTH } = useAppFrameDimensions();
    const scrollviewRef1 = useAnimatedRef();
    const scrollviewRef2 = useAnimatedRef();
    const ctx = useSharedValue(0);
    const scrollX1 = useSharedValue(0);
    const scrollX2 = useSharedValue(0);
    const scrollHandler1 = useAnimatedScrollHandler({
        onBeginDrag: () => {
            ctx.value = 1;
        },
        onScroll: (event) => {
            scrollX1.value = event.contentOffset.x;
        },
    });
    const scrollHandler2 = useAnimatedScrollHandler({
        onBeginDrag: () => {
            ctx.value = 2;
        },
        onScroll: (event) => {
            scrollX2.value = event.contentOffset.x;
        },
    });
    const animatedProps1 = useAnimatedProps(() => {
        if (ctx.value === 1 || suggestions[1].length === 0) {
            return {};
        }
        return {
            contentOffset: {
                x: interpolate(scrollX2.value, [-1, 0, mainContainer2Width, mainContainer2Width + 1], [-1, 0, mainContainer1Width, mainContainer1Width + 1]),
                y: 0,
            },
        };
    }, [mainContainer1Width, mainContainer2Width]);
    const animatedProps2 = useAnimatedProps(() => {
        if (ctx.value === 2) {
            return {};
        }
        return {
            contentOffset: {
                x: interpolate(scrollX1.value, [-1, 0, mainContainer1Width, mainContainer1Width + 1], [-1, 0, mainContainer2Width, mainContainer2Width + 1]),
                y: 0,
            },
        };
    }, [mainContainer1Width, mainContainer2Width]);
    const onLayout = (setMainContainer1Width) => (event) => {
        const { width } = event.nativeEvent.layout;
        setMainContainer1Width(width - WINDOW_WIDTH + rem(24));
    };
    const onSuggestionPress = useCallback((name, emoji) => () => {
        handleSuggestionPress(name, emoji);
    }, [handleSuggestionPress]);
    return (<>
      <EachSuggestion data={suggestions[0]} onScroll={scrollHandler1} onPress={onSuggestionPress} animatedProps={animatedProps1} scrollViewRef={scrollviewRef1} didUserAlterName={didUserAlterName} suggestedName={suggestedName} onLayout={onLayout(setMainContainer1Width)} contentContainerStyle={contentContainerStyle}/>
      <EachSuggestion data={suggestions[1]} onScroll={scrollHandler2} onPress={onSuggestionPress} animatedProps={animatedProps2} scrollViewRef={scrollviewRef2} didUserAlterName={didUserAlterName} suggestedName={suggestedName} onLayout={onLayout(setMainContainer2Width)} contentContainerStyle={contentContainerStyle}/>
    </>);
};
export default React.memo(Suggestions);
