import React from 'react';
import { Path, Mask, Ellipse } from 'react-native-svg';
import useColors from 'hooks/useColors';
import { Icon } from 'design-system/icons/Icon';
const size = 20;
const IconTooltip = ({ color, colorKey, ...props }) => {
    const colors = useColors();
    const fill = color || (colorKey ? colors.elements[colorKey] : colors.text.secondary);
    return (<Icon width={props.width || size} height={props.height || size} viewBox="0 0 20 20" fill="none" {...props}>
      <Path d="M2.79406 7.45869C3.33698 5.14417 5.14417 3.33698 7.45869 2.79406C9.13021 2.40198 10.8698 2.40198 12.5413 2.79406C14.8558 3.33698 16.663 5.14417 17.2059 7.4587C17.598 9.13021 17.598 10.8698 17.2059 12.5413C16.663 14.8558 14.8558 16.663 12.5413 17.2059C10.8698 17.598 9.13021 17.598 7.4587 17.2059C5.14418 16.663 3.33698 14.8558 2.79406 12.5413C2.40198 10.8698 2.40198 9.13021 2.79406 7.45869Z" stroke={fill} strokeWidth="1.25"/>
      <Path d="M10 12.9167V9.58334" stroke={fill} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
      <Mask id="path-3-inside-1" fill="white">
        <Ellipse cx="10.0001" cy="7.5" rx="0.833333" ry="0.833333"/>
      </Mask>
      <Ellipse cx="10.0001" cy="7.5" rx="0.833333" ry="0.833333" fill={fill}/>
      <Path d="M9.58341 7.5C9.58341 7.26988 9.76996 7.08333 10.0001 7.08333V9.58333C11.1507 9.58333 12.0834 8.65059 12.0834 7.5H9.58341ZM10.0001 7.08333C10.2302 7.08333 10.4167 7.26988 10.4167 7.5H7.91675C7.91675 8.65059 8.84949 9.58333 10.0001 9.58333V7.08333ZM10.4167 7.5C10.4167 7.73012 10.2302 7.91666 10.0001 7.91666V5.41666C8.84949 5.41666 7.91675 6.3494 7.91675 7.5H10.4167ZM10.0001 7.91666C9.76996 7.91666 9.58341 7.73012 9.58341 7.5H12.0834C12.0834 6.3494 11.1507 5.41666 10.0001 5.41666V7.91666Z" fill={fill} mask="url(#path-3-inside-1)"/>
    </Icon>);
};
export default IconTooltip;
