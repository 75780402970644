import Video from 'utils/packages/rnVideo';
import Animated from 'react-native-reanimated';
import { Circle, Path, Use } from 'react-native-svg';
import LinearGradient from 'react-native-linear-gradient';
import { Text, TextInput, SectionList } from 'react-native';
import TouchableBounce from 'utils/packages/TouchableBounce';
import { KeyboardAwareScrollView } from 'utils/packages/rnKeyboardAwareScrollview';
Animated.addWhitelistedNativeProps({ text: true });
export const AnimatedUse = Animated.createAnimatedComponent(Use);
export const AnimatedText = Animated.createAnimatedComponent(Text);
export const AnimatedPath = Animated.createAnimatedComponent(Path);
export const AnimatedVideo = Animated.createAnimatedComponent(Video);
export const AnimatedCircle = Animated.createAnimatedComponent(Circle);
export const AnimatedTextInput = Animated.createAnimatedComponent(TextInput);
export const AnimatedTouchableBounce = Animated.createAnimatedComponent(TouchableBounce);
export const AnimatedSectionList = Animated.createAnimatedComponent(SectionList);
export const LinearGradientAnimated = Animated.createAnimatedComponent(LinearGradient);
export const AnimatedKeyboardAwareScrollView = Animated.createAnimatedComponent(KeyboardAwareScrollView);
