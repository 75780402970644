import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
const IconCategoryHolidays = (props) => (<Svg width={props.width} height={props.height} fill="none" {...props}>
    <Path d="m20.813 14.693-1.125 4.266c-.32-.078-.601-.227-.765-.422-.344-.414-.89-.648-1.492-.648-.594 0-1.141.234-1.485.648-.25.305-.797.508-1.344.508-.554 0-1.085-.195-1.343-.508-.344-.414-.89-.648-1.485-.648-.601 0-1.148.234-1.492.648-.164.195-.445.336-.765.422l-1.125-4.266a.59.59 0 0 1 .328-.68l5.64-2.523a.65.65 0 0 1 .485 0l5.64 2.524a.59.59 0 0 1 .328.68Z" fill={props.fill}/>
    <Path d="M20.267 20.998c-1.144 0-2.206-.45-2.84-1.203-.618.753-1.68 1.203-2.824 1.203s-2.206-.45-2.84-1.203c-.618.753-1.68 1.203-2.824 1.203a.586.586 0 0 1 0-1.172c.791 0 1.535-.3 1.942-.785.414-.492 1.366-.492 1.78 0 .407.484 1.15.785 1.942.785.791 0 1.535-.3 1.942-.785.414-.492 1.366-.492 1.78 0 .406.484 1.15.785 1.942.785a.586.586 0 0 1 0 1.172Z" fill={props.fill}/>
    <Path d="M14.016 10.06v.735l-4.101 1.828v-1.047c.484-.43 1.015-.945 1.523-1.516h2.578Z" fill={props.fill}/>
    <Path d="M18.703 10.06h-3.516v.733l4.102 1.83v-1.976a.586.586 0 0 0-.586-.587Z" fill={props.fill}/>
    <Path d="M15.188 6.148v-.976a.586.586 0 0 0-1.171 0v.976h1.171Z" fill={props.fill}/>
    <Path d="M16.946 7.525v1.758H12.26V9.08c.5-.672.93-1.39 1.21-2.14h2.891a.59.59 0 0 1 .586.585Z" fill={props.fill}/>
    <Path d="M7.18 11.625a.584.584 0 0 1-.349-.116c-.484-.36-4.728-3.595-4.728-6.339 0-1.61 1.314-2.92 2.93-2.92.83 0 1.604.348 2.148.933a2.934 2.934 0 0 1 5.078 1.987c0 2.744-4.245 5.98-4.73 6.34a.584.584 0 0 1-.348.115Z" fill={props.fill}/>
  </Svg>);
export default IconCategoryHolidays;
