import Svg, { Path } from 'react-native-svg';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import * as React from 'react';
export const IconApple = ({ color }) => {
    const colors = useColors();
    const width = rem(24);
    const height = rem(24);
    return (<Svg viewBox="0 0 24 24" width={width} height={height} fill="none">
      <Path d="M17.663 12.571c-.009-1.644.735-2.883 2.24-3.797-.842-1.205-2.114-1.868-3.792-1.996-1.589-.125-3.327.926-3.963.926-.673 0-2.211-.883-3.421-.883-2.498.039-5.152 1.992-5.152 5.964 0 1.174.215 2.387.644 3.636.573 1.644 2.642 5.672 4.799 5.607 1.129-.026 1.927-.8 3.395-.8 1.424 0 2.162.8 3.42.8 2.177-.031 4.048-3.693 4.593-5.342-2.919-1.377-2.763-4.031-2.763-4.115ZM15.13 5.218c1.222-1.451 1.111-2.772 1.075-3.247-1.08.063-2.329.735-3.04 1.562-.784.887-1.244 1.984-1.145 3.221 1.167.089 2.232-.511 3.11-1.536Z" fill={color || colors.elements.primary}/>
    </Svg>);
};
