import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
const IconRotate = ({ width = rem(24), height = rem(24), color, }) => {
    const colors = useColors();
    return (<Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <Path fillRule="evenodd" clipRule="evenodd" d="M20.3077 3C19.9253 3 19.6154 3.30996 19.6154 3.69231V6.57203C19.3438 6.29523 19.0286 6.00111 18.6695 5.70695C17.1979 4.50141 14.971 3.28161 12 3.28161C7.01198 3.28161 3 7.23836 3 12.1408C3 17.0447 7.04064 21 12 21C16.9594 21 21 17.0447 21 12.1408C21 11.7585 20.69 11.4485 20.3077 11.4485C19.9253 11.4485 19.6154 11.7585 19.6154 12.1408C19.6154 16.2578 16.2171 19.6154 12 19.6154C7.78294 19.6154 4.38462 16.2578 4.38462 12.1408C4.38462 8.02236 7.75725 4.66623 12 4.66623C14.5675 4.66623 16.4944 5.71501 17.792 6.77805C18.2326 7.13897 18.5984 7.49995 18.8891 7.81877H16.0107C15.6283 7.81877 15.3184 8.12873 15.3184 8.51108C15.3184 8.89343 15.6283 9.20339 16.0107 9.20339H20.3077C20.69 9.20339 21 8.89343 21 8.51108V3.69231C21 3.30996 20.69 3 20.3077 3Z" fill={color ?? colors.elements.brand}/>
    </Svg>);
};
export default IconRotate;
